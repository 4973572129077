/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ApplicationView from '../ApplicationView/ApplicationView';
import { DatasetContext } from '../DatasetContext';
import CompareParameterViewControl from './ViewControl/CompareViewControl';
import CompareParameterViewHeader from './ViewHeader/CompareParameterViewHeader';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { requestDatasetList } from '../../store/deviceInstances/middleware/datasetProvider';
import { setContextMenuHelp } from '../../store/contexthelp';
import { COMPARE_DEVICE_VIEW__HELP__LOCID, COMPARE_DEVICE_VIEW__TITLE__LOCID } from './common';
import './CompareParameterView.scss';

const CompareParameterView: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const menuTitle = t(COMPARE_DEVICE_VIEW__TITLE__LOCID);
  const menuHelp = t(COMPARE_DEVICE_VIEW__HELP__LOCID);

  useEffect(() => {
    dispatch(requestDatasetList({}));
  }, []);

  useEffect(() => {
    dispatch(setContextMenuHelp({
      title: menuTitle,
      text: menuHelp,
    }));
  }, [menuTitle, menuHelp]);

  return (
    <div className="compare-parameter-view">
      <DatasetContext.Provider value={{ targetDataset: DatasetType.user }}>
        <ApplicationView buttons helpbar>
          <div className="compare-parameter-view__content">
            <CompareParameterViewHeader />
            <CompareParameterViewControl />
          </div>
        </ApplicationView>
      </DatasetContext.Provider>
    </div>
  );
};

export default CompareParameterView;
