/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import H5ManualButtonGroup from './H5ManualButtonGroup/H5ManualButtonGroup';
import OnlineCardControl from '../OnlineCardControl/OnlineCardControl';
import './H5ManualControlOnlineCard.scss';

export interface H5ManualControlOnlineCardProps {
  deviceInstanceId: string;
  controlView?: DeviceModelStatus.UI.Contactron.MotorStarterH5ManualControlView;
}

const H5ManualControlOnlineCard: React.FC<H5ManualControlOnlineCardProps> = (props: H5ManualControlOnlineCardProps):
  React.ReactElement => {
  const {
    controlView,
    deviceInstanceId,
  } = props;

  if (controlView === undefined) {
    return <></>;
  }

  return (
    <OnlineCardControl label={controlView.label ?? ''}>
      <div className="h5motorstarter-online-manual__buttons">
        <H5ManualButtonGroup deviceInstanceId={deviceInstanceId} controlView={controlView} />
      </div>
    </OnlineCardControl>
  );
};

export default H5ManualControlOnlineCard;
