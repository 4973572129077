/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { PropsWithChildren } from 'react';

export type DataTableRowHdrProps = PropsWithChildren<any>

export const DataTableRowHeader: React.FC<DataTableRowHdrProps> = (props: DataTableRowHdrProps):
  React.ReactElement => {
  const {
    children,
  } = props;

  return (
    <div className="data-table-control-row-header">
      {children}
    </div>
  );
};
