/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */

import React from 'react';
import { Services } from '@gpt/commons';
import { ButtonRole } from '../store/deviceWizard/types';

export interface WizardViewContextProps {
  downloadValues: Services.DeviceModel.StatusValueRef[];
  // eslint-disable-next-line no-unused-vars
  setDownloadValues: (values: Services.DeviceModel.StatusValueRef[]) => void,
  // eslint-disable-next-line no-unused-vars
  changeWizardPage: (button: ButtonRole) => void,
}

export const WizardViewContext = React.createContext<WizardViewContextProps>({
  downloadValues: [],
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  setDownloadValues: (values: Services.DeviceModel.StatusValueRef[]) => 0,
  changeWizardPage: () => 0,
});
