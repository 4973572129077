/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import useDisplayStatusValue from '../../../../hooks/useDisplayStatusValue';
import { DeviceStatusControl } from '../../../../../components/DeviceStatusControl/DeviceStatusControl';
import { useContextDatasetStatusDescriptor, useContextDatasetStatusValue } from '../../../../../hooks/useContextDataset';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';

export interface SystemStatusProps {
  deviceInstanceId: string;
  identRef: string;
  showValue?: boolean;
  value0color: string;
  valueN0color: string;
}

export const SystemStatus: React.FC<SystemStatusProps> = (props: SystemStatusProps): React.ReactElement => {
  const { t } = useTranslation();
  const {
    deviceInstanceId, identRef, value0color, valueN0color, showValue,
  } = props;
  const statusDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, identRef, DatasetType.device);
  const statusDisplayValue = useDisplayStatusValue(deviceInstanceId, identRef, DatasetType.device);
  const statusValue = useContextDatasetStatusValue(deviceInstanceId, identRef, DatasetType.device);

  return (
    <DeviceStatusControl
      label={t<string>(statusDescriptor?.label ?? '')}
      text={showValue === true ? statusDisplayValue : ''}
      led={{
        fgcolor: statusValue?.value === 0 ? value0color : valueN0color,
      }}
    />
  );
};
