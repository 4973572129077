/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  DeviceStartup, Report, ReportContentType, DeviceInformation,
} from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../store/reduxStore';
import { PageSaveProtocolComponent } from '../../../../PageComponents/PageSaveProtocolComponent/PageSaveProtocolComponent';
import { ActionProgressControl } from '../../../../components/ActionProgressControl/ActionProgressControl';
import { LoadingControl } from '../../../../components/LoadingControl/LoadingControl';
import { WizardViewPageProps } from '../../../WizardView/WizardViewPage';
import { deviceMethodExecutionSelector } from '../../../../store/deviceInstances/store/deviceMethod/selectors';
import { MethodStageExecutionStatus } from '../../../../store/deviceInstances/store/deviceMethod/types';
import { deviceInstanceActiveDeviceInstance } from '../../../../store/deviceInstances/store/activeDevice/selectors';
import useMethodExecutionStateStatus from '../../../hooks/useMethodExecutionStateStatus';
import { WRITE_CONFIGURATION_METHOD_IDENT } from '../types';
import { contactronWizardCreateReport } from '../../../../store/middleware/contactronWizard/actions';

const getContentHandler = async (
  activeDevice: DeviceInformation,
  userName: string,
  userNotes: string,
  serialNumber: string,
  systemName: string,
): Promise<Report> => {
  const content: DeviceStartup = {
    type: ReportContentType.DeviceStartup,
    device: {
      deviceDesignation: activeDevice.catalog?.deviceTypeName ?? '',
      deviceTag: activeDevice.instance?.deviceTag ?? '',
      firmwareRevision: activeDevice.instance?.firmwareVersion ?? '',
      hardwareRevision: activeDevice.instance?.hardwareVersion ?? '',
      serialNumber,
      itemNumber: activeDevice.catalog?.productOrderNumber ?? '',
    },
  };

  return {
    header: {
      dateTime: new Date(),
      systemName,
      userName,
      userNotes,
    },
    content,
  };
};

export interface Page7ReportProps extends WizardViewPageProps {
  stationInstanceId: string;
}

const Page7Report
: React.FC<Page7ReportProps> = (props: Page7ReportProps) : React.ReactElement => {
  const { t } = useTranslation();
  const { active, reportMenu, stationInstanceId } = props;
  const dispatch = useDispatch();
  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, stationInstanceId)?.device);

  const stageSteps = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, stationInstanceId, WRITE_CONFIGURATION_METHOD_IDENT)?.steps ?? []);
  const stageStatus = useMethodExecutionStateStatus(stationInstanceId, WRITE_CONFIGURATION_METHOD_IDENT, active);

  if (activeDevice === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const downloadMethodDone = (stageStatus === MethodStageExecutionStatus.DoneSuccess);
  return (
    <Container className="w-100 h-100" fluid>
      <Row>
        <Col xs={6}>
          <ActionProgressControl
            title={t<string>('WIZARD_DEVICE_COMMISSIONING__FINAL_PAGE__PROGRESS_CAPTION')}
            steps={stageSteps}
          />
        </Col>
        <Col xs={6}>
          <PageSaveProtocolComponent
            reportMenu={reportMenu}
            deviceInstanceId={stationInstanceId}
            caption={t<string>('WIZARD_DEVICE_COMMISSIONING__FINAL_PAGE__DOCUMENTATION__TITLE')}
            nameCaption={t<string>('WIZARD_DEVICE_COMMISSIONING__FINAL_PAGE__DOCUMENTATION__NAME')}
            descriptionCaption={t<string>('WIZARD_DEVICE_COMMISSIONING__FINAL_PAGE__DOCUMENTATION__DESCRIPTION')}
            disableButtons={!downloadMethodDone}
            serialNumber={activeDevice.instance?.serialNumber}
            fileName=""
            onGetContent={(caption, userComments, serialNumber) => getContentHandler(
              activeDevice,
              caption,
              userComments,
              serialNumber,
              t<string>('', '{{SYSTEM_NAME}}'),
            )}
            onCreateReport={(caption, userComments, serialNumber, reportType, systemName) => {
              dispatch(contactronWizardCreateReport({
                userNameValue: caption,
                userTextValue: userComments,
                serialNumberValue: serialNumber,
                type: reportType,
                systemName: systemName,
                stationInstanceId: stationInstanceId,
              }));
            }}
            showSaveParameter={false}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Page7Report;
