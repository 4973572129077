/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useDispatch } from 'react-redux';
import { ChannelSwitchNumber } from './ChannelSwitchNumber/ChannelSwitchNumber';
import { useContextStatusValue } from '../../../../../store/deviceInstances/hooks/DeviceDatasetHooks';
import { updateDeviceDatasetValues } from '../../../../../store/deviceInstances/store/deviceDataset';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { MethodStageExecutionStatus } from '../../../../../store/deviceInstances/store/deviceMethod/types';
import useIoLinkWriteParameter from '../../../../IoLink/hooks/useIoLinkWriteParameter';
import useCaparocChannelState, { channelVariables } from '../../../hooks/useCaparocChannelState';

const switchActionIdent = (value: number) => `CAPAROC_MODULE_CHANNEL_SWITCH_${value}`;

const channelStartIndex = 3343; // -1
const channel1StatusSubindex = 1;

export interface ChannelSwitchProps {
    deviceInstanceId: string;
    channelNo: number;
    disabled: boolean;
    label: string;
}

const ReduxChannelSwitch: React.FC<ChannelSwitchProps> = (props: ChannelSwitchProps)
:React.ReactElement => {
  const {
    deviceInstanceId,
    channelNo,
    disabled,
    label,
  } = props;

  const dispatch = useDispatch();
  const varChannel = channelVariables(channelNo);

  const channelStatusValue = useContextStatusValue(deviceInstanceId, varChannel.varChannelStatus, DatasetType.device)?.value ?? 0;
  const state = useCaparocChannelState(deviceInstanceId, channelNo);

  const actionId = switchActionIdent(channelNo);
  const [stage, writeIoLinkParameter] = useIoLinkWriteParameter(deviceInstanceId, actionId);
  const isDisabled = disabled || stage === MethodStageExecutionStatus.InProgress;

  return (
    <div className="caparoc-channel-switch p-1">
      <ChannelSwitchNumber
        id={varChannel.varChannelStatus}
        // eslint-disable-next-line react/no-array-index-key
        key={`channel-switch-number-${channelNo}`}
        label={label}
        state={state}
        onSwitchChannel={() => {
          const newValue = channelStatusValue === 0 ? 1 : 0;
          // send new value to dataset, while waiting for new data
          dispatch(updateDeviceDatasetValues(deviceInstanceId, {
            targetDataset: DatasetType.device,
            values: [{
              value: newValue,
              identRef: varChannel.varChannelStatus,
              backupValue: undefined,
            },
            {
              value: 0,
              identRef: varChannel.varChannelErrorStatus,
              backupValue: undefined,
            }],
          }));
          writeIoLinkParameter(channelStartIndex + channelNo, new Uint8Array([newValue]), channel1StatusSubindex);
        }}
        disabled={isDisabled}
      />
    </div>
  );
};

export default ReduxChannelSwitch;
