/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { UnsupportedControl } from '../../components/UnsupportedControl';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import withControlVisibility from '../hoc/withControlVisibility';
import { ReduxControlProps } from '../ReduxControlProps';
import MenuSelectorControl from './MenuSelectorControl/MenuSelectorControl';

const MenuSelectorReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
    : React.ReactElement | null => {
  const { identRef, onStateChanged, deviceInstanceId } = props;
  const controlDesc = useContextDatasetDescriptor(deviceInstanceId, identRef);

  if (controlDesc === undefined) {
    return <UnsupportedControl text={`MenuSelectorRedux: ${identRef} undefined element`} />;
  }
  if (controlDesc.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="MenuSelectorRedux: Only ControlDescriptor type is supported" />;
  }
  if (controlDesc.controlType.type !== DeviceModelStatus.UI.ControlType.CTLSELECT) {
    return <UnsupportedControl text="MenuSelectorRedux: Only CTLSELECT control is supported" />;
  }

  return (
    <MenuSelectorControl
      identRef={identRef}
      deviceInstanceId={deviceInstanceId}
      control={controlDesc.controlType}
      onStateChanged={onStateChanged}
    />
  );
};

export default withControlVisibility(MenuSelectorReduxControl);
