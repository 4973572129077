/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import TrendViewReduxControl from '../../../TrendViewControl/TrendViewReduxControl/TrendViewReduxControl';

export interface SystemTrendCockpitCardProps {
  deviceInstanceId: string;
  trendView?: DeviceModelStatus.UI.Contactron.MotorStarterTrendView;
}

const SystemTrendCockpitCard: React.FC<SystemTrendCockpitCardProps> = (props: SystemTrendCockpitCardProps):
  React.ReactElement => {
  const {
    trendView,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  return (
    <PaperControl title={t<string>(trendView?.label ?? 'TREND')}>
      <TrendViewReduxControl identRef={trendView?.control ?? 'unknown'} deviceInstanceId={deviceInstanceId} onStateChanged={() => 0} readonly />
    </PaperControl>
  );
};

export default SystemTrendCockpitCard;
