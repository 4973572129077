/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { setContextMenuHelp } from '../../store/contexthelp';
import { setApplicationId } from '../../store/deviceInstances/store/standardview/actions';
import './NavigationButton.css';

export interface NavigationButtonProps {
  routePath: string;
  title: string;
  subtitle: string;
  image: string;
  applicationId?: string;
  onClick?: () => void;
  deviceInstanceId: string;
}

export type NavigationButtonPropsInternal = NavigationButtonProps

/**
 * This Component is a NavigationButton with an image, title and subtitle.
 * The top of the title is laid out corresponding to the top of the image
 * The subtitle is optional.
 */
const NavigationButton:
React.FC<NavigationButtonPropsInternal> = (props: NavigationButtonPropsInternal):
React.ReactElement => {
  const {
    routePath, title, subtitle, image, onClick, applicationId,
    deviceInstanceId,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const activate = useCallback(() => {
    dispatch(setApplicationId(deviceInstanceId, applicationId));
    dispatch(setContextMenuHelp({ title: '', text: '' }));
    if (onClick !== undefined) {
      onClick();
      return;
    }
    if (routePath) {
      navigate(routePath);
    }
  }, [deviceInstanceId, applicationId, routePath]);

  let selected = (routePath === location.pathname) ? 'selected' : '';
  selected += !routePath ? 'disabled' : '';

  return (
    <div
      className={`navbutton ${selected} d-flex align-items-center noWrap`}
      role="button"
      tabIndex={0}
      onClick={(ev) => { ev.stopPropagation(); activate(); }}
      onKeyDown={(ev) => ev.key === 'Enter' && activate()}
    >
      <div>
        <img src={image} alt="" />
      </div>
      <div style={{ marginLeft: '16px' }}>
        <p className="navbutton-title-format p-0 m-0">{title}</p>
        <p className="navbutton-subtitle-format m-0 p-0">{subtitle}</p>
      </div>
    </div>
  );
};

export default NavigationButton;
