/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import {
  polarToCartesian, switchCenterX, switchCenterY, switchRadiusText, switchRadius, switchWidth, switchHeight,
} from '../types';
import SVGRotarySwitchPosition from './SVGSwitchPos/SVGSwitchPos';

export const ROTARY_SWITCH_MODE__NOT_AVAILABLE = 0;
export const ROTARY_SWITCH_MODE__NORC = 1;
export const ROTARY_SWITCH_MODE__RC = 2;

export interface DeviceRotarySwitchProps {
  nominalCurrentVaue: number;
  rotarySwitchValue: number;
  switchPositionsValues: string[];
}

const SVGDeviceRotarySwitch: React.FC<DeviceRotarySwitchProps> = (props: DeviceRotarySwitchProps):
  React.ReactElement | null => {
  const {
    nominalCurrentVaue,
    rotarySwitchValue,
    switchPositionsValues,
  } = props;

  //   const switchPositionsText. = ['RC', '2', '4', '6', '8', '10'];
  const switchPosistions = switchPositionsValues.map((txt, index) => ({
    text: txt,
    angle: 150 + ((360 / switchPositionsValues.length) * index),
  }));

  let switchPositionAngle = 0;
  const currentPos = rotarySwitchValue === ROTARY_SWITCH_MODE__RC ? 'RC' : `${nominalCurrentVaue}`;
  const switchPosition = switchPosistions.find((pos) => pos.text === currentPos);
  switchPositionAngle = switchPosition?.angle ?? 0;

  const textPoints = switchPosistions.map((pos) => {
    const arcPos = polarToCartesian(switchCenterX, switchCenterY, switchRadiusText, pos.angle);
    const key = `switch-point-${pos.angle}-${pos.text}`;
    return (
      <text
        key={key}
        x={arcPos.x}
        y={arcPos.y}
        fontSize="32px"
        fontWeight="bold"
        textAnchor="middle"
        alignmentBaseline="central"
        fill="black"
      >
        {pos.text}
      </text>
    );
  });

  return (
    <svg className="svg-rotary-switch" fontFamily="IBM Plex Sans" width="100%" height="100%" viewBox={`0 0 ${switchWidth} ${switchHeight}`} preserveAspectRatio="xMidYMid">
      <circle cx={switchCenterX} cy={switchCenterY} r={switchRadius} stroke="black" strokeWidth="2" fill="orange" />
      <SVGRotarySwitchPosition switchPositionAngle={switchPositionAngle} />
      {textPoints}
    </svg>
  );
};

export default SVGDeviceRotarySwitch;
