/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import './ColdJunctionLayout.scss';

export type ColdJunctionLayoutProps = PropsWithChildren<any>;

const ColdJunctionLayout: React.FC<ColdJunctionLayoutProps> = (
  props: ColdJunctionLayoutProps,
): React.ReactElement => {
  const {
    children,
  } = props;
  const { t } = useTranslation();
  const childrenArray = React.Children.toArray(children);

  if (childrenArray.length > 1) {
    return (
      <div className="coldjunction-layout-container">
        <div className="coldjunction-layout-header">
          <div className="designation coldjunction-layout-text">{t('COLD_JUNCTION_LINE_COMPENSATION__DESIGNATION')}</div>
          <div className="currently-set coldjunction-layout-text">{t('COLD_JUNCTION_LINE_COMPENSATION__CURRENTLY_SET')}</div>
          <div className="coldjunction-layout-text">{t('COLD_JUNCTION_LINE_COMPENSATION__CURRENTYY_MEASURED')}</div>
        </div>
        <div className="coldjunction-layout-controls">
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className="coldjunction-layout-container2">
      <div className="coldjunction-layout-header">
        <div className="currently-set2 coldjunction-layout-text">{t('COLD_JUNCTION_LINE_COMPENSATION__CURRENTLY_SET')}</div>
        <div className="coldjunction-layout-text">{t('COLD_JUNCTION_LINE_COMPENSATION__CURRENTYY_MEASURED')}</div>
      </div>
      <div className="coldjunction-layout-controls">
        {children}
      </div>
    </div>
  );
};

export default ColdJunctionLayout;
