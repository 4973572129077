import { v4 as uuid } from 'uuid';
import { Discovery } from '@gpt/cxe-nats-communication';
import { DiscoveryServiceEventBase } from './connection';

const DISCOVERY_SERVICE__EVENT__DISCOVERY = 'DISCOVERY_SERVICE__EVENT__DISCOVERY';

export interface DiscoveryServiceEventPayload {
  subject: string,
  adapter: Discovery.CommunicationAdapter[],
}

export interface DiscoveryServiceUpdateEvent extends DiscoveryServiceEventBase {
  type: typeof DISCOVERY_SERVICE__EVENT__DISCOVERY
  payload: DiscoveryServiceEventPayload,
}

export const createDiscoveryUpdateEvent = (payload: DiscoveryServiceEventPayload)
: DiscoveryServiceUpdateEvent => ({
  type: 'DISCOVERY_SERVICE__EVENT__DISCOVERY',
  id: uuid(),
  payload,
});
