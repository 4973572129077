/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext, useEffect } from 'react';
import { useTypedSelector } from '../../../store/reduxStore';
import { ReduxControlProps } from '../../ReduxControlProps';
import ReduxControl from '../../ReduxControl/ReduxControl';
import { useMenuItemStateUpdate } from '../../hooks/useMenuItemStateUpdate';
import { compareTabData, menuItemsSelector } from '../../selectors/menuItemsSelector';
import { DatasetContext } from '../../../views/DatasetContext';
import withControlVisibility from '../../hoc/withControlVisibility';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import './VerMenuLayoutReduxControl.scss';

// eslint-disable-next-line max-len
const VerMenuLayoutReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps): React.ReactElement => {
  const {
    identRef,
    onStateChanged,
    readonly,
    rootLayout,
    deviceInstanceId,
  } = props;
  const { targetDataset } = useContext(DatasetContext);

  const menuItems = useTypedSelector(
    (state) => menuItemsSelector(
      deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset),
      identRef,
    ),
    (p, c) => compareTabData(p, c),
  );

  const [modifiedState, errorState, updateMenuItemState] = useMenuItemStateUpdate();

  // When control removed, the modified and error state is clean
  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, {
      error: errorState,
      modified: modifiedState,
    });
  }, [modifiedState, errorState, identRef]);

  const menuItemControls = menuItems
    .map((element) => (
      <ReduxControl
        identRef={element.identRef}
        deviceInstanceId={deviceInstanceId}
        key={`${identRef}--${element.identRef}`}
        readonly={readonly}
        onStateChanged={(ident, state) => updateMenuItemState(ident, state)}
      />
    ));

  const layoutControl = rootLayout
    ? <div className="vmenu-layout-container--overflow-auto">{menuItemControls}</div>
    : <>{menuItemControls}</>;
  return (
    <>
      {layoutControl}
    </>
  );
};

export default withControlVisibility(VerMenuLayoutReduxControl);
