import React from 'react';
import Quint4CompareSignalOut1 from '../../Quin4Compare/Quint4CompareSignalOut1/Quint4CompareSignalOut1';
import Quint4CompareSignalOut2 from '../../Quin4Compare/Quint4CompareSignalOut2/Quint4CompareSignalOut2';
import Quint4CompareSignalOut3 from '../../Quin4Compare/Quint4CompareSignalOut3/Quint4CompareSignalOut3';
import { ComponentMap } from './types';

const compareDeviceSpecificControls: ComponentMap = {
  QUINT4_SIGNALING_OUT1: Quint4CompareSignalOut1,
  QUINT4_SIGNALING_OUT2: Quint4CompareSignalOut2,
  QUINT4_SIGNALING_OUT3: Quint4CompareSignalOut3,
};

export default compareDeviceSpecificControls;
