/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  AppLanguageActionTypes,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const setApplicationLanguage = (language: string)
  : AppLanguageActionTypes => ({
  type: 'APP_SETTINGS__LANGUAGE__SET_LANGUAGE',
  payload: language,
});
