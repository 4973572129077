/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { DeviceParameterViewState, SetCurrentTabAction } from './deviceParameterView/types';
import { GaugeControlState, SetGaugeIndicatorAction } from './gaugeControl/types';
import { DeviceInstanceActionPayload } from '../../common';

export const CLEAN_ALL_CONTROLS = 'CLEAN_ALL_CONTROLS';
export const DEVICE_INSTANCE__CONTROL_STATE__ACTIONS = 'DEVICE_INSTANCE__CONTROL_STATE__ACTIONS';

export type ControlStateItem = GaugeControlState | DeviceParameterViewState;

export interface ControlStateCollection {
    [key: string]: ControlStateItem;
}

export type CleanAllControlsAction
  = Action<typeof CLEAN_ALL_CONTROLS>

export type typeControlStateAction = CleanAllControlsAction | SetGaugeIndicatorAction | SetCurrentTabAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceDataStorageAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceControlStatePayload extends DeviceInstanceActionPayload {
  action: typeControlStateAction;
}

export interface typeDeviceInstanceControlStateAction extends Action<typeof DEVICE_INSTANCE__CONTROL_STATE__ACTIONS> {
  payload: typeDeviceInstanceControlStatePayload;
}
