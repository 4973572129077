/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  Middleware, MiddlewareAPI, Dispatch,
} from 'redux';
import { addApplicationMessage } from '../actions';
import { ApplicationMessageAction, ShowApplicationMessageAction, SHOW_APPLICATION_MESSAGE } from '../types';

const applicationMessageMiddleware = (): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends ApplicationMessageAction>(action: A): Promise<A> => {
  switch (action.type) {
    case SHOW_APPLICATION_MESSAGE: {
      const { payload } = action as ShowApplicationMessageAction;
      api.dispatch(addApplicationMessage(payload));
    }
      break;
    default:
  }
  const result = next(action);
  return result;
};

export default applicationMessageMiddleware;
