/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  ResourceProviderState,
  typeResourceProviderActionTypes,
} from './types';

export const initialResourceProviderState: ResourceProviderState = {};

const deviceModelResourceProviderReducer = (
  state = initialResourceProviderState,
  action: typeResourceProviderActionTypes,
): ResourceProviderState => {
  switch (action.type) {
    case 'RESOURCE_PROVIDER__SET_RESOURCE_ITEM':
      return {
        ...state,
        [action.payload.resourceId]: action.payload,
      };
    default:
      return state;
  }
};

export default deviceModelResourceProviderReducer;
