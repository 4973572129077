/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import { Action } from 'redux';
import { DeviceInstanceActionPayload } from '../../common';

export const TRIO3_EVENT_MONITOR__UPDATE_EVENT_LIST = 'TRIO3_EVENT_MONITOR__UPDATE_EVENT_LIST';
export const TRIO3_EVENT_MONITOR__CLEAN_EVENT_LIST = 'TRIO3_EVENT_MONITOR__CLEAN_EVENT_LIST';
export const TRIO3_EVENT_MONITOR__SET_EVENT_PENDING = 'TRIO3_EVENT_MONITOR__SET_EVENT_PENDING';

export const IOLINK__TRIO3_EVENT_MONITOR__ACTIONS = 'IOLINK__TRIO3_EVENT_MONITOR__ACTIONS';

// eslint-disable-next-line import/prefer-default-export
export enum Trio3EventState {
  // eslint-disable-next-line no-unused-vars
  active = 'active',
  // eslint-disable-next-line no-unused-vars
  pending = 'pending',
  // eslint-disable-next-line no-unused-vars
  inactive = 'inactive',
}

export interface Trio3Event {
  id: string;
  event: DeviceModelStatus.UI.Trio3EventDescription;
  state: Trio3EventState;
  timestamp: string;
}

export type Trio3EventList = {
  [key: string]: Trio3Event;
}

export type IoLinkTrio3EventMonitorState = {
  eventList: Trio3EventList;
}

/* ************************************************************************************ */
/* ACTION: UpdateTrio3EventMonitorAction                                                   */
/* ************************************************************************************ */
export interface UpdateTrio3EventMonitorPayload extends DeviceInstanceActionPayload {
  eventList: string; // base64 blob variable value
}

export interface UpdateTrio3EventMonitorAction extends Action<typeof TRIO3_EVENT_MONITOR__UPDATE_EVENT_LIST> {
  payload: UpdateTrio3EventMonitorPayload; // base64 blob variable value
}

export interface AsknowledgeTrio3EventPayload extends DeviceInstanceActionPayload {
  id: string;
  event: DeviceModelStatus.UI.Trio3EventDescription;
}

export interface SetPendingTrio3EventAction extends Action<typeof TRIO3_EVENT_MONITOR__SET_EVENT_PENDING> {
  payload: AsknowledgeTrio3EventPayload;
}

export type CleanTrio3EventListAction = Action<typeof TRIO3_EVENT_MONITOR__CLEAN_EVENT_LIST>

export type typeIoLinkTrio3EventMonitorActions = UpdateTrio3EventMonitorAction
 | SetPendingTrio3EventAction
 | CleanTrio3EventListAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceInstanceIoLinkTrio3EventMonitorAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceIoLinkTrio3EventMonitorPayload extends DeviceInstanceActionPayload {
  action: typeIoLinkTrio3EventMonitorActions;
}

export interface typeDeviceInstanceIoLinkTrio3EventMonitorAction extends Action<typeof IOLINK__TRIO3_EVENT_MONITOR__ACTIONS> {
  payload: typeDeviceInstanceIoLinkTrio3EventMonitorPayload;
}
