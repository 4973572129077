/* eslint-disable no-restricted-properties */

export class TicksCalculator {
  private minValue: number;

  private maxValue: number;

  private maxTicks: number;

  private range: number;

  private tickSpacing: number;

  private niceMinValue: number;

  private niceMaxValue: number;

  constructor(minValue: number, maxValue: number, maxTicks: number) {
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.maxTicks = maxTicks;
    this.range = this.scaleNum(this.maxValue - this.minValue, false);
    this.tickSpacing = this.scaleNum(this.range / (this.maxTicks - 1), true);
    this.niceMinValue = Math.floor(this.minValue / this.tickSpacing) * this.tickSpacing;
    this.niceMaxValue = Math.ceil(this.maxValue / this.tickSpacing) * this.tickSpacing;
  }

  // eslint-disable-next-line class-methods-use-this
  private scaleNum = (range: number, round: boolean): number => {
    const exponent = Math.floor(Math.log10(range));
    const fraction = range / 10 ** exponent;

    let niceFraction = 0;
    if (round) {
      if (fraction < 1.5) {
        niceFraction = 1;
      } else if (fraction < 3) {
        niceFraction = 2;
      } else if (fraction < 7) {
        niceFraction = 5;
      } else {
        niceFraction = 10;
      }
    } else if (fraction <= 1) {
      niceFraction = 1;
    } else if (fraction <= 2) {
      niceFraction = 2;
    } else if (fraction <= 5) {
      niceFraction = 5;
    } else {
      niceFraction = 10;
    }

    return niceFraction * 10 ** exponent;
  };

  public getNiceMinValue = (): number => this.niceMinValue;

  public getNiceMaxValue = (): number => this.niceMaxValue;

  public getTickSpacing = (): number => this.tickSpacing;
}

export default TicksCalculator;
