/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isAbortError } from '@gpt/cxe-dp-integration';
import { Services } from '@gpt/commons';
import { showApplicationMessage } from '../../../store/applicationMessage/actions';
import { MessageType } from '../../../store/applicationMessage/types';
import validateExternalDataset from '../../../helpers/validateExternalDataset';
import { activeDeviceLoadDatasetContent } from '../../../store';
import AppFileSystemService from '../../../services/AppService/AppFileSystemService';
import { FILE_EXTENTION__CXEDP, MIME_TYPE__CXEDP } from '../../../helpers/constants';
import { useContextActiveDeviceInstance } from '../../../store/deviceInstances/hooks/ActiveDeviceHooks';

const parseContent = async (content: string):
Promise<Services.DeviceModelServer.DatasetProviderService.ExternalDataset> => {
  const dataset = JSON
    .parse(content) as Services.DeviceModelServer.DatasetProviderService.ExternalDataset;
  // TODO: Check version
  await validateExternalDataset(dataset);
  return dataset;
};

const useWizardLoadParameterFile = (
  deviceInstanceId: string | undefined,
) => {
  const dispatch = useDispatch();

  const activeDevice = useContextActiveDeviceInstance(deviceInstanceId);

  const showErrorMessage = useCallback((messageType: MessageType, messageId: string) => {
    dispatch(showApplicationMessage(messageType, messageId));
  }, [dispatch]);

  const loadParameterFile = useCallback(async () => {
    const profileName = activeDevice?.device.catalog.profileName;
    const orderNumber = activeDevice?.device.catalog.productOrderNumber;

    let fileContents: string;
    try {
      const importResult = await AppFileSystemService.ImportLocalFile({
        mimeType: MIME_TYPE__CXEDP,
        extensions: [FILE_EXTENTION__CXEDP],
      });
      fileContents = importResult.contents as string;
    } catch (error) {
      if (isAbortError(error)) {
        showErrorMessage('warning', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__CANCELLED');
      } else {
        showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__CANNOT_READ_CONTENT');
      }
      return;
    }

    let dataset: Services.DeviceModelServer.DatasetProviderService.ExternalDataset;
    try {
      dataset = await parseContent(fileContents);
    } catch (error) {
      showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__INVALID_CONTENT');
      return;
    }

    if (profileName === undefined) {
      showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__INCOMPATIBLE');
      return;
    }

    try {
      const datasetProfileName = dataset.header.device.catalog.profileName.toLocaleLowerCase();
      const datasetOrderCode = `${dataset.header.device.catalog.productOrderNumber}`;

      const deviceProfileName = profileName.toLocaleLowerCase();
      const deviceOrderCode = `${orderNumber}`;

      // Check if device profileName
      if (deviceProfileName !== datasetProfileName && deviceOrderCode !== datasetOrderCode) {
        showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__INCOMPATIBLE');
        return;
      }

      if (activeDevice?.deviceInstanceId) {
        dispatch(activeDeviceLoadDatasetContent(activeDevice?.deviceInstanceId, dataset));
        showErrorMessage('success', 'LOAD_PARAMETER_FILE__LOAD_DATA__SUCCESS');
      } else {
        showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__INCOMPATIBLE');
      }
    } catch {
      showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__INCOMPATIBLE');
    }
  }, [activeDevice]);

  return loadParameterFile;
};

export default useWizardLoadParameterFile;
