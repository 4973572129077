/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setContextParameterHelp, setContextParameterWarning } from '../../../../store/contexthelp';

const useDropDownContextHelp = (
  label: string,
  help: string,
  warning?: string,
  showContextMessageDef?: boolean,
  onShowContentHelp?: () => void,
  onHideContentHelp?: () => void,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showContextMessage, setShowContextMessage] = useState<boolean>(false);

  const statusValueLabel = t(label);
  const statusValueHelp = t(help);

  const hideContentHelp = useCallback((): void => {
    setShowContextMessage(false);
    dispatch(setContextParameterWarning(undefined));
    onHideContentHelp?.();
  }, [onHideContentHelp]);

  const showContentHelp = useCallback((): void => {
    setShowContextMessage(true);
    dispatch(setContextParameterHelp({
      title: statusValueLabel,
      text: statusValueHelp,
    }));
    onShowContentHelp?.();
  }, [statusValueLabel, statusValueHelp, onShowContentHelp]);

  const showMessage = showContextMessage && showContextMessageDef;
  useEffect(() => {
    if (showMessage) {
      dispatch(setContextParameterWarning(warning));
    }
  }, [warning, showMessage]);

  return [showContentHelp, hideContentHelp] as const;
};

export default useDropDownContextHelp;
