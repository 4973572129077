/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import DisplayFormat from '../../../helpers/displayFormat';
import ParameterControl from '../ParameterControl';
import useDelayedUserInput from '../../../controls/hooks/useDelayedUserInput';
import './TextEditorControl.css';

export interface TextEditorControlProps {
    label?: string;
    value: string;
    displayFormat: string;
    unit?: string;
    warning?: string;
    readonly?: boolean;
    modified?: boolean;
    stepSize?: number;
    onValueChange: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    noWarning?: boolean;
}

const TextEditorControl:
React.FC<TextEditorControlProps> = (props: TextEditorControlProps):
React.ReactElement => {
  const {
    label, value, stepSize,
    warning, unit, readonly, modified, displayFormat,
    onValueChange, onFocus, onBlur,
    noWarning,
  } = props;
  const classNameEditedText = (modified ?? false) ? 'textfield__input--edited' : '';

  const [focusActive, setActiveFocus] = useState(false);
  const [
    userInputValue,
    currentWarning,
    setUserInputValue,
    onBlurValue,
  ] = useDelayedUserInput(value, warning, onValueChange);

  const currentValue = userInputValue ?? value;
  const visibleValue = focusActive
    ? currentValue : DisplayFormat(displayFormat, currentValue);

  const inputWarningArea = noWarning === true
    ? undefined
    : (
      <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
        <div className="input-field-control__warning-text">{currentWarning}</div>
      </div>
    );

  return (
    <ParameterControl
      label={label}
      unit={unit}
    >
      <div className="text-input-field">
        <input
          type="text"
          title={label}
          value={visibleValue}
          step={stepSize}
          className={`textfield__input ${classNameEditedText} ${currentWarning !== undefined ? 'invalid' : ''}`}
          readOnly={readonly}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setUserInputValue(e.target.value);
          }}
          onFocus={() => {
            setActiveFocus(true);
            onFocus?.();
          }}
          onBlur={() => {
            setActiveFocus(false);
            onBlurValue(currentValue);
            onBlur?.();
          }}
          data-testid="text-editor-control"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="textfield__label">{}</label>
        {inputWarningArea}
      </div>
    </ParameterControl>
  );
};

export default TextEditorControl;
