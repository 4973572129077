import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { ComponentMap } from './types';
import CompareCheckbox from './CompareCheckbox/CompareCheckbox';
import CompareDeviceSpecific from './CompareDeviceSpecific/CompareDeviceSpecific';
import CompareLinTable from './CompareLinTable/CompareLinTable';
import CompareRangeWithSpanControl from './CompareRangeWithSpan/CompareRangeWithSpan';
import CompareStatusValue from './StatusValues/CompareStatusValue';

const compareControlMap: ComponentMap = {
  StatusValue: CompareStatusValue,
  [DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN]: CompareRangeWithSpanControl,
  [DeviceModelStatus.UI.ControlType.CTLLINEARIZATION]: CompareLinTable,
  [DeviceModelStatus.UI.ControlType.CTLCHECKBOX]: CompareCheckbox,
  [DeviceModelStatus.UI.ControlType.CTLDEVICESPECIFIC]: CompareDeviceSpecific,
};

export default compareControlMap;
