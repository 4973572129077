/* eslint-disable max-len */
import React from 'react';
import { Services } from '@gpt/commons';
import { DeviceMethodExecutionStep } from '../../../store/deviceInstances/store/deviceMethod/types';
import {
  CompleteProgressStep, FailedProgressStep, InitProgressStep, RunningProgressStep,
} from './ProgressSteps';

/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
const ProgressStepSelector: React.FC<DeviceMethodExecutionStep> = (props: DeviceMethodExecutionStep)
: React.ReactElement => {
  const { state, title, message } = props;

  if (state === Services.DeviceModel.MethodStepExecutionState.init) {
    return <InitProgressStep title={title} />;
  }
  if (state === Services.DeviceModel.MethodStepExecutionState.running) {
    return <RunningProgressStep title={title} />;
  }
  if (state === Services.DeviceModel.MethodStepExecutionState.done) {
    return <CompleteProgressStep title={title} />;
  }
  return <FailedProgressStep state={state} title={title} message={message} />;
};

export default ProgressStepSelector;
