/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import { registerComponent } from '../controls/ComponentFactory/ComponentFactory';
import CockpitView from './CockpitView/CockpitView';
import { CompareParameterView } from './CompareView';
import OnlineParameterView from './OnlineParameterView/OnlineParameterView';
import ParameterView from './ParameterView/ParameterView';

const registerDeviceViews = (): void => {
  registerComponent(DeviceModelStatus.Apps.AppType.COCKPIT, CockpitView);
  registerComponent(DeviceModelStatus.Apps.AppType.EDITDEVICE, ParameterView);
  registerComponent(DeviceModelStatus.Apps.AppType.COMPAREDEVICE, CompareParameterView);
  registerComponent(DeviceModelStatus.Apps.AppType.ONLINEPARAM, OnlineParameterView);
};

export default registerDeviceViews;
