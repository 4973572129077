/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { LoadingControl } from '../../components/LoadingControl';
import { UnsupportedControl } from '../../components/UnsupportedControl';
import { InputRangeSliderControl } from '../../components/ParameterEditor';
import { setContextParameterHelp } from '../../store/contexthelp';
import { writeActiveDeviceVariableValues } from '../../store';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../hooks/useContextDataset';

export interface InputSliderRangeTitle {
  label: DeviceModelStatus.LocString;
  description?: DeviceModelStatus.LocString;
  help?: DeviceModelStatus.LocString;
}

export interface InputSliderRangeReduxProps {
  title?: InputSliderRangeTitle;
  minRangeValue: number;
  maxRangeValue: number;
  startRangeIdentRef: IdentRef;
  endRangeIdentRef: IdentRef;
  deviceInstanceId: string;
}

const InputSliderRangeDescriptorControl: React.FC<InputSliderRangeReduxProps> = (props: InputSliderRangeReduxProps)
  : React.ReactElement => {
  const {
    minRangeValue, maxRangeValue, startRangeIdentRef, endRangeIdentRef, title,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const minRangeVarDescriptor = useContextDatasetDescriptor(deviceInstanceId, startRangeIdentRef);
  const minRangeVarValue = useContextDatasetStatusValue(deviceInstanceId, startRangeIdentRef);

  const maxRangeVarDescriptor = useContextDatasetDescriptor(deviceInstanceId, endRangeIdentRef);
  const maxRangeVarValue = useContextDatasetStatusValue(deviceInstanceId, endRangeIdentRef);

  if (minRangeVarDescriptor === undefined || maxRangeVarDescriptor === undefined) {
    return <UnsupportedControl text="InputSliderRangeReduxControl: unsupported control description" />;
  }

  if (minRangeVarDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor
    || maxRangeVarDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="InputSliderRangeReduxControl: only support StatusDescriptor type" />;
  }

  if (minRangeVarValue === undefined || maxRangeVarValue === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  if ((minRangeVarDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER)
    && (minRangeVarDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER)
    && (minRangeVarDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT)
  ) {
    return (
      <UnsupportedControl text={
        `InputSliderRangeReduxControl: Unsupported value type ${minRangeVarDescriptor.valueType.type};`
        + ' supported value types are: INTEGER, UNSIGNED_INTEGER, FLOAT'
    } />
    );
  }

  if ((maxRangeVarDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER)
    && (maxRangeVarDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER)
    && (maxRangeVarDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT)
  ) {
    return (
      <UnsupportedControl text={
        `InputSliderRangeReduxControl: Unsupported value type ${maxRangeVarDescriptor.valueType.type};`
        + ' supported value types are: INTEGER, UNSIGNED_INTEGER, FLOAT'
    } />
    );
  }

  const minValueStep = minRangeVarDescriptor.valueType.stepSize ?? 0.1;
  const maxValueStep = maxRangeVarDescriptor.valueType.stepSize ?? 0.1;

  return (
    <InputRangeSliderControl
      label={title === undefined ? '' : t<string>(title.label)}
      unit={minRangeVarDescriptor.unit === undefined ? undefined : t<string>(minRangeVarDescriptor.unit)}
      min={minRangeValue}
      max={maxRangeValue}
      step={Math.min(minValueStep, maxValueStep)}
      loValue={{
        value: Number.isNaN(minRangeVarValue.value) ? 0 : minRangeVarValue.value,
        onValueBlur: () => {
          dispatch(setContextParameterHelp({
            title: '',
            text: '',
          }));
        },
        onValueFocus: () => {
          dispatch(setContextParameterHelp({
            title: minRangeVarDescriptor.label === undefined ? '' : t<string>(minRangeVarDescriptor.label),
            text: minRangeVarDescriptor.help === undefined ? '' : t<string>(minRangeVarDescriptor.help),
          }));
        },
        onValueChange: (value) => {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            value,
            backupValue: minRangeVarValue.backupValue,
            errorCode: 0,
            identRef: minRangeVarDescriptor.identRef,
          }]));
        },
        modified: minRangeVarValue.modified,
      }}
      hiValue={{
        value: Number.isNaN(maxRangeVarValue.value) ? 0 : maxRangeVarValue.value,
        onValueBlur: () => {
          dispatch(setContextParameterHelp({
            title: '',
            text: '',
          }));
        },
        onValueFocus: () => {
          dispatch(setContextParameterHelp({
            title: maxRangeVarDescriptor.label === undefined ? '' : t<string>(maxRangeVarDescriptor.label),
            text: maxRangeVarDescriptor.help === undefined ? '' : t<string>(maxRangeVarDescriptor.help),
          }));
        },
        onValueChange: (value) => {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            value,
            backupValue: maxRangeVarValue.backupValue,
            errorCode: 0,
            identRef: maxRangeVarDescriptor.identRef,
          }]));
        },
        modified: maxRangeVarValue.modified,
      }}
    />
  );
};

export default InputSliderRangeDescriptorControl;
