/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import axios from 'axios';
import { useEffect, useState } from 'react';
import { checkAppVersion, ExternalDownloadUrl } from '../AppVersion/AppVersion';

const useAppDownloadLink = (updateChannel: string | undefined): string | undefined => {
  const [availableSetup, setAvailableSetup] = useState<undefined | string>(undefined);

  let channel = 'latest';
  if (updateChannel !== undefined) {
    if (updateChannel.trim() !== 'stable' && updateChannel.trim() !== 'release') {
      channel = updateChannel.trim();
    }
  }

  useEffect(() => {
    let timeoutHandle;
    const getAppDownloadSetup = async () => {
      let retry = false;
      try {
        const channelDesc = await checkAppVersion(channel);
        setAvailableSetup(`${ExternalDownloadUrl}/${channelDesc.files[0].url}`);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (axios.isAxiosError(err) && err?.response?.status === 404) {
          retry = false;
        } else {
          retry = true;
        }
      }
      if (retry) {
        timeoutHandle = setTimeout(async () => getAppDownloadSetup(), 5000);
      }
    };
    getAppDownloadSetup();
    return () => {
      if (timeoutHandle !== undefined) {
        clearTimeout(timeoutHandle);
      }
    };
  }, []);
  return availableSetup;
};

export default useAppDownloadLink;
