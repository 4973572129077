/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import InvisibleReduxControl from '../../../controls/InvisibleReduxControl/InvisibleReduxControl';
import VariableDateEditorControl from './VariableDateEditorControl/VariableDateEditorControl';
import VariableEnumeratorEditorControl from './VariableEnumeratorEditorControl/VariableEnumeratorEditorControl';
import VariableFloatEditorControl from './VariableFloatEditorControl/VariableFloatEditorControl';
import { VariableIntegerEditorControl } from './VariableIntegerEditorControl/VariableIntegerEditorControl';
import VariableIPV4AddressEditorControl from './VariableIPV4AddressEditorControl/VariableIPV4AddressEditorControl';
import VariableMACAddressEditorControl from './VariableMacAddressEditorControl/VariableMacAddressEditorControl';
import VariableStringEditorControl from './VariableStringEditorControl/VariableStringEditorControl';
import VariableUIntegerEditorControl from './VariableUIntegerEditorControl/VariableUIntegerEditorControl';
import VariableBlobEditorControl from './VariableBlobEditorControl/VariableBlobEditorControl';
import './VariableEditorControl.css';

export interface VariableEditorControlProps {
  statusDescriptor: DeviceModelStatus.StatusDescriptor
  statusValue?: DeviceModelStatus.StatusValue;
  readonly?: boolean;
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
  deviceInstanceId: string;
}

const VariableEditorControl:
React.FC<VariableEditorControlProps> = (props: VariableEditorControlProps):
React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp, readonly,
    deviceInstanceId,
  } = props;

  const { visibility, identRef } = statusDescriptor;
  if (visibility === false) {
    return <InvisibleReduxControl deviceInstanceId={deviceInstanceId} identRef={identRef} onStateChanged={() => 0} />;
  }

  let variableEditorControl;
  switch (statusDescriptor.valueType.type) {
    case DeviceModelStatus.StatusDescriptorValueType.FLOAT:
      variableEditorControl = (
        <VariableFloatEditorControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
        />
      );
      break;
    case DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR:
      variableEditorControl = (
        <VariableEnumeratorEditorControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
        />
      );
      break;
    case DeviceModelStatus.StatusDescriptorValueType.INTEGER:
      variableEditorControl = (
        <VariableIntegerEditorControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
        />
      );
      break;
    case DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER:
      variableEditorControl = (
        <VariableUIntegerEditorControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
        />
      );
      break;
    case DeviceModelStatus.StatusDescriptorValueType.STRING:
      variableEditorControl = (
        <VariableStringEditorControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
        />
      );
      break;
    case DeviceModelStatus.StatusDescriptorValueType.DATETIME:
    case DeviceModelStatus.StatusDescriptorValueType.DATE:
      variableEditorControl = (
        <VariableDateEditorControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
        />
      );
      break;
    case DeviceModelStatus.StatusDescriptorValueType.IPV4_ADDRESS:
      variableEditorControl = (
        <VariableIPV4AddressEditorControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
        />
      );
      break;
    case DeviceModelStatus.StatusDescriptorValueType.MAC_ADDRESS:
      variableEditorControl = (
        <VariableMACAddressEditorControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
        />
      );
      break;
    case DeviceModelStatus.StatusDescriptorValueType.BLOB:
      variableEditorControl = (
        <VariableBlobEditorControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
        />
      );
      break;
    default:
      variableEditorControl = <p>Not supported type</p>;
  }

  return (
    <>
      {variableEditorControl}
    </>
  );
};

export default VariableEditorControl;
