/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { CompareModeType } from '../../views/CompareView/common';
import {
  COMPARE_VIEW__SET_COMPARE_METHOD,
  CompareViewState,
  typeCompareViewActionTypes,
  COMPARE_VIEW__SET_COMPARE_DATASET_IDENT,
} from './types';

const initialState: CompareViewState = {
  compareMethod: CompareModeType.ShowAll,
};

const compareViewReducer = (
  state = initialState,
  action: typeCompareViewActionTypes,
): CompareViewState => {
  switch (action.type) {
    case COMPARE_VIEW__SET_COMPARE_METHOD:
      return {
        ...state,
        compareMethod: action.payload,
      };
    case COMPARE_VIEW__SET_COMPARE_DATASET_IDENT:
      return {
        ...state,
        compareDatasetIdent: action.payload,
      };
    default:
      return state;
  }
};

export default compareViewReducer;
