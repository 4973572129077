/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect, useRef, useState } from 'react';
import Image from 'react-bootstrap/Image';
import arrowIcon from '../../../assets/icons/icon-arrow-indicator-left.svg';
import expandIcon from '../../../assets/icons/icon-expand.svg';
import DisplayFormat from '../../../helpers/displayFormat';
import { DragIndicatorControl } from '../../DragIndicatorControl/DragIndicatorControl';
import OverlayPortal from '../../OverlayPortal/OverlayPortal';
import './FigmaGaugeControl.css';

export interface FigmaGaugeControlProps {
  gaugeTitle: string;
  subtitle: string;
  centerUnit: string;
  currentValue: number;
  segmentStops: number[];
  showDragIndicator: boolean;
  displayFormat: string;
  // eslint-disable-next-line no-unused-vars
  setShowDragIndicator: (value: boolean) => void;
}

interface GaugeRange {
  start: number;
  end: number;
}

const mapToRange = (value: number, inputMin: number, inputMax: number, outputMin: number, outputMax: number): number => {
  if ((inputMin - inputMax) === 0) {
    return outputMin;
  }
  const slope = (outputMax - outputMin) / (inputMax - inputMin);
  return outputMin + slope * (value - inputMin);
};

const mapToTurnsRange = (value: number, inputMin: number, inputMax: number): number => mapToRange(value, inputMin, inputMax, 0, 0.5);

const fitToSize = (baseFontSize: number, segmentContainerWidth: number): number => (baseFontSize / 232) * segmentContainerWidth;

const scaleToRange = (value: number, range: GaugeRange): number => {
  if (value < range.start) {
    return range.start;
  }

  if (value > range.end) {
    return range.end;
  }

  return value;
};

const calcOffset = (value: number, segmentStops: number[]): number => (value === segmentStops[5] ? 6 : 10);

export const FigmaGaugeControl: React.FC<FigmaGaugeControlProps> = (props: FigmaGaugeControlProps): React.ReactElement => {
  const [showDragIndicatorMenu, setShowDragIndicatorMenu] = useState(false);
  const [dragIndicatorValue, setDragIndicatorValue] = useState({ minValue: Number.POSITIVE_INFINITY, maxValue: Number.NEGATIVE_INFINITY });
  const [segmentContainerWidth, setSegmentContainerWidth] = useState(0);
  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const showIndicatorButtonRef = useRef<HTMLDivElement>(null);
  const {
    gaugeTitle, subtitle, centerUnit, currentValue, segmentStops, showDragIndicator, displayFormat, setShowDragIndicator,
  } = props;
  const gaugeRange: GaugeRange = {
    start: segmentStops[0],
    end: segmentStops[segmentStops.length - 1],
  };

  const segments: number[] = segmentStops.map((seg) => mapToTurnsRange(seg, gaugeRange.start, gaugeRange.end));

  const lowLowRotation = segments[1] ?? 0;
  const lowRotation = segments[2] ?? 0;
  const normalRotation = segments[3] ?? 0;
  const highRotation = segments[4] ?? 0;
  const highhighRotation = segments[5] ?? 0;

  const label1HideState = lowLowRotation === 0 ? 'hideBargraphSegment' : '';
  const label2HideState = lowRotation === 0 ? 'hideBargraphSegment' : '';
  const label3HideState = normalRotation === 0 ? 'hideBargraphSegment' : '';
  const label4HideState = highRotation === 0 ? 'hideBargraphSegment' : '';
  const label5HideState = highhighRotation === 0 ? 'hideBargraphSegment' : '';

  const lowlowSegmentStyles = { transform: `rotate(${lowLowRotation}turn)` };
  const lowSegmentStyles = { transform: `rotate(${lowRotation}turn)` };
  const normalSegmentStyles = { transform: `rotate(${normalRotation}turn)` };
  const highSegmentStyles = { transform: `rotate(${highRotation}turn)` };
  const highhighSegmentStyles = { transform: `rotate(${highhighRotation}turn)` };

  const lowlowSegmentClasses = 'FigmaGaugeControlChart-Gauge__segmentsContainer-segmentFill FigmaGaugeControlChart-Gauge-Segment-LowLow';
  const lowSegmentClasses = 'FigmaGaugeControlChart-Gauge__segmentsContainer-segmentFill FigmaGaugeControlChart-Gauge-Segment-Low';
  const normalSegmentClasses = 'FigmaGaugeControlChart-Gauge__segmentsContainer-segmentFill FigmaGaugeControlChart-Gauge-Segment-Normal';
  const highSegmentClasses = 'FigmaGaugeControlChart-Gauge__segmentsContainer-segmentFill FigmaGaugeControlChart-Gauge-Segment-High';
  const highhighSegmentClasses = 'FigmaGaugeControlChart-Gauge__segmentsContainer-segmentFill FigmaGaugeControlChart-Gauge-Segment-HighHigh';
  const whiteSegmentClasses = 'FigmaGaugeControlChart-Gauge__segmentsContainer-segmentFill FigmaGaugeControlChart-Gauge-Segment-WhiteCover';

  const needleRotation = mapToTurnsRange(scaleToRange(currentValue, gaugeRange), gaugeRange.start, gaugeRange.end); // ((scaleToRange(CurrentValue, gaugeRange) - SegmentStops[0]) / SegmentStops[SegmentStops.length - 1]) * 0.5;
  const minIndicatorRotation = mapToTurnsRange(scaleToRange(dragIndicatorValue.minValue, gaugeRange), gaugeRange.start, gaugeRange.end); // ((scaleToRange(dragIndicatorValue.minValue, gaugeRange) - SegmentStops[0]) / SegmentStops[SegmentStops.length - 1]) * 0.5;
  const maxIndicatorRotation = mapToTurnsRange(scaleToRange(dragIndicatorValue.maxValue, gaugeRange), gaugeRange.start, gaugeRange.end); // ((scaleToRange(dragIndicatorValue.maxValue, gaugeRange) - SegmentStops[0]) / SegmentStops[SegmentStops.length - 1]) * 0.5;

  const label1Classes = `FigmaGaugeControlChart-Gauge__labelContainer ${label1HideState}`;
  const label2Classes = `FigmaGaugeControlChart-Gauge__labelContainer ${label2HideState}`;
  const label3Classes = `FigmaGaugeControlChart-Gauge__labelContainer ${label3HideState}`;
  const label4Classes = `FigmaGaugeControlChart-Gauge__labelContainer ${label4HideState}`;
  const label5Classes = `FigmaGaugeControlChart-Gauge__labelContainer ${label5HideState}`;

  const NeedleStyles = {
    transform: `rotate(${needleRotation}turn)`,
  };
  const MinIndicatorStyles = {
    transform: `rotate(${minIndicatorRotation}turn)`,
  };
  const MaxIndicatorStyles = {
    transform: `rotate(${maxIndicatorRotation}turn)`,
  };
  const label0Style = {
    transform: 'rotateY(180deg)',
  };
  const label1Style = {
    transform: `rotateY(180deg) rotate(-${lowLowRotation}turn)`,
  };
  const label2Style = {
    transform: `rotateY(180deg) rotate(-${lowRotation}turn)`,
  };
  const label3Style = {
    transform: `rotateY(180deg) rotate(-${normalRotation}turn)`,
  };
  const label4Style = {
    transform: `rotateY(180deg) rotate(-${highRotation}turn)`,
  };
  const label5Style = {
    transform: `rotateY(180deg) rotate(-${highhighRotation}turn)`,
  };
  const label0TextStyle = {
    transform: `rotateY(180deg) translate(-${fitToSize(4, segmentContainerWidth)}px, -${fitToSize(10, segmentContainerWidth)}px)`,
    fontSize: `${fitToSize(12, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(16, segmentContainerWidth)}px`,
  };
  const label1TextStyle = {
    transform: `rotateY(180deg) translate(-${fitToSize(4, segmentContainerWidth)}px, -${fitToSize(calcOffset(segmentStops[1], segmentStops), segmentContainerWidth)}px) rotate(-${lowLowRotation}turn)`,
    fontSize: `${fitToSize(12, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(16, segmentContainerWidth)}px`,
  };
  const label2TextStyle = {
    transform: `rotateY(180deg) translate(-${fitToSize(4, segmentContainerWidth)}px, -${fitToSize(calcOffset(segmentStops[2], segmentStops), segmentContainerWidth)}px) rotate(-${lowRotation}turn)`,
    fontSize: `${fitToSize(12, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(16, segmentContainerWidth)}px`,
  };
  const label3TextStyle = {
    transform: `rotateY(180deg) translate(-${fitToSize(4, segmentContainerWidth)}px, -${fitToSize(calcOffset(segmentStops[3], segmentStops), segmentContainerWidth)}px) rotate(-${normalRotation}turn)`,
    fontSize: `${fitToSize(12, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(16, segmentContainerWidth)}px`,
  };
  const label4TextStyle = {
    transform: `rotateY(180deg) translate(-${fitToSize(4, segmentContainerWidth)}px, -${fitToSize(calcOffset(segmentStops[4], segmentStops), segmentContainerWidth)}px) rotate(-${highRotation}turn)`,
    fontSize: `${fitToSize(12, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(16, segmentContainerWidth)}px`,
  };
  const label5TextStyle = {
    transform: `rotateY(180deg) translate(-${fitToSize(4, segmentContainerWidth)}px, -${fitToSize(6, segmentContainerWidth)}px) rotate(-${highhighRotation}turn)`,
    fontSize: `${fitToSize(12, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(16, segmentContainerWidth)}px`,
  };
  const titleStyle = {
    fontSize: `${fitToSize(24, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(32, segmentContainerWidth)}px`,
  };
  const subtitleStyle = {
    fontSize: `${fitToSize(12, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(16, segmentContainerWidth)}px`,
  };
  const buttonStyle = {
    width: `${fitToSize(32, segmentContainerWidth)}px`,
    height: `${fitToSize(32, segmentContainerWidth)}px`,
  };
  const currentValueStyle = {
    fontSize: `${fitToSize(32, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(24, segmentContainerWidth)}px`,
  };
  const currentUnitStyle = {
    fontSize: `${fitToSize(16, segmentContainerWidth)}px`,
    marginLeft: `${fitToSize(8, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(24, segmentContainerWidth)}px`,
  };
  const extremeValueStyle = {
    fontSize: `${fitToSize(16, segmentContainerWidth)}px`,
    lineHeight: `${fitToSize(24, segmentContainerWidth)}px`,
  };
  const extremeContainerStyle = {
    paddingTop: `${fitToSize(4, segmentContainerWidth)}px`,
    transform: `translateY(-${fitToSize(20, segmentContainerWidth)}px)`,
  };
  const currentValueContainerStyle = {
    transform: `translateY(-${fitToSize(20, segmentContainerWidth)}px)`,
  };
  const titleContainerStyle = {
    marginBottom: `${fitToSize(32, segmentContainerWidth)}px`,
  };
  const arrowStyle = {
    width: fitToSize(16, segmentContainerWidth),
    height: fitToSize(24, segmentContainerWidth),
    transform: `translate(-${fitToSize(10, segmentContainerWidth)}px, -${fitToSize(12, segmentContainerWidth)}px)`,
  };
  const lineIndicatorStyle = {
    height: `${fitToSize(4, segmentContainerWidth)}px`,
    borderRadius: `${fitToSize(3, segmentContainerWidth)}px`,
    transform: `translate(-${fitToSize(1, segmentContainerWidth)}px, -${fitToSize(2, segmentContainerWidth)}px)`,
  };

  const currentValueText = DisplayFormat(displayFormat, currentValue);
  const minValue = Number.isFinite(dragIndicatorValue.minValue) ? DisplayFormat(displayFormat, dragIndicatorValue.minValue) : 0;
  const maxValue = Number.isFinite(dragIndicatorValue.maxValue) ? DisplayFormat(displayFormat, dragIndicatorValue.maxValue) : 0;
  const minValueText = `Min: ${minValue} ${centerUnit}`;
  const maxValueText = `Max: ${maxValue} ${centerUnit}`;

  const dragIndicatorBtnTopPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.top;
  const dragIndicatorBtnBottomPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.bottom;
  const dragIndicatorBtnLeftPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.left;
  const dragIndicatorBtnRightPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.right;

  const dragIndicatorMenuXPosition = dragIndicatorBtnLeftPosition === undefined || dragIndicatorBtnRightPosition === undefined
    ? undefined : (dragIndicatorBtnRightPosition + dragIndicatorBtnLeftPosition) / 2;
  const dragIndicatorMenuYPosition = dragIndicatorBtnTopPosition === undefined || dragIndicatorBtnBottomPosition === undefined
    ? undefined : (dragIndicatorBtnTopPosition + dragIndicatorBtnBottomPosition) / 2;

  useEffect(() => {
    if (currentValue < dragIndicatorValue.minValue || currentValue > dragIndicatorValue.maxValue) {
      setDragIndicatorValue((previousState) => ({
        minValue: currentValue < dragIndicatorValue.minValue ? currentValue : previousState.minValue,
        maxValue: currentValue > dragIndicatorValue.maxValue ? currentValue : previousState.maxValue,
      }));
    }
  }, [currentValue, dragIndicatorValue]);

  useEffect(() => {
    const element = segmentContainerRef.current;
    const observer = new ResizeObserver(() => {
      if (element) {
        const currentWidth = element.clientWidth;
        setSegmentContainerWidth(currentWidth);
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [segmentContainerRef]);

  return (
    <div className="FigmaGaugeControlChart-Gauge" data-testid="figma-gauge-control">
      <div style={titleContainerStyle} className="FigmaGaugeControlChart-Gauge__titleContainer">
        <div>
          <div style={titleStyle} className="FigmaGaugeControlChart-Gauge__titleContainer-title">{gaugeTitle}</div>
          <div style={subtitleStyle} className="FigmaGaugeControlChart-Gauge__Gauge__titleContainer-subtitle">{subtitle}</div>
        </div>
        <div
          ref={showIndicatorButtonRef}
          className="FigmaGaugeControlChart-Gauge__Gauge__titleContainer-image"
          role="button"
          tabIndex={0}
          onClick={() => {
            setShowDragIndicatorMenu(!showDragIndicatorMenu);
          }}
          onKeyUp={() => {}}
        >
          <Image style={buttonStyle} src={expandIcon} className="DragIndicator-button-icon" />
        </div>
        {showDragIndicatorMenu && (
          <OverlayPortal>
            <div
              role="none"
              className="FigmaGaugeControlChart-Gauge__titleContainer-overlay"
              onClick={() => setShowDragIndicatorMenu(false)}
            >
              <div
                style={{
                  position: 'absolute',
                  top: dragIndicatorMenuYPosition,
                  left: dragIndicatorMenuXPosition,
                }}
              >
                <DragIndicatorControl
                  showIndicator={showDragIndicator}
                  onDragIndicatorChange={() => {
                    setShowDragIndicator(!showDragIndicator);
                  }}
                  onRefresh={() => {
                    setDragIndicatorValue({
                      minValue: currentValue,
                      maxValue: currentValue,
                    });
                  }}
                />
              </div>
            </div>
          </OverlayPortal>
        )}
      </div>
      <div ref={segmentContainerRef} className="FigmaGaugeControlChart-Gauge__segmentsContainer">
        <div className="FigmaGaugeControlChart-Gauge__segmentsContainer-segment">
          <div style={highhighSegmentStyles} className={highhighSegmentClasses} />
          <div style={highSegmentStyles} className={whiteSegmentClasses} />
          <div style={highSegmentStyles} className={highSegmentClasses} />
          <div style={normalSegmentStyles} className={whiteSegmentClasses} />
          <div style={normalSegmentStyles} className={normalSegmentClasses} />
          <div style={lowSegmentStyles} className={whiteSegmentClasses} />
          <div style={lowSegmentStyles} className={lowSegmentClasses} />
          <div style={lowlowSegmentStyles} className={whiteSegmentClasses} />
          <div style={lowlowSegmentStyles} className={lowlowSegmentClasses} />
          <div className="FigmaGaugeControlChart-Gauge__segmentsContainer-segmentCover" />
        </div>
        <div className="FigmaGaugeControlChart-Gauge__needleContainer">
          <div style={NeedleStyles} className="FigmaGaugeControlChart-Gauge__arrowContainer">
            <Image style={arrowStyle} src={arrowIcon} className="FigmaGaugeControlChart-Gauge__arrowContainer-arrow" />
          </div>
        </div>
        <div className="FigmaGaugeControlChart-Gauge__indicatorContainer">
          {showDragIndicator && (
            <div>
              <div style={MinIndicatorStyles} className="FigmaGaugeControlChart-Gauge__extremeIndicatorContainer">
                <div style={lineIndicatorStyle} className="FigmaGaugeControlChart-Gauge__extremeIndicatorContainer-lineIndicator" />
              </div>
              <div style={MaxIndicatorStyles} className="FigmaGaugeControlChart-Gauge__extremeIndicatorContainer">
                <div style={lineIndicatorStyle} className="FigmaGaugeControlChart-Gauge__extremeIndicatorContainer-lineIndicator" />
              </div>
            </div>
          )}
          <div style={label0Style} className="FigmaGaugeControlChart-Gauge__labelContainer">
            <div style={label0TextStyle} className="FigmaGaugeControlChart-Gauge__labelContainer-label">{DisplayFormat(displayFormat, segmentStops[0])}</div>
          </div>
          <div style={label1Style} className={label1Classes}>
            <div style={label1TextStyle} className="FigmaGaugeControlChart-Gauge__labelContainer-label">{DisplayFormat(displayFormat, segmentStops[1])}</div>
          </div>
          <div style={label2Style} className={label2Classes}>
            <div style={label2TextStyle} className="FigmaGaugeControlChart-Gauge__labelContainer-label">{DisplayFormat(displayFormat, segmentStops[2])}</div>
          </div>
          <div style={label3Style} className={label3Classes}>
            <div style={label3TextStyle} className="FigmaGaugeControlChart-Gauge__labelContainer-label">{DisplayFormat(displayFormat, segmentStops[3])}</div>
          </div>
          <div style={label4Style} className={label4Classes}>
            <div style={label4TextStyle} className="FigmaGaugeControlChart-Gauge__labelContainer-label">{DisplayFormat(displayFormat, segmentStops[4])}</div>
          </div>
          <div style={label5Style} className={label5Classes}>
            <div style={label5TextStyle} className="FigmaGaugeControlChart-Gauge__labelContainer-label">{DisplayFormat(displayFormat, segmentStops[5])}</div>
          </div>
        </div>
        <div style={currentValueContainerStyle} className="FigmaGaugeControlChart-Gauge__currentValueContainer">
          <div style={currentValueStyle} className="FigmaGaugeControlChart-Gauge__currentValueContainer-valueText">{currentValueText}</div>
          <div style={currentUnitStyle} className="FigmaGaugeControlChart-Gauge__currentValueContainer-unitText">{centerUnit}</div>
        </div>
        <div className="FigmaGaugeControlChart-Gauge__container-ExtremesContainer">
          {showDragIndicator && (
          <div style={extremeContainerStyle} className="FigmaGaugeControlChart-Gauge__ExtremesContainer">
            <div style={extremeValueStyle} className="FigmaGaugeControlChart-Gauge__currentValueContainer-extremeValueText">{minValueText}</div>
            <div style={extremeValueStyle} className="FigmaGaugeControlChart-Gauge__currentValueContainer-extremeValueText">{maxValueText}</div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};
