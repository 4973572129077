/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTypedSelector } from '../../store';
import { LoadingProgressSpinner, LoadingProgressSpinnerSize } from './LoadingProgressSpinner/LoadingProgressSpinner';

export interface LoadingProgressControlProps {
  title: string;
  size?: LoadingProgressSpinnerSize;
  deviceInstanceId: string;
  actionId: string;
}

export const LoadingProgressControl
: React.FC<LoadingProgressControlProps> = (props: LoadingProgressControlProps):
  React.ReactElement => {
  const {
    title, size, deviceInstanceId, actionId,
  } = props;
  // eslint-disable-next-line max-len
  const progress = useTypedSelector((state) => {
    const deviceInstance = state.deviceInstances.instances[deviceInstanceId];
    return deviceInstance?.deviceActionState.actions[actionId]?.progress;
  });

  return (
    <div className="d-flex justify-content-center align-items-center w-100" data-testid="loading-progress-control">
      <LoadingProgressSpinner size={size ?? 'xxl'} title={title} progress={progress} />
    </div>
  );
};
