/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext, useEffect } from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { ReduxControlProps } from '../../ReduxControlProps';
import UnsupportedControl from '../../../components/UnsupportedControl/UnsupportedControl';
import withControlVisibility from '../../hoc/withControlVisibility';
import { DatasetState, DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { useTypedSelector } from '../../../store/reduxStore';
import { DatasetContext } from '../../../views/DatasetContext';
import CheckboxEnumeratorControl from '../CheckboxEnumeratorControl/CheckboxEnumeratorControl';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';

const selectControlType = (identRef: IdentRef, dataset?: DatasetState)
: DeviceModelStatus.UI.CheckboxControlType | undefined => {
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLCHECKBOX) {
    return undefined;
  }
  return controlType;
};

const controlTypeSelector = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: IdentRef)
: DeviceModelStatus.UI.CheckboxControlType | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  return selectControlType(identRef, dataset);
};

const selectVariableDescriptor = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: IdentRef)
: DeviceModelStatus.StatusDescriptor | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (dataset === undefined) {
    return undefined;
  }

  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return undefined;
  }

  const varDescriptor = dataset.descriptors[controlType.variable];
  if (varDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }
  return varDescriptor;
};

const selectVariableValue = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: IdentRef)
  : DeviceModelStatus.StatusValue | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (dataset === undefined) {
    return undefined;
  }

  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return undefined;
  }

  return dataset.values[controlType.variable];
};

export interface CheckboxReduxEditorProps extends ReduxControlProps {
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
  // eslint-disable-next-line no-unused-vars
  onCustomValueChange?: (value: boolean) => void;
}

const CheckboxReduxControl: React.FC<CheckboxReduxEditorProps> = (props: CheckboxReduxEditorProps):
  React.ReactElement => {
  const {
    identRef, onShowContentHelp, onHideContentHelp, readonly, onStateChanged,
    onCustomValueChange,
    deviceInstanceId,
  } = props;

  const { targetDataset } = useContext(DatasetContext);

  const controlType = useTypedSelector((state) => controlTypeSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const variableDescriptor = useTypedSelector((state) => selectVariableDescriptor(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const variableValue = useTypedSelector((state) => selectVariableValue(state.deviceInstances, deviceInstanceId, targetDataset, identRef));

  const datasetValueModified = variableValue?.modified ?? false;
  const datasetValueValidity = variableValue?.valueValidity ?? DeviceModelStatus.StatusValueValidity.valid;
  const datasetDescValidity = variableDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor
    ? variableDescriptor.validity : false;

  // When control removed, the modified and error state is clean
  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    const error = datasetDescValidity ? datasetValueValidity !== DeviceModelStatus.StatusValueValidity.valid : false;
    const modified = datasetDescValidity ? datasetValueModified : false;
    onStateChanged(identRef, {
      error,
      modified,
    });
  }, [identRef, datasetValueModified, datasetValueValidity, datasetDescValidity]);

  if (controlType === undefined) {
    return <UnsupportedControl text={`CheckboxReduxControl: Undefined object ${identRef}`} />;
  }

  if (variableDescriptor?.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return <UnsupportedControl text={`CheckboxReduxControl: Unsupported valueType ${variableDescriptor?.valueType.type}`} />;
  }

  if (variableValue === undefined) {
    return <UnsupportedControl text={`CheckboxReduxControl: Undefined variable object ${identRef}`} />;
  }

  const visibility = variableDescriptor.visibility ?? true;
  if (!visibility) {
    return <></>;
  }

  const { label, help } = variableDescriptor;

  return (
    <CheckboxEnumeratorControl
      enumeratorDescriptor={variableDescriptor.valueType}
      statusValue={variableValue.value}
      backupValue={variableValue.backupValue}
      identRef={variableDescriptor.identRef}
      help={help}
      label={label}
      readonly={readonly}
      modified={variableValue.modified}
      handling={variableDescriptor.handling}
      onShowContentHelp={onShowContentHelp}
      onHideContentHelp={onHideContentHelp}
      deviceInstanceId={deviceInstanceId}
      onCustomValueChange={onCustomValueChange}
    />
  );
};

export default withControlVisibility(CheckboxReduxControl);
