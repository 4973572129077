/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  typeDeviceInstanceIoLinkSystemCommandAction,
  IoLinkSetCommandStatePayload,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const ioLinkSetSystemCommandState = (
  targetInstance: string,
  payload: IoLinkSetCommandStatePayload,
): typeDeviceInstanceIoLinkSystemCommandAction => ({
  type: 'IOLINK__SYSTEM_COMMAND__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'IOLINK__SYSTEM_COMMAND__SET_STATE',
      payload,
    },
  },
});
