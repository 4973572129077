import { DropDownOptions } from '../../Quint4DropDown/Quint4DropDownControl';

/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
export const DROPDOWN__ITEMID0__NOT_SELECTED = 0;
export const DROPDOWN__ITEMID1__POWER_OUT = 1;
export const DROPDOWN__ITEMID2__VOLTAGE_OUT = 2;
export const DROPDOWN__ITEMID3__CURRENT_OUT = 3;
export const DROPDOWN__ITEMID4__TEMPERATURE = 4;
export const DROPDOWN__ITEMID5__VIN_OK = 5;
export const DROPDOWN__ITEMID6__RUNTIME = 6;
export const DROPDOWN__ITEMID7__OVPMAIN = 7;

export const updateDropDownOptions = (
  options: DropDownOptions,
  opt: number,
  label: string,
  exclude?: number,
): DropDownOptions => {
  if (opt === exclude) {
    return options;
  }
  return {
    ...options,
    [opt]: label,
  };
};
