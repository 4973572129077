/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';

export type CTAButtonVariant = 'primary' | 'primary-small' | 'secondary' | 'secondary-small' | 'tertiary';

export interface CTAButtonProps {
  block?: boolean;
  disabled?: boolean;
  focus?: boolean;
  customClassName?: string;
  caption: string;
  variant: CTAButtonVariant;
  onClick: () => void;
}

export const CTAButton: React.FC<CTAButtonProps> = (props: CTAButtonProps):
  React.ReactElement => {
  const {
    block, caption, onClick, disabled, focus, variant, customClassName,
  } = props;

  const btnRef = useRef<HTMLButtonElement>(null);
  return (
    <Button
      className={customClassName}
      block={block}
      disabled={disabled}
      ref={btnRef}
      variant={variant}
      autoFocus={variant === 'primary'}
      onClick={onClick}
    >
      {caption}
    </Button>
  );
};
