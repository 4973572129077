/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import SQDeviceStatusLed from '../../controls/SQDeviceStatusLed/SQDeviceStatusLed';
import './SystemStatusCockpitCard.scss';

export interface SystemStatusCockpitCardProps {
  deviceInstanceId: string;
  statusView?: DeviceModelStatus.UI.Contactron.MotorStarterStatusView;
}

export const SystemStatusCockpitCard: React.FC<SystemStatusCockpitCardProps> = (props: SystemStatusCockpitCardProps):
  React.ReactElement => {
  const {
    statusView,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const deviceStatusItems = (statusView?.items ?? [])
    .map((itemIdent) => <SQDeviceStatusLed key={`contactron-system-status--${deviceInstanceId}-${itemIdent}`} identRef={itemIdent} deviceInstanceId={deviceInstanceId} onStateChanged={() => 0} />);

  return (
    <PaperControl title={t<string>(statusView?.label ?? '-')}>
      <div className="contactron-system-status-card">
        {deviceStatusItems}
      </div>
    </PaperControl>
  );
};
