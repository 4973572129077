/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { ReduxControlProps } from '../ReduxControlProps';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { ComponentFactory } from '../ComponentFactory/ComponentFactory';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import withControlVisibility from '../hoc/withControlVisibility';

const MenuReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    onStateChanged,
    readonly,
    deviceInstanceId,
  } = props;

  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  if (descriptor?.type === undefined) {
    return <UnsupportedControl text={`MenuReduxControl: Undefined object ${identRef}`} />;
  }

  if (descriptor.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return <UnsupportedControl text={`MenuReduxControl: Unsupported type ${descriptor.type}`} />;
  }

  const control = `menu-${descriptor.style ?? 'vertical'}`;
  const component = ComponentFactory({
    identRef,
    deviceInstanceId,
    control,
    key: `${control}-${identRef}`,
    onStateChanged,
    readonly,
  });

  return (
    <>
      {component}
    </>
  );
};

export default withControlVisibility(MenuReduxControl);
