/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ActionMethodControl from '../../../controls/MethodExecution/ActionMethodControl/ActionMethodControl';
import { ModalWarningDialog } from '../../../modal/ModalWarningDialog/ModalWarningDialog';
import { useTypedSelector } from '../../../store';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';
import { cleanDeviceMethodExecutionState } from '../../../store/deviceInstances/store/deviceMethod/actions';
import { ForceDialogPasswordContext } from '../Context/ForceDialogContext';

export interface ForceDialogControlProps {
    showDialog: boolean;
    title: string;
    message: string;
    actionId: string;
    deviceInstanceId: string;
    onCancel: () => void;
    onAccept: () => void;
    onSuccess: () => void;
    onFailed: () => void;
}

export const ForceDialogControl: React.FC<ForceDialogControlProps> = (
  props: ForceDialogControlProps,
): React.ReactElement => {
  const {
    showDialog, title, message, actionId, deviceInstanceId,
    onCancel, onAccept, onSuccess, onFailed,
  } = props;
  const dispatch = useDispatch();
  const { setPassword } = useContext(ForceDialogPasswordContext);
  const stageStatus = useTypedSelector((state) => state.deviceInstances.instances[deviceInstanceId]?.deviceMethodExecution[actionId]?.stage ?? MethodStageExecutionStatus.Initialize);

  useEffect(() => {
    if (stageStatus === MethodStageExecutionStatus.DoneSuccess) {
      dispatch(cleanDeviceMethodExecutionState(deviceInstanceId, actionId));
      onSuccess();
    } else if (stageStatus === MethodStageExecutionStatus.DoneFailed) {
      dispatch(cleanDeviceMethodExecutionState(deviceInstanceId, actionId));
      onFailed();
    }
  }, [stageStatus, deviceInstanceId, actionId, onSuccess, onFailed]);

  return (
    <div>
      <div>
        {showDialog === true
        && (
        <ModalWarningDialog
          title={title}
          message={message}
          onAccept={onAccept}
          onCancel={onCancel}
        />
        )}
      </div>
      <ActionMethodControl methodIdent="methodMINIMCRWriteAction" actionId={actionId} deviceInstanceId={deviceInstanceId} onSetPassword={setPassword} />
    </div>
  );
};
