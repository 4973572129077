/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceInstanceAction } from '../../common';

export const REQUEST_RESOURCE_ID = 'REQUEST_RESOURCE_ID';

type RequestResourceIdAction = DeviceInstanceAction<typeof REQUEST_RESOURCE_ID, string>

export type typeResourceProviderMiddlewareAction = RequestResourceIdAction;
