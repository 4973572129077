/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Resource } from 'i18next';

import i18nCONTACTRONen from './CONTACTRON_en.json';
import i18nCONTACTRONde from './CONTACTRON_de.json';
import i18nCONTACTRONEs from './CONTACTRON_es.json';
import i18nCONTACTRONFr from './CONTACTRON_fr.json';
import i18nCONTACTRONIt from './CONTACTRON_it.json';
import i18nCONTACTRONJa from './CONTACTRON_ja.json';
import i18nCONTACTRONPl from './CONTACTRON_pl.json';
import i18nCONTACTRONPt from './CONTACTRON_pt.json';
import i18nCONTACTRONRu from './CONTACTRON_ru.json';
import i18nCONTACTRONTr from './CONTACTRON_tr.json';
import i18nCONTACTRONZh from './CONTACTRON_zh.json';
import i18nCONTACTRONCs from './CONTACTRON_cs.json';
import i18nCONTACTRONDa from './CONTACTRON_da.json';
import i18nCONTACTRONFi from './CONTACTRON_fi.json';
import i18nCONTACTRONHu from './CONTACTRON_hu.json';
import i18nCONTACTRONKo from './CONTACTRON_ko.json';
import i18nCONTACTRONNb from './CONTACTRON_nb.json';
import i18nCONTACTRONNl from './CONTACTRON_nl.json';
import i18nCONTACTRONRo from './CONTACTRON_ro.json';
import i18nCONTACTRONSv from './CONTACTRON_sv.json';
import i18nCONTACTRONUk from './CONTACTRON_uk.json';

/**
 * Default translation for application
 */
const bundledResources: Resource = {
  en: {
    CONTACTRON: i18nCONTACTRONen,
  },
  de: {
    CONTACTRON: i18nCONTACTRONde,
  },
  es: {
    CONTACTRON: i18nCONTACTRONEs,
  },
  fr: {
    CONTACTRON: i18nCONTACTRONFr,
  },
  it: {
    CONTACTRON: i18nCONTACTRONIt,
  },
  ja: {
    CONTACTRON: i18nCONTACTRONJa,
  },
  pl: {
    CONTACTRON: i18nCONTACTRONPl,
  },
  pt: {
    CONTACTRON: i18nCONTACTRONPt,
  },
  ru: {
    CONTACTRON: i18nCONTACTRONRu,
  },
  tr: {
    CONTACTRON: i18nCONTACTRONTr,
  },
  zh: {
    CONTACTRON: i18nCONTACTRONZh,
  },
  cs: {
    CONTACTRON: i18nCONTACTRONCs,
  },
  da: {
    CONTACTRON: i18nCONTACTRONDa,
  },
  fi: {
    CONTACTRON: i18nCONTACTRONFi,
  },
  hu: {
    CONTACTRON: i18nCONTACTRONHu,
  },
  ko: {
    CONTACTRON: i18nCONTACTRONKo,
  },
  nb: {
    CONTACTRON: i18nCONTACTRONNb,
  },
  nl: {
    CONTACTRON: i18nCONTACTRONNl,
  },
  ro: {
    CONTACTRON: i18nCONTACTRONRo,
  },
  sv: {
    CONTACTRON: i18nCONTACTRONSv,
  },
  uk: {
    CONTACTRON: i18nCONTACTRONUk,
  },
};

export default bundledResources;
