import { DeviceModelStatus } from '@gpt/commons';

// eslint-disable-next-line max-len
const fdcml10DeviceModelStatusDescriptorCollection: DeviceModelStatus.DeviceModelDescriptorCollection = {
  user_char_min_x: {
    identRef: 'user_char_min_x',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:$$$User-KL minimaler X-Wert',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },
  },
  user_char_max_x: {
    identRef: 'user_char_max_x',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:$$$User-KL maximaler X-Wert',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 100,
    },
  },
  sensor: {
    identRef: 'sensor',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Sensor',
    help: '01_04_0F_14_0020:SENSOR_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 0,
      enumerator: [{
        label: '01_04_0F_14_0020:Pt 100 IEC 751 / GOST 6651 (α=0,00385)',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:Pt 1000 IEC 751 / GOST 6651 (α=0,00385)',
        value: 1,
      },
      {
        label: '01_04_0F_14_0020:Pt 200 IEC 751 / GOST 6651 (α=0,00385)',
        value: 2,
      },
      {
        label: '01_04_0F_14_0020:Pt 500 IEC 751 / GOST 6651 (α=0,00385)',
        value: 3,
      },
      {
        label: '01_04_0F_14_0020:Pt 100 GOST 6651 (α=0,00391)',
        value: 4,
      },
      {
        label: '01_04_0F_14_0020:Pt 1000 GOST 6651 (α=0,00391)',
        value: 5,
      },
      {
        label: '01_04_0F_14_0020:Pt 100 JIS C 1604',
        value: 6,
      },
      {
        label: '01_04_0F_14_0020:Pt 1000 JIS C 1604',
        value: 7,
      },
      {
        label: '01_04_0F_14_0020:Ni 100 DIN 43760',
        value: 8,
      },
      {
        label: '01_04_0F_14_0020:Ni 1000 DIN 43760',
        value: 9,
      },
      {
        label: '01_04_0F_14_0020:Cu 50 GOST 6651 (α=0,00428)',
        value: 10,
      },
      {
        label: '01_04_0F_14_0020:Cu 100 GOST 6651 (α=0,00428)',
        value: 11,
      },
      {
        label: '01_04_0F_14_0020:Cu 53 GOST 6651 (α=0,00426)',
        value: 12,
      },
      {
        label: '01_04_0F_14_0020:Resistor linear',
        value: 14,
      },
      {
        label: '01_04_0F_14_0020:User',
        value: 13,
      },
      ],
    },
  },
  filterfactor: {
    identRef: 'filterfactor',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Filter factor [value(s)]',
    help: '01_04_0F_14_0020:FILTERFACTOR_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 1,
      range: {
        regions: [{
          minValue: 0,
          maxValue: 10,
        }],
      },
      stepSize: 1,
    },
  },
  temperature_unit: {
    identRef: 'temperature_unit',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Temperature unit',
    help: '01_04_0F_14_0020:TEMPERATURE_UNIT_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 0,
      enumerator: [{
        label: '01_04_0F_14_0020:°C',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:°F',
        value: 1,
      },
      ],
    },
  },
  mode: {
    identRef: 'mode',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Mode',
    help: '01_04_0F_14_0020:MODE_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 2,
      enumerator: [{
        label: '01_04_0F_14_0020:2-wire',
        value: 1,
      },
      {
        label: '01_04_0F_14_0020:3-wire',
        value: 2,
      },
      {
        label: '01_04_0F_14_0020:4-wire',
        value: 3,
      },
      ],
    },
  },
  samplerate: {
    identRef: 'samplerate',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Samplerate',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 8,
      enumerator: [{
        label: '01_04_0F_14_0020:4,17Hz',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:6,25Hz',
        value: 1,
      },
      {
        label: '01_04_0F_14_0020:8,33Hz',
        value: 2,
      },
      {
        label: '01_04_0F_14_0020:10Hz',
        value: 3,
      },
      {
        label: '01_04_0F_14_0020:12,5Hz',
        value: 4,
      },
      {
        label: '01_04_0F_14_0020:16,7Hz / 65dB',
        value: 5,
      },
      {
        label: '01_04_0F_14_0020:16,7Hz / 80dB',
        value: 6,
      },
      {
        label: '01_04_0F_14_0020:19,6Hz',
        value: 7,
      },
      {
        label: '01_04_0F_14_0020:33,2Hz',
        value: 8,
      },
      {
        label: '01_04_0F_14_0020:39Hz',
        value: 9,
      },
      {
        label: '01_04_0F_14_0020:50Hz',
        value: 10,
      },
      {
        label: '01_04_0F_14_0020:62Hz',
        value: 11,
      },
      {
        label: '01_04_0F_14_0020:123Hz',
        value: 12,
      },
      {
        label: '01_04_0F_14_0020:242Hz',
        value: 13,
      },
      {
        label: '01_04_0F_14_0020:470Hz',
        value: 14,
      },
      ],
    },

  },
  device_type: {
    identRef: 'device_type',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Device type',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.STRING,
      initialValue: 'MINI MCR-2-RTD-UI',
      length: 32,
      stringType: DeviceModelStatus.StatusDescriptorValueTypeStringType.ASCII,
    },
  },
  fw_version: {
    identRef: 'fw_version',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:FW-Version',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 4112,
    },
  },
  serial_number: {
    identRef: 'serial_number',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Serialnumber',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 64,
      initialValue: 0,
    },
  },
  tag_number: {
    identRef: 'tag_number',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Tag number',
    help: '',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.STRING,
      initialValue: 'Messstelle',
      length: 16,
      stringType: DeviceModelStatus.StatusDescriptorValueTypeStringType.ASCII,
    },
  },
  user_message: {
    identRef: 'user_message',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:User message',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.STRING,
      initialValue: 'Anwendertext',
      length: 20,
      stringType: DeviceModelStatus.StatusDescriptorValueTypeStringType.ASCII,
    },
  },
  date: {
    identRef: 'date',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Date',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.DATETIME,
      initialValue: '31100241',
      displayFormat: '',
    },
  },
  analog_output_type: {
    identRef: 'analog_output_type',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Analog output',
    help: '01_04_0F_14_0020:ANALOG_OUTPUT_TYPE_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 2,
      enumerator: [{
        label: '01_04_0F_14_0020:0…20mA',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:20…0mA',
        value: 1,
      },
      {
        label: '01_04_0F_14_0020:4…20mA',
        value: 2,
      },
      {
        label: '01_04_0F_14_0020:20…4mA',
        value: 3,
      },
      {
        label: '01_04_0F_14_0020:0…10V',
        value: 4,
      },
      {
        label: '01_04_0F_14_0020:10…0V',
        value: 5,
      },
      {
        label: '01_04_0F_14_0020:0…5V',
        value: 6,
      },
      {
        label: '01_04_0F_14_0020:1…5V',
        value: 7,
      },
      {
        label: '01_04_0F_14_0020:Current freely definable',
        value: 8,
      },
      {
        label: '01_04_0F_14_0020:Voltage freely definable',
        value: 9,
      },
      {
        label: '01_04_0F_14_0020:Switching-Behaviour current',
        value: 10,
      },
      {
        label: '01_04_0F_14_0020:Switching-Behaviour voltage',
        value: 11,
      },
      ],
    },
  },
  error_handling: {
    identRef: 'error_handling',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:ERROR_HANDLING_LABEL',
    help: '01_04_0F_14_0020:ERROR_HANDLING_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 5,
      enumerator: [{
        label: '01_04_0F_14_0020:ERROR_HANDLING_ENUM0_LABEL',
        value: 4,
      },
      {
        label: '01_04_0F_14_0020:ERROR_HANDLING_ENUM1_LABEL',
        value: 5,
      },
      {
        label: '01_04_0F_14_0020:ERROR_HANDLING_ENUM2_LABEL',
        value: 6,
      },
      {
        label: '01_04_0F_14_0020:ERROR_HANDLING_ENUM3_LABEL',
        value: 7,
      },
      {
        label: '01_04_0F_14_0020:ERROR_HANDLING_ENUM4_LABEL',
        value: 8,
      },
      ],
    },
  },
  analog_input_range_start: {
    identRef: 'analog_input_range_start',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Analog input range start',
    help: '01_04_0F_14_0020:ANALOG_INPUT_RANGE_START_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 2,
      range: {
        regions: [{
          minValue: 2,
          maxValue: 200,
        }],
      },
    },
    unit: 'Ω',
  },
  analog_input_range_end: {
    identRef: 'analog_input_range_end',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Analog input range end',
    help: '01_04_0F_14_0020:ANALOG_INPUT_RANGE_END_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 150,
      range: {
        regions: [{
          minValue: 2,
          maxValue: 200,
        }],
      },
    },
    unit: 'Ω',
  },
  two_wire_compensation: {
    identRef: 'two_wire_compensation',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:2-wire compensation',
    help: '01_04_0F_14_0020:TWO_WIRE_COMPENSATION_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
      range: {
        regions: [{
          minValue: 0,
          maxValue: 230000000,
        }],
      },
      stepSize: 0.01,
    },
    unit: 'Ω',
  },
  application_help: {
    identRef: 'application_help',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:[ Application - Help ]',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 8,
      initialValue: 1,
      enumerator: [{
        label: '01_04_0F_14_0020:Zero/Span via free definable output',
        value: 1,
      },
      {
        label: '01_04_0F_14_0020:Zero/Span via free definable output',
        value: 0,
      },
      ],
    },
  },
  application_help_RTD: {
    identRef: 'application_help_RTD',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:[ Application - Help ]',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 8,
      initialValue: 1,
      enumerator: [{
        label: '01_04_0F_14_0020:Disabled items not relevant',
        value: 1,
      },
      {
        label: '01_04_0F_14_0020:Disabled items not relevant',
        value: 0,
      },
      ],
    },
  },
  analog_output_range_start: {
    identRef: 'analog_output_range_start',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Analog output range start',
    help: '01_04_0F_14_0020:ANALOG_OUTPUT_RANGE_START_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 4,
      range: {
        regions: [{
          minValue: 4,
          maxValue: 20,
        }],
      },
    },
    unit: 'mA',
  },
  analog_output_range_end: {
    identRef: 'analog_output_range_end',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Analog output range end',
    help: '01_04_0F_14_0020:ANALOG_OUTPUT_RANGE_END_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 20,
      range: {
        regions: [{
          minValue: 4,
          maxValue: 20,
        }],
      },
    },
    unit: 'mA',
  },
  analog_output_range_span: {
    identRef: ' analog_output_range_span',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:analog_output_range_span',
    help: '01_04_0F_14_0020:analog_output_range_span HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 20,
      range: {
        regions: [{
          minValue: 4,
          maxValue: 20,
        }],
      },
    },
    unit: 'mA',
  },
  overrange: {
    identRef: 'overrange',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Overrange',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 3.5,
    },
    unit: 'mA',
  },
  underrange: {
    identRef: 'underrange',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Underrange',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 3.5,
    },
    unit: 'mA',
  },
  linebreak: {
    identRef: 'linebreak',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Linebreak',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 3.5,
    },
    unit: 'mA',
  },
  shorted: {
    identRef: 'shorted',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Shorted',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 3.5,
    },
    unit: 'mA',
  },
  faultsignal_modulerror: {
    identRef: 'faultsignal_modulerror',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:FAULTSIGNAL_MODULERROR_LABEL',
    help: '01_04_0F_14_0020:FAULTSIGNAL_MODULERROR_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 1,
      enumerator: [{
        label: '01_04_0F_14_0020:Deactivated',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:Activated',
        value: 1,
      },
      ],
    },

  },
  faultsignal_overrange: {
    identRef: 'faultsignal_overrange',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:FAULTSIGNAL_OVERRANGE_LABEL',
    help: '01_04_0F_14_0020:FAULTSIGNAL_OVERRANGE_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 1,
      enumerator: [{
        label: '01_04_0F_14_0020:Deactivated',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:Activated',
        value: 1,
      },
      ],
    },
  },
  faultsignal_underrange: {
    identRef: 'faultsignal_underrange',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:FAULTSIGNAL_UNDERRANGE_LABEL',
    help: '01_04_0F_14_0020:FAULTSIGNAL_UNDERRANGE_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 1,
      enumerator: [{
        label: '01_04_0F_14_0020:Deactivated',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:Activated',
        value: 1,
      },
      ],
    },

  },
  faultsignal_linebreak: {
    identRef: 'faultsignal_linebreak',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:FAULTSIGNAL_LINEBREAK_LABEL',
    help: '01_04_0F_14_0020:FAULTSIGNAL_LINEBREAK_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 1,
      enumerator: [{
        label: '01_04_0F_14_0020:Deactivated',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:Activated',
        value: 1,
      },
      ],
    },

  },
  faultsignal_shorted: {
    identRef: 'faultsignal_shorted',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:FAULTSIGNAL_SHORTED_LABEL',
    help: '01_04_0F_14_0020:FAULTSIGNAL_SHORTED_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 1,
      enumerator: [{
        label: '01_04_0F_14_0020:Deactivated',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:Activated',
        value: 1,
      },
      ],
    },

  },
  analog_output_Threshold: {
    identRef: 'analog_output_Threshold',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Threshold',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },

  },
  analog_output_SetValue: {
    identRef: 'analog_output_SetValue',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:Set value',
    help: '01_04_0F_14_0020',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 10,
    },
  },
  application_pin: {
    identRef: 'application_pin',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:APPLICATION_PIN_LABEL',
    help: '01_04_0F_14_0020:APPLICATION_PIN_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.WRITE_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  paraSafetyApplication: {
    identRef: 'paraSafetyApplication',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:PARA_SAFETY_APPLICATION_LABEL',
    help: '01_04_0F_14_0020:PARA_SAFETY_APPLICATION_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
};

export default fdcml10DeviceModelStatusDescriptorCollection;
