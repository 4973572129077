/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import Backstage, { openBackstage } from './Backstage';

export default Backstage;
export { openBackstage };
