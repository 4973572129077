/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../store';
import StationModuleRow from './StationModuleRow/StationModuleRow';
import './StationAddressingTable.scss';

const StationAddressingTable: React.FC = () : React.ReactElement => {
  const { t } = useTranslation();
  const moduleList = useTypedSelector((state) => state.contactronInitWizard.moduleList);

  const moduleRows = moduleList
    .map((mkey) => (<StationModuleRow key={mkey} mkey={mkey} />));

  return (
    <table className="page-station-addressing-table">
      <thead>
        <tr className="page-station-addressing-table-header">
          <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_ADDRESS')}</th>
          <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_DETECTED_DEVICE_TYPE')}</th>
        </tr>
      </thead>
      <tbody>
        {moduleRows}
      </tbody>
    </table>
  );
};

export default StationAddressingTable;
