/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import Button from 'react-bootstrap/Button';
import './ZoomControl.css';

export interface ZoomControlProps {
  currentValue: number,
  // eslint-disable-next-line no-unused-vars
  changeZoomFactor: (value: number) => void,
  tooltip?: string,
}

export const ZoomControl: React.FC<ZoomControlProps> = (
  props: ZoomControlProps,
): React.ReactElement => {
  const {
    currentValue, changeZoomFactor, tooltip,
  } = props;

  return (
    <div className="zoom-control-container" title={tooltip}>
      <div>
        <input
          id="zoom-control-input"
          type="number"
          value={currentValue}
          readOnly
          className="zoom-control-container-textfield__input"
        />
      </div>
      <div className="zoom-control-container__button-container">
        <Button
          variant="tertiary"
          className="zoom-control-container__button rounded-0 border-0"
          onClick={() => {
            const newValue = (currentValue - 10) < 30 ? 30 : currentValue - 10;
            changeZoomFactor(newValue);
          }}
        >
          <div className="zoom-control-container__button__icon"> - </div>
        </Button>
      </div>
      <div className="zoom-control-container__button-container">
        <Button
          variant="tertiary"
          className="zoom-control-container__button rounded-0 border-0"
          onClick={() => {
            const newValue = (currentValue + 10) > 250 ? 250 : currentValue + 10;
            changeZoomFactor(newValue);
          }}
        >
          <div className="zoom-control-container__button__icon"> + </div>
        </Button>
      </div>
    </div>
  );
};
