/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER device parametrization tool
 * Component: DeviceModelServer
 *
 **************************************************************************** */

/* eslint-disable max-len */
import {
  IService,
} from '@gpt/commons';

export interface IDeviceInstanceService extends IService {
  // eslint-disable-next-line no-unused-vars
  addChildInstance: (parentInstanceId: string, childInstanceId: string) => void;
  // eslint-disable-next-line no-unused-vars
  removeChildInstance: (childInstanceId: string) => void;
  // eslint-disable-next-line no-unused-vars
  getChildInstances: (parentInstanceId: string) => string[];
  // eslint-disable-next-line no-unused-vars
  removeInstance: (parentInstanceId: string) => void;
}

interface IInstanceItem {
  [key: string]: string;
}

interface IInstanceCollection {
  [key: string]: IInstanceItem;
}

// eslint-disable-next-line import/prefer-default-export
export class DeviceInstanceService implements IDeviceInstanceService {
  // eslint-disable-next-line class-methods-use-this
  public ServiceName = () => 'DeviceInstanceManager';

  private instanceCollection: IInstanceCollection;

  constructor() {
    this.instanceCollection = {};
  }

  public addChildInstance = (parentInstanceId: string, childInstanceId: string): void => {
    if (this.instanceCollection[parentInstanceId] === undefined) {
      this.instanceCollection = {
        ...this.instanceCollection,
        [parentInstanceId]: {
          [childInstanceId]: childInstanceId,
        },
      };
    } else {
      this.instanceCollection = {
        ...this.instanceCollection,
        [parentInstanceId]: {
          ...this.instanceCollection[parentInstanceId],
          [childInstanceId]: childInstanceId,
        },
      };
    }
  };

  public removeChildInstance = (childInstanceId: string): void => {
    this.instanceCollection = Object.keys(this.instanceCollection)
      .reduce((acc, parentId) => {
        if (acc[parentId][childInstanceId] === undefined) {
          return acc;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [childInstanceId]: child, ...children } = acc[parentId];
        return {
          ...acc,
          [parentId]: children,
        };
      }, this.instanceCollection);
  };

  public getChildInstances = (parentInstanceId: string)
  : string[] => (this.instanceCollection[parentInstanceId] === undefined
    ? []
    : Object.keys(this.instanceCollection[parentInstanceId]));

  public removeInstance = (parentInstanceId: string): void => {
    if (this.instanceCollection[parentInstanceId] === undefined) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [parentInstanceId]: child, ...data } = this.instanceCollection;
    this.instanceCollection = data;
  };
}
