/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import Image from 'react-bootstrap/Image';
import iconLock from '../../assets/icons/icon-lock.svg';
import iconLockOpen from '../../assets/icons/icon-lock-open.svg';
import './DeviceStatusVariableIconControl.scss';

export interface DeviceStatusVariableIconProps {
  label: string;
  icon: string;
}

const iconMap = {
  lock: iconLock,
  unlock: iconLockOpen,
};

export const DeviceStatusVariableIconControl: React.FC<DeviceStatusVariableIconProps> = (props: DeviceStatusVariableIconProps): React.ReactElement => {
  const {
    label, icon,
  } = props;
  const svgIcon = iconMap[icon];

  return (
    <div className="device-status-variable-icon__container">
      <div className="device-status-variable-icon__container-space" />
      <div className="device-status-variable-icon__container-label">
        {`${label}:`}
      </div>
      <div className="device-status-variable-icon__container-icon">
        <Image
          src={svgIcon}
          height={18}
          width={18}
        />
      </div>
    </div>
  );
};
