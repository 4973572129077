/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ParameterControl } from '../../../components/ParameterEditor';
import { UnsupportedControl } from '../../../components/UnsupportedControl';
import { useTypedSelector } from '../../../store';
import { trio3SwitchChannel } from '../../../store/deviceInstances/middleware/iolink/ioLinkTrio3ChannelSwitch/actions';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { ioLinkTrio3ChannelSwitchSelector } from '../../../store/deviceInstances/store/ioLinkTrio3ChannelSwitch/selector';
import { Trio3ChannelSwitchState } from '../../../store/deviceInstances/store/ioLinkTrio3ChannelSwitch/types';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';
import { DatasetContext } from '../../../views/DatasetContext';
import { ChannelSwitchBlockVariable } from '../../ChannelSwitchBlockControl/ChannelSwitchBlockControl';
import { ReduxControlProps } from '../../ReduxControlProps';
import withControlVisibility from '../../hoc/withControlVisibility';
import { Trio3ChannelSwitchNumber } from './Trio3ChannelSwitchNumber/Trio3ChannelSwitchNumber';
import Trio3ChannelSwitchBlockControl from './Trio3ChannelSwitchBlockControl/Trio3ChannelSwitchBlockControl';
import { Trio3ChannelLed } from './Trio3ChannelSwitchNumber/Trio3ChannelSwitch/Trio3ChannelSwitch';

const compareSwitchBlockVariables = (xprev?: ChannelSwitchBlockVariable[], xcur?: ChannelSwitchBlockVariable[]): boolean => {
  const prev = xprev ?? [];
  const cur = xcur ?? [];
  if (prev.length !== cur.length) {
    return false;
  }
  const uneq = cur.find((item, idx) => item.identRef !== prev[idx].identRef
    || item.value !== prev[idx].value);
  return uneq === undefined;
};

const controlTypeSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: IdentRef,
): DeviceModelStatus.UI.ChannelSwitchBlockControlType | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const desc = dataset?.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  return desc.controlType.type !== DeviceModelStatus.UI.ControlType.CTLCHANNELSWITCHBLOCK
    ? undefined
    : desc.controlType;
};

const selectControlVariables = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: IdentRef,
): ChannelSwitchBlockVariable[] | undefined => {
  const control = controlTypeSelector(state, deviceInstanceId, targetDataset, identRef);
  if (control === undefined) {
    return undefined;
  }

  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (dataset === undefined) {
    return undefined;
  }

  const controlVariables = control.SWITCHVALUE.reduce((acc, item) => {
    const statusDesc = dataset.descriptors[item.variable];
    if (statusDesc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return acc;
    }
    if (!statusDesc.visibility) {
      return acc;
    }

    return [
      ...acc,
      {
        identRef: item.variable,
        value: dataset.values[item.variable].value,
      },
    ];
  }, [] as ChannelSwitchBlockVariable[]);

  return controlVariables;
};

export const TRIO3_CHANNEL_STATE__OFF = 0;
export const TRIO3_CHANNEL_STATE__ON = 1;
export const TRIO3_CHANNEL_STATE__RESTART = 2;
export const TRIO3_CHANNEL_STATE__DEFECT = 3;

const Trio3ChannelSwitchBlockReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { targetDataset } = useContext(DatasetContext);

  const control = useTypedSelector((state) => controlTypeSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const controlVariables = useTypedSelector(
    (state) => selectControlVariables(state.deviceInstances, deviceInstanceId, targetDataset, identRef),
    (prev, next) => compareSwitchBlockVariables(prev, next),
  );
  const controlState = useTypedSelector((state) => ioLinkTrio3ChannelSwitchSelector(state.deviceInstances, deviceInstanceId)?.[identRef] ?? Trio3ChannelSwitchState.Done);

  if (control === undefined || controlVariables === undefined) {
    return <UnsupportedControl text={`Trio3ChannelSwitchBlockReduxControl [${identRef}]: undefined object`} />;
  }

  const blockSwitches = controlVariables.map((item, idx) => {
    let led = Trio3ChannelLed.GREY;
    switch (item.value) {
      case TRIO3_CHANNEL_STATE__OFF:
        led = Trio3ChannelLed.GREY;
        break;
      case TRIO3_CHANNEL_STATE__ON:
        led = Trio3ChannelLed.GREEN;
        break;
      case TRIO3_CHANNEL_STATE__RESTART:
        led = Trio3ChannelLed.BLINK_RED;
        break;
      case TRIO3_CHANNEL_STATE__DEFECT:
        led = Trio3ChannelLed.RED;
        break;
      default:
        led = Trio3ChannelLed.GREY;
    }

    const channelNo = idx + 1;
    return (
      <Trio3ChannelSwitchNumber
        id={item.identRef}
      // eslint-disable-next-line react/no-array-index-key
        key={`trio3-channel-switch-number-${idx}`}
        led={led}
        label={`${channelNo}`}
        onSwitchChannel={() => {
          if (item.value === TRIO3_CHANNEL_STATE__DEFECT) {
            return;
          }
          dispatch(trio3SwitchChannel({
            ident: item.identRef,
            channel: channelNo,
            writeValue: item.value === TRIO3_CHANNEL_STATE__OFF || item.value === TRIO3_CHANNEL_STATE__RESTART
              ? TRIO3_CHANNEL_STATE__ON
              : TRIO3_CHANNEL_STATE__OFF,
            targetInstance: deviceInstanceId,
          }));
        }}
        disabled={controlState === Trio3ChannelSwitchState.Busy}
      />
    );
  });

  return (
    <ParameterControl>
      <Trio3ChannelSwitchBlockControl label={t<string>(control.title)}>
        {blockSwitches}
      </Trio3ChannelSwitchBlockControl>
    </ParameterControl>
  );
};

export default withControlVisibility(Trio3ChannelSwitchBlockReduxControl);
