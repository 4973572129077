/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import SystemDataMenuControl from '../../SystemDataMenu/SystemDataMenu';
import OnlineCardControl from '../OnlineCardControl/OnlineCardControl';

export interface SystemDataOnlineCardProps {
  deviceInstanceId: string;
  dataView?: DeviceModelStatus.UI.Contactron.MotorStarterDataView;
}

const SystemDataOnlineCard: React.FC<SystemDataOnlineCardProps> = (props: SystemDataOnlineCardProps):
  React.ReactElement => {
  const {
    dataView,
    deviceInstanceId,
  } = props;

  return (
    <OnlineCardControl label={dataView?.label ?? ''}>
      <div className="contactron-system-data">
        <SystemDataMenuControl deviceInstanceId={deviceInstanceId} dataView={dataView} />
      </div>
    </OnlineCardControl>
  );
};

export default SystemDataOnlineCard;
