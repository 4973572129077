/* eslint-disable max-len */
import React from 'react';
import {
  gaugeRadius, gaugeStrokeWidth, polarToCartesian,
  mapValueToAngle, gaugeMinAngle,
  gaugeMaxAngle, gaugeCenterX, gaugeCenterY,
} from '../../common';

const gaugePointerHeight = 15;
const gaugePointerMoveRadius = gaugeRadius - gaugeStrokeWidth / 2 - gaugePointerHeight - 2;

export interface SVGGaugePointerProps {
  currentValue: number,
  gaugeMinValue: number,
  gaugeMaxValue: number
}

const SVGGaugePointer: React.FC<SVGGaugePointerProps> = (props: SVGGaugePointerProps)
: React.ReactElement => {
  const { currentValue, gaugeMaxValue, gaugeMinValue } = props;

  const p1 = polarToCartesian(0, 0, gaugePointerHeight, 0);
  const p2 = polarToCartesian(0, 0, gaugePointerHeight, -120);
  const p3 = polarToCartesian(0, 0, gaugePointerHeight, 120);
  const triangle = `${p1.x},${p1.y} ${p2.x},${p2.y} ${p3.x},${p3.y}`;

  const pointerAngle = mapValueToAngle(gaugeMinValue, gaugeMaxValue, gaugeMinAngle, gaugeMaxAngle, currentValue);

  return (
    <polygon points={triangle} transform={`translate(${gaugeCenterX} ${gaugeCenterY}) rotate(${pointerAngle} 0 0) translate(0 -${gaugePointerMoveRadius})`} />
  );
};

export default SVGGaugePointer;
