/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import ioLinkCaparocStationReducer, { initIoLinkCaparocStationState } from './ioLinkCaparocStation/reducer';
import {
  typeCustomStorageActionTypes,
  CustomStorageState,
} from './types';

export const initialCustomStorageState: CustomStorageState = {
  ioLinkCaparocStation: initIoLinkCaparocStationState,
};

const customStorageStateReducer = (
  state = initialCustomStorageState,
  action: typeCustomStorageActionTypes,
): CustomStorageState => {
  const reducerUpdateActionSwitch = {
    IoLinkCaparocStation: () => ({
      ...state,
      ioLinkCaparocStation: ioLinkCaparocStationReducer(state.ioLinkCaparocStation, action),
    }),
  };

  const { type } = action;
  switch (type) {
    case 'CUSTOM_STORAGE__UPDATE_ACTION': {
      const { target } = action.payload;
      return reducerUpdateActionSwitch[target] === undefined
        ? state : reducerUpdateActionSwitch[target]();
    }
    case 'CUSTOM_STORAGE__CLEAN_ACTION': {
      const { target } = action.payload;
      return reducerUpdateActionSwitch[target] === undefined
        ? state : reducerUpdateActionSwitch[target]();
    }
    default:
  }
  return state;
};

export default customStorageStateReducer;
