/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Row } from 'react-bootstrap';
import InputBoxControl from '../../Basic/InputBoxControl/InputBoxControl';
import SliderBoxControl from '../../Basic/SliderBoxControl/SliderBoxControl';
import { ParameterLabelColumns, ParameterUnitColumns, ParameterValueColumns } from '../ParameterControlColumnCount';
import './InputSliderControl.css';

export interface InputSliderControlProps {
    label?: string
    value: number;
    min: number;
    max: number;
    step: number;
    unit?: string;
    warning?: string;
    modified?: boolean;
    // eslint-disable-next-line no-unused-vars
    onValueChange: (value: number) => void;
    onShowContextHelp?: () => void;
    onHideContextHelp?: () => void;
}

export const InputSliderControl:
React.FC<InputSliderControlProps> = (props: InputSliderControlProps): React.ReactElement => {
  const {
    label, unit, value,
    warning, modified,
    min, max, step,
    onValueChange, onHideContextHelp, onShowContextHelp,
  } = props;

  let labelControl;
  let paramValueColumns = ParameterValueColumns;
  if (label === undefined) {
    paramValueColumns = ParameterValueColumns + ParameterLabelColumns;
  } else {
    labelControl = (
      <div className={(
      `col-${ParameterLabelColumns} input-slider-control__label d-flex direction-row justify-content-end m-0 p-0 px-2`)}
      >
        <p>
          {label}
        </p>
      </div>
    );
  }

  return (
    <Row className="input-slider-control flex-nowrap align-items-center m-0 p-0 w-100">
      {labelControl}
      <div className={(
        `col-${paramValueColumns} input-slider-control__value m-0 p-0 px-1`
        + ' d-flex direction-row align-items-center justify-content-between')}
      >
        <div className="input-slider-control__value__slider">
          <SliderBoxControl
            value={value}
            onValueChange={(newValue) => onValueChange(newValue)}
            minRangeValue={min}
            maxRangeValue={max}
            step={step}
          />
        </div>
        <div className="input-slider-control__value__input">
          <InputBoxControl
            type="number"
            value={value}
            displayFormat="%.2f"
            onChange={(newValue) => (
              onValueChange(parseFloat(newValue))
            )}
            warning={warning}
            minValue={min}
            maxValue={max}
            step={step}
            onFocus={onShowContextHelp}
            onBlur={onHideContextHelp}
            edited={modified}
          />
        </div>
      </div>
      <div className={(
        `col-${ParameterUnitColumns} input-slider-control__unit h-100 m-0 p-0 px-1`)}
      >
        <p>
          {unit}
        </p>
      </div>
    </Row>
  );
};
