/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceInstanceInformation, WebDevice } from '@gpt/commons';
import { Discovery } from '@gpt/cxe-nats-communication';
import { Action } from 'redux';
import { ExecutionState } from '../common';
import { NatsServerConnectionState } from '../../natsConnection';

export const DISCOVERY_STATE__UPDATE_LIST = 'DISCOVERY_STATE__UPDATE_LIST';
export const DISCOVERY_STATE__SET_CONNECTION_STATE = 'DISCOVERY_STATE__SET_CONNECTION_STATE';

export const DISCOVERY_STATE__UPDATE_DEVICE_CATALOG = 'DISCOVERY_STATE__UPDATE_DEVICE_CATALOG';
export const DISCOVERY_STATE__SET_DEVICE_CATALOG_STATE = 'DISCOVERY_STATE__SET_DEVICE_CATALOG_STATE';

export enum ConnectedDeviceState {
  // eslint-disable-next-line no-unused-vars
  Disconnected = 'disconnected',
  // eslint-disable-next-line no-unused-vars
  Identification = 'identification',
  // eslint-disable-next-line no-unused-vars
  ConnectedKnown = 'connectedKnow',
  // eslint-disable-next-line no-unused-vars
  ConnectedUnknown = 'connectedUnknown',
}

export enum InterfaceCardType {
  // eslint-disable-next-line no-unused-vars
  LAN = 'LAN',
  // eslint-disable-next-line no-unused-vars
  USB = 'USB',
  // eslint-disable-next-line no-unused-vars
  BLUETOOTH = 'BLUETOOTH'
}

export interface ConnectedDevice {
  // unique device position (slot0, ....)
  id: string;
  adapterId: string;
  state: ConnectedDeviceState;
  timestamp: string;
  catalogId?: string;
  instance?: DeviceInstanceInformation;
}

export interface ConnectedAdapter {
  id: string;
  title: string;
  connection: string;
  timestamp: string;
  adapterType: Discovery.CommunicationAdapterType;
  state: Discovery.AdapterState;
}

export interface ConnectedAdapterCollection {
  [key: string]: ConnectedAdapter;
}

export interface ConnectedDeviceCollection {
  [key: string]: ConnectedDevice;
}

export interface DiscoveryServiceDeviceCatalogCollection {
  [key: string]: WebDevice.DeviceCatalogInformation;
}

export interface DiscoveryServiceDeviceCatalog {
  executionState: ExecutionState;
  deviceList: DiscoveryServiceDeviceCatalogCollection;
}

export interface DiscoveryServiceState {
  state: NatsServerConnectionState;
  adapters: ConnectedAdapterCollection;
  devices: ConnectedDeviceCollection;
  catalog: DiscoveryServiceDeviceCatalog;
}

export interface SetDiscoveryConnectionStateAction extends Action<typeof DISCOVERY_STATE__SET_CONNECTION_STATE> {
  payload: NatsServerConnectionState,
}

export interface UpdateDiscoveryListAction extends Action<typeof DISCOVERY_STATE__UPDATE_LIST> {
  payload: Discovery.CommunicationAdapter,
}

export interface UpdateDeviceCatalogAction extends Action<typeof DISCOVERY_STATE__UPDATE_DEVICE_CATALOG> {
  payload: WebDevice.DeviceCatalogInformation[],
}

export interface SetDeviceCatalogStateAction extends Action<typeof DISCOVERY_STATE__SET_DEVICE_CATALOG_STATE> {
  payload: ExecutionState,
}

export type DiscoveryServiceStateActionType =
 | UpdateDiscoveryListAction
 | SetDiscoveryConnectionStateAction
 | SetDeviceCatalogStateAction
 | UpdateDeviceCatalogAction;
