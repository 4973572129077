/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  DeviceModelStatus,
  Report, ReportContentType, DeviceReplacement,
} from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reduxStore';
import { deviceWizardUpdateButtons } from '../../../store';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import { PageSaveProtocolComponent } from '../../../PageComponents/PageSaveProtocolComponent/PageSaveProtocolComponent';
import { ActionProgressControl } from '../../../components/ActionProgressControl/ActionProgressControl';
import { LoadingControl } from '../../../components/LoadingControl/LoadingControl';
import { createReportFilename } from '../../../helpers/functions';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import DownloadMethodControl from '../../../controls/MethodExecution/DownloadMethodControl/DownloadMethodControl';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';
import { deviceMethodExecutionSelector } from '../../../store/deviceInstances/store/deviceMethod/selectors';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';

const DOWNLOAD_IDENT = DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF;

interface Page4DownloadProps extends WizardViewPageProps {
  deviceInstanceId: string;
  deviceNewInstanceId: string;
}

const Page4Download
: React.FC<Page4DownloadProps> = (props: Page4DownloadProps): React.ReactElement => {
  const { t } = useTranslation();
  const {
    active, reportMenu, deviceInstanceId, deviceNewInstanceId,
  } = props;
  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceNewInstanceId));
  const stageStatus = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceNewInstanceId, DOWNLOAD_IDENT)?.stage ?? MethodStageExecutionStatus.Initialize);
  const stageSteps = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceNewInstanceId, DOWNLOAD_IDENT)?.steps);

  const backupFileName = useTypedSelector((state) => state.deviceWizard.backupFilename);
  const oldDeviceInformation = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId)?.device);

  const dispatch = useDispatch();

  useEffect(() => {
    if (active !== true) {
      return;
    }

    switch (stageStatus) {
      case MethodStageExecutionStatus.Initialize:
      case MethodStageExecutionStatus.InProgress:
      case MethodStageExecutionStatus.RequestAcceptData:
      case MethodStageExecutionStatus.RequestPassword:
        // Download method is initializing
        dispatch(deviceWizardUpdateButtons({
          source: 'ChangeDevice: Page4Download, downloadMethodStatus init / running / request / password',
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.disabled,
          pcta: ButtonRole.complete,
        }));
        break;
      case MethodStageExecutionStatus.DoneFailed:
        // Download method is initializing
        dispatch(deviceWizardUpdateButtons({
          source: 'ChangeDevice: Page4Download, downloadMethodStatus failed',
          abort: ButtonState.enabled,
          back: ButtonState.enabled,
          next: ButtonState.hidden,
          complete: ButtonState.disabled,
          pcta: ButtonRole.back,
          focusPcta: ButtonRole.back,
        }));
        break;
      case MethodStageExecutionStatus.DoneSuccess:
        // Download method is initializing
        dispatch(deviceWizardUpdateButtons({
          source: 'ChangeDevice: Page4Download, downloadMethodStatus done',
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.enabled,
          pcta: ButtonRole.complete,
          focusPcta: ButtonRole.complete,
        }));
        break;
      default:
        // Download method is not initialized
        dispatch(deviceWizardUpdateButtons({
          source: 'ChangeDevice: Page4Download, downloadMethodStatus default',
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.disabled,
          pcta: ButtonRole.complete,
        }));
    }
  }, [stageStatus, active]);

  if (activeDevice === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const getContentHandler = async (userName: string, userNotes: string, serialNumber: string): Promise<Report> => {
    const content: DeviceReplacement = {
      type: ReportContentType.DeviceReplacement,
      originalDevice: {
        deviceDesignation: oldDeviceInformation?.catalog?.deviceTypeName ?? '',
        deviceTag: oldDeviceInformation?.instance?.deviceTag ?? '',
        firmwareRevision: oldDeviceInformation?.instance?.firmwareVersion ?? '',
        hardwareRevision: oldDeviceInformation?.instance?.hardwareVersion ?? '',
        serialNumber: oldDeviceInformation?.instance?.serialNumber ?? '',
        itemNumber: oldDeviceInformation?.catalog?.productOrderNumber ?? '',
      },
      changeDevice: {
        deviceDesignation: activeDevice?.device.catalog?.deviceTypeName ?? '',
        deviceTag: activeDevice?.device.instance?.deviceTag ?? '',
        firmwareRevision: activeDevice?.device.instance?.firmwareVersion ?? '',
        hardwareRevision: activeDevice?.device.instance?.hardwareVersion ?? '',
        serialNumber,
        itemNumber: activeDevice?.device.catalog?.productOrderNumber ?? '',
      },
      backupFileName,
    };

    return {
      header: {
        dateTime: new Date(),
        systemName: t<string>('', '{{SYSTEM_NAME}}'),
        userName,
        userNotes,
      },
      content,
    };
  };

  const disableReportButtons = (stageStatus !== MethodStageExecutionStatus.DoneSuccess);
  const control = stageStatus === MethodStageExecutionStatus.RequestPassword || stageStatus === MethodStageExecutionStatus.RequestAcceptData
    ? (
      <DownloadMethodControl methodIdent={DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF} deviceInstanceId={deviceNewInstanceId} />
    )
    : (
      <PageSaveProtocolComponent
        reportMenu={reportMenu}
        deviceInstanceId={deviceNewInstanceId}
        caption={t<string>('WIZARD_CHANGE_DEVICE__PAGE_DOCUMENTATION__REPORT__TITLE')}
        nameCaption={t<string>('WIZARD_CHANGE_DEVICE__PAGE_DOCUMENTATION__REPORT__NAME')}
        descriptionCaption={t<string>('WIZARD_CHANGE_DEVICE__PAGE_DOCUMENTATION__REPORT__DESCRIPTION')}
        serialNumber={activeDevice?.device.instance?.serialNumber}
        disableButtons={disableReportButtons}
        fileName={createReportFilename('DeviceReplacementReport', activeDevice.device)}
        onGetContent={getContentHandler}
      />
    );

  return (
    <Container className="w-100 h-100" fluid>
      <Row>
        <Col xs={6}>
          <ActionProgressControl
            title={t<string>('WIZARD_CHANGE_DEVICE__PAGE_DOCUMENTATION__PROGRESS__TITLE')}
            steps={stageSteps}
          />
        </Col>
        <Col xs={6}>
          {control}
        </Col>
      </Row>
    </Container>
  );
};

export default Page4Download;
