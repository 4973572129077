/* eslint-disable max-len */
/* eslint-disable function-paren-newline */
import { IdentRef, DeviceModelStatus } from '@gpt/commons';
import { DatasetState, DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import { deviceTargetDatasetSelector } from '../../../../store/deviceInstances/store/dataStorage/selectors';
import { DeviceInstancesState } from '../../../../store/deviceInstances/types';

export const selectControlType = (identRef: IdentRef, dataset?: DatasetState)
: DeviceModelStatus.UI.PasswordControlType | undefined => {
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLPASSWORD) {
    return undefined;
  }
  return controlType;
};

export const selectVariableDescriptor = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: IdentRef): DeviceModelStatus.StatusDescriptor | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return undefined;
  }

  const varDescriptor = dataset?.descriptors[controlType.variable];
  if (varDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }
  return varDescriptor;
};

export const selectVariableValue = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: IdentRef)
  : DeviceModelStatus.StatusValue | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return undefined;
  }

  return dataset?.values[controlType.variable];
};
