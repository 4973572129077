/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import {
  Col, Row, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './ActionProtocolButtonList.css';

export interface ActionProtocolButtonListProps {
  closeButtonText: string;
  onDone: () => Promise<void>;
  onDoneDisabled?: boolean;
}

const ActionProtocolButtonList:
// eslint-disable-next-line max-len
React.FC<ActionProtocolButtonListProps> = (props: ActionProtocolButtonListProps): React.ReactElement => {
  const { t } = useTranslation();
  const {
    closeButtonText, onDone, onDoneDisabled,
  } = props;

  return (
    <Row className="align-items-end">
      <Col xs={6} className="form-group p-0 pr-1" />
      <Col xs={6} className="form-group p-0">
        <div className="action-protocol-buttonslist__button">
          <Button block type="button" className="button-text" variant={onDoneDisabled ? 'tertiary' : 'primary'} disabled={onDoneDisabled} onClick={async () => onDone()}>
            {t(closeButtonText)}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default ActionProtocolButtonList;
