/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { WizardViewPageProps } from '../../../WizardView/WizardViewPage';
import './Page6GroupProcessData.scss';
import GroupProcessDataTable from './Table/GroupProcessDataTable';
import GroupProcessDataHeader from './Header/GroupProcessDataHeader';

export interface Page6GroupProcessDataProps extends WizardViewPageProps {
  stationInstanceId: string;
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType;
}

const Page6GroupProcessData
: React.FC<Page6GroupProcessDataProps> = (props: Page6GroupProcessDataProps) : React.ReactElement => {
  const { stationDeviceDescription, stationInstanceId } = props;

  return (
    <div className="contactron-commissioning-wizard">
      <div className="page-station-group-process-data">
        <GroupProcessDataHeader />
        <GroupProcessDataTable stationInstanceId={stationInstanceId} stationDeviceDescription={stationDeviceDescription} />
      </div>
    </div>
  );
};

export default Page6GroupProcessData;
