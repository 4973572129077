/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import './ActionProgressControl.scss';
import { DeviceMethodExecutionStep } from '../../store/deviceInstances/store/deviceMethod/types';
import ProgressStepSelector from './ProgressSteps/ProgressStepSelector';

export interface ActionProgressControlProps {
  title: string;
  steps?: DeviceMethodExecutionStep[];
}

export const ActionProgressControl:
React.FC<ActionProgressControlProps> = (props: ActionProgressControlProps):
React.ReactElement => {
  const {
    steps, title,
  } = props;

  const stepElements = (steps ?? []).map((step) => (
    <ProgressStepSelector key={`step-${step.state}--${step.title}`} state={step.state} title={step.title} message={step.message} />
  ));

  return (
    <Container className="m-0 p-0">
      <Row>
        <Col xs={8} className="d-flex justify-content-end text-right align-items-center w-100 mb-1">
          <div className="action-progress-title">{title}</div>
        </Col>
        <Col xs={4} className="mb-1" />
      </Row>
      {stepElements}
    </Container>
  );
};
