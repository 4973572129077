/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */

import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import DateTime from 'react-datetime';
import { Moment } from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/pt';
import 'moment/locale/tr';
import 'moment/locale/ru';
import 'moment/locale/zh-cn';
import 'moment/locale/pl';
import 'moment/locale/ja';
import { getAppUiLanguageCode } from '../../i18n-config';
import ParameterControl from './ParameterControl';
import useDelayedUserInput from '../../controls/hooks/useDelayedUserInput';
import 'react-datetime/css/react-datetime.css';
import './DateEditorControl.css';

export interface DateEditorControlProps {
    label?: string;
    help?: string;
    value: string;
    warning?: string;
    edited: boolean;
    onChange: (value: string) => void;
    onShowContentHelp?: () => void;
    onHideContentHelp?: () => void;
}

const DateEditorControl:
React.FC<DateEditorControlProps> = (props: DateEditorControlProps):
React.ReactElement => {
  const {
    label, value, warning, onChange, edited, help,
    onShowContentHelp, onHideContentHelp,
  } = props;

  const [
    userInputValue,
    currentWarning,
    setUserInputValue,
    onBlurValue,
  ] = useDelayedUserInput(value, warning, onChange);

  const currentValue = userInputValue ?? value;
  const classNameEditedText = (edited !== undefined && edited === true) ? 'textfield__input--edited' : '';

  return (
    <ParameterControl
      label={label}
    >
      <div>
        <div
          className="input-field"
          onFocus={() => onShowContentHelp?.()}
          onBlur={() => {
            onHideContentHelp?.();
            onBlurValue(currentValue);
          }}
        >
          <DateTime // react-datetime
            value={currentValue as string}
            initialViewDate={new Date()}
            className=""
            onChange={(val: Moment | string) => {
              const strVal = typeof val === 'string' ? val
                : (val as Moment).toISOString().substring(0, 10);

              const changeEvent = { target: { value: strVal } };
              setUserInputValue((changeEvent as React.ChangeEvent<HTMLInputElement>).target.value);
            }}
            timeFormat={false}
            inputProps={({ className: `textfield__input ${classNameEditedText}`, 'aria-label': 'react-datetime-control', role: 'input' })}
            dateFormat="YYYY-MM-DD"
            locale={getAppUiLanguageCode()}
            closeOnSelect
            utc
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="textfield__label">{}</label>
          <span className="helper-text" data-error={currentWarning} />
        </div>
        <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
          <div className="input-field-control__warning-text">{currentWarning}</div>
        </div>
      </div>
    </ParameterControl>
  );
};

export default DateEditorControl;
