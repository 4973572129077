/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  typeDeviceInstanceActiveDeviceAction,
  ACTIVE_DEVICE__SET_EXECUTION_STATUS,
  ACTIVE_DEVICE__UPDATE_DEVICE_TAG,
  ACTIVE_DEVICE__SET_ACTIVE_DEVICE,
  SetActiveDevicePayload,
} from './types';
import { ExecutionState } from '../../../common';

// TODO: Remove action
export const setActiveDevice = (targetInstance: string, data: SetActiveDevicePayload)
  : typeDeviceInstanceActiveDeviceAction => ({
  type: 'DEVICE_INSTANCE__ACTIVE_DEVICE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: ACTIVE_DEVICE__SET_ACTIVE_DEVICE,
      payload: {
        targetInstance,
        data,
      },
    },
  },
});

export const updateActiveDeviceTag = (targetInstance: string, tag: string)
  : typeDeviceInstanceActiveDeviceAction => ({
  type: 'DEVICE_INSTANCE__ACTIVE_DEVICE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: ACTIVE_DEVICE__UPDATE_DEVICE_TAG,
      payload: {
        targetInstance,
        data: tag,
      },
    },
  },
});

export const setActiveDeviceExecutionStatus = (targetInstance: string, status: ExecutionState)
  : typeDeviceInstanceActiveDeviceAction => ({
  type: 'DEVICE_INSTANCE__ACTIVE_DEVICE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: ACTIVE_DEVICE__SET_EXECUTION_STATUS,
      payload: {
        targetInstance,
        data: status,
      },
    },
  },
});
