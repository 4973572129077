/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { ReduxControlProps } from '../ReduxControlProps';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { ComponentFactory } from '../ComponentFactory/ComponentFactory';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import withControlVisibility from '../hoc/withControlVisibility';

const DynVariableReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    onStateChanged,
    readonly,
    deviceInstanceId,
  } = props;
  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);

  if (descriptor === undefined) {
    return <UnsupportedControl text={`DynVariableReduxControl: undefined object ${identRef}`} />;
  }

  if (descriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="DynVariableReduxControl: Supported object type is ControlDescriptor only" />;
  }

  if (descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE) {
    return <UnsupportedControl text="DynVariableReduxControl: Supported ControlType is CTLDYNVARIABLE only" />;
  }

  // 'gauge' | 'verticalbar' | 'horizontalbar' | 'group'
  const control = `${DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE}-${descriptor.controlType.style ?? 'gauge'}`;
  const component = ComponentFactory({
    identRef,
    control,
    deviceInstanceId,
    key: `${control}-${identRef}`,
    onStateChanged,
    readonly,
  });

  return (
    <>
      {component}
    </>
  );
};

export default withControlVisibility(DynVariableReduxControl);
