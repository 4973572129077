/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { UpdateCustomStoragePayload, typeCustomStorageActionTypes } from '../types';
import {
  IoLinkCaparocChannel,
  IoLinkCaparocModule,
  IoLinkCaparocStation,
  IoLinkCaparocStationContent,
  IoLinkCaparocStationState,
  StationChannelCollection,
} from './types';

const initChannelProps = (index: number): IoLinkCaparocChannel => ({
  productName: `var_V_CB_Channel${index + 1}_11_TN_Channel_ProductName`,
  chNrWithinTheModule: `var_V_CB_Channel${index + 1}_10_TN_Channel_ChNrWithinTheModule`,
  moduleAddress: `var_V_CB_Channel${index + 1}_09_TN_Channel_ModuleAddress`,
  // maxCurrent: `var_V_CB_Channel${index + 1}_08_TN_Channel_MaxCurrent`,
  // minCurrent: `var_V_CB_Channel${index + 1}_07_TN_Channel_MinCurrent`,
  // errorCounter: `var_V_CB_Channel${index + 1}_06_TN_Channel_ErrorCounter`,
  // errorStatus: `var_V_CB_Channel${index + 1}_05_TN_Channel_ErrorStatus`,
  actualCurrent: `var_V_CB_Channel${index + 1}_04_TN_Channel_ActualCurrent`,
  localProgrammingLock: `var_V_CB_Channel${index + 1}_03_TN_Channel_LocalProgrammingLock`,
  nominalCurrent: `var_V_CB_Channel${index + 1}_02_TN_Channel_NominalCurrent`,
  status: `var_V_CB_Channel${index + 1}_01_TN_Channel_Status`,
  channelNo: index + 1,
});

const defaultStation: IoLinkCaparocStation = {
  channels: [...Array(20).keys()].reduce((acc, va, index) => ({
    ...acc,
    [`V_REC_Channel${index + 1}`]: initChannelProps(index),
  }), {} as StationChannelCollection),
  module: [],
};

export const initIoLinkCaparocStationState: IoLinkCaparocStationState = {
  station: defaultStation,
};

// eslint-disable-next-line max-len
const updateIoLinkCaparocStation = (state: IoLinkCaparocStationState, payload: UpdateCustomStoragePayload)
:IoLinkCaparocStationState => {
  let caparocModules: IoLinkCaparocModule[] = state.station.module;
  try {
    const caparocStation = payload.content as IoLinkCaparocStationContent;
    caparocModules = caparocStation.module;
  } catch (err: unknown) {
    caparocModules = state.station.module;
  }

  const module = caparocModules.map((mod) => {
    const stateModule = state.station.module.find((md) => md.addr === mod.addr);
    if (stateModule === undefined) {
      return mod;
    }

    if (mod.channel1 === stateModule.channel1
      && mod.channel2 === stateModule.channel2
      && mod.channel3 === stateModule.channel3
      && mod.channel4 === stateModule.channel4
      && mod.productName === stateModule.productName) return stateModule;

    return {
      ...stateModule,
      productName: mod.productName,
      channel1: mod.channel1,
      channel2: mod.channel2,
      channel3: mod.channel3,
      channel4: mod.channel4,
    };
  });

  return {
    ...state,
    station: {
      ...state.station,
      module,
    },
  };
};

const ioLinkCaparocStationReducer = (
  state = initIoLinkCaparocStationState,
  action: typeCustomStorageActionTypes,
): IoLinkCaparocStationState => {
  let newstate = state;
  switch (action.type) {
    case 'CUSTOM_STORAGE__UPDATE_ACTION':
      newstate = updateIoLinkCaparocStation(state, action.payload);
      break;
    case 'CUSTOM_STORAGE__CLEAN_ACTION':
      newstate = {
        ...state,
        station: defaultStation,
      };
      break;
    default:
      newstate = state;
  }
  return newstate;
};

export default ioLinkCaparocStationReducer;
