/* eslint-disable max-len */
import React from 'react';
import {
  gaugeHeight,
  gaugeWidth,
} from './common';
import SVGGaugeTextPoints, { SVGGaugeTextPoint } from './SVGGaugeTextPoints/SVGGaugeTextPoints';
import SVGGaugeSegments, { SVGGaugeSegment } from './SVGGaugeSegments/SVGGaugeSegments';
import SVGGaugeValue from './SVGGaugeValue/SVGGaugeValue';
import SVGGaugeValueLimits from './SVGGaugeValueLimits/SVGGaugeValueLimits';
import SVGCircleButton from './SVGCircleButton/SVGCircleButton';
import SVGGaugeTitle from './SVGGaugeTitle/SVGGaugeTitle';

export interface SVGGaugeProps {
  title: string;
  subtitle: string;
  currentValue: number,
  currentUnit: string;
  gaugePoints: SVGGaugeTextPoint[],
  gaugeSegments: SVGGaugeSegment[],
  showDragValues: boolean;
  minDragValue: number,
  maxDragValue: number,
  displayFormat?: string,
  // eslint-disable-next-line no-unused-vars
  onClick: (posx?: number, posy?: number) => void
}

const calculateGaugeMinValue = (gaugeSegments: SVGGaugeSegment[])
  : number => (gaugeSegments.length === 0 ? 0 : gaugeSegments[0].minValue);

const calculateGaugeMaxValue = (gaugeSegments: SVGGaugeSegment[])
  : number => (gaugeSegments.length === 0 ? 10 : gaugeSegments[gaugeSegments.length - 1].maxValue);

const SVGGauge: React.FC<SVGGaugeProps> = (props: SVGGaugeProps)
: React.ReactElement => {
  const {
    currentValue, currentUnit, gaugePoints, gaugeSegments, onClick,
    showDragValues, minDragValue, maxDragValue,
    title, subtitle, displayFormat,
  } = props;

  const gaugeMinValue = calculateGaugeMinValue(gaugeSegments);
  const gaugeMaxValue = calculateGaugeMaxValue(gaugeSegments);

  const dragValues = showDragValues
    ? (
      <SVGGaugeValueLimits
        displayFormat={displayFormat}
        gaugeMinValue={gaugeMinValue}
        gaugeMaxValue={gaugeMaxValue}
        currentUnit={currentUnit}
        minValue={minDragValue}
        maxValue={maxDragValue}
      />
    )
    : (null);

  return (
    <svg className="svg-gauge" fontFamily="IBM Plex Sans" width="100%" height="100%" viewBox={`0 0 ${gaugeWidth} ${gaugeHeight}`} preserveAspectRatio="xMidYMid">
      <SVGGaugeTitle title={title} subtitle={subtitle} />
      <SVGCircleButton onClick={onClick} />
      <SVGGaugeSegments gaugeMinValue={gaugeMinValue} gaugeMaxValue={gaugeMaxValue} gaugeSegments={gaugeSegments} />
      <SVGGaugeTextPoints gaugeMinValue={gaugeMinValue} gaugeMaxValue={gaugeMaxValue} gaugePoints={gaugePoints} />
      <SVGGaugeValue gaugeMinValue={gaugeMinValue} gaugeMaxValue={gaugeMaxValue} currentValue={currentValue} currentUnit={currentUnit} displayFormat={displayFormat ?? '%.2f'} />
      {dragValues}
    </svg>
  );
};

export default SVGGauge;
