/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { ContactronModule } from '../../../../../../store/wizards/contactronStation';
import { sPortWriteParameter } from '../../../../../../store/deviceInstances/middleware/deviceMethod';
import { VariableStringEditorControl } from '../../../../../../components/ParameterEditor/VariableEditorControl';
import { useTypedSelector } from '../../../../../../store';
import './TableRowModule.scss';

export interface TableRowModuleProps {
  module: ContactronModule;
  stationInstanceId: string;
}

const TableRowModule: React.FC<TableRowModuleProps> = (props: TableRowModuleProps)
: React.ReactElement => {
  const { module, stationInstanceId } = props;
  const dispatch = useDispatch();

  const tagVariable = `DEVICE${module.instance.address}_IFSM_ADDR_AKZ`;
  const moduleTagDescriptor = useTypedSelector((state) => {
    const tagDescriptor = state.deviceInstances.instances[stationInstanceId]?.deviceDataset.user.descriptors[tagVariable];
    return tagDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor
      ? undefined
      : tagDescriptor;
  });
  const moduleTagValue = useTypedSelector((state) => state.deviceInstances.instances[stationInstanceId]?.deviceDataset.user.values[tagVariable]);

  const control = moduleTagDescriptor === undefined
    ? (
      <div>
        Undefined station tag variable
        {' '}
        {tagVariable}
      </div>
    )
    : (
      <VariableStringEditorControl
        noLabel
        deviceInstanceId={stationInstanceId}
        statusDescriptor={moduleTagDescriptor}
        statusValue={moduleTagValue}
        onShowContentHelp={() => {
          dispatch(sPortWriteParameter({
            actionId: 'contactron-naming-table--device-ident',
            memoryAddress: 0x7800,
            data: new Uint8Array([0x00, 0x01]),
            targetInstance: stationInstanceId,
            slaveAddress: module.instance.address,
          }));
        }}
      />
    );

  return (
    <tr>
      <td className="table-row-module__address">
        <div>{module.instance.address}</div>
      </td>
      <td className="table-row-module__name">
        <div>{module.instance.name}</div>
      </td>
      <td className="table-row-module__control">
        <div>{control}</div>
      </td>
    </tr>
  );
};

export default TableRowModule;
