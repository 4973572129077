/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Services } from '@gpt/commons';
import { DeviceInstanceAction } from '../../common';

export const METHOD_STAGE_EXECUTION__INIT = 'METHOD_STAGE_EXECUTION__INIT';
export const METHOD_STAGE_EXECUTION__PASSWORD = 'METHOD_STAGE_EXECUTION__PASSWORD';
export const METHOD_STAGE_EXECUTION__ACCEPT_DATA = 'METHOD_STAGE_EXECUTION__ACCEPT_DATA';
export const METHOD_STAGE_EXECUTION__ABORT = 'METHOD_STAGE_EXECUTION__ABORT';

/* ************************************************************************************ */
/* INTERFACE                                                                            */
/* ************************************************************************************ */
export interface MethodStageActionPayload {
  methodIdent: string;
  header?: Services.DeviceModel.MethodHeader;
  methodStatusRef?: string;
}

/* ************************************************************************************ */
/* ACTION: ExecuteStageInitAction                                                       */
/* ************************************************************************************ */
export interface StageInitPayload extends MethodStageActionPayload {
  values: Services.DeviceModel.StatusValueRef[];
}

export type ExecuteStageInitAction = DeviceInstanceAction<typeof METHOD_STAGE_EXECUTION__INIT, StageInitPayload>

/* ************************************************************************************ */
/* ACTION: ExecuteStagePasswordAction                                                       */
/* ************************************************************************************ */
export interface StagePasswordPayload extends MethodStageActionPayload {
  password: string;
}

export type ExecuteStagePasswordAction = DeviceInstanceAction<typeof METHOD_STAGE_EXECUTION__PASSWORD, StagePasswordPayload>

/* ************************************************************************************ */
/* ACTION: ExecuteStageAcceptDataAction                                                       */
/* ************************************************************************************ */
export interface StageAcceptDataPayload extends MethodStageActionPayload {
  accept: boolean;
}

export type ExecuteStageAcceptDataAction = DeviceInstanceAction<typeof METHOD_STAGE_EXECUTION__ACCEPT_DATA, StageAcceptDataPayload>

/* ************************************************************************************ */
/* ACTION: ExecuteStageAbortAction                                                       */
/* ************************************************************************************ */
export type AbortMethodPayload = MethodStageActionPayload

export type AbortMethodExecutionAction = DeviceInstanceAction<typeof METHOD_STAGE_EXECUTION__ABORT, AbortMethodPayload>

export type typeDeviceMethodStageMiddlewareActions = ExecuteStageInitAction
| ExecuteStagePasswordAction
| ExecuteStageAcceptDataAction
| AbortMethodExecutionAction;
