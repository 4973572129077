/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  DeviceModelStatus,
  Services,
} from '@gpt/commons';
import { Action } from 'redux';
import { ExecutionState } from '../../../common';
import { DeviceInstanceActionPayload } from '../../common';

export const PREVIEW_DATASET__SET_EXECUTION_STATE = 'PREVIEW_DATASET__SET_EXECUTION_STATE';
export const PREVIEW_DATASET__SET_DATASET = 'PREVIEW_DATASET__SET_DATASET';
export const DEVICE_INSTANCE__PREVIEW_DATASET__ACTIONS = 'DEVICE_INSTANCE__PREVIEW_DATASET__ACTIONS';

export interface PreviewDatasetValues {
  [key: string]: DeviceModelStatus.StatusValue;
}

export interface PreviewDatasetDescriptors {
  [key: string]: DeviceModelStatus.DeviceModelDescriptor;
}

export interface PreviewDatasetHeader {
  datasetId: Services.DeviceModelServer.DatasetProviderService.DatasetIdent;
  caption: string;
  profileName: string;
  userComment: string;
  serialNumber?: string;
  saveDateTime: Date;
}

export interface PreviewDataset {
  header: PreviewDatasetHeader;
  descriptors: PreviewDatasetDescriptors;
  values: PreviewDatasetValues;
}

export interface PreviewDatasetState {
  state: ExecutionState;
  dataset?: PreviewDataset;
}

export interface SetPreviewDatasetExecutionStatusAction extends Action<typeof PREVIEW_DATASET__SET_EXECUTION_STATE> {
  payload: ExecutionState,
}

/* ************************************************************************************ */
/* ACTION: SetExternalDatasetAction                                                     */
/* ************************************************************************************ */
export interface SetPreviewDatasetAction extends Action<typeof PREVIEW_DATASET__SET_DATASET> {
  payload?: Services.DeviceModelServer.DatasetProviderService.ExternalDataset;
}

export type typePreviewDatasetAction = SetPreviewDatasetExecutionStatusAction
  | SetPreviewDatasetAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceInstanceIoLinkTrio3EventMonitorAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstancePreviewDatasetPayload extends DeviceInstanceActionPayload {
  action: typePreviewDatasetAction;
}

export interface typeDeviceInstancePreviewDatasetAction extends Action<typeof DEVICE_INSTANCE__PREVIEW_DATASET__ACTIONS> {
  payload: typeDeviceInstancePreviewDatasetPayload;
}
