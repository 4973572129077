/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import './TableColumnHeader.css';

export interface TableColumnHeaderProps {
    header1: string,
    header2: string,
}

export const TableColumnHeader:React.FC<TableColumnHeaderProps> = (props: TableColumnHeaderProps): React.ReactElement => {
  const { header1, header2 } = props;
  const { t } = useTranslation();

  return (
    <div className="tableColumnHeader-container" data-testid="table-column-header">
      <div className="tableColumnHeader-container-splitter1" />
      <div className="tableColumnHeader-container-label">
        <div className="tableColumnHeader-container-label-unit">
          {t<string>(header1)}
        </div>
      </div>
      <div className="tableColumnHeader-container-splitter2" />
      <div className="tableColumnHeader-container-label">
        <div className="tableColumnHeader-container-label-unit">
          {t<string>(header2)}
        </div>
      </div>
    </div>
  );
};
