/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../store';
import { setLinearizationMaxRegressionError } from '../../../../store/linearizationDataset/actions';
import InputBoxControl from '../../../Basic/InputBoxControl/InputBoxControl';
import './MaxRegression.css';

export const MaxRegression:React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const maxRegError = useTypedSelector((state) => state.linearizationDataset.maxRegressionError);

  return (
    <div className="maxRegression-container">
      <div className="maxRegression-top-label">
        {t<string>('LINTABLE__TABLE_VIEW__MAX_REGRESSION_ERROR__LABEL')}
      </div>
      <div className="maxRegression-element-label">
        <InputBoxControl
          type="number"
          displayFormat={maxRegError.displayFormat}
          value={maxRegError.value}
          onChange={(value) => {
            const parsedValue = parseFloat(value);
            if (!Number.isNaN(parsedValue)) {
              dispatch(setLinearizationMaxRegressionError(parsedValue));
            }
          }}
          minValue={0.001}
          maxValue={0.5}
          step={0.001}
        />
      </div>
    </div>
  );
};

export default MaxRegression;
