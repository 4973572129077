/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store';
import { LoadingControl } from '../../../components/LoadingControl';
import { DatasetContext } from '../../../views/DatasetContext';
import { deviceTimelineValuesSelector } from '../../../store/deviceInstances/store/timelineService/selectors';
import { ApexTrendData, ApexTrendSerieData } from '../../../components/ApexTrendControl/types';
import ApexTrendControlContainer from '../../../components/ApexTrendControl/ApexTrendControlContainer/ApexTrendControlContainer';
import useTimelineVariables, { TrendViewItem } from './hooks/useTimelineVariables';
import './TrendViewDescriptionControl.css';

export interface TrendViewDescriptionProps {
  trendId: string;
  trendVars: TrendViewItem[];
  deviceInstanceId: string;
}

const TrendViewDescriptionControl: React.FC<TrendViewDescriptionProps> = (props: TrendViewDescriptionProps)
: React.ReactElement => {
  const { trendVars, trendId, deviceInstanceId } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const timelineVars = useTimelineVariables(deviceInstanceId, targetDataset, trendVars);

  const timeline = useTypedSelector((state) => deviceTimelineValuesSelector(state.deviceInstances, deviceInstanceId));
  if (timeline === undefined || timeline.length === 0) {
    return <LoadingControl title={t<string>('WAITING_TREND_DATA')} />;
  }

  const trendData: ApexTrendData[] = timelineVars
    .map((element) => {
      let series: ApexTrendSerieData[] = [{
        x: new Date().getTime(),
        y: 0,
      }];

      const xtimeline = timeline.find((actTrendValue) => actTrendValue?.identRef === element.identRef);
      if (xtimeline !== undefined) {
        series = xtimeline.timeline.reduce((acc, value) => {
          if (value === undefined) {
            return acc;
          }
          let yvalue = 0;
          if (element.range === undefined) {
            yvalue = value.value;
          } else if (element.range.maxValue !== undefined && value.value > element.range.maxValue) {
            yvalue = element.range.maxValue;
          } else if (element.range.minValue !== undefined && value.value < element.range.minValue) {
            yvalue = element.range.minValue;
          } else {
            yvalue = value.value;
          }
          return [
            ...acc,
            {
              x: Date.parse(value.timestamp),
              y: yvalue,
            }];
        }, [] as ApexTrendSerieData[]);
      }

      return {
        id: element.identRef,
        title: t<string>(element.label),
        color: element.color,
        unit: '',
        range: element.range,
        series,
      };
    });

  return (
    <div className="rootTrendViewControl" data-testid="trend-view-description-control">
      <ApexTrendControlContainer
        id={trendId}
        trendData={trendData}
      />
    </div>
  );
};

export default TrendViewDescriptionControl;
