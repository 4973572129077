/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';
import deviceModelStatusDescriptorCollection from './deviceModelStatusDescriptor';

const deviceModelControlDescriptor: DeviceModelStatus.DeviceModelDescriptorCollection = {
  ctrlIFSM_ADDR_IO_R_SENSOR_VOLTAGE: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlIFSM_ADDR_IO_R_SENSOR_VOLTAGE',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE,
      variable: 'IFSM_ADDR_IO_R_SENSOR_VOLTAGE',
      style: 'gauge',
      CMIN: 0,
      CMAX: 2020,
      CSUBTITLE: 'PT1000',
    },
  },
  ctrlIFSM_ADDR_IO_R_OUTPUT_CALC: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlIFSM_ADDR_IO_R_OUTPUT_CALC',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE,
      variable: 'IFSM_ADDR_IO_R_OUTPUT_CALC',
      style: 'gauge',
      CMIN: 0,
      CMAX: 20,
    },
  },
  ctrlTrendView: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlTrendView',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLDYNTREND,
      DYNVALUE: [
        {
          variable: deviceModelStatusDescriptorCollection.IFSM_ADDR_IO_R_SENSOR_VOLTAGE.identRef,
          color: 'medium-blue',
        },
        {
          variable: deviceModelStatusDescriptorCollection.IFSM_ADDR_IO_R_OUTPUT_CALC.identRef,
          color: 'yellow',
        },
      ],
    },
  },
  ctrlLedDEVICE_POWER_STATUS: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlLedDEVICE_POWER_STATUS',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLDYNLEDFLOAT,
      variable: 'DEVICE_POWER_STATUS',
      defcolor: 'green',
      BEHAVIOUR: [],
    },
  },
  ctrlLedDEVICE_STATUS: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlLedDEVICE_STATUS',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLDYNLEDFLOAT,
      variable: 'DEVICE_STATUS',
      defcolor: 'green',
      BEHAVIOUR: [],
    },
  },
  ctrlPARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__RANGE: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlPARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__RANGE',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLRANGE,
      title: {
        label: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__RANGE__LABEL',
        help: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__RANGE__HELP',
        description: '',
      },
      rangeMin: -1,
      rangeMax: 21,
      rangeStart: deviceModelStatusDescriptorCollection.analog_input_range_start.identRef,
      rangeEnd: deviceModelStatusDescriptorCollection.analog_input_range_end.identRef,
    },
  },
  ctrlPARAMETERS__OUTPUTCONFIGURATION__PICTURE: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlPARAMETERS__OUTPUTCONFIGURATION__PICTURE',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLPICTURE,
      picture: 'PARAMETERS__OUTPUTCONFIGURATION__PICTURE',
    },
  },
  ctrlPARAMETERS__FAULT_MONITORING_CONTACT__PICTURE: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlPARAMETERS__FAULT_MONITORING_CONTACT__PICTURE',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLPICTURE,
      picture: 'PARAMETERS__FAULT_MONITORING_CONTACT__PICTURE',
    },
  },
  ctrlPARAMETERS__INPUTCONFIGURATION__PICTURE: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlPARAMETERS__INPUTCONFIGURATION__PICTURE',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLPICTURE,
      picture: 'PARAMETERS__INPUTCONFIGURATION__PICTURE',
    },
  },
  ctrlCURRENTSTATUSLIST: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlCURRENTSTATUSLIST',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLCURRENTSTATUSLIST,
      label: 'Current Status List',
      statusList: [{
        control: 'ctrlCURRENTSTATUS',
      }],
    },
  },
  ctrlPAPER: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlPAPER',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLPAPER,
      menu: 'cockpitMenu',
    },
  },
  ctrlCURRENTSTATUS: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlCURRENTSTATUS',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLCURRENTSTATUS,
      variable: 'IFSM_ADDR_IO_R_SENSOR_CURRENT',
      title: 'Kanal 1',
      color: 'medium-blue',
    },
  },
  ctrlDEVICESTATUS: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlDEVICESTATUS',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLDEVICESTATUS,
      variable: 'IFSM_ADDR_IO_R_SENSOR_CURRENT',
      label: 'Power',
      BEHAVIOUR: [{
        flag: 15.76,
        text: 'ON',
        message: 'Message',
        led: {
          fgcolor: 'green',
          bkcolor: 'white',
        },
      }],
      DEFAULT: {
        text: '',
        led: {
          fgcolor: 'red',
        },
      },
    },
  },
  ctrlDEVICEVALUEPICKER: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlDEVICEVALUEPICKER',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLDEVICEVALUEPICKER,
      minVar: 'IFSM_ADDR_IO_R_SENSOR_CURRENT',
      maxVar: 'IFSM_ADDR_IO_R_SENSOR_CURRENT',
      deviceVar: 'IFSM_ADDR_IO_R_SENSOR_CURRENT',
    },
  },
  PARAMETERS__LINEARIZATION_CONTROL: {
    identRef: 'PARAMETERS__LINEARIZATION_CONTROL',
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLLINEARIZATION,
      label: 'locLINEARIZATION_TABLE__LABEL',
      buttonLabel: 'locLINEARIZATION_TABLE__LABEL',
      description: '01_04_0F_14_0020:locLINEARIZATION_TABLE__DESCRIPTION',
      help: '01_04_0F_14_0020:locLINEARIZATION_TABLE__HELP',
      minDeviation: 'LINEARIZATION_TABLE__MINDEVIATION',
      numberOfPoints: 'LINEARIZATION_USER_POINT_NUM',
      splineData: 'LINEARIZATION_TABLE__SPLINEBLOBDATA',
      userLinData: 'LINEARIZATION_TABLE',
      coldJunctionComp0: 'LINEARIZATION_TABLE__COLD0',
      coldJunctionComp80: 'LINEARIZATION_TABLE__COLD80',
      coldLineEquationB: 'LINEARIZATION_TABLE__COLDLINEEQB',
      coldLineEquationM: 'LINEARIZATION_TABLE__COLDLINEEQM',
      userCharMaxX: 'LINEARIZATION_TABLE__USER_MAX_X',
      userCharMinX: 'LINEARIZATION_TABLE__USER_MIN_X',
    },
  },
  ctrlRANGEWITHSPAN: {
    identRef: 'ctrlRANGEWITHSPAN',
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN,
      rangeStart: 'analog_output_range_start',
      rangeEnd: 'analog_output_range_end',
      spanVar: 'analog_output_range_span',
    },
  },
  ctrlPARAMETERS__DIPSWITCH: {
    identRef: 'ctrlPARAMETERS__DIPSWITCH',
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLDIPSWITCHBLOCKOFFLINE,
      variable: 'VARIABLE_DIP_SWITCH__S1',
      length: 8,
    },
  },
  ctrlCHANNELSTATUS: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlCHANNELSTATUS',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLCHANNELSTATUS,
      variable: 'IFSM_ADDR_IO_R_SENSOR_RESIST',
      caption: 'Kanal 1',
      label: 'I ist',
      NOMINALVALUE: {
        variable: 'DEVICE_POWER_STATUS',
        label: 'I nom',
        defcolor: 'red',
        CASE: [
          {
            value: 1,
            defcolor: 'red',
            BEHAVIOUR: [{
              min: 0,
              max: 0.7,
              color: 'green',
            },
            {
              min: 0.7,
              max: 1,
              color: 'yellow',
            },
            ],
          },
        ],
      },
    },
  },
  ctrlDEVICESTATUSVARIABLEICON: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlCHANNELSTATUS',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLDEVICESTATUSVARIABLEICON,
      variable: 'IFSM_ADDR_IO_R_SENSOR_CURRENT',
      label: 'Local programming lock',
      BEHAVIOUR: [{
        flag: 15.76,
        icon: 'unlock',
      }],
      DEFAULT: {
        icon: 'lock',
      },
    },
  },
  ctrlDEVICESTATUSVARIABLEVALUE: {
    type: DeviceModelStatus.StatusType.ControlDescriptor,
    identRef: 'ctrlCHANNELSTATUS',
    controlType: {
      type: DeviceModelStatus.UI.ControlType.CTLDEVICESTATUSVARIABLEVALUE,
      variable: 'IFSM_ADDR_IO_R_SENSOR_CURRENT',
      label: 'Rated current',
    },
  },
};

export default deviceModelControlDescriptor;
