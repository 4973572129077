/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReduxControlProps } from '../ReduxControlProps';
import withControlVisibility from '../hoc/withControlVisibility';
import SVGGaugeControl from '../../components/GaugeControl/SVGGaugeControl/SVGGaugeControl';
import { useTypedSelector } from '../../store';
import {
  analogGaugeControlTypeSelector,
  analogGaugeDataSelector,
  analogGaugeValueDescriptorSelector,
  analogGaugeValueSelector,
  calculateAnalogGaugePoints,
  calculateAnalogGaugeSegment,
} from './analogGaugeValueSelector';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { deviceControlStateCollectionSelector } from '../../store/deviceInstances/store/controlStateCollection/selectors';
import { GaugeControlState, setGaugeIndicator } from '../../store/deviceInstances/store/controlStateCollection';
import { UnsupportedControl } from '../../components/UnsupportedControl';

const AnalogGaugeReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const datasetType = DatasetType.device;
  const analogData = useTypedSelector((state) => analogGaugeDataSelector(state.deviceInstances, deviceInstanceId, datasetType, identRef));
  const currentValueDescriptor = useTypedSelector((state) => analogGaugeValueDescriptorSelector(state.deviceInstances, deviceInstanceId, datasetType, identRef));
  const currentValue = useTypedSelector((state) => analogGaugeValueSelector(state.deviceInstances, deviceInstanceId, datasetType, identRef));

  const gaugeSegments = calculateAnalogGaugeSegment(analogData);
  const gaugePoints = calculateAnalogGaugePoints(gaugeSegments, analogData?.displayFormat ?? '%.2f');

  const showDragIndicatorState = useTypedSelector((state) => {
    const control = deviceControlStateCollectionSelector(state.deviceInstances, deviceInstanceId)?.[identRef];
    return control === undefined ? undefined : control as GaugeControlState;
  });
  const showIndicator = showDragIndicatorState?.showIndicator ?? false;

  const controlType = useTypedSelector((state) => analogGaugeControlTypeSelector(state.deviceInstances, deviceInstanceId, datasetType, identRef));
  if (controlType === undefined) {
    return <UnsupportedControl text={`AnalogGaugeReduxControl [${identRef}]: undefined reference`} />;
  }

  return (
    <SVGGaugeControl
      title={t(analogData?.title ?? '')}
      subtitle={t(analogData?.subtitle ?? '')}
      currentValue={currentValue?.value}
      currentUnit={t(currentValueDescriptor?.unit ?? '')}
      gaugePoints={gaugePoints}
      gaugeSegments={gaugeSegments}
      displayFormat={analogData?.displayFormat ?? '%.2f'}
      showDragIndicator={showIndicator}
      setShowDragIndicator={(value) => {
        dispatch(setGaugeIndicator(deviceInstanceId, {
          ident: identRef,
          indicator: value,
        }));
      }}
    />
  );
};

export default withControlVisibility(AnalogGaugeReduxControl);
