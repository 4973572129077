/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  Middleware, Dispatch, MiddlewareAPI,
} from 'redux';
import { WebDevice } from '@gpt/commons';
import { v4 as uuidv4 } from 'uuid';
import { IWebWorkerDeviceManager } from '../../../../../services/WebWorkerDevice/WebWorkerDeviceManager';
import { DatasetType } from '../../../store/deviceDataset/types';
import { TRIO3_CHANNEL_SWITCH__SWITCH_CHANNEL, Trio3ChannelSwitchState, typeIoLinkTrio3SwitchChannelMiddlewareActions } from './types';
import { trio3SwitchChannelSetState } from '../../../store/ioLinkTrio3ChannelSwitch/actions';
import { deviceInstancesStoreSelector } from '../../../../reduxStoreSelector';

const createTrio3SwitchChannelMethod = (deviceInstanceId: string, channel: number, writeValue: number)
: WebDevice.WebDeviceExecuteMethodRequest => ({
  kind: 'WEBDEVICE__EXECUTE_METHOD__REQUEST',
  header: {
    index: '4224',
    subindex: `${channel + 10}`,
    value: `${writeValue}`,
  },
  methodIdent: 'IoLinkWriteValue',
  deviceInstanceId,
  requestId: uuidv4(),
  request: {
    type: 'WEBDEVICE__METHOD_EXECUTE__INIT',
    data: [],
  },
});

const ioLinkTrio3ChannelSwitchMiddleware = (webWorkerDeviceManager: IWebWorkerDeviceManager)
  : Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeIoLinkTrio3SwitchChannelMiddlewareActions>(action: A): Promise<A> => {
  switch (action.type) {
    case TRIO3_CHANNEL_SWITCH__SWITCH_CHANNEL: {
      const { payload } = action;
      const {
        channel, ident, targetInstance, writeValue,
      } = payload;

      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const webWorkerInstance = deviceInstances.instances[targetInstance]?.activeDevice.modelInstance?.webWorkerInstanceRef;
      if (webWorkerInstance === undefined) {
        break;
      }

      const webWorkerDevice = webWorkerDeviceManager.get(webWorkerInstance);
      if (webWorkerDevice === undefined) {
        break;
      }

      api.dispatch(trio3SwitchChannelSetState(targetInstance, {
        targetInstance, ident, channel, state: Trio3ChannelSwitchState.Busy,
      }));

      // Subindex : 1 -> 11, 2 -> 12 ... 8 -> 18
      const methodRequest = createTrio3SwitchChannelMethod(targetInstance, channel, writeValue);
      await webWorkerDevice.get(DatasetType.device).executeMethod(methodRequest);

      api.dispatch(trio3SwitchChannelSetState(targetInstance, {
        targetInstance, ident, channel, state: Trio3ChannelSwitchState.Done,
      }));
    }
      break;
    default:
  }
  const result = next(action);
  return result;
};

export default ioLinkTrio3ChannelSwitchMiddleware;
