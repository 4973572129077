/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  typeDeviceInstanceStandardViewAction,
  link,
  ApplicationId,
  HeaderMessage,
} from './types';

export const setDisplayedHelp = (
  targetInstance: string,
  help: Array<link>,
): typeDeviceInstanceStandardViewAction => ({
  type: 'DEVICE_INSTANCE__STANDARD_VIEW__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'STANDARD_VIEW__SET_DISPLAYED_HELP',
      payload: help,
    },
  },
});

export const setApplicationId = (
  targetInstance: string,
  appId: ApplicationId,
): typeDeviceInstanceStandardViewAction => ({
  type: 'DEVICE_INSTANCE__STANDARD_VIEW__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'STANDARD_VIEW__SET_APPLICATION_ID',
      payload: appId,
    },
  },
});

export const showHeaderMessage = (
  targetInstance: string,
  headerMessage: HeaderMessage,
): typeDeviceInstanceStandardViewAction => ({
  type: 'DEVICE_INSTANCE__STANDARD_VIEW__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'STANDARD_VIEW__SHOW_MESSAGE',
      payload: headerMessage,
    },
  },
});

export const hideHeaaderMessage = (
  targetInstance: string,
): typeDeviceInstanceStandardViewAction => ({
  type: 'DEVICE_INSTANCE__STANDARD_VIEW__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'STANDARD_VIEW__HIDE_MESSAGE',
    },
  },
});
