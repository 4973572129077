/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTypedSelector } from '../../store';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetState, DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import { DatasetContext } from '../../views/DatasetContext';
import { EventMonitor } from '../EventMonitor/EventMonitor/EventMonitor';
import { EventMonitorListItem } from '../EventMonitor/EventMonitorList/EventMonitorList';
import { ReduxControlProps } from '../ReduxControlProps';
import withControlVisibility from '../hoc/withControlVisibility';
import { DeviceStatusEventMonitorStateSelector } from '../../store/deviceInstances/store/DeviceStatusEventMonitor/selector';
import { DeviceStatusEventMonitorEventMode } from '../../store/deviceInstances/store/DeviceStatusEventMonitor/types';

const selectControlType = (identRef: IdentRef, dataset?: DatasetState)
: DeviceModelStatus.UI.DeviceStatusMonitorType | undefined => {
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLDEVICESTATUSMONITOR) {
    return undefined;
  }
  return controlType;
};

const selectControlEvents = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: string,
): DeviceModelStatus.UI.DeviceStatusMonitorEvents => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return {};
  }
  return controlType.events;
};

// eslint-disable-next-line max-len
const DeviceStatusEventMonitorControl: React.FC<ReduxControlProps> = (props: ReduxControlProps): React.ReactElement => {
  const {
    identRef, deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const deviceMonitorEvents = useTypedSelector(
    (state) => selectControlEvents(state.deviceInstances, deviceInstanceId, targetDataset, identRef),
  ) ?? [];

  // Create event list for Event Monitor Control
  const deviceMonitorEventList = useTypedSelector(
    (state) => DeviceStatusEventMonitorStateSelector(state.deviceInstances, deviceInstanceId)?.eventList,
  ) ?? [];

  const eventList: EventMonitorListItem[] = deviceMonitorEventList.map((ev) => {
    const evProp = deviceMonitorEvents[`ev${ev.code}`];
    const eventTimestamp = ev.timestamp;

    let eventMode = 'appears';
    switch (ev.mode) {
      case DeviceStatusEventMonitorEventMode.Appears:
        eventMode = 'appears';
        break;
      case DeviceStatusEventMonitorEventMode.Disappears:
        eventMode = 'disappears';
        break;
      default:
        eventMode = '';
    }

    return {
      eventId: ev.id,
      eventMessage: evProp?.eventLabel === undefined ? `Code ${ev.code}: unknown event` : t(evProp.eventLabel),
      eventMode,
      eventType: evProp?.eventType ?? 'error',
      eventTimestamp,
    };
  });

  return (
    <div className="w-100 h-100">
      <EventMonitor eventList={eventList}>
        <div>{t<string>('EVENTMONITOR_EVENT')}</div>
        <div>{t<string>('EVENTMONITOR_TYPE')}</div>
        <div>{t<string>('EVENTMONITOR_TIMESTAMP')}</div>
      </EventMonitor>
    </div>
  );
};

export default withControlVisibility(DeviceStatusEventMonitorControl);
