/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GetDisplayFormat, isInRangeInterval } from '../../helpers/functions';
import InputBoxControl from '../Basic/InputBoxControl/InputBoxControl';
import { UnsupportedControl } from '../UnsupportedControl';
import DeviceValuePicker from './DeviceValuePicker/DeviceValuePicker';
import { updateDeviceDatasetValues } from '../../store/deviceInstances/store/deviceDataset';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { writeActiveDeviceVariableValues } from '../../store';

export interface DeviceValuePickerValueType {
  statusDescriptor: DeviceModelStatus.StatusDescriptor
  datasetValue: DeviceModelStatus.StatusValue
}

export interface DeviceValuePickerControlProps {
  deviceInstanceId: string;
  minVariable: DeviceValuePickerValueType,
  maxVariable: DeviceValuePickerValueType,
  deviceVariable: DeviceValuePickerValueType,
}

const DeviceValuePickerControl: React.FC<DeviceValuePickerControlProps> = (
  props: DeviceValuePickerControlProps,
): React.ReactElement => {
  const {
    minVariable, maxVariable, deviceVariable, deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deviceValue = deviceVariable.datasetValue.value !== undefined
    ? deviceVariable.datasetValue.value as number : 0;

  const writeValueToDeviceModel = (value: number, datasetValue: DeviceModelStatus.StatusValue): void => {
    dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
      identRef: datasetValue.identRef,
      value,
      backupValue: datasetValue.backupValue ?? datasetValue.value,
    }]));
  };

  const onInputNumberValueChange = (
    value: string,
    datasetValue: DeviceModelStatus.StatusValue,
    type: DeviceModelStatus.StatusDescriptorValueType,
  ): void => {
    // Check if value valid according to the variable type
    let parseValue = 0;
    parseValue = type === DeviceModelStatus.StatusDescriptorValueType.FLOAT
      ? parseFloat(value) : parseInt(value, 10);

    if (Number.isNaN(parseValue)) {
      dispatch(updateDeviceDatasetValues(deviceInstanceId, {
        targetDataset: DatasetType.user,
        values: [{
          identRef: datasetValue.identRef,
          value,
          backupValue: datasetValue.backupValue ?? datasetValue.value,
        }],
      }));
    } else {
      dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
        identRef: datasetValue.identRef,
        value: parseValue,
        backupValue: datasetValue.backupValue ?? datasetValue.value,
      }]));
    }
  };

  const onLeftInputNumberChange = (newValue: string) => {
    onInputNumberValueChange(
      newValue,
      minVariable.datasetValue,
      minVariable.statusDescriptor.valueType.type,
    );
  };

  const onRightInputNumberChange = (newValue: string) => {
    onInputNumberValueChange(
      newValue,
      maxVariable.datasetValue,
      maxVariable.statusDescriptor.valueType.type,
    );
  };

  const leftButtonClick = () => {
    writeValueToDeviceModel(deviceValue, minVariable.datasetValue);
  };

  const rightButtonClick = () => {
    writeValueToDeviceModel(deviceValue, maxVariable.datasetValue);
  };

  const createControl = (
    datasetValue: DeviceModelStatus.StatusValue,
    statusDescriptor: DeviceModelStatus.StatusDescriptor,
    onInputChange: (value: string) => void,
  ) => {
    if (statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT
    && statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER
    && statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER) {
      return <UnsupportedControl text="DeviceValuePickerControl: Support FLOAT, INTEGER and UNSIGNED_INTEGER only" />;
    }
    let message: string | undefined;
    const { range } = statusDescriptor.valueType;
    // check if value is an invalid number
    if (Number.isNaN(datasetValue.value)) {
      message = t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT');
    } else if (range !== undefined) {
      // variable type has a range[] definition → check is value is in any range interval
      const isValueInAnyInterval = isInRangeInterval(Number(datasetValue.value), range);
      // check if value exceeds total range bounds of variable
      if (!isValueInAnyInterval) {
        // value is in total range bounds but out of the range intervals of the variable
        const intervals = Array.from(range.regions).map((r) => `${r.minValue ?? ''}…${r.maxValue ?? ''}`);
        message = t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__INTERVALS', {
          INTERVAL: intervals.join(', '),
        });
      }
    }

    return (
      <InputBoxControl
        type="number"
        displayFormat={statusDescriptor.valueType.displayFormat ?? '%.2f'}
        value={datasetValue.value}
        step={statusDescriptor.valueType.stepSize}
        onChange={(value) => onInputChange(value)}
        warning={message}
      />
    );
  };

  const displayFormat = GetDisplayFormat(deviceVariable.statusDescriptor);
  return (
    <DeviceValuePicker
      unit={deviceVariable.statusDescriptor.unit === undefined ? '' : t<string>(deviceVariable.statusDescriptor.unit)}
      onLeftButtonClick={(leftButtonClick)}
      onRightButtonClick={rightButtonClick}
      createLeftControl={() => createControl(minVariable.datasetValue, minVariable.statusDescriptor, onLeftInputNumberChange)}
      createRightControl={() => createControl(maxVariable.datasetValue, maxVariable.statusDescriptor, onRightInputNumberChange)}
      createMiddleControl={() => (
        <InputBoxControl
          type="number"
          displayFormat={displayFormat}
          value={deviceValue}
          onChange={() => 0}
          readonly
        />
      )}
    />
  );
};

export default DeviceValuePickerControl;
