/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useContextDatasetStatusDescriptor, useContextDatasetStatusValue } from '../../../../hooks/useContextDataset';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import VariableFloatEditorControl from '../../../../components/ParameterEditor/VariableEditorControl/VariableFloatEditorControl/VariableFloatEditorControl';
import { StructureItemStateChangeCallback } from '../../../ReduxControlProps';
import useStatusValueStateUpdate from '../../hooks/useStatusValueStateUpdate';

export interface Quint4OutputBlockVCPProps {
  deviceInstanceId: string;
  procentVariableIdentRef: string;
  correspontVariableIdentRef: string;
  showContextMessageDef?: boolean;
  target?: DatasetType;
  onStateChanged: StructureItemStateChangeCallback;
}

const Quint4OutputBlockVCP
:React.FC<Quint4OutputBlockVCPProps> = (props: Quint4OutputBlockVCPProps):
  React.ReactElement => {
  const {
    procentVariableIdentRef,
    correspontVariableIdentRef,
    deviceInstanceId,
    target,
    showContextMessageDef,
    onStateChanged,
  } = props;
  const signalProcentStatusValue = useContextDatasetStatusValue(deviceInstanceId, procentVariableIdentRef, target);
  const signalProcentDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, procentVariableIdentRef, target);
  const procentVisibility = useStatusValueStateUpdate(procentVariableIdentRef, deviceInstanceId, target, onStateChanged);

  const correspontStatusValue = useContextDatasetStatusValue(deviceInstanceId, correspontVariableIdentRef, target);
  const correspontDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, correspontVariableIdentRef, target);
  const correspontVisibility = useStatusValueStateUpdate(correspontVariableIdentRef, deviceInstanceId, target, onStateChanged);

  if (signalProcentDescriptor === undefined || correspontDescriptor === undefined) {
    return <>{`UNDEFINED: ${procentVariableIdentRef}, ${correspontVariableIdentRef}`}</>;
  }

  if (!procentVisibility || !correspontVisibility) {
    return <></>;
  }

  return (
    <>
      <VariableFloatEditorControl
        deviceInstanceId={deviceInstanceId}
        statusDescriptor={signalProcentDescriptor}
        statusValue={signalProcentStatusValue}
        showContextMessageDef={showContextMessageDef}
      />
      <VariableFloatEditorControl
        deviceInstanceId={deviceInstanceId}
        statusDescriptor={correspontDescriptor}
        statusValue={correspontStatusValue}
        readonly
        showContextMessageDef={showContextMessageDef}
      />
    </>
  );
};

export default Quint4OutputBlockVCP;
