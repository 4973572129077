/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import OnlineCaparocTabControl from './OnlineCaparocTabControl/OnlineCaparocTabControl';
import withControlVisibility from '../../hoc/withControlVisibility';
import { ReduxControlProps } from '../../ReduxControlProps';
import TabViewControl from '../../TabViewControl/TabViewControl';
import TabViewItem from '../../TabViewControl/TabViewItem/TabViewItem';
import useMenuCurrentTab, { TabData } from '../../hooks/TabMenuHooks/useMenuCurrentTab';
import './OnlineCaparocModule.scss';

const controlTabLabel = 'CAPAROC:locM_Online_Control__LABEL';
const controlTabHelp = 'CAPAROC:locM_Online_Control__HELP';

const controlTabData: TabData = {
  label: controlTabLabel,
  help: controlTabHelp,
  identRef: 'online-caparoc-module-tabs--tab-control',
};

const tabDataset = [
  controlTabData,
];

const OnlineCaparocModule: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const [currentTab, setCurrentTab] = useMenuCurrentTab(identRef, deviceInstanceId, tabDataset);

  return (
    <div className="online-caparoc-module">
      <TabViewControl
        actionId="online-caparoc-module"
        currentTab={currentTab}
        setCurrentTab={(tabid) => setCurrentTab(deviceInstanceId, identRef, tabid)}
      >
        <TabViewItem
          tabIdent={controlTabData.identRef}
          tabLabel={controlTabData.label}
          tabHelp={controlTabData.help}
          tabColor="normal"
        >
          <div className="online-caparoc-module__tab-control">
            <OnlineCaparocTabControl identRef={identRef} deviceInstanceId={deviceInstanceId} onStateChanged={() => 0} />
          </div>
        </TabViewItem>
      </TabViewControl>
    </div>
  );
};

export default withControlVisibility(OnlineCaparocModule);
