/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  Middleware, Dispatch, MiddlewareAPI,
} from 'redux';
import { Services } from '@gpt/commons';
import { typeStandardViewMiddlewareAction } from './types';
import { IWebWorkerDeviceManager } from '../../../../services/WebWorkerDevice/WebWorkerDeviceManager';
import { DatasetType } from '../../store/deviceDataset/types';
import { deviceInstancesStoreSelector } from '../../../reduxStoreSelector';

const connectionStatusMiddleware = (webWorkerDeviceManager: IWebWorkerDeviceManager): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeStandardViewMiddlewareAction>(action: A): Promise<A> => {
  switch (action.type) {
    case 'STANDARD_VIEW__SET_CONNECTION_STATUS': {
      const { payload } = action;
      const { targetInstance, data } = payload;
      const { status } = data;
      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const webWorkerInstance = deviceInstances.instances[targetInstance]?.activeDevice.modelInstance?.webWorkerInstanceRef;

      if (webWorkerInstance === undefined) {
        break;
      }

      const deviceWorker = webWorkerDeviceManager.get(webWorkerInstance)?.get(DatasetType.device);
      if (deviceWorker === undefined) {
        break;
      }
      await deviceWorker.updateConnectionStatus(status !== Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected);
    }
      break;
    default:
  }
  const result = next(action);
  return result;
};

export default connectionStatusMiddleware;
