/* eslint-disable @typescript-eslint/no-empty-function */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

/* eslint-disable max-len */
import React from 'react';
import { IWebWorkerDeviceManager } from './services/WebWorkerDevice/WebWorkerDeviceManager';

export interface WebWorkerContextProps {
  webWorkerDeviceManager?: IWebWorkerDeviceManager;
}

export const WebWorkerContext = React.createContext<WebWorkerContextProps>({
  webWorkerDeviceManager: undefined,
});
