/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

/* eslint-disable max-len */
import { mapValue } from './helpers/helpers';

export const HBarElementStrokeWidth = 24;

export const HBarElementWidth = 500;
export const HBarElementHeight = HBarElementStrokeWidth * 2;

export const HBarElementPaddingRight = 158;
export const HBarElementPaddingLeft = 52;

export const mapHBarElementValue = (value: number, minValue: number, maxValue: number)
: number => mapValue(value, minValue, maxValue, HBarElementPaddingLeft, HBarElementWidth - HBarElementPaddingRight);
