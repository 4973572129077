/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { Services, WebDevice } from '@gpt/commons';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import './ButtonList.StandardView.scss';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { useDeviceConnectionStatus } from '../../store/deviceInstances/hooks/StandardViewHooks';
import { useContextDatasetValid } from '../../hooks/useContextDataset';
import { useContextActiveDeviceInstance } from '../../store/deviceInstances/hooks/ActiveDeviceHooks';

export enum StandardViewButton {
  // eslint-disable-next-line no-unused-vars
  Save = 0,
  // eslint-disable-next-line no-unused-vars
  Print = 1,
  // eslint-disable-next-line no-unused-vars
  Transfer = 2,
  // eslint-disable-next-line no-unused-vars
  Load = 3,
}

export interface ButtonListStandardViewProps {
  // eslint-disable-next-line no-unused-vars
  onButtonClick: (button: StandardViewButton) => void;
  deviceInstanceId: string;
}

const ButtonListStandardView: React.FC<ButtonListStandardViewProps> = (props: ButtonListStandardViewProps)
  : React.ReactElement => {
  const { t } = useTranslation();
  const { onButtonClick, deviceInstanceId } = props;
  const datasetValid = useContextDatasetValid(deviceInstanceId, DatasetType.user);
  const selectedDevice = useContextActiveDeviceInstance(deviceInstanceId);
  const connectionStatus = useDeviceConnectionStatus(deviceInstanceId);

  const communicationEnabled = selectedDevice?.device.catalog.properties.communication === WebDevice.CommunicationType.enabled ?? false;

  const transButtonDisabled = !datasetValid
   || (connectionStatus !== Services.DeviceModelServer.ConnectionService.ConnectionStatus.connected
   && connectionStatus !== Services.DeviceModelServer.ConnectionService.ConnectionStatus.offline)
   || !communicationEnabled;

  const loadButtonDisabled = !communicationEnabled;
  const saveButtonDisabled = !datasetValid || !communicationEnabled;

  return (
    <Row className="align-items-end justify-content-end m-0 p-0">
      <Col xs="auto" className="p-0 pr-2">
        <Button
          className="button-text"
          variant="secondary"
          onClick={() => onButtonClick(StandardViewButton.Load)}
          disabled={loadButtonDisabled}
        >
          {t<string>('STANDARD_BUTTON__LOAD')}
        </Button>
      </Col>
      <Col xs="auto" className="p-0 pr-2">
        <Button
          className="button-text"
          variant="secondary"
          onClick={() => onButtonClick(StandardViewButton.Save)}
          disabled={saveButtonDisabled}
        >
          {t<string>('STANDARD_BUTTON__SAVE')}
        </Button>
      </Col>
      <Col xs="auto" className="p-0 pr-2">
        <Button
          className="button-text print-button-grid"
          variant="secondary"
          onClick={() => { onButtonClick(StandardViewButton.Print); }}
          disabled={!datasetValid}
        >
          <span className="print-button-text">{t<string>('STANDARD_BUTTON__PRINT')}</span>
        </Button>
      </Col>
      <Col xs="auto" className="p-0 pr-2">
        <Button
          className="button-text"
          variant="primary"
          onClick={() => onButtonClick(StandardViewButton.Transfer)}
          disabled={transButtonDisabled}
        >
          {t<string>('STANDARD_BUTTON__TRANSFER')}
        </Button>
      </Col>
    </Row>
  );
};

export default ButtonListStandardView;
