/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import isElectron from 'is-electron';
import {
  SystemInformationState,
  SystemInformationActionTypes,
  INFORMATION__SET_HOST_NAME,
  INFORMATION__SET_USER_NAME,
  INFORMATION__APP_INITIALIZED,
} from './types';

const initialState: SystemInformationState = {
  hostName: '',
  userName: '',
  appInitialized: !isElectron(),
};

const systemInformationReducer = (
  state = initialState,
  action: SystemInformationActionTypes,
): SystemInformationState => {
  switch (action.type) {
    case INFORMATION__SET_HOST_NAME:
      return {
        ...state,
        hostName: action.payload,
      };
    case INFORMATION__SET_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };
    case INFORMATION__APP_INITIALIZED:
      return {
        ...state,
        appInitialized: true,
      };
    default:
      return state;
  }
};

export default systemInformationReducer;
