/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  typeIoLinkParameterMiddlewareActions,
  IoLinkWriteParameterPayload,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const ioLinkWriteParameter = (payload: IoLinkWriteParameterPayload)
: typeIoLinkParameterMiddlewareActions => ({
  type: 'IOLINK__ACTION__WRITE_PARAMETER',
  payload,
});
