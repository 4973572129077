/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import React from 'react';
import { ChartScaleMark } from '../types';
import {
  HBarElementWidth,
} from '../hbarconst';
import './HBarScale.scss';
import { mapValue } from '../helpers/helpers';

const HBarScaleHeight = 20;

interface MarkPoint {
  xPos: number;
  yPos: number;
  mark: ChartScaleMark;
}

export interface HBarScaleProps {
    minValue: number;
    maxValue: number;
    marks: ChartScaleMark[];
    paddingLeft: number;
    paddingRight: number;
}

export const HBarScale: React.FC<HBarScaleProps> = (props: HBarScaleProps)
: React.ReactElement => {
  const {
    minValue, maxValue, marks,
    paddingLeft, paddingRight,
  } = props;

  const lineYPosition = HBarScaleHeight - 1;
  const markPositions = marks.reduce((acc, mark) => {
    const xPos = mapValue(mark.value, minValue, maxValue, paddingLeft, HBarElementWidth - paddingRight);
    if (acc.length === 0) {
      return [
        ...acc,
        {
          xPos,
          yPos: lineYPosition,
          mark,
        },
      ];
    }
    if (acc[acc.length - 1].xPos === xPos) {
      return acc;
    }

    return [
      ...acc,
      {
        xPos,
        yPos: lineYPosition,
        mark,
      }];
  }, [] as MarkPoint[]);

  const chartTextMarks = markPositions.map((pt) => {
    const textAnchor = 'middle';
    const key = `gauge-point-${pt.xPos}-${pt.mark.value}-${pt.mark.text}`;
    return (
      <g key={key}>
        {/* <text fontSize={14} fontWeight={300} transform={`translate(0 10) rotate(45 ${pt.xPos} ${pt.yPos}) translate(${pt.xPos - 20} ${pt.yPos - 10})`}>{pt.mark.text}</text> */}
        <text x={pt.xPos} y={pt.yPos} fontSize={14} fontWeight={300} textAnchor={textAnchor}>{pt.mark.text}</text>
        <line x1={pt.xPos} y1={1} x2={pt.xPos} y2={6} strokeWidth={1} />
      </g>
    );
  });

  return (
    <svg className="hbar-scale-element" fontFamily="IBM Plex Sans" viewBox={`0 0 ${HBarElementWidth} ${HBarScaleHeight}`} preserveAspectRatio="xMidYMid">
      <line x1={paddingLeft} y1={1} x2={HBarElementWidth - paddingRight} y2={1} strokeWidth={1} />
      {chartTextMarks}
    </svg>
  );
};
