/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { WebDevice } from '@gpt/commons';
import { Discovery } from '@gpt/cxe-nats-communication';
import {
  SetDeviceCatalogStateAction,
  SetDiscoveryConnectionStateAction,
  UpdateDeviceCatalogAction,
  UpdateDiscoveryListAction,
} from './types';
import { ExecutionState } from '../common';
import { NatsServerConnectionState } from '../../natsConnection';

// eslint-disable-next-line import/prefer-default-export
export const updateDiscoveryList = (payload: Discovery.CommunicationAdapter)
  : UpdateDiscoveryListAction => ({
  type: 'DISCOVERY_STATE__UPDATE_LIST',
  payload,
});

export const setDiscoveryConnectionState = (payload: NatsServerConnectionState)
  : SetDiscoveryConnectionStateAction => ({
  type: 'DISCOVERY_STATE__SET_CONNECTION_STATE',
  payload,
});

export const updateDiscoveryDeviceCatalog = (payload: WebDevice.DeviceCatalogInformation[])
  : UpdateDeviceCatalogAction => ({
  type: 'DISCOVERY_STATE__UPDATE_DEVICE_CATALOG',
  payload,
});

export const setDiscoveryDeviceCatalogState = (payload: ExecutionState)
  : SetDeviceCatalogStateAction => ({
  type: 'DISCOVERY_STATE__SET_DEVICE_CATALOG_STATE',
  payload,
});
