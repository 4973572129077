/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../store';
import { ModalPasswordDialog } from '../../../modal/ModalPasswordDialog/ModalPasswordDialog';
import { executeMethodStagePassword, abortMethodExecution } from '../../../store/deviceInstances/middleware/deviceMethod/actions';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';

export interface ActionMethodProps {
  actionId: string;
  methodIdent: string;
  deviceInstanceId: string;
  onSetPassword?: (pass: string) => void;
}

const ActionMethodControl
:React.FC<ActionMethodProps> = (props: ActionMethodProps): React.ReactElement => {
  const {
    methodIdent, actionId, deviceInstanceId, onSetPassword,
  } = props;
  const dispatch = useDispatch();

  const stageStatus = useTypedSelector(
    (state) => {
      const deviceInstance = state.deviceInstances.instances[deviceInstanceId];
      return deviceInstance.deviceMethodExecution[actionId]?.stage
        ?? MethodStageExecutionStatus.Initialize;
    },
  );

  let control = <div />;
  if (stageStatus === MethodStageExecutionStatus.RequestPassword) {
    control = (
      <ModalPasswordDialog
        onAccept={async (password: string) => {
          onSetPassword?.(password);
          dispatch(executeMethodStagePassword(deviceInstanceId, {
            methodIdent,
            methodStatusRef: actionId,
            password,
          }));
        }}
        onCancel={async () => {
          dispatch(abortMethodExecution(deviceInstanceId, {
            methodIdent,
            methodStatusRef: actionId,
          }));
        }}
      />
    );
  }
  return <>{control}</>;
};

export default ActionMethodControl;
