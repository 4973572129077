/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { DeviceInstancesState } from '../../types';
import { IoLinkEventMonitorState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const IoLinkEventMonitorStateSelector = (state: DeviceInstancesState, deviceInstanceId: string)
: IoLinkEventMonitorState | undefined => state.instances[deviceInstanceId]?.ioLinkEventMonitor;
