/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import ReduxControl from '../../../controls/ReduxControl/ReduxControl';
import { ReduxControlProps } from '../../../controls/ReduxControlProps';
import { PreviewLabelWidth, PreviewUnitWidth, PreviewValueWidth } from '../PreviewLayout';

const PreviewDipSwitchControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement => {
  const {
    identRef, onStateChanged, readonly, deviceInstanceId,
  } = props;

  return (
    <div className="d-flex align-items-end pb-2">
      <div className={`col-${PreviewLabelWidth} m-0 p-0`} />
      <div className={`col-${PreviewValueWidth} m-0 px-1`}>
        <ReduxControl deviceInstanceId={deviceInstanceId} identRef={identRef} onStateChanged={onStateChanged} readonly={readonly} />
      </div>
      <div className={`col-${PreviewUnitWidth} m-0 p-0`}>
        <p />
      </div>
    </div>
  );
};

export default PreviewDipSwitchControl;
