/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import useSPortWriteParameter from '../../../../SPort/hooks/useSPortWriteParameter';
import { MethodStageExecutionStatus } from '../../../../../store/deviceInstances/store/deviceMethod/types';
import H5ActivateButton from './H5ActivateButton/H5ActivateButton';
import './H5ManualButtonGroup.scss';

export const H5MANUALCONTROL_DEACTIVATE = 0x40;
export const H5MANUALCONTROL_ACTIVATE = 0x20;

export interface H5ManualButtonGroupProps {
  // eslint-disable-next-line react/no-unused-prop-types
  deviceInstanceId: string;
  controlView: DeviceModelStatus.UI.Contactron.MotorStarterH5ManualControlView;
  manualActive?: boolean;
}

const H5ManualButtonGroup: React.FC<H5ManualButtonGroupProps> = (props: H5ManualButtonGroupProps):React.ReactElement => {
  const { t } = useTranslation();
  const { controlView, deviceInstanceId, manualActive } = props;

  const [manualModeActive, setManualModeActive] = useState(manualActive ?? false);

  const [activateState, writeActivateParameter] = useSPortWriteParameter(deviceInstanceId, `s-port-activate__${controlView.activate.address}__action`);
  const [leftState, writeLeftParameter] = useSPortWriteParameter(deviceInstanceId, `s-port-left__${controlView.left.address}__action`);
  const [rightState, writeRightParameter] = useSPortWriteParameter(deviceInstanceId, `s-port-right__${controlView.right.address}__action`);
  const [stopState, writeStopParameter] = useSPortWriteParameter(deviceInstanceId, `s-port-stop__${controlView.stop.address}__action`);

  const actionRunning = activateState === MethodStageExecutionStatus.InProgress
    || leftState === MethodStageExecutionStatus.InProgress
    || rightState === MethodStageExecutionStatus.InProgress
    || stopState === MethodStageExecutionStatus.InProgress;

  const buttonDisabled = actionRunning || !manualModeActive;

  useEffect(() => () => {
    if (manualModeActive) {
      writeActivateParameter(controlView.stop.address, new Uint8Array([0x00, controlView.stop.value]));
      writeActivateParameter(controlView.activate.address, new Uint8Array([0x00, H5MANUALCONTROL_DEACTIVATE]));
    }
  }, [manualModeActive, controlView]);

  return (
    <div className="h5motorstarter-online-manual-buttons">
      <div className="h5motorstarter-online-manual--activate">
        <H5ActivateButton
          deviceInstanceId={deviceInstanceId}
          controlView={controlView}
          manualModeActive={manualModeActive}
          disabled={actionRunning}
          onClick={(writeValue) => {
            setManualModeActive(!manualModeActive);
            writeActivateParameter(controlView.activate.address, new Uint8Array([0x00, writeValue]));
          }}
        />
      </div>
      <div className="h5motorstarter-online-manual--left">
        <Button
          variant="secondary-small"
          block
          disabled={buttonDisabled}
          onClick={() => {
            if (manualModeActive) {
              writeLeftParameter(controlView.left.address, new Uint8Array([0x00, controlView.left.value]));
            }
          }}
        >
          {t(controlView.left.label)}
        </Button>
      </div>
      <div className="h5motorstarter-online-manual--stop">
        <Button
          variant="secondary-small"
          block
          disabled={buttonDisabled}
          onClick={() => {
            if (manualModeActive) {
              writeStopParameter(controlView.stop.address, new Uint8Array([0x00, controlView.stop.value]));
            }
          }}
        >
          {t(controlView.stop.label)}
        </Button>
      </div>
      <div className="h5motorstarter-online-manual--right">
        <Button
          variant="secondary-small"
          block
          disabled={buttonDisabled}
          onClick={() => {
            if (manualModeActive) {
              writeRightParameter(controlView.right.address, new Uint8Array([0x00, controlView.right.value]));
            }
          }}
        >
          {t(controlView.right.label)}
        </Button>
      </div>
    </div>
  );
};

export default H5ManualButtonGroup;
