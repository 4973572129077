/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  DeviceCatalogActionTypes,
  REQUEST_DEVICE_CATALOG_DEVICE_LIST,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const requestDeviceCatalogDeviceList = ()
  : DeviceCatalogActionTypes => ({
  type: REQUEST_DEVICE_CATALOG_DEVICE_LIST,
  payload: '-',
});
