/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { UnsupportedControl } from '../../../../../components/UnsupportedControl';
import DisplayFormat from '../../../../../helpers/displayFormat';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../../../../hooks/useContextDataset';
import { CompareModeType } from '../../../../../views/CompareView/common';
import { CompareStatusValueProps } from '../../../CompareTypes';
import useCompareMiddleButton from '../../../hooks/useCompareMiddleButton';
import useDispatchIntegerValue from '../../../hooks/useDispatchIntegerValue';
import CompareStringValue from '../CompareStringStatusValue/CompareStringValue/CompareStringValue';
import CompareIntegerValue from './CompareIntegerValue/CompareIntegerValue';
import useVariableValidationMessage from '../../../hooks/useVariableValidationMessage';

/**
 * Compare two values from Dataset Value : value vs externalValue
 * backupValue used as storage for value before modification
 *
 * @param props Control Properties
 * @returns
 */
const CompareUnsignedStatusValue
: React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
  : React.ReactElement | null => {
  const {
    compareMode,
    identRef, leftDataset, rightDataset,
    onShowContentHelp, onHideContentHelp,
    showImageAndSign,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();

  const dispatchValue = useDispatchIntegerValue(deviceInstanceId, leftDataset);

  const leftDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef, leftDataset);
  const leftValue = useContextDatasetStatusValue(deviceInstanceId, identRef, leftDataset);

  const rightDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef, rightDataset);
  const rightValue = useContextDatasetStatusValue(deviceInstanceId, identRef, rightDataset);

  const message = useVariableValidationMessage(leftDescriptor, leftValue);
  const [buttonFunction, onMiddleButtonClick] = useCompareMiddleButton(deviceInstanceId, leftValue, rightValue);

  if (leftDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareIntegerStatusValue: Unsupported type" />;
  }

  if (rightDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareIntegerStatusValue: Unsupported type" />;
  }

  if (leftDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER) {
    return <UnsupportedControl text="CompareIntegerStatusValue: only INTEGER is supported" />;
  }

  if (rightDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER) {
    return <UnsupportedControl text="CompareIntegerStatusValue: only INTEGER is supported" />;
  }

  const leftUnit = leftDescriptor.unit === undefined ? undefined : t<string>(leftDescriptor.unit);
  const rightUnit = rightDescriptor.unit === undefined ? undefined : t<string>(rightDescriptor.unit);
  const equal = leftValue?.value === rightValue?.value && rightUnit === leftUnit;

  if (compareMode === CompareModeType.ShowDifference && equal) {
    return (null);
  }

  if (compareMode === CompareModeType.ShowAll) {
    const leftStringValue = DisplayFormat(leftDescriptor.valueType.displayFormat ?? '', leftValue?.value);
    const rightStringValue = DisplayFormat(rightDescriptor.valueType.displayFormat ?? '', rightValue?.value);
    return (
      <CompareStringValue
        label={t(leftDescriptor.label)}
        help={t<string>(leftDescriptor.help)}
        equal={equal}
        valueLeft={leftStringValue}
        valueLeftUnit={leftUnit}
        hideLeftControl={leftDescriptor.visibility === false}
        valueRight={rightStringValue}
        valueRightUnit={rightUnit}
        hideRightControl={rightDescriptor.visibility === false}
        onButtonClick={() => onMiddleButtonClick(buttonFunction)}
        modified={leftValue?.modified ?? false}
        readonly
        warning={message}
        onHideContentHelp={onHideContentHelp}
        onShowContentHelp={onShowContentHelp}
        buttonFunction={buttonFunction}
        showImageAndSign={showImageAndSign}
      />
    );
  }

  return (
    <CompareIntegerValue
      label={t(leftDescriptor.label)}
      help={t<string>(leftDescriptor.help)}
      equal={equal}
      valueLeft={leftValue?.value}
      valueLeftDisplayFormat={leftDescriptor.valueType.displayFormat}
      valueLeftUnit={leftDescriptor.unit === undefined ? undefined : t<string>(leftDescriptor.unit)}
      hideLeftControl={leftDescriptor.visibility === false}
      valueRight={rightValue?.value}
      valueRightUnit={rightDescriptor.unit === undefined ? undefined : t<string>(rightDescriptor.unit)}
      valueRightDisplayFormat={rightDescriptor.valueType.displayFormat}
      hideRightControl={rightDescriptor.visibility === false}
      onButtonClick={() => onMiddleButtonClick(buttonFunction)}
      modified={leftValue?.modified ?? false}
      readonly={leftDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY}
      onLeftValueChange={(ev) => dispatchValue(ev, leftValue)}
      warning={message}
      stepSize={leftDescriptor.valueType.stepSize}
      onHideContentHelp={onHideContentHelp}
      onShowContentHelp={onShowContentHelp}
      buttonFunction={buttonFunction}
      showImageAndSign={showImageAndSign}
    />
  );
};

export default CompareUnsignedStatusValue;
