/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/base.css';
import './assets/styles/colors.css';
import './assets/styles/fonts.css';
import './assets/styles/application.css';

import { Provider } from 'react-redux';
import { Services, WebDevice } from '@gpt/commons';
import i18nextConfig from './i18n-config';
import { initializeStore } from './store/reduxStoreInitialize';
import { initializeClipxEngineerIntegrationService } from './services/clipxEngineerIntegrationService';
import registerKnownControls from './controls/register';
import registerDeviceViews from './views/registerDeviceView';
import AppSplashController from './splash/AppSplashController';
import { WebWorkerDeviceManager } from './services/WebWorkerDevice/WebWorkerDeviceManager';
import { i18nWebServiceInit } from './store/i18nWebService/actions';
import { ENV_WEBSERVER_REQUEST_PATH } from './enviroment';
import { setDeviceMethodExecutionState } from './store/deviceInstances/store/deviceMethod/actions';
import { selectDeviceInstance } from './store/deviceInstances/actions';
import { WebWorkerContext } from './WebWorkerContext';
import NatsConnectionController from './natsConnection/NatsConnectionController';
import { handleWebDeviceActionEvent, handleWebDeviceProgressEvent } from './index.events.action';
import handleWebDeviceCustomEvent from './index.events.custom';
import handleWebDeviceStationEvent from './index.events.station';
import './index.css';
import {
  stationCreateDevice, stationDeleteDevice, stationSetConnectionStatus, stationUpdateData,
} from './store/middleware/childInstanceMiddleware/actions';
import { DatasetType } from './store/deviceInstances/store/deviceDataset/types';

registerKnownControls();
registerDeviceViews();

// Create webworker reference object
const webWorkerDeviceManager = new WebWorkerDeviceManager();
// initialize Redux store with some middlewares communicating with other services
const store = initializeStore(webWorkerDeviceManager);

store.dispatch(i18nWebServiceInit({
  url: ENV_WEBSERVER_REQUEST_PATH,
}));

const processMethodNotification = (
  targetInstance: string,
  note: Services.DeviceModel.DeviceModelMethodNotification,
) => {
  const {
    methodIdent, steps,
  } = note;
  store.dispatch(setDeviceMethodExecutionState(targetInstance, {
    methodIdent: methodIdent,
    steps: steps,
  }));
};

webWorkerDeviceManager.on('WEBDEVICE__NOTIFICATION_EVENT', (ev: WebDevice.WebDeviceNotificationEvent) => {
  if (ev.kind !== 'WEBDEVICE__NOTIFICATION_EVENT') {
    return;
  }
  const { deviceInstanceId } = ev;
  if (ev.notification.type === 'method.state') {
    const methodNote = ev.notification as Services.DeviceModel.DeviceModelMethodNotification;
    processMethodNotification(methodNote.deviceInstanceId, methodNote);
  } else if (ev.notification.type === 'connection.state') {
    const { status } = ev.notification;
    store.dispatch(stationSetConnectionStatus(deviceInstanceId, status));
  }
});
webWorkerDeviceManager.on(
  'WEBDEVICE__ACTION_PROGRESS_EVENT',
  (ev: WebDevice.WebDeviceActionProgressEvent) => handleWebDeviceProgressEvent(store, ev),
);
webWorkerDeviceManager.on(
  'WEBDEVICE__ACTION_EVENT',
  (ev: WebDevice.WebDeviceActionEvent) => handleWebDeviceActionEvent(store, ev),
);

webWorkerDeviceManager.on('WEBDEVICE__UPDATE_DATA_EVENT', (event: WebDevice.WebDeviceUpdateDataEvent) => {
  const { deviceInstanceId, data } = event;
  const targetDataset: DatasetType = DatasetType[event.targetDataset] ?? DatasetType.user;
  store.dispatch(stationUpdateData({
    data,
    deviceInstanceId,
    targetDataset,
  }));
});
webWorkerDeviceManager.on('WEBDEVICE__CREATE_DEVICE__EVENT', (event: WebDevice.WebDeviceCreateDeviceEvent) => {
  store.dispatch(stationCreateDevice(event));
});
webWorkerDeviceManager.on('WEBDEVICE__DELETE_DEVICE__EVENT', (event: WebDevice.WebDeviceDeleteDeviceEvent) => {
  const { deviceInstanceId } = event;
  store.dispatch(stationDeleteDevice(deviceInstanceId));
});
webWorkerDeviceManager.on('WEBDEVICE__SELECT_DEVICE__EVENT', (event: WebDevice.WebDeviceSelectDeviceEvent) => {
  const { deviceInstanceId } = event;
  store.dispatch(selectDeviceInstance(deviceInstanceId));
});
webWorkerDeviceManager.on(
  'WEBDEVICE__UPDATE_CUSTOM_OBJECT_EVENT',
  (event: WebDevice.WebDeviceUpdateCustomObjectEvent) => handleWebDeviceCustomEvent(store, event),
);
webWorkerDeviceManager.on(
  'WEBDEVICE__UPDATE_STATION_EVENT',
  (event: WebDevice.WebDeviceStationEvent) => handleWebDeviceStationEvent(store, event),
);
// eslint-disable-next-line max-len
// webWorkerDeviceManager.on('WEBDEVICE__LOG_DATA_EVENT', (event: WebDevice.WebDeviceLogDataEvent) => {
//   console.log(`LOG_DATA_EVENT: ${event.message}`);
// });

// initialize i18next - using i18nMoleculerBackend and configured language
const i18nextInit = i18nextConfig(store);
i18nextInit.then(() => {
  // initialize "cxe-dp-integration" lib → create new HostConnector(…) instance
  initializeClipxEngineerIntegrationService(store);
  // at this point, hosting cxE app has control, i.e. to start() → change view

  // render root component of this React application
  const rootElement = document.getElementById('root');
  if (!rootElement) throw new Error('Failed to find the root element');
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <WebWorkerContext.Provider value={{ webWorkerDeviceManager }}>
        <Provider store={store}>
          <NatsConnectionController>
            <AppSplashController />
          </NatsConnectionController>
        </Provider>
      </WebWorkerContext.Provider>
    </React.StrictMode>,
  );
}, (error) => {
  // eslint-disable-next-line no-console
  console.error('i18next initialization failed:', error);
});
