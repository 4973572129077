/* eslint-disable max-len */
import React, { PropsWithChildren } from 'react';
import { ChartCardHeader } from './header/ChartCardHeader';
import './ChartCardControl.scss';

export interface ChartCardControlProps extends PropsWithChildren<unknown> {
  label: string;
  showDragIndicator: boolean;
  // eslint-disable-next-line no-unused-vars
  onShowDragIndicator: (value: boolean) => void;
  onResetDragValues: () => void;
}

const ChartCardControl: React.FC<ChartCardControlProps> = (props: ChartCardControlProps)
: React.ReactElement => {
  const {
    label,
    children,
    showDragIndicator,
    onResetDragValues,
    onShowDragIndicator,
  } = props;

  return (
    <div className="chart-card-control">
      <ChartCardHeader
        title={label}
        showDragIndicator={showDragIndicator}
        onResetDragValues={onResetDragValues}
        onShowDragIndicator={onShowDragIndicator}
      />
      {children}
    </div>
  );
};

export default ChartCardControl;
