import { WebDevice } from '@gpt/commons';
import {
  DeviceCardListItem, DeviceCardListItemAdapter,
  DeviceCardListItemDeviceUnidentified, DeviceCardListItemDeviceUnknown,
  DeviceCardListItemInterface, InterfaceCardType,
} from '../../../components/DeviceCardList/types';
import { ConnectedDeviceState, DiscoveryServiceState } from '../../../store/discoveryServiceState';
import { DeviceSimulationState } from '../../../store/deviceSimulation/types';

const createDefaultCatalogEntry = (deviceId: string, fwVersion: string, protocol: string) => ({
  deviceCatalogTitle: deviceId,
  deviceCatalogIdent: deviceId,
  deviceDriverId: '',
  deviceFamily: '',
  species: WebDevice.Species.device,
  deviceTypeName: '',
  deviceVendor: '',
  firmwareVersion: fwVersion,
  productImage: '',
  productOrderNumber: '',
  productURL: '',
  profileName: '',
  protocol,
  file: {
    version: '0.0.1',
    buildDate: '2022-11-11',
    name: '',
  },
  properties: {
    communication: WebDevice.CommunicationType.enabled,
    simulation: WebDevice.SimulationType.disabled,
    supportedWizard: [],
  },
  i18n: {
    family: 'default',
  },
});

const buildAdapterList = (state: DiscoveryServiceState)
: DeviceCardListItem[] => Object.keys(state.adapters).reduce((acc, key) => {
  const adapterId = state.adapters[key].id;
  const deviceKey = Object.keys(state.devices)
    .find((k) => state.devices[k].adapterId === adapterId
     && state.devices[k].state !== ConnectedDeviceState.Disconnected);
  if (deviceKey !== undefined) {
    return acc;
  }

  const data: DeviceCardListItemAdapter = {
    type: 'DEVICE_CARD_LIST_ITEM__ADAPTER',
    adapterId,
    state: state.adapters[key].state,
    adapterType: state.adapters[key].adapterType,
    timestamp: state.adapters[key].timestamp,
  };
  return [
    ...acc,
    data,
  ];
}, [] as DeviceCardListItemAdapter[]);

const buildInterfaceList = (): DeviceCardListItem[] => {
  const interfaceCardList = [
    InterfaceCardType.USB,
    InterfaceCardType.BLUETOOTH,
    InterfaceCardType.LAN,
  ].map((interfaceType) => {
    const data: DeviceCardListItemInterface = {
      type: 'DEVICE_CARD_LIST_ITEM__INTERFACE',
      interfaceType,
    };
    return data;
  });
  return interfaceCardList;
};

const buildDeviceList = (state: DiscoveryServiceState): DeviceCardListItem[] => {
  const devices = Object.keys(state.devices).reduce((acc, key) => {
    if (state.devices[key].state === ConnectedDeviceState.Disconnected) {
      return acc;
    }

    if (state.devices[key].state === ConnectedDeviceState.Identification) {
      const data: DeviceCardListItemDeviceUnidentified = {
        type: 'DEVICE_CARD_LIST_ITEM__DEVICE__UNIDENTIFIED',
        id: state.devices[key].id,
        adapterId: state.devices[key].adapterId,
        adapterType: state.adapters[state.devices[key].adapterId].adapterType,
        timestamp: state.devices[key].timestamp,
      };
      return [
        ...acc,
        data,
      ];
    }

    if (state.devices[key].state === ConnectedDeviceState.ConnectedUnknown) {
      const data: DeviceCardListItemDeviceUnknown = {
        type: 'DEVICE_CARD_LIST_ITEM__DEVICE__UNKNOWN',
        id: state.devices[key].id,
        adapterId: state.devices[key].adapterId,
        timestamp: state.devices[key].timestamp,
      };
      return [
        ...acc,
        data,
      ];
    }

    const { catalogId } = state.devices[key];
    const dev = state.devices[key];
    const catalog = catalogId === undefined || state.catalog.deviceList[catalogId] === undefined
      ? createDefaultCatalogEntry(dev.id, dev.instance?.firmwareVersion ?? '0', dev.instance?.communicationProtocol ?? '???')
      : state.catalog.deviceList[catalogId];

    const data: DeviceCardListItem = {
      type: catalog?.species === WebDevice.Species.device
        ? 'DEVICE_CARD_LIST_ITEM__DEVICE__KNOWN'
        : 'DEVICE_CARD_LIST_ITEM__STATION',
      id: state.devices[key].id,
      adapterId: state.devices[key].adapterId,
      device: {
        catalog,
        instance: dev.instance,
      },
      timestamp: state.devices[key].timestamp,
    };
    return [
      ...acc,
      data,
    ];
  }, [] as DeviceCardListItem[]);
  return devices;
};

const buildSimulatedDeviceList = (state: DeviceSimulationState): DeviceCardListItem[] => {
  const { simulatedDevices } = state;
  const deviceList: DeviceCardListItem[] = Object
    .keys(simulatedDevices)
    .reduce((acc, key) => {
      const simDevices: DeviceCardListItem[] = simulatedDevices[key]
        .map((dev) => ({
          type: 'DEVICE_CARD_LIST_ITEM__DEVICE__KNOWN',
          id: dev.instance?.deviceId ?? dev.catalog.deviceCatalogIdent,
          adapterId: 'simulation',
          device: dev,
          timestamp: '1',
        }));
      return [
        ...acc,
        ...simDevices,
      ];
    }, [] as DeviceCardListItem[]);
  return deviceList;
};

const discoveryListSelector = (
  distcoveryState: DiscoveryServiceState,
  simulationState: DeviceSimulationState,
): DeviceCardListItem[] => {
  const adapters = buildAdapterList(distcoveryState);
  const devices = buildDeviceList(distcoveryState);

  const dataList = [...adapters, ...devices].sort((x1, x2) => {
    const x1timestamp = x1.type === 'DEVICE_CARD_LIST_ITEM__INTERFACE' ? 0 : x1.timestamp;
    const x2timestamp = x2.type === 'DEVICE_CARD_LIST_ITEM__INTERFACE' ? 0 : x2.timestamp;

    if (x1timestamp < x2timestamp) {
      return -1;
    }
    if (x1timestamp > x2timestamp) {
      return 1;
    }
    return 0;
  });

  const simDevices = buildSimulatedDeviceList(simulationState);

  const interfaceCardList = buildInterfaceList();

  return [
    ...dataList,
    ...simDevices,
    ...interfaceCardList,
  ];
};

export default discoveryListSelector;
