/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import { StructureItemState } from '../../../controls/ReduxControlProps';
import './WizardStepper.scss';

export interface WizardStepProps {
  title: string;
  state?: StructureItemState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface WizardStepperProps extends PropsWithChildren<any> {
  activeStep: number,
  steps: WizardStepProps[];
}

const WizardStepper: React.FC<WizardStepperProps> = (props: WizardStepperProps)
: React.ReactElement => {
  const {
    activeStep, steps,
  } = props;

  let active;
  if (activeStep < 0) {
    active = 0;
  } else if (activeStep >= steps.length) {
    active = steps.length - 1;
  } else {
    active = activeStep;
  }

  const dots = steps.map((step, index) => {
    let classn = 'wizard-stepper__dots__dot--pending';
    if (index < active) {
      classn = 'wizard-stepper__dots__dot--completed';
    } else if (index === active) {
      classn = 'wizard-stepper__dots__dot--performing';
    }
    return (<div className={`wizard-stepper__dots__dot ${classn}`} key={`dot-${step.title}`}>{index + 1}</div>);
  });

  const labels = steps.map((step) => {
    let classn = 'wizard-stepper__labels__label';
    if (step.state !== undefined && step.state.error) {
      classn = 'wizard-stepper__labels__label--error';
    } else if (step.state !== undefined && step.state.modified) {
      classn = 'wizard-stepper__labels__label--modified';
    }
    return (<div key={`label-${step.title}`}><div><span className={classn}>{step.title}</span></div></div>);
  });
  const progressMargin = (steps.length < 2) ? 0 : (1 / (steps.length - 1)) * active * 100;
  const progressStyle = { marginLeft: `${progressMargin}%` };
  return (
    <div className="wizard-stepper">
      <div className="wizard-stepper__line"><div style={progressStyle} /></div>
      <div className="wizard-stepper__dots">{dots}</div>
      <div className="wizard-stepper__labels">{labels}</div>
    </div>
  );
};

export default WizardStepper;
