/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import Image from 'react-bootstrap/Image';
import { Services } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useContextDatasetModified } from '../../hooks/useContextDataset';
import failure from '../../assets/ne107icons/ne107-failure.svg';
import good from '../../assets/ne107icons/ne107-good.svg';
import indeterminate from '../../assets/ne107icons/ne107-indeterminate.svg';
import { useContextActiveDeviceInstance } from '../../store/deviceInstances/hooks/ActiveDeviceHooks';
import { useDeviceConnectionStatus, useHeaderMessage } from '../../store/deviceInstances/hooks/StandardViewHooks';
import useInvalidValueList from './hooks/useInvalidValueList';
import './Header.scss';
import { HeaderMessage } from '../HeaderMessage/HeaderMessage';

interface resolveImageValue {
  url: string;
  alt: string;
}

const resolveConnectionIconSrc = (
  status: Services.DeviceModelServer.ConnectionService.ConnectionStatus,
): resolveImageValue => {
  let result: resolveImageValue;

  switch (status) {
    case Services.DeviceModelServer.ConnectionService.ConnectionStatus.connected:
      result = { url: good, alt: 'connected' };
      break;
    case Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected:
      result = { url: failure, alt: 'disconnected' };
      break;
    case Services.DeviceModelServer.ConnectionService.ConnectionStatus.offline:
      result = { url: indeterminate, alt: 'offline' };
      break;
    default:
      result = { url: '', alt: 'offline' };
      break;
  }

  return result;
};

export interface HeaderProps {
  deviceInstanceId: string;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps): React.ReactElement => {
  const { deviceInstanceId } = props;
  const instance = useContextActiveDeviceInstance(deviceInstanceId);
  const connectionStatus = useDeviceConnectionStatus(deviceInstanceId);
  const datasetModified = useContextDatasetModified(deviceInstanceId);
  const message = useHeaderMessage(deviceInstanceId);
  const { t } = useTranslation();

  useInvalidValueList(deviceInstanceId);

  const statusImage = (instance?.device !== undefined)
    ? resolveConnectionIconSrc(connectionStatus)
    : { url: undefined, alt: 'offline' };

  const connectionStatusTr = t(`HEADER__CONNECTION_STATUS_${statusImage.alt.toUpperCase()}`);

  return (
    <div className="header-container__container" data-testid="header">
      <div className="header-container__image d-flex justify-content-center align-items-center">
        <Image
          src={instance !== undefined ? instance.device.catalog.productImage : undefined}
          alt={instance !== undefined ? instance.device.catalog.deviceTypeName : undefined}
          className="header-container__image--fit-image"
        />
      </div>
      <div className="header-container__heading">
        <p className="header-container__heading-font">{instance !== undefined && t(instance.device.catalog.deviceFamily)}</p>
      </div>
      <div className="header-container__message">
        {message
          && (
          <div className="header-container__message-text">
            <HeaderMessage message={t(message)} />
          </div>
          )}
      </div>
      <div className="header-container__one d-flex flex-column flex-nowrap justify-content-start align-items-start">
        <p className="header-container__label-font">{instance !== undefined && instance.device.catalog.deviceTypeName}</p>
        <p className="header-container__label-font">
          {`${t<string>('HEADER__ORDER_NUMBER')}: `}
          <a
            href={instance !== undefined ? instance.device.catalog.productURL : undefined}
            target="_blank"
            rel="noreferrer"
          >
            <span className="external-link ml-2">{instance !== undefined && instance.device.catalog.productOrderNumber}</span>
          </a>
        </p>
      </div>
      <div className="header-container__two">
        <div className="d-flex flex-row flex-nowrap align-items-start">
          <div className="d-flex flex-column align-items-end mr-1">
            <p className="header-container__label-font">{`${t<string>('HEADER__DEVICE_TAG')}: `}</p>
            <p className="header-container__label-font">{`${t<string>('HEADER__SERIAL_NUMBER')}: `}</p>
          </div>
          <div className="d-flex flex-column align-items-start w-75 ml-1">
            <div className="header-container__value__container">
              <p className="header-container__value-font header-container__line-limiter">{instance !== undefined && instance.device.instance?.deviceTag}</p>
            </div>
            <div className="header-container__value__container">
              <p className="header-container__value-font header-container__line-limiter">{instance !== undefined && instance.device.instance?.serialNumber}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="header-container__three">
        <div className="d-flex flex-row flex-nowrap align-items-start">
          <div className="d-flex flex-column align-items-end mr-1">
            <p className="header-container__label-font">{`${t<string>('HEADER__HARDWARE_VERSION')}: `}</p>
            <p className="header-container__label-font">{`${t<string>('HEADER__FIRMWARE_VERSION')}: `}</p>
          </div>
          <div className="d-flex flex-column align-items-start ml-1">
            <div className="header-container__value__container">
              <p className="header-container__value-font">{instance !== undefined && instance.device.instance?.hardwareVersion}</p>
            </div>
            <div className="header-container__value__container">
              <p className="header-container__value-font">{instance !== undefined && instance.device.instance?.firmwareVersion}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="header-container__status d-flex flex-column align-items-center">
        <div className="d-flex flex-row align-items-center">
          <p
            className="header-container__label-font"
            style={{ paddingLeft: 15, paddingRight: '1em' }}
          >
            {`${t<string>('HEADER__DEVICE_STATUS')}: `}
          </p>
          {statusImage.url !== undefined
            && (
            <>
              <Image
                src={statusImage.url}
                alt={statusImage.alt}
                height={20}
                width={20}
                roundedCircle
              />
              <p className="header-container__value-font" style={{ paddingLeft: '1em', paddingBottom: '0.12em' }}>{connectionStatusTr}</p>
            </>
            )}
        </div>
        { datasetModified && (
          <p className="header-container__changes-font">
            {t<string>('HEADER__MODIFICATIONS_NOT_SAVED')}
          </p>
        )}
      </div>
    </div>
  );
};

export default Header;
