/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import React from 'react';
import './HBarElement.scss';
import { ChartScaleMark, ChartSegment } from '../types';
import {
  HBarElementWidth,
} from '../hbarconst';
import { HBarChart } from '../HBarChart/HBarChart';
import { HBarScale } from '../HBarScale/HBarScale';

const HBarElementHeight = 68;

export interface HBarElementProps {
    currentValue: number;
    minValue: number;
    maxValue: number;
    segments: ChartSegment[];
    marks?: ChartScaleMark[];
    dragMinValue?: number;
    dragMaxValue?: number;
}

export const HBarElement: React.FC<HBarElementProps> = (props: HBarElementProps)
: React.ReactElement => {
  const {
    currentValue,
    minValue, maxValue, segments, marks,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    dragMaxValue, dragMinValue,
  } = props;

  // const textYposition = 48;
  const marksControl = marks === undefined ? undefined : <HBarScale minValue={minValue} maxValue={maxValue} marks={marks} paddingLeft={24} paddingRight={24} />;
  const elementHeight = HBarElementHeight;
  return (
    <svg className="hbar-element" fontFamily="IBM Plex Sans" viewBox={`0 0 ${HBarElementWidth} ${elementHeight}`} preserveAspectRatio="none">
      <HBarChart
        currentValue={currentValue}
        minValue={minValue}
        maxValue={maxValue}
        segments={segments}
        paddingLeft={24}
        paddingRight={24}
        dragMinValue={dragMinValue}
        dragMaxValue={dragMaxValue}
      />
      <g transform="translate(0 24)">
        {marksControl}
      </g>
    </svg>
  );
};
