/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { setContextParameterHelp } from '../../store/contexthelp';
import { UnsupportedControl } from '../../components/UnsupportedControl';
import VariableFloatEditorControl
  from '../../components/ParameterEditor/VariableEditorControl/VariableFloatEditorControl/VariableFloatEditorControl';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../hooks/useContextDataset';
import { LoadingControl } from '../../components/LoadingControl';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';

export interface RangeWithSpanTitle {
  label: DeviceModelStatus.LocString;
  description?: DeviceModelStatus.LocString;
  help?: DeviceModelStatus.LocString;
}

export interface RangeWithSpanReduxProps {
  readonly?: boolean;
  target?: DatasetType;
  controlType: DeviceModelStatus.UI.RangeWithSpanControlType;
  deviceInstanceId: string;
}

export const RangeWithSpanControl: React.FC<RangeWithSpanReduxProps> = (props: RangeWithSpanReduxProps)
  : React.ReactElement => {
  const {
    readonly,
    controlType,
    target, deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const rangeStartDatasetValue = useContextDatasetStatusValue(deviceInstanceId, controlType.rangeStart, target);
  const rangeStartStatusDescriptor = useContextDatasetDescriptor(deviceInstanceId, controlType.rangeStart, target);

  const rangeEndDatasetValue = useContextDatasetStatusValue(deviceInstanceId, controlType.rangeEnd, target);
  const rangeEndStatusDescriptor = useContextDatasetDescriptor(deviceInstanceId, controlType.rangeEnd, target);

  const spanVarDatasetValue = useContextDatasetStatusValue(deviceInstanceId, controlType.spanVar, target);
  const spanVarStatusDescriptor = useContextDatasetDescriptor(deviceInstanceId, controlType.spanVar, target);

  if (rangeStartStatusDescriptor === undefined) {
    return <UnsupportedControl text={`RangeWithSpanControl: Undefined object ${controlType.rangeStart}`} />;
  }
  if (rangeEndStatusDescriptor === undefined) {
    return <UnsupportedControl text={`RangeWithSpanControl: Undefined object ${controlType.rangeEnd}`} />;
  }
  if (spanVarStatusDescriptor === undefined) {
    return <UnsupportedControl text={`RangeWithSpanControl: Undefined object ${controlType.spanVar}`} />;
  }
  if ((rangeStartStatusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor)
    || (rangeEndStatusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor)
    || (spanVarStatusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor)) {
    return <UnsupportedControl text="RangeWithSpanControl: Unsupported object type" />;
  }
  if ((spanVarStatusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT)
    && (spanVarStatusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER)
    && (spanVarStatusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER)) {
    return <UnsupportedControl text="RangeWithSpanControl: Unsupported value type" />;
  }
  if (rangeStartDatasetValue === undefined || rangeEndDatasetValue === undefined || spanVarDatasetValue === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  let warningMessage: string | undefined;
  if (spanVarDatasetValue.valueValidity === DeviceModelStatus.StatusValueValidity.outOfRange) {
    const ranges = spanVarDatasetValue.valueValidityDescription;
    const rangeRegions = spanVarStatusDescriptor.valueType.range?.regions;
    const { minValue, maxValue } = rangeRegions?.[0] ?? {};
    const [rangeHasMin, rangeHasMax] = [!!minValue, !!maxValue];

    if ((rangeRegions?.length === 1) && (rangeHasMin !== rangeHasMax)) {
      if (rangeHasMin) {
        // display: Distance of values must be at least {{MIN}}
        warningMessage = t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_SPAN__MIN', { MIN: minValue });
      } else {
        // display: Distance of values must not exceed {{MAX}}
        warningMessage = t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_SPAN__MAX', { MAX: maxValue });
      }
    } else {
      // display: Distance of values must be within {{INTERVAL}}
      warningMessage = t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_SPAN__RANGE', { INTERVAL: ranges });
    }
  }

  return (
    <>
      <VariableFloatEditorControl
        readonly={readonly}
        deviceInstanceId={deviceInstanceId}
        statusDescriptor={rangeStartStatusDescriptor}
        statusValue={rangeStartDatasetValue}
        onHideContentHelp={() => {
          dispatch(setContextParameterHelp({
            title: '',
            text: '',
          }));
        }}
        warning={warningMessage}
      />
      <VariableFloatEditorControl
        readonly={readonly}
        statusDescriptor={rangeEndStatusDescriptor}
        statusValue={rangeEndDatasetValue}
        deviceInstanceId={deviceInstanceId}
        onHideContentHelp={() => {
          dispatch(setContextParameterHelp({
            title: '',
            text: '',
          }));
        }}
        warning={warningMessage ? ' ' : undefined}
      />
    </>
  );
};
