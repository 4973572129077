/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { ChannelSwitch } from './ChannelSwitch/ChannelSwitch';
import './ChannelSwitchNumber.scss';
import { CaparocChannelStateType } from '../../../../CaparocConst';

export interface ChannelSwitchNumberProps {
  id: string;
  state: CaparocChannelStateType;
  label: string,
  disabled?: boolean;
  onSwitchChannel: () => void;
}

// eslint-disable-next-line max-len
export const ChannelSwitchNumber: React.FC<ChannelSwitchNumberProps> = (props: ChannelSwitchNumberProps):
  React.ReactElement => {
  const {
    id, state, label, onSwitchChannel, disabled,
  } = props;

  return (
    <div className="caparoc-channel-switch-container">
      <div className="caparoc-channel-switch-container__header">
        <span className="caparoc-channel-switch-container__header__label">{label}</span>
      </div>
      <ChannelSwitch
        id={id}
        state={state}
        onSwitchChannel={() => {
          if (!disabled) {
            onSwitchChannel();
          }
        }}
        disabled={disabled}
      />
    </div>
  );
};
