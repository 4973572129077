/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { IdentRef } from '@gpt/commons';
import React, { PropsWithChildren } from 'react';
import './Trio3ChannelSwitchBlockControl.css';

export interface ChannelSwitchBlockVariable {
    identRef: IdentRef;
    value: number;
}

export interface Trio3ChannelSwitchBlockControlProps extends PropsWithChildren {
  label: string;
}

// eslint-disable-next-line max-len
const Trio3ChannelSwitchBlockControl: React.FC<Trio3ChannelSwitchBlockControlProps> = (props: Trio3ChannelSwitchBlockControlProps):
  React.ReactElement => {
  const {
    label, children,
  } = props;

  return (
    <div className="trio3-channel-switch-block-container">
      <div className="trio3-channel-switch-block-container__header">{label}</div>
      <div className="trio3-channel-switch-block-container__switches">
        {children}
      </div>
    </div>
  );
};

export default Trio3ChannelSwitchBlockControl;
