/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import React from 'react';
import DisplayFormat from '../../../helpers/displayFormat';
import { PreviewLabelWidth, PreviewUnitWidth, PreviewValueWidth } from '../PreviewLayout';
import './PreviewEditorControl.css';

export interface PreviewEditorControlProps {
    label?: string;
    value: string | number;
    displayFormat: string;
    warning?: string;
    unit?: string;
}

const PreviewEditorControl:
React.FC<PreviewEditorControlProps> = (props: PreviewEditorControlProps):
React.ReactElement => {
  const {
    label, value, unit,
    warning, displayFormat,
  } = props;

  return (
    <div className="preview-editor-control d-flex align-items-center pb-2" data-testid="preview-editor-control">
      <div className={`preview-editor-control__label col-${PreviewLabelWidth} m-0 p-0`}>
        <p>{label}</p>
      </div>
      <div className={`preview-editor-control__value col-${PreviewValueWidth} m-0 px-1`}>
        <div className="preview-editor-control__value__input-field input-field">
          <input
            type="text"
            title={label}
            value={DisplayFormat(displayFormat, value)}
            className={`preview-editor-control__value__input textfield__input ${warning !== undefined ? 'invalid' : ''}`}
            readOnly
            onChange={() => 0}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="textfield__label">{}</label>
          <span className="preview-editor-control__value__warning helper-text" data-error={warning} />
        </div>
      </div>
      <div className={`preview-editor-control__unit col-${PreviewUnitWidth} m-0 p-0`}>
        <p>{unit}</p>
      </div>
    </div>
  );
};

export default PreviewEditorControl;
