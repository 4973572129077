/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  ApplicationMessageAction,
  REMOVE_APPLICATION_MESSAGE,
  ApplicationMessageState,
  ADD_APPLICATION_MESSAGE,
  AddApplicationMessageAction,
  RemoveApplicationMessageAction,
  CLEANUP_APPLICATION_MESSAGES,
} from './types';

const initialState: ApplicationMessageState = {
  messageList: [],
};

const addApplicationMessageReducer = (
  state: ApplicationMessageState,
  action: AddApplicationMessageAction,
) => {
  const newMessage = {
    type: action.payload.type,
    id: action.payload.id,
    messageId: action.payload.messageId,
    show: true,
  };

  return {
    ...state,
    messageList: [...state.messageList, newMessage],
  };
};

const removeApplicationMessageReducer = (
  state: ApplicationMessageState,
  action: RemoveApplicationMessageAction,
) => {
  const id = action.payload;
  const newMessageList = state.messageList.filter((item) => item.id !== id);

  return {
    ...state,
    messageList: newMessageList,
  };
};

const cleanupApplicationMessageReducer = (
  state: ApplicationMessageState,
) => ({
  ...state,
  messageList: [],
});

const applicationMessageReducer = (
  state = initialState,
  action: ApplicationMessageAction,
): ApplicationMessageState => {
  switch (action.type) {
    case ADD_APPLICATION_MESSAGE:
      return addApplicationMessageReducer(state, action);
    case REMOVE_APPLICATION_MESSAGE:
      return removeApplicationMessageReducer(state, action);
    case CLEANUP_APPLICATION_MESSAGES:
      return cleanupApplicationMessageReducer(state);
    default:
      return state;
  }
};

export default applicationMessageReducer;
