/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { LoadingSpinner } from '../../components/LoadingControl/LoadingSpinner';
// eslint-disable-next-line import/no-unresolved
import i18n from './i18n-LoadingSplashScreen.json';
import './LoadingSplashScreen.css';
import DownloadLinkButton from './DownloadLinkButton/DownloadLinkButton';
import { LocalBackendVersionStatus } from '../hook/useBackendVersion';

const i18nTranslator = (lang: string, key: string): string => {
  const text = i18n[lang]?.[key] ?? i18n.en?.[key];
  return text ?? String(key);
};

export interface LoadingSplashScreenProps {
  status: LocalBackendVersionStatus;
}

// eslint-disable-next-line import/prefer-default-export
const LoadingSplashScreen: React.FC<LoadingSplashScreenProps> = (props: LoadingSplashScreenProps): React.ReactElement => {
  const language = window.navigator.language.substring(0, 2); // → "en-US" / "de-DE"
  const lang = (language === 'en' || language === 'de') ? language : 'en';
  const { status } = props;

  return (
    <div className="loading-splash-screen w-100 h-100 d-flex flex-column align-items-center justify-content-center">
      <div className="loading-splash-screen__header">
        {i18nTranslator(lang, 'hdr_connection_to_server')}
      </div>
      <div className="loading-splash-screen__spinner d-flex flex-column align-items-center justify-content-center">
        <LoadingSpinner size="splash" />
      </div>
      <div className="loading-splash-screen__text">
        {i18nTranslator(lang, 'txt_connecting_to_cxe')}
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <DownloadLinkButton status={status} />
      </div>
    </div>
  );
};

export default LoadingSplashScreen;
