/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OverlayPortalProps = PropsWithChildren<any>

const OverlayPortal: React.FC<OverlayPortalProps> = (props: OverlayPortalProps) => {
  const { children } = props;
  const mount = document.getElementById('overlay-root');
  const rootdiv = document.createElement('div');
  rootdiv.setAttribute('id', 'overlay-container');
  rootdiv.setAttribute('style', 'position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index:20000;');

  const el = useRef(rootdiv);

  useEffect(() => {
    const { current } = el;
    // We assume `modalRoot` exists with '!'
    mount?.appendChild(current);
    return () => {
      mount?.removeChild(current);
    };
  }, []);

  return createPortal(children, el.current);
};

export default OverlayPortal;
