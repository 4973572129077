/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import { ChannelColorType } from '../../CaparocHelper';
import './CaparocPaperControl.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CaparocPaperControlProps extends PropsWithChildren<any> {
  title?: string,
  rightBreak?: boolean,
  leftBreak?: boolean,
  color: ChannelColorType,
}

export const CaparocPaperControl: React.FC<CaparocPaperControlProps> = (props: CaparocPaperControlProps): React.ReactElement => {
  const {
    title, rightBreak, leftBreak, children,
    color,
  } = props;

  let caparocPaperControlClass = 'caparoc-paper-control';
  if (rightBreak) {
    caparocPaperControlClass = 'caparoc-paper-control__right-break';
  } else if (leftBreak) {
    caparocPaperControlClass = 'caparoc-paper-control__left-break';
  }

  return (
    <div className={caparocPaperControlClass} data-testid="caparoc-paper-control">
      <div className={`caparoc-paper-control__status caparoc-paper-control__status--${color}`} />
      {title && (
        <div className="caparoc-paper-control__header">
          <p className="caparoc-paper-control__header-title">{title}</p>
        </div>
      )}
      <div className="caparoc-paper-control__content d-flex flex-column justify-content-start align-items-start h-100">
        {children}
      </div>
    </div>
  );
};
