/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Services } from '@gpt/commons';
import { CompareModeType } from '../../../../../views/CompareView/common';
import { ButtonFunction, CompareStatusValueProps } from '../../../CompareTypes';
import CompareTableValue from './CompareTableValue/CompareTableValue';
import { useTypedSelector, writeActiveDeviceVariableValues } from '../../../../../store';
import { AreRecordsEqual } from '../../../../PreviewTableControl/PreviewTableControl';
import { useContextDatasetValues } from '../../../../../hooks/useContextDataset';
import { tableRecordSelector } from '../../../selectors/selectTableRecords';

/**
 * Compare two values from Dataset Value : value vs externalValue
 * backupValue used as storage for value before modification
 *
 * @param props Control Properties
 * @returns
 */
const CompareTableStatusValue
: React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
  // eslint-disable-next-line arrow-body-style
  : React.ReactElement | null => {
  const {
    parentId,
    compareMode,
    identRef, leftDataset, rightDataset,
    onShowContentHelp, onHideContentHelp,
    showImageAndSign,
    deviceInstanceId,
  } = props;

  const dispatch = useDispatch();
  const rightValues = useContextDatasetValues(deviceInstanceId, rightDataset);

  const leftRecords = useTypedSelector(
    (state) => tableRecordSelector(state.deviceInstances, deviceInstanceId, leftDataset, identRef),
    (prev, curr) => AreRecordsEqual(prev, curr),
  );
  const rightRecords = useTypedSelector(
    (state) => tableRecordSelector(state.deviceInstances, deviceInstanceId, rightDataset, identRef),
    (prev, curr) => AreRecordsEqual(prev, curr),
  );

  const equal = AreRecordsEqual(leftRecords, rightRecords);
  if (compareMode === CompareModeType.ShowDifference && equal) {
    return (null);
  }

  const readonly = true;
  return (
    <CompareTableValue
      label=""
      key={`cmp-table-${parentId}-${identRef}-${leftDataset}-${rightDataset}`}
      equal={equal}
      leftRecords={leftRecords}
      rightRecords={rightRecords}
      onButtonClick={() => {
        if (equal) {
          return;
        }
        const tableValues: Services.DeviceModel.StatusValueRef[] = rightRecords.reduce((acc, record) => {
          const recValues: Services.DeviceModel.StatusValueRef[] = record.values.reduce((macc, member) => {
            if (member.identRef === undefined) {
              return macc;
            }
            const statusValue = rightValues[member.identRef];
            return [
              ...macc,
              {
                value: rightValues[member.identRef].value,
                backupValue: statusValue.backupValue,
                identRef: statusValue.identRef,
                modified: true,
              }];
          }, [] as Services.DeviceModel.StatusValueRef[]);

          return [
            ...acc,
            ...recValues,
          ];
        }, [] as Services.DeviceModel.StatusValueRef[]);
        dispatch(writeActiveDeviceVariableValues(deviceInstanceId, tableValues));
      }}
      modified={false}
      readonly={readonly}
      onHideContentHelp={onHideContentHelp}
      onShowContentHelp={onShowContentHelp}
      buttonFunction={equal ? ButtonFunction.Hidden : ButtonFunction.WriteValue}
      showImageAndSign={showImageAndSign}
    />
  );
};

export default CompareTableStatusValue;
