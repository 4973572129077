/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { VariableEditorControlsProps } from '../../../../components/ParameterEditor/VariableEditorControl/types';
import { UnsupportedControl } from '../../../../components/UnsupportedControl';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { setContextParameterWarning, setContextParameterHelp } from '../../../../store/contexthelp';
import { updateDeviceDatasetValues } from '../../../../store/deviceInstances/store/deviceDataset';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import NumberEditorControl from '../../Quint4NumberValueEditor/NumberEditorControl/NumberEditorControl';

export interface AnalogStartEndControlProps extends VariableEditorControlsProps {
  warning?: string;
  showLabel?: boolean;
}

const AnalogStartEndControl
:React.FC<AnalogStartEndControlProps> = (props: AnalogStartEndControlProps):
  React.ReactElement => {
  const {
    deviceInstanceId,
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp,
    showContextMessageDef, readonly, warning, showLabel,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);
  const [showContextMessage, setShowContextMessage] = useState<boolean>(showContextMessageDef ?? false);

  const statusValueLabel = t(statusDescriptor.label);
  const statusValueHelp = t(statusDescriptor.help);

  const { valueValidityDescription, valueValidity } = statusValue ?? {
    valueValidityDescription: undefined, valueValidity: DeviceModelStatus.StatusValueValidity.valid,
  };

  useEffect(() => {
    if (statusValue === undefined) {
      return;
    }

    const updateMessage = (message?: string) => {
      if (showContextMessage) {
        dispatch(setContextParameterWarning(message));
      }
      setWarningMessage(message);
    };

    const parsedValue = parseFloat(statusValue.value);
    if (Number.isNaN(parsedValue)) {
      updateMessage(`${t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT')}`);
    } else if (valueValidity === DeviceModelStatus.StatusValueValidity.valid) {
      updateMessage(undefined);
    } else {
      updateMessage(`${t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__INTERVALS', {
        INTERVAL: valueValidityDescription,
      })}`);
    }
  }, [statusValue, showContextMessage, valueValidityDescription, valueValidity]);

  if (statusDescriptor === undefined) {
    return <div />;
  }
  if (statusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="VariableUIntegerEditorControl: Unsupported input object type" />;
  }
  if (statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
    return <UnsupportedControl text="VariableDateEditorControl: only FLOAT type is supported" />;
  }

  const { label, unit } = statusDescriptor;
  const { displayFormat, stepSize } = statusDescriptor.valueType;

  return (
    <NumberEditorControl
      label={showLabel === false ? undefined : t<string>(label)}
      value={statusValue === undefined ? '' : statusValue.value}
      readonly={readonly ?? (statusValue === undefined || statusDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY)}
      modified={statusValue?.modified}
      unit={unit === undefined ? undefined : t<string>(unit)}
      stepSize={stepSize}
      displayFormat={displayFormat ?? '%.2f'}
      onValueChange={(originalValue) => {
        const endValue = parseFloat(originalValue);
        if (Number.isNaN(endValue)) {
          dispatch(updateDeviceDatasetValues(deviceInstanceId, {
            targetDataset: DatasetType.user,
            values: [{
              value: originalValue,
              backupValue: statusValue?.backupValue,
              identRef: statusDescriptor.identRef,
              valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
              valueValidityDescription: `${t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT')}`,
            }],
          }));
        } else {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            value: endValue,
            backupValue: statusValue?.backupValue,
            identRef: statusDescriptor.identRef,
          }]));
        }
      }}
      onFocus={() => {
        setShowContextMessage(true);
        if (statusDescriptor !== undefined) {
          const range = DeviceModelStatus.Functions.GetDescriptorRange(statusDescriptor);
          const message = (range?.rangeAsText === undefined) ? '' : t<string>('PARAMETER__VALID_VALUE_RANGE__HELP', {
            INTERVAL: range?.rangeAsText ?? '',
          });
          dispatch(setContextParameterHelp({
            title: statusValueLabel,
            text: statusValueHelp,
            message,
          }));
        }
        onShowContentHelp?.();
      }}
      onBlur={() => {
        setShowContextMessage(false);
        dispatch(setContextParameterWarning(undefined));
        dispatch(setContextParameterHelp({
          title: '',
          text: '',
        }));
        onHideContentHelp?.();
      }}
      warning={warningMessage ?? warning}
    />
  );
};

export default AnalogStartEndControl;
