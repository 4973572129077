/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { IdentRef, Services } from '@gpt/commons';
import {
  Dispatch, Middleware, MiddlewareAPI,
} from 'redux';
import { IWebWorkerDeviceManager } from '../../../../services/WebWorkerDevice/WebWorkerDeviceManager';
import { ExecutionState } from '../../../common';
import {
  TIMELINE__REQUEST_DATA, typeTimelineDataMiddlewareActionTypes,
} from './types';
import { setTimelineData, setTimelineDataState } from '../../store/timelineService/actions';
import { DatasetType } from '../../store/deviceDataset/types';
import { deviceDiscoveryServiceSelector, deviceInstancesStoreSelector } from '../../../reduxStoreSelector';
import { deviceConnectionStatusSelector } from '../../hooks/StandardViewHooks';

const timelineServiceMiddleware = (webWorkerDeviceManager: IWebWorkerDeviceManager): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeTimelineDataMiddlewareActionTypes>(action: A): Promise<A> => {
  const readTimelineData = async (instanceId: string, identRef: IdentRef[])
  : Promise<Services.DeviceModelServer.DeviceTimelineData[]> => {
    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const webWorkerInstance = deviceInstances.instances[instanceId]?.activeDevice.modelInstance?.webWorkerInstanceRef;
    if (webWorkerInstance === undefined) {
      return [];
    }
    const webWorkerDevice = webWorkerDeviceManager.get(webWorkerInstance);
    if (webWorkerDevice === undefined) {
      return [];
    }
    const data = await webWorkerDevice.get(DatasetType.device).requestTrendVariableValues(identRef);
    return Object.keys(data).map((key) => data[key]);
  };

  if (action.type === TIMELINE__REQUEST_DATA) {
    const { targetInstance, identList } = action.payload;
    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const deviceDisoveryState = deviceDiscoveryServiceSelector(api.getState());
    const connectionStatus = deviceConnectionStatusSelector(deviceInstances.instances, deviceDisoveryState, targetInstance);
    if (connectionStatus !== Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = await readTimelineData(targetInstance, identList);
      if (response !== undefined) {
        api.dispatch(setTimelineData(targetInstance, response));
        api.dispatch(setTimelineDataState(targetInstance, ExecutionState.success));
      } else {
        api.dispatch(setTimelineDataState(targetInstance, ExecutionState.failed));
      }
    } else {
      api.dispatch(setTimelineDataState(targetInstance, ExecutionState.failed));
    }
  }
  const result = next(action);
  return result;
};

export default timelineServiceMiddleware;
