/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import './DeviceRotarySwitch.scss';
import SVGDeviceRotarySwitch from './SVGDeviceRotarySwitch/SVGDeviceRotarySwitch';

export const ROTARY_SWITCH_MODE__NOT_AVAILABLE = 0;
export const ROTARY_SWITCH_MODE__NORC = 1;
export const ROTARY_SWITCH_MODE__RC = 2;

export interface DeviceRotarySwitchProps {
  nominalCurrentValue: number;
  maxCurrentValue: number;
  rotarySwitchValue: number;
}

const DeviceRotarySwitch: React.FC<DeviceRotarySwitchProps> = (props: DeviceRotarySwitchProps):
  React.ReactElement | null => {
  const {
    nominalCurrentValue,
    rotarySwitchValue,
    maxCurrentValue,
  } = props;

  if (rotarySwitchValue === ROTARY_SWITCH_MODE__NOT_AVAILABLE) {
    return <></>;
  }

  const arrayLength = Math.floor(maxCurrentValue / 2);
  const positions = maxCurrentValue < 7
    ? [...Array(maxCurrentValue).keys()].map((va) => `${va + 1}`)
    : [...Array(arrayLength).keys()].map((va) => `${va * 2 + 2}`);
  const switchPositionsValues = [
    'RC',
    ...positions,
  ];

  return (
    <SVGDeviceRotarySwitch
      nominalCurrentVaue={nominalCurrentValue}
      rotarySwitchValue={rotarySwitchValue}
      switchPositionsValues={switchPositionsValues}
    />
  );
};

export default DeviceRotarySwitch;
