/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './DeviationPoint.css';

export interface DeviationPointProps {
  x: number;
  y: number;
  color: string;
}

export const DeviationPoint: React.FC<DeviationPointProps> = (props: DeviationPointProps): React.ReactElement => {
  const { x, y, color } = props;

  return (
    <div
      className="DeviationPoint"
      style={{ left: `${x - 4}px`, bottom: `${y - 4}px`, background: `${color}` }}
      data-testid="deviation-point"
    />
  );
};
