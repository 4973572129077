/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import OnlineCardControl from '../OnlineCardControl/OnlineCardControl';
import SQDeviceStatusLed from '../../controls/SQDeviceStatusLed/SQDeviceStatusLed';

export interface SystemStatusCockpitCardProps {
  deviceInstanceId: string;
  statusView?: DeviceModelStatus.UI.Contactron.MotorStarterStatusView;
}

const SystemStatusOnlineCard: React.FC<SystemStatusCockpitCardProps> = (props: SystemStatusCockpitCardProps):
  React.ReactElement => {
  const {
    statusView,
    deviceInstanceId,
  } = props;

  const deviceStatusItems = (statusView?.items ?? [])
    .map((itemIdent) => <SQDeviceStatusLed key={`contactron-system-status-online--${deviceInstanceId}-${itemIdent}`} identRef={itemIdent} deviceInstanceId={deviceInstanceId} onStateChanged={() => 0} />);

  return (
    <OnlineCardControl label={statusView?.label ?? ''}>
      <div className="contactron-system-status">
        {deviceStatusItems}
      </div>
    </OnlineCardControl>
  );
};

export default SystemStatusOnlineCard;
