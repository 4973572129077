/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useRef, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../../store';
import { setLinearizationDatasetPointCounter } from '../../../../../store/linearizationDataset/actions';
import { LinearizationTableItem } from '../../../../../store/linearizationDataset/types';
import InputBoxControl from '../../../../Basic/InputBoxControl/InputBoxControl';
import OverlayPortal from '../../../../OverlayPortal/OverlayPortal';
import { MaxRegression } from '../../MaxRegression/MaxRegression';
import { SmallTable } from '../../SmallTable/SmallTable';
import { SmallTableWithKaltStelle } from '../../SmallTable/SmallTableWithKaltstelle';
import { ActionSubmenuTypes, LinearizationActionSubmenu } from '../../TableExpand/LinearizationActionSubmenu';
import './SmallTableView.css';

export interface SmallTableViewProps {
    items: LinearizationTableItem[];
    expandIcon: string;
    header1: string;
    header2: string;
    pointsCounter: number,
    onXValueChange: (pointIndex: number, pointValue: string) => void;
    onYValueChange: (pointIndex: number, pointValue: string) => void;
    onAction: (action: ActionSubmenuTypes) => Promise<void>;
}

export const SmallTableView:React.FC<SmallTableViewProps> = (props: SmallTableViewProps): React.ReactElement => {
  const {
    items, expandIcon, header1, header2, pointsCounter, onXValueChange, onYValueChange, onAction,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const menuPositionRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const sensorType = useTypedSelector((state) => state.linearizationDataset.sensorType);
  const coldJunctionComp0 = useTypedSelector((state) => state.linearizationDataset.coldJunctionComp0);
  const coldJunctionComp80 = useTypedSelector((state) => state.linearizationDataset.coldJunctionComp80);

  const [showExpandMenu, setShowExpandMenu] = useState(false);

  const topPosition = menuPositionRef?.current?.getClientRects()[0].top;
  const leftPosition = menuPositionRef?.current?.getClientRects()[0].left;

  return (
    <div className="smallTableView-container">
      <div className="smallTableView-container-top">
        <div
          ref={menuPositionRef}
          className="smallTableView-container-top-icon-container "
          role="button"
          tabIndex={0}
          onClick={() => {
            setShowExpandMenu(!showExpandMenu);
          }}
          onKeyUp={() => {}}
        >
          <Image src={expandIcon} className="smallTableView-container-top-icon-container-icon" />
        </div>
        { showExpandMenu && (
        <OverlayPortal>
          <div
            role="none"
            className="smallTableView-container-top-expand-overlay"
            onClick={() => setShowExpandMenu(false)}
          >
            <div
              className="smallTableView-container-top-expand-container"
              ref={menuRef}
              style={{
                position: 'absolute',
                top: topPosition === undefined
                  ? undefined
                  : topPosition + 16,
                left: leftPosition === undefined
                  ? undefined
                  : leftPosition + 16,
              }}
            >
              <LinearizationActionSubmenu
                onAction={async (action) => {
                  await onAction(action);
                  setShowExpandMenu(false);
                }}
              />
            </div>
          </div>
        </OverlayPortal>
        )}
        <div className="smallTableView-container-top-headeritem">
          <div className="smallTableView-container-top-headeritem-label">
            {t<string>('LINTABLE__TABLE_VIEW__USER_POINTS__LABEL')}
          </div>
          <div className="smallTableView-container-top-headeritem-inputbox">
            <InputBoxControl
              type="number"
              minValue={2}
              maxValue={30}
              displayFormat="%d"
              value={pointsCounter}
              onChange={(value) => {
                const parsedValue = parseInt(value, 10);
                if (!Number.isNaN(parsedValue)) {
                  dispatch(setLinearizationDatasetPointCounter(parsedValue));
                }
              }}
            />
          </div>
        </div>
        <div className="smallTableView-container-top-headeritem">
          <MaxRegression />
        </div>
      </div>
      { sensorType === 'TC'
          && (
          <SmallTableWithKaltStelle
            items={items}
            header1={header1}
            header2={header2}
            coldJunctionComp0={coldJunctionComp0 ?? { value: 0, displayFormat: '%.2f' }}
            coldJunctionComp80={coldJunctionComp80 ?? { value: 0, displayFormat: '%.2f' }}
            onXValueChange={(pointIndex: number, pointValue: string) => onXValueChange(pointIndex, pointValue)}
            onYValueChange={(pointIndex: number, pointValue: string) => onYValueChange(pointIndex, pointValue)}
          />
          )}
      { sensorType !== 'TC' && (
        <SmallTable
          items={items}
          header1={header1}
          header2={header2}
          onXValueChange={(pointIndex: number, pointValue: string) => onXValueChange(pointIndex, pointValue)}
          onYValueChange={(pointIndex: number, pointValue: string) => onYValueChange(pointIndex, pointValue)}
        />
      )}
    </div>
  );
};
