/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useRef } from 'react';
import FwdRefInputBoxControl from '../../Basic/FwdRefInputBoxControl/FwdRefInputBoxControl';
import './IPV4AddressControl.css';

const addressDelimiter = '.';

export interface IPV4AddressControlProps {
    value: string;
    warning?: string;
    modified?: boolean;
    readonly?: boolean;
    // eslint-disable-next-line no-unused-vars
    onValueChange: (value: string) => void;
    onShowContextHelp?: () => void;
    onHideContextHelp?: () => void;
}

const ipValueToNumbers = (value: string): number[] => {
  const values = value.split(addressDelimiter);
  return values.map((va) => Number.parseInt(va, 10));
};

const extractIpValue = (value: string, index: number): number => {
  const values = ipValueToNumbers(value);
  return values[index];
};

const isValidIpValue = (value: string, index: number): boolean => {
  const values = ipValueToNumbers(value);
  if (index > values.length) {
    return false;
  }

  const ipvalue = values[index];
  if (Number.isNaN(ipvalue)) {
    return false;
  }

  const minValue = 0;
  if (ipvalue < minValue || ipvalue > 255) {
    return false;
  }
  return true;
};

const numbersToIpAddress = (ipValue1: number, ipValue2: number, ipValue3: number, ipValue4: number)
  : string => `${ipValue1}${addressDelimiter}${ipValue2}${addressDelimiter}${ipValue3}${addressDelimiter}${ipValue4}`;

export const IPV4AddressControl:
React.FC<IPV4AddressControlProps> = (props: IPV4AddressControlProps): React.ReactElement => {
  const {
    value, readonly,
    warning, modified,
    onValueChange, onHideContextHelp, onShowContextHelp,
  } = props;

  const refIpAddress2 = useRef<HTMLInputElement>(null);
  const refIpAddress3 = useRef<HTMLInputElement>(null);
  const refIpAddress4 = useRef<HTMLInputElement>(null);

  const ipValues = ipValueToNumbers(value);

  return (
    <div className="ipv4-address-container d-flex flex-column align-items-center justify-content-center" data-testid="ipv4-address-control">
      <div className="ipv4-address-container__input d-flex flex-row align-items-center justify-content-center">
        <div className="ipv4-address-container__input__inputbox">
          <FwdRefInputBoxControl
            type="number"
            value={extractIpValue(value, 0)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const ipValue = Number.parseInt(e.target.value, 10);
              const ipaddr = numbersToIpAddress(ipValue, ipValues[1], ipValues[2], ipValues[3]);
              if (!Number.isNaN(ipValue)) {
                if (e.target.value.length === 3) {
                  refIpAddress2.current?.focus();
                }
              }
              onValueChange(ipaddr);
            }}
            readonly={readonly}
            warning={isValidIpValue(value, 0) ? undefined : ''}
            onFocus={() => onShowContextHelp?.()}
            onBlur={() => onHideContextHelp?.()}
            edited={modified}
            maxLength={3}
          />
        </div>
        <div className="ipv4-address-container__delimeter">{addressDelimiter}</div>
        <div className="ipv4-address-container__input__inputbox">
          <FwdRefInputBoxControl
            type="number"
            ref={refIpAddress2}
            value={extractIpValue(value, 1)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const ipValue = Number.parseInt(e.target.value, 10);
              const ipaddr = numbersToIpAddress(ipValues[0], ipValue, ipValues[2], ipValues[3]);
              if (!Number.isNaN(ipValue)) {
                if (e.target.value.length === 3) {
                  refIpAddress3.current?.focus();
                }
              }
              onValueChange(ipaddr);
            }}
            readonly={readonly}
            warning={isValidIpValue(value, 1) ? undefined : ''}
            onFocus={() => onShowContextHelp?.()}
            onBlur={() => onHideContextHelp?.()}
            edited={modified}
            maxLength={3}
          />
        </div>
        <div className="ipv4-address-container__delimeter">{addressDelimiter}</div>
        <div className="ipv4-address-container__input__inputbox">
          <FwdRefInputBoxControl
            type="number"
            ref={refIpAddress3}
            value={extractIpValue(value, 2)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const ipValue = Number.parseInt(e.target.value, 10);
              const ipaddr = numbersToIpAddress(ipValues[0], ipValues[1], ipValue, ipValues[3]);
              if (!Number.isNaN(ipValue)) {
                if (e.target.value.length === 3) {
                  refIpAddress4.current?.focus();
                }
              }
              onValueChange(ipaddr);
            }}
            readonly={readonly}
            warning={isValidIpValue(value, 2) ? undefined : ''}
            onFocus={() => onShowContextHelp?.()}
            onBlur={() => onHideContextHelp?.()}
            edited={modified}
            maxLength={3}
          />
        </div>
        <div className="ipv4-address-container__delimeter">{addressDelimiter}</div>
        <div className="ipv4-address-container__input__inputbox">
          <FwdRefInputBoxControl
            type="number"
            ref={refIpAddress4}
            value={extractIpValue(value, 3)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const ipValue = Number.parseInt(e.target.value, 10);
              const values = ipValueToNumbers(value);
              const ipaddr = numbersToIpAddress(ipValues[0], values[1], ipValues[2], ipValue);
              onValueChange(ipaddr);
            }}
            warning={isValidIpValue(value, 3) ? undefined : ''}
            onFocus={() => onShowContextHelp?.()}
            onBlur={() => onHideContextHelp?.()}
            edited={modified}
            maxLength={3}
          />
        </div>
      </div>
      <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
        <div className="input-field-control__warning-text">{warning}</div>
      </div>
    </div>
  );
};
