/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { HorMenuLayoutControl } from './HorMenuLayoutControl';
import UnsupportedControl from '../../../components/UnsupportedControl/UnsupportedControl';
import { ReduxControlProps } from '../../ReduxControlProps';
import { useContextDatasetDescriptor } from '../../../hooks/useContextDataset';
import withControlVisibility from '../../hoc/withControlVisibility';

// eslint-disable-next-line max-len
const HorMenuLayoutReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps): React.ReactElement => {
  const {
    identRef, onStateChanged,
    readonly, rootLayout,
    deviceInstanceId,
  } = props;

  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  if (descriptor === undefined) {
    return <UnsupportedControl text={`HorMenuLayoutReduxControl: Undefined object ${identRef}`} />;
  }

  return (
    <HorMenuLayoutControl
      deviceInstanceId={deviceInstanceId}
      structureDescriptor={descriptor}
      onStateChanged={onStateChanged}
      readonly={readonly}
      rootLayout={rootLayout}
    />
  );
};

export default withControlVisibility(HorMenuLayoutReduxControl);
