/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */

import React from 'react';

export interface ForceDialogSessionContextProps {
  // eslint-disable-next-line no-unused-vars
  resetSession: () => void;
}

export interface ForceDialogContextProps {
    enableWarningDialog: boolean;
    // eslint-disable-next-line no-unused-vars
    setEnableWarningDialog: (value: boolean) => void;
}

export interface ForceDialogPasswordContextProps {
    password: string;
    // eslint-disable-next-line no-unused-vars
    setPassword: (value?: string) => void;
}

export const ForceDialogContext = React.createContext<ForceDialogContextProps>({
  enableWarningDialog: true,
  setEnableWarningDialog: () => 0,
});

export const ForceDialogPasswordContext = React.createContext<ForceDialogPasswordContextProps>({
  password: '0',
  setPassword: () => 0,
});

export const ForceDialogSessionContext = React.createContext<ForceDialogSessionContextProps>({
  resetSession: () => 0,
});
