/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import './PreviewTable.css';
import { PreviewTableHeader, Record } from './PreviewTableHeader/PreviewTableHeader';
import { PreviewTableItems } from './PreviewTableItems/PreviewTableItems';

export interface PreviewTableProps {
    records: Record[],
    showNumbering: boolean,
    identifier?: string,
    alignLeft?: boolean,
}

export const PreviewTable:React.FC<PreviewTableProps> = (props: PreviewTableProps): React.ReactElement => {
  const {
    records,
    showNumbering,
    identifier,
    alignLeft,
  } = props;

  if (records[0] === undefined) {
    return <></>;
  }

  const justifyContent = alignLeft ? 'justify-content-start' : 'justify-content-end';
  return (
    <div className="tableControl-container">
      <div className={`tableControl-container-headers ${justifyContent}`}>
        <PreviewTableHeader records={records} identifier={identifier} />
      </div>
      <div className="tableControl-container-bottom">
        <PreviewTableItems records={records} showNumbering={showNumbering} identifier={identifier} alignLeft={alignLeft} />
      </div>
    </div>
  );
};
