/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { useTypedSelector } from '../../../../../store';
import { deviceStatusValueSelector } from '../../../../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetContext } from '../../../../../views/DatasetContext';

const useModuleDeviceName = (stationInstanceId: string, channelNo: string): string => {
  const { targetDataset } = useContext(DatasetContext);
  const deviceNameVarIdentRef = `DEVICE${channelNo}_IFSM_ADDR_DEVICE_NAME`;
  return useTypedSelector((state) => {
    const statusValue = deviceStatusValueSelector(state.deviceInstances, stationInstanceId, targetDataset, deviceNameVarIdentRef);
    return statusValue?.value ?? '';
  });
};

export default useModuleDeviceName;
