/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { activeDeviceStateReducer, initialActiveDeviceState } from './store/activeDevice/reducer';
import { typeDeviceInstanceActiveDeviceAction } from './store/activeDevice/types';
import { typeDeviceDataStorageAction } from './store/dataStorage';
import dataStorageReducer, { initialDataStorageState } from './store/dataStorage/reducer';
import deviceDatasetReducer, { initialDeviceDatasetState } from './store/deviceDataset/reducer';
import { typeDeviceInstanceDeviceDatasetActions, typeDeviceInstanceMultipleDeviceDatasetAction } from './store/deviceDataset/types';
import deviceMethodStateReducer, { initialDeviceMethodState } from './store/deviceMethod/reducer';
import { typeDeviceInstanceDeviceMethodExecutionAction } from './store/deviceMethod/types';
import { initialIoLinkEventMonitorState } from './store/ioLinkEventMonitor/reducer';
import ioLinkSystemCommandStateReducer, { initialIoLinkSystemCommandState } from './store/ioLinkSystemCommand/reducer';
import { typeDeviceInstanceIoLinkSystemCommandAction } from './store/ioLinkSystemCommand/types';
import ioLinkTrio3ChannelSwitchReducer, { initialIoLinkTrio3ChannelSwitchState } from './store/ioLinkTrio3ChannelSwitch/reducer';
import ioLinkTrio3EventMonitorReducer, { initialIoLinkTrio3EventMonitorState } from './store/ioLinkTrio3EventMonitor/reducer';
import timelineServiceReducer, { initialTimelineServiceState } from './store/timelineService/reducer';
import { typeDeviceTimelineDataActionTypes } from './store/timelineService/types';
import {
  CreateDeviceInstanceAction,
  DeviceInstancesState,
  RemoveDeviceInstanceAction,
  SelectDeviceInstanceAction,
  typeDeviceInstanceActionTypes,
} from './types';
import { typeDeviceInstanceIoLinkTrio3SwitchChannelAction } from './store/ioLinkTrio3ChannelSwitch/types';
import { typeDeviceInstanceIoLinkTrio3EventMonitorAction } from './store/ioLinkTrio3EventMonitor/types';
import { typeDeviceInstanceControlStateAction } from './store/controlStateCollection';
import controlStateCollectionReducer, { initialControlStateCollection } from './store/controlStateCollection/reducer';
import customStorageStateReducer, { initialCustomStorageState } from './store/customStorage/reducer';
import { typeDeviceInstanceCustomStorageAction } from './store/customStorage/types';
import previewDatasetReducer, { initialPreviewDatasetState } from './store/previewDataset/reducer';
import { typeDeviceInstancePreviewDatasetAction } from './store/previewDataset/types';
import standardViewReducer, { initialStandardViewState } from './store/standardview/reducer';
import datasetProviderReducer, { initialDatasetProviderState } from './store/datasetProvider/reducer';
import deviceModelResourceProviderReducer, { initialResourceProviderState } from './store/resourceProvider/reducer';
import { typeDeviceInstanceResourceProviderAction } from './store/resourceProvider/types';
import { typeDeviceInstanceDatasetProviderAction } from './store/datasetProvider/types';
import { typeDeviceInstanceStandardViewAction } from './store/standardview/types';
import { ExecutionState } from '../common';
import { initialStationState, stationStateReducer } from './store/stationDiscovery/reducer';
import { typeDeviceInstanceStationAction } from './store/stationDiscovery/types';
import { initialDeviceStateEventMonitorState, deviceStatusEventMonitorReducer } from './store/DeviceStatusEventMonitor/reducer';
import { typeDeviceInstanceDeviceStatusEventMonitorAction } from './store/DeviceStatusEventMonitor/types';
import deviceActionStateReducer, { initialDeviceActionState } from './store/deviceActionState/reducer';
import { typeDeviceInstanceDeviceActionExecutionAction } from './store/deviceActionState/types';
import { DeviceInstanceState } from './store/types';
import deviceInstanceioLinkEventMonitorActionReducer from './store/ioLinkEventMonitor/instanceReducer';

export const initialDeviceInstancesState: DeviceInstancesState = {
  instances: {},
  activeDeviceInstance: undefined,
};

const deviceInstanceCreateActionReducer = (
  state: DeviceInstancesState,
  instanceAction: CreateDeviceInstanceAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, data } = payload;

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        dataStorage: initialDataStorageState,
        timeline: initialTimelineServiceState,
        deviceDataset: data === undefined
          ? initialDeviceDatasetState
          : {
            ...initialDeviceDatasetState,
            init: {
              ...initialDeviceDatasetState.init,
              descriptors: data.dataset.descriptors,
              values: data.dataset.values,
            },
            device: {
              ...initialDeviceDatasetState.device,
              descriptors: data.dataset.descriptors,
              values: data.dataset.values,
            },
            user: {
              ...initialDeviceDatasetState.user,
              descriptors: data.dataset.descriptors,
              values: data.dataset.values,
            },
          },
        deviceMethodExecution: initialDeviceMethodState,
        activeDevice: data === undefined
          ? initialActiveDeviceState
          : {
            ...initialActiveDeviceState,
            modelInstance: {
              device: data.device,
              meta: data.meta,
              webWorkerInstanceRef: data.webworkerInstanceId,
              deviceInstanceId: targetInstance,
              stationInstanceId: data.syncWithInstanceId,
            },
            executionState: ExecutionState.success,
          },
        ioLinkAction: initialIoLinkSystemCommandState,
        ioLinkEventMonitor: initialIoLinkEventMonitorState,
        ioLinkTrio3EventMonitor: initialIoLinkTrio3EventMonitorState,
        ioLinkTrio3ChannelSwitch: initialIoLinkTrio3ChannelSwitchState,
        controlStateCollection: initialControlStateCollection,
        customStorage: initialCustomStorageState,
        previewDataset: initialPreviewDatasetState,
        standardView: initialStandardViewState,
        datasetProvider: initialDatasetProviderState,
        resourceProvider: initialResourceProviderState,
        station: initialStationState,
        deviceStatusEventMonitor: initialDeviceStateEventMonitorState,
        deviceActionState: initialDeviceActionState,
      },
    },
  };
};

const deviceInstanceSelectActionReducer = (
  state: DeviceInstancesState,
  instanceAction: SelectDeviceInstanceAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance } = payload;
  return {
    ...state,
    activeDeviceInstance: targetInstance,
  };
};

const deviceInstanceRemoveActionReducer = (
  state: DeviceInstancesState,
  instanceAction: RemoveDeviceInstanceAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance } = payload;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [targetInstance]: abc, ...instances } = state.instances;
  return {
    ...state,
    instances,
  };
};

const deviceInstanceRemoveAllActionReducer = (
  state: DeviceInstancesState,
): DeviceInstancesState => ({
  ...state,
  activeDeviceInstance: undefined,
  instances: {},
});

const deviceDeviceDataStorageActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceDataStorageAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.dataStorage === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        dataStorage: dataStorageReducer(state.instances[targetInstance].dataStorage, action),
      },
    },
  };
};

const deviceDeviceTimelineDataActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceTimelineDataActionTypes,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.timeline === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        timeline: timelineServiceReducer(state.instances[targetInstance].timeline, action),
      },
    },
  };
};

const deviceInstanceDeviceDatasetActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceDeviceDatasetActions,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;

  if (state.instances[targetInstance]?.deviceDataset === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        deviceDataset: deviceDatasetReducer(state.instances[targetInstance].deviceDataset, action),
      },
    },
  };
};

const deviceInstanceMultipleDeviceDatasetActionReducer = (
  statex: DeviceInstancesState,
  instanceAction: typeDeviceInstanceMultipleDeviceDatasetAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstances, action } = payload;

  return targetInstances.reduce((acc, targetInstance) => {
    if (acc.instances[targetInstance]?.deviceDataset === undefined) {
      return acc;
    }
    return {
      ...acc,
      instances: {
        ...acc.instances,
        [targetInstance]: {
          ...acc.instances[targetInstance],
          // eslint-disable-next-line max-len
          deviceDataset: deviceDatasetReducer(acc.instances[targetInstance].deviceDataset, action),
        },
      },
    };
  }, statex);
};

const deviceInstanceMethodExecutionActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceDeviceMethodExecutionAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.deviceMethodExecution === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        deviceMethodExecution:
          deviceMethodStateReducer(state.instances[targetInstance].deviceMethodExecution, action),
      },
    },
  };
};

const deviceInstanceActiveDeviceActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceActiveDeviceAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.activeDevice === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        activeDevice:
          activeDeviceStateReducer(state.instances[targetInstance].activeDevice, action),
      },
    },
  };
};

const deviceInstanceIoLinkSystemCommandActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceIoLinkSystemCommandAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.ioLinkAction === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        ioLinkAction:
          ioLinkSystemCommandStateReducer(state.instances[targetInstance].ioLinkAction, action),
      },
    },
  };
};

const deviceInstanceIoLinkTrio3SwitchChannelActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceIoLinkTrio3SwitchChannelAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.ioLinkTrio3ChannelSwitch === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        ioLinkTrio3ChannelSwitch:
          // eslint-disable-next-line max-len
          ioLinkTrio3ChannelSwitchReducer(state.instances[targetInstance].ioLinkTrio3ChannelSwitch, action),
      },
    },
  };
};

const deviceInstanceIoLinkTrio3EventMonitorActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceIoLinkTrio3EventMonitorAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.ioLinkTrio3EventMonitor === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        ioLinkTrio3EventMonitor:
          // eslint-disable-next-line max-len
          ioLinkTrio3EventMonitorReducer(state.instances[targetInstance].ioLinkTrio3EventMonitor, action),
      },
    },
  };
};

const deviceInstanceControlStateActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceControlStateAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.controlStateCollection === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        controlStateCollection:
          // eslint-disable-next-line max-len
          controlStateCollectionReducer(state.instances[targetInstance].controlStateCollection, action),
      },
    },
  };
};

const deviceInstanceCustomStorageStateReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceCustomStorageAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.customStorage === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        customStorage:
          // eslint-disable-next-line max-len
          customStorageStateReducer(state.instances[targetInstance].customStorage, action),
      },
    },
  };
};

const deviceInstancePreviewDatasetStateReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstancePreviewDatasetAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.previewDataset === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        previewDataset:
          // eslint-disable-next-line max-len
          previewDatasetReducer(state.instances[targetInstance].previewDataset, action),
      },
    },
  };
};

const deviceInstanceDatasetProviderStateReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceDatasetProviderAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.datasetProvider === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        datasetProvider:
          // eslint-disable-next-line max-len
          datasetProviderReducer(state.instances[targetInstance].datasetProvider, action),
      },
    },
  };
};

const deviceInstanceStandardViewStateReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceStandardViewAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.standardView === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        standardView:
          // eslint-disable-next-line max-len
          standardViewReducer(state.instances[targetInstance].standardView, action),
      },
    },
  };
};

const deviceInstanceResourceProviderStateReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceResourceProviderAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.resourceProvider === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        resourceProvider:
          // eslint-disable-next-line max-len
          deviceModelResourceProviderReducer(state.instances[targetInstance].resourceProvider, action),
      },
    },
  };
};

const deviceInstanceStationStateReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceStationAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.station === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        station:
          // eslint-disable-next-line max-len
          stationStateReducer(state.instances[targetInstance].station, action),
      },
    },
  };
};

const deviceInstanceStatusEventMonitorReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceDeviceStatusEventMonitorAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.deviceStatusEventMonitor === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        deviceStatusEventMonitor:
          // eslint-disable-next-line max-len
          deviceStatusEventMonitorReducer(state.instances[targetInstance].deviceStatusEventMonitor, action),
      },
    },
  };
};

const deviceInstanceDeviceActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceDeviceActionExecutionAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;
  if (state.instances[targetInstance]?.deviceActionState === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [targetInstance]: {
        ...state.instances[targetInstance],
        deviceActionState:
          // eslint-disable-next-line max-len
          deviceActionStateReducer(state.instances[targetInstance].deviceActionState, action),
      },
    },
  };
};

export const deviceInstancesReducer = (
  state = initialDeviceInstancesState,
  instanceAction: typeDeviceInstanceActionTypes,
): DeviceInstancesState => {
  const { type } = instanceAction;

  switch (type) {
    case 'DEVICE_INSTANCE__CREATE_ACTION':
      return deviceInstanceCreateActionReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__SELECT_ACTION':
      return deviceInstanceSelectActionReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__REMOVE_ACTION':
      return deviceInstanceRemoveActionReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__REMOVE_ALL_ACTION':
      return deviceInstanceRemoveAllActionReducer(state);
    case 'DEVICE_INSTANCE__DATA_STORAGE__ACTIONS':
      return deviceDeviceDataStorageActionReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__TIMELINE__ACTIONS':
      return deviceDeviceTimelineDataActionReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS':
      return deviceInstanceDeviceDatasetActionReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__MULTIPLE_DEVICE_DATASET__ACTIONS':
      return deviceInstanceMultipleDeviceDatasetActionReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__METHOD_EXECUTION__ACTIONS':
      return deviceInstanceMethodExecutionActionReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__ACTIVE_DEVICE__ACTIONS':
      return deviceInstanceActiveDeviceActionReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__CONTROL_STATE__ACTIONS':
      return deviceInstanceControlStateActionReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__CUSTOM_STORAGE__ACTIONS':
      return deviceInstanceCustomStorageStateReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__PREVIEW_DATASET__ACTIONS':
      return deviceInstancePreviewDatasetStateReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__DATASET_PROVIDER__ACTIONS':
      return deviceInstanceDatasetProviderStateReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__STANDARD_VIEW__ACTIONS':
      return deviceInstanceStandardViewStateReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__RESOURCE_PROVIDER__ACTIONS':
      return deviceInstanceResourceProviderStateReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__STATION_DISCOVERY__ACTIONS':
      return deviceInstanceStationStateReducer(state, instanceAction);
    case 'DEVICE_STATUS_EVENT_MONITOR__ACTIONS':
      return deviceInstanceStatusEventMonitorReducer(state, instanceAction);
    case 'DEVICE_INSTANCE__ACTION_EXECUTION__ACTIONS':
      return deviceInstanceDeviceActionReducer(state, instanceAction);
    case 'IOLINK__SYSTEM_COMMAND__ACTIONS':
      return deviceInstanceIoLinkSystemCommandActionReducer(state, instanceAction);
    case 'IOLINK__EVENT_MONITOR__ACTIONS':
      return deviceInstanceioLinkEventMonitorActionReducer(state, instanceAction);
    case 'IOLINK__TRIO3_CHANNEL_SWITCH__ACTIONS':
      return deviceInstanceIoLinkTrio3SwitchChannelActionReducer(state, instanceAction);
    case 'IOLINK__TRIO3_EVENT_MONITOR__ACTIONS':
      return deviceInstanceIoLinkTrio3EventMonitorActionReducer(state, instanceAction);
    default:
  }
  return state;
};
