/* eslint-disable import/prefer-default-export */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  SetCurrentTabPayload,
} from './deviceParameterView/types';
import { SetGaugeIndicatorPayload } from './gaugeControl/types';
import {
  typeDeviceInstanceControlStateAction,
} from './types';

export const setGaugeIndicator = (targetInstance: string, payload: SetGaugeIndicatorPayload)
  : typeDeviceInstanceControlStateAction => ({
  type: 'DEVICE_INSTANCE__CONTROL_STATE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'SET_GAUGE_INDICATOR',
      payload,
    },
  },
});

export const setCurrentTab = (targetInstance: string, payload: SetCurrentTabPayload)
    : typeDeviceInstanceControlStateAction => ({
  type: 'DEVICE_INSTANCE__CONTROL_STATE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'SET_CURRENT_TAB',
      payload,
    },
  },
});

export const cleanAllControls = (targetInstance: string)
    : typeDeviceInstanceControlStateAction => ({
  type: 'DEVICE_INSTANCE__CONTROL_STATE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'CLEAN_ALL_CONTROLS',
    },
  },
});
