/* eslint-disable import/prefer-default-export */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  PrepareTransferValuesAction,
  CXE_TRANSFER_WIZARD__PREPARE_TRANSFER_VALUES,
  PrepareTransferValuesPayload,
  CxeCleanTransferWizardAction,
  CXE_TRANSFER_WIZARD__CLEANUP,
} from './types';

export const prepareTransferValues = (payload: PrepareTransferValuesPayload)
  : PrepareTransferValuesAction => ({
  type: CXE_TRANSFER_WIZARD__PREPARE_TRANSFER_VALUES,
  payload,
});

export const cleanupCxeTransferValues = ()
  : CxeCleanTransferWizardAction => ({
  type: CXE_TRANSFER_WIZARD__CLEANUP,
});
