/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import './ParameterGroupControl.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ParameterGroupControlProps extends PropsWithChildren<any> {
    label: string;
    alignEnd?: boolean;
}

const ParameterGroupControl:
React.FC<ParameterGroupControlProps> = (
  props: ParameterGroupControlProps,
): React.ReactElement => {
  const {
    label, children, alignEnd,
  } = props;

  const caligEnd = alignEnd === undefined || alignEnd === true ? 'page-group__label--align-end' : 'page-group__label--align-start';
  return (
    <div
      className="page-group"
      data-testid="parameter-group-control"
    >
      <p className={`page-group__label ${caligEnd}`}>{label}</p>
      <div className="page-group__children">
        {children}
      </div>
    </div>
  );
};

export default ParameterGroupControl;
