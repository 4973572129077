/* eslint-disable max-len */
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { deviceTargetDatasetSelector } from '../../../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { useTypedSelector } from '../../../../../store';

const useCheckBoxStatusValue = (
  deviceInstanceId: string,
  identRef: IdentRef,
  datasetType: DatasetType,
): DeviceModelStatus.StatusValue | undefined => {
  const dataset = useTypedSelector(
    (state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, datasetType),
  );

  const checkBoxDescriptor = dataset?.descriptors[identRef];
  if (checkBoxDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = checkBoxDescriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLCHECKBOX) {
    return undefined;
  }
  return dataset?.values[controlType.variable];
};

export default useCheckBoxStatusValue;
