/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { WizardViewPageProps } from '../../../WizardView/WizardViewPage';
import DeviceNamingHeader from './Header/DeviceNamingHeader';
import DeviceNamingImage from './Image/DeviceNamingImage';
import DeviceNamingTable from './Table/DeviceNamingTable';
import './Page4DeviceNaming.scss';

export interface Page4DeviceNamingProps extends WizardViewPageProps {
  // eslint-disable-next-line react/no-unused-prop-types
  stationInstanceId: string;
}

const Page4DeviceNaming
: React.FC<Page4DeviceNamingProps> = (props: Page4DeviceNamingProps) : React.ReactElement => {
  const { stationInstanceId } = props;

  return (
    <div className="contactron-commissioning-wizard">
      <div className="page-device-naming">
        <DeviceNamingHeader />
        <DeviceNamingImage />
        <DeviceNamingTable stationInstanceId={stationInstanceId} />
      </div>
    </div>
  );
};

export default Page4DeviceNaming;
