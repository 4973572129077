/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IconAdapter from '../../../assets/icons/icon-adapter.svg';
import './DeviceCardAdapter.scss';
import useScanIndicator from '../hooks/useScanIndicator';

export interface DeviceCardAdapterProps {
  productImage: string;
  protocolTitle: string;
  protocolText: string;
  scanActive?: boolean;
}

const DeviceCardAdapter: React.FC<DeviceCardAdapterProps> = (props: DeviceCardAdapterProps)
: React.ReactElement => {
  const {
    productImage,
    protocolTitle,
    protocolText,
    scanActive,
  } = props;

  const { t } = useTranslation();
  const scanIndicatorText = useScanIndicator(protocolText, scanActive ?? false);

  return (
    <Button
      variant="default"
      className="no-devicecardadapter-button-main p-0 m-0"
    >
      <div className="devicecardadapter-device-container">
        <div className="devicecardadapter-image-container">
          <img
            className="devicecardadapter-nodevice-image m-0"
            alt="no-device"
            src={productImage}
          />
          <img
            className="devicecardadapter-icon"
            src={IconAdapter}
            alt="adapter"
          />
        </div>
        <div className="devicecardadapter-device-body">
          <div>
            <div className="devicecardadapter-device-title">{protocolTitle}</div>
            <div className="devicecardadapter-device-text">{scanIndicatorText}</div>
          </div>
          <div className="devicecardadapter-adapter-text">{t('ADAPTER')}</div>
        </div>
      </div>
    </Button>
  );
};

export default DeviceCardAdapter;
