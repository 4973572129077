/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useContext } from 'react';
import { ReduxControlProps } from '../../ReduxControlProps';
import { UnsupportedControl } from '../../../components/UnsupportedControl';
import { DatasetContext } from '../../../views/DatasetContext';
import withControlVisibility from '../../hoc/withControlVisibility';
import TrendViewDescriptionControl from '../TrendViewDescriptionControl/TrendViewDescriptionControl';
import useReduxTimelineVariables from './hooks/useReduxTimelineVariables';

const TrendViewReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement => {
  const { identRef, deviceInstanceId } = props;

  const { targetDataset } = useContext(DatasetContext);
  const timelineVars = useReduxTimelineVariables(deviceInstanceId, targetDataset, identRef);

  if (identRef === undefined) {
    return <UnsupportedControl text={`TrendViewReduxControl: object ${identRef} undefined`} />;
  }

  return (
    <TrendViewDescriptionControl
      trendId={identRef}
      deviceInstanceId={deviceInstanceId}
      trendVars={timelineVars}
    />
  );
};

export default withControlVisibility(TrendViewReduxControl);
