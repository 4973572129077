/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { ListGroup } from 'react-bootstrap';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { ExecutionState, useTypedSelector } from '../../store';
import { LoadingControl } from '../../components/LoadingControl/LoadingControl';
import PreviewGroupReduxControl from '../../components/PreviewComponent/PreviewValueGroupControl/PreviewValueGroupControl';
import './PreviewDeviceParametersControl.css';
import PreviewDeviceHeader from './PreviewDeviceHeader/PreviewDeviceHeader';
import { DatasetContext } from '../../views/DatasetContext';
import { useContextActiveDeviceInstance } from '../../store/deviceInstances/hooks/ActiveDeviceHooks';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';

export interface PreviewDeviceParametersControlProps {
  identRef: IdentRef;
  parameterFile: string;
  deviceInstanceId: string;
}

const PreviewDeviceParametersControl: React.FC<PreviewDeviceParametersControlProps> = (props: PreviewDeviceParametersControlProps)
: React.ReactElement => {
  const { identRef, parameterFile, deviceInstanceId } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);
  const deviceDatasetValues = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)?.values);
  const deviceDatasetDescriptors = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)?.descriptors);
  const previewDatasetState = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)?.state);
  const deviceCatalog = useContextActiveDeviceInstance(deviceInstanceId)?.device?.catalog;

  if (deviceDatasetValues === undefined || deviceDatasetDescriptors === undefined) {
    return previewDatasetState === ExecutionState.pending
      ? <LoadingControl title={t<string>('LOADING_PREVIEW_DATA')} />
      : <div />;
  }

  const structureDescriptor = deviceDatasetDescriptors[identRef];
  if (structureDescriptor === undefined) {
    return <UnsupportedControl text={`PreviewDeviceParametersControl: Undefined ident ${identRef}`} />;
  }

  if (structureDescriptor.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return <UnsupportedControl text={`PreviewDeviceParametersControl: Unsupported type ${structureDescriptor.type}`} />;
  }

  const componentGroups: JSX.Element[] = [];

  if (deviceCatalog) {
    componentGroups.push(<PreviewDeviceHeader
      key={`preview-header-${identRef}-${deviceCatalog.deviceCatalogIdent}`}
      deviceCatalog={deviceCatalog}
      parameterFile={parameterFile}
    />);
  }

  const componentGroupsEl = structureDescriptor.elements.map((element) => (
    <ListGroup.Item key={`rxlistitem-${identRef}-${element.identRef}`}>
      <PreviewGroupReduxControl deviceInstanceId={deviceInstanceId} identRef={element.identRef} />
    </ListGroup.Item>
  ));
  componentGroups.push(...componentGroupsEl);

  return (
    <div className="preview-device-parameters-control h-100" data-testid="preview-device-parameters">
      <div className="preview-device-parameters-control__caption w-100">
        {t<string>('PREVIEW_DEVICE_PARAMETERS__DEVICE_TYPE')}
      </div>
      <div className="preview-device-parameters-control__list h-100">
        <ListGroup className="h-100" variant="flush">
          {componentGroups}
        </ListGroup>
      </div>
    </div>
  );
};

export default PreviewDeviceParametersControl;
