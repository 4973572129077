/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { ButtonRole, ButtonState } from '../../store/deviceWizard/types';
import { useTypedSelector } from '../../store/reduxStore';
import './ButtonList.Base.scss';

export interface ButtonListWizardProps {
  onClick: (button: ButtonRole) => void;
}

const createWizardButton = (
  role: ButtonRole,
  state: ButtonState,
  label: string,
  pca: ButtonRole,
  ctaRef: React.RefObject<HTMLButtonElement>,
  onClick: () => void,
): React.ReactElement | undefined => {
  if (state === ButtonState.hidden) {
    return <></>;
  }

  const buttonVariant = () => {
    // Check if all buttons must be disabled
    if (state === ButtonState.disabled) {
      return 'deactivated';
    }
    // Check if button is 'primary call to action' (color corporate blue, primary)
    if (pca === role) {
      return 'primary';
    }
    return 'secondary';
  };

  // set the appearance of a button (individual colors and optionally deactivated)
  const variant = buttonVariant();

  return (
    <div className="button-container" key={`wizbutton-${role}`}>
      <Button
        className="button-text"
        variant={variant}
        disabled={state === ButtonState.disabled}
        onClick={
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          state === ButtonState.enabled ? () => onClick() : () => {}
        }
        ref={variant === 'primary' ? ctaRef : undefined}
      >
        {label}
      </Button>
    </div>
  );
};

const ButtonListWizard: React.FC<ButtonListWizardProps> = (
  props: ButtonListWizardProps,
): React.ReactElement => {
  const {
    onClick,
  } = props;
  const { t } = useTranslation();
  const abortButtonRef = useRef<HTMLButtonElement>(null);
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const completeButtonRef = useRef<HTMLButtonElement>(null);
  const loadButtonRef = useRef<HTMLButtonElement>(null);

  const abortButton = useTypedSelector((state) => state.deviceWizard.abort);
  const backButton = useTypedSelector((state) => state.deviceWizard.back);
  const nextButton = useTypedSelector((state) => state.deviceWizard.next);
  const completeButton = useTypedSelector((state) => state.deviceWizard.complete);
  const loadButton = useTypedSelector((state) => state.deviceWizard.load);
  const wizardPCTAButton = useTypedSelector((state) => state.deviceWizard.pcta);
  const focusPctaState = useTypedSelector((state) => state.deviceWizard.focusPcta);

  // TODO: Check f we need useEffect here
  useEffect(() => {
    switch (focusPctaState) {
      case ButtonRole.next:
        if (nextButtonRef.current) {
          nextButtonRef.current.focus();
        }
        break;
      case ButtonRole.complete:
        if (completeButtonRef.current) {
          completeButtonRef.current.focus();
        }
        break;
      case ButtonRole.back:
        if (backButtonRef.current) {
          backButtonRef.current.focus();
        }
        break;
      default:
        break;
    }
  }, [focusPctaState]);

  const wiAbortButton = createWizardButton(
    ButtonRole.abort,
    abortButton.state,
    t(abortButton.label),
    wizardPCTAButton,
    abortButtonRef,
    () => onClick(ButtonRole.abort),
  );
  const wiBackButton = createWizardButton(
    ButtonRole.back,
    backButton.state,
    t(backButton.label),
    wizardPCTAButton,
    backButtonRef,
    () => onClick(ButtonRole.back),
  );
  const wiNextButton = createWizardButton(
    ButtonRole.next,
    nextButton.state,
    t(nextButton.label),
    wizardPCTAButton,
    nextButtonRef,
    () => onClick(ButtonRole.next),
  );
  const wiCompleteButton = createWizardButton(
    ButtonRole.complete,
    completeButton.state,
    t(completeButton.label),
    wizardPCTAButton,
    completeButtonRef,
    () => onClick(ButtonRole.complete),
  );
  const wiLoadButton = createWizardButton(
    ButtonRole.complete,
    loadButton?.state ?? ButtonState.hidden,
    t(loadButton?.label ?? ''),
    wizardPCTAButton,
    loadButtonRef,
    () => onClick(ButtonRole.load),
  );

  return (
    <Row className="align-items-center justify-content-end p-0 m-0">
      <Col className="col-12 d-flex flex-row p-0">
        {wiLoadButton}
        {wiAbortButton}
        {wiBackButton}
        {wiNextButton}
        {wiCompleteButton}
      </Col>
    </Row>
  );
};

export default ButtonListWizard;
