/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import React, { useContext, useState } from 'react';
import {
  DocumentType, DocumentLanguage, Report, IdentRef, Services,
} from '@gpt/commons';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createReport } from '../../store/reportService/actions';
import { CTAButton } from '../../components/CTAButton/CTAButton';
import { useTypedSelector } from '../../store/reduxStore';
import FileSystemSaveControl from '../../components/FileSystemSaveControl/FileSystemSaveControl';
import { MIME_TYPE__CXEDP, FILE_EXTENTION__CXEDP } from '../../helpers/constants';
import { createDatasetIdent, createParameterizationFilename } from '../../helpers/functions';
import InputBoxControl from '../../components/Basic/InputBoxControl/InputBoxControl';
import { getAppUiLanguageCode } from '../../i18n-config';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { deviceInstanceActiveDeviceInstance } from '../../store/deviceInstances/store/activeDevice/selectors';
import createDeviceDatasetBackupContent from '../../helpers/createDeviceDatasetBackupContent';
import { WebWorkerContext } from '../../WebWorkerContext';
import './PageSaveProtocolComponent.scss';

export interface PageSaveProtocolProps {
  reportMenu?: IdentRef;
  previewMenu?: IdentRef;
  caption: string;
  nameCaption: string;
  descriptionCaption: string;
  disableButtons?: boolean;
  showSaveParameter?: boolean;
  fileName: string;
  serialNumber?: string;
  deviceInstanceId: string;
  onGetContent: (caption: string, userComment: string, serialNumber: string) => Promise<Report>;
  onCreateReport?: (userName: string, userComment: string, serialNumber: string, reportType: 'PDF' | 'DOCX', systemName: string) => void;
}

export const PageSaveProtocolComponent:
React.FC<PageSaveProtocolProps> = (props: PageSaveProtocolProps): React.ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    reportMenu, previewMenu, caption, nameCaption, descriptionCaption, serialNumber,
    fileName, onGetContent, disableButtons, showSaveParameter, onCreateReport,
    deviceInstanceId,
  } = props;

  const { webWorkerDeviceManager } = useContext(WebWorkerContext);
  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId));

  const [userNameValue, setUserNameValue] = useState(`${t<string>('WIZARD_DOCUMENTATION__NAME_TEMPLATE')}`);
  const [userTextValue, setUserTextValue] = useState(`${t<string>('WIZARD_DOCUMENTATION__DESCRIPTION_TEMPLATE')}`);
  const [serialNumberValue, setSerialNumberValue] = useState(serialNumber ?? '');

  const workingSerialNumber = serialNumber ?? serialNumberValue;
  const docLanguage: DocumentLanguage = DocumentLanguage[getAppUiLanguageCode().toUpperCase()];
  let saveParameterControl: JSX.Element = <></>;

  if (showSaveParameter && activeDevice) {
    saveParameterControl = (
      <>
        <Row className="page-save-protocol__buttons">
          <p className="m-0 mb-2">{t<string>('SAVE_PARAMETER_FILE__PROMPT_TEXT')}</p>
          <FileSystemSaveControl
            caption={t<string>('STANDARD_BUTTON__SAVE_PARAMETER_FILE')}
            fileName={createParameterizationFilename(activeDevice.device)}
            mimeType={MIME_TYPE__CXEDP}
            extensions={[FILE_EXTENTION__CXEDP]}
            getContents={async (): Promise<string> => {
              const content = createDeviceDatasetBackupContent({
                activeDevice,
                caption: userNameValue,
                datasetId: createDatasetIdent('backup'),
                datasetType: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType.COMPLETE,
                webWorkerDeviceManager,
                previewMenu,
                userComment: userTextValue,
              });
              return content;
            }}
            button={{
              block: true,
              variant: 'tertiary',
              disabled: disableButtons,
            }}
            onDone={() => 0}
          />
        </Row>
      </>
    );
  }

  return (
    <Container className="page-save-protocol" fluid>
      <Row className="page-save-protocol__caption">
        <Col className="form-group p-0">
          {caption}
        </Col>
      </Row>
      <Row className="page-save-protocol__document-name">
        <Col className="form-group m-0 p-0">
          <label htmlFor="page-save-protocol__document-name__id">
            {nameCaption}
          </label>
          <input
            type="text"
            className="textfield__input"
            id="page-save-protocol__document-name__id"
            placeholder={nameCaption}
            value={userNameValue}
            onChange={(event) => setUserNameValue(event.target.value)}
          />
        </Col>
      </Row>
      <Row className="page-save-protocol__device-serial">
        <Col className="form-group m-0 p-0">
          <label htmlFor="page-save-protocol__document-serial__id">
            {t<string>('SAVE_PARAMETER_FILE__DEVICE_SERIAL_NUMBER')}
          </label>
          <InputBoxControl
            type="text"
            id="page-save-protocol__document-serial__id"
            placeholder={t<string>('SAVE_PARAMETER_FILE__DEVICE_SERIAL_NUMBER')}
            disabled={serialNumber !== undefined}
            value={workingSerialNumber}
            onChange={(value) => setSerialNumberValue(value)}
            displayFormat=""
            warning={workingSerialNumber === '' ? t<string>('SAVE_PARAMETER_FILE__DEVICE_SERIAL_NUMBER_ERROR') : undefined}
          />
        </Col>
      </Row>
      <Row className="page-save-protocol__document-text align-items-end">
        <Col className="form-group m-0 p-0 pb-2">
          <label htmlFor="page-save-protocol__document-text__id">
            {descriptionCaption}
          </label>
          <textarea
            className="page-save-protocol__document-text__input m-0 p-2"
            id="page-save-protocol__document-text__id"
            placeholder={descriptionCaption}
            value={userTextValue}
            onChange={(event) => setUserTextValue(event.target.value)}
          />
        </Col>
      </Row>
      <Row className="page-save-protocol__buttons align-items-end">
        <Col xs={6} className="page-save-protocol__buttons__button form-group p-0 pr-1">
          <CTAButton
            caption={t<string>('STANDARD_BUTTON__SAVE_DATA_AS_WORD')}
            variant="tertiary"
            block
            disabled={(disableButtons ?? false) || workingSerialNumber === '' || workingSerialNumber === undefined}
            onClick={() => {
              if (onCreateReport) {
                onCreateReport(userNameValue, userTextValue, workingSerialNumber, 'DOCX', t<string>('', '{{SYSTEM_NAME}}'));
              } else {
                onGetContent(userNameValue, userTextValue, workingSerialNumber)
                  .then((reportData) => {
                    dispatch(createReport(
                      {
                        targetInstance: deviceInstanceId,
                        report: {
                          documentType: DocumentType.DOCX,
                          language: docLanguage,
                          reportMenu,
                          reportData,
                          reportFilename: fileName,
                          activeDataset: DatasetType.user,
                          compareDataset: DatasetType.device,
                          dictFamily: activeDevice?.device?.catalog?.i18n.family ?? '',
                        },
                      },
                    ));
                  })
                  .catch(() => {
                    console.log('Cannot create report');
                  });
              }
            }}
          />
        </Col>
        <Col xs={6} className="page-save-protocol__buttons__button form-group p-0 pl-1">
          <CTAButton
            caption={t<string>('STANDARD_BUTTON__SAVE_DATA_AS_PDF')}
            variant="tertiary"
            block
            disabled={disableButtons || workingSerialNumber === '' || workingSerialNumber === undefined}
            onClick={() => {
              if (onCreateReport) {
                onCreateReport(userNameValue, userTextValue, workingSerialNumber, 'PDF', t<string>('', '{{SYSTEM_NAME}}'));
              } else {
                onGetContent(userNameValue, userTextValue, workingSerialNumber)
                  .then((reportData) => {
                    dispatch(createReport(
                      {
                        targetInstance: deviceInstanceId,
                        report: {
                          documentType: DocumentType.PDF,
                          language: docLanguage,
                          reportMenu,
                          reportData,
                          reportFilename: fileName,
                          activeDataset: DatasetType.user,
                          compareDataset: DatasetType.device,
                          dictFamily: activeDevice?.device?.catalog?.i18n.family ?? '',
                        },
                      },
                    ));
                  })
                  .catch(() => {
                    console.log('Cannot create report');
                  });
              }
            }}
          />
        </Col>
      </Row>
      { saveParameterControl }
    </Container>
  );
};

export default PageSaveProtocolComponent;
