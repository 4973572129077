/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../../../store';
import { useContextApplicationId } from '../../../../store/deviceInstances/hooks/StandardViewHooks';
import { deviceTargetDatasetSelector } from '../../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetState, DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';

const appTypeSelector = (appId: string, state?: DatasetState)
: DeviceModelStatus.Apps.ApplicationDescriptorType | undefined => {
  const appDesc = state?.descriptors[appId];
  if (appDesc?.type === DeviceModelStatus.StatusType.ApplicationDescriptor) {
    return appDesc.appType;
  }

  return undefined;
};

const useReportMenu = (deviceInstanceId: string): string | undefined => {
  const applicationId = useContextApplicationId(deviceInstanceId);
  const reportMenu = useTypedSelector((state) => {
    const appId = applicationId === undefined ? 'APP_EDITDEVICE' : applicationId;
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.user);
    const appType = appTypeSelector(appId, dataset);
    return appType ? appType.report : undefined;
  });
  return reportMenu;
};

export default useReportMenu;
