/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Report, ReportContentType, ManualDeviceSettings, IdentRef,
} from '@gpt/commons';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../store';
import { LoadingControl } from '../../components/LoadingControl';
import SaveProtocolButtonList from '../../PageComponents/PageSaveProtocolComponent/SaveProtocolButtonList/SaveProtocolButtonList';
import SaveProtocolDocumentation from '../../PageComponents/PageSaveProtocolComponent/SaveProtocolDocumentation/SaveProtocolDocumentation';
import { createReportFilename } from '../../helpers/functions';
import './ModalDIPSwitchReport.css';
import { deviceInstanceActiveDeviceInstance } from '../../store/deviceInstances/store/activeDevice/selectors';

export interface DIPSwitchReportProps {
  deviceInstanceId: string;
  onClose: () => Promise<void>;
  reportButtonsDisabled?: boolean;
  buttonDisabled?: boolean;
  reportMenu?: IdentRef,
}

export const DIPSwitchReport: React.FC<DIPSwitchReportProps> = (props: DIPSwitchReportProps): React.ReactElement => {
  const {
    onClose,
    buttonDisabled,
    reportButtonsDisabled,
    reportMenu,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId));
  const [userNameValue, setUserNameValue] = useState(`${t<string>('MODAL_DOWNLOAD__NAME_TEMPLATE')}`);
  const [userTextValue, setUserTextValue] = useState(`${t<string>('MODAL_DOWNLOAD__DESCRIPTION_TEMPLATE')}`);

  if (activeDevice?.deviceInstanceId === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const getContentHandler = async (): Promise<Report> => {
    const content: ManualDeviceSettings = {
      type: ReportContentType.ManualDeviceSettings,
      device: {
        deviceDesignation: activeDevice?.device.catalog?.deviceTypeName ?? '',
        deviceTag: activeDevice.device.instance?.deviceTag ?? '',
        firmwareRevision: activeDevice.device.instance?.firmwareVersion ?? '',
        hardwareRevision: activeDevice.device.instance?.hardwareVersion ?? '',
        serialNumber: activeDevice.device.instance?.serialNumber ?? '',
        itemNumber: activeDevice.device.catalog?.productOrderNumber ?? '',
      },
      userName: userNameValue,
    };

    return {
      header: {
        dateTime: new Date(),
        systemName: t<string>('', '{{SYSTEM_NAME}}'),
        userName: userNameValue,
        userNotes: userTextValue,
      },
      content,
    };
  };

  return (
    <div className="dip-switch-report-dialog">
      <div className="dip-switch-report-dialog__container">
        <div className="dip-switch-report-dialog__container--title">{t<string>('MODAL_DIP_SWITCH_REPORT_TITLE')}</div>
        <div className="dip-switch-report-dialog__container--body">
          <SaveProtocolDocumentation
            nameCaption={t<string>('MODAL_DOWNLOAD__DOCUMENTATION__NAME')}
            descriptionCaption={t<string>('MODAL_DOWNLOAD__DOCUMENTATION__DESCRIPTION')}
            onUserNameChanged={(text) => setUserNameValue(text)}
            onUserTextChanged={(text) => setUserTextValue(text)}
            userName={userNameValue}
            userText={userTextValue}
            instanceSerialNumber={activeDevice.device.instance?.serialNumber}
          />
          <SaveProtocolButtonList
            reportMenu={reportMenu}
            targetInstance={activeDevice.deviceInstanceId}
            dictFamily={activeDevice.device.catalog?.i18n.family ?? ''}
            disabled={reportButtonsDisabled}
            fileName={createReportFilename('DeviceSettingsReport', activeDevice.device)}
            onGetContent={() => getContentHandler()}
          />
        </div>
        <Button
          className="dip-switch-report-dialog__container--buttonClose button-text"
          variant="primary"
          onClick={() => onClose()}
          disabled={buttonDisabled}
        >
          {t<string>('STANDARD_BUTTON__CLOSE')}
        </Button>
      </div>
    </div>
  );
};

export const ModalDIPSwitchReport: React.FC<DIPSwitchReportProps> = (props: DIPSwitchReportProps) => {
  const {
    onClose,
    buttonDisabled,
    reportButtonsDisabled,
    reportMenu,
    deviceInstanceId,
  } = props;
  const modalRoot = document.getElementById('modal-root');

  if (modalRoot === null) {
    return <div />;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <DIPSwitchReport
          onClose={onClose}
          deviceInstanceId={deviceInstanceId}
          buttonDisabled={buttonDisabled}
          reportButtonsDisabled={reportButtonsDisabled}
          reportMenu={reportMenu}
        />,
        modalRoot,
      )}
    </>
  );
};
