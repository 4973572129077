import React from 'react';
import { Button } from 'react-bootstrap';
import IconChevronDownBlue from '../../../assets/icons/chevron-down-blue.svg';
import './ThumbWheelButton.scss';

export enum ThumbWheelButtonDirection {
  // eslint-disable-next-line no-unused-vars
  Down = 0,
  // eslint-disable-next-line no-unused-vars
  Up = 1,
}

export interface ThumbWheelButtonProps {
  direction: ThumbWheelButtonDirection;
  onButtonClick: () => void;
  readonly: boolean;
}

const ThumbWheelButton: React.FC<ThumbWheelButtonProps> = (props: ThumbWheelButtonProps)
: React.ReactElement => {
  const { direction, readonly, onButtonClick } = props;

  const rotationAngle = direction === ThumbWheelButtonDirection.Up ? '180' : '0';
  const iconStyle = { transform: `rotate(${rotationAngle}deg)` };

  return (
    <Button
      className="thumbwheel-button"
      variant="secondary"
      onClick={() => onButtonClick()}
      disabled={readonly}
    >
      <img style={iconStyle} src={IconChevronDownBlue} alt="IconChevronDownBlue" />
    </Button>
  );
};

export default ThumbWheelButton;
