/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import downloadIcon from '../../../../assets/icons/icon-download.svg';
import openFolderIcon from '../../../../assets/icons/icon-open-folder.svg';
import './LinearizationActionSubmenu.css';

export type ActionSubmenuTypes = 'load' | 'save';

export interface ActionSubmenuProps {
  // eslint-disable-next-line no-unused-vars
  onAction: (action: ActionSubmenuTypes) => void;
}

export const LinearizationActionSubmenu:React.FC<ActionSubmenuProps> = (props: ActionSubmenuProps): React.ReactElement => {
  const { t } = useTranslation();
  const { onAction } = props;

  return (
    <div className="linearizationTableExpand-root" data-testid="linearization-action-submenu">
      <div
        className="linearizationTableExpand-element"
        onClick={() => onAction('load')}
        role="button"
        onKeyUp={() => {}}
        tabIndex={0}
      >
        <div className="linearizationTableExpand-button">
          <Image src={openFolderIcon} className="linearizationTableExpand-icon" />
        </div>
        <div className="linearizationTableExpand-text">{t<string>('LINTABLE__POPUPMENU__LOAD')}</div>
      </div>
      <div
        className="linearizationTableExpand-element"
        onClick={() => onAction('save')}
        role="button"
        onKeyUp={() => {}}
        tabIndex={0}
      >
        <div className="linearizationTableExpand-button">
          <Image src={downloadIcon} className="linearizationTableExpand-icon" />
        </div>
        <div className="linearizationTableExpand-text">{t<string>('LINTABLE__POPUPMENU__SAVE')}</div>
      </div>
    </div>
  );
};
