/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  ResourceItem, typeDeviceInstanceResourceProviderAction,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const setResourceItem = (targetInstance: string, payload: ResourceItem)
  : typeDeviceInstanceResourceProviderAction => ({
  type: 'DEVICE_INSTANCE__RESOURCE_PROVIDER__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'RESOURCE_PROVIDER__SET_RESOURCE_ITEM',
      payload,
    },
  },
});
