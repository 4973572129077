/* eslint-disable max-len */

/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import { Action } from 'redux';

export const CXE_TRANSFER_WIZARD__PREPARE_TRANSFER_VALUES = 'CXE_TRANSFER_WIZARD__PREPARE_TRANSFER_VALUES';
export const CXE_TRANSFER_WIZARD__CLEANUP = 'CXE_TRANSFER_WIZARD__CLEANUP';

export enum CxeTransferWizardDatasetType {
    // eslint-disable-next-line no-unused-vars
    INIT = -1,
    // eslint-disable-next-line no-unused-vars
    COMPLETE = 0,
    // eslint-disable-next-line no-unused-vars
    FIRMWARE = 1,
    // eslint-disable-next-line no-unused-vars
    HARDWARE_SWITCH = 2
}

export interface CxeTransferWizardState {
    orderCode?: string;
    transferValues: Services.DeviceModel.StatusValueRef[];
    datasetType: CxeTransferWizardDatasetType;
}

export interface PrepareTransferValuesPayload {
    orderCode: string
    dataset: string,
}

export interface PrepareTransferValuesAction extends Action<typeof CXE_TRANSFER_WIZARD__PREPARE_TRANSFER_VALUES> {
    payload: PrepareTransferValuesPayload,
}

export type CxeCleanTransferWizardAction = Action<typeof CXE_TRANSFER_WIZARD__CLEANUP>

export type CxeTransferWizardActionTypes =
  PrepareTransferValuesAction | CxeCleanTransferWizardAction;
