/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { ReduxControlProps } from '../../ReduxControlProps';
import UnsupportedControl from '../../../components/UnsupportedControl/UnsupportedControl';
import { DatasetContext } from '../../../views/DatasetContext';
import useStatusValueStateUpdate from '../hooks/useStatusValueStateUpdate';
import { useContextDatasetStatusDescriptor, useContextDatasetStatusValue } from '../../../hooks/useContextDataset';
import CheckboxEnumeratorControl from '../../CheckboxControl/CheckboxEnumeratorControl/CheckboxEnumeratorControl';

export interface Quint4CheckboxControlProps extends ReduxControlProps {
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
  // eslint-disable-next-line no-unused-vars
  onCustomValueChange?: (value: boolean) => void;
}

const Quint4CheckboxControl: React.FC<Quint4CheckboxControlProps> = (props: Quint4CheckboxControlProps):
  React.ReactElement => {
  const {
    identRef, onShowContentHelp, onHideContentHelp, readonly, onStateChanged,
    onCustomValueChange,
    deviceInstanceId,
  } = props;

  const { targetDataset } = useContext(DatasetContext);

  const variableDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, identRef, targetDataset);
  const variableValue = useContextDatasetStatusValue(deviceInstanceId, identRef, targetDataset);
  const visibility = useStatusValueStateUpdate(identRef, deviceInstanceId, targetDataset, onStateChanged);

  if (variableDescriptor?.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return <UnsupportedControl text={`CheckboxReduxControl: Unsupported valueType ${variableDescriptor?.valueType.type}`} />;
  }

  if (variableValue === undefined) {
    return <UnsupportedControl text={`CheckboxReduxControl: Undefined variable object ${identRef}`} />;
  }

  if (!visibility) {
    return <></>;
  }

  const { label, help } = variableDescriptor;

  return (
    <CheckboxEnumeratorControl
      enumeratorDescriptor={variableDescriptor.valueType}
      statusValue={variableValue.value}
      backupValue={variableValue.backupValue}
      identRef={variableDescriptor.identRef}
      help={help}
      label={label}
      readonly={readonly}
      modified={variableValue.modified}
      handling={variableDescriptor.handling}
      onShowContentHelp={onShowContentHelp}
      onHideContentHelp={onHideContentHelp}
      deviceInstanceId={deviceInstanceId}
      onCustomValueChange={onCustomValueChange}
    />
  );
};

export default Quint4CheckboxControl;
