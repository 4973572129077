/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  Middleware, Dispatch,
} from 'redux';
import { AppLanguageActionTypes } from './types';
import { setAppUiLanguageCode } from '../../../i18n-config';

const appLanguageMiddleware = (): Middleware => () => (next: Dispatch) => async <A extends AppLanguageActionTypes>(instanceAction: A): Promise<A> => {
  switch (instanceAction.type) {
    case 'APP_SETTINGS__LANGUAGE__SET_LANGUAGE': {
      const { payload } = instanceAction;
      setAppUiLanguageCode(payload);
    }
      break;
    default:
  }
  const result = next(instanceAction);
  return result;
};

export default appLanguageMiddleware;
