/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { ExecutionState } from '../../../common';
import {
  TimelineServiceState,
  typeTimelineDataActionTypes,
} from './types';

export const initialTimelineServiceState: TimelineServiceState = {
  executionState: ExecutionState.init,
  timelineValues: [],
};

const timelineServiceReducer = (
  state = initialTimelineServiceState,
  action: typeTimelineDataActionTypes,
): TimelineServiceState => {
  switch (action.type) {
    case 'DEVICE_INSTANCE__IMELINE__SET_STATE':
      return {
        ...state,
        executionState: action.payload,
      };
    case 'DEVICE_INSTANCE__TIMELINE__SET_DATA':
      return {
        ...state,
        timelineValues: action.payload,
        executionState: ExecutionState.success,
      };
    case 'DEVICE_INSTANCE__TIMELINE__UPDATE_DATA': {
      // Replace existing value with updated
      const timelineValues = state.timelineValues.map((stateValue) => {
        const newValue = action.payload.find((value) => stateValue.identRef === value.identRef);
        if (newValue) {
          return {
            ...stateValue,
            timeline: [
              ...stateValue.timeline,
              newValue,
            ],
          };
        }
        return stateValue;
      });

      return {
        ...state,
        timelineValues,
      };
    }
    default:
      return state;
  }
};

export default timelineServiceReducer;
