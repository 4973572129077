/* eslint-disable max-len */
import React, { useRef } from 'react';
import {
  gaugeWidth,
} from '../common';
import expandIcon from '../../../../../assets/icons/icon-expand.svg';
import useMenuPostion from './hooks/useMenuPostion';

export interface SVGCircleButtonProps {
  // eslint-disable-next-line no-unused-vars
  onClick: (posx?: number, posy?: number) => void,
}

const SVGCircleButton: React.FC<SVGCircleButtonProps> = (props: SVGCircleButtonProps)
: React.ReactElement => {
  const { onClick } = props;
  const svgButtonRef = useRef<SVGImageElement>(null);
  const [menuXPosition, menuYPosition] = useMenuPostion(svgButtonRef);

  return (
    <g>
      <image
        ref={svgButtonRef}
        x={gaugeWidth - 60}
        y="0"
        width="40"
        height="40"
        href={expandIcon}
        onClick={() => onClick(menuXPosition, menuYPosition)}
      />
    </g>
  );
};

export default SVGCircleButton;
