/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UnsupportedControl } from '../../../../components/UnsupportedControl';
import { setContextParameterHelp } from '../../../../store/contexthelp';
import { CompareModeType } from '../../../../views/CompareView/common';
import { CompareStatusValueProps } from '../../CompareTypes';
import useCompareMiddleButton from '../../hooks/useCompareMiddleButton';
import useVariableValidationMessage from '../../hooks/useVariableValidationMessage';
import useCheckBoxStatusValue from './hooks/useCheckBoxStatusValue';
import useCheckBoxStatusDescriptor from './hooks/useCheckBoxStatusDescriptor';
import CompareCheckboxValue from './CompareCheckboxValue/CompareCheckboxValue';
import useDispatchEnumeratorValue from '../../hooks/useDispatchEnumeratorValue';

/**
 * Compare two values from Dataset Value : value vs externalValue
 * backupValue used as storage for value before modification
 *
 * @param props Control Properties
 * @returns
 */
const CompareCheckbox
: React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
  : React.ReactElement | null => {
  const {
    parentId,
    compareMode,
    identRef, leftDataset, rightDataset,
    onShowContentHelp, onHideContentHelp,
    showImageAndSign,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const leftDescriptor = useCheckBoxStatusDescriptor(deviceInstanceId, identRef, leftDataset);
  const leftValue = useCheckBoxStatusValue(deviceInstanceId, identRef, leftDataset);

  const rightDescriptor = useCheckBoxStatusDescriptor(deviceInstanceId, identRef, rightDataset);
  const rightValue = useCheckBoxStatusValue(deviceInstanceId, identRef, rightDataset);

  const dispatchValue = useDispatchEnumeratorValue(deviceInstanceId, leftDataset);
  const warning = useVariableValidationMessage(leftDescriptor, leftValue);
  const [buttonFunction, onMiddleButtonClick] = useCompareMiddleButton(deviceInstanceId, leftValue, rightValue);

  if (leftDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareEnumStatusValue: Unsupported type" />;
  }

  if (rightDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareEnumStatusValue: Unsupported type" />;
  }

  const { valueType } = leftDescriptor;
  if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return <UnsupportedControl text="CompareEnumStatusValue: only ENUMERATOR is supported" />;
  }

  if (rightDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return <UnsupportedControl text="CompareEnumStatusValue: only ENUMERATOR is supported" />;
  }

  const showContentHelp = useCallback(() => {
    if (leftDescriptor === undefined) {
      return;
    }

    const { label, help } = leftDescriptor;
    dispatch(setContextParameterHelp({
      title: t(label),
      text: help ? t(help) : '',
    }));
    onShowContentHelp?.();
  }, [leftDescriptor, onShowContentHelp]);

  const hideContentHelp = useCallback(() => {
    dispatch(setContextParameterHelp({
      title: '',
      text: '',
    }));
    onHideContentHelp?.();
  }, [onHideContentHelp]);

  const equal = leftValue?.value === rightValue?.value;
  if (compareMode === CompareModeType.ShowDifference && equal) {
    return (null);
  }

  if (compareMode === CompareModeType.ShowAll) {
    const leftBoolValue = leftValue?.value !== 0;
    const rightBoolValue = rightValue?.value !== 0;

    return (
      <CompareCheckboxValue
        id={`cmp-float-${parentId}-${identRef}`}
        key={`cmp-float-${parentId}-${identRef}`}
        equal={leftBoolValue === rightBoolValue}
        label={t(leftDescriptor.label)}
        valueLeft={leftBoolValue}
        valueRight={rightBoolValue}
        onButtonClick={() => onMiddleButtonClick(buttonFunction)}
        modified={leftValue?.modified ?? false}
        readonly
        warning={warning}
        onHideContentHelp={hideContentHelp}
        onShowContentHelp={showContentHelp}
        buttonFunction={buttonFunction}
        showImageAndSign={showImageAndSign}
      />
    );
  }

  return (
    <CompareCheckboxValue
      id={`cmp-float-${parentId}-${leftDescriptor.identRef}-${rightDescriptor.identRef}`}
      key={`cmp-float-${parentId}-${leftDescriptor.identRef}-${rightDescriptor.identRef}`}
      equal={equal}
      label={t(leftDescriptor.label)}
      valueLeft={leftValue?.value}
      valueRight={rightValue?.value}
      hideLeftControl={leftDescriptor.visibility === false}
      hideRightControl={rightDescriptor.visibility === false}
      onButtonClick={() => onMiddleButtonClick(buttonFunction)}
      modified={leftValue?.modified ?? false}
      readonly={leftDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY}
      onLeftValueChange={(ev) => {
        const checkValue = ev
          ? valueType.enumerator.find((en) => en.value !== 0)?.value ?? 0
          : 0;
        dispatchValue(checkValue, leftValue);
      }}
      warning={leftValue?.valueValidityDescription}
      onHideContentHelp={hideContentHelp}
      onShowContentHelp={showContentHelp}
      buttonFunction={buttonFunction}
      showImageAndSign={showImageAndSign}
    />
  );
};

export default CompareCheckbox;
