/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import './CockpitModuleLayout16.scss';
import { StationModuleLayoutProps } from '../types';
import { StationDiscoveryItem } from '../../../../../store/deviceInstances/store/stationDiscovery/types';
import Layout16CardReduxControl from './Layout16CardReduxControl/Layout16CardReduxControl';

export interface LayoutColumns {
  row1: number;
  row2: number;
}

export interface ContainerLayout {
  [key: number]: LayoutColumns;
}

const mapCountToLayout: ContainerLayout = {
  16: { row1: 8, row2: 8 },
  15: { row1: 8, row2: 7 },
  14: { row1: 7, row2: 7 },
  13: { row1: 7, row2: 6 },
  12: { row1: 6, row2: 6 },
  11: { row1: 6, row2: 5 },
  10: { row1: 5, row2: 5 },
  9: { row1: 5, row2: 4 },
};

const createCardRow = (
  items: StationDiscoveryItem[],
  stationInstanceId: string,
  // eslint-disable-next-line no-unused-vars
  onModuleOpen: (moduleInstanceId: string) => void,
  // eslint-disable-next-line no-unused-vars
  onModuleIdent: (moduleAddress: string) => void,
) => {
  const rowModules = items.map((item) => (
    <div key={`layout-row-station-card--${item.id}`} className="layout-container-row-card">
      <Layout16CardReduxControl
        channelNo={item.id}
        stationInstanceId={stationInstanceId}
        onModuleIdent={() => onModuleIdent(item.id)}
        onModuleOpen={() => onModuleOpen(item.instanceId)}
      />
    </div>
  ));
  return (
    <div className={`layout-container-row--${items.length}`}>
      {rowModules}
    </div>
  );
};

const ContactronCockpitModuleLayout16: React.FC<StationModuleLayoutProps> = (props: StationModuleLayoutProps):React.ReactElement => {
  const {
    topology, stationInstanceId, onModuleIdent, onModuleOpen,
  } = props;

  const layout = mapCountToLayout[topology.length] ?? { row1: 0, row2: 0 };
  const row1modules = createCardRow(topology.slice(0, layout.row1), stationInstanceId, onModuleOpen, onModuleIdent);
  const row2modules = createCardRow(topology.slice(layout.row1), stationInstanceId, onModuleOpen, onModuleIdent);

  return (
    <div className="contactron-station-cockpit-layout16">
      {row1modules}
      {row2modules}
    </div>
  );
};

export default ContactronCockpitModuleLayout16;
