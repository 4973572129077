/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import React from 'react';
import './BackstageMenu.scss';

export interface BackstagePage {
  title: string;
  createControl: () => React.ReactElement;
}

export interface BackstageMenuProps {
  pages: BackstagePage[];
  selectedIndex: number;
  onPageSelected: (index: number, page: BackstagePage) => void;
}

export const BackstageMenu: React.FC<BackstageMenuProps> = (
  props: BackstageMenuProps,
): React.ReactElement => {
  const { pages, selectedIndex, onPageSelected } = props;

  const activate = (idx: number) => {
    onPageSelected(idx, pages[idx]);
  };

  const items: React.ReactElement[] = pages.map((page, idx) => {
    const selected = (idx === selectedIndex) ? 'selected' : '';
    return (
      <div
        key={`${selected}item-${page.title}`}
        className={`backstage-menu-item ${selected}`}
        role="button"
        tabIndex={0}
        onClick={(ev) => { ev.stopPropagation(); activate(idx); }}
        onKeyDown={(ev) => ev.key === 'Enter' && activate(idx)}
      >
        {page.title}
      </div>
    );
  });

  return (
    <div className="backstage-menu">
      {items}
    </div>
  );
};

export default BackstageMenu;
