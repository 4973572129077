/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/prop-types */

import React from 'react';
import { Range, getTrackBackground } from 'react-range';

export interface SliderBoxControlProps {
  value: number;
  minRangeValue: number;
  maxRangeValue: number;
  step: number;
  // eslint-disable-next-line no-unused-vars
  onValueChange: (value: number) => void;
}

const SliderBoxControl: React.FC<SliderBoxControlProps> = (prps: SliderBoxControlProps)
: React.ReactElement => {
  const {
    value,
    minRangeValue, maxRangeValue, step,
    onValueChange,
  } = prps;

  const rangeValue = Math.min(Math.max(value, minRangeValue), maxRangeValue);

  const trackHeight = '3px';
  const thumbHeight = '11px';

  return (
    <div className="d-flex justify-content-center flex-wrap">
      <Range
        values={[rangeValue]}
        min={minRangeValue}
        max={maxRangeValue}
        step={step}
        rtl={false}
        allowOverlap={false}
        draggableTrack={false}
        onChange={(values) => onValueChange(values[0])}
        onFinalChange={(values) => onValueChange(values[0])}
        renderTrack={({ props, children }) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: thumbHeight,
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: trackHeight,
                width: '100%',
                background: getTrackBackground({
                  values: [rangeValue],
                  colors: ['#0098a1', '#f2f4f6'],
                  min: minRangeValue,
                  max: maxRangeValue,
                  rtl: false,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={{
              ...props.style,
              height: thumbHeight,
              width: trackHeight,
              outline: '0',
              backgroundColor: '##0098a1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: thumbHeight,
                width: trackHeight,
                backgroundColor: '#0098a1',
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default SliderBoxControl;
