/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../store';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import Phasen3Control from '../../../Charts/3PhasenControl/3PhasenControl';
import { calculateChartMarkPoints, calculateChartSegment, controlLimitSelector } from '../selectors';
import { deviceControlStateCollectionSelector } from '../../../../store/deviceInstances/store/controlStateCollection/selectors';
import { GaugeControlState, setGaugeIndicator } from '../../../../store/deviceInstances/store/controlStateCollection';
import useDragIndicatorStatusValue from '../hooks/useDragIndicatorStatusValue';

export interface SystemDataCurrentChartProps {
  deviceInstanceId: string;
  current: DeviceModelStatus.UI.Contactron.MotorStarterCurrent;
}

const SYSTEM_3PHASE_CONTROL_UNIT = '%';

export const SystemDataCurrentChart: React.FC<SystemDataCurrentChartProps> = (props: SystemDataCurrentChartProps):
  React.ReactElement => {
  const {
    deviceInstanceId,
    current,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const powerDragPropertyId = `chart-3phase-${current.variableL1}`;
  const powerShowDragIndicator = useTypedSelector((state) => {
    const control = deviceControlStateCollectionSelector(state.deviceInstances, deviceInstanceId)?.[powerDragPropertyId];
    return control === undefined ? false : (control as GaugeControlState).showIndicator;
  });

  const currentChartLimits = useTypedSelector((state) => controlLimitSelector(
    state.deviceInstances,
    deviceInstanceId,
    DatasetType.device,
    current.variableL1,
    current.limits,
  ));
  const currentChartSegments = calculateChartSegment(currentChartLimits);
  const currentChartMarkPoints = calculateChartMarkPoints(currentChartSegments, '%.2f');

  const [currentL1Value, currentL1MinValue, currentL1MaxValue, resetL1DragValues] = useDragIndicatorStatusValue(deviceInstanceId, current.variableL1);
  const [currentL2Value, currentL2MinValue, currentL2MaxValue, resetL2DragValues] = useDragIndicatorStatusValue(deviceInstanceId, current.variableL2);
  const [currentL3Value, currentL3MinValue, currentL3MaxValue, resetL3DragValues] = useDragIndicatorStatusValue(deviceInstanceId, current.variableL3);

  return (
    <Phasen3Control
      label={t(current.label)}
      unit={SYSTEM_3PHASE_CONTROL_UNIT}
      chartSegments={currentChartSegments}
      chartMarks={currentChartMarkPoints}
      l1Value={currentL1Value}
      l1DragMinValue={currentL1MinValue}
      l1DragMaxValue={currentL1MaxValue}
      l2Value={currentL2Value}
      l2DragMinValue={currentL2MinValue}
      l2DragMaxValue={currentL2MaxValue}
      l3Value={currentL3Value}
      l3DragMinValue={currentL3MinValue}
      l3DragMaxValue={currentL3MaxValue}
      showDragIndicator={powerShowDragIndicator}
      displayFormat="%.2f"
      onResetDragValues={() => {
        resetL1DragValues(currentL1Value);
        resetL2DragValues(currentL2Value);
        resetL3DragValues(currentL3Value);
      }}
      onShowDragIndicator={(value) => {
        dispatch(setGaugeIndicator(deviceInstanceId, {
          ident: powerDragPropertyId,
          indicator: value,
        }));
      }}
    />
  );
};
