/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../../../../../store';
import { ContactronModule } from '../../../../../../store/wizards/contactronStation';
import { VariableStringEditorControl } from '../../../../../../components/ParameterEditor/VariableEditorControl';
import './TableRowStation.scss';

export interface DeviceNamingTableProps {
  stationInstanceId: string;
  station: ContactronModule;
}

const TableRowStation: React.FC<DeviceNamingTableProps> = (props: DeviceNamingTableProps)
: React.ReactElement => {
  const { station, stationInstanceId } = props;

  const stationTagDescriptor = useTypedSelector((state) => {
    const tagDescriptor = state.deviceInstances.instances[stationInstanceId]?.deviceDataset.user.descriptors.IFSM_ADDR_APPL_AKZ;
    return tagDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor
      ? undefined
      : tagDescriptor;
  });
  const stationTagValue = useTypedSelector((state) => state.deviceInstances.instances[stationInstanceId]?.deviceDataset.user.values.IFSM_ADDR_APPL_AKZ);

  const control = stationTagDescriptor === undefined
    ? <div>Undefined station tag variable IFSM_ADDR_APPL_AKZ</div>
    : (
      <VariableStringEditorControl
        deviceInstanceId={stationInstanceId}
        statusDescriptor={stationTagDescriptor}
        statusValue={stationTagValue}
        noLabel
      />
    );

  return (
    <tr>
      <td className="table-row-station__address">
        <div>{station.instance.address}</div>
      </td>
      <td className="table-row-station__name">
        <div>{station.instance.name}</div>
      </td>
      <td className="table-row-station__control">
        <div>{control}</div>
      </td>
    </tr>
  );
};

export default TableRowStation;
