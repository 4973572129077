/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Layout16Card.scss';
import { LayoutCardButton } from '../../LayoutCardButton/LayoutCardButton';
import { LayoutCardProps } from '../../types';

export interface Layout16CardProps extends LayoutCardProps {
  firmware: string,
  hardware: string,
}

const Layout16Card: React.FC<Layout16CardProps> = (props: Layout16CardProps):React.ReactElement => {
  const {
    title, tag, firmware, hardware, color,
    onModuleOpen, onModuleIdent,
  } = props;
  const { t } = useTranslation();
  const statusClass = `contactron-layout16-card__status contactron-layout16-card__status-color-${color}`;

  return (
    <div className="contactron-layout16-card">
      <div className={statusClass} />
      <div className="contactron-layout16-card__header">
        <div className="contactron-layout16-card__header-title">{title}</div>
        <LayoutCardButton onSubmenuAction={(action) => {
          switch (action) {
            case 'open': onModuleOpen(); break;
            case 'flash': onModuleIdent(); break;
            default:
              break;
          }
        }}
        />
      </div>
      <div className="contactron-layout16-card__content">
        <div className="contactron-layout16-card__content-element">
          <div className="contactron-layout16-card__content-element-text-bold">
            {tag}
          </div>
        </div>
        <div className="contactron-layout16-card__content-element">
          <div className="contactron-layout16-card__content-element-text">
            {t<string>('CONTACTRON_STATION_CARD_FIRMWARE')}
          </div>
          <span> </span>
          <div className="contactron-layout16-card__content-element-text">
            {firmware}
          </div>
        </div>
        <div className="contactron-layout16-card__content-element">
          <div className="contactron-layout16-card__content-element-text">
            {t<string>('CONTACTRON_STATION_CARD_HARDWARE')}
          </div>
          <span> </span>
          <div className="contactron-layout16-card__content-element-text">
            {hardware}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout16Card;
