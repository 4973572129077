/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { WebDevice } from '@gpt/commons';
import {
  DEVICE_SIMULATION__DEC_SIMULATED_DEVICE_COUNT, DEVICE_SIMULATION__INC_SIMULATED_DEVICE_COUNT, DEVICE_SIMULATION__LOAD_CONFIG,
  DEVICE_SIMULATION__SAVE_CONFIG, DEVICE_SIMULATION__SET_SIMULATED_DEVICE_COUNT, SimulatedDevicesCount,
  SimulationConfig, typeDeviceSimulationActionTypes,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const incSimulatedDeviceCount = (deviceId: WebDevice.DeviceCatalogInformation): typeDeviceSimulationActionTypes => ({
  type: DEVICE_SIMULATION__INC_SIMULATED_DEVICE_COUNT,
  payload: deviceId,
});

export const decSimulatedDeviceCount = (deviceId: WebDevice.DeviceCatalogInformation): typeDeviceSimulationActionTypes => ({
  type: DEVICE_SIMULATION__DEC_SIMULATED_DEVICE_COUNT,
  payload: deviceId,
});

export const setSimulatedDeviceCount = (payload: SimulatedDevicesCount): typeDeviceSimulationActionTypes => ({
  type: DEVICE_SIMULATION__SET_SIMULATED_DEVICE_COUNT,
  payload,
});

export const loadDeviceSimulationConfig = (): typeDeviceSimulationActionTypes => ({
  type: DEVICE_SIMULATION__LOAD_CONFIG,
});

export const saveDeviceSimulationConfig = (payload: SimulationConfig): typeDeviceSimulationActionTypes => ({
  type: DEVICE_SIMULATION__SAVE_CONFIG,
  payload,
});
