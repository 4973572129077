/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import SystemDataMenuControl from '../../SystemDataMenu/SystemDataMenu';
import './SystemDataCockpitCard.scss';

export interface SystemDataCockpitCardProps {
  deviceInstanceId: string;
  dataView?: DeviceModelStatus.UI.Contactron.MotorStarterDataView;
}

const SystemDataCockpitCard: React.FC<SystemDataCockpitCardProps> = (props: SystemDataCockpitCardProps):
  React.ReactElement => {
  const {
    dataView,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  return (
    <PaperControl title={t<string>(dataView?.label ?? 'SYSTEM DATA')}>
      <div className="contactron-system-data">
        <SystemDataMenuControl deviceInstanceId={deviceInstanceId} dataView={dataView} />
      </div>
    </PaperControl>
  );
};

export default SystemDataCockpitCard;
