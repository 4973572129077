/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnsupportedControl } from '../../../../../components/UnsupportedControl';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../../../../hooks/useContextDataset';
import { CompareModeType } from '../../../../../views/CompareView/common';
import { CompareStatusValueProps } from '../../../CompareTypes';
import useCompareMiddleButton from '../../../hooks/useCompareMiddleButton';
import useDispatchDateValue from '../../../hooks/useDispatchDateValue';
import useVariableValidationMessage from '../../../hooks/useVariableValidationMessage';
import CompareDateValue from './CompareDateValue/CompareDateValue';

/**
 * Compare two values from Dataset Value : value vs externalValue
 * backupValue used as storage for value before modification
 *
 * @param props Control Properties
 * @returns
 */
const CompareDateStatusValue
: React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
  : React.ReactElement | null => {
  const {
    compareMode,
    identRef, leftDataset, rightDataset,
    onShowContentHelp, onHideContentHelp,
    showImageAndSign, deviceInstanceId,
  } = props;
  const { t } = useTranslation();

  const leftDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef, leftDataset);
  const leftValue = useContextDatasetStatusValue(deviceInstanceId, identRef, leftDataset);

  const rightDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef, rightDataset);
  const rightValue = useContextDatasetStatusValue(deviceInstanceId, identRef, rightDataset);

  const dispatchValue = useDispatchDateValue(deviceInstanceId, leftDataset);
  const warning = useVariableValidationMessage(leftDescriptor, leftValue);
  const [buttonFunction, onMiddleButtonClick] = useCompareMiddleButton(deviceInstanceId, leftValue, rightValue);

  if (leftDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareDateStatusValue: Unsupported type" />;
  }

  if (rightDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareDateStatusValue: Unsupported type" />;
  }

  if (leftDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.DATE) {
    return <UnsupportedControl text="CompareDateStatusValue: only DATE is supported" />;
  }

  if (rightDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.DATE) {
    return <UnsupportedControl text="CompareDateStatusValue: only DATE is supported" />;
  }

  const equal = leftValue?.value === rightValue?.value;

  if (compareMode === CompareModeType.ShowDifference && equal) {
    return (null);
  }

  let readonly = leftDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY;
  if (compareMode === CompareModeType.ShowAll) {
    readonly = true;
  }

  return (
    <CompareDateValue
      label={t(leftDescriptor.label)}
      help={t<string>(leftDescriptor.help)}
      equal={equal}
      valueLeft={leftValue?.value}
      hideLeftControl={leftDescriptor.visibility === false}
      valueRight={rightValue?.value}
      hideRightControl={rightDescriptor.visibility === false}
      onButtonClick={() => onMiddleButtonClick(buttonFunction)}
      modified={leftValue?.modified ?? false}
      readonly={readonly}
      onLeftValueChange={(ev) => dispatchValue(ev, leftValue)}
      warning={warning}
      onHideContentHelp={onHideContentHelp}
      onShowContentHelp={onShowContentHelp}
      buttonFunction={buttonFunction}
      showImageAndSign={showImageAndSign}
    />
  );
};

export default CompareDateStatusValue;
