/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  TRIO3_CHANNEL_SWITCH__SWITCH_CHANNEL,
  Trio3SwitchChannelPayload,
  typeIoLinkTrio3SwitchChannelMiddlewareActions,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const trio3SwitchChannel = (payload: Trio3SwitchChannelPayload)
: typeIoLinkTrio3SwitchChannelMiddlewareActions => ({
  type: TRIO3_CHANNEL_SWITCH__SWITCH_CHANNEL,
  payload,
});
