/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { useSelector, TypedUseSelectorHook } from 'react-redux';
import {
  combineReducers, PreloadedState,
} from 'redux';
import contextHelpReducer from './contexthelp/reducer';
import compareViewReducer from './compareView/reducer';
import deviceWizardStateReducer from './deviceWizard/reducer';
import linearizationDatasetReducer from './linearizationDataset/reducer';
import applicationMessageReducer from './applicationMessage/reducer';
import reportReducer from './reportService/reducer';
import deviceSimulationReducer from './deviceSimulation/reducer';
import systemInformationReducer from './systemInformation/reducer';
import cxeTransferWizardReducer from './wizards/cxeTransferWizard/reducer';
import { deviceInstancesReducer } from './deviceInstances/reducer';
import discoveryServiceStateReducer from './discoveryServiceState/reducer';
import contactronStationInitWizardReducer from './wizards/contactronStation/reducer';

export const rootReducer = combineReducers({
  contextHelp: contextHelpReducer,
  compareView: compareViewReducer,
  deviceWizard: deviceWizardStateReducer,
  linearizationDataset: linearizationDatasetReducer,
  applicationMessage: applicationMessageReducer,
  reportDataset: reportReducer,
  deviceSimulation: deviceSimulationReducer,
  systemInformation: systemInformationReducer,
  cxeTransferWizard: cxeTransferWizardReducer,
  deviceInstances: deviceInstancesReducer,
  discoveryService: discoveryServiceStateReducer,
  contactronInitWizard: contactronStationInitWizardReducer,
});

export type rootState = ReturnType<typeof rootReducer>;

export const useTypedSelector: TypedUseSelectorHook<rootState> = useSelector;

export declare type typePreloadedRootState = PreloadedState<rootState>;
