/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import question from '../../assets/icons/icon-question-circle.svg';
import { DeviceStatusLedControl } from '../DeviceStatusLed/DeviceStatusLedControl';
import './DeviceStatusControl.css';

export interface DeviceStatusLed {
  fgcolor: string;
  bkcolor?: string;
  frequency?: string;
}

export interface DeviceStatusItemProps {
  label: string;
  text: string;
  message?: string;
  led: DeviceStatusLed;
}

export const DeviceStatusControl: React.FC<DeviceStatusItemProps> = (props: DeviceStatusItemProps): React.ReactElement => {
  const {
    label, text, message, led,
  } = props;
  const { t } = useTranslation();

  const frequency = led.frequency === undefined ? undefined : `${led.frequency.replace('.', '-')}Hz`;

  const trtext = text === undefined ? undefined : t<string>(text);
  const prfx = trtext === undefined || trtext === '' ? '' : ':';
  const trlabel = `${t<string>(label)}${prfx}`;

  return (
    <div className="DeviceStatusControl_content">
      <div className="DeviceStatusControl_icon">
        <DeviceStatusLedControl fgcolor={led.fgcolor} bkcolor={led.bkcolor} frequency={frequency} />
      </div>
      <div className="DeviceStatusControl_label">
        {trlabel}
        <span> </span>
      </div>
      <div className="DeviceStatusControl_label_1">
        {trtext}
      </div>
      <div className="DeviceStatusControl_question">
        {message
        && (
        <Image
          src={question}
          height={18}
          width={18}
        />
        )}
      </div>
    </div>
  );
};
