/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { IdentRef, DeviceModelStatus } from '@gpt/commons';
import React, { useContext } from 'react';
import { ReduxControlProps } from '../../../controls/ReduxControlProps';
import { useTypedSelector } from '../../../store';
import { DatasetState, DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { DatasetContext } from '../../../views/DatasetContext';
import PreviewValueVariableControl from '../PreviewValueVariableControl/PreviewValueVariableControl';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';

const getSpanControlType = (dataset: DatasetState, identRef: IdentRef)
: DeviceModelStatus.UI.RangeWithSpanControlType | undefined => {
  const spanDescriptor = dataset.descriptors[identRef];
  if (spanDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (spanDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN) {
    return undefined;
  }
  return spanDescriptor.controlType;
};

const controlMember = (dataset: DatasetState, identRef: IdentRef, member: 'rangeEnd' | 'rangeStart') => {
  const controlType = getSpanControlType(dataset, identRef);
  if (controlType === undefined) {
    return undefined;
  }
  return controlType[member];
};

const controlMemberSelector = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: IdentRef, member: 'rangeEnd' | 'rangeStart') => {
  const deviceDataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (deviceDataset === undefined) {
    return undefined;
  }
  return controlMember(deviceDataset, identRef, member);
};

const getControlVisibility = (dataset: DatasetState, identRef: IdentRef): boolean => {
  const spanDescriptor = dataset.descriptors[identRef];
  if (spanDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return false;
  }
  const visible = spanDescriptor.visibility ?? true;
  if (!visible) {
    return false;
  }
  const spanControl = getSpanControlType(dataset, identRef);
  if (spanControl === undefined) {
    return false;
  }
  const rangeEndDesc = dataset.descriptors[spanControl.rangeEnd];
  const endVisible = rangeEndDesc.type === DeviceModelStatus.StatusType.StatusDescriptor
    ? (rangeEndDesc.visibility ?? true)
    : false;
  const rangeStartDesc = dataset.descriptors[spanControl.rangeStart];
  const startVisible = rangeStartDesc.type === DeviceModelStatus.StatusType.StatusDescriptor
    ? (rangeStartDesc.visibility ?? true)
    : false;
  return endVisible && startVisible;
};

const PreviewRangeWithSpanControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement => {
  const { identRef, deviceInstanceId } = props;
  const { targetDataset } = useContext(DatasetContext);

  const startRangeIdentRef = useTypedSelector((state) => controlMemberSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef, 'rangeStart'));
  const endRangeIdentRef = useTypedSelector((state) => controlMemberSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef, 'rangeEnd'));
  const controlVisibility = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (deviceDataset === undefined) {
      return true;
    }
    return getControlVisibility(deviceDataset, identRef);
  });

  if (controlVisibility === false) {
    return <div />;
  }

  if (startRangeIdentRef === undefined || endRangeIdentRef === undefined) {
    return <div />;
  }

  return (
    <div>
      <PreviewValueVariableControl deviceInstanceId={deviceInstanceId} identRef={startRangeIdentRef} />
      <PreviewValueVariableControl deviceInstanceId={deviceInstanceId} identRef={endRangeIdentRef} />
    </div>
  );
};

export default PreviewRangeWithSpanControl;
