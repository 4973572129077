// eslint-disable-next-line no-shadow, import/prefer-default-export
export enum NatsServerConnectionState {
  // eslint-disable-next-line no-unused-vars
  Connected = 'Connected',
  // eslint-disable-next-line no-unused-vars
  Disconnected = 'Disconnected',
  // eslint-disable-next-line no-unused-vars
  Reconnecting = 'Connecting',
  // eslint-disable-next-line no-unused-vars
  Closed = 'Closed',
}
