/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext, useEffect } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { shallowEqual } from 'react-redux';
import { useTypedSelector } from '../../../store';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { StructureItemStateChangeCallback } from '../../ReduxControlProps';
import { DatasetContext } from '../../../views/DatasetContext';

const useStatusValueStateUpdate = (
  identRef: string,
  deviceInstanceId: string | undefined,
  datasetType: DatasetType | undefined,
  onStateChanged: StructureItemStateChangeCallback,
) => {
  const { targetDataset } = useContext(DatasetContext);

  const [modified, error, visibility] = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, datasetType ?? targetDataset);
    if (dataset === undefined) {
      return [false, false, true];
    }

    const descriptor = dataset.descriptors[identRef];
    const datasetValueModified = dataset.values[identRef]?.modified ?? false;
    const datasetValueValidity = dataset.values[identRef]?.valueValidity ?? DeviceModelStatus.StatusValueValidity.valid;

    const datasetDescValidity = descriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor
      ? descriptor.validity : true;
    const datasetDescVisibility = descriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor
      ? descriptor.visibility : true;

    return [
      datasetDescValidity ? datasetValueModified : false,
      datasetDescValidity ? datasetValueValidity !== DeviceModelStatus.StatusValueValidity.valid : false,
      datasetDescVisibility,
    ];
  }, shallowEqual);

  // When control removed, the modified and error state is clean
  useEffect(() => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, {
      error,
      modified,
    });
  }, [identRef, error, modified]);

  return visibility;
};

export default useStatusValueStateUpdate;
