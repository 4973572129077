/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { NormalComponents } from 'react-markdown/lib/complex-types';
import { SpecialComponents } from 'react-markdown/lib/ast-to-react';
import { Services } from '@gpt/commons';
import {
  getSystemInformation,
} from '../BackstagePageServices';
import BackstageSupportedDeviceList from './SupportedDeviceList/SupportedDeviceList';
import useAppVersion, { formatAppVersion } from '../../../../hooks/useAppVersion';

const Console = console;

const useBackstageSystemInfo = ()
: Services.DeviceModelServer.SystemInformationResponse|undefined => {
  const [systemInfo, setSystemInfo] = useState<
    Services.DeviceModelServer.SystemInformationResponse|undefined>(undefined);

  useEffect(() => {
    getSystemInformation()
      .then((response) => setSystemInfo(response))
      .catch((err) => Console.log(err));
  }, []);

  return systemInfo;
};

export default function BackstagePageAppInfo(): React.ReactElement {
  const { t } = useTranslation();
  const appVersion = useAppVersion();
  const systemInfo = useBackstageSystemInfo();
  const serverVersion = systemInfo?.serverVersion === undefined
    ? 'unknown'
    : formatAppVersion(systemInfo.serverVersion);

  const markdownComponents: Partial<NormalComponents & SpecialComponents> = {
    h1: 'h1',
    p: ({ ...props }) => <p {...props} />,
    a: ({ ...props }) => <a {...props} target="_blank" rel="noreferrer" />,
  };

  return (
    <div>
      <h1>
        {`${t<string>('BACKSTAGE_PAGE_APP_INFO__TITLE')}`}
      </h1>
      <ReactMarkdown components={markdownComponents}>
        {`${t<string>('BACKSTAGE_PAGE_APP_INFO__COPYRIGHT')}`}
      </ReactMarkdown>
      <p>
        {`${t<string>('BACKSTAGE_PAGE_APP_INFO__APPLICATION_VERSION')}: `}
        <b>{appVersion}</b>
        <br />
        {`${t<string>('BACKSTAGE_PAGE_APP_INFO__BUILD_DATE')}: `}
        {systemInfo?.buildDate}
        <br />
        {`${t<string>('BACKSTAGE_PAGE_APP_INFO__SERVER_VERSION')}: `}
        {serverVersion}
        <br />
        {`${t<string>('BACKSTAGE_PAGE_APP_INFO__NODE_JS_VERSION')}: `}
        {systemInfo?.nodeJsVersion}
      </p>

      <h2>
        {`${t<string>('BACKSTAGE_PAGE_APP_INFO__SUPPORTED_DEVICE_TYPES')}`}
      </h2>
      <BackstageSupportedDeviceList />
    </div>
  );
}
