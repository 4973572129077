/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  DeviceModelStatus, Report, ReportContentType, TransferToDevice, IdentRef,
} from '@gpt/commons';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../store';
import { LoadingControl } from '../../components/LoadingControl';
import ActionProgressComponent from '../../PageComponents/PageSaveProtocolComponent/ActionProgress/ActionProgress';
import ActionProtocolButtonList from '../../PageComponents/PageSaveProtocolComponent/ActionProtocolButtonList/ActionProtocolButtonList';
import SaveProtocolButtonList from '../../PageComponents/PageSaveProtocolComponent/SaveProtocolButtonList/SaveProtocolButtonList';
import SaveProtocolDocumentation from '../../PageComponents/PageSaveProtocolComponent/SaveProtocolDocumentation/SaveProtocolDocumentation';
import { createReportFilename } from '../../helpers/functions';
import DownloadMethodControl from '../../controls/MethodExecution/DownloadMethodControl/DownloadMethodControl';
import { MethodStageExecutionStatus } from '../../store/deviceInstances/store/deviceMethod/types';
import { useContextActiveDeviceInstance } from '../../store/deviceInstances/hooks/ActiveDeviceHooks';
import './DownloadProgressDialog.scss';

export interface DownloadProgressDialogProps {
  closeButtonText: string;
  reportMenu?: IdentRef;
  onClose: () => Promise<void>;
  deviceInstanceId: string;
}

const DOWNLOAD_IDENT = DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF;

export const DownloadProgressDialog
: React.FC<DownloadProgressDialogProps> = (props: DownloadProgressDialogProps)
: React.ReactElement => {
  const { t } = useTranslation();
  const {
    closeButtonText, reportMenu, onClose, deviceInstanceId,
  } = props;
  const activeDeviceInstance = useContextActiveDeviceInstance(deviceInstanceId);
  const [userNameValue, setUserNameValue] = useState(`${t<string>('MODAL_DOWNLOAD__NAME_TEMPLATE')}`);
  const [userTextValue, setUserTextValue] = useState(`${t<string>('MODAL_DOWNLOAD__DESCRIPTION_TEMPLATE')}`);
  const stageStatus = useTypedSelector((state) => state.deviceInstances.instances[deviceInstanceId]?.deviceMethodExecution[DOWNLOAD_IDENT]?.stage ?? MethodStageExecutionStatus.Initialize);

  if (activeDeviceInstance === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const control = stageStatus === MethodStageExecutionStatus.RequestPassword || stageStatus === MethodStageExecutionStatus.RequestAcceptData
    ? (
      <DownloadMethodControl methodIdent={DOWNLOAD_IDENT} deviceInstanceId={deviceInstanceId} />
    )
    : (
      <>
        <SaveProtocolDocumentation
          nameCaption={t<string>('MODAL_DOWNLOAD__DOCUMENTATION__NAME')}
          descriptionCaption={t<string>('MODAL_DOWNLOAD__DOCUMENTATION__DESCRIPTION')}
          onUserNameChanged={(text) => setUserNameValue(text)}
          onUserTextChanged={(text) => setUserTextValue(text)}
          instanceSerialNumber={activeDeviceInstance.device.instance?.serialNumber}
          userName={userNameValue}
          userText={userTextValue}
        />
        <SaveProtocolButtonList
          reportMenu={reportMenu}
          targetInstance={deviceInstanceId}
          dictFamily={activeDeviceInstance.device.catalog?.i18n.family ?? ''}
          disabled={stageStatus !== MethodStageExecutionStatus.DoneSuccess}
          fileName={createReportFilename('FileTransferReport', activeDeviceInstance.device)}
          onGetContent={async () => {
            const { device } = activeDeviceInstance;
            const content: TransferToDevice = {
              type: ReportContentType.TransferToDevice,
              device: {
                deviceDesignation: device.catalog?.deviceTypeName ?? '',
                deviceTag: device.instance?.deviceTag ?? '',
                firmwareRevision: device.instance?.firmwareVersion ?? '',
                hardwareRevision: device.instance?.hardwareVersion ?? '',
                serialNumber: device.instance?.serialNumber ?? '',
                itemNumber: device.catalog?.productOrderNumber ?? '',
              },
            };

            return {
              header: {
                dateTime: new Date(),
                systemName: t<string>('', '{{SYSTEM_NAME}}'),
                userName: userNameValue,
                userNotes: userTextValue,
              },
              content,
            };
          }}
        />
        <Row className="download-progress-modal-dialog__container__row" />
        <ActionProtocolButtonList
          closeButtonText={closeButtonText}
          onDone={() => onClose()}
          onDoneDisabled={
            stageStatus === MethodStageExecutionStatus.Initialize
            || stageStatus === MethodStageExecutionStatus.InProgress
          }
        />
      </>
    );

  return (
    <div className="download-progress-modal-dialog">
      <div className="download-progress-modal-dialog__container">
        <Container className="w-100 h-100" fluid>
          <Row>
            <Col className="d-flex flex-row justify-content-center align-items-center w-100" xs={12}>
              <div className="download-progress-modal-dialog__container__title">{t<string>('MODAL_DOWNLOAD__CAPTION')}</div>
            </Col>
          </Row>
          <Row className="download-progress-modal-dialog__container__row" />
          <Row>
            <Col xs={6}>
              <ActionProgressComponent
                title={t<string>('MODAL_DOWNLOAD__PROGRESS__CAPTION')}
                methodIdent={DOWNLOAD_IDENT}
                deviceInstanceId={deviceInstanceId}
              />
            </Col>
            <Col xs={6}>
              {control}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export const ModalDownloadProgressDialog: React.FC<DownloadProgressDialogProps> = (props:DownloadProgressDialogProps) => {
  const {
    closeButtonText, reportMenu, onClose, deviceInstanceId,
  } = props;
  const modalRoot = document.getElementById('modal-root');
  if (modalRoot === null) {
    return <div />;
  }
  return (
    <>
      {ReactDOM.createPortal(
        <DownloadProgressDialog deviceInstanceId={deviceInstanceId} closeButtonText={closeButtonText} reportMenu={reportMenu} onClose={onClose} />,
        modalRoot,
      )}
    </>
  );
};
