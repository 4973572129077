/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceInformation } from '@gpt/commons';
import React from 'react';
import { Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import chevron from '../../../assets/icons/circle-chevron-white.svg';
import IconTopology from '../../../assets/icons/icon-topology.svg';
import { LoadingSpinner } from '../../LoadingControl/LoadingSpinner';
import './DeviceCardStation.scss';

export interface DeviceCardStationProps {
    device: DeviceInformation,
    size?: 'main' | 'wizard',
    showChevron?: boolean;
    selectable: boolean;
    selected?: boolean;
    busy?: boolean;
    supported?: boolean;
    onClick: () => void;
}

export const getScanProtocolName = (protocol: string): string | undefined => {
  let scanProtocol: string | undefined;
  switch (protocol.toLocaleLowerCase()) {
    case 'io-link':
      scanProtocol = 'USB';
      break;
    case 's-port':
      scanProtocol = 'USB';
      break;
    case 'blue':
      scanProtocol = 'Bluetooth';
      break;
    default:
      break;
  }

  return scanProtocol;
};

export const DeviceCardStation: React.FC<DeviceCardStationProps> = (props: DeviceCardStationProps)
: React.ReactElement => {
  const {
    device, onClick, size, showChevron, selectable, selected, busy, supported,
  } = props;
  const { t } = useTranslation();

  const selectedClass = selected ? 'selected ' : '';
  const disabledClass = selectable ? '' : 'disabled ';
  const sizeClass = `devicecardstation-button-${size ?? 'main'}`;
  const rootClasses = `${selectedClass}${disabledClass}${sizeClass}`;

  const scanProtocol = getScanProtocolName(device.catalog.protocol);
  let protocolText;
  if (device.instance?.simulation === true) {
    protocolText = `${device.catalog.protocol} Simulation`;
  } else {
    protocolText = scanProtocol === undefined ? device.catalog.protocol : `${scanProtocol}: ${device.catalog.protocol}`;
  }

  let overlay;
  if (busy) {
    overlay = (
      <div className="devicecardstation-spinner-container">
        <div className="devicecardstation-spinner-indicator">
          <LoadingSpinner size="device-card" />
        </div>
      </div>
    );
  } else if (supported === false) {
    overlay = (
      <div className="devicecardstation-text-container">
        <div className="devicecardstation-text-container__text">{t<string>('DEVICE_CARD__NOT_SUPPORTED')}</div>
      </div>
    );
  }

  return (
    <Button
      className={`devicecardstation-device-root ${rootClasses} m-0 p-0`}
      variant="default"
      onClick={() => onClick()}
      disabled={!selectable}
    >
      <div className="devicecardstation-device-container">
        <div className="devicecardstation-image-container">
          <img
            className={`devicecardstation-image${supported === false ? ' devicecardstation-image--not-supported' : ''}`}
            alt={device.catalog.deviceTypeName}
            src={device.catalog.productCardImage ?? device.catalog.productImage}
          />
          <img
            className="devicecardstation-icon"
            src={IconTopology}
            alt="topology"
          />
        </div>
        {overlay}
        <div className="devicecardstation-device-body">
          <div>
            <div className="devicecardstation-device-title">
              {device.catalog.deviceTypeName}
            </div>
            <div className="devicecardstation-device-text">
              {protocolText}
            </div>
            <div id="deviceTag" className="bmk-text">
              {`${t<string>('DEVICE_CARD__DEVICE_TAG')}: ${device.instance?.deviceTag}`}
            </div>
            <div className="serial-text">
              {`${t<string>('DEVICE_CARD__SERIAL_NUMBER')}: ${device.instance?.serialNumber}`}
            </div>
          </div>
          <div className="devicecardstation-topology-text">{t('TOPOLOGY')}</div>
          <div className="devicecardstation-symbol-container">
            {selectable && (showChevron ? (
              <Image className="devicecardstation-symbol" src={chevron} />
            ) : (
              <div className="devicecardstation-radiobutton">
                <div className={selected ? 'selected' : ''} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Button>
  );
};
