/* eslint-disable @typescript-eslint/no-empty-function */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

/* eslint-disable max-len */
import React from 'react';
import { StandardViewButton } from '../components/ButtonList/ButtonList.StandardView';

export interface ApplicationContextProps {
  // eslint-disable-next-line no-unused-vars
  onDeviceButtonClick: (button: StandardViewButton) => void;
}

export const ApplicationContext = React.createContext<ApplicationContextProps>({
  onDeviceButtonClick: () => 0,
});
