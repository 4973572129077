/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { v4 } from 'uuid';
import './Trio3ChannelSwitch.css';

export interface Trio3ChannelSwitchProps {
  id: string;
  led: Trio3ChannelLed;
  disabled?: boolean;
  onSwitchChannel: () => void;
}

export enum Trio3ChannelLed {
  // eslint-disable-next-line no-unused-vars
  GREY = 0,
  // eslint-disable-next-line no-unused-vars
  GREEN = 1,
  // eslint-disable-next-line no-unused-vars
  YELLOW = 2,
  // eslint-disable-next-line no-unused-vars
  RED = 3,
  // eslint-disable-next-line no-unused-vars
  BLINK_RED_YELLOW = 4,
  // eslint-disable-next-line no-unused-vars
  BLINK_RED = 5,
  // eslint-disable-next-line no-unused-vars
  BLINK_YELLOW = 6,
}

export const Trio3ChannelSwitch: React.FC<Trio3ChannelSwitchProps> = (props: Trio3ChannelSwitchProps)
:React.ReactElement => {
  const {
    led, onSwitchChannel, id, disabled,
  } = props;
  const channelId = `${id}-${v4()}`;

  // blinking freq 1.4Hz 2.8Hz
  // 0 - gray, 1 - green, 2 - yellow, 3 - red,
  // 4 - blink red -> yellow, 5 - blink to red, 6 - blink to yellow

  const blinkClasses = led > 3 ? '-1-4Hz' : '';
  const color = `trio3-channel-switch-${led}${blinkClasses}`;
  const classes = `trio3-channel-switch-label ${color}`;

  return (
    <label
      className={classes}
      htmlFor={channelId}
    >
      <input
        data-testid="trio3--channel-switch-checkbox"
        type="button"
        id={channelId}
        className="trio3-channel-switch-checkbox"
        onClick={() => onSwitchChannel()}
        disabled={disabled}
      />
    </label>
  );
};
