/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  typeDeviceMethodStageMiddlewareActions,
  AbortMethodPayload,
  StageAcceptDataPayload,
  StageInitPayload,
  StagePasswordPayload,
} from './types';

export const executeMethodStageInit = (targetInstance: string, payload: StageInitPayload)
  : typeDeviceMethodStageMiddlewareActions => ({
  type: 'METHOD_STAGE_EXECUTION__INIT',
  payload: {
    targetInstance,
    data: payload,
  },
});

export const executeMethodStagePassword = (targetInstance: string, payload: StagePasswordPayload)
  : typeDeviceMethodStageMiddlewareActions => ({
  type: 'METHOD_STAGE_EXECUTION__PASSWORD',
  payload: {
    targetInstance,
    data: payload,
  },
});

export const executeMethodStageAcceptData = (targetInstance: string, payload: StageAcceptDataPayload)
  : typeDeviceMethodStageMiddlewareActions => ({
  type: 'METHOD_STAGE_EXECUTION__ACCEPT_DATA',
  payload: {
    targetInstance,
    data: payload,
  },
});

export const abortMethodExecution = (targetInstance: string, payload: AbortMethodPayload)
  : typeDeviceMethodStageMiddlewareActions => ({
  type: 'METHOD_STAGE_EXECUTION__ABORT',
  payload: {
    targetInstance,
    data: payload,
  },
});
