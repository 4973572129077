/* eslint-disable import/prefer-default-export */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { v4 as uuidv4 } from 'uuid';
import {
  ApplicationMessageAction,
  ApplicationMessagePayload,
  SHOW_APPLICATION_MESSAGE,
  REMOVE_APPLICATION_MESSAGE,
  ADD_APPLICATION_MESSAGE,
  CLEANUP_APPLICATION_MESSAGES,
  MessageType,
} from './types';

export const addApplicationMessage = (payload: ApplicationMessagePayload)
  : ApplicationMessageAction => ({
  type: ADD_APPLICATION_MESSAGE,
  payload,
});

export const showApplicationMessage = (type: MessageType, messageId: string)
  : ApplicationMessageAction => ({
  type: SHOW_APPLICATION_MESSAGE,
  payload: {
    type,
    messageId,
    id: `applicationmessage-${uuidv4()}`,
  },
});

export const removeApplicationMessage = (payload: string)
  : ApplicationMessageAction => ({
  type: REMOVE_APPLICATION_MESSAGE,
  payload,
});

export const cleanupApplicationMessages = ()
  : ApplicationMessageAction => ({
  type: CLEANUP_APPLICATION_MESSAGES,
});
