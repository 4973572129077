/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap';
import './MenuTabsReduxControl.scss';
import { useTypedSelector } from '../../../store';
import UnsupportedControl from '../../../components/UnsupportedControl/UnsupportedControl';
import {
  ReduxControlProps,
} from '../../ReduxControlProps';
import ReduxControl from '../../ReduxControl/ReduxControl';
import {
  setContextMenuHelp,
  setContextParameterHelp,
  setContextParameterWarning,
} from '../../../store/contexthelp';
import { compareTabData, menuItemsSelector } from '../../selectors/menuItemsSelector';
import { useContextDatasetDescriptor } from '../../../hooks/useContextDataset';
import { DatasetContext } from '../../../views/DatasetContext';
import withControlVisibility from '../../hoc/withControlVisibility';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import useMenuCurrentTab, { TabData } from '../../hooks/TabMenuHooks/useMenuCurrentTab';
import useMenuTabState from '../../hooks/TabMenuHooks/useMenuTabState';

const createKey = (parent: IdentRef, idRef: IdentRef): string => `${parent}--${idRef}`;

const MenuTabsReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement => {
  const {
    identRef,
    onStateChanged,
    readonly,
    rootLayout,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { targetDataset } = useContext(DatasetContext);

  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  const tabsTitle: TabData[] = useTypedSelector(
    (state) => menuItemsSelector(
      deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset),
      identRef,
    ),
    (prev, curr) => compareTabData(prev, curr),
  );

  const [currentTab, setCurrentTab] = useMenuCurrentTab(identRef, deviceInstanceId, tabsTitle);
  const [childTabState, setChildTabState] = useMenuTabState(identRef, onStateChanged);

  if (descriptor === undefined) {
    return <UnsupportedControl text={`MenuTabsReduxControl: Undefined object ${identRef}`} />;
  }

  if (descriptor.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return <UnsupportedControl text={`MenuTabsReduxControl: Unsupported type ${descriptor.type}`} />;
  }

  const tabContentClassname = rootLayout ? 'menu-tabs-content--overflow-auto' : '';

  const tabs = tabsTitle.map((element) => {
    const tabstate = childTabState[element.identRef];
    let tabClassName = 'nav-tabs--normal h-100';
    if (tabstate !== undefined && tabstate.error) {
      tabClassName = 'nav-tabs--error';
    } else if (tabstate !== undefined && tabstate.modified) {
      tabClassName = 'nav-tabs--modified';
    }
    return (
      <Tab tabClassName={tabClassName} key={`tab-${createKey(descriptor.identRef, element.identRef)}`} eventKey={element.identRef} title={t(element.label)}>
        <div className={`${tabContentClassname} h-100 w-100`}>
          <ReduxControl
            identRef={element.identRef}
            deviceInstanceId={deviceInstanceId}
            readonly={readonly}
            onStateChanged={setChildTabState}
          />
        </div>
      </Tab>
    );
  });

  const activeKey = currentTab !== '' ? currentTab : (tabsTitle?.[0]?.identRef ?? '');
  return (
    <Tabs
      variant="tabs"
      activeKey={activeKey}
      id={`tabs-paramview-${identRef}`}
      className="menu-tabs"
      onSelect={(k) => {
        const tabDesc = tabsTitle.find((tab) => tab.identRef === k);
        dispatch(setContextMenuHelp({
          title: t<string>(tabDesc?.label ?? ''),
          text: t<string>(tabDesc?.help ?? ''),
        }));
        if (deviceInstanceId !== undefined) {
          setCurrentTab(deviceInstanceId, identRef, k ?? '');
        }
        dispatch(setContextParameterHelp({ title: '', text: '' }));
        dispatch(setContextParameterWarning(undefined));
      }}
    >
      {tabs}
    </Tabs>
  );
};

export default withControlVisibility(MenuTabsReduxControl);
