/* eslint-disable @typescript-eslint/no-empty-function */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import Image from 'react-bootstrap/Image';
import { DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EventMonitorTrioTemplate } from '../../EventMonitorTrioTemplate/EventMonitorTrioTemplate';
import IconError from '../../../../assets/icons/icon-error.svg';
import IconWarning from '../../../../assets/icons/icon-warning.svg';
import IconMessage from '../../../../assets/icons/icon-message.svg';
import IconRefresh from '../../../../assets/icons/icon-refresh.svg';
import './Trio3ListItem.css';
import { asknowledgeTrio3Event } from '../../../../store/deviceInstances/middleware/iolink/ioLinkTrio3EventMonitor/actions';
import { Trio3EventState } from '../../../../store/deviceInstances/store/ioLinkTrio3EventMonitor/types';

export interface Trio3ListItemProps {
  id: string;
  timestamp: string;
  state: Trio3EventState;
  event: DeviceModelStatus.UI.Trio3EventDescription,
  targetInstance: string;
}

const pad2 = (data: number): string => `00${data}`.slice(-2);

// format timestamp from milliseconds into "yyyy-mm-dd / hh:mm:ss"
const formatTimestamp = (ts) => {
  const d = new Date(ts);
  const month = pad2(d.getMonth() + 1);
  const day = pad2(d.getDate());
  const year = d.getFullYear();
  const hoursStr = pad2(d.getHours());
  const minutesStr = pad2(d.getMinutes());
  const secondsStr = pad2(d.getSeconds());

  const formattedDate = [year, month, day].join('-');
  const formattedTime = [hoursStr, minutesStr, secondsStr].join(':');

  return [formattedDate, formattedTime];
};

// eslint-disable-next-line max-len
export const Trio3ListItem: React.FC<Trio3ListItemProps> = (props: Trio3ListItemProps): React.ReactElement | null => {
  const {
    id, state, timestamp, event, targetInstance,
  } = props;
  const { label, type } = event;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let messageIcon = IconError;
  switch (type) {
    case 'error':
      messageIcon = IconError;
      break;
    case 'warning':
      messageIcon = IconWarning;
      break;
    case 'message':
      messageIcon = IconMessage;
      break;
    default:
      messageIcon = IconError;
  }

  const eventFormattedTimestamp = formatTimestamp(timestamp);

  if (state === Trio3EventState.inactive) {
    return (null);
  }

  const pendingClass = state === Trio3EventState.pending ? ' trio3-event-monitor__list-item--pending' : '';
  const pendingText = state === Trio3EventState.pending ? ' trio3-event-monitor__list-item__label--pending' : '';

  return (
    <EventMonitorTrioTemplate>
      <div className="trio3-event-monitor__list-item">
        <div className="trio3-event-monitor__list-item__icon">
          <img src={messageIcon} alt={type} className={`h-100 ${pendingClass}`} />
        </div>
        <div className={`trio3-event-monitor__list-item__label${pendingText}`}>
          {t(`TRIO3_PS:${label}`)}
        </div>
      </div>
      <div>
        {eventFormattedTimestamp[0]}
        &nbsp;
        /
        &nbsp;
        {eventFormattedTimestamp[1]}
      </div>
      <div className="trio3-event-monitor__list-item__button">
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            if (state === Trio3EventState.active) {
              dispatch(asknowledgeTrio3Event({ targetInstance, id, event }));
            }
          }}
          onKeyUp={() => {}}
        >
          <Image src={IconRefresh} className={`trio3-event-monitor__list-item__button__icon${pendingClass}`} />
        </div>
      </div>
    </EventMonitorTrioTemplate>
  );
};
