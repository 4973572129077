/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './DeviceStatusLedControl.scss';

export interface DeviceStatusLedControlProps {
  fgcolor: string;
  bkcolor?: string;
  frequency?: string;
}

export const DeviceStatusLedControl: React.FC<DeviceStatusLedControlProps> = (
  props: DeviceStatusLedControlProps,
): React.ReactElement => {
  const {
    fgcolor, bkcolor, frequency,
  } = props;

  const baseClass = 'devicestatusled';
  const colorClass = `devicestatusled-${fgcolor}`;
  let ledClasses = `${baseClass} ${colorClass}`;

  if (frequency) {
    ledClasses = `${ledClasses} devicestatusled-blink-${frequency}`;

    if (bkcolor) {
      ledClasses = `${baseClass} devicestatusled-blink-${fgcolor}-to-${bkcolor}-${frequency}`;
    }
  }

  return (
    <div className="d-flex flex-row align-items-center">
      <div className={ledClasses} />
    </div>
  );
};
