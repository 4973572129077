/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import {
  Report, ReportContentType, FileTransfer, DeviceModelStatus,
} from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reduxStore';
import { deviceWizardUpdateButtons } from '../../../store';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import { PageSaveProtocolComponent } from '../../../PageComponents/PageSaveProtocolComponent/PageSaveProtocolComponent';
import { ActionProgressControl } from '../../../components/ActionProgressControl/ActionProgressControl';
import { LoadingControl } from '../../../components/LoadingControl/LoadingControl';
import { createReportFilename } from '../../../helpers/functions';
import { RESTORE_WIZARD__DATASET_FILE_NAME, RESTORE_WIZARD__BACKUP_FILE_NAME } from '../types';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import DownloadMethodControl from '../../../controls/MethodExecution/DownloadMethodControl/DownloadMethodControl';
import { deviceDataStorageValueSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { deviceMethodExecutionSelector } from '../../../store/deviceInstances/store/deviceMethod/selectors';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';

export interface TPFilePageTransferDatasetProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const TPFilePageTransferDataset: React.FC<TPFilePageTransferDatasetProps> = (props: TPFilePageTransferDatasetProps)
  : React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { active, reportMenu, deviceInstanceId } = props;

  const methodIdent = DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF;
  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId)?.device);

  const deviceTypeName = activeDevice?.catalog.deviceTypeName ?? '';
  const deviceTag = activeDevice?.instance?.deviceTag ?? '';
  const deviceFirmwareVersion = activeDevice?.instance?.firmwareVersion ?? '';
  const deviceHardwareRevision = activeDevice?.instance?.hardwareVersion ?? '';
  const deviceSerialNumber = activeDevice?.instance?.serialNumber;
  const productOrderNumber = activeDevice?.catalog.productOrderNumber ?? '';

  const stageStatus = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, methodIdent)?.stage);
  const stageSteps = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, methodIdent)?.steps ?? []);

  const paramFileName = useTypedSelector((state) => deviceDataStorageValueSelector(state.deviceInstances, deviceInstanceId, RESTORE_WIZARD__DATASET_FILE_NAME));
  const backupFileName = useTypedSelector((state) => deviceDataStorageValueSelector(state.deviceInstances, deviceInstanceId, RESTORE_WIZARD__BACKUP_FILE_NAME));

  useEffect(() => {
    if (active !== true) {
      return;
    }
    if (stageStatus === undefined) {
      // Download method is initializing
      dispatch(deviceWizardUpdateButtons({
        abort: ButtonState.disabled,
        back: ButtonState.disabled,
        next: ButtonState.hidden,
        complete: ButtonState.disabled,
        pcta: ButtonRole.complete,
      }));
      return;
    }

    switch (stageStatus) {
      case MethodStageExecutionStatus.Initialize:
      case MethodStageExecutionStatus.InProgress:
        // Download method is initializing
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.hidden,
          pcta: ButtonRole.complete,
        }));
        break;
      case MethodStageExecutionStatus.DoneFailed:
        // Download method is failed
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.enabled,
          back: ButtonState.enabled,
          next: ButtonState.hidden,
          complete: ButtonState.disabled,
          pcta: ButtonRole.back,
          focusPcta: ButtonRole.back,
        }));
        break;
      case MethodStageExecutionStatus.DoneSuccess:
        // Download method is initializing
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.enabled,
          pcta: ButtonRole.complete,
          focusPcta: ButtonRole.complete,
        }));
        break;
      default:
        // Download method is not initialized
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.hidden,
          pcta: ButtonRole.complete,
        }));
    }
  }, [stageStatus, active]);

  if (activeDevice === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const disableReportButtons = (stageStatus !== MethodStageExecutionStatus.DoneFailed && stageStatus !== MethodStageExecutionStatus.DoneSuccess);
  const control = stageStatus === MethodStageExecutionStatus.RequestPassword
  || stageStatus === MethodStageExecutionStatus.RequestAcceptData
    ? (
      <DownloadMethodControl methodIdent={methodIdent} deviceInstanceId={deviceInstanceId} />
    )
    : (
      <PageSaveProtocolComponent
        reportMenu={reportMenu}
        deviceInstanceId={deviceInstanceId}
        caption={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_TRANSFER_DATASET__DOCUMENTATION_TITLE')}
        nameCaption={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_TRANSFER_DATASET__DOCUMENTATION_NAME')}
        descriptionCaption={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_TRANSFER_DATASET__DOCUMENTATION_DESCRIPTION')}
        serialNumber={deviceSerialNumber}
        disableButtons={disableReportButtons}
        fileName={createReportFilename('FileTransferReport', activeDevice)}
        onGetContent={async (userName: string, userNotes: string, serialNumber: string): Promise<Report> => {
          const content: FileTransfer = {
            type: ReportContentType.FileTransfer,
            device: {
              deviceDesignation: deviceTypeName,
              deviceTag: deviceTag,
              firmwareRevision: deviceFirmwareVersion,
              hardwareRevision: deviceHardwareRevision,
              serialNumber,
              itemNumber: productOrderNumber,
            },
            paramFileName: paramFileName ?? '--',
            backupFileName,
          };

          return {
            header: {
              dateTime: new Date(),
              systemName: t<string>('', '{{SYSTEM_NAME}}'),
              userName,
              userNotes,
            },
            content,
          };
        }}
      />
    );

  return (
    <Container className="w-100 h-100" fluid>
      <Row>
        <Col xs={6}>
          <ActionProgressControl
            title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_TRANSFER_DATASET__PROGRESS_CAPTION')}
            steps={stageSteps}
          />
        </Col>
        <Col xs={6}>
          {control}
        </Col>
      </Row>
    </Container>
  );
};

export default TPFilePageTransferDataset;
