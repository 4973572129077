/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-param-reassign, no-return-assign */

import { DeviceInformation, DeviceModelStatus, Services } from '@gpt/commons';
import { FILE_EXTENTION__CXEDP } from './constants';

/**
 * Invokes a passed service function and retries the call
 * in case of errors of type "ServiceNotFoundError".
 */
// eslint-disable-next-line no-unused-vars,  @typescript-eslint/no-explicit-any
export async function retry<T>(func: (...a: any[]) => Promise<T>, ...args: any[]): Promise<T> {
  const errorTypeName = 'ServiceNotFoundError';
  let result: T | undefined;
  let error: Error | undefined;

  do {
    try {
      error = undefined;
      // eslint-disable-next-line no-await-in-loop
      result = await func(...args);
    } catch (err) {
      error = err as Error;
      if (error?.name === errorTypeName) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => { setTimeout(resolve, 200); });
      } else {
        throw error;
      }
    }
  }
  while (error);

  return result as T;
}

/**
 * Converts passed data URL string, containing base64 encoded data to a Blob instance.
 *
 * @param dataURL data URL with base64 data, i.e. "data:image/png;base64,iVBORw0KGgoAAAA…"
 * @returns Blob containing the converted data
 */
export const dataURLtoBlob = (dataURL: string): Blob => {
  // i.e."data:image/png;base64,iVBORw0KGgoAAAA…"
  const base64marker = ';base64,';
  let base64index = dataURL.indexOf(base64marker);
  if (base64index < 0) throw new Error('dataURLtoBlob failed');
  base64index += base64marker.length;
  const mimeType = dataURL.split(';', 2)[0].split(':')[1];
  const base64 = dataURL.substring(base64index);

  const rawStr = window.atob(base64);
  const rawLength = rawStr.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i += 1) {
    array[i] = rawStr.charCodeAt(i);
  }

  return new Blob([array.buffer], { type: mimeType });
};

/**
 * Checks if a given value is in the passed range (min, max) limits.
 */
export const isInRange = (value: number, range: DeviceModelStatus.StatusDescriptorValueRange): boolean => (true
  && (value >= (range.minValue ?? -Infinity))
  && (value <= (range.maxValue ?? +Infinity))
);

/**
 * Checks if a given value is in at least one of the passed range (min, max) intervals.
 */
export const isInRangeInterval = (value: number, ranges?: DeviceModelStatus.StatusDescriptorRange): boolean => (
  !ranges || Array.from(ranges.regions).some((interval) => isInRange(value, interval))
);

/**
 * Calculates the lowest minimum and highes maximum value of
 * the passed list of (potentially open) range intervals
 *
 * @param range list of range[] intervals
 * @returns a range containing the total min and max value
 */
export const getRangeBounds = (
  range?: DeviceModelStatus.StatusDescriptorRange,
): DeviceModelStatus.StatusDescriptorValueRange => {
  const minTotal = !range ? +Infinity : Array.from(range.regions).reduce<number>((acc, cur) => Math.min(acc, cur.minValue ?? acc), +Infinity);
  const maxTotal = !range ? -Infinity : Array.from(range.regions).reduce<number>((acc, cur) => Math.max(acc, cur.maxValue ?? acc), -Infinity);
  return {
    minValue: minTotal === +Infinity ? undefined : minTotal,
    maxValue: maxTotal === -Infinity ? undefined : maxTotal,
  };
};

/**
 * Creates a string which contains the current date and time in a compact format.
 *
 * @returns current date and time in the following format: "20210512-082159"
 */
export const currentDateTimeShort = (withSeconds?: boolean): string => {
  const now = new Date();
  // normalize/correct ISO date value from UTC to local time
  const offsetMsec = now.getTimezoneOffset() * 60 * 1000;
  const nowLocal = new Date(now.getTime() - offsetMsec);

  // format date-time string for short dataset identifier
  let result = nowLocal.toISOString().substring(0, 19); // e.g. "2021-05-12T08:21:59"
  result = result.replace(/[-:]/g, '').replace(/T/, '-'); // e.g. "20210512-082159"
  if (!withSeconds) {
    result = result.substring(0, 13); // e.g. "20210512-0821" (stripped seconds)
  }
  return result;
};

/**
 * Creates internal unique dataset identification strings.
 */
export const createDatasetIdent = (suffix: string):
Services.DeviceModelServer.DatasetProviderService.DatasetIdent => {
  // format date+time for short string instead of default "2021-05-12T08:21:59"
  const dateTime = currentDateTimeShort(true); // // e.g. "20210512-082159"
  const result = `dataset_${dateTime}_${suffix}`; // e.g. "dataset_20210512-082159_backup"
  return result;
};

const replaceInvalidCharacters = (value: string)
  :string => value.replace(/[\\/:"*?<>|]+/g, '_');

const createCustomFilename = (prefix: string, activeDevice: DeviceInformation | undefined): string => {
  if (!activeDevice) return 'no-device';
  const { deviceDriverId } = activeDevice.catalog;
  let deviceTag = activeDevice.instance?.deviceTag;
  deviceTag = (deviceTag ?? 'undefined').replace(/\s/g, '_');
  const dateTime = currentDateTimeShort();

  const filename = replaceInvalidCharacters(`${dateTime}_${prefix}_${deviceDriverId}_${deviceTag}`);
  return filename;
};

/**
 * Builds the file name for device parameterization dataset files.
 *
 * @returns device parameterization file name with following format:
 *     "[datetime]_parameterization_[deviceident]_[tagname].cxedp"
 */
export const createParameterizationFilename = (
  activeDevice: DeviceInformation | undefined,
): string => {
  const prefix = 'Parameterization'.toLocaleLowerCase();
  const fileName = createCustomFilename(prefix, activeDevice);
  return `${fileName}${FILE_EXTENTION__CXEDP}`;
};

/**
 * Builds the file name for device parameterization backup files.
 *
 * @returns device parameterization file name with following format:
 *     "[datetime]_backup_[deviceident]_[tagname].cxedp"
 */
export const createBackupFilename = (
  activeDevice: DeviceInformation | undefined,
): string => {
  const prefix = 'Backup'.toLocaleLowerCase();
  const fileName = createCustomFilename(prefix, activeDevice);
  return `${fileName}${FILE_EXTENTION__CXEDP}`;
};

/**
 * Builds the file name for device protocol (docx / pdf) documents.
 *
 * @returns report file name with following format:
 *     "[datetime]_[reportkind]_[deviceident]_[tagname]"
 */
export const createReportFilename = (
  reportKind:
    | 'CompareParametersReport'
    | 'EditDeviceParametersReport'
    | 'InitialDeviceStartupReport'
    | 'DeviceRestorationReport'
    | 'DeviceReplacementReport'
    | 'FileTransferReport'
    | 'DeviceSettingsReport'
    | 'ContactronStation',
  activeDevice: DeviceInformation | undefined,
): string => (
  createCustomFilename(reportKind, activeDevice as DeviceInformation)
);

export const GetDisplayFormat = (statusDescriptor: DeviceModelStatus.StatusDescriptor): string => {
  let disFormat = '';
  switch (statusDescriptor.valueType.type) {
    case DeviceModelStatus.StatusDescriptorValueType.INTEGER:
      disFormat = statusDescriptor.valueType.displayFormat ?? '%d';
      break;
    case DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER:
      disFormat = statusDescriptor.valueType.displayFormat ?? '%d';
      break;
    case DeviceModelStatus.StatusDescriptorValueType.FLOAT:
      disFormat = statusDescriptor.valueType.displayFormat ?? '%.2f';
      break;
    case DeviceModelStatus.StatusDescriptorValueType.DATETIME:
      disFormat = statusDescriptor.valueType.displayFormat ?? '%s';
      break;
    default:
      disFormat = '';
  }
  return disFormat;
};

/**
* Resizes those page components which belong to / represent the application window
* to keep their displayed size on screen constant when the app contents are zoomed.
*/
export const compensateZooming = (zoomFactor: number): void => {
  const appRoot = document.querySelector('.AppRoot') as HTMLDivElement;
  const titlebar = document.querySelector('.AppTitlebar') as HTMLDivElement;
  const titlebarTop = document.querySelector('.AppTitlebarTop') as HTMLDivElement;
  const titlebarLogo = document.querySelector('.AppTitlebarLogo') as HTMLDivElement;
  const titlebarBtns = document.querySelector('.AppTitlebarButtons') as HTMLDivElement;
  const zoomDisplay = document.querySelector('.AppZoomDisplay') as HTMLDivElement;
  const appContent = document.querySelectorAll('.AppContent') as unknown as HTMLDivElement[];

  // titlebar not defined in unit test
  if (titlebar === null) {
    return;
  }

  titlebar.style.height = `${35 / zoomFactor}px`;
  titlebar.style.borderBottomWidth = `${2.1 / zoomFactor}px`;
  titlebarLogo.style.transform = `scale(${1.0 / zoomFactor})`;
  titlebarBtns.style.transform = `scale(${1.0 / zoomFactor})`;
  zoomDisplay.style.transform = `scale(${1.0 / zoomFactor})`;
  if (appRoot.classList.contains('AppRootBorder')) {
    // vertical space for AppContent is reduced by 39.3px (=35px+4.2px+ε)
    // → 100% view heigth - Titlebar{heigth} - 2 * Root{border} - εpsilon)
    appContent.forEach((elem) => (
      elem.style.height = `calc(100vh - ${39.3 / zoomFactor}px)`));
    titlebarTop.style.height = `${3.1 / zoomFactor}px`;
    appRoot.style.borderWidth = `${2.1 / zoomFactor}px`;
  } else if (titlebar.classList.contains('hidden')) {
    // vertical space for AppContent is not reduced by Titlebar & Border
    appContent.forEach((elem) => elem.style.height = '100vh');
    titlebarTop.style.height = `${5.1 / zoomFactor}px`;
    appRoot.style.borderWidth = '';
  } else {
    // without border, vertical space for AppContent is reduced by 35.1px
    appContent.forEach((elem) => (
      elem.style.height = `calc(100vh - ${35.1 / zoomFactor}px)`));
    titlebarTop.style.height = `${5.1 / zoomFactor}px`;
    appRoot.style.borderWidth = '';
  }
  // note: Titlebar{border} (2px) is part of (not additional to) Titlebar (35px).
  // note: Additional εpsilon 0.1px are used to compensate rounding errors which
  //       can be caused by floating point precission at certain zooming levels.
};

export const toHex2 = (number: number) : string => `00${number.toString(16)}`.slice(-2);
export const toHex4 = (number: number) : string => `0000${number.toString(16)}`.slice(-4);

export const pad2 = (num: number) : string => `00${num}`.slice(-2);
