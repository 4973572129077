/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useState } from 'react';
// import IconEye from '../../assets/icons/icon-eye.svg';
// import IconEyeSlash from '../../assets/icons/icon-eye-slash.svg';
import useDelayedUserInput from '../hooks/useDelayedUserInput';
import './PasswordControl.css';

export interface PasswordControlProps {
    label?: string;
    value: string;
    // eslint-disable-next-line react/no-unused-prop-types
    warning?: string;
    readonly?: boolean;
    modified?: boolean;
    // eslint-disable-next-line no-unused-vars
    onValueChange: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

const PasswordControl:
React.FC<PasswordControlProps> = (props: PasswordControlProps):
React.ReactElement => {
  const {
    label, value,
    warning, readonly, modified,
    onValueChange, onFocus, onBlur,
  } = props;

  const classNameEditedText = (modified ?? false) ? 'textfield__input--edited' : '';
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [
    userInputValue,
    currentWarning,
    setUserInputValue,
    onBlurValue,
  ] = useDelayedUserInput(value, warning, onValueChange);
  const currentValue = userInputValue ?? value;

  return (
    <div className="password-control">
      <div className="password-input-field">
        <input
          type={passwordVisible ? 'text' : 'password'}
          title={label}
          value={currentValue}
          className={`textfield__input ${classNameEditedText} ${currentWarning !== undefined ? 'invalid' : ''}`}
          readOnly={readonly}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setUserInputValue(e.target.value);
          }}
          onFocus={() => {
            onFocus?.();
          }}
          onBlur={() => {
            onBlurValue(currentValue);
            onBlur?.();
          }}
          data-testid="password-control"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        {/* <span className="helper-text" data-error={currentWarning} /> */}
        <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
          <div className="input-field-control__warning-text">{warning}</div>
        </div>
      </div>
      {/* <div
        role="button"
        className="password-control-icon"
        onClick={() => { setPasswordVisible(!passwordVisible); }}
        onKeyDown={() => 0}
        tabIndex={0}
      >
        <img src={passwordVisible ? IconEye : IconEyeSlash} alt="" />
      </div> */}
    </div>
  );
};

export default PasswordControl;
