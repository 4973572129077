/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */

import { DeviceModelStatus, IdentRef, Services } from '@gpt/commons';
import { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import { ButtonFunction } from '../../../controls/Compare/CompareTypes';
import { linTableStatusMembers } from '../../../controls/Compare/Controls/CompareLinTable/hooks/common';
import {
  COMPARE_VIEW__REDO_VALUES, COMPARE_VIEW__WRITE_VALUES, typeCompareViewActionTypes,
} from '../types';
import { selectTableRecords } from '../../../controls/Compare/selectors/selectTableRecords';
import { DatasetState, DatasetType } from '../../deviceInstances/store/deviceDataset/types';
import { deviceInstancesStoreSelector } from '../../reduxStoreSelector';
import { writeActiveDeviceVariableValues } from '../../deviceInstances/middleware/activeDevice/actions';

const createTableStatusValueRef = (
  target: DatasetState,
  source: DatasetState,
  buttonFunction: ButtonFunction,
  tableIdentRef: IdentRef,
): Services.DeviceModel.StatusValueRef[] => {
  const records = selectTableRecords(tableIdentRef, target);

  const values = records.reduce((acc, record) => {
    const recValues: Services.DeviceModel.StatusValueRef[] = record.values.reduce((macc, member) => {
      if (member.identRef === undefined) {
        return macc;
      }

      switch (buttonFunction) {
        case ButtonFunction.WriteValue:
          return [
            ...macc,
            {
              identRef: member.identRef,
              value: source.values[member.identRef].value,
              backupValue: source.values[member.identRef].backupValue ?? target.values[member.identRef].backupValue,
            }];
        case ButtonFunction.RedoValue:
          return [
            ...macc,
            {
              identRef: member.identRef,
              value: target.values[member.identRef].backupValue ?? target.values[member.identRef].value,
              backupValue: undefined,
            }];
        default:
      }
      return macc;
    }, [] as Services.DeviceModel.StatusValueRef[]);

    return [
      ...acc,
      ...recValues,
    ];
  }, [] as Services.DeviceModel.StatusValueRef[]);
  return values;
};

const createStatusValueRef = (
  target: DatasetState,
  source: DatasetState,
  buttonFunction: ButtonFunction,
  identRef: IdentRef,
): Services.DeviceModel.StatusValueRef[] => {
  const member = target.values[identRef];
  if (member === undefined) {
    return [];
  }

  switch (buttonFunction) {
    case ButtonFunction.WriteValue:
      return [{
        identRef: member.identRef,
        value: source.values[member.identRef].value,
        backupValue: source.values[member.identRef].backupValue ?? target.values[member.identRef].backupValue,
      }];
    case ButtonFunction.RedoValue:
      return [{
        identRef: member.identRef,
        value: target.values[member.identRef].backupValue ?? target.values[member.identRef].value,
        backupValue: undefined,
      }];
    default:
  }
  return [];
};

export const getLinTableControlType = (dataset: DatasetState, identRef: IdentRef)
: DeviceModelStatus.UI.LinearizationControlType | undefined => {
  const descriptor = dataset.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLLINEARIZATION) {
    return undefined;
  }
  return descriptor.controlType;
};

export const getRangeWithSpanControlType = (dataset: DatasetState, identRef: IdentRef)
: DeviceModelStatus.UI.RangeWithSpanControlType | undefined => {
  const descriptor = dataset.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN) {
    return undefined;
  }
  return descriptor.controlType;
};

const createStatusDescriptorValueRefs = (descriptor: DeviceModelStatus.StatusDescriptor, buttonFunction: ButtonFunction, source: DatasetState, target: DatasetState): Services.DeviceModel.StatusValueRef[] => {
  if (descriptor.valueType.type === DeviceModelStatus.StatusDescriptorValueType.TABLE) {
    return createTableStatusValueRef(target, source, buttonFunction, descriptor.identRef);
  }
  if (descriptor.valueType.type === DeviceModelStatus.StatusDescriptorValueType.TABLERECORD) {
    return [];
  }
  return createStatusValueRef(target, source, buttonFunction, descriptor.identRef);
};

const createLinTableStatusValueRef = (
  identRef: IdentRef,
  target: DatasetState,
  source: DatasetState,
  buttonFunction: ButtonFunction,
): Services.DeviceModel.StatusValueRef[] => {
  const controlType = getLinTableControlType(target, identRef);
  if (controlType === undefined) {
    return [];
  }

  const values = linTableStatusMembers.reduce((acc, member) => {
    const memIdent = controlType[member];
    if (memIdent === undefined) {
      return acc;
    }
    const memdesc = target.descriptors[memIdent];
    if (memdesc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return acc;
    }
    const valueRefs = createStatusDescriptorValueRefs(memdesc, buttonFunction, source, target);
    return [
      ...acc,
      ...valueRefs,
    ];
  }, [] as Services.DeviceModel.StatusValueRef[]);
  return values;
};

const createRangeWithSpanStatusValueRef = (
  identRef: IdentRef,
  target: DatasetState,
  source: DatasetState,
  buttonFunction: ButtonFunction,
): Services.DeviceModel.StatusValueRef[] => {
  const controlType = getRangeWithSpanControlType(target, identRef);
  if (controlType === undefined) {
    return [];
  }

  const values = ['rangeEnd', 'rangeStart', 'spanVar'].reduce((acc, member) => {
    const memIdent = controlType[member];
    if (memIdent === undefined) {
      return acc;
    }
    const memdesc = target.descriptors[memIdent];
    if (memdesc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return acc;
    }
    const valueRefs = createStatusDescriptorValueRefs(memdesc, buttonFunction, source, target);
    return [
      ...acc,
      ...valueRefs,
    ];
  }, [] as Services.DeviceModel.StatusValueRef[]);
  return values;
};

const createControlDescriptorValueRefs = (descriptor: DeviceModelStatus.ControlDescriptor, buttonFunction: ButtonFunction, source: DatasetState, target: DatasetState): Services.DeviceModel.StatusValueRef[] => {
  if (descriptor.controlType.type === DeviceModelStatus.UI.ControlType.CTLLINEARIZATION) {
    return createLinTableStatusValueRef(descriptor.identRef, target, source, buttonFunction);
  }
  if (descriptor.controlType.type === DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN) {
    return createRangeWithSpanStatusValueRef(descriptor.identRef, target, source, buttonFunction);
  }
  return [];
};

const createStatusValueRefs = (identRef: IdentRef, buttonFunction: ButtonFunction, source: DatasetState, target: DatasetState): Services.DeviceModel.StatusValueRef[] => {
  const descriptor = source.descriptors[identRef];
  if (descriptor.type === DeviceModelStatus.StatusType.StatusDescriptor) {
    return createStatusDescriptorValueRefs(descriptor, buttonFunction, source, target);
  }
  if (descriptor.type === DeviceModelStatus.StatusType.ControlDescriptor) {
    return createControlDescriptorValueRefs(descriptor, buttonFunction, source, target);
  }
  return [];
};

export const compareViewMiddleware = (): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeCompareViewActionTypes>(action: A): Promise<A> => {
  const actionUpdateCompareValues = (
    targetInstance: string,
    buttonFunction: ButtonFunction,
    sourceDataset: DatasetType,
    targetDataset: DatasetType,
    items: IdentRef[],
  ): Services.DeviceModel.StatusValueRef[] => {
    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const source = deviceInstances.instances[targetInstance].deviceDataset[sourceDataset] as DatasetState;
    const target = deviceInstances.instances[targetInstance].deviceDataset[targetDataset] as DatasetState;
    return items.reduce((acc, identRef) => {
      const values = createStatusValueRefs(identRef, buttonFunction, source, target);
      return [
        ...acc,
        ...values,
      ];
    }, [] as Services.DeviceModel.StatusValueRef[]);
  };

  switch (action.type) {
    case COMPARE_VIEW__WRITE_VALUES: {
      const { data, targetInstance } = action.payload;
      const { sourceDataset, targetDataset, items } = data;
      const values = actionUpdateCompareValues(targetInstance, ButtonFunction.WriteValue, sourceDataset, targetDataset, items);
      api.dispatch(writeActiveDeviceVariableValues(targetInstance, values));
      break;
    }
    case COMPARE_VIEW__REDO_VALUES: {
      const { data, targetInstance } = action.payload;
      const { sourceDataset, targetDataset, items } = data;
      const values = actionUpdateCompareValues(targetInstance, ButtonFunction.RedoValue, sourceDataset, targetDataset, items);
      api.dispatch(writeActiveDeviceVariableValues(targetInstance, values));
      break;
    }
    default:
  }

  const result = next(action);
  return result;
};
