/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useEffect } from 'react';
import { ReduxControlProps } from '../ReduxControlProps';

const InvisibleReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    onStateChanged,
  } = props;

  useEffect(() => {
    onStateChanged(identRef, {
      error: false,
      modified: false,
    });
  }, []);
  return <div />;
};

export default InvisibleReduxControl;
