/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  IoLinkEventMonitorState,
  IoLinkEvent,
  typeIoLinkEventMonitorActionTypes,
} from './types';

export const initialIoLinkEventMonitorState: IoLinkEventMonitorState = {
  eventList: [],
};

const updateIoLinkEventMonitor = (state: IoLinkEventMonitorState, payload: string)
:IoLinkEventMonitorState => {
  let ioLinkEventValue: IoLinkEvent[] = [];
  try {
    ioLinkEventValue = JSON.parse(payload) as IoLinkEvent[];
  } catch (err: any) {
    ioLinkEventValue = [];
  }

  const actualEventList: IoLinkEvent[] = ioLinkEventValue.reduce((acc, ev) => {
    const accv = acc.find((item) => item.Id === ev.Id);
    if (accv === undefined) {
      return [
        ev,
        ...acc,
      ];
    }
    return acc;
  }, state.eventList as IoLinkEvent[]);

  return {
    ...state,
    eventList: actualEventList,
  };
};

const ioLinkEventMonitorReducer = (
  state = initialIoLinkEventMonitorState,
  action: typeIoLinkEventMonitorActionTypes,
): IoLinkEventMonitorState => {
  let newstate = state;
  switch (action.type) {
    case 'IOLINK__EVENT_MONITOR__UPDATE_EVENT_LIST':
      newstate = updateIoLinkEventMonitor(state, action.payload);
      break;
    case 'IOLINK__EVENT_MONITOR__ADD_EVENT_LIST':
      newstate = {
        ...state,
        eventList: [
          ...state.eventList,
          action.payload,
        ],
      };
      break;
    case 'IOLINK__EVENT_MONITOR__CLEAN_EVENT_LIST':
      newstate = {
        ...state,
        eventList: [],
      };
      break;
    default:
      newstate = state;
  }
  return newstate;
};

export default ioLinkEventMonitorReducer;
