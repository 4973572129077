/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import {
  DeviceStatusEventMonitorEvent,
  DeviceStatusEventMonitorEventMode,
  DeviceStatusEventMonitorState,
  DeviceStatusEventStateMap,
  typeDeviceStatusEventMonitorActionTypes,
} from './types';

export const initialDeviceStateEventMonitorState: DeviceStatusEventMonitorState = {
  eventList: [],
  eventState: {},
};

const eventKey = (code: number): string => `ev${code}`;

const updateEventStateReducer = (
  eventState: DeviceStatusEventStateMap,
  appearedEvents: DeviceModelStatus.UI.DeviceStatusMonitorUpdateEvent[],
  disappearedEvents: DeviceModelStatus.UI.DeviceStatusMonitorUpdateEvent[],
): DeviceStatusEventStateMap => {
  const updateEventState = appearedEvents.reduce((acc, ev) => {
    const evkey = eventKey(ev.eventCode);
    return acc[evkey] === undefined
      ? {
        ...acc,
        [evkey]: {
          code: ev.eventCode,
          mode: DeviceStatusEventMonitorEventMode.Appears,
          timestamp: ev.timestamp,
        },
      }
      : {
        ...acc,
        [evkey]: {
          ...acc[evkey],
          mode: DeviceStatusEventMonitorEventMode.Appears,
          timestamp: ev.timestamp,
        },
      };
  }, eventState);

  return disappearedEvents.reduce((acc, ev) => {
    const evkey = eventKey(ev.eventCode);
    return acc[evkey] === undefined
      ? acc
      : {
        ...acc,
        [evkey]: {
          ...acc[evkey],
          mode: DeviceStatusEventMonitorEventMode.Disappears,
          timestamp: ev.timestamp,
        },
      };
  }, updateEventState);
};

const updateEventListReducer = (
  eventState: DeviceStatusEventStateMap,
  eventList: DeviceStatusEventMonitorEvent[],
  appearedEvents: DeviceModelStatus.UI.DeviceStatusMonitorUpdateEvent[],
  disappearedEvents: DeviceModelStatus.UI.DeviceStatusMonitorUpdateEvent[],
): DeviceStatusEventMonitorEvent[] => {
  const appearedEventList = appearedEvents
    .reduce((acc, ev) => {
      const evkey = eventKey(ev.eventCode);
      if (eventState[evkey] === undefined) {
        return [
          ...acc,
          {
            id: `event--${ev.eventCode}--${ev.timestamp}--appears`,
            code: ev.eventCode,
            mode: DeviceStatusEventMonitorEventMode.Appears,
            timestamp: ev.timestamp,
          },
        ];
      }
      if (eventState[evkey].mode === DeviceStatusEventMonitorEventMode.Disappears) {
        return [
          ...acc,
          {
            id: `event--${ev.eventCode}--${ev.timestamp}--appears`,
            code: ev.eventCode,
            mode: DeviceStatusEventMonitorEventMode.Appears,
            timestamp: ev.timestamp,
          },
        ];
      }
      return acc;
    }, [] as DeviceStatusEventMonitorEvent[]);

  const disappearedEventList = disappearedEvents.reduce((acc, ev) => {
    const evkey = eventKey(ev.eventCode);
    if (eventState[evkey] === undefined) {
      return acc;
    }
    if (eventState[evkey].mode === DeviceStatusEventMonitorEventMode.Appears) {
      return [
        ...acc,
        {
          id: `event--${ev.eventCode}--${ev.timestamp}--disappears`,
          code: ev.eventCode,
          mode: DeviceStatusEventMonitorEventMode.Disappears,
          timestamp: ev.timestamp,
        },
      ];
    }
    return acc;
  }, [] as DeviceStatusEventMonitorEvent[]);

  const xEventList = [
    ...appearedEventList,
    ...disappearedEventList,
  ].sort((x1, x2) => {
    if (x1.timestamp === x2.timestamp) {
      return 0;
    }
    return x1.timestamp > x2.timestamp ? 1 : -1;
  });
  return [
    ...eventList,
    ...xEventList,
  ];
};

const updateDeviceStatusEventMonitor = (
  state: DeviceStatusEventMonitorState,
  payload: DeviceModelStatus.UI.DeviceStatusMonitorUpdateEvents,
):DeviceStatusEventMonitorState => {
  // Update existing events
  const disappearedEvents = Object
    .keys(state.eventState)
    .reduce((acc, evkey) => {
      if (payload[evkey] === undefined && state.eventState[evkey].mode === DeviceStatusEventMonitorEventMode.Appears) {
        return [
          ...acc,
          {
            eventCode: state.eventState[evkey].code,
            timestamp: new Date().toISOString(),
          },
        ];
      }
      return acc;
    }, [] as DeviceModelStatus.UI.DeviceStatusMonitorUpdateEvent[]);

  const appearedEvents = Object
    .keys(payload)
    .reduce((acc, evkey) => {
      if (state.eventState[evkey] === undefined || state.eventState[evkey].mode === DeviceStatusEventMonitorEventMode.Disappears) {
        return [
          ...acc,
          payload[evkey],
        ];
      }
      return acc;
    }, [] as DeviceModelStatus.UI.DeviceStatusMonitorUpdateEvent[]);

  // eslint-disable-next-line max-len
  const eventState = updateEventStateReducer(state.eventState, appearedEvents, disappearedEvents);
  const eventList = updateEventListReducer(state.eventState, state.eventList, appearedEvents, disappearedEvents);

  return {
    ...state,
    eventState,
    eventList,
  };
};

export const deviceStatusEventMonitorReducer = (
  state = initialDeviceStateEventMonitorState,
  action: typeDeviceStatusEventMonitorActionTypes,
): DeviceStatusEventMonitorState => {
  let newstate = state;
  switch (action.type) {
    case 'DEVICE_STATUS_EVENT_MONITOR__UPDATE_EVENT_LIST':
      newstate = updateDeviceStatusEventMonitor(state, action.payload);
      break;
    default:
      newstate = state;
  }
  return newstate;
};
