/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
// eslint-disable-next-line import/no-unresolved
import i18n from '../i18n-LoadingSplashScreen.json';
import { CTAButton } from '../../../components/CTAButton/CTAButton';
import useAppDownloadLink from '../../hook/useAppDownloadLink';
import { ENV_UPDATE_CHANNEL } from '../../../enviroment';
import './DownloadLinkButton.css';
import { LocalBackendVersionStatus } from '../../hook/useBackendVersion';

const i18nTranslator = (lang: string, key: string): string => i18n[lang]?.[key] || String(key);

export interface DownloadLinkButtonProps {
  status: LocalBackendVersionStatus;
}

// eslint-disable-next-line import/prefer-default-export
const DownloadLinkButton: React.FC<DownloadLinkButtonProps> = (props: DownloadLinkButtonProps): React.ReactElement => {
  const updateChannel = ENV_UPDATE_CHANNEL;
  const { status } = props;
  const availableSetup = useAppDownloadLink(updateChannel);

  const language = window.navigator.language.substring(0, 2);
  const lang = (language === 'en' || language === 'de') ? language : 'en';

  const header = status === LocalBackendVersionStatus.NotAvailable
    ? i18nTranslator(lang, 'hdr_download_setup')
    : i18nTranslator(lang, 'hdr_local_service_not_compatible');

  const downloadLinkButton = (availableSetup === undefined)
    ? <div className="loading-splash-screen__text p-2">Setup link not available</div>
    : (
      <>
        <div className="loading-splash-screen__download_setup__text">
          {i18nTranslator(lang, 'txt_download_link')}
        </div>
        <div className="p-2">
          <a className="p-2" href={availableSetup}>
            <CTAButton
              caption={i18nTranslator(lang, 'hdr_download_button')}
              variant="secondary"
              onClick={() => 0}
            />
          </a>
        </div>
        <div className="loading-splash-screen__download_setup__text">
          {i18nTranslator(lang, 'txt_installation_rights')}
        </div>
      </>
    );

  return (
    <>
      <div className="loading-splash-screen__download_setup__header" data-testid="download-link-button">
        {header}
      </div>
      {downloadLinkButton}
    </>
  );
};

export default DownloadLinkButton;
