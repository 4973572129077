/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import './Tooltip.css';

interface TooltipItem {
  label: string,
  value: string,
  unit: string,
}

export interface TooltipProps {
  items: TooltipItem[]
}

export const Tooltip: React.FC<TooltipProps> = (props: TooltipProps): React.ReactElement => {
  const { items } = props;
  const { t } = useTranslation();

  const tooltipItems = items.map((item, index) => {
    const key = `LabelItem - ${index}`;
    const label = `${t<string>(item.label)}: `;
    const value = `${item.value} ${t<string>(item.unit)}`;
    return (
      <div key={key} className="Tooltip-control-item">
        <b>
          {label}
        </b>
        {value}
      </div>
    );
  });

  return (
    <div className="Tooltip-control" data-testid="tooltip">
      {tooltipItems}
    </div>
  );
};
