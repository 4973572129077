/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { ParameterControl } from '../../../../../../components/ParameterEditor';
import { IPV4AddressControl } from '../../../../../../components/ParameterEditor/IPV4AddressControl/IPV4AddressControl';
import TextEditorControl from '../../../../../../components/ParameterEditor/TextEditorControl/TextEditorControl';
import { CompareValueProps } from '../../../../CompareTypes';
import CompareContainer from '../../../CompareContainer/CompareContainer';

export interface CompareIPV4AddressValueProps extends CompareValueProps {
  label: string;
  valueLeft: string;
  valueRight: string;
  // eslint-disable-next-line no-unused-vars
  onLeftValueChange?: (value: string) => void;
}

const CompareIPV4AddressValue
  : React.FC<CompareIPV4AddressValueProps> = (props: CompareIPV4AddressValueProps)
: React.ReactElement => {
    const {
      onButtonClick, valueLeft, valueRight, readonly, modified, onLeftValueChange,
      onShowContentHelp, onHideContentHelp, buttonFunction, label, warning,
      equal,
    } = props;
    return (
      <CompareContainer
        label={label}
        buttonFunction={buttonFunction}
        equal={equal}
        onButtonClick={onButtonClick}
      >
        <ParameterControl>
          <IPV4AddressControl
            onValueChange={(e) => onLeftValueChange?.(e)}
            modified={modified}
            value={valueLeft}
            onShowContextHelp={onShowContentHelp}
            onHideContextHelp={onHideContentHelp}
            readonly={readonly}
            warning={warning}
          />
        </ParameterControl>
        <TextEditorControl
          displayFormat=""
          value={valueRight}
          onValueChange={() => 0}
          readonly
        />
      </CompareContainer>
    );
  };

export default CompareIPV4AddressValue;
