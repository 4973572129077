/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */

import React from 'react';
import { INatsClientConnection } from './nats/connection';
import { NatsServerConnectionState } from './nats/webworker.types';

export interface NatsConnectionContextProps {
  connection: INatsClientConnection | undefined;
  state: NatsServerConnectionState;
  connect: () => void,
}

export const NatsConnectionContext = React.createContext<NatsConnectionContextProps>({
  connection: undefined,
  state: NatsServerConnectionState.Disconnected,
  connect: () => 0,
});
