/* eslint-disable max-len */
import React from 'react';
import {
  gaugeCenterX, gaugeCenterY,
  gaugeRadius, mapValueToAngle,
  gaugeMinAngle, gaugeMaxAngle,
  gaugeStrokeWidth,
  polarToCartesian,
} from '../common';
import './SVGGaugeSegments.scss';

export interface SVGGaugeSegment {
  minValue: number;
  maxValue: number;
  color: 'red' | 'green' | 'yellow';
}

export interface SVGGaugeSegmentProps {
    gaugeSegments: SVGGaugeSegment[],
    gaugeMinValue: number,
    gaugeMaxValue: number
}

const createArcPath = (x: number, y: number, radius: number, startAngle: number, endAngle: number): string => {
  const start = polarToCartesian(x, y, radius, startAngle);
  const end = polarToCartesian(x, y, radius, endAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M', start.x, start.y,
    'A', radius, radius, 0, largeArcFlag, 1, end.x, end.y,
  ].join(' ');

  return d;
};

const SVGGaugeSegments: React.FC<SVGGaugeSegmentProps> = (props: SVGGaugeSegmentProps)
: React.ReactElement => {
  const { gaugeSegments, gaugeMaxValue, gaugeMinValue } = props;

  const gaugeSegmentArray = gaugeSegments
    .map((seg) => {
      const startAngle = mapValueToAngle(gaugeMinValue, gaugeMaxValue, gaugeMinAngle, gaugeMaxAngle, seg.minValue);
      const endAngle = mapValueToAngle(gaugeMinValue, gaugeMaxValue, gaugeMinAngle, gaugeMaxAngle, seg.maxValue);
      const path = createArcPath(
        gaugeCenterX,
        gaugeCenterY,
        gaugeRadius,
        startAngle,
        endAngle,
      );
      const className = `gauge-segment--${seg.color}`;
      return (
        <g className={className} key={`gauge-segment--${seg.minValue}-${seg.maxValue}`} fill="none" strokeWidth={gaugeStrokeWidth} strokeLinejoin="round" strokeLinecap="butt">
          <path d={path} />
        </g>
      );
    });
  const startSegment = gaugeSegmentArray.slice(0, 1);
  const endSegment = gaugeSegmentArray.slice(gaugeSegmentArray.length - 1);
  const midSegment = gaugeSegmentArray.slice(1, gaugeSegmentArray.length - 1);

  const path = createArcPath(
    gaugeCenterX,
    gaugeCenterY,
    gaugeRadius,
    gaugeMinAngle,
    gaugeMaxAngle,
  );
  const full = (
    <g className="gauge-segment--grey" fill="none" vectorEffect="non-scaling-stroke" strokeWidth={gaugeStrokeWidth + 4} strokeLinejoin="round" strokeLinecap="butt">
      <path d={path} />
    </g>
  );

  return (
    <g>
      {full}
      {startSegment}
      {endSegment}
      {midSegment}
    </g>
  );
};

export default SVGGaugeSegments;
