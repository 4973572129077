/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import { PreviewLabelWidth } from '../PreviewLayout';
import './PreviewParameterGroupControl.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PreviewParameterGroupProps extends PropsWithChildren<any> {
    label: string;
    alignEnd?: boolean;
}

const PreviewParameterGroupControl:
React.FC<PreviewParameterGroupProps> = (props: PreviewParameterGroupProps):
React.ReactElement => {
  const {
    label, children, alignEnd,
  } = props;

  const caligEnd = alignEnd === undefined || alignEnd === true ? 'page-preview-group__label--align-end' : 'page-preview-group__label--align-start';

  return (
    <div
      className="page-preview-group"
      data-testid="preview-parameter-group-control"
    >
      <p className={`page-preview-group__label col-${PreviewLabelWidth} p-0 m-0 ${caligEnd}`}>{label}</p>
      <div className="page-preview-group__children">
        {children}
      </div>
    </div>
  );
};

export default PreviewParameterGroupControl;
