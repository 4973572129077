/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { IdentRef } from '@gpt/commons';
import { StructureItemStateChangeCallback } from '../ReduxControlProps';

type ComponentProps = { identRef: string } & any;
type ComponentType = React.ComponentType<ComponentProps>;
type ComponentMap = { [key: string]: ComponentType };

const components: ComponentMap = {};

export const registerComponent = (control: string, component: ComponentType): void => {
  components[control] = component;
};

export interface ComponentFactoryProps {
  key?: string;
  control: string;
  identRef: IdentRef;
  deviceInstanceId: string,
  onStateChanged: StructureItemStateChangeCallback;
  readonly?: boolean;
}

export const ComponentFactory = (props: ComponentFactoryProps): React.ReactElement => {
  const {
    identRef, control, key, onStateChanged, readonly, deviceInstanceId,
  } = props;

  if (components[control] === undefined) {
    return <div key={key}>{`Unknown Control: ${control}`}</div>;
  }

  const C = components[control];
  return (
    <C
      key={key}
      identRef={identRef}
      onStateChanged={onStateChanged}
      readonly={readonly}
      deviceInstanceId={deviceInstanceId}
    />
  );
};
