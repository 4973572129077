/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store';
import {
  CompareModeType,
} from '../common';
import { compareViewSetCompareMethod } from '../../../store/compareView';
import CompareParameterViewLayout from '../CompareParameterViewLayout';
import '../CompareParameterViewLayout.scss';
import './CompareParameterViewHeader.scss';

enum SelectedDatasetType {
  ConnectedDevice = '__connectedDevice__',
  // LoadFromExtFile = '__loadFromExtFile__',
}

const CompareParameterViewHeader: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const [compareDatasetIdent, setCompareDatasetIdent] = useState<string>(SelectedDatasetType.ConnectedDevice);
  const compareMethod = useTypedSelector((state) => state.compareView.compareMethod);
  const dispatch = useDispatch();

  const options = [
    [SelectedDatasetType.ConnectedDevice, t<string>('COMPARE_PARAMETERS_SELECT_DATASET_CONNECTED_DEVICE')],
  ];
  const selectOptions = Object.values(options).map(([id, title]) => (
    <option key={id} value={id}>{`${title}`}</option>
  ));

  const compareModeMapper = {
    [CompareModeType.ShowDifference]: CompareModeType.ShowDifference,
    [CompareModeType.ShowAll]: CompareModeType.ShowAll,
    [CompareModeType.AllParamsEditable]: CompareModeType.AllParamsEditable,
  };

  const compareModeOptions = [
    {
      id: CompareModeType.ShowDifference,
      title: t<string>('COMPARE_PARAMETERS__SHOW_MODE__SHOW_DIFFERENCE'),
    },
    {
      id: CompareModeType.ShowAll,
      title: t<string>('COMPARE_PARAMETERS__SHOW_MODE__SHOW_ALL'),
    },
    {
      id: CompareModeType.AllParamsEditable,
      title: t<string>('COMPARE_PARAMETERS__SHOW_MODE__ALL_PARAMS_EDITABLE'),
    },
  ];
  const modeOptions = compareModeOptions.map((modex) => (
    <option key={modex.id} value={modex.id}>{`${modex.title}`}</option>
  ));

  return (
    <CompareParameterViewLayout>
      <div className="w-100">
        <div className="compareParameterViewHeader__title p-2 pl-4 border">
          {t<string>('COMPARE_PARAMETERS_TITLE_CURRENT_DATASET')}
        </div>
        <div className="pt-2 x-padding-12">
          <div className="select-field p-0">
            <select
              value={compareMethod}
              className="form__select"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => dispatch(compareViewSetCompareMethod(compareModeMapper[e.target.value]))}
            >
              {modeOptions}
            </select>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="form__select__label">{}</label>
            <span className="bar" />
            <span className="select-icon" />
            <span className="helper-text" />
          </div>
        </div>
      </div>
      <div />
      <div className="w-100">
        <div className="compareParameterViewHeader__title p-2 pl-4">
          {t<string>('COMPARE_PARAMETERS_TITLE_COMPARE_DATASET')}
        </div>
        <div className="pt-2 x-padding-12">
          <div className="select-field p-0">
            <select
              value={compareDatasetIdent}
              className="form__select"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCompareDatasetIdent(e.target.value)}
              disabled
            >
              { selectOptions }
            </select>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="form__select__label">{}</label>
            <span className="bar" />
            <span className="select-icon" />
            <span className="helper-text" />
          </div>
        </div>
      </div>
    </CompareParameterViewLayout>
  );
};

export default CompareParameterViewHeader;
