/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { IdentRef } from '@gpt/commons';
import { useCallback, useState } from 'react';
import { StructureItems, StructureItemState } from '../ReduxControlProps';

// eslint-disable-next-line no-unused-vars
export type StateUpdateCallback = (identRef: IdentRef, state: StructureItemState) => void;

// eslint-disable-next-line max-len
export const transformMenuItemState = (prev: StructureItems, ident: IdentRef, state: StructureItemState)
: StructureItems => (prev[ident] === undefined
  ? {
    ...prev,
    [ident]: state,
  } : {
    ...prev,
    [ident]: {
      ...prev[ident],
      modified: state.modified,
      error: state.error,
    },
  });

// eslint-disable-next-line max-len
export const useMenuItemStateUpdate = () => {
  const [childrenState, setChildrenState] = useState<StructureItems>({});

  // eslint-disable-next-line max-len
  const updateMenuItemState: StateUpdateCallback = useCallback((ident: IdentRef, state: StructureItemState) => {
    setChildrenState((prev) => transformMenuItemState(prev, ident, state));
  }, []);

  const mod = Object.keys(childrenState).find((key) => childrenState[key].modified) !== undefined;
  const iserror = Object.keys(childrenState).find((key) => childrenState[key].error) !== undefined;
  return [mod, iserror, updateMenuItemState] as const;
};
