/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  Report, ReportContentType, ManualDeviceSettings,
} from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reduxStore';
import { PageSaveProtocolComponent } from '../../../PageComponents/PageSaveProtocolComponent/PageSaveProtocolComponent';
import { LoadingControl } from '../../../components/LoadingControl/LoadingControl';
import { createReportFilename } from '../../../helpers/functions';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';

export interface Page4DocumentTransferProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const Page4DocumentTransfer
: React.FC<Page4DocumentTransferProps> = (props: Page4DocumentTransferProps) : React.ReactElement => {
  const { reportMenu, deviceInstanceId } = props;
  const { t } = useTranslation();
  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId)?.device);

  if (activeDevice === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const getContentHandler = async (userName: string, userNotes: string, serialNumber: string): Promise<Report> => {
    const content: ManualDeviceSettings = {
      type: ReportContentType.ManualDeviceSettings,
      device: {
        deviceDesignation: activeDevice?.catalog?.deviceTypeName ?? '',
        deviceTag: activeDevice?.instance?.deviceTag ?? '',
        firmwareRevision: activeDevice?.instance?.firmwareVersion ?? '',
        hardwareRevision: activeDevice?.instance?.hardwareVersion ?? '',
        serialNumber,
        itemNumber: activeDevice?.catalog?.productOrderNumber ?? '',
      },
      userName,
    };

    return {
      header: {
        dateTime: new Date(),
        systemName: t<string>('', '{{SYSTEM_NAME}}'),
        userName,
        userNotes,
      },
      content,
    };
  };

  return (
    <Container className="w-100 h-100" fluid>
      <Row>
        <Col xs={3} />
        <Col xs={6}>
          <PageSaveProtocolComponent
            reportMenu={reportMenu}
            deviceInstanceId={deviceInstanceId}
            caption={t<string>('WIZARD_SETTING_DEVICE__REPORT__TITLE')}
            nameCaption={t<string>('WIZARD_SETTING_DEVICE__REPORT__NAME')}
            descriptionCaption={t<string>('WIZARD_SETTING_DEVICE__REPORT__DESCRIPTION')}
            serialNumber={activeDevice.instance?.serialNumber}
            disableButtons={false}
            fileName={createReportFilename('DeviceSettingsReport', activeDevice)}
            onGetContent={getContentHandler}
          />
        </Col>
        <Col xs={3} />
      </Row>
    </Container>
  );
};

export default Page4DocumentTransfer;
