/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect, useState } from 'react';

const useScanIndicator = (protocolText: string, scanActive: boolean): string => {
  const maxScanLen = 3;
  const [scanIndicator, setScanIndicator] = useState(1);

  useEffect(() => {
    const timer = setInterval(() => {
      setScanIndicator((value) => (value === maxScanLen ? 1 : value + 1));
    }, 1000);
    // Specify how to clean up after this effect:
    const cleanUp = () => {
      clearInterval(timer);
    };
    return cleanUp;
  }, []);

  return scanActive ? `${protocolText} ${'.'.repeat(scanIndicator)}` : protocolText;
};

export default useScanIndicator;
