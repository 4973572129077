/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import splitterIcon from '../../assets/icons/splitter-arrow.svg';
import { Chart } from './Graphics/Chart/Chart';
import './LinearizationComponent.css';
import { LinearizationTableControl } from './LinearizationTableControl/LinearizationTableControl';

// eslint-disable-next-line import/prefer-default-export
export const LinearizationComponent: React.FC = (): React.ReactElement => {
  const [showFullTable, setShowFullTable] = useState(true);

  const splitterHandler = () => {
    setShowFullTable(!showFullTable);
  };

  const onClick = (pointIndex: number) => {
    document.getElementById(`input__x__${pointIndex}`)?.focus();
  };

  const tableContainerWidth = showFullTable ? 60 : 25;
  const tableContainerStyle = {
    flex: `0 1 ${tableContainerWidth}%`,
  };

  const arrowRotateDegre = showFullTable ? 180 : 0;
  const splitterStyle = {
    transform: `rotate(${arrowRotateDegre}deg)`,
  };

  const chartType = showFullTable ? 'small' : 'large';

  return (
    <div className="LinearizationComponent" data-testid="linearization-component">
      <div style={tableContainerStyle} className="LinearizationComponent-table d-flex flex-column justify-content-center align-items-center">
        <LinearizationTableControl
          fullView={showFullTable}
        />
      </div>
      <div
        className="LinearizationComponent-splitter d-flex flex-column justify-content-center align-items-center"
        role="button"
        onClick={splitterHandler}
        onKeyDown={() => 0}
        tabIndex={0}
      >
        <Image style={splitterStyle} src={splitterIcon} className="" />
      </div>
      <div className="LinearizationComponent-graphics d-flex flex-column justify-content-center align-items-center">
        <Chart
          onClick={onClick}
          type={chartType}
        />
      </div>
    </div>
  );
};
