/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WebDevice } from '@gpt/commons';
import { useTypedSelector } from '../../../../store';
import {
  incSimulatedDeviceCount,
  decSimulatedDeviceCount,
  saveDeviceSimulationConfig,
} from '../../../../store/deviceSimulation/actions';
import { ConfigDeviceCount } from '../../../../store/deviceSimulation/types';
import { distinct } from '../BackstagePageServices';
import './SimulationSettings.scss';

interface DeviceCountInfo {
  /** device title */
  title: string;
  /** number of simulated instances for device type */
  count: number;
  /** device type identification string */
  deviceTypeIdentifier: string;
  /** device type / product name */
  deviceTypeName: string;
  /** device type / product order code */
  orderCode: string;
}

const createDeviceCatalogTitle = (model: WebDevice.DeviceCatalogInformation)
  : string => model.deviceCatalogTitle ?? `${model.deviceTypeName} (${model.deviceCatalogIdent})`;

const BackstageSimulationSettings:React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const deviceCatalogList = useTypedSelector((state) => state.discoveryService.catalog.deviceList);
  const deviceCatalog = Object
    .keys(deviceCatalogList)
    .reduce((acc, key) => (deviceCatalogList[key].properties.simulation === WebDevice.SimulationType.enabled
      ? [
        ...acc,
        deviceCatalogList[key],
      ]
      : acc), [] as WebDevice.DeviceCatalogInformation[]);
  const protocols = distinct(deviceCatalog, (dt) => dt.protocol).map((dt) => dt.protocol);
  const simulatedDeviceList = useTypedSelector(
    (state) => state.deviceSimulation.simulatedDevices,
  );

  const dispatch = useDispatch();
  const collator = new Intl.Collator();

  useEffect(() => {
    const simDeviceCount: ConfigDeviceCount = Object.keys(simulatedDeviceList)
      .reduce((acc, device) => ({
        ...acc,
        [device]: simulatedDeviceList[device].length,
      }), {});
    dispatch(saveDeviceSimulationConfig({
      devices: simDeviceCount,
      enable: Object.keys(simulatedDeviceList).length > 0,
    }));
  }, [simulatedDeviceList]);

  const simulatedDevicesList: DeviceCountInfo[] = Object
    .entries((simulatedDeviceList))
    .map(([deviceTypeIdentifier, deviceList]) => {
      const deviceModel = deviceList[0].catalog;

      const { deviceTypeName, productOrderNumber, deviceCatalogTitle } = deviceModel;
      return {
        title: deviceCatalogTitle,
        count: deviceList.length,
        deviceTypeIdentifier,
        deviceTypeName,
        orderCode: productOrderNumber,
      };
    });

  const simOptionList = simulatedDevicesList.map((entry) => (
    <option
      key={entry.deviceTypeIdentifier}
      value={entry.deviceTypeIdentifier}
      title={entry.title}
    >
      {`${entry.count} × ${entry.title}`}
    </option>
  ));

  return (
    <div className="backstage-simulation-settings">
      <h3>
        {`${t<string>('BACKSTAGE_PAGE_SETTINGS__COMMUNICATION_SIMULATION')}`}
      </h3>
      <div style={{ margin: '0 1rem -0.5rem' }}>
        {` ${t<string>('BACKSTAGE_PAGE_SETTINGS__SIMULATION_DEVICES_AVAILABLE')}`}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {protocols.map((protocol) => (
          <div className="w-100 p-3" key={`protocolList-${protocol}`}>
            <div className="backstage-simulation-settings__protocol-title">
              {` ${protocol}`}
            </div>
            <select
              size={20}
              className="backstage-simulation-settings__protocol-select w-100"
              onDoubleClick={(ev) => {
                const ident = (ev.target as HTMLSelectElement).value;
                const device = deviceCatalog.find((dev) => dev.deviceCatalogIdent === ident);
                if (device !== undefined) {
                  dispatch(incSimulatedDeviceCount(device));
                }
              }}
            >
              {distinct(
                deviceCatalog.filter((dt) => dt.protocol === protocol),
                (dt) => dt.deviceCatalogIdent,
              )
                .sort((a, b) => collator.compare(a?.deviceTypeName, b?.deviceTypeName))
                .map((model) => (
                  <option
                    key={model.deviceCatalogIdent}
                    value={model.deviceCatalogIdent}
                    title={createDeviceCatalogTitle(model)}
                  >
                    {createDeviceCatalogTitle(model)}
                  </option>
                ))}
            </select>
          </div>
        ))}
        <div className="w-100 p-3">
          <div className="backstage-simulation-settings__protocol-title">
            {` ${t<string>('BACKSTAGE_PAGE_SETTINGS__SIMULATION_DEVICES_ENABLED')}`}
          </div>
          <select
            size={20}
            className="backstage-simulation-settings__simulation-devices w-100"
            onDoubleClick={(ev) => {
              const ident = (ev.target as HTMLSelectElement).value;
              const device = deviceCatalog.find((dev) => dev.deviceCatalogIdent === ident);
              if (device !== undefined) {
                dispatch(decSimulatedDeviceCount(device));
              }
            }}
          >
            {simOptionList}
          </select>
        </div>
      </div>
    </div>
  );
};

export default BackstageSimulationSettings;
