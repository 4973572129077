/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import {
  ControlStateCollection,
  typeControlStateAction,
  CLEAN_ALL_CONTROLS,
} from './types';

import {
  SET_CURRENT_TAB,
} from './deviceParameterView/types';
import menuTabReducer from './deviceParameterView/reducer';
import gaugeReducer from './gaugeControl/reducer';
import { SET_GAUGE_INDICATOR } from './gaugeControl/types';

export const initialControlStateCollection: ControlStateCollection = {
};

const controlStateCollectionReducer = (
  state = initialControlStateCollection,
  action: typeControlStateAction,
): ControlStateCollection => {
  switch (action.type) {
    case SET_GAUGE_INDICATOR:
      return gaugeReducer(state, action);
    case SET_CURRENT_TAB:
      return menuTabReducer(state, action);
    case CLEAN_ALL_CONTROLS:
      return {};
    default:
      return state;
  }
};

export default controlStateCollectionReducer;
