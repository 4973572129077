/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  DataStorageValue, typeDeviceDataStorageAction,
} from './types';

export const setDataStorageValue = (targetInstance: string, payload: DataStorageValue): typeDeviceDataStorageAction => ({
  type: 'DEVICE_INSTANCE__DATA_STORAGE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_INSTANCE__DATA_STORAGE__SET_VALUE',
      payload,
    },
  },
});

export const removeDataStorageValue = (targetInstance: string, payload: string): typeDeviceDataStorageAction => ({
  type: 'DEVICE_INSTANCE__DATA_STORAGE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_INSTANCE__DATA_STORAGE__REMOVE_VALUE',
      payload,
    },
  },
});

export const clearAllDataStorageValues = (targetInstance: string): typeDeviceDataStorageAction => ({
  type: 'DEVICE_INSTANCE__DATA_STORAGE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_INSTANCE__DATA_STORAGE__CLEAR_ALL',
    },
  },
});
