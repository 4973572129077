/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store';
import { CxeTransferWizardDatasetType } from '../../../store/wizards/cxeTransferWizard/types';
import { LoadingControl } from '../../../components/LoadingControl';
import FailedWizardCxe from '../FailedWizardCxe/FailedWizardCxe';
import TransferDeviceParameterWizardCxe from '../TransferDeviceParameterCxe/TransferDeviceParameterCxe';
import TransferDipSwitchCxeWizard from '../TransferDipSwitchCxe/TransferDipSwitchCxe';
import { WizardProps } from '../../types';
import './TransferSelectorCxe.scss';

const TransferSelectorCxe: React.FC<WizardProps> = (props: WizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const { deviceInstanceId } = props;
  const datasetType = useTypedSelector((state) => state.cxeTransferWizard.datasetType);

  let activeControl = <></>;
  switch (datasetType) {
    case CxeTransferWizardDatasetType.INIT:
      activeControl = (
        <LoadingControl title={t<string>('LOADING_PREVIEW_DATA')} />
      );
      break;
    case CxeTransferWizardDatasetType.COMPLETE:
      activeControl = (
        <TransferDeviceParameterWizardCxe deviceInstanceId={deviceInstanceId} />
      );
      break;
    case CxeTransferWizardDatasetType.FIRMWARE:
      activeControl = (
        <TransferDeviceParameterWizardCxe deviceInstanceId={deviceInstanceId} />
      );
      break;
    case CxeTransferWizardDatasetType.HARDWARE_SWITCH:
      activeControl = (
        <TransferDipSwitchCxeWizard deviceInstanceId={deviceInstanceId} />
      );
      break;
    default:
      activeControl = (
        <FailedWizardCxe title="FAILED__WIZARD_TRANSFER_PARAMETER_LABEL" />
      );
  }

  return (
    <>
      {activeControl}
    </>
  );
};

export default TransferSelectorCxe;
