/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { writeActiveDeviceVariableValues } from '../../../store';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';

const useDispatchEnumeratorValue = (
  deviceInstanceId: string | undefined,
  targetDataset: DatasetType,
) => {
  const dispatch = useDispatch();
  const dispatchEnumeratorValue = useCallback((
    newValue: number,
    statusValue?: DeviceModelStatus.StatusValue,
  ): void => {
    if (statusValue === undefined || deviceInstanceId === undefined) {
      return;
    }
    dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
      identRef: statusValue.identRef,
      value: newValue,
      backupValue: statusValue.backupValue ?? statusValue.value,
    }]));
  }, [targetDataset]);

  return dispatchEnumeratorValue;
};

export default useDispatchEnumeratorValue;
