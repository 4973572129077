import { pad2 } from '../../helpers/functions';

export const HDC_ERROR_MEMORY__HDR_ERROR_ENTRY__WIDTH = '40%';
export const HDC_ERROR_MEMORY__HDR_WORKING_HOURS__WIDTH = '15%';
export const HDC_ERROR_MEMORY__HDR_CURRENT__WIDTH = '15%';
export const HDC_ERROR_MEMORY__HDR_VOLTAGE__WIDTH = '15%';
export const HDC_ERROR_MEMORY__HDR_TEMPERATURE__WIDTH = '15%';

export const HDC_ERROR_MEMORY_MAX_ENTRIES = 14;

export const HDC_ERROR_MEMORY__VAR__ERROR_COUNT = 'PARA_ERROR_MEMORY_ERROR_COUNT';
export const HDC_ERROR_MEMORY__VAR__LAST_ENTRY = 'PARA_ERROR_MEMORY_LAST_ENTRY';

export const HDC_ERROR_MEMORY__VAR__ENTRY = (index: number): string => `PARA_ERROR_MEMORY_ENTRY${pad2(index)}_ENTRY`;
export const HDC_ERROR_MEMORY__VAR__WORK_HOURS = (index: number): string => `PARA_ERROR_MEMORY_ENTRY${pad2(index)}_WORK_HOURS`;
export const HDC_ERROR_MEMORY__VAR__CURRENT = (index: number): string => `PARA_ERROR_MEMORY_ENTRY${pad2(index)}_CURRENT`;
export const HDC_ERROR_MEMORY__VAR__VOLTAGE = (index: number): string => `PARA_ERROR_MEMORY_ENTRY${pad2(index)}_VOLTAGE`;
export const HDC_ERROR_MEMORY__VAR__TEMPERATURE = (index: number): string => `PARA_ERROR_MEMORY_ENTRY${pad2(index)}_TEMPERATURE`;
