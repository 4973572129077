/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import Big from 'big.js';
import { DeviceModelStatus } from '@gpt/commons';

const divMap = [
  1,
  0.1,
  0.01,
  0.001,
  0.0001,
  0.00001,
  0.000001,
];

export const roundValue = (
  value: number | string,
  precision?: number,
  mode?: number,
): number | string => {
  if (precision === undefined) {
    return value;
  }

  let precValue = value;
  try {
    const factor = divMap[precision] ?? 1 / (10 ** precision);
    precValue = Big(value).div(factor).round(mode).times(factor)
      .toNumber();
  } catch {
    precValue = value;
  }
  return precValue;
};

export const statusValuePrecision = (
  descriptor?: DeviceModelStatus.DeviceModelDescriptor,
): number | undefined => {
  if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  const { valueType } = descriptor;
  if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
    return undefined;
  }

  const { precision } = valueType;
  return precision;
};

// eslint-disable-next-line import/prefer-default-export
export const calculatePrecisionValue = (
  descriptor: DeviceModelStatus.DeviceModelDescriptor | undefined,
  value: number | string,
): number | string => {
  if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return value;
  }

  const { valueType } = descriptor;
  if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
    return value;
  }

  const { precision } = valueType;
  if (precision === undefined) {
    return value;
  }
  return roundValue(value, precision);
};

export const isModifiedStatusValues = (
  descriptor: DeviceModelStatus.DeviceModelDescriptor,
  statusValue1: number,
  statusValue2: number,
): boolean => {
  const precision = statusValuePrecision(descriptor);
  if (precision === undefined) {
    const modified = statusValue1 !== statusValue2;
    return modified;
  }

  let modified = false;
  try {
    const prValue1 = roundValue(statusValue1, precision);
    const prValue2 = roundValue(statusValue2, precision);
    modified = prValue1 !== prValue2;
  } catch {
    modified = false;
  }
  return modified;
};

export const isEqualStatusValues = (
  precision: number | undefined,
  statusValue1: number | undefined,
  statusValue2: number | undefined,
): boolean => {
  if (statusValue1 === undefined && statusValue2 === undefined) {
    return true;
  }

  if (statusValue1 === undefined || statusValue2 === undefined) {
    return false;
  }

  if (precision === undefined) {
    return statusValue1 === statusValue2;
  }

  let isEqual = true;
  try {
    const prValue1 = roundValue(statusValue1, precision);
    const prValue2 = roundValue(statusValue2, precision);
    isEqual = prValue1 === prValue2;
  } catch {
    isEqual = false;
  }
  return isEqual;
};
