/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { useEffect, useState } from 'react';
import axios from 'axios';
import { Comparator } from 'semver';
import {
  ENV_APP_MODE, ENV_CXEDPTSERVICE_HOST, ENV_CXEDPTSERVICE_PORT, ENV_SUPPORTED_LOCAL_SERVICE,
} from '../../enviroment';

const logConsole = console;

interface ServiceInfo {
  Path: string;
  Version: string;
}

interface ProcessManagerInfo {
  EnvMode: string;
  ServicePath: string;
  CommunicationServer: ServiceInfo;
  InfrastructureServer: ServiceInfo
}

// eslint-disable-next-line no-unused-vars
export enum LocalBackendVersionStatus {
  // eslint-disable-next-line no-unused-vars
  Compatible = 'compatible',
  // eslint-disable-next-line no-unused-vars
  NotCompatible = 'not-compatible',
  // eslint-disable-next-line no-unused-vars
  NotAvailable = 'not-available',
}

const getLocalBackendInfo = (): Promise<ProcessManagerInfo> => new Promise<ProcessManagerInfo>((resolve, reject) => {
  axios
    .get<ProcessManagerInfo>(`${ENV_CXEDPTSERVICE_HOST}:${ENV_CXEDPTSERVICE_PORT}/api/v4/service/info`, { timeout: 1000 })
    .then((res) => res.data)
  // ok, reject, servicenotfound,
    .then((data) => resolve(data))
    .catch((err) => reject(err));
});

const parseVersion = (serviceVersion: string): string => {
  const regVersion = serviceVersion.match(/(.+?)\+.*/);
  return regVersion?.[1] ?? serviceVersion;
};

export const useBackendVersion = (): LocalBackendVersionStatus => {
  const [versionStatus, setVersionStatus] = useState(LocalBackendVersionStatus.NotAvailable);

  useEffect(() => {
    let timeoutHandle;
    const backendConnection = async () => {
      let retry = false;
      if (ENV_APP_MODE !== 'CLOUD') {
        setVersionStatus(LocalBackendVersionStatus.Compatible);
        return;
      }

      try {
        const status = await getLocalBackendInfo();
        logConsole.log('useBackendVersion', status);
        const commVersion = parseVersion(status.CommunicationServer.Version);
        const infraVersion = parseVersion(status.InfrastructureServer.Version);
        const supLocalVersion = new Comparator(ENV_SUPPORTED_LOCAL_SERVICE ?? '1.0.0');

        if (supLocalVersion.test(commVersion) && supLocalVersion.test(infraVersion)) {
          setVersionStatus(LocalBackendVersionStatus.Compatible);
          retry = false;
        } else {
          setVersionStatus(LocalBackendVersionStatus.NotCompatible);
          retry = true;
        }
      } catch (err) {
        retry = true;
        setVersionStatus(LocalBackendVersionStatus.NotAvailable);
      }
      if (retry) {
        timeoutHandle = setTimeout(async () => backendConnection(), 5000);
      }
    };

    backendConnection();
    return () => {
      if (timeoutHandle !== undefined) {
        clearTimeout(timeoutHandle);
      }
    };
  }, []);
  return versionStatus;
};
