export const ENV_CXEDPTSERVICE_HOST = process.env.REACT_APP_CXEDPTSERVICE_HOST;
export const ENV_CXEDPTSERVICE_PORT = process.env.REACT_APP_CXEDPTSERVICE_PORT;

export const ENV_WEBSERVER_HOST = process.env.REACT_APP_WEBSERVER_HOST;
export const ENV_WEBSERVER_PORT = process.env.REACT_APP_WEBSERVER_PORT;
export const ENV_WEBSERVER_PATH = process.env.REACT_APP_WEBSERVER_PATH;

export const ENV_WEBSERVER_REQUEST_PATH = `${ENV_WEBSERVER_HOST}:${ENV_WEBSERVER_PORT}${ENV_WEBSERVER_PATH}`;

export const ENV_DEVICEMODEL_SERVER_HOST = process.env.REACT_APP_DEVICEMODEL_SERVER_HOST;
export const ENV_DEVICEMODEL_SERVER_PORT = process.env.REACT_APP_DEVICEMODEL_SERVER_PORT;

export const ENV_UPDATE_CHANNEL = process.env.REACT_APP_UPDATE_CHANNEL;

export const ENV_APP_MODE = process.env.REACT_APP_MODE;
export const ENV_SUPPORTED_LOCAL_SERVICE = process.env.REACT_APP_SUPPORTED_LOCAL_SERVICE;

export const ENV_CXEDPTSERVICE_UPDATE_LINK = process.env.REACT_APP_CXEDPTSERVICE_UPDATE_LINK;

export const ENV_COPYRIGHT_YEAR = process.env.REACT_APP_COPYRIGHT_YEAR;
