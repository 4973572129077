/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ParameterControl } from '../../../../components/ParameterEditor';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { setContextParameterWarning, setContextParameterHelp } from '../../../../store/contexthelp';
import { updateDeviceDatasetValues } from '../../../../store/deviceInstances/store/deviceDataset';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import PasswordControl from '../../PasswordControl';
import { TypedPasswordControlProps } from '../types';

const PasswordUIntegerControl:
React.FC<TypedPasswordControlProps> = (props: TypedPasswordControlProps):
React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp,
    showContextMessageDef,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warning, setWarning] = useState<string | undefined>(undefined);
  const [showContextMessage, setShowContextMessage] = useState<boolean>(showContextMessageDef ?? false);

  const statusValueLabel = t(statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? statusDescriptor.label : '');
  const statusValueHelp = t(statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? statusDescriptor.help : '');

  const { valueValidityDescription, valueValidity } = statusValue;

  useEffect(() => {
    const updateMessage = (message?: string) => {
      if (showContextMessage) {
        dispatch(setContextParameterWarning(message));
      }
      setWarning(message);
    };

    const parsedValue = parseFloat(statusValue.value);
    if (Number.isNaN(parsedValue)) {
      updateMessage(t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT'));
    } else if (valueValidity === DeviceModelStatus.StatusValueValidity.valid) {
      updateMessage(undefined);
    } else {
      updateMessage(t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__INTERVALS', {
        INTERVAL: valueValidityDescription,
      }));
    }
  }, [statusValue, showContextMessage, valueValidityDescription, valueValidity]);

  return (
    <ParameterControl
      label={statusValueLabel}
    >
      <PasswordControl
        value={statusValue.value}
        warning={warning}
        onValueChange={(ev) => {
          const originalValue = ev;
          const parsedValue = Number(originalValue);
          if (Number.isNaN(parsedValue)) {
            dispatch(updateDeviceDatasetValues(deviceInstanceId, {
              targetDataset: DatasetType.user,
              values: [{
                value: originalValue,
                backupValue: statusValue.backupValue,
                identRef: statusValue.identRef,
                valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
                valueValidityDescription: t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT'),
              }],
            }));
          } else {
            dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
              value: parsedValue,
              backupValue: statusValue.backupValue,
              identRef: statusValue.identRef,
            }]));
          }
        }}
        modified={statusValue.modified}
        onFocus={() => {
          setShowContextMessage(true);
          if (statusDescriptor !== undefined) {
            const range = DeviceModelStatus.Functions.GetDescriptorRange(statusDescriptor);
            const message = (range?.rangeAsText === undefined) ? '' : t<string>('PARAMETER__VALID_VALUE_RANGE__HELP', {
              INTERVAL: range?.rangeAsText ?? '',
            });
            dispatch(setContextParameterHelp({
              title: statusValueLabel,
              text: statusValueHelp,
              message,
            }));
          }
          onShowContentHelp?.();
        }}
        onBlur={() => {
          setShowContextMessage(false);
          dispatch(setContextParameterWarning(undefined));
          onHideContentHelp?.();
        }}
      />
    </ParameterControl>
  );
};

export default PasswordUIntegerControl;
