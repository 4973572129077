/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { Services, WebDevice } from '@gpt/commons';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import {
  METHOD_STAGE_EXECUTION__ABORT,
  METHOD_STAGE_EXECUTION__ACCEPT_DATA,
  METHOD_STAGE_EXECUTION__INIT,
  METHOD_STAGE_EXECUTION__PASSWORD,
  typeDeviceMethodStageMiddlewareActions,
} from './types';
import { IWebWorkerDeviceManager } from '../../../../services/WebWorkerDevice/WebWorkerDeviceManager';
import { showApplicationMessage } from '../../../applicationMessage/actions';
import { DatasetType } from '../../store/deviceDataset/types';
import { setDeviceMethodExecutionState } from '../../store/deviceMethod/actions';
import { MethodStageExecutionStatus } from '../../store/deviceMethod/types';
import { IWebWorkerDevice } from '../../../../services/WebWorkerDevice/WebWorkerDevice';
import { deviceInstancesStoreSelector } from '../../../reduxStoreSelector';

export const stageExecutionMiddleware = (webWorkerDeviceManager: IWebWorkerDeviceManager): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeDeviceMethodStageMiddlewareActions>(action: A): Promise<A> => {
  const processSequenceResponse = async (methodStatusRef: string, resp: Services.DeviceModel.MethodExecutionResponse): Promise<void> => {
    const { response, deviceInstanceId } = resp;
    switch (response.type) {
      case 'WEBDEVICE__METHOD_EXECUTE__PASSWORD_REQUEST':
        api.dispatch(setDeviceMethodExecutionState(deviceInstanceId, {
          methodIdent: methodStatusRef,
          stage: MethodStageExecutionStatus.RequestPassword,
        }));
        if (response.message !== undefined) {
          api.dispatch(showApplicationMessage('warning', response.message));
        }
        break;
      case 'WEBDEVICE__METHOD_EXECUTE__ACCEPT_DATA_REQUEST':
        api.dispatch(setDeviceMethodExecutionState(deviceInstanceId, {
          methodIdent: methodStatusRef,
          stage: MethodStageExecutionStatus.RequestAcceptData,
          acceptData: response.data,
        }));
        break;
      case 'WEBDEVICE__METHOD_EXECUTE__DONE__FAILED':
        api.dispatch(setDeviceMethodExecutionState(deviceInstanceId, {
          methodIdent: methodStatusRef,
          stage: MethodStageExecutionStatus.DoneFailed,
          message: response.message,
        }));
        break;
      case 'WEBDEVICE__METHOD_EXECUTE__DONE__ABORTED':
        api.dispatch(setDeviceMethodExecutionState(deviceInstanceId, {
          methodIdent: methodStatusRef,
          stage: MethodStageExecutionStatus.DoneFailed,
          message: 'Aborted',
        }));
        break;
      case 'WEBDEVICE__METHOD_EXECUTE__DONE__SUCCESS':
        if (response.data) {
          const deviceInstances = deviceInstancesStoreSelector(api.getState());
          const webWorkerInstance = deviceInstances.instances[deviceInstanceId]?.activeDevice.modelInstance?.webWorkerInstanceRef;
          if (webWorkerInstance === undefined) {
            return;
          }
          const deviceWorker = webWorkerDeviceManager.get(webWorkerInstance);
          if (deviceWorker !== undefined && response.suppressReinitDataset !== true) {
            // Initialize user dataset
            await deviceWorker.get(DatasetType.user).writeUploadValues(response.data ?? []);
            // Initialize init dataset
            await deviceWorker.get(DatasetType.init).writeUploadValues(response.data ?? []);
          }
        }
        api.dispatch(setDeviceMethodExecutionState(deviceInstanceId, {
          methodIdent: methodStatusRef,
          stage: MethodStageExecutionStatus.DoneSuccess,
        }));
        break;
      default:
    }
  };

  const createMethodRequest = (
    deviceInstanceId: string,
    methodIdent: string,
    methodStatusRef: string,
    request: Services.DeviceModel.MethodRequestPayload,
    header?: Services.DeviceModel.MethodHeader,
  ): WebDevice.WebDeviceExecuteMethodRequest => ({
    kind: 'WEBDEVICE__EXECUTE_METHOD__REQUEST',
    methodIdent,
    actionId: methodStatusRef,
    requestId: uuidv4(),
    header: header ?? {},
    request,
    deviceInstanceId,
  });

  const executeDeviceInitSequence = async (
    webWorkerDevice: IWebWorkerDevice,
    deviceInstanceId: string,
    methodIdent: string,
    methodStatusRef: string,
    header?: Services.DeviceModel.MethodHeader,
    data?: Services.DeviceModel.StatusValueRef[],
  ): Promise<void> => {
    api.dispatch(setDeviceMethodExecutionState(deviceInstanceId, {
      methodIdent: methodStatusRef,
      stage: MethodStageExecutionStatus.InProgress,
    }));
    // Initialize write upload values to Init and User dataset
    const request = createMethodRequest(deviceInstanceId, methodIdent, methodStatusRef, {
      type: 'WEBDEVICE__METHOD_EXECUTE__INIT',
      data,
    }, header);
    const response = await webWorkerDevice.get(DatasetType.device).executeMethod(request);
    await processSequenceResponse(methodStatusRef, response);
  };

  const executeDevicePasswordSequence = async (
    webWorkerDevice: IWebWorkerDevice,
    deviceInstanceId: string,
    methodIdent: string,
    methodStatusRef: string,
    password: string,
    header?: Services.DeviceModel.MethodHeader,
  ): Promise<void> => {
    // Initialize write upload values to Init and User dataset
    api.dispatch(setDeviceMethodExecutionState(deviceInstanceId, {
      methodIdent: methodStatusRef, stage: MethodStageExecutionStatus.InProgress,
    }));
    const request = createMethodRequest(deviceInstanceId, methodIdent, methodStatusRef, {
      type: 'WEBDEVICE__METHOD_EXECUTE__PASSWORD',
      password,
    }, header);
    const response = await webWorkerDevice.get(DatasetType.device)
      .executeMethod(request);
    await processSequenceResponse(methodStatusRef, response);
  };

  const executeDeviceAcceptDataSequence = async (
    webWorkerDevice: IWebWorkerDevice,
    deviceInstanceId: string,
    methodIdent: string,
    methodStatusRef: string,
    accept: boolean,
    header?: Services.DeviceModel.MethodHeader,
  ): Promise<void> => {
    // Initialize write upload values to Init and User dataset
    api.dispatch(setDeviceMethodExecutionState(
      deviceInstanceId,
      {
        methodIdent: methodStatusRef,
        stage: MethodStageExecutionStatus.InProgress,
      },
    ));
    const request = createMethodRequest(deviceInstanceId, methodIdent, methodStatusRef, {
      type: 'WEBDEVICE__METHOD_EXECUTE__ACCEPT_DATA',
      accept,
    }, header);

    const response = await webWorkerDevice.get(DatasetType.device)
      .executeMethod(request);
    await processSequenceResponse(methodStatusRef, response);
  };

  const abortDeviceMethodSequence = async (
    webWorkerDevice: IWebWorkerDevice,
    deviceInstanceId: string,
    methodIdent: string,
    methodStatusRef: string,
    header?: Services.DeviceModel.MethodHeader,
  ): Promise<void> => {
    const request = createMethodRequest(deviceInstanceId, methodIdent, methodStatusRef, {
      type: 'WEBDEVICE__METHOD_EXECUTE__ABORT',
      deviceInstance: deviceInstanceId,
    }, header);

    const response = await webWorkerDevice.get(DatasetType.device)
      .executeMethod(request);
    await processSequenceResponse(methodStatusRef, response);
  };

  const getWebWorkerDevice = (
    targetInstance: string,
    methodIdent: string,
  ): IWebWorkerDevice | undefined => {
    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const webWorkerInstance = deviceInstances.instances[targetInstance]?.activeDevice.modelInstance?.webWorkerInstanceRef;
    if (webWorkerInstance === undefined) {
      return undefined;
    }

    const deviceWorker = webWorkerDeviceManager.get(webWorkerInstance);
    if (deviceWorker === undefined) {
      api.dispatch(setDeviceMethodExecutionState(targetInstance, {
        methodIdent,
        stage: MethodStageExecutionStatus.DoneFailed,
      }));
    }
    return deviceWorker;
  };

  switch (action.type) {
    case METHOD_STAGE_EXECUTION__INIT: {
      const { data, targetInstance } = action.payload;
      const {
        methodIdent, header, values, methodStatusRef,
      } = data;

      const deviceWorker = getWebWorkerDevice(targetInstance, methodIdent);
      if (deviceWorker === undefined) {
        break;
      }
      await executeDeviceInitSequence(deviceWorker, targetInstance, methodIdent, methodStatusRef ?? methodIdent, header, values);
      break;
    }

    case METHOD_STAGE_EXECUTION__PASSWORD: {
      const { data, targetInstance } = action.payload;
      const {
        methodIdent, header, password, methodStatusRef,
      } = data;
      const deviceWorker = getWebWorkerDevice(targetInstance, methodIdent);
      if (deviceWorker === undefined) {
        break;
      }
      await executeDevicePasswordSequence(deviceWorker, targetInstance, methodIdent, methodStatusRef ?? methodIdent, password, header);
      break;
    }

    case METHOD_STAGE_EXECUTION__ACCEPT_DATA: {
      const { data, targetInstance } = action.payload;
      const {
        methodIdent, accept, header, methodStatusRef,
      } = data;
      const deviceWorker = getWebWorkerDevice(targetInstance, methodIdent);
      if (deviceWorker === undefined) {
        break;
      }
      await executeDeviceAcceptDataSequence(deviceWorker, targetInstance, methodIdent, methodStatusRef ?? methodIdent, accept, header);
      break;
    }

    case METHOD_STAGE_EXECUTION__ABORT: {
      const { data, targetInstance } = action.payload;
      const { methodIdent, header, methodStatusRef } = data;
      const deviceWorker = getWebWorkerDevice(targetInstance, methodIdent);
      if (deviceWorker === undefined) {
        break;
      }
      await abortDeviceMethodSequence(deviceWorker, targetInstance, methodIdent, methodStatusRef ?? methodIdent, header);
      break;
    }
    default:
  }
  return next(action);
};
