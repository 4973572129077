/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { toFixedValue } from '@gpt/commons';
import './LoadingProgressSpinner.scss';

export type LoadingProgressSpinnerSize = '' | 'lg' | 'xl' | 'xxl' | 'device-card' | 'contactron-station';

export interface LoadingProgressSpinnerProps {
  size?: LoadingProgressSpinnerSize;
  title?: string;
  progress?: number;
}

const cssClassName = (nclass: string, prop?: string | number)
  : string => `${nclass}${prop ? '' : ` ${nclass}--hidden`}`;

export const LoadingProgressSpinner
: React.FC<LoadingProgressSpinnerProps> = (props: LoadingProgressSpinnerProps):
  React.ReactElement => {
  const {
    size,
    title,
    progress,
  } = props;
  const cssClass = size === '' || size === undefined ? 'loading-progress-spinner' : `loading-progress-spinner--${size}`;

  const cssTitleClass = cssClassName('loading-progress-spinner-container__title', title);
  const cssProgressClass = cssClassName('loading-progress-spinner-container__progress', progress);

  const progressText = `${toFixedValue(progress, 0)} %`;

  return (
    <div>
      <div className={cssClass}>
        <div />
        <div />
        <div />
        <div />
        <p className="loading-progress-spinner-container">
          <span className={cssTitleClass}>{title}</span>
          <span className={cssProgressClass}>{progressText}</span>
        </p>
      </div>
    </div>
  );
};
