/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { IdentRef } from '@gpt/commons';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ReduxControlProps, StructureItemState, StructureItems } from '../../ReduxControlProps';
import { Quint4DigitalSignalControl } from '../Quint4DigitalSignal/Quint4DigitalSignal';
import { Quint4SignalVariableBlock } from '../Quint4DigitalSignal/types';
import { writeActiveDeviceVariableValues } from '../../../store';
import { DatasetContext } from '../../../views/DatasetContext';
import Quint4DigitalResultDropDown from '../Quint4DigitalSignal/DropDown/DigitalResultDropDown/DigitalResultDropDown';
import Quint4AnalogSignalRedux from '../Quint4AnalogSignal/Quint4AnalogSignalRedux';
import { useContextDatasetStatusValue } from '../../../hooks/useContextDataset';
import { StateUpdateCallback, transformMenuItemState } from '../../hooks/useMenuItemStateUpdate';

const blockVariables = Quint4SignalVariableBlock(2);

const useQuint4SignalOut2StateUpdate = () => {
  const [childrenState, setChildrenState] = useState<StructureItems>({});

  // eslint-disable-next-line max-len
  const updateMenuItemState: StateUpdateCallback = useCallback((ident: IdentRef, state: StructureItemState) => {
    setChildrenState((prev) => transformMenuItemState(prev, ident, state));
  }, []);

  const mod = Object.keys(childrenState).find((vr) => childrenState[vr]?.modified === true) !== undefined;
  const iserror = Object.keys(childrenState).find((vr) => childrenState[vr]?.error === true) !== undefined;
  return [mod, iserror, updateMenuItemState] as const;
};

const Quint4SignalOut2: React.FC<ReduxControlProps> = (props: ReduxControlProps)
  : React.ReactElement => {
  const { deviceInstanceId, identRef, onStateChanged } = props;
  const { targetDataset } = useContext(DatasetContext);
  const dispatch = useDispatch();

  const switchVariableIdent = 'var_UDP_Out2AnalogFunc_Out2FunSelect_Out2Ctrl';
  const switchStatusValue = useContextDatasetStatusValue(deviceInstanceId, switchVariableIdent, targetDataset);

  const [analogMod, analogErr, analogOnStateChange] = useQuint4SignalOut2StateUpdate();
  const [digitalMod, digitalErr, digitalOnStateChange] = useQuint4SignalOut2StateUpdate();
  const [switchMod, switchErr, switchOnStateChange] = useQuint4SignalOut2StateUpdate();

  const blockMod = switchStatusValue?.value === 0 ? analogMod || switchMod : digitalMod || switchMod;
  const blockError = switchStatusValue?.value === 0 ? analogErr || switchErr : digitalErr || switchErr;

  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, {
      error: blockError,
      modified: blockMod,
    });
  }, [blockMod, blockError, identRef]);

  const outputBlock = switchStatusValue?.value === 0
    ? (
      <Quint4AnalogSignalRedux
        identRef={identRef}
        deviceInstanceId={deviceInstanceId}
        onStateChanged={analogOnStateChange}
      />
    )
    : (
      <Quint4DigitalSignalControl
        identRef={identRef}
        deviceInstanceId={deviceInstanceId}
        blockVariables={blockVariables}
        showContextMessageDef
        onStateChanged={digitalOnStateChange}
      />
    );

  return (
    <>
      <Quint4DigitalResultDropDown
        identRef={switchVariableIdent}
        deviceInstanceId={deviceInstanceId}
        onStateChanged={switchOnStateChange}
        onValueChange={(value) => {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            identRef: switchVariableIdent,
            backupValue: switchStatusValue?.backupValue,
            value,
          }]));
        }}
      />
      {outputBlock}
    </>
  );
};

export default Quint4SignalOut2;
