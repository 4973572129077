import { Services } from '@gpt/commons';
import {
  CxeTransferWizardActionTypes,
  CxeTransferWizardState,
  PrepareTransferValuesAction,
  CXE_TRANSFER_WIZARD__PREPARE_TRANSFER_VALUES,
  CXE_TRANSFER_WIZARD__CLEANUP,
  CxeTransferWizardDatasetType,
} from './types';

const initialState: CxeTransferWizardState = {
  orderCode: '',
  transferValues: [],
  datasetType: CxeTransferWizardDatasetType.INIT,
};

const toDatasetType = (dset?: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType)
: CxeTransferWizardDatasetType => {
  if (dset === undefined) {
    return CxeTransferWizardDatasetType.COMPLETE;
  }

  switch (dset) {
    case Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType.COMPLETE:
      return CxeTransferWizardDatasetType.COMPLETE;
    case Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType.FIRMWARE:
      return CxeTransferWizardDatasetType.FIRMWARE;
    case Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType.HARDWARE_SWITCH:
      return CxeTransferWizardDatasetType.HARDWARE_SWITCH;
    default:
  }
  return CxeTransferWizardDatasetType.COMPLETE;
};

const prepareTransferValuesReducer = (
  state: CxeTransferWizardState,
  action: PrepareTransferValuesAction,
): CxeTransferWizardState => {
  const { orderCode, dataset } = action.payload;
  // eslint-disable-next-line max-len
  const parameterDataset = JSON.parse(dataset) as Services.DeviceModelServer.DatasetProviderService.ExternalDataset;

  if (parameterDataset === null) {
    return state;
  }

  const datasetType = toDatasetType(parameterDataset.header.datasetType);
  const datasetValues = parameterDataset.values;
  const values: Services.DeviceModel.StatusValueRef[] = Object
    .keys(datasetValues)
    .map((identRef) => ({
      identRef,
      value: datasetValues[identRef].value,
      backupValue: undefined,
      modified: false,
    }));

  return {
    ...state,
    orderCode: orderCode,
    transferValues: values,
    datasetType,
  };
};

const cxeTransferWizardReducer = (
  state = initialState,
  action: CxeTransferWizardActionTypes,
): CxeTransferWizardState => {
  switch (action.type) {
    case CXE_TRANSFER_WIZARD__CLEANUP:
      return {
        ...state,
        datasetType: CxeTransferWizardDatasetType.INIT,
        orderCode: '',
        transferValues: [],
      };
    case CXE_TRANSFER_WIZARD__PREPARE_TRANSFER_VALUES:
      return prepareTransferValuesReducer(state, action);
    default:
      return state;
  }
};

export default cxeTransferWizardReducer;
