/* eslint-disable max-len */
import React from 'react';
import { ChartScaleMark, ChartSegment } from '../Elements/types';
import ChartCardControl from '../ChartCardControl/ChartCardControl';
import { Phasen3Chart } from './chart/3PhasenChart';
import './3PhasenControl.scss';

export interface Phasen3ControlProps {
  label: string;
  unit: string;
  l1Value: number;
  l1DragMinValue: number;
  l1DragMaxValue: number;
  l2Value: number;
  l2DragMinValue: number;
  l2DragMaxValue: number;
  l3Value: number;
  l3DragMinValue: number;
  l3DragMaxValue: number;
  chartSegments: ChartSegment[];
  chartMarks: ChartScaleMark[];
  displayFormat: string;
  showDragIndicator: boolean;
  // eslint-disable-next-line no-unused-vars
  onShowDragIndicator: (value: boolean) => void;
  onResetDragValues: () => void;
}

const Phasen3Control: React.FC<Phasen3ControlProps> = (props: Phasen3ControlProps)
: React.ReactElement => {
  const {
    label,
    l1Value, l1DragMinValue, l1DragMaxValue,
    l2Value, l2DragMinValue, l2DragMaxValue,
    l3Value, l3DragMinValue, l3DragMaxValue,
    unit,
    chartSegments,
    chartMarks,
    displayFormat,
    showDragIndicator,
    onResetDragValues,
    onShowDragIndicator,
  } = props;

  return (
    <ChartCardControl label={label} showDragIndicator={showDragIndicator} onResetDragValues={onResetDragValues} onShowDragIndicator={onShowDragIndicator}>
      <Phasen3Chart
        l1Value={l1Value}
        l1DragMinValue={l1DragMinValue}
        l1DragMaxValue={l1DragMaxValue}
        l2Value={l2Value}
        l2DragMinValue={l2DragMinValue}
        l2DragMaxValue={l2DragMaxValue}
        l3Value={l3Value}
        l3DragMinValue={l3DragMinValue}
        l3DragMaxValue={l3DragMaxValue}
        unit={unit}
        chartSegments={chartSegments}
        chartMarks={chartMarks}
        displayFormat={displayFormat}
        showDragValues={showDragIndicator}
      />
    </ChartCardControl>
  );
};

export default Phasen3Control;
