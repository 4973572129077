/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { IdentRef } from '@gpt/commons';
import { Action } from 'redux';
import { DeviceInstanceActionPayload } from '../../common';

export const TIMELINE__REQUEST_DATA = 'TIMELINE__REQUEST_DATA';

export interface RequestTimelineDataPayload extends DeviceInstanceActionPayload {
  identList: IdentRef[];
}

export interface RequestTimelineDataAction extends Action<typeof TIMELINE__REQUEST_DATA> {
  payload: RequestTimelineDataPayload;
}

export type typeTimelineDataMiddlewareActionTypes = RequestTimelineDataAction;
