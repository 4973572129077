/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useRef } from 'react';
import FwdRefInputBoxControl from '../../Basic/FwdRefInputBoxControl/FwdRefInputBoxControl';
import './MacAddressControl.css';

const addressDelimiter = ':';

export interface MACAddressControlProps {
    value: string;
    warning?: string;
    modified?: boolean;
    readonly?: boolean;
    // eslint-disable-next-line no-unused-vars
    onValueChange: (value: string) => void;
    onShowContextHelp?: () => void;
    onHideContextHelp?: () => void;
}

const extractMacValue = (value: string, index: number): string => {
  const values = value.split(addressDelimiter);
  return values[index];
};

const isValidMacValue = (value: string, index: number): boolean => {
  const values = value.split(addressDelimiter);
  if (index > values.length) {
    return false;
  }

  const macValue = values[index];
  if (/^[0-9A-Fa-f]{2}$/.test(macValue)) {
    return true;
  }

  return false;
};

const stringsToMACAddress = (
  macValue1: string,
  macValue2: string,
  macValue3: string,
  macValue4: string,
  macValue5: string,
  macValue6: string,
)
  : string => `${macValue1}${addressDelimiter}${macValue2}${addressDelimiter}${macValue3}${addressDelimiter}${macValue4}${addressDelimiter}${macValue5}${addressDelimiter}${macValue6}`;

export const MACAddressControl:
React.FC<MACAddressControlProps> = (props: MACAddressControlProps): React.ReactElement => {
  const {
    value, readonly,
    warning, modified,
    onValueChange, onHideContextHelp, onShowContextHelp,
  } = props;

  const macValues = value.split(addressDelimiter);
  const refAddress2 = useRef<HTMLInputElement>(null);
  const refAddress3 = useRef<HTMLInputElement>(null);
  const refAddress4 = useRef<HTMLInputElement>(null);
  const refAddress5 = useRef<HTMLInputElement>(null);
  const refAddress6 = useRef<HTMLInputElement>(null);

  return (
    <div className="mac-address-container d-flex flex-column align-items-center justify-content-center" data-testid="mac-address-control">
      <div className="mac-address-container__input d-flex flex-row align-items-center justify-content-center">
        <div className="mac-address-container__input__inputbox">
          <FwdRefInputBoxControl
            type="string"
            value={extractMacValue(value, 0)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const macValue = e.target.value;
              const macAddr = stringsToMACAddress(
                macValue,
                macValues[1],
                macValues[2],
                macValues[3],
                macValues[4],
                macValues[5],
              );

              if (macValue !== '') {
                if (e.target.value.length === 2) {
                  refAddress2.current?.focus();
                }
              }
              onValueChange(macAddr);
            }}
            readonly={readonly}
            warning={isValidMacValue(value, 0) ? undefined : ''}
            onFocus={() => onShowContextHelp?.()}
            onBlur={() => onHideContextHelp?.()}
            edited={modified}
            maxLength={2}
          />
        </div>
        <div className="mac-address-container__delimeter">{addressDelimiter}</div>
        <div className="mac-address-container__input__inputbox">
          <FwdRefInputBoxControl
            type="string"
            ref={refAddress2}
            value={extractMacValue(value, 1)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const macValue = e.target.value;
              const macAddr = stringsToMACAddress(
                macValues[0],
                macValue,
                macValues[2],
                macValues[3],
                macValues[4],
                macValues[5],
              );

              if (macValue !== '') {
                if (e.target.value.length === 2) {
                  refAddress3.current?.focus();
                }
              }
              onValueChange(macAddr);
            }}
            readonly={readonly}
            warning={isValidMacValue(value, 1) ? undefined : ''}
            onFocus={() => onShowContextHelp?.()}
            onBlur={() => onHideContextHelp?.()}
            edited={modified}
            maxLength={2}
          />
        </div>
        <div className="mac-address-container__delimeter">{addressDelimiter}</div>
        <div className="mac-address-container__input__inputbox">
          <FwdRefInputBoxControl
            type="string"
            ref={refAddress3}
            value={extractMacValue(value, 2)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const macValue = e.target.value;
              const macAddr = stringsToMACAddress(
                macValues[0],
                macValues[1],
                macValue,
                macValues[3],
                macValues[4],
                macValues[5],
              );

              if (macValue !== '') {
                if (e.target.value.length === 2) {
                  refAddress4.current?.focus();
                }
              }
              onValueChange(macAddr);
            }}
            readonly={readonly}
            warning={isValidMacValue(value, 2) ? undefined : ''}
            onFocus={() => onShowContextHelp?.()}
            onBlur={() => onHideContextHelp?.()}
            edited={modified}
            maxLength={2}
          />
        </div>
        <div className="mac-address-container__delimeter">{addressDelimiter}</div>
        <div className="mac-address-container__input__inputbox">
          <FwdRefInputBoxControl
            type="string"
            ref={refAddress4}
            value={extractMacValue(value, 3)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const macValue = e.target.value;
              const macAddr = stringsToMACAddress(
                macValues[0],
                macValues[1],
                macValues[2],
                macValue,
                macValues[4],
                macValues[5],
              );

              if (macValue !== '') {
                if (e.target.value.length === 2) {
                  refAddress5.current?.focus();
                }
              }
              onValueChange(macAddr);
            }}
            readonly={readonly}
            warning={isValidMacValue(value, 3) ? undefined : ''}
            onFocus={() => onShowContextHelp?.()}
            onBlur={() => onHideContextHelp?.()}
            edited={modified}
            maxLength={2}
          />
        </div>
        <div className="mac-address-container__delimeter">{addressDelimiter}</div>
        <div className="mac-address-container__input__inputbox">
          <FwdRefInputBoxControl
            type="string"
            ref={refAddress5}
            value={extractMacValue(value, 4)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const macValue = e.target.value;
              const macAddr = stringsToMACAddress(
                macValues[0],
                macValues[1],
                macValues[2],
                macValues[3],
                macValue,
                macValues[5],
              );

              if (macValue !== '') {
                if (e.target.value.length === 2) {
                  refAddress6.current?.focus();
                }
              }
              onValueChange(macAddr);
            }}
            readonly={readonly}
            warning={isValidMacValue(value, 4) ? undefined : ''}
            onFocus={() => onShowContextHelp?.()}
            onBlur={() => onHideContextHelp?.()}
            edited={modified}
            maxLength={2}
          />
        </div>
        <div className="mac-address-container__delimeter">{addressDelimiter}</div>
        <div className="mac-address-container__input__inputbox">
          <FwdRefInputBoxControl
            type="string"
            ref={refAddress6}
            value={extractMacValue(value, 5)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const macValue = e.target.value;
              const macAddr = stringsToMACAddress(
                macValues[0],
                macValues[1],
                macValues[2],
                macValues[3],
                macValues[4],
                macValue,
              );
              onValueChange(macAddr);
            }}
            readonly={readonly}
            warning={isValidMacValue(value, 5) ? undefined : ''}
            onFocus={() => onShowContextHelp?.()}
            onBlur={() => onHideContextHelp?.()}
            edited={modified}
            maxLength={2}
          />
        </div>
      </div>
      <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
        <div className="input-field-control__warning-text">{warning}</div>
      </div>
    </div>
  );
};
