/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';
import {
  Middleware, MiddlewareAPI, Dispatch,
} from 'redux';
import {
  DEVICE_DATASET__UPDATE_DATASET,
  DatasetType,
  typeDeviceDatasetActionTypes,
  typeDeviceInstanceDeviceDatasetAction,
} from '../deviceInstances/store/deviceDataset/types';
import { updateIoLinkEventMonitor } from '../deviceInstances/store/ioLinkEventMonitor/actions';
import { updateTrio3EventMonitor } from '../deviceInstances/store/ioLinkTrio3EventMonitor/actions';
import { IOLINKEVENTLIST_IDENT } from '../deviceInstances/store/ioLinkEventMonitor/types';

const deviceDatasetMiddleware = (): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeDeviceInstanceDeviceDatasetAction>(instanceAction: A): Promise<A> => {
  const handleDeviceDatasetActions = (action: typeDeviceDatasetActionTypes, targetInstance: string) => {
    switch (action.type) {
      case DEVICE_DATASET__UPDATE_DATASET: {
        const { payload } = action;
        const { data, targetDataset } = payload;
        if (targetDataset !== DatasetType.device) {
          break;
        }
        const ioLinkEvent = data.find((value) => value.identRef === IOLINKEVENTLIST_IDENT);
        if (ioLinkEvent?.type === DeviceModelStatus.StatusType.StatusValue) {
          api.dispatch(updateIoLinkEventMonitor(targetInstance, ioLinkEvent.value));
        }
        // TODO: Create more sophisticated solution
        const trio3eventList = data.find((ev) => ev.identRef === 'var_V_EventStatus_EventListData');
        if (trio3eventList?.type === DeviceModelStatus.StatusType.StatusValue) {
          api.dispatch(updateTrio3EventMonitor(targetInstance, {
            eventList: trio3eventList.value,
            targetInstance,
          }));
        }
      }
        break;
      default:
    }
  };

  switch (instanceAction.type) {
    case 'DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS': {
      const { payload } = instanceAction;
      const { action, targetInstance } = payload;
      handleDeviceDatasetActions(action, targetInstance);
    }
      break;
    case 'DEVICE_INSTANCE__MULTIPLE_DEVICE_DATASET__ACTIONS': {
      const { payload } = instanceAction;
      const { targetInstances, action } = payload;
      targetInstances.forEach((target) => {
        handleDeviceDatasetActions(action, target);
      });
    }
      break;
    default:
  }
  const result = next(instanceAction);
  return result;
};

export default deviceDatasetMiddleware;
