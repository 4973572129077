/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useTranslation } from 'react-i18next';
import './DriverHint.scss';

const StartupViewDriverHint: React.FC = () : React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="startup-view-driver-hint" data-testid="startup-view-driver-hint">
      <div>{t('STARTUP_VIEW__DRIVER_HINT__NO_DEVICE_FOUND')}</div>
    </div>
  );
};

export default StartupViewDriverHint;
