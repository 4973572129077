/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import React from 'react';
import { PreviewLabelWidth, PreviewUnitWidth, PreviewValueWidth } from '../PreviewLayout';
import PreviewValueVariableControl from '../PreviewValueVariableControl/PreviewValueVariableControl';
import './PreviewLinearizationControl.css';
import PreviewLinearizationTableControl from './PreviewLinearizationTableControl/PreviewLinearizationTableControl';

export interface PreviewLinearizationControlProps {
    identRef: IdentRef;
    deviceInstanceId: string;
    linControl: DeviceModelStatus.UI.LinearizationControlType;
}

const PreviewLinearizationControl: React.FC<PreviewLinearizationControlProps> = (props: PreviewLinearizationControlProps)
: React.ReactElement => {
  const {
    identRef, linControl: lincontrol, deviceInstanceId,
  } = props;

  const createPreviewControl = (elIdentref: string | undefined) => {
    if (elIdentref !== undefined) {
      return (
        <div className="py-1" key={`group-vitem--${identRef}--${elIdentref}`}>
          <PreviewValueVariableControl deviceInstanceId={deviceInstanceId} identRef={elIdentref} />
        </div>
      );
    }
    return <></>;
  };

  const createTable = (elIdentref: string) => (
    <div className="py-1" key={`group-vitem--${identRef}--${elIdentref}`}>
      <div className="linearization_table_container pb-2">
        <div className={`col-${PreviewLabelWidth} m-0 p-0`} />
        <div className={`col-${PreviewValueWidth} m-0 px-1`}>
          <PreviewLinearizationTableControl deviceInstanceId={deviceInstanceId} tableId={elIdentref} numOfPointsId={lincontrol.numberOfPoints} />
        </div>
        <div className={`col-${PreviewUnitWidth} m-0 p-0`}>
          <p />
        </div>
      </div>
    </div>
  );

  const controls:JSX.Element[] = [
    createPreviewControl(lincontrol.numberOfPoints),
    createPreviewControl(lincontrol.minDeviation),
    createPreviewControl(lincontrol.coldJunctionComp0),
    createPreviewControl(lincontrol.coldJunctionComp80),
    createPreviewControl(lincontrol.coldLineEquationB),
    createPreviewControl(lincontrol.coldLineEquationM),
    createTable(lincontrol.userLinData),
  ];

  return <div>{controls}</div>;
};

export default PreviewLinearizationControl;
