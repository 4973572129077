/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { DatasetContext } from '../../../../../views/DatasetContext';
import { useTypedSelector } from '../../../../../store';
import { deviceStatusValueSelector } from '../../../../../store/deviceInstances/store/deviceDataset/selector';

const bcd2dec = (bcd: number) => parseInt(bcd.toString(16), 10);

const valueToHwVersion = (hwValue: number): string => {
  let decValue = 0;
  try {
    decValue = bcd2dec(hwValue);
  } catch {
    decValue = 0;
  }
  return (`0${decValue}`).slice(-2);
};

const useModuleHardwareVersion = (stationInstanceId: string, channelNo: string): string => {
  const { targetDataset } = useContext(DatasetContext);
  const hardwareVarIdentRef = `DEVICE${channelNo}_IFSM_ADDR_HW_VERSION`;
  return useTypedSelector((state) => {
    const statusValue = deviceStatusValueSelector(state.deviceInstances, stationInstanceId, targetDataset, hardwareVarIdentRef);
    return valueToHwVersion(statusValue?.value ?? 0);
  });
};

export default useModuleHardwareVersion;
