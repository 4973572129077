/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownTrend } from '../../DropDownTrend/DropDownTrend';
import { PlayPauseButton } from '../../PlayPauseButton/PlayPauseButton';
import ApexTrendControl from '../ApexTrendControl/ApexTrendControl';
import InfoIcon from '../../../assets/icons/info.svg';
import { ApexTrendData } from '../types';
import './ApexTrendControlContainer.css';

interface TrendItemState {
  id: string;
  label: string;
  checked: boolean;
}

export interface ApexTrendControlContainerProps {
  id: string;
  trendData: ApexTrendData[];
}

const ApexTrendControlContainer: React.FC<ApexTrendControlContainerProps> = (props: ApexTrendControlContainerProps): React.ReactElement => {
  const {
    id,
    trendData,
  } = props;

  const { t } = useTranslation();

  const itemStatesX: TrendItemState[] = trendData.map((s, idx) => ({ id: s.id, label: s.title, checked: idx < 4 }));
  const [itemStates, setItemStates] = useState<TrendItemState[]>(itemStatesX);

  const [buttonState, setButtonState] = useState(false);
  const [trendDataStopped, setTrendDataStopped] = useState<ApexTrendData[]>([]);

  const series = trendData
    .filter((data, idx) => (itemStates[idx].checked))
    .map((data) => ({
      name: data.title,
      color: data.color,
      data: data.series,
      range: data.range,
    }));

  const seriesStopped = trendDataStopped
    .filter((data, idx) => (itemStates[idx].checked))
    .map((data) => ({
      name: data.title,
      color: data.color,
      data: data.series,
      range: data.range,
    }));

  useEffect(() => {
    setTrendDataStopped(trendData);
  }, [buttonState]);

  return (
    <div className="trend-control">
      <div className="trend-control-header">
        <div className="trend-control-dropdown">
          <DropDownTrend
            itemStates={itemStates}
            onChange={(idx) => {
              const itemsChecked = itemStates.filter((item) => item.checked);

              // not allowed: more than 4 curves and less than 1 curve
              if ((itemsChecked.length === 4 && itemStates.find((item) => item.id === idx)?.checked === false) || (itemsChecked.length === 1 && itemStates.find((item) => item.id === idx)?.checked === true)) {
                setItemStates(itemStates);
              } else {
                const newState: TrendItemState[] = itemStates.map((xstate) => (xstate.id === idx
                  ? { id: xstate.id, label: xstate.label, checked: !xstate.checked }
                  : xstate));
                setItemStates(newState);
              }
            }}
          />
        </div>
        <div className="playpausebutton">
          <PlayPauseButton checked={buttonState} onChange={() => { setButtonState(!buttonState); }} />
        </div>
      </div>
      <div className="chart">
        <ApexTrendControl id={id} series={buttonState ? seriesStopped : series} />
      </div>
      <div className="hint">
        <img src={InfoIcon} alt="" className="info-icon" height="12px" />
        <span>{t<string>('TREND_DATA_INFO')}</span>
      </div>
    </div>
  );
};

export default ApexTrendControlContainer;
