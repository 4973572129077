/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import {
  DataTableControl,
} from '../../../components/DataTableControl';
import HDCErrorMemoryTableRow from './TableRow/TableRow';
import { useContextDatasetStatusValue } from '../../../hooks/useContextDataset';
import {
  HDC_ERROR_MEMORY__VAR__LAST_ENTRY,
  HDC_ERROR_MEMORY_MAX_ENTRIES,
} from '../HDCConstants';
import { HDCErrorMemoryTableHeader } from './TableHeader/TableHeader';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';

export const calculateRingPositions = (
  lastEntry: number,
  maxEntriesCount: number,
): number[] => {
  const row1 = [...Array(lastEntry).keys()].map((x) => x + 1).reverse();
  const row2 = [...Array(maxEntriesCount - lastEntry).keys()].map((x) => x + lastEntry + 1).reverse();
  return [...row1, ...row2];
};

export interface HDCErrorMemoryTableProps {
  deviceInstanceId: string;
  targetDataset?: DatasetType;
}

const HDCErrorMemoryTable
: React.FC<HDCErrorMemoryTableProps> = (props: HDCErrorMemoryTableProps):
  React.ReactElement => {
  const { deviceInstanceId, targetDataset } = props;

  const lastEntryValue = useContextDatasetStatusValue(deviceInstanceId, HDC_ERROR_MEMORY__VAR__LAST_ENTRY, targetDataset)?.value ?? 0;
  const lastEntry = lastEntryValue > HDC_ERROR_MEMORY_MAX_ENTRIES || lastEntryValue < 0 ? 0 : lastEntryValue;
  const ringPositions = calculateRingPositions(lastEntry, HDC_ERROR_MEMORY_MAX_ENTRIES);

  const dataTableRows = ringPositions.map((variableIndex, index) => {
    const rowNo = index;
    return (
      <HDCErrorMemoryTableRow
        key={`error-entry-${rowNo}`}
        deviceInstanceId={deviceInstanceId}
        variableIndex={variableIndex}
        targetDataset={targetDataset}
      />
    );
  });

  return (
    <DataTableControl>
      <HDCErrorMemoryTableHeader deviceInstanceId={deviceInstanceId} targetDataset={targetDataset} />
      {dataTableRows}
    </DataTableControl>
  );
};

export default HDCErrorMemoryTable;
