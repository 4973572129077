import { LinearizationTablePoint } from '../../../store/linearizationDataset/types';

export interface LinearizationFile {
  label: string,
  type: string; // 'TC' | 'RTD' | 'UI',
  linearizationData: LinearizationTablePoint[],
  cold80Value: number,
}

export class LoadLinearizationError extends Error {
  public readonly reason: string;

  constructor(message: string, reason: string) {
    super(message);
    this.name = 'LoadLinearizationError';
    this.reason = reason;
  }
}
export const createLinFileContent = async (linData: LinearizationFile): Promise<string> => {
  // The AnalogConf expect , as decimal separator in the file
  const data = linData.linearizationData
    .map((pt) => `${pt.xValue}\r\n${pt.yValue}`.replaceAll('.', ',')).join('\r\n');
  return `UserfileMACX_TUI\r\nTYPE=${linData.type}\r\n${linData.label}\r\n${linData.cold80Value}\r\n${data}\r\n`;
};

export const parseLinearizationFile = async (content: string): Promise<LinearizationFile> => {
  const lines = content.split('\r\n');
  // 0: "UserfileMACX_TUI"
  // 1: "TYPE=RTD"
  // 2: "Lin table"
  // 3: "0"     <-- When type TC, this is 80° Kaltstelle
  // 4: "-200"  <-- Temperature
  // 5: "0"     <-- Widerstand
  // ...
  const parseFloatValue = (line: string): number => Number.parseFloat(line.replace(',', '.'));

  const pts: LinearizationTablePoint[] = [];
  for (let index = 0; index < (lines.length - 4) / 2; index += 1) {
    const xLine = lines[4 + index * 2];
    if (xLine === '') {
      break;
    }
    const xValue = parseFloatValue(lines[4 + index * 2]);
    if (Number.isNaN(xValue)) {
      throw new LoadLinearizationError('LINTABLE__ERROR__CANNOT_PARSE_FLOATVALUE', `${lines[4 + index * 2]}`);
    }
    const yValue = parseFloatValue(lines[4 + index * 2 + 1]);
    if (Number.isNaN(yValue)) {
      throw new LoadLinearizationError('LINTABLE__ERROR__CANNOT_PARSE_FLOATVALUE', `${lines[4 + index * 2 + 1]}`);
    }
    pts.push({ xValue, yValue });
  }

  const sensorTypeLine = lines[1];
  const sensorTypeMatch: RegExpMatchArray | null = sensorTypeLine.match(/^TYPE\s*=\s*(RTD|TC|USR)$/);
  if (sensorTypeMatch === null || sensorTypeMatch.length < 2) {
    throw new LoadLinearizationError('LINTABLE__ERROR__CANNOT_PARSE_SENSORTYPE', `${sensorTypeLine}`);
  }
  const sensorType = sensorTypeMatch[1];

  const cold80line = lines[3];
  const cold80Value = parseFloatValue(cold80line);
  if (Number.isNaN(cold80Value)) {
    throw new LoadLinearizationError('LINTABLE__ERROR__CANNOT_PARSE_80COLDVALUE', `${cold80line}`);
  }

  const label = lines[2];
  return {
    label,
    type: sensorType,
    linearizationData: pts,
    cold80Value,
  };
};
