/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './LoadingSpinner.scss';

export type LoadingSpinnerSize = '' | 'lg' | 'xl' | 'xxl' | 'device-card' | 'splash';

export interface LoadingSpinnerProps {
  size?: LoadingSpinnerSize;
  title?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props: LoadingSpinnerProps):
  React.ReactElement => {
  const {
    size,
    title,
  } = props;
  const cssClass = size === '' || size === undefined ? 'loading-spinner' : `loading-spinner--${size}`;

  return (
    <div className={cssClass}>
      <div />
      <div />
      <div />
      <div />
      { title !== undefined
        && (
        <p className="loading-spinner-title">
          <span>{title}</span>
        </p>
        )}
    </div>
  );
};
