/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingControl } from '../../../../components/LoadingControl';
import { useTypedSelector } from '../../../../store';
import { ioLinkExecuteSystemCommand } from '../../../../store/deviceInstances/middleware/iolink/ioLinkSystemCommand/actions';
import { ioLinkSystemCommandSelector } from '../../../../store/deviceInstances/store/ioLinkSystemCommand/selector';
import { IoLinkSystemCommandStage } from '../../../../store/deviceInstances/store/ioLinkSystemCommand/types';
import './IoLinkSystemCommandDialog.css';

export interface IoLinkSystemCommandProps {
    title: string;
    command: number;
    actionLabel: string;
    // eslint-disable-next-line no-unused-vars
    onClose: (result: boolean) => void;
    deviceInstanceId: string;
}

export const IoLinkSystemCommandDialog:React.FC<IoLinkSystemCommandProps> = (props: IoLinkSystemCommandProps): React.ReactElement => {
  const {
    onClose, title, actionLabel, command,
    deviceInstanceId,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const systemCommandState = useTypedSelector((state) => ioLinkSystemCommandSelector(state.deviceInstances, deviceInstanceId)?.state);

  useEffect(() => {
    switch (systemCommandState) {
      case IoLinkSystemCommandStage.stageDoneSuccess:
        onClose(true);
        break;
      case IoLinkSystemCommandStage.stageDoneFailed:
        onClose(false);
        break;
      default:
        break;
    }
  }, [systemCommandState]);

  let disableButton = true;
  let bodyControl = <LoadingControl title={t<string>(actionLabel)} size="xl" />;
  switch (systemCommandState) {
    case IoLinkSystemCommandStage.stageInitial:
      disableButton = false;
      bodyControl = (
        <div className="iolink-system-command__container__body__message">
          {t('MODAL_DIALOG__ACKNOWLEDGE_ACTION__MESSAGE', { ACTION_NAME: actionLabel })}
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className="iolink-system-command">
      <div className="iolink-system-command__container">
        <div className="iolink-system-command__container__header">
          <div className="iolink-system-command__container__header__text">
            {title}
          </div>
        </div>
        <div className="iolink-system-command__container__body">
          {bodyControl}
        </div>
        <div className="iolink-system-command__container__footer">
          <div className="iolink-system-command__container__footer__buttons">
            <Button
              className="button-text"
              variant="secondary"
              onClick={() => {
                onClose(false);
              }}
              disabled={disableButton}
            >
              {t<string>('STANDARD_BUTTON__ABORT')}
            </Button>
            <Button
              className="button-text"
              variant="primary"
              onClick={() => {
                dispatch(ioLinkExecuteSystemCommand({ targetInstance: deviceInstanceId, command }));
              }}
              disabled={disableButton}
            >
              {t<string>('STANDARD_BUTTON__OK')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModalIoLinkSystemCommandDialog: React.FC<IoLinkSystemCommandProps> = (props:IoLinkSystemCommandProps) => {
  const {
    title, onClose, actionLabel, command, deviceInstanceId,
  } = props;
  const modalRoot = document.getElementById('method-modal-root');
  if (modalRoot === null) {
    return <div />;
  }
  return (
    <>
      {ReactDOM.createPortal(
        <IoLinkSystemCommandDialog
          title={title}
          actionLabel={actionLabel}
          command={command}
          onClose={onClose}
          deviceInstanceId={deviceInstanceId}
        />,
        modalRoot,
      )}
    </>
  );
};
