/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  setLinearizationDatasetKaltstelle,
} from '../../../../store/linearizationDataset/actions';
import { LinTableValue } from '../../../../store/linearizationDataset/types';
import InputBoxControl from '../../../Basic/InputBoxControl/InputBoxControl';
import { TableColumnHeader } from '../TableColumnHeader/TableColumnHeader';
import './TableKaltstelleView.css';

export interface TableKaltstelleViewProps {
    header1: string,
    header2: string,
    items: TableKaltstelleItem[],
}

export interface TableKaltstelleItem {
    value1: LinTableValue;
    value2: LinTableValue;
}

export const TableKaltstelleView:React.FC<TableKaltstelleViewProps> = (props: TableKaltstelleViewProps): React.ReactElement => {
  const { header1, header2, items } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="tableKaltstelleView-container">
      <div className="tableKaltstelleView-container-title">
        <div className="tableKaltstelleView-container-title-label">{t<string>('Kaltstelle')}</div>
      </div>
      <div className="tableKaltstelleView-container-header">
        <TableColumnHeader header1={header1} header2={header2} />
      </div>

      <div className="tableKaltstelleView-container-table">
        {items.map((item, index) => (
          <div
            className="tableKaltstelleView-container-table-element"
            key={`kaltstelleTableElement-${index + 1}`}
          >
            <div className="tableKaltstelleView-container-table-element-label">
              <InputBoxControl
                type="number"
                displayFormat={item.value1.displayFormat}
                value={item.value1.value}
                onChange={() => 0}
                disabled
              />
            </div>
            <div className="tableKaltstelleView-container-table-element-splitter2" />
            <div className="tableKaltstelleView-container-table-element-label">
              <InputBoxControl
                type="number"
                displayFormat={item.value2.displayFormat}
                value={item.value2.value}
                onChange={
                   index !== 1 ? () => 0
                     : (value) => {
                       const parsedValue = parseFloat(value);
                       dispatch(setLinearizationDatasetKaltstelle(parsedValue));
                     }
                    }
                disabled={index !== 1}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="tableKaltstelleView-container_separator-container">
        <div className="tableKaltstelleView-container_separator-container_separator" />
      </div>
    </div>
  );
};
