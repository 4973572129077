/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import { CaparocChannel } from '../CaparocChannel/CaparocChannel';
import './CockpitCaparocModule.css';

export interface CockpitCaparocInactiveModuleProps {
  channels: number[],
}

export const CockpitCaparocInactiveModule: React.FC<CockpitCaparocInactiveModuleProps> = (
  props: CockpitCaparocInactiveModuleProps,
): React.ReactElement => {
  const { channels } = props;
  const { t } = useTranslation();

  const channelControls = channels.map((ch) => (
    <div key={`channel-${ch}`} className="cockpit-caparoc-module-container__channels">
      <CaparocChannel
        channelNumber={ch}
        color="gray"
        nomCurVal={0}
        outCurVal={0}
        inactiveChannel
      />
    </div>
  ));

  return (
    <PaperControl title={t<string>('CAPAROC_STATION_INACTIVE_MODULE_TITLE')}>
      <div className="cockpit-caparoc-module-container">
        {channelControls}
      </div>
    </PaperControl>
  );
};
