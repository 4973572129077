/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { deviceDatasetSelector } from '../deviceInstances/store/dataStorage/selectors';
import { DeviceInstancesState } from '../deviceInstances/types';
import { DatasetState, DatasetType } from '../deviceInstances/store/deviceDataset/types';

export type ApplicationIdentType = 'APP_INITIALDEVICESTARTUP'
    | 'APP_EDITDEVICE'
    | 'APP_REVIEWDEVICE'
    | 'APP_COMPAREDEVICE'
    | 'APP_EXPERTCOMPAREDEVICE'
    | 'APP_REPORTDEVICE'
    | 'APP_COCKPIT'
    | 'APP_POPUPEDITOR'
    | 'APP_ONLINEPARAM'
    | 'APP_DIPSWITCH_WIZARD_CONFIGURATION'
    | 'APP_DIPSWITCH_WIZARD_DISPLAY'
    | 'APP_DIPSWITCH_POPUP_DISPLAY';

export type WizardIdentType = 'WIZARD_INITIALDEVICESTARTUP'
    | 'WIZARD_CHANGEDEVICE'
    | 'WIZARD_CREATEPARAMETERFILE'
    | 'WIZARD_CXEEDITPARAMETERFILE'
    | 'WIZARD_RESTOREDEVICE'
    | 'WIZARD_TRANSFERPARAMETERFILE'
    | 'WIZARD_DEVICESETTINGS'

export const GetWizardReportMenu = (wizardType: WizardIdentType, state?: DatasetState): IdentRef | undefined => {
  const wizardDesc = state?.descriptors[wizardType];

  if (wizardDesc === undefined || wizardDesc.type !== DeviceModelStatus.StatusType.WizardDescriptor
      || (wizardDesc.wizardType.type === DeviceModelStatus.Wizards.WizardType.CREATEPARAMETERFILE
        || wizardDesc.wizardType.type === DeviceModelStatus.Wizards.WizardType.CXEEDITPARAMETERFILE
        || wizardDesc.wizardType.type === DeviceModelStatus.Wizards.WizardType.CONTACTRON_STATION_COMMISSIONING)) {
    return undefined;
  }

  return wizardDesc.wizardType.report;
};

export const wizardReportMenuSelector = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, wizardType: WizardIdentType): IdentRef | undefined => {
  const dataset = deviceDatasetSelector(state, deviceInstanceId);
  return GetWizardReportMenu(wizardType, dataset?.[targetDataset]);
};

export const GetWizardPreviewMenu = (state: DatasetState, wizardType: WizardIdentType): IdentRef | undefined => {
  const wizardDesc = state?.descriptors[wizardType];

  if (wizardDesc === undefined || wizardDesc.type !== DeviceModelStatus.StatusType.WizardDescriptor
    || wizardDesc.wizardType.type === DeviceModelStatus.Wizards.WizardType.INITIALDEVICESTARTUP
    || wizardDesc.wizardType.type === DeviceModelStatus.Wizards.WizardType.CONTACTRON_STATION_COMMISSIONING) {
    return undefined;
  }

  return wizardDesc.wizardType.preview;
};

export const wizardPreviewMenuSelector = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, wizardType: WizardIdentType): IdentRef | undefined => {
  const dataset = deviceDatasetSelector(state, deviceInstanceId);
  if (dataset === undefined) {
    return undefined;
  }
  return GetWizardPreviewMenu(dataset[targetDataset], wizardType);
};

export const GetApplicationMenu = (appIdent: ApplicationIdentType, state?: DatasetState): IdentRef | undefined => {
  const appDesc = state?.descriptors[appIdent];
  if (appDesc === undefined || appDesc.type !== DeviceModelStatus.StatusType.ApplicationDescriptor) {
    return undefined;
  }
  return appDesc.appType.menu;
};

export const appDescriptionSelector = (appIdent: ApplicationIdentType, state?: DatasetState): DeviceModelStatus.ApplicationDescriptor | undefined => {
  const appDesc = state?.descriptors[appIdent];
  if (appDesc === undefined || appDesc.type !== DeviceModelStatus.StatusType.ApplicationDescriptor) {
    return undefined;
  }
  return appDesc;
};

export const appStructureSelector = (appIdent: ApplicationIdentType, state?: DatasetState): DeviceModelStatus.DeviceModelDescriptor | undefined => {
  const appDesc = state?.descriptors[appIdent];
  if (appDesc === undefined || appDesc.type !== DeviceModelStatus.StatusType.ApplicationDescriptor) {
    return undefined;
  }
  return state?.descriptors[appDesc.appType.menu];
};
