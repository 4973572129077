/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { UnsupportedControl } from '../../../../components/UnsupportedControl';
import { CompareStatusValueProps } from '../../CompareTypes';
import { useTypedSelector } from '../../../../store';
import CompareDateStatusValue from './CompareDateStatusValue/CompareDateStatusValue';
import CompareEnumStatusValue from './CompareEnumStatusValue/CompareEnumStatusValue';
import CompareFloatStatusValue from './CompareFloatStatusValue/CompareFloatStatusValue';
import CompareIntegerStatusValue from './CompareIntegerStatusValue/CompareIntegerStatusValue';
import CompareUnsignedStatusValue from './CompareIntegerStatusValue/CompareUnsignedStatusValue';
import CompareIPV4AddressStatusValue from './CompareIPV4AddressStatusValue/CompareIPV4AddressStatusValue';
import CompareMACAddressStatusValue from './CompareMACAddressStatusValue/CompareMACAddressStatusValue';
import CompareStringStatusValue from './CompareStringStatusValue/CompareStringStatusValue';
import CompareTableStatusValue from './CompareTableStatusValue/CompareTableStatusValue';
import { deviceDescriptorSelector } from '../../../../store/deviceInstances/store/deviceDataset/selector';

type ComponentMap = { [key: string]: React.ComponentType<CompareStatusValueProps> };
const statusValueControls: ComponentMap = {
  [DeviceModelStatus.StatusDescriptorValueType.INTEGER]: CompareIntegerStatusValue,
  [DeviceModelStatus.StatusDescriptorValueType.FLOAT]: CompareFloatStatusValue,
  [DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER]: CompareUnsignedStatusValue,
  [DeviceModelStatus.StatusDescriptorValueType.DATE]: CompareDateStatusValue,
  [DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR]: CompareEnumStatusValue,
  [DeviceModelStatus.StatusDescriptorValueType.STRING]: CompareStringStatusValue,
  [DeviceModelStatus.StatusDescriptorValueType.IPV4_ADDRESS]: CompareIPV4AddressStatusValue,
  [DeviceModelStatus.StatusDescriptorValueType.MAC_ADDRESS]: CompareMACAddressStatusValue,
  [DeviceModelStatus.StatusDescriptorValueType.TABLE]: CompareTableStatusValue,
};

/**
 * Compare two values from Dataset Value : value vs externalValue
 * backupValue used as storage for value before modification
 *
 * @param props Control Properties
 * @returns
 */
const CompareStatusValue
: React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
  : React.ReactElement => {
  const {
    parentId,
    identRef,
    leftDataset, rightDataset,
    compareMode,
    onShowContentHelp, onHideContentHelp,
    onMidButtonClick,
    showImageAndSign,
    deviceInstanceId,
  } = props;

  const valueType = useTypedSelector((state) => {
    const desc = deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, leftDataset, identRef);
    if (desc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return undefined;
    }
    return desc.valueType.type;
  });

  if (valueType === undefined || statusValueControls[valueType] === undefined) {
    return <UnsupportedControl text={`CompareStatusValue: unknown value's type [${valueType ?? 'undefined'}] / [${identRef}]`} />;
  }

  const C = statusValueControls[valueType];
  return (
    <C
      parentId={`statusValue--${parentId}`}
      identRef={identRef}
      deviceInstanceId={deviceInstanceId}
      leftDataset={leftDataset}
      rightDataset={rightDataset}
      onHideContentHelp={onHideContentHelp}
      onShowContentHelp={onShowContentHelp}
      compareMode={compareMode}
      onMidButtonClick={onMidButtonClick}
      showImageAndSign={showImageAndSign}
    />
  );
};

export default CompareStatusValue;
