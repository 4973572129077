/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useCallback, useEffect, useState } from 'react';
import { IdentRef } from '@gpt/commons';
import { StructureItemState, StructureItemStateChangeCallback, StructureItems } from '../../ReduxControlProps';

const useMenuTabState = (
  actionIdentRef: string,
  onStateChanged: StructureItemStateChangeCallback,
) => {
  const [childTabState, setChildTabState] = useState<StructureItems>({});

  useEffect(() => {
    const ismodified = Object.keys(childTabState).find((key) => childTabState[key].modified);
    const iserror = Object.keys(childTabState).find((key) => childTabState[key].error);
    onStateChanged(actionIdentRef, {
      error: iserror !== undefined,
      modified: ismodified !== undefined,
    });
  }, [childTabState, actionIdentRef]);

  useEffect(() => {
    const destroy = () => {
      onStateChanged(actionIdentRef, {
        error: false,
        modified: false,
      });
    };

    return destroy;
  }, []);

  const setTabState = useCallback((identRef: IdentRef, state: StructureItemState) => {
    setChildTabState((prev) => ((prev[identRef] === undefined) ? {
      ...prev,
      [identRef]: state,
    } : {
      ...prev,
      [identRef]: {
        ...prev[identRef],
        modified: state.modified,
        error: state.error,
      },
    }));
  }, []);

  return [childTabState, setTabState] as const;
};

export default useMenuTabState;
