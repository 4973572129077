/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../store/reduxStore';
import CreateBackupComponent from '../../../components/CreateBackupComponent/CreateBackupComponent';
import { deviceWizardUpdateButtons } from '../../../store';
import { ButtonState, ButtonRole } from '../../../store/deviceWizard/types';
import PreviewValueDatasetReduxControl from '../../../controls/PreviewValueDatasetReduxControl/PreviewValueDatasetReduxControl';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';

export interface TPFilePageCreateBackupProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const TPFilePageCreateBackup: React.FC<TPFilePageCreateBackupProps> = (props: TPFilePageCreateBackupProps)
  : React.ReactElement => {
  const { t } = useTranslation();
  const { active, previewMenu, deviceInstanceId } = props;

  const dispatch = useDispatch();
  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId)?.device);

  useEffect(() => {
    if (active !== true) {
      return;
    }

    dispatch(deviceWizardUpdateButtons({
      abort: ButtonState.enabled,
      back: ButtonState.enabled,
      next: activeDevice === undefined ? ButtonState.disabled : ButtonState.enabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.next,
      focusPcta: activeDevice === undefined ? undefined : ButtonRole.next,
    }));
  }, [activeDevice, active]);

  const previewDataset = (previewMenu === undefined)
    ? <div />
    : (
      <PreviewValueDatasetReduxControl
        caption={t<string>('CREATE_BACKUP__DATASET_PREVIEW')}
        identRef={previewMenu}
        deviceInstanceId={deviceInstanceId}
      />
    );

  return (
    <Container className="w-100 h-100 pb-4" fluid>
      <Row className="h-100">
        <Col xs={6}>
          <CreateBackupComponent deviceInstanceId={deviceInstanceId} />
        </Col>
        <Col className="preview-control--frame-padding h-100" xs={6}>
          {previewDataset}
        </Col>
      </Row>
    </Container>
  );
};

export default TPFilePageCreateBackup;
