/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';
import useSPortWriteParameter from '../../SPort/hooks/useSPortWriteParameter';

export interface HDCErrorMemoryButtonProps {
  deviceInstanceId: string;
}

export const HDCErrorMemoryButton
: React.FC<HDCErrorMemoryButtonProps> = (props: HDCErrorMemoryButtonProps):
  React.ReactElement => {
  const { t } = useTranslation();
  const { deviceInstanceId } = props;
  const [cleanErrorTableState, clearErrorTable] = useSPortWriteParameter(deviceInstanceId, 'hdc-error-memory-clean-error-table--action');

  return (
    <Button
      className="button-text"
      variant="secondary-small"
      onClick={() => {
        clearErrorTable(0x7831, new Uint8Array([0x00, 0x10]));
      }}
      disabled={
        cleanErrorTableState === MethodStageExecutionStatus.InProgress
        || cleanErrorTableState === MethodStageExecutionStatus.RequestAcceptData
        || cleanErrorTableState === MethodStageExecutionStatus.RequestPassword
      }
    >
      {t<string>('STANDARD_BUTTON__CLEAR')}
    </Button>
  );
};
