/* eslint-disable no-bitwise */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, {
  useCallback,
  useContext, useEffect, useState,
} from 'react';
import { IdentRef, Services } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ParameterControl } from '../../../components/ParameterEditor';
import CheckboxEditorControl from '../../../components/Basic/CheckboxEditorControl/CheckboxEditorControl';
import Quint4DigitalEnablelDropDown from './DropDown/DigitalEnablelDropDown/DigitalEnablelDropDown';
import Quint4DigitalResultDropDown from './DropDown/DigitalResultDropDown/DigitalResultDropDown';
import Quint4OutputBlockSwitch from './OutputBlock/OutputBlockSwitch';
import Quint4DigitalSelectlDropDown from './DropDown/DigitalSelectlDropDown/DigitalSelectlDropDown';
import {
  Quint4DigitalSignalBlock,
} from './types';
import { writeActiveDeviceVariableValues } from '../../../store';
import { DatasetContext } from '../../../views/DatasetContext';
import {
  DROPDOWN__ITEMID3__CURRENT_OUT,
} from './DropDown/DropDownHelpers';
import { StructureItemState, StructureItemStateChangeCallback, StructureItems } from '../../ReduxControlProps';
import { useContextDatasetStatusValue } from '../../../hooks/useContextDataset';
import { StateUpdateCallback, transformMenuItemState } from '../../hooks/useMenuItemStateUpdate';
import './Quint4DigitalSignal.scss';

export interface Quint4DigitalSignalProps {
  identRef: string;
  deviceInstanceId: string;
  blockVariables: Quint4DigitalSignalBlock;
  showContextMessageDef?: boolean;
  onStateChanged: StructureItemStateChangeCallback;
}

const createWriteVariableValues = (
  enableDropValue: number,
  backupEnableDropValue: number | undefined,
  connectDropValue: number,
  backupConnectDropValue: number | undefined,
  blockVariables: Quint4DigitalSignalBlock,
): Services.DeviceModel.StatusValueRef[] => {
  const outUseINotP = (enableDropValue === DROPDOWN__ITEMID3__CURRENT_OUT || connectDropValue === DROPDOWN__ITEMID3__CURRENT_OUT) ? 1 : 0;

  return [
    {
      value: enableDropValue,
      backupValue: backupEnableDropValue,
      identRef: blockVariables.outCombiEnableDropValue,
    },
    {
      value: connectDropValue,
      backupValue: backupConnectDropValue,
      identRef: blockVariables.outCombiConnectDropValue,
    },
    {
      value: outUseINotP,
      backupValue: undefined,
      identRef: blockVariables.outUseINotP,
    },
  ];
};

export const useQuint4DigitalSignalStateUpdate = (showConnectBlock: boolean, block: Quint4DigitalSignalBlock) => {
  const [childrenState, setChildrenState] = useState<StructureItems>({});

  // eslint-disable-next-line max-len
  const updateMenuItemState: StateUpdateCallback = useCallback((ident: IdentRef, state: StructureItemState) => {
    setChildrenState((prev) => transformMenuItemState(prev, ident, state));
  }, []);

  let stateCheck = [
    block.outCombiConnectDropValue,
    block.outCombiEnableDropValue,
    block.outCombiInvert,
    `${block.blockIdent}-1`,
  ];

  if (showConnectBlock) {
    stateCheck = [
      ...stateCheck,
      `${block.blockIdent}-2`,
    ];
  }

  const mod = stateCheck.find((vr) => childrenState[vr]?.modified === true) !== undefined;
  const iserror = stateCheck.find((vr) => childrenState[vr]?.error === true) !== undefined;
  return [mod, iserror, updateMenuItemState] as const;
};

export const Quint4DigitalSignalControl: React.FC<Quint4DigitalSignalProps> = (props: Quint4DigitalSignalProps)
  : React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
    blockVariables,
    showContextMessageDef,
    onStateChanged,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);
  const [manualAndSwitch, setManualAndSwitch] = useState(false);

  const combiEnableDropStatusValue = useContextDatasetStatusValue(deviceInstanceId, blockVariables.outCombiEnableDropValue, targetDataset);
  const combiConnectDropStatusValue = useContextDatasetStatusValue(deviceInstanceId, blockVariables.outCombiConnectDropValue, targetDataset);
  const resultDropDownValue = useContextDatasetStatusValue(deviceInstanceId, blockVariables.outCombiInvert, targetDataset);
  const valueANDSwitch = combiConnectDropStatusValue?.value !== 0 || manualAndSwitch;

  const [modified, error, onBlockStatusChange] = useQuint4DigitalSignalStateUpdate(valueANDSwitch, blockVariables);

  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, {
      error: error ?? false,
      modified: modified ?? false,
    });
  }, [error, modified, identRef]);

  if (combiEnableDropStatusValue?.value === undefined || combiConnectDropStatusValue?.value === undefined) {
    return <></>;
  }

  const connectBlock = valueANDSwitch
    ? (
      <>
        <Quint4DigitalSelectlDropDown
          identRef={blockVariables.outCombiConnectDropValue}
          deviceInstanceId={deviceInstanceId}
          onStateChanged={onBlockStatusChange}
          onValueChange={(value) => {
            const variableList = createWriteVariableValues(
              combiEnableDropStatusValue.value,
              combiEnableDropStatusValue.backupValue,
              value,
              combiConnectDropStatusValue.backupValue,
              blockVariables,
            );
            dispatch(writeActiveDeviceVariableValues(deviceInstanceId, variableList));
          }}
          showContextMessageDef={showContextMessageDef}
          enableDropDownValue={combiEnableDropStatusValue.value}
        />
        <Quint4OutputBlockSwitch
          blockIdent={`${blockVariables.blockIdent}-2`}
          deviceInstanceId={deviceInstanceId}
          switchValue={combiConnectDropStatusValue.value}
          block={blockVariables}
          showContextMessageDef={showContextMessageDef}
          onStateChanged={onBlockStatusChange}
        />
      </>
    )
    : <></>;

  return (
    <div className="quint4-digital-signal">
      <Quint4DigitalEnablelDropDown
        identRef={blockVariables.outCombiEnableDropValue}
        deviceInstanceId={deviceInstanceId}
        onStateChanged={onBlockStatusChange}
        onValueChange={(value) => {
          const variableList = createWriteVariableValues(
            value,
            combiEnableDropStatusValue.backupValue,
            combiConnectDropStatusValue.value,
            combiConnectDropStatusValue.backupValue,
            blockVariables,
          );
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, variableList));
        }}
        showContextMessageDef={showContextMessageDef}
        connectDropDownValue={combiConnectDropStatusValue.value}
      />
      <Quint4OutputBlockSwitch
        blockIdent={`${blockVariables.blockIdent}-1`}
        deviceInstanceId={deviceInstanceId}
        switchValue={combiEnableDropStatusValue.value}
        block={blockVariables}
        showContextMessageDef={showContextMessageDef}
        onStateChanged={onBlockStatusChange}
      />
      <ParameterControl label={t('QUINT4_PS:locLogicalLinkOptLbl') ?? ''}>
        <CheckboxEditorControl
          label="QUINT4_PS:locLogicalLinkOptLbl"
          help="QUINT4_PS:locLogicalLinkOptHlp"
          id={`quint4-block--${identRef}-checkbox`}
          value={valueANDSwitch}
          onChange={(switchValue) => {
            setManualAndSwitch(switchValue);
            const variableList = createWriteVariableValues(
              combiEnableDropStatusValue.value,
              combiEnableDropStatusValue.backupValue,
              0,
              combiConnectDropStatusValue.backupValue,
              blockVariables,
            );
            dispatch(writeActiveDeviceVariableValues(deviceInstanceId, variableList));
          }}
          readonly={false}
        />
      </ParameterControl>
      {connectBlock}
      <Quint4DigitalResultDropDown
        identRef={blockVariables.outCombiInvert}
        deviceInstanceId={deviceInstanceId}
        onStateChanged={onBlockStatusChange}
        onValueChange={(value) => {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            value,
            backupValue: resultDropDownValue?.backupValue,
            identRef: blockVariables.outCombiInvert,
          }]));
        }}
        showContextMessageDef={showContextMessageDef}
      />
    </div>
  );
};
