/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import {
  polarToCartesian, switchCenterX, switchCenterY, switchRadius,
} from '../../types';

export interface SVGCurrentValueProps {
  switchPositionAngle: number;
}

const createPonterPath = (centerX: number, centerY: number, radius: number, angle: number): React.ReactElement => {
  const dr = 20;
  const start = polarToCartesian(0, 0, radius, -dr);
  const end = polarToCartesian(0, 0, radius, dr);
  const center = polarToCartesian(0, 0, radius / 2, 0);

  const path = [
    'M', start.x, start.y,
    'A', radius, radius, 0, 0, 1, end.x, end.y,
    'L', center.x, center.y,
  ].join(' ');

  return (
    <g fill="black">
      <path d={path} transform={`translate(${centerX} ${centerY}) rotate(${angle} 0 0)`} />
    </g>
  );
};

const SVGRotarySwitchPosition: React.FC<SVGCurrentValueProps> = (props: SVGCurrentValueProps):
  React.ReactElement | null => {
  const {
    switchPositionAngle,
  } = props;

  const pointer = createPonterPath(
    switchCenterX,
    switchCenterY,
    switchRadius,
    switchPositionAngle,
  );

  return (
    <g>
      <g fill="black" strokeWidth={5}>
        <line
          stroke="black"
          x1={-switchRadius}
          y1={0}
          x2={switchRadius}
          y2={0}
          transform={`translate(${switchCenterX} ${switchCenterY}) rotate(${switchPositionAngle} 0 0)`}
        />
      </g>
      {pointer}
    </g>
  );
};

export default SVGRotarySwitchPosition;
