/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { useTypedSelector } from '../../../store';

// eslint-disable-next-line import/prefer-default-export
export const useStationTopology = (targetInstance: string) => {
  const discovery = useTypedSelector((state) => {
    const instance = state.deviceInstances.instances[targetInstance];
    return instance?.station.discovery;
  });
  return discovery ?? [];
};
