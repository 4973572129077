/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '../../../../store';
import { deviceInstanceActiveDeviceInstance } from '../../../../store/deviceInstances/store/activeDevice/selectors';

// eslint-disable-next-line no-unused-vars
export enum selectedWizardType {
  // eslint-disable-next-line no-unused-vars
  select = 'select',
  // eslint-disable-next-line no-unused-vars
  software = 'software',
  // eslint-disable-next-line no-unused-vars
  dipswitch = 'dipswitch',
  // eslint-disable-next-line no-unused-vars
  none = 'none',
}

const useEditParameterWizardMode = (deviceInstanceId: string) => {
  const wizardList = useTypedSelector(
    (state) => (deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId)?.device?.catalog.properties.supportedWizard ?? []).join(';'),
  );
  const [mode, setMode] = useState<selectedWizardType>(selectedWizardType.none);

  useEffect(() => {
    const supportedWizard = wizardList.split(';');
    const supportSoftwareParam = supportedWizard.find((wiz) => wiz === 'CXE_FIRMWARE_PARAMETER') !== undefined;
    const supportDipSwitchParam = supportedWizard.find((wiz) => wiz === 'CXE_HARDWARE_SWITCH') !== undefined;
    if (supportSoftwareParam && supportDipSwitchParam) {
      setMode(selectedWizardType.select);
    } else if (supportDipSwitchParam) {
      setMode(selectedWizardType.dipswitch);
    } else if (supportSoftwareParam) {
      setMode(selectedWizardType.software);
    } else {
      setMode(selectedWizardType.none);
    }
  }, [wizardList]);
  return [mode, setMode] as const;
};

export default useEditParameterWizardMode;
