/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../store';
import { ProgressBar } from '../../../../../../components/ProgressBar/ProgressBar';
import './SelectionProgressBar.scss';

const convertToPercentage = (selectedCount: number, maxCount: number)
    : number => (selectedCount > maxCount ? 100 : (selectedCount / maxCount) * 100);

export interface SelectionProgressBarProps {
  kind: 'input' | 'output';
  maxIoCount: number;
  label: string;
}

export const SelectionProgressBar:
React.FC<SelectionProgressBarProps> = (props: SelectionProgressBarProps):
React.ReactElement => {
  const { maxIoCount, label, kind } = props;

  const { t } = useTranslation();
  const totalIOSelected = useTypedSelector(
    (state) => (kind === 'input'
      ? state.contactronInitWizard.totalInputSelected
      : state.contactronInitWizard.totalOutputSelected),
  );

  const percent = convertToPercentage(totalIOSelected, maxIoCount);

  return (
    <div className="selectprocessdataparent-progressbar">
      <ProgressBar percent={percent} />
      <div className="selectprocessdataparent-progressbar-text">
        {t(label, {
          COUNTER: totalIOSelected,
          MAX_NUMBER: maxIoCount,
        })}
      </div>
    </div>
  );
};
