/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { DatasetContext } from '../../../../../views/DatasetContext';
import { deviceStatusValueSelector } from '../../../../../store/deviceInstances/store/deviceDataset/selector';
import { useTypedSelector } from '../../../../../store';

const bcd2dec = (bcd: number) => parseInt(bcd.toString(16), 10);

const valueToFwVersion = (fwValue: number): string => {
  let res = '0';
  try {
    const decValue = bcd2dec(fwValue);
    const frValue = decValue / 1000.0;
    res = frValue.toFixed(2);
  } catch {
    res = '0';
  }
  return res;
};

const useModuleFirmwareVersion = (stationInstanceId: string, channelNo: string): string => {
  const { targetDataset } = useContext(DatasetContext);

  const firmwareVarIdentRef = `DEVICE${channelNo}_IFSM_ADDR_FW_VERSION`;
  return useTypedSelector((state) => {
    const statusValue = deviceStatusValueSelector(state.deviceInstances, stationInstanceId, targetDataset, firmwareVarIdentRef);
    return valueToFwVersion(statusValue?.value ?? 0);
  });
};

export default useModuleFirmwareVersion;
