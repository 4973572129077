/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';

export const SHOW_APPLICATION_MESSAGE = 'SHOW_APPLICATION_MESSAGE';
export const REMOVE_APPLICATION_MESSAGE = 'REMOVE_APPLICATION_MESSAGE';
export const ADD_APPLICATION_MESSAGE = 'ADD_APPLICATION_MESSAGE';
export const CLEANUP_APPLICATION_MESSAGES = 'CLEANUP_APPLICATION_MESSAGES';

export type MessageType = 'error' | 'warning' | 'success';

export interface ApplicationMessage {
    type: MessageType,
    id: string,
    messageId: string,
    show: boolean;
}

export interface ApplicationMessageState {
    messageList: ApplicationMessage[],
}

export interface ApplicationMessagePayload {
    type: MessageType,
    id: string,
    messageId: string,
}

export interface ShowApplicationMessageAction extends Action<typeof SHOW_APPLICATION_MESSAGE> {
    payload: ApplicationMessagePayload;
}

export interface AddApplicationMessageAction extends Action<typeof ADD_APPLICATION_MESSAGE> {
    payload: ApplicationMessagePayload;
}

export interface RemoveApplicationMessageAction extends Action<typeof REMOVE_APPLICATION_MESSAGE> {
    payload: string,
}

export type CleanupApplicationMessagesAction = Action<typeof CLEANUP_APPLICATION_MESSAGES>;

export type ApplicationMessageAction =
ShowApplicationMessageAction
| RemoveApplicationMessageAction
| AddApplicationMessageAction
| CleanupApplicationMessagesAction;
