/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import DisplayFormat from '../../../helpers/displayFormat';
import useDelayedUserInput from '../../../controls/hooks/useDelayedUserInput';
import './InputBoxControl.scss';

export interface InputBoxControlProps {
    type: string;
    value: string | number;
    displayFormat: string;
    id?: string;
    minValue?: number;
    maxValue?: number;
    step?: number;
    warning?: string;
    readonly?: boolean;
    disabled?: boolean;
    edited?: boolean;
    maxLength?: number;
    placeholder?: string;
    autofocus?: boolean;
    onChange: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    inputDelay?: number;
}

const InputBoxControl: React.FC<InputBoxControlProps> = (props) => {
  const {
    type, value, displayFormat,
    minValue, maxValue, step,
    warning, id,
    readonly, disabled,
    edited, maxLength, placeholder, autofocus,
    onChange, onBlur, onFocus, inputDelay,
  } = props;
  const [focusActive, setFocusActive] = useState(false);
  const [
    userInputValue,
    currentWarning,
    setUserInputValue,
    onBlurValue,
  ] = useDelayedUserInput(value, warning, onChange, inputDelay);

  const classNameEditedText = (edited !== undefined && edited === true) ? 'textfield__input--edited' : '';

  const currentValue = userInputValue ?? value;
  const curValue = focusActive ? currentValue : DisplayFormat(displayFormat, currentValue);

  return (
    <div className="inputbox-control">
      <div className="input-field">
        <input
          id={id}
          type={type}
          value={curValue}
          min={minValue}
          max={maxValue}
          step={step}
          className={`textfield__input ${classNameEditedText} ${currentWarning !== undefined ? 'invalid' : ''}`}
          readOnly={readonly}
          disabled={disabled}
          onChange={(e) => {
            setUserInputValue(e.target.value);
          }}
          onFocus={() => {
            setFocusActive(true);
            onFocus?.();
          }}
          onBlur={() => {
            setFocusActive(false);
            onBlurValue(currentValue);
            onBlur?.();
          }}
          maxLength={maxLength}
          placeholder={placeholder}
          data-testid="input-box-control-input"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autofocus}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="textfield__label">{}</label>
        {/* <span className="helper-text" data-error={currentWarning} /> */}
        <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
          <div className="input-field-control__warning-text">{currentWarning}</div>
        </div>
      </div>
    </div>
  );
};

export default InputBoxControl;
