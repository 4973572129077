/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, {
  useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { compensateZooming } from '../../../../helpers/functions';
import AppSystemService from '../../../../services/AppService/AppSystemService';
import { ZoomControl } from '../../../ZoomControl/ZoomControl';
import { setApplicationZoomLevel } from '../BackstagePageServices';

const BackstageZoomSettings:React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const [zoomLevel, setZoomLevel] = useState(100);

  useEffect(() => AppSystemService.onZoomChange((zoom) => setZoomLevel(Math.round(zoom))), []);

  return (
    <>
      <br />
      {`${t<string>('BACKSTAGE_PAGE_SETTINGS__ZOOM_LEVEL')}`}
      <br />
      <ZoomControl
        key="zoom-control"
        currentValue={zoomLevel}
        changeZoomFactor={(value) => {
          setApplicationZoomLevel(value / 100)
            .then(() => compensateZooming(value / 100));
        }}
        tooltip={t<string>('BACKSTAGE_PAGE_SETTINGS__ZOOM_HINT')}
      />
    </>
  );
};

export default BackstageZoomSettings;
