/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './LedLinkControl.css';

export interface LedLinkControlProps {
  title: string;
  color: string;
  link?: string;
  blink?: string; // 1-4Hz, 2-8Hz
}

// eslint-disable-next-line max-len
export const LedLinkControl: React.FC<LedLinkControlProps> = (props: LedLinkControlProps): React.ReactElement => {
  const {
    color, title, link, blink,
  } = props;

  const ledClass = `link-led-container_icon-content link-led-container_icon-content-${color}`;
  const ledclasses = blink === undefined ? ledClass : `${ledClass} devicestatusled-blink-${blink}`;

  const titleClass = 'led-link-container_label';
  const titleClasses = link === undefined ? titleClass : `${titleClass} led-link-container_label-color`;

  return (
    <div className="led-link-container">
      <div className="led-link-container_icon">
        <div className={ledclasses} />
      </div>
      <div className={titleClasses}>
        {title}
      </div>
    </div>
  );
};
