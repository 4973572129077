/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { CaparocChannelProps } from '../CockpitCaparocStationHook';
import { CaparocChannel } from '../CaparocChannel/CaparocChannel';
import { ChannelColorType, calculateChannelColor } from '../CaparocHelper';
import { CaparocPaperControl } from './CaparocPaperControl/CaparocPaperControl';
import './CockpitCaparocModule.css';

export interface CockpitCaparocModuleProps {
  channels: CaparocChannelProps[],
  addr: number,
  rightBreak?: boolean,
  leftBreak?: boolean,
}

interface ChannelProps extends CaparocChannelProps {
  color: ChannelColorType;
}

export const CockpitCaparocModule: React.FC<CockpitCaparocModuleProps> = (
  props: CockpitCaparocModuleProps,
): React.ReactElement => {
  const {
    channels, addr, leftBreak, rightBreak,
  } = props;
  const { t } = useTranslation();

  const channelProps: ChannelProps[] = channels.map((ch) => {
    const color = calculateChannelColor(
      ch.channelStatus,
      ch.errorStatus,
      ch.nominalCurrent,
      ch.actualCurrent,
    );

    return ({
      color,
      ...ch,
    });
  });

  const moduleColor: ChannelColorType = channelProps
    .reduce((color, ch) => {
      if (color === 'red') {
        return color;
      }
      if (color === 'yellow' && ch.color === 'red') {
        return ch.color;
      }
      if (color === 'green' && (ch.color === 'yellow' || ch.color === 'red')) {
        return ch.color;
      }
      if (color === 'gray' && (ch.color === 'green' || ch.color === 'yellow' || ch.color === 'red')) {
        return ch.color;
      }
      return color;
    }, 'gray' as ChannelColorType);

  const channelControls = channelProps.map((ch) => (
    <div key={`caparoc-channel-${ch.moduleAddr}-${ch.channelNo}`} className="cockpit-caparoc-module-container__channels">
      <CaparocChannel
        channelNumber={ch.channelNo}
        color={ch.color}
        nomCurVal={ch.nominalCurrent}
        outCurVal={ch.actualCurrent}
      />
    </div>
  ));

  return (
    <CaparocPaperControl title={`${t('MODULE')} ${addr}`} leftBreak={leftBreak} rightBreak={rightBreak} color={moduleColor}>
      <div className="cockpit-caparoc-module-container">
        {channelControls}
      </div>
    </CaparocPaperControl>
  );
};
