/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceInstancesState } from '../../types';
import { DeviceDatasetState } from '../deviceDataset';
import { DatasetState, DatasetType } from '../deviceDataset/types';

// eslint-disable-next-line import/prefer-default-export
export const deviceDatasetSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
): DeviceDatasetState | undefined => {
  if (deviceInstanceId === undefined) {
    return undefined;
  }
  return state.instances[deviceInstanceId]?.deviceDataset;
};

export const deviceTargetDatasetSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
  targetDataset: DatasetType,
): DatasetState | undefined => {
  if (deviceInstanceId === undefined) {
    return undefined;
  }
  return state.instances[deviceInstanceId]?.deviceDataset[targetDataset];
};

export const deviceDataStorageValueSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
  propName: string,
): string | undefined => {
  if (deviceInstanceId === undefined) {
    return undefined;
  }
  return state.instances[deviceInstanceId]?.dataStorage.values[propName];
};
