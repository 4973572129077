/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { DeviceModelStatus, Services } from '@gpt/commons';
import { DeviceInstanceActionPayload } from '../../common';

export const METHOD_EXECUTION__SET_EXECUTION_STATE = 'METHOD_EXECUTION__SET_EXECUTION_STATE';
export const METHOD_EXECUTION__CLEANUP = 'METHOD_EXECUTION__CLEANUP';
export const DEVICE_INSTANCE__METHOD_EXECUTION__ACTIONS = 'DEVICE_INSTANCE__METHOD_EXECUTION__ACTIONS';

/* ************************************************************************************ */
/* INTERFACE                                                                            */
/* ************************************************************************************ */
export enum MethodStageExecutionStatus {
  // eslint-disable-next-line no-unused-vars
  Initialize = 'init',
  // eslint-disable-next-line no-unused-vars
  RequestPassword = 'request-password',
  // eslint-disable-next-line no-unused-vars
  RequestAcceptData = 'request-accept-data',
  // eslint-disable-next-line no-unused-vars
  DoneSuccess = 'done-success',
  // eslint-disable-next-line no-unused-vars
  DoneFailed = 'done-failed',
  // eslint-disable-next-line no-unused-vars
  InProgress = 'in-progress',
}

export interface DeviceMethodExecutionStep {
  title: DeviceModelStatus.LocString;
  message?: string;
  state: Services.DeviceModel.MethodStepExecutionState;
}

export interface DeviceMethodExecutionStatus {
  steps: DeviceMethodExecutionStep[];
  stage: MethodStageExecutionStatus;
  message?: string;
  data?: Services.DeviceModel.DeviceResponseValueRef[];
  acceptData?: Services.DeviceModel.MethodAcceptDatasetValue[];
}

export interface DeviceMethodState {
  [key: string]: DeviceMethodExecutionStatus;
}

export interface CleanDeviceMethodExecutionState extends Action<typeof METHOD_EXECUTION__CLEANUP> {
  payload: DeviceModelStatus.MethodIdent;
}

export interface SetDeviceMethodExecutionStatePayload {
  methodIdent: string;
  steps?: DeviceMethodExecutionStep[];
  stage?: MethodStageExecutionStatus;
  data?: Services.DeviceModel.DeviceResponseValueRef[];
  acceptData?: Services.DeviceModel.MethodAcceptDatasetValue[];
  message?: string;
}

export interface SetDeviceMethodExecutionStateAction extends Action<typeof METHOD_EXECUTION__SET_EXECUTION_STATE> {
  payload: SetDeviceMethodExecutionStatePayload;
}

export type typeDeviceMethodActions = CleanDeviceMethodExecutionState
| SetDeviceMethodExecutionStateAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceDataStorageAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceDeviceMethodExecutionPayload extends DeviceInstanceActionPayload {
  action: typeDeviceMethodActions;
}

export interface typeDeviceInstanceDeviceMethodExecutionAction extends Action<typeof DEVICE_INSTANCE__METHOD_EXECUTION__ACTIONS> {
  payload: typeDeviceInstanceDeviceMethodExecutionPayload;
}
