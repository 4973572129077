/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { useTypedSelector } from '../../../../../store';
import { deviceStatusValueSelector } from '../../../../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetContext } from '../../../../../views/DatasetContext';

const useModuleColorStatus = (stationInstanceId: string, channelNo: string): string => {
  const { targetDataset } = useContext(DatasetContext);

  const errorStatusVarIdentRef = `DEVICE${channelNo}_S_REG_ADDR_STATE_ERROR`;
  const msgStatusVarIdentRef = `DEVICE${channelNo}_S_REG_ADDR_STATE_MSG`;

  const errorVariableValue = useTypedSelector((state) => {
    const statusValue = deviceStatusValueSelector(state.deviceInstances, stationInstanceId, targetDataset, errorStatusVarIdentRef);
    return statusValue?.value ?? 0;
  });
  const msgVariableValue = useTypedSelector((state) => {
    const statusValue = deviceStatusValueSelector(state.deviceInstances, stationInstanceId, targetDataset, msgStatusVarIdentRef);
    return statusValue?.value ?? 0;
  });

  let color = 'green';
  if (errorVariableValue === 1) {
    color = 'red';
  } else if (msgVariableValue === 1) {
    color = 'yellow';
  }

  return color;
};

export default useModuleColorStatus;
