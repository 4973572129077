/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import { typeDeviceInstanceDeviceStatusEventMonitorAction } from './types';

// eslint-disable-next-line import/prefer-default-export
export const updateDeviceStatusEventMonitor = (
  targetInstance: string,
  payload: DeviceModelStatus.UI.DeviceStatusMonitorUpdateEvents,
): typeDeviceInstanceDeviceStatusEventMonitorAction => ({
  type: 'DEVICE_STATUS_EVENT_MONITOR__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_STATUS_EVENT_MONITOR__UPDATE_EVENT_LIST',
      payload,
    },
  },
});
