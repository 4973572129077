/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  WizardActionTypes,
  DEVICE_WIZARD__INIT_BUTTONS,
  DEVICE_WIZARD__UPDATE_BUTTONS,
  DEVICE_WIZARD__DISABLE_ALL_BUTTONS,
  DEVICE_WIZARD__SET_BUTTON_STATE,
  DEVICE_WIZARD__RESET_FOCUS,
  WizardSetButtonStatePayload,
  WizardUpdateButtonPayload,
} from './types';

export const deviceWizardInitButtons = (payload: WizardUpdateButtonPayload): WizardActionTypes => ({
  type: DEVICE_WIZARD__INIT_BUTTONS,
  payload,
});

export const deviceWizardUpdateButtons = (payload: WizardUpdateButtonPayload):
  WizardActionTypes => ({
  type: DEVICE_WIZARD__UPDATE_BUTTONS,
  payload,
});

export const deviceWizardSetButtons = (payload: WizardSetButtonStatePayload):
  WizardActionTypes => ({
  type: DEVICE_WIZARD__SET_BUTTON_STATE,
  payload,
});

export const deviceWizardDisableAllButtons = (): WizardActionTypes => ({
  type: DEVICE_WIZARD__DISABLE_ALL_BUTTONS,
});

export const deviceWizardResetFocus = (): WizardActionTypes => ({
  type: DEVICE_WIZARD__RESET_FOCUS,
});

export const deviceWizardSetBackupFilename = (payload: string): WizardActionTypes => ({
  type: 'DEVICE_WIZARD__SET_BACKUP_FILENAME',
  payload,
});
