/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  DeviceStartup, DeviceModelStatus, Report, ReportContentType, DeviceInformation,
} from '@gpt/commons';
import { useTypedSelector } from '../../../../store/reduxStore';
import { PageSaveProtocolComponent } from '../../../../PageComponents/PageSaveProtocolComponent/PageSaveProtocolComponent';
import { ActionProgressControl } from '../../../../components/ActionProgressControl/ActionProgressControl';
import { LoadingControl } from '../../../../components/LoadingControl/LoadingControl';
import { createReportFilename } from '../../../../helpers/functions';
import { WizardViewPageProps } from '../../../WizardView/WizardViewPage';
import DownloadMethodControl from '../../../../controls/MethodExecution/DownloadMethodControl/DownloadMethodControl';
import { deviceMethodExecutionSelector } from '../../../../store/deviceInstances/store/deviceMethod/selectors';
import { MethodStageExecutionStatus } from '../../../../store/deviceInstances/store/deviceMethod/types';
import { deviceInstanceActiveDeviceInstance } from '../../../../store/deviceInstances/store/activeDevice/selectors';
import useMethodExecutionStateStatus from '../../../hooks/useMethodExecutionStateStatus';

const getContentHandler = async (
  activeDevice: DeviceInformation,
  userName: string,
  userNotes: string,
  serialNumber: string,
  systemName: string,
): Promise<Report> => {
  const content: DeviceStartup = {
    type: ReportContentType.DeviceStartup,
    device: {
      deviceDesignation: activeDevice.catalog?.deviceTypeName ?? '',
      deviceTag: activeDevice.instance?.deviceTag ?? '',
      firmwareRevision: activeDevice.instance?.firmwareVersion ?? '',
      hardwareRevision: activeDevice.instance?.hardwareVersion ?? '',
      serialNumber,
      itemNumber: activeDevice.catalog?.productOrderNumber ?? '',
    },
  };

  return {
    header: {
      dateTime: new Date(),
      systemName,
      userName,
      userNotes,
    },
    content,
  };
};

const METHOD_DOWNLOAD_IDENT = DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF;

export interface PageDocumentationProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const PageDocumentation
: React.FC<PageDocumentationProps> = (props: PageDocumentationProps) : React.ReactElement => {
  const { t } = useTranslation();
  const { active, reportMenu, deviceInstanceId } = props;
  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId));
  const stageSteps = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, METHOD_DOWNLOAD_IDENT)?.steps ?? []);
  const stageStatus = useMethodExecutionStateStatus(deviceInstanceId, METHOD_DOWNLOAD_IDENT, active);

  if (activeDevice === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const downloadMethodDone = (stageStatus === MethodStageExecutionStatus.DoneSuccess);
  const control = stageStatus === MethodStageExecutionStatus.RequestPassword || stageStatus === MethodStageExecutionStatus.RequestAcceptData
    ? (
      <DownloadMethodControl methodIdent={METHOD_DOWNLOAD_IDENT} deviceInstanceId={deviceInstanceId} />
    )
    : (
      <PageSaveProtocolComponent
        reportMenu={reportMenu}
        deviceInstanceId={deviceInstanceId}
        caption={t<string>('WIZARD_DEVICE_COMMISSIONING__FINAL_PAGE__DOCUMENTATION__TITLE')}
        nameCaption={t<string>('WIZARD_DEVICE_COMMISSIONING__FINAL_PAGE__DOCUMENTATION__NAME')}
        descriptionCaption={t<string>('WIZARD_DEVICE_COMMISSIONING__FINAL_PAGE__DOCUMENTATION__DESCRIPTION')}
        disableButtons={!downloadMethodDone}
        serialNumber={activeDevice.device.instance?.serialNumber}
        fileName={createReportFilename('InitialDeviceStartupReport', activeDevice.device)}
        onGetContent={(caption, userComments, serialNumber) => getContentHandler(
          activeDevice.device,
          caption,
          userComments,
          serialNumber,
          t<string>('', '{{SYSTEM_NAME}}'),
        )}
        showSaveParameter
      />
    );
  return (
    <Container className="w-100 h-100" fluid>
      <Row>
        <Col xs={6}>
          <ActionProgressControl
            title={t<string>('WIZARD_DEVICE_COMMISSIONING__FINAL_PAGE__PROGRESS_CAPTION')}
            steps={stageSteps}
          />
        </Col>
        <Col xs={6}>
          {control}
        </Col>
      </Row>
    </Container>
  );
};

export default PageDocumentation;
