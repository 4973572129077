/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { DeviceModelStatus } from '@gpt/commons';
import { DeviceInstanceActionPayload } from '../../common';

export const DEVICE_STATUS_EVENT_MONITOR__UPDATE_EVENT_LIST = 'DEVICE_STATUS_EVENT_MONITOR__UPDATE_EVENT_LIST';
export const DEVICE_STATUS_EVENT_MONITOR__ACTIONS = 'DEVICE_STATUS_EVENT_MONITOR__ACTIONS';

/* ************************************************************************************ */
/* INTERFACE                                                                            */
/* ************************************************************************************ */
export enum DeviceStatusEventMonitorEventMode
{
    // eslint-disable-next-line no-unused-vars
    Disappears = 'disappears',
    // eslint-disable-next-line no-unused-vars
    Appears = 'appears'
}

export enum DeviceStatusEventMonitorEventType
{
    // eslint-disable-next-line no-unused-vars
    Message = 'message',
    // eslint-disable-next-line no-unused-vars
    Warning = 'warning',
    // eslint-disable-next-line no-unused-vars
    Error = 'error'
}

export interface DeviceStatusEventMonitorEvent {
  id: string;
  code: number;
  mode: DeviceStatusEventMonitorEventMode;
  timestamp: string;
}

export interface DeviceStatusEventState {
  code: number;
  mode: DeviceStatusEventMonitorEventMode;
  timestamp: string;
}

export interface DeviceStatusEventStateMap {
  [keys: string]: DeviceStatusEventState;
}

export type DeviceStatusEventMonitorState = {
  eventList: DeviceStatusEventMonitorEvent[];
  eventState: DeviceStatusEventStateMap;
}

/* ************************************************************************************ */
/* ACTION: UpdateDeviceStatusEventMonitorAction                                                   */
/* ************************************************************************************ */
export interface UpdateDeviceStatusEventMonitorAction extends Action<typeof DEVICE_STATUS_EVENT_MONITOR__UPDATE_EVENT_LIST> {
  payload: DeviceModelStatus.UI.DeviceStatusMonitorUpdateEvents;
}

export type typeDeviceStatusEventMonitorActionTypes = UpdateDeviceStatusEventMonitorAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceInstanceIoLinkEventMonitorAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceDeviceStatusEventMonitorPayload extends DeviceInstanceActionPayload {
  action: typeDeviceStatusEventMonitorActionTypes;
}

export interface typeDeviceInstanceDeviceStatusEventMonitorAction extends Action<typeof DEVICE_STATUS_EVENT_MONITOR__ACTIONS> {
  payload: typeDeviceInstanceDeviceStatusEventMonitorPayload;
}
