/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import ReduxControl from '../../../ReduxControl/ReduxControl';
import './IoStatusValueCard.scss';

export interface IoStatusValueCardProps {
  deviceInstanceId: string;
  ioGroupState: DeviceModelStatus.UI.Contactron.StationCockpitIOGroupStateCard;
}

const IoStatusValueCard: React.FC<IoStatusValueCardProps> = (props: IoStatusValueCardProps):
  React.ReactElement => {
  const {
    ioGroupState,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const menuItems = ioGroupState.items.map((identRef) => (
    <ReduxControl key={`iostatus-value--${identRef}`} identRef={identRef} deviceInstanceId={deviceInstanceId} readonly onStateChanged={() => 0} />
  ));

  return (
    <PaperControl title={t(ioGroupState.label) ?? ''}>
      <div className="cockpit-contactron-station-iostatus">
        {menuItems}
      </div>
    </PaperControl>
  );
};

export default IoStatusValueCard;
