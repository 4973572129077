/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTypedSelector } from '../../../../store';
import { deviceDescriptorSelector } from '../../../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import { TrendViewItem } from '../../TrendViewDescriptionControl/hooks/useTimelineVariables';
import { requestTimelineData } from '../../../../store/deviceInstances/middleware/timelineService/actions';

const compareTrendViewItem = (prev: TrendViewItem[], cur: TrendViewItem[]): boolean => {
  if (prev.length !== cur.length) {
    return false;
  }
  const uneq = cur.find((item, idx) => item.identRef !== prev[idx].identRef || item.color !== prev[idx].color);
  return uneq === undefined;
};

const useReduxTimelineVariables = (
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: IdentRef,
): TrendViewItem[] => {
  const dispatch = useDispatch();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const timelineVars: TrendViewItem[] = useTypedSelector((state) => {
    const trendDescriptor = deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef);
    if (trendDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
      return [];
    }

    if (trendDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDYNTREND) {
      return [];
    }

    return trendDescriptor.controlType.DYNVALUE
      .map((item) => ({
        identRef: item.variable,
        color: item.color ?? 'magenta',
      }));
  }, (prev, cur) => compareTrendViewItem(prev, cur));

  useEffect(() => {
    const clear = () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };

    const updateTimelineData = () => {
      if (timelineVars.length > 0) {
        const vars = timelineVars.map((item) => item.identRef);
        dispatch(requestTimelineData(deviceInstanceId, vars));
      }
      clear();
      timeoutRef.current = setTimeout(() => updateTimelineData(), 1000);
    };
    updateTimelineData();

    return clear;
  }, [timelineVars, deviceInstanceId]);

  return timelineVars;
};
export default useReduxTimelineVariables;
