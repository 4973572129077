/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import SelectEditorControl from '../../SelectEditorControl';
import TextEditorControl from '../../TextEditorControl/TextEditorControl';
import { updateDeviceDatasetValues } from '../../../../store/deviceInstances/store/deviceDataset';
import { VariableEditorControlsProps } from '../types';
import useIntegerMessageContextHelp from '../hooks/useIntegerMessageContextHelp';

const itemOptions = (
  valueType: DeviceModelStatus.StatusDescriptorValueTypeEnumerator,
  statusValue: DeviceModelStatus.StatusValue | undefined,
  t: TFunction,
): { [val: number]: string } => {
  const result = {};
  if (statusValue !== undefined) {
    valueType.enumerator.forEach((entry) => {
      result[entry.value] = t(entry.label);
    });
  } else {
    result[0] = t('N/A');
  }
  return result;
};

const enumItemValue = (
  valueType: DeviceModelStatus.StatusDescriptorValueTypeEnumerator,
  statusValue: DeviceModelStatus.StatusValue | undefined,
  t: TFunction,
): string => {
  let result = t('N/A');
  if (statusValue !== undefined) {
    const value = valueType.enumerator.find((entry) => entry.value === statusValue.value);
    result = value !== undefined ? t(value.label) : t('N/A');
  }
  return result;
};

const VariableEnumeratorEditorControl
:React.FC<VariableEditorControlsProps> = (props: VariableEditorControlsProps):
  React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp, readonly,
    deviceInstanceId, showContextMessageDef,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warning, showContextHelp, hideContentHelp] = useIntegerMessageContextHelp(statusDescriptor, statusValue, showContextMessageDef, onShowContentHelp, onHideContentHelp);

  const { valueType } = statusDescriptor;
  if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return <div />;
  }

  const { label, help } = statusDescriptor;
  const createSelectEditorControl = () => (
    <SelectEditorControl
      label={`${t(label)}`}
      value={statusValue === undefined ? 0 : statusValue.value}
      options={itemOptions(valueType, statusValue, t)}
      edited={statusValue?.modified}
      help={`${t(help)}`}
      warning={warning}
      onChange={(event) => {
        const onNumberChangeHandler = (parsedValue: number, originalValue: string) => {
          if (statusValue === undefined) {
            return;
          }

          if (Number.isNaN(parsedValue)) {
            dispatch(updateDeviceDatasetValues(deviceInstanceId, {
              targetDataset: DatasetType.user,
              values: [{
                value: originalValue,
                backupValue: statusValue.backupValue,
                identRef: statusValue.identRef,
                valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
                valueValidityDescription: `${t('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT')}`,
              }],
            }));
          } else {
            dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
              value: parsedValue,
              backupValue: statusValue.backupValue,
              identRef: statusDescriptor.identRef,
            }]));
          }
        };
        onNumberChangeHandler(parseInt(event.target.value, 10), event.target.value);
      }}
      onShowContentHelp={() => {
        showContextHelp();
      }}
      onHideContentHelp={() => hideContentHelp()}
    />
  );

  const createReadOnlySelectEditorControl = () => (
    <TextEditorControl
      label={`${t(label)}`}
      displayFormat="%s"
      value={enumItemValue(valueType, statusValue, t)}
      readonly
      warning={warning}
      onValueChange={() => 0}
      onFocus={() => showContextHelp()}
      onBlur={() => hideContentHelp()}
      modified={statusValue?.modified}
    />
  );

  const isReadonly = readonly ?? statusDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY;
  const control = isReadonly ? createReadOnlySelectEditorControl() : createSelectEditorControl();

  return (
    <>{control}</>
  );
};

export default VariableEnumeratorEditorControl;
