/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../store';
import {
  DropDownStationNavMenu, DropDownStationNavModule,
  DropDownStationNavStation, DropDownStationNavToggle,
} from './DropDownStationNav/DropDownStationNav';
import IconTopology from '../../assets/icons/icon-topology.svg';
import { useStationTopology } from './hooks/useStationTopology';
import { useStationDeviceSelector } from './hooks/useStationDeviceSelector';
import { executeMethodStageInit } from '../../store/deviceInstances/middleware/deviceMethod/actions';
import { setDeviceMethodExecutionState } from '../../store/deviceInstances/store/deviceMethod/actions';
import { MethodStageExecutionStatus } from '../../store/deviceInstances/store/deviceMethod/types';
import './StationNavigation.css';

export interface StationNavigationProps {
  stationInstanceId: string;
}

const StationNavigation: React.FC<StationNavigationProps> = (props: StationNavigationProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { stationInstanceId } = props;

  const stationTitle = useTypedSelector((state) => state.deviceInstances.instances[stationInstanceId]?.station.title ?? '???');
  const stationSubtitle = useTypedSelector((state) => state.deviceInstances.instances[stationInstanceId]?.station.subtitle ?? '');

  const topology = useStationTopology(stationInstanceId);
  const [activeDeviceInstance, selectStationDevice] = useStationDeviceSelector(stationInstanceId);

  let selectedItemTitle = '';
  if (activeDeviceInstance !== undefined) {
    if (activeDeviceInstance === stationInstanceId) {
      selectedItemTitle = stationTitle;
    } else {
      const module = topology.find((top) => top.instanceId === activeDeviceInstance);
      selectedItemTitle = module?.title ?? '???';
    }
  }

  useEffect(() => {
    const methodIdent = 'TopologyScan';
    dispatch(setDeviceMethodExecutionState(stationInstanceId, {
      methodIdent,
      stage: MethodStageExecutionStatus.Initialize,
      steps: [],
    }));
    dispatch(executeMethodStageInit(stationInstanceId, {
      methodIdent,
      values: [],
    }));
  }, [stationInstanceId]);

  return (
    <div className="station-navigation__container">
      <div className="station-navigation__icon">
        <img src={IconTopology} alt="Station Navigation Icon" />
      </div>
      <div className="station-navigation__title">{t('STATION_NAVIGATION_TITLE')}</div>
      <div className="station-navigation__dropdown">
        <Dropdown>
          <Dropdown.Toggle as={DropDownStationNavToggle}>
            {t(selectedItemTitle)}
          </Dropdown.Toggle>
          <Dropdown.Menu
            as={DropDownStationNavMenu}
            style={{ width: '231px' }}
          >
            <Dropdown.Item
              as={DropDownStationNavStation}
              onClick={() => selectStationDevice()}
              title={t(stationTitle)}
              subtitle={t(stationSubtitle)}
            />
            <Dropdown.Divider />
            {topology.map((item) => (
              <Dropdown.Item
                key={`module-${item.deviceUniqueIdentifier}`}
                as={DropDownStationNavModule}
                onClick={() => selectStationDevice(item)}
                title={t(item.title === '' ? 'STATION_NAVIGATION__MODULE__DEFTITLE' : item.title)}
                subtitle={t(item.subtitle)}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default StationNavigation;
