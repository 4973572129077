/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { typeDeviceMethodStageMiddlewareActions } from '../../../store/deviceInstances/middleware';
import { executeMethodStageInit } from '../../../store/deviceInstances/middleware/deviceMethod/actions';

// eslint-disable-next-line import/prefer-default-export
export const WRITE_CONFIGURATION_METHOD_IDENT = 'contactronWriteStationConfiguration';
export const WRITE_CONFIGURATION_METHOD_PROGRESS = 'contactronWriteStationConfiguration::state';

export const METHOD__CONTACTRON_START_DISCOVERY = 'contactronStartStationDeviceDiscovery';
export const METHOD__CONTACTRON_STOP_DISCOVERY = 'contactronStopStationDeviceDiscovery';

export const startContactronDiscovery = (deviceInstanceId: string)
: typeDeviceMethodStageMiddlewareActions => executeMethodStageInit(deviceInstanceId, {
  methodIdent: METHOD__CONTACTRON_START_DISCOVERY,
  values: [],
});

export const stopContactronDiscovery = (deviceInstanceId: string)
: typeDeviceMethodStageMiddlewareActions => executeMethodStageInit(deviceInstanceId, {
  methodIdent: METHOD__CONTACTRON_STOP_DISCOVERY,
  values: [],
});
