/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useState } from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './SaveProtocolDocumentation.scss';

export interface SaveProtocolDocumentationProps {
  nameCaption: string;
  descriptionCaption: string;
  userName: string;
  userText: string;
  instanceSerialNumber?: string;
  // eslint-disable-next-line no-unused-vars
  onUserNameChanged: (userName: string) => void;
  // eslint-disable-next-line no-unused-vars
  onUserTextChanged: (userText: string) => void;
}

const SaveProtocolDocumentation:
React.FC<SaveProtocolDocumentationProps> = (props: SaveProtocolDocumentationProps)
: React.ReactElement => {
  const {
    nameCaption, descriptionCaption, userName, userText,
    onUserNameChanged, onUserTextChanged, instanceSerialNumber,
  } = props;

  const { t } = useTranslation();
  const [serialNumberValue, setSerialNumberValue] = useState(instanceSerialNumber);

  return (
    <>
      <Row className="save-protocol-document__document-name">
        <Col className="form-group m-0 p-0">
          <label htmlFor="save-protocol-document__document-name__id">
            {nameCaption}
          </label>
          <input
            type="text"
            className="textfield__input"
            id="save-protocol-document__document-name__id"
            placeholder={nameCaption}
            value={userName}
            onChange={(event) => onUserNameChanged(event.target.value)}
          />
        </Col>
      </Row>
      <Row className="page-save-protocol__device-serial">
        <Col className="form-group m-0 p-0">
          <label htmlFor="page-save-protocol__document-serial__id">
            {t<string>('SAVE_PARAMETER_FILE__DEVICE_SERIAL_NUMBER')}
          </label>
          <input
            type="text"
            className="textfield__input"
            id="page-save-protocol__document-serial__id"
            placeholder={t<string>('SAVE_PARAMETER_FILE__DEVICE_SERIAL_NUMBER')}
            disabled={instanceSerialNumber !== undefined}
            value={serialNumberValue}
            onChange={(event) => setSerialNumberValue(event.target.value)}
          />
        </Col>
      </Row>
      <Row className="save-protocol-document__document-text align-items-end">
        <Col className="form-group m-0 p-0 pb-2">
          <label htmlFor="save-protocol-document__document-text__id">
            {descriptionCaption}
          </label>
          <textarea
            className="textfield__input m-0 p-2"
            id="save-protocol-document__document-text__id"
            placeholder={descriptionCaption}
            value={userText}
            onChange={(event) => onUserTextChanged(event.target.value)}
          />
        </Col>
      </Row>
    </>
  );
};

export default SaveProtocolDocumentation;
