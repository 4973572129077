/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { ExecutionState } from '../../common';
import { useTypedSelector } from '../../reduxStore';
import { ModelInstanceInformation } from '../store/activeDevice/types';

// eslint-disable-next-line import/prefer-default-export
export const useContextActiveDeviceInstance = (deviceInstanceId: string | undefined)
: ModelInstanceInformation | undefined => useTypedSelector((state) => {
  if (deviceInstanceId === undefined) {
    return undefined;
  }
  const instance = state.deviceInstances.instances[deviceInstanceId];
  return instance?.activeDevice.modelInstance;
});

export const useContextActiveDeviceInstanceState = (deviceInstanceId: string | undefined)
: ExecutionState => useTypedSelector((state) => {
  if (deviceInstanceId === undefined) {
    return ExecutionState.init;
  }
  const instance = state.deviceInstances.instances[deviceInstanceId];
  return instance?.activeDevice.executionState ?? ExecutionState.init;
});
