/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  Trio3SwitchChannelSetStatePayload,
  typeDeviceInstanceIoLinkTrio3SwitchChannelAction,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const trio3SwitchChannelSetState = (
  targetInstance: string,
  payload: Trio3SwitchChannelSetStatePayload,
): typeDeviceInstanceIoLinkTrio3SwitchChannelAction => ({
  type: 'IOLINK__TRIO3_CHANNEL_SWITCH__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'IOLINK__TRIO3_CHANNEL_SWITCH__SET_CHANNEL_STATE',
      payload,
    },
  },
});
