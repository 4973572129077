/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { Action } from 'redux';
import { ExecutionState } from '../../../common';
import { InvalidDatasetValue } from './helpers';
import { DeviceInstanceActionPayload } from '../../common';

export const DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS = 'DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS';
export const DEVICE_INSTANCE__MULTIPLE_DEVICE_DATASET__ACTIONS = 'DEVICE_INSTANCE__MULTIPLE_DEVICE_DATASET__ACTIONS';

export const DEVICE_DATASET__INITIALIZE_DATASETS = 'DEVICE_DATASET__INITIALIZE_DATASETS';
export const DEVICE_DATASET__CLEANUP_DATASET = 'DEVICE_DATASET__CLEANUP_DATASET';
export const DEVICE_DATASET__REQUEST_DATASET = 'DEVICE_DATASET__REQUEST_DATASET';
export const DEVICE_DATASET__UPDATE_DATASET = 'DEVICE_DATASET__UPDATE_DATASET';
export const DEVICE_DATASET__UPDATE_MULTIPLE_DATASETS = 'DEVICE_DATASET__UPDATE_MULTIPLE_DATASETS';
export const DEVICE_DATASET__SET_EXECUTION_STATE = 'DEVICE_DATASET__SET_EXECUTION_STATE';
export const DEVICE_DATASET__UPDATE_DATASET_VALUE = 'DEVICE_DATASET__UPDATE_DATASET_VALUE';
export const DEVICE_DATASET__RESET__IS_EDITED = 'DEVICE_DATASET__RESET__IS_EDITED';

/* ************************************************************************************ */
/* INTERFACE                                                                            */
/* ************************************************************************************ */
// eslint-disable-next-line import/prefer-default-export
export enum DatasetType {
  // eslint-disable-next-line no-unused-vars
  user = 'user',
  // eslint-disable-next-line no-unused-vars
  device = 'device',
  // eslint-disable-next-line no-unused-vars
  init = 'init',
}

export interface DeviceDatasetValueCollection {
  [key: string]: DeviceModelStatus.StatusValue;
}

export interface DatasetState {
  descriptors: DeviceModelStatus.DeviceModelDescriptorCollection;
  values: DeviceDatasetValueCollection;
  state: ExecutionState;
  datasetValid: boolean;
  datasetModified: boolean;
  invalidValueList?: InvalidDatasetValue[];
  modifiedItemList?: string[];
}

export type DeviceDatasetState = {
  // eslint-disable-next-line no-unused-vars
  [key in DatasetType]: DatasetState;
}

export interface DeviceTargetDatasetPayload {
  targetDataset: DatasetType;
}

/* ************************************************************************************ */
/* ACTION: RequestDeviceDatasetAction                                                   */
/* ************************************************************************************ */
export interface RequestDeviceDatasetAction extends Action<typeof DEVICE_DATASET__REQUEST_DATASET> {
  payload: DeviceTargetDatasetPayload;
}

/* ************************************************************************************ */
/* ACTION: UpdateDeviceDatasetAction                                                    */
/* ************************************************************************************ */
export interface UpdateDeviceDatasetPayload extends DeviceTargetDatasetPayload {
  data: DeviceModelStatus.DeviceModelChanges[];
}

export interface UpdateDeviceDatasetAction extends Action<typeof DEVICE_DATASET__UPDATE_DATASET> {
  payload: UpdateDeviceDatasetPayload;
}

/* ************************************************************************************ */
/* ACTION: UpdateDeviceDatasetAction                                                    */
/* ************************************************************************************ */
export interface UpdateDeviceDatasetValue {
  identRef: IdentRef;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  valueValidity?: DeviceModelStatus.StatusValueValidity;
  valueValidityDescription?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  backupValue: any;
  errorCode?: DeviceModelStatus.ErrorCodeType;
  timestamp?: string;
}

export interface UpdateDeviceDatasetValuePayload extends DeviceTargetDatasetPayload {
  values: UpdateDeviceDatasetValue[];
}

export interface UpdateDeviceDatasetValueAction extends Action<typeof DEVICE_DATASET__UPDATE_DATASET_VALUE> {
  payload: UpdateDeviceDatasetValuePayload;
}

/* ************************************************************************************ */
/* ACTION: SetDeviceDatasetStateAction                                                  */
/* ************************************************************************************ */
export interface SetDeviceDatasetStatePayload extends DeviceTargetDatasetPayload {
  state: ExecutionState;
}

export interface SetDeviceDatasetStateAction
  extends Action<typeof DEVICE_DATASET__SET_EXECUTION_STATE> {
    payload: SetDeviceDatasetStatePayload;
}

/* ************************************************************************************ */
/* ACTION: ResetDeviceDatasetModifiedAction                                             */
/* ************************************************************************************ */
export interface ResetDeviceDatasetModifiedAction extends Action<typeof DEVICE_DATASET__RESET__IS_EDITED> {
  payload: DeviceTargetDatasetPayload;
}

/* ************************************************************************************ */
/* ACTION: CleanupDeviceDatasetAction                                             */
/* ************************************************************************************ */
export interface CleanupDeviceDatasetAction extends Action<typeof DEVICE_DATASET__CLEANUP_DATASET> {
  payload: DeviceTargetDatasetPayload;
}

/* ************************************************************************************ */
/* ACTION: CleanupDeviceDatasetAction                                                   */
/* ************************************************************************************ */
export interface InitDataset {
  descriptors: DeviceModelStatus.DeviceModelDescriptorCollection;
  values: DeviceDatasetValueCollection;
}

export type InitDatasetState = {
  // eslint-disable-next-line no-unused-vars
  [key in DatasetType]: InitDataset;
}

export interface InitDeviceDatasetPayload extends DeviceTargetDatasetPayload {
  datasets: InitDatasetState;
}

export interface InitializeDeviceDatasetsAction extends Action<typeof DEVICE_DATASET__INITIALIZE_DATASETS> {
  payload: InitDeviceDatasetPayload;
}

export const createInitialDataset = (): DatasetState => ({
  datasetModified: false,
  datasetValid: true,
  descriptors: { },
  values: { },
  state: ExecutionState.init,
});

export type typeDeviceDatasetActionTypes = RequestDeviceDatasetAction
  | SetDeviceDatasetStateAction
  | UpdateDeviceDatasetAction
  | ResetDeviceDatasetModifiedAction
  | UpdateDeviceDatasetValueAction
  | CleanupDeviceDatasetAction
  | InitializeDeviceDatasetsAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceDataStorageAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceDeviceDatasetActionPayload extends DeviceInstanceActionPayload {
  action: typeDeviceDatasetActionTypes;
}

export interface typeDeviceInstanceDeviceDatasetActions extends Action<typeof DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS> {
  payload: typeDeviceInstanceDeviceDatasetActionPayload;
}

export interface typeDeviceInstanceMultipleDeviceDatasetPayload {
  targetInstances: string[];
  action: typeDeviceDatasetActionTypes;
}

export interface typeDeviceInstanceMultipleDeviceDatasetAction extends Action<typeof DEVICE_INSTANCE__MULTIPLE_DEVICE_DATASET__ACTIONS> {
  payload: typeDeviceInstanceMultipleDeviceDatasetPayload;
}

export type typeDeviceInstanceDeviceDatasetAction = typeDeviceInstanceDeviceDatasetActions | typeDeviceInstanceMultipleDeviceDatasetAction;
