/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  IdentRef,
  Services,
} from '@gpt/commons';
import { DeviceInstanceAction } from '../../common';

export const PREVIEW_DATASET__REQUEST_EXTERNAL_DATASET = 'PREVIEW_DATASET__REQUEST_EXTERNAL_DATASET';
export const PREVIEW_DATASET__REQUEST_DEVICE_DATASET = 'PREVIEW_DATASET__REQUEST_DEVICE_DATASET';
export const PREVIEW_DATASET__REQUEST_INITIAL_DATASET = 'PREVIEW_DATASET__REQUEST_INITIAL_DATASET';

/* ************************************************************************************ */
/* ACTION: RequestDeviceDatasetAction                                                   */
/* ************************************************************************************ */
export interface RequestPreviewDatasetPayload {
  datasetIdent: Services.DeviceModelServer.DatasetProviderService.DatasetIdent;
  profileName: string;
}
export type RequestPreviewDatasetAction = DeviceInstanceAction<typeof PREVIEW_DATASET__REQUEST_EXTERNAL_DATASET, RequestPreviewDatasetPayload>

/* ************************************************************************************ */
/* ACTION: RequestPreviewInitialDatasetAction                                                   */
/* ************************************************************************************ */
export interface RequestPreviewInitialDatasetPayload {
  previewMenuId: IdentRef;
  deviceInstanceId: string;
}
export type RequestPreviewInitialDatasetAction = DeviceInstanceAction<typeof PREVIEW_DATASET__REQUEST_INITIAL_DATASET, RequestPreviewInitialDatasetPayload>

/* ************************************************************************************ */
/* ACTION: RequestPreviewDeviceDatasetAction                                                   */
/* ************************************************************************************ */
export type RequestPreviewDeviceDatasetAction = DeviceInstanceAction<typeof PREVIEW_DATASET__REQUEST_DEVICE_DATASET, RequestPreviewInitialDatasetPayload>

/* ************************************************************************************ */
/* ACTION: SetExternalDatasetAction                                                     */
/* ************************************************************************************ */
export type typePreviewDatasetActionTypes = RequestPreviewInitialDatasetAction
  | RequestPreviewDeviceDatasetAction
  | RequestPreviewDatasetAction;
