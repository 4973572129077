/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceInformation } from '@gpt/commons';
import { DeviceCard } from '../../DeviceCards/DeviceCard/DeviceCard';

export interface ListItemDeviceCardProps {
  device: DeviceInformation;
  // eslint-disable-next-line no-unused-vars
  onRowClick: (device: DeviceInformation) => void;
}

export const ListItemDeviceCard: React.FC<ListItemDeviceCardProps> = (props: ListItemDeviceCardProps)
: React.ReactElement => {
  const {
    device, onRowClick,
  } = props;

  const { catalog } = device;
  const isPlaceholder = !device.instance;
  const hasDeviceDriver = !!catalog.deviceDriverId;

  return (
    <DeviceCard
      showChevron
      selectable={!isPlaceholder && hasDeviceDriver}
      device={device}
      onClick={() => onRowClick(device)}
    />
  );
};
