/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceInformation } from '@gpt/commons';
import { PageSelectDeviceComponent } from '../../../PageComponents';
import { DeviceListItem } from '../../../components/WizardDeviceList/WizardDeviceList';
import { ButtonState, ButtonRole } from '../../../store/deviceWizard/types';
import {
  useTypedSelector, ExecutionState, deviceWizardUpdateButtons, instantiateDevice,
} from '../../../store';
import { deviceInstanceActiveDeviceState } from '../../../store/deviceInstances/store/activeDevice/selectors';
import './PageSelectConnectedDevice.scss';
import discoveryListSelector from '../../../views/StartupViewControl/selectors/discoveryListSelector';
import { DeviceCardListItemDeviceKnown } from '../../../components/DeviceCardList/types';

export interface PageSelectConnectedDeviceProps {
  title?: string;
  // eslint-disable-next-line no-unused-vars
  filter?: (device: DeviceInformation) => boolean;
  active?: boolean;
  deviceInstanceId: string;
}

const PageSelectConnectedDeviceComponent: React.FC<PageSelectConnectedDeviceProps> = (props: PageSelectConnectedDeviceProps): React.ReactElement => {
  const dispatch = useDispatch();

  const {
    title, filter, active, deviceInstanceId,
  } = props;

  const [selectedDeviceBusy, setSelectedDeviceBusy] = useState<boolean>(false);
  const [selectedDeviceSerialNum, setSelectedDeviceSerialNum] = useState<string>('');

  const activeDeviceList = useTypedSelector((state) => discoveryListSelector(state.discoveryService, state.deviceSimulation));
  const activeDeviceInstantiationState = useTypedSelector((state) => deviceInstanceActiveDeviceState(state.deviceInstances, deviceInstanceId));

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const defautFilter = (device: DeviceInformation) => true;
  const filterFn = (filter === undefined) ? defautFilter : filter;

  const wizardDeviceList: DeviceListItem[] = activeDeviceList
    .reduce((acc, item) => (item.type === 'DEVICE_CARD_LIST_ITEM__DEVICE__KNOWN' && filterFn(item.device)
      ? [
        ...acc,
        item,
      ] : acc), [] as DeviceCardListItemDeviceKnown[])
    .map((item) => {
      let busy = false;
      if (selectedDeviceSerialNum !== '') {
        // unselected DeviceCards are brighter; selected DeviceCard is displayed "normally"
        busy = selectedDeviceSerialNum === item.device.instance?.serialNumber ? selectedDeviceBusy : false;
      }
      const supported = item.device.instance?.serialNumber.trim() !== '0';
      return {
        device: item.device,
        selectable: true,
        busy,
        supported,
      };
    });

  useEffect(() => {
    if (active !== true) {
      return;
    }

    if (activeDeviceInstantiationState === ExecutionState.success) {
      setSelectedDeviceBusy(false);
    }
  }, [activeDeviceInstantiationState, active]);

  useEffect(() => {
    if (active !== true) {
      return;
    }

    const nextEnabled = (selectedDeviceSerialNum !== '')
      && (activeDeviceInstantiationState === ExecutionState.success);

    dispatch(deviceWizardUpdateButtons({
      source: 'PageSelectConnectedDeviceComponent',
      abort: ButtonState.enabled,
      back: ButtonState.disabled,
      next: nextEnabled ? ButtonState.enabled : ButtonState.disabled,
      complete: ButtonState.hidden,
      pcta: nextEnabled ? ButtonRole.next : ButtonRole.complete,
      focusPcta: nextEnabled ? ButtonRole.next : ButtonRole.complete,
    }));
  }, [activeDeviceInstantiationState, selectedDeviceSerialNum, active]);

  return (
    <PageSelectDeviceComponent
      caption={title ?? ''}
      deviceList={wizardDeviceList}
      onSelectDevice={async (device) => {
        if (device.instance !== undefined) {
          setSelectedDeviceBusy(true);
          dispatch(deviceWizardUpdateButtons({
            source: 'PageSelectConnectedDeviceComponent: onSelectDevice',
            abort: ButtonState.disabled,
            back: ButtonState.disabled,
            next: ButtonState.disabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.complete,
          }));
          setSelectedDeviceSerialNum(device.instance.serialNumber);
          dispatch(instantiateDevice({
            deviceInfo: device,
            targetInstance: deviceInstanceId,
            wizardMode: true,
          }));
        }
      }}
    />
  );
};

export default PageSelectConnectedDeviceComponent;
