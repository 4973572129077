/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { isInRangeInterval } from '../../../helpers/functions';

const validateIPaddress = (ipaddress?: string)
  : boolean => (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress ?? 'a'));

const validateMacAddress = (macAddress?: string)
  : boolean => (/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(macAddress ?? '-'));

const validateDate = (value: string): boolean => {
  const isValidDate = Date.parse(value);
  return !Number.isNaN(isValidDate);
};

const useVariableValidationMessage = (
  statusDescriptor?: DeviceModelStatus.DeviceModelDescriptor,
  statusValue?: DeviceModelStatus.StatusValue,
)
: string | undefined => {
  const { t } = useTranslation();

  if (statusValue === undefined) {
    return undefined;
  }

  if (statusDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  let message: string | undefined;
  const statusType = statusDescriptor.valueType.type;
  if (statusType === DeviceModelStatus.StatusDescriptorValueType.FLOAT
    || statusType === DeviceModelStatus.StatusDescriptorValueType.INTEGER
    // eslint-disable-next-line max-len
    || statusType === DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER) {
    // a range[] definition may only be present at numeric variable types
    const { range } = statusDescriptor.valueType;
    // check if value is an invalid number
    if (Number.isNaN(statusValue.value)) {
      message = t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT');
    } else if (range !== undefined) {
      // variable type has a range[] definition → check is value is in any range interval
      const isValueInAnyInterval = isInRangeInterval(Number(statusValue.value), range);
      // check if value exceeds total range bounds of variable
      if (!isValueInAnyInterval) {
        // value is in total range bounds but out of the range intervals of the variable
        const intervals = Array.from(range.regions).map((r) => `${r.minValue ?? ''}…${r.maxValue ?? ''}`);
        message = t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__INTERVALS', {
          INTERVAL: intervals.join(', '),
        });
      }
    }
  } else if (statusType === DeviceModelStatus.StatusDescriptorValueType.IPV4_ADDRESS) {
    message = validateIPaddress(statusValue.value) ? undefined
      : t<string>('PARAMETER_VALIDATION_ERROR__INVALID_IPADDRESS_FORMAT');
  } else if (statusType === DeviceModelStatus.StatusDescriptorValueType.MAC_ADDRESS) {
    message = validateMacAddress(statusValue.value) ? undefined
      : t<string>('PARAMETER_VALIDATION_ERROR__INVALID_MACADDRESS_FORMAT');
  } else if (statusType === DeviceModelStatus.StatusDescriptorValueType.DATE) {
    message = validateDate(statusValue.value) ? undefined
      : t<string>('PARAMETER_VALIDATION_ERROR__INVALID_DATE_FORMAT');
  } else if (statusDescriptor.valueType.type === DeviceModelStatus.StatusDescriptorValueType.STRING) {
    const maxLength = statusDescriptor.valueType.length;
    const value = statusValue?.value === undefined ? '' : `${statusValue?.value}`;
    message = maxLength < value.length
      ? t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__MAXLENGTH', {
        MAXLENGTH: `${maxLength}`,
      }) : undefined;
  }

  if (statusValue.valueValidity !== DeviceModelStatus.StatusValueValidity.valid
      && statusValue.valueValidityDescription && message === undefined) {
    return statusValue.valueValidityDescription;
  }

  return message;
};

export default useVariableValidationMessage;
