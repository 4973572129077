/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: Device model / driver for cxE device parametrization tool
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';

const deviceModelWizardDescriptor: DeviceModelStatus.DeviceModelDescriptorCollection = {
  WIZARD_INITIALDEVICESTARTUP: {
    type: DeviceModelStatus.StatusType.WizardDescriptor,
    identRef: 'WIZARD_INITIALDEVICESTARTUP',
    wizardType: {
      type: DeviceModelStatus.Wizards.WizardType.INITIALDEVICESTARTUP,
      menu: 'PARAMETERS',
      report: 'PREVIEW',
    },
  },
  WIZARD_CHANGEDEVICE: {
    type: DeviceModelStatus.StatusType.WizardDescriptor,
    identRef: 'WIZARD_CHANGEDEVICE',
    wizardType: {
      type: DeviceModelStatus.Wizards.WizardType.CHANGEDEVICE,
      report: 'PREVIEW',
      preview: 'PREVIEW',
    },
  },
  WIZARD_CREATEPARAMETERFILE: {
    type: DeviceModelStatus.StatusType.WizardDescriptor,
    identRef: 'WIZARD_CREATEPARAMETERFILE',
    wizardType: {
      type: DeviceModelStatus.Wizards.WizardType.CREATEPARAMETERFILE,
      menu: 'PARAMETERS',
      preview: 'PREVIEW',
    },
  },
  WIZARD_CXEEDITPARAMETERFILE: {
    type: DeviceModelStatus.StatusType.WizardDescriptor,
    identRef: 'WIZARD_CXEEDITPARAMETERFILE',
    wizardType: {
      type: DeviceModelStatus.Wizards.WizardType.CXEEDITPARAMETERFILE,
      menu: 'PARAMETERS',
      preview: 'PREVIEW',
    },
  },
  WIZARD_RESTOREDEVICE: {
    type: DeviceModelStatus.StatusType.WizardDescriptor,
    identRef: 'WIZARD_RESTOREDEVICE',
    wizardType: {
      type: DeviceModelStatus.Wizards.WizardType.RESTOREDEVICE,
      report: 'PREVIEW',
      preview: 'PREVIEW',
    },
  },
  WIZARD_TRANSFERPARAMETERFILE: {
    type: DeviceModelStatus.StatusType.WizardDescriptor,
    identRef: 'WIZARD_TRANSFERPARAMETERFILE',
    wizardType: {
      type: DeviceModelStatus.Wizards.WizardType.TRANSFERPARAMETERFILE,
      report: 'PREVIEW',
      preview: 'PREVIEW',
    },
  },
  WIZARD_DEVICESETTINGS: {
    type: DeviceModelStatus.StatusType.WizardDescriptor,
    identRef: 'WIZARD_DEVICESETTINGS',
    wizardType: {
      type: DeviceModelStatus.Wizards.WizardType.DEVICESETTINGS,
      menu: 'PARAMETERS',
      report: 'PREVIEW',
      preview: 'PREVIEW',
      display: 'EMPTYMENU',
      popupDisplay: 'EMPTYMENU',
    },
  },
  WIZARD_CXE_FIRMWARE_PARAMETER: {
    type: DeviceModelStatus.StatusType.WizardDescriptor,
    identRef: 'WIZARD_CXE_FIRMWARE_PARAMETER',
    wizardType: {
      type: DeviceModelStatus.Wizards.WizardType.CXE_FIRMWARE_PARAMETER,
      menu: 'PARAMETERS',
      report: 'PREVIEW',
      preview: 'PREVIEW',
    },
  },
  WIZARD_CXE_HARDWARE_SWITCH: {
    type: DeviceModelStatus.StatusType.WizardDescriptor,
    identRef: 'WIZARD_CXE_HARDWARE_SWITCH',
    wizardType: {
      type: DeviceModelStatus.Wizards.WizardType.CXE_HARDWARE_SWITCH,
      menu: 'PARAMETERS',
      report: 'EMPTYMENU',
      preview: 'EMPTYMENU',
    },
  },
};

export default deviceModelWizardDescriptor;
