/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import axios, { AxiosError } from 'axios';
import yaml from 'js-yaml';
import { ENV_CXEDPTSERVICE_UPDATE_LINK } from '../../enviroment';

export interface UpdateChannelInfoFile {
    url: string
    sha512: string;
    size: number;
  }

export interface UpdateChannelInfo {
    version: string;
    files: UpdateChannelInfoFile[];
    path: string;
    sha512: string;
    releaseDate: string;
  }

export const ExternalDownloadUrl = ENV_CXEDPTSERVICE_UPDATE_LINK;

export const checkAppVersion = (channel: string): Promise<UpdateChannelInfo> => {
  const url = `${ExternalDownloadUrl}/${channel}.yml`;

  return new Promise<UpdateChannelInfo>((resolve, reject) => {
    axios
      .get(url, { timeout: 1000 })
      .then((res) => yaml.load(res.data) as UpdateChannelInfo)
      .then((info) => resolve(info))
      .catch((err: AxiosError) => reject(err));
  });
};

export const parseAppVersion = (version: string): string => {
  const regVersion: RegExpMatchArray | null = version.match(/(.*?)\+.*/);
  return (regVersion && regVersion.length === 2) ? regVersion[1] : version;
};
