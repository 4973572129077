/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import React, { useContext } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReduxControlProps } from '../../ReduxControlProps';
import { useTypedSelector } from '../../../store';
import UnsupportedControl from '../../../components/UnsupportedControl/UnsupportedControl';
import { BargraphControlVerticalFigma } from '../../../components/BargraphControlVerticalFigma/BargraphControlVerticalFigma';
import {
  getGaugeDeviceValue, getGaugeSegmentStops, getGaugeSubtitle, getGaugeTitle, getGaugeVarDescriptor,
} from '../controlFunctions/controlFunctions';
import { GetDisplayFormat } from '../../../helpers/functions';
import { DatasetContext } from '../../../views/DatasetContext';
import withControlVisibility from '../../hoc/withControlVisibility';
import { deviceDescriptorSelector } from '../../../store/deviceInstances/store/deviceDataset/selector';
import { GaugeControlState, setGaugeIndicator } from '../../../store/deviceInstances/store/controlStateCollection';
import { deviceControlStateCollectionSelector } from '../../../store/deviceInstances/store/controlStateCollection/selectors';

export type BargraphVerReduxProps = ReduxControlProps

const BargraphVerReduxControl: React.FC<BargraphVerReduxProps> = (props: BargraphVerReduxProps): React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { targetDataset } = useContext(DatasetContext);

  const showIndicatorState = useTypedSelector((state) => (deviceControlStateCollectionSelector(state.deviceInstances, deviceInstanceId)?.[identRef]));
  const showIndicator = showIndicatorState !== undefined ? (showIndicatorState as GaugeControlState).showIndicator ?? false : false;

  const segmentStops = useTypedSelector((state) => getGaugeSegmentStops(state.deviceInstances, deviceInstanceId, targetDataset, identRef), shallowEqual);
  const gaugeVarDescriptor = useTypedSelector((state) => getGaugeVarDescriptor(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const deviceValue = useTypedSelector((state) => getGaugeDeviceValue(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const subtitle = useTypedSelector((state) => getGaugeSubtitle(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const title = useTypedSelector((state) => getGaugeTitle(state.deviceInstances, deviceInstanceId, targetDataset, identRef));

  const gaugeDescriptor = useTypedSelector((state) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  if (gaugeDescriptor === undefined) {
    return <UnsupportedControl text={`BargraphVerReduxControl: object ${identRef} undefined`} />;
  }

  if (gaugeDescriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="BargraphVerReduxControl: supported descriptor type is 'ControlDescriptor'" />;
  }

  if (gaugeDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE) {
    return <UnsupportedControl text="BargraphVerReduxControl: supported control type is 'CTLDYNVARIABLE'" />;
  }

  if (gaugeVarDescriptor?.visibility === false) {
    return (null);
  }

  const unit = gaugeVarDescriptor?.unit === undefined ? '' : t<string>(gaugeVarDescriptor.unit);
  const displayFormat = gaugeVarDescriptor !== undefined ? GetDisplayFormat(gaugeVarDescriptor) : '%.2f';

  return (
    <BargraphControlVerticalFigma
      bargraphTitle={t(title)}
      subtitle={subtitle}
      centerUnit={unit}
      currentValue={deviceValue ?? 0}
      segmentStops={segmentStops}
      showDragIndicator={showIndicator}
      displayFormat={displayFormat}
      segmentDisplayFormat="%d"
      setShowDragIndicator={(value) => {
        dispatch(setGaugeIndicator(deviceInstanceId, {
          ident: identRef,
          indicator: value,
        }));
      }}
    />
  );
};

export default withControlVisibility(BargraphVerReduxControl);
