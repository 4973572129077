/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { Services } from '@gpt/commons';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { controlChannelListSelector } from './selector/controlTypeSelector';
import CaparocChannelCurrentEditor from './ChannelCurrentEditor/ChannelCurrentEditor';
import ReduxChannelSwitch from './ReduxChannelSwitch/ReduxChannelSwitch';
import ChannelCounterReset from './CounterReset/CounterReset';
import { useTypedSelector } from '../../../../store';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import ImageControl from '../../../ImageControl/ImageControl';
import { ReduxControlProps } from '../../../ReduxControlProps';
import withControlVisibility from '../../../hoc/withControlVisibility';
import './OnlineCaparocTabControl.scss';
import { useDeviceConnectionStatus } from '../../../../store/deviceInstances/hooks/StandardViewHooks';

const ChannelSwitchBlockLabel = 'CAPAROC:locModuleOnlineParam__ChannelSwitch__LABEL';
const ChannelSwitchBlockHelp = 'CAPAROC:locModuleOnlineParam__ChannelSwitch__DISPLAY_HELP';
const ChannelCurrentBlockLabel = 'CAPAROC:locModuleOnlineParam__ChannelCurrent__LABEL';
const ChannelErrorCounterBlockLabel = 'CAPAROC:locTN_Channel_ErrorCounter__LABEL';

const OnlineCaparocTabControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const channelList = useTypedSelector((state) => controlChannelListSelector(state.deviceInstances, deviceInstanceId, DatasetType.device, identRef), shallowEqual);
  const connectionStatus = useDeviceConnectionStatus(deviceInstanceId);
  const disabled = connectionStatus === Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;

  const switchChannelControl = channelList.map((channelNo, index) => (
    <ReduxChannelSwitch
      label={`${index + 1}`}
      key={`online-caparoc-module-channel-switch${channelNo}`}
      channelNo={channelNo}
      deviceInstanceId={deviceInstanceId}
      disabled={disabled}
    />
  ));

  const nominalCurrentChannelControl = channelList.map((chno, index) => (
    <CaparocChannelCurrentEditor
      key={`online-caparoc-module-channel-editor-${chno}`}
      label={`CAPAROC:locTN_Menu_Ch${index + 1}__LABEL`}
      channelNo={chno}
      deviceInstanceId={deviceInstanceId}
      disabled={disabled}
    />
  ));

  const resetChannelControls = channelList.map((chno, index) => (
    <ChannelCounterReset
      key={`online-caparoc-module-channel-reset-${chno}`}
      label={`CAPAROC:locTN_Menu_Ch${index + 1}__LABEL`}
      channelNo={chno}
      deviceInstanceId={deviceInstanceId}
      disabled={disabled}
    />
  ));

  return (
    <div className="online-caparoc-tab-control">
      <div className="online-caparoc-tab-control__editor">
        <div className="online-caparoc-tab-control__editor__switch">
          <div className="online-caparoc-tab-control__editor__switch__title">
            {t(ChannelSwitchBlockLabel)}
          </div>
          <div className="online-caparoc-tab-control__editor__switch__controls">
            <div className="online-caparoc-tab-control__editor__switch__controls__switches">
              {switchChannelControl}
            </div>
            <div className="online-caparoc-tab-control__editor__switch__controls__text">
              {t(ChannelSwitchBlockHelp)}
            </div>
          </div>
        </div>
        <div className="online-caparoc-tab-control__editor__nominal_current">
          <div className="online-caparoc-tab-control__editor__nominal_current__title">
            {t(ChannelCurrentBlockLabel)}
          </div>
          <div className="online-caparoc-tab-control__editor__nominal_current__controls">
            {nominalCurrentChannelControl}
          </div>
        </div>
        <div className="online-caparoc-tab-control__editor__error_counter">
          <div className="online-caparoc-tab-control__editor__error_counter__title">
            {t(ChannelErrorCounterBlockLabel)}
          </div>
          <div className="online-caparoc-tab-control__editor__error_counter__controls">
            {resetChannelControls}
          </div>
        </div>
      </div>
      <div className="online-caparoc-tab-control__image">
        <div className="online-caparoc-tab-control__image__image">
          <ImageControl deviceInstanceId={deviceInstanceId} identRef="M_MODULE_ONLINE_PARAMETER_EDITOR__IMAGE" onStateChanged={() => 0} />
        </div>
      </div>
    </div>
  );
};

export default withControlVisibility(OnlineCaparocTabControl);
