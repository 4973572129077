/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import './TableArea.scss';
import StationAddressingTable from './Table/StationAddressingTable';
import StationAddressingFinishButton from './Button/StationAddressingButton';
import { CommissioningWizardState } from '../../hooks/useCommissioningWizardState';

export interface StationAddressingTableAreaProps {
  onFinishClick: () => void;
  wizardState: CommissioningWizardState;
}

const StationAddressingTableArea: React.FC<StationAddressingTableAreaProps> = (props: StationAddressingTableAreaProps)
: React.ReactElement => {
  const { onFinishClick, wizardState } = props;

  return (
    <div className="page-station-addressing__table contactron-commissioning-wizard">
      <div className="page-station-addressing-table-area">
        <div className="page-station-addressing-table-area__table">
          <StationAddressingTable />
        </div>
        <div className="page-station-addressing-table-area__button">
          <StationAddressingFinishButton disabled={wizardState !== CommissioningWizardState.Page2StationAddressingScanStart} onClick={onFinishClick} />
        </div>
      </div>
    </div>
  );
};

export default StationAddressingTableArea;
