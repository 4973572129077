import { DeviceModelStatus } from '@gpt/commons';
import fdcml10DeviceModelStatusDescriptorCollection from './deviceModelStatusDescriptor.fdcml10';

const deviceModelStatusDescriptorCollection: DeviceModelStatus.DeviceModelDescriptorCollection = {
  ...fdcml10DeviceModelStatusDescriptorCollection,
  DEVICE_POWER_STATUS: {
    identRef: 'DEVICE_POWER_STATUS',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:DEVICE_POWER_STATUS',
    help: '01_04_0F_14_0020:DEVICE_POWER_STATUS_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 1,
      enumerator: [{
        label: '01_04_0F_14_0020:DEVICE_POWER_OFF',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:DEVICE_POWER_ON',
        value: 1,
      }],
    },
  },
  DEVICE_STATUS: {
    identRef: 'DEVICE_STATUS',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:DEVICE_STATUS',
    help: '01_04_0F_14_0020:DEVICE_STATUS_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR,
      sizeInBits: 16,
      initialValue: 0,
      enumerator: [{
        label: '01_04_0F_14_0020:DEVICE_STATUS_NO_ERROR',
        value: 0,
      },
      {
        label: '01_04_0F_14_0020:DEVICE_STATUS_MODULE_ERROR',
        value: 1,
      },
      {
        label: '01_04_0F_14_0020:DEVICE_STATUS_SIMULATION',
        value: 2,
      },
      {
        label: '01_04_0F_14_0020:DEVICE_STATUS_MEASURE_CHANNEL_ERROR',
        value: 3,
      }],
    },
  },
  IFSM_ADDR_IO_R_SENSOR_VOLTAGE: {
    identRef: 'IFSM_ADDR_IO_R_SENSOR_VOLTAGE',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_SENSOR_VOLTAGE',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_SENSOR_VOLTAGE_HELP',
    description: '',
    unit: '01_04_0F_14_0020:locUNITmV__LABEL',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
      range: {
        regions: [{
          minValue: 0,
          maxValue: 2020,
        }],
      },
      stepSize: 0.01,
    },
  },
  IFSM_ADDR_IO_R_SENSOR_CURRENT: {
    identRef: 'IFSM_ADDR_IO_R_SENSOR_CURRENT',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_SENSOR_CURRENT',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_SENSOR_CURRENT_HELP',
    description: '',
    unit: 'mA',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
      range: {
        regions: [{
          minValue: 0,
          maxValue: 250,
        }],
      },
      stepSize: 0.01,
    },
  },
  IFSM_ADDR_IO_R_SENSOR_RESIST: {
    identRef: 'IFSM_ADDR_IO_R_SENSOR_RESIST',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_SENSOR_RESIST',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_SENSOR_RESIST_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_R_LINE_RESIST: {
    identRef: 'IFSM_ADDR_IO_R_LINE_RESIST',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_LINE_RESIST',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_LINE_RESIST_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_R_OUTPUT_CALC: {
    identRef: 'IFSM_ADDR_IO_R_OUTPUT_CALC',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_OUTPUT_CALC',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_OUTPUT_CALC_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
      range: {
        regions: [{
          minValue: 0,
          maxValue: 20,
        }],
      },
      stepSize: 0.1,
    },
  },
  IFSM_ADDR_IO_R_TEMPERATURE_C: {
    identRef: 'IFSM_ADDR_IO_R_TEMPERATURE_C',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_TEMPERATURE_C',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_TEMPERATURE_C_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_R_TEMPERATURE_F: {
    identRef: 'IFSM_ADDR_IO_R_TEMPERATURE_F',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_TEMPERATURE_F',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_TEMPERATURE_F_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_R_OUTPUT_UNIT: {
    identRef: 'IFSM_ADDR_IO_R_OUTPUT_UNIT',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_OUTPUT_UNIT',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_OUTPUT_UNIT_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_R_DIPSWITCH_S1: {
    identRef: 'IFSM_ADDR_IO_R_DIPSWITCH_S1',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_DIPSWITCH_S1',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_DIPSWITCH_S1_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_R_DIPSWITCH_S3: {
    identRef: 'IFSM_ADDR_IO_R_DIPSWITCH_S3',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_DIPSWITCH_S3',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_DIPSWITCH_S3_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_RW_DEVICE_STATE: {
    identRef: 'IFSM_ADDR_IO_R_SENSOR_RESIST',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_R_SENSOR_RESIST',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_R_SENSOR_RESIST_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_RW_PWM_OUT_POSITIV: {
    identRef: 'IFSM_ADDR_IO_RW_PWM_OUT_POSITIV',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_PWM_OUT_POSITIV',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_PWM_OUT_POSITIV_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_RW_PWM_OUT_NEGATIV: {
    identRef: 'IFSM_ADDR_IO_RW_PWM_OUT_NEGATIV',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_PWM_OUT_NEGATIV',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_PWM_OUT_NEGATIV_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_RW_ANALOG_OUT: {
    identRef: 'IFSM_ADDR_IO_RW_ANALOG_OUT',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_ANALOG_OUT',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_ANALOG_OUT_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_RW_ANALOG_OUT_ABS: {
    identRef: 'IFSM_ADDR_IO_RW_ANALOG_OUT_ABS',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_ANALOG_OUT_ABS',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_ANALOG_OUT_ABS_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_RW_ERROR_CONTACT_STATE: {
    identRef: 'IFSM_ADDR_IO_RW_ERROR_CONTACT_STATE',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_ERROR_CONTACT_STATE',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_ERROR_CONTACT_STATE_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_RW_MODULE_STATE: {
    identRef: 'IFSM_ADDR_IO_RW_MODULE_STATE',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_MODULE_STATE',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_MODULE_STATE_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  IFSM_ADDR_IO_RW_CHANNEL_STATE: {
    identRef: 'IFSM_ADDR_IO_RW_CHANNEL_STATE',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_CHANNEL_STATE',
    help: '01_04_0F_14_0020:IFSM_ADDR_IO_RW_CHANNEL_STATE_HELP',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_ONLY,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 16,
      initialValue: 0,
    },
  },
  LINEARIZATION_TABLE: {
    identRef: 'LINEARIZATION_TABLE',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01-04-0f-0e-0020-2902049:locLINEARIZATION_TABLE__LABEL',
    help: '01-04-0f-0e-0020-2902049:locLINEARIZATION_TABLE__HELP',
    description: '01-04-0f-0e-0020-2902049:locLINEARIZATION_TABLE__DESCRIPTION',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.TABLE,
      records: [
        'recLinTable_00', 'recLinTable_01',
      ],
    },

    onValueChanged: [],
  },
  recLinTable_00: {
    identRef: 'recLinTable_00',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '',
    help: '',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.TABLERECORD,
      members: ['recLinTable_01_0000', 'recLinTable_01_0001'],
    },

    onValueChanged: [],
  },
  recLinTable_01: {
    identRef: 'recLinTable_01',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '',
    help: '',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.TABLERECORD,
      members: ['recLinTable_01_0000', 'recLinTable_01_0001'],
    },

    onValueChanged: [],
  },
  recLinTable_01_0000: {
    identRef: 'recLinTable_01_0000',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '',
    help: '',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    unit: '01_04_0F_14_0020:°C',
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },

    onValueChanged: [],
  },
  recLinTable_01_0001: {
    identRef: 'recLinTable_01_0001',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '',
    help: '',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    unit: '01_04_0F_14_0020:°C',
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },

    onValueChanged: [],
  },
  LINEARIZATION_USER_POINT_NUM: {
    identRef: '01_04_0F_14_0020:LINEARIZATION_USER_POINT_NUM',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:LINEARIZATION_USER_POINT_NUM__LABEL',
    help: '01_04_0F_14_0020:locLINEARIZATION_USER_POINT_NUM__HELP',
    description: '01_04_0F_14_0020:locLINEARIZATION_USER_POINT_NUM__DESC',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER,
      sizeInBits: 32,
      initialValue: 30,
    },

    onValueChanged: [],
  },
  LINEARIZATION_TABLE__SPLINEBLOBDATA: {
    identRef: 'LINEARIZATION_TABLE__SPLINEBLOBDATA',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '',
    help: '',
    description: '',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.BLOB,
      initialValue: 'AA',
      length: 10,
    },
  },

  LINEARIZATION_TABLE__MINDEVIATION: {
    identRef: 'LINEARIZATION_TABLE__MINDEVIATION',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:LINEARIZATION_TABLE__MINDEVIATION__LABEL',
    help: '01_04_0F_14_0020:locLINEARIZATION_TABLE__MINDEVIATION__HELP',
    description: '01_04_0F_14_0020:locLINEARIZATION_TABLE__MINDEVIATION__DESC',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },

    onValueChanged: [],
  },
  LINEARIZATION_TABLE__COLD80: {
    identRef: 'LINEARIZATION_TABLE__COLD80',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:LINEARIZATION_TABLE__COLD80__LABEL',
    help: '01_04_0F_14_0020:locLINEARIZATION_TABLE__COLD80__HELP',
    description: '01_04_0F_14_0020:locLINEARIZATION_TABLE__COLD80__DESC',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    unit: '01_04_0F_14_0020:locUNITC__LABEL',
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },

    onValueChanged: [],
  },
  LINEARIZATION_TABLE__COLD0: {
    identRef: 'LINEARIZATION_TABLE__COLD0',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01-04-0f-0e-0020-2902049:locLINEARIZATION_TABLE__COLD0__LABEL',
    help: '01-04-0f-0e-0020-2902049:locLINEARIZATION_TABLE__COLD0__HELP',
    description: '01-04-0f-0e-0020-2902049:locLINEARIZATION_TABLE__COLD0__DESC',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    unit: '01-04-0f-0e-0020-2902049:locUNITC__LABEL',
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },

    onValueChanged: [],
  },
  LINEARIZATION_TABLE__COLDLINEEQB: {
    identRef: 'LINEARIZATION_TABLE__COLDLINEEQB',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01_04_0F_14_0020:LINEARIZATION_TABLE__COLDLINEQB_LABEL',
    help: '01_04_0F_14_0020:locLINEARIZATION_TABLE__COLDLINEQB__HELP',
    description: '01_04_0F_14_0020:locLINEARIZATION_TABLE__COLDLINEQB__DESC',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    unit: '01_04_0F_14_0020:locUNITC__LABEL',
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },

    onValueChanged: [],
  },
  LINEARIZATION_TABLE__COLDLINEEQM: {
    identRef: 'LINEARIZATION_TABLE__COLDLINEEQM',
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    label: '01-04-0f-0e-0020-2902049:locLINEARIZATION_TABLE__COLDLINEQM_LABEL',
    help: '01-04-0f-0e-0020-2902049:locLINEARIZATION_TABLE__COLDLINEQM__HELP',
    description: '01-04-0f-0e-0020-2902049:locLINEARIZATION_TABLE__COLDLINEQM__DESC',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    unit: '01-04-0f-0e-0020-2902049:locUNITC__LABEL',
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.FLOAT,
      initialValue: 0,
    },

    onValueChanged: [],
  },
  VARIABLE_DIP_SWITCH__S1: {
    type: DeviceModelStatus.StatusType.StatusDescriptor,
    identRef: 'VARIABLE_DIP_SWITCH__S1',
    handling: DeviceModelStatus.StatusDescriptorHandling.READ_WRITE,
    help: '',
    description: '',
    label: 'DIP S1',
    validity: true,
    visibility: true,
    valueType: {
      type: DeviceModelStatus.StatusDescriptorValueType.INTEGER,
      initialValue: 0x55,
      sizeInBits: 16,
    },
  },
};

export default deviceModelStatusDescriptorCollection;
