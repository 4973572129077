/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import { EventMonitorList, EventMonitorListItem } from '../EventMonitorList/EventMonitorList';
import { EventMonitorTemplate } from '../EventMonitorTemplate/EventMonitorTemplate';
import './EventMonitor.css';

export interface EventMonitorProps extends PropsWithChildren<any> {
  eventList: EventMonitorListItem[];
}

// eslint-disable-next-line max-len
export const EventMonitor: React.FC<EventMonitorProps> = (props: EventMonitorProps):
  React.ReactElement => {
  const { eventList, children } = props;

  return (
    <div className="event-monitor">
      <EventMonitorTemplate isHeader>
        <div>{ children[0] }</div>
        <div>{ children[1] }</div>
        <div>{ children[2] }</div>
      </EventMonitorTemplate>
      <EventMonitorList eventList={eventList} />
    </div>
  );
};
