/* eslint-disable @typescript-eslint/no-empty-function */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../store';
import './SelectDevice.scss';
import { contactronWizardSelectModule } from '../../../../../../store/wizards/contactronStation';

export interface SelectDeviceProps {
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType;
}

const SelectDevice: React.FC<SelectDeviceProps> = (props: SelectDeviceProps):React.ReactElement => {
  const { stationDeviceDescription } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moduleList = useTypedSelector((state) => state.contactronInitWizard.moduleList);
  const modules = useTypedSelector((state) => state.contactronInitWizard.modules);
  const selectedModule = useTypedSelector((state) => state.contactronInitWizard.selectedModule);

  const moduleControlList = moduleList
    .filter((device) => modules[device].type === 'module')
    .map((device) => (
      <div
        className={`select-device-devices-item ${selectedModule === device
          ? 'select-device-devices-item-selected' : 'select-device-devices-item-unselected'}`}
        key={`select-device-${modules[device].instance.address}`}
        role="button"
        onClick={() => {
          dispatch(contactronWizardSelectModule(modules[device].instance.address));
        }}
        onKeyUp={() => {}}
        tabIndex={0}
      >
        {`${modules[device].instance.address} - `}
        {stationDeviceDescription[modules[device].moduleId]?.deviceIdent.deviceTypeName ?? ''}
      </div>
    ));

  return (
    <div className="select-device">
      <div className="select-device-title">
        <div className="select-device-title-text">{t('WIZARD_CONTACTRON__PAGE5_SELECT_DEVICE')}</div>
      </div>
      <div className="select-device-devices">
        <div className="select-device-devices-item-container">
          {moduleControlList}
        </div>
      </div>
    </div>
  );
};

export default SelectDevice;
