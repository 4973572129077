/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  IoLinkSystemCommandState,
  typeIoLinkSystemCommandActions,
  IOLINK__SYSTEM_COMMAND__SET_STATE,
  IoLinkSetCommandStateAction,
  IoLinkSystemCommandStage,
} from './types';

export const initialIoLinkSystemCommandState: IoLinkSystemCommandState = {
  state: IoLinkSystemCommandStage.stageInitial,
};

const ioLinkActionSetStateReducer = (st: IoLinkSystemCommandState, action: IoLinkSetCommandStateAction)
: IoLinkSystemCommandState => ({
  ...st,
  state: action.payload.stage,
});

const reducerSwitchFunction = {
  [IOLINK__SYSTEM_COMMAND__SET_STATE]: ioLinkActionSetStateReducer,
};

const ioLinkSystemCommandStateReducer = (
  state = initialIoLinkSystemCommandState,
  action: typeIoLinkSystemCommandActions,
): IoLinkSystemCommandState => (reducerSwitchFunction[action.type] === undefined
  ? state
  : reducerSwitchFunction[action.type](state, action));

export default ioLinkSystemCommandStateReducer;
