/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Resource } from 'i18next';

import i18nTRIO3PSEn from './TRIO3_PS_en.json';
import i18nTRIO3PSDe from './TRIO3_PS_de.json';
import i18nTRIO3PSEs from './TRIO3_PS_es.json';
import i18nTRIO3PSFr from './TRIO3_PS_fr.json';
import i18nTRIO3PSIt from './TRIO3_PS_it.json';
import i18nTRIO3PSJa from './TRIO3_PS_ja.json';
import i18nTRIO3PSPl from './TRIO3_PS_pl.json';
import i18nTRIO3PSPt from './TRIO3_PS_pt.json';
import i18nTRIO3PSRu from './TRIO3_PS_ru.json';
import i18nTRIO3PSTr from './TRIO3_PS_tr.json';
import i18nTRIO3PSZh from './TRIO3_PS_zh.json';
import i18nTRIO3PSCs from './TRIO3_PS_cs.json';
import i18nTRIO3PSDa from './TRIO3_PS_da.json';
import i18nTRIO3PSFi from './TRIO3_PS_fi.json';
import i18nTRIO3PSHu from './TRIO3_PS_hu.json';
import i18nTRIO3PSKo from './TRIO3_PS_ko.json';
import i18nTRIO3PSNb from './TRIO3_PS_nb.json';
import i18nTRIO3PSNl from './TRIO3_PS_nl.json';
import i18nTRIO3PSRo from './TRIO3_PS_ro.json';
import i18nTRIO3PSSv from './TRIO3_PS_sv.json';
import i18nTRIO3PSUk from './TRIO3_PS_uk.json';

/**
 * Default translation for application
 */
const bundledResources: Resource = {
  en: {
    TRIO3_PS: i18nTRIO3PSEn,
  },
  de: {
    TRIO3_PS: i18nTRIO3PSDe,
  },
  es: {
    TRIO3_PS: i18nTRIO3PSEs,
  },
  fr: {
    TRIO3_PS: i18nTRIO3PSFr,
  },
  it: {
    TRIO3_PS: i18nTRIO3PSIt,
  },
  ja: {
    TRIO3_PS: i18nTRIO3PSJa,
  },
  pl: {
    TRIO3_PS: i18nTRIO3PSPl,
  },
  pt: {
    TRIO3_PS: i18nTRIO3PSPt,
  },
  ru: {
    TRIO3_PS: i18nTRIO3PSRu,
  },
  tr: {
    TRIO3_PS: i18nTRIO3PSTr,
  },
  zh: {
    TRIO3_PS: i18nTRIO3PSZh,
  },
  cs: {
    TRIO3_PS: i18nTRIO3PSCs,
  },
  da: {
    TRIO3_PS: i18nTRIO3PSDa,
  },
  fi: {
    TRIO3_PS: i18nTRIO3PSFi,
  },
  hu: {
    TRIO3_PS: i18nTRIO3PSHu,
  },
  ko: {
    TRIO3_PS: i18nTRIO3PSKo,
  },
  nb: {
    TRIO3_PS: i18nTRIO3PSNb,
  },
  nl: {
    TRIO3_PS: i18nTRIO3PSNl,
  },
  ro: {
    TRIO3_PS: i18nTRIO3PSRo,
  },
  sv: {
    TRIO3_PS: i18nTRIO3PSSv,
  },
  uk: {
    TRIO3_PS: i18nTRIO3PSUk,
  },
};

export default bundledResources;
