/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import useDelayedUserInput from '../../../../controls/hooks/useDelayedUserInput';
import DisplayFormat from '../../../../helpers/displayFormat';
import './BasicTextEditor.css';

export interface BasicTextEditorProps {
    label?: string;
    value: string;
    displayFormat: string;
    warning?: string;
    readonly?: boolean;
    modified?: boolean;
    stepSize?: number;
    onValueChange: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

const BasicTextEditor:
React.FC<BasicTextEditorProps> = (props: BasicTextEditorProps):
React.ReactElement => {
  const {
    label, value, stepSize,
    warning, readonly, modified, displayFormat,
    onValueChange, onFocus, onBlur,
  } = props;
  const classNameEditedText = (modified ?? false) ? 'textfield__input--edited' : '';

  const [focusActive, setActiveFocus] = useState(false);
  const [
    userInputValue,
    currentWarning,
    setUserInputValue,
    onBlurValue,
  ] = useDelayedUserInput(value, warning, onValueChange);

  const currentValue = userInputValue ?? value;
  const visibleValue = focusActive
    ? currentValue : DisplayFormat(displayFormat, currentValue);

  return (
    <div className="text-input-field">
      <input
        type="text"
        title={label}
        value={visibleValue}
        step={stepSize}
        className={`textfield__input ${classNameEditedText} ${currentWarning !== undefined ? 'invalid' : ''}`}
        readOnly={readonly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setUserInputValue(e.target.value);
        }}
        onFocus={() => {
          setActiveFocus(true);
          onFocus?.();
        }}
        onBlur={() => {
          setActiveFocus(false);
          onBlurValue(currentValue);
          onBlur?.();
        }}
        data-testid="text-editor-control"
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="textfield__label">{}</label>
      {/* <span className="helper-text" data-error={currentWarning} /> */}
      <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
        <div className="input-field-control__warning-text">{currentWarning}</div>
      </div>
    </div>
  );
};

export default BasicTextEditor;
