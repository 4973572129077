/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './StatusMessage.css';

export type StatusMessageType = 'hidden' | 'ok' | 'warning' | 'error';

export interface StatusMessageProps {
  type: StatusMessageType;
  message: string;
}

const setColor = (messageType: StatusMessageType): string => {
  let color = 'green';

  if (messageType === 'error') {
    color = 'red';
  } else if (messageType === 'warning') {
    color = 'yellow';
  }

  return color;
};

export const StatusMessage: React.FC<StatusMessageProps> = (props: StatusMessageProps):
React.ReactElement | null => {
  const {
    type, message,
  } = props;

  if (type === 'hidden') {
    return (null);
  }

  const color = setColor(type);

  return (
    <div className={`status-message-container status-message-${color}`}>
      <span className="status-message-container-text">{message}</span>
    </div>
  );
};
