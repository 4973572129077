/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useContextDatasetNumberProperty } from '../../../../hooks/useContextDataset';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';

const ANALOG_MODE__VOLTAGE = 0;
const ANALOG_MODE__CURRENT = 1;
// const ANALOG_MODE__POWER = 2;

const useAnalogLimitValues = (deviceInstanceId: string) => {
  const dispatch = useDispatch();

  const propVoltageEndValue = useContextDatasetNumberProperty(deviceInstanceId, 'analogVoltageMaxDefault', DatasetType.user);
  const propCurrentEndValue = useContextDatasetNumberProperty(deviceInstanceId, 'analogCurrentMaxDefault', DatasetType.user);
  const propPowerEndValue = useContextDatasetNumberProperty(deviceInstanceId, 'analogPowerMaxDefault', DatasetType.user);

  const onModeChangeHandler = useCallback((
    mode: number,
    signalSelectorStatusValue?: DeviceModelStatus.StatusValue,
    analogStartStatusValue?: DeviceModelStatus.StatusValue,
    analogEndStatusValue?: DeviceModelStatus.StatusValue,
  ) => {
    if (signalSelectorStatusValue === undefined || analogStartStatusValue === undefined || analogEndStatusValue === undefined) {
      return;
    }

    let analogStartValue = 0;
    let analogEndValue = 0;
    if (mode === ANALOG_MODE__VOLTAGE) {
      analogStartValue = 0;
      analogEndValue = propVoltageEndValue ?? 15;
    } else if (mode === ANALOG_MODE__CURRENT) {
      analogStartValue = 0;
      analogEndValue = propCurrentEndValue ?? 5;
    } else {
      analogStartValue = 0;
      analogEndValue = propPowerEndValue ?? 120;
    }

    dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
      value: mode,
      backupValue: signalSelectorStatusValue.backupValue,
      identRef: signalSelectorStatusValue.identRef,
    },
    {
      value: analogStartValue,
      backupValue: analogStartStatusValue.backupValue,
      identRef: analogStartStatusValue.identRef,
    },
    {
      value: analogEndValue,
      backupValue: analogEndStatusValue.backupValue,
      identRef: analogEndStatusValue.identRef,
    }]));
  }, [
    deviceInstanceId,
    propVoltageEndValue,
    propCurrentEndValue,
    propPowerEndValue,
  ]);

  return onModeChangeHandler;
};

export default useAnalogLimitValues;
