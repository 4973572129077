/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import './PaperControl.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PaperControlProps extends PropsWithChildren<any> {
  title?: string,
}

export const PaperControl: React.FC<PaperControlProps> = (props: PaperControlProps): React.ReactElement => {
  const {
    title, children,
  } = props;

  return (
    <div className="paper-control" data-testid="paper-control">
      {title && (
        <div className="paper-control__header">
          <p className="paper-control__header-title">{title}</p>
        </div>
      )}
      <div className="paper-control__content d-flex flex-column justify-content-start align-items-start h-100">
        {children}
      </div>
    </div>
  );
};
