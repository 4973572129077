/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './DeviceNamingHeader.scss';
import { useTranslation } from 'react-i18next';

const DeviceNamingHeader: React.FC = () : React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="page-device-naming__header contactron-commissioning-wizard">
      <div className="page-device-naming-header">
        {t('WIZARD_CONTACTRON__PAGE4_DEVICE_NAMING__TEXT')}
      </div>
    </div>
  );
};

export default DeviceNamingHeader;
