/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import {
  Col,
  OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IconCheck from '../../../assets/icons/icon-check-blue.svg';
import { LoadingSpinner } from '../../LoadingControl/LoadingSpinner';
import { DeviceMethodExecutionStep } from '../../../store/deviceInstances/store/deviceMethod/types';

interface ActionProgressStepsProps extends PropsWithChildren<unknown> {
  title: string;
}

interface ActionProgressStepProps {
  title: string;
}

const ActionProgressStep: React.FC<ActionProgressStepsProps> = (props: ActionProgressStepsProps): React.ReactElement => {
  const { t } = useTranslation();
  const { title, children } = props;
  return (
    <Row key={title} className="pt-2">
      <Col xs={8} className="d-flex justify-content-end text-right align-items-center w-100">
        <div className="action-progress-step__title">{t<string>(title)}</div>
      </Col>
      <Col xs={4}>
        <div className="action-progress-step__status">
          {children}
        </div>
      </Col>
    </Row>
  );
};

export const CompleteProgressStep: React.FC<ActionProgressStepProps> = (props: ActionProgressStepProps): React.ReactElement => {
  const { title } = props;
  return (
    <ActionProgressStep title={title}>
      <div className="action-progress-step__status--completed">
        <img src={IconCheck} alt={title} />
      </div>
    </ActionProgressStep>
  );
};

export const RunningProgressStep: React.FC<ActionProgressStepProps> = (props: ActionProgressStepProps): React.ReactElement => {
  const { title } = props;
  return (
    <ActionProgressStep title={title}>
      <div className="action-progress-step__status--running">
        <LoadingSpinner />
      </div>
    </ActionProgressStep>
  );
};

export const InitProgressStep: React.FC<ActionProgressStepProps> = (props: ActionProgressStepProps): React.ReactElement => {
  const { title } = props;
  const { t } = useTranslation();
  return (
    <ActionProgressStep title={title}>
      <div className="action-progress-step__status--init">
        {t<string>('TEXTUAL_PROGRESS_PENDING')}
      </div>
    </ActionProgressStep>
  );
};

export const FailedProgressStep: React.FC<DeviceMethodExecutionStep> = (props: DeviceMethodExecutionStep): React.ReactElement => {
  const { t } = useTranslation();
  const { message, title } = props;
  const renderTooltip = (tooltipprops) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip {...tooltipprops}>{t<string>(message ?? '')}</Tooltip>
  );
  const control = message === undefined
    ? (
      <div className="action-progress-step__status--failed">⚠</div>
    )
    : (
      <OverlayTrigger placement="top" overlay={renderTooltip}>
        <div className="action-progress-step__status--failed">⚠</div>
      </OverlayTrigger>
    );
  return (
    <ActionProgressStep title={title}>
      {control}
    </ActionProgressStep>
  );
};
