/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  DatasetProviderState, DATASET_PROVIDER__SET_DATASET_LIST, typeDatasetProviderAction,
} from './types';

export const initialDatasetProviderState: DatasetProviderState = {
  datasetList: [],
};

const datasetProviderReducer = (
  state = initialDatasetProviderState,
  action: typeDatasetProviderAction,
): DatasetProviderState => {
  if (action.type === DATASET_PROVIDER__SET_DATASET_LIST) {
    return {
      ...state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      datasetList: (action as any).payload,
    };
  }
  return state;
};

export default datasetProviderReducer;
