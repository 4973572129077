import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { DatasetState } from '../../store/deviceInstances/store/deviceDataset/types';

// eslint-disable-next-line max-len
const controlTypeSelector = <T>(controlType: string, identRef: IdentRef, dataset?: DatasetState): T | undefined => {
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }

  if (descriptor.controlType.type !== controlType) {
    return undefined;
  }

  return (descriptor.controlType) as T;
};

export default controlTypeSelector;
