/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useContext, useEffect, useState } from 'react';
import { Services } from '@gpt/commons';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reduxStore';
import { deviceWizardUpdateButtons } from '../../../store';
import { ButtonState, ButtonRole } from '../../../store/deviceWizard/types';
import { MIME_TYPE__CXEDP, FILE_EXTENTION__CXEDP } from '../../../helpers/constants';
import { LoadingControl } from '../../../components/LoadingControl/LoadingControl';
import PreviewDeviceDatasetReduxControl from '../../../controls/PreviewDeviceDatasetReduxControl/PreviewDeviceDatasetReduxControl';
import FileSystemSaveControl from '../../../components/FileSystemSaveControl/FileSystemSaveControl';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';
import createDeviceDatasetBackupContent from '../../../helpers/createDeviceDatasetBackupContent';
import { createDatasetIdent } from '../../../helpers/functions';
import { WebWorkerContext } from '../../../WebWorkerContext';

// eslint-disable-next-line no-unused-vars
enum CTAButtonType {
  // eslint-disable-next-line no-unused-vars
  Complete,
  // eslint-disable-next-line no-unused-vars
  SaveParameters,
}

export interface CPFilePageDocumentationProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const CPFilePageDocumentation: React.FC<CPFilePageDocumentationProps> = (props: CPFilePageDocumentationProps)
  : React.ReactElement => {
  const { t } = useTranslation();
  const { active, previewMenu, deviceInstanceId } = props;

  const { webWorkerDeviceManager } = useContext(WebWorkerContext);
  const activeDeviceInstance = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId));
  const datasetValid = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.user)?.datasetValid ?? true);
  const [ctaButton, setCTAButton] = useState<CTAButtonType>(CTAButtonType.SaveParameters);
  const dispatch = useDispatch();

  useEffect(() => {
    if (active !== true) {
      return;
    }

    const pcta = ctaButton === CTAButtonType.Complete ? ButtonRole.complete : ButtonRole.next;

    dispatch(deviceWizardUpdateButtons({
      source: 'CPFilePageDocumentation',
      abort: ButtonState.enabled,
      back: ButtonState.enabled,
      next: ButtonState.hidden,
      complete: datasetValid ? ButtonState.enabled : ButtonState.disabled,
      pcta,
      focusPcta: pcta,
    }));
  }, [ctaButton, datasetValid, active]);

  if (activeDeviceInstance === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const activeDevice = activeDeviceInstance.device;
  const previewControl = previewMenu === undefined
    ? undefined
    : (
      <PreviewDeviceDatasetReduxControl
        deviceInstanceId={deviceInstanceId}
        caption={t<string>('WIZARD_CREATE_PARAMETER_FILE__FINAL_PAGE__DATASET_PREVIEW')}
        identRef={previewMenu}
      />
    );

  return (
    <Container className="w-100 h-100" fluid>
      <Row className="h-100">
        <Col xs={6} className="preview-control--frame-padding h-100">
          {previewControl}
        </Col>
        <Col xs={6}>
          <div className="pb-4">
            <p>{t<string>('WIZARD_CREATE_PARAMETER_FILE__FINAL_PAGE__SAVE_PARAMETER_DIALOG__DESCRIPTION')}</p>
            <FileSystemSaveControl
              caption={t<string>('WIZARD_CREATE_PARAMETER_FILE__FINAL_PAGE__SAVE_PARAMETER_DIALOG__CAPTION')}
              fileName={`${activeDevice.catalog.deviceDriverId}`}
              mimeType={MIME_TYPE__CXEDP}
              extensions={[FILE_EXTENTION__CXEDP]}
              getContents={async (): Promise<string> => createDeviceDatasetBackupContent({
                webWorkerDeviceManager,
                activeDevice: activeDeviceInstance,
                caption: 'Caption',
                datasetId: createDatasetIdent('backup'),
                datasetType: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType.COMPLETE,
                previewMenu,
              })}
              button={{
                block: true,
                variant: ctaButton === CTAButtonType.SaveParameters ? 'primary' : 'tertiary',
                disabled: !datasetValid,
              }}
              onDone={() => setCTAButton(CTAButtonType.Complete)}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CPFilePageDocumentation;
