/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useContextDatasetDescriptorLabel } from '../../../../hooks/useContextDataset';
import { setContextParameterHelp } from '../../../../store/contexthelp';
import { CompareModeType } from '../../../../views/CompareView/common';
import { CompareStatusValueProps } from '../../CompareTypes';
import CompareContainer from '../CompareContainer/CompareContainer';
import CompareStatusValue from '../StatusValues/CompareStatusValue';
import useCompareLinTableMembers from './hooks/useCompareLinTableMembers';
import useLintableMemberDescriptors from './hooks/useLintableMemberDescriptors';
import useLintableMidButtonStatus from './hooks/useLintableMidButtonStatus';
import './CompareLinTable.css';

const CompareLinTable
  : React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
    : React.ReactElement | null => {
    const {
      identRef, leftDataset, rightDataset, compareMode, parentId,
      deviceInstanceId, onShowContentHelp, onHideContentHelp,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const label = useContextDatasetDescriptorLabel(deviceInstanceId, identRef, leftDataset);
    const memberDescriptors = useLintableMemberDescriptors(deviceInstanceId, identRef, leftDataset);
    // eslint-disable-next-line max-len
    const [butFunction, action] = useLintableMidButtonStatus(deviceInstanceId, identRef, leftDataset, rightDataset);

    const equal = useCompareLinTableMembers(deviceInstanceId, identRef, leftDataset, rightDataset);
    if (compareMode === CompareModeType.ShowDifference && equal) {
      return (null);
    }

    const groupElements = memberDescriptors.reduce((acc, descriptor) => {
      const showContentHelp = () => {
        dispatch(setContextParameterHelp({
          title: t<string>(descriptor.label),
          text: t<string>(descriptor.help),
        }));
        onShowContentHelp?.();
      };
      const hideContentHelp = () => {
        dispatch(setContextParameterHelp({
          title: '',
          text: '',
        }));
        onHideContentHelp?.();
      };

      const compareControl = (
        <CompareStatusValue
          parentId={`cmp-lintable-${parentId}--${identRef}`}
          key={`cmp-lintable-${parentId}--${identRef}--${descriptor.identRef}`}
          compareMode={compareMode}
          identRef={descriptor.identRef}
          leftDataset={leftDataset}
          rightDataset={rightDataset}
          onShowContentHelp={showContentHelp}
          onHideContentHelp={hideContentHelp}
          onMidButtonClick={() => 0}
          showImageAndSign={false}
          deviceInstanceId={deviceInstanceId}
        />
      );
      return [
        ...acc,
        compareControl,
      ];
    }, [] as React.ReactElement[]);

    return (
      <div>
        <div className="lin-table-elements">
          <div className="lin-table-sign">
            <CompareContainer
              label={label ?? ''}
              buttonFunction={butFunction}
              equal={equal}
              onButtonClick={() => action(butFunction)}
            >
              <></>
              <></>
            </CompareContainer>
          </div>
          {groupElements}
        </div>
      </div>
    );
  };

export default CompareLinTable;
