/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { sPortMinMCRWriteAction } from '../../../../../store/deviceInstances/middleware/deviceMethod';
import { ForceDialogContext, ForceDialogPasswordContext } from '../../../Context/ForceDialogContext';
import { ForceDialogControl } from '../../../ForceDialogControl/ForceDialogControl';

export interface ForceAnalogButtonProps {
  deviceInstanceId: string;
  button: DeviceModelStatus.UI.ForceAnalogOutputButton;
  value: number;
  actionId: string;
  disabled: boolean;
}

const ForceAnalogButton: React.FC<ForceAnalogButtonProps> = (props: ForceAnalogButtonProps):
  React.ReactElement => {
  const {
    deviceInstanceId,
    disabled,
    button,
    actionId,
    value,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { enableWarningDialog, setEnableWarningDialog } = useContext(ForceDialogContext);
  const { password } = useContext(ForceDialogPasswordContext);

  const [showDialog, setShowDialog] = useState(false);
  const [enabled, setEnabled] = useState(true);

  return (
    <>
      <Button
        className="forceanalogeditor-button"
        block
        variant="secondary"
        onClick={() => {
          if (enableWarningDialog) {
            setShowDialog(true);
          } else {
            dispatch(sPortMinMCRWriteAction({
              actionId,
              targetInstance: deviceInstanceId,
              memoryAddress: button.address,
              ui16value: value * button.scale,
              password,
            }));
          }
        }}
        disabled={disabled || !enabled}
      >
        {t<string>(button.label)}
      </Button>
      <ForceDialogControl
        title={button.title}
        message={button.message}
        actionId={actionId}
        showDialog={showDialog}
        deviceInstanceId={deviceInstanceId}
        onAccept={() => {
          setEnabled(false);
          dispatch(sPortMinMCRWriteAction({
            actionId,
            targetInstance: deviceInstanceId,
            memoryAddress: button.address,
            ui16value: value * button.scale,
            password,
          }));
          setEnableWarningDialog(false);
          setShowDialog(false);
        }}
        onCancel={() => {
          setShowDialog(false);
        }}
        onFailed={() => {
          setEnabled(true);
        }}
        onSuccess={() => {
          setEnabled(true);
        }}
      />
    </>
  );
};

export default ForceAnalogButton;
