/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  CreateReportState, ReportStatus, SetReportStatusAction, ReportServiceActionTypes,
} from './types';

const initialState: CreateReportState = {
  status: ReportStatus.INIT,
};

const reportServiceSwitch = {
  REPORT_SERVICE__SET_REPORT_STATUS: (state: CreateReportState, action: SetReportStatusAction)
  : CreateReportState => ({
    ...state,
    status: action.payload,
  }),
};

const activeDeviceStateReducer = (
  state = initialState,
  action: ReportServiceActionTypes,
): CreateReportState => (reportServiceSwitch[action.type] === undefined ? state
  : reportServiceSwitch[action.type](state, action));

export default activeDeviceStateReducer;
