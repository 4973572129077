/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { WizardViewPageProps } from '../../../WizardView/WizardViewPage';
import StationAddressingHeader from './Header/StationAddressingHeader';
import { CommissioningWizardState } from '../hooks/useCommissioningWizardState';
import StationAddressingTableArea from './TableArea/TableArea';
import StationAddressingControl from './Control/StationAddressingControl';
import './Page2StationAddressing.scss';

export interface Page2StationAddrProps extends WizardViewPageProps {
  wizardState: CommissioningWizardState;
  onFinish: () => void;
}

const Page2StationAddressing
: React.FC<Page2StationAddrProps> = (props: Page2StationAddrProps) : React.ReactElement => {
  const {
    onFinish, wizardState,
  } = props;

  return (
    <div className="contactron-commissioning-wizard">
      <div className="page-station-addressing">
        <StationAddressingHeader />
        <StationAddressingControl wizardState={wizardState} />
        <StationAddressingTableArea wizardState={wizardState} onFinishClick={onFinish} />
      </div>
    </div>
  );
};

export default Page2StationAddressing;
