import { Services, WebDevice } from '@gpt/commons';
import { Store } from 'redux';
import { setDeviceActionExecutionProgress } from './store/deviceInstances/store/deviceActionState/actions';
import { addApplicationMessage } from './store/applicationMessage/actions';
import { contactronWizardAddModule, contactronWizardUpdateModule } from './store/middleware/contactronWizard/actions';
import { DeviceMethodExecutionStep, MethodStageExecutionStatus, typeDeviceInstanceDeviceMethodExecutionAction } from './store/deviceInstances/store/deviceMethod/types';
import { setDeviceMethodExecutionState } from './store/deviceInstances/store/deviceMethod/actions';
import { ContactronModuleInstance } from './store/middleware/contactronWizard/types';

const CONTACTRON_STATION_EVENT__DISCOVERY = 'ContactronStationDiscovery';
const CONTACTRON_STATION_EVENT__MODULE_UPDATE = 'ContactronStationModuleUpdateEvent';
const CONTACTRON_STATION_EVENT__FAILED = 'ContactronStationFailedEvent';
const EVENT__METHOD_PROGRESS = 'MethodProgressEvent';

const logConsole = console;

/* *********************************************************************
 *  CONTACTRON STATION: START DISCOVERY CONFIGURATION
 ********************************************************************* */
export interface StationFailedEvent {
  type: typeof CONTACTRON_STATION_EVENT__FAILED,
  errorMessage: string;
  errorType: Services.DeviceModel.CommunicationErrorType;
}

export const isStationEventFailed = (payload: unknown)
// eslint-disable-next-line max-len
    : payload is StationFailedEvent => (payload as StationFailedEvent).type === CONTACTRON_STATION_EVENT__FAILED;

export interface StationDiscoveryEvent {
  type: typeof CONTACTRON_STATION_EVENT__DISCOVERY,
  stationInstanceId: string,
  userPressButton: boolean;
  module: ContactronModuleInstance;
}

export interface StationUpdateModuleEvent {
  type: typeof CONTACTRON_STATION_EVENT__MODULE_UPDATE,
  stationInstanceId: string,
  module: ContactronModuleInstance;
}

export interface NatsMethodProgressStep
{
    title: string;
    message: string;
    state: Services.DeviceModel.MethodStepExecutionState;
    progress: number;
}

export interface NatsMethodProgressPayload {
  type: typeof EVENT__METHOD_PROGRESS,
  steps: NatsMethodProgressStep[];
}

const deviceMethodExecutionState = (
  deviceInstanceId: string,
  actionId: string,
  payload: NatsMethodProgressPayload,
): typeDeviceInstanceDeviceMethodExecutionAction => {
  const steps: DeviceMethodExecutionStep[] = payload.steps.map((step) => ({
    title: step.title,
    state: step.state,
    message: step.message,
  }));

  return setDeviceMethodExecutionState(deviceInstanceId, {
    methodIdent: actionId,
    stage: MethodStageExecutionStatus.InProgress,
    steps,
  });
};

export const isStationDiscoveryEvent = (payload: unknown)
// eslint-disable-next-line max-len
  : payload is StationDiscoveryEvent => (payload as StationDiscoveryEvent).type === CONTACTRON_STATION_EVENT__DISCOVERY;

export const isStationUpdateModuleEvent = (payload: unknown)
// eslint-disable-next-line max-len
  : payload is StationUpdateModuleEvent => (payload as StationUpdateModuleEvent).type === CONTACTRON_STATION_EVENT__MODULE_UPDATE;

export const isMethodProgressEvent = (payload: unknown)
// eslint-disable-next-line max-len
    : payload is NatsMethodProgressPayload => (payload as NatsMethodProgressPayload).type === EVENT__METHOD_PROGRESS;

export const handleWebDeviceActionEvent = (store: Store, ev: WebDevice.WebDeviceActionEvent) => {
  if (ev.kind !== 'WEBDEVICE__ACTION_EVENT') {
    return;
  }

  const {
    payload,
  } = ev;
  if (isStationDiscoveryEvent(payload)) {
    store.dispatch(contactronWizardAddModule(payload));
  } else if (isStationUpdateModuleEvent(payload)) {
    store.dispatch(contactronWizardUpdateModule(payload));
  } else if (isStationEventFailed(payload)) {
    store.dispatch(addApplicationMessage({
      id: '123',
      messageId: 'WIZARD_CONTACTRON__MESSAGE__STATION_PRESSED_BUTTON',
      type: 'warning',
    }));
  } else if (isMethodProgressEvent(payload)) {
    const dispatchMsg = deviceMethodExecutionState(ev.deviceInstanceId, ev.actionId, payload);
    store.dispatch(dispatchMsg);
  } else {
    logConsole.log('UNKNOWN EVENT', ev);
  }
};

// eslint-disable-next-line max-len
export const handleWebDeviceProgressEvent = (store: Store, ev: WebDevice.WebDeviceActionProgressEvent) => {
  if (ev.kind !== 'WEBDEVICE__ACTION_PROGRESS_EVENT') {
    return;
  }
  const {
    deviceInstanceId, actionId, progress, text,
  } = ev;
  store.dispatch(setDeviceActionExecutionProgress(deviceInstanceId, {
    actionId,
    progress,
    title: text,
  }));
};
