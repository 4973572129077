/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import useDisplayStatusValue from '../../../../hooks/useDisplayStatusValue';
import { useContextDatasetStatusDescriptor } from '../../../../../hooks/useContextDataset';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import './SystemSettingVariable.scss';

export interface SystemSettingVariableProps {
  deviceInstanceId: string;
  variableIdent: string;
}

export const SystemSettingVariable: React.FC<SystemSettingVariableProps> = (props: SystemSettingVariableProps): React.ReactElement => {
  const { t } = useTranslation();
  const { deviceInstanceId, variableIdent } = props;
  const variableDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, variableIdent, DatasetType.device);
  const variableDisplayValue = useDisplayStatusValue(deviceInstanceId, variableIdent, DatasetType.device);

  return (
    <div className="caparoc-system-settings-variable">
      <div className="caparoc-system-settings-variable__label">{`${t<string>(variableDescriptor?.label ?? '')}: `}</div>
      <div className="caparoc-system-settings-variable__value">{variableDisplayValue}</div>
    </div>
  );
};
