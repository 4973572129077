/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext, useEffect } from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { shallowEqual } from 'react-redux';
import { useTypedSelector } from '../../store/reduxStore';
import { ReduxControlProps } from '../ReduxControlProps';
import { UnsupportedControl } from '../../components/UnsupportedControl';
import { RangeWithSpanControl } from './RangeWithSpanDescriptorControl';
import { variablesErrorStatusSelector, variablesModifiedStatusSelector, variablesVisibilitySelector } from '../selectors/variablesStatusSelector';
import { DatasetContext } from '../../views/DatasetContext';
import controlTypeSelector from '../selectors/controlTypeSelector';
import withControlVisibility from '../hoc/withControlVisibility';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';

const rangeWithSpanAttributeSelector = (deviceInstances: DeviceInstancesState, targetDataset: DatasetType, identRef: IdentRef, deviceInstanceId: string) => {
  const dataset = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  const control = controlTypeSelector<DeviceModelStatus.UI.RangeWithSpanControlType>(DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN, identRef, dataset);
  if (control === undefined || dataset === undefined) {
    return [false, false, false];
  }

  const varList = [
    control.rangeStart,
    control.rangeEnd,
    control.spanVar,
  ];

  const modified = variablesModifiedStatusSelector(dataset, varList);
  const error = variablesErrorStatusSelector(dataset, varList);
  const visibility = variablesVisibilitySelector(dataset, varList);

  return [modified, error, visibility] as const;
};

const RangeWithSpanReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
  : React.ReactElement | null => {
  const { identRef, onStateChanged, deviceInstanceId } = props;
  const { targetDataset } = useContext(DatasetContext);

  const [modified, error, visibility] = useTypedSelector(
    (state) => rangeWithSpanAttributeSelector(state.deviceInstances, targetDataset, identRef, deviceInstanceId),
    shallowEqual,
  );

  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, {
      error,
      modified,
    });
  }, [error, modified, identRef]);

  const controlType = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    return controlTypeSelector<DeviceModelStatus.UI.RangeWithSpanControlType>(DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN, identRef, dataset);
  });

  if (controlType === undefined) {
    return <UnsupportedControl text={`RangeWithSpanReduxControl: Undefined object ${identRef}`} />;
  }

  if (visibility === false) {
    return (null);
  }

  return (
    <RangeWithSpanControl
      controlType={controlType}
      deviceInstanceId={deviceInstanceId}
    />
  );
};

export default withControlVisibility(RangeWithSpanReduxControl);
