/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useState } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import ForceDeviceEnableControl from './ForceDeviceEnableControl/ForceDeviceEnableControl';
import { ForceSwitchOutputControl } from './ForceSwitchOutputControl/ForceSwitchOutputControl';
import { useTypedSelector } from '../../../store';
import { deviceStatusValueSelector } from '../../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import ForceAnalogOutputReduxControl from './ForceAnalogOutputReduxControl/ForceAnalogOutputReduxControl';
import { ForceDialogContext, ForceDialogPasswordContext, ForceDialogSessionContext } from '../Context/ForceDialogContext';
import useSessionPassword from './hooks/useSessionPassword';
import './ForceOutputBlock.scss';

export interface ForceOutputBlockProps {
    deviceInstanceId: string;
    block: DeviceModelStatus.UI.SPort.ForceOutputBlockControlType;
}

const ForceOutputBlock: React.FC<ForceOutputBlockProps> = (props: ForceOutputBlockProps):
  React.ReactElement => {
  const {
    block,
    deviceInstanceId,
  } = props;
  const { forceDeviceEnable, forceAnalogOutput, forceSwitchOutput } = block;

  const [enableWarningDialog, setEnableWarningDialog] = useState(true);
  const [accessPassword, setAccessPassword] = useSessionPassword();

  const simulationValue = useTypedSelector((state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.device, 'IFSM_ADDR_IO_RW_DEVICE_STATE_SIMULATION'));
  const forceSwitchValue = simulationValue?.value !== undefined && simulationValue?.value !== 0;

  const forceAnalogItems = (forceAnalogOutput ?? []).map((forceAnalog) => (
    <div className="force-analog-output" key={`force-analog-output--${deviceInstanceId}-${forceAnalog.nodeid}`}>
      <ForceAnalogOutputReduxControl
        deviceInstanceId={deviceInstanceId}
        forceAnalogOutput={forceAnalog}
        disabled={!forceSwitchValue}
      />
    </div>
  ));

  const forceSwitchOutputItems = (forceSwitchOutput ?? []).map((forceOutput) => (
    <div className="force-switch-output" key={`force-switch-output--${deviceInstanceId}--${forceOutput.nodeid}`}>
      <ForceSwitchOutputControl
        title={forceOutput.label}
        text={forceOutput.subLabel}
        disabled={!forceSwitchValue}
        deviceInstanceId={deviceInstanceId}
        buttonOn={forceOutput.buttonOn}
        buttonOff={forceOutput.buttonOff}
      />
    </div>
  ));

  return (
    <ForceDialogSessionContext.Provider value={{
      resetSession: () => {
        setAccessPassword();
        setEnableWarningDialog(true);
      },
    }}
    >
      <ForceDialogContext.Provider value={{
        enableWarningDialog,
        setEnableWarningDialog: (value) => setEnableWarningDialog(value),
      }}
      >
        <ForceDialogPasswordContext.Provider value={{
          password: accessPassword,
          setPassword: (pass) => setAccessPassword(pass),
        }}
        >
          <div className="force-output-block">
            <div className="force-device">
              {forceDeviceEnable !== undefined && (
              <ForceDeviceEnableControl
                deviceInstanceId={deviceInstanceId}
                switchValue={forceSwitchValue}
                actionOn={forceDeviceEnable.actionOn}
                actionOff={forceDeviceEnable.actionOff}
              />
              )}
            </div>
            <div>
              {forceAnalogItems}
            </div>
            <div>
              {forceSwitchOutputItems}
            </div>
          </div>
        </ForceDialogPasswordContext.Provider>
      </ForceDialogContext.Provider>
    </ForceDialogSessionContext.Provider>
  );
};

export default ForceOutputBlock;
