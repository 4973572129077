/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Services } from '@gpt/commons';
import { IWebWorkerDeviceManager } from '../services/WebWorkerDevice/WebWorkerDeviceManager';
import { DatasetType } from '../store/deviceInstances/store/deviceDataset/types';
import createExternalDatasetContent from './createExternalDatasetContent';
import { ModelInstanceInformation } from '../store/deviceInstances/store/activeDevice/types';

export interface createDeviceDatasetBackupContentProps {
    webWorkerDeviceManager: IWebWorkerDeviceManager | undefined,
    activeDevice: ModelInstanceInformation,
    caption: string,
    datasetId: string,
    datasetType: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType,
    previewMenu?: string,
    userComment?: string,
}

const createDeviceDatasetBackupContent = async (props: createDeviceDatasetBackupContentProps)
: Promise<string> => {
  const {
    activeDevice, caption, datasetId, datasetType, webWorkerDeviceManager, previewMenu,
    userComment,
  } = props;

  const webWorker = webWorkerDeviceManager
    ?.get(activeDevice.deviceInstanceId)?.get(DatasetType.user);
  if (webWorker === undefined) {
    return '';
  }

  const modelStatus = await webWorker.createExternalDeviceModelStatus(
    Services.DeviceModelServer.DatasetProviderService.EXTERNAL_DATASET__VERSION,
  );
  const saveContent = await createExternalDatasetContent({
    caption,
    datasetId,
    device: {
      info: activeDevice.device,
      descriptors: modelStatus.descriptor,
      values: modelStatus.statusVector.statusValue,
      previewMenu: previewMenu ?? '',
    },
    datasetType,
    userComment,
  });
  return JSON.stringify(saveContent, null, 2);
};

export default createDeviceDatasetBackupContent;
