/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import {
  typeDeviceInstanceActiveDeviceMiddlewareActions,
  ACTIVE_DEVICE__LOAD_DATASET__FROM__FILE,
  ACTIVE_DEVICE__WRITE_VARIABLE_VALUES,
  ACTIVE_DEVICE__WRITE_INITIAL_DATASET,
  ACTIVE_DEVICE__LOAD_DATASET__CONTENT,
} from './types';
import { ExternalFileContent } from '../../../types';

export const activeDeviceUploadDatasetFromFile = (targetInstance: string, externalFile: ExternalFileContent)
  : typeDeviceInstanceActiveDeviceMiddlewareActions => ({
  type: ACTIVE_DEVICE__LOAD_DATASET__FROM__FILE,
  payload: {
    targetInstance,
    data: {
      externalFile,
    },
  },
});

export const activeDeviceLoadDatasetContent = (targetInstance: string, externalDataset: Services.DeviceModelServer.DatasetProviderService.ExternalDataset)
  : typeDeviceInstanceActiveDeviceMiddlewareActions => ({
  type: ACTIVE_DEVICE__LOAD_DATASET__CONTENT,
  payload: {
    targetInstance,
    data: {
      externalDataset,
    },
  },
});

export const writeActiveDeviceVariableValues = (targetInstance: string, values: Services.DeviceModel.StatusValueRef[])
  : typeDeviceInstanceActiveDeviceMiddlewareActions => ({
  type: ACTIVE_DEVICE__WRITE_VARIABLE_VALUES,
  payload: {
    targetInstance,
    data: {
      values,
    },
  },
});

export const activeDeviceWriteInitialDataset = (deviceInstance: string)
  : typeDeviceInstanceActiveDeviceMiddlewareActions => ({
  type: ACTIVE_DEVICE__WRITE_INITIAL_DATASET,
  payload: {
    deviceInstance,
  },
});
