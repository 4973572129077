/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  IOLINK__TRIO3_CHANNEL_SWITCH__SET_CHANNEL_STATE,
  IoLinkTrio3ChannelSwitchState,
  typeIoLinkTrio3SwitchChannelActions,
} from './types';

export const initialIoLinkTrio3ChannelSwitchState: IoLinkTrio3ChannelSwitchState = {
};

const ioLinkTrio3ChannelSwitchSetStateReducer = (state: IoLinkTrio3ChannelSwitchState, action: typeIoLinkTrio3SwitchChannelActions)
: IoLinkTrio3ChannelSwitchState => {
  const { ident } = action.payload;
  return {
    ...state,
    [ident]: action.payload.state,
  };
};

const reducerSwitchFunction = {
  [IOLINK__TRIO3_CHANNEL_SWITCH__SET_CHANNEL_STATE]: ioLinkTrio3ChannelSwitchSetStateReducer,
};

const ioLinkTrio3ChannelSwitchReducer = (
  state = initialIoLinkTrio3ChannelSwitchState,
  action: typeIoLinkTrio3SwitchChannelActions,
): IoLinkTrio3ChannelSwitchState => reducerSwitchFunction[action.type]?.(state, action) ?? state;

export default ioLinkTrio3ChannelSwitchReducer;
