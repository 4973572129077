/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  typeIoLinkSystemCommandMiddlewareActions,
  IoLinkExecuteSysCommandPayload,
  IOLINK__ACTION__EXECUTE_SYSTEM_COMMAND,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const ioLinkExecuteSystemCommand = (payload: IoLinkExecuteSysCommandPayload)
: typeIoLinkSystemCommandMiddlewareActions => ({
  type: IOLINK__ACTION__EXECUTE_SYSTEM_COMMAND,
  payload,
});
