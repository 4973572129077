/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import OpStatusControl from '../../controls/OpStatusControl/OpStatusControl';
import './ActualValuesCard.scss';

export interface ActualValuesCardProps {
  actualValues: DeviceModelStatus.UI.Contactron.StationCockpitActualValuesCard;
  deviceInstanceId: string;
}

const ActualValuesCard: React.FC<ActualValuesCardProps> = (props: ActualValuesCardProps):
  React.ReactElement => {
  const {
    actualValues,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const menuItems = actualValues.items.map((identRef) => (
    <OpStatusControl key={`akt-value--${identRef}`} identRef={identRef} deviceInstanceId={deviceInstanceId} />
  ));

  return (
    <PaperControl title={t(actualValues.label) ?? ''}>
      <div className="cockpit-contactron-station-actualvalues">
        {menuItems}
      </div>
    </PaperControl>
  );
};

export default ActualValuesCard;
