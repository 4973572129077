/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { LinearizationTableItem } from '../types';

export type SplineArrPoint = number[];

export interface SplineFn {
  equation: number[];
  string: string;
  points: SplineArrPoint[]
}

const calcFunctions = (aPoints: SplineArrPoint[], fMaxError: number): SplineFn[] => {
  const oFunctions: SplineFn[] = [];
  let aCurPoints: SplineArrPoint[] = [];

  if (aPoints.length < 2) {
    return [];
  }

  let SumX = aPoints[0][0]; // X
  let SumX2 = aPoints[0][0] * aPoints[0][0]; // X * X
  let SumX3 = aPoints[0][0] * aPoints[0][0] * aPoints[0][0]; // X * X * X
  let SumX4 = aPoints[0][0] * aPoints[0][0] * aPoints[0][0] * aPoints[0][0]; // X * X * X * X
  let SumY = aPoints[0][1]; // Y
  let SumYX = aPoints[0][1] * aPoints[0][0]; // Y * X
  let SumYX2 = aPoints[0][1] * aPoints[0][0] * aPoints[0][0]; // Y * X * X

  const fncCalcFunc = (aPs: SplineArrPoint[]): SplineFn | null => {
    let n = aPs.length - 1; // Indes des letzten Punktes

    let A0 = 0; let A1 = 0; let
      A2 = 0;

    SumX += aPs[n][0]; // X
    SumX2 += aPs[n][0] * aPs[n][0]; // X * X
    SumX3 += aPs[n][0] * aPs[n][0] * aPs[n][0]; // X * X * X
    SumX4 += aPs[n][0] * aPs[n][0] * aPs[n][0] * aPs[n][0]; // X * X * X * X
    SumY += aPs[n][1]; // Y
    SumYX += aPs[n][1] * aPs[n][0]; // Y * X
    SumYX2 += aPs[n][1] * aPs[n][0] * aPs[n][0]; // Y * X * X

    const x1 = aPs[0][0];
    const y1 = aPs[0][1];
    const x2 = aPs[n][0];
    const y2 = aPs[n][1];

    const PowX1 = x1 * x1;
    const PowX2 = x2 * x2;

    n += 1;

    if (n === 2) { /* 2 Punkte */
      A1 = (y1 - y2) / (x1 - x2);

      A0 = y1 - A1 * x1;

      A2 = 0;
    } else if (n === 3) { /* 3 Punktete */
      const x3 = aPs[1][0];
      const y3 = aPs[1][1];

      A2 = (((y1 - y2) / (x1 - x2) - (y2 - y3) / (x2 - x3)) / ((PowX1 - PowX2) / (x1 - x2) - (PowX2 - (x3 * x3)) / (x2 - x3)));

      A1 = ((y2 - y3) / (x2 - x3) - (A2 * (PowX2 - (x3 * x3))) / (x2 - x3));

      A0 = y1 - A1 * x1 - A2 * PowX1;
    } else { /* n Punkte */
      const Nenner = ((((x1 - x2)) * ((SumX4 + SumX2 * PowX1 - 2 * x1 * (((-2) * SumX2 + SumX * x1)) * x2
                    + ((SumX2 + x1 * (((-2) * SumX + n * x1)))) * PowX2 - 2 * SumX3 * ((x1 + x2))))));

      A0 = (x1 * ((x1 - x2)) * x2 * ((SumYX2 + SumY * x1 * x2 - SumYX * ((x1 + x2))))
                + x2 * (((-SumX4) + SumX3 * ((x1 + 2 * x2)) + x2 * (((-2) * SumX2 * x1 - SumX2 * x2 + SumX * x1 * x2)))) * y1
                + x1 * ((SumX4 - SumX3 * ((2 * x1 + x2)) + x1 * ((SumX2 * x1 + 2 * SumX2 * x2 - SumX * x1 * x2)))) * y2)
                / Nenner;

      A1 = (((-SumY) * x1 * x1 * x1 * x2 + SumY * x1 * x2 * x2 * x2 + SumYX * ((x1 - x2)) * (x1 + x2) * (x1 + x2)
                    + SumYX2 * (((-(x1 * x1)) + x2 * x2)) + SumX4 * y1 - SumX3 * x1 * y1 - SumX3 * x2 * y1
                    + SumX2 * x1 * x2 * y1 - SumX2 * (x2 * x2) * y1 + SumX * x1 * x2 * x2 * y1 + SumX * x2 * x2 * x2 * y1
                    - n * x1 * x2 * x2 * x2 * y1 - ((SumX4 - SumX3 * ((x1 + x2)) + x1 * (((-SumX2) * x1 + SumX * (x1 * x1)
                    + SumX2 * x2 + SumX * x1 * x2 - n * x1 * x1 * x2)))) * y2)
      )
                  / Nenner;

      A2 = (SumYX2 * ((x1 - x2)) + SumY * PowX1 * x2 - SumY * x1 * PowX2
                + SumYX * (((-PowX1) + PowX2)) - SumX3 * y1 + SumX2 * x1 * y1 + 2 * SumX2 * x2 * y1
                - 2 * SumX * x1 * x2 * y1 - SumX * PowX2 * y1 + n * x1 * PowX2 * y1
                + ((SumX3 - SumX2 * ((2 * x1 + x2)) + x1 * ((SumX * x1 + 2 * SumX * x2 - n * x1 * x2)))) * y2)
                / Nenner;
    }

    // Ergebnis : Berechnete Funktion
    const oFunc: SplineFn = {
      equation: [A0, A1, A2],
      points: [],
      string: `y = ${A2}x^2 + ${A1}x + ${A0}`,
    };

    /* Prüfen, ob Fehlergrenzen eingehalten werden */
    for (let i = 0; i < aPs.length; i += 1) {
      const yist = (A2 * aPs[i][0] + A1) * aPs[i][0] + A0;

      oFunc.points.push([aPs[i][0], yist]);

      if (Math.abs(yist - aPs[i][1]) > fMaxError) {
        return (null);
      }
    }

    return oFunc;
  };

  let wLE = 1;
  let oLastValidFunction: SplineFn | null = null;

  aCurPoints.push(aPoints[0]);

  while (wLE < aPoints.length) {
    aCurPoints.push(aPoints[wLE]);

    /* check for invalid points */
    if (aPoints[wLE - 1] === aPoints[wLE]) {
      return ([]);
    }

    const oTmp = fncCalcFunc(aCurPoints);

    if (oTmp != null) { /* gültige Gleichung gefunden */
      oLastValidFunction = oTmp;
      wLE += 1;
    } else { /* Gleichung nicht gültig */
      if (oLastValidFunction !== null) {
        oFunctions.push(oLastValidFunction);
      }
      oLastValidFunction = null;

      aCurPoints = [];
      aCurPoints.push(aPoints[wLE - 1]);

      const x = aPoints[wLE - 1][0];
      const y = aPoints[wLE - 1][1];

      SumX = x; // X
      SumX2 = x * x; // X * X
      SumX3 = x * x * x; // X * X * X
      SumX4 = x * x * x * x; // X * X * X * X
      SumY = y; // Y
      SumYX = y * x; // Y * X
      SumYX2 = y * x * x; // Y * X * X
    }
  }

  if (oLastValidFunction) {
    oFunctions.push(oLastValidFunction);
  }

  return oFunctions;
};

export const calcDeviationFunctions = (fMaxErr: number, aX: number[], aY: number[], nNumElements: number): SplineFn[] => {
  let aNodes: SplineArrPoint[] = [];

  for (let i = 0; i < nNumElements; i += 1) {
    aNodes.push([aX[i], aY[i]]);
  }

  if (aNodes.length > 1) {
    if (aNodes[0][0] > aNodes[1][0]) {
      const oTmpPoints: SplineArrPoint[] = [];
      for (let i = aNodes.length - 1; i >= 0; i -= 1) {
        oTmpPoints.push(aNodes[i]);
      }
      aNodes = oTmpPoints;
    }
  }

  const maoFunctionArray = calcFunctions(aNodes, fMaxErr);

  /* change order ? */
  const wCnt = maoFunctionArray.length - 1;
  if (maoFunctionArray[0].points[0][0] > maoFunctionArray[wCnt].points[maoFunctionArray[wCnt].points.length - 1][0]) {
    maoFunctionArray.reverse();
  }

  return maoFunctionArray;
};

const findFunction = (x: number, oFuncs: SplineFn[]): SplineFn | undefined => {
  if (oFuncs.length > 0) {
  /* richtigen Abschnitt suchen */
    return oFuncs.find((item) => (x >= item.points[0][1] && x <= item.points[item.points.length - 1][1]));
  }

  return undefined;
};

const calcPointError = (oFunc: SplineFn, pt: LinearizationTableItem): SplineArrPoint => {
  const calculate = (value: number) => (oFunc.equation[2] * value + oFunc.equation[1]) * value + oFunc.equation[0];

  const fSoll = pt.xValue;
  const fIst = calculate(pt.yValue);
  return [fSoll, (fSoll - fIst)];
};

export const fncCalcPointsDeviation = (oFuncs: SplineFn[], pts: LinearizationTableItem[]): SplineArrPoint[] => pts.map((pt) => {
  const oFunc = findFunction(pt.xValue, oFuncs);
  return oFunc === undefined ? [pt.xValue, 0] : calcPointError(oFunc, pt);
});
