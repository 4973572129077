/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { PSRConfigurationStep } from './ConfigurationStep/ConfigurationStep';
import './PSRManualControl.scss';

export interface PSRManualControlProps {
  steps: string[];
}

const PSRManualControl: React.FC<PSRManualControlProps> = (props: PSRManualControlProps)
:React.ReactElement => {
  const { steps } = props;
  const items = steps
    .map((text, index) => (<PSRConfigurationStep key={`${text}`} index={index + 1} text={text} />));

  return (
    <div className="psr-uni__manual_configuration">
      {items}
    </div>
  );
};

export default PSRManualControl;
