/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import { DeviceInstanceActionPayload } from '../../../common';
import { typeDeviceMethodStageMiddlewareActions } from '../types';
import { bytesToBase64 } from '../../../../linearizationDataset/middleware/base64';

export interface SPortWriteParameterPayload extends DeviceInstanceActionPayload {
  actionId: string;
  memoryAddress: number;
  slaveAddress?: number | string;
  data: Uint8Array;
}

// eslint-disable-next-line import/prefer-default-export
export const sPortWriteParameter = (parameters: SPortWriteParameterPayload)
: typeDeviceMethodStageMiddlewareActions => {
  const {
    memoryAddress, data, targetInstance, actionId,
    slaveAddress,
  } = parameters;

  let header: Services.DeviceModel.MethodHeader = {
    address: `${memoryAddress}`,
    data: `${bytesToBase64(data)}`,
    actionId,
  };

  header = slaveAddress === undefined
    ? header
    : {
      ...header,
      'connection:address': `${slaveAddress}`,
    };

  return {
    type: 'METHOD_STAGE_EXECUTION__INIT',
    payload: {
      targetInstance,
      data: {
        header,
        methodIdent: 'WriteParameter',
        methodStatusRef: actionId,
        values: [],
      },
    },
  };
};
