/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { setSimulatedDeviceCount } from '../actions';
import {
  DEVICE_SIMULATION__LOAD_CONFIG, DEVICE_SIMULATION__SAVE_CONFIG,
  SimulatedDeviceList, SimulatedDevicesCount, typeDeviceSimulationActionTypes,
} from '../types';
import { deviceDiscoveryServiceSelector, deviceSimulationStoreSelector } from '../../reduxStoreSelector';
import { infrastructureService } from '../../../services/InfrastructureService/InfrastructureService';

export const deviceSimulationMiddleware = ()
: Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeDeviceSimulationActionTypes>(action: A): Promise<A> => {
  switch (action.type) {
    case DEVICE_SIMULATION__LOAD_CONFIG: {
      const config = await infrastructureService.getSimulationConfiguration();
      const deviceDiscoveryService = deviceDiscoveryServiceSelector(api.getState());
      const devices: SimulatedDevicesCount = Object.keys(config?.deviceCount ?? {})
        .reduce((acc, ident) => {
          const count = config.deviceCount[ident];
          const info = deviceDiscoveryService.catalog.deviceList[ident];
          return info === undefined
            ? acc
            : {
              ...acc,
              [ident]: {
                info,
                count,
              },
            };
        }, {});
      api.dispatch(setSimulatedDeviceCount(devices));
      break;
    }
    case DEVICE_SIMULATION__SAVE_CONFIG: {
      const deviceSimulationState = deviceSimulationStoreSelector(api.getState());
      const simEnabled = deviceSimulationState.enabled;
      const simulatedDevices = deviceSimulationState.simulatedDevices as SimulatedDeviceList;

      const deviceCount: {[key: string]: number} = Object.keys(simulatedDevices).reduce((acc, ident) => ({
        ...acc,
        [ident]: simulatedDevices[ident].length,
      }), {});
      await infrastructureService.setSimulationConfiguration({
        simEnabled,
        deviceCount,
      });
      break;
    }
    default:
  }
  const result = next(action);
  return result;
};
