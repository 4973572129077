/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import './CockpitModuleLayout8.scss';
import { StationModuleLayoutProps } from '../types';
import Layout8CardReduxControl from './Layout8CardReduxControl/Layout8CardReduxControl';

const ContactronCockpitModuleLayout8: React.FC<StationModuleLayoutProps> = (props: StationModuleLayoutProps):React.ReactElement => {
  const {
    topology, stationInstanceId, onModuleIdent, onModuleOpen,
  } = props;

  const rowModules = topology.map((item) => (
    <div key={`layout-row-station-card-${item.id}`} className="layout-container-row-card">
      <Layout8CardReduxControl
        channelNo={item.id}
        stationInstanceId={stationInstanceId}
        onModuleIdent={() => onModuleIdent(item.id)}
        onModuleOpen={() => onModuleOpen(item.instanceId)}
      />
    </div>
  ));
  return (
    <div className="contactron-station-cockpit-layout8">
      <div className="layout-container-row--8">
        {rowModules}
      </div>
    </div>
  );
};

export default ContactronCockpitModuleLayout8;
