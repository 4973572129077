/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReduxControlProps } from '../ReduxControlProps';
import { useTypedSelector, writeActiveDeviceVariableValues } from '../../store';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { ParameterGroupControl } from '../../components/ParameterEditor';
import DIPSwitchBlockControl from '../DIPSwitchBlockControl/DIPSwitchBlockControl';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import { DatasetContext } from '../../views/DatasetContext';
import withControlVisibility from '../hoc/withControlVisibility';
import { deviceDescriptorSelector, deviceStatusValueSelector } from '../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';

const DIPSwitchBlockReduxOnline: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, readonly, deviceInstanceId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { targetDataset } = useContext(DatasetContext);
  const ctrlDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);

  if (ctrlDescriptor === undefined) {
    return <UnsupportedControl text={`DIPSwitchBlockReduxOnline [${identRef}]: undefined object`} />;
  }
  if (ctrlDescriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text={`DIPSwitchBlockReduxOnline [${identRef}]: supported object type is ControlDescriptor only`} />;
  }
  if (ctrlDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDIPSWITCHBLOCKONLINE) {
    return <UnsupportedControl text={`DIPSwitchBlockReduxOnline [${identRef}]: supported controlType is CTLDIPSWITCHBLOCKONLINE only`} />;
  }

  const { variable, length } = ctrlDescriptor.controlType;
  const varDescriptor = useTypedSelector((state) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, variable));

  if (varDescriptor === undefined) {
    return <UnsupportedControl text={`DIPSwitchBlockReduxOnline [${identRef}]: undefined object`} />;
  }
  if (varDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text={`DIPSwitchBlockReduxOnline [${identRef}]: supported object type is ControlDescriptor only`} />;
  }
  if (varDescriptor.visibility === false) {
    return (null);
  }

  const { label } = varDescriptor;
  const valueNumber = useTypedSelector((state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, variable));
  const deviceValueNumber = useTypedSelector((state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.device, variable));

  return (
    <ParameterGroupControl label={t(label)} alignEnd={false}>
      <DIPSwitchBlockControl
        id={`dip-online-${variable}`}
        mainValue={valueNumber?.value}
        comparisonValue={deviceValueNumber?.value}
        length={length}
        readonly={readonly}
        onValueChanged={(value) => {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            identRef: variable,
            value,
            backupValue: undefined,
          }]));
        }}
      />
    </ParameterGroupControl>
  );
};

export default withControlVisibility(DIPSwitchBlockReduxOnline);
