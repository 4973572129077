/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useCallback } from 'react';
import {
  DeviceModelStatus, ReportContent, ReportContentType, ReportHeader, Report, DocumentType, DocumentLanguage,
} from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  useTypedSelector, createReport,
} from '../../../store';
import { createReportFilename } from '../../../helpers/functions';
import { DatasetState, DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { useContextApplicationId } from '../../../store/deviceInstances/hooks/StandardViewHooks';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { getAppUiLanguageCode } from '../../../i18n-config';

const appTypeSelector = (appId: string, state?: DatasetState): DeviceModelStatus.Apps.ApplicationDescriptorType | undefined => {
  const appDesc = state?.descriptors[appId];
  if (appDesc?.type === DeviceModelStatus.StatusType.ApplicationDescriptor) {
    return appDesc.appType;
  }

  return undefined;
};

const reportAppId = (applicationId?: string)
  : string => (applicationId === undefined ? 'APP_EDITDEVICE' : applicationId);

const useDeviceViewPrintReport = (deviceInstanceId?: string) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeDeviceInstance = useTypedSelector((state) => {
    if (deviceInstanceId === undefined) {
      return undefined;
    }
    return state.deviceInstances.instances[deviceInstanceId]?.activeDevice.modelInstance;
  });

  const applicationId = useContextApplicationId(activeDeviceInstance?.deviceInstanceId);

  const [reportMenu, reportType] = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, activeDeviceInstance?.deviceInstanceId, DatasetType.user);
    const applId = reportAppId(applicationId);
    const appType = appTypeSelector(applId, dataset);
    return [
      appType ? appType.report : undefined,
      appType ? appType.reportType : ReportContentType.EditParameters,
    ] as const;
  });

  const deviceViewPrintReport = useCallback(() => {
    if (activeDeviceInstance === undefined || deviceInstanceId === undefined) {
      return;
    }

    const activeDeviceInfo = activeDeviceInstance?.device;

    const appId = reportAppId(applicationId);
    const reportKind = (appId === 'APP_COMPAREDEVICE')
      ? 'CompareParametersReport' : 'EditDeviceParametersReport';
    const reportFilename = createReportFilename(reportKind, activeDeviceInfo);
    const docLanguage: DocumentLanguage = DocumentLanguage[getAppUiLanguageCode().toUpperCase()];

    const getReportContentHandler = (): Report => {
      const header: ReportHeader = {
        dateTime: new Date(),
        systemName: t<string>('', '{{SYSTEM_NAME}}'),
        userName: '',
        userNotes: '',
      };

      let content: ReportContent;
      switch (reportType) {
        case ReportContentType.EditParameters:
          content = {
            type: reportType,
            device: {
              deviceDesignation: activeDeviceInfo?.catalog?.deviceTypeName ?? '',
              deviceTag: activeDeviceInfo?.instance?.deviceTag ?? '',
              firmwareRevision: activeDeviceInfo?.instance?.firmwareVersion ?? '',
              hardwareRevision: activeDeviceInfo?.instance?.hardwareVersion ?? '',
              serialNumber: activeDeviceInfo?.instance?.serialNumber ?? '',
              itemNumber: activeDeviceInfo?.catalog?.productOrderNumber ?? '',
            },
          };
          break;
        case ReportContentType.CompareParameters:
          content = {
            type: reportType,
            device: {
              deviceDesignation: activeDeviceInfo?.catalog?.deviceTypeName ?? '',
              deviceTag: activeDeviceInfo?.instance?.deviceTag ?? '',
              firmwareRevision: activeDeviceInfo?.instance?.firmwareVersion ?? '',
              hardwareRevision: activeDeviceInfo?.instance?.hardwareVersion ?? '',
              serialNumber: activeDeviceInfo?.instance?.serialNumber ?? '',
              itemNumber: activeDeviceInfo?.catalog?.productOrderNumber ?? '',
            },
          };
          break;
        default:
          throw new Error(`Report generating error. Not supported application id: ${applicationId}/${reportType}`);
      }

      return {
        header,
        content,
      };
    };

    dispatch(createReport({
      targetInstance: deviceInstanceId,
      report: {
        documentType: DocumentType.PDF,
        language: docLanguage,
        reportMenu,
        reportData: getReportContentHandler(),
        reportFilename,
        activeDataset: DatasetType.user,
        compareDataset: DatasetType.device,
        dictFamily: activeDeviceInfo?.catalog.i18n.family ?? '',
      },
    }));
  }, [activeDeviceInstance, applicationId, deviceInstanceId, reportMenu, reportType, dispatch]);
  return deviceViewPrintReport;
};

export default useDeviceViewPrintReport;
