/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import ReduxControl from '../../controls/ReduxControl/ReduxControl';
import './DeviceStatusListControl.scss';

export interface DeviceStatusListControlProps {
  statusList: string[];
  deviceInstanceId: string;
}

const DeviceStatusListControl: React.FC<DeviceStatusListControlProps> = (props: DeviceStatusListControlProps)
  : React.ReactElement => {
  const { statusList, deviceInstanceId } = props;
  const elements = statusList.slice(0, 6).map((item) => {
    const key = `device-status-${item}`;
    return (<ReduxControl deviceInstanceId={deviceInstanceId} identRef={item} key={key} onStateChanged={() => 0} />);
  });

  return (
    <div className="devicelist-container">
      {elements}
    </div>
  );
};

export default DeviceStatusListControl;
