/* eslint-disable max-len */

/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { WebDevice } from '@gpt/commons';
import { Store } from 'redux';
import { addStationDiscoveryInformation, removeStationDiscoveryInformation, initializeStation } from './store/deviceInstances/store/stationDiscovery/actions';
import { StationItemState } from './store/deviceInstances/store/stationDiscovery/types';

const handleWebDeviceStationEvent = (store: Store, event: WebDevice.WebDeviceStationEvent): void => {
  const { deviceInstanceId, payload } = event;
  switch (payload.kind) {
    case 'WEBDEVICE__UPDATE_STATION__ADD_DISCOVERY__EVENT':
      store.dispatch(addStationDiscoveryInformation(
        deviceInstanceId,
        payload.items.map((item) => ({
          id: item.id,
          title: item.title,
          subtitle: item.subtitle,
          instanceId: item.instanceId,
          deviceTypeIdentifier: item.deviceTypeIdentifier,
          deviceUniqueIdentifier: item.deviceUniqueIdentifier,
          state: StationItemState.Initialized,
          connectionString: item.connectionString,
          instance: item.instance,
        })),
      ));
      break;
    case 'WEBDEVICE__UPDATE_STATION__REMOVE_DISCOVERY__EVENT':
      store.dispatch(removeStationDiscoveryInformation(
        deviceInstanceId,
        payload.items,
      ));
      break;
    case 'WEBDEVICE__UPDATE_STATION__INFORMATION__EVENT':
      store.dispatch(initializeStation(
        deviceInstanceId,
        payload.title,
        payload.subtitle,
      ));
      break;
    default:
  }
};

export default handleWebDeviceStationEvent;
