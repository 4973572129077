/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { DeviceProcessData } from './DeviceProcessData/DeviceProcessData';
import SelectDevice from './SelectDevice/SelectDevice';
import { useTypedSelector } from '../../../../../store';
import { MAX_SELECTED_STATION_INPUTS, MAX_SELECTED_STATION_OUTPUTS } from './Helpers/processDataHelpers';
import { SelectionProgressBar } from './SelectionProgressBar/SelectionProgressBar';
import './SelectProcessDataParent.scss';

export interface SelectProcessDataParentProps {
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType;
}

const SelectProcessView:
React.FC<SelectProcessDataParentProps> = (props: SelectProcessDataParentProps):
React.ReactElement => {
  const { stationDeviceDescription } = props;

  const moduleId = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule]?.moduleId);
  const moduleType = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule]?.type);
  const moduleDesc = moduleId === undefined ? undefined : stationDeviceDescription[moduleId];

  const processData = moduleType === 'module' && moduleDesc !== undefined
    ? <DeviceProcessData moduleDesc={moduleDesc} />
    : <div />;

  return <>{processData}</>;
};

export const SelectProcessDataParent:
React.FC<SelectProcessDataParentProps> = (props: SelectProcessDataParentProps):
React.ReactElement => {
  const { stationDeviceDescription } = props;

  return (
    <div className="selectprocessdataparent-container">
      <div className="selectprocessdataparent-device-selection">
        <SelectDevice stationDeviceDescription={stationDeviceDescription} />
      </div>
      <div className="selectprocessdataparent-process-data-selection">
        <SelectProcessView stationDeviceDescription={stationDeviceDescription} />
      </div>
      <div className="selectprocessdataparent-progressbar--input">
        <SelectionProgressBar kind="input" maxIoCount={MAX_SELECTED_STATION_INPUTS} label="WIZARD_CONTACTRON__LABEL__TOTAL__SELECTED_INPUT_PROCESS_DATA" />
      </div>
      <div className="selectprocessdataparent-progressbar--output">
        <SelectionProgressBar kind="output" maxIoCount={MAX_SELECTED_STATION_OUTPUTS} label="WIZARD_CONTACTRON__LABEL__TOTAL__SELECTED_OUTPUT_PROCESS_DATA" />
      </div>
    </div>
  );
};
