/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useEffect, useState } from 'react';
import { infrastructureService } from '../../../../services/InfrastructureService/InfrastructureService';
import { LegalInformation } from '../LegalInformation/PackageLegal/PackageLegalInformation';

const Console = console;

const useLicenseInfo = (): LegalInformation[] => {
  const [licenseInfoJson, setLicenseInfoJson] = useState<LegalInformation[]>([]);
  useEffect(() => {
    infrastructureService.getTextDocument('license-info.json')
      .then((response) => {
        const json = JSON.parse(response);
        if (Array.isArray(json)) {
          const data = json as LegalInformation[];
          const sorted = data.sort((a, b) => {
            if (a.package?.name === b.package?.name) {
              return 0;
            }
            return a.package?.name > b.package?.name
              ? 1
              : -1;
          });
          setLicenseInfoJson(sorted);
        } else {
          setLicenseInfoJson([]);
        }
      })
      .catch((err) => Console.warn(err));
  }, []);
  return licenseInfoJson;
};

export default useLicenseInfo;
