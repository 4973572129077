/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { v4 } from 'uuid';
import './ChannelSwitch.css';
import { CaparocChannelStateType } from '../../../../../CaparocConst';

export interface ChannelSwitchProps {
  id: string;
  state: CaparocChannelStateType;
  onSwitchChannel: () => void;
  disabled?: boolean;
}

export const ChannelSwitch: React.FC<ChannelSwitchProps> = (props: ChannelSwitchProps):
  React.ReactElement => {
  const {
    onSwitchChannel, id, disabled, state,
  } = props;
  const channelId = `${id}-${v4()}`;

  let stateClassname = '';
  switch (state) {
    case CaparocChannelStateType.OFF:
      stateClassname = 'caparoc-channel-switch-color--white';
      break;
    case CaparocChannelStateType.ON:
      stateClassname = 'caparoc-channel-switch-color--green';
      break;
    case CaparocChannelStateType.WARNING:
      stateClassname = 'caparoc-channel-switch-color--yellow';
      break;
    case CaparocChannelStateType.ERROR:
      stateClassname = 'caparoc-channel-switch-color--red-blink';
      break;
    default:
      stateClassname = 'caparoc-channel-switch-color--white';
  }

  const classes = `caparoc-channel-switch-label ${stateClassname}`;

  return (
    <label
      className={classes}
      htmlFor={channelId}
    >
      <input
        data-testid="caparoc-channel-switch-checkbox"
        type="button"
        id={channelId}
        className="caparoc-channel-switch-checkbox"
        onClick={() => onSwitchChannel()}
        disabled={disabled}
      />
    </label>
  );
};
