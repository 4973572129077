/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { ReduxControlProps } from '../ReduxControlProps';
import { useTypedSelector } from '../../store';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { ParameterGroupControl } from '../../components/ParameterEditor';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import { DatasetContext } from '../../views/DatasetContext';
import { StatusMessage } from '../../components/StatusMessage/StatusMessage';
import withControlVisibility from '../hoc/withControlVisibility';
import { variablesVisibilitySelector } from '../selectors/variablesStatusSelector';
import { DatasetState } from '../../store/deviceInstances/store/deviceDataset/types';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';

const selectControlType = (identRef: IdentRef, dataset?: DatasetState): DeviceModelStatus.UI.StatusMessageControlType | undefined => {
  const desc = dataset?.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (desc.controlType.type !== DeviceModelStatus.UI.ControlType.CTLSTATUSMESSAGE) {
    return undefined;
  }
  return desc.controlType;
};

const selectVariableVisibility = (identRef: IdentRef, dataset?: DatasetState): boolean => {
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined || dataset === undefined) {
    return false;
  }
  return variablesVisibilitySelector(dataset, [
    controlType.variable,
  ]);
};

const selectControlVariableValue = (identRef: IdentRef, dataset?: DatasetState): DeviceModelStatus.StatusValue | undefined => {
  const controlType = selectControlType(identRef, dataset);
  return controlType === undefined
    ? undefined
    : dataset?.values[controlType.variable];
};

const selectControlMessages = (identRef: IdentRef, dataset?: DatasetState): DeviceModelStatus.UI.StatusMessageType[] | undefined => {
  const controlType = selectControlType(identRef, dataset);
  return controlType === undefined
    ? undefined
    : controlType.MESSAGES;
};

const StatusMessageReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);
  const ctrlDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  const visibility = useTypedSelector((state) => selectVariableVisibility(identRef, deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)));
  const ctrlVariableValue = useTypedSelector((state) => selectControlVariableValue(identRef, deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)));
  const messages = useTypedSelector((state) => selectControlMessages(identRef, deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset))) ?? [];

  if (ctrlDescriptor === undefined) {
    return <UnsupportedControl text={`StatusMessageReduxControl [${identRef}]: undefined object`} />;
  }
  if (ctrlDescriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text={`StatusMessageReduxControl [${identRef}]: supported object type is ControlDescriptor only`} />;
  }
  if (ctrlDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLSTATUSMESSAGE) {
    return <UnsupportedControl text={`StatusMessageReduxControl [${identRef}]: supported controlType is CTLSTATUSMESSAGE only`} />;
  }

  if (visibility === false) {
    return (null);
  }
  if (ctrlVariableValue === undefined) {
    return <UnsupportedControl text={`StatusMessageReduxControl [${identRef}]: undefined control variable value`} />;
  }

  const message = messages.find((x) => x.value === ctrlVariableValue.value);
  const type = message?.type ?? 'hidden';
  const text = message?.text;

  return (
    <ParameterGroupControl label="" alignEnd={false}>
      <StatusMessage type={type} message={text ? t(text) : ''} />
    </ParameterGroupControl>
  );
};

export default withControlVisibility(StatusMessageReduxControl);
