/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext, useEffect } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { ReduxControlProps } from '../../ReduxControlProps';
import UnsupportedControl from '../../../components/UnsupportedControl/UnsupportedControl';
import withControlVisibility from '../../hoc/withControlVisibility';
import { useTypedSelector } from '../../../store/reduxStore';
import { DatasetContext } from '../../../views/DatasetContext';
import PasswordUIntegerControl from './PasswordUIntegerControl/PasswordUIntegerControl';
import PasswordAsciiControl from './PasswordAsciiControl/PasswordAsciiControl';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { selectControlType, selectVariableDescriptor, selectVariableValue } from './selectors/passwordSelector';
import { userInputValidation } from './PasswordAsciiControl/helper';

export interface VariableReduxEditorProps extends ReduxControlProps {
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
}

const PasswordReduxControl: React.FC<VariableReduxEditorProps> = (props: VariableReduxEditorProps):
  React.ReactElement => {
  const {
    identRef, onShowContentHelp, onHideContentHelp, readonly, onStateChanged,
    deviceInstanceId,
  } = props;

  const { targetDataset } = useContext(DatasetContext);

  const controlType = useTypedSelector((state) => selectControlType(identRef, deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)));
  const variableDescriptor = useTypedSelector((state) => selectVariableDescriptor(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const variableValue = useTypedSelector((state) => selectVariableValue(state.deviceInstances, deviceInstanceId, targetDataset, identRef));

  // When control removed, the modified and error state is clean
  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    const datasetValueValidity = variableValue?.valueValidity ?? DeviceModelStatus.StatusValueValidity.valid;
    const datasetDescValidity = variableDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor
      ? variableDescriptor.validity : false;
    let error = datasetDescValidity ? datasetValueValidity !== DeviceModelStatus.StatusValueValidity.valid : false;

    // Workaround for invalid password range
    if (error === false && datasetDescValidity && variableDescriptor?.valueType.type === DeviceModelStatus.StatusDescriptorValueType.STRING && variableValue !== undefined) {
      const message = userInputValidation(variableValue.value, controlType?.minLength ?? 0, controlType?.maxLength ?? variableDescriptor.valueType.length);
      error = message !== undefined;
    }

    const datasetValueModified = variableValue?.modified ?? false;
    const modified = datasetDescValidity ? datasetValueModified : false;
    onStateChanged(identRef, {
      error,
      modified,
    });
  }, [identRef, variableValue, variableDescriptor]);

  if (controlType === undefined) {
    return <UnsupportedControl text={`PasswordReduxControl: Undefined object ${identRef}`} />;
  }

  if (variableDescriptor === undefined) {
    return <UnsupportedControl text={`PasswordReduxControl: Undefined object ${identRef}`} />;
  }

  if (variableValue === undefined) {
    return <UnsupportedControl text={`PasswordReduxControl: Undefined variable object ${identRef}`} />;
  }

  const visibility = variableDescriptor.visibility ?? true;
  if (!visibility) {
    return <></>;
  }

  let control;
  switch (variableDescriptor.valueType.type) {
    case DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER:
      control = (
        <PasswordUIntegerControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={variableDescriptor}
          statusValue={variableValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
          minLength={controlType.minLength ?? 0}
          maxLength={controlType.maxLength ?? 8}
        />
      );
      break;
    case DeviceModelStatus.StatusDescriptorValueType.STRING:
      control = (
        <PasswordAsciiControl
          deviceInstanceId={deviceInstanceId}
          statusDescriptor={variableDescriptor}
          statusValue={variableValue}
          readonly={readonly}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
          minLength={controlType.minLength ?? 0}
          maxLength={controlType.maxLength ?? 8}
        />
      );
      break;
    default:
      control = <UnsupportedControl text="Supported UNSIGNED INTEGER and ASCII Only" />;
  }

  return <>{control}</>;
};

export default withControlVisibility(PasswordReduxControl);
