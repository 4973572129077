/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import InputBoxControl from '../../Basic/InputBoxControl/InputBoxControl';
import { Record } from '../PreviewTableHeader/PreviewTableHeader';
import './PreviewTableItems.css';

export interface PreviewTableItemsProps {
    records: Record[],
    showNumbering: boolean,
    identifier?: string,
    alignLeft?: boolean,
}

export const PreviewTableItems:React.FC<PreviewTableItemsProps> = (props: PreviewTableItemsProps): React.ReactElement => {
  const {
    records,
    showNumbering,
    identifier,
    alignLeft,
  } = props;

  if (records[0] === undefined) {
    return <></>;
  }

  const justifyContent = alignLeft ? 'justify-content-start' : 'justify-content-end';
  const tableControls = records.map((item, index) => (
    <div
      className={`tableControl-container-bottom-container ${justifyContent}`}
      key={`${identifier}-tableControl-el-${index + 1}`}
    >
      {showNumbering && (
        <div className="tableControl-container-bottom-row-index">
          <div className="tableControl-container-bottom-row-index-element">{`${index + 1}.`}</div>
        </div>
      )}
      <div className="tableControl-container-bottom-splitter" />
      <div
        className="tableControl-container-bottom-row"
        key={`${identifier}-tableControl-el-${index + 1}-row-${index + 1}`}
      >
        {item.values.map((valueType, index2) => (
          <div
            className="tableControl-container-bottom-row-element"
            key={`${identifier}-tableControl-el-${index + 1}-row-${index + 1}-el-${index2 + 1}`}
          >
            <InputBoxControl
              type="number"
              displayFormat="%.2f"
              value={valueType?.value ?? ''}
              readonly
              onChange={() => 0}
            />
          </div>
        ))}
      </div>
    </div>
  ));

  return (<>{tableControls}</>);
};
