import {
  legacy_createStore as createStore, PreloadedState, applyMiddleware, Middleware, Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import deviceCatalogMiddleware from './deviceCatalog/middleware/deviceCatalogServiceMiddleware';
import { linearizationDatasetMiddleware } from './linearizationDataset/middleware/linearizationDatasetMiddleware';
import { reportMiddleware } from './reportService/middleware/reportMiddleware';
import { ReportMiddleWareService } from './reportService/middleware/reportMiddlewareService';
import applicationMessageMiddleware from './applicationMessage/middleware/applicationMessageMiddleware';
import { deviceSimulationMiddleware } from './deviceSimulation/middleware/deviceSimulationMiddleware';
import { IWebWorkerDeviceManager } from '../services/WebWorkerDevice/WebWorkerDeviceManager';
import { compareViewMiddleware } from './compareView/middleware/compareViewMiddleware';
import { i18nWebServiceMiddleware } from './i18nWebService/middleware/i18nWebServiceMiddleware';
import { rootReducer, rootState } from './reduxStore';
import deviceDatasetMiddleware from './middleware/deviceDatasetMiddleware';
import {
  DatasetProviderMiddleware,
  ioLinkSystemCommandMiddleware,
  ioLinkTrio3ChannelSwitchMiddleware,
  ioLinkTrio3EventMonitorMiddleware,
  webWorkerDeviceInstantiationMiddleware,
  activeDeviceMiddleware,
  connectionStatusMiddleware,
  previewDatasetMiddleware,
  deviceMethodExecutionMiddleware,
  stageExecutionMiddleware,
  resourceProviderMiddleware,
  timelineServiceMiddleware,
} from './deviceInstances/middleware';
import ioLinkParameterMiddleware from './deviceInstances/middleware/iolink/ioLinkWriteParameter/middleware';
import appLanguageMiddleware from './middleware/appLanguage/appLanguageMiddleware';
import contactronWizardMiddleware from './middleware/contactronWizard/contactronWizardMiddleware';
import childInstanceMiddleware from './middleware/childInstanceMiddleware/childInstanceMiddleware';

export const initializeMiddlewareStore = (
  middlewares: Middleware[],
  preloadedState?: PreloadedState<rootState>,
): Store => {
  // Create middleware enhancer
  const middlewareEnhancer = applyMiddleware(...middlewares);
  // Compose with redux dev tools
  const composedEnhancers = composeWithDevTools(middlewareEnhancer);
  // Create store with all things
  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
};

export const initializeStore = (
  webWorkerDeviceManager: IWebWorkerDeviceManager,
  preloadedState?: PreloadedState<rootState>,
): Store => {
  // Create array of middlewares
  const middlewares = [
    deviceSimulationMiddleware(),
    compareViewMiddleware(),
    webWorkerDeviceInstantiationMiddleware(webWorkerDeviceManager),
    activeDeviceMiddleware(webWorkerDeviceManager),
    stageExecutionMiddleware(webWorkerDeviceManager),
    deviceMethodExecutionMiddleware(),
    DatasetProviderMiddleware(webWorkerDeviceManager),
    resourceProviderMiddleware(webWorkerDeviceManager),
    deviceCatalogMiddleware(),
    timelineServiceMiddleware(webWorkerDeviceManager),
    previewDatasetMiddleware(),
    linearizationDatasetMiddleware(),
    reportMiddleware(() => new ReportMiddleWareService()),
    applicationMessageMiddleware(),
    i18nWebServiceMiddleware(),
    deviceDatasetMiddleware(),
    ioLinkSystemCommandMiddleware(webWorkerDeviceManager),
    ioLinkTrio3ChannelSwitchMiddleware(webWorkerDeviceManager),
    ioLinkTrio3EventMonitorMiddleware(webWorkerDeviceManager),
    ioLinkParameterMiddleware(webWorkerDeviceManager),
    connectionStatusMiddleware(webWorkerDeviceManager),
    appLanguageMiddleware(),
    contactronWizardMiddleware(),
    childInstanceMiddleware(),
  ];

  return initializeMiddlewareStore(middlewares, preloadedState);
};
