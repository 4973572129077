/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */

import React from 'react';
import {
  DeviceModelStatus,
} from '@gpt/commons';
import { useTypedSelector } from '../../store/reduxStore';
import { appStructureSelector } from '../../store/selectors/rootStructureSelector';
import ReduxControl from '../../controls/ReduxControl/ReduxControl';
import ApplicationView from '../ApplicationView/ApplicationView';
import { DatasetContext } from '../DatasetContext';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import './CockpitView.scss';

const CockpitView: React.FC = ()
: React.ReactElement => {
  const activeDeviceInstance = useTypedSelector((state) => state.deviceInstances.activeDeviceInstance);
  // eslint-disable-next-line arrow-body-style
  const cockpitRootMenu = useTypedSelector((state) => {
    const rootMenu = appStructureSelector('APP_COCKPIT', deviceTargetDatasetSelector(state.deviceInstances, activeDeviceInstance, DatasetType.device));
    return rootMenu?.type !== DeviceModelStatus.StatusType.StructureDescriptor ? undefined : rootMenu;
  });
  const cockpitItems: string[] = (cockpitRootMenu?.elements ?? []).map((element) => element.identRef);

  if (activeDeviceInstance === undefined) {
    return <div />;
  }

  const cockpitViewLayout = cockpitItems.length === 1
    ? (
      <div className="cockpit-view-control__layout-container--single-row">
        <ReduxControl deviceInstanceId={activeDeviceInstance} identRef={cockpitItems[0]} onStateChanged={() => 0} readonly />
      </div>
    )
    : (
      <div className="cockpit-view-control__layout-container">
        <div>
          <ReduxControl deviceInstanceId={activeDeviceInstance} identRef={cockpitItems[0]} onStateChanged={() => 0} readonly />
        </div>
        <div className="p-2 h-100">
          <ReduxControl deviceInstanceId={activeDeviceInstance} identRef={cockpitItems[1]} onStateChanged={() => 0} readonly />
        </div>
      </div>
    );

  return (
    <div className="cockpit-view-control" data-testid="cockpit-view">
      <DatasetContext.Provider value={{ targetDataset: DatasetType.device }}>
        <ApplicationView helpbar={false} buttons={false}>
          {cockpitViewLayout}
        </ApplicationView>
      </DatasetContext.Provider>
    </div>
  );
};

export default CockpitView;
