/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { IdentRef } from '@gpt/commons';
import { StructureItems, StructureItemState } from '../controls/ReduxControlProps';

export const WIZARD_BUTTON_LABEL__TRANSFER = 'WIZARD_BUTTON__TRANSFER';
export const WIZARD_BUTTON_LABEL__NEXT = 'WIZARD_BUTTON__NEXT';

// eslint-disable-next-line max-len
const updateWizardPageState = (state: StructureItems, ident: IdentRef, pagestate: StructureItemState): StructureItems => ((state[ident] === undefined)
  ? {
    ...state,
    [ident]: pagestate,
  }
  : {
    ...state,
    [ident]: {
      ...state[ident],
      modified: pagestate.modified,
      error: pagestate.error,
    },
  });

export default updateWizardPageState;
