/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';

const deviceModelStructureDescriptorPreview: DeviceModelStatus.DeviceModelDescriptorCollection = {
  PREVIEW: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PREVIEW',
    help: '01_04_0F_14_0020:PARAMETERS__HELP',
    label: '01_04_0F_14_0020:PARAMETERS__LABEL',
    description: '',
    elements: [{
      identRef: 'PARAMETERS__DEVICEIDENTIFICATION__DEVICEIDENTIFICATION_GROUP',
    },
    {
      identRef: 'PARAMETERS__INPUTCONFIGURATION__SIGNALTYPE_GROUP',
    },
    {
      identRef: 'PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__RANGE',
    },
    {
      identRef: 'PARAMETERS__INPUTCONFIGURATION__FILTER_FACTOR_GROUP',
    },
    {
      identRef: 'PARAMETERS__INPUTCONFIGURATION__2_WIRE_COMPENSATION_GROUP',
    },
    {
      identRef: 'PARAMETERS__OUTPUTCONFIGURATION__ANALOG_OUTPUT_TYPE_GROUP',
    },
    {
      identRef: 'PARAMETERS__OUTPUTCONFIGURATION__SIGNALRANGE_ANALOG_OUTPUT_GROUP',
    },
    {
      identRef: 'PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_GROUP',
    },
    {
      identRef: 'PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_CURRENT_GROUP',
    },
    {
      identRef: 'PARAMETERS__FAULT_MONITORING_CONTACT__FAULT_MONITORING_CONTACT_GROUP',
    },
    {
      identRef: 'PARAMETERS__DIP_SWITCH__GROUP',
    },
    ],
  },
};

export default deviceModelStructureDescriptorPreview;
