/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  DataStorageState,
  typeDataStorageAction,
} from './types';

export const initialDataStorageState: DataStorageState = {
  values: {},
};

const dataStorageReducer = (
  state = initialDataStorageState,
  action: typeDataStorageAction,
): DataStorageState => {
  switch (action.type) {
    case 'DEVICE_INSTANCE__DATA_STORAGE__SET_VALUE': {
      return {
        ...state,
        values: {
          ...state.values,
          [(action).payload.key]: (action).payload.value,
        },
      };
    }
    case 'DEVICE_INSTANCE__DATA_STORAGE__REMOVE_VALUE': {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [(action).payload]: value, ...newvalues } = state.values;
      return {
        ...state,
        values: newvalues,
      };
    }
    case 'DEVICE_INSTANCE__DATA_STORAGE__CLEAR_ALL':
      return {
        ...state,
        values: {},
      };
    default:
      return state;
  }
};

export default dataStorageReducer;
