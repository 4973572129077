/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import {
  typeDeviceInstanceDatasetProviderAction,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const setDatasetList = (targetInstance: string, payload: Services.DeviceModelServer.DatasetProviderService.ExternalDataset[])
  : typeDeviceInstanceDatasetProviderAction => ({
  type: 'DEVICE_INSTANCE__DATASET_PROVIDER__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DATASET_PROVIDER__SET_DATASET_LIST',
      payload,
    },
  },
});
