/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { ExecutionState } from '../../../common';
import {
  PreviewDatasetState,
  PREVIEW_DATASET__SET_DATASET,
  PREVIEW_DATASET__SET_EXECUTION_STATE,
  SetPreviewDatasetAction,
  SetPreviewDatasetExecutionStatusAction,
  typePreviewDatasetAction,
} from './types';

export const initialPreviewDatasetState: PreviewDatasetState = {
  state: ExecutionState.init,
};

const previewDatasetReducer = (
  pstate = initialPreviewDatasetState,
  action: typePreviewDatasetAction,
): PreviewDatasetState => {
  switch (action.type) {
    case PREVIEW_DATASET__SET_EXECUTION_STATE:
      return {
        ...pstate,
        state: (action as SetPreviewDatasetExecutionStatusAction).payload,
      };
    case PREVIEW_DATASET__SET_DATASET: {
      const { payload } = (action as SetPreviewDatasetAction);
      if (payload === undefined) {
        return {
          ...pstate,
          dataset: undefined,
        };
      }

      const header = pstate.dataset?.header === undefined ? {
        caption: payload.header.caption,
        datasetId: payload.header.datasetId,
        profileName: payload.header.device.catalog.profileName,
        saveDateTime: payload.header.saveDateTime,
        userComment: payload.header.userComment,
        serialNumber: payload.header.device.instance?.serialNumber,
      } : {
        ...pstate.dataset.header,
        caption: payload.header.caption,
        datasetId: payload.header.datasetId,
        profileName: payload.header.device.catalog.profileName,
        saveDateTime: payload.header.saveDateTime,
        userComment: payload.header.userComment,
        serialNumber: payload.header.device.instance?.serialNumber,
      };

      try {
        const result = {
          ...pstate,
          dataset: {
            ...pstate.dataset,
            header,
            values: payload.values,
            descriptors: payload.descriptors,
          },
        };
        return result;
      // eslint-disable-next-line no-empty
      } catch (err) {
      }
      return pstate;
    }
    default:
      return pstate;
  }
};

export default previewDatasetReducer;
