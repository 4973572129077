/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../../../store';
import { deviceTargetDatasetSelector } from '../../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import { DatasetContext } from '../../../../views/DatasetContext';

const useComboResultValue = (outCombiVariable: string, deviceInstanceId: string, target?: DatasetType) => {
  const { targetDataset } = useContext(DatasetContext);

  const combiStatusValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target ?? targetDataset);
    return dataset?.values[outCombiVariable];
  });

  const combiStatusDescriptor = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target ?? targetDataset);
    return dataset?.descriptors[outCombiVariable];
  });

  if (combiStatusValue?.value === undefined) {
    return [undefined, '', '', undefined, '', undefined, ''] as const;
  }

  if (combiStatusDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return [undefined, '', '', undefined, '', undefined, ''] as const;
  }
  const { valueType } = combiStatusDescriptor;
  if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return [undefined, '', '', undefined, '', undefined, ''] as const;
  }

  const hiItem = valueType.enumerator.find((it) => it.value === 0);
  const loItem = valueType.enumerator.find((it) => it.value !== 0);

  return [
    combiStatusValue,
    combiStatusDescriptor.label,
    combiStatusDescriptor.help,
    hiItem?.value,
    hiItem?.label ?? '',
    loItem?.value,
    loItem?.label ?? '',
  ] as const;
};

export default useComboResultValue;
