/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, {
  useCallback, useState,
} from 'react';
import { StructureItemState, StructureItems } from '../../../controls/ReduxControlProps';
import updateWizardPageState from '../../helper';

const useWizardPageState = () => {
  const [pageStates, setPageState] = useState<StructureItems>({});
  // eslint-disable-next-line max-len
  const updatePageState = useCallback((ident: string, pagestate: StructureItemState) => setPageState(
    (state) => updateWizardPageState(state, ident, pagestate),
  ), []);

  return [pageStates, updatePageState] as const;
};

export default useWizardPageState;
