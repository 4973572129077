/* eslint-disable max-len */
import Big from 'big.js';
import { toFixedValue } from '@gpt/commons';

const logConsole = console;
/**
 * sprintf like format
 * %[flags][width][.precision|#base]specifier
 * - specifier:
 *    s - string
 *    f - float
 *    d - decimal
 *    e - exponential
 *    x - Unsigned hexadecimal integer
 * - flags
 *    - Left-justify within the given field width; Right justification is the default
 *    + Forces to precede the result with a plus or minus sign (+ or -) even for positive numbers.
 *      By default, only negative numbers are preceded with a -ve sign.
 * - width:
 *    number - Minimum number of characters to be printed
 * - precision:
 *    number - For integer specifiers (d, x): precision specifies the minimum number of digits to be written.
 *    #      - base (2, 7, 16)
 * example:
 *  log("%5d".$(12));         // '   12'
 *  log("%05d".$(12));        // '00012'
 *  log("%-5d".$(12));        // '12   '
 *  log("%5.2d".$(123));      // '  120'
 *  log("%.2f".$(1.1234));    // '1.12'
 *  log("%5.2f".$(1.1));      // ' 1.10'
 *  log("%10.2e".$(1.1));     // '   1.10e+0'
 *  log("%5.3p".$(1.12345));  // ' 1.12'
 *  ------ base converstion ---------------------
 *  log("%5x".$(45054));      // ' affe'
 *  log("%20#2x".$("45054")); // '    1010111111111110'
 *  log("%6#2d".$("111"));    // '     7'
 *  log("%6#16d".$("affe"));  // ' 45054'
 *
 * @param str Format string:
 * @param arr
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const displayFormatArgs = (str: string, ...arr: any[]): string => {
  let i = -1;
  // %
  const formatCallback = (iexp, p0, p1, p2, p3, p4): string => {
    let exp = iexp;
    if (exp === '%%') return '%';
    // eslint-disable-next-line no-plusplus
    i += 1;
    if (arr[i] === undefined) return '';
    // Parse exponential numbers
    exp = p2 ? parseInt(p2.substr(1), 10) : undefined;
    if (exp !== undefined) {
      exp = Number.isNaN(exp) ? undefined : exp;
    }
    // Parse base
    const base = p3 ? parseInt(p3.substr(1), 10) : undefined;
    if (base !== undefined) {
      exp = Number.isNaN(base) ? undefined : base;
    }

    let val: string | number;
    switch (p4) {
      case 's': val = arr[i]; break;
      // eslint-disable-next-line prefer-destructuring
      case 'c': val = arr[i][0]; break;
      case 'f': {
        const parseValue = parseFloat(arr[i]);
        if (Number.isNaN(parseValue)) return '';
        const bval = new Big(parseValue);
        val = bval.toFixed(exp);
        break;
      }
      case 'p': {
        const parseValue = parseFloat(arr[i]);
        if (Number.isNaN(parseValue)) return '';
        const bval = new Big(parseValue);
        val = bval.toPrecision(exp);
        break;
      }
      case 'e': {
        const parseValue = parseFloat(arr[i]);
        if (Number.isNaN(parseValue)) return '';
        const bval = new Big(parseValue);
        val = bval.toExponential(exp);
        break;
      }
      case 'x': {
        const parseValue = parseInt(arr[i], 10);
        if (Number.isNaN(parseValue)) return '';
        val = parseValue.toString(base || 16);
        break;
      }
      case 'd': {
        const parseValue = parseInt(arr[i], base || 10);
        if (Number.isNaN(parseValue)) return '';
        const bval = new Big(parseValue);
        val = toFixedValue(parseFloat(bval.toPrecision(exp)), 0);
        break;
      }
      default:
        return '';
    }
    val = typeof (val) === 'object' ? JSON.stringify(val) : val.toString(base);
    let sz = parseInt(p1, 10); /* padding size */
    if (Number.isNaN(sz)) sz = 0;
    const ch = p1 && p1[0] === '0' ? '0' : ' '; /* isnull? */
    while (val.length < sz) val = p0 !== undefined ? val + ch : ch + val; /* isminus? */
    return val;
  };
  const regex = /%(-)?(0?[0-9]+)?([.][0-9]+)?([#][0-9]+)?([scfpexd%])/g;
  let data = str;
  try {
    data = str.replace(regex, formatCallback);
  } catch (err: any) {
    logConsole.log('FAILED', str, arr);
    logConsole.log(' -- ', err?.message ?? 'exception');
  }
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DisplayFormat = (str: string, value: any)
  : string => (str === '' || str === undefined || str === null ? value : displayFormatArgs(str, value));

export default DisplayFormat;
