/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import {
  DeviceModelStatus, IdentRef,
} from '@gpt/commons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector, deviceWizardUpdateButtons, disposeDevice } from '../../../store';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import ReduxControl from '../../../controls/ReduxControl/ReduxControl';
import { closeCxeDeviceParameterizationApp } from '../../../services/clipxEngineerIntegrationService';
import { LoadingControl } from '../../../components/LoadingControl';
import { StructureItems } from '../../../controls/ReduxControlProps';
import updateWizardPageState from '../../helper';
import { compareTabData, MenuItemData, menuItemsSelector } from '../../../controls/selectors/menuItemsSelector';
import { DatasetContext } from '../../../views/DatasetContext';
import WizardViewPage from '../../WizardView/WizardViewPage';
import WizardView from '../../WizardView/WizardView';
import { DatasetState, DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import Page4DocumentTransfer from '../../SettingDevice/Page4DocumentTransfer/Page4DocumentTransfer';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { WizardProps } from '../../types';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';

export const GetWizardType = (state?: DatasetState): DeviceModelStatus.Wizards.CxeHardwareSwitchType | undefined => {
  const wizardDesc = state?.descriptors?.WIZARD_CXE_HARDWARE_SWITCH;

  if (wizardDesc?.type !== DeviceModelStatus.StatusType.WizardDescriptor) {
    return undefined;
  }

  if (wizardDesc.wizardType.type !== DeviceModelStatus.Wizards.WizardType.CXE_HARDWARE_SWITCH) {
    return undefined;
  }

  return wizardDesc.wizardType;
};

export const GetWizardMenu = (state?: DatasetState): IdentRef | undefined => {
  const wizardType = GetWizardType(state);
  return wizardType?.menu;
};

export const GetWizardPreviewMenu = (state?: DatasetState): IdentRef | undefined => {
  const wizardType = GetWizardType(state);
  return wizardType?.preview;
};

export const GetWizardReportMenu = (state?: DatasetState): IdentRef | undefined => {
  const wizardType = GetWizardType(state);
  return wizardType?.report;
};

const TransferDipSwitchCxeWizard: React.FC<WizardProps> = (props: WizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deviceInstanceId } = props;
  const { targetDataset } = useContext(DatasetContext);
  const [pageStates, setPageState] = useState<StructureItems>({});

  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId));
  const reportMenu = useTypedSelector((state) => GetWizardReportMenu(deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.user)));

  const wizardData: MenuItemData[] = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const rootMenuIdent = GetWizardMenu(deviceDataset);
    return menuItemsSelector(deviceDataset, rootMenuIdent);
  }, (p, c) => compareTabData(p, c));
  const wizardDataLen = wizardData.length;

  if (activeDevice === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const pages = wizardData
    .map((element, index) => (
      <WizardViewPage
        key={`wiz-cxe-transfer-hardware-page--${element.identRef}`}
        title={t<string>(element.label)}
        state={pageStates[element.identRef]}
        onChangeToNextPage={() => {
          const isLastDevicePage = (index === wizardData.length - 1);
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: isLastDevicePage ? ButtonState.hidden : ButtonState.enabled,
            complete: isLastDevicePage ? ButtonState.enabled : ButtonState.hidden,
            pcta: isLastDevicePage ? ButtonRole.complete : ButtonRole.next,
          }));
        }}
        onChangeToPreviousPage={() => {
          const isFirstDevicePage = (index === 1);
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: isFirstDevicePage ? ButtonState.disabled : ButtonState.enabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
      >
        <ReduxControl
          readonly
          identRef={element.identRef}
          deviceInstanceId={deviceInstanceId}
          onStateChanged={(ident, pagestate) => setPageState((state) => updateWizardPageState(state, ident, pagestate))}
        />
      </WizardViewPage>
    ));

  return (
    <WizardView
      title={t<string>('WIZARD_TRANSFER_HARDWARE_SWITCH__TITLE')}
      onWizardExit={async () => {
        closeCxeDeviceParameterizationApp({
          result: 'success',
        });
        dispatch(disposeDevice(deviceInstanceId));
      }}
    >
      {pages}
      <Page4DocumentTransfer
        title={t<string>('WIZARD_SETTING_DEVICE__CUSTOM_PAGE_4__TITLE')}
        deviceInstanceId={deviceInstanceId}
        reportMenu={reportMenu}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: wizardDataLen > 1 ? ButtonState.enabled : ButtonState.disabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
      />
    </WizardView>
  );
};

export default TransferDipSwitchCxeWizard;
