/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { Children, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap';
import { setContextMenuHelp, setContextParameterHelp, setContextParameterWarning } from '../../store/contexthelp';
import { TabViewItemProps } from './TabViewItem/TabViewItem';
import './TabViewControl.scss';

const createKey = (parent: IdentRef, idRef: IdentRef): string => `${parent}--${idRef}`;

export interface TabViewControlProps extends PropsWithChildren<unknown> {
  actionId: string;
  currentTab: string;
  // eslint-disable-next-line no-unused-vars
  setCurrentTab: (tabIdent: string) => void;
}

const TabViewControl: React.FC<TabViewControlProps> = (props: TabViewControlProps)
: React.ReactElement => {
  const {
    actionId,
    currentTab,
    setCurrentTab,
    children,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const arrayChildren = Children.toArray(children);
  const tabs = Children.map(arrayChildren, (child) => {
    /* istanbul ignore next */
    if (child === undefined || child === null) {
      return <></>;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const element: React.ReactElement<TabViewItemProps> = child as any;
    const { tabIdent, tabLabel, tabColor } = element.props;
    let tabClassName = 'nav-tabs--normal h-100';
    if (tabColor === 'error') {
      tabClassName = 'nav-tabs--error';
    } else if (tabColor === 'modified') {
      tabClassName = 'nav-tabs--modified';
    }
    return (
      <Tab tabClassName={tabClassName} key={`tab-${createKey(actionId, tabIdent)}`} eventKey={tabIdent} title={t(tabLabel)}>
        {child}
      </Tab>
    );
  });

  const activeKey = currentTab !== '' ? currentTab : (arrayChildren?.[0] as React.ReactElement<TabViewItemProps>)?.props?.tabIdent ?? '';
  return (
    <Tabs
      variant="tabs"
      activeKey={activeKey}
      id={`tabs-paramview-${actionId}`}
      className="menu-tabs"
      onSelect={(k) => {
        const arChildren = Children.toArray(children);
        const tabDesc = arChildren.find((tab) => (tab as React.ReactElement<TabViewItemProps>)?.props?.tabIdent === k);
        const tabLabel = tabDesc === undefined ? '' : (tabDesc as React.ReactElement<TabViewItemProps>)?.props?.tabLabel ?? '';
        const tabHelp = tabDesc === undefined ? '' : (tabDesc as React.ReactElement<TabViewItemProps>)?.props?.tabHelp ?? '';

        dispatch(setContextMenuHelp({
          title: t(tabLabel),
          text: t(tabHelp),
        }));
        setCurrentTab(k ?? '');
        dispatch(setContextParameterHelp({ title: '', text: '' }));
        dispatch(setContextParameterWarning(undefined));
      }}
    >
      {tabs}
    </Tabs>
  );
};

export default TabViewControl;
