/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';

export interface EmptyViewProps {
  title?: string;
}

const EmptyView: React.FC<EmptyViewProps> = (props: EmptyViewProps): React.ReactElement => {
  const { title } = props;

  return (
    <div>
      <h2>
        {title}
      </h2>
    </div>
  );
};

export default EmptyView;
