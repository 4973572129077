/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BackstageMenu, BackstagePage } from './BackstageMenu';
import iconArrowBack from '../../assets/icons/icon-arrow-circle-left.svg';
import BackstagePageAppInfo from './BackstagePages/PageAppInfo/BackstagePageAppInfo';
import BackstagePageSettings from './BackstagePages/BackstagePageSettings';
import BackstagePageLegalInformationV2 from './BackstagePages/LegalInformation/BackstagePageLegalInformationV2';
import './DelegateLinksToExternalBrowser';
import './Backstage.scss';

export type BackstageProps = WithTranslation;

interface BackstageState {
  selectedIndex: number;
  selectedPage?: BackstagePage;
}

class Backstage extends React.Component<BackstageProps, BackstageState> {
  public static open = (): void => {
    const bs = document.querySelector('.backstage') as HTMLDivElement;
    if (!bs || bs.classList.contains('opened')) {
      return;
    }
    bs.classList.add('slide');
    setTimeout(() => bs.classList.add('opened'), 0);
  };

  private ref = React.createRef<HTMLDivElement>();

  constructor(props: BackstageProps) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }

  private setSelectedPage = (
    selectedIndex = -1,
    selectedPage: BackstagePage | undefined = undefined,
  ) => {
    this.setState((state) => ({
      ...state, selectedIndex, selectedPage,
    }));
  };

  private close() {
    const bs = this.ref.current;
    if (!bs || bs.classList.contains('collapsed')) {
      return;
    }
    bs.classList.add('slide');
    bs.classList.add('collapsed');
    setTimeout(() => {
      bs.classList.remove('slide');
      bs.classList.remove('collapsed');
      bs.classList.remove('opened');
      this.setSelectedPage();
    }, 1000);
  }

  render(): React.ReactElement {
    const { t } = this.props;
    let { selectedIndex, selectedPage } = this.state;
    const pages = [
      {
        title: t<string>('BACKSTAGE_PAGE_TITLE__APP_INFO'),
        createControl: () => <BackstagePageAppInfo />,
      },
      {
        title: t<string>('BACKSTAGE_PAGE_TITLE__LEGAL_INFORMATION'),
        createControl: () => <BackstagePageLegalInformationV2 />,
      },
      {
        title: t<string>('BACKSTAGE_PAGE_TITLE__SETTINGS'),
        createControl: () => <BackstagePageSettings />,
      },
    ];

    if (!selectedPage) {
      // eslint-disable-next-line prefer-destructuring
      selectedPage = pages[0];
      selectedIndex = 0;
    }

    return (
      <div className="backstage-container">
        <div className="backstage AppContent" ref={this.ref}>
          <div className="backstage-menubar">
            <div>
              <div
                className="backstage-close"
                role="button"
                tabIndex={0}
                onClick={(ev) => { ev.stopPropagation(); this.close(); }}
                onKeyDown={(ev) => ev.key === 'Enter' && this.close()}
              >
                <img src={iconArrowBack} alt="" />
              </div>
            </div>
            <BackstageMenu
              selectedIndex={selectedIndex}
              onPageSelected={this.setSelectedPage}
              pages={pages}
            />
          </div>
          <div className={`backstage-content ${selectedPage ? 'opened' : ''}`}>
            { selectedPage?.createControl() ?? <></> }
          </div>
        </div>
      </div>
    );
  }
}

export const openBackstage = (): void => {
  Backstage.open();
};

export default withTranslation()(Backstage);
