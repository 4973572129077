/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { SystemSettingVariable } from './SystemSettingVariable/SystemSettingVariable';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import { ROOT_DEVICEINSTANCE_ID } from '../../../../helpers/createDeviceInstanceId';
import './CaparocSystemSettings.scss';

const varSwitchOnDelayBetweenChannels = 'var_V_SwitchOnDelayBetweenChannels';
const varDeviceAccessLocksLocalParameterization = 'var_V_DeviceAccessLocks__LocalParameterization';
const varDeviceAccessLocksLocalUserInterface = 'var_V_DeviceAccessLocks__LocalUserInterface';

const CaparocSystemSettings: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  const systemSettings = [
    varSwitchOnDelayBetweenChannels,
    varDeviceAccessLocksLocalParameterization,
    varDeviceAccessLocksLocalUserInterface,
  ].map((variable) => (
    <SystemSettingVariable key={`system-settings--${variable}`} deviceInstanceId={ROOT_DEVICEINSTANCE_ID} variableIdent={variable} />
  ));

  return (
    <PaperControl title={t<string>('CAPAROC_STATION_SYSTEM_SETTINGS')}>
      <div className="caparoc-system-settings-container">
        <div className="caparoc-system-settings-container__content">
          {systemSettings}
        </div>
      </div>
    </PaperControl>
  );
};

export default CaparocSystemSettings;
