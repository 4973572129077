/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../../store';
import './StationModuleRow.scss';

export interface StationModuleRowProps {
  mkey: string;
}

const StationModuleRow: React.FC<StationModuleRowProps> = (props: StationModuleRowProps)
: React.ReactElement => {
  const { mkey } = props;
  const { t } = useTranslation();
  const mod = useTypedSelector((state) => state.contactronInitWizard.modules[mkey]);

  const rowClassName = mod.highlight ? 'tr--highlight' : 'tr--standard';
  const hintTextClassName = mod.highlight ? 'tr-device-type-container__hint-text' : 'tr-device-type-container__hint-text--hidden';

  return (
    <tr className={rowClassName}>
      <td>{mod.instance.address}</td>
      <td>
        <div className="tr-device-type-container">
          <div>{mod.instance.name}</div>
          <div className={hintTextClassName}>{t('WIZARD_CONTACTRON__STATION_ADDRESS__MODULE_HIGHLIGHT_TEXT', { DEVICE_NUMBER: mod.instance.address })}</div>
        </div>
      </td>
    </tr>
  );
};

export default StationModuleRow;
