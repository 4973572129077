/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */

import { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import {
  Services,
} from '@gpt/commons';
import { IWebWorkerDeviceManager } from '../../../../services/WebWorkerDevice/WebWorkerDeviceManager';
import { deviceInstancesStoreSelector } from '../../../reduxStoreSelector';
import { DatasetType } from '../../store/deviceDataset/types';
import { setDeviceMethodExecutionState } from '../../store/deviceMethod/actions';
import { MethodStageExecutionStatus } from '../../store/deviceMethod/types';
import { parseExternalDataset, prepareDatasetFromExternalValues } from '../helpers/webDeviceExternalFile';
import {
  typeDeviceInstanceActiveDeviceMiddlewareActions,
  ACTIVE_DEVICE__WRITE_VARIABLE_VALUES,
  ACTIVE_DEVICE__LOAD_DATASET__FROM__FILE,
  ACTIVE_DEVICE__LOAD_DATASET__CONTENT,
  ACTIVE_DEVICE__WRITE_INITIAL_DATASET,
} from './types';
import { deviceInstanceActiveDeviceInstance } from '../../store/activeDevice/selectors';

const logConsole = console;

export const activeDeviceMiddleware = (webWorkerDeviceManager: IWebWorkerDeviceManager)
: Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeDeviceInstanceActiveDeviceMiddlewareActions>(action: A)
: Promise<A> => {
  const dispatchLoadingExternalFileProgress = (
    deviceInstanceId: string,
    methodIdent: string,
    stage: MethodStageExecutionStatus,
    message?: string,
  ): void => {
    api.dispatch(setDeviceMethodExecutionState(deviceInstanceId, {
      methodIdent, stage, steps: [], message,
    }));
  };
  /**
   * Write values to the device model and reset modified flag
   * @param statusRefs list of values to write
   */
  const actionWriteVariableValues = async (
    deviceInstanceId: string,
    targetDataset: DatasetType,
    statusRefs: Services.DeviceModel.StatusValueRef[],
  ) => {
    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const webWorkerInstance = deviceInstances.instances[deviceInstanceId]?.activeDevice.modelInstance?.webWorkerInstanceRef;
    if (webWorkerInstance === undefined) {
      return;
    }

    const deviceDataset = webWorkerDeviceManager.get(webWorkerInstance)?.get(targetDataset);
    if (deviceDataset === undefined) {
      return;
    }
    await deviceDataset.writeVariableValues(statusRefs);
  };

  /**
   * UseCase: Load external dataset for dataset comparison
   * Write values to external value
   * */
  const actionUploadDatasetFromFile = async (
    deviceInstanceId: string,
    targetDataset: DatasetType,
    externalFilename: string,
    externalFileContent: string,
    methodId: string,
  ) => {
    dispatchLoadingExternalFileProgress(deviceInstanceId, methodId, MethodStageExecutionStatus.InProgress);
    const loadedFile = await parseExternalDataset(externalFilename, externalFileContent);
    const { dataset } = loadedFile;
    if (dataset === undefined) {
      const errorMessage = `Failed to load external file "${externalFilename}": "${loadedFile.errorMessage}"`;
      dispatchLoadingExternalFileProgress(deviceInstanceId, methodId, MethodStageExecutionStatus.DoneFailed, errorMessage);
      return;
    }

    // Check if dataset pass to the selected device
    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const deviceInstance = deviceInstanceActiveDeviceInstance(deviceInstances, deviceInstanceId);
    const device = deviceInstance?.device;
    // Check if device profileName
    if (device?.catalog.profileName !== dataset.header.device.catalog.profileName) {
      dispatchLoadingExternalFileProgress(
        deviceInstanceId,
        methodId,
        MethodStageExecutionStatus.DoneFailed,
        'Cannot load external dataset, profile name is not equal: Expected '
        + `${device?.catalog.profileName ?? 'unknown'}, file: ${dataset.header.device.catalog.profileName}`,
      );
      return;
    }

    const webWorkerInstance = deviceInstance?.webWorkerInstanceRef;
    if (webWorkerInstance === undefined) {
      dispatchLoadingExternalFileProgress(
        deviceInstanceId,
        methodId,
        MethodStageExecutionStatus.DoneFailed,
        'WebWorker Refernce is not set',
      );
      return;
    }

    const userDataset = deviceInstances.instances[deviceInstanceId]?.deviceDataset.user;
    if (userDataset === undefined) {
      dispatchLoadingExternalFileProgress(deviceInstanceId, methodId, MethodStageExecutionStatus.DoneFailed);
      return;
    }

    const newValues = prepareDatasetFromExternalValues(dataset.values, userDataset.values, userDataset.descriptors);
    const webDeviceDataset = webWorkerDeviceManager.get(webWorkerInstance)?.get(targetDataset);
    if (webDeviceDataset === undefined) {
      dispatchLoadingExternalFileProgress(deviceInstanceId, methodId, MethodStageExecutionStatus.DoneFailed);
    } else {
      await webDeviceDataset.writeUploadValues(newValues);
      dispatchLoadingExternalFileProgress(deviceInstanceId, methodId, MethodStageExecutionStatus.DoneSuccess);
    }
  };

  /**
   * UseCase: Load external dataset for dataset comparison
   * Write values to external value
   * */
  const actionLoadDatasetContent = async (
    deviceInstanceId: string,
    targetDataset: DatasetType,
    externalDataset: Services.DeviceModelServer.DatasetProviderService.ExternalDataset,
  ) => {
    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const userDataset = deviceInstances.instances[deviceInstanceId]?.deviceDataset.device;
    const newValues = prepareDatasetFromExternalValues(externalDataset.values, userDataset.values, userDataset.descriptors);

    const webWorkerInstanceRef = deviceInstances.instances[deviceInstanceId]?.activeDevice.modelInstance?.webWorkerInstanceRef;
    if (webWorkerInstanceRef === undefined) {
      return;
    }

    const webDeviceDataset = webWorkerDeviceManager.get(webWorkerInstanceRef)?.get(targetDataset);
    if (webDeviceDataset === undefined) {
      return;
    }
    await webDeviceDataset.writeUploadValues(newValues);
  };

  /**
   * UseCase: Load external dataset for dataset comparison
   * Write values to external value
   * */
  const actionWriteInitialValueDataset = async (deviceInstanceId: string, targetDataset: DatasetType) => {
    // Device model instantiated and data read from device
    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const webWorkerInstanceRef = deviceInstances.instances[deviceInstanceId]?.activeDevice.modelInstance?.webWorkerInstanceRef;
    if (webWorkerInstanceRef === undefined) {
      return;
    }
    const webDeviceDataset = webWorkerDeviceManager.get(webWorkerInstanceRef)?.get(targetDataset);
    if (webDeviceDataset === undefined) {
      return;
    }
    // TODO: Check if initial values must be uploaded
    const methodResponse = await webDeviceDataset.executeMethod({
      kind: 'WEBDEVICE__EXECUTE_METHOD__REQUEST',
      requestId: 'CREATE_DEFAULT_VALUES',
      methodIdent: 'createDefaultDatasetValues',
      deviceInstanceId,
      request: {
        type: 'WEBDEVICE__METHOD_EXECUTE__INIT',
      },
      header: {},
    });
    const { response } = methodResponse;
    if (response.type === 'WEBDEVICE__METHOD_EXECUTE__DONE__SUCCESS') {
      // await webDeviceDataset.writeDataset(initialValues);
      await webDeviceDataset.writeUploadValues(response.data ?? []);
    }
  };

  try {
    switch (action.type) {
      case ACTIVE_DEVICE__WRITE_VARIABLE_VALUES: {
        const { data, targetInstance } = action.payload;
        await actionWriteVariableValues(targetInstance, DatasetType.user, data.values);
        break;
      }
      case ACTIVE_DEVICE__LOAD_DATASET__FROM__FILE: {
        const { data, targetInstance } = action.payload;
        const { externalFile } = data;
        const { filename, content, methodId } = externalFile;
        await actionUploadDatasetFromFile(targetInstance, DatasetType.user, filename, content, methodId);
        break;
      }
      case ACTIVE_DEVICE__LOAD_DATASET__CONTENT: {
        const { data, targetInstance } = action.payload;
        const { externalDataset } = data;
        await actionLoadDatasetContent(targetInstance, DatasetType.user, externalDataset);
        break;
      }
      case ACTIVE_DEVICE__WRITE_INITIAL_DATASET: {
        const { deviceInstance } = action.payload;
        await actionWriteInitialValueDataset(deviceInstance, DatasetType.user);
        break;
      }
      default:
    }
  } catch (error) {
    logConsole.log('deviceInstantiationMiddleware failed', error);
  }

  const result = next(action);
  return result;
};
