/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus, IdentRef, toFixedValue } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { PreviewTableControl } from '../../../controls/PreviewTableControl/PreviewTableControl';
import { GetDisplayFormat } from '../../../helpers/functions';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../../hooks/useContextDataset';
import UnsupportedControl from '../../UnsupportedControl/UnsupportedControl';
import PreviewEditorControl from '../PreviewEditorControl/PreviewEditorControl';

export interface PreviewValueVariableControlProps {
  identRef: IdentRef;
  deviceInstanceId: string;
}

const PreviewValueVariableControl:
React.FC<PreviewValueVariableControlProps> = (props: PreviewValueVariableControlProps):
React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const statusDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  const statusValue = useContextDatasetStatusValue(deviceInstanceId, identRef);

  if (statusDescriptor === undefined || statusValue === undefined) {
    return <UnsupportedControl text={`PreviewValueVariableControl: Undeifined object ${identRef}`} />;
  }

  if (statusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text={`PreviewValueVariableControl: Unsupported type ${statusDescriptor.type}`} />;
  }

  let variableValue = '';
  let isTableDesc = false;
  switch (statusDescriptor.valueType.type) {
    case DeviceModelStatus.StatusDescriptorValueType.FLOAT: {
      const parsedValue = Number.parseFloat(`${statusValue.value}`);
      variableValue = toFixedValue(parsedValue, 2);
    }
      break;
    case DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR: {
      const enumValue = statusDescriptor.valueType as DeviceModelStatus.StatusDescriptorValueTypeEnumerator;
      const value = enumValue.enumerator.find((entry) => entry.value === statusValue.value);
      variableValue = value !== undefined ? t(value.label) : '';
    }
      break;
    case DeviceModelStatus.StatusDescriptorValueType.INTEGER:
    case DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER:
    case DeviceModelStatus.StatusDescriptorValueType.STRING:
    case DeviceModelStatus.StatusDescriptorValueType.DATETIME:
    case DeviceModelStatus.StatusDescriptorValueType.DATE:
    case DeviceModelStatus.StatusDescriptorValueType.IPV4_ADDRESS:
    case DeviceModelStatus.StatusDescriptorValueType.MAC_ADDRESS:
      variableValue = statusValue.value ?? '';
      break;
    case DeviceModelStatus.StatusDescriptorValueType.TABLE:
      isTableDesc = true;
      break;
    default:
      variableValue = '';
  }

  if (isTableDesc) {
    return <PreviewTableControl deviceInstanceId={deviceInstanceId} identRef={identRef} />;
  }

  let warning;
  if (statusDescriptor.handling !== DeviceModelStatus.StatusDescriptorHandling.READ_ONLY) {
    warning = statusValue.valueValidity !== DeviceModelStatus.StatusValueValidity.valid ? statusValue.valueValidityDescription : undefined;
  }

  const displayFormat = GetDisplayFormat(statusDescriptor);
  return (
    <PreviewEditorControl
      label={t<string>(statusDescriptor.label)}
      value={variableValue}
      displayFormat={displayFormat}
      warning={warning}
    />
  );
};

export default PreviewValueVariableControl;
