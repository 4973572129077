/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { useContext } from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { DatasetContext } from '../../../views/DatasetContext';
import { useTypedSelector } from '../../reduxStore';
import { deviceTargetDatasetSelector } from '../store/dataStorage/selectors';
import { DatasetType, DatasetState } from '../store/deviceDataset/types';

// eslint-disable-next-line import/prefer-default-export
export const useContextDeviceDataset = (deviceInstanceId: string | undefined, target?: DatasetType): DatasetState | undefined => useTypedSelector((state) => {
  const { targetDataset } = useContext(DatasetContext);
  const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target ?? targetDataset);
  return dataset;
});

export const useContextModelDescriptor = (deviceInstanceId: string | undefined, identRef: IdentRef, target?: DatasetType)
: DeviceModelStatus.DeviceModelDescriptor | undefined => {
  const { targetDataset } = useContext(DatasetContext);
  return useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target ?? targetDataset);
    return dataset?.descriptors[identRef];
  });
};

export const useContextModelDescriptorCollection = (deviceInstanceId: string | undefined, target?: DatasetType)
: DeviceModelStatus.DeviceModelDescriptorCollection | undefined => {
  const { targetDataset } = useContext(DatasetContext);
  return useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target ?? targetDataset);
    return dataset?.descriptors;
  });
};

export const useContextStatusValue = (deviceInstanceId: string | undefined, identRef: IdentRef, target?: DatasetType)
  : DeviceModelStatus.StatusValue | undefined => {
  const { targetDataset } = useContext(DatasetContext);
  return useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target ?? targetDataset);
    return dataset?.values[identRef];
  });
};

export const useContextModelStatusValueCollection = (deviceInstanceId: string | undefined, target?: DatasetType) => {
  const { targetDataset } = useContext(DatasetContext);
  return useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target ?? targetDataset);
    return dataset?.values;
  });
};

export const useContextDatasetValid = (deviceInstanceId: string | undefined, target?: DatasetType): boolean => {
  const { targetDataset } = useContext(DatasetContext);
  return useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target ?? targetDataset);
    return dataset?.datasetValid ?? true;
  });
};
