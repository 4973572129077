/* eslint-disable max-len */
import {
  DeviceInformation, WebDevice,
} from '@gpt/commons';
import EventEmitter from 'events';
import WebWorkerLoader from './WebWorkerLoader';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import WebWorkerDeviceDataset, { IWebWorkerDeviceDataset } from './WebWorkerDeviceDataset';

export interface IWebWorkerDevice {
  // eslint-disable-next-line no-unused-vars
  init: (deviceInstanceId: string, device: DeviceInformation, wizardMode: boolean, connectionString?: string)
    => Promise<WebDevice.WebDeviceInitResponse | WebDevice.WebDeviceErrorResponse>;
  // eslint-disable-next-line no-unused-vars
  get: (dataset: DatasetType) => IWebWorkerDeviceDataset;

  dispose: () => Promise<void>;
}

export type WebWorkerDatasets = {
    // eslint-disable-next-line no-unused-vars
    [key in DatasetType]?: IWebWorkerDeviceDataset;
}

const datasetToId = {
  [DatasetType.init]: 'init',
  [DatasetType.user]: 'user',
  [DatasetType.device]: 'device',
};

export class WebWorkerDevice implements IWebWorkerDevice {
  private webWorkerDataset: WebWorkerDatasets = {};

  private webWorkerEventHandle: EventEmitter;

  constructor(webWorkerEventHandle: EventEmitter) {
    this.webWorkerEventHandle = webWorkerEventHandle;
  }

  public init = async (deviceInstanceId: string, device: DeviceInformation, wizardMode: boolean, connString?: string)
  : Promise<WebDevice.WebDeviceInitResponse | WebDevice.WebDeviceErrorResponse> => {
    // User dataset must not communicate with device
    const offlineDevice: DeviceInformation = {
      ...device,
      instance: undefined,
    };

    // Create init webworker reference object
    this.webWorkerDataset.init = this.createWebWorkerDevice(deviceInstanceId, 'init');
    const initInitResponse = await this.webWorkerDataset.init.initializeDeviceModel(offlineDevice, wizardMode);
    if (initInitResponse.kind === 'WEBDEVICE__ERROR_RESPONSE') {
      return initInitResponse;
    }

    // Create user webworker reference object
    this.webWorkerDataset.user = this.createWebWorkerDevice(deviceInstanceId, 'user');
    const userInitResponse = await this.webWorkerDataset.user.initializeDeviceModel(offlineDevice, wizardMode);
    if (userInitResponse.kind === 'WEBDEVICE__ERROR_RESPONSE') {
      return userInitResponse;
    }

    // Create device webworker reference object
    this.webWorkerDataset.device = this.createWebWorkerDevice(deviceInstanceId, 'device');
    // Initialize
    const deviceInitResponse = await this.webWorkerDataset.device.initializeDeviceModel(device, wizardMode, connString);
    if (deviceInitResponse.kind === 'WEBDEVICE__ERROR_RESPONSE') {
      return deviceInitResponse;
    }
    return deviceInitResponse;
  };

  public dispose = async (): Promise<void> => {
    const deinit = Object.keys(this.webWorkerDataset)
      .map((key) => this.webWorkerDataset[key].disposeDeviceModel());
    await Promise.all(deinit);
    this.webWorkerDataset = { };
  };

  public get = (dataset: DatasetType)
    : IWebWorkerDeviceDataset => this.webWorkerDataset[datasetToId[dataset] ?? 'user'];

  private createWebWorkerDevice = (
    deviceInstanceId: string,
    targetDataset: string,
  ): IWebWorkerDeviceDataset => {
    const webWorkerLoader = new WebWorkerLoader(this.webWorkerEventHandle);
    return new WebWorkerDeviceDataset(webWorkerLoader, deviceInstanceId, targetDataset);
  };
}
