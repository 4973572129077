/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { useTypedSelector } from '../../../../../store';
import { getControlType, linTableStatusMembers } from './common';
import { deviceTargetDatasetSelector } from '../../../../../store/deviceInstances/store/dataStorage/selectors';

const useLintableMemberDescriptors = (deviceInstanceId: string, linTableIdentRef: IdentRef, target: DatasetType)
: DeviceModelStatus.StatusDescriptor[] => {
  const dataset = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target));
  const controlType = getControlType(linTableIdentRef, dataset);
  if (controlType === undefined) {
    return [];
  }

  const members = linTableStatusMembers.reduce((acc, member) => {
    const memberId = controlType[member as string];
    const descriptor = dataset?.descriptors[memberId] as DeviceModelStatus.DeviceModelDescriptor;
    if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return acc;
    }
    if (descriptor.valueType.type === DeviceModelStatus.StatusDescriptorValueType.BLOB) {
      return acc;
    }
    return [
      ...acc,
      descriptor,
    ];
  }, [] as DeviceModelStatus.StatusDescriptor[]);
  return members;
};

export default useLintableMemberDescriptors;
