/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './ProgressBar.css';

export interface ProgressBarProps {
  percent: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps):
React.ReactElement => {
  const { percent } = props;

  return (
    <div className="progressbar-container">
      <div className="progressbar-bar" style={{ width: `${percent}%` }} />
    </div>
  );
};
