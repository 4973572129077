/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ButtonState } from '../../../store/deviceWizard';
import EditParameterFileCxeWizard from '../EditParameterFileCxe/EditParameterFileCxe';
import WizardButton from './WizardButton/WizardButton';
import IconCreateData from '../../../assets/icons/icon-create-data-grey.svg';
import IconDIPSwitches from '../../../assets/icons/dip-switches.svg';
import EditParameterDipSwitchCxeWizard from '../EditParameterDipSwitchCxe/EditParameterDipSwitchCxe';
import FailedWizardCxe from '../FailedWizardCxe/FailedWizardCxe';
import { WizardProps } from '../../types';
import useEditParameterWizardMode, { selectedWizardType } from './hooks/useEditParameterWizardMode';
import './EditParameterSelectorCxe.scss';

const EditParameterSelectorCxe: React.FC<WizardProps> = (props: WizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const { deviceInstanceId } = props;
  const [mode, setWizardMode] = useEditParameterWizardMode(deviceInstanceId);

  let activeControl = <></>;
  if (mode === selectedWizardType.select) {
    activeControl = (
      <div className="edit-parameter-selector w-100 h-100">
        <div className="edit-parameter-selector__title d-flex justify-content-start align-items-center h-100 w-100">
          <p>{t('EDIT_PARAMETER_SELECTOR__TITLE')}</p>
        </div>
        <div className="edit-parameter-selector__content d-flex flex-row justify-content-center align-items-center h-100 pt-4">
          <div className="d-flex flex-column justify-content-around align-items-center w-100 h-50">
            <div className="edit-parameter-selector__content__title">
              {t('EDIT_PARAMETER_SELECTOR__SOFTWARE_TITLE')}
            </div>
            <div>
              <Image src={IconCreateData} className="edit-parameter-selector__content__image" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <WizardButton label={t('STANDARD_BUTTON__SELECT')} pca={false} state={ButtonState.enabled} onClick={() => setWizardMode(selectedWizardType.software)} />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-around align-items-center w-100 h-50">
            <div className="edit-parameter-selector__content__title">
              {t('EDIT_PARAMETER_SELECTOR__DIPSWITCH_TITLE')}
            </div>
            <div>
              <Image src={IconDIPSwitches} className="edit-parameter-selector__content__image" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <WizardButton label={t('STANDARD_BUTTON__SELECT')} pca={false} state={ButtonState.enabled} onClick={() => setWizardMode(selectedWizardType.dipswitch)} />
            </div>
          </div>
        </div>
        {/* <div className="edit-parameter-selector__buttons d-flex flex-row-reverse align-items-right w-100 h-100">
          <WizardButton label={t('STANDARD_BUTTON__CLOSE')} pca={false} state={ButtonState.enabled} onClick={() => 0} />
        </div> */}
      </div>
    );
  } else if (mode === selectedWizardType.software) {
    activeControl = (
      <EditParameterFileCxeWizard deviceInstanceId={deviceInstanceId} />
    );
  } else if (mode === selectedWizardType.dipswitch) {
    activeControl = (
      <EditParameterDipSwitchCxeWizard deviceInstanceId={deviceInstanceId} />
    );
  } else {
    activeControl = <FailedWizardCxe title="FAILED__WIZARD_EDIT_PARAMETER__LABEL" />;
  }

  return (
    <>
      {activeControl}
    </>
  );
};

export default EditParameterSelectorCxe;
