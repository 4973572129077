/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { PropsWithChildren } from 'react';
import './DataTableControl.scss';

export type DataTableControlProps = PropsWithChildren<any>

export const DataTableControl: React.FC<DataTableControlProps> = (props: DataTableControlProps):
  React.ReactElement => {
  const {
    children,
  } = props;

  return (
    <div className="data-table-control-container">
      <div className="data-table-control">
        {children}
      </div>
    </div>
  );
};
