/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from '../../RoutePaths';
import StartupButton from '../../components/StartupButton/StartupButton';
import IconCheck from '../../assets/icons/icon-check-grey.svg';
import IconReset from '../../assets/icons/icon-reset-grey.svg';
import IconWriteData from '../../assets/icons/icon-write-data-grey.svg';
import IconExchangeDevice from '../../assets/icons/icon-exchange-device-grey.svg';
import IconCreateData from '../../assets/icons/icon-create-data-grey.svg';
import IconDIPSwitches from '../../assets/icons/dip-switches.svg';
import StartupViewHeader from './header/StartupViewHeader';
import StartupViewDeviceList from './content/StartupViewDeviceList';
import './StartupViewControl.scss';

const StartupViewControl: React.FC = () : React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="startup-view" data-testid="startup-view">
      <StartupViewHeader />
      <StartupViewDeviceList />
      <div className="startup-view__wizard">
        <div className="startup-view__wizard__header">
          <span className="startup-view__wizard__header__title startup-view__text__title">
            {t<string>('STARTUP_VIEW__WIZARDS__TITLE')}
          </span>
          <span className="startup-view__wizard__header__text  startup-view__text__subtitle">
            {t<string>('STARTUP_VIEW__WIZARDS__TEXT')}
          </span>
        </div>
        <div className="startup-view__wizard__buttons">
          <div className="startup-button startup-button-clickable">
            <StartupButton
              title={t<string>('STARTUP_BUTTON__SETUP_DEVICE__TITLE')}
              subtitle={t<string>('STARTUP_BUTTON__SETUP_DEVICE__SUBTITLE')}
              image={IconCheck}
              onClick={() => navigate(RoutePaths.WizardCommissioning)}
            />
          </div>
          <div className="startup-button startup-button-clickable">
            <StartupButton
              title={t<string>('STARTUP_BUTTON__REPLACE_DEVICE__TITLE')}
              subtitle={t<string>('STARTUP_BUTTON__REPLACE_DEVICE__SUBTITLE')}
              image={IconExchangeDevice}
              onClick={() => navigate(RoutePaths.WizardChangeDevice)}
            />
          </div>
          <div className="startup-button startup-button-clickable">
            <StartupButton
              title={t<string>('STARTUP_BUTTON__RESTORE_DEVICE__TITLE')}
              subtitle={t<string>('STARTUP_BUTTON__RESTORE_DEVICE__SUBTITLE')}
              image={IconReset}
              onClick={() => navigate(RoutePaths.WizardRestoreDevice)}
            />
          </div>
          <div className="startup-button startup-button-clickable">
            <StartupButton
              title={t<string>('STARTUP_BUTTON__CREATE_PARAMETER_FILE__TITLE')}
              subtitle={t<string>('STARTUP_BUTTON__CREATE_PARAMETER_FILE__SUBTITLE')}
              image={IconCreateData}
              onClick={() => navigate(RoutePaths.WizardCreateParameterSet)}
            />
          </div>
          <div className="startup-button startup-button-clickable">
            <StartupButton
              title={t<string>('STARTUP_BUTTON__TRANSFER_PARAMETER_FILE__TITLE')}
              subtitle={t<string>('STARTUP_BUTTON__TRANSFER_PARAMETER_FILE__SUBTITLE')}
              image={IconWriteData}
              onClick={() => navigate(RoutePaths.WizardTransferParameterFile)}
            />
          </div>
          <div className="startup-button startup-button-clickable">
            <StartupButton
              title={t<string>('STARTUP_BUTTON__SETTING_DEVICE__TITLE')}
              subtitle={t<string>('STARTUP_BUTTON__SETTING_DEVICE__SUBTITLE')}
              image={IconDIPSwitches}
              onClick={() => navigate(RoutePaths.WizardSettingDevice)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupViewControl;
