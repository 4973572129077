/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  SetDeviceDatasetStatePayload,
  UpdateDeviceDatasetPayload,
  DeviceTargetDatasetPayload,
  UpdateDeviceDatasetValuePayload,
  InitDatasetState,
  DatasetType,
  typeDeviceInstanceDeviceDatasetAction,
} from './types';

export const requestDeviceDataset = (targetInstance: string, payload: DeviceTargetDatasetPayload): typeDeviceInstanceDeviceDatasetAction => ({
  type: 'DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_DATASET__REQUEST_DATASET',
      payload,
    },
  },
});

export const setDeviceDatasetExecutionState = (targetInstance: string, payload: SetDeviceDatasetStatePayload)
  : typeDeviceInstanceDeviceDatasetAction => ({
  type: 'DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_DATASET__SET_EXECUTION_STATE',
      payload,
    },
  },
});

export const updateDeviceDataset = (targetInstance: string, payload: UpdateDeviceDatasetPayload)
  : typeDeviceInstanceDeviceDatasetAction => ({
  type: 'DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_DATASET__UPDATE_DATASET',
      payload,
    },
  },
});

export const updateMultipleDeviceDataset = (targetInstances: string[], payload: UpdateDeviceDatasetPayload)
  : typeDeviceInstanceDeviceDatasetAction => ({
  type: 'DEVICE_INSTANCE__MULTIPLE_DEVICE_DATASET__ACTIONS',
  payload: {
    targetInstances,
    action: {
      type: 'DEVICE_DATASET__UPDATE_DATASET',
      payload,
    },
  },
});

export const updateDeviceDatasetValues = (targetInstance: string, payload: UpdateDeviceDatasetValuePayload)
  : typeDeviceInstanceDeviceDatasetAction => ({
  type: 'DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_DATASET__UPDATE_DATASET_VALUE',
      payload,
    },
  },
});

export const resetDeviceDatasetIsEditedFlag = (targetInstance: string, payload: DeviceTargetDatasetPayload): typeDeviceInstanceDeviceDatasetAction => ({
  type: 'DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_DATASET__RESET__IS_EDITED',
      payload,
    },
  },
});

// TODO: Remove action
export const cleanupDeviceDataset = (targetInstance: string): typeDeviceInstanceDeviceDatasetAction => ({
  type: 'DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_DATASET__CLEANUP_DATASET',
      payload: { targetDataset: DatasetType.user },
    },
  },
});

// TODO: Remove action
export const initializeDeviceDatasets = (targetInstance: string, datasets: InitDatasetState): typeDeviceInstanceDeviceDatasetAction => ({
  type: 'DEVICE_INSTANCE__DEVICE_DATASET__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_DATASET__INITIALIZE_DATASETS',
      payload: { targetDataset: DatasetType.user, datasets },
    },
  },
});
