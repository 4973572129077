/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ResourceLoadingImage, ResourceFailedImage } from '../../../../helpers/resourceImages';
import { IWebWorkerDeviceManager } from '../../../../services/WebWorkerDevice/WebWorkerDeviceManager';
import { ExecutionState } from '../../../common';
import { DatasetType } from '../../store/deviceDataset/types';
import { typeResourceProviderMiddlewareAction, REQUEST_RESOURCE_ID } from './types';
import { setResourceItem } from '../../store/resourceProvider';
import { deviceInstancesStoreSelector } from '../../../reduxStoreSelector';

const resourceProviderMiddleware = (
  webWorkerDeviceManager: IWebWorkerDeviceManager,
): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeResourceProviderMiddlewareAction>(action: A): Promise<A> => {
  switch (action.type) {
    case REQUEST_RESOURCE_ID: {
      const { payload } = action;
      const { targetInstance, data: resourceId } = payload;

      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const webWorkerInstance = deviceInstances.instances[targetInstance]?.activeDevice.modelInstance?.webWorkerInstanceRef;

      if (webWorkerInstance === undefined) {
        break;
      }

      const webWorkerDevice = webWorkerDeviceManager.get(webWorkerInstance);
      if (webWorkerDevice === undefined) {
        break;
      }
      // resource is currently loading…
      let resourceDataUrl = ResourceLoadingImage;
      api.dispatch(setResourceItem(targetInstance, { resourceId, state: ExecutionState.pending, resourceDataUrl }));
      const response = await webWorkerDevice.get(DatasetType.device).requestDeviceResource(resourceId);

      if (response !== '') {
        // resource has been loaded successfully
        api.dispatch(setResourceItem(targetInstance, {
          resourceId,
          state: ExecutionState.success,
          resourceDataUrl: response,
        }));
      } else {
        resourceDataUrl = ResourceFailedImage;
        // resource failed to load → show error image
        api.dispatch(setResourceItem(targetInstance, { resourceId, state: ExecutionState.failed, resourceDataUrl }));
      }
      break;
    }
    default:
  }

  const result = next(action);
  return result;
};

export default resourceProviderMiddleware;
