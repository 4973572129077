/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Button } from 'react-bootstrap';
import './DeviceCardUnidentified.scss';
import { LoadingSpinner } from '../../LoadingControl/LoadingSpinner';

export interface DeviceCardUnidentifiedProps {
  productImage: string;
  protocolTitle: string;
  protocolText: string;
}

const DeviceCardUnidentified: React.FC<DeviceCardUnidentifiedProps> = (
  props: DeviceCardUnidentifiedProps,
)
: React.ReactElement => {
  const {
    productImage,
    protocolTitle,
    protocolText,
  } = props;

  return (
    <Button
      variant="default"
      className="no-devicecardunidentified-button-main p-0 m-0"
    >
      <div className="devicecardunidentified-device-container">
        <div className="devicecardunidentified-image-container">
          <img
            className="devicecardunidentified-nodevice-image m-0"
            alt="no-device"
            src={productImage}
          />
          <div className="devicecardunidentified-spinner-container">
            <LoadingSpinner size="" />
          </div>
        </div>
        <div className="devicecardunidentified-device-body">
          <div className="devicecardunidentified-device-title">{protocolTitle}</div>
          <div className="devicecardunidentified-device-text">{protocolText}</div>
        </div>
      </div>
    </Button>
  );
};

export default DeviceCardUnidentified;
