/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import ReactDOM from 'react-dom';
import {
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackstageSimulationSettings from '../../components/Backstage/BackstagePages/Settings/SimulationSettings';
import './ModalSimulationSettings.scss';

export interface SimulationSettingsDialogProps {
  closeButtonText: string;
  onClose: () => void;
}

export const SimulationSettingsDialog
: React.FC<SimulationSettingsDialogProps> = (props: SimulationSettingsDialogProps)
: React.ReactElement => {
  const { t } = useTranslation();
  const { closeButtonText, onClose } = props;

  return (
    <div className="simulation-settings-dialog__overlay">
      <div className="simulation-settings-dialog">
        <div className="simulation-settings-dialog__container">
          <div className="simulation-settings-dialog__container_settings">
            <BackstageSimulationSettings />
          </div>
          <div className="d-flex flex-row justify-content-end align-items-center w-100 px-3">
            <Button
              className="button-text"
              variant="primary"
              onClick={() => onClose()}
            >
              {t<string>(closeButtonText)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModalSimulationSettingsDialog: React.FC<SimulationSettingsDialogProps> = (props:SimulationSettingsDialogProps) => {
  const { closeButtonText, onClose } = props;
  const modalRoot = document.getElementById('modal-root');
  if (modalRoot === null) {
    return <div />;
  }
  return (
    <>
      {ReactDOM.createPortal(
        <SimulationSettingsDialog closeButtonText={closeButtonText} onClose={onClose} />,
        modalRoot,
      )}
    </>
  );
};
