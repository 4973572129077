/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useContextDatasetStatusDescriptor, useContextDatasetStatusValue } from '../../../../hooks/useContextDataset';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import VariableFloatEditorControl from '../../../../components/ParameterEditor/VariableEditorControl/VariableFloatEditorControl/VariableFloatEditorControl';
import { StructureItemStateChangeCallback } from '../../../ReduxControlProps';
import useStatusValueStateUpdate from '../../hooks/useStatusValueStateUpdate';

export interface Quint4OutputBlockRuntimeProps {
  deviceInstanceId: string;
  runtimeVariableIdentRef: string;
  target?: DatasetType;
  showContextMessageDef?: boolean;
  onStateChanged: StructureItemStateChangeCallback;
}

const Quint4OutputBlockRuntime
:React.FC<Quint4OutputBlockRuntimeProps> = (props: Quint4OutputBlockRuntimeProps):
  React.ReactElement => {
  const {
    runtimeVariableIdentRef,
    deviceInstanceId,
    target,
    showContextMessageDef,
    onStateChanged,
  } = props;
  const statusValue = useContextDatasetStatusValue(deviceInstanceId, runtimeVariableIdentRef, target);
  const statusDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, runtimeVariableIdentRef, target);
  const visibility = useStatusValueStateUpdate(runtimeVariableIdentRef, deviceInstanceId, target, onStateChanged);

  if (statusDescriptor === undefined) {
    return <>{`UNDEFINED: ${runtimeVariableIdentRef}`}</>;
  }

  if (!visibility) {
    return <></>;
  }

  return (
    <VariableFloatEditorControl
      deviceInstanceId={deviceInstanceId}
      statusDescriptor={statusDescriptor}
      statusValue={statusValue}
      readonly
      showContextMessageDef={showContextMessageDef}
    />
  );
};

export default Quint4OutputBlockRuntime;
