/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import chevron from '../../assets/icons/circle-chevron-blue.svg';
import './StartupButton.css';

export interface StartupButtonProps {
    title: string,
    subtitle: string,
    image: string,
    onClick: () => void
}

const StartupButton: React.FC<StartupButtonProps> = (props: StartupButtonProps)
: React.ReactElement => {
  const {
    title, subtitle, image, onClick,
  } = props;

  return (
    <div className="startup-button-container">
      <Button className="startup-button-root" variant="default" onClick={onClick}>
        <div className="startup-button-left-icon-container">
          <Image src={image} className="startup-button-left-icon" />
        </div>
        <div className="startup-button-text-container">
          <p className="startup-button-title-format m-0 p-0">{title}</p>
          <p className="startup-button-subtitle-format m-0 p-0">{subtitle}</p>
        </div>
        <div className="startup-button-right-icon-container">
          <Image className="startup-button-chevron-icon" src={chevron} />
        </div>
      </Button>
    </div>
  );
};

export default StartupButton;
