/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import useAppVersion from './useAppVersion';

const useAppTitleVersion = (title: string): string => {
  const appVersion = useAppVersion();

  const svgString = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <svg width="900" height="55" xmlns="http://www.w3.org/2000/svg">
        <text x="112" y="36" textAnchor="start" fill="#000000" fontFamily="'IBM Plex Sans', 'Source Sans Pro', 'Open Sans', Arial, Helvetica, sans-serif" fontSize="24" fontWeight="normal">{`${title} ${appVersion}`}</text>
      </svg>,
    ),
  );
  return `url("data:image/svg+xml,${svgString}")`;
};

export default useAppTitleVersion;
