/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IdentRef } from '@gpt/commons';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { useTypedSelector } from '../../../../../store';
import { ButtonFunction } from '../../../CompareTypes';
import { redoCompareValues, writeCompareValues } from '../../../../../store/compareView/actions';
import { buttonFunctionMode } from '../../../hooks/useCompareMiddleButton';
import { deviceTargetDatasetSelector } from '../../../../../store/deviceInstances/store/dataStorage/selectors';
import useRangeMemberDescriptors from './useRangeMemberDescriptors';

const useRangeMidButton = (deviceInstanceId: string, ctrlIdentRef: IdentRef, leftDataset: DatasetType, rightDataset: DatasetType) => {
  const dispatch = useDispatch();
  const lDataset = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, leftDataset));
  const rDataset = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, rightDataset));

  const descriptors = useRangeMemberDescriptors(deviceInstanceId, ctrlIdentRef, leftDataset);

  const middleButtonFunction = descriptors.reduce((acc, item) => {
    const btnFunc = buttonFunctionMode(lDataset?.values[item.identRef], rDataset?.values[item.identRef]);
    if (btnFunc === ButtonFunction.RedoValue) {
      return ButtonFunction.RedoValue;
    }
    if (btnFunc === ButtonFunction.Hidden) {
      return acc;
    }
    if (btnFunc === ButtonFunction.WriteValue && acc === ButtonFunction.RedoValue) {
      return acc;
    }
    return btnFunc;
  }, ButtonFunction.Hidden);

  const onMiddleButtonClick = useCallback((buttonFunction: ButtonFunction): void => {
    if (buttonFunction === ButtonFunction.RedoValue) {
      dispatch(redoCompareValues(deviceInstanceId, {
        items: [ctrlIdentRef],
        sourceDataset: rightDataset,
        targetDataset: leftDataset,
      }));
    } else if (buttonFunction === ButtonFunction.WriteValue) {
      dispatch(writeCompareValues(deviceInstanceId, {
        items: [ctrlIdentRef],
        sourceDataset: rightDataset,
        targetDataset: leftDataset,
      }));
    }
  }, [rightDataset, leftDataset, ctrlIdentRef]);

  return [middleButtonFunction, onMiddleButtonClick] as const;
};

export default useRangeMidButton;
