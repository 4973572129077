export const convertToAbsolute = (
  percentageValue: number,
  rangeStart: number,
  rangeEnd: number,
): number => {
  const isValidRange = rangeEnd != null && rangeStart != null && rangeEnd !== rangeStart;
  if (!isValidRange) {
    return 0;
  }

  return ((rangeEnd - rangeStart) * (percentageValue / 100)) + rangeStart;
};

export const convertToPercentage = (
  absoluteValue: number,
  rangeStart: number,
  rangeEnd: number,
): number => {
  const isValidRange = rangeEnd != null && rangeStart != null && rangeEnd !== rangeStart;
  if (!isValidRange) {
    return 0;
  }

  if (rangeEnd - rangeStart === 0) {
    return 0;
  }

  const proc = Math.abs(((absoluteValue - rangeStart) / (rangeEnd - rangeStart)) * 100);
  return proc;
};
