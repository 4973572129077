/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  WebDevice, DeviceModelStatus, IdentRef,
} from '@gpt/commons';
import { Store } from 'redux';
import { updateDeviceStatusEventMonitor } from './store/deviceInstances/store/DeviceStatusEventMonitor/actions';
import { updateCustomStorage } from './store/deviceInstances/store/customStorage/actions';
import { TargetCustomStorage } from './store/deviceInstances/store/customStorage/types';
import { showHeaderMessage, hideHeaaderMessage } from './store/deviceInstances/store/standardview/actions';

interface DeviceSimulationEvent {
  identRef: IdentRef;
  deviceInstanceId: string;
  value: any;
}

const handleWebDeviceCustomEvent = (store: Store, event: WebDevice.WebDeviceUpdateCustomObjectEvent): void => {
  const updateSelector = {
    ioLinkCaparocStation: () => {
      store.dispatch(updateCustomStorage(
        {
          target: TargetCustomStorage.IoLinkCaparocStation,
          content: event.object.content,
        },
      ));
    },
    DEVICESTATUSMONITOR: () => {
      const content = event.object.content as DeviceModelStatus.UI.DeviceStatusMonitorUpdateEvents;
      store.dispatch(updateDeviceStatusEventMonitor(
        event.deviceInstanceId,
        content,
      ));
    },
    'MINI-MCR2::DEVICE_STATE_SIMULATION': () => {
      const item = event.object.content as DeviceSimulationEvent;
      const { deviceInstanceId, value } = item;
      if (value === 1) {
        store.dispatch(showHeaderMessage(deviceInstanceId, { text: 'FORCE_DEVICE_HEADER__MESSAGE' }));
      } else {
        store.dispatch(hideHeaaderMessage(deviceInstanceId));
      }
    },
  };
  updateSelector[event.object.target]?.();
};

export default handleWebDeviceCustomEvent;
