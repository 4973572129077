/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/prop-types */

import React from 'react';
import { getTrackBackground, Range } from 'react-range';

export interface RangeSliderBoxProps {
  loValue: number;
  hiValue: number;
  minRangeValue: number;
  maxRangeValue: number;
  step: number;
  // eslint-disable-next-line no-unused-vars
  onRangeChange: (minValue: number, maxValue: number) => void;
  // eslint-disable-next-line no-unused-vars
  onFinalRangeChange: (minValue: number, maxValue: number) => void;
}

const RangeSliderBoxControl: React.FC<RangeSliderBoxProps> = (prps: RangeSliderBoxProps)
: React.ReactElement => {
  const {
    loValue, hiValue, minRangeValue, maxRangeValue, step,
    onRangeChange, onFinalRangeChange,
  } = prps;

  const curLoValue = Math.max(minRangeValue, loValue);
  const curHiValue = Math.min(maxRangeValue, hiValue);

  const trackHeight = '3px';
  const thumbHeight = '11px';

  return (
    <div className="d-flex justify-content-center flex-wrap">
      <Range
        values={[curLoValue, curHiValue]}
        min={minRangeValue}
        max={maxRangeValue}
        step={step}
        rtl={false}
        allowOverlap
        draggableTrack={false}
        onChange={(values) => onRangeChange(values[0], values[1])}
        onFinalChange={(values) => onFinalRangeChange(values[0], values[1])}
        renderTrack={({ props, children }) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: thumbHeight,
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: trackHeight,
                width: '100%',
                background: getTrackBackground({
                  values: [curLoValue, curHiValue],
                  colors: ['#a2a7a9', '#0098a1', '#a2a7a9'],
                  min: minRangeValue,
                  max: maxRangeValue,
                  rtl: false,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={{
              ...props.style,
              height: thumbHeight,
              width: trackHeight,
              outline: '0',
              backgroundColor: '##0098a1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: thumbHeight,
                width: trackHeight,
                backgroundColor: '#0098a1',
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default RangeSliderBoxControl;
