/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import './NumberEditorControl.css';
import { ParameterControl } from '../../../../components/ParameterEditor';
import DisplayFormat from '../../../../helpers/displayFormat';
import { roundValue } from '../../../../helpers/precisionValue';
import useDelayedPrecisionInput from '../../../hooks/useDelayedPrecisionInput';

export interface NumberEditorProps {
    label?: string;
    value: string | number;
    displayFormat: string;
    precision?: number;
    unit?: string;
    warning?: string;
    readonly?: boolean;
    modified?: boolean;
    stepSize?: number;
    onValueChange: (e: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

const NumberEditorControl:
React.FC<NumberEditorProps> = (props: NumberEditorProps):
React.ReactElement => {
  const {
    label, value, stepSize,
    warning, unit, readonly, modified, displayFormat,
    onValueChange, onFocus, onBlur, precision,
  } = props;
  const classNameEditedText = (modified ?? false) ? 'textfield__input--edited' : '';

  const [focusActive, setActiveFocus] = useState(false);
  const [
    userInputValue,
    currentWarning,
    setUserInputValue,
    onBlurValue,
  ] = useDelayedPrecisionInput('--', value, precision, warning, onValueChange);

  const currentValue = userInputValue ?? roundValue(value, precision);
  const visibleValue = focusActive ? currentValue : DisplayFormat(displayFormat, currentValue);

  return (
    <ParameterControl
      label={label}
      unit={unit}
    >
      <div className="number-input-field">
        <input
          type="number"
          title={label}
          value={visibleValue}
          step={stepSize}
          className={`textfield__input ${classNameEditedText} ${currentWarning !== undefined ? 'invalid' : ''}`}
          readOnly={readonly}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInputValue(e.target.value)}
          onFocus={() => {
            setActiveFocus(true);
            onFocus?.();
          }}
          onBlur={() => {
            setActiveFocus(false);
            onBlurValue(currentValue);
            onBlur?.();
          }}
          data-testid="number-editor-control"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="textfield__label">{}</label>
        {/* <span className="helper-text" data-error={warning} /> */}
        <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
          <div className="input-field-control__warning-text">{warning}</div>
        </div>
      </div>
    </ParameterControl>
  );
};

export default NumberEditorControl;
