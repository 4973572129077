/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { ReduxControlProps } from '../ReduxControlProps';
import { useTypedSelector } from '../../store';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import DeviceValuePickerControl from '../../components/DeviceValuePickerControl/DeviceValuePickerControl';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import withControlVisibility from '../hoc/withControlVisibility';
import { deviceDescriptorSelector, deviceStatusValueSelector } from '../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { DeviceInstancesState } from '../../store/deviceInstances/types';

const variableSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  varIdentRef: string,
  targetDataset: DatasetType,
) => {
  const desc = deviceDescriptorSelector(state, deviceInstanceId, targetDataset, varIdentRef);
  if (desc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  if (desc.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT
    && desc.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER
    && desc.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER) {
    return undefined;
  }

  const datasetValue = deviceStatusValueSelector(state, deviceInstanceId, targetDataset, varIdentRef);
  if (datasetValue === undefined) {
    return undefined;
  }

  return {
    statusDescriptor: desc,
    datasetValue,
  };
};

const DeviceValuePickerReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);

  if (descriptor === undefined) {
    return <UnsupportedControl text={`DeviceStatusReduxControl [${identRef}]: undefined object`} />;
  }
  if (descriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text={`DeviceStatusReduxControl [${identRef}]: supported object type is ControlDescriptor only`} />;
  }
  const { controlType } = descriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLDEVICEVALUEPICKER) {
    return <UnsupportedControl text={`DeviceStatusReduxControl [${identRef}]: supported controlType is CTLDEVICEVALUEPICKER only`} />;
  }

  const minVariable = useTypedSelector((state) => variableSelector(state.deviceInstances, deviceInstanceId, controlType.minVar, DatasetType.user));
  const maxVariable = useTypedSelector((state) => variableSelector(state.deviceInstances, deviceInstanceId, controlType.maxVar, DatasetType.user));
  const deviceVariable = useTypedSelector((state) => variableSelector(state.deviceInstances, deviceInstanceId, controlType.deviceVar, DatasetType.user));

  if (minVariable === undefined || deviceVariable === undefined || maxVariable === undefined) {
    return <UnsupportedControl text={`DeviceStatusReduxControl [${identRef}]: undefined variable`} />;
  }

  if (minVariable.statusDescriptor.visibility === false || maxVariable.statusDescriptor.visibility === false
    || deviceVariable.statusDescriptor.visibility === false) {
    return (null);
  }

  return (
    <DeviceValuePickerControl
      minVariable={minVariable}
      maxVariable={maxVariable}
      deviceVariable={deviceVariable}
      deviceInstanceId={deviceInstanceId}
    />
  );
};

export default withControlVisibility(DeviceValuePickerReduxControl);
