/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import ArrowLeftIcon from '../../../../assets/icons/button-arrow-left.svg';
import './ColdJunctionLineCompensationControl.scss';
import VariableFloatEditorControl from '../../../ParameterEditor/VariableEditorControl/VariableFloatEditorControl/VariableFloatEditorControl';
import { writeActiveDeviceVariableValues } from '../../../../store';

export interface LineBalancerControlProps {
    statusDescriptor: DeviceModelStatus.StatusDescriptor;
    statusValue: DeviceModelStatus.StatusValue;
    deviceStatusDescriptor: DeviceModelStatus.StatusDescriptor;
    deviceStatusValue: DeviceModelStatus.StatusValue;
    deviceInstanceId: string;
    designation?: string;
}

const ColdJunctionLineCompensationControl: React.FC<LineBalancerControlProps> = (
  props: LineBalancerControlProps,
)
: React.ReactElement => {
  const {
    statusDescriptor, statusValue, deviceStatusDescriptor, deviceStatusValue,
    deviceInstanceId, designation,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const designationControl = designation
    ? (
      <div className="coldjunction-line-compensation-designation-container">
        <div className="coldjunction-line-compensation-designation">
          {designation}
        </div>
      </div>
    )
    : <></>;
  return (
    <div className="coldjunction-line-compensation-container">
      {designationControl}
      <div className="coldjunction-line-compensation-input">
        <VariableFloatEditorControl
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          deviceInstanceId={deviceInstanceId}
          showLabel={false}
        />
      </div>
      <div className="coldjunction-line-compensation-button-container">
        <Button
          variant="tertiary"
          className="coldjunction-line-compensation-button"
          onClick={() => {
            dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
              identRef: statusValue.identRef,
              value: deviceStatusValue.value,
              backupValue: statusValue.backupValue ?? statusValue.value,
            }]));
          }}
        >
          <img src={ArrowLeftIcon} height="20px" alt="button icon" />
          <div className="coldjunction-line-compensation-text">{t('COLD_JUNCTION_LINE_COMPENSATION__UPDATE')}</div>
        </Button>
      </div>
      <div className="coldjunction-line-compensation-devicevar">
        <VariableFloatEditorControl
          statusDescriptor={deviceStatusDescriptor}
          statusValue={deviceStatusValue}
          deviceInstanceId={deviceInstanceId}
          showLabel={false}
          readonly
        />
      </div>
    </div>
  );
};

export default ColdJunctionLineCompensationControl;
