/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../../store';

export interface RowData {
  deviceTagVarName: string;
  ifsAddress: number;
  processValueAddress: number;
  processValueLabel: string;
  deviceType: string;
  key: string;
  kind: string;
}

export interface GroupTableRowProps {
  stationInstanceId: string;
  index: number;
  row: RowData;
  pdcIndex: number
}

const GroupTableRow: React.FC<GroupTableRowProps> = (
  props: GroupTableRowProps,
) : React.ReactElement => {
  const {
    row, index, stationInstanceId, pdcIndex,
  } = props;
  const { t } = useTranslation();
  const tagValue = useTypedSelector((state) => {
    const value = state.deviceInstances.instances[stationInstanceId]?.deviceDataset.user.values[row.deviceTagVarName]?.value;
    return value === undefined ? '' : value as string;
  });

  return (
    <tr>
      <td>{index}</td>
      <td>{tagValue}</td>
      <td>{row.ifsAddress}</td>
      <td>{pdcIndex}</td>
      <td>{t(row.processValueLabel)}</td>
      <td>{row.deviceType}</td>
    </tr>
  );
};

export default GroupTableRow;
