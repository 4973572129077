/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Services } from '@gpt/commons';
import {
  typeDeviceInstanceActionTypes,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const createDeviceInstance = (
  targetInstance: string,
  data?: Services.DeviceModel.DeviceInstanceData,
): typeDeviceInstanceActionTypes => ({
  type: 'DEVICE_INSTANCE__CREATE_ACTION',
  payload: {
    targetInstance,
    data,
  },
});

export const removeDeviceInstance = (targetInstance: string)
: typeDeviceInstanceActionTypes => ({
  type: 'DEVICE_INSTANCE__REMOVE_ACTION',
  payload: {
    targetInstance,
  },
});

export const removeAllDeviceInstances = ()
: typeDeviceInstanceActionTypes => ({
  type: 'DEVICE_INSTANCE__REMOVE_ALL_ACTION',
});

export const selectDeviceInstance = (targetInstance?: string)
: typeDeviceInstanceActionTypes => ({
  type: 'DEVICE_INSTANCE__SELECT_ACTION',
  payload: {
    targetInstance,
  },
});
