/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { SelectEditorControl } from '../../../../components/ParameterEditor';
import useIntegerMessageContextHelp from '../../../../components/ParameterEditor/VariableEditorControl/hooks/useIntegerMessageContextHelp';
import { VariableEditorControlsProps } from '../../../../components/ParameterEditor/VariableEditorControl/types';

const itemOptions = (
  valueType: DeviceModelStatus.StatusDescriptorValueTypeEnumerator,
  statusValue: DeviceModelStatus.StatusValue | undefined,
  t: TFunction,
): { [val: number]: string } => {
  const result = {};
  if (statusValue !== undefined) {
    valueType.enumerator.forEach((entry) => {
      result[entry.value] = t(entry.label);
    });
  } else {
    result[0] = t('N/A');
  }
  return result;
};

export interface ModeSelectorControlProps extends VariableEditorControlsProps {
   // eslint-disable-next-line no-unused-vars
   onChangeHandler: (value: number) => void;
}

const ModeSelectorControl
:React.FC<ModeSelectorControlProps> = (props: ModeSelectorControlProps):
  React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp,
    showContextMessageDef,
    onChangeHandler,
  } = props;
  const { t } = useTranslation();
  const [warning, showContextHelp, hideContentHelp] = useIntegerMessageContextHelp(statusDescriptor, statusValue, showContextMessageDef, onShowContentHelp, onHideContentHelp);

  const { valueType } = statusDescriptor;
  if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return <div />;
  }

  const { label, help } = statusDescriptor;
  return (
    <SelectEditorControl
      label={`${t(label)}`}
      value={statusValue === undefined ? 0 : statusValue.value}
      options={itemOptions(valueType, statusValue, t)}
      edited={statusValue?.modified}
      help={`${t(help)}`}
      warning={warning}
      onChange={(event) => onChangeHandler(parseInt(event.target.value, 10))}
      onShowContentHelp={() => showContextHelp()}
      onHideContentHelp={() => hideContentHelp()}
    />
  );
};

export default ModeSelectorControl;
