/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import './OnlineCardControl.scss';

export interface OnlineCardControlProps extends PropsWithChildren<any> {
  label: string;
}

const OnlineCardControl: React.FC<OnlineCardControlProps> = (props: OnlineCardControlProps):
  React.ReactElement => {
  const {
    label,
    children,
  } = props;

  const { t } = useTranslation();
  return (
    <div className="motorstarter-online-card">
      <div className="motorstarter-online-card__title">{t(label)}</div>
      <div className="motorstarter-online-card__body">
        {children}
      </div>
    </div>
  );
};

export default OnlineCardControl;
