import { Services, WebDevice } from '@gpt/commons';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line import/prefer-default-export
export const createExecuteMethodRequest = (
  deviceInstanceId: string,
  methodIdent: string,
  actionId: string,
  data?: Services.DeviceModel.StatusValueRef[],
): WebDevice.WebDeviceExecuteMethodRequest => ({
  kind: 'WEBDEVICE__EXECUTE_METHOD__REQUEST',
  header: {},
  methodIdent,
  requestId: uuidv4(),
  actionId,
  deviceInstanceId,
  request: {
    type: 'WEBDEVICE__METHOD_EXECUTE__INIT',
    data: data ?? [],
  },
});
