/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import stationImage from '../../assets/00162570_STANDARD_PICTURE_15_SRGB_klein.png';
import './DeviceNamingImage.scss';

const DeviceNamingImage: React.FC = () : React.ReactElement => (
  <div className="page-device-naming__image contactron-commissioning-wizard">
    <div className="page-device-naming-image">
      <img className="page-device-naming-image__image" src={stationImage} alt="Phoenix Contact Splash" />
    </div>
  </div>
);

export default DeviceNamingImage;
