/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import {
  typeStandardViewMiddlewareAction,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const setWebWorkerConnectionStatus = (
  targetInstance: string,
  status: Services.DeviceModelServer.ConnectionService.ConnectionStatus,
): typeStandardViewMiddlewareAction => ({
  type: 'STANDARD_VIEW__SET_CONNECTION_STATUS',
  payload: {
    targetInstance,
    data: {
      status,
    },
  },
});
