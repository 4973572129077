/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { setContextParameterHelp, setContextParameterWarning } from '../../../../store/contexthelp';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import { UnsupportedControl } from '../../../UnsupportedControl';
import { IPV4AddressControl } from '../../IPV4AddressControl/IPV4AddressControl';
import ParameterControl from '../../ParameterControl';
import './VariableIPV4AddressEditorControl.css';
import { updateDeviceDatasetValues } from '../../../../store/deviceInstances/store/deviceDataset/actions';
import { VariableEditorControlsProps } from '../types';

const validateIPaddress = (ipaddress: string)
  : boolean => (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress));

const VariableIPV4AddressEditorControl:
React.FC<VariableEditorControlsProps> = (props: VariableEditorControlsProps):
React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp,
    showContextMessageDef, readonly,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warning, setWarning] = useState<string | undefined>(undefined);
  const [showContextMessage, setShowContextMessage] = useState<boolean>(showContextMessageDef ?? false);

  const statusValueLabel = t(statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? statusDescriptor.label : '');
  const statusValueHelp = t(statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? statusDescriptor.help : '');

  const { valueValidityDescription, valueValidity } = statusValue ?? {
    valueValidityDescription: undefined, valueValidity: DeviceModelStatus.StatusValueValidity.valid,
  };

  useEffect(() => {
    if (statusValue === undefined) {
      return;
    }

    const updateMessage = (message?: string) => {
      if (showContextMessage) {
        dispatch(setContextParameterWarning(message));
      }
      setWarning(message);
    };

    if (valueValidity === DeviceModelStatus.StatusValueValidity.valid) {
      updateMessage(undefined);
    } else {
      updateMessage(valueValidityDescription
        ? t<string>(valueValidityDescription)
        : t<string>('PARAMETER_VALIDATION_ERROR__INVALID_IPADDRESS_FORMAT'));
    }
  }, [statusValue, showContextMessage, valueValidityDescription, valueValidity]);

  if (statusDescriptor === undefined) {
    return <div />;
  }
  if (statusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="VariableUIntegerEditorControl: Unsupported input object type" />;
  }
  if (statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.IPV4_ADDRESS) {
    return <UnsupportedControl text="VariableDateEditorControl: only IPV4_ADDRESS type is supported" />;
  }

  const { label } = statusDescriptor;
  return (
    <ParameterControl
      label={t<string>(label)}
    >
      <IPV4AddressControl
        value={statusValue === undefined ? 'N/A' : statusValue.value}
        readonly={readonly ?? (statusValue === undefined || statusDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY)}
        warning={warning}
        onValueChange={(value) => {
          if (statusValue === undefined) {
            return;
          }

          if (validateIPaddress(value)) {
            dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
              value,
              backupValue: statusValue.backupValue,
              identRef: statusValue.identRef,
            }]));
          } else {
            dispatch(updateDeviceDatasetValues(deviceInstanceId, {
              targetDataset: DatasetType.user,
              values: [{
                value,
                backupValue: statusValue.backupValue,
                identRef: statusValue.identRef,
                valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
                valueValidityDescription: t<string>('PARAMETER_VALIDATION_ERROR__INVALID_IPADDRESS_FORMAT'),
              }],
            }));
          }
        }}
        onShowContextHelp={() => {
          setShowContextMessage(true);
          dispatch(setContextParameterHelp({
            title: statusValueLabel,
            text: statusValueHelp,
          }));

          onShowContentHelp?.();
        }}
        onHideContextHelp={() => {
          setShowContextMessage(false);
          dispatch(setContextParameterWarning(undefined));
          onHideContentHelp?.();
        }}
        modified={statusValue?.modified}
      />
    </ParameterControl>
  );
};

export default VariableIPV4AddressEditorControl;
