/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  DeviceWizardState,
  WizardActionTypes,
  ButtonRole,
  ButtonState,
  DEVICE_WIZARD__UPDATE_BUTTONS,
  DEVICE_WIZARD__DISABLE_ALL_BUTTONS,
  DEVICE_WIZARD__INIT_BUTTONS,
  DEVICE_WIZARD__SET_BUTTON_STATE,
  DEVICE_WIZARD__RESET_FOCUS,
  WizardButtonState,
} from './types';

export const initialDeviceWizardState: DeviceWizardState = {
  abort: {
    state: ButtonState.enabled,
    label: 'WIZARD_BUTTON__ABORT',
  },
  back: {
    state: ButtonState.disabled,
    label: 'WIZARD_BUTTON__BACK',
  },
  next: {
    state: ButtonState.enabled,
    label: 'WIZARD_BUTTON__NEXT',
  },
  complete: {
    state: ButtonState.hidden,
    label: 'WIZARD_BUTTON__COMPLETE',
  },
  load: {
    state: ButtonState.hidden,
    label: 'WIZARD_BUTTON__LOAD',
  },
  pcta: ButtonRole.next,
  backupFilename: '',
};

const initLoadButtonState: WizardButtonState = {
  label: 'WIZARD_BUTTON__LOAD',
  state: ButtonState.hidden,
};

const deviceWizardStateReducer = (
  state = initialDeviceWizardState,
  action: WizardActionTypes,
): DeviceWizardState => {
  switch (action.type) {
    case DEVICE_WIZARD__INIT_BUTTONS: {
      return {
        ...state,
        abort: {
          state: action.payload.abort,
          label: 'WIZARD_BUTTON__ABORT',
        },
        back: {
          state: action.payload.back,
          label: 'WIZARD_BUTTON__BACK',
        },
        next: {
          state: action.payload.next,
          label: 'WIZARD_BUTTON__NEXT',
        },
        complete: {
          state: action.payload.complete,
          label: 'WIZARD_BUTTON__COMPLETE',
        },
        load: {
          state: action.payload.load ?? ButtonState.hidden,
          label: 'WIZARD_BUTTON__LOAD',
        },
        pcta: action.payload.pcta,
      };
    }
    case DEVICE_WIZARD__SET_BUTTON_STATE: {
      return {
        ...state,
        abort: {
          ...state.abort,
          label: action.payload.abort.label ?? state.abort.label,
          state: action.payload.abort.state,
        },
        back: {
          ...state.back,
          label: action.payload.back.label ?? state.back.label,
          state: action.payload.back.state,
        },
        next: {
          ...state.next,
          label: action.payload.next.label ?? state.next.label,
          state: action.payload.next.state,
        },
        load: {
          ...(state.load ?? initLoadButtonState),
          label: action.payload.load?.label ?? (state.load?.label ?? 'WIZARD_BUTTON__LOAD'),
          state: action.payload.load?.state ?? ButtonState.hidden,
        },
        complete: {
          ...state.complete,
          label: action.payload.complete.label ?? state.complete.label,
          state: action.payload.complete.state,
        },
        pcta: action.payload.pcta ?? state.pcta,
        focusPcta: action.payload.focusPcta,
      };
    }
    case DEVICE_WIZARD__UPDATE_BUTTONS: {
      return {
        ...state,
        abort: {
          ...state.abort,
          state: action.payload.abort,
        },
        back: {
          ...state.back,
          state: action.payload.back,
        },
        next: {
          ...state.next,
          state: action.payload.next,
        },
        complete: {
          ...state.complete,
          state: action.payload.complete,
        },
        load: {
          ...(state.load ?? initLoadButtonState),
          state: action.payload.load ?? ButtonState.hidden,
        },
        pcta: action.payload.pcta ?? state.pcta,
        focusPcta: action.payload.focusPcta ?? state.focusPcta,
      };
    }
    case DEVICE_WIZARD__DISABLE_ALL_BUTTONS: {
      const newstate = {
        ...state,
        abort: {
          ...state.abort,
          state: state.abort.state === ButtonState.hidden ? state.abort.state : ButtonState.disabled,
        },
        back: {
          ...state.back,
          state: state.back.state === ButtonState.hidden ? state.back.state : ButtonState.disabled,
        },
        next: {
          ...state.next,
          state: state.next.state === ButtonState.hidden ? state.next.state : ButtonState.disabled,
        },
        complete: {
          ...state.complete,
          state: state.complete.state === ButtonState.hidden ? state.complete.state : ButtonState.disabled,
        },
      };
      return newstate;
    }
    case DEVICE_WIZARD__RESET_FOCUS:
      return {
        ...state,
        focusPcta: undefined,
      };
    case 'DEVICE_WIZARD__SET_BACKUP_FILENAME':
      return {
        ...state,
        backupFilename: action.payload,
      };
    default:
      return state;
  }
};

export default deviceWizardStateReducer;
