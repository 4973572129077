/* eslint-disable max-len */
import React from 'react';
import {
  mapValueToAngle, gaugeMinAngle, gaugeMaxAngle,
  gaugeStrokeWidth, gaugeCenterX, gaugeCenterY, gaugeRadius,
} from '../../common';
import './SVGGaugeIndicator.scss';

export interface SVGGaugeControlProps {
  value: number,
  gaugeMinValue: number,
  gaugeMaxValue: number
}

const SVGGaugeIndicator: React.FC<SVGGaugeControlProps> = (props: SVGGaugeControlProps)
: React.ReactElement => {
  const { value, gaugeMaxValue, gaugeMinValue } = props;

  const indicatorAngle = mapValueToAngle(gaugeMinValue, gaugeMaxValue, gaugeMinAngle, gaugeMaxAngle, value);

  return (
    <line
      className="svg-gauge-indicator"
      x1={0}
      y1={-gaugeStrokeWidth / 2}
      x2={0}
      y2={gaugeStrokeWidth / 2}
      transform={`translate(${gaugeCenterX} ${gaugeCenterY}) rotate(${indicatorAngle} 0 0) translate(0 -${gaugeRadius})`}
    />
  );
};

export default SVGGaugeIndicator;
