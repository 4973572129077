/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { IdentRef } from '@gpt/commons';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { StructureItemState } from '../../../controls/ReduxControlProps';
import {
  instantiateDevice, useTypedSelector,
} from '../../../store';
import { selectDeviceInstance } from '../../../store/deviceInstances/actions';
import { showApplicationMessage } from '../../../store/applicationMessage/actions';
import { StationDiscoveryItem } from '../../../store/deviceInstances/store/stationDiscovery/types';
import matchDeviceDriver from '../../../store/selectors/matchDeviceDriver';

// eslint-disable-next-line no-unused-vars
export type StateUpdateCallback = (identRef: IdentRef, state: StructureItemState) => void;

export const useStationDeviceSelector = (stationInstanceId: string) => {
  const dispatch = useDispatch();
  const activeInstance = useTypedSelector((state) => state.deviceInstances.activeDeviceInstance);

  const deviceInstances = useTypedSelector((state) => state.deviceInstances.instances);
  const deviceCatalogList = useTypedSelector((state) => state.discoveryService.catalog.deviceList);

  // eslint-disable-next-line max-len
  const selectStationInstance = useCallback((item?: StationDiscoveryItem) => {
    if (item === undefined) {
      dispatch(selectDeviceInstance(stationInstanceId));
      return;
    }

    if (deviceInstances[item.instanceId]) {
      dispatch(selectDeviceInstance(item.instanceId));
      return;
    }

    const deviceInformation = matchDeviceDriver(item.deviceTypeIdentifier, deviceCatalogList);
    if (deviceInformation !== undefined) {
      dispatch(selectDeviceInstance(item.instanceId));
      dispatch(instantiateDevice({
        deviceInfo: { catalog: deviceInformation, instance: item.instance },
        targetInstance: item.instanceId,
        parentInstance: item.instanceId,
        webWorkerInstanceRef: item.instanceId,
        connectionString: item.connectionString ?? '',
        wizardMode: false,
      }));
    } else {
      dispatch(showApplicationMessage('error', 'NO DEVICE MODEL FOUND'));
      dispatch(selectDeviceInstance(stationInstanceId));
    }
  }, [deviceInstances, deviceCatalogList, stationInstanceId]);

  return [activeInstance, selectStationInstance] as const;
};
