/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { ReduxControlProps } from '../ReduxControlProps';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import withControlVisibility from '../hoc/withControlVisibility';
import { useTypedSelector } from '../../store/reduxStore';
import { DatasetContext } from '../../views/DatasetContext';
import { LedLinkControl } from '../LedLinkControl/component/LedLinkControl';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { IoLinkEventMonitorStateSelector } from '../../store/deviceInstances/store/ioLinkEventMonitor/selector';

const selectControlType = (state: DeviceInstancesState, deviceInstanceId: string, target: DatasetType, identRef: IdentRef)
: DeviceModelStatus.UI.IoLinkEventMonitorStatusControlType | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, target);
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLIOLINKEVENTMONITORSTATUS) {
    return undefined;
  }
  return controlType;
};

const EventMonitorStatusReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  // Create event list for Event Monitor Control
  const ioLinkEventList = useTypedSelector((state) => IoLinkEventMonitorStateSelector(state.deviceInstances, deviceInstanceId)?.eventList ?? []);
  const controlType = useTypedSelector((state) => selectControlType(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  if (controlType === undefined) {
    return <UnsupportedControl text={`EventMonitorStatusReduxControl: Undefined object ${identRef}`} />;
  }

  if (ioLinkEventList.length === 0) {
    const { color, label, link } = controlType.status.NoEvents;
    return <LedLinkControl title={t(label)} color={color} link={link} />;
  }

  const { color, label, link } = controlType.status.EventsAvailable;
  return <LedLinkControl title={t(label)} color={color} link={link} />;
};

export default withControlVisibility(EventMonitorStatusReduxControl);
