/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren, useContext } from 'react';
import { Helpbar } from '../../components/Helpbar/Helpbar';
import { ButtonListStandardView } from '../../components/ButtonList';
import { ApplicationContext } from '../ApplicationContext';
import './ApplicationView.scss';
import { useTypedSelector } from '../../store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ApplicationViewProps extends PropsWithChildren<any> {
  helpbar: boolean;
  buttons: boolean;
}

const ApplicationView: React.FC<ApplicationViewProps> = (props: ApplicationViewProps): React.ReactElement => {
  const { children, helpbar, buttons } = props;
  const applicationContext = useContext(ApplicationContext);

  const activeDeviceInstance = useTypedSelector((state) => state.deviceInstances.activeDeviceInstance ?? '-');

  const appViewHelpbar = helpbar ? '' : '--no-help';
  const appViewButtons = buttons ? '' : '--no-buttons';

  return (
    <div className={`applicationview-container applicationview-container-layout${appViewHelpbar}${appViewButtons}`} data-testid="application-view">
      <div className="applicationview-container__content">
        <div className="applicationview-container__content__children">
          {children}
        </div>
      </div>
      <div className={`applicationview-container__helpbar${appViewHelpbar}`}>
        <Helpbar />
      </div>
      <div className={`applicationview-container__buttons${appViewButtons}`}>
        <ButtonListStandardView
          deviceInstanceId={activeDeviceInstance}
          onButtonClick={(btn) => applicationContext.onDeviceButtonClick(btn)}
        />
      </div>
    </div>
  );
};

export default ApplicationView;
