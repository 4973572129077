/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LoadingControl } from '../../../components/LoadingControl/LoadingControl';
import PageSelectFileComponent from '../../../PageComponents/PageSelectFileComponent/PageSelectFileComponent';
import PreviewValueDatasetReduxControl from '../../../controls/PreviewValueDatasetReduxControl/PreviewValueDatasetReduxControl';
import { ButtonState, ButtonRole } from '../../../store/deviceWizard/types';
import { RESTORE_WIZARD__DATASET_FILE_NAME } from '../types';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import { setDataStorageValue } from '../../../store/deviceInstances/store/dataStorage';
import { deviceMethodExecutionSelector } from '../../../store/deviceInstances/store/deviceMethod/selectors';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';
import { useTypedSelector, deviceWizardUpdateButtons, instantiateDeviceOfflineExternalFile } from '../../../store';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';
import './TPFilePageSelectParameterFile.scss';

export const TRANSFER_PARAM_WIZARD__METHOD = 'TRANSFER-PARAM-WIZARD--INSTANTIATE-DEVICE-EXTFILE';

interface ExternalFileInfo {
  filename: string;
  content: string;
}

export interface TPFilePageSelectParameterFileProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const TPFilePageSelectParameterFile: React.FC<TPFilePageSelectParameterFileProps> = (props: TPFilePageSelectParameterFileProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { active, previewMenu, deviceInstanceId } = props;

  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId)?.device);
  const methodWarning = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, TRANSFER_PARAM_WIZARD__METHOD)?.message);
  const methodStage = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, TRANSFER_PARAM_WIZARD__METHOD)?.stage ?? MethodStageExecutionStatus.Initialize);
  const [selectedFile, setSelectedFile] = useState<ExternalFileInfo | undefined>(undefined);

  useEffect(() => {
    if (active !== true || activeDevice === undefined) {
      return;
    }
    if (selectedFile !== undefined) {
      // setWaitingDevice(false);
      dispatch(deviceWizardUpdateButtons({
        abort: ButtonState.enabled,
        back: ButtonState.disabled,
        next: methodStage === MethodStageExecutionStatus.DoneSuccess ? ButtonState.enabled : ButtonState.disabled,
        complete: ButtonState.hidden,
        pcta: ButtonRole.next,
        focusPcta: methodStage === MethodStageExecutionStatus.DoneSuccess ? ButtonRole.next : undefined,
      }));
      dispatch(setDataStorageValue(deviceInstanceId, {
        key: RESTORE_WIZARD__DATASET_FILE_NAME,
        value: selectedFile.filename.replace(/^.+[/\\]/, ''), // strip path prefix
      }));
    }
  }, [selectedFile, methodStage, active]);

  let previewDatasetControl;
  if (selectedFile === undefined) {
    previewDatasetControl = undefined;
  } else {
    switch (methodStage) {
      case MethodStageExecutionStatus.Initialize:
        previewDatasetControl = undefined;
        break;
      case MethodStageExecutionStatus.DoneFailed:
        previewDatasetControl = undefined;
        break;
      case MethodStageExecutionStatus.DoneSuccess: {
        const caption = t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_SELECT_FILE__DATASET_PREVIEW', {
          FILENAME: selectedFile.filename,
        });
        previewDatasetControl = (previewMenu === undefined) ? undefined : (
          <PreviewValueDatasetReduxControl
            caption={caption}
            identRef={previewMenu}
            deviceInstanceId={deviceInstanceId}
          />
        );
        break;
      }
      default:
        previewDatasetControl = <LoadingControl title={t<string>('LOADING_DATA')} />;
    }
  }

  return (
    <Container className="h-100" fluid>
      <Row className="h-100">
        <Col xs={6} className="h-100">
          <PageSelectFileComponent
            title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_SELECT_FILE__PROMPT_TEXT')}
            buttonCaption={t<string>('STANDARD_BUTTON__SELECT_FILE')}
            buttonVariant={selectedFile === undefined ? 'primary' : 'tertiary'}
            selectedFilename={selectedFile?.filename}
            warning={methodWarning}
            onFileSelected={async (result) => {
              setSelectedFile({ filename: result.fileName, content: result.contents as string });
              dispatch(deviceWizardUpdateButtons({
                abort: ButtonState.disabled,
                back: ButtonState.disabled,
                next: ButtonState.disabled,
                complete: ButtonState.hidden,
                pcta: ButtonRole.next,
              }));
              dispatch(instantiateDeviceOfflineExternalFile({
                methodId: TRANSFER_PARAM_WIZARD__METHOD,
                filename: result.fileName,
                content: result.contents as string,
              }, deviceInstanceId));
            }}
          />
        </Col>
        <Col className="preview-control--frame-padding h-100" xs={6}>
          {previewDatasetControl}
        </Col>
      </Row>
    </Container>
  );
};

export default TPFilePageSelectParameterFile;
