/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { DeviceModelStatus } from '@gpt/commons';
import { ExecutionState } from '../../../common';
import { checkDatasetModified, checkDatasetValid } from './helpers';
import {
  DEVICE_DATASET__UPDATE_DATASET,
  DEVICE_DATASET__SET_EXECUTION_STATE,
  DEVICE_DATASET__RESET__IS_EDITED,
  DeviceDatasetState,
  typeDeviceDatasetActionTypes,
  UpdateDeviceDatasetAction,
  DEVICE_DATASET__UPDATE_DATASET_VALUE,
  UpdateDeviceDatasetValueAction,
  DEVICE_DATASET__CLEANUP_DATASET,
  createInitialDataset,
  DEVICE_DATASET__INITIALIZE_DATASETS,
  DatasetState,
  DatasetType,
} from './types';
import { isModifiedStatusValues } from '../../../../helpers/precisionValue';

export const initialDeviceDatasetState: DeviceDatasetState = {
  device: createInitialDataset(),
  init: createInitialDataset(),
  user: createInitialDataset(),
};

const updateDatasetStateReducer = (
  datasetState: DatasetState,
  deviceDatasetState: DatasetState,
  data: DeviceModelStatus.DeviceModelChanges[],
  modifiedFlag: boolean | undefined,
):DatasetState => {
  const descriptors = data
    .reduce((acc, descriptor) => {
      if (descriptor.type === DeviceModelStatus.StatusType.StatusValue) {
        return acc;
      }
      return {
        ...acc,
        [descriptor.identRef]: descriptor,
      };
    }, datasetState?.descriptors ?? {});

  const values = data
    .reduce((acc, item) => {
      if (item.type !== DeviceModelStatus.StatusType.StatusValue) {
        return acc;
      }
      const stateValue = datasetState.values[item.identRef];
      if (stateValue === undefined) {
        return acc;
      }
      const newValue = item.value ?? stateValue.value;

      const modified = modifiedFlag ?? isModifiedStatusValues(descriptors[item.identRef], newValue, deviceDatasetState.values[item.identRef]?.value);

      return {
        ...acc,
        [item.identRef]: {
          ...acc[item.identRef],
          value: newValue,
          backupValue: item.backupValue === null ? undefined : item.backupValue,
          errorCode: item.errorCode,
          modified,
          changeCounter: item.changeCounter,
          timestamp: item.timestamp,
          valueValidity: item.valueValidity,
          valueValidityDescription: item.valueValidityDescription,
        },
      };
    }, datasetState?.values ?? {});
  return {
    ...datasetState,
    descriptors,
    values,
  };
};

const updateDatasetReducer = (
  state: DeviceDatasetState,
  action: UpdateDeviceDatasetAction,
):DeviceDatasetState => {
  const { targetDataset, data } = action.payload;

  const modifiedFlag = targetDataset === DatasetType.device ? false : undefined;

  const datasetState = updateDatasetStateReducer(state[targetDataset], state.device, data, modifiedFlag);
  return {
    ...state,
    [targetDataset]: datasetState,
  };
};

const updateValuesDatasetReducer = (
  state: DeviceDatasetState,
  action: UpdateDeviceDatasetValueAction,
):DeviceDatasetState => {
  const { targetDataset, values } = action.payload;
  const datasetValues = values
    .reduce((acc, value) => {
      const { identRef } = value;
      const stateValue = state[targetDataset].values[identRef];

      const modified = isModifiedStatusValues(state[targetDataset].descriptors[identRef], value.value, state.device.values[identRef]?.value);
      return {
        ...acc,
        [value.identRef]: {
          ...acc[value.identRef],
          value: value.value,
          backupValue: value.backupValue === null ? undefined : value.backupValue,
          timestamp: value.timestamp ?? stateValue.timestamp,
          errorCode: value.errorCode ?? stateValue.errorCode,
          modified,
          valueValidity: value.valueValidity ?? DeviceModelStatus.StatusValueValidity.valid,
          valueValidityDescription: value.valueValidityDescription ?? undefined,
        },
      };
    }, state[targetDataset].values);

  return {
    ...state,
    [targetDataset]: {
      ...state[targetDataset],
      values: datasetValues,
    },
  };
};

const resetDatasetModifiedFlagReducer = (
  targetDataset: DatasetType,
  state: DeviceDatasetState,
):DeviceDatasetState => {
  // Update values in the statusVector
  const datasetValues = Object.keys(state[targetDataset].values)
    .reduce((acc, key) => ({
      ...acc,
      [key]: {
        ...acc[key],
        modified: false,
      },
    }), {});

  return {
    ...state,
    [targetDataset]: {
      ...state[targetDataset],
      values: datasetValues,
      datasetModified: false,
    },
  };
};

const deviceDatasetReducer = (
  state = initialDeviceDatasetState,
  action: typeDeviceDatasetActionTypes,
): DeviceDatasetState => {
  let newstate = state;
  switch (action.type) {
    case DEVICE_DATASET__UPDATE_DATASET:
      newstate = updateDatasetReducer(state, action);
      break;
    case DEVICE_DATASET__UPDATE_DATASET_VALUE:
      newstate = updateValuesDatasetReducer(state, action);
      break;
    case DEVICE_DATASET__SET_EXECUTION_STATE: {
      const { state: targetState, targetDataset } = action.payload;
      return {
        ...state,
        [targetDataset]: {
          ...state[targetDataset],
          state: targetState,
        },
      };
    }
    case DEVICE_DATASET__RESET__IS_EDITED: {
      const { targetDataset } = action.payload;
      return resetDatasetModifiedFlagReducer(targetDataset, state);
    }
    case DEVICE_DATASET__CLEANUP_DATASET:
      return {
        ...state,
        device: createInitialDataset(),
        init: createInitialDataset(),
        user: createInitialDataset(),
      };
    case DEVICE_DATASET__INITIALIZE_DATASETS:
      return {
        ...state,
        init: {
          datasetModified: false,
          datasetValid: true,
          state: ExecutionState.init,
          descriptors: action.payload.datasets.init.descriptors,
          values: action.payload.datasets.init.values,
        },
        user: {
          datasetModified: false,
          datasetValid: true,
          state: ExecutionState.init,
          descriptors: action.payload.datasets.user.descriptors,
          values: action.payload.datasets.user.values,
        },
        device: {
          datasetModified: false,
          datasetValid: true,
          state: ExecutionState.init,
          descriptors: action.payload.datasets.device.descriptors,
          values: action.payload.datasets.device.values,
        },
      };
    default:
      return state;
  }

  const { targetDataset } = action.payload;
  const { descriptors, values } = newstate[targetDataset];

  const invalidValueList = checkDatasetValid(descriptors, values);
  const modifiedItemList = checkDatasetModified(descriptors, values);

  return {
    ...newstate,
    [targetDataset]: {
      ...newstate[targetDataset],
      datasetValid: invalidValueList.length === 0,
      datasetModified: modifiedItemList.length > 0,
      invalidValueList,
      modifiedItemList,
    },
  };
};

export default deviceDatasetReducer;
