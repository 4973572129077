/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import ReactDOM from 'react-dom';
import ApplicationMessageRedux from '../../controls/ApplicationMessageRedux/ApplicationMessageRedux';
import './ModalApplicationMessageBox.css';

const ModalApplicationMessageBox: React.FC = () => {
  const modalRoot = document.getElementById('message-root');
  if (modalRoot === null) {
    return <div />;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <div className="modal-application-message-box">
          <ApplicationMessageRedux />
        </div>,
        modalRoot,
      )}
    </>
  );
};

export default ModalApplicationMessageBox;
