/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { RoutePaths } from '../../../RoutePaths';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import { NavigationButtonProps } from '../../../components/NavigationButton';
import { disposeDevice } from '../../../store';
import { setApplicationId } from '../../../store/deviceInstances/store/standardview/actions';
import { DeviceApplication } from '../hooks/useApplicationList';

export interface DeviceViewSidebarProps {
  activeDeviceInstanceId: string;
  applicationList: DeviceApplication[];
}

const DeviceViewSidebar: React.FC<DeviceViewSidebarProps> = (props: DeviceViewSidebarProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { applicationList, activeDeviceInstanceId } = props;

  const navigationBarButtons: NavigationButtonProps[] = activeDeviceInstanceId === undefined ? [] : applicationList.map((app) => ({
    id: app.id,
    title: t<string>(app.label),
    subtitle: t<string>(app.description),
    routePath: `${RoutePaths.DeviceView}${app.routePath}`,
    image: app.image,
    applicationId: app.applicationId,
    deviceInstanceId: activeDeviceInstanceId,
  }));

  return (
    <NavigationBar
      buttons={navigationBarButtons}
      onStartClick={async (): Promise<void> => {
        if (activeDeviceInstanceId !== undefined) {
          dispatch(disposeDevice(activeDeviceInstanceId));
          dispatch(setApplicationId(activeDeviceInstanceId, undefined));
          navigate(RoutePaths.StartupView);
        }
      }}
    />
  );
};

export default DeviceViewSidebar;
