/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './GroupProcessDataHeader.scss';
import { RadioEditorControl } from '../../../../../components/ParameterEditor';
import { useTypedSelector } from '../../../../../store';
import { ContactronWizardGroupMode, contactronWizardSetGroupMode } from '../../../../../store/wizards/contactronStation';

const GroupProcessDataHeader: React.FC = () : React.ReactElement => {
  const groupMode = useTypedSelector((state) => state.contactronInitWizard.groupMode);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="page-station-group-process-data__header contactron-commissioning-wizard">
      <div className="page-group-process-data-header">
        <div className="page-group-process-data-header-text">
          {t('WIZARD_CONTACTRON__PAGE6_RADIO_BUTTON__TITLE')}
        </div>
        <div className="page-group-process-data-header-radio-buttons">
          <RadioEditorControl
            onChange={() => dispatch(contactronWizardSetGroupMode(ContactronWizardGroupMode.Device))}
            id="process-data-radio1"
            group="process-data-radio"
            label={t('WIZARD_CONTACTRON__PAGE6_RADIO_BUTTON__TEXT1')}
            value={groupMode === ContactronWizardGroupMode.Device}
            readonly={false}
          />
          <RadioEditorControl
            onChange={() => dispatch(contactronWizardSetGroupMode(ContactronWizardGroupMode.ProcessValueName))}
            id="process-data-radio2"
            group="process-data-radio2"
            label={t('WIZARD_CONTACTRON__PAGE6_RADIO_BUTTON__TEXT2')}
            value={groupMode === ContactronWizardGroupMode.ProcessValueName}
            readonly={false}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupProcessDataHeader;
