/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { DeviceInstanceActionPayload } from '../../../common';

export const TRIO3_CHANNEL_SWITCH__SWITCH_CHANNEL = 'TRIO3_CHANNEL_SWITCH__SWITCH_CHANNEL';

export enum Trio3ChannelSwitchState {
  // eslint-disable-next-line no-unused-vars
  Busy = 'busy',
  // eslint-disable-next-line no-unused-vars
  Done = 'done',
}

export interface ioLinkTrio3ChannelSwitchState {
  [key: string]: Trio3ChannelSwitchState;
}

/* ************************************************************************************ */
/* ACTION: Trio3SwitchChannelAction                                               */
/* ************************************************************************************ */
export interface Trio3SwitchChannelPayload extends DeviceInstanceActionPayload {
  ident: string;
  writeValue: number;
  channel: number;
}

export interface Trio3SwitchChannelAction extends Action<typeof TRIO3_CHANNEL_SWITCH__SWITCH_CHANNEL> {
  payload: Trio3SwitchChannelPayload;
}

export type typeIoLinkTrio3SwitchChannelMiddlewareActions = Trio3SwitchChannelAction;
