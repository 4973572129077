/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DIPSwitchNumber } from '../../components/DIPSwitchNumber/DIPSwitchNumber';
import './DIPSwitchBlockControl.css';

export interface DIPSwitchBlockControlProps {
  id: string;
  mainValue: number;
  comparisonValue: number;
  length: number;
  // eslint-disable-next-line no-unused-vars
  onValueChanged: (value: number) => void;
  readonly?: boolean;
}

export const valueToBoolArray = (value: number): boolean[] => {
  let mask = 0x8000;
  const switchStates: boolean[] = [];

  // 0000 0000 0101 0101 = 0x55
  // 0000 0000 0000 0001 = mask

  while (mask !== 0x0) {
    // eslint-disable-next-line no-bitwise
    const bit = ((mask & value) === mask);
    switchStates.push(bit);
    // eslint-disable-next-line no-bitwise
    mask >>>= 1;
  }

  return switchStates;
};

export const boolArrayToValue = (swStates: boolean[]): number => {
  const result = swStates.reduce((acc, boolValue, index) => {
    // eslint-disable-next-line no-bitwise
    const mask = 0x8000 >>> index;

    // eslint-disable-next-line no-bitwise
    return boolValue ? (acc | mask) : acc;
  }, 0);

  return result;
};

export const boolArrayTo16BitValue = (states: boolean[]): number => {
  const len = 16 - states.length;
  if (len < 0) {
    return 0;
  }
  const state16bit = [] as boolean[];
  for (let i = 0; i < 16; i += 1) {
    state16bit.push(i < len ? false : states[i - len]);
  }
  return boolArrayToValue(state16bit);
};

const DIPSwitchBlockControl: React.FC<DIPSwitchBlockControlProps> = (props: DIPSwitchBlockControlProps):
  React.ReactElement => {
  const {
    id,
    mainValue,
    comparisonValue,
    length,
    onValueChanged,
    readonly,
  } = props;

  const switchStates = valueToBoolArray(mainValue); // 16 boolean array
  const switchComparison = valueToBoolArray(comparisonValue); // 16 boolean array

  // 0x0054 => 0 0 0 0 0 0 0 0 0 1 0 1 0 1 0 0
  // == length: 8 ==
  // 0 0 0 0 0 0 0 0 0 1 0 1 0 1 0 0 => 0 1 0 1 0 1 0 0 => 0 0 1 0 1 0 1 0
  const switchChanged = switchStates
    .map((switchState, index) => (switchComparison[index] !== switchState))
    .slice(switchStates.length - length)
    .reverse();
  const dipSwitchState = switchStates
    .slice(switchStates.length - length)
    .reverse();

  const switchBlock = dipSwitchState.map((switchState, idx) => (
    <DIPSwitchNumber
      id={`${id}-dip${idx}`}
        // eslint-disable-next-line react/no-array-index-key
      key={`dip-state-${idx}`}
      isOn={switchState}
      label={`${idx + 1}`}
      changed={switchChanged[idx]}
      readonly={readonly}
      onSwitchChange={(value) => {
        const states = dipSwitchState.map((v, ix) => (idx === ix ? value : v));
        const state16bit = boolArrayTo16BitValue(states.reverse());
        onValueChanged(state16bit);
      }}
    />
  ));

  return (
    <div className="dip-switch-block-container">
      <div className="dip-switch-block-container__status">
        <span className="dip-switch-block-container__status__on">ON</span>
        <span className="dip-switch-block-container__status__off">OFF</span>
      </div>
      <div className="dip-switch-block-container__switches">
        {switchBlock}
      </div>
    </div>
  );
};

export default DIPSwitchBlockControl;
