/* eslint-disable max-len */

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cleanDeviceMethodExecutionState } from '../../../store/deviceInstances/store/deviceMethod/actions';
import { useTypedSelector } from '../../../store';
import { deviceMethodExecutionSelector } from '../../../store/deviceInstances/store/deviceMethod/selectors';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';
import { sPortWriteParameter } from '../../../store/deviceInstances/middleware/deviceMethod';

const useSPortWriteParameter = (deviceInstanceId: string, actionId: string) => {
  const dispatch = useDispatch();

  const mstate = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, actionId));
  const executionStage = mstate?.stage ?? MethodStageExecutionStatus.Initialize;

  useEffect(() => {
    dispatch(cleanDeviceMethodExecutionState(deviceInstanceId, actionId));
  }, [actionId, deviceInstanceId]);

  const writeParameter = useCallback((address: number, data: Uint8Array) => {
    dispatch(sPortWriteParameter({
      targetInstance: deviceInstanceId,
      actionId,
      memoryAddress: address,
      data,
    }));
  }, [deviceInstanceId, actionId]);

  return [executionStage, writeParameter] as const;
};

export default useSPortWriteParameter;
