/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import expandIcon from '../../../../../assets/icons/icon-expand.svg';
import useMenuPostion from './useMenuPosition';
import { DragIndicatorControl } from '../../../../../components/DragIndicatorControl/DragIndicatorControl';
import OverlayPortal from '../../../../../components/OverlayPortal/OverlayPortal';
import './ChartCardButton.scss';

export interface ChartCardButtonProps {
  showDragIndicator: boolean;
  // eslint-disable-next-line no-unused-vars
  onResetDragValues: () => void,
  // eslint-disable-next-line no-unused-vars
  onShowDragIndicator: (show: boolean) => void,
}

export const ChartCardButton: React.FC<ChartCardButtonProps> = (props: ChartCardButtonProps)
: React.ReactElement => {
  const { showDragIndicator, onResetDragValues, onShowDragIndicator } = props;
  const [showDragIndicatorMenu, setShowDragIndicatorMenu] = useState(false);
  const showIndicatorButtonRef = useRef<HTMLDivElement>(null);
  const [menuXPosition, menuYPosition] = useMenuPostion(showIndicatorButtonRef);

  return (
    <div className="chart-card-header-button">
      <div
        ref={showIndicatorButtonRef}
        className="chart-card-header-button__image_container"
        role="button"
        tabIndex={0}
        onClick={() => {
          setShowDragIndicatorMenu((state) => !state);
        }}
        onKeyUp={() => 0}
      >
        <Image src={expandIcon} className="chart-card-header-button__image_container__image" />
      </div>
      {showDragIndicatorMenu && (
      <OverlayPortal>
        <div
          role="none"
          className="chart-card-header-button__overlay"
          onClick={() => {
            setShowDragIndicatorMenu(false);
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: menuYPosition,
              left: menuXPosition ? menuXPosition - 200 : menuXPosition,
            }}
          >
            <DragIndicatorControl
              showIndicator={showDragIndicator}
              onDragIndicatorChange={() => {
                onShowDragIndicator(!showDragIndicator);
              }}
              onRefresh={() => onResetDragValues()}
            />
          </div>
        </div>
      </OverlayPortal>
      )}
    </div>
  );
};
