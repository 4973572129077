/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxControlProps } from '../ReduxControlProps';
import { useTypedSelector } from '../../store';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { DatasetContext } from '../../views/DatasetContext';
import withControlVisibility from '../hoc/withControlVisibility';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetState } from '../../store/deviceInstances/store/deviceDataset/types';
import { DeviceStatusVariableIconControl } from '../../components/DeviceStatusVariableIconControl/DeviceStatusVariableIconControl';

const selectControlType = (
  identRef: IdentRef,
  dataset?: DatasetState,
): DeviceModelStatus.UI.DeviceStatusVariableIconControlType | undefined => {
  const desc = dataset?.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (desc.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDEVICESTATUSVARIABLEICON) {
    return undefined;
  }
  return desc.controlType;
};

const DeviceStatusVariableIconReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const controlType = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    return selectControlType(identRef, dataset);
  });
  const variableDescriptor = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const ctrlType = selectControlType(identRef, dataset);
    if (ctrlType === undefined) {
      return undefined;
    }

    const varDescriptor = dataset?.descriptors[ctrlType.variable];
    if (varDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return undefined;
    }
    return varDescriptor;
  });

  const variableValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const ctrlType = selectControlType(identRef, dataset);
    if (ctrlType === undefined) {
      return undefined;
    }
    return dataset?.values[ctrlType.variable];
  });

  if (controlType === undefined) {
    return <UnsupportedControl text={`DeviceStatusVariableIconReduxControl: Undefined object ${identRef}`} />;
  }

  if (variableDescriptor === undefined) {
    return <UnsupportedControl text={`DeviceStatusVariableIconReduxControl: Undefined object ${identRef}`} />;
  }

  if (variableValue === undefined) {
    return <UnsupportedControl text={`DeviceStatusVariableIconReduxControl: Undefined variable object ${identRef}`} />;
  }

  if (variableDescriptor.visibility === false) {
    return (null);
  }

  const behavior = controlType.BEHAVIOUR
    .find((beh) => (beh.flag === variableValue.value));
  const beh = behavior === undefined ? controlType.DEFAULT : behavior;

  const label = controlType.label ?? variableDescriptor.label;

  return (
    <DeviceStatusVariableIconControl
      label={t<string>(label)}
      icon={beh.icon}
    />
  );
};

export default withControlVisibility(DeviceStatusVariableIconReduxControl);
