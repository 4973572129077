/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/prefer-default-export
export enum RoutePaths {
  StartupView = '/',
  DeviceView = '/device',
  DeviceViewNested = '/device/*',
  DeviceViewNavDefault = '/device/main',
  DeviceViewDefault = '/main',
  DeviceParameterView = '/device/parameters',
  DeviceCompareParameterView = '/device/compare',
  EmptyView = '/empty',
  LoadingView = '/loading',
  WizardChangeDevice = '/wizard/changedevice',
  WizardRestoreDevice = '/wizard/restoredevice',
  WizardCreateParameterSet = '/wizard/createparameterset',
  WizardEditParameterSetCxe = '/wizard/editparametersetcxe',
  WizardTransferDeviceParameterCxe = '/wizard/transferdeviceparametercxe',
  WizardTransferParameterFile = '/wizard/transferparameterfile',
  WizardCommissioning = '/wizard/commissioning',
  WizardSettingDevice = '/wizard/settingdevice',
}
