/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { IoLinkCaparocStationState } from './ioLinkCaparocStation/types';
import { DeviceInstanceActionPayload } from '../../common';

export const CUSTOM_STORAGE__UPDATE_ACTION = 'CUSTOM_STORAGE__UPDATE_ACTION';
export const CUSTOM_STORAGE__CLEAN_ACTION = 'CUSTOM_STORAGE__CLEAN_ACTION';
export const DEVICE_INSTANCE__CONTROL_STATE__ACTIONS = 'DEVICE_INSTANCE__CUSTOM_STORAGE__ACTIONS';

export enum TargetCustomStorage {
  // eslint-disable-next-line no-unused-vars
  IoLinkCaparocStation = 'IoLinkCaparocStation',
}

export type CustomStorageState = {
  ioLinkCaparocStation: IoLinkCaparocStationState;
}

/* ************************************************************************************ */
/* ACTION: UpdateCustomStorageAction                                                   */
/* ************************************************************************************ */
export interface UpdateCustomStoragePayload {
  target: TargetCustomStorage;
  content: unknown;
}

export interface UpdateCustomStorageAction extends Action<typeof CUSTOM_STORAGE__UPDATE_ACTION> {
  payload: UpdateCustomStoragePayload;
}

export interface CleanCustomStoragePayload {
  target: TargetCustomStorage;
}

export interface CleanCustomStorageAction extends Action<typeof CUSTOM_STORAGE__CLEAN_ACTION> {
  payload: CleanCustomStoragePayload;
}

export type typeCustomStorageActionTypes = UpdateCustomStorageAction | CleanCustomStorageAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceDataStorageAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceCustomStoragePayload extends DeviceInstanceActionPayload {
  action: typeCustomStorageActionTypes;
}

export interface typeDeviceInstanceCustomStorageAction extends Action<typeof DEVICE_INSTANCE__CONTROL_STATE__ACTIONS> {
  payload: typeDeviceInstanceCustomStoragePayload;
}
