/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ParameterControl } from '../../../../components/ParameterEditor';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { setContextParameterWarning, setContextParameterHelp } from '../../../../store/contexthelp';
import { updateDeviceDatasetValues } from '../../../../store/deviceInstances/store/deviceDataset';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import PasswordControl from '../../PasswordControl';
import { TypedPasswordControlProps } from '../types';
import { createWarningMessage, i18nLocString, userInputValidation } from './helper';

const PasswordAsciiControl:
React.FC<TypedPasswordControlProps> = (props: TypedPasswordControlProps):
React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp,
    showContextMessageDef, minLength, maxLength,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warning, setWarning] = useState<i18nLocString | undefined>(undefined);
  const [showContextMessage, setShowContextMessage] = useState<boolean>(showContextMessageDef ?? false);

  const statusValueLabel = t(statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? statusDescriptor.label : '');
  const statusValueHelp = t(statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? statusDescriptor.help : '');

  const i18translate = useCallback((message: i18nLocString | undefined): string => {
    let i18message;
    if (message !== undefined) {
      i18message = message.args === undefined ? t(message.id) : t(message.id, message.args);
    }
    return i18message;
  }, [t]);

  useEffect(() => {
    const updateMessage = (message?: i18nLocString) => {
      if (showContextMessage) {
        let i18message;
        if (message !== undefined) {
          i18message = message.args === undefined ? t(message.id) : t(message.id, message.args);
        }
        dispatch(setContextParameterWarning(i18message));
      }
      setWarning(message);
    };
    const message = createWarningMessage(statusValue, minLength, maxLength);
    updateMessage(message);
  }, [t, statusValue, showContextMessage, minLength, maxLength]);

  return (
    <ParameterControl
      label={statusValueLabel}
    >
      <PasswordControl
        value={statusValue.value}
        warning={i18translate(warning)}
        onValueChange={(ev) => {
          const originalValue = ev;
          const message = userInputValidation(originalValue, minLength, maxLength);
          if (message !== undefined) {
            dispatch(updateDeviceDatasetValues(deviceInstanceId, {
              targetDataset: DatasetType.user,
              values: [{
                value: originalValue,
                backupValue: statusValue.backupValue,
                identRef: statusValue.identRef,
                valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
                valueValidityDescription: message.id,
              }],
            }));
          } else {
            dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
              value: originalValue,
              backupValue: statusValue.backupValue,
              identRef: statusValue.identRef,
            }]));
          }
        }}
        modified={statusValue.modified}
        onFocus={() => {
          setShowContextMessage(true);
          if (statusDescriptor !== undefined) {
            const range = DeviceModelStatus.Functions.GetDescriptorRange(statusDescriptor);
            const message = (range?.rangeAsText === undefined) ? '' : t<string>('PARAMETER__VALID_PASSWORD_LENGTH', {
              MIN: minLength,
              MAX: maxLength,
            });
            dispatch(setContextParameterHelp({
              title: statusValueLabel,
              text: statusValueHelp,
              message,
            }));
          }
          onShowContentHelp?.();
        }}
        onBlur={() => {
          setShowContextMessage(false);
          dispatch(setContextParameterWarning(undefined));
          onHideContentHelp?.();
        }}
      />
    </ParameterControl>
  );
};

export default PasswordAsciiControl;
