import { IdentRef } from '@gpt/commons';
import { CompareModeType } from '../../views/CompareView/common';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';

export enum ButtonFunction {
    // eslint-disable-next-line no-unused-vars
    WriteValue,
    // eslint-disable-next-line no-unused-vars
    RedoValue,
    // eslint-disable-next-line no-unused-vars
    Hidden,
}

export interface CompareValueProps {
    label: string;
    help?: string;
    modified: boolean;
    warning?: string;
    readonly?: boolean;
    onButtonClick: () => void;
    onShowContentHelp?: () => void;
    onHideContentHelp?: () => void;
    equal: boolean;
    buttonFunction: ButtonFunction;
    showImageAndSign?: boolean;
    hideLeftControl?: boolean;
    hideRightControl?: boolean;
}

export interface CompareStatusValueProps {
    parentId: IdentRef;
    identRef: IdentRef
    deviceInstanceId: string;
    leftDataset: DatasetType;
    rightDataset: DatasetType;
    compareMode: CompareModeType;
    onShowContentHelp?: () => void;
    onHideContentHelp?: () => void;
    showImageAndSign?: boolean;
    // eslint-disable-next-line no-unused-vars
    onMidButtonClick?: (buttonFunction: ButtonFunction) => void;
}
