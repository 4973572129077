/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showApplicationMessage } from '../../store/applicationMessage/actions';
import PasswordControl from '../../controls/PasswordControl/PasswordControl';
import './ModalPasswordDialog.css';

export interface PasswordDialogProps {
    onCancel: () => Promise<void>;
    // eslint-disable-next-line no-unused-vars
    onAccept: (passwd: string) => Promise<void>;
}

export const PasswordDialog:React.FC<PasswordDialogProps> = (props: PasswordDialogProps): React.ReactElement => {
  const { onAccept, onCancel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');

  const acceptButtonDisabled = password === '';

  return (
    <div className="password-dialog">
      <div className="password-dialog__container">
        <div className="password-dialog__container__header">
          <div className="password-dialog__container__header__text">
            {t<string>('PASSWORD_DIALOG__TITLE')}
          </div>
        </div>
        <div className="password-dialog__container__row" />
        <div className="password-dialog__container__body">
          <div className="password-dialog__container__body-label">
            {t<string>('PASSWORD_DIALOG__LABEL')}
          </div>
          <div className="password-dialog__container__row" />
          <PasswordControl
            value={password}
            onValueChange={(value) => setPassword(value)}
          />
          <div className="password-dialog__container__row" />
          <div className="password-dialog__container__body-buttons">
            <div className="password-dialog__container__body-buttons__f">
              <Button
                className="button-text"
                variant="secondary"
                onClick={() => {
                  dispatch(showApplicationMessage('warning', 'PASSWORD_DIALOG__CANCELLED'));
                  onCancel();
                }}
              >
                {t<string>('STANDARD_BUTTON__ABORT')}
              </Button>
              <Button className="button-text" variant="primary" onClick={() => onAccept(password)} disabled={acceptButtonDisabled}>
                {t<string>('STANDARD_BUTTON__OK')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModalPasswordDialog: React.FC<PasswordDialogProps> = (props:PasswordDialogProps) => {
  const { onAccept, onCancel } = props;
  const modalRoot = document.getElementById('method-modal-root');
  if (modalRoot === null) {
    return <div />;
  }
  return (
    <>
      {ReactDOM.createPortal(
        <PasswordDialog onAccept={onAccept} onCancel={onCancel} />,
        modalRoot,
      )}
    </>
  );
};
