export class AxisAlignedBoundingBox {
  readonly minX: number;

  readonly minY: number;

  readonly maxX: number;

  readonly maxY: number;

  constructor(minX: number, minY: number, maxX: number, maxY: number) {
    this.minX = minX;
    this.minY = minY;
    this.maxX = maxX;
    this.maxY = maxY;
  }

  public Height = (): number => this.maxY - this.minY;

  public Width = (): number => this.maxX - this.minX;

  public Area = (): number => this.Width() * this.Height();

  public IsValid = (): boolean => this.minX !== Number.NEGATIVE_INFINITY
    && this.minY !== Number.NEGATIVE_INFINITY
    && this.maxX !== Number.POSITIVE_INFINITY
    && this.minY !== Number.POSITIVE_INFINITY;
}

export default AxisAlignedBoundingBox;
