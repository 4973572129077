/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingControl } from '../../components/LoadingControl';
import './LoadingView.scss';

const LoadingView: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="" data-testid="loading-view">
      <div className="">
        <LoadingControl title={t<string>('LOADING_DATA')} />
      </div>
    </div>
  );
};

export default LoadingView;
