/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { IdentRef } from '@gpt/commons';
import CheckboxEditorControl from '../../../../../../components/Basic/CheckboxEditorControl/CheckboxEditorControl';
import './OnlineCaparocCheckbox.scss';

export interface OnlineCaparocCheckboxProps {
    identRef: IdentRef;
    value: number;
    label: string;
    help: string;
    disable: boolean;
    // eslint-disable-next-line no-unused-vars
    onCheckboxChange: (value: number) => void;
}

const OnlineCaparocCheckbox: React.FC<OnlineCaparocCheckboxProps> = (props: OnlineCaparocCheckboxProps):
React.ReactElement => {
  const {
    identRef, value,
    label, help, disable,
    onCheckboxChange,
  } = props;
  const { t } = useTranslation();

  const onlineCaparocCheckboxDisabled = (disable ?? true) ? 'online-caparoc-checkbox--disabled' : '';

  return (
    <div className={`online-caparoc-checkbox ${onlineCaparocCheckboxDisabled}`}>
      {t(label)}
      <CheckboxEditorControl
        value={Boolean(value)}
        label={label}
        help={help}
        readonly={disable}
        showLabel={false}
        id={identRef}
        onChange={(originalValue) => onCheckboxChange(+originalValue)}
      />
    </div>
  );
};

export default OnlineCaparocCheckbox;
