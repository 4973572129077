/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { ReduxControlProps } from '../../ReduxControlProps';
import HDCErrorMemoryTable from '../HDCErrorMemoryTable/HDCErrorMemoryTable';
import { DatasetContext } from '../../../views/DatasetContext';
import { HDCErrorMemoryCounter } from '../HDCErrorMemoryCounter/HDCErrorMemoryCounter';
import { HDCErrorMemoryButton } from '../HDCErrorMemoryButton/HDCErrorMemoryButton';
import './HDCErrorMemoryControl.scss';

const HDCErrorMemoryControl
: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const { deviceInstanceId } = props;
  const { targetDataset } = useContext(DatasetContext);

  return (
    <div className="hdc-error-memory-control">
      <div className="hdc-error-memory-counter">
        <HDCErrorMemoryCounter deviceInstanceId={deviceInstanceId} />
      </div>
      <div className="hdc-error-memory-table">
        <HDCErrorMemoryTable
          targetDataset={targetDataset}
          deviceInstanceId={deviceInstanceId}
        />
      </div>
      <div className="hdc-error-memory-button">
        <HDCErrorMemoryButton deviceInstanceId={deviceInstanceId} />
      </div>
    </div>
  );
};

export default HDCErrorMemoryControl;
