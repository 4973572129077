/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  Middleware, Dispatch,
  MiddlewareAPI,
} from 'redux';
import { DeviceInstanceService, IDeviceInstanceService } from '../../../services/DeviceInstanceService/DeviceInstanceService';
import { StationActionTypes } from './types';
import { setWebWorkerConnectionStatus } from '../../deviceInstances/middleware/connectionStatus/actions';
import { updateMultipleDeviceDataset } from '../../deviceInstances/store/deviceDataset/actions';
import { createDeviceInstance, removeDeviceInstance } from '../../deviceInstances/actions';

const instanceService: IDeviceInstanceService = new DeviceInstanceService();

const childInstanceMiddleware = (): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends StationActionTypes>(instanceAction: A): Promise<A> => {
  switch (instanceAction.type) {
    case 'STATION__CHILD_INSTANCE__SET_CONNECTION_STATUS': {
      const { payload } = instanceAction;
      const { deviceInstanceId, status } = payload;
      api.dispatch(setWebWorkerConnectionStatus(deviceInstanceId, status));
      const instances = instanceService.getChildInstances(deviceInstanceId);
      instances.forEach((inst) => api.dispatch(setWebWorkerConnectionStatus(inst, status)));
    }
      break;
    case 'STATION__CHILD_INSTANCE__UPDATE_DATA': {
      const { payload } = instanceAction;
      const { deviceInstanceId, data, targetDataset } = payload;
      const instances = instanceService.getChildInstances(deviceInstanceId);
      const targetInstances = [
        deviceInstanceId,
        ...instances,
      ];
      api.dispatch(updateMultipleDeviceDataset(targetInstances, { targetDataset, data }));
    }
      break;
    case 'STATION__CHILD_INSTANCE__CREATE_DEVICE': {
      const { payload } = instanceAction;
      const { deviceInstanceId, instance } = payload;
      if (instance.syncWithInstanceId) {
        instanceService.addChildInstance(instance.syncWithInstanceId, deviceInstanceId);
      }
      api.dispatch(createDeviceInstance(deviceInstanceId, instance));
    }
      break;
    case 'STATION__CHILD_INSTANCE__DELETE_DEVICE': {
      const { payload } = instanceAction;
      instanceService.removeChildInstance(payload);
      api.dispatch(removeDeviceInstance(payload));
    }
      break;
    default:
  }
  const result = next(instanceAction);
  return result;
};

export default childInstanceMiddleware;
