/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import {
  DeviceModelStatus, Report, ReportContentType,
} from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reduxStore';
import { deviceWizardUpdateButtons } from '../../../store';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import { PageSaveProtocolComponent } from '../../../PageComponents/PageSaveProtocolComponent/PageSaveProtocolComponent';
import { ActionProgressControl } from '../../../components/ActionProgressControl/ActionProgressControl';
import { LoadingControl } from '../../../components/LoadingControl/LoadingControl';
import { createReportFilename } from '../../../helpers/functions';
import { RESTORE_WIZARD__DATASET_FILE_NAME, RESTORE_WIZARD__BACKUP_FILE_NAME } from '../types';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import DownloadMethodControl from '../../../controls/MethodExecution/DownloadMethodControl/DownloadMethodControl';
import { deviceDataStorageValueSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { deviceMethodExecutionSelector } from '../../../store/deviceInstances/store/deviceMethod/selectors';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';

export interface RestoreDeviceFinalPageProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const RestoreDeviceFinalPage
: React.FC<RestoreDeviceFinalPageProps> = (props: RestoreDeviceFinalPageProps) : React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { active, reportMenu, deviceInstanceId } = props;
  const activeDevice = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId)?.device);
  const methodIdent = DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF;

  const stageStatus = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, methodIdent)?.stage);
  const stageSteps = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, methodIdent)?.steps);

  const datasetFileName = useTypedSelector((state) => deviceDataStorageValueSelector(state.deviceInstances, RESTORE_WIZARD__DATASET_FILE_NAME, deviceInstanceId));
  const backupFileName = useTypedSelector((state) => deviceDataStorageValueSelector(state.deviceInstances, RESTORE_WIZARD__BACKUP_FILE_NAME, deviceInstanceId));

  useEffect(() => {
    if (active !== true) {
      return;
    }

    if (stageStatus === undefined) {
      // Download method is initializing
      dispatch(deviceWizardUpdateButtons({
        abort: ButtonState.disabled,
        back: ButtonState.disabled,
        next: ButtonState.hidden,
        complete: ButtonState.disabled,
        pcta: ButtonRole.complete,
      }));
      return;
    }

    switch (stageStatus) {
      case MethodStageExecutionStatus.Initialize:
      case MethodStageExecutionStatus.InProgress:
        // Download method is initializing
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.disabled,
          pcta: ButtonRole.complete,
        }));
        break;
      case MethodStageExecutionStatus.DoneFailed:
        // Download method is failed
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.enabled,
          back: ButtonState.enabled,
          next: ButtonState.hidden,
          complete: ButtonState.disabled,
          pcta: ButtonRole.back,
          focusPcta: ButtonRole.back,
        }));
        break;
      case MethodStageExecutionStatus.DoneSuccess:
        // Download method is initializing
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.enabled,
          pcta: ButtonRole.complete,
          focusPcta: ButtonRole.complete,
        }));
        break;
      default:
        // Download method is not initialized
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.enabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.disabled,
          pcta: ButtonRole.complete,
        }));
    }
  }, [stageStatus, active]);

  if (activeDevice === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const disableReportButtons = (
    stageStatus !== MethodStageExecutionStatus.DoneFailed
    && stageStatus !== MethodStageExecutionStatus.DoneSuccess
  );

  const control = stageStatus === MethodStageExecutionStatus.RequestPassword
  || stageStatus === MethodStageExecutionStatus.RequestAcceptData
    ? (
      <DownloadMethodControl methodIdent={DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF} deviceInstanceId={deviceInstanceId} />
    )
    : (
      <PageSaveProtocolComponent
        reportMenu={reportMenu}
        deviceInstanceId={deviceInstanceId}
        caption={t<string>('WIZARD_RESTORE_DEVICE__PAGE_DOCUMENTATION__REPORT__TITLE')}
        nameCaption={t<string>('WIZARD_RESTORE_DEVICE__PAGE_DOCUMENTATION__REPORT__NAME')}
        descriptionCaption={t<string>('WIZARD_RESTORE_DEVICE__PAGE_DOCUMENTATION__REPORT__DESCRIPTION')}
        disableButtons={disableReportButtons}
        serialNumber={activeDevice.instance?.serialNumber}
        fileName={createReportFilename('DeviceRestorationReport', activeDevice)}
        onGetContent={async (userName, userNotes, serialNumber) => {
          const report: Report = {
            header: {
              dateTime: new Date(),
              systemName: t<string>('', '{{SYSTEM_NAME}}'),
              userName,
              userNotes,
            },
            content: {
              type: ReportContentType.DeviceRecovery,
              device: {
                deviceDesignation: activeDevice.catalog?.deviceTypeName ?? '',
                deviceTag: activeDevice.instance?.deviceTag ?? '',
                firmwareRevision: activeDevice.instance?.firmwareVersion ?? '',
                hardwareRevision: activeDevice.instance?.hardwareVersion ?? '',
                serialNumber,
                itemNumber: activeDevice.catalog?.productOrderNumber ?? '',
              },
              paramFileName: datasetFileName ?? '--',
              backupFileName,
            },
          };

          return report;
        }}
      />
    );

  return (
    <Container className="w-100 h-100" fluid>
      <Row>
        <Col xs={6}>
          <ActionProgressControl
            title={t<string>('WIZARD_RESTORE_DEVICE__PAGE_DOCUMENTATION__PROGRESS__TITLE')}
            steps={stageSteps}
          />
        </Col>
        <Col xs={6}>
          {control}
        </Col>
      </Row>
    </Container>
  );
};

export default RestoreDeviceFinalPage;
