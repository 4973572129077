/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../store';
import TableRowModule from './TableRowModule/TableRowModule';
import TableRowStation from './TableRowStation/TableRowStation';
import './DeviceNamingTable.scss';

export interface DeviceNamingTableProps {
  stationInstanceId: string;
}

const DeviceNamingTable: React.FC<DeviceNamingTableProps> = (props: DeviceNamingTableProps)
: React.ReactElement => {
  const { t } = useTranslation();
  const { stationInstanceId } = props;
  const moduleCollection = useTypedSelector((state) => state.contactronInitWizard.modules);
  const moduleList = useTypedSelector((state) => state.contactronInitWizard.moduleList);

  const moduleRows = moduleList.map((mkey) => {
    const mod = moduleCollection[mkey];
    return mod.instance.address === 0
      ? <TableRowStation key={`naming--station-${mod.instance.address}`} stationInstanceId={stationInstanceId} station={mod} />
      : <TableRowModule key={`naming--${mod.instance.address}-${mod.instance.serialNo}`} module={mod} stationInstanceId={stationInstanceId} />;
  });

  return (
    <div className="page-device-naming__table contactron-commissioning-wizard">
      <table className="page-device-naming-table">
        <thead className="page-device-naming-table-header">
          <tr>
            <th className="page-device-naming-table-header__address">{t('WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_ADDRESS')}</th>
            <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_DETECTED_DEVICE_TYPE')}</th>
            <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_TAG')}</th>
          </tr>
        </thead>
        <tbody>
          {moduleRows}
        </tbody>
      </table>
    </div>
  );
};

export default DeviceNamingTable;
