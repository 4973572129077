/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showApplicationMessage } from '../../store/applicationMessage/actions';
import { AcceptDataList, AcceptDataListItem } from './AcceptDataList/AcceptDataList';
import './ModalAcceptDataDialog.css';

export interface AcceptDataDialogProps {
  acceptDataList: AcceptDataListItem[];
  onCancel: () => Promise<void>;
  onAccept: () => Promise<void>;
}

export const AcceptDataDialog: React.FC<AcceptDataDialogProps> = (props: AcceptDataDialogProps): React.ReactElement => {
  const { acceptDataList, onAccept, onCancel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="accept-data-dialog">
      <div className="accept-data-dialog__container">
        <div className="accept-data-dialog__container-header">
          <div className="accept-data-dialog__container-header__text">
            {t<string>('ACCEPT_DATA_DIALOG__TITLE')}
          </div>
        </div>
        <div className="accept-data-dialog__container-label">
          {t<string>('ACCEPT_DATA_DIALOG__LABEL')}
        </div>
        <div className="accept-data-dialog__container-body">
          <AcceptDataList acceptDataList={acceptDataList} />
        </div>
        <div className="accept-data-dialog__container-buttons">
          <div className="accept-data-dialog__container-buttons__f">
            <Button
              className="button-text"
              variant="secondary"
              onClick={() => {
                dispatch(showApplicationMessage('warning', 'ACCEPT_DATA_DIALOG__CANCELLED'));
                onCancel();
              }}
            >
              {t<string>('STANDARD_BUTTON__ABORT')}
            </Button>
            <Button className="button-text" variant="primary" onClick={() => onAccept()}>
              {t<string>('STANDARD_BUTTON__OK')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModalAcceptDataDialog: React.FC<AcceptDataDialogProps> = (props: AcceptDataDialogProps) => {
  const { acceptDataList, onAccept, onCancel } = props;
  const modalRoot = document.getElementById('modal-root');
  if (modalRoot === null) {
    return <div />;
  }
  return (
    <>
      {ReactDOM.createPortal(
        <AcceptDataDialog acceptDataList={acceptDataList} onAccept={onAccept} onCancel={onCancel} />,
        modalRoot,
      )}
    </>
  );
};
