/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import {
  Report, ReportContentType, FileTransfer, DeviceModelStatus,
} from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PageSaveProtocolComponent } from '../../../../PageComponents/PageSaveProtocolComponent/PageSaveProtocolComponent';
import { ActionProgressControl } from '../../../../components/ActionProgressControl/ActionProgressControl';
import { LoadingControl } from '../../../../components/LoadingControl';
import { createReportFilename } from '../../../../helpers/functions';
import { useTypedSelector, deviceWizardUpdateButtons } from '../../../../store';
import { ButtonState, ButtonRole } from '../../../../store/deviceWizard';
import { RESTORE_WIZARD__DATASET_FILE_NAME, RESTORE_WIZARD__BACKUP_FILE_NAME } from '../../../RestoreDevice/types';
import { WizardViewPageProps } from '../../../WizardView/WizardViewPage';
import DownloadMethodControl from '../../../../controls/MethodExecution/DownloadMethodControl/DownloadMethodControl';
import { deviceMethodExecutionSelector } from '../../../../store/deviceInstances/store/deviceMethod/selectors';
import { deviceDataStorageValueSelector } from '../../../../store/deviceInstances/store/dataStorage/selectors';
import { MethodStageExecutionStatus } from '../../../../store/deviceInstances/store/deviceMethod/types';
import { deviceInstanceActiveDeviceInstance } from '../../../../store/deviceInstances/store/activeDevice/selectors';

export interface TPFilePageTransferDatasetCxeProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const TPFilePageTransferDatasetCxe: React.FC<TPFilePageTransferDatasetCxeProps> = (props: TPFilePageTransferDatasetCxeProps)
  : React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { active, reportMenu, deviceInstanceId } = props;

  const deviceInstance = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId));

  const deviceTypeName = deviceInstance?.device?.catalog.deviceTypeName ?? '';
  const deviceTag = deviceInstance?.device?.instance?.deviceTag ?? '';
  const deviceFirmwareVersion = deviceInstance?.device?.instance?.firmwareVersion ?? '';
  const deviceHardwareRevision = deviceInstance?.device?.instance?.hardwareVersion ?? '';
  const deviceSerialNumber = deviceInstance?.device?.instance?.serialNumber;
  const productOrderNumber = deviceInstance?.device?.catalog.productOrderNumber ?? '';

  const methodSteps = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF)?.steps);
  const methodStage = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF)?.stage);

  const paramFileName = useTypedSelector((state) => deviceDataStorageValueSelector(state.deviceInstances, deviceInstanceId, RESTORE_WIZARD__DATASET_FILE_NAME));
  const backupFileName = useTypedSelector((state) => deviceDataStorageValueSelector(state.deviceInstances, deviceInstanceId, RESTORE_WIZARD__BACKUP_FILE_NAME));

  useEffect(() => {
    if (active !== true) {
      return;
    }
    if (methodStage === undefined) {
      // Download method is initializing
      dispatch(deviceWizardUpdateButtons({
        abort: ButtonState.disabled,
        back: ButtonState.disabled,
        next: ButtonState.hidden,
        complete: ButtonState.disabled,
        pcta: ButtonRole.complete,
      }));
      return;
    }

    switch (methodStage) {
      case MethodStageExecutionStatus.Initialize:
      case MethodStageExecutionStatus.InProgress:
      case MethodStageExecutionStatus.RequestAcceptData:
      case MethodStageExecutionStatus.RequestPassword:
        // Download method is initializing
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.hidden,
          pcta: ButtonRole.complete,
        }));
        break;
      case MethodStageExecutionStatus.DoneFailed:
        // Download method is failed
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.enabled,
          back: ButtonState.enabled,
          next: ButtonState.hidden,
          complete: ButtonState.disabled,
          pcta: ButtonRole.back,
        }));
        break;
      case MethodStageExecutionStatus.DoneSuccess:
        // Download method is initializing
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.enabled,
          pcta: ButtonRole.complete,
        }));
        break;
      default:
        // Download method is not initialized
        dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.disabled,
          back: ButtonState.disabled,
          next: ButtonState.hidden,
          complete: ButtonState.disabled,
          pcta: ButtonRole.complete,
        }));
    }
  }, [methodStage, active]);

  if (deviceInstance === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const disableReportButtons = (methodStage !== MethodStageExecutionStatus.DoneSuccess);
  const control = methodStage === MethodStageExecutionStatus.RequestPassword || methodStage === MethodStageExecutionStatus.RequestAcceptData
    ? (
      <DownloadMethodControl methodIdent={DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF} deviceInstanceId={deviceInstanceId} />
    )
    : (
      <PageSaveProtocolComponent
        reportMenu={reportMenu}
        deviceInstanceId={deviceInstanceId}
        caption={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_TRANSFER_DATASET__DOCUMENTATION_TITLE')}
        nameCaption={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_TRANSFER_DATASET__DOCUMENTATION_NAME')}
        descriptionCaption={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_TRANSFER_DATASET__DOCUMENTATION_DESCRIPTION')}
        serialNumber={deviceSerialNumber}
        disableButtons={disableReportButtons}
        fileName={createReportFilename('FileTransferReport', deviceInstance.device)}
        onGetContent={async (userName: string, userNotes: string, serialNumber: string): Promise<Report> => {
          const content: FileTransfer = {
            type: ReportContentType.FileTransfer,
            device: {
              deviceDesignation: deviceTypeName,
              deviceTag: deviceTag,
              firmwareRevision: deviceFirmwareVersion,
              hardwareRevision: deviceHardwareRevision,
              serialNumber,
              itemNumber: productOrderNumber,
            },
            paramFileName: paramFileName ?? '--',
            backupFileName,
          };

          return {
            header: {
              dateTime: new Date(),
              systemName: t<string>('', '{{SYSTEM_NAME}}'),
              userName,
              userNotes,
            },
            content,
          };
        }}
      />
    );

  return (
    <Container className="w-100 h-100" fluid>
      <Row>
        <Col xs={6}>
          <ActionProgressControl
            title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_TRANSFER_DATASET__PROGRESS_CAPTION')}
            steps={methodSteps}
          />
        </Col>
        <Col xs={6}>
          {control}
        </Col>
      </Row>
    </Container>
  );
};

export default TPFilePageTransferDatasetCxe;
