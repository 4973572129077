/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import React, { CSSProperties } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useContextResourceProvider } from '../../store/deviceInstances/hooks/ResourceProviderHooks';
import './ImageComponent.scss';

export interface ImageComponentProps extends WithTranslation {
  /**
   * Resource identifier of the image to display.
   */
  imageResourceId: string;
  deviceInstanceId: string | undefined;
  width?: number;
  height?: number;
  top?: number;
}

const ImageComponent:
React.FC<ImageComponentProps> = (props: ImageComponentProps): React.ReactElement => {
  const {
    t, imageResourceId, deviceInstanceId,
    width, height, top,
  } = props;
  // Image Id can be translated
  const i18nResourceId = t(imageResourceId);
  const resourceDataUrl = useContextResourceProvider(deviceInstanceId, i18nResourceId);

  const style: CSSProperties | undefined = top === undefined
    ? undefined
    : {
      marginTop: `${top}px`,
    };

  return (
    <div className="image-component-container" aria-label={imageResourceId} style={style}>
      <img
        src={resourceDataUrl}
        alt={i18nResourceId}
        data-testid="image-component"
        width={width ? `${width}px` : undefined}
        height={height ? `${height}px` : undefined}
      />
    </div>
  );
};

export default withTranslation()(ImageComponent);
