/* eslint-disable max-len */
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { deviceTargetDatasetSelector } from '../../../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { useTypedSelector } from '../../../../../store';

const useCheckBoxStatusDescriptor = (
  deviceInstanceId: string,
  identRef: IdentRef,
  datasetType: DatasetType,
): DeviceModelStatus.StatusDescriptor | undefined => {
  const dataset = useTypedSelector(
    (state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, datasetType),
  );

  const checkBoxDescriptor = dataset?.descriptors[identRef];
  if (checkBoxDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = checkBoxDescriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLCHECKBOX) {
    return undefined;
  }

  const variableDescriptor = dataset?.descriptors[controlType.variable];
  if (variableDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }
  return variableDescriptor;
};

export default useCheckBoxStatusDescriptor;
