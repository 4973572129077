/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  CleanDeviceActionState,
  DeviceActionState,
  SetDeviceActionProgressStateAction,
  typeDeviceActionActions,
} from './types';

export const initialDeviceActionState: DeviceActionState = {
  actions: {},
};

const setDeviceActionExecutionState = (state: DeviceActionState, action: SetDeviceActionProgressStateAction)
: DeviceActionState => {
  const {
    actionId, progress, title,
  } = action.payload;

  return state.actions[actionId] === undefined
    ? ({
      ...state,
      actions: {
        ...state.actions,
        [actionId]: {
          progress,
          title,
        },
      },
    })
    : ({
      ...state,
      actions: {
        ...state.actions,
        [actionId]: {
          ...state.actions[actionId],
          progress: progress ?? state.actions[actionId].progress,
          title: title ?? state.actions[actionId].title,
        },
      },
    });
};

const cleanupDeviceActionState = (state: DeviceActionState, action: CleanDeviceActionState)
: DeviceActionState => {
  if (state.actions === undefined) {
    return state;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [action.payload]: value, ...remainder } = state.actions;
  return {
    ...state,
    actions: {
      ...remainder,
    },
  };
};

const deviceActionStateReducer = (
  state = initialDeviceActionState,
  action: typeDeviceActionActions,
): DeviceActionState => {
  switch (action.type) {
    case 'ACTION_EXECUTION__SET_EXECUTION_PROGRESS':
      return setDeviceActionExecutionState(state, action);
    case 'ACTION_EXECUTION__CLEANUP':
      return cleanupDeviceActionState(state, action);
    default:
  }
  return state;
};

export default deviceActionStateReducer;
