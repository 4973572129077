/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { LoadingControl } from '../../components/LoadingControl';
import { RoutePaths } from '../../RoutePaths';
import DeviceViewFailed from './DeviceViewFailed/DeviceViewFailed';
import {
  useTypedSelector, ExecutionState, instantiateLatestDeviceType, disposeDevice, instantiateDevice,
} from '../../store';
import { setApplicationId } from '../../store/deviceInstances/store/standardview/actions';
import { useContextActiveDeviceInstanceState } from '../../store/deviceInstances/hooks/ActiveDeviceHooks';
import { LoadingProgressControl } from '../../components/LoadingProgressControl';
import DeviceViewDialog, { DialogMode } from './DeviceViewDialog/DeviceViewDialog';
import useApplicationList from './hooks/useApplicationList';
import DeviceViewSidebar from './DeviceViewSidebar/DeviceViewSidebar';
import DeviceViewRouteNavigation from './DeviceViewRouteNavigation/DeviceViewRouteNavigation';
import './DeviceViewContainer.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DeviceViewContainerProps = React.PropsWithChildren<any>

const DeviceViewContainer: React.FC<DeviceViewContainerProps> = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogMode, setDialogMode] = useState(DialogMode.hideDialog);

  const activeDeviceInstance = useTypedSelector((state) => {
    const { activeDeviceInstance: deviceInstanceId } = state.deviceInstances;
    if (deviceInstanceId === undefined) {
      return undefined;
    }
    return state.deviceInstances.instances[deviceInstanceId]?.activeDevice.modelInstance;
  });
  const activeDeviceInstanceId = activeDeviceInstance?.deviceInstanceId;
  const activeDeviceInfo = activeDeviceInstance?.device;

  const activeDeviceState = useContextActiveDeviceInstanceState(activeDeviceInstanceId);

  const applicationList = useApplicationList(activeDeviceInstanceId);

  if (activeDeviceState === ExecutionState.init) {
    // in case of manual navigation with changed location URL of current web page,
    // no device model is loaded → implicitly instantiate latest device model again:
    dispatch(instantiateLatestDeviceType());
  }

  if (activeDeviceState === ExecutionState.failed) {
    return (
      <div className="deviceviewcontainer-container d-flex justify-content-center align-items-center">
        <DeviceViewFailed
          onCancelClick={() => {
            if (activeDeviceInstanceId !== undefined) {
              dispatch(disposeDevice(activeDeviceInstanceId));
              dispatch(setApplicationId(activeDeviceInstanceId, undefined));
            }
            navigate(RoutePaths.StartupView);
          }}
          onRetryClick={() => {
            if (activeDeviceInfo !== undefined) {
              dispatch(instantiateDevice({
                deviceInfo: activeDeviceInfo,
                targetInstance: activeDeviceInstanceId,
                wizardMode: false,
              }));
            } else {
              navigate(RoutePaths.StartupView);
            }
          }}
        />
      </div>
    );
  }

  if (activeDeviceInstanceId === undefined) {
    return (
      <div className="deviceviewcontainer-container d-flex justify-content-center align-items-center">
        <LoadingControl title={t<string>('LOADING_DATA')} />
      </div>
    );
  }
  if (applicationList.length === 0 || activeDeviceState !== ExecutionState.success || activeDeviceInfo === undefined) {
    return (
      <div className="deviceviewcontainer-container d-flex justify-content-center align-items-center">
        <LoadingProgressControl title={t<string>('LOADING_DATA')} deviceInstanceId={activeDeviceInstanceId} actionId="DEVICE_INSTANTIATION__UPLOAD_ACTION" />
      </div>
    );
  }

  return (
    <div className="deviceviewcontainer-container deviceviewcontainer-container--help" data-testid="device-view-container">
      <div className="deviceviewcontainer-container__sidebar">
        <DeviceViewSidebar
          activeDeviceInstanceId={activeDeviceInstanceId}
          applicationList={applicationList}
        />
      </div>
      <div className="deviceviewcontainer-container__content">
        <DeviceViewRouteNavigation
          activeDeviceInstanceId={activeDeviceInstanceId}
          applicationList={applicationList}
          setDialogMode={setDialogMode}
        />
      </div>
      <DeviceViewDialog activeDeviceInstanceId={activeDeviceInstanceId} dialogMode={dialogMode} setDialogMode={setDialogMode} />
    </div>
  );
};

export default DeviceViewContainer;
