import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { Record, RecordValueType } from '../../../components/PreviewTable/PreviewTableHeader/PreviewTableHeader';
import { DatasetState, DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';

const getMemberValue = (member: string, dataset?: DatasetState): RecordValueType | undefined => {
  const memDescriptor = dataset?.descriptors[member];
  // eslint-disable-next-line max-len
  if (memDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  if (memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT
      && memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER
      // eslint-disable-next-line max-len
      && memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER) {
    return undefined;
  }

  if (dataset?.values[member] === undefined) {
    return undefined;
  }

  return {
    identRef: member,
    unit: memDescriptor.unit ?? '',
    value: dataset.values[member].value as number,
  };
};

export const selectTableRecords = (identRef: IdentRef, dataset?: DatasetState): Record[] => {
  const tableDescription = dataset?.descriptors[identRef];
  if (tableDescription?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return [];
  }

  if (tableDescription.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.TABLE) {
    return [];
  }

  return tableDescription.valueType.records
    .reduce((acc, ident) => {
      const recDef = dataset?.descriptors[ident] as DeviceModelStatus.StatusDescriptor;
      const record = recDef.valueType as DeviceModelStatus.StatusDescriptorValueTypeTableRecord;

      const recordValues: RecordValueType[] = record.members.reduce((macc, mem) => {
        const recordValue = getMemberValue(mem, dataset);
        return recordValue === undefined ? macc : [
          ...macc,
          recordValue,
        ];
      }, [] as RecordValueType[]);

      return recordValues.length > 0 ? [
        ...acc,
        { values: recordValues },
      ] : acc;
    }, [] as Record[]);
};

export const tableRecordSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  target: DatasetType,
  identRef: IdentRef,
): Record[] => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, target);
  return selectTableRecords(identRef, dataset);
};
