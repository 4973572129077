/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import React from 'react';
import './HBarChart.scss';
import { ChartSegment } from '../types';
import {
  HBarElementWidth, HBarElementHeight, HBarElementStrokeWidth,
} from '../hbarconst';
import { mapValue } from '../helpers/helpers';

export interface HBarChartProps {
    currentValue: number;
    minValue: number;
    maxValue: number;
    segments: ChartSegment[];
    paddingLeft: number;
    paddingRight: number;
    dragMaxValue?: number;
    dragMinValue?: number;
}

const dragPointer = (
  dragValue: number | undefined,
  minValue: number,
  maxValue: number,
  paddingLeft: number,
  paddingRight: number,
): React.ReactElement | undefined => {
  const dragX = dragValue === undefined ? 0 : mapValue(dragValue, minValue, maxValue, paddingLeft, HBarElementWidth - paddingRight);
  return dragValue === undefined
    ? undefined
    : <line x1={dragX} y1={HBarElementStrokeWidth} x2={dragX} y2={HBarElementStrokeWidth * 2} strokeWidth={3} stroke="black" />;
};

const currentPointer = (
  value: number | undefined,
  minValue: number,
  maxValue: number,
  paddingLeft: number,
  paddingRight: number,
): React.ReactElement | undefined => {
  if (value === undefined) {
    return undefined;
  }
  const triangleLen = HBarElementStrokeWidth - 4;
  const triangle = `${-triangleLen},${0} ${triangleLen},${0} ${0},${triangleLen}`;
  const currXPos = mapValue(value, minValue, maxValue, paddingLeft, HBarElementWidth - paddingRight);
  return <polygon points={triangle} transform={`translate(${currXPos} 0)`} />;
};

export const HBarChart: React.FC<HBarChartProps> = (props: HBarChartProps)
: React.ReactElement => {
  const {
    currentValue, minValue, maxValue, segments,
    paddingLeft, paddingRight,
    dragMaxValue, dragMinValue,
  } = props;

  const lineYPosition = HBarElementHeight - HBarElementStrokeWidth / 2;
  const chartSegments = segments
    .map((seg) => {
      const startSegment = mapValue(seg.minValue, minValue, maxValue, paddingLeft, HBarElementWidth - paddingRight);
      const endSegment = mapValue(seg.maxValue, minValue, maxValue, paddingLeft, HBarElementWidth - paddingRight);
      const className = `hbar-chart-segment--${seg.color}`;
      return (
        <g className={className} key={`hbar-chart-segment--${seg.minValue}-${seg.maxValue}`} fill="none">
          <line x1={startSegment} y1={lineYPosition} x2={endSegment} y2={lineYPosition} strokeWidth={HBarElementStrokeWidth} />
        </g>
      );
    });

  const dragMin = dragPointer(dragMinValue, minValue, maxValue, paddingLeft, paddingRight);
  const dragMax = dragPointer(dragMaxValue, minValue, maxValue, paddingLeft, paddingRight);
  const curPointer = currentPointer(currentValue, minValue, maxValue, paddingLeft, paddingRight);

  return (
    <g>
      <g className="hbar-chart-segment--grey" fill="none" strokeLinejoin="round" strokeLinecap="butt">
        <line x1={paddingLeft} y1={lineYPosition} x2={HBarElementWidth - paddingRight} y2={lineYPosition} strokeWidth={HBarElementStrokeWidth} />
      </g>
      {chartSegments}
      {curPointer}
      {dragMin}
      {dragMax}
    </g>
  );
};
