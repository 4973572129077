/* eslint-disable class-methods-use-this */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars, @typescript-eslint/no-empty-function */

import isElectron from 'is-electron';
import { PackageVersion } from '../../PackageVersion';

export type ZoomDirection = 'in' | 'out' | 'set';
export type AppCommand = 'minimize' | 'maximize' | 'fullscreen' | 'reload' | 'exit' | 'devtools';

interface AppSystemService {
  GetAppVersion: () => Promise<string>;
  IsAppPortable: () => Promise<boolean>;
  GetZoomLevel: () => Promise<number>;
  MainViewInitialized: () => Promise<void>;
  MainViewZoom: (dir: ZoomDirection, value?: number) => Promise<number>;
  TriggerAppCommand: (command: AppCommand) => Promise<void>;
  OpenUrlInSystemBrowser: (url: string) => Promise<void>;

  onMainWindowMaximized: (listener: (isMaximized: boolean) => void) => () => void;
  onMainWindowFocused: (listener: (hasFocus: boolean) => void) => () => void;
  onZoomChange: (listener: (value: number) => void) => () => void;

  ExecuteDriverSetup: () => Promise<boolean>;
}

class AppSystemServiceFake implements AppSystemService {
  GetAppVersion = () => Promise.resolve(PackageVersion);

  GetZoomLevel = () => Promise.resolve(1);

  IsAppPortable = () => Promise.resolve(true);

  MainViewInitialized = () => Promise.resolve();

  MainViewZoom = () => Promise.resolve(1);

  TriggerAppCommand = () => Promise.resolve();

  OpenUrlInSystemBrowser = () => Promise.resolve();

  onMainWindowMaximized = () => () => {};

  onMainWindowFocused = () => () => {};

  onZoomChange = () => () => {};

  ExecuteDriverSetup = () => Promise.resolve(true);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appSystemService = (window as any).electronService as AppSystemService;

const AppSystemServiceImpl = isElectron()
  ? appSystemService : new AppSystemServiceFake();

export default AppSystemServiceImpl;
