/* eslint-disable no-bitwise */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

export const COMBI_ENABLE00__DISABLE = 0;
export const COMBI_ENABLE01__POWER_OUT = 1;
export const COMBI_ENABLE02__VOLTAGE_OUT = 2;
export const COMBI_ENABLE04__TEMPERATURE = 4;
export const COMBI_ENABLE08__VIN_OK = 8;
export const COMBI_ENABLE16__RUNTIME = 16;
export const COMBI_ENABLE32__OVPMAIN = 32;

export interface Quint4DigitalSignalBlock {
  blockIdent: string;
  outCombiEnableDropValue: string;
  outCombiConnectDropValue: string;
  outCombiInvert: string;
  outLimitTotRuntime: string;
  outLimitVout: string;
  outLimitPout: string;
  outUseINotP: string;
  outCorrespondToI: string;
  outCorrespondToP: string;
  outCorrespondToV: string;
  outOVPVariable: string;
  outConglomerationThreshold: string;
}

export interface Quint4DigitalSignalCompareBlock {
  outCombiInvert: string;
  outLimitTotRuntime: string;
  outLimitVout: string;
  outLimitPout: string;
  outCorrespondToI: string;
  outCorrespondToP: string;
  outCorrespondToV: string;
  outOVPVariable: string;
  outCombiCompareEnable: string;
  outCombiCompareConnect: string;
  outConglomerationThreshold: string;
}

export const Quint4SignalVariableBlock = (num: number): Quint4DigitalSignalBlock => ({
  blockIdent: `Quint4SignalVariableBlock${num}`,
  outCombiEnableDropValue: `var_UDP_Out${num}Parameter_Out${num}CombiEnable_COMPARE`,
  outCombiConnectDropValue: `var_UDP_Out${num}Parameter_Out${num}CombiConnect_COMPARE`,
  outCombiInvert: `var_UDP_Out${num}Parameter_Out${num}CombiInvert`,
  outLimitVout: `var_UDP_Out${num}Parameter_Out${num}LimitVout`,
  outLimitPout: `var_UDP_Out${num}Parameter_Out${num}LimitPout`,
  outLimitTotRuntime: `var_UDP_Out${num}Parameter_Out${num}LimitTotRuntime`,
  outUseINotP: `var_UDA_Additionals1_Activation_Out${num}UseINotP`,
  outCorrespondToI: `var_IoutCorrespondOut${num}__local`,
  outCorrespondToP: `var_PoutCorrespondOut${num}__local`,
  outCorrespondToV: `var_VoutCorrespondOut${num}__local`,
  outOVPVariable: 'var_UOutSmaller32volt',
  outConglomerationThreshold: `var_CON_Conglomeration_OUT${num}VinOk2Threshold`,
});

export const createQuint4SignalCompareVariableBlock = (num: number)
: Quint4DigitalSignalCompareBlock => ({
  outCombiCompareEnable: `var_UDP_Out${num}Parameter_Out${num}CombiEnable_COMPARE`,
  outCombiCompareConnect: `var_UDP_Out${num}Parameter_Out${num}CombiConnect_COMPARE`,
  outLimitVout: `var_UDP_Out${num}Parameter_Out${num}LimitVout`,
  outLimitPout: `var_UDP_Out${num}Parameter_Out${num}LimitPout`,
  outLimitTotRuntime: `var_UDP_Out${num}Parameter_Out${num}LimitTotRuntime`,
  outCorrespondToI: `var_IoutCorrespondOut${num}__local`,
  outCorrespondToP: `var_PoutCorrespondOut${num}__local`,
  outCorrespondToV: `var_VoutCorrespondOut${num}__local`,
  outOVPVariable: 'var_UOutSmaller32volt',
  outCombiInvert: `var_UDP_Out${num}Parameter_Out${num}CombiInvert`,
  outConglomerationThreshold: `var_CON_Conglomeration_OUT${num}VinOk2Threshold`,
});

export const createQuint4SignalCompareVariableArray = (num: number)
: string[] => ([
  `var_UDP_Out${num}Parameter_Out${num}CombiEnable_COMPARE`,
  `var_UDP_Out${num}Parameter_Out${num}LimitVout`,
  `var_UDP_Out${num}Parameter_Out${num}LimitPout`,
  `var_UDP_Out${num}Parameter_Out${num}CombiConnect_COMPARE`,
  `var_IoutCorrespondOut${num}__local`,
  `var_PoutCorrespondOut${num}__local`,
  `var_VoutCorrespondOut${num}__local`,
  `var_UDP_Out${num}Parameter_Out${num}LimitTotRuntime`,
  'var_UOutSmaller32volt',
  `var_UDP_Out${num}Parameter_Out${num}CombiInvert`,
  `var_CON_Conglomeration_OUT${num}VinOk2Threshold`,
]);

export const createQuint4SignalWriteVariables = (num: number)
: string[] => {
  const analogSignal = num === 2
    ? [
      'var_UDP_Out2AnalogFunc_Out2FunSelect_Out2Ctrl',
      'var_UDP_Out2AnalogFunc_Out2FunSelect_Out2Routing_AnalogControl',
      'var_UDP_Out2AnalogFunc_Out2AnalogStartValue_AnalogControl',
      'var_UDP_Out2AnalogFunc_Out2AnalogEndValue_AnalogControl',
    ] : [];

  return [
    `var_UDP_Out${num}Parameter_Out${num}CombiEnable_COMPARE`,
    `var_UDP_Out${num}Parameter_Out${num}LimitVout`,
    `var_UDP_Out${num}Parameter_Out${num}LimitPout`,
    `var_UDP_Out${num}Parameter_Out${num}CombiConnect_COMPARE`,
    `var_CON_Conglomeration_OUT${num}VinOk2Threshold`,
    `var_UDP_Out${num}Parameter_Out${num}LimitTotRuntime`,
    `var_UDP_Out${num}Parameter_Out${num}CombiInvert`,
    ...analogSignal,
  ];
};
