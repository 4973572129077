/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';

const deviceModelParametersStatusVector: DeviceModelStatus.StatusValueCollection = {
  DEVICE_POWER_STATUS: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'DEVICE_POWER_STATUS',
    value: 1,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  DEVICE_STATUS: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'DEVICE_STATUS',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_R_SENSOR_VOLTAGE: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_SENSOR_VOLTAGE',
    value: 123.35,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_R_SENSOR_CURRENT: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_SENSOR_CURRENT',
    value: 15.76,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_R_SENSOR_RESIST: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_SENSOR_RESIST',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_R_LINE_RESIST: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_LINE_RESIST',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_R_OUTPUT_CALC: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_OUTPUT_CALC',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_R_TEMPERATURE_C: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_TEMPERATURE_C',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_R_TEMPERATURE_F: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_TEMPERATURE_F',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_R_DIPSWITCH_S1: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_DIPSWITCH_S1',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_R_DIPSWITCH_S3: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_DIPSWITCH_S3',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_R_OUTPUT_UNIT: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_OUTPUT_UNIT',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_RW_DEVICE_STATE: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_R_DIPSWITCH_S1',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_RW_PWM_OUT_POSITIV: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_RW_PWM_OUT_POSITIV',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_RW_PWM_OUT_NEGATIV: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_RW_PWM_OUT_NEGATIV',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_RW_ANALOG_OUT: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_RW_ANALOG_OUT',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_RW_ANALOG_OUT_ABS: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_RW_ANALOG_OUT_ABS',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_RW_ERROR_CONTACT_STATE: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_RW_ERROR_CONTACT_STATE',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_RW_MODULE_STATE: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_RW_MODULE_STATE',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  IFSM_ADDR_IO_RW_CHANNEL_STATE: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'IFSM_ADDR_IO_RW_CHANNEL_STATE',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  VARIABLE_DIP_SWITCH__S1: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'VARIABLE_DIP_SWITCH__S1',
    value: 0x55,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    changeCounter: 0,
    errorCode: 0,
    modified: false,
    timestamp: '11-11-11',
  },
};

export default deviceModelParametersStatusVector;
