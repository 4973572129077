/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';

export interface LegalInformationPackage {
  name: string;
  version?: string;
  description?: string;
  homepage?: string;
  author?: string;
  license?: string;
}

export interface LegalInformationLicense {
  text: string;
}

export interface LegalInformation {
  package: LegalInformationPackage;
  license?: LegalInformationLicense;
}

export interface PackageInformationProps {
  pkg: LegalInformation;
  expandedLicense: boolean;
  setExpandedLicense: () => void;
}

const PackageLegalInformation: React.FC<PackageInformationProps> = (props: PackageInformationProps)
: React.ReactElement => {
  const { pkg, expandedLicense, setExpandedLicense } = props;

  const license = expandedLicense ? (
    <pre style={{
      paddingTop: '12px',
      paddingLeft: '20px',
      userSelect: 'text',
      whiteSpace: 'break-spaces',
    }}
    >
      {pkg?.license?.text ?? ''}
    </pre>
  ) : undefined;
  const background = expandedLicense ? (
    {
      padding: '12px',
      borderRadius: '12px',
      backgroundColor: 'var(--light-gray)',
    }) : {};

  return (
    // eslint-disable-next-line react/no-array-index-key
    <div style={{ paddingBottom: '3px', ...background }}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role="button"
        tabIndex={0}
        onClick={() => setExpandedLicense()}
      >
        <div key={`${pkg.package.name ?? '???'}--${pkg.package.version ?? 'unknown'}`}>
          <span title={pkg.package.description}>
            {pkg.package.name}
          </span>
          &nbsp;
          {pkg.package.homepage && (
            <a
              href={pkg.package.homepage}
              rel="noreferrer"
              target="_blank"
            >
              ↗&nbsp;
            </a>
          )}
          {`(${pkg.package.license ?? ''})`}
        </div>
      </div>
      {license}
    </div>
  );
};

export default PackageLegalInformation;
