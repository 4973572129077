/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import ContactronCockpitModuleLayout from './CockpitModuleLayout/CockpitModuleLayout';
import ActualValuesCard from './ActualValuesCard/ActualValuesCard';
import IoStatusValueCard from './IoStatusValueCard/IoStatusValueCard';
import DeviceStatusCard from './DeviceStatusCard/DeviceStatusCard';
import './CockpitContactronStation.scss';

export interface CockpitContactronStationProps {
  deviceInstanceId: string;
  deviceViewType: DeviceModelStatus.UI.Contactron.CockpitStationControlType;
}

const CockpitContactronStation: React.FC<CockpitContactronStationProps> = (props: CockpitContactronStationProps):
  React.ReactElement => {
  const {
    deviceViewType,
    deviceInstanceId,
  } = props;

  return (
    <div className="cockpit-contactron-station">
      <div className="cockpit-contactron-station-item cockpit-contactron-station__devicestatus">
        <DeviceStatusCard deviceState={deviceViewType.deviceState} deviceInstanceId={deviceInstanceId} />
      </div>
      <div className="cockpit-contactron-station-item cockpit-contactron-station__iostatus">
        <IoStatusValueCard ioGroupState={deviceViewType.ioGroupState} deviceInstanceId={deviceInstanceId} />
      </div>
      <div className="cockpit-contactron-station-item cockpit-contactron-station__actualvalues">
        <ActualValuesCard actualValues={deviceViewType.actualValues} deviceInstanceId={deviceInstanceId} />
      </div>
      <div className="cockpit-contactron-station-item cockpit-contactron-station__modules">
        <ContactronCockpitModuleLayout stationInstanceId={deviceInstanceId} />
      </div>
    </div>
  );
};

export default CockpitContactronStation;
