/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  CleanCustomStoragePayload,
  UpdateCustomStoragePayload,
  typeCustomStorageActionTypes,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const updateCustomStorage = (payload: UpdateCustomStoragePayload)
: typeCustomStorageActionTypes => ({
  type: 'CUSTOM_STORAGE__UPDATE_ACTION',
  payload,
});

export const cleanCustomStorage = (payload: CleanCustomStoragePayload)
: typeCustomStorageActionTypes => ({
  type: 'CUSTOM_STORAGE__CLEAN_ACTION',
  payload,
});
