/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { AcceptDataTemplate } from '../AcceptDataTemplate/AcceptDataTemplate';
import './AcceptDataList.css';

export interface AcceptDataListItem {
  acceptDataId: string,
  acceptDataLabel: string,
  acceptDataValueUnit: string,
}

export interface AcceptDataListProps {
  acceptDataList: AcceptDataListItem[];
}

// eslint-disable-next-line max-len
export const AcceptDataList: React.FC<AcceptDataListProps> = (props: AcceptDataListProps): React.ReactElement => {
  const { acceptDataList } = props;

  const acceptDataListItems = acceptDataList.reduce((acc, item) => {
    const {
      acceptDataId, acceptDataLabel, acceptDataValueUnit,
    } = item;

    if (acceptDataLabel === undefined) {
      return acc;
    }

    return [
      ...acc,
      <AcceptDataTemplate key={acceptDataId}>
        <div className="accept-data-list__label">
          {acceptDataLabel}
        </div>
        <div className="accept-data-list__value">
          {acceptDataValueUnit}
        </div>
      </AcceptDataTemplate>,
    ];
  }, [] as React.ReactElement[]);

  return (
    <div className="accept-data-list">
      {acceptDataListItems}
    </div>
  );
};
