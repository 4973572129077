/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  IoLinkEvent,
  typeDeviceInstanceIoLinkEventMonitorAction,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const updateIoLinkEventMonitor = (targetInstance: string, payload: string)
: typeDeviceInstanceIoLinkEventMonitorAction => ({
  type: 'IOLINK__EVENT_MONITOR__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'IOLINK__EVENT_MONITOR__UPDATE_EVENT_LIST',
      payload,
    },
  },
});

export const addIoLinkEventMonitor = (targetInstance: string, payload: IoLinkEvent)
: typeDeviceInstanceIoLinkEventMonitorAction => ({
  type: 'IOLINK__EVENT_MONITOR__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'IOLINK__EVENT_MONITOR__ADD_EVENT_LIST',
      payload,
    },
  },
});

export const cleanIoLinkEventMonitor = (targetInstance: string)
: typeDeviceInstanceIoLinkEventMonitorAction => ({
  type: 'IOLINK__EVENT_MONITOR__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'IOLINK__EVENT_MONITOR__CLEAN_EVENT_LIST',
    },
  },
});
