/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import iconProcessDataHelp from '../../../../../../../assets/icons/icon-process-data-help.svg';
import SelectProcessCheckbox from '../../SelectProcessCheckbox/SelectProcessCheckbox';
import useProcessDataCheckbox from './hooks/useProcessDataCheckbox';
import './ProcessDataIOElement.scss';

export interface ProcessDataIOElementProps {
  name: string;
  label: string;
  help: string | undefined;
  kind: 'input' | 'output';
  defaultValue: boolean;
  readonly: boolean;
}

export const ProcessDataIOElement: React.FC<ProcessDataIOElementProps> = (
  props: ProcessDataIOElementProps,
) => {
  const {
    name, kind, label, help, defaultValue, readonly,
  } = props;
  const { t } = useTranslation();
  const [checkboxValue, setCheckboxValue] = useProcessDataCheckbox(name, defaultValue, kind);

  return (
    <div className="device-process-data-input-output-element">
      <SelectProcessCheckbox
        checked={checkboxValue}
        label={t(label)}
        onChange={setCheckboxValue}
        disabled={readonly}
      />
      { help && (
      <div className="device-process-data-input-output-element__tooltip">
        <OverlayTrigger placement="top" overlay={<Tooltip id={`process-io-tooltip-${name}`}>{t(help)}</Tooltip>}>
          <div>
            <img src={iconProcessDataHelp} alt="" />
          </div>
        </OverlayTrigger>
      </div>
      )}
    </div>
  );
};
