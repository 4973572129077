/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useState } from 'react';
import SVGGauge from './SVGGauge/SVGGauge';
import OverlayPortal from '../../OverlayPortal/OverlayPortal';
import { DragIndicatorControl } from '../../DragIndicatorControl/DragIndicatorControl';
import useDragIndicatorValues from './hooks/useDragIndicatorValues';
import { SVGGaugeSegment } from './SVGGauge/SVGGaugeSegments/SVGGaugeSegments';
import { SVGGaugeTextPoint } from './SVGGauge/SVGGaugeTextPoints/SVGGaugeTextPoints';
import './SVGGaugeControl.scss';

export interface SVGGaugeControlProps {
  title: string;
  subtitle: string;
  currentValue: number;
  currentUnit: string;
  gaugeSegments: SVGGaugeSegment[];
  gaugePoints: SVGGaugeTextPoint[];
  displayFormat?: string;
  showDragIndicator: boolean;
  // eslint-disable-next-line no-unused-vars
  setShowDragIndicator: (value: boolean) => void;
}

interface GaugeDragIndicatorMenu {
  show: boolean;
  xPosition?: number;
  yPosition?: number;
}

const SVGGaugeControl: React.FC<SVGGaugeControlProps> = (props: SVGGaugeControlProps)
: React.ReactElement => {
  const {
    currentValue, currentUnit, title, subtitle, gaugePoints, gaugeSegments, displayFormat,
    showDragIndicator, setShowDragIndicator,
  } = props;
  const [dragIndicatorMenu, setDragIndicatorMenu] = useState<GaugeDragIndicatorMenu>({
    show: false, xPosition: 0, yPosition: 0,
  });
  const [minDragValue, maxDragValue, resetDragValues] = useDragIndicatorValues(currentValue);

  return (
    <div className="svg-gauge-control">
      <SVGGauge
        title={title}
        subtitle={subtitle}
        displayFormat={displayFormat}
        currentValue={currentValue}
        currentUnit={currentUnit}
        gaugePoints={gaugePoints}
        gaugeSegments={gaugeSegments}
        showDragValues={showDragIndicator}
        maxDragValue={maxDragValue}
        minDragValue={minDragValue}
        onClick={(x, y) => {
          setDragIndicatorMenu((state) => ({
            ...state,
            show: !state.show,
            xPosition: x,
            yPosition: y,
          }));
        }}
      />
      {dragIndicatorMenu.show && (
      <OverlayPortal>
        <div
          role="none"
          className="svg-gauge-control__overlay"
          onClick={() => {
            setDragIndicatorMenu((state) => ({
              ...state,
              show: false,
              xPosition: undefined,
              yPosition: undefined,
            }));
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: dragIndicatorMenu.yPosition,
              left: dragIndicatorMenu.xPosition,
            }}
          >
            <DragIndicatorControl
              showIndicator={showDragIndicator}
              onDragIndicatorChange={() => {
                setShowDragIndicator(!showDragIndicator);
              }}
              onRefresh={() => resetDragValues(currentValue)}
            />
          </div>
        </div>
      </OverlayPortal>
      )}
    </div>
  );
};

export default SVGGaugeControl;
