/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars, class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

const Console = console;

export interface Logger {
  trace(message?: any, ...optionalParams: any[]): void;
  debug(message?: any, ...optionalParams: any[]): void;
  info(message?: any, ...optionalParams: any[]): void;
  warn(message?: any, ...optionalParams: any[]): void;
  error(message?: any, ...optionalParams: any[]): void;
  fatal(message?: any, ...optionalParams: any[]): void;
}

export class LoggerFactory {
  public create(realm: string): Logger {
    const logger = (global.process?.env?.JEST_WORKER_ID)
      ? this.getNullLogger(realm) : this.getProdLogger(realm); // getDevLogger(realm);
    return {
      trace: (...args) => logger('trace', args),
      debug: (...args) => logger('debug', args),
      info: (...args) => logger('info', args),
      warn: (...args) => logger('warn', args),
      error: (...args) => logger('error', args),
      fatal: (...args) => logger('fatal', args),
    };
  }

  private getNullLogger = (realm) => ((type, args) => null);

  private getProdLogger = (realm) => ((type, args) => (
    {
      trace: () => null,
      debug: () => null,
      info: Console.debug.bind(Console),
      warn: Console.warn.bind(Console),
      error: Console.error.bind(Console),
      fatal: Console.error.bind(Console),
    }
  )[type](`[cxEdp.${realm}]`, ...args));

  private getDevLogger = (realm) => ((type, args) => (
    {
      // Note:
      // Calls of console.debug(…) are usually only shown in browser when "Verbose" is activated.
      // Calls of console.trace(…) are shown when "Info" is activated and also display call stack.
      // Calls of console.dir(object) allow to inspect/debug the passed object
      trace: Console.debug.bind(Console),
      debug: Console.debug.bind(Console),
      info: Console.info.bind(Console),
      warn: Console.warn.bind(Console),
      error: Console.error.bind(Console),
      fatal: Console.error.bind(Console),
    }
  )[type](`[cxEdp.${realm}]`, ...args));
}

export default new LoggerFactory();
