/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { ReduxControlProps } from '../../ReduxControlProps';
import withControlVisibility from '../../hoc/withControlVisibility';
import { Quint4AnalogSignalControl } from './Quint4AnalogSignal';

const Quint4AnalogSignalRedux: React.FC<ReduxControlProps> = (props: ReduxControlProps)
  : React.ReactElement | null => {
  const { onStateChanged, deviceInstanceId } = props;

  return (
    <Quint4AnalogSignalControl
      signalSelectorIdentRef="var_UDP_Out2AnalogFunc_Out2FunSelect_Out2Routing_AnalogControl"
      analogStartValueIdentRef="var_UDP_Out2AnalogFunc_Out2AnalogStartValue_AnalogControl"
      analogEndValueIdentRef="var_UDP_Out2AnalogFunc_Out2AnalogEndValue_AnalogControl"
      outCombiInvertIdentRef="var_UDP_Out2Parameter_Out2CombiInvert"
      deviceInstanceId={deviceInstanceId}
      onStateChanged={onStateChanged}
    />
  );
};

export default withControlVisibility(Quint4AnalogSignalRedux);
