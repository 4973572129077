/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';

export const INFORMATION__SET_USER_NAME = 'INFORMATION__SET_USER_NAME';
export const INFORMATION__SET_HOST_NAME = 'INFORMATION__SET_HOST_NAME';
export const INFORMATION__APP_INITIALIZED = 'INFORMATION__APP_INITIALIZED';

export interface SystemInformationState {
  userName: string;
  hostName: string;
  appInitialized: boolean;
}

export interface SetUserNameAction extends Action<typeof INFORMATION__SET_USER_NAME> {
  payload: string;
}

export interface SetHostNameAction extends Action<typeof INFORMATION__SET_HOST_NAME> {
  payload: string;
}

export type SetAppInitializedAction = Action<typeof INFORMATION__APP_INITIALIZED>

export type SystemInformationActionTypes = SetUserNameAction
 | SetHostNameAction
 | SetAppInitializedAction;
