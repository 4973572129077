/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus, Services } from '@gpt/commons';
import { deviceTargetDatasetSelector } from '../../../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetState, DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { DeviceInstancesState } from '../../../../../store/deviceInstances/types';

const internalControlTypeSelector = (identRef: string, state?: DatasetState)
: DeviceModelStatus.UI.OnlineModuleCaparocControlType | undefined => {
  const desc = state?.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  return desc.controlType.type === DeviceModelStatus.UI.ControlType.CTLONLINEMODULEEDITORCAPAROC ? desc.controlType : undefined;
};

export const controlTypeSelector = (deviceInstances: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string)
: DeviceModelStatus.UI.OnlineModuleCaparocControlType | undefined => {
  const state = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  return internalControlTypeSelector(identRef, state);
};

export const controlChannelListSelector = (deviceInstances: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string)
: number[] => {
  const state = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  const controlType = internalControlTypeSelector(identRef, state);
  return controlType?.channels ?? [];
};
