/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LedControl } from '../../components/LedControl';
import { UnsupportedControl } from '../../components/UnsupportedControl';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import { useTypedSelector } from '../../store';
import { DatasetContext } from '../../views/DatasetContext';
import withControlVisibility from '../hoc/withControlVisibility';
import { ReduxControlProps } from '../ReduxControlProps';
import { DatasetState, DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import './DynLedFloatControl.css';

const selectControlType = (identRef: IdentRef, dataset?: DatasetState): DeviceModelStatus.UI.DynLedFloatControlType | undefined => {
  const desc = dataset?.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (desc.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDYNLEDFLOAT) {
    return undefined;
  }
  return desc.controlType;
};

const selectControlVariable = (state: DeviceInstancesState, deviceInstanceId: string, target: DatasetType, identRef: IdentRef): DeviceModelStatus.DeviceModelDescriptor | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, target);
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return undefined;
  }
  return dataset?.descriptors[controlType.variable];
};

const selectControlVariableValue = (state: DeviceInstancesState, deviceInstanceId: string, target: DatasetType, identRef: IdentRef): DeviceModelStatus.StatusValue | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, target);
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return undefined;
  }
  return dataset?.values[controlType.variable];
};

// eslint-disable-next-line max-len
// eslint-disable-next-line import/prefer-default-export
const DynLedFloatControl: React.FC<ReduxControlProps> = (props: ReduxControlProps): React.ReactElement | null => {
  const {
    identRef,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  const controlVariable = useTypedSelector((state) => selectControlVariable(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const controlVariableValue = useTypedSelector((state) => selectControlVariableValue(state.deviceInstances, deviceInstanceId, targetDataset, identRef));

  if (descriptor === undefined) {
    return <UnsupportedControl text={`DynLedFloatControl [${identRef}]: undefined object`} />;
  }
  if (descriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text={`DynLedFloatControl [${identRef}]: supported object type is ControlDescriptor only`} />;
  }
  if (descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDYNLEDFLOAT) {
    return <UnsupportedControl text={`DynLedFloatControl [${identRef}]: supported controlType is CTLDYNLEDFLOAT only`} />;
  }
  if (controlVariable === undefined || controlVariableValue === undefined) {
    return <UnsupportedControl text={`DynLedFloatControl [${identRef}]: undefined control variable or variable value`} />;
  }
  if (controlVariable.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text={`DynLedFloatControl [${controlVariable.identRef}]: supported variable type is StatusDescriptor only`} />;
  }

  if (controlVariable.visibility === false) {
    return (null);
  }

  const selectedBehaviour = descriptor.controlType.BEHAVIOUR
    .find((behaviour) => (controlVariableValue.value >= behaviour.min && controlVariableValue.value <= behaviour.max));
  const color = selectedBehaviour === undefined ? descriptor.controlType.defcolor : selectedBehaviour.fgcolor;
  const blink = selectedBehaviour === undefined ? undefined : `${selectedBehaviour.frequency?.replace('.', '-')}Hz`;
  return <LedControl title={t<string>(controlVariable.label)} color={color} blink={blink} />;
};

export default withControlVisibility(DynLedFloatControl);
