import { DeviceInformation, WebDevice } from '@gpt/commons';
import deviceImage from './assets/MiniMCR.jpg';

const demoDevice: DeviceInformation = {
  catalog: {
    deviceCatalogTitle: 'deviceCatalogTitle',
    deviceCatalogIdent: 'deviceCatalogIdent',
    deviceDriverId: '01-04-0F-14-0020_mini_mcr-2-rtd-ui_fw1020',
    protocol: 'LAN: 192.168.1.68',
    deviceTypeName: 'MACX MCR-EX-T-UIREL-UP',
    profileName: 'MACX MCR-EX-T',
    deviceFamily: 'Widerstandsthermometer-Messumformer',
    species: WebDevice.Species.device,
    deviceVendor: 'Phoenix Contact',
    productURL: 'link to product page',
    productImage: deviceImage,
    productOrderNumber: 'OrderNumber',
    firmwareVersion: '1.0',
    file: {
      version: '1.0.0',
      buildDate: '2020-12-11',
      name: 'example-script.js',
    },
    properties: {
      communication: WebDevice.CommunicationType.enabled,
      simulation: WebDevice.SimulationType.enabled,
      supportedWizard: [
        'INITIALDEVICESTARTUP',
        'CHANGEDEVICE',
        'CREATEPARAMETERFILE',
        'TRANSFERPARAMETERFILE',
        'CXEEDITPARAMETERFILE',
        'RESTOREDEVICE',
        'DEVICESETTINGS',
        'CXE_FIRMWARE_PARAMETER',
        'CXE_HARDWARE_SWITCH',
      ],
    },
    i18n: {
      family: 'MINI_MCR',
    },
  },
  instance: {
    deviceId: 'Device-ID',
    hardwareVersion: '1.23',
    firmwareVersion: '2.34',
    deviceTag: 'T_RTI323',
    serialNumber: 'PXC23459999',
    communicationProtocol: 'LAN',
    deviceTypeIdentifier: '01-04-0F-14-0020',
    simulation: false,
  },
};

export default demoDevice;
