/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import { ExecutionState } from '../../../common';
import {
  typeDeviceInstancePreviewDatasetAction,
} from './types';

export const setPreviewDeviceRequestStatus = (targetInstance: string, status: ExecutionState)
  : typeDeviceInstancePreviewDatasetAction => ({
  type: 'DEVICE_INSTANCE__PREVIEW_DATASET__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'PREVIEW_DATASET__SET_EXECUTION_STATE',
      payload: status,
    },
  },
});

export const setPreviewDataset = (
  targetInstance: string,
  payload?: Services.DeviceModelServer.DatasetProviderService.ExternalDataset,
): typeDeviceInstancePreviewDatasetAction => ({
  type: 'DEVICE_INSTANCE__PREVIEW_DATASET__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'PREVIEW_DATASET__SET_DATASET',
      payload,
    },
  },
});
