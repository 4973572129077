/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { CompareValueProps } from '../../../CompareTypes';
import CompareContainer from '../../CompareContainer/CompareContainer';
import CheckboxEditorControl from '../../../../../components/Basic/CheckboxEditorControl/CheckboxEditorControl';

export interface CompareCheckboxValueProps extends CompareValueProps {
  id: string;
  label: string;
  valueLeft: boolean;
  valueRight: boolean;
  // eslint-disable-next-line no-unused-vars
  onLeftValueChange?: (value: boolean) => void;
}

const CompareCheckboxValue
  : React.FC<CompareCheckboxValueProps> = (props: CompareCheckboxValueProps)
: React.ReactElement => {
    const {
      onButtonClick, label,
      valueLeft,
      valueRight,
      readonly, modified, onLeftValueChange,
      onShowContentHelp, onHideContentHelp,
      buttonFunction, showImageAndSign,
      help,
      id,
      equal,
    } = props;

    return (
      <CompareContainer
        label={label}
        buttonFunction={buttonFunction}
        equal={equal}
        onButtonClick={onButtonClick}
        showImageAndSign={showImageAndSign}
      >
        <CheckboxEditorControl
          id={`cmp-${id}-left`}
          value={valueLeft}
          label={label}
          help={help ?? ''}
          onChange={(value) => onLeftValueChange?.(value)}
          edited={modified}
          readonly={readonly ?? false}
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
          showLabel={false}
        />
        <CheckboxEditorControl
          id={`cmp-${id}-right`}
          value={valueRight}
          label={label}
          help={help ?? ''}
          onChange={() => 0}
          readonly
          onShowContentHelp={onShowContentHelp}
          onHideContentHelp={onHideContentHelp}
          showLabel={false}
        />
      </CompareContainer>
    );
  };

export default CompareCheckboxValue;
