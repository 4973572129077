import { DeviceModelStatus } from '@gpt/commons';

export interface i18nLocString {
  id: string,
  args?: { [key: string]: number | string | undefined }
}

export const userInputValidation = (
  originalValue: string,
  minLength: number,
  maxLength: number,
): i18nLocString | undefined => {
  let message: i18nLocString | undefined;
  if (!/^[\x20-\x7F]*$/.test(originalValue)) {
    message = { id: 'PARAMETER_VALIDATION_ERROR__NON_ASCII_CHARACTER' };
  } else if (originalValue.length < minLength || originalValue.length > maxLength) {
    message = {
      id: 'PARAMETER_VALIDATION_ERROR__INVALID_PASSWORD_LENGTH',
      args: {
        MIN: minLength,
        MAX: maxLength,
      },
    };
  }
  return message;
};

export const createWarningMessage = (
  statusValue: DeviceModelStatus.StatusValue,
  minLength: number,
  maxLength: number,
): i18nLocString | undefined => {
  const originalValue = `${statusValue.value}`;
  let message = userInputValidation(originalValue, minLength, maxLength);
  if (message !== undefined) {
    return message;
  }

  const { valueValidity, valueValidityDescription } = statusValue;
  if (valueValidity === DeviceModelStatus.StatusValueValidity.valid) {
    message = undefined;
  } else {
    message = {
      id: 'PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__INTERVALS',
      args: {
        INTERVAL: valueValidityDescription,
      },
    };
  }

  return message;
};
