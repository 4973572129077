import React from 'react';
import './ThumbWheel.scss';

export interface ThumbWheelProps {
  thumbWheelValue: number,
}

const ThumbWheel: React.FC<ThumbWheelProps> = (props: ThumbWheelProps)
: React.ReactElement => {
  const { thumbWheelValue } = props;

  return (
    <div className="thumbwheel">
      <div className="rec rec1" />
      <div className="rec rec2" />
      <div className="rec rec3" />
      <div className="rec rec4" />
      <div className="thumbWheelValue">{thumbWheelValue}</div>
    </div>
  );
};

export default ThumbWheel;
