/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';

const fdcml10DeviceModelStatusValueCollection: DeviceModelStatus.StatusValueCollection = {
  paraSafetyApplication: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'paraSafetyApplication',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  user_char_min_x: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'user_char_min_x',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  user_char_max_x: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'user_char_max_x',
    value: 100,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  sensor: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'sensor',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  filterfactor: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'filterfactor',
    value: 1,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  temperature_unit: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'temperature_unit',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  mode: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'mode',
    value: 2,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  samplerate: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'samplerate',
    value: 8,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  device_type: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'device_type',
    value: 'MINI MCR-2-RTD-UI',
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  fw_version: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'fw_version',
    value: 4128,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  serial_number: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'serial_number',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  tag_number: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'tag_number',
    value: 'Messstelle',
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  user_message: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'user_message',
    value: 'Anwendertext',
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  date: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'date',
    value: 62600271,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  analog_output_type: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'analog_output_type',
    value: 2,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  error_handling: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'error_handling',
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    value: 5,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  analog_input_range_start: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'analog_input_range_start',
    value: -50,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  analog_input_range_end: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'analog_input_range_end',
    value: 150,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  two_wire_compensation: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'two_wire_compensation',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  application_help: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'application_help',
    value: 1,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  application_help_RTD: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'application_help_RTD',
    value: 1,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  analog_output_range_start: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'analog_output_range_start',
    value: 4,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  analog_output_range_end: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'analog_output_range_end',
    value: 20,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  analog_output_range_span: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'analog_output_range_span',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  overrange: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'overrange',
    value: 3.5,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  underrange: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'underrange',
    value: 3.5,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  linebreak: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'linebreak',
    value: 3.5,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  shorted: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'shorted',
    value: 3.5,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  faultsignal_modulerror: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'faultsignal_modulerror',
    value: 1,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  faultsignal_overrange: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'faultsignal_overrange',
    value: 1,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  faultsignal_underrange: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'faultsignal_underrange',
    value: 1,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  faultsignal_linebreak: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'faultsignal_linebreak',
    value: 1,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  faultsignal_shorted: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'faultsignal_shorted',
    value: 1,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  analog_output_Threshold: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'analog_output_Threshold',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  analog_output_SetValue: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'analog_output_SetValue',
    value: 10,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  application_pin: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'application_pin',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  recLinTable_00: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'recLinTable_00',
    value: 'record',
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  recLinTable_01: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'recLinTable_01',
    value: 'record',
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  LINEARIZATION_TABLE: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'LINEARIZATION_TABLE',
    value: 'table',
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  recLinTable_01_0000: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'recLinTable_01_0000',
    value: -160,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  recLinTable_01_0001: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'recLinTable_01_0001',
    value: 15,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  LINEARIZATION_USER_POINT_NUM: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'LINEARIZATION_USER_POINT_NUM',
    value: 30,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  LINEARIZATION_TABLE__SPLINEBLOBDATA: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'LINEARIZATION_TABLE__SPLINEBLOBDATA',
    value: 'AACkQQAAAIAAAIA/AAAAgOgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAA==',
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  LINEARIZATION_TABLE__MINDEVIATION: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'LINEARIZATION_TABLE__MINDEVIATION',
    value: 0.024,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  LINEARIZATION_TABLE__COLD80: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'LINEARIZATION_TABLE__COLD80',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  LINEARIZATION_TABLE__COLD0: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'LINEARIZATION_TABLE__COLD0',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  LINEARIZATION_TABLE__COLDLINEEQB: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'LINEARIZATION_TABLE__COLDLINEEQB',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
  LINEARIZATION_TABLE__COLDLINEEQM: {
    type: DeviceModelStatus.StatusType.StatusValue,
    identRef: 'LINEARIZATION_TABLE__COLDLINEEQM',
    value: 0,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
    errorCode: 0,
    modified: false,
    timestamp: '',
    changeCounter: 0,
  },
};

export default fdcml10DeviceModelStatusValueCollection;
