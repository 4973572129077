/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { WebDevice } from '@gpt/commons';
import { ExecutionState, useTypedSelector } from '../../../store';
import { deviceInstanceActiveDeviceInstance, deviceInstanceActiveDeviceState } from '../../../store/deviceInstances/store/activeDevice/selectors';
import WizardView from '../../WizardView/WizardView';
import { WizardProps } from '../../types';
import ContactronCommissioningWizard from '../Contactron/ContactronCommissioning';
import CommissioningWizard from '../Standard/Commissioning';
import Page1SelectActiveDevice from './Page1SelectActiveDevice/Page1SelectActiveDevice';

const WSelectorCommissioning: React.FC<WizardProps> = (props: WizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const { deviceInstanceId } = props;
  const activeDeviceInstance = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId));
  const activeDeviceInstantiationState = useTypedSelector((state) => deviceInstanceActiveDeviceState(state.deviceInstances, deviceInstanceId));

  let control;
  if (activeDeviceInstance === undefined || activeDeviceInstantiationState !== ExecutionState.success) {
    control = (
      <WizardView
        title={t<string>('WIZARD_DEVICE_COMMISSIONING__TITLE')}
        onWizardExit={() => 0}
        deviceInstanceId={deviceInstanceId}
      >
        <Page1SelectActiveDevice
          title={t<string>('WIZARD_DEVICE_COMMISSIONING__SELECT_DEVICE')}
          hideStepper
          onChangeToNextPage={() => 0}
          deviceInstanceId={deviceInstanceId}
        />
      </WizardView>
    );
  } else if (activeDeviceInstance.device.catalog.species === WebDevice.Species.station && activeDeviceInstance.device.catalog.deviceFamily === 'CONTACTRON GATEWAYS') {
    control = <ContactronCommissioningWizard deviceInstanceId={deviceInstanceId} startPage={1} />;
  } else {
    control = <CommissioningWizard deviceInstanceId={deviceInstanceId} startPage={1} />;
  }

  return <>{control}</>;
};

export default WSelectorCommissioning;
