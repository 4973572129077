/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  typeDeviceInstanceIoLinkTrio3EventMonitorAction,
  AsknowledgeTrio3EventPayload,
  UpdateTrio3EventMonitorPayload,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const updateTrio3EventMonitor = (
  targetInstance: string,
  payload: UpdateTrio3EventMonitorPayload,
): typeDeviceInstanceIoLinkTrio3EventMonitorAction => ({
  type: 'IOLINK__TRIO3_EVENT_MONITOR__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'TRIO3_EVENT_MONITOR__UPDATE_EVENT_LIST',
      payload,
    },
  },
});

export const setPendingTrio3Event = (
  targetInstance: string,
  payload: AsknowledgeTrio3EventPayload,
): typeDeviceInstanceIoLinkTrio3EventMonitorAction => ({
  type: 'IOLINK__TRIO3_EVENT_MONITOR__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'TRIO3_EVENT_MONITOR__SET_EVENT_PENDING',
      payload,
    },
  },
});

export const cleanTrio3EventMonitor = (targetInstance: string)
: typeDeviceInstanceIoLinkTrio3EventMonitorAction => ({
  type: 'IOLINK__TRIO3_EVENT_MONITOR__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'TRIO3_EVENT_MONITOR__CLEAN_EVENT_LIST',
    },
  },
});
