/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { WebDevice } from '@gpt/commons';
import './DeviceCatalogCardList.css';
import { ListGroup } from 'react-bootstrap';
import DeviceCatalogCard from '../DeviceCatalogCard/DeviceCatalogCard';

export interface DeviceCatalogCardListProps {
    deviceList: WebDevice.DeviceCatalogInformation[];
    selectedDeviceCatalogIdent?: string;
    onClick:
      (
        // eslint-disable-next-line no-unused-vars
        device: WebDevice.DeviceCatalogInformation,
      ) => void;
}

const DeviceCatalogCardList:
  React.FC<DeviceCatalogCardListProps> = (props: DeviceCatalogCardListProps)
: React.ReactElement => {
    const { deviceList, onClick, selectedDeviceCatalogIdent } = props;

    const listGroupItems = deviceList.map((device) => {
      const classNameActive = selectedDeviceCatalogIdent === device.deviceCatalogIdent ? 'active' : '';
      return (
        <ListGroup.Item className={`device-catalog-card-item ${classNameActive} m-0 p-0 border-0`} key={`${device.deviceDriverId}-${device.deviceTypeName}`}>
          <DeviceCatalogCard device={device} onClick={() => onClick(device)} />
        </ListGroup.Item>
      );
    });

    return (
      <ListGroup className="device-catalog-card-list" variant="flush">
        {listGroupItems}
      </ListGroup>
    );
  };

export default DeviceCatalogCardList;
