/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { CaparocChannelProps, CaparocModule } from './CockpitCaparocStationHook';

export interface ModuleData {
    addr: number;
    channelCount: number;
    channels: CaparocChannelProps[];
    startCol: number;
    endCol: number;
    row: number;
    leftBreak?: boolean;
    rightBreak?: boolean;
}

export interface ChannelCollectionData {
    channelNumber: number;
    modules: ModuleData[];
}

export type ChannelColorType = 'gray' | 'yellow' | 'red' | 'green';

export const createDataFromConfiguration = (moduleCfg: CaparocModule[]) => {
  const data = moduleCfg.reduce((acc, item) => {
    const { channels, addr } = item;
    const channelCount = channels.length;

    if (acc.channelNumber < 10 && (acc.channelNumber + channelCount) > 10) {
      const firstChannelsCount = 10 - acc.channelNumber;
      const secondChannelsCount = channelCount - firstChannelsCount;

      const firstChannels = channels.slice(0, firstChannelsCount);
      const secondChannles = channels.slice(firstChannelsCount);

      const moduleOne: ModuleData = {
        addr: addr,
        channelCount: firstChannelsCount,
        channels: firstChannels,
        startCol: 1 + acc.channelNumber,
        endCol: 1 + acc.channelNumber + firstChannelsCount,
        row: 1,
        rightBreak: true,
      };

      const moduleSecond: ModuleData = {
        addr: addr,
        channelCount: secondChannelsCount,
        channels: secondChannles,
        startCol: 1,
        endCol: 1 + secondChannelsCount,
        row: 2,
        leftBreak: true,
      };

      return {
        ...acc,
        addr: addr,
        channelNumber: acc.channelNumber + channelCount,
        modules: [
          ...acc.modules,
          ...[moduleOne, moduleSecond],
        ],
      };
    }

    const columnFactor = acc.channelNumber + channelCount <= 10
      ? 1 + acc.channelNumber : 1 + acc.channelNumber - 10;

    return {
      ...acc,
      channelNumber: acc.channelNumber + channelCount,
      modules: [
        ...acc.modules,
        {
          addr: addr,
          channelCount: channelCount,
          channels: channels,
          startCol: columnFactor,
          endCol: columnFactor + channelCount,
          row: acc.channelNumber + channelCount <= 10 ? 1 : 2,
        },
      ],
    };
  }, { channelNumber: 0, modules: [] } as ChannelCollectionData);

  const controlData = data.modules.map((item) => {
    const xchannels = item.channels.sort((x, y) => x.moduleChannelNo - y.moduleChannelNo);

    return {
      ...item,
      addr: item.addr,
      channels: xchannels,
    };
  });

  return controlData;
};

export const calculateChannelColor = (
  chStatus: number,
  chErrorStatus: number,
  nomCurrent: number,
  actCurrent: number,
): ChannelColorType => {
  let color: ChannelColorType = 'gray';

  if (chErrorStatus !== 0) {
    color = 'red';
  } else if (chStatus === 0) {
    color = 'gray';
  } else if (actCurrent >= 0.8 * nomCurrent) {
    color = 'yellow';
  } else {
    color = 'green';
  }

  return color;
};
