/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useState } from 'react';
import { ChartCardButton } from './button/ChartCardButton';
import './ChartCardHeader.scss';

export interface ChartCardHeaderProps {
  title: string;
  showDragIndicator: boolean;
  // eslint-disable-next-line no-unused-vars
  onShowDragIndicator: (value: boolean) => void;
  onResetDragValues: () => void;
}

export const ChartCardHeader: React.FC<ChartCardHeaderProps> = (props: ChartCardHeaderProps)
: React.ReactElement => {
  const {
    title,
    showDragIndicator,
    onShowDragIndicator,
    onResetDragValues,
  } = props;

  return (
    <div className="x3phasen-header">
      <div className="x3phasen-header__title-container">
        <div className="x3phasen-header__title-container__title">{title}</div>
      </div>
      <div className="x3phasen-header__button">
        <ChartCardButton onResetDragValues={onResetDragValues} onShowDragIndicator={onShowDragIndicator} showDragIndicator={showDragIndicator} />
      </div>
    </div>
  );
};
