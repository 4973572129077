/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import { EventMonitorTrioList } from '../EventMonitorTrioList/EventMonitorTrioList';
import { EventMonitorTrioTemplate } from '../EventMonitorTrioTemplate/EventMonitorTrioTemplate';
import './EventMonitorTrio.css';
import { Trio3EventList } from '../../../store/deviceInstances/store/ioLinkTrio3EventMonitor/types';

export interface EventMonitorTrioProps extends PropsWithChildren<any> {
  eventList?: Trio3EventList;
  targetInstance: string;
}

// eslint-disable-next-line max-len
export const EventMonitorTrio: React.FC<EventMonitorTrioProps> = (props: EventMonitorTrioProps):
  React.ReactElement => {
  const { eventList, children, targetInstance } = props;

  return (
    <div className="event-monitor-trio">
      <EventMonitorTrioTemplate isHeader>
        <div>{ children[0] }</div>
        <div>{ children[1] }</div>
        <div>{ children[2] }</div>
      </EventMonitorTrioTemplate>
      <EventMonitorTrioList eventList={eventList} targetInstance={targetInstance} />
    </div>
  );
};
