/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { v4 } from 'uuid';
import './DIPSwitch.css';

export interface DIPSwitchProps {
  id: string;
  isOn: boolean;
  readonly?: boolean;
  // eslint-disable-next-line no-unused-vars
  onSwitchChange: (switchState: boolean) => void;
}

export const DIPSwitch: React.FC<DIPSwitchProps> = (props: DIPSwitchProps):
  React.ReactElement => {
  const {
    isOn, onSwitchChange, id, readonly,
  } = props;
  const dipId = `${id}-${v4()}`;

  return (
    <label
      className={`dip-switch-label${isOn ? ' dip-switch-label--on' : ''}`}
      htmlFor={dipId}
    >
      <input
        data-testid="dip-switch-checkbox"
        type="checkbox"
        id={dipId}
        className="dip-switch-checkbox"
        checked={isOn}
        onChange={(ev) => (readonly ? 0 : onSwitchChange(ev.target.checked))}
      />
      <span className="dip-switch-button" />
    </label>
  );
};
