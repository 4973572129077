/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
export const switchWidth = 250;
export const switchHeight = switchWidth;

export const switchRadius = switchWidth / 2 - 80;
export const switchCenterX = switchWidth / 2;
export const switchCenterY = switchHeight / 2;

export const switchRadiusText = switchRadius + 24;

const angleInRadians = (angleInDegrees: number)
    : number => ((angleInDegrees - 90) * Math.PI) / 180.0;

export interface Point {
  x: number;
  y: number;
}

export const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
): Point => {
  const angle = angleInRadians(angleInDegrees);
  return {
    x: centerX + (radius * Math.cos(angle)),
    y: centerY + (radius * Math.sin(angle)),
  };
};
