/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { WizardViewPageProps } from '../../../WizardView/WizardViewPage';
import ReduxControl from '../../../../controls/ReduxControl/ReduxControl';
import './Page3DeviceIdent.scss';

export interface Page3DeviceIdentProps extends WizardViewPageProps {
  // eslint-disable-next-line react/no-unused-prop-types
  stationInstanceId: string;
}

const Page3DeviceIdent
: React.FC<Page3DeviceIdentProps> = (props: Page3DeviceIdentProps) : React.ReactElement => {
  const { stationInstanceId, identRef } = props;

  return (
    <div className="contactron-commissioning-wizard">
      <div className="page-station-identification">
        <ReduxControl
          identRef={identRef ?? 'unknown'}
          deviceInstanceId={stationInstanceId}
          onStateChanged={() => 0}
        />
      </div>
    </div>
  );
};

export default Page3DeviceIdent;
