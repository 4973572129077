/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import { ContactronModuleCollection } from '../../../../../../store/wizards/contactronStation';
import { RowData } from './GroupTableRow/GroupTableRow';

const groupData = (
  modules: ContactronModuleCollection,
  devices: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
  kind: 'input' | 'output',
  moduleType: 'station' | 'module',
): RowData[] => Object.entries(modules).reduce((acc: RowData[], [, module]) => {
  if (module.type !== moduleType) {
    return acc;
  }

  const device = devices[module.moduleId];
  if (!device) {
    return acc;
  }

  const moduleCheckList = kind === 'input' ? device.inputDataList : device.outputDataList;
  const moduleCheckboxes = kind === 'input' ? module.input.dataCheckbox : module.output.dataCheckbox;
  const deviceDataCheckboxDesc = kind === 'input' ? device.inputDataDescription : device.outputDataDescription;

  const deviceTagVarName = moduleType === 'station' ? 'IFSM_ADDR_APPL_AKZ' : `DEVICE${module.instance.address}_IFSM_ADDR_AKZ`;

  const checkboxList = moduleCheckList.reduce((xacc, processValueName) => {
    const checkboxValue = moduleCheckboxes[processValueName] ?? deviceDataCheckboxDesc[processValueName]?.checked;
    if (checkboxValue !== true) {
      return xacc;
    }

    const processValueDesc = deviceDataCheckboxDesc[processValueName];
    return [
      ...xacc,
      {
        deviceTagVarName,
        processValueLabel: processValueDesc.label,
        ifsAddress: module.instance.address,
        deviceType: module.instance.name,
        processValueAddress: processValueDesc.address,
        key: `${kind}-${module.instance.address}-${module.instance.serialNo}-${processValueDesc.name}`,
        kind,
      }];
  }, [] as RowData[]);

  return [
    ...acc,
    ...checkboxList,
  ];
}, [] as RowData[]);

export const groupByProcessValueNames = (
  modules: ContactronModuleCollection,
  devices: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
  kind: 'input' | 'output',
  moduleType: 'station' | 'module',
) => {
  const data = groupData(modules, devices, kind, moduleType);
  return data.sort((a, b) => a.processValueAddress - b.processValueAddress);
};

export const groupByDevices = (
  modules: ContactronModuleCollection,
  devices: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
  kind: 'input' | 'output',
  moduleType: 'station' | 'module',
) => groupData(modules, devices, kind, moduleType);
