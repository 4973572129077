/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  Middleware, MiddlewareAPI, Dispatch,
} from 'redux';
import { Services, WebDevice } from '@gpt/commons';
import { v4 as uuidv4 } from 'uuid';
import { IWebWorkerDeviceManager } from '../../../../../services/WebWorkerDevice/WebWorkerDeviceManager';
import {
  typeIoLinkParameterMiddlewareActions,
} from './types';
import { DatasetType } from '../../../store/deviceDataset/types';
import { deviceInstancesStoreSelector } from '../../../../reduxStoreSelector';
import { bytesToBase64 } from '../../../../linearizationDataset/middleware/base64';
import { setDeviceMethodExecutionState } from '../../../store/deviceMethod/actions';
import { MethodStageExecutionStatus } from '../../../store/deviceMethod/types';

const createIoLinkWriteParameterMethod = (deviceInstanceId: string, index: number, subindex?: number, data?: Uint8Array)
: WebDevice.WebDeviceExecuteMethodRequest => {
  let header: Services.DeviceModel.MethodHeader = {
    index: `${index}`,
  };
  header = subindex ? { ...header, subindex: `${subindex}` } : header;
  header = data ? { ...header, data: `${bytesToBase64(data)}` } : header;

  return {
    kind: 'WEBDEVICE__EXECUTE_METHOD__REQUEST',
    header,
    methodIdent: 'IoLinkWriteParameter',
    deviceInstanceId,
    requestId: uuidv4(),
    request: {
      type: 'WEBDEVICE__METHOD_EXECUTE__INIT',
      data: [],
    },
  };
};

const ioLinkParameterMiddleware = (webWorkerDeviceManager: IWebWorkerDeviceManager): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeIoLinkParameterMiddlewareActions>(action: A): Promise<A> => {
  switch (action.type) {
    case 'IOLINK__ACTION__WRITE_PARAMETER': {
      const { payload } = action;
      const {
        index,
        subindex,
        data,
        targetInstance,
        actionId,
      } = payload;

      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const webWorkerInstance = deviceInstances.instances[targetInstance]?.activeDevice.modelInstance?.webWorkerInstanceRef;
      if (webWorkerInstance === undefined) {
        break;
      }

      const webWorkerDevice = webWorkerDeviceManager.get(webWorkerInstance);
      if (webWorkerDevice === undefined) {
        break;
      }

      api.dispatch(setDeviceMethodExecutionState(targetInstance, {
        methodIdent: actionId,
        stage: MethodStageExecutionStatus.InProgress,
      }));

      const request = createIoLinkWriteParameterMethod(targetInstance, index, subindex, data);
      const response = await webWorkerDevice.get(DatasetType.device)
        .executeMethod(request);

      switch (response.response.type) {
        case 'WEBDEVICE__METHOD_EXECUTE__DONE__SUCCESS':
          api.dispatch(setDeviceMethodExecutionState(targetInstance, {
            methodIdent: actionId,
            stage: MethodStageExecutionStatus.DoneSuccess,
          }));
          break;
        case 'WEBDEVICE__METHOD_EXECUTE__DONE__FAILED':
          api.dispatch(setDeviceMethodExecutionState(targetInstance, {
            methodIdent: actionId,
            stage: MethodStageExecutionStatus.DoneFailed,
            message: response.response.message,
          }));
          break;
        default:
          api.dispatch(setDeviceMethodExecutionState(targetInstance, {
            methodIdent: actionId,
            stage: MethodStageExecutionStatus.DoneFailed,
          }));
      }
    }
      break;
    default:
  }
  const result = next(action);
  return result;
};

export default ioLinkParameterMiddleware;
