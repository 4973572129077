/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../store/reduxStore';
import { LoadingControl } from '../../components/LoadingControl/LoadingControl';
import ReduxControl from '../../controls/ReduxControl/ReduxControl';
import { GetApplicationMenu } from '../../store/selectors/rootStructureSelector';
import ApplicationView from '../ApplicationView/ApplicationView';
import { DatasetContext } from '../DatasetContext';
import './OnlineParameterView.scss';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';

const OnlineParameterView: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const deviceInstance = useTypedSelector((state) => state.deviceInstances.activeDeviceInstance);
  const rootMenuRef = useTypedSelector(
    (state) => GetApplicationMenu('APP_ONLINEPARAM', deviceTargetDatasetSelector(state.deviceInstances, deviceInstance, DatasetType.device)),
  );
  if (rootMenuRef === undefined || deviceInstance === undefined) {
    return <LoadingControl title={t<string>('SIDEBAR__ONLINE_PARAMETERS_TITLE')} />;
  }

  return (
    <div className="online-param-view w-100 h-100">
      <DatasetContext.Provider value={{ targetDataset: DatasetType.device }}>
        <ApplicationView buttons={false} helpbar>
          <ReduxControl
            deviceInstanceId={deviceInstance}
            identRef={rootMenuRef}
            onStateChanged={() => 0}
            readonly
            rootLayout
          />
        </ApplicationView>
      </DatasetContext.Provider>
    </div>
  );
};

export default OnlineParameterView;
