/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../../store/reduxStore';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import SelectDeviceDialog from '../../../PageComponents/SelectDeviceDialog/SelectDeviceDialog';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import './Page1SelectDevice.css';
import { ExecutionState, deviceWizardUpdateButtons, instantiateDevice } from '../../../store';
import { deviceInstanceActiveDeviceState } from '../../../store/deviceInstances/store/activeDevice/selectors';

export interface Page1SelectDeviceProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const Page1SelectDevice: React.FC<Page1SelectDeviceProps> = (props: Page1SelectDeviceProps)
  : React.ReactElement => {
  const { active, deviceInstanceId } = props;
  const [deviceCatalogIdent, setDeviceCatalogIdent] = useState<string | undefined>(undefined);
  const catalogDeviceList = useTypedSelector((state) => {
    const deviceList = Object.keys(state.discoveryService.catalog.deviceList).map((key) => state.discoveryService.catalog.deviceList[key]);
    return deviceList.filter((device) => device.properties.supportedWizard.includes(DeviceModelStatus.Wizards.WizardType.DEVICESETTINGS) === true);
  });
  const activeDeviceState = useTypedSelector((state) => deviceInstanceActiveDeviceState(state.deviceInstances, deviceInstanceId));
  const dispatch = useDispatch();

  useEffect(() => {
    if (active !== true) {
      return;
    }

    if (activeDeviceState !== ExecutionState.success) {
      dispatch(deviceWizardUpdateButtons({
        abort: ButtonState.enabled,
        back: ButtonState.disabled,
        next: ButtonState.disabled,
        complete: ButtonState.hidden,
        pcta: ButtonRole.next,
      }));
      return;
    }

    if (deviceCatalogIdent !== undefined) {
      dispatch(deviceWizardUpdateButtons({
        abort: ButtonState.enabled,
        back: ButtonState.disabled,
        next: ButtonState.enabled,
        complete: ButtonState.hidden,
        pcta: ButtonRole.next,
        focusPcta: ButtonRole.next,
      }));
    }
  }, [activeDeviceState, deviceCatalogIdent, active]);

  return (
    <Container fluid className="w-100 h-100">
      <Row className="h-100">
        <Col xs={3} className="h-100" />
        <Col xs={6} className="h-100">
          <div className="select-device-container p-2">
            <SelectDeviceDialog
              catalogDeviceList={catalogDeviceList}
              onSelectDevice={async (device) => {
                dispatch(instantiateDevice({
                  deviceInfo: {
                    catalog: {
                      ...device,
                    },
                  },
                  targetInstance: deviceInstanceId,
                  wizardMode: true,
                }));
                setDeviceCatalogIdent(device.deviceCatalogIdent);
              }}
              selectedDeviceCatalogIdent={deviceCatalogIdent}
            />
          </div>
        </Col>
        <Col xs={3} className="h-100" />
      </Row>
    </Container>
  );
};

export default Page1SelectDevice;
