import React from 'react';
import ThumbWheelButton, { ThumbWheelButtonDirection } from './ThumbWheelButton';
import ThumbWheel from './ThumbWheel';
import './ThumbWheelWithButtons.scss';

export interface ThumbWheelProps {
  label: string;
  thumbWheelValue: number;
  readonly: boolean,
  // eslint-disable-next-line no-unused-vars
  onValueChanged: (value: number) => void;
}

const ThumbWheelWithButtons:
  React.FC<ThumbWheelProps> = (props: ThumbWheelProps): React.ReactElement => {
    const {
      thumbWheelValue, label, readonly, onValueChanged,
    } = props;

    return (
      <div className="thumbwheelwithbuttons">
        <div className="thumbwheelwithbuttons-label">
          {label}
        </div>
        <div className="thumbwheelwithbuttons-button">
          <ThumbWheelButton
            direction={ThumbWheelButtonDirection.Up}
            onButtonClick={() => {
              const newValue = thumbWheelValue === 9 ? 0 : thumbWheelValue + 1;
              onValueChanged(newValue);
            }}
            readonly={readonly}
          />
        </div>
        <div className="thumbwheelwithbuttons-thumbwheel">
          <ThumbWheel thumbWheelValue={thumbWheelValue} />
        </div>
        <div className="thumbwheelwithbuttons-button">
          <ThumbWheelButton
            direction={ThumbWheelButtonDirection.Down}
            onButtonClick={() => {
              const newValue = thumbWheelValue === 0 ? 9 : thumbWheelValue - 1;
              onValueChanged(newValue);
            }}
            readonly={readonly}
          />
        </div>
      </div>
    );
  };

export default ThumbWheelWithButtons;
