/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { IdentRef } from '@gpt/commons';
import { ExecutionState } from '../common';
import {
  LINEARIZATION_DATASET__INIT_LINEARIZATION_CONTROL,
  LINEARIZATION_DATASET__INIT_DATASET,
  LINEARIZATION_DATASET__SET_POINT_COUNTER,
  LINEARIZATION_DATASET__SET_COLDPOINT,
  LINEARIZATION_DATASET__SET_POINT_X,
  LINEARIZATION_DATASET__SET_POINT_Y,
  InitLinearizationDatasetActionPayload,
  SetPointXYActionPayload,
  typeLinearizationDatasetActionTypes,
  LINEARIZATION_DATASET__SET_EXECUTION_STATE,
  LINEARIZATION_DATASET__SAVE_TO_DATASET,
  LINEARIZATION_DATASET__SET_USER_POINTS,
  LinearizationTablePoint,
  LINEARIZATION_DATASET__MAX_REGRESSION_ERROR,
  LINEARIZATION_DATASET__SET_CALCULATION_STATUS,
  UpdateLinearizationDatasetActionPayload,
  LINEARIZATION_DATASET__UPDATE_DATASET,
  LinTableMessage,
  SplineCalculationStatus,
  LINEARIZATION_DATASET__SET_SPLINE_MESSAGE,
  LINEARIZATION_DATASET__SHOW_SPLINE_MESSAGE,
  LINEARIZATION_DATASET__SHOW_LINEARIZATION_MESSAGE,
  LINEARIZATION_DATASET__SET_LINEARIZATION_MESSAGE,
} from './types';

export const setLinearizationState = (payload: ExecutionState)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SET_EXECUTION_STATE,
  payload,
});

export const initLinearizationControl = (deviceInstanceId: string, controlIdent: IdentRef)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__INIT_LINEARIZATION_CONTROL,
  payload: {
    controlIdent,
    deviceInstanceId,
  },
});

export const saveLinearizationToDataset = (deviceInstanceId: string, controlIdent: IdentRef)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SAVE_TO_DATASET,
  payload: {
    controlIdent,
    deviceInstanceId,
  },
});

export const initLinearizationDataset = (payload: InitLinearizationDatasetActionPayload)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__INIT_DATASET,
  payload,
});

export const setLinearizationUserPoints = (payload: LinearizationTablePoint[])
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SET_USER_POINTS,
  payload,
});

export const setLinearizationDatasetPointCounter = (payload: number)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SET_POINT_COUNTER,
  payload,
});

export const setLinearizationDatasetKaltstelle = (payload: number)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SET_COLDPOINT,
  payload,
});

export const setLinearizationDatasetPointX = (payload: SetPointXYActionPayload)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SET_POINT_X,
  payload,
});

export const setLinearizationDatasetPointY = (payload: SetPointXYActionPayload)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SET_POINT_Y,
  payload,
});

export const setLinearizationMaxRegressionError = (payload: number)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__MAX_REGRESSION_ERROR,
  payload,
});

export const setSplineCalculationStatus = (payload: SplineCalculationStatus)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SET_CALCULATION_STATUS,
  payload,
});

export const updateLinearizationDataset = (payload: UpdateLinearizationDatasetActionPayload)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__UPDATE_DATASET,
  payload,
});

export const showLinearizationSplineMessage = (payload?: LinTableMessage)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SHOW_SPLINE_MESSAGE,
  payload,
});

export const setLinearizationSplineMessage = (payload?: LinTableMessage)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SET_SPLINE_MESSAGE,
  payload,
});

export const showLinearizationMessage = (payload?: LinTableMessage)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SHOW_LINEARIZATION_MESSAGE,
  payload,
});

export const setLinearizationMessage = (payload?: LinTableMessage)
  : typeLinearizationDatasetActionTypes => ({
  type: LINEARIZATION_DATASET__SET_LINEARIZATION_MESSAGE,
  payload,
});
