/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */

import { ExportLocalFileResult, isAbortError } from '@gpt/cxe-dp-integration';
import React, { ReactElement, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import AppFileSystemService from '../../services/AppService/AppFileSystemService';
import { showApplicationMessage } from '../../store/applicationMessage/actions';
import './FileSystemSaveControl.scss';

const Console = console;

/**
 * Options for saving files both with file picker dialog with FileSaveControl component.
 */
interface ButtonControlProps {
  block?: boolean;
  disabled?: boolean;
  variant: 'primary' | 'secondary' | 'tertiary';
}

/**
 * Options for saving files both with file picker dialog with FileSaveControl component.
 */

interface FileSystemSaveProps {
   /**
   * Caption
   */
  caption: string;

  /**
   * Button configuration
   */
  button?: ButtonControlProps;

  /**
   * Custom class name for button
   */
  customClassName?: string;

  /**
   * Suggested filename in file-save-dialog
   */
  fileName: string;

  /**
   * Output file mime type
   */
  mimeType: string;

  /**
   * Output file extensions
   */
  extensions?: string[];

  /**
   * Function to asynchronously provide the data for contents of the file to be saved.
   */
  getContents: () => Promise<string> | Promise<ArrayBuffer>;

  /**
   * Data save successfully
   */
  onDone?: (result: ExportLocalFileResult) => void;
}

/**
 * Component to save files - display a file picker dialog when available, otherwise directly
 * saves files in "downloads" directory without showing a dialog to change directory or file name.
 */
const FileSystemSaveControl:
React.FC<FileSystemSaveProps> = (props: FileSystemSaveProps): ReactElement => {
  const {
    caption, button, customClassName, fileName, mimeType, extensions, getContents, onDone,
  } = props;
  const dispatch = useDispatch();
  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (button?.variant === 'primary') {
      if (btnRef.current) {
        btnRef.current.focus();
      }
    }
  }, [button]);

  return (
    <Button
      // autoFocus={button?.variant === 'primary'}
      className={customClassName}
      block={button?.block}
      variant={button?.variant ?? 'secondary'}
      disabled={button?.disabled}
      ref={btnRef}
      onClick={async () => {
        btnRef.current?.blur();
        try {
          const result = await AppFileSystemService.ExportLocalFile({
            suggestedName: fileName,
            contents: getContents(),
            mimeType,
            extensions,
          });
          dispatch(showApplicationMessage('success', 'SAVE_PARAMETER_FILE__SAVE_DATA__SUCCESS'));
          onDone?.(result);
        } catch (error) {
          if (isAbortError(error)) {
            dispatch(showApplicationMessage('warning', 'SAVE_PARAMETER_FILE__SAVE_DATA__ERROR__CANCELLED'));
          } else {
            dispatch(showApplicationMessage('error', 'SAVE_PARAMETER_FILE__SAVE_DATA__FAIL'));
          }
        }
      }}
    >
      {caption}
    </Button>
  );
};
export default FileSystemSaveControl;
