/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { toFixedValue } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import DeviceEnumeratorEditor from './DeviceEnumeratorEditor/DeviceEnumeratorEditor';
import { useContextDatasetStatusValue } from '../../../../../hooks/useContextDataset';
import { updateDeviceDatasetValues } from '../../../../../store/deviceInstances/store/deviceDataset';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { MethodStageExecutionStatus } from '../../../../../store/deviceInstances/store/deviceMethod/types';
import useIoLinkWriteParameter from '../../../../IoLink/hooks/useIoLinkWriteParameter';
import { createChannelVariables } from '../moduleVariables';
import ChannelCurrentChart from './ChannelCurrentChart/ChannelCurrentChart';
import DeviceRotarySwitch, { ROTARY_SWITCH_MODE__NORC } from './DeviceRotarySwitch/DeviceRotarySwitch';
import './ChannelCurrentEditor.scss';

const currentActionIdent = (value: number) => `CAPAROC_MODULE_CHANNEL_CURRENT_${value}`;

const channelStartIndex = 3343; // -1
const channel1NominalCurrentSubIndex = 2;

export interface CaparocChannelCurrentEditorProps {
  deviceInstanceId: string;
  label: string;
  disabled: boolean;
  channelNo: number;
}

const CaparocChannelCurrentEditor: React.FC<CaparocChannelCurrentEditorProps> = (props: CaparocChannelCurrentEditorProps):
  React.ReactElement => {
  const {
    deviceInstanceId,
    disabled,
    channelNo,
    label,
  } = props;

  const dispatch = useDispatch();
  const actionId = currentActionIdent(channelNo);
  const channelVariables = createChannelVariables(channelNo);
  const [executionStage, writeIoLinkParameter] = useIoLinkWriteParameter(deviceInstanceId, actionId);

  const nominalCurrentValue = useContextDatasetStatusValue(deviceInstanceId, channelVariables.nominalCurrent, DatasetType.device);
  const actualCurrentValue = useContextDatasetStatusValue(deviceInstanceId, channelVariables.actualCurrent, DatasetType.device);

  const rotarySwitchValue = useContextDatasetStatusValue(deviceInstanceId, channelVariables.rotarySwitchMode, DatasetType.device);
  const channelMinCurrentValue = useContextDatasetStatusValue(deviceInstanceId, channelVariables.minCurrent, DatasetType.device);
  const channelMaxCurrentValue = useContextDatasetStatusValue(deviceInstanceId, channelVariables.maxCurrent, DatasetType.device);

  const switchValue = rotarySwitchValue?.value ?? 0;
  const disableEnum = disabled || switchValue === ROTARY_SWITCH_MODE__NORC || executionStage === MethodStageExecutionStatus.InProgress;

  return (
    <div className="caparoc-channel-current-editor">
      <div className="caparoc-channel-current-editor__enumerator">
        <DeviceEnumeratorEditor
          label={label}
          unit="A"
          min={channelMinCurrentValue?.value ?? 0}
          max={channelMaxCurrentValue?.value ?? 0}
          value={nominalCurrentValue?.value ?? 0}
          readonly={disableEnum}
          onValueChange={(value) => {
            const currentIdentRef = `var_V_CB_Channel${channelNo}_02_TN_Channel_NominalCurrent`;
            dispatch(updateDeviceDatasetValues(deviceInstanceId, {
              targetDataset: DatasetType.device,
              values: [{
                value,
                identRef: currentIdentRef,
                backupValue: undefined,
              }],
            }));
            writeIoLinkParameter(channelStartIndex + channelNo, new Uint8Array([value]), channel1NominalCurrentSubIndex);
          }}
        />
      </div>
      <div className="caparoc-channel-current-editor__current-chart">
        <ChannelCurrentChart
          deviceInstanceId={deviceInstanceId}
          channelNo={channelNo}
        />
      </div>
      <div className="caparoc-channel-current-editor__current-value">
        <div>{toFixedValue(actualCurrentValue?.value ?? 0, 2)}</div>
      </div>
      <div className="caparoc-channel-current-editor__rotary-switch">
        <DeviceRotarySwitch
          rotarySwitchValue={rotarySwitchValue?.value ?? 0}
          nominalCurrentValue={nominalCurrentValue?.value ?? 0}
          maxCurrentValue={channelMaxCurrentValue?.value ?? 10}
        />
      </div>
    </div>
  );
};

export default CaparocChannelCurrentEditor;
