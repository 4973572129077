/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import './LayoutCardButton.scss';
import expandIcon from '../../../../../assets/icons/icon-expand.svg';
import useMenuPostion from '../../../../Charts/ChartCardControl/header/button/useMenuPosition';
import OverlayPortal from '../../../../../components/OverlayPortal/OverlayPortal';
import { LayoutCardActionSubmenu } from './LayoutCardActionSubmenu/LayoutCardActionSubmenu';

export interface LayoutCardButtonProps {
    // eslint-disable-next-line no-unused-vars
    onSubmenuAction: (action: 'open' | 'flash') => void,
}

export const LayoutCardButton: React.FC<LayoutCardButtonProps> = (props: LayoutCardButtonProps)
: React.ReactElement => {
  const { onSubmenuAction } = props;

  const menuPositionRef = useRef<HTMLDivElement>(null);
  const [showExpandMenu, setShowExpandMenu] = useState(false);
  const [menuXPosition, menuYPosition] = useMenuPostion(menuPositionRef);

  return (
    <div>
      <div
        className="contactron-layout-card__header-icon-container"
        ref={menuPositionRef}
        role="button"
        tabIndex={0}
        onClick={() => {
          setShowExpandMenu(!showExpandMenu);
        }}
        onKeyUp={() => {}}
      >
        <Image src={expandIcon} className="contactron-layout-card__header-icon-container-icon" />
      </div>
      {showExpandMenu && (
      <OverlayPortal>
        <div
          role="none"
          className="contactron-layout-card__header-overlay"
          onClick={() => {
            setShowExpandMenu(false);
          }}
        >
          <div
            className="contactron-layout-card__header-overlay-container"
            style={{
              position: 'absolute',
              top: menuYPosition,
              left: menuXPosition ? menuXPosition - 160 : menuXPosition,
            }}
          >
            <LayoutCardActionSubmenu
              onAction={(action) => {
                onSubmenuAction(action);
                setShowExpandMenu(false);
              }}
            />
          </div>
        </div>
      </OverlayPortal>
      )}
    </div>
  );
};
