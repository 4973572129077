/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import {
  DeviceModelStatus, IdentRef, Services,
} from '@gpt/commons';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector, deviceWizardUpdateButtons, disposeDevice } from '../../../store';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import { deviceWizardInitButtons } from '../../../store/deviceWizard';
import ReduxControl from '../../../controls/ReduxControl/ReduxControl';
import { closeCxeDeviceParameterizationApp } from '../../../services/clipxEngineerIntegrationService';
import CPFilePageDocumentation from '../../CreateParameterFile/CPFilePageDocumentation/CPFilePageDocumentation';
import { createDatasetIdent } from '../../../helpers/functions';
import { LoadingControl } from '../../../components/LoadingControl';
import { compareTabData, MenuItemData, menuItemsSelector } from '../../../controls/selectors/menuItemsSelector';
import { DatasetContext } from '../../../views/DatasetContext';
import WizardViewPage from '../../WizardView/WizardViewPage';
import WizardView from '../../WizardView/WizardView';
import { DatasetState } from '../../../store/deviceInstances/store/deviceDataset/types';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { WizardProps } from '../../types';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';
import createDeviceDatasetBackupContent from '../../../helpers/createDeviceDatasetBackupContent';
import { WebWorkerContext } from '../../../WebWorkerContext';
import useWizardPageState from '../hooks/useWizardPageState';

export const GetWizardType = (state?: DatasetState): DeviceModelStatus.Wizards.CxeFirmwareParameterType | undefined => {
  const wizardDesc = state?.descriptors?.WIZARD_CXE_FIRMWARE_PARAMETER;

  if (wizardDesc?.type !== DeviceModelStatus.StatusType.WizardDescriptor) {
    return undefined;
  }

  if (wizardDesc.wizardType.type !== DeviceModelStatus.Wizards.WizardType.CXE_FIRMWARE_PARAMETER) {
    return undefined;
  }

  return wizardDesc.wizardType;
};

const GetWizardMenu = (state?: DatasetState): IdentRef | undefined => {
  const wizardType = GetWizardType(state);
  return wizardType?.menu;
};

const GetWizardPreviewMenu = (state?: DatasetState): IdentRef | undefined => {
  const wizardType = GetWizardType(state);
  return wizardType?.preview;
};

const EditParameterFileCxeWizard: React.FC<WizardProps> = (props: WizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deviceInstanceId } = props;
  const { targetDataset } = useContext(DatasetContext);
  const [pageStates, setPageState] = useWizardPageState();

  const { webWorkerDeviceManager } = useContext(WebWorkerContext);
  const activeDeviceInstance = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId));
  const previewMenu = useTypedSelector((state) => GetWizardPreviewMenu(deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)));

  const wizardData: MenuItemData[] = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const rootMenuIdent = GetWizardMenu(deviceDataset);
    return menuItemsSelector(deviceDataset, rootMenuIdent);
  }, (p, c) => compareTabData(p, c));

  useEffect(() => {
    dispatch(deviceWizardInitButtons({
      abort: ButtonState.enabled,
      back: ButtonState.disabled,
      next: ButtonState.enabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.next,
    }));
  }, []);

  const pages = wizardData
    .map((element, index) => (
      <WizardViewPage
        key={`wiz-cxe-edit-page--${element.identRef}`}
        title={t<string>(element.label)}
        state={pageStates[element.identRef]}
        onChangeToNextPage={() => {
          const isLastDevicePage = (index === wizardData.length - 1);
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: isLastDevicePage ? ButtonState.hidden : ButtonState.enabled,
            complete: isLastDevicePage ? ButtonState.enabled : ButtonState.hidden,
            pcta: isLastDevicePage ? ButtonRole.complete : ButtonRole.next,
          }));
        }}
        onChangeToPreviousPage={() => {
          const isFirstDevicePage = (index === 1);
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: isFirstDevicePage ? ButtonState.disabled : ButtonState.enabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
      >
        <ReduxControl
          identRef={element.identRef}
          deviceInstanceId={deviceInstanceId}
          onStateChanged={(ident, pagestate) => setPageState(ident, pagestate)}
        />
      </WizardViewPage>
    ));

  if (activeDeviceInstance?.device === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  return (
    <WizardView
      title={t<string>('WIZARD_CREATE_PARAMETER_FILE__TITLE')}
      onWizardExit={async (mode: ButtonRole) => {
        if (mode === ButtonRole.abort) {
          closeCxeDeviceParameterizationApp({
            result: 'cancelled',
          });
        } else {
          const parameterDataSet = await createDeviceDatasetBackupContent({
            caption: 'Caption',
            datasetId: createDatasetIdent('parameterization'),
            activeDevice: activeDeviceInstance,
            datasetType: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType.FIRMWARE,
            previewMenu,
            webWorkerDeviceManager,
          });

          closeCxeDeviceParameterizationApp({
            result: 'success',
            parameterDataSet,
          });
        }
        dispatch(disposeDevice(deviceInstanceId));
      }}
    >
      {pages}
      <CPFilePageDocumentation
        title={t<string>('WIZARD_CREATE_PARAMETER_FILE__FINAL_PAGE__TITLE')}
        previewMenu={previewMenu}
        deviceInstanceId={deviceInstanceId}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
      />
    </WizardView>
  );
};

export default EditParameterFileCxeWizard;
