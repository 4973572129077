/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceInformation, WebDevice } from '@gpt/commons';
import { Action } from 'redux';

export const DEVICE_SIMULATION__SET_SIMULATED_DEVICE_COUNT = 'DEVICE_SIMULATION__SET_SIMULATED_DEVICE_COUNT';

export const DEVICE_SIMULATION__INC_SIMULATED_DEVICE_COUNT = 'DEVICE_SIMULATION__INC_SIMULATED_DEVICE_COUNT';
export const DEVICE_SIMULATION__DEC_SIMULATED_DEVICE_COUNT = 'DEVICE_SIMULATION__DEC_SIMULATED_DEVICE_COUNT';

export const DEVICE_SIMULATION__LOAD_CONFIG = 'DEVICE_SIMULATION__LOAD_CONFIG';
export const DEVICE_SIMULATION__SAVE_CONFIG = 'DEVICE_SIMULATION__SAVE_CONFIG';

export interface SimulatedDeviceList {
  [key: string]: DeviceInformation[];
}

export interface SimulatedDeviceCount {
  info: WebDevice.DeviceCatalogInformation;
  count: number;
}

export interface SimulatedDevicesCount {
  [key: string]: SimulatedDeviceCount;
}

export interface ConfigDeviceCount {
  [key: string]: number;
}

/* ************************************************************************************ */
/* INTERFACE                                                                            */
/* ************************************************************************************ */
export interface DeviceSimulationState {
  enabled: boolean;
  simulatedDevices: SimulatedDeviceList;
}

/* ************************************************************************************ */
/* ACTION: UpdateSimulatedDeviceCountAction                                             */
/* ************************************************************************************ */
export interface IncSimulatedDeviceCountAction extends Action<typeof DEVICE_SIMULATION__INC_SIMULATED_DEVICE_COUNT> {
  payload: WebDevice.DeviceCatalogInformation;
}

export interface DecSimulatedDeviceCountAction extends Action<typeof DEVICE_SIMULATION__DEC_SIMULATED_DEVICE_COUNT> {
  payload: WebDevice.DeviceCatalogInformation;
}

/* ************************************************************************************ */
/* ACTION: SetSimulatedDeviceCountAction                                                */
/* ************************************************************************************ */
export interface SetSimulatedDeviceCountAction extends Action<typeof DEVICE_SIMULATION__SET_SIMULATED_DEVICE_COUNT> {
  payload: SimulatedDevicesCount;
}

/* ************************************************************************************ */
/* ACTION: SetSimulatedDeviceCountAction                                                */
/* ************************************************************************************ */
export type LoadDeviceSimulationConfigAction = Action<typeof DEVICE_SIMULATION__LOAD_CONFIG>

export interface SimulationConfig {
  devices: ConfigDeviceCount;
  enable: boolean;
}

export interface SaveDeviceSimulationConfigAction extends Action<typeof DEVICE_SIMULATION__SAVE_CONFIG> {
  payload: SimulationConfig;
}

export type typeDeviceSimulationActionTypes = SetSimulatedDeviceCountAction
 | IncSimulatedDeviceCountAction
 | DecSimulatedDeviceCountAction
 | LoadDeviceSimulationConfigAction
 | SaveDeviceSimulationConfigAction;
