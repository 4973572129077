/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import useDisplayStatusValue from '../../hooks/useDisplayStatusValue';
import { ReduxControlProps } from '../../ReduxControlProps';
import './VariableValueTableRow.scss';

const VariableValueTableRow: React.FC<ReduxControlProps> = (props: ReduxControlProps): React.ReactElement => {
  const { t } = useTranslation();
  const { identRef, deviceInstanceId } = props;

  const label = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.device);
    const descriptor = dataset?.descriptors[identRef];
    return descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor
      ? ''
      : descriptor.label;
  });
  const variableValue = useDisplayStatusValue(deviceInstanceId, identRef, DatasetType.device);

  return (
    <>
      <div className="variable-value-table__col__label">
        <div className="variable-value-table__col__label__text">
          {t(label)}
        </div>
      </div>
      <div className="variable-value-table__col__delimiter">
        <div className="variable-value-table__col__label__text">:</div>
      </div>
      <div className="variable-value-table__col__value">
        <div className="variable-value-table__col__value__text">
          {variableValue}
        </div>
      </div>
    </>
  );
};

export default VariableValueTableRow;
