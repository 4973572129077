/* eslint-disable max-len */

import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../../store';
import displayStatusValueSelector from '../../selectors/displayStatusValueSelector';

export interface CaparocChannelProps {
  moduleAddr: number;
  moduleChannelNo: number;
  channelNo: number;
  productName: string,
  errorStatus: number,
  actualCurrent: number,
  nominalCurrent: number,
  channelStatus: number,
}

export interface CaparocModule {
  channels: CaparocChannelProps[];
  addr: number;
}

export interface CaparocModuleProps {
  ch1: CaparocChannelProps | undefined;
  ch2: CaparocChannelProps | undefined;
  ch3: CaparocChannelProps | undefined;
  ch4: CaparocChannelProps | undefined;
  addr: number;
}

export interface ModuleCollection {
  [key: string]: CaparocModuleProps;
}

export interface CaparocSystemSettingsProps {
  switchOnDelay: number;
  switchOnDelayLabel: string;
  switchOnDelayUnit: string;
}

export interface CaparocSystemStatusProps {
  voltageError: number;
  voltageErrorLabel: string;
  voltageErrorEnumValue: string;
  cumulativeError: number;
  cumulativeErrorLabel: string;
  percent80: number;
  percent80Label: string;
  totalCurrentSys: number;
  totalCurrentSysLabel: string;
}

const moduleKey = (no: number): string => `CAPAROC_MODULE${no}`;

const default20ModuleProps: ModuleCollection = [...Array(20).keys()]
  .reduce((acc, va, index) => ({
    ...acc,
    [moduleKey(index + 1)]: {
      addr: 0,
      ch1: undefined,
      ch2: undefined,
      ch3: undefined,
      ch4: undefined,
    },
  }), {} as ModuleCollection);

export const useCaparocSystemSettings = (deviceInstanceId: string): CaparocSystemSettingsProps => {
  const deviceDataset = useTypedSelector((state) => state.deviceInstances.instances[deviceInstanceId].deviceDataset.device);

  const descriptorLabel = (descriptor: DeviceModelStatus.DeviceModelDescriptor): string => {
    if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return '';
    }

    return descriptor.label;
  };

  const descriptorUnit = (descriptor: DeviceModelStatus.DeviceModelDescriptor): string => {
    if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return '';
    }

    return descriptor.unit ?? '';
  };

  const sysStatusProps: CaparocSystemSettingsProps = {
    switchOnDelay: deviceDataset.values.var_PI_ProcessDataIn_TN_PD_VoltageError?.value ?? 0,
    switchOnDelayLabel: descriptorLabel(deviceDataset.descriptors.var_PI_ProcessDataIn_TN_PD_VoltageError),
    switchOnDelayUnit: descriptorUnit(deviceDataset.descriptors.var_PI_ProcessDataIn_TN_PD_VoltageError),
  };

  return sysStatusProps;
};

export const useCaparocSystemStatus = (deviceInstanceId: string): CaparocSystemStatusProps => {
  const deviceDataset = useTypedSelector((state) => state.deviceInstances.instances[deviceInstanceId].deviceDataset.device);

  const descriptorLabel = (descriptor: DeviceModelStatus.DeviceModelDescriptor): string => {
    if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return '';
    }

    return descriptor.label;
  };

  const sysStatusProps: CaparocSystemStatusProps = {
    voltageError: deviceDataset.values.var_PI_ProcessDataIn_TN_PD_VoltageError?.value ?? 0,
    voltageErrorLabel: descriptorLabel(deviceDataset.descriptors.var_PI_ProcessDataIn_TN_PD_VoltageError),
    voltageErrorEnumValue: displayStatusValueSelector(deviceDataset, 'var_PI_ProcessDataIn_TN_PD_VoltageError'),
    cumulativeError: deviceDataset.values.var_PI_ProcessDataIn_TN_PD_CumulativeError?.value ?? 0,
    cumulativeErrorLabel: descriptorLabel(deviceDataset.descriptors.var_PI_ProcessDataIn_TN_PD_CumulativeError),
    percent80: deviceDataset.values.var_PI_ProcessDataIn_TN_PD_80Percent?.value ?? 0,
    percent80Label: descriptorLabel(deviceDataset.descriptors.var_PI_ProcessDataIn_TN_PD_80Percent),
    totalCurrentSys: deviceDataset.values.var_PI_ProcessDataIn_TN_PD_TotalCurrentSystem?.value ?? 0,
    totalCurrentSysLabel: descriptorLabel(deviceDataset.descriptors.var_PI_ProcessDataIn_TN_PD_TotalCurrentSystem),
  };

  return sysStatusProps;
};

export const useCaparocStationModules = (deviceInstanceId: string): CaparocModule[] => {
  const deviceDataset = useTypedSelector((state) => state.deviceInstances.instances[deviceInstanceId].deviceDataset.device);
  const channelProps: CaparocChannelProps[] = [...Array(20).keys()].map((va, index) => ({
    productName: deviceDataset.values[`var_V_CB_Channel${index + 1}_11_TN_Channel_ProductName`]?.value ?? '',
    moduleAddr: deviceDataset.values[`var_V_CB_Channel${index + 1}_09_TN_Channel_ModuleAddress`]?.value ?? 0,
    moduleChannelNo: deviceDataset.values[`var_V_CB_Channel${index + 1}_10_TN_Channel_ChNrWithinTheModule`]?.value ?? 0,
    errorStatus: deviceDataset.values[`var_V_CB_Channel${index + 1}_05_TN_Channel_ErrorStatus`]?.value ?? 0,
    actualCurrent: deviceDataset.values[`var_V_CB_Channel${index + 1}_04_TN_Channel_ActualCurrent`]?.value ?? 0,
    actualCurrentIdentRef: `var_V_CB_Channel${index + 1}_04_TN_Channel_ActualCurrent`,
    nominalCurrent: deviceDataset.values[`var_V_CB_Channel${index + 1}_02_TN_Channel_NominalCurrent`]?.value ?? 0,
    channelStatus: deviceDataset.values[`var_V_CB_Channel${index + 1}_01_TN_Channel_Status`]?.value ?? 0,
    channelNo: index + 1,
  }));

  const moduleProps = channelProps.reduce((acc, channel) => {
    if (channel.moduleAddr === 0) {
      return acc;
    }
    const chaddr = moduleKey(channel.moduleAddr);
    return {
      ...acc,
      [chaddr]: {
        ...acc[chaddr],
        [`ch${channel.moduleChannelNo}`]: channel,
        addr: channel.moduleAddr,
      },
    };
  }, default20ModuleProps);

  const modules = Object.keys(moduleProps).reduce((acc, key) => {
    const module = moduleProps[key];

    if (module === undefined || module.addr === 0) {
      return acc;
    }

    let xchannels: CaparocChannelProps[] = [];

    if (module.ch1) {
      xchannels = [...xchannels, module.ch1];
    }

    if (module.ch2) {
      xchannels = [...xchannels, module.ch2];
    }

    if (module.ch3) {
      xchannels = [...xchannels, module.ch3];
    }

    if (module.ch4) {
      xchannels = [...xchannels, module.ch4];
    }

    return [
      ...acc,
      {
        addr: moduleProps[key].addr,
        channels: xchannels,
      }];
  }, [] as CaparocModule[]);

  return modules;
};
