/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setContextParameterHelp } from '../../store/contexthelp';
import './RadioEditorControl.css';

export interface RadioEditorControlProps {
  label: string;
  help?: string;
  value: boolean;
  id: string;
  group: string;
  readonly: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
}

const RadioEditorControl: React.FC<RadioEditorControlProps> = (props: RadioEditorControlProps): React.ReactElement => {
  const {
    label, value, onChange, id, readonly, group, help,
    onShowContentHelp, onHideContentHelp,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="parameter-radio-control" data-testid="radio-editor-control">
      <div className={`parameter-radio-control__container parameter-radio-control__container-${value}`}>
        <input
          className="parameter-radio-control__container__input"
          type="radio"
          name={group}
          readOnly={readonly}
          id={id}
          checked={value}
          onChange={(readonly) ? () => 0 : (e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
          onFocus={() => {
            dispatch(setContextParameterHelp({
              title: label ? t(label) : '',
              text: help ? t(help) : '',
            }));
            onShowContentHelp?.();
          }}
          onBlur={() => {
            dispatch(setContextParameterHelp({
              title: '',
              text: '',
            }));
            onHideContentHelp?.();
          }}
          data-testid="radio-editor-control-input"
        />
        <label className="parameter-radio-control__container__label" htmlFor={id}>
          <span />
          {label}
        </label>
      </div>
    </div>
  );
};

export default RadioEditorControl;
