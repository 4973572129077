/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { DeviceModelStatus } from '@gpt/commons';
import { ReduxControlProps } from '../../controls/ReduxControlProps';
import { ModalLinearizationDialog } from './ModalLinearization/LinearizationDialog';
import { initLinearizationControl, saveLinearizationToDataset } from '../../store/linearizationDataset/actions';
import { ParameterControl } from '../../components/ParameterEditor';
import { useTypedSelector } from '../../store';
import { UnsupportedControl } from '../../components/UnsupportedControl';
import { lintableErrorSelector, lintableModifiedSelector } from './LinearizationSelector';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import { DatasetContext } from '../../views/DatasetContext';
import withControlVisibility from '../../controls/hoc/withControlVisibility';

const LinearizationButtonControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    onStateChanged,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { targetDataset } = useContext(DatasetContext);
  const [showDialog, setShowDialog] = useState(false);

  const linear = useContextDatasetDescriptor(deviceInstanceId, identRef);
  const modified = useTypedSelector((state) => lintableModifiedSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const error = useTypedSelector((state) => lintableErrorSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));

  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, { modified, error });
  }, [modified, error, identRef]);

  if (linear?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="Only ControlDescriptor is supported" />;
  }
  const { controlType } = linear;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLLINEARIZATION) {
    return <UnsupportedControl text="Only CTLLINEARIZATION is supported" />;
  }

  let buttonVariant = 'secondary';
  if (error) {
    buttonVariant = 'invalid';
  } else if (modified) {
    buttonVariant = 'modified';
  }

  return (
    <>
      <ParameterControl label="">
        <Button
          className="button-text w-100"
          variant={buttonVariant}
          block
          onClick={() => {
            dispatch(initLinearizationControl(deviceInstanceId, identRef));
            setShowDialog(true);
          }}
        >
          {t<string>(controlType.buttonLabel)}
        </Button>
        <div className="menu-control--bottom-padding" />
      </ParameterControl>
      {showDialog && (
        <ModalLinearizationDialog
          label={t<string>(controlType.label)}
          onAccept={async (): Promise<void> => {
            dispatch(saveLinearizationToDataset(deviceInstanceId, identRef));
            setShowDialog(false);
          }}
          onCancel={async (): Promise<void> => {
            setShowDialog(false);
          }}
        />
      )}
    </>
  );
};

export default withControlVisibility(LinearizationButtonControl);
