/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import {
  DocumentType, DocumentLanguage, Report, IdentRef,
} from '@gpt/commons';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createReport } from '../../../store/reportService/actions';
// import { getAppUiLanguageCode } from '../../../i18n-config';
import { CTAButton } from '../../../components/CTAButton/CTAButton';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { getAppUiLanguageCode } from '../../../i18n-config';
import '../PageSaveProtocolComponent.scss';

export interface SaveProtocolButtonsProps {
  targetInstance: string,
  fileName: string;
  dictFamily: string;
  reportMenu?: IdentRef,
  disabled?: boolean;
  onGetContent: () => Promise<Report>;
}

const SaveProtocolButtonList:
React.FC<SaveProtocolButtonsProps> = (props: SaveProtocolButtonsProps): React.ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    fileName, onGetContent, disabled, reportMenu, dictFamily, targetInstance,
  } = props;

  const docLanguage: DocumentLanguage = DocumentLanguage[getAppUiLanguageCode().toUpperCase()];

  return (
    <Row className="page-save-protocol__buttons align-items-end">
      <Col xs={6} className="page-save-protocol__buttons__button form-group pt-2 pb-2 pl-0 pr-3">
        <CTAButton
          caption={t<string>('STANDARD_BUTTON__SAVE_DATA_AS_WORD')}
          variant="tertiary"
          block
          disabled={disabled ?? false}
          onClick={() => {
            onGetContent().then((reportData) => {
              const createReportAction = createReport({
                targetInstance,
                report: {
                  documentType: DocumentType.DOCX,
                  language: docLanguage,
                  reportMenu,
                  reportData,
                  reportFilename: fileName,
                  activeDataset: DatasetType.user,
                  compareDataset: DatasetType.device,
                  dictFamily: dictFamily,
                },
              });
              dispatch(createReportAction);
            }).catch(() => {
              console.log('Cannot create report');
            });
          }}
        />
      </Col>
      <Col xs={6} className="page-save-protocol__buttons__button form-group pt-2 pb-2 pl-3 pr-0">
        <CTAButton
          caption={t<string>('STANDARD_BUTTON__SAVE_DATA_AS_PDF')}
          variant="tertiary"
          block
          disabled={disabled ?? false}
          onClick={() => {
            onGetContent().then((reportData) => {
              const createReportAction = createReport({
                targetInstance,
                report: {
                  documentType: DocumentType.PDF,
                  language: docLanguage,
                  reportMenu,
                  reportData,
                  reportFilename: fileName,
                  activeDataset: DatasetType.user,
                  compareDataset: DatasetType.device,
                  dictFamily: dictFamily,
                },
              });
              dispatch(createReportAction);
            }).catch(() => {
              console.log('Cannot create report');
            });
          }}
        />
      </Col>
    </Row>
  );
};

export default SaveProtocolButtonList;
