/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: Electron application (Web runtime engine)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '../../components/LoadingControl/LoadingSpinner';
import splashscreenEn from '../../assets/splashscreens/splashscreen_en.png';
import splashscreenDe from '../../assets/splashscreens/splashscreen_de.png';
import splashscreenFr from '../../assets/splashscreens/splashscreen_fr.png';
import splashscreenEs from '../../assets/splashscreens/splashscreen_es.png';
import splashscreenIt from '../../assets/splashscreens/splashscreen_it.png';
import splashscreenPt from '../../assets/splashscreens/splashscreen_pt.png';
import splashscreenTr from '../../assets/splashscreens/splashscreen_tr.png';
import splashscreenRu from '../../assets/splashscreens/splashscreen_ru.png';
import splashscreenZh from '../../assets/splashscreens/splashscreen_zh.png';
import splashscreenPl from '../../assets/splashscreens/splashscreen_pl.png';
import splashscreenJa from '../../assets/splashscreens/splashscreen_ja.png';
import splashscreenCs from '../../assets/splashscreens/splashscreen_cs.png';
import splashscreenDa from '../../assets/splashscreens/splashscreen_da.png';
import splashscreenFi from '../../assets/splashscreens/splashscreen_fi.png';
import splashscreenHu from '../../assets/splashscreens/splashscreen_hu.png';
import splashscreenKo from '../../assets/splashscreens/splashscreen_ko.png';
import splashscreenNb from '../../assets/splashscreens/splashscreen_nb.png';
import splashscreenNl from '../../assets/splashscreens/splashscreen_nl.png';
import splashscreenRo from '../../assets/splashscreens/splashscreen_ro.png';
import splashscreenSv from '../../assets/splashscreens/splashscreen_sv.png';
import splashscreenUk from '../../assets/splashscreens/splashscreen_uk.png';
import { ENV_COPYRIGHT_YEAR } from '../../enviroment';
import './AppSplashScreen.scss';
import useAppVersion from '../../hooks/useAppVersion';

const images = {
  splashscreenEn,
  splashscreenDe,
  splashscreenFr,
  splashscreenEs,
  splashscreenIt,
  splashscreenPt,
  splashscreenTr,
  splashscreenRu,
  splashscreenZh,
  splashscreenPl,
  splashscreenJa,
  splashscreenCs,
  splashscreenDa,
  splashscreenFi,
  splashscreenHu,
  splashscreenKo,
  splashscreenNb,
  splashscreenNl,
  splashscreenRo,
  splashscreenSv,
  splashscreenUk,
};

const copyrightDiffLocLanguages = [
  'splashscreenEn',
  'splashscreenJa',
  'splashscreenKo',
  'splashscreenTr',
  'splashscreenZh',
];

const versionDiffLocLanguages = [
  'splashscreenEs',
  'splashscreenIt',
  'splashscreenJa',
  'splashscreenKo',
  'splashscreenPl',
  'splashscreenRu',
  'splashscreenUk',
  'splashscreenZh',
];

const AppSplashScreen: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const appSplashScreen = t('APPLICATION_SPLASHSCREEN');
  const classCopyrightPos = copyrightDiffLocLanguages.includes(appSplashScreen) ? 'app-splash-screen__copyright-diff' : 'app-splash-screen__copyright-norm';
  const classVersionPos = versionDiffLocLanguages.includes(appSplashScreen) ? 'app-splash-screen__version-diff' : 'app-splash-screen__version-norm';
  const appVersion = useAppVersion();

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100" data-testid="app-splash-screen">
      <div className="app-splash-screen">
        <img className="app-splash-screen__splash--image" src={images[appSplashScreen] ?? splashscreenEn} alt="Phoenix Contact Splash" />
        <h3 className={`app-splash-screen__version ${classVersionPos}`}>{`${t('VERSION')}: ${appVersion}`}</h3>
        <h5 className={`app-splash-screen__copyright ${classCopyrightPos}`}>{`© Phoenix Contact 2021 - ${ENV_COPYRIGHT_YEAR}`}</h5>
        <div className="app-splash-screen__splash-spinner">
          <LoadingSpinner size="xxl" />
        </div>
      </div>
    </div>
  );
};

export default AppSplashScreen;
