/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import withControlVisibility from '../../../../controls/hoc/withControlVisibility';
import { ReduxControlProps } from '../../../../controls/ReduxControlProps';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../../../hooks/useContextDataset';
import UnsupportedControl from '../../../UnsupportedControl/UnsupportedControl';
import CheckboxEditorControl from '../../../Basic/CheckboxEditorControl/CheckboxEditorControl';

export interface CheckboxReduxEditorProps extends ReduxControlProps {
    uniqueid?: string;
    // eslint-disable-next-line no-unused-vars
    onChange: (value: boolean) => void;
    onShowContentHelp?: () => void;
    onHideContentHelp?: () => void;
}

const CheckboxReduxEditorControl: React.FC<CheckboxReduxEditorProps> = (props: CheckboxReduxEditorProps): React.ReactElement => {
  const {
    identRef, uniqueid,
    onChange, onShowContentHelp, onHideContentHelp,
    onStateChanged, deviceInstanceId,
  } = props;

  const { t } = useTranslation();

  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  const datasetValue = useContextDatasetStatusValue(deviceInstanceId, identRef);

  const modified = datasetValue?.modified ?? false;

  useEffect(() => () => {
    onStateChanged(identRef, { modified: false, error: false });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, { modified, error: false });
  }, [modified, identRef]);

  if (descriptor === undefined || datasetValue === undefined) {
    return <UnsupportedControl text={`CheckboxReduxEditorControl: Undefined object ${identRef}`} />;
  }

  if (descriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CheckboxStructureEditor: Unsupported type" />;
  }

  return (
    <CheckboxEditorControl
      label={t<string>(descriptor.label)}
      help={t<string>(descriptor.help)}
      id={uniqueid ?? descriptor.identRef}
      readonly={descriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY}
      value={datasetValue.value}
      edited={modified}
      onChange={onChange}
      onShowContentHelp={onShowContentHelp}
      onHideContentHelp={onHideContentHelp}
    />
  );
};

export default withControlVisibility(CheckboxReduxEditorControl);
