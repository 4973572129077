/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deviceWizardUpdateButtons, useTypedSelector } from '../../store';
import { deviceMethodExecutionSelector } from '../../store/deviceInstances/store/deviceMethod/selectors';
import { MethodStageExecutionStatus } from '../../store/deviceInstances/store/deviceMethod/types';
import { ButtonState, ButtonRole } from '../../store/deviceWizard';

const useMethodExecutionStateStatus = (deviceInstanceId: string, methodId: string, active?: boolean) => {
  const stageStatus = useTypedSelector(
    (state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, methodId)?.stage ?? MethodStageExecutionStatus.Initialize,
  );
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (active !== true) {
        return;
      }

      switch (stageStatus) {
        case MethodStageExecutionStatus.Initialize:
        case MethodStageExecutionStatus.InProgress:
        // Download method is initializing
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.disabled,
            back: ButtonState.disabled,
            next: ButtonState.hidden,
            complete: ButtonState.disabled,
            pcta: ButtonRole.complete,
          }));
          break;
        case MethodStageExecutionStatus.DoneFailed:
        // Download method is failed
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: ButtonState.hidden,
            complete: ButtonState.disabled,
            pcta: ButtonRole.back,
            focusPcta: ButtonRole.back,
          }));
          break;
        case MethodStageExecutionStatus.DoneSuccess:
        // Download method is initializing
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.disabled,
            back: ButtonState.disabled,
            next: ButtonState.hidden,
            complete: ButtonState.enabled,
            pcta: ButtonRole.complete,
            focusPcta: ButtonRole.complete,
          }));
          break;
        default:
        // Download method is not initialized
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.disabled,
            back: ButtonState.disabled,
            next: ButtonState.hidden,
            complete: ButtonState.disabled,
            pcta: ButtonRole.complete,
          }));
      }
    },
    [stageStatus, active],
  );
  return stageStatus;
};

export default useMethodExecutionStateStatus;
