/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import BackstageLanguageSettings from './Settings/LanguageSettings';
import BackstageZoomSettings from './Settings/ZoomSettings';
import BackstageSimulationSettings from './Settings/SimulationSettings';

const BackstagePageSettings: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div>
      <h1>
        {`${t<string>('BACKSTAGE_PAGE_SETTINGS__TITLE')}`}
      </h1>
      <div>
        <BackstageLanguageSettings />
      </div>
      <div>
        <BackstageZoomSettings />
      </div>
      <div>
        <BackstageSimulationSettings />
      </div>
    </div>
  );
};

export default BackstagePageSettings;
