/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { ReduxControlProps } from '../../ReduxControlProps';
import withControlVisibility from '../../hoc/withControlVisibility';
import { useTypedSelector } from '../../../store';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { deviceDescriptorSelector } from '../../../store/deviceInstances/store/deviceDataset/selector';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';
import { SystemStatusCockpitCard } from './SystemStatusCockpitCard/SystemStatusCockpitCard';
import SystemDataCockpitCard from './SystemDataCockpitCard/SystemDataCockpitCard';
import SystemOperCockpitCard from './OperationalCard/SystemOperCockpitCard';
import SystemTrendCockpitCard from './SystemTrendCockpitCard/SystemTrendCockpitCard';
import './MotorStarterCockpit.scss';

const contactronMotorStaterSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
  targetDataset: DatasetType,
  identRef: IdentRef,
): DeviceModelStatus.UI.Contactron.CockpitMotorStarterControlType | undefined => {
  const descriptor = deviceDescriptorSelector(state, deviceInstanceId, targetDataset, identRef);
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  if (controlType?.type !== DeviceModelStatus.UI.ControlType.CTLCONTACTRON) {
    return undefined;
  }
  const { deviceViewType } = controlType;
  return deviceViewType.type === DeviceModelStatus.UI.Contactron.DeviceViewType.CTLCOCKPITMOTORSTARTER
    ? deviceViewType : undefined;
};

const ContactronMotorStarterCockpit: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const motorStaterControl = useTypedSelector((state) => contactronMotorStaterSelector(state.deviceInstances, deviceInstanceId, DatasetType.device, identRef));

  return (
    <div className="contactron-motorstarter-cockpit">
      <div className="contactron-motorstarter-cockpit-item contactron-motorstarter-cockpit__systemstatus">
        <SystemStatusCockpitCard statusView={motorStaterControl?.statusView} deviceInstanceId={deviceInstanceId} />
      </div>
      <div className="contactron-motorstarter-cockpit-item contactron-motorstarter-cockpit__systemdata">
        <SystemDataCockpitCard dataView={motorStaterControl?.dataView} deviceInstanceId={deviceInstanceId} />
      </div>
      <div className="contactron-motorstarter-cockpit-item contactron-motorstarter-cockpit__operation">
        <SystemOperCockpitCard operView={motorStaterControl?.operationView} deviceInstanceId={deviceInstanceId} />
      </div>
      <div className="contactron-motorstarter-cockpit-item contactron-motorstarter-cockpit__trend">
        <SystemTrendCockpitCard trendView={motorStaterControl?.trendView} deviceInstanceId={deviceInstanceId} />
      </div>
    </div>
  );
};

export default withControlVisibility(ContactronMotorStarterCockpit);
