/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useCallback, useEffect } from 'react';
import { IdentRef, DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store';
import { setContextMenuHelp } from '../../../store/contexthelp';
import { DeviceParameterViewState, setCurrentTab } from '../../../store/deviceInstances/store/controlStateCollection';
import { deviceControlStateCollectionSelector } from '../../../store/deviceInstances/store/controlStateCollection/selectors';

export interface TabData {
  identRef: IdentRef;
  label: DeviceModelStatus.LocString;
  help: DeviceModelStatus.LocString;
}

const useMenuCurrentTab = (
  actionIdentRef: string,
  deviceInstanceId: string,
  tabData: TabData[],
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentTab = useTypedSelector(
    (state) => {
      const controlState = deviceControlStateCollectionSelector(state.deviceInstances, deviceInstanceId)?.[actionIdentRef];
      return controlState !== undefined ? (controlState as DeviceParameterViewState).currentTab ?? '' : '';
    },
  );

  useEffect(() => {
    if (currentTab === '') {
      dispatch(setCurrentTab(deviceInstanceId, {
        current: tabData[0]?.identRef ?? '',
        ident: actionIdentRef,
      }));
    } else {
      // Check if current tab exists, if not - goto 1st page
      const curTab = tabData.find((tab) => tab.identRef === currentTab);
      if (curTab === undefined) {
        dispatch(setCurrentTab(deviceInstanceId, {
          current: tabData[0]?.identRef ?? '',
          ident: actionIdentRef,
        }));
      }
    }
  }, [tabData, currentTab, actionIdentRef, deviceInstanceId]);

  useEffect(() => {
    if (tabData.length === 0) {
      return;
    }

    const currentElement = tabData.find((tab) => tab.identRef === currentTab);
    const element = currentElement ?? tabData[0];

    if (element.label !== '' && element.help !== '') {
      dispatch(setContextMenuHelp({
        title: t<string>(element.label),
        text: t<string>(element.help),
      }));
    }
  }, []);

  const setCurrentTabAct = useCallback((devInstanceId: string, actIdentRef: string, curTab: string) => {
    dispatch(setCurrentTab(devInstanceId, {
      current: curTab,
      ident: actIdentRef,
    }));
  }, []);

  return [currentTab, setCurrentTabAct] as const;
};

export default useMenuCurrentTab;
