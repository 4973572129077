/* eslint-disable max-len */
import { DeviceModelStatus, Services } from '@gpt/commons';
import demoDevice from './DeviceInformation';
import deviceModelDescriptorCollection from './deviceModel/deviceModelDescriptions';
import deviceModelStatusValueCollection from './deviceModel/deviceModelStatusVector';

export const createFakeExternalDataset = (
  datasetId: Services.DeviceModelServer.DatasetProviderService.DatasetIdent,
  profileName: string,
  caption: string,
  saveDateTime: string,
): Services.DeviceModelServer.DatasetProviderService.ExternalDataset => ({
  version: Services.DeviceModelServer.DatasetProviderService.EXTERNAL_DATASET__VERSION,
  header: {
    datasetId,
    saveDateTime: new Date(saveDateTime),
    device: demoDevice,
    previewMenu: (deviceModelDescriptorCollection.APP_REVIEWDEVICE as DeviceModelStatus.ApplicationDescriptor).appType.menu,
    userComment: '',
    caption,
  },
  values: {
    ...deviceModelStatusValueCollection,
    [deviceModelStatusValueCollection.IFSM_ADDR_IO_R_SENSOR_VOLTAGE.identRef]: {
      ...deviceModelStatusValueCollection.IFSM_ADDR_IO_R_SENSOR_VOLTAGE,
      value: 1000,
    },
    [deviceModelStatusValueCollection.IFSM_ADDR_IO_R_SENSOR_CURRENT.identRef]: {
      ...deviceModelStatusValueCollection.IFSM_ADDR_IO_R_SENSOR_CURRENT,
      value: 15,
    },
    [deviceModelStatusValueCollection.DEVICE_POWER_STATUS.identRef]: {
      ...deviceModelStatusValueCollection.DEVICE_POWER_STATUS,
      value: 1,
    },
    [deviceModelStatusValueCollection.DEVICE_STATUS.identRef]: {
      ...deviceModelStatusValueCollection.DEVICE_STATUS,
      value: 3,
    },
    [deviceModelStatusValueCollection.temperature_unit.identRef]: {
      ...deviceModelStatusValueCollection.temperature_unit,
      value: deviceModelStatusValueCollection.temperature_unit.value === 0 ? 1 : 0,
    },
    [deviceModelStatusValueCollection.user_message.identRef]: {
      ...deviceModelStatusValueCollection.user_message,
      value: 'new text',
    },
    [deviceModelStatusValueCollection.faultsignal_overrange.identRef]: {
      ...deviceModelStatusValueCollection.faultsignal_overrange,
      value: deviceModelStatusValueCollection.faultsignal_overrange.value === 0 ? 1 : 0,
    },
    [deviceModelStatusValueCollection.faultsignal_underrange.identRef]: {
      ...deviceModelStatusValueCollection.faultsignal_underrange,
      value: deviceModelStatusValueCollection.faultsignal_underrange.value === 0 ? 1 : 0,
    },
  },
  descriptors: {
    ...deviceModelDescriptorCollection,
  },
});

export const A = 0;
