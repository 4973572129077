/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { toFixedValue } from '@gpt/commons';
import { createChannelVariables } from '../../moduleVariables';
import { useContextDatasetStatusValue } from '../../../../../../hooks/useContextDataset';
import { DatasetType } from '../../../../../../store/deviceInstances/store/deviceDataset/types';
import { HBarElement } from '../../../../../Charts/Elements/HBarElement/HBarElement';
import './ChannelCurrentChart.scss';

export interface ChannelCurrentChartProps {
    deviceInstanceId: string;
    channelNo: number;
  }

const ChannelCurrentChart: React.FC<ChannelCurrentChartProps> = (props: ChannelCurrentChartProps)
:React.ReactElement => {
  const {
    deviceInstanceId,
    channelNo,
  } = props;

  const channelVariables = createChannelVariables(channelNo);

  const nominalStatusValue = useContextDatasetStatusValue(deviceInstanceId, channelVariables.nominalCurrent, DatasetType.device);

  const actualStatusValue = useContextDatasetStatusValue(deviceInstanceId, channelVariables.actualCurrent, DatasetType.device);
  const actualMinStatusValue = useContextDatasetStatusValue(deviceInstanceId, channelVariables.actualCurrentMin, DatasetType.device);
  const actualMaxStatusValue = useContextDatasetStatusValue(deviceInstanceId, channelVariables.actualCurrentMax, DatasetType.device);

  const channel80nominal = (nominalStatusValue?.value ?? 0) * 0.8;
  const channel110nominal = (nominalStatusValue?.value ?? 0) * 1.1;
  const channel150nominal = (nominalStatusValue?.value ?? 0) * 1.5;

  return (
    <div className="caparoc-channel-current-chart">
      <HBarElement
        minValue={0}
        maxValue={channel150nominal}
        currentValue={actualStatusValue?.value ?? 0}
        segments={[
          {
            minValue: 0,
            maxValue: channel80nominal,
            color: 'green',
          },
          {
            minValue: channel80nominal,
            maxValue: channel110nominal,
            color: 'yellow',
          },
          {
            minValue: channel110nominal,
            maxValue: channel150nominal,
            color: 'red',
          },
        ]}
        marks={[
          {
            value: 0,
            text: '0.00',
          },
          {
            value: channel80nominal,
            text: toFixedValue(channel80nominal, 2),
          },
          {
            value: channel110nominal,
            text: toFixedValue(channel110nominal, 2),
          },
          {
            value: channel150nominal,
            text: toFixedValue(channel150nominal, 2),
          },
        ]}
        dragMinValue={actualMinStatusValue?.value}
        dragMaxValue={actualMaxStatusValue?.value}
      />
    </div>
  );
};

export default ChannelCurrentChart;
