/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { WebDevice } from '@gpt/commons';
import Navigation from '../Navigation/Navigation';
import { NavigationButtonProps } from '../NavigationButton';
import StationNavigation from '../StationNavigation/StationNavigation';
import { useTypedSelector } from '../../store';
import { ROOT_DEVICEINSTANCE_ID } from '../../helpers/createDeviceInstanceId';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import './NavigationBar.css';

const isStationSelector = (deviceInstances: DeviceInstancesState): boolean => {
  const rootDeviceInstance = deviceInstances.instances[ROOT_DEVICEINSTANCE_ID];
  const device = rootDeviceInstance?.activeDevice.modelInstance?.device;
  return device?.catalog.species === WebDevice.Species.station;
};

export interface NavigationBarProps {
  buttons: Array<NavigationButtonProps>;
  onStartClick: () => void;
}

const NavigationBar: React.FC<NavigationBarProps> = (props: NavigationBarProps):
React.ReactElement => {
  const {
    onStartClick, buttons,
  } = props;

  const isStation = useTypedSelector((state) => isStationSelector(state.deviceInstances));

  const stationDropDown = isStation ? (
    <div className="navigationbar-container__station">
      <StationNavigation stationInstanceId="ROOT_DEVICEINSTANCE_ID" />
    </div>
  ) : undefined;

  return (
    <div className={`navigationbar-container navigationbar-container${isStation ? '' : '--no-station'}`}>
      {stationDropDown}
      <div className="navigationbar-container__nav">
        <Navigation
          buttons={buttons}
          onStartClick={onStartClick}
        />
      </div>
    </div>
  );
};

export default NavigationBar;
