/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  Middleware, MiddlewareAPI, Dispatch,
} from 'redux';
import { Services, WebDevice } from '@gpt/commons';
import { v4 as uuidv4 } from 'uuid';
import { IWebWorkerDeviceManager } from '../../../../../services/WebWorkerDevice/WebWorkerDeviceManager';
import {
  typeIoLinkSystemCommandMiddlewareActions,
} from './types';
import { DatasetType } from '../../../store/deviceDataset/types';
import { ioLinkSetSystemCommandState } from '../../../store/ioLinkSystemCommand/actions';
import { IoLinkSystemCommandStage } from '../../../store/ioLinkSystemCommand/types';
import { deviceInstancesStoreSelector } from '../../../../reduxStoreSelector';

const createIoLinkExecuteCommandMethod = (deviceInstanceId: string, command: number)
: WebDevice.WebDeviceExecuteMethodRequest => {
  const header: Services.DeviceModel.MethodHeader = {
    command: `${command}`,
  };

  return {
    kind: 'WEBDEVICE__EXECUTE_METHOD__REQUEST',
    requestId: uuidv4(),
    methodIdent: 'ioLinkExecuteCommand',
    deviceInstanceId,
    header,
    request: {
      type: 'WEBDEVICE__METHOD_EXECUTE__INIT',
      data: [],
    },
  };
};

const ioLinkSystemCommandMiddleware = (webWorkerDeviceManager: IWebWorkerDeviceManager): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeIoLinkSystemCommandMiddlewareActions>(action: A): Promise<A> => {
  switch (action.type) {
    case 'IOLINK__ACTION__EXECUTE_SYSTEM_COMMAND': {
      const { payload } = action;

      const {
        command,
        targetInstance,
      } = payload;

      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const webWorkerInstance = deviceInstances.instances[targetInstance]?.activeDevice.modelInstance?.webWorkerInstanceRef;
      if (webWorkerInstance === undefined) {
        break;
      }

      const webWorkerDevice = webWorkerDeviceManager.get(webWorkerInstance);
      if (webWorkerDevice === undefined) {
        api.dispatch(ioLinkSetSystemCommandState(targetInstance, {
          stage: IoLinkSystemCommandStage.stageDoneFailed,
          targetInstance,
        }));
        break;
      }

      api.dispatch(ioLinkSetSystemCommandState(targetInstance, {
        stage: IoLinkSystemCommandStage.stageCommandExecuting,
        targetInstance,
      }));

      const methodRequest = createIoLinkExecuteCommandMethod(targetInstance, command);
      const rsp = await webWorkerDevice.get(DatasetType.device)
        .executeMethod(methodRequest);

      const { response } = rsp;
      if (response.type === 'WEBDEVICE__METHOD_EXECUTE__DONE__SUCCESS') {
        // Initialize user dataset
        await webWorkerDevice.get(DatasetType.user).writeUploadValues(response.data ?? []);
        // Initialize init dataset
        await webWorkerDevice.get(DatasetType.init).writeUploadValues(response.data ?? []);
      }

      const state = response.type === 'WEBDEVICE__METHOD_EXECUTE__DONE__SUCCESS'
        ? IoLinkSystemCommandStage.stageDoneSuccess
        : IoLinkSystemCommandStage.stageDoneFailed;
      api.dispatch(ioLinkSetSystemCommandState(targetInstance, {
        stage: state,
        targetInstance,
      }));
    }
      break;
    default:
  }
  const result = next(action);
  return result;
};

export default ioLinkSystemCommandMiddleware;
