/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceInformation } from '@gpt/commons';
import { Discovery } from '@gpt/cxe-nats-communication';

export const DEVICE_CARD_LIST_ITEM__INTERFACE = 'DEVICE_CARD_LIST_ITEM__INTERFACE';

export const DEVICE_CARD_LIST_ITEM__DEVICE__UNIDENTIFIED = 'DEVICE_CARD_LIST_ITEM__DEVICE__UNIDENTIFIED';
export const DEVICE_CARD_LIST_ITEM__DEVICE__UNKNOWN = 'DEVICE_CARD_LIST_ITEM__DEVICE__UNKNOWN';
export const DEVICE_CARD_LIST_ITEM__DEVICE__KNOWN = 'DEVICE_CARD_LIST_ITEM__DEVICE__KNOWN';
export const DEVICE_CARD_LIST_ITEM__STATION = 'DEVICE_CARD_LIST_ITEM__STATION';

export const DEVICE_CARD_LIST_ITEM__ADAPTER = 'DEVICE_CARD_LIST_ITEM__ADAPTER';

export enum InterfaceCardType {
  // eslint-disable-next-line no-unused-vars
  LAN = 'LAN',
  // eslint-disable-next-line no-unused-vars
  USB = 'USB',
  // eslint-disable-next-line no-unused-vars
  BLUETOOTH = 'BLUETOOTH',
}

export interface DeviceCardListItemDeviceKnown {
  type: typeof DEVICE_CARD_LIST_ITEM__DEVICE__KNOWN,
  id: string;
  adapterId: string,
  device: DeviceInformation;
  timestamp: string;
}

export interface DeviceCardListItemDeviceUnidentified {
  type: typeof DEVICE_CARD_LIST_ITEM__DEVICE__UNIDENTIFIED,
  id: string;
  adapterId: string,
  adapterType: Discovery.CommunicationAdapterType;
  timestamp: string;
}

export interface DeviceCardListItemDeviceUnknown {
  type: typeof DEVICE_CARD_LIST_ITEM__DEVICE__UNKNOWN,
  id: string;
  adapterId: string,
  timestamp: string;
}

export interface DeviceCardListItemAdapter {
  type: typeof DEVICE_CARD_LIST_ITEM__ADAPTER,
  adapterId: string;
  adapterType: Discovery.CommunicationAdapterType;
  state: Discovery.AdapterState;
  timestamp: string;
}

export interface DeviceCardListItemInterface {
  type: typeof DEVICE_CARD_LIST_ITEM__INTERFACE,
  interfaceType: InterfaceCardType;
  timestamp?: string;
}

export interface DeviceCardListItemStation {
  type: typeof DEVICE_CARD_LIST_ITEM__STATION,
  id: string;
  adapterId: string,
  device: DeviceInformation;
  timestamp: string;
}

// eslint-disable-next-line max-len
export type DeviceCardListItem = DeviceCardListItemDeviceKnown
  | DeviceCardListItemDeviceUnidentified
  | DeviceCardListItemDeviceUnknown
  | DeviceCardListItemStation
  | DeviceCardListItemInterface
  | DeviceCardListItemAdapter;
