/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import { DeviceInstanceActionPayload } from '../../../common';
import { typeDeviceMethodStageMiddlewareActions } from '../types';

export interface sPortMinMCRWriteActionPayload extends DeviceInstanceActionPayload {
  actionId: string;
  memoryAddress: number;
  ui16value: number;
  password: string;
}

// eslint-disable-next-line import/prefer-default-export
export const sPortMinMCRWriteAction = (parameters: sPortMinMCRWriteActionPayload)
: typeDeviceMethodStageMiddlewareActions => {
  const {
    memoryAddress, targetInstance, actionId,
    ui16value, password,
  } = parameters;

  let header: Services.DeviceModel.MethodHeader = {
    address: `${memoryAddress}`,
    ui16value: `${ui16value}`,
    actionId,
  };

  if (password !== undefined) {
    header = {
      ...header,
      pin: password,
    };
  }

  return {
    type: 'METHOD_STAGE_EXECUTION__INIT',
    payload: {
      targetInstance,
      data: {
        header,
        methodIdent: 'methodMINIMCRWriteAction',
        methodStatusRef: actionId,
        values: [],
      },
    },
  };
};

// eslint-disable-next-line import/prefer-default-export
export const sPortMinMCRResetDeviceAction = (parameters: sPortMinMCRWriteActionPayload)
: typeDeviceMethodStageMiddlewareActions => {
  const {
    memoryAddress, targetInstance, actionId,
    ui16value, password,
  } = parameters;

  let header: Services.DeviceModel.MethodHeader = {
    address: `${memoryAddress}`,
    ui16value: `${ui16value}`,
    actionId,
  };

  if (password !== undefined) {
    header = {
      ...header,
      pin: password,
    };
  }

  return {
    type: 'METHOD_STAGE_EXECUTION__INIT',
    payload: {
      targetInstance,
      data: {
        header,
        methodIdent: 'methodMINIMCRResetDevice',
        methodStatusRef: actionId,
        values: [],
      },
    },
  };
};
