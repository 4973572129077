/* eslint-disable max-len */

export const gaugeWidth = 490;
export const gaugeHeight = 400;

export const gaugeCenterX = 245;
export const gaugeCenterY = 250;
export const gaugeRadius = 145;
export const gaugeStrokeWidth = 26;

export const gaugeMinAngle = -135;
export const gaugeMaxAngle = 135;

export const gaugeDisplayFormat = '%.2f';

const angleInRadians = (angleInDegrees: number)
    : number => ((angleInDegrees - 90) * Math.PI) / 180.0;

export interface Point {
  x: number;
  y: number;
}

export const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
): Point => {
  const angle = angleInRadians(angleInDegrees);
  return {
    x: centerX + (radius * Math.cos(angle)),
    y: centerY + (radius * Math.sin(angle)),
  };
};

// eslint-disable-next-line max-len
export const createArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number): string => {
  const start = polarToCartesian(x, y, radius, startAngle);
  const end = polarToCartesian(x, y, radius, endAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M', start.x, start.y,
    'A', radius, radius, 0, largeArcFlag, 1, end.x, end.y,
  ].join(' ');

  return d;
};

export const mapValueToAngle = (minAngle: number, maxAngle: number, minValue: number, maxValue: number, value: number): number => {
  const xAngle = ((value - minAngle) * (maxValue - minValue)) / (maxAngle - minAngle) + minValue;
  if (xAngle < minValue) return minValue;
  if (xAngle > maxValue) return maxValue;
  return xAngle;
};
