/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './CaparocGauges.css';
import { useTranslation } from 'react-i18next';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import { ROOT_DEVICEINSTANCE_ID } from '../../../../helpers/createDeviceInstanceId';
import AnalogGaugeRedux from '../../../AnalogGaugeRedux/AnalogGaugeRedux';

const CaparocGauges: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <PaperControl title={t<string>('CAPAROC_STATION_SYSTEM_DATA')}>
      <div className="caparoc-gauges-container">
        <AnalogGaugeRedux
          deviceInstanceId={ROOT_DEVICEINSTANCE_ID}
          identRef="ctrlCockpitInputVoltage"
          onStateChanged={() => 0}
        />
        <AnalogGaugeRedux
          deviceInstanceId={ROOT_DEVICEINSTANCE_ID}
          identRef="ctrlCockpitTotalOutputPower"
          onStateChanged={() => 0}
        />
      </div>
    </PaperControl>
  );
};

export default CaparocGauges;
