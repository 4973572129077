/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { IdentRef } from '@gpt/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './PreviewTableHeader.css';

export interface RecordValueType {
  identRef?: IdentRef;
  value: number;
  unit: string;
}

export interface Record {
    values: RecordValueType[],
}

export interface PreviewTableHeaderProps {
    records: Record[],
    identifier?: string,
}

export const PreviewTableHeader:React.FC<PreviewTableHeaderProps> = (props: PreviewTableHeaderProps): React.ReactElement => {
  const {
    records,
    identifier,
  } = props;

  const { t } = useTranslation();
  if (records[0] === undefined) {
    return <></>;
  }

  const headers = records[0].values.map((item) => t(item.unit));
  const headerControls = headers.map((item, index) => (
    <div
      className="tableControl-container-bottom-row-element"
      key={`${identifier}-tableControl-header-el-${index + 1}`}
    >
      <div className="tableControl-container-headers-element">
        {item}
      </div>
    </div>
  ));

  return (<>{headerControls}</>);
};
