/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeviceInstancesState } from './deviceInstances/types';
import { DeviceSimulationState } from './deviceSimulation/types';
import { LinearizationDatasetState } from './linearizationDataset/types';
import { SystemInformationState } from './systemInformation/types';
import { DiscoveryServiceState } from './discoveryServiceState';

export const deviceInstancesStoreSelector = (state: any)
  : DeviceInstancesState => state.deviceInstances as DeviceInstancesState;

export const deviceDiscoveryServiceSelector = (state: any)
  : DiscoveryServiceState => state.discoveryService as DiscoveryServiceState;

export const deviceSimulationStoreSelector = (state: any)
  : DeviceSimulationState => state.deviceSimulation as DeviceSimulationState;

export const linearizationDatasetStoreSelector = (state: any)
  : LinearizationDatasetState => state.linearizationDataset as LinearizationDatasetState;

export const systemInformationStoreSelector = (state: any)
  : SystemInformationState => state.systemInformation as SystemInformationState;
