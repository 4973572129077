/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { MessageBox, MessageBoxProps } from '../MessageBox/MessageBox';
import './MessageBoxContainer.css';

export interface MessageBoxContainerProps {
    messages: MessageBoxProps[],
}

export const MessageBoxContainer: React.FC<MessageBoxContainerProps> = (props: MessageBoxContainerProps): React.ReactElement => {
  const {
    messages,
  } = props;

  return (
    <div
      className="MessageBoxContainer__Container"
      data-testid="messagebox-container"
      style={{ height: '100%' }}
    >
      { messages.map((item) => (
        <MessageBox
          key={`messagebox-${item.id}`}
          id={item.id}
          type={item.type}
          message={item.message}
          showCloseButton={item.showCloseButton}
        />
      ))}
    </div>
  );
};
