import { DeviceModelStatus } from '@gpt/commons';

export interface InvalidDatasetValue {
  identRef: string;
  value: string | number;
  message?: string;
}

export const checkDatasetModified = (
  descriptors?: DeviceModelStatus.DeviceModelDescriptorCollection,
  values?: DeviceModelStatus.StatusValueCollection,
): string[] => {
  if (values === undefined || descriptors === undefined) {
    return [];
  }
  return Object
    .keys(values)
    .reduce((acc, key) => {
      if (!values[key].modified) {
        return acc;
      }
      const desc = descriptors[key];
      if (desc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
        return acc;
      }
      const { handling, validity } = desc;
      if (!validity || handling !== DeviceModelStatus.StatusDescriptorHandling.READ_WRITE) {
        return acc;
      }
      return [
        ...acc,
        key,
      ];
    }, [] as string[]);
};

export const getInvalidDatasetValues = (
  descriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
  values: DeviceModelStatus.StatusValueCollection,
): InvalidDatasetValue[] => {
  const invalid = Object
    .keys(values)
    .reduce((acc, key) => {
      const descriptor = descriptors[key];
      if (descriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
        return acc;
      }

      const { validity, handling } = descriptor;
      if (!validity || handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY) {
        return acc;
      }

      if (values[key].valueValidity === DeviceModelStatus.StatusValueValidity.valid) {
        return acc;
      }
      return [
        ...acc,
        {
          identRef: key,
          value: values[key].value,
          message: values[key].valueValidityDescription,
        },
      ];
    }, [] as InvalidDatasetValue[]);
  return invalid;
};

export const checkDatasetValid = (
  descriptors?: DeviceModelStatus.DeviceModelDescriptorCollection,
  values?: DeviceModelStatus.StatusValueCollection,
): InvalidDatasetValue[] => {
  if (descriptors === undefined || values === undefined) {
    return [];
  }
  return getInvalidDatasetValues(descriptors, values);
};
