/* eslint-disable max-len */

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cleanDeviceMethodExecutionState } from '../../../store/deviceInstances/store/deviceMethod/actions';
import { ioLinkWriteParameter } from '../../../store/deviceInstances/middleware/iolink/ioLinkWriteParameter/actions';
import { useTypedSelector } from '../../../store';
import { deviceMethodExecutionSelector } from '../../../store/deviceInstances/store/deviceMethod/selectors';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';

const useIoLinkWriteParameter = (deviceInstanceId: string, actionId: string) => {
  const dispatch = useDispatch();

  const mstate = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, actionId));
  const executionStage = mstate?.stage ?? MethodStageExecutionStatus.Initialize;

  useEffect(() => {
    dispatch(cleanDeviceMethodExecutionState(deviceInstanceId, actionId));
  }, [actionId, deviceInstanceId]);

  const writeParameter = useCallback((index: number, data: Uint8Array, subindex?: number) => {
    dispatch(ioLinkWriteParameter({
      actionId,
      index,
      subindex,
      data,
      targetInstance: deviceInstanceId,
    }));
  }, [deviceInstanceId, actionId]);

  return [executionStage, writeParameter] as const;
};

export default useIoLinkWriteParameter;
