/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useContext } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import {
  DROPDOWN__ITEMID0__NOT_SELECTED, DROPDOWN__ITEMID1__POWER_OUT, DROPDOWN__ITEMID2__VOLTAGE_OUT,
  DROPDOWN__ITEMID3__CURRENT_OUT, DROPDOWN__ITEMID4__TEMPERATURE, DROPDOWN__ITEMID5__VIN_OK,
  DROPDOWN__ITEMID6__RUNTIME, DROPDOWN__ITEMID7__OVPMAIN, updateDropDownOptions,
} from '../DropDownHelpers';
import Quint4DropDownBaseControl, { DropDownOptions } from '../../../Quint4DropDown/Quint4DropDownControl';
import { StructureItemStateChangeCallback } from '../../../../ReduxControlProps';
import { useTypedSelector } from '../../../../../store';
import { deviceTargetDatasetSelector } from '../../../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetContext } from '../../../../../views/DatasetContext';
import useStatusValueStateUpdate from '../../../hooks/useStatusValueStateUpdate';

export interface Quint4DigitalSelectlDropDownProps {
  identRef: string;
  deviceInstanceId: string;
  onValueChange: (value: number) => void;
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
  showContextMessageDef?: boolean;
  warning?: string;
  readonly?: boolean;
  onStateChanged: StructureItemStateChangeCallback,
  enableDropDownValue?: number;
}

const Quint4DigitalSelectlDropDown
  :React.FC<Quint4DigitalSelectlDropDownProps> = (props: Quint4DigitalSelectlDropDownProps):
    React.ReactElement => {
    const {
      identRef,
      deviceInstanceId,
      onStateChanged,
      warning,
      readonly,
      onShowContentHelp,
      onHideContentHelp,
      onValueChange,
      enableDropDownValue,
      showContextMessageDef,
    } = props;
    const { t } = useTranslation();

    const { targetDataset } = useContext(DatasetContext);
    const visibility = useStatusValueStateUpdate(identRef, deviceInstanceId, targetDataset, onStateChanged);

    const [statusValue, label, help] = useTypedSelector((state) => {
      const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
      const descriptor = dataset?.descriptors[identRef];
      const desc = descriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor
        ? descriptor
        : undefined;
      return [
        dataset?.values[identRef],
        desc?.label ?? '',
        desc?.help ?? '',
      ] as const;
    });

    if (visibility === false) {
      return <></>;
    }

    let dropDownOptions: DropDownOptions = {};
    if (enableDropDownValue === undefined) {
      dropDownOptions = {};
    } else if (enableDropDownValue === DROPDOWN__ITEMID1__POWER_OUT || enableDropDownValue === DROPDOWN__ITEMID3__CURRENT_OUT) {
      dropDownOptions = {
        [DROPDOWN__ITEMID0__NOT_SELECTED]: '---',
        [DROPDOWN__ITEMID2__VOLTAGE_OUT]: t('QUINT4_PS:locOutxInputInvVoutOptLbl'),
        [DROPDOWN__ITEMID4__TEMPERATURE]: t('QUINT4_PS:locOutxInputInvTempOptLbl'),
        [DROPDOWN__ITEMID5__VIN_OK]: t('QUINT4_PS:locOutxInputInvVinOkOptLbl'),
        [DROPDOWN__ITEMID6__RUNTIME]: t('QUINT4_PS:locOutxInputInvRunTimeOptLbl'),
        [DROPDOWN__ITEMID7__OVPMAIN]: t('QUINT4_PS:locOutxInputInvOVPMainOptLbl'),
      };
    } else {
      dropDownOptions = updateDropDownOptions(dropDownOptions, DROPDOWN__ITEMID0__NOT_SELECTED, '---', enableDropDownValue);
      dropDownOptions = updateDropDownOptions(dropDownOptions, DROPDOWN__ITEMID1__POWER_OUT, t('QUINT4_PS:locOutxInputInvPoutOptLbl'), enableDropDownValue);
      dropDownOptions = updateDropDownOptions(dropDownOptions, DROPDOWN__ITEMID2__VOLTAGE_OUT, t('QUINT4_PS:locOutxInputInvVoutOptLbl'), enableDropDownValue);
      dropDownOptions = updateDropDownOptions(dropDownOptions, DROPDOWN__ITEMID3__CURRENT_OUT, t('QUINT4_PS:locOutxInputInvIoutOptLbl'), enableDropDownValue);
      dropDownOptions = updateDropDownOptions(dropDownOptions, DROPDOWN__ITEMID4__TEMPERATURE, t('QUINT4_PS:locOutxInputInvTempOptLbl'), enableDropDownValue);
      dropDownOptions = updateDropDownOptions(dropDownOptions, DROPDOWN__ITEMID5__VIN_OK, t('QUINT4_PS:locOutxInputInvVinOkOptLbl'), enableDropDownValue);
      dropDownOptions = updateDropDownOptions(dropDownOptions, DROPDOWN__ITEMID6__RUNTIME, t('QUINT4_PS:locOutxInputInvRunTimeOptLbl'), enableDropDownValue);
      dropDownOptions = updateDropDownOptions(dropDownOptions, DROPDOWN__ITEMID7__OVPMAIN, t('QUINT4_PS:locOutxInputInvOVPMainOptLbl'), enableDropDownValue);
    }

    return (
      <Quint4DropDownBaseControl
        label={label}
        help={help}
        value={statusValue?.value}
        options={dropDownOptions}
        valueModified={statusValue?.modified ?? false}
        warning={warning}
        onValueChange={onValueChange}
        onShowContentHelp={onShowContentHelp}
        onHideContentHelp={onHideContentHelp}
        readonly={readonly}
        showContextMessageDef={showContextMessageDef}
      />
    );
  };

export default Quint4DigitalSelectlDropDown;
