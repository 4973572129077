/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { ReduxControlProps } from '../ReduxControlProps';
import { useTypedSelector } from '../../store';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { ChannelStatusControl } from '../../components/ChannelStatusControl/ChannelStatusControl';
import { DatasetContext } from '../../views/DatasetContext';
import { DatasetState } from '../../store/deviceInstances/store/deviceDataset/types';
import withControlVisibility from '../hoc/withControlVisibility';
import displayStatusValueSelector from '../selectors/displayStatusValueSelector';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';

const selectControlChannelStatus = (
  dataset: DatasetState,
  identRef: IdentRef,
): DeviceModelStatus.UI.ChannelStatusControlType | undefined => {
  const desc = dataset.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (desc.controlType.type !== DeviceModelStatus.UI.ControlType.CTLCHANNELSTATUS) {
    return undefined;
  }
  return desc.controlType;
};

const selectControlVariable = (
  dataset: DatasetState,
  identRef: IdentRef,
): DeviceModelStatus.StatusDescriptor | undefined => {
  const controlType = selectControlChannelStatus(dataset, identRef);
  if (controlType === undefined) {
    return undefined;
  }

  const desc = dataset.descriptors[controlType.variable];
  if (desc.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  return desc;
};

const selectControlNominalVariable = (
  dataset: DatasetState,
  identRef: IdentRef,
): DeviceModelStatus.StatusDescriptor | undefined => {
  const controlType = selectControlChannelStatus(dataset, identRef);
  if (controlType === undefined) {
    return undefined;
  }

  const desc = dataset.descriptors[controlType.NOMINALVALUE.variable];
  if (desc.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  return desc;
};

const selectControlVariableValue = (
  dataset: DatasetState,
  identRef: IdentRef,
): DeviceModelStatus.StatusValue | undefined => {
  const controlType = selectControlChannelStatus(dataset, identRef);
  if (controlType === undefined) {
    return undefined;
  }
  return dataset.values[controlType.variable];
};

const selectControlNomVariableValue = (
  dataset: DatasetState,
  identRef: IdentRef,
): DeviceModelStatus.StatusValue | undefined => {
  const controlType = selectControlChannelStatus(dataset, identRef);
  if (controlType === undefined) {
    return undefined;
  }

  return dataset.values[controlType.NOMINALVALUE.variable];
};

const ChannelStatusReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const control = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    return deviceDataset === undefined
      ? undefined
      : selectControlChannelStatus(deviceDataset, identRef);
  });
  const controlVariable = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    return deviceDataset === undefined
      ? undefined
      : selectControlVariable(deviceDataset, identRef);
  });
  const controlVariableValue = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    return deviceDataset === undefined
      ? undefined
      : selectControlVariableValue(deviceDataset, identRef);
  });

  const controlVariableDisplayValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (dataset === undefined) {
      return '';
    }
    const desc = selectControlVariable(dataset, identRef);
    if (desc === undefined) {
      return '';
    }
    return displayStatusValueSelector(dataset, desc.identRef);
  });

  const controlNomVariable = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    return deviceDataset === undefined
      ? undefined
      : selectControlNominalVariable(deviceDataset, identRef);
  });
  const controlNomVariableValue = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    return deviceDataset === undefined
      ? undefined
      : selectControlNomVariableValue(deviceDataset, identRef);
  });
  const controlNomVariableDisplayValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (dataset === undefined) {
      return '';
    }
    const desc = selectControlNominalVariable(dataset, identRef);
    if (desc === undefined) {
      return '';
    }
    return displayStatusValueSelector(dataset, desc.identRef);
  });

  if (control === undefined) {
    return <UnsupportedControl text={`ChannelStatusReduxControl [${identRef}]: undefined control`} />;
  }
  if (controlVariable === undefined || controlVariableValue === undefined) {
    return <UnsupportedControl text={`ChannelStatusReduxControl [${identRef}]: undefined control variable`} />;
  }
  if (controlNomVariable === undefined || controlNomVariableValue === undefined) {
    return <UnsupportedControl text={`ChannelStatusReduxControl [${identRef}]: undefined control nominl variable`} />;
  }

  if (controlVariable.visibility === false) {
    return (null);
  }

  const { caption, label } = control;
  const { defcolor, label: nomLabel, CASE } = control.NOMINALVALUE;

  let nomVariableTitle = '';
  const nominalLabel = nomLabel ?? controlNomVariable.label;

  if (controlNomVariable.valueType.type === DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    nomVariableTitle = `${t<string>(nominalLabel)}: ${controlNomVariableDisplayValue}`;
  } else {
    const nomVariableUnit = controlNomVariable?.unit === undefined ? '' : t<string>(controlNomVariable.unit);
    nomVariableTitle = `${t<string>(nominalLabel)}: ${controlNomVariableDisplayValue} ${nomVariableUnit}`;
  }

  const nomCase = CASE.find((caseItem) => caseItem.value === controlNomVariableValue.value);
  let controlColor = nomCase ? nomCase.defcolor : defcolor;

  const selectedBehaviour = nomCase?.BEHAVIOUR
    .find((behaviour) => (controlVariableValue.value >= behaviour.min && controlVariableValue.value <= behaviour.max));
  controlColor = selectedBehaviour === undefined ? defcolor : selectedBehaviour.color;

  return (
    <ChannelStatusControl
      title={t<string>(caption)}
      nomVariableTitle={nomVariableTitle}
      variableLabel={t<string>(label ?? controlVariable.label)}
      unit={controlVariable.unit === undefined ? '' : t<string>(controlVariable.unit)}
      currentValue={controlVariableDisplayValue}
      color={controlColor}
    />
  );
};

export default withControlVisibility(ChannelStatusReduxControl);
