/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import IconForceWarning from '../../assets/icons/icon-force-warning.svg';
import './ModalWarningDialog.scss';

export interface WarningDialogProps {
  title: string;
  message: string;
  onCancel: () => void;
  onAccept: () => void;
}

export const WarningDialog: React.FC<WarningDialogProps> = (
  props: WarningDialogProps,
): React.ReactElement => {
  const {
    title, message, onAccept, onCancel,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="warning-dialog">
      <div className="warning-dialog-container">
        <div className="warning-dialog-color">
          <div className="warning-dialog-color__image">
            <img
              src={IconForceWarning}
              alt="warning"
            />
          </div>
        </div>
        <div className="warning-dialog-data">
          <div className="warning-dialog-data__header">
            <div className="warning-dialog-data__header__text">
              {t(title)}
            </div>
          </div>
          <div className="warning-dialog-data__message">
            <div className="warning-dialog-data__message-text">
              <div className="warning-dialog-data__message-text__data">
                {t(message)}
              </div>
            </div>
          </div>
          <div className="warning-dialog-data__buttons">
            <div className="warning-dialog-data__buttons-container">
              <Button
                className="button-text warning-dialog-data__buttons-container__ok"
                variant="secondary"
                onClick={() => onAccept()}
              >
                {t<string>('STANDARD_BUTTON__OK')}
              </Button>
              <Button
                className="button-text warning-dialog-data__buttons-container__cancel"
                variant="primary"
                onClick={() => {
                  onCancel();
                }}
              >
                {t<string>('STANDARD_BUTTON__CANCEL')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModalWarningDialog: React.FC<WarningDialogProps> = (props:WarningDialogProps) => {
  const {
    title, message, onAccept, onCancel,
  } = props;
  const modalRoot = document.getElementById('modal-root');
  if (modalRoot === null) {
    return <div />;
  }
  return (
    <>
      {ReactDOM.createPortal(
        <WarningDialog
          title={title}
          message={message}
          onAccept={onAccept}
          onCancel={onCancel}
        />,
        modalRoot,
      )}
    </>
  );
};
