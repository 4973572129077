/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import './ConfigurationStep.scss';

export interface PSRConfigurationStepProps {
    text: string;
    index: number;
}

export const PSRConfigurationStep
    : React.FC<PSRConfigurationStepProps> = (props: PSRConfigurationStepProps)
        :React.ReactElement => {
      const { text, index } = props;
      const { t } = useTranslation();
      return (
        <div className="psr-uni__manual_configuration__step">
          <div className="psr-uni__manual_configuration__step__index">{index}</div>
          <div className="psr-uni__manual_configuration__step__text">{t(text)}</div>
        </div>
      );
    };
