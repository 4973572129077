import { DeviceModelStatus } from '@gpt/commons';
import deviceModelControlDescriptor from './deviceModelControlDescriptor';

const deviceModelStructureDescriptorCockpit: DeviceModelStatus.DeviceModelDescriptorCollection = {
  cockpitMenu: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'cockpitMenu',
    help: '01_04_0F_14_0020:cockpitMenu_help',
    label: '01_04_0F_14_0020:cockpitMenu_label',
    description: '',
    elements: [{
      identRef: 'cockpitAnalogMenu',
    },
    {
      identRef: deviceModelControlDescriptor.ctrlTrendView.identRef,
    }],
  },
  cockpitAnalogMenu: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'cockpitAnalogMenu',
    style: 'horizontal',
    help: '01_04_0F_14_0020:cockpitAnalogMenu_help',
    label: '01_04_0F_14_0020:cockpitAnalogMenu_label',
    description: '',
    elements: [{
      identRef: deviceModelControlDescriptor.ctrlIFSM_ADDR_IO_R_SENSOR_VOLTAGE.identRef,
    },
    {
      identRef: deviceModelControlDescriptor.ctrlIFSM_ADDR_IO_R_OUTPUT_CALC.identRef,
    },
    {
      identRef: 'cockpitDeviceStatusMenu',
    }],
  },
  cockpitDeviceStatusMenu: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'cockpitDeviceStatusMenu',
    help: '01_04_0F_14_0020:cockpitDeviceStatusMenu_help',
    label: '01_04_0F_14_0020:cockpitDeviceStatusMenu_label',
    description: '',
    elements: [{
      position: 'power_status',
      identRef: deviceModelControlDescriptor.ctrlLedDEVICE_POWER_STATUS.identRef,
    },
    {
      position: 'device_status',
      identRef: deviceModelControlDescriptor.ctrlLedDEVICE_STATUS.identRef,
    }],
  },
};

export default deviceModelStructureDescriptorCockpit;
