/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { DeviceInformation } from '@gpt/commons';
import './PageSelectDeviceComponent.scss';
import WizardDeviceList, { DeviceListItem } from '../../components/WizardDeviceList/WizardDeviceList';

export interface PageSelectDeviceComponentProps {
    caption: string;
    deviceList: DeviceListItem[];
    alignRight?: boolean;
    // eslint-disable-next-line no-unused-vars
    onSelectDevice?: (device: DeviceInformation) => Promise<void>;
}

const PageSelectDeviceComponent: React.FC<PageSelectDeviceComponentProps> = (props: PageSelectDeviceComponentProps): React.ReactElement => {
  const {
    deviceList, caption, onSelectDevice, alignRight,
  } = props;

  const titleContent = alignRight ? 'justify-content-end' : 'justify-content-start';
  return (
    <div className="page-select-device w-100">
      <div className="page-select-device__container">
        <div className={`page-select-device__devicelist-title d-flex align-items-center ${titleContent} pr-2 w-100`}>
          <p>{caption}</p>
        </div>
        <div className="page-select-device__devicelist">
          <WizardDeviceList
            deviceList={deviceList}
            alignRight={alignRight}
            onActivate={(device) => onSelectDevice?.(device)}
          />
        </div>
      </div>
    </div>
  );
};

export default PageSelectDeviceComponent;
