/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WizardView from '../../WizardView/WizardView';
import { disposeDevice } from '../../../store';
import { WizardProps } from '../../types';
import Page1SelectDevice from './Page1SelectDevice/Page1SelectDevice';
import Page2StationAddressing from './Page2StationAddressing/Page2StationAddressing';
import Page3DeviceIdent from './Page3DeviceIdent/Page3DeviceIdent';
import Page4DeviceNaming from './Page4DeviceNaming/Page4DeviceNaming';
import Page5SelectProcessData from './Page5SelectProcessData/Page5SelectProcessData';
import Page6GroupProcessData from './Page6GroupProcessData/Page6GroupProcessData';
import Page7Report from './Page7Report/Page7Report';
import { CommissioningWizardState, useCommissioningWizardState } from './hooks/useCommissioningWizardState';
import useCommissioningWizardType from './hooks/useCommissioningWizardType';
import { contactronWizardWriteConfiguration } from '../../../store/middleware/contactronWizard/actions';
import { WRITE_CONFIGURATION_METHOD_IDENT, startContactronDiscovery, stopContactronDiscovery } from './types';
import { contactronWizardCleanup } from '../../../store/wizards/contactronStation';

const ContactronCommissioningWizard: React.FC<WizardProps> = (props: WizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deviceInstanceId, startPage } = props;
  const [wizardState, setWizardState] = useCommissioningWizardState(CommissioningWizardState.Page2StationAddressingInit);
  const wizardType = useCommissioningWizardType(deviceInstanceId);

  useEffect(() => {
    if (wizardState === CommissioningWizardState.Page2StationAddressingInit) {
      dispatch(contactronWizardCleanup());
      dispatch(startContactronDiscovery(deviceInstanceId));
      setWizardState(CommissioningWizardState.Page2StationAddressingScanStart);
    }
  }, [wizardState, deviceInstanceId]);

  return (
    <WizardView
      title={t<string>('WIZARD_DEVICE_COMMISSIONING__TITLE')}
      onWizardExit={() => {
        dispatch(stopContactronDiscovery(deviceInstanceId));
        dispatch(disposeDevice(deviceInstanceId));
      }}
      deviceInstanceId={deviceInstanceId}
      startPage={startPage}
    >
      <Page1SelectDevice
        title={t<string>('WIZARD_DEVICE_COMMISSIONING__SELECT_DEVICE')}
        hideStepper
        onChangeToNextPage={() => 0}
      />
      <Page2StationAddressing
        title={t<string>('WIZARD_CONTACTRON__PAGE2_STATION_ADDRESSING__TITLE')}
        onChangeToNextPage={() => {
          setWizardState(CommissioningWizardState.Page3DeviceIdentificationInit);
        }}
        onChangeToPreviousPage={() => {
          dispatch(stopContactronDiscovery(deviceInstanceId));
          dispatch(disposeDevice(deviceInstanceId));
        }}
        onFinish={() => {
          setWizardState(CommissioningWizardState.Page2StationAddressingScanFinish);
          dispatch(stopContactronDiscovery(deviceInstanceId));
        }}
        wizardState={wizardState}
      />
      <Page3DeviceIdent
        identRef={wizardType?.stationIdentificationMenu ?? 'PARAMETERS__DEVICEIDENTIFICATION__DEVICEIDENTIFICATION_GROUP'}
        title={t<string>('WIZARD_CONTACTRON__PAGE3_DEVICE_IDENTIFICATION__TITLE')}
        onChangeToNextPage={() => {
          setWizardState(CommissioningWizardState.Page4DeviceNamingInit);
        }}
        onChangeToPreviousPage={() => {
          setWizardState(CommissioningWizardState.Page2StationAddressingInit);
        }}
        stationInstanceId={deviceInstanceId}
      />
      <Page4DeviceNaming
        title={t<string>('WIZARD_CONTACTRON__PAGE4_DEVICE_NAMING__TITLE')}
        onChangeToNextPage={() => {
          setWizardState(CommissioningWizardState.Page5SelectProcessDataInit);
        }}
        onChangeToPreviousPage={() => {
          setWizardState(CommissioningWizardState.Page3DeviceIdentificationInit);
        }}
        stationInstanceId={deviceInstanceId}
      />
      <Page5SelectProcessData
        title={t<string>('WIZARD_CONTACTRON__PAGE5_SELECT_PROCESS_DATA__TITLE')}
        onChangeToNextPage={() => {
          setWizardState(CommissioningWizardState.Page6GroupProcessDataInit);
        }}
        onChangeToPreviousPage={() => {
          setWizardState(CommissioningWizardState.Page4DeviceNamingInit);
        }}
        stationInstanceId={deviceInstanceId}
        stationDeviceDescription={wizardType?.deviceList ?? {}}
      />
      <Page6GroupProcessData
        title={t<string>('WIZARD_CONTACTRON__PAGE6_GROUP_PROCESS_DATA__TITLE')}
        onChangeToNextPage={() => {
          setWizardState(CommissioningWizardState.Page7ReportInit);
          dispatch(contactronWizardWriteConfiguration(deviceInstanceId, WRITE_CONFIGURATION_METHOD_IDENT));
        }}
        onChangeToPreviousPage={() => {
          setWizardState(CommissioningWizardState.Page5SelectProcessDataInit);
        }}
        stationDeviceDescription={wizardType?.deviceList ?? {}}
        stationInstanceId={deviceInstanceId}
      />
      <Page7Report
        title={t<string>('WIZARD_CONTACTRON__PAGE7_TRANSFER_AND_REPORT__TITLE')}
        reportMenu={wizardType?.stationReportMenu}
        stationInstanceId={deviceInstanceId}
        onChangeToPreviousPage={() => {
          setWizardState(CommissioningWizardState.Page6GroupProcessDataInit);
        }}
      />
    </WizardView>
  );
};

export default ContactronCommissioningWizard;
