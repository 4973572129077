/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { ReduxControlProps } from '../../../ReduxControlProps';
import withControlVisibility from '../../../hoc/withControlVisibility';
import ImageControl from '../../../ImageControl/ImageControl';
import './OnlineCaparocStationControl.scss';
import OnlineCaparocBlockCheckbox from './OnlineCaparocBlockCheckbox/OnlineCaparocBlockCheckbox';
import IoLinkSystemCommandControl from '../../../IoLink/IoLinkSystemCommandControl/IoLinkSystemCommandControl';
import useIoLinkWriteParameter from '../../../IoLink/hooks/useIoLinkWriteParameter';
import { MethodStageExecutionStatus } from '../../../../store/deviceInstances/store/deviceMethod/types';

const AcceptCurrentTopologyLabel = 'CAPAROC:locTN_AcceptTopology__LABEL';
const RestartDeviceLabel = 'CAPAROC:locRestartDevice__LABEL';
const RestoreDeliveryStatusLabel = 'CAPAROC:locRestoreDeliveryState__LABEL';
const varAcceptTopologyIndex = 3332;

const OnlineCaparocStationControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();

  const [executionStage, writeIoLinkParameter] = useIoLinkWriteParameter(deviceInstanceId, 'ONLINE_CAPAAROC_STATION_CONTROL');
  const buttonDisabled = executionStage === MethodStageExecutionStatus.InProgress;

  return (
    <div className="online-caparoc-station-control">
      <div className="online-caparoc-station-control__editor__checkboxes">
        <OnlineCaparocBlockCheckbox deviceInstanceId={deviceInstanceId} />
      </div>
      <div className="online-caparoc-station-control__editor__buttons">
        <Button
          className=""
          variant="secondary"
          block
          disabled={buttonDisabled}
          onClick={() => {
            writeIoLinkParameter(varAcceptTopologyIndex, new Uint8Array([0x0A]));
          }}
        >
          {t(AcceptCurrentTopologyLabel)}
        </Button>
        <div className="online-caparoc-station-control__editor__buttons-iolink">
          <IoLinkSystemCommandControl deviceInstanceId={deviceInstanceId} identRef="ctrlIolDeviceReset" onStateChanged={() => 0} noParamControl />
        </div>
        <div className="online-caparoc-station-control__editor__buttons-iolink">
          <IoLinkSystemCommandControl deviceInstanceId={deviceInstanceId} identRef="ctrlIolRestoreFactorySettings" onStateChanged={() => 0} noParamControl />
        </div>
      </div>
      <div className="online-caparoc-station-control__image">
        <div className="online-caparoc-station-control__image__image">
          <ImageControl deviceInstanceId={deviceInstanceId} identRef="M_MODULE_ONLINE_PARAMETER_EDITOR__IMAGE" onStateChanged={() => 0} />
        </div>
      </div>
    </div>
  );
};

export default withControlVisibility(OnlineCaparocStationControl);
