/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  ContactronWizardAddModuleInstancePayload,
} from '../../wizards/contactronStation';
import {
  contactronWizardAddModuleAction, contactronWizardCreateReportAction,
  contactronWizardReportPayload, contactronWizardUpdateModuleAction,
  contactronWizardUpdateModulePayload,
  contactronWizardWriteConfigAction,
} from './types';

export const contactronWizardAddModule = (payload: ContactronWizardAddModuleInstancePayload)
  : contactronWizardAddModuleAction => ({
  type: 'CONTACTRON_STATION__MIDDLEWARE__ADD_MODULE',
  payload,
});

export const contactronWizardUpdateModule = (payload: contactronWizardUpdateModulePayload)
  : contactronWizardUpdateModuleAction => ({
  type: 'CONTACTRON_STATION__MIDDLEWARE__UPDATE_MODULE',
  payload,
});

export const contactronWizardWriteConfiguration = (
  stationInstanceId: string,
  actionId: string,
  pinCode?: number,
): contactronWizardWriteConfigAction => ({
  type: 'CONTACTRON_STATION__MIDDLEWARE__WRITE_CONFIG',
  payload: {
    stationInstanceId,
    methodStatusRef: actionId,
    pinCode: pinCode ?? 0,
  },
});

export const contactronWizardCreateReport = (payload: contactronWizardReportPayload)
  : contactronWizardCreateReportAction => ({
  type: 'CONTACTRON_STATION__MIDDLEWARE__CREATE_REPORT',
  payload,
});
