/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import IconCancelInstantiation from '../../../assets/icons/cross-circle.svg';
import IconRetryInstantiation from '../../../assets/icons/button-arrow-circle.svg';
import { ButtonState } from '../../../store/deviceWizard';
import WizardButton from '../../../wizards/cxe/EditParameterSelectorCxe/WizardButton/WizardButton';
import './DeviceViewFailed.scss';

export interface DeviceViewFailedProps {
  onRetryClick: () => void,
  onCancelClick: () => void,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DeviceViewFailed: React.FC<DeviceViewFailedProps> = (props: DeviceViewFailedProps): React.ReactElement => {
  const { t } = useTranslation();
  const { onCancelClick, onRetryClick } = props;
  return (
    <div className="device-view-failed w-100 h-100">
      <div className="device-view-failed__title d-flex justify-content-start align-items-center h-100 w-100">
        <p>{t('DEVICE_MODEL_INSTANTIATION_FAILED__TITLE')}</p>
      </div>
      <div className="device-view-failed__content d-flex flex-row justify-content-center align-items-center h-100 pt-4">
        <div className="d-flex flex-column justify-content-around align-items-center w-100 h-50">
          <div className="device-view-failed__content__title">{t('DEVICE_MODEL_INSTANTIATION_FAILED__CANCEL')}</div>
          <div>
            <Image src={IconCancelInstantiation} className="device-view-failed__content__image" />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <WizardButton label={t('STANDARD_BUTTON__CANCEL')} pca={false} state={ButtonState.enabled} onClick={onCancelClick} />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-around align-items-center w-100 h-50">
          <div className="device-view-failed__content__title">{t('DEVICE_MODEL_INSTANTIATION_FAILED__RETRY')}</div>
          <div>
            <Image src={IconRetryInstantiation} className="device-view-failed__content__image" />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <WizardButton label={t('STANDARD_BUTTON__RETRY')} pca={false} state={ButtonState.enabled} onClick={onRetryClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceViewFailed;
