/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reduxStore';
import PageSelectConnectedDeviceComponent from '../../PageCollection/PageSelectConnectedDevice/PageSelectConnectedDevice';
import { DeviceListItem } from '../../../components/WizardDeviceList/WizardDeviceList';
import { PageSelectDeviceComponent } from '../../../PageComponents';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';
import './Page3ChangeDevice.scss';

interface Page3ChangeDeviceProps extends WizardViewPageProps {
  deviceInstanceId: string;
  deviceNewInstanceId: string;
}

const Page3ChangeDevice: React.FC<Page3ChangeDeviceProps> = (props: Page3ChangeDeviceProps): React.ReactElement => {
  const { t } = useTranslation();
  const { active, deviceInstanceId, deviceNewInstanceId } = props;
  const deviceInstance = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId));

  if (deviceInstance === undefined) {
    return <div>No device selected</div>;
  }

  const selectedDevice: DeviceListItem = {
    device: deviceInstance.device,
    selectable: false,
    busy: false,
    supported: true,
  };

  return (
    <Container fluid className="wiz-page-select-device w-100 h-100 p-0 pt-4">
      <Row className="wiz-page-select-device__text_container w-100 m-0">
        <Col className="d-flex flex-column justify-content-start align-items-start w-100 p-0">
          <div className="wiz-page-select-device__text_container__line">{t<string>('WIZARD_CHANGE_DEVICE__PAGE_SELECT_REPLACEMENT__HINT_LINE1')}</div>
          <div className="wiz-page-select-device__text_container__line">{t<string>('WIZARD_CHANGE_DEVICE__PAGE_SELECT_REPLACEMENT__HINT_LINE2')}</div>
        </Col>
      </Row>
      <Row className="wiz-page-select-device__select_device w-100 p-0 m-0">
        <Col xs={9} className="d-flex flex-column justify-content-start align-items-start p-0">
          <PageSelectConnectedDeviceComponent
            active={active}
            deviceInstanceId={deviceNewInstanceId}
            title={t<string>('WIZARD_CHANGE_DEVICE__PAGE_SELECT_REPLACEMENT__TITLE')}
            filter={(device) => {
              const result = deviceInstance.device.instance?.serialNumber !== undefined
                && deviceInstance.device.instance?.serialNumber !== device.instance?.serialNumber
                && deviceInstance.device.catalog.profileName === device.catalog.profileName;
              return result;
            }}
          />
        </Col>
        <Col xs={3} className="d-flex flex-column justify-content-end align-items-start p-0">
          <PageSelectDeviceComponent
            caption={t<string>('WIZARD_CHANGE_DEVICE__PAGE_SELECT_REPLACEMENT__OLD_DEVICE_TITLE')}
            deviceList={[selectedDevice]}
            alignRight
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Page3ChangeDevice;
