/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector, instantiateDevice } from '../../../../../store';
import { showApplicationMessage } from '../../../../../store/applicationMessage/actions';
import { selectDeviceInstance } from '../../../../../store/deviceInstances/actions';
import matchDeviceDriver from '../../../../../store/selectors/matchDeviceDriver';

const consoleLog = console;

const useModuleInstanceSelector = (stationInstanceId: string) => {
  const dispatch = useDispatch();

  const deviceCatalogList = useTypedSelector((state) => state.discoveryService.catalog.deviceList);
  const deviceInstances = useTypedSelector((state) => state.deviceInstances);
  const stationDiscoveryItems = useTypedSelector(
    (state) => state.deviceInstances.instances[stationInstanceId]?.station?.discovery,
  );

  // eslint-disable-next-line max-len
  const selectStationInstance = useCallback((instanceId?: string) => {
    if (instanceId === undefined) {
      dispatch(selectDeviceInstance(stationInstanceId));
      return;
    }

    if (deviceInstances.instances[instanceId]) {
      dispatch(selectDeviceInstance(instanceId));
      return;
    }

    const item = stationDiscoveryItems.find((it) => it.instanceId === instanceId);
    if (item === undefined) {
      dispatch(selectDeviceInstance(stationInstanceId));
      return;
    }

    const deviceInformation = matchDeviceDriver(item.deviceTypeIdentifier, deviceCatalogList);
    if (deviceInformation !== undefined) {
      dispatch(selectDeviceInstance(item.instanceId));
      dispatch(instantiateDevice({
        deviceInfo: { catalog: deviceInformation, instance: item.instance },
        targetInstance: item.instanceId,
        parentInstance: item.instanceId,
        webWorkerInstanceRef: item.instanceId,
        connectionString: item.connectionString ?? '',
        wizardMode: false,
      }));
    } else {
      consoleLog.log('NO DEVICE MODEL FOUND', item.deviceTypeIdentifier);
      dispatch(showApplicationMessage('error', 'CONTACTRON_STATION__NO_DEVICE_MODEL_FOUND'));
      dispatch(selectDeviceInstance(stationInstanceId));
    }
  }, [stationDiscoveryItems, deviceInstances, deviceCatalogList, stationInstanceId]);

  return selectStationInstance;
};

export default useModuleInstanceSelector;
