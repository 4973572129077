/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import { ComponentFactory } from '../ComponentFactory/ComponentFactory';
import { ReduxControlProps } from '../ReduxControlProps';

const getControlType = (descriptor?: DeviceModelStatus.DeviceModelDescriptor) => {
  if (descriptor === undefined) {
    return 'undefined';
  }
  if (descriptor.type === DeviceModelStatus.StatusType.StatusDescriptor) {
    return 'editor';
  }
  if (descriptor.type === DeviceModelStatus.StatusType.StructureDescriptor) {
    return 'menu';
  }
  if (descriptor.type === DeviceModelStatus.StatusType.ApplicationDescriptor) {
    return `APP--${DeviceModelStatus.Apps.AppType[descriptor.appType.type]}`;
  }
  if (descriptor.type === DeviceModelStatus.StatusType.ControlDescriptor) {
    return DeviceModelStatus.UI.ControlType[descriptor.controlType.type];
  }
  return 'undefined';
};

const ReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef, onStateChanged, readonly, deviceInstanceId,
  } = props;
  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  const control = getControlType(descriptor);

  const component = ComponentFactory({
    identRef,
    deviceInstanceId,
    control,
    key: `${control}-${identRef}`,
    onStateChanged,
    readonly,
  });
  return <>{component}</>;
};

export default ReduxControl;
