/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxControlProps } from '../ReduxControlProps';
import withControlVisibility from '../hoc/withControlVisibility';
import ThumbWheelWithButtons from './ThumbWheel/ThumbWheelWithButtons';
import useThumbWheelControl from './hooks/useThumbWheelControl';

const ThumbWheelControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const [statusValue, visibility, label, readonly, writeStatusValue] = useThumbWheelControl(identRef, deviceInstanceId);

  const { t } = useTranslation();

  if (visibility === false || statusValue === undefined) {
    return (null);
  }

  return (
    <div className="thumb-wheel-control-container">
      <ThumbWheelWithButtons
        label={t(label)}
        thumbWheelValue={statusValue.value ?? 0}
        onValueChanged={(value) => writeStatusValue(value, statusValue.identRef, statusValue.backupValue)}
        readonly={readonly}
      />
    </div>
  );
};

export default withControlVisibility(ThumbWheelControl);
