/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { RoutePaths } from './RoutePaths';
import EmptyView from './views/EmptyView';
import LoadingView from './views/LoadingView';
import StandardView from './views/StandardView';
import DeviceViewContainer from './views/DeviceViewContainer/DeviceViewContainer';
import ChangeDeviceWizard from './wizards/ChangeDevice/ChangeDeviceWizard';
import CreateParameterFile from './wizards/CreateParameterFile/CreateParameterFile';
import RestoreDeviceWizard from './wizards/RestoreDevice/RestoreDeviceWizard';
import TransferParameterFile from './wizards/TransferParameterFile/TransferParameterFile';
import SettingDevice from './wizards/SettingDevice/SettingDevice';
import { DatasetContext } from './views/DatasetContext';
import EditParameterSelectorCxe from './wizards/cxe/EditParameterSelectorCxe/EditParameterSelectorCxe';
import TransferSelectorCxe from './wizards/cxe/TransferSelectorCxe/TransferSelectorCxe';
import { DatasetType } from './store/deviceInstances/store/deviceDataset/types';
import StartupViewControl from './views/StartupViewControl/StartupViewControl';
import { WIZARD__CXE_EDIT_DEVICE_PARAM__ID, WIZARD__CXE_TRANSFER_DEVICE_PARAM__ID } from './services/clipxEngineerIntegrationService/clipxEngineerConsts';
import WSelectorCommissioning from './wizards/Commissioning/WizardSelector/WSelectorCommissioning';

const AppRoutes: React.FC = (): React.ReactElement => (
  <Routes>
    <Route
      path={RoutePaths.EmptyView}
      element={<EmptyView />}
    />
    <Route
      path={RoutePaths.LoadingView}
      element={<LoadingView />}
    />
    <Route
      path={RoutePaths.StartupView}
      element={<StartupViewControl />}
    />
    <Route
      path={RoutePaths.DeviceViewNested}
      element={(
        <StandardView>
          <DeviceViewContainer />
        </StandardView>
      )}
    />
    <Route
      path={RoutePaths.WizardChangeDevice}
      element={(
        <StandardView>
          <DatasetContext.Provider value={{ targetDataset: DatasetType.user }}>
            <ChangeDeviceWizard deviceInstanceId="WIZARD__CHANGE_DEVICE__ID" deviceNewInstanceId="WIZARD__CHANGE_DEVICE__NEW_ID" />
          </DatasetContext.Provider>
        </StandardView>
      )}
    />
    <Route
      path={RoutePaths.WizardRestoreDevice}
      element={(
        <StandardView>
          <DatasetContext.Provider value={{ targetDataset: DatasetType.user }}>
            <RestoreDeviceWizard />
          </DatasetContext.Provider>
        </StandardView>
      )}
    />
    <Route
      path={RoutePaths.WizardCreateParameterSet}
      element={(
        <StandardView>
          <DatasetContext.Provider value={{ targetDataset: DatasetType.user }}>
            <CreateParameterFile deviceInstanceId="WIZARD__CREATE_PARAM_FILE__ID" />
          </DatasetContext.Provider>
        </StandardView>
      )}
    />
    <Route
      path={RoutePaths.WizardEditParameterSetCxe}
      element={(
        <StandardView>
          <DatasetContext.Provider value={{ targetDataset: DatasetType.user }}>
            <EditParameterSelectorCxe deviceInstanceId={WIZARD__CXE_EDIT_DEVICE_PARAM__ID} />
          </DatasetContext.Provider>
        </StandardView>
      )}
    />
    <Route
      path={RoutePaths.WizardTransferDeviceParameterCxe}
      element={(
        <StandardView>
          <DatasetContext.Provider value={{ targetDataset: DatasetType.user }}>
            <TransferSelectorCxe deviceInstanceId={WIZARD__CXE_TRANSFER_DEVICE_PARAM__ID} />
          </DatasetContext.Provider>
        </StandardView>
      )}
    />
    <Route
      path={RoutePaths.WizardTransferParameterFile}
      element={(
        <StandardView>
          <DatasetContext.Provider value={{ targetDataset: DatasetType.user }}>
            <TransferParameterFile />
          </DatasetContext.Provider>
        </StandardView>
      )}
    />
    <Route
      path={RoutePaths.WizardCommissioning}
      element={(
        <StandardView>
          <DatasetContext.Provider value={{ targetDataset: DatasetType.user }}>
            <WSelectorCommissioning deviceInstanceId="WIZARD__COMMISSIONING__ID" />
          </DatasetContext.Provider>
        </StandardView>
      )}
    />
    <Route
      path={RoutePaths.WizardSettingDevice}
      element={(
        <StandardView>
          <DatasetContext.Provider value={{ targetDataset: DatasetType.user }}>
            <SettingDevice deviceInstanceId="WIZARD__SETTING_DEVICE__ID" />
          </DatasetContext.Provider>
        </StandardView>
      )}
    />
    {/* default: */}
    <Route
      element={<StartupViewControl />}
    />
  </Routes>
);

export default AppRoutes;
