/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Services } from '@gpt/commons';
import { DeviceInstancesState } from '../../types';

// eslint-disable-next-line import/prefer-default-export
export const deviceTimelineValuesSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
):Services.DeviceModelServer.DeviceTimelineData[] => {
  if (deviceInstanceId === undefined) {
    return [];
  }
  return state.instances[deviceInstanceId]?.timeline.timelineValues ?? [];
};
