/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PlayPauseIcon from '../../assets/icons/play-pause.svg';
import './PlayPauseButton.css';

export interface PlayPauseButtonProps {
  checked: boolean;
  onChange: () => void;
}

export const PlayPauseButton: React.FC<PlayPauseButtonProps> = (props: PlayPauseButtonProps):
React.ReactElement => {
  const {
    checked,
    onChange,
  } = props;
  const { t } = useTranslation();

  return (
    <ToggleButton
      id="play-pause-button"
      type="checkbox"
      variant="secondary"
      checked={checked}
      value="1"
      onChange={() => onChange()}
      style={{ height: '35px' }}
    >
      <div className="flex-container">
        <img src={PlayPauseIcon} alt="" height="12px" />
        <span>{ checked ? t<string>('PLAY') : t<string>('PAUSE') }</span>
      </div>
    </ToggleButton>
  );
};
