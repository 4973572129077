/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  Services,
} from '@gpt/commons';
import { Action } from 'redux';
import { DeviceInstanceActionPayload } from '../../common';

export const DATASET_PROVIDER__SET_DATASET_LIST = 'DATASET_PROVIDER__SET_DATASET_LIST';
export const DEVICE_INSTANCE__DATASET_PROVIDER__ACTIONS = 'DEVICE_INSTANCE__DATASET_PROVIDER__ACTIONS';

export interface DatasetProviderState {
  datasetList: Services.DeviceModelServer.DatasetProviderService.ExternalDataset[];
}
/**
 * Set received dataset list into redux
 */
export interface SetDatasetListAction extends Action<typeof DATASET_PROVIDER__SET_DATASET_LIST> {
  payload: Services.DeviceModelServer.DatasetProviderService.ExternalDataset[];
}

export type typeDatasetProviderAction = SetDatasetListAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceDataStorageAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceDatasetProviderPayload extends DeviceInstanceActionPayload {
  action: typeDatasetProviderAction;
}

export interface typeDeviceInstanceDatasetProviderAction extends Action<typeof DEVICE_INSTANCE__DATASET_PROVIDER__ACTIONS> {
  payload: typeDeviceInstanceDatasetProviderPayload;
}
