/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  Middleware, MiddlewareAPI, Dispatch,
} from 'redux';
import { WebDevice } from '@gpt/commons';
import { v4 as uuidv4 } from 'uuid';
import { TRIO3_EVENT_MONITOR__EVENT_ASKNOWLEDGE, typeIoLinkTrio3EventMonitorMiddlewareActions } from './types';
import { IWebWorkerDeviceManager } from '../../../../../services/WebWorkerDevice/WebWorkerDeviceManager';
import { DatasetType } from '../../../store/deviceDataset/types';
import { setPendingTrio3Event } from '../../../store/ioLinkTrio3EventMonitor/actions';
import { deviceInstancesStoreSelector } from '../../../../reduxStoreSelector';

const createIoLinkExecuteCommandMethod = (deviceInstanceId: string, ioLinkIndex: number, ioLinkSubindex: number)
: WebDevice.WebDeviceExecuteMethodRequest => ({
  kind: 'WEBDEVICE__EXECUTE_METHOD__REQUEST',
  requestId: uuidv4(),
  methodIdent: 'TRIO3EventAcknowledge',
  deviceInstanceId,
  header: {
    index: `${ioLinkIndex}`,
    subindex: `${ioLinkSubindex}`,
  },
  request: {
    type: 'WEBDEVICE__METHOD_EXECUTE__INIT',
    data: [],
  },
});

const ioLinkTrio3EventMonitorMiddleware = (webWorkerDeviceManager: IWebWorkerDeviceManager): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeIoLinkTrio3EventMonitorMiddlewareActions>(action: A): Promise<A> => {
  switch (action.type) {
    case TRIO3_EVENT_MONITOR__EVENT_ASKNOWLEDGE: {
      const { payload } = action;
      const { event, targetInstance } = payload;

      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const webWorkerInstance = deviceInstances.instances[targetInstance]?.activeDevice.modelInstance?.webWorkerInstanceRef;
      if (webWorkerInstance === undefined) {
        break;
      }

      const webWorkerDevice = webWorkerDeviceManager.get(webWorkerInstance);
      if (webWorkerDevice === undefined) {
        break;
      }

      api.dispatch(setPendingTrio3Event(targetInstance, payload));

      const methodRequest = createIoLinkExecuteCommandMethod(targetInstance, event.ioLinkIndex, event.ioLinkSubindex);
      await webWorkerDevice.get(DatasetType.device)
        .executeMethod(methodRequest);
    }
      break;
    default:
  }
  const result = next(action);
  return result;
};

export default ioLinkTrio3EventMonitorMiddleware;
