/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  SetDeviceActionExecutionProgressPayload,
  typeDeviceInstanceDeviceActionExecutionAction,
} from './types';

export const setDeviceActionExecutionProgress = (targetInstance: string, payload: SetDeviceActionExecutionProgressPayload)
  : typeDeviceInstanceDeviceActionExecutionAction => ({
  type: 'DEVICE_INSTANCE__ACTION_EXECUTION__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'ACTION_EXECUTION__SET_EXECUTION_PROGRESS',
      payload: payload,
    },
  },
});

export const cleanDeviceActionExecutionState = (targetInstance: string, payload: string)
  : typeDeviceInstanceDeviceActionExecutionAction => ({
  type: 'DEVICE_INSTANCE__ACTION_EXECUTION__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'ACTION_EXECUTION__CLEANUP',
      payload,
    },
  },
});
