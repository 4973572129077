/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
export interface CaparocModuleVariables {
  nominalCurrent: string;
  rotarySwitchMode: string;
  minCurrent: string;
  maxCurrent: string;
  errorCounter: string;
  actualCurrent: string;
  actualCurrentMin: string;
  actualCurrentMax: string;
}

export const createChannelVariables = (channelNo: number): CaparocModuleVariables => ({
  nominalCurrent: `var_V_CB_Channel${channelNo}_02_TN_Channel_NominalCurrent`,
  rotarySwitchMode: `var_Channel${channelNo}__RotarySwitchMode`,
  minCurrent: `var_V_CB_Channel${channelNo}_07_TN_Channel_MinCurrent`,
  maxCurrent: `var_V_CB_Channel${channelNo}_08_TN_Channel_MaxCurrent`,
  errorCounter: `var_V_CB_Channel${channelNo}_06_TN_Channel_ErrorCounter`,
  actualCurrent: `var_V_CB_Channel${channelNo}_04_TN_Channel_ActualCurrent`,
  actualCurrentMin: `var_V_CB_Channel${channelNo}_TN_Channel_ActualCurrent_Min`,
  actualCurrentMax: `var_V_CB_Channel${channelNo}_TN_Channel_ActualCurrent_Max`,
});
