/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import withControlVisibility from '../hoc/withControlVisibility';
import { ReduxControlProps } from '../ReduxControlProps';

// eslint-disable-next-line max-len
const PlaceholderControl: React.FC<ReduxControlProps> = (props: ReduxControlProps): React.ReactElement => {
  const { identRef } = props;

  return (
    <Container fluid>
      <Row>
        <Col className="col-12 d-flex justify-content-center align-items-center p-1 m-1 bg-warning">
          {identRef}
        </Col>
      </Row>
    </Container>
  );
};

export default withControlVisibility(PlaceholderControl);
