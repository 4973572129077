/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { DeviceModelStatus } from '@gpt/commons';
import { registerComponent } from './ComponentFactory/ComponentFactory';
import VariableEditorReduxControl from '../components/ParameterEditor/VariableEditorControl/VariableEditorReduxControl';
import ImageControl from './ImageControl/ImageControl';
import MenuReduxControl from './MenuReduxControl/MenuReduxControl';
import ParameterGroupReduxControl from './MenuLayoutReduxControl/ParameterGroupReduxControl/ParameterGroupReduxControl';
import MenuTabsReduxControl from './MenuLayoutReduxControl/MenuTabsReduxControl/MenuTabsReduxControl';
import HorMenuLayoutReduxControl from './MenuLayoutReduxControl/HorMenuLayoutReduxControl/HorMenuLayoutReduxControl';
import PlaceholderControl from './PlaceholderControl/PlaceholderControl';
import InputSliderReduxControl from './InputSliderReduxControl/InputSliderReduxControl';
import CheckboxReduxEditorControl from '../components/ParameterEditor/CheckboxEditorControl/CheckboxReduxEditorControl/CheckboxReduxEditorControl';
import BargraphHorReduxControl from './DynVariableReduxControl/BargraphHorReduxControl/BargraphHorReduxControl';
import BargraphVerReduxControl from './DynVariableReduxControl/BargraphVerReduxControl/BargraphVerReduxControl';
import VerMenuLayoutReduxControl from './MenuLayoutReduxControl/VerMenuLayoutReduxControl/VerMenuLayoutReduxControl';
import DynVariableReduxControl from './DynVariableReduxControl/DynVariableReduxControl';
import InputSliderRangeReduxControl from './InputSliderRangeReduxControl/InputSliderRangeReduxControl';
import RangeWithSpanReduxControl from './RangeWithSpanReduxControl/RangeWithSpanReduxControl';
import TrendViewReduxControl from './TrendViewControl/TrendViewReduxControl/TrendViewReduxControl';
import GaugeReduxControl from './DynVariableReduxControl/GaugeReduxControl/GaugeReduxControl';
import DynLedFloatControl from './DynLedFloatControl/DynLedFloatControl';
import MenuSelectorReduxControl from './MenuSelectorReduxControl/MenuSelectorReduxControl';
import CurrentStatusListReduxControl from './CurrentStatusListControl/CurrentStatusListReduxControl';
import DeviceStatusListReduxControl from './DeviceStatusListReduxControl/DeviceStatusListReduxControl';
import CurrentStatusReduxControl from './CurrentStatusControl/CurrentStatusReduxControl';
import DeviceStatusReduxControl from './DeviceStatusControl/DeviceStatusReduxControl';
import DeviceValuePickerReduxControl from './DeviceValuePickerReduxControl/DeviceValuePickerReduxControl';
import PaperReduxControl from './PaperControl/PaperReduxControl';
import LinearizationButtonControl from '../modal/LinearizationButtonControl/LinearizationButtonControl';
import DIPSwitchBlockReduxOnline from './DIPSwitchBlock/DIPSwitchBlockReduxOnline';
import DIPSwitchBlockReduxOffline from './DIPSwitchBlock/DIPSwitchBlockReduxOffline';
import StatusMessageReduxControl from './StatusMessageReduxControl/StatusMessageReduxControl';
import DIPSwitchBlockReduxLive from './DIPSwitchBlock/DIPSwitchBlockReduxLive';
import GridLayoutReduxControl from './GridLayoutControl/GridLayoutReduxControl';
import ChannelStatusReduxControl from './ChannelStatusReduxControl/ChannelStatusReduxControl';
import LedLinkReduxControl from './LedLinkControl/LedLinkReduxControl';
import EventMonitorReduxControl from './EventMonitor/EventMonitorReduxControl/EventMonitorReduxControl';
import EventMonitorStatusReduxControl from './EventMonitorStatus/EventMonitorStatusControl';
import Trio3EventMonitorReduxControl from './EventMonitorTrio/EventMonitorTrioReduxControl/EventMonitorTrioReduxControl';
import Trio3EventMonitorStatusReduxControl from './EventMonitorTrio/Trio3EventMonitorStatus/Trio3EventMonitorStatus';
import ChannelStatusTrioReduxControl from './ChannelStatusTrio/ChannelStatusTrioReduxControl/ChannelStatusTrioReduxControl';
import IoLinkSystemCommandControl from './IoLink/IoLinkSystemCommandControl/IoLinkSystemCommandControl';
import IoLinkCommandControl from './IoLink/IoLinkCommandControl/IoLinkCommandControl';
import PasswordReduxControl from './PasswordControl/PasswordReduxControl/PasswordReduxControl';
import CheckboxReduxControl from './CheckboxControl/CheckboxReduxControl/CheckboxReduxControl';
import AnalogGaugeRedux from './AnalogGaugeRedux/AnalogGaugeRedux';
import ContactronControlSelector from './Contactron/ContactronControlSelector';
import registerSportControls from './SPort/register';
import CockpitCaparocStation from './Caparoc/CockpitCaparocStation/CockpitCaparocStation';
import OnlineCaparocModule from './Caparoc/OnlineCaparocModule/OnlineCaparocModule';
import DeviceStatusEventMonitorControl from './DeviceStatusEventMonitor/DeviceStatusEventMonitor';
import DeviceStatusVariableIconReduxControl from './DeviceStatusVariableIconReduxControl/DeviceStatusVariableIconReduxControl';
import DeviceStatusVariableValueReduxControl from './DeviceStatusVariableValueReduxControl/DeviceStatusVariableValueReduxControl';
import OnlineCaparocStation from './Caparoc/OnlineCaparocStation/OnlineCaparocStation';
import Quint4AnalogSignalRedux from './Quint4/Quint4AnalogSignal/Quint4AnalogSignalRedux';
import DeviceSpecificControl from './DeviceSpecificControl/DeviceSpecificControl';
import Quint4SignalOut1 from './Quint4/Quint4SignalOut1/Quint4SignalOut1';
import Quint4SignalOut2 from './Quint4/Quint4SignalOut2/Quint4SignalOut2';
import Quint4SignalOut3 from './Quint4/Quint4SignalOut3/Quint4SignalOut3';
import Quint4Characteristics from './Quint4/Quint4Characteristics/Quint4Characteristics';
import SPortSpecificControl from '../components/SPort/SPortSpecificControl';
import Trio3ChannelSwitchBlockReduxControl from './IoLink/Trio3ChannelSwitchBlockReduxControl/Trio3ChannelSwitchBlockReduxControl';
import ThumbWheelControl from './ThumbWheelControl/ThumbWheelControl';
import HDCErrorMemoryControl from './HDC/HDCErrorMemoryControl/HDCErrorMemoryControl';
import { PSRManualControlCH1, PSRManualControlCH2 } from './PSR';

const registerKnownControls = (): void => {
  // Basic controls
  registerComponent('editor', VariableEditorReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLPICTURE, ImageControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLSLIDER, InputSliderReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLRANGE, InputSliderRangeReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN, RangeWithSpanReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLPLACEHOLDER, PlaceholderControl);
  registerComponent('checkbox', CheckboxReduxEditorControl);

  // Layout controls
  registerComponent('menu', MenuReduxControl);
  registerComponent('menu-tabs', MenuTabsReduxControl);
  registerComponent('menu-horizontal', HorMenuLayoutReduxControl);
  registerComponent('menu-vertical', VerMenuLayoutReduxControl);
  registerComponent('menu-group', ParameterGroupReduxControl);

  // Cockpit Controls
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDYNTREND, TrendViewReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDYNLEDFLOAT, DynLedFloatControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLCURRENTSTATUSLIST, CurrentStatusListReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDEVICESTATUSLIST, DeviceStatusListReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLCURRENTSTATUS, CurrentStatusReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDEVICESTATUS, DeviceStatusReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDEVICEVALUEPICKER, DeviceValuePickerReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLPAPER, PaperReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLGRIDLAYOUT, GridLayoutReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLCHANNELSTATUS, ChannelStatusReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLLEDLINK, LedLinkReduxControl);

  // Gauge Control Family
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE, DynVariableReduxControl);
  registerComponent(`${DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE}-gauge`, GaugeReduxControl);
  registerComponent(`${DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE}-horizontalbar`, BargraphHorReduxControl);
  registerComponent(`${DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE}-verticalbar`, BargraphVerReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLANALOGGAUGE, AnalogGaugeRedux);

  // Controls
  registerComponent(DeviceModelStatus.UI.ControlType.CTLSELECT, MenuSelectorReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLLINEARIZATION, LinearizationButtonControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDIPSWITCHBLOCKONLINE, DIPSwitchBlockReduxOnline);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDIPSWITCHBLOCKOFFLINE, DIPSwitchBlockReduxOffline);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDIPSWITCHBLOCKLIVE, DIPSwitchBlockReduxLive);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLSTATUSMESSAGE, StatusMessageReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDEVICESTATUSMONITOR, DeviceStatusEventMonitorControl);

  registerComponent(DeviceModelStatus.UI.ControlType.CTLIOLINKEVENTMONITOR, EventMonitorReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLIOLINKEVENTMONITORSTATUS, EventMonitorStatusReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLTRIO3EVENTMONITOR, Trio3EventMonitorReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLTRIO3EVENTMONITORSTATUS, Trio3EventMonitorStatusReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLTRIO3CHANNELSTATUS, ChannelStatusTrioReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLIOLINKSYSTEMCOMMAND, IoLinkSystemCommandControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLIOLINKCOMMAND, IoLinkCommandControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLCHANNELSWITCHBLOCK, Trio3ChannelSwitchBlockReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLPASSWORD, PasswordReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLCHECKBOX, CheckboxReduxControl);

  registerComponent(DeviceModelStatus.UI.ControlType.CTLTHUMBWHEEL, ThumbWheelControl);

  registerComponent(DeviceModelStatus.UI.ControlType.CTLCOCKPITCAPAROC, CockpitCaparocStation);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLONLINEMODULEEDITORCAPAROC, OnlineCaparocModule);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDEVICESTATUSVARIABLEICON, DeviceStatusVariableIconReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLDEVICESTATUSVARIABLEVALUE, DeviceStatusVariableValueReduxControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLONLINECAPAROC, OnlineCaparocStation);

  /* QUINT4 */
  registerComponent(DeviceModelStatus.UI.ControlType.CTLQUINT4ANALOGSIGNAL, Quint4AnalogSignalRedux);
  registerComponent('QUINT4_SIGNALING_OUT1', Quint4SignalOut1);
  registerComponent('QUINT4_SIGNALING_OUT2', Quint4SignalOut2);
  registerComponent('QUINT4_SIGNALING_OUT3', Quint4SignalOut3);
  registerComponent('QUINT4_CHARACTERISTICS', Quint4Characteristics);

  /* CONTACTRON */
  registerComponent(DeviceModelStatus.UI.ControlType.CTLCONTACTRON, ContactronControlSelector);

  registerComponent(DeviceModelStatus.UI.ControlType.CTLDEVICESPECIFIC, DeviceSpecificControl);
  registerComponent(DeviceModelStatus.UI.ControlType.CTLSPORTSPECIFIC, SPortSpecificControl);

  registerComponent('HDC_ERROR_MEMORY_TABLE', HDCErrorMemoryControl);
  registerComponent('PSR_UNI__CONFIGURATION_MANUAL_CH1', PSRManualControlCH1);
  registerComponent('PSR_UNI__CONFIGURATION_MANUAL_CH2', PSRManualControlCH2);

  registerSportControls();
};

export default registerKnownControls;
