/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Resource } from 'i18next';

import i18nMINIMCREn from './MINI_MCR_en.json';
import i18nMINIMCRDe from './MINI_MCR_de.json';
import i18nMINIMCREs from './MINI_MCR_es.json';
import i18nMINIMCRFr from './MINI_MCR_fr.json';
import i18nMINIMCRIt from './MINI_MCR_it.json';
import i18nMINIMCRJa from './MINI_MCR_ja.json';
import i18nMINIMCRPl from './MINI_MCR_pl.json';
import i18nMINIMCRPt from './MINI_MCR_pt.json';
import i18nMINIMCRRu from './MINI_MCR_ru.json';
import i18nMINIMCRTr from './MINI_MCR_tr.json';
import i18nMINIMCRZh from './MINI_MCR_zh.json';
import i18nMINIMCRCs from './MINI_MCR_cs.json';
import i18nMINIMCRDa from './MINI_MCR_da.json';
import i18nMINIMCRFi from './MINI_MCR_fi.json';
import i18nMINIMCRHu from './MINI_MCR_hu.json';
import i18nMINIMCRKo from './MINI_MCR_ko.json';
import i18nMINIMCRNb from './MINI_MCR_nb.json';
import i18nMINIMCRNl from './MINI_MCR_nl.json';
import i18nMINIMCRRo from './MINI_MCR_ro.json';
import i18nMINIMCRSv from './MINI_MCR_sv.json';
import i18nMINIMCRUk from './MINI_MCR_uk.json';

/**
 * Default translation for application
 */
const bundledResources: Resource = {
  en: {
    MINI_MCR: i18nMINIMCREn,
  },
  de: {
    MINI_MCR: i18nMINIMCRDe,
  },
  es: {
    MINI_MCR: i18nMINIMCREs,
  },
  fr: {
    MINI_MCR: i18nMINIMCRFr,
  },
  it: {
    MINI_MCR: i18nMINIMCRIt,
  },
  ja: {
    MINI_MCR: i18nMINIMCRJa,
  },
  pl: {
    MINI_MCR: i18nMINIMCRPl,
  },
  pt: {
    MINI_MCR: i18nMINIMCRPt,
  },
  ru: {
    MINI_MCR: i18nMINIMCRRu,
  },
  tr: {
    MINI_MCR: i18nMINIMCRTr,
  },
  zh: {
    MINI_MCR: i18nMINIMCRZh,
  },
  cs: {
    MINI_MCR: i18nMINIMCRCs,
  },
  da: {
    MINI_MCR: i18nMINIMCRDa,
  },
  fi: {
    MINI_MCR: i18nMINIMCRFi,
  },
  hu: {
    MINI_MCR: i18nMINIMCRHu,
  },
  ko: {
    MINI_MCR: i18nMINIMCRKo,
  },
  nb: {
    MINI_MCR: i18nMINIMCRNb,
  },
  nl: {
    MINI_MCR: i18nMINIMCRNl,
  },
  ro: {
    MINI_MCR: i18nMINIMCRRo,
  },
  sv: {
    MINI_MCR: i18nMINIMCRSv,
  },
  uk: {
    MINI_MCR: i18nMINIMCRUk,
  },
};

export default bundledResources;
