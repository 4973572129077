/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createChannelVariables } from '../moduleVariables';
import { useContextDatasetStatusValue } from '../../../../../hooks/useContextDataset';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { MethodStageExecutionStatus } from '../../../../../store/deviceInstances/store/deviceMethod/types';
import useIoLinkWriteParameter from '../../../../IoLink/hooks/useIoLinkWriteParameter';
import { updateDeviceDatasetValues } from '../../../../../store/deviceInstances/store/deviceDataset';
import './CounterReset.scss';

export interface ChannelCounterResetProps {
  deviceInstanceId: string;
  channelNo: number;
  disabled: boolean;
  label: string;
}

const channelResetActionId = (value: number) => `CAPAROC_MODULE_CHANNEL_RESET_COUNTER_${value}`;

const ChannelCounterReset: React.FC<ChannelCounterResetProps> = (props: ChannelCounterResetProps):
    React.ReactElement => {
  const {
    deviceInstanceId,
    channelNo,
    disabled,
    label,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const actionId = channelResetActionId(channelNo);
  const channelVariables = createChannelVariables(channelNo);
  const statusValue = useContextDatasetStatusValue(deviceInstanceId, channelVariables.errorCounter, DatasetType.device);

  const [stageState, writeParameter] = useIoLinkWriteParameter(deviceInstanceId, actionId);
  const buttonDisabled = disabled || stageState === MethodStageExecutionStatus.InProgress;

  return (
    <div className="caparoc-channel-reset-counter">
      <div className="caparoc-channel-reset-counter__label">
        {`${t(label)} : ${statusValue?.value ?? 0}`}
      </div>
      <div className="caparoc-channel-reset-counter__button">
        <Button
          variant="secondary-small"
          block
          onClick={() => {
          // send new value to dataset, while waiting for new data
            dispatch(updateDeviceDatasetValues(deviceInstanceId, {
              targetDataset: DatasetType.device,
              values: [{
                value: 0,
                identRef: channelVariables.errorCounter,
                backupValue: undefined,
              }],
            }));
            writeParameter(3333, new Uint8Array([channelNo]));
          }}
          disabled={buttonDisabled}
        >
          {t('CAPAROC:locBUTTON__MODULE__RESET_CHANNEL_ERROR_COUNTER')}
        </Button>
      </div>
    </div>
  );
};

export default ChannelCounterReset;
