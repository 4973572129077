/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppRoutes from './AppRoutes';
import AppTitlebar from './AppTitlebar';
import Backstage from './components/Backstage';
import {
  requestDeviceCatalogDeviceList,
} from './store';
import {
  ENV_APP_MODE,
} from './enviroment';
import { setSystemHostName, setSystemUserName } from './store/systemInformation/actions';
import { getStoredLanguageCode, setAppUiLanguageCode } from './i18n-config';
import AppSplashScreen from './splash/AppSplashScreen/AppSplashScreen';
import { useTypedSelector } from './store/reduxStore';
import ModalApplicationMessageBox from './modal/ModalApplicationMessageBox/ModalApplicationMessageBox';
import { infrastructureService } from './services/InfrastructureService/InfrastructureService';
import './App.css';

const logConsole = console;

const App: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  const appInitialized = useTypedSelector((state) => state.systemInformation.appInitialized);

  useEffect(() => {
    // start scanning of communication interfaces for connected devices
    dispatch(requestDeviceCatalogDeviceList());
    // fetch user information
    infrastructureService.getUserInformation().then((res) => {
      dispatch(setSystemUserName(res.username));
      dispatch(setSystemHostName(res.hostname));
    // eslint-disable-next-line no-console
    }).catch((reason) => logConsole.log('reason', reason));
    if (ENV_APP_MODE !== 'CLOUD') {
      getStoredLanguageCode().then((lng) => {
        if (lng !== undefined) {
          setAppUiLanguageCode(lng);
        }
      // eslint-disable-next-line no-console
      }).catch((reason) => logConsole.log('reason', reason));
    }
  }, []);

  const app = appInitialized
    ? (
      <div className="AppRoot">
        <AppTitlebar />
        <Backstage />
        <div className="AppContent">
          <Router>
            <AppRoutes />
          </Router>
        </div>
        <ModalApplicationMessageBox />
      </div>
    )
    : <AppSplashScreen />;
  return <>{app}</>;
};

export default App;
