/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setContextParameterWarning } from '../../../store/contexthelp';
import InputBoxControl from '../../Basic/InputBoxControl/InputBoxControl';
import RangeSliderBoxControl from '../../Basic/RangeSliderBoxControl/RangeSliderBoxControl';
import { ParameterLabelColumns, ParameterUnitColumns, ParameterValueColumns } from '../ParameterControlColumnCount';
import './InputRangeSliderControl.css';

export interface InputRangeSliderValueProps {
  value: number;
  // eslint-disable-next-line no-unused-vars
  onValueChange: (value: number) => void;
  onValueFocus: () => void;
  onValueBlur: () => void;
  modified?: boolean;
  warning?: string;
}

export interface InputRangeSliderControlProps {
    label: string
    min: number;
    max: number;
    step: number;
    unit?: string;
    hiValue: InputRangeSliderValueProps;
    loValue: InputRangeSliderValueProps;
}

// eslint-disable-next-line max-len
export const InputRangeSliderControl: React.FC<InputRangeSliderControlProps> = (props: InputRangeSliderControlProps): React.ReactElement => {
  const {
    label, unit,
    hiValue, loValue,
    min, max, step,
  } = props;

  const [stateLoValue, setStateLoValue] = useState(loValue.value);
  const [stateHiValue, setStateHiValue] = useState(hiValue.value);
  const [sliderDragState, setSliderDragState] = useState(false);
  const [loValueWarning, setLoValueWarning] = useState<string | undefined>(undefined);
  const [hiValueWarning, setHiValueWarning] = useState<string | undefined>(undefined);
  const [showLoValueContextMessage, setShowLoValueContextMessage] = useState(false);
  const [showHiValueContextMessage, setShowHiValueContextMessage] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!sliderDragState) {
    if (hiValue.value !== stateHiValue) {
      setStateHiValue(hiValue.value);
    }

    if (loValue.value !== stateLoValue) {
      setStateLoValue(loValue.value);
    }
  }

  useEffect(() => {
    const inRange = (value: number): boolean => value >= min && value <= max;

    const updateLoWarningMessage = (message?: string) => {
      if (showLoValueContextMessage) {
        dispatch(setContextParameterWarning(message));
      }
      setLoValueWarning(message);
    };

    const updateHiWarningMessage = (message?: string) => {
      if (showHiValueContextMessage) {
        dispatch(setContextParameterWarning(message));
      }
      setHiValueWarning(message);
    };

    if (Number.isNaN(stateLoValue)) {
      updateLoWarningMessage(t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT'));
    } else if (!inRange(stateLoValue)) {
      updateLoWarningMessage(t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__INTERVALS', {
        INTERVAL: `${min}...${max}`,
      }));
    } else {
      updateLoWarningMessage(undefined);
    }

    if (Number.isNaN(stateHiValue)) {
      updateHiWarningMessage(t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT'));
    } else if (!inRange(stateHiValue)) {
      updateHiWarningMessage(t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__INTERVALS', {
        INTERVAL: `${min}...${max}`,
      }));
    } else {
      updateHiWarningMessage(undefined);
    }
  }, [stateLoValue, stateHiValue]);

  const warningMessage = `${loValueWarning ?? ''} \n ${hiValueWarning ?? ''}`;
  const warningClasses = loValueWarning !== undefined || hiValueWarning !== undefined ? 'invalid' : '';

  let loVarValue = stateLoValue;
  if (Number.isNaN(stateLoValue)) {
    loVarValue = min;
  } else if (stateLoValue < min || stateLoValue > max) {
    loVarValue = min;
  }

  let hiVarValue = stateHiValue;
  if (Number.isNaN(stateHiValue)) {
    hiVarValue = max;
  } else if (stateHiValue < min || stateHiValue > max) {
    hiVarValue = max;
  }

  return (
    <Row className="input-range-slider-control flex-nowrap align-items-center py-1 m-0 p-0 w-100">
      <div className={`col-${ParameterLabelColumns} input-range-slider-control__label d-flex direction-row justify-content-end m-0 p-0 px-2`}>
        <p>
          {label}
        </p>
      </div>
      <div className={`col-${ParameterValueColumns} m-0 p-0 px-1`}>
        <div className={`input-range-slider-control__value ${warningClasses} d-flex direction-row align-items-center justify-content-between`}>
          <div className="input-range-slider-control__value__input__low">
            <InputBoxControl
              type="number"
              displayFormat="%.2f"
              value={stateLoValue}
              onChange={(value) => {
                const parsedValue = parseFloat(value);
                setStateLoValue(parsedValue);
                loValue.onValueChange(parsedValue);
              }}
              warning={loValueWarning !== undefined ? ' ' : undefined}
              step={step}
              onFocus={() => {
                setShowLoValueContextMessage(true);
                dispatch(setContextParameterWarning(loValueWarning));
                loValue.onValueFocus();
              }}
              onBlur={() => {
                setShowLoValueContextMessage(false);
                dispatch(setContextParameterWarning(undefined));
                loValue.onValueBlur();
              }}
              edited={loValue.modified}
            />
          </div>
          <div className="input-range-slider-control__value__slider">
            <RangeSliderBoxControl
              loValue={loVarValue}
              hiValue={hiVarValue}
              onRangeChange={(newLoValue, newHiValue) => {
                setSliderDragState(true);
                if (newLoValue <= stateHiValue) {
                  setStateLoValue(newLoValue);
                }
                if (newHiValue >= stateLoValue) {
                  setStateHiValue(newHiValue);
                }
              }}
              onFinalRangeChange={(newLoValue, newHiValue) => {
                setSliderDragState(false);
                if (newLoValue <= stateHiValue) {
                  setStateLoValue(newLoValue);
                  loValue.onValueChange(newLoValue);
                }
                if (newHiValue >= stateLoValue) {
                  setStateHiValue(newHiValue);
                  hiValue.onValueChange(newHiValue);
                }
              }}
              minRangeValue={min}
              maxRangeValue={max}
              step={step}
            />
          </div>
          <div className="input-range-slider-control__value__input__high">
            <InputBoxControl
              type="number"
              displayFormat="%.2f"
              value={stateHiValue}
              onChange={(value) => {
                const parsedValue = parseFloat(value);
                setStateHiValue(parsedValue);
                hiValue.onValueChange(parsedValue);
              }}
              warning={hiValueWarning !== undefined ? ' ' : undefined}
              step={step}
              onFocus={() => {
                setShowHiValueContextMessage(true);
                dispatch(setContextParameterWarning(hiValueWarning));
                hiValue.onValueFocus();
              }}
              onBlur={() => {
                setShowHiValueContextMessage(false);
                dispatch(setContextParameterWarning(undefined));
                hiValue.onValueBlur();
              }}
              edited={hiValue.modified}
            />
          </div>
        </div>
        {/* <span className="helper-text" data-error={warningMessage} /> */}
        <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
          <div className="input-field-control__warning-text">{warningMessage}</div>
        </div>
      </div>
      <div className={`col-${ParameterUnitColumns} input-range-slider-control__unit h-100 m-0 p-0 px-1`}>
        <p>
          {unit}
        </p>
      </div>
    </Row>
  );
};
