/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  DeviceInformation,
} from '@gpt/commons';
import { Action } from 'redux';
import { ExecutionState } from '../../../common';
import { DeviceInstanceAction, DeviceInstanceActionPayload } from '../../common';

export const DEVICE_INSTANCE__ACTIVE_DEVICE__ACTIONS = 'DEVICE_INSTANCE__ACTIVE_DEVICE__ACTIONS';

export const ACTIVE_DEVICE__SET_EXECUTION_STATUS = 'ACTIVE_DEVICE__SET_EXECUTION_STATUS';
export const ACTIVE_DEVICE__SET_ACTIVE_DEVICE = 'ACTIVE_DEVICE__SET_ACTIVE_DEVICE';
export const ACTIVE_DEVICE__UPDATE_DEVICE_TAG = 'ACTIVE_DEVICE__UPDATE_DEVICE_TAG';

export interface ModelMetaInformation {
  [key: string]: string;
}

export interface ModelInstanceInformation {
  device: DeviceInformation;
  deviceInstanceId: string;
  stationInstanceId?: string;
  webWorkerInstanceRef: string | undefined;
  meta?: ModelMetaInformation;
}

export interface ActiveDeviceState {
  executionState: ExecutionState;
  modelInstance?: ModelInstanceInformation;
}

export interface SetActiveDevicePayload {
  device: DeviceInformation;
  deviceInstanceId: string;
  webWorkerInstanceRef: string;
  stationInstanceId?: string;
}

export type SetActiveDeviceAction = DeviceInstanceAction<typeof ACTIVE_DEVICE__SET_ACTIVE_DEVICE, SetActiveDevicePayload>

export type UpdateActiveDeviceTagAction = DeviceInstanceAction<typeof ACTIVE_DEVICE__UPDATE_DEVICE_TAG, string>

export type SetActiveDeviceExecutionStatusAction = DeviceInstanceAction<typeof ACTIVE_DEVICE__SET_EXECUTION_STATUS, ExecutionState>

export type typeActiveDeviceAction =
 | SetActiveDeviceAction
 | UpdateActiveDeviceTagAction
 | SetActiveDeviceExecutionStatusAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceDataStorageAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceActiveDevicePayload extends DeviceInstanceActionPayload {
  action: typeActiveDeviceAction;
}

export interface typeDeviceInstanceActiveDeviceAction extends Action<typeof DEVICE_INSTANCE__ACTIVE_DEVICE__ACTIONS> {
  payload: typeDeviceInstanceActiveDevicePayload;
}
