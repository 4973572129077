/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deviceWizardUpdateButtons } from '../../store';
import { ButtonState, ButtonRole } from '../../store/deviceWizard';
import { WizardViewPageProps } from '../WizardView/WizardViewPage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CPFileDevicePageProps extends WizardViewPageProps, PropsWithChildren<any> {
}

const CPFileDevicePage: React.FC<CPFileDevicePageProps> = (
  props: CPFileDevicePageProps,
): React.ReactElement => {
  const {
    active, children,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (active) {
      dispatch(deviceWizardUpdateButtons({
        source: 'CPFileDevicePage',
        abort: ButtonState.enabled,
        back: ButtonState.enabled,
        next: ButtonState.enabled,
        complete: ButtonState.hidden,
        pcta: ButtonRole.next,
      }));
    }
  }, [active]);

  return (
    <>{children}</>
  );
};

export default CPFileDevicePage;
