/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { UnsupportedControl } from '../../../UnsupportedControl';
import TextEditorControl from '../../TextEditorControl/TextEditorControl';
import './VariableStringEditorControl.css';
import { VariableEditorControlsProps } from '../types';
import useStringMessageContextHelp from '../hooks/useStringMessageContextHelp';

const VariableStringEditorControl:
React.FC<VariableEditorControlsProps> = (props: VariableEditorControlsProps):
React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp,
    showContextMessageDef, readonly, deviceInstanceId,
    noLabel,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warning, showContentHelp, hideContentHelp] = useStringMessageContextHelp(statusDescriptor, statusValue, showContextMessageDef, onShowContentHelp, onHideContentHelp);

  if (statusDescriptor === undefined) {
    return <div />;
  }
  if (statusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="VariableUIntegerEditorControl: Unsupported input object type" />;
  }
  if (statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.STRING) {
    return <UnsupportedControl text="VariableDateEditorControl: only FLOAT type is supported" />;
  }

  const { label } = statusDescriptor;
  return (
    <TextEditorControl
      label={noLabel === true ? undefined : t<string>(label)}
      value={statusValue === undefined ? 'N/A' : statusValue.value}
      displayFormat="%s"
      readonly={readonly ?? (statusValue === undefined || statusDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY)}
      warning={warning}
      onValueChange={(event) => {
        if (statusValue === undefined) {
          return;
        }
        // eslint-disable-next-line no-control-regex
        const valueOnlyAsciiChars = event.replace(/[^\x00-\x7F]/ig, '_');
        dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
          value: valueOnlyAsciiChars,
          backupValue: statusValue.backupValue,
          identRef: statusValue.identRef,
        }]));
      }}
      onFocus={() => showContentHelp()}
      onBlur={() => hideContentHelp()}
      modified={statusValue?.modified}
    />
  );
};

export default VariableStringEditorControl;
