/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import OnlineCardControl from '../OnlineCardControl/OnlineCardControl';
import VariableValueTableRow from '../../../VariableValueTableControl/VariableValueTableRow/VariableValueTableRow';
import './OperationalDataOnlineCard.scss';

export interface OperationalDataOnlineCardProps {
  deviceInstanceId: string;
  operationalView?: DeviceModelStatus.UI.Contactron.MotorStarterOperationalView;
}

const OperationalDataOnlineCard: React.FC<OperationalDataOnlineCardProps> = (props: OperationalDataOnlineCardProps):
  React.ReactElement => {
  const {
    operationalView,
    deviceInstanceId,
  } = props;

  const variableList = (operationalView?.items ?? []).map((item) => (
    <div key={`op-${deviceInstanceId}--${item}`} className="operational-data-variable-value">
      <VariableValueTableRow deviceInstanceId={deviceInstanceId} identRef={item} onStateChanged={() => 0} />
    </div>
  ));

  return (
    <OnlineCardControl label={operationalView?.label ?? ''}>
      {variableList}
    </OnlineCardControl>
  );
};

export default OperationalDataOnlineCard;
