/* eslint-disable max-len */
import React from 'react';
import { ChartScaleMark, ChartSegment } from '../Elements/types';
import ChartCardControl from '../ChartCardControl/ChartCardControl';
import './PowerBarControl.scss';
import { PowerBarChart } from './chart/PowerBarChart';

export interface PowerBarControlProps {
  label: string;
  unit: string;
  value: number;
  dragMinValue: number;
  dragMaxValue: number;
  chartSegments: ChartSegment[];
  chartMarks: ChartScaleMark[];
  displayFormat: string;
  showDragIndicator: boolean;
  // eslint-disable-next-line no-unused-vars
  onShowDragIndicator: (value: boolean) => void;
  onResetDragValues: () => void;
}

const PowerBarControl: React.FC<PowerBarControlProps> = (props: PowerBarControlProps)
: React.ReactElement => {
  const {
    label,
    value,
    dragMinValue,
    dragMaxValue,
    unit,
    chartSegments,
    chartMarks,
    displayFormat,
    showDragIndicator,
    onResetDragValues,
    onShowDragIndicator,
  } = props;

  return (
    <ChartCardControl label={label} showDragIndicator={showDragIndicator} onResetDragValues={onResetDragValues} onShowDragIndicator={onShowDragIndicator}>
      <PowerBarChart
        value={value}
        unit={unit}
        dragMinValue={dragMinValue}
        dragMaxValue={dragMaxValue}
        chartMarks={chartMarks}
        chartSegments={chartSegments}
        showDragValues={showDragIndicator}
        displayFormat={displayFormat}
      />
    </ChartCardControl>
  );
};

export default PowerBarControl;
