/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UnsupportedControl } from '../../../../../components/UnsupportedControl';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../../../../hooks/useContextDataset';
import { CompareModeType } from '../../../../../views/CompareView/common';
import { CompareStatusValueProps } from '../../../CompareTypes';
import useCompareMiddleButton from '../../../hooks/useCompareMiddleButton';
import useDispatchStringValue from '../../../hooks/useDispatchStringValue';
import useVariableValidationMessage from '../../../hooks/useVariableValidationMessage';
import CompareMACAddressValue from './CompareMACAddressValue/CompareMACAddressValue';
import { updateDeviceDatasetValues } from '../../../../../store/deviceInstances/store/deviceDataset';
import { setContextParameterHelp } from '../../../../../store/contexthelp';

const validateMacAddress = (macAddress?: string)
  : boolean => (/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(macAddress ?? '-'));

/**
 * Compare two values from Dataset Value : value vs externalValue
 * backupValue used as storage for value before modification
 *
 * @param props Control Properties
 * @returns
 */
const CompareMACAddressStatusValue
: React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
  : React.ReactElement | null => {
  const {
    compareMode,
    identRef, leftDataset, rightDataset,
    onShowContentHelp, onHideContentHelp,
    showImageAndSign,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const leftDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef, leftDataset);
  const leftValue = useContextDatasetStatusValue(deviceInstanceId, identRef, leftDataset);

  const rightDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef, rightDataset);
  const rightValue = useContextDatasetStatusValue(deviceInstanceId, identRef, rightDataset);

  const dispatchValue = useDispatchStringValue(deviceInstanceId);
  const warning = useVariableValidationMessage(leftDescriptor, leftValue);
  const [buttonFunction, onMiddleButtonClick] = useCompareMiddleButton(deviceInstanceId, leftValue, rightValue);

  const onValueValidationChange = useCallback((newValue: string, statusValue?: DeviceModelStatus.StatusValue): void => {
    if (statusValue === undefined) {
      return;
    }

    if (validateMacAddress(newValue)) {
      dispatchValue(newValue, statusValue);
    } else {
      dispatch(updateDeviceDatasetValues(deviceInstanceId, {
        targetDataset: leftDataset,
        values: [{
          identRef: statusValue.identRef,
          value: newValue,
          backupValue: statusValue.backupValue ?? statusValue.value,
        }],
      }));
    }
  }, [dispatchValue]);

  if (leftDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareMACAddressStatusValue: Unsupported type" />;
  }

  if (rightDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareMACAddressStatusValue: Unsupported type" />;
  }

  if (leftDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.MAC_ADDRESS) {
    return <UnsupportedControl text="CompareMACAddressStatusValue: only MAC_ADDRESS is supported" />;
  }

  if (rightDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.MAC_ADDRESS) {
    return <UnsupportedControl text="CompareMACAddressStatusValue: only MAC_ADDRESS is supported" />;
  }

  const equal = leftValue?.value === rightValue?.value;
  if (compareMode === CompareModeType.ShowDifference && equal) {
    return (null);
  }

  let readonly = leftDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY;
  if (compareMode === CompareModeType.ShowAll) {
    readonly = true;
  }

  const label = t(leftDescriptor.label);
  const help = t(leftDescriptor.help);

  return (
    <CompareMACAddressValue
      label={label}
      equal={equal}
      valueLeft={leftValue?.value}
      valueRight={rightValue?.value}
      onButtonClick={() => onMiddleButtonClick(buttonFunction)}
      modified={leftValue?.modified ?? false}
      readonly={readonly}
      onLeftValueChange={(ev) => onValueValidationChange(ev, leftValue)}
      warning={warning}
      onShowContentHelp={() => {
        dispatch(setContextParameterHelp({
          title: t(label),
          text: t(help),
        }));
        onShowContentHelp?.();
      }}
      onHideContentHelp={() => {
        dispatch(setContextParameterHelp({
          title: '',
          text: '',
        }));
        onHideContentHelp?.();
      }}
      buttonFunction={buttonFunction}
      showImageAndSign={showImageAndSign}
    />
  );
};

export default CompareMACAddressStatusValue;
