/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Button } from 'react-bootstrap';
import '../DeviceCard/DeviceCard.scss';
import useScanIndicator from '../hooks/useScanIndicator';

export interface DeviceCardNoDeviceProps {
  productImage: string;
  protocolTitle: string;
  protocolText: string;
  scanActive?: boolean;
}

const DeviceCardNoDevice: React.FC<DeviceCardNoDeviceProps> = (props: DeviceCardNoDeviceProps)
: React.ReactElement => {
  const {
    productImage,
    protocolTitle,
    protocolText,
    scanActive,
  } = props;

  const scanIndicatorText = useScanIndicator(protocolText, scanActive ?? false);

  return (
    <Button
      variant="default"
      className="no-devicecard-button-main p-0 m-0"
    >
      <div className="devicecard-device-container">
        <div className="devicecard-image-container">
          <img className="devicecard-nodevice-image m-0" alt="no-device" src={productImage} />
        </div>
        <div className="devicecard-nodevice-body">
          <div className="devicecard-base-title">{protocolTitle}</div>
          <div className="devicecard-base-text">{scanIndicatorText}</div>
        </div>
      </div>
    </Button>
  );
};

export default DeviceCardNoDevice;
