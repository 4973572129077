/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Action } from 'redux';

export const REQUEST_DEVICE_CATALOG_DEVICE_LIST = 'REQUEST_DEVICE_CATALOG_DEVICE_LIST';

export interface RequestDeviceCatalogDeviceListServiceAction
  extends Action<typeof REQUEST_DEVICE_CATALOG_DEVICE_LIST> {
  payload: string,
}

export type DeviceCatalogActionTypes = RequestDeviceCatalogDeviceListServiceAction;
