/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setContextParameterHelp, setContextParameterWarning } from '../../../../store/contexthelp';
import { UnsupportedControl } from '../../../UnsupportedControl';
import TextEditorControl from '../../TextEditorControl/TextEditorControl';
import './VariableBlobEditorControl.css';
import { base64ToBytes } from '../../../../store/linearizationDataset/middleware/base64';
import { VariableEditorControlsProps } from '../types';

const dec2hex = (i: number): string => (`0${i.toString(16)}`).slice(-2);

const GetStringMaxLength = (descriptor: DeviceModelStatus.DeviceModelDescriptor): number => {
  if (descriptor === undefined) {
    return 0;
  }
  if (descriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return 0;
  }
  if (descriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.STRING) {
    return 0;
  }
  return descriptor.valueType.length;
};

const VariableBlobEditorControl:
React.FC<VariableEditorControlsProps> = (props: VariableEditorControlsProps):
React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warning, setWarning] = useState<string | undefined>(undefined);
  const [showContextMessage, setShowContextMessage] = useState<boolean>(false);

  const statusValueLabel = t(statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? statusDescriptor.label : '');
  const statusValueHelp = t(statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? statusDescriptor.help : '');

  const { valueValidityDescription, valueValidity } = statusValue ?? {
    valueValidityDescription: undefined, valueValidity: DeviceModelStatus.StatusValueValidity.valid,
  };

  useEffect(() => {
    if (statusValue === undefined) {
      return;
    }

    const updateMessage = (message?: string) => {
      if (showContextMessage) {
        dispatch(setContextParameterWarning(message));
      }
      setWarning(message);
    };

    if (valueValidity === DeviceModelStatus.StatusValueValidity.valid) {
      updateMessage(undefined);
    } else {
      updateMessage(t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__MAXLENGTH', {
        MAXLENGTH: valueValidityDescription,
      }));
    }
  }, [statusValue, showContextMessage, valueValidityDescription, valueValidity]);

  if (statusDescriptor === undefined) {
    return <div />;
  }
  if (statusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="VariableUIntegerEditorControl: Unsupported input object type" />;
  }
  if (statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.BLOB) {
    return <UnsupportedControl text="VariableDateEditorControl: only BLOB type is supported" />;
  }

  let value = 'N/A';
  if (statusValue?.value !== undefined) {
    const base64str = statusValue.value as string;
    const byteArr = base64ToBytes(base64str);
    value = byteArr.reduce((acc, i) => `${acc}${dec2hex(i)} `, '');
  }

  const { label } = statusDescriptor;
  return (
    <TextEditorControl
      label={t<string>(label)}
      value={value}
      displayFormat="%s"
      readonly
      warning={warning}
      onValueChange={() => 0}
      onFocus={() => {
        setShowContextMessage(true);
        if (statusDescriptor !== undefined) {
          const maxLength = GetStringMaxLength(statusDescriptor);
          const message = t<string>('PARAMETER__VALID_STRING_LENGTH__HELP', {
            MAXLENGTH: `${maxLength}`,
          });
          dispatch(setContextParameterHelp({
            title: statusValueLabel,
            text: statusValueHelp,
            message,
          }));
        }
        onShowContentHelp?.();
      }}
      onBlur={() => {
        setShowContextMessage(false);
        dispatch(setContextParameterWarning(undefined));
        onHideContentHelp?.();
      }}
      modified={statusValue?.modified}
    />
  );
};

export default VariableBlobEditorControl;
