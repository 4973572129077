/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import { CompareModeType } from '../../views/CompareView/common';
import {
  CompareValuesPayload,
  COMPARE_VIEW__REDO_VALUES,
  COMPARE_VIEW__SET_COMPARE_DATASET_IDENT,
  COMPARE_VIEW__SET_COMPARE_METHOD,

  COMPARE_VIEW__WRITE_VALUES,

  typeCompareViewActionTypes,
} from './types';

export const compareViewSetCompareMethod = (payload: CompareModeType)
  : typeCompareViewActionTypes => ({
  type: COMPARE_VIEW__SET_COMPARE_METHOD,
  payload,
});

export const setCompareDatasetIdent = (payload: Services.DeviceModelServer.DatasetProviderService.DatasetIdent): typeCompareViewActionTypes => ({
  type: COMPARE_VIEW__SET_COMPARE_DATASET_IDENT,
  payload,
});

export const writeCompareValues = (targetInstance: string, data: CompareValuesPayload): typeCompareViewActionTypes => ({
  type: COMPARE_VIEW__WRITE_VALUES,
  payload: {
    targetInstance,
    data,
  },
});

export const redoCompareValues = (targetInstance: string, data: CompareValuesPayload): typeCompareViewActionTypes => ({
  type: COMPARE_VIEW__REDO_VALUES,
  payload: {
    targetInstance,
    data,
  },
});
