/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { ReduxControlProps } from '../../ReduxControlProps';
import withControlVisibility from '../../hoc/withControlVisibility';
import { useTypedSelector } from '../../../store';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { deviceDescriptorSelector } from '../../../store/deviceInstances/store/deviceDataset/selector';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';
import OperationalDataOnlineCard from './OperationalDataOnlineCard/OperationalDataOnlineCard';
import SystemStatusOnlineCard from './SystemStatusOnlineCard/SystemStatusOnlineCard';
import H3ManualControlOnlineCard from './H3ManualControlOnlineCard/H3ManualControlOnlineCard';
import SystemDataOnlineCard from './SystemDataOnlineCard/SystemDataOnlineCard';
import SystemTrendOnlineCard from './SystemTrendOnlineCard/SystemTrendOnlineCard';
import { ImageComponent } from '../../../components/ImageComponent';
import ReduxControl from '../../ReduxControl/ReduxControl';
import TabViewControl from '../../TabViewControl/TabViewControl';
import TabViewItem from '../../TabViewControl/TabViewItem/TabViewItem';
import useMenuCurrentTab, { TabData } from '../../hooks/TabMenuHooks/useMenuCurrentTab';
import './MotorStarterOnlineControl.scss';

const contactronMotorStaterSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
  targetDataset: DatasetType,
  identRef: IdentRef,
): DeviceModelStatus.UI.Contactron.OnlineControlH3MotorStarterControlType | undefined => {
  const descriptor = deviceDescriptorSelector(state, deviceInstanceId, targetDataset, identRef);
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  if (controlType?.type !== DeviceModelStatus.UI.ControlType.CTLCONTACTRON) {
    return undefined;
  }
  const { deviceViewType } = controlType;
  return deviceViewType.type === DeviceModelStatus.UI.Contactron.DeviceViewType.CTLONLINECONTROLH3MOTORSTARTER
    ? deviceViewType : undefined;
};

const controlTabData: TabData = {
  label: 'CONTACTRON:locmnuOnlineParameters_Control_Lbl',
  help: 'CONTACTRON:locmnuOnlineParameters_Control_Hlp',
  identRef: 'contactron-online-tabs--tab-control',
};

const nameplateTabData: TabData = {
  label: 'CONTACTRON:locMENUPARAMETERS_DEVICEIDENT__Lbl',
  help: 'CONTACTRON:locMENUPARAMETERS_DEVICEIDENT__Hlp',
  identRef: 'contactron-online-tabs--tab-nameplate',
};

const tabDataset = [
  controlTabData,
  nameplateTabData,
];

const ContactronH3MotorStarterOnlineControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const motorStater = useTypedSelector((state) => contactronMotorStaterSelector(state.deviceInstances, deviceInstanceId, DatasetType.device, identRef));
  const [currentTab, setCurrentTab] = useMenuCurrentTab(identRef, deviceInstanceId, tabDataset);

  return (
    <div className="contactron-motorstarter-online">
      <TabViewControl
        actionId="contactron-online-tabs"
        currentTab={currentTab}
        setCurrentTab={(tabid) => setCurrentTab(deviceInstanceId, identRef, tabid)}
      >
        <TabViewItem
          tabIdent={controlTabData.identRef}
          tabLabel={controlTabData.label}
          tabHelp={controlTabData.help}
          tabColor="normal"
        >
          <div className="contactron-motorstarter-online__control">
            <div className="contactron-motorstarter-online__item contactron-motorstarter-online__systemstatus">
              <SystemStatusOnlineCard statusView={motorStater?.statusView} deviceInstanceId={deviceInstanceId} />
            </div>
            <div className="contactron-motorstarter-online__item contactron-motorstarter-online__manual">
              <H3ManualControlOnlineCard controlView={motorStater?.manualControlView} deviceInstanceId={deviceInstanceId} />
            </div>
            <div className="contactron-motorstarter-online__item contactron-motorstarter-online__systemdata">
              <SystemDataOnlineCard dataView={motorStater?.dataView} deviceInstanceId={deviceInstanceId} />
            </div>
            <div className="contactron-motorstarter-online__item contactron-motorstarter-online__operational">
              <OperationalDataOnlineCard operationalView={motorStater?.operationView} deviceInstanceId={deviceInstanceId} />
            </div>
            <div className="contactron-motorstarter-online__item contactron-motorstarter-online__trend">
              <SystemTrendOnlineCard trendView={motorStater?.trendView} deviceInstanceId={deviceInstanceId} />
            </div>
            <div className="contactron-motorstarter-online__item contactron-motorstarter-online__picture">
              <ImageComponent imageResourceId={motorStater?.pictureRef ?? '--'} deviceInstanceId={deviceInstanceId} />
            </div>
          </div>
        </TabViewItem>
        <TabViewItem
          tabIdent={nameplateTabData.identRef}
          tabLabel={nameplateTabData.label}
          tabHelp={nameplateTabData.help}
          tabColor="normal"
        >
          <div className="contactron-motorstarter-online__nameplate">
            <ReduxControl identRef="OnlineParam__nameplate" deviceInstanceId={deviceInstanceId} onStateChanged={() => 0} />
          </div>
        </TabViewItem>
      </TabViewControl>
    </div>
  );
};

export default withControlVisibility(ContactronH3MotorStarterOnlineControl);
