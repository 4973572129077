/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
// eslint-disable-next-line max-len
import { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line no-unused-vars
const useDragIndicatorValues = (
  currentValue: number,
  initialMin?: number,
  initialMax?: number,
): [number, number, (number) => void] => {
  const [indicatorMinValue, setIndicatorMinValue] = useState<number>(initialMin ?? currentValue);
  const [indicatorMaxValue, setIndicatorMaxValue] = useState<number>(initialMax ?? currentValue);

  useEffect(() => {
    if (indicatorMinValue === undefined || indicatorMinValue > currentValue) {
      setIndicatorMinValue(currentValue);
    }
  }, [indicatorMinValue, currentValue]);

  useEffect(() => {
    if (indicatorMaxValue === undefined || indicatorMaxValue < currentValue) {
      setIndicatorMaxValue(currentValue);
    }
  }, [indicatorMaxValue, currentValue]);

  const resetValues = useCallback((value: number): void => {
    setIndicatorMinValue(value);
    setIndicatorMaxValue(value);
  }, []);
  return [indicatorMinValue, indicatorMaxValue, resetValues];
};

export default useDragIndicatorValues;
