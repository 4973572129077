/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useRef, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  setLinearizationDatasetPointCounter,
} from '../../../../../store/linearizationDataset/actions';
import { LinearizationTableItem } from '../../../../../store/linearizationDataset/types';
import InputBoxControl from '../../../../Basic/InputBoxControl/InputBoxControl';
import OverlayPortal from '../../../../OverlayPortal/OverlayPortal';
import { MaxRegression } from '../../MaxRegression/MaxRegression';
import { TableColumnHeader } from '../../TableColumnHeader/TableColumnHeader';
import { TableElement } from '../../TableElement/TableElement';
import { ActionSubmenuTypes, LinearizationActionSubmenu } from '../../TableExpand/LinearizationActionSubmenu';
import './FullTableView.css';

export interface FullTableViewProps {
    items: LinearizationTableItem[];
    expandIcon: string;
    header1: string;
    header2: string;
    pointsCounter: number,
    onXValueChange: (pointIndex: number, pointValue: string) => void;
    onYValueChange: (pointIndex: number, pointValue: string) => void;
    onAction: (action: ActionSubmenuTypes) => Promise<void>;
}

export const FullTableView:React.FC<FullTableViewProps> = (props: FullTableViewProps): React.ReactElement => {
  const {
    items, expandIcon, header1, header2, pointsCounter, onXValueChange, onYValueChange, onAction,
  } = props;

  const { t } = useTranslation();
  const menuPositionRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [showExpandMenu, setShowExpandMenu] = useState(false);

  const topPosition = menuPositionRef?.current?.getClientRects()[0].top;
  const leftPosition = menuPositionRef?.current?.getClientRects()[0].left;

  return (
    <div className="fullTableView-container">
      <div className="fullTableView-container-top">
        <div className="fullTableView-container-top-items">
          <div className="fullTableView-container-top-headeritem">
            <div className="fullTableView-container-top-headeritem-label">
              {t<string>('LINTABLE__TABLE_VIEW__USER_POINTS__LABEL')}
            </div>
            <div className="fullTableView-container-top-headeritem-inputbox">
              <InputBoxControl
                autofocus
                minValue={2}
                maxValue={30}
                type="number"
                displayFormat="%d"
                value={pointsCounter}
                onChange={(value) => {
                  const parsedValue = parseInt(value, 10);
                  if (!Number.isNaN(parsedValue)) {
                    dispatch(setLinearizationDatasetPointCounter(parsedValue));
                  }
                }}
              />
            </div>
          </div>
          <div className="fullTableView-container-top-headeritem">
            <MaxRegression />
          </div>
        </div>
        <div
          className="fullTableView-container-top-icon-container"
          ref={menuPositionRef}
          role="button"
          tabIndex={0}
          onClick={() => {
            setShowExpandMenu(!showExpandMenu);
          }}
          onKeyUp={() => {}}
        >
          <Image src={expandIcon} className="fullTableView-container-top-icon-container-icon" />
        </div>
        { showExpandMenu && (
        <OverlayPortal>
          <div
            role="none"
            className="fullTableView-container-top-expand-overlay"
            onClick={() => setShowExpandMenu(false)}
          >
            <div
              className="fullTableView-container-top-expand-container"
              ref={menuRef}
              style={{
                position: 'absolute',
                top: topPosition === undefined
                  ? undefined
                  : topPosition + 16,
                left: leftPosition === undefined
                  ? undefined
                  : leftPosition + 16,
              }}
            >
              <LinearizationActionSubmenu
                onAction={async (action) => {
                  await onAction(action);
                  setShowExpandMenu(false);
                }}
              />
            </div>
          </div>
        </OverlayPortal>
        )}
      </div>
      <div className="fullTableView-container_separator" />
      <div className="fullTableView-container-bottom-header">
        <TableColumnHeader header1={header1} header2={header2} />
        <TableColumnHeader header1={header1} header2={header2} />
        <TableColumnHeader header1={header1} header2={header2} />
      </div>
      <div className="fullTableView-container-bottom">
        {items.map((item, index) => (
          <div
            className="fullTableView-container-bottom-element"
            key={`tableElement-${index + 1}`}
          >
            <TableElement
              index={index}
              item={item}
              onYValueChange={onYValueChange}
              onXValueChange={onXValueChange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
