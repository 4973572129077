/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { AxisAlignedBoundingBox } from '../../AxisAlignedBoundingBox';
import './HorizontalAxis.css';

export interface DrawableLabel {
  label: number;
  isVisible: boolean;
}

export interface HorizontalAxisProps {
  labels: DrawableLabel[];
  name: string;
  boundingBox: AxisAlignedBoundingBox
}

const RoundValue = (value: number, base: number): string => (Math.round(value * base) / base).toString();

export const HorizontalAxis: React.FC<HorizontalAxisProps> = (props: HorizontalAxisProps)
: React.ReactElement => {
  const { labels, name, boundingBox } = props;
  const { minX, maxX } = boundingBox;
  const range = maxX - minX;

  const labelSpaces: number[] = [];
  for (let i = 0; i < labels.length - 1; i += 1) {
    const currentLabel = labels[i].label;
    const nextLabel = labels[i + 1].label;
    const space = ((nextLabel - currentLabel) / range) * 100;
    labelSpaces.push(space);
  }
  labelSpaces.push(0);

  const ticks = labels.map((label, index) => {
    const key = `hor-label-${index}`;
    const displayLabel = label.isVisible ? RoundValue(label.label, 100) : '';
    const space = labelSpaces[index];
    return (
      <div key={key} style={{ width: `${space}%` }}>
        <div className="HorizontalAxis-control-labelsContainer-label HorizontalAxis-control-labelsContainer-labelText">{displayLabel}</div>
      </div>
    );
  });

  return (
    <div className="HorizontalAxis-control" data-testid="horizontal-axis">
      <div className="HorizontalAxis-control-labelsContainer">
        {ticks}
      </div>
      <div className="HorizontalAxis-control-name">
        {name}
      </div>
    </div>
  );
};
