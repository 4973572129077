/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { SpecialComponents } from 'react-markdown/lib/ast-to-react';
import { NormalComponents } from 'react-markdown/lib/complex-types';
import { useTypedSelector } from '../../store';
import './Helpbar.scss';

export const Helpbar: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const menuTitle = useTypedSelector((state) => state.contextHelp.menu.title);
  const menuHelpText = useTypedSelector((state) => state.contextHelp.menu.text);
  const paramTitle = useTypedSelector((state) => state.contextHelp.parameter.title);
  const paramHelpText = useTypedSelector((state) => state.contextHelp.parameter.text);
  const paramMessage = useTypedSelector((state) => state.contextHelp.parameter.message);
  const paramWarning = useTypedSelector((state) => state.contextHelp.warning);

  const markdownComponents: Partial<NormalComponents & SpecialComponents> = {
    h1: ({ ...props }) => <p className="helpbar-markdown h2" {...props} />,
    p: ({ ...props }) => <p className="helpbar-markdown m-0 p-0 pb-1" {...props} />,
    ul: ({ ...props }) => <ul className="helpbar-markdown pl-3 mb-1" {...props} />,
    li: ({ ...props }) => <li className="helpbar-markdown m-0 p-0" {...props} />,
  };

  return (
    <div className="helpbar-container d-flex flex-column h-100" data-testid="helpbar">
      <div className="helpbar-container__title pb-2">
        <p>{t<string>('HELPBAR__TITLE')}</p>
      </div>
      <div className="helpbar-container__content w-100 h-100">
        <div className="helpbar-container__content__menu-context pb-2 h-100 w-100">
          <div className="helpbar-container__content__menu-context__title w-100 py-2">
            <p>{menuTitle}</p>
          </div>
          <div className="helpbar-container__content__menu-context__text w-100">
            <ReactMarkdown components={markdownComponents}>
              {menuHelpText}
            </ReactMarkdown>
          </div>
        </div>
        <div className="helpbar-container__content__param-context h-100 w-100">
          <div className="helpbar-container__content__param-context__title w-100 py-2">
            <p>{paramTitle}</p>
          </div>
          <div className="helpbar-container__content__param-context__warning w-100 py-2">
            <p>{paramWarning}</p>
          </div>
          <div className="helpbar-container__content__param-context__text w-100 py-2">
            <ReactMarkdown components={markdownComponents}>
              {paramHelpText}
            </ReactMarkdown>
          </div>
        </div>
        <div className="helpbar-container__content__param-message mt-3 w-100">
          <ReactMarkdown components={markdownComponents}>
            {paramMessage ?? ''}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default Helpbar;
