/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import { DatasetState, DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';

const isModifiedStatusValue = (varValue?: DeviceModelStatus.StatusValue)
  : boolean => varValue?.modified === true;

const isErrorStatusValue = (varValue?: DeviceModelStatus.StatusValue)
  : boolean => varValue?.valueValidity !== DeviceModelStatus.StatusValueValidity.valid;

interface StatusValueRecordType {
  xValue: DeviceModelStatus.StatusValue;
  yValue: DeviceModelStatus.StatusValue;
}

const selectLinearizationUserPointCount = (
  lincontrol: DeviceModelStatus.UI.LinearizationControlType,
  state?: DatasetState,
): number => state?.values[lincontrol.numberOfPoints]?.value ?? 0;

const selectLinearizationUserPointValues = (
  lincontrol: DeviceModelStatus.UI.LinearizationControlType,
  deviceDataset?: DatasetState,
): StatusValueRecordType[] => {
  if (deviceDataset === undefined) {
    return [];
  }

  const tableDesc = deviceDataset?.descriptors[lincontrol.userLinData];
  if (tableDesc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return [];
  }

  if (tableDesc.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.TABLE) {
    return [];
  }

  const count = selectLinearizationUserPointCount(lincontrol, deviceDataset);

  const recordVars: StatusValueRecordType[] = tableDesc.valueType.records
    .slice(0, count)
    .map((item) => {
      const desc = deviceDataset?.descriptors[item] as DeviceModelStatus.StatusDescriptor;
      const record = desc.valueType as DeviceModelStatus.StatusDescriptorValueTypeTableRecord;
      const xValue = deviceDataset.values[record.members[0]];
      const yValue = deviceDataset.values[record.members[1]];
      return { xValue, yValue };
    });
  return recordVars;
};

// eslint-disable-next-line import/prefer-default-export
export const isLintableModified = (lincontrol: DeviceModelStatus.UI.LinearizationControlType, deviceDataset?: DatasetState): boolean => {
  if (deviceDataset === undefined) {
    return false;
  }
  if (isModifiedStatusValue(deviceDataset.values[lincontrol.numberOfPoints])) {
    return true;
  }
  if (isModifiedStatusValue(deviceDataset.values[lincontrol.minDeviation])) {
    return true;
  }
  if (lincontrol.coldJunctionComp0 !== undefined && isModifiedStatusValue(deviceDataset.values[lincontrol.coldJunctionComp0])) {
    return true;
  }
  if (lincontrol.coldJunctionComp80 !== undefined && isModifiedStatusValue(deviceDataset.values[lincontrol.coldJunctionComp80])) {
    return true;
  }

  const userPointValues = selectLinearizationUserPointValues(lincontrol, deviceDataset);
  const modifiedUserPoint = userPointValues.find((point) => point.xValue.modified || point.yValue.modified);

  return modifiedUserPoint !== undefined;
};

export const isLintableError = (lincontrol: DeviceModelStatus.UI.LinearizationControlType, deviceDataset?: DatasetState): boolean => {
  if (deviceDataset === undefined) {
    return false;
  }
  if (isErrorStatusValue(deviceDataset.values[lincontrol.numberOfPoints])) {
    return true;
  }
  if (isErrorStatusValue(deviceDataset.values[lincontrol.minDeviation])) {
    return true;
  }
  if (lincontrol.coldJunctionComp0 !== undefined && isErrorStatusValue(deviceDataset.values[lincontrol.coldJunctionComp0])) {
    return true;
  }
  if (lincontrol.coldJunctionComp80 !== undefined && isErrorStatusValue(deviceDataset.values[lincontrol.coldJunctionComp80])) {
    return true;
  }

  const userPointValues = selectLinearizationUserPointValues(lincontrol, deviceDataset);
  const errorUserPoint = userPointValues.find((point) => isErrorStatusValue(point.xValue) || isErrorStatusValue(point.yValue));

  return errorUserPoint !== undefined;
};

export const lintableModifiedSelector = (state: DeviceInstancesState, deviceInstanceId: string | undefined, target: DatasetType, identRef: IdentRef): boolean => {
  const deviceDataset = deviceTargetDatasetSelector(state, deviceInstanceId, target);
  const linear = deviceDataset?.descriptors[identRef];
  if (linear?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return false;
  }
  const { controlType } = linear;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLLINEARIZATION) {
    return false;
  }
  return isLintableModified(controlType, deviceDataset);
};

export const lintableErrorSelector = (state: DeviceInstancesState, deviceInstanceId: string | undefined, target: DatasetType, identRef: IdentRef): boolean => {
  const deviceDataset = deviceTargetDatasetSelector(state, deviceInstanceId, target);
  const linear = deviceDataset?.descriptors[identRef];
  if (linear?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return false;
  }
  const { controlType } = linear;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLLINEARIZATION) {
    return false;
  }
  return isLintableError(controlType, deviceDataset);
};
