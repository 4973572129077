/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { PropsWithChildren } from 'react';
import './CompareParameterViewLayout.scss';

export type CompareParameterViewLayoutProps = PropsWithChildren<any>

const CompareParameterViewLayout
// eslint-disable-next-line max-len
: React.FC<CompareParameterViewLayoutProps> = (props: CompareParameterViewLayoutProps): React.ReactElement => {
  const { children } = props;
  return (
    <div className="compare-parameter-view-layout">
      <div className="compare-parameter-view-layout__left d-flex align-items-center">
        {children[0] ?? (null)}
      </div>
      <div className="compare-parameter-view-layout__center d-flex align-items-center">
        {children[1] ?? (null)}
      </div>
      <div className="compare-parameter-view-layout__right d-flex align-items-center">
        {children[2] ?? (null)}
      </div>
    </div>
  );
};

export default CompareParameterViewLayout;
