/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import { deviceWizardInitButtons } from '../../../store/deviceWizard';
import { closeCxeDeviceParameterizationApp } from '../../../services/clipxEngineerIntegrationService';
import WizardView from '../../WizardView/WizardView';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const EmptyPage: React.FC<WizardViewPageProps> = (props: WizardViewPageProps) => <div />;

export interface FailedWizardCxeProps {
  title: string;
}

const FailedWizardCxe: React.FC<FailedWizardCxeProps> = (props: FailedWizardCxeProps): React.ReactElement => {
  const { t } = useTranslation();
  const { title } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(deviceWizardInitButtons({
      abort: ButtonState.enabled,
      back: ButtonState.disabled,
      next: ButtonState.disabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.abort,
    }));
  }, []);

  return (
    <WizardView
      title={t<string>(title)}
      onWizardExit={async () => {
        closeCxeDeviceParameterizationApp({
          result: 'cancelled',
        });
      }}
    >
      <EmptyPage title="EMPTY" />
    </WizardView>
  );
};

export default FailedWizardCxe;
