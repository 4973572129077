/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './ButtonWithDescriptionControl.scss';
import { Button } from 'react-bootstrap';
import { ForceDialogControl } from '../../ForceDialogControl/ForceDialogControl';
import { sPortMinMCRResetDeviceAction, sPortMinMCRWriteAction } from '../../../../store/deviceInstances/middleware/deviceMethod';
import { ForceDialogPasswordContext } from '../../Context/ForceDialogContext';

export interface ButtonDescriptionControlProps {
  title: string;
  text: string;
  address: number;
  resetCode: number;
  buttonLabel: string;
  dialogTitle: string;
  dialogMessage: string;
  deviceInstanceId: string;
  actionId: string;
  resetDevice?: boolean;
}

const ButtonWithDescriptionControl: React.FC<ButtonDescriptionControlProps> = (
  props: ButtonDescriptionControlProps,
)
: React.ReactElement => {
  const {
    title, text, address, resetCode, dialogTitle, dialogMessage,
    buttonLabel, actionId, deviceInstanceId, resetDevice,
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const { password } = useContext(ForceDialogPasswordContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div>
      <div className="buttonwithdescription-container">
        <div className="buttonwithdescription-title">
          {t(title)}
        </div>
        <div className="buttonwithdescription-text">
          {t(text)}
        </div>
        <div className="buttonwithdescription-button">
          <Button
            className="button-text w-100"
            variant="secondary"
            onClick={() => {
              setShowDialog(true);
            }}
            disabled={!enabled}
          >
            {t<string>(buttonLabel)}
          </Button>
        </div>
      </div>
      <ForceDialogControl
        title={dialogTitle}
        message={dialogMessage}
        actionId={actionId}
        showDialog={showDialog}
        deviceInstanceId={deviceInstanceId}
        onAccept={() => {
          setEnabled(false);
          if (resetDevice) {
            dispatch(sPortMinMCRResetDeviceAction({
              actionId,
              targetInstance: deviceInstanceId,
              memoryAddress: address,
              ui16value: resetCode,
              password,
            }));
          } else {
            dispatch(sPortMinMCRWriteAction({
              actionId,
              targetInstance: deviceInstanceId,
              memoryAddress: address,
              ui16value: resetCode,
              password,
            }));
          }
          setShowDialog(false);
        }}
        onCancel={() => {
          setShowDialog(false);
        }}
        onFailed={() => {
          setEnabled(true);
        }}
        onSuccess={() => {
          setEnabled(true);
        }}
      />
    </div>
  );
};

export default ButtonWithDescriptionControl;
