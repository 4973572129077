/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useContext, useEffect, useState } from 'react';
import { DeviceModelStatus, Services } from '@gpt/commons';
import { isAbortError } from '@gpt/cxe-dp-integration';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FILE_EXTENTION__CXEDP, MIME_TYPE__CXEDP } from '../../helpers/constants';
import { createBackupFilename, createDatasetIdent } from '../../helpers/functions';
import AppFileSystemService from '../../services/AppService/AppFileSystemService';
import { showApplicationMessage } from '../../store/applicationMessage/actions';
import { LineToDiv } from '../TextFormatter/TextFormatter';
import './CreateBackupComponent.scss';
import { deviceWizardSetBackupFilename } from '../../store/deviceWizard/actions';
import { useContextActiveDeviceInstance } from '../../store/deviceInstances/hooks/ActiveDeviceHooks';
import { WebWorkerContext } from '../../WebWorkerContext';
import createDeviceDatasetBackupContent from '../../helpers/createDeviceDatasetBackupContent';
import { useTypedSelector } from '../../store';

export interface CreateBackupComponentProps {
  title?: string;
  description?: string;
  onBackupCreated?: () => void;
  deviceInstanceId: string;
}

const CreateBackupComponent:
React.FC<CreateBackupComponentProps> = (props: CreateBackupComponentProps): React.ReactElement => {
  const {
    onBackupCreated, title, description, deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { webWorkerDeviceManager } = useContext(WebWorkerContext);
  const activeDevice = useContextActiveDeviceInstance(deviceInstanceId);
  const previewMenu = useTypedSelector((state) => {
    const deviceInstance = state.deviceInstances.instances[deviceInstanceId]?.deviceDataset.user.descriptors;
    return deviceInstance?.APP_REVIEWDEVICE === undefined
      ? undefined
      : (deviceInstance.APP_REVIEWDEVICE as DeviceModelStatus.ApplicationDescriptor).appType.menu;
  });

  const [userComment, setUserComment] = useState<string>(t<string>('CREATE_BACKUP__DESCRIPTION_TEMPLATE'));

  useEffect(() => {
    if (activeDevice?.deviceInstanceId !== undefined) {
      dispatch(deviceWizardSetBackupFilename(''));
    }
  }, []);

  return (
    <div className="create-backup-component">
      <div className="create-backup-component__title">
        {title ?? t<string>('CREATE_BACKUP__TITLE')}
      </div>
      <div className="create-backup-component__message">
        <LineToDiv>
          {description ?? t<string>('CREATE_BACKUP__MESSAGE')}
        </LineToDiv>
      </div>
      <div className="create-backup-component__buttons">
        <div className="create-backup-component__button_create_backup_file w-100">
          <Button
            block
            variant="tertiary"
            onClick={() => {
              if (activeDevice === undefined) {
                return;
              }

              const datasetId = createDatasetIdent('backup');
              createDeviceDatasetBackupContent({
                webWorkerDeviceManager,
                activeDevice,
                caption: datasetId,
                datasetId,
                datasetType: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType.COMPLETE,
                previewMenu,
                userComment,
              })
                .then((contents) => {
                  const datasetFilename = createBackupFilename(activeDevice.device);
                  return {
                    suggestedName: datasetFilename,
                    mimeType: MIME_TYPE__CXEDP,
                    extensions: [FILE_EXTENTION__CXEDP],
                    contents,
                  };
                })
                .then(AppFileSystemService.ExportLocalFile)
                .then((exportResult) => {
                  dispatch(deviceWizardSetBackupFilename(exportResult.fileName));
                  dispatch(showApplicationMessage('success', 'SAVE_BACKUP_FILE__SUCCESS'));
                  onBackupCreated?.();
                })
                .catch((err) => {
                  dispatch(deviceWizardSetBackupFilename(''));
                  const action = isAbortError(err)
                    ? showApplicationMessage('warning', 'SAVE_BACKUP_FILE__ERROR__CANCELLED')
                    : showApplicationMessage('error', 'SAVE_BACKUP_FILE__FAIL');
                  dispatch(action);
                });
            }}
          >
            {t<string>('CREATE_BACKUP__BUTTON_CREATE_BACKUP_FILE')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateBackupComponent;
