/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceInformation } from '@gpt/commons';
import {
  InstantiateDevicePayload,
  typeDeviceInstantiationMiddlewareActions,
} from './types';
import { ExternalFileContent } from '../../../types';

export const instantiateDevice = (payload: InstantiateDevicePayload)
  : typeDeviceInstantiationMiddlewareActions => ({
  type: 'DEVICE_INSTANTIATION__INSTANTIATE_DEVICE',
  payload,
});

export const instantiateDeviceOfflineExternalFile = (content: ExternalFileContent, targetInstance?: string)
  : typeDeviceInstantiationMiddlewareActions => ({
  type: 'DEVICE_INSTANTIATION__INSTANTIATE_DEVICE__OFFLINE__EXTERNAL_FILE',
  payload: {
    targetInstance,
    content,
  },
});

export const instantiateLatestDeviceType = ()
  : typeDeviceInstantiationMiddlewareActions => ({
  type: 'DEVICE_INSTANTIATION__INSTANTIATE_LATEST_DEVICE_TYPE',
});

export const storeLatestDeviceType = (payload: DeviceInformation)
  : typeDeviceInstantiationMiddlewareActions => ({
  type: 'DEVICE_INSTANTIATION__STORE_LATEST_DEVICE_TYPE',
  payload,
});

export const disposeDevice = (targetInstance: string): typeDeviceInstantiationMiddlewareActions => ({
  type: 'DEVICE_INSTANTIATION__DISPOSE_DEVICE',
  payload: targetInstance,
});
