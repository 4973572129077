/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import useDropDownContextHelp from './hooks/useDropDownContextHelp';
import { SelectEditorControl } from '../../../components/ParameterEditor';
import TextEditorControl from '../../../components/ParameterEditor/TextEditorControl/TextEditorControl';

export interface DropDownOptions {
  [value: number]: string;
}

export interface Quint4DropDownProps {
    value: number;
    valueModified: boolean;
    label: string;
    help: string;
    // eslint-disable-next-line no-unused-vars
    onValueChange: (value: number) => void;
    onShowContentHelp?: () => void;
    onHideContentHelp?: () => void;
    showContextMessageDef?: boolean;
    warning?: string;
    readonly?: boolean;
  }

export interface Quint4DropDownBaseProps extends Quint4DropDownProps {
    options: DropDownOptions,
}

const Quint4DropDownBaseControl
:React.FC<Quint4DropDownBaseProps> = (props: Quint4DropDownBaseProps):
  React.ReactElement => {
  const {
    label,
    help,
    value,
    valueModified,
    options,
    onShowContentHelp, onHideContentHelp, readonly,
    showContextMessageDef,
    warning,
    onValueChange,
  } = props;
  const { t } = useTranslation();

  const [showContextHelp, hideContentHelp] = useDropDownContextHelp(
    label,
    help,
    warning,
    showContextMessageDef,
    onShowContentHelp,
    onHideContentHelp,
  );

  const createSelectEditorControl = () => (
    <SelectEditorControl
      label={`${t(label)}`}
      value={value}
      options={options}
      edited={valueModified}
      warning={warning}
      onChange={(event) => onValueChange(parseInt(event.target.value, 10))}
      onShowContentHelp={() => showContextHelp()}
      onHideContentHelp={() => hideContentHelp()}
    />
  );

  const createReadOnlySelectEditorControl = () => (
    <TextEditorControl
      label={`${t(label)}`}
      displayFormat="%s"
      value={options[value] ?? '???'}
      readonly
      warning={warning}
      onValueChange={() => 0}
      onFocus={() => showContextHelp()}
      onBlur={() => hideContentHelp()}
      modified={valueModified}
    />
  );

  const control = readonly ? createReadOnlySelectEditorControl() : createSelectEditorControl();

  return (
    <>{control}</>
  );
};

export default Quint4DropDownBaseControl;
