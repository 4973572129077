/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Services } from '@gpt/commons';
import {
  StationActionTypes,
  StationCreateDevicePayload,
  StationUpdateDataPayload,
} from './types';

export const stationSetConnectionStatus = (
  deviceInstanceId: string,
  status: Services.DeviceModelServer.ConnectionService.ConnectionStatus,
): StationActionTypes => ({
  type: 'STATION__CHILD_INSTANCE__SET_CONNECTION_STATUS',
  payload: {
    status,
    deviceInstanceId,
  },
});

export const stationUpdateData = (
  payload: StationUpdateDataPayload,
): StationActionTypes => ({
  type: 'STATION__CHILD_INSTANCE__UPDATE_DATA',
  payload,
});

export const stationCreateDevice = (
  payload: StationCreateDevicePayload,
): StationActionTypes => ({
  type: 'STATION__CHILD_INSTANCE__CREATE_DEVICE',
  payload,
});

export const stationDeleteDevice = (
  deviceInstance: string,
): StationActionTypes => ({
  type: 'STATION__CHILD_INSTANCE__DELETE_DEVICE',
  payload: deviceInstance,
});
