/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { ENV_APP_MODE, ENV_CXEDPTSERVICE_HOST, ENV_CXEDPTSERVICE_PORT } from '../../enviroment';
import { LocalBackendVersionStatus } from './useBackendVersion';
import { NatsConnectionContext, NatsServerConnectionState } from '../../natsConnection';

// eslint-disable-next-line no-unused-vars
export enum ServiceProcessResponse {
  // eslint-disable-next-line no-unused-vars
  Ok = 'ok',
  // eslint-disable-next-line no-unused-vars
  Reject = 'reject',
  // eslint-disable-next-line no-unused-vars
  ServiceNotFound = 'servicenotfound'
}

const localBackendConnection = (): Promise<ServiceProcessResponse> => new Promise<ServiceProcessResponse>((resolve, reject) => {
  axios
    .get(`${ENV_CXEDPTSERVICE_HOST}:${ENV_CXEDPTSERVICE_PORT}/api/v4/service/start`, { timeout: 1000 })
    .then((res) => res.data)
    // ok, reject, servicenotfound,
    .then((data) => resolve(data.Response))
    .catch((err) => reject(err));
});

export const useCheckBackendConnection = (versionStatus: LocalBackendVersionStatus): ServiceProcessResponse => {
  const [backendStatus, setBackendStatus] = useState<ServiceProcessResponse>(ServiceProcessResponse.ServiceNotFound);
  const { connect, state } = useContext(NatsConnectionContext);

  useEffect(() => {
    if (ENV_APP_MODE !== 'CLOUD') {
      return;
    }

    if (backendStatus === ServiceProcessResponse.Ok && state !== NatsServerConnectionState.Connected) {
      try {
        connect();
      } catch (err) {
        console.log('Cannot connect to NATS', err);
      }
    }
  }, [backendStatus, connect, state]);

  useEffect(() => {
    if (ENV_APP_MODE !== 'CLOUD') {
      setBackendStatus(ServiceProcessResponse.Ok);
      return;
    }

    const backendConnection = async () => {
      let status: ServiceProcessResponse = ServiceProcessResponse.ServiceNotFound;
      try {
        status = await localBackendConnection();
      } catch (err) {
        console.log('localBackendConnection ==> ServiceProcessResponse.ServiceNotFound', err);
        setBackendStatus(ServiceProcessResponse.ServiceNotFound);
      }

      if (status !== ServiceProcessResponse.Ok) {
        setTimeout(() => { backendConnection(); }, 2000);
      }
      setBackendStatus(status);
    };

    backendConnection();
  }, [versionStatus, setBackendStatus, state]);
  return backendStatus;
};
