/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { ReduxControlProps } from '../ReduxControlProps';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import withControlVisibility from '../hoc/withControlVisibility';
import { useTypedSelector } from '../../store/reduxStore';
import { DatasetContext } from '../../views/DatasetContext';
import { LedLinkControl } from './component/LedLinkControl';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetState } from '../../store/deviceInstances/store/deviceDataset/types';

const selectControlType = (identRef: IdentRef, dataset?: DatasetState)
: DeviceModelStatus.UI.LedLinkControlType | undefined => {
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLLEDLINK) {
    return undefined;
  }
  return controlType;
};

const selectLedLinkVariableVisibility = (identRef: IdentRef, dataset?: DatasetState): boolean => {
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return false;
  }
  const varDescriptor = dataset?.descriptors[controlType.variable];
  if (varDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return false;
  }
  return varDescriptor.visibility ?? true;
};

const selectLedLinkVariableValue = (identRef: IdentRef, dataset?: DatasetState)
: DeviceModelStatus.StatusValue | undefined => {
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return undefined;
  }
  return dataset?.values[controlType.variable];
};

const LedLinkReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const controlType = useTypedSelector((state) => selectControlType(identRef, deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)));
  if (controlType === undefined) {
    return <UnsupportedControl text={`LedLinkReduxControl: Undefined object ${identRef}`} />;
  }

  const variableValue = useTypedSelector((state) => selectLedLinkVariableValue(identRef, deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)));
  if (variableValue === undefined) {
    return <UnsupportedControl text={`LedLinkReduxControl: Undefined variable object ${identRef}`} />;
  }

  const visibility = useTypedSelector((state) => selectLedLinkVariableVisibility(identRef, deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)));
  if (!visibility) {
    return <></>;
  }

  const behaviour = controlType.BEHAVIOUR.find((beh) => beh.value === variableValue?.value);
  const { color, label, link } = behaviour === undefined ? controlType.DEFAULT : behaviour;
  return <LedLinkControl title={t(label)} color={color} link={link} />;
};

export default withControlVisibility(LedLinkReduxControl);
