/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import {
  Accordion, AccordionContext, Card, useAccordionToggle,
} from 'react-bootstrap';
import RadioEditorControl from '../ParameterEditor/RadioEditorControl';
import './SelectableAccordion.scss';

export interface SelectableAccordionItem {
  id: string;
  title: string;
  createControl: () => React.ReactElement | undefined;
}

export interface SelectableAccordionProps {
  items: SelectableAccordionItem[];
  line?: boolean;
  group: string;
  defaultActiveKey?: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (eventKey: string) => void;
}

interface ContextAwareToggleProps {
  title: string;
  eventKey: string;
  group: string;
  // eslint-disable-next-line no-unused-vars
  callback: (eventKey: string) => void;
}

const ContextAwareToggle = (props: ContextAwareToggleProps): React.ReactElement => {
  const currentEventKey = useContext(AccordionContext);
  const {
    eventKey, title, callback, group,
  } = props;

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isSelected = currentEventKey === eventKey;

  return (
    <RadioEditorControl
      label={title}
      id={eventKey}
      readonly={false}
      value={isSelected}
      group={group}
      onChange={decoratedOnClick}
    />
  );
};

// eslint-disable-next-line max-len
export default function SelectableAccordion(props: SelectableAccordionProps): React.ReactElement {
  const {
    items, line, onClick, group, defaultActiveKey,
  } = props;

  const lineClass = line === undefined || line ? 'accordion-content-line' : 'accordion-content-line border-0';

  const cards = items.map((item) => {
    const child = item.createControl();
    return (
      <Card className="accordion-content__card border-0" key={item.id}>
        <Card.Header className="d-flex justify-content-start align-items-center border-0 accordion-header p-0 pb-1">
          <ContextAwareToggle
            eventKey={item.id}
            title={item.title}
            group={group}
            callback={() => onClick(item.id)}
          />
        </Card.Header>
        <Accordion.Collapse eventKey={item.id}>
          <Card.Body className="p-0">
            {child !== undefined
            && (
            <div className="accordion-content-container">
              <div className={lineClass} />
              <div className="accordion-content">
                {child}
              </div>
            </div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  });

  return (
    <div className="accordion-container">
      <Accordion
        className="d-flex flex-column align-items-start"
        defaultActiveKey={defaultActiveKey ?? (items.length > 0 ? items[0].id : undefined)}
      >
        {cards}
      </Accordion>
    </div>
  );
}
