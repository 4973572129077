/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import i18next from 'i18next';
import { v4 } from 'uuid';
import { Services } from '@gpt/commons';
import { Dispatch, Middleware } from 'redux';
import {
  i18nWebServiceActionTypes,
  I18N__WEBSERVICE__INITIALIZE,
  I18N__WEBSERVICE__REQUEST,
} from '../types';
import {
  i18nServiceResponseTypes, i18nServiceTranslationRequest,
} from './interface/events';
import deviceResources from '../../../assets/i18n';

// let i18nWebWorker: SharedWorker;
// let initialized = false;
let resourceUrl: string;

// eslint-disable-next-line @typescript-eslint/no-empty-function, no-unused-vars, @typescript-eslint/no-unused-vars
const debug = (text): void => { }; // { console.log(text); };

const fetchTranslation = async (
  request: i18nServiceTranslationRequest,
): Promise<i18nServiceResponseTypes> => {
  const { namespace, language, requestId } = request;
  if (deviceResources[language]?.[namespace] !== undefined) {
    return {
      kind: 'I18NSERVICE_TRANSLATION_RESPONSE',
      requestId,
      namespace,
      language,
      dict: deviceResources[language][namespace],
    };
  }

  const resourceFile = `${resourceUrl}i18n/${namespace}/${namespace}_${language}.json`;
  let response;
  try {
    response = await fetch(resourceFile, {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Headers': '*',
        'Access-Control-Request-Method': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': '*',
        Connection: 'keep-alive',
        Accept: 'application/json',
      },
    });
  } catch (ex: any) {
    debug(`!!!!!!!!!!!! exception !!!!!!!!!!!!!!! ${ex.message}`);
  }

  if (response.ok) {
    try {
      const json = await response.json();
      const dict = json as Services.DeviceModel.DeviceI18nDictionary;
      return {
        kind: 'I18NSERVICE_TRANSLATION_RESPONSE',
        requestId,
        namespace,
        language,
        dict,
      };
    } catch (err: any) {
      return {
        kind: 'I18NSERVICE_FAILED_RESPONSE',
        requestId,
        message: err.message,
      };
    }
  }

  if (response.status === 404) {
    const message = `Dictionary not found ${resourceFile} -> ${response.status} ${response.statusText}`;
    return {
      kind: 'I18NSERVICE_FAILED_NOT_FOUND',
      requestId,
      message,
    };
  }

  return {
    kind: 'I18NSERVICE_FAILED_RESPONSE',
    requestId,
    message: `Cannot fetch dictionary ${response.status} ${response.statusText}`,
  };
};

export const i18nWebServiceMiddleware = (): Middleware => () => (next: Dispatch) => async <A extends i18nWebServiceActionTypes>(action: A): Promise<A> => {
  // if (!initialized) {
  //   // TODO: Language Change
  //   i18next.on('languageChanged', (lng: string) => {
  //     const deviceInstances = deviceInstancesStoreSelector(api.getState());
  //     const activeDeviceState = deviceInstanceActiveDeviceInstance(deviceInstances);
  //     const device = activeDeviceState?.device;
  //     if (device === undefined) {
  //       return;
  //     }
  //     const nm = device.catalog.i18n.family;
  //     if (!i18next.hasResourceBundle(lng, nm)) {
  //       const request: i18nServiceTranslationRequest = {
  //         kind: 'I18NSERVICE_TRANSLATION_REQUEST',
  //         requestId: `i18n-change-${v4()}`,
  //         language: lng,
  //         namespace: nm,
  //       };
  //       fetchTranslation(request)
  //         .then((response) => {
  //           switch (response.kind) {
  //             case 'I18NSERVICE_EVENT_LOG':
  //               break;
  //             case 'I18NSERVICE_INIT_RESPONSE':
  //               break;
  //             case 'I18NSERVICE_FAILED_RESPONSE':
  //               break;
  //             case 'I18NSERVICE_TRANSLATION_RESPONSE': {
  //               const { dict, language, namespace } = response;
  //               i18next
  //                 .addResourceBundle(language, namespace, dict)
  //                 .reloadResources();
  //             }
  //               break;
  //             default:
  //               debug(`Unknown response ${response as any}`);
  //           }
  //         })
  //         .catch((err) => debug('Failed'));
  //     }
  //   });
  //   initialized = true;
  // }

  switch (action.type) {
    case I18N__WEBSERVICE__INITIALIZE: {
      const { url } = action.payload;
      resourceUrl = url;
      break;
    }

    case I18N__WEBSERVICE__REQUEST: {
      const { namespace, language } = action.payload;
      const request: i18nServiceTranslationRequest = {
        kind: 'I18NSERVICE_TRANSLATION_REQUEST',
        requestId: `i18n-trans-${v4()}`,
        namespace,
        language,
      };
      fetchTranslation(request)
        .then((response) => {
          switch (response.kind) {
            case 'I18NSERVICE_EVENT_LOG':
              break;
            case 'I18NSERVICE_INIT_RESPONSE':
              break;
            case 'I18NSERVICE_FAILED_RESPONSE':
              break;
            case 'I18NSERVICE_TRANSLATION_RESPONSE': {
              const { dict } = response;
              i18next
                .addResourceBundle(language, namespace, dict)
                .reloadResources();
            }
              break;
            default:
              debug(`Unknown response ${response as any}`);
          }
        })
        .catch(() => debug('Failed'));
      break;
    }
    default:
  }
  const result = next(action);
  return result;
};
