/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
export const DEVICE_WIZARD__UPDATE_BUTTONS = 'DEVICE_WIZARD__UPDATE_BUTTONS';
export const DEVICE_WIZARD__SET_BUTTON_STATE = 'DEVICE_WIZARD__SET_BUTTON_STATE';
export const DEVICE_WIZARD__INIT_BUTTONS = 'DEVICE_WIZARD__INIT_BUTTONS';
export const DEVICE_WIZARD__DISABLE_ALL_BUTTONS = 'DEVICE_WIZARD__DISABLE_ALL_BUTTONS';
export const DEVICE_WIZARD__RESET_FOCUS = 'DEVICE_WIZARD__RESET_FOCUS';

export const DEVICE_WIZARD__SET_BACKUP_FILENAME = 'DEVICE_WIZARD__SET_BACKUP_FILENAME';

export enum ButtonState {
  // eslint-disable-next-line no-unused-vars
  enabled = 'enabled',
  // eslint-disable-next-line no-unused-vars
  disabled = 'disabled',
  // eslint-disable-next-line no-unused-vars
  hidden = 'hidden',
}

export enum ButtonRole {
  // eslint-disable-next-line no-unused-vars
  abort = 'abort',
  // eslint-disable-next-line no-unused-vars
  back = 'back',
  // eslint-disable-next-line no-unused-vars
  next = 'next',
  // eslint-disable-next-line no-unused-vars
  complete = 'complete',
  // eslint-disable-next-line no-unused-vars
  load = 'load',
}

/**
 * label:
 *  - string    : label string
 *  - undefined : use current state label
 */
export interface ButtonConfig {
  state: ButtonState;
  label?: string;
}

/**
 * label:
 *  - string    : label string
 */
export interface WizardButtonState {
  label: string;
  state: ButtonState;
}

/**
 * Defines if and how each wizard button is displayed.
 */
export interface DeviceWizardState {
  abort: WizardButtonState;
  back: WizardButtonState;
  next: WizardButtonState;
  complete: WizardButtonState;
  load?: WizardButtonState;
  pcta: ButtonRole;
  focusPcta?: ButtonRole;
  backupFilename: string;
}

/**
 * Defines if and how each wizard button shall be displayed
 * by setting its state (enabled / disabled / hidden) and
 * defining the PCTA (primary call to action) button.
 */
export interface WizardUpdateButtonPayload {
  source?: string;
  abort: ButtonState;
  back: ButtonState;
  next: ButtonState;
  complete: ButtonState;
  load?: ButtonState;

  /**
   * Defines the 'primary call to action' button of current wizard page.
   * According button is highlighted by different color (PxC corporate blue).
   */
  pcta: ButtonRole;
  focusPcta?: ButtonRole;
}
/**
 * Defines if and how each wizard button shall be displayed
 * by setting its state (enabled / disabled / hidden) and
 * defining the PCTA (primary call to action) button.
 */
export interface WizardSetButtonStatePayload {
  source?: string;
  abort: ButtonConfig;
  back: ButtonConfig;
  next: ButtonConfig;
  complete: ButtonConfig;
  load?: ButtonConfig;
  /**
   * Defines the 'primary call to action' button of current wizard page.
   * According button is highlighted by different color (PxC corporate blue).
   */
  pcta: ButtonRole;
  focusPcta?: ButtonRole;
}

export interface WizardUpdateButtonAction {
  type: typeof DEVICE_WIZARD__UPDATE_BUTTONS,
  payload: WizardUpdateButtonPayload,
}

export interface WizardSetButtonStateAction {
  type: typeof DEVICE_WIZARD__SET_BUTTON_STATE,
  payload: WizardSetButtonStatePayload,
}

export interface WizardInitButtonAction {
  type: typeof DEVICE_WIZARD__INIT_BUTTONS,
  payload: WizardUpdateButtonPayload,
}

export interface WizardDisableAllButtonsAction {
  type: typeof DEVICE_WIZARD__DISABLE_ALL_BUTTONS,
}

export interface WizardResetFocusAction {
  type: typeof DEVICE_WIZARD__RESET_FOCUS,
}

export interface WizardSetBackupFilenameAction {
  type: typeof DEVICE_WIZARD__SET_BACKUP_FILENAME,
  payload: string,
}

export type WizardActionTypes = WizardUpdateButtonAction
 | WizardSetButtonStateAction
 | WizardDisableAllButtonsAction
 | WizardInitButtonAction
 | WizardResetFocusAction
 | WizardSetBackupFilenameAction;
