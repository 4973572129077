/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reduxStore';
import { ReduxControlProps } from '../../ReduxControlProps';
import withControlVisibility from '../../hoc/withControlVisibility';
import { EventMonitorTrio } from '../EventMonitorTrio/EventMonitorTrio';
import { ioLinkTrio3EventMonitorStateSelector } from '../../../store/deviceInstances/store/ioLinkTrio3EventMonitor/selector';

// eslint-disable-next-line max-len
const EventMonitorTrioReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps): React.ReactElement => {
  const { t } = useTranslation();
  const { deviceInstanceId } = props;
  const ioLinkEventList = useTypedSelector(
    // eslint-disable-next-line max-len
    (state) => ioLinkTrio3EventMonitorStateSelector(state.deviceInstances, deviceInstanceId)?.eventList,
  );

  return (
    <div className="w-100 h-100">
      <EventMonitorTrio eventList={ioLinkEventList} targetInstance={deviceInstanceId}>
        <div>{t<string>('EVENTMONITOR_EVENT')}</div>
        <div>{t<string>('EVENTMONITOR_TIMESTAMP')}</div>
        <div>{t<string>('EVENTMONITOR_QUIT')}</div>
      </EventMonitorTrio>
    </div>
  );
};

export default withControlVisibility(EventMonitorTrioReduxControl);
