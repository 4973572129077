/* eslint-disable max-len */
import React from 'react';
import SVGGaugeIndicator from './SVGGaugeIndicator/SVGGaugeIndicator';
import { gaugeCenterX, gaugeCenterY, gaugeDisplayFormat } from '../common';
import DisplayFormat from '../../../../../helpers/displayFormat';

export interface SVGGaugeValueLimitsProps {
  currentUnit: string;
  minValue: number;
  maxValue: number;
  gaugeMinValue: number,
  gaugeMaxValue: number,
  displayFormat?: string;
}

const SVGGaugeValueLimits: React.FC<SVGGaugeValueLimitsProps> = (props: SVGGaugeValueLimitsProps)
: React.ReactElement | null => {
  const {
    currentUnit, minValue, maxValue,
    gaugeMaxValue, gaugeMinValue,
    displayFormat,
  } = props;

  const minValueFormat = DisplayFormat(displayFormat ?? gaugeDisplayFormat, minValue);
  const maxValueFormat = DisplayFormat(displayFormat ?? gaugeDisplayFormat, maxValue);

  return (
    <g>
      <SVGGaugeIndicator
        gaugeMaxValue={gaugeMaxValue}
        gaugeMinValue={gaugeMinValue}
        value={minValue}
      />
      <SVGGaugeIndicator
        gaugeMaxValue={gaugeMaxValue}
        gaugeMinValue={gaugeMinValue}
        value={maxValue}
      />
      <text x={gaugeCenterX} y={gaugeCenterY + 40} fontSize={22} textAnchor="middle" fill="black">{`Min: ${minValueFormat} ${currentUnit}`}</text>
      <text x={gaugeCenterX} y={gaugeCenterY + 72} fontSize={22} textAnchor="middle" fill="black">{`Max: ${maxValueFormat} ${currentUnit}`}</text>
    </g>
  );
};

export default SVGGaugeValueLimits;
