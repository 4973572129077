/* eslint-disable max-len */
import { LinearizationSplineData } from '../types';
import { base64ToBytes, bytesToBase64 } from './base64';

/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
// <!-- IFSM_PARAMETER_AREA_3
// 0x4480   0x10C0  S1: MAX, A0, A1, A2
// 0x4490   0x10C8  S2: MAX|CHECKSUM8(S1, U8), A0, A1, A2
// 0x44A0   0x10D0  S3: MAX|CHECKSUM8(S2, U8), A0, A1, A2
// 0x44B0   0x10D8  S4: MAX|CHECKSUM8(S3, U8), A0, A1, A2
// 0x44C0   0x10E0  S5: MAX|CHECKSUM8(S4, U8), A0, A1, A2
// 0x44D0   0x10E8  S6: MAX|CHECKSUM8(S5, U8), A0, A1, A2
// 0x44E0   0x10F0  S7: MAX|CHECKSUM8(S6, U8), A0, A1, A2
// 0x44F0   0x10F8  CHECKSUM8(S7, U8)
// 0x44F2   0x10F9  USER_CHAR_QTY (U16)
// 0x44FA   0x10FD  ....
// 0x10FE   AREA_CHECKSUM
// -->
// export decodeSplineItemBlob =
// (responseData: DataView, byteOffset: number): LinearizationSplineData => {
// }

const USER_CHAR_QTY_OFFSET = 0x72;

// eslint-disable-next-line max-len
// pHBQQk1GcsNR1g9AXA35Oj2KqEI7tnPDc3ITQFONsDrNDCFDYH91w9Z/FkDiVIc6wzVpQ1Lxb8N1MRJAEwWiOlzPj0PsRGTDf70LQDuOvjpxPaND5aBUw66HBEBMHdk614vAQxfZMsMncO4/eYkBO/0ABwAAAAAAAAAAAA==, 124
export const decodeSplineCount = (dataView: DataView): number => dataView.getInt16(0x72, true);

export const decodeSplineBlock = (blobDataView: DataView, offset: number)
: LinearizationSplineData => {
  const Max = blobDataView.getFloat32(offset, true);
  const A0 = blobDataView.getFloat32(offset + 0x04, true);
  const A1 = blobDataView.getFloat32(offset + 0x08, true);
  const A2 = blobDataView.getFloat32(offset + 0x0C, true);
  return {
    A0, A1, A2, Max,
  };
};

export const decodeSplineBlob = (blobValue: string): LinearizationSplineData[] => {
  const blobBuffer = base64ToBytes(blobValue);
  const blobDataView = new DataView(blobBuffer.buffer);

  const count = decodeSplineCount(blobDataView);
  if (count === 0) {
    return [];
  }

  const splineData:LinearizationSplineData[] = [];
  for (let i = 0; i < count; i += 1) {
    const data = decodeSplineBlock(blobDataView, 0x10 * i);
    splineData.push(data);
  }
  return splineData;
};

export const encodeSplineCount = (dataView: DataView, count: number)
  : void => dataView.setInt16(USER_CHAR_QTY_OFFSET, count, true);

export const encodeSpline = (blobDataView: DataView, offset: number, data: LinearizationSplineData)
  : void => {
  blobDataView.setFloat32(offset, data.Max, true);
  blobDataView.setFloat32(offset + 0x04, data.A0, true);
  blobDataView.setFloat32(offset + 0x08, data.A1, true);
  blobDataView.setFloat32(offset + 0x0C, data.A2, true);
};

/**
     * Calc CRC8 checksum for defined memory block
     *
     * @param blobDataView      Memory object
     * @param wRegCnt           register count
     * @return {Number}         Checksum
     */
const fncCalcChecksumCRC8 = (blobDataView: DataView, wRegCnt: number): number => {
  const aCRC8Table = [
    0x00, 0x07, 0x0e, 0x09, 0x1c, 0x1b, 0x12, 0x15,
    0x38, 0x3f, 0x36, 0x31, 0x24, 0x23, 0x2a, 0x2d,
    0x70, 0x77, 0x7E, 0x79, 0x6C, 0x6B, 0x62, 0x65,
    0x48, 0x4F, 0x46, 0x41, 0x54, 0x53, 0x5A, 0x5D,
    0xE0, 0xE7, 0xEE, 0xE9, 0xFC, 0xFB, 0xF2, 0xF5,
    0xD8, 0xDF, 0xD6, 0xD1, 0xC4, 0xC3, 0xCA, 0xCD,
    0x90, 0x97, 0x9E, 0x99, 0x8C, 0x8B, 0x82, 0x85,
    0xA8, 0xAF, 0xA6, 0xA1, 0xB4, 0xB3, 0xBA, 0xBD,
    0xC7, 0xC0, 0xC9, 0xCE, 0xDB, 0xDC, 0xD5, 0xD2,
    0xFF, 0xF8, 0xF1, 0xF6, 0xE3, 0xE4, 0xED, 0xEA,
    0xB7, 0xB0, 0xB9, 0xBE, 0xAB, 0xAC, 0xA5, 0xA2,
    0x8F, 0x88, 0x81, 0x86, 0x93, 0x94, 0x9D, 0x9A,
    0x27, 0x20, 0x29, 0x2E, 0x3B, 0x3C, 0x35, 0x32,
    0x1F, 0x18, 0x11, 0x16, 0x03, 0x04, 0x0D, 0x0A,
    0x57, 0x50, 0x59, 0x5E, 0x4B, 0x4C, 0x45, 0x42,
    0x6F, 0x68, 0x61, 0x66, 0x73, 0x74, 0x7D, 0x7A,
    0x89, 0x8E, 0x87, 0x80, 0x95, 0x92, 0x9B, 0x9C,
    0xB1, 0xB6, 0xBF, 0xB8, 0xAD, 0xAA, 0xA3, 0xA4,
    0xF9, 0xFE, 0xF7, 0xF0, 0xE5, 0xE2, 0xEB, 0xEC,
    0xC1, 0xC6, 0xCF, 0xC8, 0xDD, 0xDA, 0xD3, 0xD4,
    0x69, 0x6E, 0x67, 0x60, 0x75, 0x72, 0x7B, 0x7C,
    0x51, 0x56, 0x5F, 0x58, 0x4D, 0x4A, 0x43, 0x44,
    0x19, 0x1E, 0x17, 0x10, 0x05, 0x02, 0x0B, 0x0C,
    0x21, 0x26, 0x2F, 0x28, 0x3D, 0x3A, 0x33, 0x34,
    0x4E, 0x49, 0x40, 0x47, 0x52, 0x55, 0x5C, 0x5B,
    0x76, 0x71, 0x78, 0x7F, 0x6A, 0x6D, 0x64, 0x63,
    0x3E, 0x39, 0x30, 0x37, 0x22, 0x25, 0x2C, 0x2B,
    0x06, 0x01, 0x08, 0x0F, 0x1A, 0x1D, 0x14, 0x13,
    0xAE, 0xA9, 0xA0, 0xA7, 0xB2, 0xB5, 0xBC, 0xBB,
    0x96, 0x91, 0x98, 0x9F, 0x8A, 0x8D, 0x84, 0x83,
    0xDE, 0xD9, 0xD0, 0xD7, 0xC2, 0xC5, 0xCC, 0xCB,
    0xE6, 0xE1, 0xE8, 0xEF, 0xFA, 0xFD, 0xF4, 0xF3,
  ];

  function fncCRCAddFloat(wAddr: number, inChkSum: number): number {
    let byTmp; let wTmp;

    let byChkSum = inChkSum;
    // byTmp = (oMem.getUint16(wAddr) >> 8) & 0x00FF;
    byTmp = blobDataView.getUint8(wAddr);
    // eslint-disable-next-line no-bitwise
    wTmp = (byChkSum ^ byTmp) & 0xff;
    byChkSum = aCRC8Table[wTmp];

    /* low byte */
    // byTmp = oMem.GetWord(wAddr) & 0x00FF;
    byTmp = blobDataView.getUint8(wAddr + 1);
    // eslint-disable-next-line no-bitwise
    wTmp = (byChkSum ^ byTmp) & 0xff;
    byChkSum = aCRC8Table[wTmp];

    /* high byte */
    // byTmp = (oMem.GetWord(wAddr + 1) >> 8) & 0x00FF;
    byTmp = blobDataView.getUint8(wAddr + 2);
    // eslint-disable-next-line no-bitwise
    wTmp = (byChkSum ^ byTmp) & 0xff;
    byChkSum = aCRC8Table[wTmp];

    // byTmp = oMem.GetWord(wAddr + 1) & 0x00FF;
    byTmp = blobDataView.getUint8(wAddr + 3);
    // eslint-disable-next-line no-bitwise
    wTmp = (byChkSum ^ byTmp) & 0xff;
    byChkSum = aCRC8Table[wTmp];

    return byChkSum;
  }

  let wChecksum = 0;
  for (let i = 0; i < wRegCnt; i += 1) {
    wChecksum = fncCRCAddFloat((i * 4), wChecksum);
  }

  return wChecksum;
};

export const encodeSplineBlob = (splineData: LinearizationSplineData[], length: number): string => {
  const uint8arr = new Uint8Array(length);
  const blobDataView = new DataView(uint8arr.buffer);

  const splineCount = splineData.length > 7 ? 7 : splineData.length;
  // Write spline data
  splineData
    .slice(0, 7)
    .forEach((spline, idx) => {
      encodeSpline(blobDataView, 0x10 * idx, spline);
    });
  // Write checksum for spline (n-splines each contain 4 float values)
  const checksum = fncCalcChecksumCRC8(blobDataView, splineCount * 4);
  blobDataView.setInt8(splineCount * 0x10, checksum);
  // Write number of sline
  blobDataView.setInt16(USER_CHAR_QTY_OFFSET, splineCount, true);

  return bytesToBase64(new Uint8Array(blobDataView.buffer));
};
