/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WebDevice } from '@gpt/commons';
import { Button } from 'react-bootstrap';
import DeviceCatalogCardList from '../../components/DeviceCatalogComponents/DeviceCatalogCardList/DeviceCatalogCardList';
import FilterIcon from '../../assets/icons/icon-lines-and-angles-filter.svg';
import './SelectDeviceDialog.css';

export interface SelectDeviceDialogProps {
    catalogDeviceList: WebDevice.DeviceCatalogInformation[];
    // eslint-disable-next-line no-unused-vars
    onSelectDevice: (device: WebDevice.DeviceCatalogInformation) => Promise<void>;
    selectedDeviceCatalogIdent?: string;
}

const SelectDeviceDialog: React.FC<SelectDeviceDialogProps> = (props: SelectDeviceDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const { catalogDeviceList, onSelectDevice, selectedDeviceCatalogIdent } = props;
  const [filter, setFilter] = useState<string>('');

  const deviceList = catalogDeviceList.filter((item) => {
    const title = `${item.deviceTypeName}${item.productOrderNumber}`.toLocaleLowerCase();
    return title.indexOf(filter.toLocaleLowerCase()) !== -1;
  });

  return (
    <div className="select-device-dialog w-100 h-100">
      <div className="select-device-dialog__header">
        <div className="select-device-dialog__title p-3">
          <p>{t<string>('SELECT_DEVICE_DIALOG__TITLE')}</p>
        </div>
        <div className="select-device-dialog__search d-flex flex-row w-100 px-3">
          <div className="select-device-dialog__search__input w-100">
            <div className="input-field">
              <input
                type="text"
                className="textfield__input"
                value={filter}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)}
                placeholder={t<string>('SELECT_DEVICE_DIALOG__SEARCH_PLACEHOLDER')}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="textfield__label">{}</label>
            </div>
          </div>
          <div className="select-device-dialog__search__filter-button pl-2">
            <Button variant="secondary">
              <img className="select-device-dialog__search__filter-button__icon" src={FilterIcon} alt="Filter" />
            </Button>
          </div>
        </div>
        <div className="select-device-dialog__category d-flex flex-row justify-content-start align-items-center w-100 px-3 py-2">
          <div className="select-device-dialog__category__title">
            <p>{t<string>('SELECT_DEVICE_DIALOG__SELECTED_CATEGORIES')}</p>
          </div>
          <div className="select-device-dialog__category__list px-2" />
        </div>
      </div>
      <div className="select-device-dialog__content h-100 px-3 pb-2">
        <div className="select-device-dialog__content__card-list h-100">
          <DeviceCatalogCardList
            deviceList={deviceList}
            onClick={(device) => {
              onSelectDevice(device);
            }}
            selectedDeviceCatalogIdent={selectedDeviceCatalogIdent}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectDeviceDialog;
