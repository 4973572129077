/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { writeActiveDeviceVariableValues } from '../../../store';
import { updateDeviceDatasetValues } from '../../../store/deviceInstances/store/deviceDataset';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';

const useDispatchFloatValue = (deviceInstance: string | undefined, targetDataset: DatasetType) => {
  const dispatch = useDispatch();
  const dispatchFloatValue = useCallback((
    newValue: string,
    statusValue?: DeviceModelStatus.StatusValue,
  ): void => {
    if (statusValue === undefined || deviceInstance === undefined) {
      return;
    }

    let parseValue = 0;
    parseValue = parseFloat(newValue);

    if (Number.isNaN(parseValue)) {
      dispatch(updateDeviceDatasetValues(deviceInstance, {
        targetDataset,
        values: [{
          identRef: statusValue.identRef,
          value: newValue,
          backupValue: statusValue.backupValue ?? statusValue.value,
        }],
      }));
    } else {
      dispatch(writeActiveDeviceVariableValues(deviceInstance, [{
        identRef: statusValue.identRef,
        value: parseValue,
        backupValue: statusValue.backupValue ?? statusValue.value,
      }]));
    }
  }, [targetDataset, deviceInstance]);

  return dispatchFloatValue;
};

export default useDispatchFloatValue;
