/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
export const varIoutCharacteristicsPowerMode = 'var_UDP_OutputCharacteristics_IoutCharacteristics_PowerMode';
export const varIoutCharacteristicsStaticBoost = 'var_UDP_OutputCharacteristics_IoutCharacteristics_StaticBoost';
export const varIoutCharacteristicsDynamicBoost = 'var_UDP_OutputCharacteristics_IoutCharacteristics_DynamicBoost';
export const varIoutCharacteristicsSFB = 'var_UDP_OutputCharacteristics_IoutCharacteristics_SFB';
export const varTfuseMax = 'var_UDP_OutputCharacteristics_TfuseMax';
export const varFuseTriggerFactor = 'var_UDA_Additionals1_FuseTriggerFactor';
export const varFuseTriggerFactorCorrespond = 'var_FuseTriggerFactorCorrespond__local';
