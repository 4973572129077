/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import IconNoDeviceNA from '../../../assets/icons/icon-no-device-na.svg';
import DeviceCardNoDevice from '../../DeviceCards/DeviceCardNoDevice/DeviceCardNoDevice';

const ListItemUnknownCard: React.FC = ()
: React.ReactElement => {
  const { t } = useTranslation();

  return (
    <DeviceCardNoDevice
      protocolTitle={t('DEVICE_CARD__UNKNOWN_DEVICE__TITLE')}
      productImage={IconNoDeviceNA}
      protocolText="Unsupported"
      scanActive={false}
    />
  );
};

export default ListItemUnknownCard;
