/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { writeActiveDeviceVariableValues } from '../../../store';
import { updateDeviceDatasetValues } from '../../../store/deviceInstances/store/deviceDataset';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';

const useDispatchIntegerValue = (
  deviceInstanceId: string | undefined,
  targetDataset: DatasetType,
) => {
  const dispatch = useDispatch();
  const dispatchIntegerValue = useCallback((
    newValue: string,
    statusValue?: DeviceModelStatus.StatusValue,
  ): void => {
    if (statusValue === undefined || deviceInstanceId === undefined) {
      return;
    }
    let parseValue = 0;
    parseValue = parseInt(newValue, 10);

    if (Number.isNaN(parseValue)) {
      dispatch(updateDeviceDatasetValues(deviceInstanceId, {
        targetDataset,
        values: [{
          identRef: statusValue.identRef,
          value: newValue,
          backupValue: statusValue.backupValue ?? statusValue.value,
        }],
      }));
    } else {
      dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
        identRef: statusValue.identRef,
        value: parseValue,
        backupValue: statusValue.backupValue ?? statusValue.value,
      }]));
    }
  }, [targetDataset]);

  return dispatchIntegerValue;
};

export default useDispatchIntegerValue;
