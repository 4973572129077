/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

export interface ApexTrendSerieData {
    x: number;
    y: number;
  }

export interface ApexTrendDataRange {
    minValue?: number;
    maxValue?: number;
    step?: number;
  }

export interface ApexTrendData {
    id: string;
    title: string;
    unit: string;
    color: string;
    series: ApexTrendSerieData[];
    range?: {
      minValue?: number;
      maxValue?: number;
      step?: number;
    }
  }

export const trendColorsMap = {
  'medium-blue': '#00838a',
  yellow: '#ffa800',
  'medium-green': '#09ae3b',
  'royal-purple': '#7030a0',
  'deep-sky-blue': '#00b0f0',
  sapphire: '#002060',
  magenta: '#e11d78',
  gray: '#636363',
};
