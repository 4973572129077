/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { base64ToBytes } from '../../../linearizationDataset/middleware/base64';
import {
  TRIO3_EVENT_MONITOR__UPDATE_EVENT_LIST,
  TRIO3_EVENT_MONITOR__SET_EVENT_PENDING,
  IoLinkTrio3EventMonitorState,
  AsknowledgeTrio3EventPayload,
  typeIoLinkTrio3EventMonitorActions,
  Trio3EventState,
  Trio3EventList,
  TRIO3_EVENT_MONITOR__CLEAN_EVENT_LIST,
} from './types';

export const initialIoLinkTrio3EventMonitorState: IoLinkTrio3EventMonitorState = {
  eventList: {
    'event-offset-32': {
      id: 'event-offset-32',
      event: {
        bitOffset: 32, label: 'locTnEventdcnotokLbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 1,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-33': {
      id: 'event-offset-33',
      event: {
        bitOffset: 33, label: 'locTnEventpowerover90percentLbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 2,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-38': {
      id: 'event-offset-38',
      event: {
        bitOffset: 38, label: 'locTnEventovertempLbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 3,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-16': {
      id: 'event-offset-16',
      event: {
        bitOffset: 16, label: 'locTdEventshortcircuit1Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 4,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-17': {
      id: 'event-offset-17',
      event: {
        bitOffset: 17, label: 'locTdEventshortcircuit2Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 5,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-18': {
      id: 'event-offset-18',
      event: {
        bitOffset: 18, label: 'locTdEventshortcircuit3Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 6,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-19': {
      id: 'event-offset-19',
      event: {
        bitOffset: 19, label: 'locTdEventshortcircuit4Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 7,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-20': {
      id: 'event-offset-20',
      event: {
        bitOffset: 20, label: 'locTdEventshortcircuit5Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 8,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-21': {
      id: 'event-offset-21',
      event: {
        bitOffset: 21, label: 'locTdEventshortcircuit6Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 9,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-22': {
      id: 'event-offset-22',
      event: {
        bitOffset: 22, label: 'locTdEventshortcircuit7Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 10,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-23': {
      id: 'event-offset-23',
      event: {
        bitOffset: 23, label: 'locTdEventshortcircuit8Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 11,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-24': {
      id: 'event-offset-24',
      event: {
        bitOffset: 24, label: 'locTdEventoverload1Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 12,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-25': {
      id: 'event-offset-25',
      event: {
        bitOffset: 25, label: 'locTdEventoverload2Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 13,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-26': {
      id: 'event-offset-26',
      event: {
        bitOffset: 26, label: 'locTdEventoverload3Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 14,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-27': {
      id: 'event-offset-27',
      event: {
        bitOffset: 27, label: 'locTdEventoverload4Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 15,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-28': {
      id: 'event-offset-28',
      event: {
        bitOffset: 28, label: 'locTdEventoverload5Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 16,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-29': {
      id: 'event-offset-29',
      event: {
        bitOffset: 29, label: 'locTdEventoverload6Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 17,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-30': {
      id: 'event-offset-30',
      event: {
        bitOffset: 30, label: 'locTdEventoverload7Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 18,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-31': {
      id: 'event-offset-31',
      event: {
        bitOffset: 31, label: 'locTdEventoverload8Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 19,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-35': {
      id: 'event-offset-35',
      event: {
        bitOffset: 35, label: 'locTnEventtotalcurrentshutoffLbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 20,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-0': {
      id: 'event-offset-0',
      event: {
        bitOffset: 0, label: 'locTdEventerroroutputpath1Lbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 21,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-1': {
      id: 'event-offset-1',
      event: {
        bitOffset: 1, label: 'locTdEventerroroutputpath2Lbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 22,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-2': {
      id: 'event-offset-2',
      event: {
        bitOffset: 2, label: 'locTdEventerroroutputpath3Lbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 23,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-3': {
      id: 'event-offset-3',
      event: {
        bitOffset: 3, label: 'locTdEventerroroutputpath4Lbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 24,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-4': {
      id: 'event-offset-4',
      event: {
        bitOffset: 4, label: 'locTdEventerroroutputpath5Lbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 25,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-5': {
      id: 'event-offset-5',
      event: {
        bitOffset: 5, label: 'locTdEventerroroutputpath6Lbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 26,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-6': {
      id: 'event-offset-6',
      event: {
        bitOffset: 6, label: 'locTdEventerroroutputpath7Lbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 27,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-7': {
      id: 'event-offset-7',
      event: {
        bitOffset: 7, label: 'locTdEventerroroutputpath8Lbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 28,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-34': {
      id: 'event-offset-34',
      event: {
        bitOffset: 34, label: 'locTnEventpsudownLbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 29,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-8': {
      id: 'event-offset-8',
      event: {
        bitOffset: 8, label: 'locTdEventvoltagediff1Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 30,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-9': {
      id: 'event-offset-9',
      event: {
        bitOffset: 9, label: 'locTdEventvoltagediff2Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 31,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-10': {
      id: 'event-offset-10',
      event: {
        bitOffset: 10, label: 'locTdEventvoltagediff3Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 32,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-11': {
      id: 'event-offset-11',
      event: {
        bitOffset: 11, label: 'locTdEventvoltagediff4Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 33,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-12': {
      id: 'event-offset-12',
      event: {
        bitOffset: 12, label: 'locTdEventvoltagediff5Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 34,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-13': {
      id: 'event-offset-13',
      event: {
        bitOffset: 13, label: 'locTdEventvoltagediff6Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 35,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-14': {
      id: 'event-offset-14',
      event: {
        bitOffset: 14, label: 'locTdEventvoltagediff7Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 36,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-15': {
      id: 'event-offset-15',
      event: {
        bitOffset: 15, label: 'locTdEventvoltagediff8Lbl', type: 'warning', ioLinkIndex: 128, ioLinkSubindex: 37,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-36': {
      id: 'event-offset-36',
      event: {
        bitOffset: 36, label: 'locTnEventcurrentdiffLbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 38,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
    'event-offset-37': {
      id: 'event-offset-37',
      event: {
        bitOffset: 37, label: 'locTnEventchanoffcurrsourceLbl', type: 'error', ioLinkIndex: 128, ioLinkSubindex: 39,
      },
      state: Trio3EventState.inactive,
      timestamp: '',
    },
  },
};

const eventKey = (offset: number): string => `event-offset-${offset}`;

const uint8arrToBools = (uint8Arr: Uint8Array): boolean[] => {
  const arr = uint8Arr.reduce((xacc, bt) => {
    const bits = Array.from(Array(8).keys()).reduce((acc, key) => {
      // eslint-disable-next-line no-bitwise
      const mask = 0x80 >> key;
      // eslint-disable-next-line no-bitwise
      const value = (bt & mask) !== 0;
      return [
        ...acc,
        value,
      ];
    }, [] as boolean[]);
    return [
      ...xacc,
      ...bits,
    ];
  }, [] as boolean[]);
  return arr.reverse();
};

const updateTrio3EventMonitor = (
  state: IoLinkTrio3EventMonitorState,
  payload: string,
):IoLinkTrio3EventMonitorState => {
  let uint8arr: Uint8Array;
  try {
    uint8arr = base64ToBytes(payload);
  } catch {
    uint8arr = new Uint8Array(0);
  }
  if (uint8arr.length !== 5) {
    return state;
  }

  const bits = uint8arrToBools(uint8arr);
  const actualEventList: Trio3EventList = bits.reduce((acc, bitActive, index) => {
    const key = eventKey(index);
    const ev = acc[key];
    if (ev === undefined) {
      return acc;
    }

    if (ev.state === Trio3EventState.active && bitActive) {
      return acc;
    }

    if (ev.state === Trio3EventState.inactive && !bitActive) {
      return acc;
    }

    if (ev.state === Trio3EventState.pending && bitActive) {
      return acc;
    }

    const date = new Date();
    return {
      ...acc,
      [key]: {
        ...acc[key],
        state: bitActive ? Trio3EventState.active : Trio3EventState.inactive,
        timestamp: bitActive ? date.toISOString() : '',
      },
    };
  }, state.eventList);

  return {
    ...state,
    eventList: {
      ...actualEventList,
    },
  };
};

const setPendingTrio3EventMonitor = (
  state: IoLinkTrio3EventMonitorState,
  payload: AsknowledgeTrio3EventPayload,
):IoLinkTrio3EventMonitorState => {
  const { id } = payload;
  return {
    ...state,
    eventList: {
      ...state.eventList,
      [id]: {
        ...state.eventList[id],
        state: Trio3EventState.pending,
      },
    },
  };
};

const ioLinkTrio3EventMonitorReducer = (
  state = initialIoLinkTrio3EventMonitorState,
  action: typeIoLinkTrio3EventMonitorActions,
): IoLinkTrio3EventMonitorState => {
  let newstate = state;
  switch (action.type) {
    case TRIO3_EVENT_MONITOR__UPDATE_EVENT_LIST:
      newstate = updateTrio3EventMonitor(state, action.payload.eventList);
      break;
    case TRIO3_EVENT_MONITOR__CLEAN_EVENT_LIST:
      newstate = {
        ...state,
        eventList: initialIoLinkTrio3EventMonitorState.eventList,
      };
      break;
    case TRIO3_EVENT_MONITOR__SET_EVENT_PENDING:
      newstate = setPendingTrio3EventMonitor(state, action.payload);
      break;
    default:
      newstate = state;
  }
  return newstate;
};

export default ioLinkTrio3EventMonitorReducer;
