/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinearizationMessageBox } from '../LinearizationMessageBox/LinearizationMessageBox';
import { useTypedSelector } from '../../store';
import './LinearizationMessage.css';

const LinearizationMessage: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const splineMesssage = useTypedSelector((state) => state.linearizationDataset.splineMessage);
  const linearizationMessage = useTypedSelector((state) => state.linearizationDataset.linearizationMessage);

  let message = splineMesssage;
  let isSpline = true;

  if (linearizationMessage) {
    message = linearizationMessage;
    isSpline = false;
  }

  if (message === undefined || message.text === '') {
    return (<></>);
  }

  return (
    <div className="linearization-message">
      <LinearizationMessageBox
        type={message.type}
        message={t(message.text)}
        showCloseButton
        splineMessage={isSpline}
      />
    </div>
  );
};

export default LinearizationMessage;
