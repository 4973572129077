/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Discovery } from '@gpt/cxe-nats-communication';
import iconIoLinkAdapter from '../images/Icon_IOLink.svg';
import iconNFCAdapter from '../images/Icon_NFC.svg';
import iconSPortAdapter from '../images/Icon_S_Port.svg';
import iconSPortBluetoothAdapter from '../images/Icon_S_Port_Bluetooth.svg';
import iconUnknownAdapter from '../../../assets/icons/icon-no-device-na.svg';
import DeviceCardUnidentified from '../../DeviceCards/DeviceCardUnidentified/DeviceCardUnidentified';

export interface ListItemUnidentifiedCardProps {
  adapterType: Discovery.CommunicationAdapterType;
}

const mapToIcon = {
  [Discovery.CommunicationAdapterType.Unknown]: iconUnknownAdapter,
  [Discovery.CommunicationAdapterType.Phoenix_NFC_USB_Reader]: iconNFCAdapter,
  [Discovery.CommunicationAdapterType.Phoenix_TMG_USB_IOLink_Master]: iconIoLinkAdapter,
  [Discovery.CommunicationAdapterType.Phoenix_USB_Bluegiga_Prog_Adapter]: iconSPortBluetoothAdapter,
  [Discovery.CommunicationAdapterType.Phoenix_USB_Prog_Adapter]: iconSPortAdapter,
  [Discovery.CommunicationAdapterType.Phoenix_USB_USI_IOLink_Master]: iconIoLinkAdapter,
};

const ListItemUnidentifiedCard: React.FC<ListItemUnidentifiedCardProps> = (props: ListItemUnidentifiedCardProps)
: React.ReactElement => {
  const { adapterType } = props;
  const { t } = useTranslation();

  const image = mapToIcon[adapterType] ?? '';

  return (
    <DeviceCardUnidentified
      protocolTitle={t('DEVICE_CARD__DEVICE_CONNECTED__TITLE')}
      productImage={image}
      protocolText="Identification"
    />
  );
};

export default ListItemUnidentifiedCard;
