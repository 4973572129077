/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import './AcceptDataTemplate.css';

export type AcceptDataTemplateProps = PropsWithChildren<any>

// eslint-disable-next-line max-len
export const AcceptDataTemplate: React.FC<AcceptDataTemplateProps> = (props: AcceptDataTemplateProps):
  React.ReactElement => {
  const { children } = props;

  return (
    <div className="accept-data-template">
      <div className="accept-data-template__column-1">
        { children[0] }
      </div>
      <div className="accept-data-template__column-2">
        { children[1] }
      </div>
    </div>
  );
};
