/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { IdentRef } from '@gpt/commons';
import { DatasetType, DeviceDatasetState } from '../../../store/deviceInstances/store/deviceDataset/types';
import { isEqualStatusValues, statusValuePrecision } from '../../../helpers/precisionValue';

const compareSignalOutputMembers = (
  state: DeviceDatasetState,
  blockVariables: IdentRef[],
  target1: DatasetType,
  target2: DatasetType,
): boolean => {
  const dataset1 = state[target1];
  const dataset2 = state[target2];

  if (dataset1 === undefined && dataset2 === undefined) {
    return true;
  }

  if (dataset1 === undefined || dataset2 === undefined) {
    return false;
  }

  const unequal = blockVariables.find((memberIdent) => {
    const value1 = dataset1.values[memberIdent]?.value;
    const value2 = dataset2.values[memberIdent]?.value;

    const precision = statusValuePrecision(dataset1.descriptors[memberIdent]);

    return !isEqualStatusValues(precision, value1, value2);
  });
  return unequal === undefined;
};

export default compareSignalOutputMembers;
