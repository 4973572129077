/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import checkboxChecked from '../../assets/icons/checkbox-checked.svg';
import checkboxUnchecked from '../../assets/icons/checkbox-unchecked.svg';
import iconRefresh from '../../assets/icons/icon-refresh.svg';
import './DragIndicatorControl.css';

export interface DragIndicatorControlProps extends PropsWithChildren<any> {
  showIndicator: boolean;
  onDragIndicatorChange: () => void;
  onRefresh: () => void;
}

export const DragIndicatorControl: React.FC<DragIndicatorControlProps> = (props: DragIndicatorControlProps): React.ReactElement => {
  const { showIndicator, onDragIndicatorChange, onRefresh } = props;
  const { t } = useTranslation();

  const checkbox = showIndicator ? checkboxChecked : checkboxUnchecked;

  return (
    <div className="DragIndicator-container" data-testid="drag-indicator-control">
      <div className="DragIndicator-item">
        <div
          className="DragIndicator-button"
          role="button"
          tabIndex={0}
          onClick={() => onDragIndicatorChange()}
          onKeyUp={() => {}}
        >
          <Image src={checkbox} className="DragIndicator-button-icon" />
        </div>
        <div className="DragIndicator-item-text">{t<string>('COCKPIT_DRAG_INDICATOR_MENU__SHOW_BUTTON_TITLE')}</div>
      </div>
      <div className="DragIndicator-item">
        <div
          className="DragIndicator-button"
          role="button"
          tabIndex={0}
          onClick={() => { onRefresh(); }}
          onKeyUp={() => {}}
        >
          <Image src={iconRefresh} className="DragIndicator-button-icon" />
        </div>
        <div className="DragIndicator-item-text">{t<string>('COCKPIT_DRAG_INDICATOR_MENU__RESET_BUTTON_TITLE')}</div>
      </div>
    </div>
  );
};
