/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import './CompareTableValue.css';
import CompareContainer from '../../../CompareContainer/CompareContainer';
import { PreviewTable } from '../../../../../../components/PreviewTable/PreviewTable';
import { CompareValueProps } from '../../../../CompareTypes';
import { ParameterControl } from '../../../../../../components/ParameterEditor';
import { Record } from '../../../../../../components/PreviewTable/PreviewTableHeader/PreviewTableHeader';

export interface CompareTableValueProps extends CompareValueProps {
    leftRecords: Record[];
    rightRecords: Record[];
}

const CompareTableValue
: React.FC<CompareTableValueProps> = (props: CompareTableValueProps): React.ReactElement => {
  const {
    leftRecords, rightRecords, buttonFunction, onButtonClick, showImageAndSign,
  } = props;
  return (
    <CompareContainer
      buttonFunction={buttonFunction}
      equal
      onButtonClick={onButtonClick}
      showImageAndSign={showImageAndSign}
    >
      <ParameterControl>
        <PreviewTable records={leftRecords} showNumbering={false} identifier="previewTable-header-left" />
      </ParameterControl>
      <ParameterControl>
        <PreviewTable records={rightRecords} showNumbering={false} identifier="previewTable-header-right" />
      </ParameterControl>
    </CompareContainer>
  );
};

export default CompareTableValue;
