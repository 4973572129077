/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import React, { useContext } from 'react';
import { PreviewTableControl } from '../../../../controls/PreviewTableControl/PreviewTableControl';
import { useTypedSelector } from '../../../../store';
import { DatasetContext } from '../../../../views/DatasetContext';
import { UnsupportedControl } from '../../../UnsupportedControl';
import { deviceDescriptorSelector, deviceStatusValueSelector } from '../../../../store/deviceInstances/store/deviceDataset/selector';

export interface PreviewLinearizationTableControlProps {
    tableId: IdentRef,
    numOfPointsId: IdentRef,
    deviceInstanceId: string;
}

const PreviewLinearizationTableControl: React.FC<PreviewLinearizationTableControlProps> = (
  props: PreviewLinearizationTableControlProps,
)
: React.ReactElement => {
  const {
    tableId, numOfPointsId, deviceInstanceId,
  } = props;
  const { targetDataset } = useContext(DatasetContext);

  const numOfPointsStatusDesc = useTypedSelector((state) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, numOfPointsId));
  const statusValue = useTypedSelector((state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, targetDataset, numOfPointsId));

  if (numOfPointsStatusDesc === undefined || statusValue === undefined) {
    return <UnsupportedControl text={`PreviewLinearizationTableControl: Undeifined object ${numOfPointsId}`} />;
  }

  if (numOfPointsStatusDesc.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text={`PreviewLinearizationTableControl: Unsupported type ${numOfPointsStatusDesc.type}`} />;
  }

  const variableValue = statusValue.value as number;

  return <PreviewTableControl deviceInstanceId={deviceInstanceId} identRef={tableId} numberOfPoints={variableValue} />;
};

export default PreviewLinearizationTableControl;
