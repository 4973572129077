/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../../../../../store';
import { deviceTargetDatasetSelector } from '../../../../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../../../../store/deviceInstances/store/deviceDataset/types';

export const H5MANUALCONTROL_DEACTIVATE = 0x40;
export const H5MANUALCONTROL_ACTIVATE = 0x20;

export interface H5ActivateButtonProps {
  deviceInstanceId: string;
  controlView: DeviceModelStatus.UI.Contactron.MotorStarterH5ManualControlView;
  manualModeActive: boolean;
  disabled: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick: (value: number) => void;
}

const H5ActivateButton: React.FC<H5ActivateButtonProps> = (props: H5ActivateButtonProps):React.ReactElement => {
  const { t } = useTranslation();
  const {
    controlView, deviceInstanceId, manualModeActive, disabled, onClick,
  } = props;

  const activateButtonTitle = manualModeActive
    ? 'CONTACTRON__ONLINE__MANUAL_CONTROL_BUTTON__DEACTIVATE_TITLE'
    : 'CONTACTRON__ONLINE__MANUAL_CONTROL_BUTTON__ACTIVATE_TITLE';

  const ledRStatusValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.device);
    return dataset?.values.IFS_ADDR_LED_R.value ?? 0;
  });
  const ledLStatusValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.device);
    return dataset?.values.IFS_ADDR_LED_L.value ?? 0;
  });

  return (
    <Button
      variant="secondary-small"
      block
      disabled={disabled}
      onClick={() => {
        let writeValue = H5MANUALCONTROL_DEACTIVATE;
        if (!manualModeActive) {
          writeValue = H5MANUALCONTROL_ACTIVATE;
          if (ledRStatusValue !== 0) {
            writeValue = controlView.right.value;
          } else if (ledLStatusValue !== 0) {
            writeValue = controlView.left.value;
          }
        }
        onClick(writeValue);
      }}
    >
      {t(activateButtonTitle)}
    </Button>
  );
};

export default H5ActivateButton;
