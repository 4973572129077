/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { TFunction } from 'i18next';
import Quint4DropDownBaseControl from '../../Quint4DropDown/Quint4DropDownControl';
import { useContextDatasetStatusDescriptor } from '../../../../hooks/useContextDataset';
import useStatusValueStateUpdate from '../../hooks/useStatusValueStateUpdate';
import { StructureItemStateChangeCallback } from '../../../ReduxControlProps';

interface InputOptions {
  [val: number]: string;
}

const itemOptions = (
  valueType: DeviceModelStatus.StatusDescriptorValueTypeEnumerator,
  statusValue: DeviceModelStatus.StatusValue | undefined,
  t: TFunction,
): InputOptions => {
  let result: InputOptions = {};
  if (statusValue !== undefined) {
    result = valueType.enumerator.reduce((acc, entry) => ({
      ...acc,
      [entry.value]: t(entry.label),
    }), {});
  } else {
    result = {
      0: t('N/A'),
    };
  }
  return result;
};

export interface Quint4CharacteristicSelectorProps {
  deviceInstanceId: string;
  statusValue?: DeviceModelStatus.StatusValue;
  // eslint-disable-next-line no-unused-vars
  readonly?: boolean;
  showContextMessageDef?: boolean;
  onStateChanged: StructureItemStateChangeCallback,
  // eslint-disable-next-line no-unused-vars
  onValueChange: (value: number) => void,
}

const Quint4CharacteristicSelector
  :React.FC<Quint4CharacteristicSelectorProps> = (props: Quint4CharacteristicSelectorProps):
    React.ReactElement => {
    const {
      deviceInstanceId,
      statusValue,
      readonly,
      showContextMessageDef,
      onStateChanged,
      onValueChange,
    } = props;
    const { t } = useTranslation();

    const statusDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, statusValue?.identRef ?? '-');
    const visibility = useStatusValueStateUpdate(statusValue?.identRef ?? '-', deviceInstanceId, undefined, onStateChanged);

    if (statusValue === undefined) {
      return <></>;
    }

    if (visibility === false) {
      return <></>;
    }

    if (statusDescriptor?.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
      return <></>;
    }

    const options = itemOptions(statusDescriptor.valueType, statusValue, t);

    return (
      <Quint4DropDownBaseControl
        label={t(statusDescriptor.label)}
        help={t(statusDescriptor.help)}
        value={statusValue?.value}
        options={options}
        valueModified={statusValue?.modified ?? false}
        onValueChange={(value) => onValueChange(value)}
        readonly={readonly}
        showContextMessageDef={showContextMessageDef}
      />
    );
  };

export default Quint4CharacteristicSelector;
