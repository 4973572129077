/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTypedSelector } from '../../../store/reduxStore';
import { ReduxControlProps } from '../../ReduxControlProps';
import { DatasetState, DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { DatasetContext } from '../../../views/DatasetContext';
import withControlVisibility from '../../hoc/withControlVisibility';
import { EventMonitorListItem } from '../EventMonitorList/EventMonitorList';
import { EventMonitor } from '../EventMonitor/EventMonitor';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { IoLinkEventMonitorStateSelector } from '../../../store/deviceInstances/store/ioLinkEventMonitor/selector';
import { eIoLinkEventMode, eIoLinkEventType } from '../../../store/deviceInstances/store/ioLinkEventMonitor/types';

const selectControlType = (identRef: IdentRef, dataset?: DatasetState)
: DeviceModelStatus.UI.IoLinkEventMonitorControlType | undefined => {
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLIOLINKEVENTMONITOR) {
    return undefined;
  }
  return controlType;
};

const selectEventList = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: string,
): DeviceModelStatus.UI.IoLinkEventList => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return {};
  }
  return controlType.eventList;
};

// eslint-disable-next-line max-len
const EventMonitorReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps): React.ReactElement => {
  const {
    identRef, deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  // Get EventList definitions from control description
  const controlEventList = useTypedSelector(
    (state) => selectEventList(state.deviceInstances, deviceInstanceId, targetDataset, identRef),
  );

  // Create event list for Event Monitor Control
  const ioLinkEventList = useTypedSelector(
    (state) => IoLinkEventMonitorStateSelector(state.deviceInstances, deviceInstanceId)?.eventList ?? [],
  );

  const eventList: EventMonitorListItem[] = ioLinkEventList.map((ev) => {
    const label = controlEventList[`ev${ev.code}`]?.label;
    const eventTimestamp = ev.timestamp;

    let eventMode = 'appears';
    switch (ev.mode) {
      case eIoLinkEventMode.Appears:
        eventMode = 'appears';
        break;
      case eIoLinkEventMode.Disappears:
        eventMode = 'disappears';
        break;
      case eIoLinkEventMode.SingleShot:
        eventMode = 'singleshot';
        break;
      default:
        eventMode = '';
    }

    let eventType = 'error';
    switch (ev.type) {
      case eIoLinkEventType.Error:
        eventType = 'error';
        break;
      case eIoLinkEventType.Warning:
        eventType = 'warning';
        break;
      case eIoLinkEventType.Message:
        eventType = 'message';
        break;
      default:
        eventType = '';
    }

    return {
      eventId: ev.Id,
      eventMessage: label === undefined ? '' : t(label),
      eventMode,
      eventType,
      eventTimestamp,
    };
  });
  return (
    <div className="w-100 h-100">
      <EventMonitor eventList={eventList}>
        <div>{t<string>('EVENTMONITOR_EVENT')}</div>
        <div>{t<string>('EVENTMONITOR_TYPE')}</div>
        <div>{t<string>('EVENTMONITOR_TIMESTAMP')}</div>
      </EventMonitor>
    </div>
  );
};

export default withControlVisibility(EventMonitorReduxControl);
