/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './OpDeviceStatusControl.scss';

export interface DeviceStatusItemProps {
  label: string;
  value: string;
  unit: string,
}

export const OpDeviceStatusControl: React.FC<DeviceStatusItemProps> = (props: DeviceStatusItemProps): React.ReactElement => {
  const {
    label, value, unit,
  } = props;

  return (
    <div className="op-device-status-control">
      <div className="op-device-status-control__padding" />
      <div className="op-device-status-control__label">
        {`${label}:`}
        <span> </span>
      </div>
      <div className="op-device-status-control__value">
        {value}
      </div>
      <div className="op-device-status-control__unit">
        {unit}
      </div>
    </div>
  );
};
