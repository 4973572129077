/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus, Services } from '@gpt/commons';
import PageSelectActiveDevice from '../PageCollection/PageSelectActiveDevice/PageSelectActiveDevice';
import Page2ChangeDeviceBackup from './Page2ChangeDeviceBackup/Page2ChangeDeviceBackup';
import Page3ChangeDevice from './Page3ChangeDevice/Page3ChangeDevice';
import Page4Download from './Page4Download/Page4Download';
import { ButtonRole, ButtonState } from '../../store/deviceWizard/types';
import { deviceWizardInitButtons, deviceWizardSetButtons, deviceWizardUpdateButtons } from '../../store/deviceWizard';
import { WIZARD_BUTTON_LABEL__TRANSFER } from '../helper';
import WizardView from '../WizardView/WizardView';
import { wizardPreviewMenuSelector, wizardReportMenuSelector } from '../../store/selectors/rootStructureSelector';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { disposeDevice, useTypedSelector } from '../../store';
import { executeDeviceDownloadMethod } from '../../store/deviceInstances/middleware/deviceMethodExecution/actions';
import { WizardProps } from '../types';

export interface ChangeDeviceWizardProps extends WizardProps {
  deviceNewInstanceId: string;
}

const ChangeDeviceWizard: React.FC<ChangeDeviceWizardProps> = (props: ChangeDeviceWizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deviceInstanceId, deviceNewInstanceId } = props;
  const device1DatasetValues = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.user)?.values ?? {});
  const reportMenu = useTypedSelector((state) => wizardReportMenuSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, 'WIZARD_CHANGEDEVICE'));
  const previewMenu = useTypedSelector((state) => wizardPreviewMenuSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, 'WIZARD_CHANGEDEVICE'));

  // const [downloadValues, setDownloadValues] = useState<Services.DeviceModel.StatusValueRef[]>([]);

  useEffect(() => {
    dispatch(deviceWizardInitButtons({
      source: 'deviceWizardInitButtons',
      abort: ButtonState.enabled,
      back: ButtonState.disabled,
      next: ButtonState.disabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.next,
    }));
  }, []);

  return (
    <WizardView
      title={t<string>('WIZARD_CHANGE_DEVICE__TITLE')}
      onWizardExit={() => {
        dispatch(disposeDevice(deviceInstanceId));
        dispatch(disposeDevice(deviceNewInstanceId));
      }}
    >
      <PageSelectActiveDevice
        title={t<string>('WIZARD_CHANGE_DEVICE__PAGE_SELECT_DEVICE__TITLE')}
        hintTextLine1={t<string>('WIZARD_CHANGE_DEVICE__PAGE_SELECT_DEVICE__HINTLINE1')}
        hintTextLine2={t<string>('WIZARD_CHANGE_DEVICE__PAGE_SELECT_DEVICE__HINTLINE2')}
        supportedWizard={DeviceModelStatus.Wizards.WizardType.CHANGEDEVICE}
        first
        notSupport0Serial
        deviceInstanceId={deviceInstanceId}
      />
      <Page2ChangeDeviceBackup
        title={t<string>('WIZARD_CHANGE_DEVICE__PAGE_CREATE_BACKUP__TITLE')}
        previewMenu={previewMenu}
        deviceInstanceId={deviceInstanceId}
        onChangeToNextPage={() => {
          dispatch(deviceWizardSetButtons({
            source: 'WIZARD_CHANGE_DEVICE__PAGE_CREATE_BACKUP__TITLE',
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.disabled },
            next: { state: ButtonState.hidden, label: WIZARD_BUTTON_LABEL__TRANSFER },
            complete: { state: ButtonState.disabled },
            pcta: ButtonRole.complete,
          }));
        }}
      />
      <Page3ChangeDevice
        title={t<string>('WIZARD_CHANGE_DEVICE__PAGE_SELECT_REPLACEMENT__TITLE')}
        deviceInstanceId={deviceInstanceId}
        deviceNewInstanceId={deviceNewInstanceId}
        onChangeToNextPage={() => {
          const downloadValues: Services.DeviceModel.StatusValueRef[] = Object.keys(device1DatasetValues)
            .map((key) => (
              {
                identRef: key,
                value: device1DatasetValues[key].value,
                backupValue: undefined,
              }));
          // Start download
          dispatch(executeDeviceDownloadMethod({
            targetInstance: deviceNewInstanceId,
            methodIdent: DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF,
            values: downloadValues,
          }));

          dispatch(deviceWizardUpdateButtons({
            source: 'WIZARD_CHANGE_DEVICE__PAGE_SELECT_REPLACEMENT__TITLE',
            abort: ButtonState.disabled,
            back: ButtonState.disabled,
            next: ButtonState.hidden,
            complete: ButtonState.disabled,
            pcta: ButtonRole.complete,
          }));
        }}
      />
      <Page4Download
        title={t<string>('WIZARD_CHANGE_DEVICE__PAGE_DOCUMENTATION__TITLE')}
        reportMenu={reportMenu}
        deviceInstanceId={deviceInstanceId}
        deviceNewInstanceId={deviceNewInstanceId}
      />
    </WizardView>
  );
};

export default ChangeDeviceWizard;
