/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import UnsupportedControl from '../../../UnsupportedControl/UnsupportedControl';
import NumberEditorControl from '../../NumberEditorControl/NumberEditorControl';
import './VariableIntegerEditorControl.css';

import { updateDeviceDatasetValues } from '../../../../store/deviceInstances/store/deviceDataset/actions';
import { VariableEditorControlsProps } from '../types';
import useIntegerMessageContextHelp from '../hooks/useIntegerMessageContextHelp';

// eslint-disable-next-line import/prefer-default-export
export const VariableIntegerEditorControl:
React.FC<VariableEditorControlsProps> = (props: VariableEditorControlsProps):
React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp, showContextMessageDef,
    readonly, deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warningMessage, showContentHelp, hideContentHelp] = useIntegerMessageContextHelp(statusDescriptor, statusValue, showContextMessageDef, onShowContentHelp, onHideContentHelp);

  if (statusDescriptor === undefined) {
    return <div />;
  }
  if (statusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="VariableUIntegerEditorControl: Unsupported input object type" />;
  }
  if (statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER) {
    return <UnsupportedControl text="VariableDateEditorControl: only INTEGER type is supported" />;
  }

  const { label } = statusDescriptor;
  const { stepSize, displayFormat } = statusDescriptor.valueType;

  return (
    <NumberEditorControl
      label={t<string>(label)}
      value={statusValue === undefined ? 'N/A' : statusValue.value}
      displayFormat={displayFormat ?? '%d'}
      readonly={readonly ?? (statusValue === undefined || statusDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY)}
      stepSize={stepSize}
      warning={warningMessage}
      modified={statusValue?.modified}
      onValueChange={(originalValue) => {
        if (statusValue === undefined) {
          return;
        }

        const parsedValue = parseInt(originalValue, 10);
        if (Number.isNaN(parsedValue)) {
          dispatch(updateDeviceDatasetValues(deviceInstanceId, {
            targetDataset: DatasetType.user,
            values: [{
              value: originalValue,
              backupValue: statusValue.backupValue,
              identRef: statusValue.identRef,
              valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
              valueValidityDescription: t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT'),
            }],
          }));
        } else {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            value: parsedValue,
            backupValue: statusValue.backupValue,
            identRef: statusValue.identRef,
          }]));
        }
      }}
      onFocus={() => showContentHelp()}
      onBlur={() => hideContentHelp()}
    />
  );
};
