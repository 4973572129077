/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { DeviceModelStatus, Services } from '@gpt/commons';
import { ParameterControl } from '../../../components/ParameterEditor';
import { UnsupportedControl } from '../../../components/UnsupportedControl';
import { useContextDatasetDescriptor } from '../../../hooks/useContextDataset';
import { ReduxControlProps } from '../../ReduxControlProps';
import withControlVisibility from '../../hoc/withControlVisibility';
import { ioLinkWriteParameter } from '../../../store/deviceInstances/middleware/iolink/ioLinkWriteParameter/actions';
import { useDeviceConnectionStatus } from '../../../store/deviceInstances/hooks/StandardViewHooks';

const IoLinkCommandControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const connectionStatus = useDeviceConnectionStatus(deviceInstanceId);
  const controlDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);

  if (controlDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="Only ControlDescriptor is supported" />;
  }
  const { controlType } = controlDescriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLIOLINKCOMMAND) {
    return <UnsupportedControl text="Only CTLIOLINKCOMMAND is supported" />;
  }

  const disabled = connectionStatus === Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;
  const actionLabel = t<string>(controlType.label);

  return (
    <>
      <ParameterControl label="">
        <Button
          className="button-text w-100"
          variant="secondary"
          block
          disabled={disabled}
          onClick={() => {
            dispatch(ioLinkWriteParameter({
              targetInstance: deviceInstanceId,
              actionId: controlType.methodIdentRef,
              index: controlType.index,
              subindex: controlType.subindex,
              data: controlType.data,
            }));
          }}
        >
          {actionLabel}
        </Button>
      </ParameterControl>
    </>
  );
};

export default withControlVisibility(IoLinkCommandControl);
