/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, {
  PropsWithChildren, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { NatsClientConnection } from './nats/connection';
import { DiscoveryServiceConnectionStateEvent, DiscoveryServiceUpdateEvent } from './events';
import { NatsServerConnectionState } from './nats/webworker.types';
import { IoLinkMasterEvent } from './events/iolinkmasterevent';
import { NatsConnectionContext } from './NatsConnectionContext';
import { addIoLinkEventMonitor } from '../store/deviceInstances/store/ioLinkEventMonitor/actions';
import { updateDiscoveryList, setDiscoveryConnectionState } from '../store/discoveryServiceState';

const natsConnection = new NatsClientConnection();

export type NatsConnectionProps = PropsWithChildren<unknown>

const NatsConnectionController: React.FC<NatsConnectionProps> = (props: NatsConnectionProps)
: React.ReactElement => {
  const { children } = props;
  const [connect, setConnect] = useState(false);
  // eslint-disable-next-line max-len
  const [natsConnectionStatus, setNatsConnectionStatus] = useState(NatsServerConnectionState.Disconnected);
  const natsConnectionRef = useRef<NatsClientConnection>(natsConnection);
  const dispatch = useDispatch();

  useEffect(() => {
    setConnect(true);
  }, []);

  useEffect(() => {
    if (!connect) {
      return () => 0;
    }
    const onConnectionState = (msg: DiscoveryServiceConnectionStateEvent) => {
      setNatsConnectionStatus(msg.state);
    };
    const onDiscovery = (msg: DiscoveryServiceUpdateEvent) => {
      (msg.payload.adapter ?? []).forEach((adapter) => dispatch(updateDiscoveryList(adapter)));
    };
    const onIoLinkMasterEvent = (msg: IoLinkMasterEvent) => {
      dispatch(addIoLinkEventMonitor(msg.payload.DeviceInstanceId, msg.payload));
    };

    if (natsConnectionRef.current.isClosed()) {
      natsConnectionRef.current.on('on-connection-state', onConnectionState);
      natsConnectionRef.current.on('on-discovery', onDiscovery);
      natsConnectionRef.current.on('on-iolink-master-event', onIoLinkMasterEvent);
      natsConnectionRef.current.connect('discovery', 'nats://127.0.0.1:4223');
    }
    return () => {
      natsConnectionRef.current.off('on-connection-state', onConnectionState);
      natsConnectionRef.current.off('on-discovery', onDiscovery);
      natsConnectionRef.current.off('on-iolink-master-event', onIoLinkMasterEvent);
      natsConnectionRef.current.disconnect();
    };
  }, [connect]);

  useEffect(() => {
    dispatch(setDiscoveryConnectionState(natsConnectionStatus));
  }, [natsConnectionStatus]);

  return (
    <NatsConnectionContext.Provider value={{
      connection: natsConnection, state: natsConnectionStatus, connect: () => setConnect(true),
    }}
    >
      {children}
    </NatsConnectionContext.Provider>
  );
};

export default NatsConnectionController;
