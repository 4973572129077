import { Resource } from 'i18next';

import i18nEn from './ECM_UC_en.json';
import i18nDe from './ECM_UC_de.json';

const ECM_UC: Resource = {
  en: {
    ECM_UC: i18nEn,
  },
  de: {
    ECM_UC: i18nDe,
  },
};

export default ECM_UC;
