/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import Button from 'react-bootstrap/Button';
import RightArrow from '../../../assets/icons/splitter-arrow.svg';
import { ParameterControl } from '../../ParameterEditor';
import './DeviceValuePicker.css';

export interface DeviceValuePickerProps {
    unit: string;
    onLeftButtonClick: () => void;
    onRightButtonClick: () => void;
    createLeftControl: () => React.ReactElement;
    createRightControl: () => React.ReactElement;
    createMiddleControl: () => React.ReactElement;
}

const DeviceValuePicker
  : React.FC<DeviceValuePickerProps> = (props: DeviceValuePickerProps)
: React.ReactElement => {
    const {
      unit,
      onLeftButtonClick, onRightButtonClick,
      createLeftControl, createRightControl, createMiddleControl,
    } = props;

    const iconToLeft = {
      transform: 'rotate(180deg)',
    };

    return (
      <ParameterControl
        label=""
        unit={unit}
      >
        <div className="device-value-picker-container">
          <div>
            {createLeftControl()}
          </div>
          <div>
            <Button variant="tertiary" className="device-value-picker__button rounded-0 border-0 text-format" onClick={() => onLeftButtonClick()}>
              <img style={iconToLeft} className="device-value-picker__button__arrow p-0 m-0" src={RightArrow} alt="button icon" />
            </Button>
          </div>
          <div>
            {createMiddleControl()}
          </div>
          <div>
            <Button variant="tertiary" className="device-value-picker__button rounded-0 border-0 text-format" onClick={() => onRightButtonClick()}>
              <img className="device-value-picker__button__arrow p-0 m-0" src={RightArrow} alt="button icon" />
            </Button>
          </div>
          <div>
            {createRightControl()}
          </div>
        </div>
      </ParameterControl>
    );
  };

export default DeviceValuePicker;
