/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useCallback } from 'react';
import { Services } from '@gpt/commons';
import { isAbortError } from '@gpt/cxe-dp-integration';
import { useDispatch } from 'react-redux';
import { MIME_TYPE__CXEDP, FILE_EXTENTION__CXEDP } from '../../../helpers/constants';
import validateExternalDataset from '../../../helpers/validateExternalDataset';
import appFileSystemService from '../../../services/AppService/AppFileSystemService';
import {
  activeDeviceLoadDatasetContent,
} from '../../../store';
import { showApplicationMessage } from '../../../store/applicationMessage/actions';
import { MessageType } from '../../../store/applicationMessage/types';
import { ModelInstanceInformation } from '../../../store/deviceInstances/store/activeDevice/types';

const parseContent = async (content: string):
  Promise<Services.DeviceModelServer.DatasetProviderService.ExternalDataset> => {
  const dataset = JSON
    .parse(content) as Services.DeviceModelServer.DatasetProviderService.ExternalDataset;
    // TODO: Check version
  await validateExternalDataset(dataset);
  return dataset;
};

const useDeviceViewLoadExternalFile = () => {
  const dispatch = useDispatch();

  const loadExternalFile = useCallback((activeDeviceInstance: ModelInstanceInformation | undefined) => {
    const showErrorMessage = (messageType: MessageType, messageId: string) => {
      dispatch(showApplicationMessage(messageType, messageId));
    };

    appFileSystemService.ImportLocalFile({
      mimeType: MIME_TYPE__CXEDP,
      extensions: [FILE_EXTENTION__CXEDP],
    })
      .then((importResult) => importResult.contents as string)
      .then((content) => parseContent(content))
      .then((dataset) => {
        const profileName = activeDeviceInstance?.device.catalog.profileName;
        if (profileName === undefined) {
          showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__INCOMPATIBLE');
          return;
        }

        const orderNumber = activeDeviceInstance?.device.catalog.productOrderNumber;
        try {
          const datasetProfileName = dataset.header.device.catalog.profileName.toLocaleLowerCase();
          const datasetOrderCode = `${dataset.header.device.catalog.productOrderNumber}`;

          const deviceProfileName = profileName.toLocaleLowerCase();
          const deviceOrderCode = `${orderNumber}`;

          // Check if device profileName
          if (deviceProfileName !== datasetProfileName && deviceOrderCode !== datasetOrderCode) {
            showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__INCOMPATIBLE');
            return;
          }

          if (activeDeviceInstance?.deviceInstanceId) {
            dispatch(activeDeviceLoadDatasetContent(activeDeviceInstance.deviceInstanceId, dataset));
            showErrorMessage('success', 'LOAD_PARAMETER_FILE__LOAD_DATA__SUCCESS');
          } else {
            showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__INCOMPATIBLE');
          }
        } catch {
          showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__INCOMPATIBLE');
        }
      })
      .catch((error: any) => {
        if (isAbortError(error)) {
          showErrorMessage('warning', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__CANCELLED');
        } else {
          showErrorMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__CANNOT_READ_CONTENT');
        }
      });
  }, [dispatch]);
  return loadExternalFile;
};

export default useDeviceViewLoadExternalFile;
