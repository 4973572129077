/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

/* eslint-disable max-len */
/* eslint-disable quote-props */
import React from 'react';
import { toFixedValue } from '@gpt/commons';
import { renderToString } from 'react-dom/server';
import ReactApexChart from 'react-apexcharts';
import { ApexTrendSerieData, trendColorsMap } from '../types';
import { CurrentStatusControl } from '../../CurrentStatusControl/CurrentStatusControl';
import './ApexTrendControl.css';

export interface ApexTrendControlProps {
  id: string;
  series: {
    name: string;
    color: string;
    data: ApexTrendSerieData[];
    range?: {
      minValue?: number;
      maxValue?: number;
      step?: number;
    }
  }[];
}

const ApexTrendControl: React.FC<ApexTrendControlProps> = (props: ApexTrendControlProps): React.ReactElement => {
  const {
    id,
    series: inputSeries,
  } = props;

  const xaxis = {
    type: 'datetime',
    labels: {
      formatter: (value, timestamp) => {
        const hour = `00${new Date(timestamp ?? 0).getHours()}`.slice(-2);
        const min = `00${new Date(timestamp ?? 0).getMinutes()}`.slice(-2);
        const sec = `00${new Date(timestamp ?? 0).getSeconds()}`.slice(-2);
        return `${hour}:${min}:${sec}`;
      },
    },
    range: 30000,
    axisTicks: {
      color: '#5C5C5C',
    },
  };

  const yaxis = inputSeries
    .map((data, index) => ({
      opposite: (index % 2 !== 0),
      seriesName: data.name,
      title: {
        text: data.name,
        style: {
          color: trendColorsMap[data.color] ?? '#FFFFFF',
        },
      },
      labels: {
        formatter: (value) => toFixedValue(value, 2),
      },
      tickAmount: 5,
      min: typeof data.range === 'undefined' ? 0 : data.range.minValue,
      max: typeof data.range === 'undefined' ? 10000 : data.range.maxValue,
    }));

  const seriesWithoutColors = inputSeries.map(({ color, ...rest }) => rest);

  return (
    <ReactApexChart
      type="line"
      height="100%"
      series={seriesWithoutColors}
      options={({
        chart: {
          id,
          height: '100%',
          stacked: false,
          fontFamily: 'IBM Plex Sans',
          animations: {
            enabled: false,
            easing: 'linear',
            dynamicAnimation: {
              speed: 900,
            },
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          width: 4,
        },
        colors: inputSeries.map((seriesItem) => trendColorsMap[seriesItem.color]),
        markers: {
          size: 0,
        },
        xaxis: {
          ...xaxis,
          type: 'datetime',
        },
        yaxis,
        legend: {
          show: false,
          position: 'top',
          horizontalAlign: 'center',
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
          // eslint-disable-next-line func-names
          custom: function ({
            series, seriesIndex, dataPointIndex, w,
          }) {
            const data = series.map((sr, idx) => `<div class="p-1">${renderToString(<CurrentStatusControl variableTitle={w.globals.seriesNames[idx]} currentValue={toFixedValue(sr[dataPointIndex], 2) ?? 'N/A'} color={inputSeries[idx].color} />)}</div>`);
            return `<div class="p-2">${data.join('')}</div>`;
          },
        },
        grid: {
          borderColor: '#5C5C5C',
        },
      })}
    />
  );
};

export default ApexTrendControl;
