/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { ReduxControlProps } from '../../ReduxControlProps';
import { Quint4DigitalSignalControl } from '../Quint4DigitalSignal/Quint4DigitalSignal';
import { Quint4SignalVariableBlock } from '../Quint4DigitalSignal/types';

const blockVariables = Quint4SignalVariableBlock(3);

const Quint4SignalOut3: React.FC<ReduxControlProps> = (props: ReduxControlProps)
  : React.ReactElement => {
  const { deviceInstanceId, identRef, onStateChanged } = props;

  return (
    <Quint4DigitalSignalControl
      identRef={identRef}
      deviceInstanceId={deviceInstanceId}
      blockVariables={blockVariables}
      showContextMessageDef
      onStateChanged={onStateChanged}
    />
  );
};

export default Quint4SignalOut3;
