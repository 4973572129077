/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  typeStationAction,
  StationState,
  AddStationDiscoveryAction,
  RemoveStationDiscoveryAction,
  StationDiscoveryItem,
  InitializeStationAction,
} from './types';

export const initialStationState: StationState = {
  instanceId: '',
  subtitle: '',
  title: '',
  discovery: [],
};

const reducerSwitchFunction = {
  STATION_DISCOVERY__ADD_DISCOVERY_INFORMATION: (state: StationState, action: AddStationDiscoveryAction)
    : StationState => {
    const { payload } = action;
    const { data } = payload;
    // Update existing elements
    const discovery: StationDiscoveryItem[] = state.discovery.map((disc) => {
      const item = data.find((ax) => ax.id === disc.id);
      return item ?? disc;
    });

    const addItems = data.filter((disc) => discovery.find((d) => d.id === disc.id) === undefined);
    const newstate = {
      ...state,
      discovery: [
        ...discovery,
        ...addItems,
      ],
    };
    return newstate;
  },
  STATION_DISCOVERY__REMOVE_DISCOVERY_INFORMATION: (state: StationState, action: RemoveStationDiscoveryAction)
  : StationState => {
    const { payload } = action;
    const { data } = payload;
    const discovery: StationDiscoveryItem[] = state.discovery.filter((disc) => data.find((d) => d === disc.id) === undefined);
    return {
      ...state,
      discovery,
    };
  },
  STATION_DISCOVERY__INITIALIZE: (state: StationState, action: InitializeStationAction)
  : StationState => {
    const { payload } = action;
    const { data } = payload;
    return {
      ...state,
      instanceId: data.instanceId,
      subtitle: data.subtitle,
      title: data.title,
    };
  },
};

export const stationStateReducer = (
  state = initialStationState,
  action: typeStationAction,
): StationState => (reducerSwitchFunction[action.type] === undefined ? state
  : reducerSwitchFunction[action.type](state, action as any));
