/* eslint-disable class-methods-use-this */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER device parametrization tool
 * Component: DeviceModelServer
 *
 **************************************************************************** */

/* eslint-disable max-len */
import {
  IService, Services, WebDevice,
} from '@gpt/commons';
import { v4 as uuidv4 } from 'uuid';
import {
  ENV_APP_MODE, ENV_DEVICEMODEL_SERVER_HOST, ENV_DEVICEMODEL_SERVER_PORT, ENV_WEBSERVER_REQUEST_PATH,
} from '../../enviroment';
import { fetchGetData, fetchReadData, fetchWriteData } from './fetchMethod';

type ReportServiceRequest = Services.DeviceModelServer.ReportServiceRequest;
type ReportServiceResponse = Services.DeviceModelServer.ReportServiceResponse;

export interface SimulationConfiguration {
  /**
   * Indicates if device simulation shall be enabled in general.
   */
   simEnabled: boolean;

   /**
   * Information about devices to be requested for simulation (type and count).
   * key: device type identifier, value: according instance count
   */
   deviceCount: {[key: string]: number};
}

export interface UserInformation {
  username: string;
  hostname: string;
}

/**
 * Configuration file 'app.config.json' key for communication simulation settings.
 */
const SimulationConfigKey = 'communication.simulation';
/** Configuration file comm. simulation sub key for en/disabled simulation. */
const SimulationSimEnabled = 'enabled';
/** Configuration file comm. simulation sub key for device types and count. */
const SimulationDeviceCount = 'deviceCount';

export interface IInfrastructureService extends IService {
  // eslint-disable-next-line no-unused-vars
  getConfigValue: (configKey: string) => Promise<Services.DeviceModelServer.ConfigValue | undefined>;
  // eslint-disable-next-line no-unused-vars
  setConfigValue: (configKey: string, configValue: Services.DeviceModelServer.ConfigValue) => Promise<boolean>;
  // eslint-disable-next-line no-unused-vars
  getSystemInfo: () => Promise<Services.DeviceModelServer.SystemInformationResponse>;
  // eslint-disable-next-line no-unused-vars
  getTextDocument: (fileName: string) => Promise<string>;

  getUserInformation: () => Promise<UserInformation>;
  // eslint-disable-next-line no-unused-vars
  getSimulationConfiguration: () => Promise<SimulationConfiguration>;
  // eslint-disable-next-line no-unused-vars
  setSimulationConfiguration: (config: SimulationConfiguration) => Promise<boolean>;
  // eslint-disable-next-line no-unused-vars
  createReport: (req: ReportServiceRequest) => Promise<ReportServiceResponse>;
  // eslint-disable-next-line no-unused-vars
  downloadDeviceModel: (deviceCatalogFilename: string) => Promise<string | undefined>;

  getDeviceCatalog: () => Promise<WebDevice.DeviceCatalog | undefined>;
}

// eslint-disable-next-line import/prefer-default-export
class InfrastructureService implements IInfrastructureService {
  // eslint-disable-next-line class-methods-use-this
  public ServiceName = () => 'DeviceInstanceManager';

  public getConfigValue = async (configKey: string): Promise<Services.DeviceModelServer.ConfigValue | undefined> => {
    let response: Services.DeviceModelServer.GetConfigurationResponse | undefined;
    try {
      response = await fetchReadData<Services.DeviceModelServer.GetConfigurationRequest, Services.DeviceModelServer.GetConfigurationResponse>(
        `${ENV_DEVICEMODEL_SERVER_HOST}:${ENV_DEVICEMODEL_SERVER_PORT}/api/config/value`,
        {
          requestId: uuidv4(),
          deviceInstanceId: '--',
          configKey,
        },
      );
    } catch {
      response = undefined;
    }

    return response === undefined || response.error
      ? undefined
      : response.configValue;
  };

  public setConfigValue = async (configKey: string, configValue: Services.DeviceModelServer.ConfigValue): Promise<boolean> => {
    let response = false;
    try {
      response = await fetchWriteData<Services.DeviceModelServer.SetConfigurationRequest>(
        `${ENV_DEVICEMODEL_SERVER_HOST}:${ENV_DEVICEMODEL_SERVER_PORT}/api/config/value`,
        {
          requestId: uuidv4(),
          deviceInstanceId: '--',
          configValue,
          configKey,
        },
      );
    } catch {
      response = false;
    }
    return response;
  };

  public getSystemInfo = async (): Promise<Services.DeviceModelServer.SystemInformationResponse> => {
    let systemInfo: Services.DeviceModelServer.SystemInformationResponse | undefined;
    try {
      const response = await fetchReadData<Services.DeviceModelServer.SystemInformationRequest, Services.DeviceModelServer.SystemInformationResponse>(
        `${ENV_DEVICEMODEL_SERVER_HOST}:${ENV_DEVICEMODEL_SERVER_PORT}/api/system/info`,
        {
          requestId: uuidv4(),
          deviceInstanceId: '--',
        },
      );
      if (response?.error) throw response.error;
      systemInfo = response;
    } catch {
      systemInfo = undefined;
    }
    return systemInfo ?? {
      serverVersion: 'unknown',
      buildDate: 'unknown',
      nodeJsVersion: 'unknown',
      requestId: uuidv4(),
      deviceInstanceId: '--',
    };
  };

  public getTextDocument = async (fileName: string): Promise<string> => {
    let text = '{}';
    try {
      const response = await fetchReadData<Services.DeviceModelServer.GetTextDocumentRequest, Services.DeviceModelServer.GetTextDocumentResponse>(
        `${ENV_DEVICEMODEL_SERVER_HOST}:${ENV_DEVICEMODEL_SERVER_PORT}/api/system/document`,
        {
          requestId: uuidv4(),
          deviceInstanceId: '--',
          fileName,
        },
      );
      if (response?.error) throw response.error;
      text = response?.contents ?? '{}';
    } catch {
      text = '{}';
    }
    return text;
  };

  public getSimulationConfiguration = async (): Promise<SimulationConfiguration> => {
    const defaultConfig: SimulationConfiguration = {
      deviceCount: {},
      simEnabled: false,
    };

    const configValue = await this.getConfigValue(SimulationConfigKey);
    if (configValue === undefined) {
      return defaultConfig;
    }

    if (typeof configValue !== 'object') {
      return defaultConfig;
    }

    const simEnabled = configValue?.[SimulationSimEnabled] ?? false;
    const deviceCount = configValue?.[SimulationDeviceCount] ?? {};
    return {
      simEnabled,
      deviceCount,
    };
  };

  /**
 * Changes the current device simulation configuration settings
 * (generally en/disabled, device types and according instance counts).
 */
  public setSimulationConfiguration = async (config: SimulationConfiguration): Promise<boolean> => {
    const configValue = await this.setConfigValue(
      SimulationConfigKey,
      {
        [SimulationSimEnabled]: config.simEnabled,
        [SimulationDeviceCount]: config.deviceCount,
      },
    );
    return configValue !== undefined;
  };

  public getUserInformation = async (): Promise<UserInformation> => {
    let response: UserInformation | undefined = { hostname: '', username: '' };

    try {
      response = await fetchGetData<UserInformation>(
        `${ENV_DEVICEMODEL_SERVER_HOST}:${ENV_DEVICEMODEL_SERVER_PORT}/api/system/userinfo`,
      );
    } catch {
      response = { hostname: '', username: '' };
    }
    return response ?? { hostname: '', username: '' };
  };

  public createReport = async (request: ReportServiceRequest): Promise<ReportServiceResponse> => {
    let response: ReportServiceResponse | undefined;
    const { requestId, deviceInstanceId } = request;
    try {
      response = await fetchReadData<ReportServiceRequest, ReportServiceResponse>(
        `${ENV_DEVICEMODEL_SERVER_HOST}:${ENV_DEVICEMODEL_SERVER_PORT}/api/report/createReport`,
        request,
      );
    } catch (ex) {
      response = undefined;
    }

    return response ?? {
      requestId,
      deviceInstanceId,
      documentDataUrl: '',
      error: {
        name: 'RESPONSE_UNDEFINED',
        message: 'createReport() failed: response undefined',
      },
    };
  };

  public downloadDeviceModel = async (deviceCatalogFilename: string): Promise<string | undefined> => {
    const response = await fetch(`${ENV_WEBSERVER_REQUEST_PATH}catalog/${deviceCatalogFilename}`);
    if (response.status === 404) {
      throw new Error(response.statusText);
    }
    if (response.status === 200) {
      const data = await response.text();
      return data;
    }
    return undefined;
  };

  public getDeviceCatalog = async (): Promise<WebDevice.DeviceCatalog | undefined> => {
    let response: WebDevice.DeviceCatalog | undefined;
    const request = ENV_APP_MODE !== 'CLOUD'
      ? `${ENV_WEBSERVER_REQUEST_PATH}catalog/catalog.json`
      : `${ENV_WEBSERVER_REQUEST_PATH}catalog/catalog.json?v=2.2.0`;

    try {
      response = await fetchGetData<WebDevice.DeviceCatalog>(request);
    } catch (err) {
      response = undefined;
    }
    return response;
  };
}

export const infrastructureService: IInfrastructureService = new InfrastructureService();
