/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import { MiddlewareAPI } from 'redux';

export enum ExecutionState {
    init = 0,
    pending = 1,
    success = 2,
    failed = 3
}

export interface MiddlewareService {
  startService: (api: MiddlewareAPI) => Promise<void>;
  stopService: (api: MiddlewareAPI) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type typeCreateMiddlewareService<T> = (...args: any[])
    => T;
