/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import { ReduxControlProps } from '../ReduxControlProps';
import InvisibleReduxControl from '../InvisibleReduxControl/InvisibleReduxControl';

const useVisibility = (deviceInstanceId: string, identRef: IdentRef): boolean => {
  const desc = useContextDatasetDescriptor(deviceInstanceId, identRef);
  if (desc?.type === DeviceModelStatus.StatusType.ControlDescriptor) {
    return desc.visibility ?? true;
  }
  if (desc?.type === DeviceModelStatus.StatusType.StructureDescriptor) {
    return desc.visibility ?? true;
  }
  if (desc?.type === DeviceModelStatus.StatusType.StatusDescriptor) {
    return desc.visibility;
  }
  return true;
};

const withControlVisibility = <T extends ReduxControlProps>(WrappedComponent: React.ComponentType<T>) => {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithVisibility = (props: T) => {
    const { identRef, onStateChanged, deviceInstanceId } = props;
    // Fetch the props you want to inject. This could be done with context instead.
    const visibility = useVisibility(deviceInstanceId, identRef);
    if (visibility === false) {
      return <InvisibleReduxControl deviceInstanceId={deviceInstanceId} identRef={identRef} onStateChanged={onStateChanged} />;
    }
    // props comes afterwards so the can override the default ones.
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} />;
  };

  ComponentWithVisibility.displayName = `withControlVisibility(${displayName})`;

  return ComponentWithVisibility;
};

export default withControlVisibility;
