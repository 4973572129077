/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useCallback, useContext } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { DatasetContext } from '../../../views/DatasetContext';
import { useTypedSelector, writeActiveDeviceVariableValues } from '../../../store';
import { DatasetState } from '../../../store/deviceInstances/store/deviceDataset/types';

const thumbWheelControlSelector = (dataset: DatasetState, identRef: string) => {
  const descriptor = dataset.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return [undefined, '', false, true] as const;
  }

  const { controlType } = descriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLTHUMBWHEEL) {
    return [undefined, '', false, true] as const;
  }

  const wheelValueVariable = controlType.variable;
  const wheelVariableDescriptor = dataset.descriptors[wheelValueVariable];
  if (wheelVariableDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return [undefined, '', false, true] as const;
  }

  const statusValue = dataset.values[wheelValueVariable];
  const hidden = descriptor.visibility === false || statusValue?.valueValidity !== DeviceModelStatus.StatusValueValidity.valid;

  return [statusValue, controlType.label, !hidden, wheelVariableDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY] as const;
};

const useThumbWheelControl = (identRef: string, deviceInstanceId: string) => {
  const dispatch = useDispatch();

  const { targetDataset } = useContext(DatasetContext);
  const [wheelStatusValue, label, visibility, readonly] = useTypedSelector(
    (state) => thumbWheelControlSelector(state.deviceInstances.instances[deviceInstanceId].deviceDataset[targetDataset], identRef),
  );

  const writeWheelValue = useCallback((value: number, varIdentRef: string, varBackupValue: number) => {
    dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
      value,
      backupValue: varBackupValue,
      identRef: varIdentRef,
    }]));
  }, [deviceInstanceId]);

  return [wheelStatusValue, visibility, label, readonly, writeWheelValue] as const;
};

export default useThumbWheelControl;
