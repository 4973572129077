/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  Services,
} from '@gpt/commons';
import { Action } from 'redux';
import { ExternalFileContent } from '../../../types';
import { DeviceInstanceAction } from '../../common';

export const ACTIVE_DEVICE__WRITE_VARIABLE_VALUES = 'ACTIVE_DEVICE__WRITE_VARIABLE_VALUES';
export const ACTIVE_DEVICE__LOAD_DATASET__FROM__FILE = 'ACTIVE_DEVICE__LOAD_DATASET__FROM__FILE';
export const ACTIVE_DEVICE__LOAD_DATASET__CONTENT = 'ACTIVE_DEVICE__LOAD_DATASET__CONTENT';
export const ACTIVE_DEVICE__WRITE_INITIAL_DATASET = 'ACTIVE_DEVICE__WRITE_INITIAL_DATASET';

export interface ExternalFileContentPayload {
  externalFile: ExternalFileContent;
}
export type ActiveDeviceUploadDatasetFromFileAction = DeviceInstanceAction<typeof ACTIVE_DEVICE__LOAD_DATASET__FROM__FILE, ExternalFileContentPayload>

export interface ExternalDatasetPayload {
  externalDataset: Services.DeviceModelServer.DatasetProviderService.ExternalDataset;
}
export type ActiveDeviceLoadDatasetContentAction = DeviceInstanceAction<typeof ACTIVE_DEVICE__LOAD_DATASET__CONTENT, ExternalDatasetPayload>

export interface WriteVariableValuesPayload {
  values: Services.DeviceModel.StatusValueRef[];
}

export type WriteVariableValuesAction = DeviceInstanceAction<typeof ACTIVE_DEVICE__WRITE_VARIABLE_VALUES, WriteVariableValuesPayload>

export interface WriteInitialDatasetPayload {
  deviceInstance: string;
}

export interface WriteInitialDatasetAction extends Action<typeof ACTIVE_DEVICE__WRITE_INITIAL_DATASET> {
  payload: WriteInitialDatasetPayload;
}

export type typeDeviceInstanceActiveDeviceMiddlewareActions =
 | ActiveDeviceUploadDatasetFromFileAction
 | WriteVariableValuesAction
 | WriteInitialDatasetAction
 | ActiveDeviceLoadDatasetContentAction;
