/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { toFixedValue } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { CurrentStatusControl, CurrentStatusControlType } from '../CurrentStatusControl/CurrentStatusControl';
import './CurrentStatusListControl.css';

export interface CurrentStatusListControlProps {
    statusList: CurrentStatusControlType[];
}

export const CurrentStatusListControl: React.FC<CurrentStatusListControlProps> = (
  props: CurrentStatusListControlProps,
)
  : React.ReactElement => {
  const { statusList } = props;
  const { t } = useTranslation();

  return (
    <div className="CurrentStatusListControl__Container">
      { statusList.map((item, index) => {
        const curValue = Number.parseFloat(item.currentValue);
        const title = item.title === undefined ? undefined : t<string>(item.title);
        const unit = item.unit === undefined ? undefined : t<string>(item.unit);
        return (
          <CurrentStatusControl
            // eslint-disable-next-line react/no-array-index-key
            key={`${title}-${index}`}
            title={title}
            variableTitle={t(item.variableTitle)}
            currentValue={Number.isNaN(curValue) ? 'N/A' : toFixedValue(curValue, 2)}
            unit={unit}
            color={item.color}
          />
        );
      })}
    </div>
  );
};
