/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  Services,
} from '@gpt/commons';
import { Action } from 'redux';

export const DATASET_PROVIDER__REQUEST_DATASET_LIST = 'DATASET_PROVIDER__REQUEST_DATASET_LIST';
export const DATASET_PROVIDER__REQUEST_EXTERNAL_DEVICE_DATASET = 'DATASET_PROVIDER__REQUEST_EXTERNAL_DEVICE_DATASET';
export const DATASET_PROVIDER__SAVE_DATASET__EXTERNAL_FILE = 'DATASET_PROVIDER__SAVE_DATASET__EXTERNAL_FILE';

export interface WriteDatasetToExternalFilePayload {
  deviceInstanceId: string;
  filename: string;
  menu: string;
}

export interface WriteDatasetToExternalFileAction
  extends Action<typeof DATASET_PROVIDER__SAVE_DATASET__EXTERNAL_FILE> {
    payload: WriteDatasetToExternalFilePayload;
}

/**
 * Request Dataset List from DatasetProvider Service
 */
export interface DatasetListRequestAction extends Action<typeof DATASET_PROVIDER__REQUEST_DATASET_LIST> {
  payload: Services.DeviceModelServer.DatasetProviderService.DatasetFilter;
}

/**
 * Request external dataset from dataset provider service
 */
export interface RequestExternalDeviceDatasetPayload {
  deviceInstanceId: string;
}
export interface RequestExternalDeviceDatasetAction extends Action<typeof DATASET_PROVIDER__REQUEST_EXTERNAL_DEVICE_DATASET> {
  payload: RequestExternalDeviceDatasetPayload;
}

export type typeDatasetProviderMiddlewareAction = DatasetListRequestAction
  | RequestExternalDeviceDatasetAction
  | WriteDatasetToExternalFileAction;
