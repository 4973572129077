/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  REQUEST_RESOURCE_ID,
  typeResourceProviderMiddlewareAction,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const requestDeviceResource = (targetInstance: string, resourceId: string)
  : typeResourceProviderMiddlewareAction => ({
  type: REQUEST_RESOURCE_ID,
  payload: {
    targetInstance,
    data: resourceId,
  },
});
