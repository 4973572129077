/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { ReduxControlProps } from '../../ReduxControlProps';
import { useTypedSelector } from '../../../store';
import UnsupportedControl from '../../../components/UnsupportedControl/UnsupportedControl';
import { DatasetContext } from '../../../views/DatasetContext';
import { DatasetState, DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import withControlVisibility from '../../hoc/withControlVisibility';
import displayStatusValueSelector from '../../selectors/displayStatusValueSelector';
import { ChannelStatusTrioControl } from '../ChannelStatusTrioControl/ChannelStatusTrioControl';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';

const selectControlType = (
  dataset: DatasetState,
  identRef: IdentRef,
): DeviceModelStatus.UI.Trio3ChannelStatusControlType | undefined => {
  const desc = dataset.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (desc.controlType.type !== DeviceModelStatus.UI.ControlType.CTLTRIO3CHANNELSTATUS) {
    return undefined;
  }
  return desc.controlType;
};

const controlTypeSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: IdentRef,
): DeviceModelStatus.UI.Trio3ChannelStatusControlType | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (dataset === undefined) {
    return undefined;
  }
  return selectControlType(dataset, identRef);
};

const selectControlVariable = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: IdentRef,
): DeviceModelStatus.StatusDescriptor | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (dataset === undefined) {
    return undefined;
  }

  const controlType = selectControlType(dataset, identRef);
  if (controlType === undefined) {
    return undefined;
  }

  const desc = dataset.descriptors[controlType.variable];
  if (desc.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  return desc;
};

const selectControlNominalVariable = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: IdentRef,
): DeviceModelStatus.StatusDescriptor | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (dataset === undefined) {
    return undefined;
  }

  const controlType = selectControlType(dataset, identRef);
  if (controlType === undefined) {
    return undefined;
  }

  const desc = dataset.descriptors[controlType.nominalVariable];
  if (desc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  return desc;
};

const selectControlLedVariableValue = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: IdentRef,
): DeviceModelStatus.StatusValue | undefined => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (dataset === undefined) {
    return undefined;
  }

  const controlType = selectControlType(dataset, identRef);
  if (controlType === undefined) {
    return undefined;
  }

  const desc = dataset.descriptors[controlType.ledVariable];
  if (desc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }

  return dataset.values[controlType.ledVariable];
};

const ChannelStatusTrioReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const control = useTypedSelector((state) => controlTypeSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const controlVariable = useTypedSelector((state) => selectControlVariable(state.deviceInstances, deviceInstanceId, targetDataset, identRef));

  const controlVariableDisplayValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (dataset === undefined) {
      return undefined;
    }
    const desc = selectControlVariable(state.deviceInstances, deviceInstanceId, targetDataset, identRef);
    if (desc === undefined) {
      return '';
    }
    return displayStatusValueSelector(dataset, desc.identRef);
  });

  const controlNomVariable = useTypedSelector((state) => selectControlNominalVariable(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const controlNomVariableDisplayValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (dataset === undefined) {
      return undefined;
    }
    const desc = selectControlNominalVariable(state.deviceInstances, deviceInstanceId, targetDataset, identRef);
    if (desc === undefined) {
      return '';
    }
    return displayStatusValueSelector(dataset, desc.identRef);
  });

  const controlLedVariableValue = useTypedSelector((state) => selectControlLedVariableValue(state.deviceInstances, deviceInstanceId, targetDataset, identRef));

  if (control === undefined) {
    return <UnsupportedControl text={`ChannelStatusTrioReduxControl [${identRef}]: undefined control`} />;
  }
  if (controlVariable === undefined) {
    return <UnsupportedControl text={`ChannelStatusTrioReduxControl [${identRef}]: undefined control variable`} />;
  }
  if (controlNomVariable === undefined) {
    return <UnsupportedControl text={`ChannelStatusTrioReduxControl [${identRef}]: undefined control nominal variable`} />;
  }
  if (controlLedVariableValue === undefined) {
    return <UnsupportedControl text={`ChannelStatusTrioReduxControl [${identRef}]: undefined control led variable`} />;
  }

  if (controlVariable.visibility === false) {
    return (null);
  }

  const nomLabel = t<string>('TRIO3_PS:locInomLbl');
  let nomVariableTitle = '';

  if (controlNomVariable.valueType.type === DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    nomVariableTitle = `${nomLabel}: ${controlNomVariableDisplayValue}`;
  } else {
    const nomVariableUnit = controlNomVariable?.unit === undefined ? '' : t<string>(controlNomVariable.unit);
    nomVariableTitle = `${nomLabel}: ${controlNomVariableDisplayValue} ${nomVariableUnit}`;
  }

  return (
    <ChannelStatusTrioControl
      title={t<string>(control.caption)}
      nomVariableTitle={nomVariableTitle}
      variableLabel={t<string>('TRIO3_PS:locIIstLbl')}
      unit={controlVariable.unit === undefined ? '' : t<string>(controlVariable.unit)}
      currentValue={controlVariableDisplayValue ?? ''}
      colorValue={controlLedVariableValue.value}
    />
  );
};

export default withControlVisibility(ChannelStatusTrioReduxControl);
