/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { DeviceInstanceActionPayload } from '../../common';

export const IOLINK__EVENT_MONITOR__UPDATE_EVENT_LIST = 'IOLINK__EVENT_MONITOR__UPDATE_EVENT_LIST';
export const IOLINK__EVENT_MONITOR__CLEAN_EVENT_LIST = 'IOLINK__EVENT_MONITOR__CLEAN_EVENT_LIST';
export const IOLINK__EVENT_MONITOR__ADD_EVENT_LIST = 'IOLINK__EVENT_MONITOR__ADD_EVENT_LIST';

export const IOLINK__EVENT_MONITOR__ACTIONS = 'IOLINK__EVENT_MONITOR__ACTIONS';

export const IOLINKEVENTLIST_IDENT = 'IOLINK_EVENTLIST';

/* ************************************************************************************ */
/* INTERFACE                                                                            */
/* ************************************************************************************ */
export enum eIoLinkEventMode
{
    // eslint-disable-next-line no-unused-vars
    SingleShot = 64,
    // eslint-disable-next-line no-unused-vars
    Disappears = 128,
    // eslint-disable-next-line no-unused-vars
    Appears = 192
}

export enum eIoLinkEventType
{
    // eslint-disable-next-line no-unused-vars
    Message = 16,
    // eslint-disable-next-line no-unused-vars
    Warning = 32,
    // eslint-disable-next-line no-unused-vars
    Error = 48
}

export interface IoLinkEvent {
  Id: string;
  connectionId: number;
  qualifier: number;
  code: number;
  mode: eIoLinkEventMode;
  type: eIoLinkEventType;
  timestamp: string;
}

export type IoLinkEventMonitorState = {
  eventList: IoLinkEvent[];
}

/* ************************************************************************************ */
/* ACTION: AddIoLinkEventMonitorAction                                                   */
/* ************************************************************************************ */
export interface UpdateIoLinkEventMonitorAction extends Action<typeof IOLINK__EVENT_MONITOR__UPDATE_EVENT_LIST> {
  payload: string;
}

export interface AddIoLinkEventMonitorAction extends Action<typeof IOLINK__EVENT_MONITOR__ADD_EVENT_LIST> {
  payload: IoLinkEvent;
}

export type CleanIoLinkEventMonitorAction = Action<typeof IOLINK__EVENT_MONITOR__CLEAN_EVENT_LIST>

export type typeIoLinkEventMonitorActionTypes = UpdateIoLinkEventMonitorAction
 | CleanIoLinkEventMonitorAction
 | AddIoLinkEventMonitorAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceInstanceIoLinkEventMonitorAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceIoLinkEventMonitorPayload extends DeviceInstanceActionPayload {
  action: typeIoLinkEventMonitorActionTypes;
}

export interface typeDeviceInstanceIoLinkEventMonitorAction extends Action<typeof IOLINK__EVENT_MONITOR__ACTIONS> {
  payload: typeDeviceInstanceIoLinkEventMonitorPayload;
}
