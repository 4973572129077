/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceInstancesState } from '../../types';
import { DeviceMethodExecutionStatus } from './types';

// eslint-disable-next-line import/prefer-default-export
export const deviceMethodExecutionSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
  methodIdent: string,
):DeviceMethodExecutionStatus | undefined => {
  if (deviceInstanceId === undefined) {
    return undefined;
  }
  return state.instances[deviceInstanceId]?.deviceMethodExecution[methodIdent];
};
