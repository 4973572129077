/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
// eslint-disable-next-line import/prefer-default-export
export const ROOT_DEVICEINSTANCE_ID = 'ROOT_DEVICEINSTANCE_ID';
