/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/prop-types */

import React, { PropsWithChildren } from 'react';
import IconStation from '../../../assets/icons/icon-station.svg';
import './DropDownStationNav.css';

interface DropDownStationNavToggleProps extends PropsWithChildren<any> {
  onClick: (e) => void;
}

// eslint-disable-next-line max-len
export const DropDownStationNavToggle = React.forwardRef<HTMLAnchorElement, DropDownStationNavToggleProps>((props, ref) => {
  const {
    children,
    onClick,
  } = props;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdownstationnav-icon-link"
      data-testid="station-nav-toggle"
    >
      <div className="flex-container-dropdownstationnav">
        <div className="dropdownstationnav-text">
          {children}
        </div>
        <div className="dropdownstationnav-icon-container">
          <div className="dropdownstationnav-icon" />
        </div>
      </div>
    </a>
  );
});

interface DropDownStationNavMenuProps extends PropsWithChildren<any> {
  style: React.CSSProperties;
  className: string;
}

// eslint-disable-next-line max-len
export const DropDownStationNavMenu = React.forwardRef<HTMLDivElement, DropDownStationNavMenuProps>((props, ref) => {
  const {
    style,
    className,
    children,
  } = props;

  return (
    <div
      ref={ref}
      style={style}
      className={className}
    >
      {children}
    </div>
  );
});

interface DropDownStationNavStationProps {
  title: string;
  subtitle: string;
  onClick: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

// eslint-disable-next-line max-len
export const DropDownStationNavStation = React.forwardRef<HTMLInputElement, DropDownStationNavStationProps>((props, ref) => {
  const {
    onClick,
    title,
    subtitle,
  } = props;

  return (
    <div
      className="dropdownstationnav-container"
      role="button"
      onClick={onClick}
      data-testid="station-nav-station"
    >
      <div className="dropdownstationnav-container__icon">
        <img src={IconStation} alt="IconStation" />
      </div>
      <div className="dropdownstationnav-container__text">
        <div className="dropdownstationnav-container__title">
          {title}
        </div>
        <div className="dropdownstationnav-container__subtitle">
          {subtitle}
        </div>
      </div>
    </div>
  );
});

interface DropDownStationNavModuleProps {
  title: string;
  subtitle: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

// eslint-disable-next-line max-len, no-unused-vars
export const DropDownStationNavModule = React.forwardRef<HTMLInputElement, DropDownStationNavModuleProps>((props, ref) => {
  const {
    onClick,
    title,
    subtitle,
  } = props;

  return (
    <div
      className="dropdownstationnav-container"
      role="button"
      data-testid="station-nav-module"
      onClick={onClick}
    >
      <div className="dropdownstationnav-container__icon" />
      <div className="dropdownstationnav-container__text">
        <div className="dropdownstationnav-container__title">
          {title}
        </div>
        <div className="dropdownstationnav-container__subtitle">
          {subtitle}
        </div>
      </div>
    </div>
  );
});
