/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect } from 'react';
import { useTypedSelector } from '../../../store';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';

const logConsole = console;

const useInvalidValueList = (deviceInstanceId: string): void => {
  const invalidValueList = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.user);
    const text = (dataset?.invalidValueList || [])
      .map((value) => `${value.identRef} => [${value.value}] ${value.message ?? ''}`)
      .join(', ');
    return text;
  });

  useEffect(() => {
    if (invalidValueList !== '') {
      logConsole.log('invalidValueList', invalidValueList);
    }
  }, [invalidValueList]);
};

export default useInvalidValueList;
