/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import React, { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReduxControl from '../../ReduxControl/ReduxControl';
import UnsupportedControl from '../../../components/UnsupportedControl/UnsupportedControl';
import { StructureItemStateChangeCallback } from '../../ReduxControlProps';
import { useMenuItemStateUpdate } from '../../hooks/useMenuItemStateUpdate';
import { compareTabData, menuItemsSelector } from '../../selectors/menuItemsSelector';
import { useTypedSelector } from '../../../store';
import { DatasetContext } from '../../../views/DatasetContext';
import './HorMenuLayoutControl.scss';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';

export interface HorMenuLayoutProps {
  onStateChanged: StructureItemStateChangeCallback;
  structureDescriptor: DeviceModelStatus.DeviceModelDescriptor;
  readonly?: boolean;
  rootLayout?: boolean;
  deviceInstanceId: string;
}

// eslint-disable-next-line max-len
export const HorMenuLayoutControl: React.FC<HorMenuLayoutProps> = (props: HorMenuLayoutProps): React.ReactElement => {
  const {
    structureDescriptor,
    onStateChanged,
    readonly,
    rootLayout,
    deviceInstanceId,
  } = props;

  const [modifiedState, errorState, updateMenuItemState] = useMenuItemStateUpdate();
  const { identRef } = structureDescriptor;
  const { targetDataset } = useContext(DatasetContext);

  const menuItems = useTypedSelector(
    (state) => menuItemsSelector(deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset), identRef),
    (p, c) => compareTabData(p, c),
  );

  // When control removed, the modified and error state is clean
  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, {
      error: errorState,
      modified: modifiedState,
    });
  }, [modifiedState, errorState, identRef]);

  if (structureDescriptor.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return <UnsupportedControl text="HorMenuLayoutControl: Supported type is StructureDescriptor only" />;
  }

  const columns = menuItems
    .map((element) => {
      const key = `${structureDescriptor.identRef}--${element.identRef}`;
      const colw = element.width === undefined ? 'col' : `col-${element.width}`;
      return (
        <Col key={`hm-${key}`} className={`${colw} d-flex flex-column justify-content-center align-items-center h-100 px-2 pb-1`}>
          <ReduxControl
            identRef={element.identRef}
            deviceInstanceId={deviceInstanceId}
            onStateChanged={(ident, state) => updateMenuItemState(ident, state)}
            readonly={readonly}
          />
        </Col>
      );
    });

  const className = rootLayout ? 'hmenu-layout-container--overflow-auto' : '';
  return (
    <Container fluid className={`hmenu-layout-container ${className} h-100 p-0`}>
      <Row className="m-0 p-0 flex-row justify-content-center align-items-start h-100">
        {columns}
      </Row>
    </Container>
  );
};
