/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import {
  DocumentType, DocumentMimeType, Services, DeviceModelStatus,
} from '@gpt/commons';
import {
  Middleware, MiddlewareAPI, Dispatch,
} from 'redux';
import { v4 as uuidv4 } from 'uuid';
import {
  ReportServiceActionTypes,
  REPORT_SERVICE__CREATE_REPORT,
  ReportStatus,
  CreateReportActionPayload,
} from '../types';
import { typeCreateMiddlewareService } from '../../common';
import { IReportMiddlewareService } from './reportMiddlewareService';
import { setReportStatus } from '../actions';
import AppFileSystemService from '../../../services/AppService/AppFileSystemService';
import { showApplicationMessage } from '../../applicationMessage/actions';
import { dataURLtoBlob } from '../../../helpers/functions';
import bundledResources from '../../../assets/i18n';
import { deviceTargetDatasetSelector } from '../../deviceInstances/store/dataStorage/selectors';
import { deviceInstancesStoreSelector } from '../../reduxStoreSelector';
import { roundValue } from '../../../helpers/precisionValue';
import { createReportDataset } from './reportDatasetHelper';

let service: IReportMiddlewareService | undefined;

const updatePrecisionValue = (
  datasetDescriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
  datasetValues: DeviceModelStatus.StatusValueCollection,
): DeviceModelStatus.StatusValueCollection => Object
  .keys(datasetValues)
  .reduce((acc, key) => {
    const descriptor = datasetDescriptors[key];
    if (descriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return acc;
    }
    const { valueType } = descriptor;
    if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
      return acc;
    }

    const { precision } = valueType;
    if (precision === undefined) {
      return acc;
    }

    return {
      ...acc,
      [key]: {
        ...acc[key],
        value: roundValue(acc[key].value, precision),
      },
    };
  }, datasetValues);

export const reportMiddleware = (
  createMiddlewareService: typeCreateMiddlewareService<IReportMiddlewareService>,
): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends ReportServiceActionTypes>(action: A): Promise<A> => {
  if (service === undefined) {
    service = createMiddlewareService(api);
  }

  const actionCreateReportContents = async (deviceInstanceId: string, report: CreateReportActionPayload): Promise<ArrayBuffer> => {
    const {
      activeDataset, compareDataset,
      language, documentType, reportData, reportMenu, dictFamily,
    } = report;

    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const datasetState = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, activeDataset);
    const compareDatasetState = deviceInstances.instances[deviceInstanceId]?.deviceDataset[compareDataset];

    const datasetDescriptors = datasetState?.descriptors ?? {};
    const datasetValues = datasetState?.values ?? {};

    const dataset: DeviceModelStatus.DeviceModelStatus = {
      descriptor: createReportDataset(reportMenu ?? '-', datasetDescriptors, datasetValues),
      statusVector: {
        changeCounter: 0,
        statusValue: updatePrecisionValue(datasetDescriptors, datasetValues),
      },
    };

    const compareDatasets: DeviceModelStatus.DeviceModelStatus = {
      descriptor: createReportDataset(reportMenu ?? '-', compareDatasetState.descriptors, compareDatasetState.values),
      statusVector: {
        changeCounter: 0,
        statusValue: updatePrecisionValue(compareDatasetState.descriptors, compareDatasetState.values),
      },
    };

    const bundle = bundledResources[language] ?? bundledResources.en;
    const deviceTranslation = bundle[dictFamily] ?? bundledResources.en[dictFamily];

    const request: Services.DeviceModelServer.ReportServiceRequest = {
      requestId: `report-${uuidv4()}`,
      deviceInstanceId,
      documentType,
      language,
      reportData,
      reportMenu,
      dataset,
      compareDataset: compareDatasets,
      deviceTranslation,
    };

    let response: Services.DeviceModelServer.ReportServiceResponse | undefined;
    try {
      response = await service?.generateReport(request);
    } catch {
      response = undefined;
    }

    if (!response || response.error) {
      throw new Error(`${response?.error ?? 'ReportService.createReport failed'}`);
    }

    const blob = dataURLtoBlob(response.documentDataUrl);
    const arrayBuffer = await blob.arrayBuffer();
    return arrayBuffer;
  };

  const actionCreateReport = async (deviceInstanceId: string, report: CreateReportActionPayload) => {
    api.dispatch(setReportStatus(ReportStatus.INPROGRESS));

    try {
      const asyncReportContents: Promise<ArrayBuffer> = actionCreateReportContents(deviceInstanceId, report);

      let mimeType = 'application/octet-string';
      let extensions: string[] = [];
      if (report.documentType === DocumentType.DOCX) {
        mimeType = DocumentMimeType.DOCX;
        extensions = ['.docx'];
      } else if (report.documentType === DocumentType.PDF) {
        mimeType = DocumentMimeType.PDF;
        extensions = ['.pdf'];
      }

      await AppFileSystemService.ExportLocalFile({
        contents: asyncReportContents,
        suggestedName: report.reportFilename,
        open: report.documentType === DocumentType.PDF,
        mimeType,
        extensions,
      });

      api.dispatch(setReportStatus(ReportStatus.SUCCESS));
      api.dispatch(showApplicationMessage('success', 'SAVE_REPORT_FILE__SUCCESS'));
    } catch (err) {
      const error = err as Error;
      if (error?.name === 'AbortError' || `${error}`.includes('user aborted')) {
        api.dispatch(setReportStatus(ReportStatus.FAILED));
        api.dispatch(showApplicationMessage('warning', 'SAVE_REPORT_FILE__ERROR__CANCELLED'));
      } else {
        api.dispatch(setReportStatus(ReportStatus.FAILED));
        api.dispatch(showApplicationMessage('error', 'SAVE_REPORT_FILE__FAILED'));
      }
    }
  };

  switch (action.type) {
    case REPORT_SERVICE__CREATE_REPORT: {
      const { report, targetInstance } = action.payload;
      await actionCreateReport(targetInstance, report);
      break;
    }

    default:
      break;
  }

  const result = next(action);
  return result;
};

export default reportMiddleware;
