/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Action } from 'redux';
import { DeviceInstanceInformation } from '@gpt/commons';
import { DeviceInstanceAction, DeviceInstanceActionPayload } from '../../common';

export const STATION_DISCOVERY__INITIALIZE = 'STATION_DISCOVERY__INITIALIZE';
export const STATION_DISCOVERY__ADD_DISCOVERY_INFORMATION = 'STATION_DISCOVERY__ADD_DISCOVERY_INFORMATION';
export const STATION_DISCOVERY__REMOVE_DISCOVERY_INFORMATION = 'STATION_DISCOVERY__REMOVE_DISCOVERY_INFORMATION';

export const DEVICE_INSTANCE__STATION_DISCOVERY__ACTIONS = 'DEVICE_INSTANCE__STATION_DISCOVERY__ACTIONS';

export enum StationItemState {
  // eslint-disable-next-line no-unused-vars
  Initialized = 'initialized',
  // eslint-disable-next-line no-unused-vars
  InProgress = 'in-progress',
  // eslint-disable-next-line no-unused-vars
  Instantiated = 'done',
  // eslint-disable-next-line no-unused-vars
  Failed = 'failed',
}

export interface StationDiscoveryItem {
  /** Unique ID */
  id: string;
  /** Title (Human readable) */
  title: string;
  /** Title (Human readable) */
  subtitle: string;
  /** Instance ID (for communication and updates) */
  instanceId: string;
  /** Instance state */
  state: StationItemState;
  /** Instance ID (for communication and updates) */
  deviceTypeIdentifier: string;
  /** Instance ID (for communication and updates) */
  deviceUniqueIdentifier: string;
  /** Connection string for slave devices */
  connectionString?: string;
  /** Device ionstance information for slave device */
  instance?: DeviceInstanceInformation
}

export interface StationState {
  title: string;
  subtitle: string;
  instanceId: string;
  discovery: StationDiscoveryItem[];
}

export interface StationInitPayload {
  /** Title (Human readable) */
  title: string;
  /** Title (Human readable) */
  subtitle: string;
  /** Instance ID (for communication and updates) */
  instanceId: string;
}

export type InitializeStationAction = DeviceInstanceAction<typeof STATION_DISCOVERY__INITIALIZE, StationInitPayload>

export type StationDiscoveryPayload = StationDiscoveryItem[];

export type AddStationDiscoveryAction = DeviceInstanceAction<typeof STATION_DISCOVERY__ADD_DISCOVERY_INFORMATION, StationDiscoveryPayload>

export type RemoveStationDiscoveryAction = DeviceInstanceAction<typeof STATION_DISCOVERY__REMOVE_DISCOVERY_INFORMATION, string[]>

export type typeStationAction = AddStationDiscoveryAction | RemoveStationDiscoveryAction | InitializeStationAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceDataStorageAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceStationPayload extends DeviceInstanceActionPayload {
  action: typeStationAction;
}

export interface typeDeviceInstanceStationAction extends Action<typeof DEVICE_INSTANCE__STATION_DISCOVERY__ACTIONS> {
  payload: typeDeviceInstanceStationPayload;
}
