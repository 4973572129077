/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IdentRef } from '@gpt/commons';
import { ListGroup } from 'react-bootstrap';
import { ExecutionState, useTypedSelector } from '../../store';
import { LoadingControl } from '../../components/LoadingControl/LoadingControl';
import PreviewValueGroupControl from '../../components/PreviewComponent/PreviewValueGroupControl/PreviewValueGroupControl';
import { DatasetContext } from '../../views/DatasetContext';
import { menuItemsSelector } from '../selectors/menuItemsSelector';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import './PreviewDeviceDatasetReduxControl.css';

export interface PreviewDeviceDatasetReduxControlProps {
  caption: string;
  identRef: IdentRef;
  deviceInstanceId: string;
}

const PreviewDeviceDatasetReduxControl: React.FC<PreviewDeviceDatasetReduxControlProps> = (props: PreviewDeviceDatasetReduxControlProps)
: React.ReactElement => {
  const { identRef, caption, deviceInstanceId } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const deviceDatasetValues = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)?.values);
  const deviceDatasetDescriptors = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)?.descriptors);
  const previewDatasetState = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)?.state);

  const elementDescriptors = useTypedSelector(
    (state) => {
      const items = menuItemsSelector(deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset), identRef);
      return items.map((item) => item.identRef);
    },
  );

  if (deviceDatasetValues === undefined || deviceDatasetDescriptors === undefined) {
    return previewDatasetState === ExecutionState.pending
      ? <LoadingControl title={t<string>('LOADING_PREVIEW_DATA')} />
      : <div />;
  }

  const componentGroups = elementDescriptors.map((element) => (
    <ListGroup.Item key={`rxlistitem-${identRef}-${element}`}>
      <PreviewValueGroupControl deviceInstanceId={deviceInstanceId} identRef={element} />
    </ListGroup.Item>
  ));

  return (
    <div className="preview-device-dataset-redux-control h-100">
      <div className="preview-device-dataset-redux-control__caption w-100">
        {caption}
      </div>
      <div className="preview-device-dataset-redux-control__list h-100">
        <ListGroup className="h-100" variant="flush">
          {componentGroups}
        </ListGroup>
      </div>
    </div>
  );
};

export default PreviewDeviceDatasetReduxControl;
