/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './LedControl.css';

export interface LedControlProps {
  title: string
  color: string;
  blink?: string; // 1-4Hz, 2-8Hz
}

// eslint-disable-next-line max-len
export const LedControl: React.FC<LedControlProps> = (props: LedControlProps): React.ReactElement => {
  const {
    color, title, blink,
  } = props;

  const classes = `led led-${color}`;
  const ledclasses = blink === undefined ? classes : `${classes} led-blink-${blink}`;

  return (
    <div className="led-root-container d-flex flex-row align-items-center w-100 h-100" data-testid="led-control">
      <p className="led-title">{title}</p>
      <div className="led-container d-flex flex-row align-items-center">
        <div className={ledclasses} />
      </div>
    </div>
  );
};
