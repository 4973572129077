/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { DeviceModelStatus, Services } from '@gpt/commons';
import { DeviceInstanceActionPayload } from '../../common';
import { DatasetType } from '../../store/deviceDataset/types';

export const EXECUTE_DEVICE_UPLOAD_METHOD = 'EXECUTE_DEVICE_UPLOAD_METHOD';
export const EXECUTE_DEVICE_DOWNLOAD_METHOD = 'EXECUTE_DEVICE_DOWNLOAD_METHOD';
export const EXECUTE_DEVICE_DOWNLOAD_DATASET_METHOD = 'EXECUTE_DEVICE_DOWNLOAD_DATASET_METHOD';

export const ACTIONID__DOWNLOAD_DATA_TO_DEVICE = 'ACTION__DOWNLOAD_DATA_TO_DEVICE';
export const ACTIONID__UPLOAD_DATA_FROM_DEVICE = 'ACTIONID__UPLOAD_DATA_FROM_DEVICE';

export interface ExecuteDeviceUploadMethodPayload extends DeviceInstanceActionPayload {
  actionId: string;
  methodIdent: DeviceModelStatus.MethodIdent;
}

export interface ExecuteDeviceUploadMethodAction extends Action<typeof EXECUTE_DEVICE_UPLOAD_METHOD> {
  payload: ExecuteDeviceUploadMethodPayload;
}

export interface ExecuteDeviceDownloadMethodPayload extends DeviceInstanceActionPayload {
  methodIdent: DeviceModelStatus.MethodIdent;
  values: Services.DeviceModel.StatusValueRef[];
}

export interface ExecuteDeviceDownloadMethodAction extends Action<typeof EXECUTE_DEVICE_DOWNLOAD_METHOD> {
  payload: ExecuteDeviceDownloadMethodPayload;
}

export interface ExecuteDeviceDownloadDatasetMethodPayload extends DeviceInstanceActionPayload {
  actionId: string;
  methodIdent: DeviceModelStatus.MethodIdent;
  sourceDataset: DatasetType;
}

export interface ExecuteDeviceDownloadDatasetMethodAction extends Action<typeof EXECUTE_DEVICE_DOWNLOAD_DATASET_METHOD> {
  payload: ExecuteDeviceDownloadDatasetMethodPayload;
}

export type typeDeviceMethodMiddlewareActions = ExecuteDeviceUploadMethodAction
| ExecuteDeviceDownloadMethodAction
| ExecuteDeviceDownloadDatasetMethodAction;
