/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../store';
import { GaugeControlState, setGaugeIndicator } from '../../../../store/deviceInstances/store/controlStateCollection';
import { deviceControlStateCollectionSelector } from '../../../../store/deviceInstances/store/controlStateCollection/selectors';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import { controlLimitSelector, calculateChartSegment, calculateChartMarkPoints } from '../selectors';
import PowerBarControl from '../../../Charts/PowerBarControl/PowerBarControl';
import useDragIndicatorStatusValue from '../hooks/useDragIndicatorStatusValue';

export interface SystemDataPowerChartProps {
  deviceInstanceId: string;
  power: DeviceModelStatus.UI.Contactron.MotorStarterPower;
}

export const SystemDataPowerChart: React.FC<SystemDataPowerChartProps> = (props: SystemDataPowerChartProps):
  React.ReactElement => {
  const {
    deviceInstanceId,
    power,
  } = props;

  const dispatch = useDispatch();
  const powerDragPropertyId = `power-chart--${power.variable}`;
  const powerShowDragIndicator = useTypedSelector((state) => {
    const control = deviceControlStateCollectionSelector(state.deviceInstances, deviceInstanceId)?.[powerDragPropertyId];
    return control === undefined ? false : (control as GaugeControlState).showIndicator;
  });

  const powerChartLimits = useTypedSelector((state) => controlLimitSelector(
    state.deviceInstances,
    deviceInstanceId,
    DatasetType.device,
    power.variable,
    power.limits,
  ));
  const powerChartSegments = calculateChartSegment(powerChartLimits);
  const powerChartMarkPoints = calculateChartMarkPoints(powerChartSegments, '%.2f');

  const [powerValue, powerMinValue, powerMaxValue, resetDragValue] = useDragIndicatorStatusValue(deviceInstanceId, power.variable);

  const { t } = useTranslation();
  return (
    <PowerBarControl
      label={t(power.label)}
      value={powerValue}
      unit="%"
      chartMarks={powerChartMarkPoints}
      chartSegments={powerChartSegments}
      displayFormat="%.2f"
      showDragIndicator={powerShowDragIndicator}
      onShowDragIndicator={(value) => {
        dispatch(setGaugeIndicator(deviceInstanceId, {
          ident: powerDragPropertyId,
          indicator: value,
        }));
      }}
      onResetDragValues={() => resetDragValue(powerValue)}
      dragMaxValue={powerMaxValue}
      dragMinValue={powerMinValue}
    />
  );
};
