/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import isElectron from 'is-electron';
import * as cxEdp from '@gpt/cxe-dp-integration';
// eslint-disable-next-line import/no-cycle
import { exportLocalFile, importLocalFile } from '../clipxEngineerIntegrationService';

const Console = console;

interface AppFileSystemService {
  ExportLocalFile: (options: cxEdp.ExportLocalFileOptions) => Promise<cxEdp.ExportLocalFileResult>;
  ImportLocalFile: (options: cxEdp.ImportLocalFileOptions) => Promise<cxEdp.ImportLocalFileResult>;
}

const browserFileSystemService: AppFileSystemService = {
  ExportLocalFile: exportLocalFile,
  ImportLocalFile: importLocalFile,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const electronService = (window as any).electronService as AppFileSystemService;

const electronFileSystemService = {
  ...electronService,

  // wrap Electron service function "ExportLocalFile" to ensure that "contents" are
  // only passed by value (string|ArrayBuffer) but not as (not cloneable) Promise:
  ExportLocalFile: async (options: cxEdp.ExportLocalFileOptions):
  Promise<cxEdp.ExportLocalFileResult> => {
    let options2 = options;
    if (options.contents instanceof Promise) {
      // Service handler electronService.ExportLocalFile can be called twice,
      // this enables to show the file-save-dialog while content is generated
      // and afterwards write the file, even without having to pass a Promise.
      const omitted = null as unknown as string;
      const dialog = await electronService.ExportLocalFile({ ...options, contents: omitted });
      const contents = await options.contents;
      options2 = { ...options, ...dialog, contents };
    }
    Console.debug('ExportLocalFile →', options2);
    const result = await electronService.ExportLocalFile(options2);
    Console.debug('ExportLocalFile ←', result);
    return result;
  },

  // wrap Electron service function "ImportLocalFile" to add console debug log:
  ImportLocalFile: async (options: cxEdp.ImportLocalFileOptions):
  Promise<cxEdp.ImportLocalFileResult> => {
    Console.debug('ImportLocalFile →', options);
    const result = electronService.ImportLocalFile(options);
    Console.debug('ImportLocalFile ←', result);
    return result;
  },
};

const appFileSystemService = isElectron()
  ? electronFileSystemService : browserFileSystemService;

export default appFileSystemService;
