/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { CTAButton } from '../../../../components/CTAButton/CTAButton';
import AppSystemServiceImpl from '../../../../services/AppService/AppSystemService';

const InstallDriverButton: React.FC = () : React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <CTAButton
        caption={t('STANDARD_BUTTON__INSTALL_DRIVER')}
        variant="secondary-small"
        onClick={() => {
          AppSystemServiceImpl.ExecuteDriverSetup();
        }}
        block
      />
    </>
  );
};

export default InstallDriverButton;
