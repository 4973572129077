/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect, useRef, useState } from 'react';
import Image from 'react-bootstrap/Image';
import arrowIcon from '../../assets/icons/icon-arrow-indicator-left.svg';
import expandIcon from '../../assets/icons/icon-expand.svg';
import DisplayFormat from '../../helpers/displayFormat';
import { DragIndicatorControl } from '../DragIndicatorControl/DragIndicatorControl';
import OverlayPortal from '../OverlayPortal/OverlayPortal';
import './BargraphControlVerticalFigma.css';

export interface BargraphControlVerticalFigmaProps {
  bargraphTitle: string;
  subtitle: string;
  centerUnit: string;
  currentValue: number;
  segmentStops: number[];
  showDragIndicator: boolean;
  displayFormat: string;
  segmentDisplayFormat: string;
  // eslint-disable-next-line no-unused-vars
  setShowDragIndicator: (value: boolean) => void;
}

interface BarRange {
  start: number;
  end: number;
}

export const BargraphControlVerticalFigma: React.FC<BargraphControlVerticalFigmaProps> = (props: BargraphControlVerticalFigmaProps): React.ReactElement => {
  const MAX_CONTROL_WIDTH = 350;
  const MAX_BAR_HEIGHT = 240;
  const {
    bargraphTitle, subtitle, centerUnit, currentValue, segmentStops,
    showDragIndicator, displayFormat, segmentDisplayFormat, setShowDragIndicator,
  } = props;
  const [dragIndicatorValue, setDragIndicatorValue] = useState({ minValue: Number.POSITIVE_INFINITY, maxValue: Number.NEGATIVE_INFINITY });
  const [controlWidth, setControlWidth] = useState(0);
  const [showDragIndicatorMenu, setShowDragIndicatorMenu] = useState(false);
  const controlRef = useRef<HTMLDivElement>(null);
  const showIndicatorButtonRef = useRef<HTMLDivElement>(null);
  const segmentsNumber = segmentStops.length - 1;
  const barRange: BarRange = {
    start: segmentStops[0],
    end: segmentStops[segmentsNumber],
  };

  if (currentValue < dragIndicatorValue.minValue) {
    setDragIndicatorValue((previousState) => ({
      minValue: currentValue,
      maxValue: previousState.maxValue,
    }));
  }

  if (currentValue > dragIndicatorValue.maxValue) {
    setDragIndicatorValue((previousState) => ({
      minValue: previousState.minValue,
      maxValue: currentValue,
    }));
  }

  const fitToSize = (baseFontSize: number): number => (baseFontSize / MAX_CONTROL_WIDTH) * controlWidth;

  const scaleToRange = (value: number, range: BarRange): number => {
    if (value < range.start) {
      return range.start;
    }

    if (value > range.end) {
      return range.end;
    }

    return value;
  };

  const Laufweite = segmentStops[segmentsNumber] - segmentStops[0];
  const segments: number[] = [];
  for (let i = 0; i < segmentsNumber; i += 1) {
    const segment = (MAX_BAR_HEIGHT / Laufweite) * (segmentStops[i + 1] - segmentStops[i]);
    segments.push(segment);
  }

  const segment1 = segments[0] ?? 0;
  const segment2 = segments[1] ?? 0;
  const segment3 = segments[2] ?? 0;
  const segment4 = segments[3] ?? 0;
  const segment5 = segments[4] ?? 0;

  const segmentSpacerUnits = `${fitToSize(10)}px`;
  const segmentLowLow = `${fitToSize(segment1)}px`;
  const segmentLow = `${fitToSize(segment2)}px`;
  const segmentNormal = `${fitToSize(segment3)}px`;
  const segmentHigh = `${fitToSize(segment4)}px`;
  const segmentHighHigh = `${fitToSize(segment5)}px`;
  const scalaHeight = `${fitToSize((240 / Laufweite) * Laufweite)}px`;
  const firstTickHeight = `${fitToSize(segment1 - 1)}px`;
  const segmentWidth = `${fitToSize(24)}px`;

  const segment1HideState = segment1 === 0 ? 'hideBargraphSegment' : '';
  const segment2HideState = segment2 === 0 ? 'hideBargraphSegment' : '';
  const segment3HideState = segment3 === 0 ? 'hideBargraphSegment' : '';
  const segment4HideState = segment4 === 0 ? 'hideBargraphSegment' : '';
  const segment5HideState = segment5 === 0 ? 'hideBargraphSegment' : '';

  const segment1Classes = `FigmaBargraphControlVerticalChart-Bargraph-SegmentContainer-item ${segment1HideState} FigmaBargraphControlVerticalChart-Segment-LowLow`;
  const segment2Classes = `FigmaBargraphControlVerticalChart-Bargraph-SegmentContainer-item ${segment2HideState} FigmaBargraphControlVerticalChart-Segment-Low`;
  const segment3Classes = `FigmaBargraphControlVerticalChart-Bargraph-SegmentContainer-item ${segment3HideState} FigmaBargraphControlVerticalChart-Segment-Normal`;
  const segment4Classes = `FigmaBargraphControlVerticalChart-Bargraph-SegmentContainer-item ${segment4HideState} FigmaBargraphControlVerticalChart-Segment-High`;
  const segment5Classes = `FigmaBargraphControlVerticalChart-Bargraph-SegmentContainer-item ${segment5HideState} FigmaBargraphControlVerticalChart-Segment-HighHigh`;

  const segmentLabel1Classes = `FigmaBargraphControlVerticalChart-Bargraph__LabelContainer-label ${segment1HideState}`;
  const segmentLabel2Classes = `FigmaBargraphControlVerticalChart-Bargraph__LabelContainer-label ${segment2HideState}`;
  const segmentLabel3Classes = `FigmaBargraphControlVerticalChart-Bargraph__LabelContainer-label ${segment3HideState}`;
  const segmentLabel4Classes = `FigmaBargraphControlVerticalChart-Bargraph__LabelContainer-label ${segment4HideState}`;
  const segmentLabel5Classes = `FigmaBargraphControlVerticalChart-Bargraph__LabelContainer-label ${segment5HideState}`;

  const tick1Classes = `FigmaBargraphControlVertical-Bargraph__TickContainer-Tick ${segment1HideState}`;
  const tick2Classes = `FigmaBargraphControlVertical-Bargraph__TickContainer-Tick ${segment2HideState}`;
  const tick3Classes = `FigmaBargraphControlVertical-Bargraph__TickContainer-Tick ${segment3HideState}`;
  const tick4Classes = `FigmaBargraphControlVertical-Bargraph__TickContainer-Tick ${segment4HideState}`;
  const tick5Classes = `FigmaBargraphControlVertical-Bargraph__TickContainer-Tick ${segment5HideState}`;

  const currentValueY = 0 - ((((MAX_BAR_HEIGHT - 2) / Laufweite) * (scaleToRange(currentValue, barRange) - segmentStops[0])) - 12);
  const minValueY = 0 - (((MAX_BAR_HEIGHT - 2) / Laufweite) * scaleToRange(dragIndicatorValue.minValue, barRange));
  const maxValueY = 0 - (((MAX_BAR_HEIGHT - 2) / Laufweite) * scaleToRange(dragIndicatorValue.maxValue, barRange));
  const NeedleStyles = {
    width: fitToSize(16),
    height: fitToSize(24),
    transform: `translate(0px, ${fitToSize(currentValueY)}px)`,
  };
  const MinIndicatorStyles = {
    transform: `translate(0px, ${fitToSize(minValueY)}px)`,
  };
  const MaxIndicatorStyles = {
    transform: `translate(0px, ${fitToSize(maxValueY)}px)`,
  };
  const titleStyles = {
    fontSize: `${fitToSize(24)}px`,
    lineHeight: `${fitToSize(32)}px`,
  };
  const subtitleStyles = {
    fontSize: `${fitToSize(16)}px`,
    lineHeight: `${fitToSize(24)}px`,
  };
  const titleContainerStyle = {
    marginBottom: `${fitToSize(8)}px`,
  };
  const buttonStyle = {
    width: `${fitToSize(32)}px`,
    height: `${fitToSize(32)}px`,
  };
  const currentValueContainerStyles = {
    marginLeft: `${fitToSize(40)}px`,
  };
  const currentValueStyle = {
    fontSize: `${fitToSize(32)}px`,
    lineHeight: `${fitToSize(24)}px`,
  };
  const currentUnitStyle = {
    fontSize: `${fitToSize(16)}px`,
    lineHeight: `${fitToSize(24)}px`,
    marginLeft: `${fitToSize(8)}px`,
    transform: `translate(0px, ${fitToSize(3)}px)`,
  };
  const extremeContainerStyle = {
    marginTop: `${fitToSize(4)}px`,
  };
  const extremeValueStyle = {
    fontSize: `${fitToSize(16)}px`,
    lineHeight: `${fitToSize(24)}px`,
  };
  const lineStyle = {
    width: `${fitToSize(2.5)}px`,
    marginRight: `${fitToSize(16)}px`,
    height: scalaHeight,
  };
  const extremeIndicatorStyle = {
    width: `${fitToSize(32)}px`,
    height: `${fitToSize(4)}px`,
    borderRadius: `${fitToSize(3)}px`,
    transform: `translateY(-${fitToSize(2)}px)`,
  };
  const tickStyle = {
    width: `${fitToSize(8)}px`,
    height: `${fitToSize(0.771084)}px`,
    marginLeft: `${fitToSize(5)}px`,
  };

  const minValue = Number.isFinite(dragIndicatorValue.minValue) ? DisplayFormat(displayFormat, dragIndicatorValue.minValue) : 0;
  const maxValue = Number.isFinite(dragIndicatorValue.maxValue) ? DisplayFormat(displayFormat, dragIndicatorValue.maxValue) : 0;
  const minValueText = `Min: ${minValue} ${centerUnit}`;
  const maxValueText = `Max: ${maxValue} ${centerUnit}`;
  const currentValueText = DisplayFormat(displayFormat, currentValue);

  const dragIndicatorBtnTopPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.top;
  const dragIndicatorBtnBottomPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.bottom;
  const dragIndicatorBtnLeftPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.left;
  const dragIndicatorBtnRightPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.right;

  const dragIndicatorMenuXPosition = dragIndicatorBtnLeftPosition === undefined || dragIndicatorBtnRightPosition === undefined
    ? undefined : (dragIndicatorBtnRightPosition + dragIndicatorBtnLeftPosition) / 2;
  const dragIndicatorMenuYPosition = dragIndicatorBtnTopPosition === undefined || dragIndicatorBtnBottomPosition === undefined
    ? undefined : (dragIndicatorBtnTopPosition + dragIndicatorBtnBottomPosition) / 2;

  useEffect(() => {
    const element = controlRef.current;
    const observer = new ResizeObserver(() => {
      if (element) {
        const currentWidth = element.clientWidth;
        setControlWidth(currentWidth);
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [controlRef]);

  return (
    <div ref={controlRef} className="FigmaBargraphControlVerticalChart-Bargraph">
      <div style={titleContainerStyle} className="FigmaBargraphControlVerticalChart-Bargraph__TitleContainer">
        <div>
          <div style={titleStyles} className="FigmaBargraphControlVerticalChart-Bargraph__title">{bargraphTitle}</div>
          <div style={subtitleStyles} className="FigmaBargraphControlVerticalChart-Bargraph__subtitle">{subtitle}</div>
        </div>
        <div
          ref={showIndicatorButtonRef}
          className="FigmaBargraphControlVerticalChart-Bargraph__image"
          role="button"
          tabIndex={0}
          onClick={() => {
            setShowDragIndicatorMenu(!showDragIndicatorMenu);
          }}
          onKeyUp={() => {}}
        >
          <Image style={buttonStyle} src={expandIcon} className="DragIndicator-button-icon" />
        </div>
        {showDragIndicatorMenu && (
          <OverlayPortal>
            <div
              role="none"
              className="FigmaBargraphControlVerticalChart-Bargraph__overlay"
              onClick={() => setShowDragIndicatorMenu(false)}
            >
              <div
                style={{
                  position: 'absolute',
                  top: dragIndicatorMenuYPosition,
                  left: dragIndicatorMenuXPosition,
                }}
              >
                <DragIndicatorControl
                  showIndicator={showDragIndicator}
                  onDragIndicatorChange={() => {
                    setShowDragIndicator(!showDragIndicator);
                  }}
                  onRefresh={() => {
                    setDragIndicatorValue({
                      minValue: currentValue,
                      maxValue: currentValue,
                    });
                  }}
                />
              </div>
            </div>
          </OverlayPortal>
        )}
      </div>
      <div className="FigmaBargraphControlVerticalChart-Bargraph__ControlContainer">
        <div className="FigmaBargraphControlVerticalChart-Bargraph__BarContainer">
          <div className="FigmaBargraphControlVerticalChart-Bargraph-LabelContainer">
            <div style={{ height: segmentHighHigh, fontSize: `${fitToSize(14)}px`, lineHeight: `${fitToSize(18)}px` }} className={segmentLabel5Classes}>
              {DisplayFormat(segmentDisplayFormat, segmentStops[5])}
            </div>
            <div style={{ height: segmentHigh, fontSize: `${fitToSize(14)}px`, lineHeight: `${fitToSize(18)}px` }} className={segmentLabel4Classes}>
              {DisplayFormat(segmentDisplayFormat, segmentStops[4])}
            </div>
            <div style={{ height: segmentNormal, fontSize: `${fitToSize(14)}px`, lineHeight: `${fitToSize(18)}px` }} className={segmentLabel3Classes}>
              {DisplayFormat(segmentDisplayFormat, segmentStops[3])}
            </div>
            <div style={{ height: segmentLow, fontSize: `${fitToSize(14)}px`, lineHeight: `${fitToSize(18)}px` }} className={segmentLabel2Classes}>
              {DisplayFormat(segmentDisplayFormat, segmentStops[2])}
            </div>
            <div style={{ height: segmentLowLow, fontSize: `${fitToSize(14)}px`, lineHeight: `${fitToSize(18)}px` }} className={segmentLabel1Classes}>
              {DisplayFormat(segmentDisplayFormat, segmentStops[1])}
            </div>
            <div style={{ height: segmentSpacerUnits, fontSize: `${fitToSize(14)}px`, lineHeight: `${fitToSize(18)}px` }} className="FigmaBargraphControlVerticalChart-Bargraph__LabelContainer-label">
              {DisplayFormat(segmentDisplayFormat, segmentStops[0])}
            </div>
          </div>
          <div className="FigmaBargraphControlVerticalChart-Bargraph__TickContainer">
            <div style={{ height: segmentHighHigh }}>
              <div style={tickStyle} className={tick1Classes} />
            </div>
            <div style={{ height: segmentHigh }}>
              <div style={tickStyle} className={tick2Classes} />
            </div>
            <div style={{ height: segmentNormal }}>
              <div style={tickStyle} className={tick3Classes} />
            </div>
            <div style={{ height: segmentLow }}>
              <div style={tickStyle} className={tick4Classes} />
            </div>
            <div style={{ height: firstTickHeight }}>
              <div style={tickStyle} className={tick5Classes} />
            </div>
            <div>
              <div style={tickStyle} className="FigmaBargraphControlVertical-Bargraph__TickContainer-Tick" />
            </div>
          </div>
          <div className="FigmaBargraphControlVertical-Bargraph__VerticalLineContainer">
            <div style={lineStyle} className="FigmaBargraphControlVertical-Bargraph__Line" />
          </div>
          <div className="FigmaBargraphControlVerticalChart-Bargraph__SegmentContainer">
            <div style={{ height: segmentHighHigh, width: segmentWidth }} className={segment5Classes} />
            <div style={{ height: segmentHigh, width: segmentWidth }} className={segment4Classes} />
            <div style={{ height: segmentNormal, width: segmentWidth }} className={segment3Classes} />
            <div style={{ height: segmentLow, width: segmentWidth }} className={segment2Classes} />
            <div style={{ height: segmentLowLow, width: segmentWidth }} className={segment1Classes} />
            {showDragIndicator && (
            <div>
              <div style={MinIndicatorStyles} className="FigmaBargraphControlVerticalChart-Bargraph-ExtremeIndicatorContainer">
                <div style={extremeIndicatorStyle} className="FigmaBargraphControlVerticalChart-Bargraph-SegmentContainer-extremeIndicator" />
              </div>
              <div style={MaxIndicatorStyles} className="FigmaBargraphControlVerticalChart-Bargraph-ExtremeIndicatorContainer">
                <div style={extremeIndicatorStyle} className="FigmaBargraphControlVerticalChart-Bargraph-SegmentContainer-extremeIndicator" />
              </div>
            </div>
            )}
          </div>
          <Image style={NeedleStyles} src={arrowIcon} />
        </div>
        <div style={currentValueContainerStyles} className="FigmaBargraphControlVerticalChart-Bargraph__ValuesContainer">
          <div className="FigmaBargraphControlVerticalChart-Bargraph__CurrentValueContainer">
            <div style={currentValueStyle} className="FigmaBargraphControlVerticalChart-Bargraph__CurrentValueContainer-valueText">{currentValueText}</div>
            <div style={currentUnitStyle} className="FigmaBargraphControlVerticalChart-Bargraph__CurrentValueContainer-unitText">{centerUnit}</div>
          </div>
          {showDragIndicator && (
          <div style={extremeContainerStyle} className="FigmaBargraphControlVerticalChart-Bargraph__ExtremesContainer">
            <div style={extremeValueStyle} className="FigmaBargraphControlVerticalChart-Bargraph__ExtremesContainer-text">{minValueText}</div>
            <div style={extremeValueStyle} className="FigmaBargraphControlVerticalChart-Bargraph__ExtremesContainer-text">{maxValueText}</div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};
