/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';

const useDelayedUserInput = (
  value: string | number,
  warning: string | undefined,
  onValueChange: (value: string) => void,
  delayTimeout?: number,
) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const [userInputValue, setUserInputValue] = useState<string | undefined>(undefined);
  const [blurValue, setBlurValue] = useState<string | number | undefined>(undefined);

  useEffect(() => {
    setBlurValue(undefined);
  }, [value]);

  useEffect(() => {
    if (userInputValue === undefined) {
      return () => 0;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
    const tmpValue = userInputValue;
    timeoutRef.current = setTimeout(() => {
      setBlurValue(tmpValue);
      onValueChange(tmpValue);
      setUserInputValue(undefined);
    }, delayTimeout ?? 1000);

    return () => {
      if (timeoutRef?.current) clearTimeout(timeoutRef.current);
    };
  }, [userInputValue, timeoutRef, delayTimeout]);

  const onBlurValue = useCallback((blvalue) => {
    if (blvalue !== undefined) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
      setBlurValue(blvalue);
      onValueChange(`${blvalue}`);
      setUserInputValue(undefined);
    }
  }, [timeoutRef, onValueChange]);

  const currentValue = userInputValue !== undefined
    ? userInputValue
    : blurValue;

  // Remove warning when user input is not undefined
  const currentWarning = userInputValue === undefined ? warning : undefined;
  return [currentValue, currentWarning, setUserInputValue, onBlurValue] as const;
};

export default useDelayedUserInput;
