/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { MessageType } from '../store/applicationMessage/types';

export const setColor = (messageType: MessageType): string => {
  let borderColorStyle = 'var(--green)';

  if (messageType === 'error') {
    borderColorStyle = 'var(--red)';
  } else if (messageType === 'warning') {
    borderColorStyle = 'var(--yellow)';
  }

  return borderColorStyle;
};

export const getTimeout = (type: MessageType): number => {
  let timeout = Math.max();
  if (type !== 'error') {
    timeout = type === 'success' ? 5000 : 10000;
  }

  return timeout;
};
