/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { CaparocChannelStateType } from '../CaparocConst';
import { useContextStatusValue } from '../../../store/deviceInstances/hooks/DeviceDatasetHooks';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';

const calculateChannelStatus = (
  chStatus: number,
  chErrorStatus: number,
  nomCurrent: number,
  actCurrent: number,
): CaparocChannelStateType => {
  let state = CaparocChannelStateType.OFF;

  if (chErrorStatus !== 0) {
    state = CaparocChannelStateType.ERROR;
  } else if (chStatus === 0) {
    state = CaparocChannelStateType.OFF;
  } else if (actCurrent >= 0.8 * nomCurrent) {
    state = CaparocChannelStateType.WARNING;
  } else {
    state = CaparocChannelStateType.ON;
  }

  return state;
};

interface CaparocChannelData {
  varChannelStatus: string;
  varChannelErrorStatus: string;
  varChannelActualCurrent: string;
  varChannelNominalCurrent: string;
}

export const channelVariables = (channelNo: number): CaparocChannelData => ({
  varChannelStatus: `var_V_CB_Channel${channelNo}_01_TN_Channel_Status`,
  varChannelErrorStatus: `var_V_CB_Channel${channelNo}_05_TN_Channel_ErrorStatus`,
  varChannelActualCurrent: `var_V_CB_Channel${channelNo}_04_TN_Channel_ActualCurrent`,
  varChannelNominalCurrent: `var_V_CB_Channel${channelNo}_02_TN_Channel_NominalCurrent`,
});

const useCaparocChannelState = (deviceInstanceId: string, channelNo: number): CaparocChannelStateType => {
  const varChannel = channelVariables(channelNo);
  const channelStatusValue = useContextStatusValue(deviceInstanceId, varChannel.varChannelStatus, DatasetType.device)?.value ?? 0;
  const channelErrorStatus = useContextStatusValue(deviceInstanceId, varChannel.varChannelErrorStatus, DatasetType.device)?.value ?? 0;
  const channelActualCurrent = useContextStatusValue(deviceInstanceId, varChannel.varChannelActualCurrent, DatasetType.device)?.value ?? 0;
  const channelNominalCurrent = useContextStatusValue(deviceInstanceId, varChannel.varChannelNominalCurrent, DatasetType.device)?.value ?? 0;

  return calculateChannelStatus(channelStatusValue, channelErrorStatus, channelNominalCurrent, channelActualCurrent);
};

export default useCaparocChannelState;
