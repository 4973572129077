/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { LoadingSpinner } from './LoadingSpinner';
import { LoadingSpinnerSize } from './LoadingSpinner/LoadingSpinner';

export interface LoadingControlProps {
  title: string;
  size?: LoadingSpinnerSize;
}

export const LoadingControl: React.FC<LoadingControlProps> = (props: LoadingControlProps):
  React.ReactElement => {
  const {
    title, size,
  } = props;

  return (
    <div className="d-flex justify-content-center align-items-center w-100" data-testid="loading-control">
      <LoadingSpinner size={size ?? 'xxl'} title={title} />
    </div>
  );
};
