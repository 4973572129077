/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, Services } from '@gpt/commons';
import { ExecutionState } from '../../../common';
import {
  typeDeviceTimelineDataActionTypes,
} from './types';

export const setTimelineDataState = (targetInstance: string, payload: ExecutionState)
  : typeDeviceTimelineDataActionTypes => ({
  type: 'DEVICE_INSTANCE__TIMELINE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_INSTANCE__IMELINE__SET_STATE',
      payload,
    },
  },
});

export const setTimelineData = (
  targetInstance: string,
  payload: Services.DeviceModelServer.DeviceTimelineData[],
): typeDeviceTimelineDataActionTypes => ({
  type: 'DEVICE_INSTANCE__TIMELINE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_INSTANCE__TIMELINE__SET_DATA',
      payload,
    },
  },
});

export const updateTimelineData = (targetInstance: string, payload: DeviceModelStatus.StatusValue[])
  : typeDeviceTimelineDataActionTypes => ({
  type: 'DEVICE_INSTANCE__TIMELINE__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'DEVICE_INSTANCE__TIMELINE__UPDATE_DATA',
      payload,
    },
  },
});
