/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { Services } from '@gpt/commons';
import { isAbortError } from '@gpt/cxe-dp-integration';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { MIME_TYPE__CXEDP, FILE_EXTENTION__CXEDP } from '../../../../helpers/constants';
import AppFileSystemService from '../../../../services/AppService/AppFileSystemService';
import { showApplicationMessage } from '../../../applicationMessage/actions';
import { deviceInstancesStoreSelector } from '../../../reduxStoreSelector';
import { DeviceDatasetValueCollection } from '../../store/deviceDataset';
import { writeActiveDeviceVariableValues } from '../activeDevice/actions';
import { typeDatasetProviderMiddlewareAction, WriteDatasetToExternalFileAction } from './types';
import { createDatasetIdent } from '../../../../helpers/functions';
import { setDatasetList } from '../../store/datasetProvider';
import { deviceInstanceActiveDeviceInstance } from '../../store/activeDevice/selectors';
import createDeviceDatasetBackupContent from '../../../../helpers/createDeviceDatasetBackupContent';
import { IWebWorkerDeviceManager } from '../../../../services/WebWorkerDevice/WebWorkerDeviceManager';

const DatasetProviderMiddleware = (webWorkerDeviceManager: IWebWorkerDeviceManager): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeDatasetProviderMiddlewareAction>(action: A): Promise<A> => {
  const actionWriteDatasetToExternalFile = async (deviceInstance: string, filename: string, menu: string): Promise<void> => {
    // check passed variable values for changes compared to deviceDataset values
    const deviceInstancesState = deviceInstancesStoreSelector(api.getState());
    const activeDeviceInstance = deviceInstanceActiveDeviceInstance(deviceInstancesState, deviceInstance);

    if (activeDeviceInstance === undefined) {
      return;
    }

    const contents = await createDeviceDatasetBackupContent({
      webWorkerDeviceManager,
      caption: 'Caption',
      datasetId: createDatasetIdent('parameterization'),
      activeDevice: activeDeviceInstance,
      datasetType: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType.COMPLETE,
      previewMenu: menu,
    });

    try {
      await AppFileSystemService.ExportLocalFile({
        suggestedName: filename,
        mimeType: MIME_TYPE__CXEDP,
        extensions: [FILE_EXTENTION__CXEDP],
        contents,
      });
      api.dispatch(showApplicationMessage('success', 'SAVE_PARAMETER_FILE__SAVE_DATA__SUCCESS'));
    } catch (error) {
      if (isAbortError(error)) {
        api.dispatch(showApplicationMessage('warning', 'SAVE_PARAMETER_FILE__SAVE_DATA__ERROR__CANCELLED'));
      } else {
        api.dispatch(showApplicationMessage('error', 'SAVE_PARAMETER_FILE__SAVE_DATA__FAILED'));
      }
    }
  };

  switch (action.type) {
    case 'DATASET_PROVIDER__REQUEST_DATASET_LIST': {
      const { payload } = action;
      api.dispatch(setDatasetList(payload.datasetId ?? '', []));
    }
      break;
    case 'DATASET_PROVIDER__REQUEST_EXTERNAL_DEVICE_DATASET': {
      const { deviceInstanceId } = action.payload;
      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const deviceDatasetValues: DeviceDatasetValueCollection = deviceInstances.instances[deviceInstanceId]?.deviceDataset.user.values ?? {};
      // Copy data from device value -> external values
      const externalValue: Services.DeviceModel.StatusValueRef[] = Object.keys(deviceDatasetValues)
        .map((key) => ({
          identRef: key,
          value: deviceDatasetValues[key].value,
          backupValue: deviceDatasetValues[key].backupValue,
        }));
      api.dispatch(writeActiveDeviceVariableValues(deviceInstanceId, externalValue));
      break;
    }
    case 'DATASET_PROVIDER__SAVE_DATASET__EXTERNAL_FILE': {
      const { filename, menu, deviceInstanceId } = (action as WriteDatasetToExternalFileAction).payload;
      await actionWriteDatasetToExternalFile(deviceInstanceId, filename, menu);
      break;
    }
    default:
  }
  const result = next(action);
  return result;
};

export default DatasetProviderMiddleware;
