/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import IconNoDeviceBluetooth from '../../../assets/icons/icon-no-device-bluetooth.svg';
import IconNoDeviceLAN from '../../../assets/icons/icon-no-device-lan.svg';
import IconNoDeviceUSB from '../../../assets/icons/icon-no-device-usb.svg';
import DeviceCardNoDevice from '../../DeviceCards/DeviceCardNoDevice/DeviceCardNoDevice';
import { InterfaceCardType } from '../types';

export interface ListItemInterfaceCardProps {
  interfaceType: InterfaceCardType;
}

const mapToProtocol = {
  [InterfaceCardType.LAN]: 'DEVICE_CARD__NO_DEVICE__LAN_TEXT',
  [InterfaceCardType.BLUETOOTH]: 'DEVICE_CARD__NO_DEVICE__BLUETOOTH_TEXT',
  [InterfaceCardType.USB]: 'DEVICE_CARD__NO_DEVICE__USB_TEXT',
};

const mapToImage = {
  [InterfaceCardType.LAN]: IconNoDeviceLAN,
  [InterfaceCardType.BLUETOOTH]: IconNoDeviceBluetooth,
  [InterfaceCardType.USB]: IconNoDeviceUSB,
};

// eslint-disable-next-line max-len
export const ListItemInterfaceCard: React.FC<ListItemInterfaceCardProps> = (props: ListItemInterfaceCardProps)
: React.ReactElement => {
  const { interfaceType } = props;
  const { t } = useTranslation();

  return (
    <DeviceCardNoDevice
      protocolTitle={t('DEVICE_CARD__NO_DEVICE__TITLE')}
      productImage={mapToImage[interfaceType] ?? IconNoDeviceUSB}
      protocolText={t<string>(mapToProtocol[interfaceType] ?? '???')}
      scanActive
    />
  );
};
