/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback, useState } from 'react';

const sessionTimoutMillsec = 10 * 1000 * 60;
const defaultPassword = '0';

const useSessionPassword = () => {
  const [accessPassword, setAccessPassword] = useState(defaultPassword);
  const [passwordTimestamp, setPasswordTimestamp] = useState(new Date().getTime());

  const setTempPassword = useCallback((pass?: string) => {
    setAccessPassword(pass ?? defaultPassword);
    setPasswordTimestamp(pass === undefined ? 0 : new Date().getTime());
  }, [setAccessPassword, setPasswordTimestamp]);

  const nowTime = new Date().getTime();
  const password = (nowTime - passwordTimestamp) > sessionTimoutMillsec
    ? defaultPassword
    : accessPassword;
  return [password, setTempPassword] as const;
};

export default useSessionPassword;
