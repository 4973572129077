/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import CreateBackupComponent from '../../../components/CreateBackupComponent/CreateBackupComponent';
import { deviceWizardUpdateButtons } from '../../../store';
import PreviewDeviceDatasetReduxControl from '../../../controls/PreviewDeviceDatasetReduxControl/PreviewDeviceDatasetReduxControl';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import { ButtonState, ButtonRole } from '../../../store/deviceWizard';
import './Page2ChangeDeviceBackup.scss';

interface Page2ChangeDeviceBackupProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const Page2ChangeDeviceBackup: React.FC<Page2ChangeDeviceBackupProps> = (props: Page2ChangeDeviceBackupProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { active, previewMenu, deviceInstanceId } = props;

  useEffect(() => {
    if (active !== true) {
      return;
    }

    dispatch(deviceWizardUpdateButtons({
      source: `Page2ChangeDeviceBackup ${active}`,
      abort: ButtonState.enabled,
      back: ButtonState.enabled,
      next: ButtonState.enabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.next,
    }));
  }, [active]);

  const previewControl = previewMenu === undefined
    ? undefined
    : (
      <PreviewDeviceDatasetReduxControl
        caption={t<string>('WIZARD_CHANGE_DEVICE__PAGE_CREATE_BACKUP__DATASET_PREVIEW')}
        identRef={previewMenu}
        deviceInstanceId={deviceInstanceId}
      />
    );

  return (
    <Container fluid className="reset-wizard-page2-container h-100 w-100">
      <Row className="h-100">
        <Col xs={6}>
          <CreateBackupComponent deviceInstanceId={deviceInstanceId} />
        </Col>
        <Col xs={6} className="preview-control--frame-padding h-100">
          {previewControl}
        </Col>
      </Row>
    </Container>
  );
};

export default Page2ChangeDeviceBackup;
