/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { SVGGaugeSegment } from '../../../components/GaugeControl/SVGGaugeControl/SVGGauge/SVGGaugeSegments/SVGGaugeSegments';
import { SVGGaugeTextPoint } from '../../../components/GaugeControl/SVGGaugeControl/SVGGauge/SVGGaugeTextPoints/SVGGaugeTextPoints';
import DisplayFormat from '../../../helpers/displayFormat';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { deviceStatusDescriptorSelector } from '../../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetState, DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { DeviceInstancesState } from '../../../store/deviceInstances/types';
import { ChartSegment } from '../../Charts/Elements/types';

export interface ChartLimitsData {
    minValue: number;
    maxValue: number;
    loloValue?: number;
    loValue?: number;
    hiValue?: number;
    hihiValue?: number;
    displayFormat?: string;
  }

const numberParamSelector = (numberParam?: DeviceModelStatus.UI.typeGaugeNumberParam, state?: DatasetState)
  : number | undefined => {
  if (numberParam === undefined) {
    return undefined;
  }
  if (numberParam.type === DeviceModelStatus.UI.ControlValueKind.constantInteger) {
    return numberParam.value;
  }
  return state?.values[numberParam.variable]?.value;
};

const minValueSelector = (state: DatasetState | undefined, minValue: DeviceModelStatus.UI.typeGaugeNumberParam | undefined, range: DeviceModelStatus.StatusDescriptorRange | undefined)
  : number | undefined => (minValue === undefined ? (range?.regions[0].minValue ?? 0) : numberParamSelector(minValue, state));

const maxValueSelector = (state: DatasetState | undefined, maxValue: DeviceModelStatus.UI.typeGaugeNumberParam | undefined, range: DeviceModelStatus.StatusDescriptorRange | undefined)
  : number | undefined => {
  if (range === undefined) {
    return numberParamSelector(maxValue, state);
  }

  return maxValue === undefined
    ? range.regions[range.regions.length - 1].maxValue ?? 0
    : numberParamSelector(maxValue, state);
};

export const controlLimitSelector = (
  deviceInstances: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  variableIdentRef?: IdentRef,
  variableLimits?: DeviceModelStatus.UI.typeGaugeLimits,
)
  :ChartLimitsData | undefined => {
  if (variableIdentRef === undefined || variableLimits === undefined) {
    return undefined;
  }

  const state = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  const descriptor = deviceStatusDescriptorSelector(deviceInstances, deviceInstanceId, targetDataset, variableIdentRef);
  const range = descriptor ? DeviceModelStatus.Functions.GetDescriptorRange(descriptor) : undefined;

  return {
    minValue: minValueSelector(state, variableLimits.minValue, range) ?? 0,
    maxValue: maxValueSelector(state, variableLimits.maxValue, range) ?? 100,
    loloValue: numberParamSelector(variableLimits.loloValue, state),
    loValue: numberParamSelector(variableLimits.loValue, state),
    hiValue: numberParamSelector(variableLimits.hiValue, state),
    hihiValue: numberParamSelector(variableLimits.hihiValue, state),
  };
};

export const calculateChartMarkPoints = (segments: ChartLimitsData[], displayFormat: string): SVGGaugeTextPoint[] => {
  const minValue = segments.length > 0 ? segments[0].minValue : 0;

  const minPoint: SVGGaugeTextPoint = {
    value: minValue,
    text: DisplayFormat(displayFormat, minValue),
  };

  const points = segments.reduce((acc, seg) => {
    if (acc[acc.length - 1].value === seg.maxValue) {
      return acc;
    }
    return [
      ...acc,
      {
        value: seg.maxValue,
        text: DisplayFormat(displayFormat, seg.maxValue),
      },
    ];
  }, [minPoint] as SVGGaugeTextPoint[]);
  return points;
};

export const calculateChartSegment = (chartLimits: ChartLimitsData | undefined)
  : SVGGaugeSegment[] => {
  const minValue = chartLimits?.minValue ?? 0;
  const mxValue = chartLimits?.maxValue ?? 100;

  const maxValue = [minValue, chartLimits?.loloValue, chartLimits?.loValue, chartLimits?.hiValue, chartLimits?.hihiValue, mxValue]
    .reduce((acc, vx) => (vx === undefined ? acc : Math.max(acc ?? 0, vx)), mxValue) ?? 100;

  const gaugeSegments: ChartSegment[] = [
    {
      minValue,
      maxValue: chartLimits?.loloValue ?? minValue,
      color: 'red',
    },
    {
      minValue: chartLimits?.loloValue ?? minValue,
      maxValue: chartLimits?.loValue ?? (chartLimits?.loloValue ?? minValue),
      color: 'yellow',
    },
    {
      minValue: chartLimits?.loValue ?? (chartLimits?.loloValue ?? minValue),
      maxValue: chartLimits?.hiValue ?? (chartLimits?.hihiValue ?? maxValue),
      color: 'green',
    },
    {
      minValue: chartLimits?.hiValue ?? (chartLimits?.hihiValue ?? maxValue),
      maxValue: chartLimits?.hihiValue ?? maxValue,
      color: 'yellow',
    },
    {
      minValue: chartLimits?.hihiValue ?? maxValue,
      maxValue,
      color: 'red',
    },
  ];
  const segments = gaugeSegments
    .filter((seg) => seg.minValue !== seg.maxValue);
  return segments.length > 0 ? segments : [{
    minValue,
    maxValue,
    color: 'green',
  }];
};
