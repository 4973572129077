/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { ReduxControlProps } from '../ReduxControlProps';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { CurrentStatusListDescriptorControl } from './CurrentStatusListDescriptorControl';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import withControlVisibility from '../hoc/withControlVisibility';

const CurrentStatusListReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement => {
  const { identRef, deviceInstanceId } = props;
  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);

  if (descriptor === undefined) {
    return <UnsupportedControl text={`CurrentStatusListReduxControl: Undefined object ${identRef}`} />;
  }

  if (descriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="CurrentStatusListReduxControl: Supported type is ControlDescriptor only" />;
  }

  if (descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLCURRENTSTATUSLIST) {
    return <UnsupportedControl text="CurrentStatusListReduxControl: Supported ControlType is CTLCURRENTSTATUSLIST only" />;
  }

  return (
    <CurrentStatusListDescriptorControl
      deviceInstanceId={deviceInstanceId}
      statusList={descriptor.controlType.statusList}
    />
  );
};

export default withControlVisibility(CurrentStatusListReduxControl);
