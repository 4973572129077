/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import { Action } from 'redux';
import { DeviceInstanceActionPayload } from '../../../common';

export const TRIO3_EVENT_MONITOR__EVENT_ASKNOWLEDGE = 'TRIO3_EVENT_MONITOR__EVENT_ASKNOWLEDGE';

/* ************************************************************************************ */
/* ACTION: AsknowledgeTrio3EventAction                                                   */
/* ************************************************************************************ */
export interface AsknowledgeTrio3EventPayload extends DeviceInstanceActionPayload {
  id: string;
  event: DeviceModelStatus.UI.Trio3EventDescription;
}

export interface AsknowledgeTrio3EventAction extends Action<typeof TRIO3_EVENT_MONITOR__EVENT_ASKNOWLEDGE> {
  payload: AsknowledgeTrio3EventPayload;
}

export type typeIoLinkTrio3EventMonitorMiddlewareActions = AsknowledgeTrio3EventAction;
