/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { ExecutionState } from '../common';
import { MessageType } from '../applicationMessage/types';

export const LINEARIZATION_DATASET__INIT_LINEARIZATION_CONTROL = 'LINEARIZATION_DATASET__INIT_LINEARIZATION_CONTROL';
export const LINEARIZATION_DATASET__SAVE_TO_DATASET = 'LINEARIZATION_DATASET__SAVE_TO_DATASET';

export const LINEARIZATION_DATASET__INIT_DATASET = 'LINEARIZATION_DATASET__INIT_DATASET';
export const LINEARIZATION_DATASET__SET_EXECUTION_STATE = 'LINEARIZATION_DATASET__SET_EXECUTION_STATE';
export const LINEARIZATION_DATASET__SET_POINT_COUNTER = 'LINEARIZATION_DATASET__SET_POINT_COUNTER';
export const LINEARIZATION_DATASET__SET_POINT_X = 'LINEARIZATION_DATASET__SET_POINT_X';
export const LINEARIZATION_DATASET__SET_POINT_Y = 'LINEARIZATION_DATASET__SET_POINT_Y';
export const LINEARIZATION_DATASET__SET_COLDPOINT = 'LINEARIZATION_DATASET__SET_COLDPOINT';
export const LINEARIZATION_DATASET__SET_USER_POINTS = 'LINEARIZATION_DATASET__SET_USER_POINTS';
export const LINEARIZATION_DATASET__MAX_REGRESSION_ERROR = 'LINEARIZATION_DATASET__MAX_REGRESSION_ERROR';
export const LINEARIZATION_DATASET__SET_CALCULATION_STATUS = 'LINEARIZATION_DATASET__SET_CALCULATION_STATUS';

export const LINEARIZATION_DATASET__SHOW_SPLINE_MESSAGE = 'LINEARIZATION_DATASET__SHOW_SPLINE_MESSAGE';
export const LINEARIZATION_DATASET__SET_SPLINE_MESSAGE = 'LINEARIZATION_DATASET__SET_SPLINE_MESSAGE';

export const LINEARIZATION_DATASET__SHOW_LINEARIZATION_MESSAGE = 'LINEARIZATION_DATASET__SHOW_LINEARIZATION_MESSAGE';
export const LINEARIZATION_DATASET__SET_LINEARIZATION_MESSAGE = 'LINEARIZATION_DATASET__SET_LINEARIZATION_MESSAGE';

export const LINEARIZATION_DATASET__UPDATE_DATASET = 'LINEARIZATION_DATASET__UPDATE_DATASET';

export interface TableHeader {
    header1: string;
    header2: string;
}

export interface LinTableValue {
    value: number;
    displayFormat: string;
}

export interface LinearizationTablePoint {
    xValue: number;
    yValue: number;
}

export interface LinSplineFunctions {
    equation: number[];
    string: string;
    points: number[][];
}

export interface LinearizationSplineData {
    A0: number;
    A1: number;
    A2: number;
    Max: number;
}

export interface LinearizationTableItem {
    xValue: number;
    yValue: number;
    deviationValue: number;
    isXValid: boolean;
    isYValid: boolean;
}

export interface LinearizationTable {
    label: DeviceModelStatus.LocString;
    header: TableHeader;
    linearizationData: LinearizationTableItem[];
    isValid: boolean;
}

export enum SplineCalculationStatus {
    // eslint-disable-next-line no-unused-vars
    done = 0,
    // eslint-disable-next-line no-unused-vars
    inprogress = 1,
    // eslint-disable-next-line no-unused-vars
    error = 2,
}

export type LinearizationSensorType = 'RTD' | 'TC';

export interface LinTableMessage {
    type: MessageType;
    text: string;
}

export interface LinearizationDatasetState {
    executionState: ExecutionState,
    sensorType: LinearizationSensorType,
    pointsCounter: LinTableValue;
    maxRegressionError: LinTableValue;
    coldJunctionComp0?: LinTableValue;
    coldJunctionComp80?: LinTableValue;
    table: LinearizationTable;
    spline: LinearizationSplineData[];
    splineCalculationState: SplineCalculationStatus;
    splineMessage?: LinTableMessage;
    linearizationMessage? : LinTableMessage;
}

/* ************************************************************************************ */
/* ACTION: SetLinearizationDatasetAction                                                       */
/* ************************************************************************************ */
export interface InitLinearizationDatasetActionPayload {
    pointsCounter: LinTableValue;
    sensorType: LinearizationSensorType;
    maxRegressionError: LinTableValue;
    coldJunctionComp0?: LinTableValue;
    coldJunctionComp80?: LinTableValue;
    table: LinearizationTable;
    spline: LinearizationSplineData[];
}

export interface SetPointXYActionPayload {
    index: number;
    value: number;
}

/* ************************************************************************************ */
/* REDUX ACTIONS: UpdateLinearizationDatasetAction                                         */
/* ************************************************************************************ */
export interface UpdateLinearizationDatasetActionPayload {
    pointsCounter?: number;
    sensorType?: LinearizationSensorType,
    maxRegressionError?: number;
    coldJunctionComp0?: number;
    coldJunctionComp80?: number;
    table?: LinearizationTable;
    spline?: LinearizationSplineData[];
    splineCalculationState?: SplineCalculationStatus;
    executionState?: ExecutionState;
}

export interface UpdateLinearizationDatasetAction extends Action<typeof LINEARIZATION_DATASET__UPDATE_DATASET> {
    payload: UpdateLinearizationDatasetActionPayload;
}

export interface SetSplineCalculationStatusAction extends Action<typeof LINEARIZATION_DATASET__SET_CALCULATION_STATUS> {
    payload: SplineCalculationStatus;
}
/* ************************************************************************************ */

export interface SetLinearizationUserPointsAction extends Action<typeof LINEARIZATION_DATASET__SET_USER_POINTS> {
    payload: LinearizationTablePoint[];
}

export interface SetLinearizationExecutionStateAction extends Action<typeof LINEARIZATION_DATASET__SET_EXECUTION_STATE> {
    payload: ExecutionState;
}

export interface LinearizationControlPayload {
    controlIdent: IdentRef;
    deviceInstanceId: string;
}

export interface InitLinearizationControlAction extends Action<typeof LINEARIZATION_DATASET__INIT_LINEARIZATION_CONTROL> {
    payload: LinearizationControlPayload;
}

export interface SaveLinearizationToDatasetAction extends Action<typeof LINEARIZATION_DATASET__SAVE_TO_DATASET> {
    payload: LinearizationControlPayload;
}

export interface InitLinearizationDatasetAction extends Action<typeof LINEARIZATION_DATASET__INIT_DATASET> {
    payload: InitLinearizationDatasetActionPayload;
}

export interface SetPointsCounterAction extends Action<typeof LINEARIZATION_DATASET__SET_POINT_COUNTER> {
    payload: number;
}

export interface SetColdPointAction extends Action<typeof LINEARIZATION_DATASET__SET_COLDPOINT> {
    payload: number;
}

export interface SetPointXAction extends Action<typeof LINEARIZATION_DATASET__SET_POINT_X> {
    payload: SetPointXYActionPayload;
}

export interface SetPointYAction extends Action<typeof LINEARIZATION_DATASET__SET_POINT_Y> {
    payload: SetPointXYActionPayload;
}

export interface SetLinMaxRegressionErrorAction extends Action<typeof LINEARIZATION_DATASET__MAX_REGRESSION_ERROR> {
    payload: number;
}

export interface ShowLinTableSplineMessageAction extends Action<typeof LINEARIZATION_DATASET__SHOW_SPLINE_MESSAGE> {
    payload?: LinTableMessage;
}

export interface SetLinTableSplineMessageAction extends Action<typeof LINEARIZATION_DATASET__SET_SPLINE_MESSAGE> {
    payload?: LinTableMessage;
}

export interface ShowLinTableLinearizationMessageAction extends Action<typeof LINEARIZATION_DATASET__SHOW_LINEARIZATION_MESSAGE> {
    payload?: LinTableMessage;
}

export interface SetLinTableLinearizationMessageAction extends Action<typeof LINEARIZATION_DATASET__SET_LINEARIZATION_MESSAGE> {
    payload?: LinTableMessage;
}

export type typeLinearizationDatasetActionTypes = InitLinearizationDatasetAction
| SetLinearizationUserPointsAction
| SetLinearizationExecutionStateAction
| InitLinearizationControlAction
| SaveLinearizationToDatasetAction
| SetPointsCounterAction
| SetColdPointAction
| SetPointXAction
| SetPointYAction
| SetLinMaxRegressionErrorAction
| SetSplineCalculationStatusAction
| UpdateLinearizationDatasetAction
| ShowLinTableSplineMessageAction
| SetLinTableSplineMessageAction
| ShowLinTableLinearizationMessageAction
| SetLinTableLinearizationMessageAction;
