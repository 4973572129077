/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { IdentRef } from '@gpt/commons';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { useTypedSelector } from '../../../../../store';
import { getControlType, linTableStatusMembers } from './common';
import { AreRecordsEqual } from '../../../../PreviewTableControl/PreviewTableControl';
import { deviceTargetDatasetSelector } from '../../../../../store/deviceInstances/store/dataStorage/selectors';
import { selectTableRecords } from '../../../selectors/selectTableRecords';

const useCompareLinTableMembers = (deviceInstanceId: string, linTableIdentRef: IdentRef, target1: DatasetType, target2: DatasetType)
: boolean => {
  const dataset1 = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target1));
  const dataset2 = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target2));

  if (dataset1 === undefined && dataset2 === undefined) {
    return true;
  }

  if (dataset1 === undefined || dataset2 === undefined) {
    return false;
  }

  const controlType = getControlType(linTableIdentRef, dataset1);
  if (controlType === undefined) {
    return true;
  }

  const unequal = linTableStatusMembers.find((member) => {
    const memberIdent = controlType[member as string];
    const value1 = dataset1.values[memberIdent]?.value;
    const value2 = dataset2.values[memberIdent]?.value;
    return value1 !== value2;
  });

  const leftRecords = selectTableRecords(controlType.userLinData, dataset1);
  const rightRecords = selectTableRecords(controlType.userLinData, dataset2);

  const tableEqual = AreRecordsEqual(leftRecords, rightRecords);

  return (unequal === undefined && tableEqual);
};

export default useCompareLinTableMembers;
