/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { ReduxControlProps } from '../ReduxControlProps';
import { useTypedSelector } from '../../store';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { ParameterGroupControl } from '../../components/ParameterEditor';
import DIPSwitchBlockControl from '../DIPSwitchBlockControl/DIPSwitchBlockControl';
import { DatasetContext } from '../../views/DatasetContext';
import withControlVisibility from '../hoc/withControlVisibility';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import { DatasetState, DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';

const controlTypeSelector = (deviceDataset: DatasetState, identRef: IdentRef): DeviceModelStatus.UI.DIPSwitchBlockLiveType | string => {
  const ctrlDescriptor = deviceDataset.descriptors[identRef];
  if (ctrlDescriptor === undefined) {
    return `DIPSwitchBlockReduxLive [${identRef}]: undefined object`;
  }
  if (ctrlDescriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return `DIPSwitchBlockReduxLive [${identRef}]: supported object type is ControlDescriptor only`;
  }
  if (ctrlDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDIPSWITCHBLOCKLIVE) {
    return `DIPSwitchBlockReduxLive [${identRef}]: supported controlType is CTLDIPSWITCHBLOCKLIVE only`;
  }
  return ctrlDescriptor.controlType;
};

const dipVariableSelector = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: IdentRef): DeviceModelStatus.StatusDescriptor | string => {
  const deviceDataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (deviceDataset === undefined) {
    return `DIPSwitchBlockReduxLive [${targetDataset}]: undefined deviceDataset`;
  }

  const ctrlDescriptor = controlTypeSelector(deviceDataset, identRef);
  if (typeof ctrlDescriptor === 'string') {
    return ctrlDescriptor;
  }

  const dipVariable = deviceDataset.descriptors[ctrlDescriptor.variable];
  if (dipVariable === undefined) {
    return `DIPSwitchBlockReduxLive [${ctrlDescriptor.variable}]: undefined object`;
  }
  if (dipVariable.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return `DIPSwitchBlockReduxLive [${ctrlDescriptor.variable}]: supported variable type is StatusDescriptor only`;
  }

  return dipVariable;
};

const dipVariableValueSelector = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: IdentRef): number | string => {
  const deviceDataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (deviceDataset === undefined) {
    return `DIPSwitchBlockReduxLive [${targetDataset}]: undefined deviceDataset`;
  }

  const ctrlDescriptor = controlTypeSelector(deviceDataset, identRef);
  if (typeof ctrlDescriptor === 'string') {
    return ctrlDescriptor;
  }
  if (deviceDataset.values[ctrlDescriptor.variable] === undefined) {
    return `DIPSwitchBlockReduxLive [${ctrlDescriptor.variable}]: value is undefined`;
  }
  return deviceDataset.values[ctrlDescriptor.variable].value;
};

const dipLengthSelector = (state: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: IdentRef): number | string => {
  const deviceDataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  if (deviceDataset === undefined) {
    return 0;
  }
  const ctrlDescriptor = controlTypeSelector(deviceDataset, identRef);
  if (typeof ctrlDescriptor === 'string') {
    return ctrlDescriptor;
  }
  return ctrlDescriptor.length;
};

const DIPSwitchBlockReduxLive: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, readonly, deviceInstanceId } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const dipLength = useTypedSelector((state) => dipLengthSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const dipVariable = useTypedSelector((state) => dipVariableSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const dipDeviceValue = useTypedSelector((state) => dipVariableValueSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef));

  if (typeof dipVariable === 'string') {
    return <UnsupportedControl text={dipVariable} />;
  }

  if (typeof dipDeviceValue === 'string') {
    return <UnsupportedControl text={dipDeviceValue} />;
  }

  if (dipVariable.visibility === false) {
    return (null);
  }

  if (typeof dipLength === 'string') {
    return <UnsupportedControl text={dipLength} />;
  }

  const { label, identRef: dipVariableIdent } = dipVariable;

  return (
    <ParameterGroupControl label={t(label)} alignEnd={false}>
      <DIPSwitchBlockControl
        id={`dip-live-${dipVariableIdent}`}
        mainValue={dipDeviceValue}
        comparisonValue={dipDeviceValue}
        length={dipLength}
        onValueChanged={() => 0}
        readonly={readonly}
      />
    </ParameterGroupControl>
  );
};

export default withControlVisibility(DIPSwitchBlockReduxLive);
