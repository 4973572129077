/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceCardListItem } from '../../../components/DeviceCardList/types';
import { ENV_APP_MODE } from '../../../enviroment';
import useIsAppPortable from '../../../hooks/useIsAppPortable';

const useShowDriverMessage = (deviceList: DeviceCardListItem[]): boolean => {
  const isAppPortable = useIsAppPortable();
  const found = deviceList.find((item) => item.type !== 'DEVICE_CARD_LIST_ITEM__INTERFACE');
  const isCouldVersion = ENV_APP_MODE === 'CLOUD';
  return isAppPortable && !found && !isCouldVersion;
};

export default useShowDriverMessage;
