/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTypedSelector } from '../../../store';
import { setContextParameterHelp } from '../../../store/contexthelp';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { DeviceInstanceState } from '../../../store/deviceInstances/store/types';
import { CompareModeType } from '../../../views/CompareView/common';
import { ButtonFunction } from '../../Compare/CompareTypes';
import CompareStringValue from '../../Compare/Controls/StatusValues/CompareStringStatusValue/CompareStringValue/CompareStringValue';
import useDisplayStatusValue from '../../hooks/useDisplayStatusValue';

const statusVariableSelector = (state: DeviceInstanceState | undefined, identRef: string, target: DatasetType) => {
  const defaultRes = ['', '', undefined, false, undefined, false] as const;
  const dataset = state?.deviceDataset[target];
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return defaultRes;
  }
  const statusValue = dataset?.values[identRef];
  if (statusValue === undefined) {
    return defaultRes;
  }
  return [descriptor.label, descriptor.help, descriptor.unit, descriptor.visibility, statusValue.modified] as const;
};

export interface CompareQuint4StatusValueProps {
  identRef: IdentRef
  deviceInstanceId: string;
  leftDataset: DatasetType;
  hideLeftControl: boolean;
  rightDataset: DatasetType;
  hideRightControl: boolean;
  compareMode: CompareModeType;
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
  showImageAndSign?: boolean;
  // eslint-disable-next-line no-unused-vars
  onMidButtonClick?: () => void;
  buttonFunction?: ButtonFunction;
  equal: boolean;
  readonly?: boolean;
  warning?: string;
}

const CompareQuint4StatusValue: React.FC<CompareQuint4StatusValueProps> = (props: CompareQuint4StatusValueProps)
  : React.ReactElement | null => {
  const {
    deviceInstanceId,
    identRef,
    compareMode,
    leftDataset,
    hideLeftControl,
    rightDataset,
    hideRightControl,
    onHideContentHelp,
    onShowContentHelp,
    onMidButtonClick,
    showImageAndSign,
    readonly,
    warning,
    buttonFunction,
    equal,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const lValue = useDisplayStatusValue(deviceInstanceId, identRef, leftDataset);
  const [label, help, lUnit, lVisibility, lModified] = useTypedSelector(
    (state) => statusVariableSelector(state.deviceInstances.instances[deviceInstanceId], identRef, leftDataset),
  );

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const rValue = useDisplayStatusValue(deviceInstanceId, identRef, rightDataset);
  const [xlabel, xhelp, rUnit, rVisibility, rModified] = useTypedSelector(
    (state) => statusVariableSelector(state.deviceInstances.instances[deviceInstanceId], identRef, rightDataset),
  );

  if (compareMode === CompareModeType.ShowDifference && equal) {
    return (null);
  }

  const ronly = readonly || compareMode === CompareModeType.ShowAll;

  return (
    <CompareStringValue
      label={t(label)}
      help={t<string>(help)}
      equal={equal}
      valueLeft={lValue ?? ''}
      valueLeftUnit={lUnit === undefined ? undefined : t<string>(lUnit)}
      hideLeftControl={hideLeftControl || lVisibility === false}
      valueRight={rValue ?? ''}
      valueRightUnit={rUnit === undefined ? undefined : t<string>(rUnit)}
      hideRightControl={hideRightControl || rVisibility === false}
      onButtonClick={() => onMidButtonClick?.()}
      modified={lModified ?? false}
      readonly={ronly}
      onLeftValueChange={(ev) => 0}
      warning={warning}
      buttonFunction={buttonFunction ?? ButtonFunction.Hidden}
      showImageAndSign={showImageAndSign ?? false}
      onShowContentHelp={() => {
        dispatch(setContextParameterHelp({
          title: t<string>(label),
          text: t<string>(help),
        }));
        onShowContentHelp?.();
      }}
      onHideContentHelp={() => {
        dispatch(setContextParameterHelp({
          title: '',
          text: '',
        }));
        onHideContentHelp?.();
      }}
    />
  );
};

export default CompareQuint4StatusValue;
