/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
// eslint-disable-next-line import/prefer-default-export
export const mapValue = (value: number, inputStart: number, inputEnd: number, outputStart: number, outputEnd: number): number => {
  const output = outputStart + ((outputEnd - outputStart) / (inputEnd - inputStart)) * (value - inputStart);
  if (output < outputStart) return outputStart;
  if (output > outputEnd) return outputEnd;
  return output;
};
