/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { LinearizationTableItem, LinTableValue } from '../../../../store/linearizationDataset/types';
import { TableKaltstelleView } from '../KaltstelleView/TableKaltstelleView';
import { TableColumnHeader } from '../TableColumnHeader/TableColumnHeader';
import { TableElement } from '../TableElement/TableElement';
import './SmallTable.css';

export interface SmallTableWithKaltStelleProps {
    items: LinearizationTableItem[];
    header1: string;
    header2: string;
    coldJunctionComp0: LinTableValue;
    coldJunctionComp80: LinTableValue;
    // eslint-disable-next-line no-unused-vars
    onXValueChange: (pointIndex: number, pointValue: string) => void;
    // eslint-disable-next-line no-unused-vars
    onYValueChange: (pointIndex: number, pointValue: string) => void;
}

export const SmallTableWithKaltStelle:React.FC<SmallTableWithKaltStelleProps> = (props: SmallTableWithKaltStelleProps): React.ReactElement => {
  const {
    items, header1, header2, coldJunctionComp0, coldJunctionComp80, onXValueChange, onYValueChange,
  } = props;

  return (
    <>
      <div className="smallTable_separator-container">
        <div className="smallTable_separator-container-separator" />
      </div>
      <div className="smallTableKaltstelle-container">
        <TableKaltstelleView
          header1={header1}
          header2={header2}
          items={[{
            value1: {
              value: 0,
              displayFormat: coldJunctionComp0.displayFormat,
            },
            value2: {
              value: 0,
              displayFormat: coldJunctionComp0.displayFormat,
            },
          },
          {
            value1: coldJunctionComp0,
            value2: coldJunctionComp80,
          }]}
        />
        <div className="smallTableKaltstelle-container-table">
          <div className="smallTable-container-header">
            <TableColumnHeader header1={header1} header2={header2} />
          </div>
          <div className="smallTable-container-data">
            {items.map((item, index) => (
              <div
                className="smallTable-container-data-element"
                key={`tableElement-${index + 1}`}
              >
                <TableElement
                  index={index}
                  item={item}
                  onXValueChange={(pointIndex: number, pointValue: string) => onXValueChange(pointIndex, pointValue)}
                  onYValueChange={(pointIndex: number, pointValue: string) => onYValueChange(pointIndex, pointValue)}
                  id={`input__x__${index}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
