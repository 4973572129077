/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { ExecutionState } from '../../../common';
import { DeviceInstancesState } from '../../types';
import { ModelInstanceInformation } from './types';

export const deviceInstanceActiveDeviceInstance = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
): ModelInstanceInformation | undefined => {
  if (deviceInstanceId === undefined) {
    return undefined;
  }
  const instance = state.instances[deviceInstanceId];
  return instance?.activeDevice.modelInstance;
};

export const deviceInstanceActiveDeviceState = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
): ExecutionState => {
  if (deviceInstanceId === undefined) {
    return ExecutionState.init;
  }
  const instance = state.instances[deviceInstanceId];
  return instance?.activeDevice.executionState ?? ExecutionState.init;
};
