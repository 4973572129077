/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { IdentRef } from '@gpt/commons';
import { useContextDatasetStatusValue } from '../../../../../hooks/useContextDataset';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';

const useHDCIsEntryEmpty = (deviceInstanceId: string | undefined, identRef: IdentRef, target?: DatasetType): boolean => {
  const statusValue = useContextDatasetStatusValue(deviceInstanceId, identRef, target);
  return statusValue?.value === undefined || statusValue.value === 0xFFFF;
};

export default useHDCIsEntryEmpty;
