/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, {
  useContext, useEffect, useState,
} from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reduxStore';
import { deviceWizardInitButtons, deviceWizardSetButtons, deviceWizardUpdateButtons } from '../../../store/deviceWizard';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import PageDocumentation from './PageDocumentation/PageDocumentation';
import ReduxControl from '../../../controls/ReduxControl/ReduxControl';
import updateWizardPageState, { WIZARD_BUTTON_LABEL__NEXT, WIZARD_BUTTON_LABEL__TRANSFER } from '../../helper';
import { StructureItems } from '../../../controls/ReduxControlProps';
import { compareTabData, MenuItemData, menuItemsSelector } from '../../../controls/selectors/menuItemsSelector';
import { DatasetContext } from '../../../views/DatasetContext';
import WizardView from '../../WizardView/WizardView';
import WizardViewPage from '../../WizardView/WizardViewPage';
import { disposeDevice } from '../../../store';
import { wizardReportMenuSelector } from '../../../store/selectors/rootStructureSelector';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { executeDeviceDownloadDatasetMethod } from '../../../store/deviceInstances/middleware/deviceMethodExecution/actions';
import { WizardProps } from '../../types';
import { ACTIONID__DOWNLOAD_DATA_TO_DEVICE } from '../../../store/deviceInstances/middleware/deviceMethodExecution/types';
import Page1SelectDevice from './Page1SelectDevice/Page1SelectDevice';

// Download page is last wizard page
const isPreDownloadPage = (length: number, page: number): boolean => (page === length - 1);
// Pre Download page is pre-last wizard page
const isPrePreDownloadPage = (length: number, page: number): boolean => (page === length - 2);

const CommissioningWizard: React.FC<WizardProps> = (props: WizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { targetDataset } = useContext(DatasetContext);
  const { deviceInstanceId, startPage } = props;
  const [stopStateUpdate, setStopStateUpdate] = useState(true);

  const wizardData: MenuItemData[] = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const rootMenu = dataset?.descriptors.WIZARD_INITIALDEVICESTARTUP;

    if (rootMenu?.type !== DeviceModelStatus.StatusType.WizardDescriptor
      || rootMenu.wizardType.type !== DeviceModelStatus.Wizards.WizardType.INITIALDEVICESTARTUP) {
      return [];
    }

    return menuItemsSelector(dataset, rootMenu.wizardType.menu);
  }, (p, c) => compareTabData(p, c));

  const reportMenu = useTypedSelector((state) => wizardReportMenuSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, 'WIZARD_INITIALDEVICESTARTUP'));
  const datasetValid = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.user)?.datasetValid);
  const [pageStates, setPageState] = useState<StructureItems>({});

  useEffect(() => {
    dispatch(deviceWizardInitButtons({
      abort: ButtonState.enabled,
      back: ButtonState.enabled,
      next: ButtonState.enabled,
      complete: ButtonState.hidden,
      load: ButtonState.enabled,
      pcta: ButtonRole.next,
    }));
  }, []);

  const pages = wizardData
    .map((element, index) => (
      <WizardViewPage
        key={`wiz-com-page--${element.identRef}`}
        title={t<string>(element.label)}
        state={stopStateUpdate ? undefined : pageStates[element.identRef]}
        onChangeToNextPage={() => {
          if (isPreDownloadPage(wizardData.length, index)) {
            // Execute Download on last page only
            dispatch(executeDeviceDownloadDatasetMethod({
              methodIdent: DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF,
              actionId: ACTIONID__DOWNLOAD_DATA_TO_DEVICE,
              sourceDataset: DatasetType.user,
              targetInstance: deviceInstanceId,
            }));

            dispatch(deviceWizardUpdateButtons({
              abort: ButtonState.disabled,
              back: ButtonState.disabled,
              next: ButtonState.hidden,
              complete: ButtonState.disabled,
              load: ButtonState.disabled,
              pcta: ButtonRole.complete,
            }));
          } if (isPrePreDownloadPage(wizardData.length, index)) {
            dispatch(deviceWizardSetButtons({
              abort: { state: ButtonState.enabled },
              back: { state: ButtonState.enabled },
              next: {
                state: datasetValid ? ButtonState.enabled : ButtonState.disabled,
                label: WIZARD_BUTTON_LABEL__TRANSFER,
              },
              load: { state: ButtonState.enabled },
              complete: { state: ButtonState.hidden },
              pcta: ButtonRole.next,
            }));
          } else {
            dispatch(deviceWizardUpdateButtons({
              abort: ButtonState.enabled,
              back: ButtonState.enabled,
              next: ButtonState.enabled,
              complete: ButtonState.hidden,
              load: ButtonState.enabled,
              pcta: ButtonRole.next,
            }));
          }
        }}
        onChangeToPreviousPage={() => {
          if (index === 0) {
            dispatch(disposeDevice(deviceInstanceId));
            dispatch(deviceWizardUpdateButtons({
              abort: ButtonState.enabled,
              back: ButtonState.disabled,
              next: ButtonState.disabled,
              complete: ButtonState.hidden,
              load: ButtonState.enabled,
              pcta: ButtonRole.next,
            }));
          } else if (isPreDownloadPage(wizardData.length, index)) {
            dispatch(deviceWizardSetButtons({
              abort: { state: ButtonState.enabled },
              back: { state: ButtonState.enabled },
              next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__NEXT },
              complete: { state: ButtonState.hidden },
              load: { state: ButtonState.enabled },
              pcta: ButtonRole.next,
            }));
          } else {
            dispatch(deviceWizardUpdateButtons({
              abort: ButtonState.enabled,
              back: ButtonState.enabled,
              next: ButtonState.enabled,
              complete: ButtonState.hidden,
              load: ButtonState.enabled,
              pcta: ButtonRole.next,
            }));
          }
        }}
      >
        <ReduxControl
          identRef={element.identRef}
          deviceInstanceId={deviceInstanceId}
          onStateChanged={(ident, pagestate) => setPageState((state) => updateWizardPageState(state, ident, pagestate))}
        />
      </WizardViewPage>
    ));

  const wizardView = (
    <WizardView
      title={t<string>('WIZARD_DEVICE_COMMISSIONING__TITLE')}
      onWizardExit={() => {
        dispatch(disposeDevice(deviceInstanceId));
      }}
      startPage={startPage}
      deviceInstanceId={deviceInstanceId}
    >
      <Page1SelectDevice
        title={t<string>('WIZARD_DEVICE_COMMISSIONING__SELECT_DEVICE')}
        hideStepper
      />
      {pages}
      <PageDocumentation
        title={t<string>('WIZARD_DEVICE_COMMISSIONING__FINAL_PAGE__TITLE')}
        reportMenu={reportMenu}
        deviceInstanceId={deviceInstanceId}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardSetButtons({
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.enabled },
            next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__TRANSFER },
            complete: { state: ButtonState.hidden },
            load: { state: ButtonState.enabled },
            pcta: ButtonRole.next,
            focusPcta: ButtonRole.next,
          }));
        }}
      />
    </WizardView>
  );
  return <>{wizardView}</>;
};

export default CommissioningWizard;
