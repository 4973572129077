/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  StandardViewState, typeStandardViewAction,
} from './types';

export const initialStandardViewState: StandardViewState = {
  help: [],
  applicationId: undefined,
};

const standardViewReducer = (
  state = initialStandardViewState,
  action: typeStandardViewAction,
): StandardViewState => {
  switch (action.type) {
    case 'STANDARD_VIEW__SET_DISPLAYED_HELP':
      return {
        ...state,
        help: action.payload,
      };
    case 'STANDARD_VIEW__SET_APPLICATION_ID':
      return {
        ...state,
        applicationId: action.payload,
      };
    case 'STANDARD_VIEW__SHOW_MESSAGE':
      return {
        ...state,
        message: action.payload,
      };
    case 'STANDARD_VIEW__HIDE_MESSAGE':
      return {
        ...state,
        message: undefined,
      };
    default:
      return state;
  }
};

export default standardViewReducer;
