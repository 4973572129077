/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { WebDevice } from '@gpt/commons';
import { ParameterUnitColumns, ParameterLabelColumns, ParameterValueColumns } from '../../../components/ParameterEditor/ParameterControlColumnCount';
import DeviceCatalogCard from '../../../components/DeviceCatalogComponents/DeviceCatalogCard/DeviceCatalogCard';
import './PreviewDeviceHeader.css';

export interface PreviewDeviceHeaderProps {
    deviceCatalog: WebDevice.DeviceCatalogInformation;
    parameterFile: string;
  }

const PreviewDeviceHeader: React.FC<PreviewDeviceHeaderProps> = (props: PreviewDeviceHeaderProps)
  : React.ReactElement => {
  const { deviceCatalog, parameterFile } = props;
  const { t } = useTranslation();

  const text = t<string>('PREVIEW_DEVICE_PARAMETERS__PARAMETER_FILE');

  return (
    <div>
      <div className="page-device-header d-flex align-items-center">
        <div className={`col-${ParameterLabelColumns + ParameterValueColumns} page-device-header__cardcatalog h-100`}>
          <DeviceCatalogCard device={deviceCatalog} isButton={false} onClick={() => 0} />
        </div>
        <div className={`col-${ParameterUnitColumns}`}>
          <p />
        </div>
      </div>
      <div className="page-device-header__spliter" />
      <div className="py-1">
        <p className="page-device-header__label">{`${text} "${parameterFile}"`}</p>
      </div>
    </div>
  );
};

export default PreviewDeviceHeader;
