/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import OpStatusControl from '../../controls/OpStatusControl/OpStatusControl';
import './SystemOperCockpitCard.scss';

export interface SystemOperCockpitCardProps {
  deviceInstanceId: string;
  operView?: DeviceModelStatus.UI.Contactron.MotorStarterOperationalView;
}

const SystemOperCockpitCard: React.FC<SystemOperCockpitCardProps> = (props: SystemOperCockpitCardProps):
  React.ReactElement => {
  const {
    operView,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const variableList = (operView?.items ?? []).map((item) => (
    <OpStatusControl key={`op-${deviceInstanceId}--${item}`} deviceInstanceId={deviceInstanceId} identRef={item} />
  ));

  return (
    <PaperControl title={t<string>(operView?.label ?? 'OPERATIONAL: LABEL')}>
      <div className="contactron-system-operational-card">
        {variableList}
      </div>
    </PaperControl>
  );
};

export default SystemOperCockpitCard;
