/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReduxControlProps } from '../ReduxControlProps';
import { useTypedSelector } from '../../store';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { DeviceStatusControl } from '../../components/DeviceStatusControl/DeviceStatusControl';
import { DatasetContext } from '../../views/DatasetContext';
import withControlVisibility from '../hoc/withControlVisibility';
import { deviceDescriptorSelector } from '../../store/deviceInstances/store/deviceDataset/selector';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetState } from '../../store/deviceInstances/store/deviceDataset/types';

const controlDeviceStatusType = (
  identRef: IdentRef,
  dataset?: DatasetState,
): DeviceModelStatus.UI.DeviceStatusControlType | undefined => {
  const desc = dataset?.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (desc.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDEVICESTATUS) {
    return undefined;
  }
  return desc.controlType;
};

const DeviceStatusReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const { targetDataset } = useContext(DatasetContext);
  const descriptor = useTypedSelector(
    (state) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef),
  );
  const { t } = useTranslation();

  const controlVariable = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const controlType = controlDeviceStatusType(identRef, dataset);
    if (controlType === undefined) {
      return undefined;
    }
    return dataset?.descriptors[controlType.variable];
  });

  const controlVariableValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const controlType = controlDeviceStatusType(identRef, dataset);
    if (controlType === undefined) {
      return undefined;
    }
    return dataset?.values[controlType.variable];
  });

  if (descriptor === undefined) {
    return <UnsupportedControl text={`DeviceStatusReduxControl [${identRef}]: undefined object`} />;
  }
  if (descriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text={`DeviceStatusReduxControl [${identRef}]: supported object type is ControlDescriptor only`} />;
  }
  if (descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDEVICESTATUS) {
    return <UnsupportedControl text={`DeviceStatusReduxControl [${identRef}]: supported controlType is CTLDEVICESTATUS only`} />;
  }
  if (controlVariable === undefined || controlVariableValue === undefined) {
    return <UnsupportedControl text={`DeviceStatusReduxControl [${identRef}]: undefined control variable or its value`} />;
  }
  if (controlVariable.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text={`DeviceStatusReduxControl [${controlVariable.identRef}]: supported variable type is StatusDescriptor only`} />;
  }

  if (controlVariable.visibility === false) {
    return (null);
  }

  const behavior = descriptor.controlType.BEHAVIOUR
    .find((beh) => (beh.flag === controlVariableValue.value));
  const beh = behavior === undefined ? descriptor.controlType.DEFAULT : behavior;

  return (
    <DeviceStatusControl
      label={descriptor.controlType.label}
      text={beh.text}
      message={beh.message === undefined ? undefined : t<string>(beh.message)}
      led={beh.led}
    />
  );
};

export default withControlVisibility(DeviceStatusReduxControl);
