/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { writeActiveDeviceVariableValues } from '../../../store';
import { ButtonFunction } from '../CompareTypes';
import { isEqualStatusValues } from '../../../helpers/precisionValue';

// Value1 === Value2 && backupValue === undefined : Button is hidden
// Value1 !== Value2 && backupValue === undefined : Show WriteValue button
// Value1 !== Value2 && backupValue === Value1 : Show WriteValue button
// Value1 !== Value2 && backupValue !== undefined : Show WriteValue button
// Value1 === Value2 && backupValue !== undefined : Show RedoValue button
export const buttonFunctionMode = (
  lValue?: DeviceModelStatus.StatusValue,
  rValue?: DeviceModelStatus.StatusValue,
  precision?: number,
): ButtonFunction => {
  const isEqual = isEqualStatusValues(precision, lValue?.value, rValue?.value);

  let buttonFunction = ButtonFunction.WriteValue;
  if (lValue === undefined || rValue === undefined) {
    buttonFunction = ButtonFunction.Hidden;
  } else if (!isEqual && isEqualStatusValues(precision, lValue?.backupValue, lValue?.value)) {
    buttonFunction = ButtonFunction.WriteValue;
  } else if (!isEqual && lValue?.backupValue !== undefined) {
    buttonFunction = ButtonFunction.RedoValue;
  } else if (!isEqual) {
    buttonFunction = ButtonFunction.WriteValue;
  } else if (isEqual) {
    buttonFunction = ButtonFunction.Hidden;
  }
  return buttonFunction;
};

const useCompareMiddleButton = (
  deviceInstanceId?: string,
  leftValue?: DeviceModelStatus.StatusValue,
  rightValue?: DeviceModelStatus.StatusValue,
  precision?: number,
) => {
  const dispatch = useDispatch();
  const middleButtonFunction = buttonFunctionMode(leftValue, rightValue, precision);
  const onMiddleButtonClick = useCallback((buttonFunction: ButtonFunction): void => {
    // eslint-disable-next-line max-len
    if (leftValue === undefined || rightValue === undefined || deviceInstanceId === undefined) {
      return;
    }
    switch (buttonFunction) {
      case ButtonFunction.WriteValue:
        // Write right value to left value
        dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
          identRef: leftValue.identRef,
          value: rightValue.value,
          backupValue: leftValue.backupValue ?? leftValue.value,
        }]));
        break;
      case ButtonFunction.RedoValue:
        // Write backup value to the device model
        dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
          identRef: leftValue.identRef,
          value: leftValue.backupValue ?? leftValue.value,
          backupValue: undefined,
        }]));
        break;
      default:
    }
  }, [leftValue, rightValue]);

  return [middleButtonFunction, onMiddleButtonClick] as const;
};

export default useCompareMiddleButton;
