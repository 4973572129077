/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

export enum CompareModeType {
  // eslint-disable-next-line no-unused-vars
  ShowDifference = 'ShowDifference',
  // eslint-disable-next-line no-unused-vars
  ShowAll = 'ShowAll',
  // eslint-disable-next-line no-unused-vars
  AllParamsEditable = 'AllParamsEditable',
}

export const COMPARE_DEVICE_VIEW__TITLE__LOCID = 'SIDEBAR__COMPAREDEVICE_TITLE';
export const COMPARE_DEVICE_VIEW__HELP__LOCID = 'COMPARE_PARAMETERS__VIEW_HELP';
