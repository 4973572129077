/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextDatasetDescriptorLabel, useContextDatasetStatusValue } from '../../../hooks/useContextDataset';
import { DatasetContext } from '../../../views/DatasetContext';

export interface HDCErrorMemoryCounterProps {
  deviceInstanceId: string;
}

export const HDCErrorMemoryCounter
: React.FC<HDCErrorMemoryCounterProps> = (props: HDCErrorMemoryCounterProps):
  React.ReactElement => {
  const { t } = useTranslation();
  const { deviceInstanceId } = props;

  const { targetDataset } = useContext(DatasetContext);
  const rawErrorCount = useContextDatasetStatusValue(deviceInstanceId, 'PARA_ERROR_MEMORY_ERROR_COUNT', targetDataset)?.value ?? 0;
  const errorCount = rawErrorCount === 0xFFFF ? 0 : rawErrorCount;

  const errorCountLabel = useContextDatasetDescriptorLabel(deviceInstanceId, 'PARA_ERROR_MEMORY_ERROR_COUNT', targetDataset) ?? '';

  return (
    <div>{`${t(errorCountLabel)} ${errorCount}`}</div>
  );
};
