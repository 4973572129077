/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Services } from '@gpt/commons';
import { useTypedSelector } from '../../reduxStore';
import { ApplicationId } from '../store/standardview/types';
import { DeviceInstances } from '../types';
import { DiscoveryServiceState } from '../../discoveryServiceState';
import { ActiveDeviceState } from '../store/activeDevice/types';
import { stationSetConnectionStatus } from '../../middleware/childInstanceMiddleware/actions';

// eslint-disable-next-line import/prefer-default-export
export const useContextApplicationId = (deviceInstanceId: string | undefined)
: ApplicationId | undefined => useTypedSelector((state) => (deviceInstanceId === undefined
  ? undefined
  : state.deviceInstances.instances[deviceInstanceId]?.standardView.applicationId));

export const useHeaderMessage = (deviceInstanceId: string | undefined)
: string | undefined => useTypedSelector((state) => (deviceInstanceId === undefined
  ? undefined
  : state.deviceInstances.instances[deviceInstanceId]?.standardView.message?.text));

const IsDeviceConnected = (
  activeDevice: ActiveDeviceState,
  discoveryService: DiscoveryServiceState,
): Services.DeviceModelServer.ConnectionService.ConnectionStatus => {
  const deviceId = activeDevice.modelInstance?.device.instance?.deviceId;
  if (deviceId === undefined) {
    return Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;
  }

  const connectedDevice = Object
    .keys(discoveryService.devices)
    .find((key) => discoveryService.devices[key].instance?.deviceId === deviceId);

  return connectedDevice !== undefined
    ? Services.DeviceModelServer.ConnectionService.ConnectionStatus.connected
    : Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;
};

const IsContactronModule = (activeDevice: ActiveDeviceState): boolean => {
  const protocol = activeDevice.modelInstance?.device.catalog.protocol;
  return protocol === 'S-PORT-CONTACTRON';
};

const IsContactronModuleConnected = (
  instances: DeviceInstances,
  discoveryService: DiscoveryServiceState,
): Services.DeviceModelServer.ConnectionService.ConnectionStatus => {
  const deviceId = instances.ROOT_DEVICEINSTANCE_ID?.activeDevice.modelInstance?.device.instance?.deviceId;
  if (deviceId === undefined) {
    return Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;
  }

  const connectedDevice = Object
    .keys(discoveryService.devices)
    .find((key) => discoveryService.devices[key].instance?.deviceId === deviceId);

  return connectedDevice !== undefined
    ? Services.DeviceModelServer.ConnectionService.ConnectionStatus.connected
    : Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;
};

const IsCaparocModule = (activeDevice: ActiveDeviceState): boolean => {
  const deviceDriverId = activeDevice.modelInstance?.device.catalog.deviceDriverId;
  return deviceDriverId === 'CAPAROC_MODULE';
};

const IsCaparocModuleConnected = (
  instances: DeviceInstances,
  discoveryService: DiscoveryServiceState,
): Services.DeviceModelServer.ConnectionService.ConnectionStatus => {
  const stationDeviceId = instances.ROOT_DEVICEINSTANCE_ID?.activeDevice.modelInstance?.device.instance?.deviceId;
  if (stationDeviceId === undefined) {
    return Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;
  }

  const connectedDevice = Object
    .keys(discoveryService.devices)
    .find((key) => discoveryService.devices[key].instance?.deviceId === stationDeviceId);

  return connectedDevice !== undefined
    ? Services.DeviceModelServer.ConnectionService.ConnectionStatus.connected
    : Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;
};

export const deviceConnectionStatusSelector = (
  instances: DeviceInstances,
  discoveryService: DiscoveryServiceState,
  deviceInstanceId: string | undefined,
): Services.DeviceModelServer.ConnectionService.ConnectionStatus => {
  if (deviceInstanceId === undefined) {
    return Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;
  }

  const activeDevice = instances[deviceInstanceId]?.activeDevice;
  if (activeDevice === undefined) {
    return Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected;
  }

  const simulation = activeDevice.modelInstance?.device.instance?.simulation;
  if (simulation === true) {
    return Services.DeviceModelServer.ConnectionService.ConnectionStatus.offline;
  }

  // Check if connected device is connected to the station (CONTACTRON)
  // species: contactron-module
  // const deviceDriverId = activeDevice.modelInstance?.device.catalog.deviceDriverId;
  if (IsContactronModule(activeDevice)) {
    return IsContactronModuleConnected(instances, discoveryService);
  }
  if (IsCaparocModule(activeDevice)) {
    return IsCaparocModuleConnected(instances, discoveryService);
  }
  return IsDeviceConnected(activeDevice, discoveryService);
};

export const useDeviceConnectionStatus = (deviceInstanceId: string | undefined)
: Services.DeviceModelServer.ConnectionService.ConnectionStatus => {
  const dispatch = useDispatch();
  const status = useTypedSelector(
    (state) => deviceConnectionStatusSelector(state.deviceInstances.instances, state.discoveryService, deviceInstanceId),
  );

  useEffect(() => {
    if (deviceInstanceId === undefined) {
      return;
    }
    dispatch(stationSetConnectionStatus(deviceInstanceId, status));
  }, [status, deviceInstanceId, dispatch, stationSetConnectionStatus]);
  return status;
};
