/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  TRIO3_EVENT_MONITOR__EVENT_ASKNOWLEDGE,
  AsknowledgeTrio3EventPayload,
  typeIoLinkTrio3EventMonitorMiddlewareActions,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const asknowledgeTrio3Event = (payload: AsknowledgeTrio3EventPayload)
: typeIoLinkTrio3EventMonitorMiddlewareActions => ({
  type: TRIO3_EVENT_MONITOR__EVENT_ASKNOWLEDGE,
  payload,
});
