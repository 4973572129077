/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import SelectableAccordion, { SelectableAccordionItem } from '../../../components/SelectableAccordion/SelectableAccordion';
import PageSelectFileComponent from '../../../PageComponents/PageSelectFileComponent/PageSelectFileComponent';
import { LoadingControl } from '../../../components/LoadingControl/LoadingControl';
import PreviewValueDatasetReduxControl from '../../../controls/PreviewValueDatasetReduxControl/PreviewValueDatasetReduxControl';
import { ButtonState, ButtonRole } from '../../../store/deviceWizard/types';
import './RestoreDeviceResetTemplatePage.css';
import { RESTORE_WIZARD__DATASET_FILE_NAME } from '../types';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import { setDataStorageValue } from '../../../store/deviceInstances/store/dataStorage';
import { deviceMethodExecutionSelector } from '../../../store/deviceInstances/store/deviceMethod/selectors';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';
import {
  useTypedSelector, deviceWizardUpdateButtons, activeDeviceWriteInitialDataset,
} from '../../../store';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';
import { activeDeviceUploadDatasetFromFile } from '../../../store/deviceInstances/middleware/activeDevice/actions';

// eslint-disable-next-line no-unused-vars
enum TemplateType {
    // eslint-disable-next-line no-unused-vars
    FactorySetting,
    // eslint-disable-next-line no-unused-vars
    ExternalFile,
}

interface ExternalFileInfo {
  filename: string;
  content: string;
}

export const METHOD_ID__LOAD_EXTERNAL_FILE = 'RESTORE-DEVICE--LOAD-EXTERNAL-FILE';

export interface RestoreDeviceResetTemplatePageProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const RestoreDeviceResetTemplatePage: React.FC<RestoreDeviceResetTemplatePageProps> = (props: RestoreDeviceResetTemplatePageProps)
  : React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { active, previewMenu, deviceInstanceId } = props;

  const deviceInstance = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId)?.device);
  const loadExternalDataMessage = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, METHOD_ID__LOAD_EXTERNAL_FILE)?.message);
  const loadExternalDataState = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, METHOD_ID__LOAD_EXTERNAL_FILE)?.stage
    ?? MethodStageExecutionStatus.Initialize);

  const [selectedFile, setSelectedFile] = useState<ExternalFileInfo | undefined>(undefined);
  const [selectedMethod, setSelectedMethod] = useState<TemplateType>(TemplateType.FactorySetting);

  useEffect(() => {
    if (active !== true) {
      return;
    }

    if (selectedMethod === TemplateType.FactorySetting) {
      dispatch(deviceWizardUpdateButtons({
        abort: ButtonState.enabled,
        back: ButtonState.enabled,
        next: ButtonState.enabled,
        complete: ButtonState.hidden,
        pcta: ButtonRole.next,
      }));
    } else if (selectedMethod === TemplateType.ExternalFile) {
      switch (loadExternalDataState) {
        case MethodStageExecutionStatus.Initialize:
        case MethodStageExecutionStatus.InProgress:
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.disabled,
            back: ButtonState.disabled,
            next: ButtonState.disabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
          break;
        case MethodStageExecutionStatus.DoneFailed:
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: ButtonState.disabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
          break;
        case MethodStageExecutionStatus.DoneSuccess:
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
            focusPcta: ButtonRole.next,
          }));
          break;
        default:
          break;
      }
    }
  }, [selectedMethod, loadExternalDataState, active]);

  useEffect(() => {
    if (active !== true || deviceInstance === undefined) {
      return;
    }

    if (selectedMethod === TemplateType.FactorySetting) {
      dispatch(activeDeviceWriteInitialDataset(deviceInstanceId));
      dispatch(setDataStorageValue(deviceInstanceId, {
        key: RESTORE_WIZARD__DATASET_FILE_NAME, value: '',
      }));
    } else if (selectedMethod === TemplateType.ExternalFile && selectedFile !== undefined) {
      dispatch(activeDeviceUploadDatasetFromFile(deviceInstanceId, {
        methodId: METHOD_ID__LOAD_EXTERNAL_FILE,
        filename: selectedFile.filename,
        content: selectedFile.content,
      }));
      dispatch(setDataStorageValue(deviceInstanceId, {
        key: RESTORE_WIZARD__DATASET_FILE_NAME,
        value: selectedFile.filename.replace(/^.+[/\\]/, ''), // strip path prefix
      }));
    }
  }, [selectedMethod, deviceInstanceId, deviceInstance, selectedFile, active]);

  // Factory setting reset and external file is always available
  const datasetMethodsItems: SelectableAccordionItem[] = [
    {
      id: 'TEMPLATE_FACTORY_SETTING',
      title: t<string>('WIZARD_RESTORE_DEVICE__PAGE_RESTORE_OPTIONS__DATASET_FACTORY_SETTINGS'),
      createControl: (): React.ReactElement | undefined => undefined,
    },
    {
      id: 'TEMPLATE_EXTFILE_SETTINGS',
      title: t<string>('WIZARD_RESTORE_DEVICE__PAGE_RESTORE_OPTIONS__DATASET_EXT_FILE'),
      createControl: (): React.ReactElement => (
        <PageSelectFileComponent
          title=""
          buttonCaption={t<string>('STANDARD_BUTTON__SELECT_FILE')}
          buttonVariant={selectedFile ? 'tertiary' : 'primary'}
          selectedFilename={selectedFile?.filename}
          warning={selectedFile ? loadExternalDataMessage : undefined}
          onFileSelected={async (result) => {
            setSelectedFile({ filename: result.fileName, content: result.contents as string });
          }}
        />
      ),
    },
  ];

  let previewDatasetControl;
  if (selectedMethod === TemplateType.FactorySetting) {
    previewDatasetControl = previewMenu === undefined ? undefined : (
      <PreviewValueDatasetReduxControl
        deviceInstanceId={deviceInstanceId}
        caption={t<string>('WIZARD_RESTORE_DEVICE__PAGE_RESTORE_OPTIONS__DATASET_FACTORY_SETTINGS_PREVIEW')}
        identRef={previewMenu}
      />
    );
  } else if (selectedMethod === TemplateType.ExternalFile) {
    switch (loadExternalDataState) {
      case MethodStageExecutionStatus.Initialize:
        previewDatasetControl = undefined;
        break;
      case MethodStageExecutionStatus.DoneFailed:
        previewDatasetControl = undefined;
        break;
      case MethodStageExecutionStatus.DoneSuccess:
        if (selectedFile !== undefined && previewMenu !== undefined) {
          const caption = t<string>('WIZARD_RESTORE_DEVICE__PAGE_RESTORE_OPTIONS__DATASET_EXT_FILE_PREVIEW', {
            FILENAME: selectedFile.filename,
          });
          previewDatasetControl = (
            <PreviewValueDatasetReduxControl
              caption={caption}
              identRef={previewMenu}
              deviceInstanceId={deviceInstanceId}
            />
          );
        } else {
          previewDatasetControl = undefined;
        }
        break;
      default:
        previewDatasetControl = <LoadingControl title={t<string>('LOADING_DATA')} />;
        break;
    }
  }

  return (
    <Container fluid className="wiz-reset-container h-100">
      <Row className="h-100">
        <Col xs={6}>
          <div className="wiz-reset-container__title pb-3">
            {t<string>('WIZARD_RESTORE_DEVICE__PAGE_RESTORE_OPTIONS__TEMPLATE_TITLE')}
          </div>
          <SelectableAccordion
            group="method-group"
            items={datasetMethodsItems}
            line={false}
            onClick={(id) => {
              if (id === 'TEMPLATE_FACTORY_SETTING') {
                setSelectedMethod(TemplateType.FactorySetting);
              } else if (id === 'TEMPLATE_EXTFILE_SETTINGS') {
                setSelectedMethod(TemplateType.ExternalFile);
              }
            }}
          />
        </Col>
        <Col xs={6} className="wiz-reset-container__preview h-100">
          <div className="wiz-reset-container__preview__control p-1 preview-control--frame-padding">
            {previewDatasetControl}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RestoreDeviceResetTemplatePage;
