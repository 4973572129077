/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

export const RESTORE_WIZARD__DATASET_FILE_NAME = 'RESTORE-WIZARD--DATASET-FILE-NAME';
export const RESTORE_WIZARD__BACKUP_FILE_NAME = 'RESTORE-WIZARD--BACKUP-FILE-NAME';
