/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import {
  DeviceModelStatus, IdentRef,
} from '@gpt/commons';
import iconUnknown from '../../../assets/icons/icon-app-unknown.svg';
import iconCockpit from '../../../assets/icons/icon-cockpit-grey.svg';
import iconCompare from '../../../assets/icons/icon-compare-grey.svg';
import iconOnlineParam from '../../../assets/icons/icon-online-param.svg';
import iconChange from '../../../assets/icons/icon-write-data-grey-small.svg';
import { RoutePaths } from '../../../RoutePaths';
import { useTypedSelector } from '../../../store';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetState, DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';

const enabledAppTypes: string[] = [
  DeviceModelStatus.Apps.AppType.COCKPIT,
  DeviceModelStatus.Apps.AppType.EDITDEVICE,
  DeviceModelStatus.Apps.AppType.COMPAREDEVICE,
  DeviceModelStatus.Apps.AppType.ONLINEPARAM,
];

const appIconMap = {
  COCKPIT: iconCockpit,
  EDITDEVICE: iconChange,
  COMPAREDEVICE: iconCompare,
  ONLINEPARAM: iconOnlineParam,
};

export interface DeviceApplication {
  id: string;
  identRef: IdentRef;
  control: string;
  label: string;
  description: string;
  routePath: string;
  image: string;
  applicationId?: string;
}

const createRoute = (index, type: DeviceModelStatus.Apps.AppType): string => (
  index === 0
    ? `${RoutePaths.DeviceViewDefault}`
    : `/app-${type.toLowerCase()}`
);

const applicationListSelector = (dataset: DatasetState)
  : DeviceApplication[] => enabledAppTypes
  .map((type) => dataset.descriptors[`APP_${type}`])
  .filter((desc) => desc?.type === DeviceModelStatus.StatusType.ApplicationDescriptor)
  .map((desc) => desc as DeviceModelStatus.ApplicationDescriptor)
  .map((app, index) => {
    const { identRef, appType } = app;
    const id = `app-button__${identRef}`;
    const routePath = createRoute(index, appType.type);
    const { label, description, icon } = appType;
    const image = icon || (appIconMap[appType.type] ?? iconUnknown);
    const applId = `APP_${appType.type}`;
    return {
      id,
      control: appType.type,
      identRef,
      label,
      description,
      routePath,
      image,
      applicationId: applId,
    };
  });

const compareApplicationList = (left: DeviceApplication[], right: DeviceApplication[])
    : boolean => left.length === right.length;

const useApplicationList = (deviceInstanceId?: string): DeviceApplication[] => useTypedSelector(
  (state) => {
    if (deviceInstanceId === undefined) {
      return [];
    }
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.user);
    return dataset === undefined ? [] : applicationListSelector(dataset);
  },
  (left, right) => compareApplicationList(left, right),
);

export default useApplicationList;
