/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { setContextParameterHelp, setContextParameterWarning } from '../../../../store/contexthelp';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import UnsupportedControl from '../../../UnsupportedControl/UnsupportedControl';
import DateEditorControl from '../../DateEditorControl';
import TextEditorControl from '../../TextEditorControl/TextEditorControl';
import { updateDeviceDatasetValues } from '../../../../store/deviceInstances/store/deviceDataset/actions';
import { VariableEditorControlsProps } from '../types';
import './VariableDateEditorControl.css';

const validateRange = (value: string): boolean => {
  const isValidDate = new Date(value);
  return !Number.isNaN(isValidDate.getDate());
};

const VariableDateEditorControl
:React.FC<VariableEditorControlsProps> = (props: VariableEditorControlsProps):
  React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp,
    readonly, deviceInstanceId, showContextMessageDef,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warning, setWarning] = useState<string | undefined>(undefined);
  const [showContextMessage, setShowContextMessage] = useState<boolean>(showContextMessageDef ?? false);

  const hideContentHelp = (): void => {
    setShowContextMessage(false);
    dispatch(setContextParameterWarning(undefined));
    dispatch(setContextParameterHelp({
      title: '',
      text: '',
    }));
    onHideContentHelp?.();
  };

  const showContentHelp = (): void => {
    setShowContextMessage(true);
    const { help, label } = statusDescriptor;
    dispatch(setContextParameterHelp({
      title: t(label),
      text: t(help) ?? '',
    }));
    onShowContentHelp?.();
  };

  useEffect(() => {
    if (statusValue === undefined || statusDescriptor === undefined) {
      return;
    }

    if ((statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.DATETIME)
      && (statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.DATE)) {
      return;
    }

    if (!validateRange(statusValue.value)) {
      const message = t<string>('PARAMETER_VALIDATION_ERROR__INVALID_DATE_FORMAT');
      if (showContextMessage) {
        dispatch(setContextParameterWarning(message));
      }
      setWarning(message);
    } else {
      if (showContextMessage) {
        dispatch(setContextParameterWarning(undefined));
      }
      setWarning(undefined);
    }
  }, [statusValue, statusDescriptor, showContextMessage]);

  if (statusDescriptor === undefined) {
    return <UnsupportedControl text="VariableDateEditorControl: statusDescriptor is undefined" />;
  }

  if ((statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.DATETIME)
    && (statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.DATE)) {
    return <UnsupportedControl text="VariableDateEditorControl: only DATETIME type is supported" />;
  }

  const { label, help } = statusDescriptor;

  const createEditorControl = () => (
    <DateEditorControl
      label={`${t(label)}`}
      value={statusValue === undefined ? 'N/A' : statusValue.value}
      edited={statusValue?.modified ?? false}
      help={`${t(help)}`}
      warning={warning}
      onChange={(value) => {
        if (statusValue === undefined) {
          return;
        }

        if (validateRange(value)) {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            value,
            backupValue: statusValue.backupValue,
            identRef: statusDescriptor.identRef,
          }]));
        } else {
          dispatch(updateDeviceDatasetValues(deviceInstanceId, {
            targetDataset: DatasetType.user,
            values: [{
              value,
              backupValue: statusValue.backupValue,
              identRef: statusValue.identRef,
              valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
              valueValidityDescription: `${t<string>('PARAMETER_VALIDATION_ERROR__INVALID_DATE_FORMAT')}`,
            }],
          }));
        }
      }}
      onShowContentHelp={showContentHelp}
      onHideContentHelp={hideContentHelp}
    />
  );

  const createReadOnlyEditorControl = () => (
    <TextEditorControl
      label={`${t(label)}`}
      value={statusValue === undefined ? 'N/A' : statusValue.value}
      displayFormat="%s"
      readonly
      warning={warning}
      onValueChange={() => 0}
      onFocus={showContentHelp}
      onBlur={hideContentHelp}
      modified={statusValue?.modified}
    />
  );

  const isreadonly = readonly ?? (statusValue === undefined || statusDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY);
  const control = isreadonly ? createReadOnlyEditorControl() : createEditorControl();

  return (
    <>{control}</>
  );
};

export default VariableDateEditorControl;
