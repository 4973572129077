/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { LayoutCardReduxControlProps } from '../../types';
import Layout32Card from '../Layout32Card/Layout32Card';
import useModuleColorStatus from '../../hooks/useModuleColorStatus';
import useModuleDeviceName from '../../hooks/useModuleDeviceName';
import useModuleDeviceTag from '../../hooks/useModuleDeviceTag';

const Layout32CardReduxControl: React.FC<LayoutCardReduxControlProps> = (props: LayoutCardReduxControlProps)
: React.ReactElement | null => {
  const {
    channelNo, stationInstanceId, onModuleIdent, onModuleOpen,
  } = props;

  const moduleDeviceName = useModuleDeviceName(stationInstanceId, channelNo);
  const moduleDeviceTag = useModuleDeviceTag(stationInstanceId, channelNo);
  const color = useModuleColorStatus(stationInstanceId, channelNo);

  return (
    <Layout32Card
      color={color}
      title={moduleDeviceName}
      tag={moduleDeviceTag}
      onModuleIdent={() => onModuleIdent()}
      onModuleOpen={() => onModuleOpen()}
    />
  );
};

export default Layout32CardReduxControl;
