/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

const ParameterLabelColumns = 6;
const ParameterValueColumns = 5;
const ParameterUnitColumns = 1;

export {
  ParameterLabelColumns,
  ParameterValueColumns,
  ParameterUnitColumns,
};
