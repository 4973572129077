/* eslint-disable no-unused-vars */
import { IdentRef, DeviceModelStatus } from '@gpt/commons';
import { DatasetState } from '../../../../../store/deviceInstances/store/deviceDataset/types';

export const getControlType = (identRef: IdentRef, dataset?: DatasetState)
: DeviceModelStatus.UI.LinearizationControlType | undefined => {
  const spanDescriptor = dataset?.descriptors[identRef];
  if (spanDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (spanDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLLINEARIZATION) {
    return undefined;
  }
  return spanDescriptor.controlType;
};

export const linTableStatusMembers = ['minDeviation', 'numberOfPoints', 'userCharMaxX', 'userCharMinX', 'coldJunctionComp0', 'coldJunctionComp80', 'coldLineEquationB', 'coldLineEquationM', 'splineData', 'userLinData'];

export type LintableMember = 'minDeviation'
 | 'numberOfPoints'
 | 'splineData'
 | 'userCharMaxX'
 | 'userCharMinX'
 | 'userLinData'
 | 'coldJunctionComp0'
 | 'coldJunctionComp80'
 | 'coldLineEquationB'
 | 'coldLineEquationM';
