/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/prop-types */

import React from 'react';
import './SelectProcessCheckbox.scss';

export interface SelectProcessCheckboxProps {
    checked: boolean;
    label: string;
    disabled?: boolean;
    // eslint-disable-next-line no-unused-vars
    onChange: (value: boolean) => void;
}

const SelectProcessCheckbox: React.FC<SelectProcessCheckboxProps> = (
  props: SelectProcessCheckboxProps,
): React.ReactElement => {
  const {
    checked, label, disabled, onChange,
  } = props;

  return (
    <div className="select-process-checkbox">
      <label className="m-0">
        <input
          type="checkbox"
          className="select-process-checkbox-input"
          onChange={() => onChange(!checked)}
          data-testid="process-data-checkbox-input"
          disabled={disabled}
        />
        <svg
          className="select-process-checkbox-svg"
          viewBox="0 0 15 11"
          fill="none"
        >
          <path
            d="M1 4.5L5 9L14 1"
            strokeWidth="2"
            stroke={checked ? '#0098a1' : 'none'}
          />
        </svg>
      </label>
      <div className="select-process-checkbox-label">{label}</div>
    </div>
  );
};

export default SelectProcessCheckbox;
