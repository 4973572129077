/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext, useEffect } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reduxStore';
import UnsupportedControl from '../../../components/UnsupportedControl/UnsupportedControl';
import ParameterGroupControl from '../../../components/ParameterEditor/ParameterGroupControl';
import { ReduxControlProps } from '../../ReduxControlProps';
import './ParameterGroupReduxControl.css';
import ReduxControl from '../../ReduxControl/ReduxControl';
import { useMenuItemStateUpdate } from '../../hooks/useMenuItemStateUpdate';
import { compareTabData, MenuItemData, menuItemsSelector } from '../../selectors/menuItemsSelector';
import { useContextDatasetDescriptor } from '../../../hooks/useContextDataset';
import { DatasetContext } from '../../../views/DatasetContext';
import withControlVisibility from '../../hoc/withControlVisibility';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';

const ParameterGroupReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    onStateChanged,
    readonly,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  const { targetDataset } = useContext(DatasetContext);

  const [modifiedState, errorState, updateMenuItemState] = useMenuItemStateUpdate();

  const tabsTitle: MenuItemData[] = useTypedSelector(
    (state) => menuItemsSelector(deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset), identRef),
    (prev, curr) => compareTabData(prev, curr),
  );

  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, {
      error: errorState,
      modified: modifiedState,
    });
  }, [modifiedState, errorState, identRef]);

  if (descriptor === undefined) {
    return <UnsupportedControl text={`ParameterGroupReduxControl: Undefined object ${identRef}`} />;
  }

  if (descriptor.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return <UnsupportedControl text={`ParameterGroupReduxControl: Unsupported type ${descriptor.type}`} />;
  }

  const structureControl = tabsTitle.map((element) => {
    const key = `ctrl-${descriptor.identRef}--${element.identRef}`;
    return (
      <ReduxControl
        key={`group-item-${key}`}
        identRef={element.identRef}
        deviceInstanceId={deviceInstanceId}
        readonly={readonly}
        onStateChanged={(ident, state) => updateMenuItemState(ident, state)}
      />
    );
  });

  return (
    <ParameterGroupControl label={t<string>(descriptor.label)}>
      {
        descriptor.description !== ''
        && <div className="parameter-group-description">{t<string>(descriptor.description)}</div>
      }
      {structureControl}
    </ParameterGroupControl>
  );
};

export default withControlVisibility(ParameterGroupReduxControl);
