/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import { useContextActiveDeviceInstance } from '../../store/deviceInstances/hooks/ActiveDeviceHooks';
import './OfflineHeader.scss';

export interface OfflineHeaderProps {
  deviceInstanceId: string;
}

const OfflineHeader: React.FC<OfflineHeaderProps> = (props: OfflineHeaderProps)
: React.ReactElement => {
  const { t } = useTranslation();
  const { deviceInstanceId } = props;
  const instance = useContextActiveDeviceInstance(deviceInstanceId);

  const productImage = instance ? instance.device.catalog.productImage : undefined;
  const deviceTypeName = instance ? instance.device.catalog.deviceTypeName : undefined;
  const deviceFamily = instance ? t(instance.device.catalog.deviceFamily) : '';
  const productURL = instance ? t(instance.device.catalog.productURL) : undefined;
  const productOrderNumber = instance ? instance.device.catalog.productOrderNumber : undefined;
  const firmwareVersion = instance ? instance.device.catalog.firmwareVersion : undefined;

  return (
    <div className="offline-header-container__container" data-testid="offline-header">
      <div className="offline-header-container__image d-flex justify-content-center align-items-center">
        <Image
          src={productImage}
          alt={deviceTypeName}
          className="offline-header-container__image--fit-image"
        />
      </div>
      <div className="offline-header-container__heading">
        <p className="offline-header-container__heading-font">{deviceFamily}</p>
      </div>
      <div className="offline-header-container__one d-flex flex-column flex-nowrap justify-content-start align-items-start">
        <p className="offline-header-container__label-font">{deviceTypeName ?? ''}</p>
        <p className="offline-header-container__label-font">
          {`${t<string>('HEADER__ORDER_NUMBER')}: `}
          <a
            href={productURL}
            target="_blank"
            rel="noreferrer"
          >
            <span className="external-link ml-2">{productOrderNumber}</span>
          </a>
        </p>
      </div>
      <div className="offline-header-container__two">
        <div className="d-flex flex-row flex-nowrap align-items-start">
          <div className="d-flex flex-column align-items-end mr-1">
            <p className="offline-header-container__label-font">{`${t<string>('HEADER__FIRMWARE_VERSION')}: `}</p>
          </div>
          <div className="d-flex flex-column align-items-start ml-1">
            <p className="offline-header-container__value-font">{firmwareVersion}</p>
          </div>
        </div>
      </div>
      <div className="offline-header-container__three" />
      <div className="offline-header-container__status d-flex flex-column align-items-center" />
    </div>
  );
};

export default OfflineHeader;
