/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { MenuItemData, menuItemsSelector, compareTabData } from '../../../controls/selectors/menuItemsSelector';
import { useTypedSelector } from '../../../store';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetState } from '../../../store/deviceInstances/store/deviceDataset/types';
import { DatasetContext } from '../../../views/DatasetContext';

const settingsWizardSelector = (state: DatasetState): DeviceModelStatus.Wizards.DeviceSettingsType | undefined => {
  const wizardDesc = state?.descriptors.WIZARD_DEVICESETTINGS;

  if (wizardDesc === undefined || wizardDesc.type !== DeviceModelStatus.StatusType.WizardDescriptor
      || wizardDesc.wizardType.type !== DeviceModelStatus.Wizards.WizardType.DEVICESETTINGS) {
    return undefined;
  }

  return wizardDesc.wizardType;
};

const useWizardMenuItems = (deviceInstanceId: string, itemName: 'display' | 'menu'): MenuItemData[] => {
  const { targetDataset } = useContext(DatasetContext);

  const wizardData: MenuItemData[] = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (deviceDataset === undefined) {
      return [];
    }

    const wizardDescType = settingsWizardSelector(deviceDataset);

    if (wizardDescType === undefined) {
      return [];
    }

    return menuItemsSelector(deviceDataset, wizardDescType[itemName]);
  }, (p, c) => compareTabData(p, c));

  return wizardData;
};

export default useWizardMenuItems;
