/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IdentRef } from '@gpt/commons';
import ModeSelectorControl from './ModeSelectorControl/ModeSelectorControl';
import AnalogStartEndControl from './AnalogStartEndControl/AnalogStartEndControl';
import './Quint4AnalogSignal.scss';
import useAnalogLimitValues from './hooks/useAnalogLimitValues';
import { UnsupportedControl } from '../../../components/UnsupportedControl';
import { useContextDatasetStatusValue, useContextDatasetStatusDescriptor } from '../../../hooks/useContextDataset';
import { setContextParameterHelp } from '../../../store/contexthelp';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import Quint4DigitalResultDropDown from '../Quint4DigitalSignal/DropDown/DigitalResultDropDown/DigitalResultDropDown';
import { writeActiveDeviceVariableValues } from '../../../store';
import { StructureItemStateChangeCallback } from '../../ReduxControlProps';
import useStatusValueStateUpdate from '../hooks/useStatusValueStateUpdate';

export interface Quint4AnalogSignalProps {
  target?: DatasetType;
  signalSelectorIdentRef: IdentRef;
  analogStartValueIdentRef: IdentRef;
  analogEndValueIdentRef: IdentRef;
  outCombiInvertIdentRef: IdentRef;
  deviceInstanceId: string;
  onStateChanged: StructureItemStateChangeCallback;
}

export const Quint4AnalogSignalControl: React.FC<Quint4AnalogSignalProps> = (props: Quint4AnalogSignalProps)
  : React.ReactElement => {
  const {
    target,
    deviceInstanceId,
    signalSelectorIdentRef,
    analogStartValueIdentRef,
    analogEndValueIdentRef,
    outCombiInvertIdentRef,
    onStateChanged,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const signalSelectorStatusValue = useContextDatasetStatusValue(deviceInstanceId, signalSelectorIdentRef, target);
  const signalSelectorDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, signalSelectorIdentRef, target);
  useStatusValueStateUpdate(signalSelectorIdentRef, deviceInstanceId, target, onStateChanged);

  const startValueStatusValue = useContextDatasetStatusValue(deviceInstanceId, analogStartValueIdentRef, target);
  const startValueDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, analogStartValueIdentRef, target);
  useStatusValueStateUpdate(analogStartValueIdentRef, deviceInstanceId, target, onStateChanged);

  const endValueStatusValue = useContextDatasetStatusValue(deviceInstanceId, analogEndValueIdentRef, target);
  const endValueDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, analogEndValueIdentRef, target);
  useStatusValueStateUpdate(analogEndValueIdentRef, deviceInstanceId, target, onStateChanged);

  const resultDropDownValue = useContextDatasetStatusValue(deviceInstanceId, outCombiInvertIdentRef, target);

  const onModeChangeHandler = useAnalogLimitValues(deviceInstanceId);

  if (signalSelectorDescriptor === undefined) {
    return <UnsupportedControl text={`Quint4AnalogSignalControl: Undefined object ${signalSelectorIdentRef}`} />;
  }

  if (startValueDescriptor === undefined) {
    return <UnsupportedControl text={`Quint4AnalogSignalControl: Undefined object ${analogStartValueIdentRef}`} />;
  }

  if (endValueDescriptor === undefined) {
    return <UnsupportedControl text={`Quint4AnalogSignalControl: Undefined object ${analogEndValueIdentRef}`} />;
  }

  return (
    <div className="quint4-analog-signal">
      <ModeSelectorControl
        deviceInstanceId={deviceInstanceId}
        statusDescriptor={signalSelectorDescriptor}
        statusValue={signalSelectorStatusValue}
        onShowContentHelp={() => {
          dispatch(setContextParameterHelp({
            title: signalSelectorDescriptor.label === undefined ? '' : t<string>(signalSelectorDescriptor.label),
            text: signalSelectorDescriptor.help === undefined ? '' : t<string>(signalSelectorDescriptor.help),
          }));
        }}
        onHideContentHelp={() => {
          dispatch(setContextParameterHelp({
            title: '',
            text: '',
          }));
        }}
        onChangeHandler={(mode: number) => {
          onModeChangeHandler(mode, signalSelectorStatusValue, startValueStatusValue, endValueStatusValue);
        }}
      />
      <AnalogStartEndControl
        deviceInstanceId={deviceInstanceId}
        statusDescriptor={startValueDescriptor}
        statusValue={startValueStatusValue}
      />
      <AnalogStartEndControl
        deviceInstanceId={deviceInstanceId}
        statusDescriptor={endValueDescriptor}
        statusValue={endValueStatusValue}
      />
      <Quint4DigitalResultDropDown
        identRef={outCombiInvertIdentRef}
        deviceInstanceId={deviceInstanceId}
        onStateChanged={onStateChanged}
        onValueChange={(value) => {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            value,
            backupValue: resultDropDownValue?.backupValue,
            identRef: outCombiInvertIdentRef,
          }]));
        }}
      />
    </div>
  );
};
