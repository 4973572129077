/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import {
  DeviceModelStatus, IdentRef, Services,
} from '@gpt/commons';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector, deviceWizardUpdateButtons, disposeDevice } from '../../../store';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import { deviceWizardInitButtons, deviceWizardSetButtons } from '../../../store/deviceWizard';
import ReduxControl from '../../../controls/ReduxControl/ReduxControl';
import { closeCxeDeviceParameterizationApp } from '../../../services/clipxEngineerIntegrationService';
import { createDatasetIdent } from '../../../helpers/functions';
import { LoadingControl } from '../../../components/LoadingControl';
import { compareTabData, MenuItemData, menuItemsSelector } from '../../../controls/selectors/menuItemsSelector';
import { DatasetContext } from '../../../views/DatasetContext';
import WizardViewPage from '../../WizardView/WizardViewPage';
import WizardView from '../../WizardView/WizardView';
import { DatasetState } from '../../../store/deviceInstances/store/deviceDataset/types';
import { deviceDatasetSelector, deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';
import { WizardProps } from '../../types';
import { deviceInstanceActiveDeviceInstance } from '../../../store/deviceInstances/store/activeDevice/selectors';
import createDeviceDatasetBackupContent from '../../../helpers/createDeviceDatasetBackupContent';
import { WebWorkerContext } from '../../../WebWorkerContext';
import useWizardPageState from '../hooks/useWizardPageState';

export const GetWizardType = (state: DatasetState): DeviceModelStatus.Wizards.CxeHardwareSwitchType | undefined => {
  const wizardDesc = state.descriptors?.WIZARD_CXE_HARDWARE_SWITCH;

  if (wizardDesc?.type !== DeviceModelStatus.StatusType.WizardDescriptor) {
    return undefined;
  }

  if (wizardDesc.wizardType.type !== DeviceModelStatus.Wizards.WizardType.CXE_HARDWARE_SWITCH) {
    return undefined;
  }

  return wizardDesc.wizardType;
};

export const GetWizardMenu = (state: DatasetState): IdentRef | undefined => {
  const wizardType = GetWizardType(state);
  return wizardType?.menu;
};

export const GetWizardPreviewMenu = (state: DatasetState): IdentRef | undefined => {
  const wizardType = GetWizardType(state);
  return wizardType?.preview;
};

const EditParameterDipSwitchCxeWizard: React.FC<WizardProps> = (props: WizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deviceInstanceId } = props;
  const { targetDataset } = useContext(DatasetContext);
  const [pageStates, setPageState] = useWizardPageState();

  const { webWorkerDeviceManager } = useContext(WebWorkerContext);
  const activeDeviceInstance = useTypedSelector((state) => deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstanceId));

  const previewMenu = useTypedSelector((state) => {
    const dataset = deviceDatasetSelector(state.deviceInstances, deviceInstanceId);
    if (dataset === undefined) {
      return undefined;
    }
    return GetWizardPreviewMenu(dataset[targetDataset]);
  });

  const wizardData: MenuItemData[] = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (deviceDataset === undefined) {
      return [];
    }
    const rootMenuIdent = GetWizardMenu(deviceDataset);
    return menuItemsSelector(deviceDataset, rootMenuIdent);
  }, (p, c) => compareTabData(p, c));
  const wizardDataLen = wizardData.length;

  useEffect(() => {
    dispatch(deviceWizardInitButtons({
      abort: ButtonState.enabled,
      back: ButtonState.disabled,
      next: ButtonState.disabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.next,
    }));
  }, []);

  useEffect(() => {
    dispatch(deviceWizardSetButtons({
      abort: { state: ButtonState.enabled },
      back: { state: ButtonState.disabled },
      next: wizardDataLen > 1 ? { state: ButtonState.enabled } : { state: ButtonState.disabled },
      complete: wizardDataLen > 1 ? { state: ButtonState.hidden } : { state: ButtonState.enabled },
      pcta: wizardDataLen > 1 ? ButtonRole.next : ButtonRole.complete,
    }));
  }, [wizardDataLen]);

  if (activeDeviceInstance === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  const pages = wizardData
    .map((element, index) => (
      <WizardViewPage
        key={`wiz-cxe-edit-page--${element.identRef}`}
        title={t<string>(element.label)}
        state={pageStates[element.identRef]}
        onChangeToNextPage={() => {
          const isLastDevicePage = (index === wizardData.length - 1);
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: isLastDevicePage ? ButtonState.hidden : ButtonState.enabled,
            complete: isLastDevicePage ? ButtonState.enabled : ButtonState.hidden,
            pcta: isLastDevicePage ? ButtonRole.complete : ButtonRole.next,
          }));
        }}
        onChangeToPreviousPage={() => {
          const isFirstDevicePage = (index === 0);
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: isFirstDevicePage ? ButtonState.disabled : ButtonState.enabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
      >
        <ReduxControl
          identRef={element.identRef}
          deviceInstanceId={deviceInstanceId}
          onStateChanged={(ident, pagestate) => setPageState(ident, pagestate)}
        />
      </WizardViewPage>
    ));

  return (
    <WizardView
      title={t<string>('WIZARD_EDIT_HARDWARE_SWITCH__TITLE')}
      onWizardExit={async (mode: ButtonRole) => {
        if (mode === ButtonRole.abort) {
          closeCxeDeviceParameterizationApp({
            result: 'cancelled',
          });
        } else {
          const parameterDataSet = await createDeviceDatasetBackupContent({
            caption: 'Hardware Switch Configuration',
            datasetId: createDatasetIdent('parameterization'),
            activeDevice: activeDeviceInstance,
            datasetType: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType.HARDWARE_SWITCH,
            previewMenu,
            webWorkerDeviceManager,
          });

          closeCxeDeviceParameterizationApp({
            result: 'success',
            parameterDataSet,
          });
        }
        dispatch(disposeDevice(deviceInstanceId));
      }}
    >
      {pages}
    </WizardView>
  );
};

export default EditParameterDipSwitchCxeWizard;
