/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceInformation } from '@gpt/commons';
import React, { useState } from 'react';
import { DeviceCard } from '../DeviceCards/DeviceCard/DeviceCard';
import './WizardDeviceList.scss';

export interface DeviceListItem {
  device: DeviceInformation;
  selectable: boolean;
  busy: boolean;
  supported: boolean;
}

export interface WizardDeviceListProps {
    deviceList: DeviceListItem[];
    alignRight?: boolean;
    // eslint-disable-next-line no-unused-vars
    onActivate: (device: DeviceInformation) => void;
}

const WizardDeviceList: React.FC<WizardDeviceListProps> = (props: WizardDeviceListProps)
: React.ReactElement => {
  const { deviceList, onActivate, alignRight } = props;
  const [selectedDeviceId, setSelectedDeviceId] = useState('');

  const actDeviceList = deviceList.map((listItem) => {
    const { catalog, instance } = listItem.device;
    const isPlaceholder = !listItem.device.instance;
    // DeviceId || DeviceDriverId → DeviceType, i.e. "01.04.0F.0E.0020"
    let deviceType = (instance?.deviceId || catalog?.deviceDriverId || '');
    deviceType = deviceType?.substring(0, 16)?.toUpperCase()?.replace(/-/g, '.');
    // build tooltip string with product OrderCode and DeviceType IDs…
    const tooltip = `OrderCode: ${catalog.productOrderNumber}\nID: ${deviceType}`;

    return (
      <div
        className="p-1"
        key={instance?.deviceId || catalog.deviceDriverId}
        title={tooltip}
      >
        <DeviceCard
          size="main"
          selectable={!isPlaceholder && listItem.selectable}
          selected={selectedDeviceId === instance?.deviceId}
          device={listItem.device}
          busy={listItem.busy}
          supported={listItem.supported}
          onClick={() => {
            setSelectedDeviceId(instance?.deviceId ?? '');
            onActivate(listItem.device);
          }}
        />
      </div>
    );
  });

  const justifyContent = (alignRight ?? false) ? 'justify-content-end' : 'justify-content-start';
  return (
    <div className={`WizardDeviceList d-flex flex-row ${justifyContent} align-items-center m-0`}>
      {actDeviceList}
    </div>
  );
};

export default WizardDeviceList;
