/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import {
  ReportServiceActionTypes,
  REPORT_SERVICE__CREATE_REPORT,
  REPORT_SERVICE__SET_REPORT_STATUS,
  ReportStatus,
  CreateReportPayload,
} from './types';

export const createReport = (payload: CreateReportPayload)
  : ReportServiceActionTypes => ({
  type: REPORT_SERVICE__CREATE_REPORT,
  payload,
});

export const setReportStatus = (payload: ReportStatus)
  : ReportServiceActionTypes => ({
  type: REPORT_SERVICE__SET_REPORT_STATUS,
  payload,
});
