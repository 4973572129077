/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import React from 'react';
import ParameterControl from './ParameterControl';
import './SelectEditorControl.css';
import BasicSelectEditor from './Basic/BasicSelectEditor/BasicSelectEditor';

export interface SelectEditorControlProps {
    label?: string;
    value?: number;
    help?: string;
    options: { [val: number]: string };
    warning?: string;
    unit?: string;
    edited?: boolean;
    readonly?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onShowContentHelp?: () => void;
    onHideContentHelp?: () => void;
  }

const SelectEditorControl: React.FC<SelectEditorControlProps> = (props: SelectEditorControlProps):
React.ReactElement => {
  const {
    label, value, options, warning, unit, onChange, edited, help, readonly,
    onShowContentHelp, onHideContentHelp,
  } = props;

  return (
    <ParameterControl
      label={label}
      unit={unit}
    >
      <BasicSelectEditor
        onChange={onChange}
        options={options}
        edited={edited}
        onHideContentHelp={onHideContentHelp}
        onShowContentHelp={onShowContentHelp}
        readonly={readonly}
        value={value}
        warning={warning}
      />
    </ParameterControl>
  );
};

export default SelectEditorControl;
