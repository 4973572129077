/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useDispatch } from 'react-redux';
import { ButtonFunction, CompareStatusValueProps } from '../../Compare/CompareTypes';
import { createQuint4SignalCompareVariableArray, createQuint4SignalWriteVariables } from '../../Quint4/Quint4DigitalSignal/types';
import CompareQuint4StatusValue from '../CompareValue/CompareQuint4Value';
import { CompareModeType } from '../../../views/CompareView/common';
import compareSignalOutputMembers from '../hooks/compareSignalOutputMembers';
import { useTypedSelector } from '../../../store';
import { writeCompareValues } from '../../../store/compareView/actions';

const displayOrderVarArray = createQuint4SignalCompareVariableArray(1);

const Quint4CompareSignalOut1: React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
: React.ReactElement | null => {
  const {
    identRef,
    deviceInstanceId,
    leftDataset,
    rightDataset,
    compareMode,
    parentId,
    onShowContentHelp,
    onHideContentHelp,
  } = props;

  const dispatch = useDispatch();
  const blockEqual = useTypedSelector((state) => {
    const dataset = state.deviceInstances.instances[deviceInstanceId].deviceDataset;
    return compareSignalOutputMembers(dataset, displayOrderVarArray, leftDataset, rightDataset);
  });
  if (blockEqual && compareMode === CompareModeType.ShowDifference) {
    return (null);
  }

  const middleButtonFunction = blockEqual ? ButtonFunction.Hidden : ButtonFunction.WriteValue;

  const controlList = displayOrderVarArray.map((varIdent, index) => (
    <CompareQuint4StatusValue
      key={`${parentId}-${identRef}---${varIdent}`}
      deviceInstanceId={deviceInstanceId}
      equal={blockEqual}
      compareMode={compareMode}
      identRef={varIdent}
      leftDataset={leftDataset}
      hideLeftControl={false}
      rightDataset={rightDataset}
      hideRightControl={false}
      onShowContentHelp={onShowContentHelp}
      onHideContentHelp={onHideContentHelp}
      readonly
      buttonFunction={middleButtonFunction}
      onMidButtonClick={() => {
        if (!blockEqual) {
          const variableList = createQuint4SignalWriteVariables(1);
          dispatch(writeCompareValues(deviceInstanceId, {
            items: variableList,
            sourceDataset: rightDataset,
            targetDataset: leftDataset,
          }));
        }
      }}
      showImageAndSign={index === 0}
    />
  ));

  return (
    <>{controlList}</>
  );
};

export default Quint4CompareSignalOut1;
