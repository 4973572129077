/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import TrendViewReduxControl from '../../../TrendViewControl/TrendViewReduxControl/TrendViewReduxControl';
import OnlineCardControl from '../OnlineCardControl/OnlineCardControl';

export interface SystemTrendOnlineCardProps {
  deviceInstanceId: string;
  trendView?: DeviceModelStatus.UI.Contactron.MotorStarterTrendView;
}

const SystemTrendOnlineCard: React.FC<SystemTrendOnlineCardProps> = (props: SystemTrendOnlineCardProps):
  React.ReactElement => {
  const {
    trendView,
    deviceInstanceId,
  } = props;

  return (
    <OnlineCardControl label={trendView?.label ?? ''}>
      <TrendViewReduxControl identRef={trendView?.control ?? 'unknown'} deviceInstanceId={deviceInstanceId} onStateChanged={() => 0} readonly />
    </OnlineCardControl>
  );
};

export default SystemTrendOnlineCard;
