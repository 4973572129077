/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTypedSelector } from '../../../../store';
import { deviceDescriptorSelector } from '../../../../store/deviceInstances/store/deviceDataset/selector';
import { getRangeBounds } from '../../../../helpers/functions';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';

export interface TrendViewItem {
  identRef: IdentRef;
  color: string;
}

export interface TrendVariable {
    identRef: IdentRef;
    label: string;
    color: string;
    range?: DeviceModelStatus.StatusDescriptorValueRange;
}

const getVarRange = (trendElement: DeviceModelStatus.StatusDescriptor)
: DeviceModelStatus.StatusDescriptorValueRange | undefined => {
  const ranges = (
    (trendElement.valueType.type === DeviceModelStatus.StatusDescriptorValueType.INTEGER)
    || (trendElement.valueType.type === DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER))
    || (trendElement.valueType.type === DeviceModelStatus.StatusDescriptorValueType.FLOAT)
    ? trendElement.valueType.range : undefined;
  return ranges ? getRangeBounds(ranges) : undefined;
};

const compareTrendVariableRange = (
  prev?: DeviceModelStatus.StatusDescriptorValueRange,
  cur?: DeviceModelStatus.StatusDescriptorValueRange,
): boolean => (prev?.minValue === cur?.minValue && prev?.maxValue === cur?.maxValue);

const compareTrendVariables = (prev: TrendVariable[], cur: TrendVariable[]): boolean => {
  if (prev.length !== cur.length) {
    return false;
  }
  const uneq = cur.find((item, idx) => item.identRef !== prev[idx].identRef
    || item.color !== prev[idx].color
    || item.label !== prev[idx].label
    || !compareTrendVariableRange(item.range, prev[idx].range));
  return uneq === undefined;
};

const useTimelineVariables = (
  deviceInstanceId: string,
  targetDataset: DatasetType,
  trendVars: TrendViewItem[],
): TrendVariable[] => {
  const timelineVars: TrendVariable[] = useTypedSelector((state) => trendVars
    .reduce((acc, item) => {
      const descriptor = deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, item.identRef);
      if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
        return acc;
      }
      return [
        ...acc,
        {
          identRef: item.identRef,
          color: item.color,
          label: descriptor.label,
          range: getVarRange(descriptor),
        },
      ];
    }, [] as TrendVariable[]), (prev, curr) => compareTrendVariables(prev, curr));

  return timelineVars;
};
export default useTimelineVariables;
