/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
// eslint-disable-next-line import/no-cycle
import './ChannelStatusTrioControl.css';

export interface ChannelStatusControlProps {
    title: string,
    nomVariableTitle: string,
    variableLabel: string,
    currentValue: string;
    unit?: string;
    colorValue: number;
}

export const ChannelStatusTrioControl: React.FC<ChannelStatusControlProps> = (
  props: ChannelStatusControlProps,
): React.ReactElement => {
  const {
    title, nomVariableTitle, variableLabel, unit, currentValue, colorValue,
  } = props;

  const blinkClasses = colorValue > 3 ? '-1-4Hz' : '';
  const color = `channel-status-trio__color-${colorValue}${blinkClasses}`;
  const classes = `channel-status-trio__container-rectangle ${color}`;
  const label = `${variableLabel}: `;

  return (
    <div className="channel-status-trio__container">
      <div className={classes} />
      <div className="channel-status-trio__title">
        {
            title && (
            <div className="channel-status-trio__title-channel">
              {title}
            </div>
            )
        }
        <div className="channel-status-trio__title-current">
          <div className="channel-status-trio__title-label">{nomVariableTitle}</div>
          <div className="channel-status-trio__title-value-unit">
            <div className="channel-status-trio__title-value-unit_label">{label}</div>
            <div className="channel-status-trio__title-value-unit_value">{currentValue}</div>
            <div className="channel-status-trio__title-value-unit_unit">{unit}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
