/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CTAButton } from '../../../../../../components/CTAButton/CTAButton';
import './StationAddressingButton.scss';

export interface StationAddressingButtonProps {
    onClick: () => void;
    disabled: boolean;
}

const StationAddressingFinishButton: React.FC<StationAddressingButtonProps> = (props: StationAddressingButtonProps)
: React.ReactElement => {
  const { t } = useTranslation();
  const { onClick, disabled } = props;

  return (
    <div className="page-station-addressing__button">
      <div className="page-station-addressing-button">
        <div className="page-station-addressing-button__text">
          {t('WIZARD_CONTACTRON__STATION_ADDRESS__PRESS_FINISH_BUTTON')}
        </div>
        <div className="page-station-addressing-button__button">
          <CTAButton
            caption={t<string>('WIZARD_BUTTON__COMPLETE')}
            variant={disabled ? 'secondary-small' : 'primary-small'}
            onClick={onClick}
            disabled={disabled}
            focus
          />
        </div>
      </div>
    </div>
  );
};

export default StationAddressingFinishButton;
