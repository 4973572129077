/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../reduxStore';
import { requestDeviceResource } from '../middleware/resourceProvider/actions';
import { DeviceInstancesState } from '../types';
import { ResourceLoadingImage } from '../../../helpers/resourceImages';

const resourceDataUrlSelector = (
  state: DeviceInstancesState,
  resourceId: string,
  deviceInstanceId: string | undefined,
): string => {
  const { instances } = state;
  if (deviceInstanceId === undefined) {
    return ResourceLoadingImage;
  }
  // eslint-disable-next-line max-len
  const dataUrl = instances[deviceInstanceId]?.resourceProvider[resourceId]?.resourceDataUrl;
  if (dataUrl === undefined || dataUrl === '') {
    return ResourceLoadingImage;
  }
  return dataUrl;
};

// eslint-disable-next-line import/prefer-default-export
export const useContextResourceProvider = (deviceInstanceId: string | undefined, i18nResId: string)
: string => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (deviceInstanceId === undefined) {
      return;
    }
    dispatch(requestDeviceResource(deviceInstanceId, i18nResId));
  }, [i18nResId, deviceInstanceId]);

  return useTypedSelector(
    (s) => resourceDataUrlSelector(s.deviceInstances, i18nResId, deviceInstanceId),
  );
};
