/* eslint-disable max-len */
import { DeviceModelStatus, Services } from '@gpt/commons';
import validateExternalDataset from '../../../../helpers/validateExternalDataset';
import { DeviceDatasetValueCollection } from '../../store/deviceDataset';
import { isModifiedStatusValues } from '../../../../helpers/precisionValue';

export interface LoadExternalDatasetResult {
  dataset?: Services.DeviceModelServer.DatasetProviderService.ExternalDataset;
  errorMessage?: string;
}

export const parseExternalDataset = async (filename: string, fileContents: string): Promise<LoadExternalDatasetResult> => {
  let parameterDataset: Services.DeviceModelServer.DatasetProviderService.ExternalDataset;
  try {
    parameterDataset = JSON.parse(fileContents);
  } catch (error) {
    return { errorMessage: `${error}` || `Failed to parse JSON content of file ${filename}` };
  }

  try {
    await validateExternalDataset(parameterDataset);
  } catch (error) {
    return { errorMessage: `${error}` || `Content validation failed for file ${filename}` };
  }

  return { dataset: parameterDataset };
};

const GetScaleFactor = (descriptor?: DeviceModelStatus.DeviceModelDescriptor): number | undefined => {
  if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }
  const { valueType } = descriptor;
  if (valueType.type === DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
    const scale = valueType.scaleFactor ?? 1.0;
    return scale === 0 ? 1.0 : scale;
  }
  return undefined;
};

export const prepareDatasetFromExternalValues = (
  externalValues: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetValues,
  datasetValues: DeviceDatasetValueCollection,
  descriptors: DeviceModelStatus.DeviceModelDescriptorCollection,
): Services.DeviceModel.StatusValueRef[] => {
  const newValues: Services.DeviceModel.StatusValueRef[] = Object
    .keys(externalValues)
    .filter((identRef) => datasetValues[identRef] !== undefined)
    .map((identRef) => {
      const descriptor = descriptors[identRef];
      const scaleFactor = GetScaleFactor(descriptor);

      const value = scaleFactor === undefined
        ? externalValues[identRef].value
        : externalValues[identRef].value / scaleFactor;

      const modified = isModifiedStatusValues(descriptor, value, datasetValues[identRef].value);

      return {
        identRef,
        value,
        backupValue: undefined,
        modified,
      };
    });
  return newValues;
};
