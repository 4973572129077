import React from 'react';
import { Button } from 'react-bootstrap';
import { ButtonState } from '../../../../store/deviceWizard';
import './WizardButton.scss';

export interface WizardButtonProps {
  state: ButtonState,
  label: string,
  pca: boolean,
  onClick: () => void,
}

const WizardButton: React.FC<WizardButtonProps> = (props: WizardButtonProps)
: React.ReactElement => {
  const {
    state, label, pca, onClick,
  } = props;
  if (state === ButtonState.hidden) {
    return <></>;
  }

  const buttonVariant = () => {
    // Check if all buttons must be disabled
    if (state === ButtonState.disabled) {
      return 'deactivated';
    }
    return pca ? 'primary' : 'secondary';
  };

  // set the appearance of a button (individual colors and optionally deactivated)
  const variant = buttonVariant();

  return (
    <Button
      className="edit-parameter-selector__button-text"
      variant={variant}
      disabled={state === ButtonState.disabled}
      onClick={state === ButtonState.enabled ? () => onClick() : () => 0}
    >
      {label}
    </Button>
  );
};

export default WizardButton;
