/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  deviceWizardUpdateButtons,
  disposeDevice,
  ExecutionState,
  setActiveDeviceExecutionStatus,
  useTypedSelector,
} from '../../store';
import { ButtonRole, ButtonState } from '../../store/deviceWizard/types';
import ReduxControl from '../../controls/ReduxControl/ReduxControl';
import { deviceWizardInitButtons } from '../../store/deviceWizard';
import { StructureItems } from '../../controls/ReduxControlProps';
import updateWizardPageState from '../helper';
import Page1SelectDevice from './Page1SelectDevice/Page1SelectDevice';
import Page4DocumentTransfer from './Page4DocumentTransfer/Page4DocumentTransfer';
import { MenuItemData } from '../../controls/selectors/menuItemsSelector';
import WizardView from '../WizardView/WizardView';
import WizardViewPage from '../WizardView/WizardViewPage';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { wizardReportMenuSelector } from '../../store/selectors/rootStructureSelector';
import { WizardProps } from '../types';
import useWizardMenuItems from './hooks/useWizardMenuItems';

const SettingDeviceWizard: React.FC<WizardProps> = (props: WizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deviceInstanceId } = props;
  const [pageStates, setPageState] = useState<StructureItems>({});

  useEffect(() => {
    dispatch(deviceWizardInitButtons({
      abort: ButtonState.enabled,
      back: ButtonState.disabled,
      next: ButtonState.disabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.next,
    }));
  }, []);

  const reportMenu = useTypedSelector((state) => wizardReportMenuSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, 'WIZARD_DEVICESETTINGS'));

  const wizardData: MenuItemData[] = useWizardMenuItems(deviceInstanceId, 'menu');

  const pages = wizardData
    .map((element, index) => (
      <WizardViewPage
        key={`wiz-dip-page--${element.identRef}`}
        title={t(element.label)}
        state={pageStates[element.identRef]}
        onChangeToNextPage={() => {
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
        onChangeToPreviousPage={() => {
          const isFirstDevicePage = (index === 0);
          if (isFirstDevicePage) {
            dispatch(setActiveDeviceExecutionStatus(deviceInstanceId, ExecutionState.init));
            dispatch(disposeDevice(deviceInstanceId));
          }
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: isFirstDevicePage ? ButtonState.disabled : ButtonState.enabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
      >
        <ReduxControl
          identRef={element.identRef}
          deviceInstanceId={deviceInstanceId}
          onStateChanged={(ident, pagestate) => setPageState((state) => updateWizardPageState(state, ident, pagestate))}
        />
      </WizardViewPage>
    ));

  const wizardData2: MenuItemData[] = useWizardMenuItems(deviceInstanceId, 'display');

  const pages2 = wizardData2
    .map((element) => (
      <WizardViewPage
        key={`wiz-dip-page--${element.identRef}`}
        title={t(element.label)}
        state={pageStates[element.identRef]}
        onChangeToNextPage={() => {
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: ButtonState.hidden,
            complete: ButtonState.enabled,
            pcta: ButtonRole.complete,
            focusPcta: ButtonRole.complete,
          }));
        }}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.enabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
      >
        <ReduxControl
          identRef={element.identRef}
          deviceInstanceId={deviceInstanceId}
          onStateChanged={(ident, pagestate) => setPageState((state) => updateWizardPageState(state, ident, pagestate))}
          readonly
        />
      </WizardViewPage>
    ));

  return (
    <WizardView
      title={t<string>('WIZARD_SETTING_DEVICE__TITLE')}
      onWizardExit={() => {
        dispatch(disposeDevice(deviceInstanceId));
      }}
    >
      <Page1SelectDevice
        title={t<string>('WIZARD_SETTING_DEVICE__CUSTOM_PAGE_1__TITLE')}
        deviceInstanceId={deviceInstanceId}
        onChangeToNextPage={() => dispatch(deviceWizardUpdateButtons({
          abort: ButtonState.enabled,
          back: ButtonState.enabled,
          next: ButtonState.enabled,
          complete: ButtonState.hidden,
          pcta: ButtonRole.next,
        }))}
      />
      {pages}
      {pages2}
      <Page4DocumentTransfer
        title={t<string>('WIZARD_SETTING_DEVICE__CUSTOM_PAGE_4__TITLE')}
        deviceInstanceId={deviceInstanceId}
        reportMenu={reportMenu}
      />
    </WizardView>
  );
};

export default SettingDeviceWizard;
