/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';

export interface LineToDivProps {
  children?: React.ReactNode;
}

const LineToDiv: React.FC<LineToDivProps> = (props: LineToDivProps): React.ReactElement => {
  const { children } = props;
  const data = children?.toString()
    .split(/\r?\n/)
    .map((line) => (
      <div key={line}>
        {line}
      </div>
    ));
  return <>{data}</>;
};

export { LineToDiv };
