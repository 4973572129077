/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { IdentRef } from '@gpt/commons';
import {
  TIMELINE__REQUEST_DATA,
  typeTimelineDataMiddlewareActionTypes,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const requestTimelineData = (targetInstance: string, identList: IdentRef[])
  : typeTimelineDataMiddlewareActionTypes => ({
  type: TIMELINE__REQUEST_DATA,
  payload: {
    targetInstance,
    identList,
  },
});
