/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { DeviceInstancesState } from '../../types';
import { DatasetType } from './types';
import { deviceTargetDatasetSelector } from '../dataStorage/selectors';

export const deviceDescriptorSelector = (state: DeviceInstancesState, deviceInstanceId: string | undefined, targetDataset: DatasetType, identRef: IdentRef)
: DeviceModelStatus.DeviceModelDescriptor | undefined => {
  const deviceDataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  return deviceDataset?.descriptors[identRef];
};

export const deviceStatusValueSelector = (state: DeviceInstancesState, deviceInstanceId: string | undefined, targetDataset: DatasetType, identRef: IdentRef)
  : DeviceModelStatus.StatusValue | undefined => {
  const deviceDataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  return deviceDataset?.values[identRef];
};

export const deviceStatusDescriptorSelector = (state: DeviceInstancesState, deviceInstanceId: string | undefined, targetDataset: DatasetType, identRef: IdentRef)
  : DeviceModelStatus.StatusDescriptor | undefined => {
  const deviceDataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const descriptor = deviceDataset?.descriptors[identRef];
  return descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor
    ? undefined
    : descriptor;
};

export const deviceStructureDescriptorSelector = (state: DeviceInstancesState, deviceInstanceId: string | undefined, targetDataset: DatasetType, identRef: IdentRef)
  : DeviceModelStatus.StructureDescriptor | undefined => {
  const deviceDataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const descriptor = deviceDataset?.descriptors[identRef];
  return descriptor?.type !== DeviceModelStatus.StatusType.StructureDescriptor
    ? undefined
    : descriptor;
};
