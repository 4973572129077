/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Resource } from 'i18next';

import i18nCAPAROCen from './CAPAROC_en.json';
import i18nCAPAROCde from './CAPAROC_de.json';
import i18nCAPAROCEs from './CAPAROC_es.json';
import i18nCAPAROCFr from './CAPAROC_fr.json';
import i18nCAPAROCIt from './CAPAROC_it.json';
import i18nCAPAROCJa from './CAPAROC_ja.json';
import i18nCAPAROCPl from './CAPAROC_pl.json';
import i18nCAPAROCPt from './CAPAROC_pt.json';
import i18nCAPAROCRu from './CAPAROC_ru.json';
import i18nCAPAROCTr from './CAPAROC_tr.json';
import i18nCAPAROCZh from './CAPAROC_zh.json';
import i18nCAPAROCCs from './CAPAROC_cs.json';
import i18nCAPAROCDa from './CAPAROC_da.json';
import i18nCAPAROCFi from './CAPAROC_fi.json';
import i18nCAPAROCHu from './CAPAROC_hu.json';
import i18nCAPAROCKo from './CAPAROC_ko.json';
import i18nCAPAROCNb from './CAPAROC_nb.json';
import i18nCAPAROCNl from './CAPAROC_nl.json';
import i18nCAPAROCRo from './CAPAROC_ro.json';
import i18nCAPAROCSv from './CAPAROC_sv.json';
import i18nCAPAROCUk from './CAPAROC_uk.json';

/**
 * Default translation for application
 */
const bundledResources: Resource = {
  en: {
    CAPAROC: i18nCAPAROCen,
  },
  de: {
    CAPAROC: i18nCAPAROCde,
  },
  es: {
    CAPAROC: i18nCAPAROCEs,
  },
  fr: {
    CAPAROC: i18nCAPAROCFr,
  },
  it: {
    CAPAROC: i18nCAPAROCIt,
  },
  ja: {
    CAPAROC: i18nCAPAROCJa,
  },
  pl: {
    CAPAROC: i18nCAPAROCPl,
  },
  pt: {
    CAPAROC: i18nCAPAROCPt,
  },
  ru: {
    CAPAROC: i18nCAPAROCRu,
  },
  tr: {
    CAPAROC: i18nCAPAROCTr,
  },
  zh: {
    CAPAROC: i18nCAPAROCZh,
  },
  cs: {
    CAPAROC: i18nCAPAROCCs,
  },
  da: {
    CAPAROC: i18nCAPAROCDa,
  },
  fi: {
    CAPAROC: i18nCAPAROCFi,
  },
  hu: {
    CAPAROC: i18nCAPAROCHu,
  },
  ko: {
    CAPAROC: i18nCAPAROCKo,
  },
  nb: {
    CAPAROC: i18nCAPAROCNb,
  },
  nl: {
    CAPAROC: i18nCAPAROCNl,
  },
  ro: {
    CAPAROC: i18nCAPAROCRo,
  },
  sv: {
    CAPAROC: i18nCAPAROCSv,
  },
  uk: {
    CAPAROC: i18nCAPAROCUk,
  },
};
export default bundledResources;
