/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import {
  EXECUTE_DEVICE_UPLOAD_METHOD,
  EXECUTE_DEVICE_DOWNLOAD_METHOD,
  ExecuteDeviceDownloadMethodPayload,
  ExecuteDeviceDownloadDatasetMethodPayload,
  EXECUTE_DEVICE_DOWNLOAD_DATASET_METHOD,
  typeDeviceMethodMiddlewareActions,
} from './types';

export const executeDeviceUploadMethod = (
  targetInstance: string,
  methodIdent: DeviceModelStatus.MethodIdent,
  actionId: string,
): typeDeviceMethodMiddlewareActions => ({
  type: EXECUTE_DEVICE_UPLOAD_METHOD,
  payload: {
    targetInstance,
    methodIdent,
    actionId,
  },
});

export const executeDeviceDownloadMethod = (payload: ExecuteDeviceDownloadMethodPayload)
: typeDeviceMethodMiddlewareActions => ({
  type: EXECUTE_DEVICE_DOWNLOAD_METHOD,
  payload,
});

// eslint-disable-next-line max-len
export const executeDeviceDownloadDatasetMethod = (payload: ExecuteDeviceDownloadDatasetMethodPayload)
  : typeDeviceMethodMiddlewareActions => ({
  type: EXECUTE_DEVICE_DOWNLOAD_DATASET_METHOD,
  payload,
});
