/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect, useRef, useState } from 'react';
import Image from 'react-bootstrap/Image';
import arrowIcon from '../../assets/icons/icon-arrow-indicator-bottom.svg';
import expandIcon from '../../assets/icons/icon-expand.svg';
import DisplayFormat from '../../helpers/displayFormat';
import { DragIndicatorControl } from '../DragIndicatorControl/DragIndicatorControl';
import OverlayPortal from '../OverlayPortal/OverlayPortal';
import './BarGraphControlHorizontal.css';

export interface BargraphControlHorizontalProps {
  bargraphTitle: string;
  subtitle: string;
  centerUnit: string;
  currentValue: number;
  segmentStops: number[];
  showDragIndicator: boolean;
  displayFormat: string;
  segmentDisplayFormat: string;
  // eslint-disable-next-line no-unused-vars
  setShowDragIndicator: (value: boolean) => void;
}

interface BarRange {
  start: number;
  end: number;
}

export const BargraphControlHorizontalFigma: React.FC<BargraphControlHorizontalProps> = (props: BargraphControlHorizontalProps): React.ReactElement => {
  const MAX_BAR_WIDTH = 240;
  const {
    bargraphTitle, subtitle, centerUnit, currentValue, segmentStops,
    showDragIndicator, displayFormat, segmentDisplayFormat, setShowDragIndicator,
  } = props;

  const [dragIndicatorValue, setDragIndicatorValue] = useState({ minValue: Number.POSITIVE_INFINITY, maxValue: Number.NEGATIVE_INFINITY });
  const [showDragIndicatorMenu, setShowDragIndicatorMenu] = useState(false);
  const [segmentContainerWidth, setSegmentContainerWidth] = useState(0);
  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const showIndicatorButtonRef = useRef<HTMLDivElement>(null);
  const segmentsNumber = segmentStops.length - 1;
  const barRange: BarRange = {
    start: segmentStops[0],
    end: segmentStops[segmentsNumber],
  };

  if (currentValue < dragIndicatorValue.minValue) {
    setDragIndicatorValue((previousState) => ({
      minValue: currentValue,
      maxValue: previousState.maxValue,
    }));
  }

  if (currentValue > dragIndicatorValue.maxValue) {
    setDragIndicatorValue((previousState) => ({
      minValue: previousState.minValue,
      maxValue: currentValue,
    }));
  }

  const fitToSize = (baseFontSize: number): number => (baseFontSize / MAX_BAR_WIDTH) * segmentContainerWidth;

  const Round2Decimals = (value: number): number => (Math.round(value * 100) / 100);

  const scaleToRange = (value: number, range: BarRange): number => {
    if (value < range.start) {
      return range.start;
    }

    if (value > range.end) {
      return range.end;
    }

    return value;
  };

  const Laufweite = segmentStops[segmentsNumber] - segmentStops[0];
  const segments: number[] = [];
  for (let i = 0; i < segmentsNumber; i += 1) {
    const segment = (MAX_BAR_WIDTH / Laufweite) * (segmentStops[i + 1] - segmentStops[i]);
    segments.push(segment);
  }

  const segment1 = segments[0] ?? 0;
  const segment2 = segments[1] ?? 0;
  const segment3 = segments[2] ?? 0;
  const segment4 = segments[3] ?? 0;
  const segment5 = segments[4] ?? 0;

  const segmentLowLow = `${fitToSize(segment1)}px`;
  const segmentLow = `${fitToSize(segment2)}px`;
  const segmentNormal = `${fitToSize(segment3)}px`;
  const segmentHigh = `${fitToSize(segment4)}px`;
  const segmentHighHigh = `${fitToSize(segment5)}px`;

  const segment1HideState = segment1 === 0 ? 'hideBargraphSegment' : '';
  const segment2HideState = segment2 === 0 ? 'hideBargraphSegment' : '';
  const segment3HideState = segment3 === 0 ? 'hideBargraphSegment' : '';
  const segment4HideState = segment4 === 0 ? 'hideBargraphSegment' : '';
  const segment5HideState = segment5 === 0 ? 'hideBargraphSegment' : '';

  const tickLowLow = segment5 === 0 && segment4 === 0 && segment3 === 0 && segment2 === 0 && segment1 > 0 ? `${fitToSize(segment1 - 1)}px` : `${fitToSize(segment1)}px`;
  const tickLow = segment5 === 0 && segment4 === 0 && segment3 === 0 && segment2 ? `${fitToSize(segment2 - 1)}px` : `${fitToSize(segment2)}px`;
  const tickNormal = segment5 === 0 && segment4 === 0 && segment3 > 0 ? `${fitToSize(segment3 - 1)}px` : `${fitToSize(segment3)}px`;
  const tickHigh = segment5 === 0 && segment4 > 0 ? `${fitToSize(segment4 - 1)}px` : `${fitToSize(segment4)}px`;
  const tickHighHigh = segment5 > 0 ? `${fitToSize(segment5 - 1)}px` : `${fitToSize(segment5)}px`;

  const segment1Classes = `FigmaBargraphControlHorizontalChart-Bargraph-SegmentContainer-item ${segment1HideState} FigmaBargraphControlHorizontalChart-Segment-LowLow`;
  const segment2Classes = `FigmaBargraphControlHorizontalChart-Bargraph-SegmentContainer-item ${segment2HideState} FigmaBargraphControlHorizontalChart-Segment-Low`;
  const segment3Classes = `FigmaBargraphControlHorizontalChart-Bargraph-SegmentContainer-item ${segment3HideState} FigmaBargraphControlHorizontalChart-Segment-Normal`;
  const segment4Classes = `FigmaBargraphControlHorizontalChart-Bargraph-SegmentContainer-item ${segment4HideState} FigmaBargraphControlHorizontalChart-Segment-High`;
  const segment5Classes = `FigmaBargraphControlHorizontalChart-Bargraph-SegmentContainer-item ${segment5HideState} FigmaBargraphControlHorizontalChart-Segment-HighHigh`;

  const tick2Classes = `FigmaBargraphControlHorizontalChart-Bargraph-TickContainer_item ${segment1HideState}`;
  const tick3Classes = `FigmaBargraphControlHorizontalChart-Bargraph-TickContainer_item ${segment2HideState}`;
  const tick4Classes = `FigmaBargraphControlHorizontalChart-Bargraph-TickContainer_item ${segment3HideState}`;
  const tick5Classes = `FigmaBargraphControlHorizontalChart-Bargraph-TickContainer_item ${segment4HideState}`;
  const tick6Classes = `FigmaBargraphControlHorizontalChart-Bargraph-TickContainer_item ${segment5HideState}`;

  // In case when first segment is not 0 we must substract it -> segmentStops[0]
  const currentValueX = ((MAX_BAR_WIDTH / Laufweite) * (scaleToRange(currentValue, barRange) - segmentStops[0])) - 11;
  const minValueX = (MAX_BAR_WIDTH / Laufweite) * scaleToRange(dragIndicatorValue.minValue, barRange);
  const maxValueX = (MAX_BAR_WIDTH / Laufweite) * scaleToRange(dragIndicatorValue.maxValue, barRange);
  const NeedleStyles = {
    width: fitToSize(24),
    height: fitToSize(19),
    transform: `translate(${fitToSize(currentValueX)}px, 0px)`,
  };
  const MinIndicatorStyles = {
    transform: `translate(-${fitToSize(MAX_BAR_WIDTH + 2)}px, 0px) translate(${fitToSize(minValueX)}px, 0px)`,
  };
  const MaxIndicatorStyles = {
    transform: `translate(-${fitToSize(MAX_BAR_WIDTH + 2)}px, 0px) translate(${fitToSize(maxValueX)}px, 0px)`,
  };
  const titleStyles = {
    fontSize: `${fitToSize(24)}px`,
    lineHeight: `${fitToSize(32)}px`,
  };
  const subtitleStyles = {
    fontSize: `${fitToSize(16)}px`,
    lineHeight: `${fitToSize(24)}px`,
  };
  const buttonStyle = {
    width: `${fitToSize(32)}px`,
    height: `${fitToSize(32)}px`,
  };
  const currentValueContainerStyles = {
    marginTop: `${fitToSize(24)}px`,
    marginBottom: `${fitToSize(16)}px`,
  };
  const currentValueStyle = {
    fontSize: `${fitToSize(32)}px`,
    lineHeight: `${fitToSize(24)}px`,
  };
  const currentUnitStyle = {
    fontSize: `${fitToSize(16)}px`,
    lineHeight: `${fitToSize(24)}px`,
    marginLeft: `${fitToSize(8)}px`,
    paddingRight: `${fitToSize(16)}px`,
    transform: `translate(0px, ${fitToSize(3)}px)`,
    borderRight: showDragIndicator ? `${fitToSize(2)}px solid #EAEAEA` : `${fitToSize(2)}px none #EAEAEA`,
  };
  const extremeContainerStyle = {
    paddingLeft: `${fitToSize(16)}px`,
  };
  const extremeValueStyle = {
    fontSize: `${fitToSize(16)}px`,
    lineHeight: `${fitToSize(24)}px`,
  };
  const scaleLineStyle = {
    height: `${fitToSize(2.5)}px`,
    marginTop: `${fitToSize(8)}px`,
  };
  const extremeIndicatorStyle = {
    width: `${fitToSize(4)}px`,
    borderRadius: `${fitToSize(3)}px`,
    height: `${fitToSize(32)}px`,
  };
  const tickStyle = {
    width: `${fitToSize(0.771084)}px`,
    height: `${fitToSize(8)}px`,
  };
  const labelStyle = {
    fontSize: `${fitToSize(14)}px`,
    lineHeight: `${fitToSize(18)}px`,
  };

  const minValue = Number.isFinite(dragIndicatorValue.minValue) ? DisplayFormat(displayFormat, dragIndicatorValue.minValue) : 0;
  const maxValue = Number.isFinite(dragIndicatorValue.maxValue) ? DisplayFormat(displayFormat, dragIndicatorValue.maxValue) : 0;
  const minValueText = `Min: ${minValue} ${centerUnit}`;
  const maxValueText = `Max: ${maxValue} ${centerUnit}`;
  const currentValueText = DisplayFormat(displayFormat, currentValue);

  const dragIndicatorBtnTopPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.top;
  const dragIndicatorBtnBottomPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.bottom;
  const dragIndicatorBtnLeftPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.left;
  const dragIndicatorBtnRightPosition = showIndicatorButtonRef?.current?.getClientRects()?.[0]?.right;

  const dragIndicatorMenuXPosition = dragIndicatorBtnLeftPosition === undefined || dragIndicatorBtnRightPosition === undefined
    ? undefined : (dragIndicatorBtnRightPosition + dragIndicatorBtnLeftPosition) / 2;
  const dragIndicatorMenuYPosition = dragIndicatorBtnTopPosition === undefined || dragIndicatorBtnBottomPosition === undefined
    ? undefined : (dragIndicatorBtnTopPosition + dragIndicatorBtnBottomPosition) / 2;

  useEffect(() => {
    const element = segmentContainerRef.current;
    const observer = new ResizeObserver(() => {
      if (element) {
        const currentWidth = element.clientWidth;
        setSegmentContainerWidth(currentWidth);
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [segmentContainerRef]);
  return (
    <div className="FigmaBargraphControlHorizontalChart-Bargraph">
      <div className="FigmaBargraphControlHorizontalChart-Bargraph__TitleContainer">
        <div style={{ position: 'relative' }}>
          <div style={titleStyles} className="FigmaBargraphControlHorizontalChart-Bargraph__title">{bargraphTitle}</div>
          <div style={subtitleStyles} className="FigmaBargraphControlHorizontalChart-Bargraph__subtitle">{subtitle}</div>
          <div
            ref={showIndicatorButtonRef}
            className="FigmaBargraphControlHorizontalChart-Bargraph__image"
            role="button"
            tabIndex={0}
            onClick={() => {
              setShowDragIndicatorMenu(!showDragIndicatorMenu);
            }}
            onKeyUp={() => {}}
          >
            <Image style={buttonStyle} src={expandIcon} className="DragIndicator-button-icon" />
          </div>
          {showDragIndicatorMenu && (
          <OverlayPortal>
            <div
              role="none"
              className="FigmaBargraphControlHorizontalChart-Bargraph__overlay"
              onClick={() => setShowDragIndicatorMenu(false)}
            >
              <div
                style={{
                  position: 'absolute',
                  top: dragIndicatorMenuYPosition,
                  left: dragIndicatorMenuXPosition,
                }}
              >
                <DragIndicatorControl
                  showIndicator={showDragIndicator}
                  onDragIndicatorChange={() => {
                    setShowDragIndicator(!showDragIndicator);
                  }}
                  onRefresh={() => {
                    setDragIndicatorValue({
                      minValue: currentValue,
                      maxValue: currentValue,
                    });
                  }}
                />
              </div>
            </div>
          </OverlayPortal>
          )}
        </div>
      </div>
      <div className="FigmaBargraphControlHorizontalChart-Bargraph__ControlContainer">
        <div style={currentValueContainerStyles} className="FigmaBargraphControlHorizontalChart-Bargraph__CurrentValueContainer">
          <div style={currentValueStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__CurrentValueContainer-valueText">{currentValueText}</div>
          <div style={currentUnitStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__CurrentValueContainer-unitText">{centerUnit}</div>
          {showDragIndicator && (
          <div style={extremeContainerStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__ExtremesContainer">
            <div style={extremeValueStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__ExtremesContainer-text">{minValueText}</div>
            <div style={extremeValueStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__ExtremesContainer-text">{maxValueText}</div>
          </div>
          )}
        </div>
        <div ref={segmentContainerRef} className="FigmaBargraphControlHorizontalChart-Bargraph__BarContainer">
          <Image style={NeedleStyles} src={arrowIcon} />
          <div className="FigmaBargraphControlHorizontalChart-Bargraph__SegmentContainer">
            <div style={{ width: segmentLowLow, height: `${fitToSize(25)}px` }} className={segment1Classes} />
            <div style={{ width: segmentLow, height: `${fitToSize(25)}px` }} className={segment2Classes} />
            <div style={{ width: segmentNormal, height: `${fitToSize(25)}px` }} className={segment3Classes} />
            <div style={{ width: segmentHigh, height: `${fitToSize(25)}px` }} className={segment4Classes} />
            <div style={{ width: segmentHighHigh, height: `${fitToSize(25)}px` }} className={segment5Classes} />
            {showDragIndicator && (
            <div style={MinIndicatorStyles} className="FigmaBargraphControlHorizontalChart-Bargraph-ExtremeIndicatorContainer">
              <div style={extremeIndicatorStyle} className="FigmaBargraphControlHorizontalChart-Bargraph-SegmentContainer-extremeIndicator" />
            </div>
            )}
            {showDragIndicator && (
            <div style={MaxIndicatorStyles} className="FigmaBargraphControlHorizontalChart-Bargraph-ExtremeIndicatorContainer">
              <div style={extremeIndicatorStyle} className="FigmaBargraphControlHorizontalChart-Bargraph-SegmentContainer-extremeIndicator" />
            </div>
            )}
          </div>
          <div style={scaleLineStyle} className="FigmaBargraphControlHorizontal-Bargraph__Line" />
          <div className="FigmaBargraphControlHorizontalChart-Bargraph__TickContainer">
            <div style={{ width: tickLowLow }}>
              <div className="FigmaBargraphControlHorizontalChart-Bargraph-TickContainer_item">
                <div style={tickStyle} className="FigmaBargraphControlHorizontal-Bargraph__TickContainer-Tick" />
                <div style={labelStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__LabelContainer-label">
                  {DisplayFormat(segmentDisplayFormat, segmentStops[0])}
                </div>
              </div>
            </div>
            <div style={{ width: tickLow }}>
              <div className={tick2Classes}>
                <div style={tickStyle} className="FigmaBargraphControlHorizontal-Bargraph__TickContainer-Tick" />
                <div style={labelStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__LabelContainer-label">
                  {DisplayFormat(segmentDisplayFormat, segmentStops[1])}
                </div>
              </div>
            </div>
            <div style={{ width: tickNormal }}>
              <div className={tick3Classes}>
                <div style={tickStyle} className="FigmaBargraphControlHorizontal-Bargraph__TickContainer-Tick" />
                <div style={labelStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__LabelContainer-label">
                  {DisplayFormat(segmentDisplayFormat, segmentStops[2])}
                </div>
              </div>
            </div>
            <div style={{ width: tickHigh }}>
              <div className={tick4Classes}>
                <div style={tickStyle} className="FigmaBargraphControlHorizontal-Bargraph__TickContainer-Tick" />
                <div style={labelStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__LabelContainer-label">
                  {DisplayFormat(segmentDisplayFormat, segmentStops[3])}
                </div>
              </div>
            </div>
            <div style={{ width: tickHighHigh }}>
              <div className={tick5Classes}>
                <div style={tickStyle} className="FigmaBargraphControlHorizontal-Bargraph__TickContainer-Tick" />
                <div style={labelStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__LabelContainer-label">
                  {DisplayFormat(segmentDisplayFormat, segmentStops[4])}
                </div>
              </div>
            </div>
            <div>
              <div className={tick6Classes}>
                <div style={tickStyle} className="FigmaBargraphControlHorizontal-Bargraph__TickContainer-Tick" />
                <div style={labelStyle} className="FigmaBargraphControlHorizontalChart-Bargraph__LabelContainer-label">
                  {DisplayFormat(segmentDisplayFormat, segmentStops[5])}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
