/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SelectEditorControl } from '../../../../../../components/ParameterEditor';
import TextEditorControl from '../../../../../../components/ParameterEditor/TextEditorControl/TextEditorControl';
import { setContextParameterHelp } from '../../../../../../store/contexthelp/actions';
import { CompareValueProps } from '../../../../CompareTypes';
import CompareContainer from '../../../CompareContainer/CompareContainer';
import './CompareEnumValue.css';

export interface CompareEnumeratorOptions {
  [val: number]: string
}

export interface CompareEnumValueProps extends CompareValueProps {
  label: string;
  valueLeft: number;
  valueLeftUnit?: string;
  valueRight: number;
  valueRightUnit?: string;
  options: CompareEnumeratorOptions;
  // eslint-disable-next-line no-unused-vars
  onLeftValueChange?: (value: string) => void;
}

const CompareEnumValue: React.FC<CompareEnumValueProps> = (props: CompareEnumValueProps)
: React.ReactElement => {
  const {
    onButtonClick, label, help,
    valueLeft, valueLeftUnit,
    valueRight, valueRightUnit,
    readonly, modified, options, onLeftValueChange,
    onShowContentHelp, onHideContentHelp, buttonFunction,
    equal,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <CompareContainer
      label={label}
      buttonFunction={buttonFunction}
      equal={equal}
      onButtonClick={onButtonClick}
    >
      <SelectEditorControl
        onChange={(e) => onLeftValueChange?.(e.target.value)}
        unit={valueLeftUnit}
        edited={modified}
        options={options}
        value={options[valueLeft] !== undefined ? valueLeft : undefined}
        onShowContentHelp={() => {
          dispatch(setContextParameterHelp({
            title: t(label),
            text: help ? t(help) : '',
          }));
          onShowContentHelp?.();
        }}
        onHideContentHelp={() => {
          dispatch(setContextParameterHelp({
            title: '',
            text: '',
          }));
          onHideContentHelp?.();
        }}
        readonly={readonly}
      />
      <TextEditorControl
        displayFormat=""
        value={options[valueRight] ?? valueRight}
        onValueChange={() => 0}
        unit={valueRightUnit}
        readonly
      />
    </CompareContainer>
  );
};

export default CompareEnumValue;
