/* eslint-disable react/jsx-props-no-spreading */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export interface UnsupportedControlProps {
  text: string;
}

const UnsupportedControl
    : React.FC<UnsupportedControlProps> = (props: UnsupportedControlProps): React.ReactElement => {
      const { text } = props;
      const { t } = useTranslation();

      return (
        <Container fluid className="m-1 border border-danger">
          <Row>
            <Col className="col-12 d-flex justify-content-center align-items-center p-1 bg-warning">
              {t<string>('UNSUPPORTED_CONTROL_TYPE')}
            </Col>
          </Row>
          <Row>
            <Col className="col-12 d-flex justify-content-center align-items-center p-1">
              {text}
            </Col>
          </Row>
        </Container>
      );
    };

export default UnsupportedControl;
