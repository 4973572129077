/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import SQDeviceStatusLed from '../../controls/SQDeviceStatusLed/SQDeviceStatusLed';
import './DeviceStatusCard.scss';

export interface DeviceStatusCardProps {
  deviceInstanceId: string;
  deviceState: DeviceModelStatus.UI.Contactron.StationCockpitDeviceStateCard;
}

const DeviceStatusCard: React.FC<DeviceStatusCardProps> = (props: DeviceStatusCardProps):
  React.ReactElement => {
  const {
    deviceState,
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const menuItems = deviceState.items.map((identRef) => (
    <SQDeviceStatusLed key={`cockpit--devstatus-value--${identRef}`} identRef={identRef} deviceInstanceId={deviceInstanceId} onStateChanged={() => 0} />));

  return (
    <PaperControl title={t(deviceState.label) ?? ''}>
      <div className="cockpit-contactron-station-devicestatus">
        {menuItems}
      </div>
    </PaperControl>
  );
};

export default DeviceStatusCard;
