/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { Reducer } from 'redux';
import { Discovery } from '@gpt/cxe-nats-communication';
import { DeviceInstanceInformation, WebDevice } from '@gpt/commons';
import {
  DiscoveryServiceState,
  DiscoveryServiceStateActionType,
  ConnectedDeviceCollection,
  ConnectedAdapter,
  DiscoveryServiceDeviceCatalog,
  ConnectedDeviceState,
  ConnectedDevice,
  DiscoveryServiceDeviceCatalogCollection,
} from './types';
import { ExecutionState } from '../common';
import { NatsServerConnectionState } from '../../natsConnection';

const initialState: DiscoveryServiceState = {
  adapters: {},
  devices: {},
  catalog: {
    deviceList: {},
    executionState: ExecutionState.init,
  },
  state: NatsServerConnectionState.Disconnected,
};

const discoveryAddInitAdapter = (state: DiscoveryServiceState, adapter: Discovery.CommunicationAdapter): DiscoveryServiceState => ({
  ...state,
  adapters: {
    ...state.adapters,
    [adapter.id]: {
      id: adapter.id,
      adapterType: adapter.adapterType,
      connection: adapter.connection,
      title: adapter.title,
      state: adapter.state,
      timestamp: adapter.timestamp,
    },
  },
  devices: Object.keys(state.devices)
    .reduce((acc, key) => (state.devices[key].adapterId === adapter.id
      ? acc
      : { ...acc, [key]: state.devices[key] }), {} as ConnectedDeviceCollection),
});

const discoveryRemoveAdapter = (state: DiscoveryServiceState, adapter: ConnectedAdapter): DiscoveryServiceState => {
  const { [adapter.id]: alt, ...rest } = state.adapters;
  return {
    ...state,
    adapters: rest,
    devices: Object.keys(state.devices)
      .reduce((acc, key) => (alt !== undefined && state.devices[key].adapterId === alt.id
        ? acc
        : { ...acc, [key]: state.devices[key] }), {} as ConnectedDeviceCollection),
  };
};

const connectedDeviceState = (devState: Discovery.DeviceInstanceState, catalog?: WebDevice.DeviceCatalogInformation): ConnectedDeviceState => {
  let state = ConnectedDeviceState.ConnectedUnknown;
  if (devState === Discovery.DeviceInstanceState.NoDevice) {
    state = ConnectedDeviceState.Disconnected;
  } else if (devState === Discovery.DeviceInstanceState.Supported && catalog !== undefined) {
    state = ConnectedDeviceState.ConnectedKnown;
  } else if (devState === Discovery.DeviceInstanceState.Supported && catalog === undefined) {
    state = ConnectedDeviceState.ConnectedUnknown;
  } else if (devState === Discovery.DeviceInstanceState.Unidentified) {
    state = ConnectedDeviceState.Identification;
  } else if (devState === Discovery.DeviceInstanceState.Unsupported) {
    state = ConnectedDeviceState.ConnectedUnknown;
  }
  return state;
};

const createDeviceInstance = (instance?: Discovery.DeviceInstanceData): DeviceInstanceInformation | undefined => (instance === undefined || instance === null ? undefined : {
  communicationProtocol: instance.communicationProtocol,
  deviceId: instance.deviceInstanceId,
  deviceTag: instance.deviceTag,
  deviceTypeIdentifier: instance.deviceTypeIdentifier,
  firmwareVersion: instance.firmwareVersion,
  hardwareVersion: instance.hardwareVersion,
  serialNumber: instance.serialNo,
  simulation: false,
});

const discoveryUpdateAdapterDeviceList = (deviceList: ConnectedDeviceCollection, adapter: Discovery.CommunicationAdapter, deviceCatalog: DiscoveryServiceDeviceCatalog)
: ConnectedDeviceCollection => {
  const upDeviceList: ConnectedDeviceCollection = (adapter.devices ?? []).reduce((acc, dev) => {
    const catalog = dev.instance ? deviceCatalog.deviceList[dev.instance.catalogId] : undefined;
    const state = connectedDeviceState(dev.state, catalog);
    const deviceId = `${adapter.id}.${dev.id}`;
    if (acc[deviceId] === undefined) { // Create new device entry
      const newConnectedDevice: ConnectedDevice = {
        id: dev.id,
        adapterId: adapter.id,
        state,
        timestamp: dev.timestamp,
        catalogId: dev.instance?.catalogId,
        instance: createDeviceInstance(dev.instance),
      };
      return {
        ...acc,
        [deviceId]: newConnectedDevice,
      };
    }

    // Device from action, contain no instance data -> remove instance data from device
    if (dev.instance === undefined || dev.instance === null) {
      return {
        ...acc,
        [deviceId]: {
          ...acc[deviceId],
          adapterId: adapter.id,
          state,
          catalogId: undefined,
          instance: undefined,
          timestamp: dev.timestamp,
        },
      };
    }

    // Previous state don't contain instance data -> update device with intance data
    const { instance } = acc[deviceId];
    if (instance === undefined || instance === null) {
      return {
        ...acc,
        [deviceId]: {
          ...acc[deviceId],
          adapterId: adapter.id,
          state,
          catalogId: dev.instance.catalogId,
          instance: createDeviceInstance(dev.instance),
          timestamp: dev.timestamp,
        },
      };
    }

    return {
      ...acc,
      [deviceId]: {
        ...acc[deviceId],
        adapterId: adapter.id,
        state,
        catalogId: dev.instance.catalogId,
        instance: createDeviceInstance(dev.instance),
        timestamp: dev.timestamp,
      },
    };
  }, deviceList);
  // Remove not existing devices
  const cleanDeviceList = Object.keys(upDeviceList)
    .reduce((acc, key) => (upDeviceList[key].adapterId === adapter.id && adapter.devices.find((dv) => dv.id === upDeviceList[key].id) !== undefined
      ? {
        ...acc,
        [key]: upDeviceList[key],
      } : acc), deviceList);
  return cleanDeviceList;
};

const discoveryUpdateAdapter = (state: DiscoveryServiceState, adapter: Discovery.CommunicationAdapter): DiscoveryServiceState => {
  const devices = discoveryUpdateAdapterDeviceList(state.devices, adapter, state.catalog);
  return state.adapters[adapter.id] === undefined
    ? {
      ...state,
      adapters: {
        ...state.adapters,
        [adapter.id]: {
          state: adapter.state,
          adapterType: adapter.adapterType,
          connection: adapter.connection,
          id: adapter.id,
          title: adapter.title,
          timestamp: adapter.timestamp,
        },
      },
      devices,
    } : {
      ...state,
      adapters: {
        ...state.adapters,
        [adapter.id]: {
          ...state.adapters[adapter.id],
          state: adapter.state,
        },
      },
      devices,
    };
};

const discoveryUpdateDeviceCatalog = (state: DiscoveryServiceState, catalog: WebDevice.DeviceCatalogInformation[]): DiscoveryServiceState => ({
  ...state,
  catalog: {
    executionState: ExecutionState.success,
    deviceList: catalog.reduce((acc, entry) => ({
      ...acc,
      [entry.deviceCatalogIdent]: entry,
    }), {} as DiscoveryServiceDeviceCatalogCollection),
  },
});

const discoveryServiceStateReducer: Reducer<DiscoveryServiceState, DiscoveryServiceStateActionType> = (
  state = initialState,
  action: DiscoveryServiceStateActionType,
): DiscoveryServiceState => {
  switch (action.type) {
    case 'DISCOVERY_STATE__SET_CONNECTION_STATE': {
      return {
        ...state,
        state: action.payload,
      };
    }
    case 'DISCOVERY_STATE__UPDATE_LIST': {
      const adapter = action.payload;
      let newState = state;
      if (adapter.state === Discovery.AdapterState.Init) {
        newState = discoveryAddInitAdapter(state, adapter);
      } else if (adapter.state === Discovery.AdapterState.Detached) {
        newState = discoveryRemoveAdapter(state, adapter);
      } else {
        newState = discoveryUpdateAdapter(state, adapter);
      }
      return newState;
    }
    case 'DISCOVERY_STATE__UPDATE_DEVICE_CATALOG': {
      return discoveryUpdateDeviceCatalog(state, action.payload);
    }
    case 'DISCOVERY_STATE__SET_DEVICE_CATALOG_STATE': {
      return {
        ...state,
        catalog: {
          ...state.catalog,
          executionState: action.payload,
          deviceList: action.payload === ExecutionState.failed ? {} : state.catalog.deviceList,
        },
      };
    }
    default:
      return state;
  }
};

export default discoveryServiceStateReducer;
