/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingControl } from '../../components/LoadingControl';
import { InputSliderControl } from '../../components/ParameterEditor';
import { UnsupportedControl } from '../../components/UnsupportedControl';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../hooks/useContextDataset';
import { writeActiveDeviceVariableValues } from '../../store';
import { setContextParameterHelp } from '../../store/contexthelp';

export interface InputSliderDescriptorProps {
  variable: IdentRef;
  minRangeValue: number;
  maxRangeValue: number;
  deviceInstanceId: string;
}

const InputSliderDescriptorControl
  : React.FC<InputSliderDescriptorProps> = (props: InputSliderDescriptorProps)
  : React.ReactElement => {
    const {
      variable, maxRangeValue, minRangeValue, deviceInstanceId,
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const varDescriptor = useContextDatasetDescriptor(deviceInstanceId, variable);
    const varValue = useContextDatasetStatusValue(deviceInstanceId, variable);

    if (varDescriptor === undefined) {
      return <UnsupportedControl text={`InputSliderDescriptorControl: Undefined control ${variable}`} />;
    }

    if (varDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return <UnsupportedControl text={`InputSliderDescriptorControl: Unsupported type ${varDescriptor?.type}`} />;
    }

    if (varValue === undefined) {
      return <LoadingControl title={t<string>('LOADING_DATA')} />;
    }

    if ((varDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER)
    && (varDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER)
    && (varDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT)
    ) {
      return (
        <UnsupportedControl text={
        `InputSliderDescriptorControl: Unsupported value type ${varDescriptor.valueType.type};`
        + ' supported value types are: INTEGER, UNSIGNED_INTEGER, FLOAT, DOUBLE'
    }
        />
      );
    }

    const sliderValue = Number.isNaN(varValue.value) ? 0 : varValue.value;

    return (
      <InputSliderControl
        label={t<string>(varDescriptor.label)}
        value={sliderValue}
        unit={varDescriptor.unit === undefined ? undefined : t<string>(varDescriptor.unit)}
        min={minRangeValue}
        max={maxRangeValue}
        step={varDescriptor.valueType.stepSize ?? 0.1}
        onValueChange={(parsedValue) => {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            value: parsedValue,
            backupValue: varValue.backupValue,
            identRef: variable,
          }]));
        }}
        onShowContextHelp={() => {
          dispatch(setContextParameterHelp({
            title: varDescriptor.label === undefined ? '' : t<string>(varDescriptor.label),
            text: varDescriptor.help === undefined ? '' : t<string>(varDescriptor.help),
          }));
        }}
        onHideContextHelp={() => {
          dispatch(setContextParameterHelp({
            title: '',
            text: '',
          }));
        }}
        modified={varValue.modified}
      />
    );
  };

export default InputSliderDescriptorControl;
