/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reduxStore';
import { ActionProgressControl } from '../../../components/ActionProgressControl/ActionProgressControl';
import { deviceMethodExecutionSelector } from '../../../store/deviceInstances/store/deviceMethod/selectors';

export interface ActionProgressComponentProps {
  title: string;
  methodIdent: string;
  deviceInstanceId: string,
}

const ActionProgressComponent: React.FC<ActionProgressComponentProps> = (props: ActionProgressComponentProps)
  : React.ReactElement => {
  const { t } = useTranslation();
  const { methodIdent, deviceInstanceId, title } = props;

  const methodSteps = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, methodIdent)?.steps);

  return (
    <ActionProgressControl
      title={t<string>(title)}
      steps={methodSteps}
    />
  );
};

export default ActionProgressComponent;
