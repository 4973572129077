/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { WebDevice } from '@gpt/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './DeviceCatalogCard.css';

export interface DeviceCatalogCardProps {
    device: WebDevice.DeviceCatalogInformation;
    isButton?: boolean;
    onClick: () => void;
}

const DeviceCatalogCard: React.FC<DeviceCatalogCardProps> = (props: DeviceCatalogCardProps)
: React.ReactElement => {
  const { device, isButton, onClick } = props;
  const { t } = useTranslation();

  const content = (
    <>
      <div className="device-catalog-card__image d-flex justify-content-center align-items-center">
        <img alt={device.deviceTypeName} src={device.productImage} />
      </div>
      <div className="device-catalog-card__description w-100 d-flex flex-column justify-content-center align-items-center">
        <p className="device-catalog-card__description__title w-100 m-0 p-0 pb-1">
          {device.deviceTypeName}
        </p>
        <p className="device-catalog-card__description__text w-100 m-0 pb-1">
          {`${t<string>('DEVICE_CATALOG_CARD__ITEM_NUMBER')} ${device.productOrderNumber}`}
        </p>
        <p className="device-catalog-card__description__text w-100 m-0">
          {`${t<string>('DEVICE_CATALOG_CARD__FIRMWARE')} ${device.firmwareVersion}`}
        </p>
      </div>
    </>
  );

  if (isButton !== undefined && isButton === false) {
    return (
      <div
        key={`catalog-card-${device.deviceFamily}-${device.productOrderNumber}`}
        className="device-catalog-card d-flex flex-row w-100 justify-content-start align-items-center"
        style={{
          backgroundColor: '#F2F2F2',
        }}
      >
        {content}
      </div>
    );
  }

  return (
    <div
      className="device-catalog-card d-flex flex-row w-100 justify-content-start align-items-center"
      onClick={() => onClick()}
      role="button"
      tabIndex={0}
      onKeyDown={(ev) => ev.key === 'Enter' && onClick()}
    >
      {content}
    </div>
  );
};

export default DeviceCatalogCard;
