/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import {
  DATASET_PROVIDER__REQUEST_DATASET_LIST,
  typeDatasetProviderMiddlewareAction,
  DATASET_PROVIDER__REQUEST_EXTERNAL_DEVICE_DATASET,
  DATASET_PROVIDER__SAVE_DATASET__EXTERNAL_FILE,
  WriteDatasetToExternalFilePayload,
  RequestExternalDeviceDatasetPayload,
} from './types';

export const requestDatasetList = (payload: Services.DeviceModelServer.DatasetProviderService.DatasetFilter)
  : typeDatasetProviderMiddlewareAction => ({
  type: DATASET_PROVIDER__REQUEST_DATASET_LIST,
  payload,
});

export const requestExternalDeviceDataset = (payload: RequestExternalDeviceDatasetPayload)
: typeDatasetProviderMiddlewareAction => ({
  type: DATASET_PROVIDER__REQUEST_EXTERNAL_DEVICE_DATASET,
  payload,
});

export const writeDeviceDatasetToExternalFile = (payload: WriteDatasetToExternalFilePayload)
  : typeDatasetProviderMiddlewareAction => ({
  type: DATASET_PROVIDER__SAVE_DATASET__EXTERNAL_FILE,
  payload,
});
