/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceInformation } from '@gpt/commons';
import { ListItemDeviceCard } from './ListItemDeviceCard/ListItemDeviceCard';
import { ListItemStationCard } from './ListItemStationCard/ListItemStationCard';
import { DeviceCardListItem } from './types';
import { ListItemInterfaceCard } from './ListItemInterfaceCard/ListItemInterfaceCard';
import './DeviceCardList.css';
import { ListItemAdapterCard } from './ListItemAdapterCard/ListItemAdapterCard';
import ListItemUnidentifiedCard from './ListItemUnidentifiedCard/ListItemUnidentifiedCard';
import ListItemUnknownCard from './ListItemUnknownCard/ListItemUnknownCard';

export interface DeviceCardListProps {
    deviceList: DeviceCardListItem[];
    // eslint-disable-next-line no-unused-vars
    onRowClick: (device: DeviceInformation) => void;
}

const createToolTip = (device: DeviceInformation): string => {
  const { catalog, instance } = device;
  // DeviceId || DeviceDriverId → DeviceType, i.e. "01.04.0F.0E.0020"
  let deviceType = (instance?.deviceId || catalog?.deviceDriverId || '');
  deviceType = deviceType?.substring(0, 16)?.toUpperCase()?.replace(/-/g, '.');
  // build tooltip string with product OrderCode and DeviceType IDs…
  return `OrderCode: ${catalog.productOrderNumber}\nID: ${deviceType}`;
};

const DeviceCardList: React.FC<DeviceCardListProps> = (props: DeviceCardListProps)
: React.ReactElement => {
  const { deviceList, onRowClick } = props;

  const devices = deviceList
    .filter((d) => d.type === 'DEVICE_CARD_LIST_ITEM__DEVICE__UNIDENTIFIED'
      || d.type === 'DEVICE_CARD_LIST_ITEM__DEVICE__UNKNOWN'
      || d.type === 'DEVICE_CARD_LIST_ITEM__DEVICE__KNOWN'
      || d.type === 'DEVICE_CARD_LIST_ITEM__STATION')
    .sort((d1, d2) => {
      const ts1 = d1.timestamp ?? '0';
      const ts2 = d2.timestamp ?? '0';
      return ts1 > ts2 ? -1 : 1;
    });
  const adapters = deviceList
    .filter((a) => a.type === 'DEVICE_CARD_LIST_ITEM__ADAPTER')
    .sort((a1, a2) => {
      const ts1 = a1.timestamp ?? '0';
      const ts2 = a2.timestamp ?? '0';
      return ts1 > ts2 ? -1 : 1;
    });
  const interfaces = deviceList.filter((i) => i.type === 'DEVICE_CARD_LIST_ITEM__INTERFACE');
  const sortedDeviceList = [
    ...devices,
    ...adapters,
    ...interfaces,
  ];
  const deviceListControls = sortedDeviceList.map((listItem) => {
    if (listItem.type === 'DEVICE_CARD_LIST_ITEM__INTERFACE') {
      return (
        <div
          className="CardListItem"
          key={`no-${listItem.interfaceType}-device-available`}
          data-testid="card-list-item__interface"
        >
          <ListItemInterfaceCard
            interfaceType={listItem.interfaceType}
          />
        </div>
      );
    }

    if (listItem.type === 'DEVICE_CARD_LIST_ITEM__ADAPTER') {
      return (
        <div
          className="CardListItem"
          key={`adapter-${listItem.adapterId}--${listItem.adapterType}`}
          data-testid="card-list-item__adapter"
        >
          <ListItemAdapterCard
            adapterType={listItem.adapterType}
            state={listItem.state}
          />
        </div>
      );
    }

    if (listItem.type === 'DEVICE_CARD_LIST_ITEM__DEVICE__UNIDENTIFIED') {
      return (
        <div
          className="CardListItem"
          key={`device-${listItem.adapterId}--${listItem.id}`}
          data-testid="card-list-item__device__unidentified"
        >
          <ListItemUnidentifiedCard
            adapterType={listItem.adapterType}
          />
        </div>
      );
    }

    if (listItem.type === 'DEVICE_CARD_LIST_ITEM__DEVICE__UNKNOWN') {
      return (
        <div
          className="CardListItem"
          key={`device-${listItem.adapterId}--${listItem.id}`}
          data-testid="card-list-item__device__unknown"
        >
          <ListItemUnknownCard />
        </div>
      );
    }

    const tooltip = createToolTip(listItem.device);
    if (listItem.type === 'DEVICE_CARD_LIST_ITEM__STATION') {
      return (
        <div
          className="CardListItem"
          key={`device-${listItem.adapterId}--${listItem.id}`}
          title={tooltip}
          data-testid="card-list-item__station"
        >
          <ListItemStationCard
            device={listItem.device}
            onRowClick={() => onRowClick(listItem.device)}
          />
        </div>
      );
    }

    return (
      <div
        className="CardListItem"
        key={`device-${listItem.adapterId}--${listItem.id}`}
        title={tooltip}
        data-testid="card-list-item__device"
      >
        <ListItemDeviceCard
          device={listItem.device}
          onRowClick={() => onRowClick(listItem.device)}
        />
      </div>
    );
  });

  return (
    <div className="DeviceCardList">
      {deviceListControls}
    </div>
  );
};

export default DeviceCardList;
