/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import './StationAddressingHeader.scss';

const StationAddressingHeader: React.FC = () : React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="page-station-addressing__header contactron-commissioning-wizard">
      <div className="page-station-addressing-header">
        {t('WIZARD_CONTACTRON__STATION_ADDRESS__HEADER_TEXT_LINE1')}
        {' '}
        <strong>{t('WIZARD_CONTACTRON__STATION_ADDRESS__HEADER_TEXT_LINE2')}</strong>
        {' '}
        {t('WIZARD_CONTACTRON__STATION_ADDRESS__HEADER_TEXT_LINE3_NEW')}
        {' '}
        <strong>{t('WIZARD_CONTACTRON__STATION_ADDRESS__HEADER_TEXT_LINE4')}</strong>
      </div>
    </div>
  );
};

export default StationAddressingHeader;
