/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';

export interface TabViewItemProps extends PropsWithChildren<any> {
  // eslint-disable-next-line react/no-unused-prop-types
  tabIdent: string;
  // eslint-disable-next-line react/no-unused-prop-types
  tabLabel: string;
  // eslint-disable-next-line react/no-unused-prop-types
  tabHelp: string;
  // eslint-disable-next-line react/no-unused-prop-types
  tabColor: 'normal' | 'error' | 'modified';
}

const TabViewItem: React.FC<TabViewItemProps> = (props: TabViewItemProps)
: React.ReactElement => {
  const {
    children,
  } = props;
  return <>{children}</>;
};

export default TabViewItem;
