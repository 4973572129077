/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { IdentRef } from '@gpt/commons';
import { Action } from 'redux';

export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';

export interface DeviceParameterViewState {
    currentTab: IdentRef;
}

export interface SetCurrentTabPayload {
    current: IdentRef;
    ident: IdentRef;
}

export interface SetCurrentTabAction extends Action<typeof SET_CURRENT_TAB> {
    payload: SetCurrentTabPayload;
}
