/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { DeviceModelStatus } from '@gpt/commons';
import { registerComponent } from '../ComponentFactory/ComponentFactory';
import SPortWriteCommandControl from './SPortCommandControl/SPortCommandControl';

const registerSportControls = (): void => {
  /* CONTACTRON */
  registerComponent(DeviceModelStatus.UI.ControlType.CTLSPORTWRITECOMMAND, SPortWriteCommandControl);
};

export default registerSportControls;
