/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ExecutionState } from '../../common';
import { setSystemAppInitialized } from '../../systemInformation/actions';
import {
  DeviceCatalogActionTypes,
  REQUEST_DEVICE_CATALOG_DEVICE_LIST,
} from '../types';
import { setDiscoveryDeviceCatalogState, updateDiscoveryDeviceCatalog } from '../../discoveryServiceState/actions';
import { infrastructureService } from '../../../services/InfrastructureService/InfrastructureService';

// const GetDeviceCatalog = async (): Promise<WebDevice.DeviceCatalog | undefined> => {
//   let data: WebDevice.DeviceCatalog | undefined;
//   try {
//     // http://localhost:8080
//     const response = await fetch(`${ENV_WEBSERVER_REQUEST_PATH}catalog/catalog.json`);
//     data = await response.json();
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   } catch (err) {
//     data = undefined;
//   }
//   return data;
// };

// const retryGetDeviceCatalog = async (maxRetry: number): Promise<WebDevice.DeviceCatalog> => {
//   // eslint-disable-next-line no-unused-vars
//   const fetchCatalog = async (counter: number, resolve: (value: WebDevice.DeviceCatalog | PromiseLike<WebDevice.DeviceCatalog>) => void): Promise<void> => {
//     if (counter === 0) {
//       resolve({});
//       return;
//     }
//     const catalog = await GetDeviceCatalog();
//     if (catalog === undefined) {
//       setTimeout(() => fetchCatalog(counter - 1, resolve), 100);
//     } else {
//       resolve(catalog);
//     }
//   };

//   return new Promise<WebDevice.DeviceCatalog>((resolve) => {
//     fetchCatalog(maxRetry, resolve);
//   });
// };

const deviceCatalogMiddleware = ()
  : Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends DeviceCatalogActionTypes>(action: A): Promise<A> => {
  if (action.type === REQUEST_DEVICE_CATALOG_DEVICE_LIST) {
    api.dispatch(setDiscoveryDeviceCatalogState(ExecutionState.pending));
    try {
      const response = await infrastructureService.getDeviceCatalog();
      const catalog = response ?? {};
      const deviceList = Object.keys(catalog).map((key) => catalog[key].model);
      api.dispatch(updateDiscoveryDeviceCatalog(deviceList));
      api.dispatch(setDiscoveryDeviceCatalogState(ExecutionState.success));
      setTimeout(() => {
        api.dispatch(setSystemAppInitialized());
      }, 2000);
    } catch (err) {
      api.dispatch(setDiscoveryDeviceCatalogState(ExecutionState.failed));
      api.dispatch(setSystemAppInitialized());
    }
  }
  const result = next(action);
  return result;
};

export default deviceCatalogMiddleware;
