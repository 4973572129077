/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useContextDatasetStatusValue, useContextDatasetStatusDescriptor } from '../../../../../hooks/useContextDataset';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';

export const HDC_EMPTY_ROW = '';
const IFSM_ADDR_ERROR_ENUM_VARIABLE = 'IFSM_ADDR_ERROR';

export const useHDCEntryEnumLabelValue = (deviceInstanceId: string | undefined, identRef: IdentRef, target?: DatasetType): string => {
  const statusValue = useContextDatasetStatusValue(deviceInstanceId, identRef, target);
  const errorEnumDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, IFSM_ADDR_ERROR_ENUM_VARIABLE, target);
  const { t } = useTranslation();

  if (statusValue?.value === undefined) {
    return HDC_EMPTY_ROW;
  }

  if (statusValue.value === 0xFFFF) {
    return HDC_EMPTY_ROW;
  }

  if (errorEnumDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return `${statusValue.value}`;
  }

  const { valueType } = errorEnumDescriptor;
  if (valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return `${statusValue.value}`;
  }

  const value = valueType.enumerator.find((entry) => entry.value === statusValue.value);
  return value === undefined ? `${statusValue.value}` : t(value.label);
};
