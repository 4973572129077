/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */

import React from 'react';
import Header from '../../components/Header/Header';
import OfflineHeader from '../../components/OfflineHeader/OfflineHeader';
import { useTypedSelector } from '../../store/reduxStore';
import './StandardView.scss';
import { deviceInstanceActiveDeviceInstance } from '../../store/deviceInstances/store/activeDevice/selectors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StandardViewProps = React.PropsWithChildren<any>

const StandardView: React.FC<StandardViewProps> = (props: StandardViewProps)
: React.ReactElement => {
  const {
    children,
  } = props;

  const activeDevice = useTypedSelector(
    (state) => {
      const deviceInstance = state.deviceInstances.activeDeviceInstance;
      return deviceInstanceActiveDeviceInstance(state.deviceInstances, deviceInstance);
    },
  );

  let headerClass: string;
  let containerClass: string;
  let headerControl: React.ReactElement | undefined;
  if (activeDevice === undefined) {
    headerControl = undefined;
    headerClass = 'standardview-hidden';
    containerClass = 'standardview-container standardview-container-no-header';
  } else if (activeDevice?.device?.instance === undefined) {
    headerControl = <OfflineHeader deviceInstanceId={activeDevice?.deviceInstanceId} />;
    headerClass = 'standardview-header';
    containerClass = 'standardview-container';
  } else {
    headerControl = <Header deviceInstanceId={activeDevice?.deviceInstanceId} />;
    headerClass = 'standardview-header';
    containerClass = 'standardview-container';
  }

  return (
    <div className={containerClass} data-testid="standard-view">
      <div className={headerClass}>
        {headerControl}
      </div>
      <div className="standardview-content">
        {children}
      </div>
    </div>
  );
};

export default StandardView;
