/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import {
  LINEARIZATION_DATASET__SET_COLDPOINT,
  LINEARIZATION_DATASET__SET_EXECUTION_STATE,
  LINEARIZATION_DATASET__SET_CALCULATION_STATUS,
  LINEARIZATION_DATASET__UPDATE_DATASET,
  LinearizationDatasetState,
  typeLinearizationDatasetActionTypes,
  SetColdPointAction,
  SetLinearizationExecutionStateAction,
  SplineCalculationStatus,
  SetSplineCalculationStatusAction,
  UpdateLinearizationDatasetAction,
  InitLinearizationDatasetAction,
  LINEARIZATION_DATASET__INIT_DATASET,
  LinTableValue,
  LINEARIZATION_DATASET__SET_SPLINE_MESSAGE,
  SetLinTableSplineMessageAction,
  SetLinTableLinearizationMessageAction,
  LINEARIZATION_DATASET__SET_LINEARIZATION_MESSAGE,
} from './types';
import { ExecutionState } from '../common';

const initialState: LinearizationDatasetState = {
  sensorType: 'RTD',
  executionState: ExecutionState.init,
  coldJunctionComp0: {
    value: 0,
    displayFormat: '%.2f',
  },
  coldJunctionComp80: {
    value: 0,
    displayFormat: '%.2f',
  },
  pointsCounter: {
    value: 0,
    displayFormat: '%d',
  },
  maxRegressionError: {
    value: 0.1,
    displayFormat: '%.2f',
  },
  table: {
    label: '',
    linearizationData: [],
    header: { header1: '', header2: '' },
    isValid: true,
  },
  spline: [],
  splineCalculationState: SplineCalculationStatus.done,
};

const reducerUpdateLinValue = (state?: LinTableValue, newvalue?: number): LinTableValue | undefined => {
  let linTableValue: LinTableValue | undefined;
  if (state === undefined) {
    linTableValue = undefined;
  } else if (newvalue === undefined) {
    linTableValue = state;
  } else {
    linTableValue = {
      ...state,
      value: newvalue,
    };
  }
  return linTableValue;
};

const updateDatasetReducer = (
  state: LinearizationDatasetState,
  action: UpdateLinearizationDatasetAction,
): LinearizationDatasetState => ({
  ...state,
  sensorType: action.payload.sensorType ?? state.sensorType,
  pointsCounter: {
    ...state.pointsCounter,
    value: action.payload.pointsCounter ?? state.pointsCounter.value,
  },
  maxRegressionError: {
    ...state.maxRegressionError,
    value: action.payload.maxRegressionError ?? state.maxRegressionError.value,
  },
  table: action.payload.table ?? state.table,
  coldJunctionComp0: reducerUpdateLinValue(state.coldJunctionComp0, action.payload.coldJunctionComp0),
  coldJunctionComp80: reducerUpdateLinValue(state.coldJunctionComp80, action.payload.coldJunctionComp80),
  spline: action.payload.spline ?? state.spline,
  splineCalculationState: action.payload.splineCalculationState ?? state.splineCalculationState,
  executionState: action.payload.executionState ?? state.executionState,
});

const initDatasetReducer = (
  state: LinearizationDatasetState,
  action: InitLinearizationDatasetAction,
): LinearizationDatasetState => ({
  ...state,
  sensorType: action.payload.sensorType,
  pointsCounter: action.payload.pointsCounter,
  maxRegressionError: action.payload.maxRegressionError,
  table: action.payload.table,
  coldJunctionComp0: action.payload.coldJunctionComp0,
  coldJunctionComp80: action.payload.coldJunctionComp80,
  spline: action.payload.spline,
  splineCalculationState: SplineCalculationStatus.done,
  executionState: ExecutionState.success,
});

const setExecutionStateReducer = (
  state: LinearizationDatasetState,
  action: SetLinearizationExecutionStateAction,
): LinearizationDatasetState => ({
  ...state,
  executionState: action.payload,
});

const setSplineCalculationStatusReducer = (
  state: LinearizationDatasetState,
  action: SetSplineCalculationStatusAction,
): LinearizationDatasetState => ({
  ...state,
  splineCalculationState: action.payload,
});

const setKaltstelleReducer = (
  state: LinearizationDatasetState,
  action: SetColdPointAction,
): LinearizationDatasetState => {
  if (state.coldJunctionComp80 === undefined) {
    return {
      ...state,
      coldJunctionComp80: {
        displayFormat: '%.2f',
        value: action.payload,
      },
    };
  }

  return {
    ...state,
    coldJunctionComp80: {
      ...state.coldJunctionComp80,
      value: action.payload,
    },
  };
};

const setLinearizationSplineMessageReducer = (
  state: LinearizationDatasetState,
  action: SetLinTableSplineMessageAction,
): LinearizationDatasetState => ({
  ...state,
  splineMessage: action.payload,
});

const setLinearizationMessageReducer = (
  state: LinearizationDatasetState,
  action: SetLinTableLinearizationMessageAction,
): LinearizationDatasetState => ({
  ...state,
  linearizationMessage: action.payload,
});

const linearizationDatasetReducer = (
  state = initialState,
  action: typeLinearizationDatasetActionTypes,
): LinearizationDatasetState => {
  switch (action.type) {
    case LINEARIZATION_DATASET__INIT_DATASET:
      return initDatasetReducer(state, action);
    case LINEARIZATION_DATASET__UPDATE_DATASET:
      return updateDatasetReducer(state, action);
    case LINEARIZATION_DATASET__SET_COLDPOINT:
      return setKaltstelleReducer(state, action);
    case LINEARIZATION_DATASET__SET_EXECUTION_STATE:
      return setExecutionStateReducer(state, action);
    case LINEARIZATION_DATASET__SET_CALCULATION_STATUS:
      return setSplineCalculationStatusReducer(state, action);
    case LINEARIZATION_DATASET__SET_SPLINE_MESSAGE:
      return setLinearizationSplineMessageReducer(state, action);
    case LINEARIZATION_DATASET__SET_LINEARIZATION_MESSAGE:
      return setLinearizationMessageReducer(state, action);
    default:
      return state;
  }
};

export default linearizationDatasetReducer;
