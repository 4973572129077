/* eslint-disable no-unused-vars */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Services } from '@gpt/commons';
import { infrastructureService } from '../../../services/InfrastructureService/InfrastructureService';

type ReportServiceRequest = Services.DeviceModelServer.ReportServiceRequest;
type ReportServiceResponse = Services.DeviceModelServer.ReportServiceResponse;

export interface IReportMiddlewareService {
  generateReport: (request: ReportServiceRequest) => Promise<ReportServiceResponse>;
}

export class ReportMiddleWareService implements IReportMiddlewareService {
  // eslint-disable-next-line class-methods-use-this
  public generateReport = async (request: ReportServiceRequest):
    Promise<ReportServiceResponse> => infrastructureService.createReport(request);
}
