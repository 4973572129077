/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import home from '../../assets/icons/home.svg';
import { NavigationButton, NavigationButtonProps } from '../NavigationButton';
import './Navigation.css';

export interface NavigationProps {
  buttons: Array<NavigationButtonProps>;
  onStartClick: () => void;
}

const Navigation: React.FC<NavigationProps> = (props: NavigationProps):
React.ReactElement => {
  const {
    buttons, onStartClick,
  } = props;

  const { t } = useTranslation();

  const navbuttons = buttons.map((item) => (
    <NavigationButton
      key={`${item.routePath}-${item.title}`}
      routePath={item.routePath}
      title={item.title}
      subtitle={item.subtitle}
      image={item.image}
      applicationId={item.applicationId}
      onClick={item.onClick}
      deviceInstanceId={item.deviceInstanceId}
    />
  ));

  return (
    <div className="navigation-container">
      <div className="navigation-container__start">
        <Row
          noGutters
          className="navigation-container__start-row"
        >
          <Col>
            <Container style={{ paddingRight: 0 }}>
              <Row
                noGutters
                className="align-items-center"
                style={{ width: '100%', height: '64px', paddingTop: '5px' }}
                onClick={(ev) => { ev.stopPropagation(); onStartClick(); }}
              >
                <Col xs={1}>
                  <Image src={home} height={32} width={40} style={{ marginLeft: '-10px' }} />
                </Col>
                <Col style={{ marginLeft: '5%' }}>
                  <p className="navigation-container__navbuttons-title-format p-0 m-0">{t<string>('SIDEBAR__START_TITLE')}</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
      <div className="navigation-container__navbuttons">
        <Row
          noGutters
          className="navigation-container__navbuttons-row"
        >
          <Col>
            { navbuttons }
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Navigation;
