/* eslint-disable max-len */
import { DeviceInstancesState } from '../../types';
import ioLinkEventMonitorReducer from './reducer';
import { typeDeviceInstanceIoLinkEventMonitorAction } from './types';

const deviceInstanceioLinkEventMonitorActionReducer = (
  state: DeviceInstancesState,
  instanceAction: typeDeviceInstanceIoLinkEventMonitorAction,
): DeviceInstancesState => {
  const { payload } = instanceAction;
  const { targetInstance, action } = payload;

  // TODO: Event send from Communication use UniqueDeviceID (InstanceId)
  let instanceKey: string | undefined;
  if (state.instances[targetInstance] === undefined) {
    instanceKey = Object.keys(state.instances)
      .find((key) => state.instances[key].activeDevice.modelInstance?.device.instance?.deviceId === targetInstance);
  } else {
    instanceKey = targetInstance;
  }

  if (instanceKey === undefined || state.instances[instanceKey]?.ioLinkEventMonitor === undefined) {
    return state;
  }

  return {
    ...state,
    instances: {
      ...state.instances,
      [instanceKey]: {
        ...state.instances[instanceKey],
        ioLinkEventMonitor:
            ioLinkEventMonitorReducer(state.instances[instanceKey].ioLinkEventMonitor, action),
      },
    },
  };
};

export default deviceInstanceioLinkEventMonitorActionReducer;
