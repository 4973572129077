/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
import React from 'react';
import { toFixedValue } from '@gpt/commons';
import { ChartSegment, ChartScaleMark } from '../../Elements/types';
import { HBarElement } from '../../Elements/HBarElement/HBarElement';
import './3PhasenChart.scss';

export interface Phasen3ChartProps {
  l1Value: number;
  l1DragMinValue: number;
  l1DragMaxValue: number;
  l2Value: number;
  l2DragMinValue: number;
  l2DragMaxValue: number;
  l3Value: number;
  l3DragMinValue: number;
  l3DragMaxValue: number;
  showDragValues: boolean;
  unit: string;
  chartSegments: ChartSegment[];
  chartMarks: ChartScaleMark[];
  displayFormat: string;
}

const calculateMinValue = (segments: ChartSegment[])
  : number => (segments.length === 0 ? 0 : segments[0].minValue);

const calculateMaxValue = (segments: ChartSegment[])
  : number => (segments.length === 0 ? 10 : segments[segments.length - 1].maxValue);

export const Phasen3Chart: React.FC<Phasen3ChartProps> = (props: Phasen3ChartProps)
: React.ReactElement => {
  const {
    unit,
    l1Value, l1DragMinValue, l1DragMaxValue,
    l2Value, l2DragMinValue, l2DragMaxValue,
    l3Value, l3DragMinValue, l3DragMaxValue,
    chartSegments,
    chartMarks,
    showDragValues,
  } = props;

  const minValue = calculateMinValue(chartSegments);
  const maxValue = calculateMaxValue(chartSegments);

  return (
    <div className="x3phasen-chart">
      <div className="x3phasen-chart__element" />
      <div className="x3phasen-chart__element">
        <div className="x3phasen-chart__element__label">
          L1
        </div>
        <div className="x3phasen-chart__element__chart">
          <HBarElement
            currentValue={l1Value}
            dragMinValue={showDragValues ? l1DragMinValue : undefined}
            dragMaxValue={showDragValues ? l1DragMaxValue : undefined}
            minValue={minValue}
            maxValue={maxValue}
            segments={chartSegments}
          />
        </div>
        <div className="x3phasen-chart__element__value">
          {`${toFixedValue(l1Value, 2)} ${unit}`}
        </div>
      </div>
      <div className="x3phasen-chart__element">
        <div className="x3phasen-chart__element__label">
          L2
        </div>
        <div className="x3phasen-chart__element__chart">
          <HBarElement
            currentValue={l2Value}
            dragMinValue={showDragValues ? l2DragMinValue : undefined}
            dragMaxValue={showDragValues ? l2DragMaxValue : undefined}
            minValue={minValue}
            maxValue={maxValue}
            segments={chartSegments}
          />
        </div>
        <div className="x3phasen-chart__element__value">
          {`${toFixedValue(l2Value, 2)} ${unit}`}
        </div>
      </div>
      <div className="x3phasen-chart__element">
        <div className="x3phasen-chart__element__label">
          L3
        </div>
        <div className="x3phasen-chart__element__chart">
          <HBarElement
            currentValue={l3Value}
            dragMinValue={showDragValues ? l3DragMinValue : undefined}
            dragMaxValue={showDragValues ? l3DragMaxValue : undefined}
            minValue={minValue}
            maxValue={maxValue}
            segments={chartSegments}
            marks={chartMarks}
          />
        </div>
        <div className="x3phasen-chart__element__value">
          {`${toFixedValue(l3Value, 2)} ${unit}`}
        </div>
      </div>
    </div>
  );
};
