/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';
import deviceModelControlDescriptor from './deviceModelControlDescriptor';
import deviceModelStatusDescriptorCollection from './deviceModelStatusDescriptor';

const deviceModelStructureDescriptorParameters: DeviceModelStatus.DeviceModelDescriptorCollection = {
  PARAMETERS__DEVICEIDENTIFICATION__DEVICEIDENTIFICATION_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__DEVICEIDENTIFICATION__DEVICEIDENTIFICATION_GROUP',
    style: 'group',
    label: '01_04_0F_14_0020:PARAMETERS__DEVICEIDENTIFICATION__DEVICEIDENTIFICATION_GROUP__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__DEVICEIDENTIFICATION__DEVICEIDENTIFICATION_GROUP__HELP',
    description: '',
    elements: [{
      position: 'tag',
      identRef: deviceModelStatusDescriptorCollection.tag_number.identRef,
    },
    {
      position: 'message',
      identRef: deviceModelStatusDescriptorCollection.user_message.identRef,
    },
    {
      position: 'date',
      identRef: deviceModelStatusDescriptorCollection.date.identRef,
    },
    ],
  },
  PARAMETERS__DEVICEIDENTIFICATION__PARAMETERS: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__DEVICEIDENTIFICATION__PARAMETERS',
    label: '01_04_0F_14_0020:PARAMETERS__DEVICEIDENTIFICATION__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__DEVICEIDENTIFICATION__HELP',
    description: '',
    elements: [{
      identRef: 'PARAMETERS__DEVICEIDENTIFICATION__DEVICEIDENTIFICATION_GROUP',
    }],
  },
  PARAMETERS__DEVICEIDENTIFICATION__PICTURE_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    style: 'group',
    identRef: 'PARAMETERS__DEVICEIDENTIFICATION__PICTURE_GROUP',
    help: '',
    description: '',
    label: '01_04_0F_14_0020:PARAMETERS__DEVICEIDENTIFICATION__PICTURE_GROUP__LABEL',
    elements: [],
  },
  PARAMETERS__DEVICEIDENTIFICATION: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__DEVICEIDENTIFICATION',
    style: 'horizontal',
    label: '01_04_0F_14_0020:PARAMETERS__DEVICEIDENTIFICATION__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__DEVICEIDENTIFICATION__HELP',
    description: '',
    elements: [
      {
        width: 6,
        identRef: 'PARAMETERS__DEVICEIDENTIFICATION__PARAMETERS',
      },
      {
        width: 6,
        identRef: 'PARAMETERS__DEVICEIDENTIFICATION__PICTURE_GROUP',
      },
    ],
  },
  PARAMETERS__INPUTCONFIGURATION__SIGNALTYPE_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__INPUTCONFIGURATION__SIGNALTYPE_GROUP',
    style: 'group',
    label: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__SIGNALTYPE_GROUP__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__SIGNALTYPE_GROUP__HELP',
    description: '',
    elements: [{
      identRef: deviceModelStatusDescriptorCollection.sensor.identRef,
    },
    {
      identRef: deviceModelStatusDescriptorCollection.mode.identRef,
    },
    ],
  },
  PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__RANGE: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__RANGE',
    label: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__RANGE__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__RANGE__HELP',
    description: '',
    elements: [{
      position: 'start',
      identRef: deviceModelStatusDescriptorCollection.analog_input_range_start.identRef,
    },
    {
      position: 'end',
      identRef: deviceModelStatusDescriptorCollection.analog_input_range_end.identRef,
    },
    ],
  },
  PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP',
    style: 'group',
    label: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__HELP',
    description: '',
    elements: [{
      identRef: 'ctrlPARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP__RANGE',
    },
    {
      position: 'temperature_unit',
      identRef: deviceModelStatusDescriptorCollection.temperature_unit.identRef,
    },
    ],
  },
  PARAMETERS__INPUTCONFIGURATION__FILTER_FACTOR_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__INPUTCONFIGURATION__FILTER_FACTOR_GROUP',
    style: 'group',
    label: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__FILTER_FACTOR_GROUP__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__FILTER_FACTOR_GROUP__HELP',
    description: '',
    elements: [{
      position: 'filterfactor',
      identRef: deviceModelStatusDescriptorCollection.filterfactor.identRef,
    },
    ],
  },
  PARAMETERS__INPUTCONFIGURATION__2_WIRE_COMPENSATION_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__INPUTCONFIGURATION__2_WIRE_COMPENSATION_GROUP',
    style: 'group',
    label: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__2_WIRE_COMPENSATION_GROUP__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__2_WIRE_COMPENSATION_GROUP__HELP',
    description: '',
    elements: [{
      position: 'two_wire_compensation',
      identRef: deviceModelStatusDescriptorCollection.two_wire_compensation.identRef,
    },
    ],
  },
  PARAMETERS__INPUTCONFIGURATION__PARAMETERS: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__INPUTCONFIGURATION__PARAMETERS',
    help: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__HELP',
    label: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__LABEL',
    description: '',
    elements: [{
      identRef: 'PARAMETERS__INPUTCONFIGURATION__SIGNALTYPE_GROUP',
    },
    {
      identRef: 'PARAMETERS__INPUTCONFIGURATION__SIGNALRANGE_ANALOG_INPUT_GROUP',
    },
    {
      identRef: 'PARAMETERS__INPUTCONFIGURATION__FILTER_FACTOR_GROUP',
    },
    {
      identRef: 'PARAMETERS__INPUTCONFIGURATION__2_WIRE_COMPENSATION_GROUP',
    },
    ],
  },
  PARAMETERS__OUTPUTCONFIGURATION__ANALOG_OUTPUT_TYPE_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__OUTPUTCONFIGURATION__ANALOG_OUTPUT_TYPE_GROUP',
    style: 'group',
    label: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__ANALOG_OUTPUT_TYPE_GROUP__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__ANALOG_OUTPUT_TYPE_GROUP__HELP',
    description: '',
    elements: [{
      position: 'analog_output_type',
      identRef: deviceModelStatusDescriptorCollection.analog_output_type.identRef,
    },
    ],
  },
  PARAMETERS__OUTPUTCONFIGURATION__SIGNALRANGE_ANALOG_OUTPUT_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__OUTPUTCONFIGURATION__SIGNALRANGE_ANALOG_OUTPUT_GROUP',
    style: 'group',
    label: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__SIGNALRANGE_ANALOG_OUTPUT_GROUP__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__SIGNALRANGE_ANALOG_OUTPUT_GROUP__HELP',
    description: '',
    elements: [{
      position: 'analog_output_range_start',
      identRef: deviceModelStatusDescriptorCollection.analog_output_range_start.identRef,
    },
    {
      position: 'analog_output_range_end',
      identRef: deviceModelStatusDescriptorCollection.analog_output_range_end.identRef,
    },
    {
      position: 'ctrlDEVICEVALUEPICKER',
      identRef: deviceModelControlDescriptor.ctrlDEVICEVALUEPICKER.identRef,
    },
    ],
  },
  PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_GROUP',
    style: 'group',
    label: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_GROUP__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_GROUP__HELP',
    description: '',
    elements: [{
      position: 'error_handling',
      identRef: deviceModelStatusDescriptorCollection.error_handling.identRef,
    },
    ],
  },
  PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_CURRENT_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_CURRENT_GROUP',
    style: 'group',
    label: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_CURRENT_GROUP__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_CURRENT_GROUP__HELP',
    description: '',
    elements: [{
      position: 'overrange',
      identRef: deviceModelStatusDescriptorCollection.overrange.identRef,
    },
    {
      position: 'underrange',
      identRef: deviceModelStatusDescriptorCollection.underrange.identRef,
    },
    {
      position: 'linebreak',
      identRef: deviceModelStatusDescriptorCollection.linebreak.identRef,
    },
    {
      position: 'shorted',
      identRef: deviceModelStatusDescriptorCollection.shorted.identRef,
    },
    ],
  },
  PARAMETERS__OUTPUTCONFIGURATION_PARAMETERS: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__OUTPUTCONFIGURATION_PARAMETERS',
    help: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__HELP',
    label: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__LABEL',
    description: '',
    elements: [{
      identRef: 'PARAMETERS__OUTPUTCONFIGURATION__ANALOG_OUTPUT_TYPE_GROUP',
    },
    {
      identRef: 'PARAMETERS__OUTPUTCONFIGURATION__SIGNALRANGE_ANALOG_OUTPUT_GROUP',
    },
    {
      identRef: 'PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_GROUP',
    },
    {
      identRef: 'PARAMETERS__OUTPUTCONFIGURATION__FAULT_SIGNALING_CURRENT_GROUP',
    },
    ],
  },
  PARAMETERS__OUTPUTCONFIGURATION__PICTURE_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__OUTPUTCONFIGURATION__PICTURE_GROUP',
    style: 'group',
    help: '',
    label: '',
    description: '',
    elements: [
      {
        identRef: 'ctrlPARAMETERS__OUTPUTCONFIGURATION__PICTURE',
      },
    ],
  },
  PARAMETERS__OUTPUTCONFIGURATION: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__OUTPUTCONFIGURATION',
    style: 'horizontal',
    label: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__OUTPUTCONFIGURATION__HELP',
    description: '',
    elements: [
      {
        width: 6,
        identRef: 'PARAMETERS__OUTPUTCONFIGURATION_PARAMETERS',
      },
      {
        width: 6,
        identRef: 'PARAMETERS__OUTPUTCONFIGURATION__PICTURE_GROUP',
      },
    ],
  },
  PARAMETERS__FAULT_MONITORING_CONTACT__FAULT_MONITORING_CONTACT_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__FAULT_MONITORING_CONTACT__FAULT_MONITORING_CONTACT_GROUP',
    style: 'group',
    label: '01_04_0F_14_0020:PARAMETERS__FAULT_MONITORING_CONTACT__FAULT_MONITORING_CONTACT_GROUP__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__FAULT_MONITORING_CONTACT__FAULT_MONITORING_CONTACT_GROUP__HELP',
    description: '',
    elements: [
      {
        position: 'faultsignal_overrange',
        identRef: deviceModelStatusDescriptorCollection.faultsignal_overrange.identRef,
      },
      {
        position: 'faultsignal_underrange',
        identRef: deviceModelStatusDescriptorCollection.faultsignal_underrange.identRef,
      },
      {
        position: 'faultsignal_linebreak',
        identRef: deviceModelStatusDescriptorCollection.faultsignal_linebreak.identRef,
      },
      {
        position: 'faultsignal_shorted',
        identRef: deviceModelStatusDescriptorCollection.faultsignal_shorted.identRef,
      },
      {
        position: 'faultsignal_modulerror',
        identRef: deviceModelStatusDescriptorCollection.faultsignal_modulerror.identRef,
      },
    ],
  },
  PARAMETERS__FAULT_MONITORING_CONTACT__PARAMETERS: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__FAULT_MONITORING_CONTACT__PARAMETERS',
    help: '01_04_0F_14_0020:PARAMETERS__FAULT_MONITORING_CONTACT__PARAMETERS__HELP',
    label: '01_04_0F_14_0020:PARAMETERS__FAULT_MONITORING_CONTACT__PARAMETERS__LABEL',
    description: '',
    elements: [{
      identRef: 'PARAMETERS__FAULT_MONITORING_CONTACT__FAULT_MONITORING_CONTACT_GROUP',
    },
    ],
  },
  PARAMETERS__FAULT_MONITORING_CONTACT__PICTURE_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__FAULT_MONITORING_CONTACT__PICTURE_GROUP',
    style: 'group',
    help: '',
    label: '',
    description: '',
    elements: [
      {
        identRef: 'ctrlPARAMETERS__FAULT_MONITORING_CONTACT__PICTURE',
      },
    ],
  },
  PARAMETERS__FAULT_MONITORING_CONTACT: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__FAULT_MONITORING_CONTACT',
    style: 'horizontal',
    label: '01_04_0F_14_0020:PARAMETERS__FAULT_MONITORING_CONTACT__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__FAULT_MONITORING_CONTACT__HELP',
    description: '',
    elements: [
      {
        width: 6,
        identRef: 'PARAMETERS__FAULT_MONITORING_CONTACT__PARAMETERS',
      },
      {
        width: 6,
        identRef: 'PARAMETERS__FAULT_MONITORING_CONTACT__PICTURE_GROUP',
      },
    ],
  },
  PARAMETERS__INPUTCONFIGURATION__PICTURE_GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__INPUTCONFIGURATION__PICTURE_GROUP',
    style: 'group',
    help: '',
    label: '',
    description: '',
    elements: [
      {
        identRef: 'ctrlPARAMETERS__INPUTCONFIGURATION__PICTURE',
      },
    ],
  },
  PARAMETERS__INPUTCONFIGURATION: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__INPUTCONFIGURATION',
    style: 'horizontal',
    label: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__LABEL',
    help: '01_04_0F_14_0020:PARAMETERS__INPUTCONFIGURATION__HELP',
    description: '',
    elements: [
      {
        width: 6,
        identRef: 'PARAMETERS__INPUTCONFIGURATION__PARAMETERS',
      },
      {
        width: 6,
        identRef: 'PARAMETERS__INPUTCONFIGURATION__PICTURE_GROUP',
      },
    ],
  },
  PARAMETERS: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS',
    style: 'tabs',
    help: '01_04_0F_14_0020:PARAMETERS__HELP',
    label: '01_04_0F_14_0020:PARAMETERS__LABEL',
    description: '',
    elements: [{
      position: 'page1',
      identRef: 'PARAMETERS__DEVICEIDENTIFICATION',
    },
    {
      position: 'page2',
      identRef: 'PARAMETERS__INPUTCONFIGURATION',
    },
    {
      position: 'page3',
      identRef: 'PARAMETERS__OUTPUTCONFIGURATION',
    },
    {
      position: 'page4',
      identRef: 'PARAMETERS__FAULT_MONITORING_CONTACT',
    },
    ],
  },
  PARAMETERS__LIN_TABLE: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__LIN_TABLE',
    style: 'group',
    help: '',
    label: 'Linearization control',
    description: '',
    elements: [
      {
        identRef: 'PARAMETERS__LINEARIZATION_CONTROL',
      },
    ],
  },
  PARAMETERS__RANGEWITHSPAN: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__RANGEWITHSPAN',
    style: 'group',
    help: '',
    label: 'RangeWithSpan control',
    description: '',
    elements: [
      {
        identRef: 'ctrlRANGEWITHSPAN',
      },
    ],
  },
  PARAMETERS__DIP_SWITCH__GROUP: {
    type: DeviceModelStatus.StatusType.StructureDescriptor,
    identRef: 'PARAMETERS__DIP_SWITCH__GROUP',
    help: '',
    label: '01-04-0f-0e-0020-2902049:loc_DIPSWITCH_S1__LABEL',
    description: '',
    style: 'group',
    elements: [
      {
        identRef: 'ctrlPARAMETERS__DIPSWITCH',
      },
    ],
  },
};

export default deviceModelStructureDescriptorParameters;
