/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
// eslint-disable-next-line max-len
import React, { RefObject, useEffect, useState } from 'react';

const calculateMenuXPosition = (svgButtonRef: React.RefObject<SVGImageElement>)
: number | undefined => {
  const btnLeftPosition = svgButtonRef?.current?.getClientRects()?.[0]?.left;
  const btnRightPosition = svgButtonRef?.current?.getClientRects()?.[0]?.right;
  return btnLeftPosition === undefined || btnRightPosition === undefined
    ? undefined : (btnRightPosition + btnLeftPosition) / 2;
};

const calculateMenuYPosition = (svgButtonRef: React.RefObject<SVGImageElement>)
: number | undefined => {
  const btnTopPosition = svgButtonRef?.current?.getClientRects()?.[0]?.top;
  const btnBottomPosition = svgButtonRef?.current?.getClientRects()?.[0]?.bottom;
  return btnTopPosition === undefined || btnBottomPosition === undefined
    ? undefined : (btnTopPosition + btnBottomPosition) / 2;
};

// eslint-disable-next-line no-unused-vars
const useMenuPostion = (svgButtonRef: RefObject<SVGImageElement>)
: [number | undefined, number | undefined] => {
  // eslint-disable-next-line max-len
  const [menuXPosition, setMenuXPosition] = useState(calculateMenuXPosition(svgButtonRef));
  // eslint-disable-next-line max-len
  const [menuYPosition, setMenuYPosition] = useState(calculateMenuYPosition(svgButtonRef));

  useEffect(() => {
    const element = svgButtonRef?.current;
    const observer = new ResizeObserver(() => {
      if (element) {
        const menuXPos = calculateMenuXPosition(svgButtonRef);
        const menuYPos = calculateMenuYPosition(svgButtonRef);
        setMenuXPosition(menuXPos);
        setMenuYPosition(menuYPos);
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [svgButtonRef]);

  return [menuXPosition, menuYPosition];
};

export default useMenuPostion;
