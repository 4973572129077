/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';

export interface GroupTableSectionTitleProps {
  subtitle: string;
}

export const GroupTableSectionTitle: React.FC<GroupTableSectionTitleProps> = (
  props: GroupTableSectionTitleProps,
) : React.ReactElement => {
  const {
    subtitle,
  } = props;
  const { t } = useTranslation();

  return (
    <tr>
      <td colSpan={5}>{t(subtitle)}</td>
    </tr>
  );
};
