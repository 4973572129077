/* eslint-disable @typescript-eslint/no-empty-function */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

/* eslint-disable max-len */
import React from 'react';
import { DatasetType } from '../store/deviceInstances/store/deviceDataset/types';

export interface DatasetContextProps {
  // eslint-disable-next-line no-unused-vars
  targetDataset: DatasetType;
}

export const DatasetContext = React.createContext<DatasetContextProps>({
  targetDataset: DatasetType.user,
});
