/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { DeviceInstanceActionPayload } from '../../common';

export const IOLINK__TRIO3_CHANNEL_SWITCH__SET_CHANNEL_STATE = 'IOLINK__TRIO3_CHANNEL_SWITCH__SET_CHANNEL_STATE';
export const IOLINK__TRIO3_CHANNEL_SWITCH__ACTIONS = 'IOLINK__TRIO3_CHANNEL_SWITCH__ACTIONS';

export enum Trio3ChannelSwitchState {
  // eslint-disable-next-line no-unused-vars
  Busy = 'busy',
  // eslint-disable-next-line no-unused-vars
  Done = 'done',
}

export interface IoLinkTrio3ChannelSwitchState {
  [key: string]: Trio3ChannelSwitchState;
}

/* ************************************************************************************ */
/* ACTION: Trio3ChannelSwitchSetStateAction                                               */
/* ************************************************************************************ */
export interface Trio3SwitchChannelSetStatePayload extends DeviceInstanceActionPayload {
  ident: string;
  channel: number;
  state: Trio3ChannelSwitchState;
}

export interface Trio3ChannelSwitchSetStateAction extends Action<typeof IOLINK__TRIO3_CHANNEL_SWITCH__SET_CHANNEL_STATE> {
  payload: Trio3SwitchChannelSetStatePayload;
}

export type typeIoLinkTrio3SwitchChannelActions = Trio3ChannelSwitchSetStateAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceInstanceIoLinkSystemCommandAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceIoLinkTrio3SwitchChannelPayload extends DeviceInstanceActionPayload {
  action: typeIoLinkTrio3SwitchChannelActions;
}

export interface typeDeviceInstanceIoLinkTrio3SwitchChannelAction extends Action<typeof IOLINK__TRIO3_CHANNEL_SWITCH__ACTIONS> {
  payload: typeDeviceInstanceIoLinkTrio3SwitchChannelPayload;
}
