/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
const appVersion = process.env.REACT_APP_VERSION;

const parseVersion = (version: string | undefined): string => {
  if (version === undefined) {
    return 'undefined';
  }
  const regVersion: RegExpMatchArray | null = version.match(/(.*?)\+.*/);
  return (regVersion && regVersion.length === 2) ? regVersion[1] : version;
};

// eslint-disable-next-line import/prefer-default-export
export const PackageVersion = parseVersion(appVersion);
