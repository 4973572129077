/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import React from 'react';
import { UnsupportedControl } from '../../../components/UnsupportedControl';
import { useContextDatasetStatusValue } from '../../../hooks/useContextDataset';
import ReduxControl from '../../ReduxControl/ReduxControl';
import { StructureItemStateChangeCallback } from '../../ReduxControlProps';

export interface MenuSelectorControlProps {
  identRef: IdentRef;
  control: DeviceModelStatus.UI.SelectControlType;
  onStateChanged: StructureItemStateChangeCallback;
  deviceInstanceId: string;
}

const MenuSelectorControl: React.FC<MenuSelectorControlProps> = (props: MenuSelectorControlProps)
    : React.ReactElement => {
  const {
    control, identRef, onStateChanged, deviceInstanceId,
  } = props;
  const variableValue = useContextDatasetStatusValue(deviceInstanceId, control.variable);

  if (variableValue === undefined) {
    return <UnsupportedControl text={`MenuSelectorControl: ${control.variable} undefined status values`} />;
  }

  const itemIdentRefs = control.CASE
    .find((item) => item.value === variableValue.value)?.ITEMS ?? control.DEFAULT;

  const controls = itemIdentRefs.map((menuItem) => (
    <ReduxControl
      identRef={menuItem.identRef}
      deviceInstanceId={deviceInstanceId}
      key={`select--${identRef}--${menuItem.identRef}`}
      onStateChanged={onStateChanged}
    />
  ));

  return (
    <>{controls}</>
  );
};

export default MenuSelectorControl;
