/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */

import { isAbortError } from '@gpt/cxe-dp-integration';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import expandIcon from '../../../assets/icons/icon-expand.svg';
import AppFileSystemService from '../../../services/AppService/AppFileSystemService';
import { useTypedSelector } from '../../../store';
import {
  setLinearizationDatasetKaltstelle,
  setLinearizationDatasetPointX,
  setLinearizationDatasetPointY,
  setLinearizationUserPoints,
  setSplineCalculationStatus,
  showLinearizationMessage,
} from '../../../store/linearizationDataset/actions';
import { SplineCalculationStatus } from '../../../store/linearizationDataset/types';
import {
  createLinFileContent, LinearizationFile, LoadLinearizationError, parseLinearizationFile,
} from './fileFunctions';
import { FullTableView } from './TableContent/FullTableView/FullTableView';
import { SmallTableView } from './TableContent/SmallTableView/SmallTableView';
import { ActionSubmenuTypes } from './TableExpand/LinearizationActionSubmenu';

export interface LinearizationTableControlProps {
    fullView: boolean;
}

export const LinearizationTableControl:React.FC<LinearizationTableControlProps> = (props: LinearizationTableControlProps): React.ReactElement => {
  const {
    fullView,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const label = useTypedSelector((state) => state.linearizationDataset.table.label);
  const linearizationData = useTypedSelector((state) => state.linearizationDataset.table.linearizationData);
  const pointsCounter = useTypedSelector((state) => state.linearizationDataset.pointsCounter);
  const coldJunctionComp80 = useTypedSelector((state) => state.linearizationDataset.coldJunctionComp80);
  const header1 = useTypedSelector((state) => state.linearizationDataset.table.header.header1);
  const header2 = useTypedSelector((state) => state.linearizationDataset.table.header.header2);

  const currentSensorType = useTypedSelector((state) => state.linearizationDataset.sensorType);
  const coldJunctionComp80Value = coldJunctionComp80 === undefined ? 0 : coldJunctionComp80.value;

  const linTableVisibleItems = linearizationData.filter((value, index) => index <= pointsCounter.value - 1);

  const onXValueChange = useCallback((index: number, pointValue: string) => {
    const value = parseFloat(pointValue);
    if (!Number.isNaN(value)) {
      dispatch(setLinearizationDatasetPointX({ index, value }));
    }
  }, []);

  const onYValueChange = useCallback((index: number, pointValue: string) => {
    const value = parseFloat(pointValue);
    if (!Number.isNaN(value)) {
      dispatch(setLinearizationDatasetPointY({ index, value }));
    }
  }, []);

  const onSubmenuAction = async (act: ActionSubmenuTypes): Promise<void> => {
    const readLinearizationFile = async (): Promise<void> => {
      let fileContents: string;
      const fileFilter = currentSensorType === 'RTD' ? ['.urt', '.usr'] : ['.utc', '.usr'];

      try {
        const importResult = await AppFileSystemService.ImportLocalFile({
          mimeType: `application/pxc-lin-char${fileFilter[0]}`,
          extensions: [...fileFilter],
        });
        fileContents = importResult.contents as string;
      } catch (error) {
        if (isAbortError(error)) {
          dispatch(showLinearizationMessage({ type: 'warning', text: t<string>('LINTABLE__ERROR__CANCELLED') }));
        } else {
          dispatch(showLinearizationMessage({ type: 'error', text: (error as Error).message }));
        }
        return;
      }

      let errorMessage;
      let linTable: LinearizationFile | undefined;
      try {
        linTable = await parseLinearizationFile(fileContents);
      } catch (error) {
        if (error instanceof LoadLinearizationError) {
          errorMessage = `${t(error.message)}:${error.reason}`;
        } else { // other/unknown error
          errorMessage = `${(error as Error)?.message ?? ''}`;
        }
      }

      if (errorMessage !== undefined) {
        dispatch(setSplineCalculationStatus(SplineCalculationStatus.error));
        dispatch(showLinearizationMessage({ type: 'error', text: errorMessage }));
        return;
      }

      if (linTable === undefined) {
        dispatch(setSplineCalculationStatus(SplineCalculationStatus.error));
        dispatch(showLinearizationMessage({ type: 'error', text: t<string>('LINTABLE__ERROR__CANNOT_PARSE_FILE') }));
        return;
      }

      dispatch(setLinearizationUserPoints(linTable.linearizationData));
      dispatch(setLinearizationDatasetKaltstelle(linTable.cold80Value));
      dispatch(showLinearizationMessage({ type: 'success', text: t<string>('LINTABLE__SUCCESS__FILE_LOADED') }));
    };

    const writeLinearizationFile = async () => {
      let errorMessage;
      try {
        const contents = await createLinFileContent({
          label: t<string>(label),
          type: currentSensorType,
          cold80Value: currentSensorType === 'TC' ? coldJunctionComp80Value : 0,
          linearizationData: linearizationData.slice(0, pointsCounter.value),
        });

        // UTC für TC
        // URT für RTD
        // USR ->  UI
        let fileExt: string;
        switch (currentSensorType) {
          case 'TC':
            fileExt = '.utc';
            break;
          case 'RTD':
            fileExt = '.urt';
            break;
          // case 'UI':
          //   fileExt = 'usr';
          //   break;
          default:
            fileExt = '.urt';
        }

        try {
          await AppFileSystemService.ExportLocalFile({
            suggestedName: `linearization.${fileExt}`,
            mimeType: `application/pxc-lin-char${fileExt}`,
            extensions: [fileExt],
            contents,
          });
        } catch (error) {
          if (isAbortError(error)) {
            errorMessage = t<string>('LINTABLE__ERROR__SAVE_CANCELLED');
          } else {
            errorMessage = t<string>('LINTABLE__ERROR__CANNOT_SAVE_FILE');
          }
        }
      } catch (error) {
        if (error instanceof LoadLinearizationError) {
          errorMessage = `${t(error.message)}:${error.reason}`;
        } else { // other/unknown error
          errorMessage = `${(error as Error).message}`;
        }
      }

      const action = errorMessage !== undefined
        ? showLinearizationMessage({ type: 'warning', text: errorMessage })
        : showLinearizationMessage({ type: 'success', text: t<string>('LINTABLE__SUCCESS__FILE_SAVE') });
      dispatch(action);
    };

    switch (act) {
      case 'load': await readLinearizationFile(); break;
      case 'save': await writeLinearizationFile(); break;
      default:
        break;
    }
  };

  return (
    <div>
      {fullView
      && (
        <FullTableView
          items={linTableVisibleItems}
          expandIcon={expandIcon}
          header1={header1}
          header2={header2}
          pointsCounter={pointsCounter.value}
          onXValueChange={onXValueChange}
          onYValueChange={onYValueChange}
          onAction={(action) => onSubmenuAction(action)}
        />
      )}
      { !fullView
      && (
        <SmallTableView
          items={linTableVisibleItems}
          expandIcon={expandIcon}
          header1={header1}
          header2={header2}
          pointsCounter={pointsCounter.value}
          onXValueChange={onXValueChange}
          onYValueChange={onYValueChange}
          onAction={(action) => onSubmenuAction(action)}
        />
      )}
    </div>
  );
};
