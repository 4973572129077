/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { InputGroupTable } from './GroupTable/InputGroupTable';
import { OutputGroupTable } from './GroupTable/OutputGroupTable';
import './GroupProcessDataTable.scss';

export interface GroupProcessDataTableProps {
  stationInstanceId: string;
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType;
}

const GroupProcessDataTable: React.FC<GroupProcessDataTableProps> = (
  props: GroupProcessDataTableProps,
) : React.ReactElement => {
  const { stationDeviceDescription, stationInstanceId } = props;
  return (
    <div className="page-station-group-process-data__table contactron-commissioning-wizard">
      <InputGroupTable
        stationInstanceId={stationInstanceId}
        stationDeviceDescription={stationDeviceDescription}
      />
      <div className="page-station-group-process-data__table__splitter" />
      <OutputGroupTable
        stationInstanceId={stationInstanceId}
        stationDeviceDescription={stationDeviceDescription}
      />
    </div>
  );
};

export default GroupProcessDataTable;
