/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { ReduxControlProps } from '../../ReduxControlProps';
import OnlineCaparocStationControl from './OnlineCaparocStationControl/OnlineCaparocStationControl';
import ReduxControl from '../../ReduxControl/ReduxControl';
import TabViewControl from '../../TabViewControl/TabViewControl';
import useMenuCurrentTab, { TabData } from '../../hooks/TabMenuHooks/useMenuCurrentTab';
import TabViewItem from '../../TabViewControl/TabViewItem/TabViewItem';
import './OnlineCaparocStation.scss';

const controlTabLabel = 'CAPAROC:locM_Online_Control__LABEL';
const controlTabHelp = 'CAPAROC:locM_Online_Control__HELP';

const identTabLabel = 'CAPAROC:locM_Ident__LABEL';
const identTabHelp = 'CAPAROC:locM_Ident__HELP';

const eventsTabLabel = 'CAPAROC:locM_Online_Events__LABEL';
const eventsTabHelp = 'CAPAROC:locM_Online_Events__HELP';

const controlTabData: TabData = {
  label: controlTabLabel,
  help: controlTabHelp,
  identRef: 'caparoc-online-tabs--tab-control',
};

const identTabData: TabData = {
  label: identTabLabel,
  help: identTabHelp,
  identRef: 'caparoc-online-tabs--tab-ident',
};

const eventsTabData: TabData = {
  label: eventsTabLabel,
  help: eventsTabHelp,
  identRef: 'caparoc-online-tabs--tab-events',
};

const tabDataset = [
  controlTabData,
  identTabData,
  eventsTabData,
];

const OnlineCaparocStation: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const [currentTab, setCurrentTab] = useMenuCurrentTab(identRef, deviceInstanceId, tabDataset);

  return (
    <div className="online-caparoc-station">
      <TabViewControl
        actionId="online-caparoc-tabs"
        currentTab={currentTab}
        setCurrentTab={(tabid) => setCurrentTab(deviceInstanceId, identRef, tabid)}
      >
        <TabViewItem
          tabIdent={controlTabData.identRef}
          tabLabel={controlTabData.label}
          tabHelp={controlTabData.help}
          tabColor="normal"
        >
          <div className="online-caparoc-station__element">
            <OnlineCaparocStationControl identRef="--" deviceInstanceId={deviceInstanceId} onStateChanged={() => 0} />
          </div>
        </TabViewItem>
        <TabViewItem
          tabIdent={identTabData.identRef}
          tabLabel={identTabData.label}
          tabHelp={identTabData.help}
          tabColor="normal"
        >
          <div className="online-caparoc-station__element">
            <ReduxControl identRef="M_StationOnlineParam__IDENT" deviceInstanceId={deviceInstanceId} onStateChanged={() => 0} />
          </div>
        </TabViewItem>
        <TabViewItem
          tabIdent={eventsTabData.identRef}
          tabLabel={eventsTabData.label}
          tabHelp={eventsTabData.help}
          tabColor="normal"
        >
          <div className="online-caparoc-station__element">
            <ReduxControl identRef="M_StationOnlineParam__EVENTS" deviceInstanceId={deviceInstanceId} onStateChanged={() => 0} />
          </div>
        </TabViewItem>
      </TabViewControl>
    </div>
  );
};

export default OnlineCaparocStation;
