/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../GroupProcessDataTable.scss';

const GroupTableHeader: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <thead className="page-group-process-data-table-header">
      <tr>
        <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_POSITION')}</th>
        <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_TAG')}</th>
        <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_IFS_ADDRESS')}</th>
        <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_OFFSET')}</th>
        <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_PROCESS_VALUE_NAME')}</th>
        <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_TYPE')}</th>
      </tr>
    </thead>
  );
};

export default GroupTableHeader;
