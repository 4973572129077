/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DIPSwitch } from '../DIPSwitch/DIPSwitch';
import './DIPSwitchNumber.css';

export interface DIPSwitchNumberProps {
  id: string;
  isOn: boolean;
  label: string;
  changed: boolean;
  readonly?: boolean;
  // eslint-disable-next-line no-unused-vars
  onSwitchChange: (switchState: boolean) => void;
}

export const DIPSwitchNumber: React.FC<DIPSwitchNumberProps> = (props: DIPSwitchNumberProps):
  React.ReactElement => {
  const {
    isOn, label, changed, onSwitchChange, id,
    readonly,
  } = props;

  return (
    <div className="dip-switch-container">
      <div className={`dip-switch-container__header ${changed ? 'dip-switch-container__header--changed' : ''}`}>
        <span className="dip-switch-container__header__label">{label}</span>
      </div>
      <DIPSwitch
        id={id}
        isOn={isOn}
        readonly={readonly}
        onSwitchChange={(value) => onSwitchChange(value)}
      />
    </div>
  );
};
