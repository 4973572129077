/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../../../store';
import { showApplicationMessage } from '../../../../../../../../store/applicationMessage/actions';
import { contactronWizardSetProcessDataCheckbox } from '../../../../../../../../store/wizards/contactronStation';
import {
  i18nMessage, isMaxSelectionReached, MAX_SELECTED_STATION_INPUTS, MAX_SELECTED_STATION_OUTPUTS,
} from '../../../Helpers/processDataHelpers';

const useProcessDataCheckbox = (name: string, defaultValue: boolean, kind: 'input' | 'output') => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedStationIOs = useTypedSelector((state) => (kind === 'input'
    ? state.contactronInitWizard.totalInputSelected
    : state.contactronInitWizard.totalOutputSelected));

  const checked = useTypedSelector((state) => {
    const value = state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule][kind].dataCheckbox[name];
    return value ?? defaultValue;
  });
  const selectedModuleInputs = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].input.totalSelected);
  const selectedModuleOutputs = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].output.totalSelected);

  const address = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].instance.address);
  const applyAllData = useTypedSelector((state) => state.contactronInitWizard.applyAllData);
  const modulesCount = useTypedSelector((state) => Object.keys(state.contactronInitWizard.modules).length - 1);

  const onValueChange = useCallback((value: boolean) => {
    let setProcess = true;
    if (value === true) {
      let message: i18nMessage | undefined;
      if (kind === 'input' && selectedStationIOs === MAX_SELECTED_STATION_INPUTS) {
        message = {
          message: 'WIZARD_CONTACTRON__MESSAGE__STATION__MAX_SELECTED_INPUT_PROCESS_DATA',
          arg: `${MAX_SELECTED_STATION_INPUTS}`,
        };
      } else if (kind === 'output' && selectedStationIOs === MAX_SELECTED_STATION_OUTPUTS) {
        message = {
          message: 'WIZARD_CONTACTRON__MESSAGE__STATION__MAX_SELECTED_OUTPUT_PROCESS_DATA',
          arg: `${MAX_SELECTED_STATION_OUTPUTS}`,
        };
      } else {
        const preSelectedModuleInputs = kind === 'input' ? selectedModuleInputs + 1 : selectedModuleInputs;
        const preSelectedModuleOutputs = kind === 'output' ? selectedModuleOutputs + 1 : selectedModuleOutputs;
        message = isMaxSelectionReached(preSelectedModuleInputs, preSelectedModuleOutputs, applyAllData, modulesCount);
      }

      if (message !== undefined) {
        dispatch(showApplicationMessage('warning', t(message.message, {
          MAX_NUMBER: message.arg,
        })));
        setProcess = false;
      }
    }
    if (setProcess) {
      dispatch(contactronWizardSetProcessDataCheckbox({
        address,
        kind,
        name,
        value,
      }));
    }
  }, [address, name, selectedStationIOs, selectedModuleInputs, selectedModuleOutputs, applyAllData, modulesCount]);
  return [checked, onValueChange] as const;
};

export default useProcessDataCheckbox;
