/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaperControl } from '../../../../components/PaperControl/PaperControl';
import { ROOT_DEVICEINSTANCE_ID } from '../../../../helpers/createDeviceInstanceId';
import { SystemStatus } from './SystemStatus/SystemStatus';
import EventMonitorStatusReduxControl from '../../../EventMonitorStatus/EventMonitorStatusControl';

const varVoltageErrorStatus = 'var_PI_ProcessDataIn_TN_PD_VoltageError';
const varCumulativeErrorStatus = 'var_PI_ProcessDataIn_TN_PD_CumulativeError';
const varPercent80Status = 'var_PI_ProcessDataIn_TN_PD_80Percent';
const varTotalCurrentSysStatus = 'var_PI_ProcessDataIn_TN_PD_TotalCurrentSystem';

const CaparocSystemStatus: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <PaperControl title={t<string>('CAPAROC_STATION_SYSTEM_STATUS')}>
      <SystemStatus
        deviceInstanceId={ROOT_DEVICEINSTANCE_ID}
        identRef={varVoltageErrorStatus}
        value0color="gray"
        valueN0color="green"
      />
      <SystemStatus
        deviceInstanceId={ROOT_DEVICEINSTANCE_ID}
        identRef={varCumulativeErrorStatus}
        value0color="gray"
        valueN0color="red"
      />
      <SystemStatus
        deviceInstanceId={ROOT_DEVICEINSTANCE_ID}
        identRef={varPercent80Status}
        value0color="gray"
        valueN0color="yellow"
      />
      <SystemStatus
        deviceInstanceId={ROOT_DEVICEINSTANCE_ID}
        identRef={varTotalCurrentSysStatus}
        value0color="gray"
        valueN0color="red"
      />
      <EventMonitorStatusReduxControl
        deviceInstanceId={ROOT_DEVICEINSTANCE_ID}
        identRef="C_STATION_EVENT_MONITOR_STATUS"
        onStateChanged={() => 0}
      />
    </PaperControl>
  );
};

export default CaparocSystemStatus;
