/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import {
  CleanDeviceMethodExecutionState,
  DeviceMethodState,
  SetDeviceMethodExecutionStateAction,
  typeDeviceMethodActions,
  MethodStageExecutionStatus,
} from './types';

export const initialDeviceMethodState: DeviceMethodState = {};

const methodSetDeviceMethodExecutionState = (state: DeviceMethodState, action: SetDeviceMethodExecutionStateAction)
: DeviceMethodState => {
  const {
    methodIdent, message, stage, steps, acceptData, data,
  } = action.payload;

  return state[methodIdent] === undefined
    ? ({
      ...state,
      [methodIdent]: {
        stage: stage ?? MethodStageExecutionStatus.Initialize,
        steps: steps ?? [],
        message,
        data,
        acceptData,
      },
    })
    : ({
      ...state,
      [methodIdent]: {
        ...state[methodIdent],
        stage: stage ?? state[methodIdent].stage,
        steps: steps ?? state[methodIdent].steps,
        message: message,
        data: data ?? state[methodIdent].data,
        acceptData: acceptData ?? state[methodIdent].acceptData,
      },
    });
};

const cleanupDeviceMethodExecutionState = (state: DeviceMethodState, action: CleanDeviceMethodExecutionState)
: DeviceMethodState => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [action.payload]: value, ...remainder } = state;
  return remainder;
};

const deviceMethodStateReducer = (
  state = initialDeviceMethodState,
  action: typeDeviceMethodActions,
): DeviceMethodState => {
  switch (action.type) {
    case 'METHOD_EXECUTION__SET_EXECUTION_STATE':
      return methodSetDeviceMethodExecutionState(state, action);
    case 'METHOD_EXECUTION__CLEANUP':
      return cleanupDeviceMethodExecutionState(state, action);
    default:
  }
  return state;
};

export default deviceMethodStateReducer;
