/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ButtonState, ButtonRole, deviceWizardUpdateButtons, deviceWizardSetButtons,
} from '../../../../store/deviceWizard';
import { WizardActionTypes } from '../../../../store/deviceWizard/types';
import { WIZARD_BUTTON_LABEL__NEXT, WIZARD_BUTTON_LABEL__TRANSFER } from '../../../helper';

export enum CommissioningWizardState {
    // eslint-disable-next-line no-unused-vars
    WizardInit = 'wizard_init',
    // eslint-disable-next-line no-unused-vars
    Page1SelectDeviceInit = 'page1_init',
    // eslint-disable-next-line no-unused-vars
    Page2StationAddressingInit = 'page2_init',
    // eslint-disable-next-line no-unused-vars
    Page2StationAddressingScanStart = 'page2_scan_start',
    // eslint-disable-next-line no-unused-vars
    Page2StationAddressingScanFinish = 'page2_scan_finish',
    // eslint-disable-next-line no-unused-vars
    Page3DeviceIdentificationInit = 'page3_init',
    // eslint-disable-next-line no-unused-vars
    Page4DeviceNamingInit = 'page4_init',
    // eslint-disable-next-line no-unused-vars
    Page5SelectProcessDataInit = 'page5_init',
    // eslint-disable-next-line no-unused-vars
    Page6GroupProcessDataInit = 'page6_init',
    // eslint-disable-next-line no-unused-vars
    Page7ReportInit = 'page7_init',
    // eslint-disable-next-line no-unused-vars
    Page7ReportDone = 'page7_done',
}

const wizardButtonStates = (state: CommissioningWizardState): WizardActionTypes => {
  let result: WizardActionTypes;
  switch (state) {
    case CommissioningWizardState.Page1SelectDeviceInit:
      result = deviceWizardSetButtons({
        abort: { state: ButtonState.enabled },
        back: { state: ButtonState.disabled },
        next: { state: ButtonState.disabled, label: WIZARD_BUTTON_LABEL__NEXT },
        load: { state: ButtonState.hidden },
        complete: { state: ButtonState.hidden },
        pcta: ButtonRole.next,
        focusPcta: ButtonRole.next,
      });
      break;
    case CommissioningWizardState.Page2StationAddressingInit:
      result = deviceWizardSetButtons({
        abort: { state: ButtonState.enabled },
        back: { state: ButtonState.enabled },
        next: { state: ButtonState.disabled, label: WIZARD_BUTTON_LABEL__NEXT },
        load: { state: ButtonState.hidden },
        complete: { state: ButtonState.hidden },
        pcta: ButtonRole.next,
        focusPcta: ButtonRole.next,
      });
      break;
    case CommissioningWizardState.Page2StationAddressingScanStart:
      result = deviceWizardSetButtons({
        abort: { state: ButtonState.enabled },
        back: { state: ButtonState.enabled },
        next: { state: ButtonState.disabled, label: WIZARD_BUTTON_LABEL__NEXT },
        load: { state: ButtonState.hidden },
        complete: { state: ButtonState.hidden },
        pcta: ButtonRole.next,
        focusPcta: ButtonRole.next,
      });
      break;
    case CommissioningWizardState.Page2StationAddressingScanFinish:
      result = deviceWizardSetButtons({
        abort: { state: ButtonState.enabled },
        back: { state: ButtonState.enabled },
        next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__NEXT },
        load: { state: ButtonState.hidden },
        complete: { state: ButtonState.hidden },
        pcta: ButtonRole.next,
        focusPcta: ButtonRole.next,
      });
      break;
    case CommissioningWizardState.Page3DeviceIdentificationInit:
      result = deviceWizardSetButtons({
        abort: { state: ButtonState.enabled },
        back: { state: ButtonState.enabled },
        next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__NEXT },
        load: { state: ButtonState.hidden },
        complete: { state: ButtonState.hidden },
        pcta: ButtonRole.next,
        focusPcta: ButtonRole.next,
      });
      break;
    case CommissioningWizardState.Page4DeviceNamingInit:
      result = deviceWizardSetButtons({
        abort: { state: ButtonState.enabled },
        back: { state: ButtonState.enabled },
        next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__NEXT },
        load: { state: ButtonState.hidden },
        complete: { state: ButtonState.hidden },
        pcta: ButtonRole.next,
        focusPcta: ButtonRole.next,
      });
      break;
    case CommissioningWizardState.Page5SelectProcessDataInit:
      result = deviceWizardSetButtons({
        abort: { state: ButtonState.enabled },
        back: { state: ButtonState.enabled },
        next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__NEXT },
        load: { state: ButtonState.hidden },
        complete: { state: ButtonState.hidden },
        pcta: ButtonRole.next,
        focusPcta: ButtonRole.next,
      });
      break;
    case CommissioningWizardState.Page6GroupProcessDataInit:
      result = deviceWizardSetButtons({
        abort: { state: ButtonState.enabled },
        back: { state: ButtonState.enabled },
        next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__TRANSFER },
        complete: { state: ButtonState.hidden },
        pcta: ButtonRole.next,
        focusPcta: ButtonRole.next,
      });
      break;
    case CommissioningWizardState.Page7ReportInit:
      result = deviceWizardUpdateButtons({
        abort: ButtonState.disabled,
        back: ButtonState.disabled,
        next: ButtonState.hidden,
        load: ButtonState.hidden,
        complete: ButtonState.disabled,
        pcta: ButtonRole.complete,
      });
      break;
    case CommissioningWizardState.Page7ReportDone:
      result = deviceWizardUpdateButtons({
        abort: ButtonState.enabled,
        back: ButtonState.enabled,
        next: ButtonState.hidden,
        load: ButtonState.hidden,
        complete: ButtonState.enabled,
        pcta: ButtonRole.complete,
      });
      break;
    default:
      result = deviceWizardUpdateButtons({
        abort: ButtonState.disabled,
        back: ButtonState.disabled,
        next: ButtonState.disabled,
        load: ButtonState.hidden,
        complete: ButtonState.hidden,
        pcta: ButtonRole.next,
      });
  }
  return result;
};

export const useCommissioningWizardState = (initState?: CommissioningWizardState) => {
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState(initState ?? CommissioningWizardState.WizardInit);

  const userSetWizardState = useCallback((state: CommissioningWizardState) => {
    if (state === CommissioningWizardState.WizardInit) {
      return;
    }
    const wizardButtonAction = wizardButtonStates(state);
    setWizardState(state);
    dispatch(wizardButtonAction);
  }, []);
  return [wizardState, userSetWizardState] as const;
};
