/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { IdentRef, DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { useTypedSelector } from '../../store';
import { deviceDescriptorSelector } from '../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import { ReduxControlProps } from '../ReduxControlProps';
import withControlVisibility from '../hoc/withControlVisibility';
import MotorStarterCockpit from './MotorStarterCockpit/MotorStarterCockpit';
import H3MotorStarterOnlineControl from './MotorStarterOnlineControl/H3MotorStarterOnlineControl';
import H5MotorStarterOnlineControl from './MotorStarterOnlineControl/H5MotorStarterOnlineControl';
import CockpitContactronStation from './CockpitContactronStation/CockpitContactronStation';

const contactronControlSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
  targetDataset: DatasetType,
  identRef: IdentRef,
): DeviceModelStatus.UI.Contactron.ContactronControlType | undefined => {
  const descriptor = deviceDescriptorSelector(state, deviceInstanceId, targetDataset, identRef);
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  return controlType?.type !== DeviceModelStatus.UI.ControlType.CTLCONTACTRON
    ? undefined
    : controlType;
};

const ContactronControlSelector: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const contactron = useTypedSelector((state) => contactronControlSelector(state.deviceInstances, deviceInstanceId, DatasetType.device, identRef));
  if (contactron?.deviceViewType?.type === DeviceModelStatus.UI.Contactron.DeviceViewType.CTLCOCKPITMOTORSTARTER) {
    return <MotorStarterCockpit deviceInstanceId={deviceInstanceId} identRef={identRef} onStateChanged={() => 0} />;
  }
  if (contactron?.deviceViewType?.type === DeviceModelStatus.UI.Contactron.DeviceViewType.CTLONLINECONTROLH3MOTORSTARTER) {
    return <H3MotorStarterOnlineControl deviceInstanceId={deviceInstanceId} identRef={identRef} onStateChanged={() => 0} />;
  }
  if (contactron?.deviceViewType?.type === DeviceModelStatus.UI.Contactron.DeviceViewType.CTLONLINECONTROLH5MOTORSTARTER) {
    return <H5MotorStarterOnlineControl deviceInstanceId={deviceInstanceId} identRef={identRef} onStateChanged={() => 0} />;
  }
  if (contactron?.deviceViewType?.type === DeviceModelStatus.UI.Contactron.DeviceViewType.CTLCOCKPITSTATION) {
    return <CockpitContactronStation deviceInstanceId={deviceInstanceId} deviceViewType={contactron.deviceViewType} />;
  }
  return <div>Unknown</div>;
};

export default withControlVisibility(ContactronControlSelector);
