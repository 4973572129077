/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

// eslint-disable-next-line import/prefer-default-export
export enum CaparocChannelStateType {
    // eslint-disable-next-line no-unused-vars
    OFF = 'off',
    // eslint-disable-next-line no-unused-vars
    ERROR = 'error',
    // eslint-disable-next-line no-unused-vars
    WARNING = 'warning',
    // eslint-disable-next-line no-unused-vars
    ON = 'on'
  }
