/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../store';
import { ContactronWizardGroupMode } from '../../../../../../store/wizards/contactronStation';
import GroupTableRow from './GroupTableRow/GroupTableRow';
import { groupByDevices, groupByProcessValueNames } from './common';
import { GroupTableSectionTitle } from './GroupTableSectionTitle/GroupTableSectionTitle';
import GroupTableHeader from './GroupTableHeader/GroupTableHeader';
import '../GroupProcessDataTable.scss';

export interface InputGroupTableProps {
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType;
  stationInstanceId: string;
}

export const InputGroupTable: React.FC<InputGroupTableProps> = (props: InputGroupTableProps): React.ReactElement => {
  const {
    stationDeviceDescription, stationInstanceId,
  } = props;
  const { t } = useTranslation();
  const moduleCollection = useTypedSelector((state) => state.contactronInitWizard.modules);
  const groupMode = useTypedSelector((state) => state.contactronInitWizard.groupMode);

  const stationRowsData = groupByDevices(moduleCollection, stationDeviceDescription, 'input', 'station');

  const rowsData = groupMode === ContactronWizardGroupMode.Device
    ? groupByDevices(moduleCollection, stationDeviceDescription, 'input', 'module')
    : groupByProcessValueNames(moduleCollection, stationDeviceDescription, 'input', 'module');

  const moduleRows = [...stationRowsData, ...rowsData]
    .reduce((acc, row, index) => {
      const pdcIndex = index % 16;
      const rows = pdcIndex === 0
        ? [
          ...acc,
          <GroupTableSectionTitle
            key={`input-pdc--${row.key}`}
            subtitle={t(index > 15 ? 'WIZARD_CONTACTRON__TABLE__INPUT_PDC_49_64' : 'WIZARD_CONTACTRON__TABLE__INPUT_PDC_33_48')}
          />,
        ]
        : [
          ...acc,
        ];

      return [
        ...rows,
        <GroupTableRow
          key={row.key}
          index={index + 1}
          row={row}
          stationInstanceId={stationInstanceId}
          pdcIndex={pdcIndex}
        />,
      ];
    }, [] as React.ReactElement[]);

  return (
    <>
      <div className="page-group-process-data-table-title">
        <div className="page-group-process-data-table-title-text">{t('WIZARD_CONTACTRON__PAGE6_INPUT_PROCESS_DATA__TITLE')}</div>
      </div>
      <table className="page-group-process-data-table">
        <GroupTableHeader />
        <tbody>
          {moduleRows}
        </tbody>
      </table>
    </>
  );
};
