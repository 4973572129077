const Console = console;
/* eslint-disable max-len */
const internalFetchMethod = async <TReq, TRes>(url: string, method: string, req?: TReq): Promise<TRes | undefined> => {
  let data: TRes | undefined;
  try {
    const requestInit = req === undefined
      ? {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
      }
      : {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      };

    // http://localhost:8080
    const response = await fetch(url, requestInit);
    if (response.ok) {
      data = await response.json() as TRes;
    } else {
      data = undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    Console.log(`Cannot fetch <${url}> data: ${err?.message ?? ''}`);
    data = undefined;
  }
  return data;
};

export const fetchWriteData = async <TReq>(url: string, req: TReq, maxRetry?: number, retryInterval?: number): Promise<boolean> => {
  // eslint-disable-next-line no-unused-vars, max-len
  const fetchPutData = async (counter: number, timeout: number, resolve: (value: boolean) => void): Promise<void> => {
    if (counter === 0) {
      resolve(false);
      return;
    }
    const response = await internalFetchMethod<TReq, boolean>(url, 'PUT', req);
    if (response === undefined) {
      setTimeout(() => fetchPutData(counter - 1, timeout, resolve), timeout);
    } else {
      resolve(response);
    }
  };

  return new Promise<boolean>((resolve) => {
    fetchPutData(maxRetry ?? 10, retryInterval ?? 100, resolve);
  });
};

export const fetchReadData = async <TReq, TRes>(url: string, req: TReq, maxRetry?: number, retryInterval?: number): Promise<TRes | undefined> => {
  // eslint-disable-next-line no-unused-vars, max-len
  const fetchGetData = async (counter: number, timeout: number, resolve: (value: TRes | undefined) => void): Promise<void> => {
    if (counter === 0) {
      resolve(undefined);
      return;
    }

    const response = await internalFetchMethod<TReq, TRes>(url, 'POST', req);
    if (response === undefined) {
      setTimeout(() => fetchGetData(counter - 1, timeout, resolve), timeout);
    } else {
      resolve(response);
    }
  };

  return new Promise<TRes | undefined>((resolve) => {
    fetchGetData(maxRetry ?? 10, retryInterval ?? 100, resolve);
  });
};

export const fetchGetData = async <TRes>(url: string, maxRetry?: number, retryInterval?: number): Promise<TRes | undefined> => {
  // eslint-disable-next-line no-unused-vars, max-len
  const internalFetchGetData = async (counter: number, timeout: number, resolve: (value: TRes | undefined) => void): Promise<TRes | undefined> => {
    if (counter === 0) {
      resolve(undefined);
      return;
    }

    const response = await internalFetchMethod<void, TRes>(url, 'GET');
    if (response === undefined) {
      setTimeout(() => internalFetchGetData(counter - 1, timeout, resolve), timeout);
    } else {
      resolve(response);
    }
  };

  return new Promise<TRes | undefined>((resolve) => {
    internalFetchGetData(maxRetry ?? 10, retryInterval ?? 100, resolve);
  });
};
