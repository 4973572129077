/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import H3ManualButtonGroup from './H3ManualButtonGroup/H3ManualButtonGroup';
import OnlineCardControl from '../OnlineCardControl/OnlineCardControl';
import './H3ManualControlOnlineCard.scss';

export interface H3ManualControlOnlineCardProps {
  deviceInstanceId: string;
  controlView?: DeviceModelStatus.UI.Contactron.MotorStarterH3ManualControlView;
}

const H3ManualControlOnlineCard: React.FC<H3ManualControlOnlineCardProps> = (props: H3ManualControlOnlineCardProps):
  React.ReactElement => {
  const {
    controlView,
    deviceInstanceId,
  } = props;

  if (controlView === undefined) {
    return <></>;
  }

  return (
    <OnlineCardControl label={controlView.label ?? ''}>
      <div className="h3motorstarter-online-manual__buttons">
        <H3ManualButtonGroup deviceInstanceId={deviceInstanceId} controlView={controlView} />
      </div>
    </OnlineCardControl>
  );
};

export default H3ManualControlOnlineCard;
