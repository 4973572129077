/* eslint-disable max-len */
import { v4 as uuidv4 } from 'uuid';
import { DeviceInformation, WebDevice } from '@gpt/commons';
import { SimulatedDevicesCount, SimulatedDeviceList } from './types';

export const createSimulatedDevice = (catalog: WebDevice.DeviceCatalogInformation): DeviceInformation => ({
  catalog,
  instance: {
    deviceId: `${catalog.deviceCatalogIdent}${uuidv4()}`,
    communicationProtocol: `Simulation ${catalog.protocol}`,
    deviceTag: 'DeviceTag',
    deviceTypeIdentifier: catalog.deviceCatalogIdent,
    firmwareVersion: catalog.firmwareVersion,
    hardwareVersion: '0',
    serialNumber: `SIM${Math.floor(Math.random() * 9000000) + 1000000}`,
    simulation: true,
  },
});

export const createSimulatedDevices = (catalog: WebDevice.DeviceCatalogInformation, count: number)
  : DeviceInformation[] => {
  if (count === 0) {
    return [];
  }
  const deviceList: DeviceInformation[] = Array.from(Array(count).keys())
    .map(() => createSimulatedDevice(catalog));
  return deviceList;
};

export const createSimulatedDeviceList = (deviceCount: SimulatedDevicesCount): SimulatedDeviceList => {
  const deviceList: SimulatedDeviceList = Object.keys(deviceCount)
    .reduce((acc, key) => {
      const simDeviceList = createSimulatedDevices(deviceCount[key].info, deviceCount[key].count);
      return {
        ...acc,
        [key]: simDeviceList,
      };
    }, {});
  return deviceList;
};
