/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext, useEffect } from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTypedSelector } from '../../store/reduxStore';
import { ReduxControlProps } from '../ReduxControlProps';
import ReduxControl from '../ReduxControl/ReduxControl';
import { useMenuItemStateUpdate } from '../hooks/useMenuItemStateUpdate';
import { compareTabData, MenuItemData, menuItemsSelector } from '../selectors/menuItemsSelector';
import withControlVisibility from '../hoc/withControlVisibility';
import { DatasetContext } from '../../views/DatasetContext';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import { DatasetState, DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import './GridLayoutReduxControl.scss';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';

const MAX_GRID_ROWS = 12;
const MAX_GRID_COLS = 12;

const selectControlType = (identRef: IdentRef, dataset?: DatasetState)
: DeviceModelStatus.UI.GridLayoutControlType | undefined => {
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLGRIDLAYOUT) {
    return undefined;
  }
  return controlType;
};

const selectGridItemList = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  target: DatasetType,
  identRef: IdentRef,
): MenuItemData[] => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, target);
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return [];
  }
  return menuItemsSelector(dataset, controlType.menu);
};

const selectGridRowNumber = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  target: DatasetType,
  identRef: IdentRef,
): number => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, target);
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return 1;
  }

  let { rows } = controlType;
  if (rows < 0) {
    rows = 1;
  } else if (rows > MAX_GRID_ROWS) {
    rows = MAX_GRID_ROWS;
  }
  return rows;
};

const selectGridColNumber = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  target: DatasetType,
  identRef: IdentRef,
): number => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, target);
  const controlType = selectControlType(identRef, dataset);
  if (controlType === undefined) {
    return 1;
  }

  let { cols } = controlType;
  if (cols < 0) {
    cols = 1;
  } else if (cols > MAX_GRID_COLS) {
    cols = MAX_GRID_COLS;
  }
  return cols;
};

// eslint-disable-next-line max-len
const GridLayoutReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps): React.ReactElement => {
  const {
    identRef,
    onStateChanged,
    readonly,
    deviceInstanceId,
  } = props;
  const { targetDataset } = useContext(DatasetContext);
  const [modifiedState, errorState, updateMenuItemState] = useMenuItemStateUpdate();

  const menuItems = useTypedSelector(
    (state) => selectGridItemList(state.deviceInstances, deviceInstanceId, targetDataset, identRef),
    (p, c) => compareTabData(p, c),
  );

  const rowNumber = useTypedSelector(
    (state) => selectGridRowNumber(state.deviceInstances, deviceInstanceId, targetDataset, identRef),
  );

  const colNumber = useTypedSelector(
    (state) => selectGridColNumber(state.deviceInstances, deviceInstanceId, targetDataset, identRef),
  );

  // When control removed, the modified and error state is clean
  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, {
      error: errorState,
      modified: modifiedState,
    });
  }, [modifiedState, errorState, identRef]);

  const menuItemControls = menuItems
    .slice(0, colNumber * rowNumber)
    .map((element, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`grid--${identRef}--${index}--${element.identRef}`}
        className="grid-layout-container__item w-100 h-100"
      >
        <ReduxControl
          identRef={element.identRef}
          deviceInstanceId={deviceInstanceId}
          readonly={readonly}
          onStateChanged={(ident, state) => updateMenuItemState(ident, state)}
        />
      </div>
    ));

  const columns = `grid-layout-container--columns-${colNumber}`;
  const rows = `grid-layout-container--rows-${rowNumber}`;
  return (
    <div className={`grid-layout-container ${columns} ${rows}`}>
      {menuItemControls}
    </div>
  );
};

export default withControlVisibility(GridLayoutReduxControl);
