/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector, writeActiveDeviceVariableValues } from '../../../../store';
import { UnsupportedControl } from '../../../UnsupportedControl';
import { deviceDescriptorSelector, deviceStatusValueSelector } from '../../../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import { RadioEditorControl } from '../../../ParameterEditor';
import { convertToAbsolute, convertToPercentage } from './ForceAnalogEditorControl/functions';
import { ForceAnalogEditorControl } from './ForceAnalogEditorControl/ForceAnalogEditorControl';
import { updateDeviceDatasetValues } from '../../../../store/deviceInstances/store/deviceDataset';
import ForceAnalogButton from './ForceAnalogButton/ForceAnalogButton';
import './ForceAnalogOutputReduxControl.scss';

export interface ForceAnalogOutputReduxControlProps {
  deviceInstanceId: string;
  forceAnalogOutput: DeviceModelStatus.UI.SPort.typeForceAnalogOutput;
  disabled: boolean;
}

// const useForceAnalogOutputValue = (
//   deviceInstanceId: string,
//   forceAnalogOutput: DeviceModelStatus.UI.SPort.typeForceAnalogOutput,
// ) => {
//   const {
//     variable, varRangeStart, varRangeEnd, type,
//   } = forceAnalogOutput;

//   const { t } = useTranslation();
//   const [isAbsoluteValue, setIsAbsoluteValue] = useState(true);

//   const dispatch = useDispatch();

//   const varStatusValue = useTypedSelector((state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, variable));

//   const deviceRangeStartStatusValue = useTypedSelector(
//     (state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, varRangeStart)?.value ?? 0,
//   );
//   const deviceRangeEndStatusValue = useTypedSelector(
//     (state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, varRangeEnd)?.value ?? 0,
//   );

//   if (varStatusValue === undefined) {
//     return [0, undefined, setIsAbsoluteValue];
//   }

//   const { identRef, backupValue, value } = varStatusValue;

//   let convertedValue = 0;
//   if (type === DeviceModelStatus.UI.SPort.ForceAnalogOutputType.ABSOLUTE) {
//     convertedValue = isAbsoluteValue ? value : convertToPercentage(value, deviceRangeStartStatusValue, deviceRangeEndStatusValue);
//   } else {
//     convertedValue = !isAbsoluteValue ? value : convertToAbsolute(value, deviceRangeStartStatusValue, deviceRangeEndStatusValue);
//   }

//   const writeVariableValue = useCallback((devInstanceId: string, newValue: string) => {
//     const parsedValue = parseFloat(newValue);
//     if (Number.isNaN(parsedValue)) {
//       dispatch(updateDeviceDatasetValues(devInstanceId, {
//         targetDataset: DatasetType.user,
//         values: [{
//           value: newValue,
//           backupValue,
//           identRef,
//           valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
//           valueValidityDescription: `${t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT')}`,
//         }],
//       }));
//     } else {
//       let valueToSend = 0;
//       if (type === DeviceModelStatus.UI.SPort.ForceAnalogOutputType.ABSOLUTE) {
//         valueToSend = isAbsoluteValue ? parsedValue : convertToAbsolute(parsedValue, deviceRangeStartStatusValue, deviceRangeEndStatusValue);
//       } else {
//         valueToSend = !isAbsoluteValue ? parsedValue : convertToPercentage(parsedValue, deviceRangeStartStatusValue, deviceRangeEndStatusValue);
//       }
//       dispatch(writeActiveDeviceVariableValues(devInstanceId, [{
//         value: valueToSend,
//         backupValue,
//         identRef,
//       }]));
//     }
//   }, [isAbsoluteValue, deviceRangeStartStatusValue, deviceRangeEndStatusValue, type, backupValue, identRef]);

//   return [convertedValue, writeVariableValue, setIsAbsoluteValue] as const;
// };

const ForceAnalogOutputReduxControl: React.FC<ForceAnalogOutputReduxControlProps> = (props: ForceAnalogOutputReduxControlProps):
  React.ReactElement => {
  const {
    forceAnalogOutput,
    deviceInstanceId,
    disabled,
  } = props;

  const {
    label, subLabel, button, variable, varRangeStart, varRangeEnd, type,
  } = forceAnalogOutput;

  const { t } = useTranslation();
  const [isAbsoluteValue, setIsAbsoluteValue] = useState(true);
  const dispatch = useDispatch();

  const varDescriptor = useTypedSelector((state) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, variable));
  const varStatusValue = useTypedSelector((state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, variable));

  const deviceRangeStartStatusValue = useTypedSelector(
    (state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, varRangeStart)?.value ?? 0,
  );
  const deviceRangeEndStatusValue = useTypedSelector(
    (state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, varRangeEnd)?.value ?? 0,
  );

  if (varDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor || varStatusValue === undefined) {
    return <UnsupportedControl text="ForceAnalogOutputReduxControl: undefined object" />;
  }

  let convertedValue = 0;
  if (type === DeviceModelStatus.UI.SPort.ForceAnalogOutputType.ABSOLUTE) {
    convertedValue = isAbsoluteValue ? varStatusValue.value : convertToPercentage(varStatusValue.value, deviceRangeStartStatusValue, deviceRangeEndStatusValue);
  } else {
    convertedValue = !isAbsoluteValue ? varStatusValue.value : convertToAbsolute(varStatusValue.value, deviceRangeStartStatusValue, deviceRangeEndStatusValue);
  }

  return (
    <div className="force-analog-output-control">
      <div className="force-analog-output-control__title">
        {t(label)}
      </div>
      <div className="force-analog-output-control__text">
        {t(subLabel)}
      </div>
      <div className="force-analog-output-control__absolute_checkbox">
        <RadioEditorControl
          onChange={() => setIsAbsoluteValue(true)}
          id={`${variable}--absolute`}
          group={`${variable}--absolute`}
          label={t('FORCE_ANALOG_OUTPUT__ABSOLUTE')}
          value={isAbsoluteValue}
          readonly={disabled}
        />
      </div>
      <div className="force-analog-output-control__percentage_checkbox">
        <RadioEditorControl
          onChange={() => setIsAbsoluteValue(false)}
          id={`${variable}--percent`}
          group={`${variable}--percent`}
          label={t('FORCE_ANALOG_OUTPUT__PERCENTAGE')}
          value={!isAbsoluteValue}
          readonly={disabled}
        />
      </div>
      <div className="force-analog-output-control__input">
        <ForceAnalogEditorControl
          value={convertedValue}
          statusDescriptor={varDescriptor}
          statusValue={varStatusValue}
          disabled={disabled}
          unit={isAbsoluteValue ? t(varDescriptor?.unit ?? '') ?? '' : '%'}
          onChange={(originalValue) => {
            const parsedValue = parseFloat(originalValue);
            if (Number.isNaN(parsedValue)) {
              dispatch(updateDeviceDatasetValues(deviceInstanceId, {
                targetDataset: DatasetType.user,
                values: [{
                  value: originalValue,
                  backupValue: varStatusValue.backupValue,
                  identRef: varStatusValue.identRef,
                  valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
                  valueValidityDescription: `${t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT')}`,
                }],
              }));
            } else {
              let valueToSend = 0;
              if (type === DeviceModelStatus.UI.SPort.ForceAnalogOutputType.ABSOLUTE) {
                valueToSend = isAbsoluteValue ? parsedValue : convertToAbsolute(parsedValue, deviceRangeStartStatusValue, deviceRangeEndStatusValue);
              } else {
                valueToSend = !isAbsoluteValue ? parsedValue : convertToPercentage(parsedValue, deviceRangeStartStatusValue, deviceRangeEndStatusValue);
              }
              dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
                value: valueToSend,
                backupValue: varStatusValue.backupValue,
                identRef: varStatusValue.identRef,
              }]));
            }
          }}
        />
      </div>
      <div className="force-analog-output-control__button">
        <ForceAnalogButton
          button={button}
          deviceInstanceId={deviceInstanceId}
          disabled={disabled}
          value={varStatusValue.value}
          actionId={`s-port--mini-mcr--force-analog--${variable}`}
        />
      </div>
    </div>
  );
};

export default ForceAnalogOutputReduxControl;
