/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import {
  DeviceInformation, DeviceModelStatus, IdentRef, Services, WebDevice,
} from '@gpt/commons';
import { v4 as uuidv4 } from 'uuid';
import { ENV_DEVICEMODEL_SERVER_HOST, ENV_DEVICEMODEL_SERVER_PORT } from '../../enviroment';
import { IWebWorkerLoader } from './WebWorkerLoader';

export interface IWebWorkerDeviceDataset {
  isDisposed: () => boolean;

  datasetId: () => string;

  disposeDeviceModel: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  initializeDeviceModel: (device: DeviceInformation, wizardMode: boolean, connectionString?: string)
    => Promise<WebDevice.WebDeviceInitResponse | WebDevice.WebDeviceErrorResponse>;
  reinitializeDeviceModel: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  requestDeviceResource: (resourceId: string) => Promise<string>
  // eslint-disable-next-line no-unused-vars
  requestDeviceTranslation: (lanaguge: string) => Promise<Services.DeviceModel.DeviceI18nDictionary>
  // eslint-disable-next-line no-unused-vars
  writeVariableValues: (statusRefs: Services.DeviceModel.StatusValueRef[]) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  writeUploadValues: (statusRefs: Services.DeviceModel.DeviceResponseValueRef[]) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  requestTrendVariableValues: (variables: IdentRef[]) => Promise<WebDevice.WebDeviceTrendValues>
  // eslint-disable-next-line no-unused-vars
  executeMethod: (request: WebDevice.WebDeviceExecuteMethodRequest) => Promise<WebDevice.WebDeviceExecuteMethodResponse>;
  // eslint-disable-next-line no-unused-vars
  writeDataset: (values: Services.DeviceModel.DeviceResponseValueRef[]) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  updateConnectionStatus: (connected: boolean) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  createExternalDeviceModelStatus: (version: number) => Promise<DeviceModelStatus.DeviceModelStatus>;
}

class WebWorkerDeviceDataset implements IWebWorkerDeviceDataset {
  private webWorkerLoader: IWebWorkerLoader;

  private disposed: boolean;

  private deviceInstanceId: string;

  private datasetIdent: string;

  constructor(webWorkerLoader: IWebWorkerLoader, deviceInstanceId: string, datasetIdent: string) {
    this.webWorkerLoader = webWorkerLoader;
    this.disposed = false;
    this.deviceInstanceId = deviceInstanceId;
    this.datasetIdent = datasetIdent;
  }

  public isDisposed = (): boolean => this.disposed;

  public datasetId = (): string => this.datasetIdent;

  public disposeDeviceModel = async (): Promise<void> => {
    const request: WebDevice.WebDeviceDisposeRequest = {
      kind: 'WEBDEVICE__DISPOSE_REQUEST',
      deviceInstanceId: this.deviceInstanceId,
      requestId: uuidv4(),
    };
    await this.webWorkerLoader
      .postMessage<WebDevice.WebDeviceDisposeRequest, WebDevice.WebDeviceDisposeResponse>(request);
    await this.webWorkerLoader.dispose();
    this.disposed = true;
  };

  public initializeDeviceModel = async (
    device: DeviceInformation,
    wizardMode: boolean,
    connectionString?: string,
  ): Promise<WebDevice.WebDeviceInitResponse> => {
    let mode = WebDevice.WorkerDeviceCommunicationMode.NoDevice;
    if (device.instance === undefined) {
      mode = WebDevice.WorkerDeviceCommunicationMode.NoDevice;
    } else if (device.instance.simulation) {
      mode = WebDevice.WorkerDeviceCommunicationMode.Simulation;
    } else {
      mode = WebDevice.WorkerDeviceCommunicationMode.Device;
    }

    const request: WebDevice.WebDeviceInitRequest = {
      kind: 'WEBDEVICE__INIT_REQUEST',
      requestId: uuidv4(),
      device,
      datasetId: this.datasetIdent,
      deviceInstanceId: this.deviceInstanceId,
      communicationMode: mode,
      host: `${ENV_DEVICEMODEL_SERVER_HOST}`,
      port: parseInt(`${ENV_DEVICEMODEL_SERVER_PORT}`, 10),
      deviceUniqueId: device.instance?.deviceId ?? uuidv4(),
      connectionString: connectionString ?? '',
      wizardMode,
    };
    await this.webWorkerLoader.dispose();
    this.disposed = true;

    const result = await this.webWorkerLoader.create(device);
    if (!result) {
      throw new Error('Cannot create device model');
    }

    this.disposed = false;
    return this.webWorkerLoader
      .postMessage<WebDevice.WebDeviceInitRequest, WebDevice.WebDeviceInitResponse>(request);
  };

  public reinitializeDeviceModel = async (): Promise<void> => {
    const request: WebDevice.DeviceModelReinitDatasetRequest = {
      kind: 'WEBDEVICE__REINITIALIZE_DATASET__REQUEST',
      requestId: uuidv4(),
      deviceInstanceId: this.deviceInstanceId,
    };
    await this.webWorkerLoader
      .postMessage<WebDevice.DeviceModelReinitDatasetRequest, WebDevice.DeviceModelReinitDatasetResponse>(request);
  };

  public requestDeviceResource = async (resourceId: string)
    : Promise<string> => {
    const request: WebDevice.DeviceModelResourceRequest = {
      kind: 'WEBDEVICE__RESOURCE__REQUEST',
      requestId: uuidv4(),
      resourceId,
      deviceInstanceId: this.deviceInstanceId,
    };
    const response = await this.webWorkerLoader
      .postMessage<WebDevice.DeviceModelResourceRequest, WebDevice.DeviceModelResourceResponse>(request);
    return response.resource;
  };

  // eslint-disable-next-line no-unused-vars
  public requestDeviceTranslation = async (language: string)
    : Promise<Services.DeviceModel.DeviceI18nDictionary> => {
    const request: WebDevice.DeviceModelTranslationRequest = {
      kind: 'WEBDEVICE__TRANSLATION__REQUEST',
      requestId: uuidv4(),
      language,
      deviceInstanceId: this.deviceInstanceId,
    };
    const response = await this.webWorkerLoader
      .postMessage<WebDevice.DeviceModelTranslationRequest, WebDevice.DeviceModelTranslationResponse>(request);
    return response.dictionary;
  };

  public writeVariableValues = async (statusRefs: Services.DeviceModel.StatusValueRef[]): Promise<void> => {
    const request: WebDevice.WriteVariableValuesRequest = {
      kind: 'WEBDEVICE__WRITE_VARIABLE_VALUES__REQUEST',
      requestId: uuidv4(),
      statusValue: statusRefs,
      deviceInstanceId: this.deviceInstanceId,
    };
    await this.webWorkerLoader
      .postMessage<WebDevice.WriteVariableValuesRequest, WebDevice.WriteVariableValuesResponse>(request);
  };

  public requestTrendVariableValues = async (variables: IdentRef[]): Promise<WebDevice.WebDeviceTrendValues> => {
    const request: WebDevice.WebDeviceTrendDataRequest = {
      kind: 'WEBDEVICE__TRENDDATA_REQUEST',
      requestId: uuidv4(),
      variables,
      deviceInstanceId: this.deviceInstanceId,
    };
    const response = await this.webWorkerLoader
      .postMessage<WebDevice.WebDeviceTrendDataRequest, WebDevice.WebDeviceTrendDataResponse>(request);
    return response.trendValues;
  };

  public executeMethod = async (request: WebDevice.WebDeviceExecuteMethodRequest)
    : Promise<WebDevice.WebDeviceExecuteMethodResponse> => {
    const response = await this.webWorkerLoader
      .postMessage<WebDevice.WebDeviceExecuteMethodRequest, WebDevice.WebDeviceExecuteMethodResponse>(request);
    return response;
  };

  public writeUploadValues = async (values: Services.DeviceModel.DeviceResponseValueRef[]): Promise<void> => {
    const request: WebDevice.WriteUploadValuesRequest = {
      kind: 'WEBDEVICE__WRITE_UPLOAD_VALUES__REQUEST',
      requestId: uuidv4(),
      values,
      deviceInstanceId: this.deviceInstanceId,
    };
    await this.webWorkerLoader
      .postMessage<WebDevice.WriteUploadValuesRequest, WebDevice.WriteUploadValuesResponse>(request);
  };

  public writeDataset = async (values: Services.DeviceModel.DeviceResponseValueRef[]): Promise<void> => {
    const request: WebDevice.WriteDatasetRequest = {
      kind: 'WEBDEVICE__WRITE_DATASET__REQUEST',
      requestId: uuidv4(),
      values,
      deviceInstanceId: this.deviceInstanceId,
    };
    await this.webWorkerLoader
      .postMessage<WebDevice.WriteDatasetRequest, WebDevice.WriteDatasetResponse>(request);
  };

  public updateConnectionStatus = async (connected: boolean): Promise<void> => {
    const request: WebDevice.WebDeviceUpdateConnectionRequest = {
      kind: 'WEBDEVICE__UPDATE_CONNECTION_STATUS__REQUEST',
      requestId: uuidv4(),
      connected,
      deviceInstanceId: this.deviceInstanceId,
    };
    await this.webWorkerLoader
      .postMessage<WebDevice.WebDeviceUpdateConnectionRequest, WebDevice.WebDeviceUpdateConnectionResponse>(request);
  };

  public createExternalDeviceModelStatus = async (version: number): Promise<DeviceModelStatus.DeviceModelStatus> => {
    const request: WebDevice.CreateExternalDeviceModelStatusRequest = {
      kind: 'WEBDEVICE__CREATE_EXTERNAL_DEVICEMODEL_STATUS__REQUEST',
      requestId: uuidv4(),
      deviceInstanceId: this.deviceInstanceId,
      version,
    };
    const response = await this.webWorkerLoader
      .postMessage<WebDevice.CreateExternalDeviceModelStatusRequest, WebDevice.CreateExternalDeviceModelStatusResponse>(request);
    return response.status;
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function, class-methods-use-this, no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  private debug = (...args: any[]) => {}; // console.log(...args); // this.logger.debug(...args);
}

export default WebWorkerDeviceDataset;
