/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  typeDeviceInstanceStationAction,
  StationDiscoveryPayload,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const addStationDiscoveryInformation = (targetInstance: string, data: StationDiscoveryPayload)
  : typeDeviceInstanceStationAction => ({
  type: 'DEVICE_INSTANCE__STATION_DISCOVERY__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'STATION_DISCOVERY__ADD_DISCOVERY_INFORMATION',
      payload: {
        targetInstance,
        data,
      },
    },
  },
});

export const removeStationDiscoveryInformation = (targetInstance: string, data: string[])
  : typeDeviceInstanceStationAction => ({
  type: 'DEVICE_INSTANCE__STATION_DISCOVERY__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'STATION_DISCOVERY__REMOVE_DISCOVERY_INFORMATION',
      payload: {
        targetInstance,
        data,
      },
    },
  },
});

export const initializeStation = (targetInstance: string, title: string, subtitle: string)
  : typeDeviceInstanceStationAction => ({
  type: 'DEVICE_INSTANCE__STATION_DISCOVERY__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'STATION_DISCOVERY__INITIALIZE',
      payload: {
        targetInstance,
        data: {
          instanceId: targetInstance,
          title,
          subtitle,
        },
      },
    },
  },
});
