/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { ExecutionState } from '../../../common';
import {
  ActiveDeviceState,
  typeActiveDeviceAction,
  SetActiveDeviceAction,
  SetActiveDeviceExecutionStatusAction,
  UpdateActiveDeviceTagAction,
} from './types';

export const initialActiveDeviceState: ActiveDeviceState = {
  executionState: ExecutionState.init,
  modelInstance: undefined,
};

const reducerSwitchFunction = {
  ACTIVE_DEVICE__SET_ACTIVE_DEVICE: (state: ActiveDeviceState, action: SetActiveDeviceAction)
    : ActiveDeviceState => ({
    ...state,
    modelInstance: {
      device: action.payload.data.device,
      deviceInstanceId: action.payload.data.deviceInstanceId,
      webWorkerInstanceRef: action.payload.data.webWorkerInstanceRef,
      stationInstanceId: action.payload.data.stationInstanceId,
    },
    executionState: state.executionState,
  }),
  ACTIVE_DEVICE__UPDATE_DEVICE_TAG: (state: ActiveDeviceState, action: UpdateActiveDeviceTagAction)
    : ActiveDeviceState => {
    if (state.modelInstance?.device?.instance === undefined) {
      return state;
    }
    if (state.modelInstance.device.instance.deviceTag === action.payload.data) {
      return state;
    }
    return {
      ...state,
      modelInstance: {
        ...state.modelInstance,
        device: {
          ...state.modelInstance.device,
          instance: {
            ...state.modelInstance.device.instance,
            deviceTag: action.payload.data,
          },
        },
      },
    };
  },
  ACTIVE_DEVICE__SET_EXECUTION_STATUS: (state: ActiveDeviceState, action: SetActiveDeviceExecutionStatusAction)
    : ActiveDeviceState => ({
    ...state,
    executionState: action.payload.data,
  }),
};

export const activeDeviceStateReducer = (
  state = initialActiveDeviceState,
  action: typeActiveDeviceAction,
): ActiveDeviceState => (reducerSwitchFunction[action.type] === undefined ? state
  : reducerSwitchFunction[action.type](state, action as any));
