/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UnsupportedControl } from '../../../../../components/UnsupportedControl';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../../../../hooks/useContextDataset';
import { setContextParameterHelp } from '../../../../../store/contexthelp';
import { CompareModeType } from '../../../../../views/CompareView/common';
import { CompareStatusValueProps } from '../../../CompareTypes';
import useCompareMiddleButton from '../../../hooks/useCompareMiddleButton';
import useDispatchIntegerValue from '../../../hooks/useDispatchIntegerValue';
import useVariableValidationMessage from '../../../hooks/useVariableValidationMessage';
import CompareStringValue from '../CompareStringStatusValue/CompareStringValue/CompareStringValue';
import CompareEnumValue, { CompareEnumeratorOptions } from './CompareEnumValue/CompareEnumValue';

const enumItemLabel = (valueType: DeviceModelStatus.StatusDescriptorValueTypeEnumerator, statusValue?: DeviceModelStatus.StatusValue): string => {
  const enumValue = valueType.enumerator.find((entry) => entry.value === statusValue?.value);
  const retValue = enumValue !== undefined ? enumValue.label : statusValue?.value;
  return retValue ?? '';
};

/**
 * Compare two values from Dataset Value : value vs externalValue
 * backupValue used as storage for value before modification
 *
 * @param props Control Properties
 * @returns
 */
const CompareEnumStatusValue
: React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
  : React.ReactElement | null => {
  const {
    parentId,
    compareMode,
    identRef, leftDataset, rightDataset,
    onShowContentHelp, onHideContentHelp,
    showImageAndSign,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const leftDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef, leftDataset);
  const leftValue = useContextDatasetStatusValue(deviceInstanceId, identRef, leftDataset);

  const rightDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef, rightDataset);
  const rightValue = useContextDatasetStatusValue(deviceInstanceId, identRef, rightDataset);

  const dispatchValue = useDispatchIntegerValue(deviceInstanceId, leftDataset);
  const warning = useVariableValidationMessage(leftDescriptor, leftValue);
  const [buttonFunction, onMiddleButtonClick] = useCompareMiddleButton(deviceInstanceId, leftValue, rightValue);

  if (leftDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareEnumStatusValue: Unsupported type" />;
  }

  if (rightDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareEnumStatusValue: Unsupported type" />;
  }

  if (leftDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return <UnsupportedControl text="CompareEnumStatusValue: only ENUMERATOR is supported" />;
  }

  if (rightDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR) {
    return <UnsupportedControl text="CompareEnumStatusValue: only ENUMERATOR is supported" />;
  }

  const showContentHelp = useCallback(() => {
    if (leftDescriptor === undefined) {
      return;
    }

    const { label, help } = leftDescriptor;
    dispatch(setContextParameterHelp({
      title: t(label),
      text: t(help),
    }));
    onShowContentHelp?.();
  }, [leftDescriptor, onShowContentHelp]);

  const hideContentHelp = () => {
    dispatch(setContextParameterHelp({
      title: '',
      text: '',
    }));
    onHideContentHelp?.();
  };

  const options: CompareEnumeratorOptions = leftDescriptor.valueType.enumerator.reduce((acc, entry) => ({
    ...acc,
    [entry.value]: t<string>(entry.label),
  }), {});

  const equal = leftValue?.value === rightValue?.value;

  if (compareMode === CompareModeType.ShowDifference && equal) {
    return (null);
  }

  if (compareMode === CompareModeType.ShowAll) {
    const leftStringValue = enumItemLabel(leftDescriptor.valueType, leftValue);
    const rightStringValue = enumItemLabel(rightDescriptor.valueType, rightValue);

    return (
      <CompareStringValue
        key={`cmp-float-${parentId}-${identRef}`}
        label={t(leftDescriptor.label)}
        help={t<string>(leftDescriptor.help)}
        equal={equal}
        valueLeft={t(leftStringValue)}
        valueRight={t(rightStringValue)}
        onButtonClick={() => onMiddleButtonClick(buttonFunction)}
        modified={leftValue?.modified ?? false}
        readonly
        warning={warning}
        onHideContentHelp={hideContentHelp}
        onShowContentHelp={showContentHelp}
        buttonFunction={buttonFunction}
        showImageAndSign={showImageAndSign}
      />
    );
  }

  return (
    <CompareEnumValue
      key={`cmp-float-${parentId}-${leftDescriptor.identRef}-${rightDescriptor.identRef}`}
      label={t(leftDescriptor.label)}
      help={t<string>(leftDescriptor.help)}
      equal={equal}
      valueLeft={leftValue?.value}
      valueLeftUnit={leftDescriptor.unit === undefined ? undefined : t<string>(leftDescriptor.unit)}
      valueRight={rightValue?.value}
      hideLeftControl={leftDescriptor.visibility === false}
      valueRightUnit={rightDescriptor.unit === undefined ? undefined : t<string>(rightDescriptor.unit)}
      hideRightControl={rightDescriptor.visibility === false}
      options={options}
      onButtonClick={() => onMiddleButtonClick(buttonFunction)}
      modified={leftValue?.modified ?? false}
      readonly={leftDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY}
      onLeftValueChange={(ev) => dispatchValue(ev, leftValue)}
      warning={leftValue?.valueValidityDescription}
      onHideContentHelp={hideContentHelp}
      onShowContentHelp={showContentHelp}
      buttonFunction={buttonFunction}
      showImageAndSign={showImageAndSign}
    />
  );
};

export default CompareEnumStatusValue;
