/* eslint-disable max-len */
import { DeviceModelStatus, ReportContentType } from '@gpt/commons';

const deviceModelApplicationDescriptor: DeviceModelStatus.DeviceModelDescriptorCollection = {
  APP_INITIALDEVICESTARTUP: {
    type: DeviceModelStatus.StatusType.ApplicationDescriptor,
    identRef: 'APP_INITIALDEVICESTARTUP',
    appType: {
      type: DeviceModelStatus.Apps.AppType.INITIALDEVICESTARTUP,
      label: '',
      description: '',
      help: '',
      menu: 'PARAMETERS',
      icon: '',
    },
  },
  APP_EDITDEVICE: {
    type: DeviceModelStatus.StatusType.ApplicationDescriptor,
    identRef: 'APP_EDITDEVICE',
    appType: {
      type: DeviceModelStatus.Apps.AppType.EDITDEVICE,
      label: 'SIDEBAR__EDITDEVICE_TITLE',
      description: 'SIDEBAR__EDITDEVICE_SUBTITLE',
      help: '',
      menu: 'PARAMETERS',
      icon: '',
      report: 'PREVIEW',
      reportType: ReportContentType.EditParameters,
    },
  },
  APP_ONLINEPARAM: {
    type: DeviceModelStatus.StatusType.ApplicationDescriptor,
    identRef: 'APP_ONLINEPARAM',
    appType: {
      type: DeviceModelStatus.Apps.AppType.ONLINEPARAM,
      label: 'SIDEBAR__ONLINEPARAM_TITLE',
      description: 'SIDEBAR__ONLINEPARAM_SUBTITLE',
      help: '',
      menu: 'PARAMETERS',
      icon: '',
    },
  },
  APP_REVIEWDEVICE: {
    type: DeviceModelStatus.StatusType.ApplicationDescriptor,
    identRef: 'APP_REVIEWDEVICE',
    appType: {
      type: DeviceModelStatus.Apps.AppType.REVIEWDEVICE,
      label: '',
      description: '',
      help: '',
      menu: 'PREVIEW',
      icon: '',
    },
  },
  APP_COMPAREDEVICE: {
    type: DeviceModelStatus.StatusType.ApplicationDescriptor,
    identRef: 'APP_COMPAREDEVICE',
    appType: {
      type: DeviceModelStatus.Apps.AppType.COMPAREDEVICE,
      label: 'SIDEBAR__COMPAREDEVICE_TITLE',
      description: 'SIDEBAR__COMPAREDEVICE_SUBTITLE',
      help: '',
      menu: 'COMPARE',
      icon: '',
      report: 'PREVIEW',
      reportType: ReportContentType.CompareParameters,
    },
  },
  APP_EXPERTCOMPAREDEVICE: {
    type: DeviceModelStatus.StatusType.ApplicationDescriptor,
    identRef: 'APP_EXPERTCOMPAREDEVICE',
    appType: {
      type: DeviceModelStatus.Apps.AppType.COMPAREDEVICE,
      label: 'SIDEBAR__COMPAREDEVICE_TITLE',
      description: 'SIDEBAR__COMPAREDEVICE_SUBTITLE',
      help: '',
      menu: 'COMPARE',
      icon: '',
    },
  },
  APP_REPORTDEVICE: {
    type: DeviceModelStatus.StatusType.ApplicationDescriptor,
    identRef: 'APP_REPORTDEVICE',
    appType: {
      type: DeviceModelStatus.Apps.AppType.REVIEWDEVICE,
      label: '',
      description: '',
      help: '',
      menu: 'PREVIEW',
      icon: '',
    },
  },
  APP_COCKPIT: {
    type: DeviceModelStatus.StatusType.ApplicationDescriptor,
    identRef: 'APP_COCKPIT',
    appType: {
      type: DeviceModelStatus.Apps.AppType.COCKPIT,
      label: 'SIDEBAR__COCKPIT_TITLE',
      description: 'SIDEBAR__COCKPIT_DESCRIPTION',
      help: 'SIDEBAR__COCKPIT_HELP',
      menu: 'cockpitMenu',
      icon: '',
    },
  },
  APP_POPUPEDITOR: {
    type: DeviceModelStatus.StatusType.ApplicationDescriptor,
    identRef: 'APP_POPUPEDITOR',
    appType: {
      type: DeviceModelStatus.Apps.AppType.COCKPIT,
      label: '',
      description: '',
      help: '',
      menu: 'EMPTYMENU',
      icon: '',
    },
  },
};

export default deviceModelApplicationDescriptor;
