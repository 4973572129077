/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import React from 'react';
import './BasicSelectEditor.css';

export interface BasicSelectEditorProps {
    value?: number;
    options: { [val: number]: string };
    warning?: string;
    edited?: boolean;
    readonly?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onShowContentHelp?: () => void;
    onHideContentHelp?: () => void;
  }

const BasicSelectEditor: React.FC<BasicSelectEditorProps> = (props: BasicSelectEditorProps):
React.ReactElement => {
  const {
    value, options, warning, onChange, edited, readonly,
    onShowContentHelp, onHideContentHelp,
  } = props;

  const selectOptions = Object.entries(options).map(([opVal, opLabel]) => (
    <option key={opVal} value={parseInt(opVal, 10)}>{opLabel}</option>
  ));

  const classNameEditedText = (edited !== undefined && edited === true) ? 'textfield__input--edited' : '';
  const disabled = readonly !== undefined ? readonly : false;

  return (
    <div className="enum-select-field">
      <div className="select-field">
        <select
          data-testid="select-control"
          value={value}
          className={`form__select ${classNameEditedText}`}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
          }}
          onFocus={() => onShowContentHelp?.()}
          onBlur={() => onHideContentHelp?.()}
          disabled={disabled}
        >
          { selectOptions }
        </select>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="form__select__label">{}</label>
        <span className="bar" />
        <span className="select-icon" />
        {/* <span className="helper-text" data-error={warning} /> */}
      </div>
      <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
        <div className="input-field-control__warning-text">{warning}</div>
      </div>
    </div>
  );
};

export default BasicSelectEditor;
