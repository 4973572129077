/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './Trio3ChannelSwitchNumber.css';
import { Trio3ChannelLed, Trio3ChannelSwitch } from './Trio3ChannelSwitch/Trio3ChannelSwitch';

export interface Trio3ChannelSwitchNumberProps {
  id: string;
  led: Trio3ChannelLed;
  label: string,
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onSwitchChannel: () => void;
}

// eslint-disable-next-line max-len
export const Trio3ChannelSwitchNumber: React.FC<Trio3ChannelSwitchNumberProps> = (props: Trio3ChannelSwitchNumberProps):
  React.ReactElement => {
  const {
    id, led, label, onSwitchChannel, disabled,
  } = props;

  return (
    <div className="trio3-channel-switch-container">
      <div className="trio3-channel-switch-container__header">
        <span className="trio3-channel-switch-container__header__label">{label}</span>
      </div>
      <Trio3ChannelSwitch
        id={id}
        led={led}
        onSwitchChannel={onSwitchChannel}
        disabled={disabled}
      />
    </div>
  );
};
