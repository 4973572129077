/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { DeviceInstancesState } from '../../types';
import { IoLinkTrio3EventMonitorState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const ioLinkTrio3EventMonitorStateSelector = (state: DeviceInstancesState, deviceInstanceId: string | undefined)
: IoLinkTrio3EventMonitorState | undefined => {
  if (deviceInstanceId === undefined) {
    return undefined;
  }
  return state.instances[deviceInstanceId]?.ioLinkTrio3EventMonitor;
};
