/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import { createSimulatedDevice, createSimulatedDeviceList } from './helper';
import {
  DecSimulatedDeviceCountAction, DeviceSimulationState, DEVICE_SIMULATION__DEC_SIMULATED_DEVICE_COUNT, DEVICE_SIMULATION__INC_SIMULATED_DEVICE_COUNT, DEVICE_SIMULATION__SET_SIMULATED_DEVICE_COUNT, IncSimulatedDeviceCountAction, typeDeviceSimulationActionTypes,
} from './types';

export const initSimulationDeviceState: DeviceSimulationState = {
  enabled: false,
  simulatedDevices: { },
};

const incSimulatedDevicesReducer = (state: DeviceSimulationState, action: IncSimulatedDeviceCountAction)
  :DeviceSimulationState => {
  const deviceCatalogInfo = action.payload;
  const newDevice = createSimulatedDevice(deviceCatalogInfo);

  // Add new device to the list
  if (state.simulatedDevices[deviceCatalogInfo.deviceCatalogIdent] !== undefined) {
    return {
      ...state,
      simulatedDevices: {
        ...state.simulatedDevices,
        [deviceCatalogInfo.deviceCatalogIdent]: [
          ...state.simulatedDevices[deviceCatalogInfo.deviceCatalogIdent],
          newDevice,
        ],
      },
    };
  }

  return {
    ...state,
    simulatedDevices: {
      ...state.simulatedDevices,
      [deviceCatalogInfo.deviceCatalogIdent]: [newDevice],
    },
  };
};

const decSimulatedDevicesReducer = (state: DeviceSimulationState, action: DecSimulatedDeviceCountAction)
  :DeviceSimulationState => {
  const deviceCatalogInfo = action.payload;
  if (state.simulatedDevices[deviceCatalogInfo.deviceCatalogIdent] === undefined) {
    return state;
  }

  // Remove one elemnt from device list
  const count = state.simulatedDevices[deviceCatalogInfo.deviceCatalogIdent].length - 1;
  if (count > 0) {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const [first, ...list] = state.simulatedDevices[deviceCatalogInfo.deviceCatalogIdent];
    return {
      ...state,
      simulatedDevices: {
        ...state.simulatedDevices,
        [deviceCatalogInfo.deviceCatalogIdent]: list,
      },
    };
  }

  // Remove device from list
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [deviceCatalogInfo.deviceCatalogIdent]: deviceIdProp, ...restObject } = state.simulatedDevices;
  return {
    ...state,
    simulatedDevices: restObject,
  };
};

const deviceSimulationReducer = (
  state = initSimulationDeviceState,
  action: typeDeviceSimulationActionTypes,
): DeviceSimulationState => {
  let newstate = state;
  switch (action.type) {
    case DEVICE_SIMULATION__INC_SIMULATED_DEVICE_COUNT:
      newstate = incSimulatedDevicesReducer(state, action);
      break;
    case DEVICE_SIMULATION__DEC_SIMULATED_DEVICE_COUNT:
      newstate = decSimulatedDevicesReducer(state, action);
      break;
    case DEVICE_SIMULATION__SET_SIMULATED_DEVICE_COUNT:
      newstate = {
        ...state,
        simulatedDevices: createSimulatedDeviceList(action.payload),
      };
      break;
    default:
      newstate = state;
  }
  return newstate;
};

export default deviceSimulationReducer;
