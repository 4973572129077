/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import './EventMonitorTrioTemplate.css';

export interface EventMonitorTrioTemplateProps extends PropsWithChildren<any> {
  isHeader?: boolean;
}

// eslint-disable-next-line max-len
export const EventMonitorTrioTemplate: React.FC<EventMonitorTrioTemplateProps> = (props: EventMonitorTrioTemplateProps):
  React.ReactElement => {
  const { isHeader, children } = props;

  return (
    <div className={`event-monitor-trio-template ${isHeader ? 'event-monitor-trio-template__header' : ''}`}>
      <div className="event-monitor-trio-template__column-1">
        { children[0] }
      </div>
      <div className="event-monitor-trio-template__column-2">
        { children[1] }
      </div>
      <div className="event-monitor-trio-template__column-3">
        { children[2] }
      </div>
    </div>
  );
};
