/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useCaparocStationModules } from './CockpitCaparocStationHook';
import { CockpitCaparocModule } from './CockpitCaparocModule/CockpitCaparocModule';
import { createDataFromConfiguration } from './CaparocHelper';
import CaparocSystemStatus from './CaparocSystemStatus/CaparocSystemStatus';
import CaparocGauges from './CaparocGauges/CaparocGauges';
import CaparocSystemSettings from './CaparocSystemSettings/CaparocSystemSettings';
import { CockpitCaparocInactiveModule } from './CockpitCaparocModule/CockpitCaparocInactiveModule';
import { ROOT_DEVICEINSTANCE_ID } from '../../../helpers/createDeviceInstanceId';
import './CockpitCaparocStation.scss';

const CockpitCaparocStation: React.FC = ():React.ReactElement => {
  const moduleConfig = useCaparocStationModules(ROOT_DEVICEINSTANCE_ID);
  const controlData = createDataFromConfiguration(moduleConfig);

  let lastChannelEndCol;
  let lastChannelRow;
  if (controlData.length === 0) {
    lastChannelEndCol = 1;
    lastChannelRow = 1;
  } else {
    lastChannelEndCol = controlData[controlData.length - 1].endCol;
    lastChannelRow = controlData[controlData.length - 1].row;
  }

  let moduleControls = controlData.map((module) => (
    <div key={`module-${module.addr}`} className={`cockpit-caparoc-station__grid-column-start-${module.startCol} cockpit-caparoc-station__grid-column-end-${module.endCol} cockpit-caparoc-station__grid-row-${module.row}`}>
      <CockpitCaparocModule
        addr={module.addr}
        channels={module.channels}
        leftBreak={module.leftBreak}
        rightBreak={module.rightBreak}
      />
    </div>
  ));

  if (lastChannelRow === 1 && lastChannelEndCol < 11) {
    moduleControls = [
      ...moduleControls,
      (
        <div key="inactivemodule-1" className={`cockpit-caparoc-station__grid-column-start-${lastChannelEndCol} cockpit-caparoc-station__grid-column-end-11 cockpit-caparoc-station__grid-row-1`}>
          <CockpitCaparocInactiveModule channels={[...Array(11 - lastChannelEndCol).keys()].map((idx) => idx + lastChannelEndCol)} />
        </div>
      ),
      (
        <div key="inactivemodule-2" className="cockpit-caparoc-station__grid-column-start-1 cockpit-caparoc-station__grid-column-end-11 cockpit-caparoc-station__grid-row-2">
          <CockpitCaparocInactiveModule channels={[...Array(10).keys()].map((idx) => idx + 11)} />
        </div>
      ),
    ];
  }

  if (lastChannelRow === 1 && lastChannelEndCol === 11) {
    moduleControls = [
      ...moduleControls,
      (
        <div key="inactivemodule-2" className="cockpit-caparoc-station__grid-column-start-1 cockpit-caparoc-station__grid-column-end-11 cockpit-caparoc-station__grid-row-2">
          <CockpitCaparocInactiveModule channels={[...Array(10).keys()].map((idx) => idx + 11)} />
        </div>
      ),
    ];
  }

  if (lastChannelRow === 2 && lastChannelEndCol < 11) {
    moduleControls = [
      ...moduleControls,
      (
        <div key="inactivemodule-2" className={`cockpit-caparoc-station__grid-column-start-${lastChannelEndCol} cockpit-caparoc-station__grid-column-end-11 cockpit-caparoc-station__grid-row-2`}>
          <CockpitCaparocInactiveModule channels={[...Array(11 - lastChannelEndCol).keys()].map((idx) => idx + lastChannelEndCol + 10)} />
        </div>
      ),
    ];
  }

  return (
    <div className="cockpit-caparoc-station__container">
      <div className="cockpit-caparoc-station__systemStatus">
        <CaparocSystemStatus />
      </div>
      <div className="cockpit-caparoc-station__systemData">
        <CaparocGauges />
      </div>
      <div className="cockpit-caparoc-station__systemSettings">
        <CaparocSystemSettings />
      </div>
      <div className="cockpit-caparoc-station__modules">
        {moduleControls}
      </div>
    </div>
  );
};

export default CockpitCaparocStation;
