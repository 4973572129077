/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { UnsupportedControl } from '../../../UnsupportedControl';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import { useTypedSelector } from '../../../../store';
import { deviceDescriptorSelector, deviceStatusValueSelector } from '../../../../store/deviceInstances/store/deviceDataset/selector';
import ColdJunctionLineCompensationControl from '../ColdJunctionLineCompensationControl/ColdJunctionLineCompensationControl';

export interface ColdJunctionLineCmpReduxControlProps {
  deviceInstanceId: string;
  block: DeviceModelStatus.UI.SPort.ColdJunctionLineCompensationBlock;
}

const ColdJunctionLineCompensationBlock: React.FC<ColdJunctionLineCmpReduxControlProps> = (props: ColdJunctionLineCmpReduxControlProps)
: React.ReactElement | null => {
  const { deviceInstanceId, block } = props;

  const varDescriptor = useTypedSelector((state) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, block.variable));
  const varStatusValue = useTypedSelector((state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, block.variable));

  const deviceVarDescriptor = useTypedSelector((state) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, DatasetType.device, block.deviceVar));
  const deviceVarStatusValue = useTypedSelector((state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.device, block.deviceVar));

  if (varDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor
        || deviceVarDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="ColdJunctionLineCompensationBlock: undefined variable descriptor" />;
  }

  if (varStatusValue === undefined || deviceVarStatusValue === undefined) {
    return <UnsupportedControl text="ColdJunctionLineCompensationBlock: undefined status value" />;
  }

  return (
    <ColdJunctionLineCompensationControl
      statusValue={varStatusValue}
      statusDescriptor={varDescriptor}
      deviceStatusValue={deviceVarStatusValue}
      deviceStatusDescriptor={deviceVarDescriptor}
      deviceInstanceId={deviceInstanceId}
      designation={block.label}
    />
  );
};

export default ColdJunctionLineCompensationBlock;
