/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../../../store';
import { DeviceInstancesState } from '../../../../store/deviceInstances/types';

const wizardDescriptorSelector = (state: DeviceInstancesState, deviceInstanceId?: string)
: DeviceModelStatus.Wizards.ContactronStationCommissioningType | undefined => {
  if (deviceInstanceId === undefined || state.instances[deviceInstanceId] === undefined) {
    return undefined;
  }
  const { descriptors } = state.instances[deviceInstanceId].deviceDataset.user;
  const wizardDesc = descriptors.WIZARD_CONTACTRON_STATION_COMMISSIONING;
  if (wizardDesc?.type !== DeviceModelStatus.StatusType.WizardDescriptor) {
    return undefined;
  }
  const { wizardType } = wizardDesc;
  return wizardType.type !== DeviceModelStatus.Wizards.WizardType.CONTACTRON_STATION_COMMISSIONING
    ? undefined
    : wizardType;
};

const useCommissioningWizardType = (deviceInstanceId?: string)
: DeviceModelStatus.Wizards.ContactronStationCommissioningType | undefined => useTypedSelector(
  (state) => wizardDescriptorSelector(state.deviceInstances, deviceInstanceId),
);

export default useCommissioningWizardType;
