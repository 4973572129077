import {
  DeviceInformation, WebDevice,
} from '@gpt/commons';
import EventEmitter from 'events';
import { IWebWorkerDevice, WebWorkerDevice } from './WebWorkerDevice';
import { ROOT_DEVICEINSTANCE_ID } from '../../helpers/createDeviceInstanceId';

export interface IWebWorkerDeviceManager {
    // eslint-disable-next-line no-unused-vars, max-len
    init: (webWorkerInstanceId: string, instanceId: string, device: DeviceInformation, wizardMode: boolean, connectionString?: string)
      => Promise<WebDevice.WebDeviceInitResponse | WebDevice.WebDeviceErrorResponse>;
    // eslint-disable-next-line no-unused-vars
    get: (webWorkerInstanceId: string) => IWebWorkerDevice | undefined;
    // eslint-disable-next-line no-unused-vars
    dispose: (webWorkerInstanceId: string) => Promise<void>;
}

type WebWorkerDeviceCollection = {
    // eslint-disable-next-line no-unused-vars
    [key: string]: IWebWorkerDevice;
}

export class WebWorkerDeviceManager extends EventEmitter implements IWebWorkerDeviceManager {
  private webWorkerCollection: WebWorkerDeviceCollection = {};

  public init = async (
    webWorkerInstanceId: string,
    instanceId: string,
    device: DeviceInformation,
    wizardMode: boolean,
    connectionString?: string,
  ): Promise<WebDevice.WebDeviceInitResponse | WebDevice.WebDeviceErrorResponse> => {
    const webInstance = this.createWebWorkerDevice();
    const response = await webInstance.init(instanceId, device, wizardMode, connectionString);
    if (response.kind === 'WEBDEVICE__INIT_RESPONSE') {
      this.webWorkerCollection[webWorkerInstanceId] = webInstance;
    }
    return response;
  };

  public dispose = async (webWorkerInstanceId: string): Promise<void> => {
    const webDevice = this.webWorkerCollection[webWorkerInstanceId];
    if (webDevice === undefined) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [webWorkerInstanceId]: device, ...devices } = this.webWorkerCollection;
    this.webWorkerCollection = devices;
    await device.dispose();
  };

  public get = (webWorkerInstanceId: string)
    : IWebWorkerDevice | undefined => this.webWorkerCollection[webWorkerInstanceId]
      ?? this.webWorkerCollection[ROOT_DEVICEINSTANCE_ID];

  private createWebWorkerDevice = ()
    : IWebWorkerDevice => new WebWorkerDevice(this);
}
