/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import isElectron from 'is-electron';
import AppSystemService from '../../services/AppService/AppSystemService';

const Console = console;

/**
 * Opens a link in the registered default browser of the system
 * instead of opening a new window which belongs to the current
 * application (i.e. Electron).
 *
 * @param linkHref URL of link to open in system browser
 */
const openLinkInExternalBrowser = (linkHref: string): void => {
  AppSystemService.OpenUrlInSystemBrowser(linkHref)
    .catch((error) => Console.warn(error));
};

/**
 * Checks if a clicked element is part of an HTMLAnchorElement
 * with "target" set to "_blank" and in this case delegates
 * the "href" of the link to an external browser instead of
 * using a new window of the current application (Electron).
 *
 * @param eventTarget target element of click event
 * @returns true, when the click action has been handled here
 */
const checkClickedElementForAnchor = (eventTarget: EventTarget | null): boolean => {
  // check if clicked target is part of <a> link
  if (eventTarget instanceof HTMLElement) {
    let target: HTMLElement | null = eventTarget;

    while (target) {
      if (target instanceof HTMLAnchorElement) {
        const a = target as HTMLAnchorElement;
        if (a.target === '_blank') {
          openLinkInExternalBrowser(a.href);
          return true;
        }
      }
      target = target.parentElement;
    }
  }

  return false;
};

/**
 * Activates the UIP component.
 * @param deviceAccess host interface to access device model and device
 * @param domElement DOM element where the diagnostic markers shall be displayed
 */
const addHandlerToDelegateLinkClicks = (): void => {
  const body = document.querySelector('body');
  body?.addEventListener('click', (ev) => {
    if (checkClickedElementForAnchor(ev.target)) {
      ev.preventDefault();
    }
  });
};

if (isElectron()) {
  addHandlerToDelegateLinkClicks();
}
