import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../../../store';
import { MethodStageExecutionStatus } from '../../../../store/deviceInstances/store/deviceMethod/types';

const useDownloadMethodExecutionStatus = (deviceInstanceId?: string): string | undefined => {
  const uploadMethodState = useTypedSelector(
    (state) => {
      if (deviceInstanceId === undefined) {
        return MethodStageExecutionStatus.Initialize;
      }
      const instanceState = state.deviceInstances.instances[deviceInstanceId];
      // eslint-disable-next-line max-len
      return instanceState?.deviceMethodExecution[DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF]?.stage ?? MethodStageExecutionStatus.Initialize;
    },
  ); return uploadMethodState;
};

export default useDownloadMethodExecutionStatus;
