/* eslint-disable max-len */
import {
  ContactronModuleInputOutput, ContactronModuleCollection, ContactronStationWizardState,
} from './types';

// eslint-disable-next-line max-len
const calculateTotalSelected = (inoutBlock: ContactronModuleInputOutput): number => Object.values(inoutBlock.dataCheckbox)
  .reduce((total, isChecked) => total + (isChecked ? 1 : 0), 0);

const calculateTotalSelectedInputs = (modules: ContactronModuleCollection)
  : number => Object.values(modules).reduce((total, module) => total + (module.input.totalSelected || 0), 0);

const calculateTotalSelectedOutputs = (modules: ContactronModuleCollection)
  : number => Object.values(modules).reduce((total, module) => total + (module.output.totalSelected || 0), 0);

export const updateStateTotalCounters = (state: ContactronStationWizardState): ContactronStationWizardState => {
  const totalSelectedInputsAllModules = calculateTotalSelectedInputs(state.modules);
  const totalSelectedOutputsAllModules = calculateTotalSelectedOutputs(state.modules);
  return {
    ...state,
    totalInputSelected: totalSelectedInputsAllModules,
    totalOutputSelected: totalSelectedOutputsAllModules,
  };
};

export const updateModuleStateTotalCounters = (moduleKey: string, state: ContactronStationWizardState): ContactronStationWizardState => {
  const totalInputSelected = calculateTotalSelected(state.modules[moduleKey].input);
  const totalOutputSelected = calculateTotalSelected(state.modules[moduleKey].output);

  const newState: ContactronStationWizardState = {
    ...state,
    modules: {
      ...state.modules,
      [moduleKey]: {
        ...state.modules[moduleKey],
        input: {
          ...state.modules[moduleKey].input,
          totalSelected: totalInputSelected,
        },
        output: {
          ...state.modules[moduleKey].output,
          totalSelected: totalOutputSelected,
        },
      },
    },
  };
  return updateStateTotalCounters(newState);
};
