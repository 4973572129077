/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { StationModuleLayoutProps } from '../types';
import { StationDiscoveryItem } from '../../../../../store/deviceInstances/store/stationDiscovery/types';
import './CockpitModuleLayout32.scss';
import Layout32CardReduxControl from './Layout32CardReduxControl/Layout32CardReduxControl';

export interface LayoutColumns {
  row1: number;
  row2: number;
  row3: number;
  row4: number;
}

export interface ContainerLayout {
  [key: number]: LayoutColumns;
}

const mapCountToLayout: ContainerLayout = {
  17: {
    row1: 6, row2: 6, row3: 5, row4: 0,
  },
  18: {
    row1: 6, row2: 6, row3: 6, row4: 0,
  },
  19: {
    row1: 7, row2: 6, row3: 6, row4: 0,
  },
  20: {
    row1: 7, row2: 7, row3: 6, row4: 0,
  },
  21: {
    row1: 7, row2: 7, row3: 7, row4: 0,
  },
  22: {
    row1: 8, row2: 7, row3: 7, row4: 0,
  },
  23: {
    row1: 8, row2: 8, row3: 7, row4: 0,
  },
  24: {
    row1: 8, row2: 8, row3: 8, row4: 0,
  },
  25: {
    row1: 7, row2: 6, row3: 6, row4: 6,
  },
  26: {
    row1: 7, row2: 7, row3: 6, row4: 6,
  },
  27: {
    row1: 7, row2: 7, row3: 7, row4: 6,
  },
  28: {
    row1: 7, row2: 7, row3: 7, row4: 7,
  },
  29: {
    row1: 8, row2: 7, row3: 7, row4: 7,
  },
  30: {
    row1: 8, row2: 8, row3: 7, row4: 7,
  },
  31: {
    row1: 8, row2: 8, row3: 8, row4: 7,
  },
  32: {
    row1: 8, row2: 8, row3: 8, row4: 8,
  },
};

const createCardRow = (
  items: StationDiscoveryItem[],
  stationInstanceId: string,
  // eslint-disable-next-line no-unused-vars
  onModuleOpen: (moduleInstanceId: string) => void,
  // eslint-disable-next-line no-unused-vars
  onModuleIdent: (moduleAddress: string) => void,
) => {
  const rowModules = items.map((item) => (
    <div key={`layout-row-station-card--${item.id}`} className="layout-container-row-card">
      <Layout32CardReduxControl
        channelNo={item.id}
        stationInstanceId={stationInstanceId}
        onModuleIdent={() => onModuleIdent(item.id)}
        onModuleOpen={() => onModuleOpen(item.instanceId)}
      />
    </div>
  ));
  return (
    <div className={`layout-container-row--${items.length}`}>
      {rowModules}
    </div>
  );
};

const ContactronCockpitModuleLayout32: React.FC<StationModuleLayoutProps> = (props: StationModuleLayoutProps):React.ReactElement => {
  const {
    topology, stationInstanceId, onModuleIdent, onModuleOpen,
  } = props;

  const layout = mapCountToLayout[topology.length] ?? {
    row1: 0, row2: 0, row3: 0, row4: 0,
  };

  const row1modules = createCardRow(topology.slice(0, layout.row1), stationInstanceId, onModuleOpen, onModuleIdent);
  const row2modules = createCardRow(topology.slice(layout.row1, layout.row1 + layout.row2), stationInstanceId, onModuleOpen, onModuleIdent);
  const row3modules = createCardRow(topology.slice(layout.row1 + layout.row2, layout.row1 + layout.row2 + layout.row3), stationInstanceId, onModuleOpen, onModuleIdent);
  const row4modules = createCardRow(topology.slice(layout.row1 + layout.row2 + layout.row3), stationInstanceId, onModuleOpen, onModuleIdent);

  return (
    <div className="contactron-station-cockpit-layout32">
      {row1modules}
      {row2modules}
      {row3modules}
      {row4modules}
    </div>
  );
};

export default ContactronCockpitModuleLayout32;
