/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import {
  DeviceModelStatus,
} from '@gpt/commons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router';
import { StandardViewButton } from '../../../components/ButtonList/ButtonList.StandardView';
import { ComponentFactory } from '../../../controls/ComponentFactory/ComponentFactory';
import { createParameterizationFilename } from '../../../helpers/functions';
import { useContextDatasetDescriptors } from '../../../hooks/useContextDataset';
import { useTypedSelector } from '../../../store';
import { writeDeviceDatasetToExternalFile } from '../../../store/deviceInstances/middleware/datasetProvider';
import { executeDeviceDownloadDatasetMethod } from '../../../store/deviceInstances/middleware/deviceMethodExecution/actions';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { ApplicationContext } from '../../ApplicationContext';
import useDeviceViewLoadExternalFile from '../ButtonHandling/useDeviceViewLoadExternalFile';
import useDeviceViewPrintReport from '../ButtonHandling/useDeviceViewReport';
import { DeviceApplication } from '../hooks/useApplicationList';
import { DialogMode } from '../DeviceViewDialog/DeviceViewDialog';
import { ACTIONID__DOWNLOAD_DATA_TO_DEVICE } from '../../../store/deviceInstances/middleware/deviceMethodExecution/types';

export interface DeviceViewRouteNavigationProps {
  activeDeviceInstanceId: string;
  applicationList: DeviceApplication[];
  // eslint-disable-next-line no-unused-vars
  setDialogMode: (mode: DialogMode) => void;
}

const DeviceViewRouteNavigation: React.FC<DeviceViewRouteNavigationProps> = (props: DeviceViewRouteNavigationProps): React.ReactElement => {
  const dispatch = useDispatch();
  const { activeDeviceInstanceId, applicationList, setDialogMode } = props;

  const deviceInstances = useTypedSelector((state) => state.deviceInstances);

  const activeDeviceInstance = useTypedSelector((state) => state.deviceInstances.instances[activeDeviceInstanceId]?.activeDevice.modelInstance);

  const deviceViewLoadExternalFile = useDeviceViewLoadExternalFile();
  const deviceViewPrintReport = useDeviceViewPrintReport(activeDeviceInstanceId);

  const deviceDatasetDescriptors = useContextDatasetDescriptors(DatasetType.user);

  const deviceViewRoutes = applicationList.map((app) => {
    const {
      control, identRef, routePath, id,
    } = app;
    const key = `route-deviceview--${id}--${control}-${identRef}`;
    const view = ComponentFactory({
      identRef,
      deviceInstanceId: activeDeviceInstanceId,
      control,
      onStateChanged: () => 0,
    });
    return (
      <Route
        key={key}
        path={routePath}
        element={view}
      />
    );
  });

  return (
    <ApplicationContext.Provider value={{
      onDeviceButtonClick: (button) => {
        switch (button) {
          case StandardViewButton.Transfer:
            dispatch(executeDeviceDownloadDatasetMethod({
              targetInstance: activeDeviceInstanceId,
              actionId: ACTIONID__DOWNLOAD_DATA_TO_DEVICE,
              methodIdent: DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF,
              sourceDataset: DatasetType.user,
            }));
            setDialogMode(DialogMode.downloadDialog);
            break;

          case StandardViewButton.Print:
            deviceViewPrintReport();
            break;

          case StandardViewButton.Save: {
            const mainDeviceId = deviceInstances.instances[activeDeviceInstanceId].activeDevice.modelInstance?.stationInstanceId ?? activeDeviceInstanceId;
            const mainDeviceInfo = deviceInstances.instances[activeDeviceInstanceId].activeDevice.modelInstance?.device;
            // TODO: Check menu
            dispatch(writeDeviceDatasetToExternalFile({
              deviceInstanceId: mainDeviceId,
              filename: createParameterizationFilename(mainDeviceInfo),
              menu: (deviceDatasetDescriptors?.APP_REVIEWDEVICE as DeviceModelStatus.ApplicationDescriptor)?.appType.menu ?? '',
            }));
          }
            break;

          case StandardViewButton.Load: {
            const mainDeviceId = deviceInstances.instances[activeDeviceInstanceId].activeDevice.modelInstance?.stationInstanceId === undefined
              ? activeDeviceInstance
              : deviceInstances.instances[activeDeviceInstanceId].activeDevice.modelInstance;
            deviceViewLoadExternalFile(mainDeviceId);
          }
            break;
          default:
        }
      },
    }}
    >
      <Routes>
        {deviceViewRoutes}
      </Routes>
    </ApplicationContext.Provider>
  );
};

export default DeviceViewRouteNavigation;
