/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import * as cxEdp from '@gpt/cxe-dp-integration';
import React, { Dispatch } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import InputBoxControl from '../../components/Basic/InputBoxControl/InputBoxControl';
import { CTAButton, CTAButtonVariant } from '../../components/CTAButton/CTAButton';
import { FILE_EXTENTION__CXEDP, MIME_TYPE__CXEDP } from '../../helpers/constants';
import AppFileSystemService from '../../services/AppService/AppFileSystemService';
import { showApplicationMessage } from '../../store/applicationMessage/actions';
import './PageSelectFileComponent.scss';

export interface ParameterFileInfo {
  infoLines: string[],
}

export interface PageLoadParameterFileComponentProps {
  title: string;
  buttonCaption: string;
  buttonVariant?: CTAButtonVariant;
  selectedFilename?: string;
  onFileSelected: (result: cxEdp.ImportLocalFileResult) => Promise<void>;
  warning?: string;
}

interface OpenFileDialogMessages {
  cancelMessage: string,
  successfullMessage: string,
  errorMessage: string,
}

export const OpenFile = async (
  selectedFilename: string | undefined,
  onFileSelected: (result: cxEdp.ImportLocalFileResult) => Promise<void>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  openFileMessages: OpenFileDialogMessages,
  mimeType: string,
  extensions?: string[],
): Promise<void> => {
  let result: cxEdp.ImportLocalFileResult;
  try {
    result = await AppFileSystemService.ImportLocalFile({
      suggestedName: selectedFilename, mimeType, extensions,
    });
    await onFileSelected(result);
  } catch (error) {
    if (cxEdp.isAbortError(error)) {
      dispatch(showApplicationMessage('warning', openFileMessages.cancelMessage));
    } else {
      dispatch(showApplicationMessage('error', openFileMessages.errorMessage));
    }
  }
};

export const PageLoadParameterFileComponent:
React.FC<PageLoadParameterFileComponentProps> = (
  props: PageLoadParameterFileComponentProps,
): React.ReactElement => {
  const {
    title, buttonCaption, buttonVariant, onFileSelected, warning, selectedFilename,
  } = props;

  const dispatch = useDispatch();

  const openFileMessages: OpenFileDialogMessages = {
    cancelMessage: 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__CANCELLED',
    successfullMessage: 'LOAD_PARAMETER_FILE__LOAD_DATA__SUCCESS',
    errorMessage: 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__CANNOT_READ_CONTENT',
  };

  return (
    <Container fluid className="page-load-parameter-file">
      <Row className="m-0">
        <Col className="p-0">
          <div className="page-load-parameter-file__title">
            {title}
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={8} className="pr-2">
          <InputBoxControl
            type="text"
            displayFormat=""
            value={selectedFilename ?? ''}
            onChange={() => 0}
            warning={warning}
            readonly
          />
        </Col>
        <Col xs={4} className="pl-0">
          <CTAButton
            caption={buttonCaption}
            customClassName="page-load-parameter-file__button"
            variant={buttonVariant ?? 'tertiary'}
            focus={buttonVariant === 'primary'}
            block
            onClick={async () => {
              await OpenFile(
                selectedFilename,
                onFileSelected,
                dispatch,
                openFileMessages,
                MIME_TYPE__CXEDP,
                [FILE_EXTENTION__CXEDP],
              );
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PageLoadParameterFileComponent;
