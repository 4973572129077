/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { useDispatch, shallowEqual } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store';
import { ReduxControlProps } from '../../ReduxControlProps';
import { UnsupportedControl } from '../../../components/UnsupportedControl';
import { FigmaGaugeControl } from '../../../components/GaugeControl/FigmaGaugeControl/FigmaGaugeControl';
import {
  getGaugeDeviceValue, getGaugeSegmentStops, getGaugeSubtitle, getGaugeTitle, getGaugeVarDescriptor,
} from '../controlFunctions/controlFunctions';
import { GetDisplayFormat } from '../../../helpers/functions';
import { useContextDatasetDescriptor } from '../../../hooks/useContextDataset';
import { DatasetContext } from '../../../views/DatasetContext';
import withControlVisibility from '../../hoc/withControlVisibility';
import { deviceControlStateCollectionSelector } from '../../../store/deviceInstances/store/controlStateCollection/selectors';
import { GaugeControlState, setGaugeIndicator } from '../../../store/deviceInstances/store/controlStateCollection';

export type GaugeReduxControlProps = ReduxControlProps;

const GaugeReduxControl: React.FC<GaugeReduxControlProps> = (props: GaugeReduxControlProps): React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { targetDataset } = useContext(DatasetContext);

  const showIndicatorState = useTypedSelector((state) => deviceControlStateCollectionSelector(state.deviceInstances, deviceInstanceId)?.[identRef]);
  const showIndicator = showIndicatorState !== undefined ? (showIndicatorState as GaugeControlState).showIndicator ?? false : false;

  const segmentStops = useTypedSelector((state) => getGaugeSegmentStops(state.deviceInstances, deviceInstanceId, targetDataset, identRef), shallowEqual);
  const gaugeVarDescriptor = useTypedSelector((state) => getGaugeVarDescriptor(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const deviceValue = useTypedSelector((state) => getGaugeDeviceValue(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const subtitle = useTypedSelector((state) => getGaugeSubtitle(state.deviceInstances, deviceInstanceId, targetDataset, identRef));
  const title = useTypedSelector((state) => getGaugeTitle(state.deviceInstances, deviceInstanceId, targetDataset, identRef));

  const gaugeDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  if (gaugeDescriptor === undefined) {
    return <UnsupportedControl text={`GaugeDescriptorControl: object ${identRef} undefined`} />;
  }

  if (gaugeDescriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="GaugeDescriptorControl: supported descriptor type is 'ControlDescriptor'" />;
  }

  if (gaugeDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDYNVARIABLE) {
    return <UnsupportedControl text="GaugeDescriptorControl: supported control type is 'CTLDYNVARIABLE'" />;
  }

  if (gaugeVarDescriptor?.visibility === false) {
    return (null);
  }

  const unit = gaugeVarDescriptor?.unit === undefined ? '' : t<string>(gaugeVarDescriptor.unit);
  const displayFormat = gaugeVarDescriptor !== undefined ? GetDisplayFormat(gaugeVarDescriptor) : '%.2f';

  return (
    <FigmaGaugeControl
      gaugeTitle={t(title)}
      subtitle={subtitle}
      centerUnit={unit}
      currentValue={deviceValue}
      segmentStops={segmentStops}
      showDragIndicator={showIndicator}
      displayFormat={displayFormat}
      setShowDragIndicator={(value) => {
        dispatch(setGaugeIndicator(deviceInstanceId, {
          ident: identRef,
          indicator: value,
        }));
      }}
    />
  );
};

export default withControlVisibility(GaugeReduxControl);
