/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Resource } from 'i18next';

import i18nQUINT4PSEn from './QUINT4_PS_en.json';
import i18nQUINT4PSDe from './QUINT4_PS_de.json';
import i18nQUINT4PSEs from './QUINT4_PS_es.json';
import i18nQUINT4PSFr from './QUINT4_PS_fr.json';
import i18nQUINT4PSIt from './QUINT4_PS_it.json';
import i18nQUINT4PSJa from './QUINT4_PS_ja.json';
import i18nQUINT4PSPl from './QUINT4_PS_pl.json';
import i18nQUINT4PSPt from './QUINT4_PS_pt.json';
import i18nQUINT4PSRu from './QUINT4_PS_ru.json';
import i18nQUINT4PSTr from './QUINT4_PS_tr.json';
import i18nQUINT4PSZh from './QUINT4_PS_zh.json';
import i18nQUINT4PSCs from './QUINT4_PS_cs.json';
import i18nQUINT4PSDa from './QUINT4_PS_da.json';
import i18nQUINT4PSFi from './QUINT4_PS_fi.json';
import i18nQUINT4PSHu from './QUINT4_PS_hu.json';
import i18nQUINT4PSKo from './QUINT4_PS_ko.json';
import i18nQUINT4PSNb from './QUINT4_PS_nb.json';
import i18nQUINT4PSNl from './QUINT4_PS_nl.json';
import i18nQUINT4PSRo from './QUINT4_PS_ro.json';
import i18nQUINT4PSSv from './QUINT4_PS_sv.json';
import i18nQUINT4PSUk from './QUINT4_PS_uk.json';

/**
 * Default translation for application
 */
const bundledResources: Resource = {
  en: {
    QUINT4_PS: i18nQUINT4PSEn,
  },
  de: {
    QUINT4_PS: i18nQUINT4PSDe,
  },
  es: {
    QUINT4_PS: i18nQUINT4PSEs,
  },
  fr: {
    QUINT4_PS: i18nQUINT4PSFr,
  },
  it: {
    QUINT4_PS: i18nQUINT4PSIt,
  },
  ja: {
    QUINT4_PS: i18nQUINT4PSJa,
  },
  pl: {
    QUINT4_PS: i18nQUINT4PSPl,
  },
  pt: {
    QUINT4_PS: i18nQUINT4PSPt,
  },
  ru: {
    QUINT4_PS: i18nQUINT4PSRu,
  },
  tr: {
    QUINT4_PS: i18nQUINT4PSTr,
  },
  zh: {
    QUINT4_PS: i18nQUINT4PSZh,
  },
  cs: {
    QUINT4_PS: i18nQUINT4PSCs,
  },
  da: {
    QUINT4_PS: i18nQUINT4PSDa,
  },
  fi: {
    QUINT4_PS: i18nQUINT4PSFi,
  },
  hu: {
    QUINT4_PS: i18nQUINT4PSHu,
  },
  ko: {
    QUINT4_PS: i18nQUINT4PSKo,
  },
  nb: {
    QUINT4_PS: i18nQUINT4PSNb,
  },
  nl: {
    QUINT4_PS: i18nQUINT4PSNl,
  },
  ro: {
    QUINT4_PS: i18nQUINT4PSRo,
  },
  sv: {
    QUINT4_PS: i18nQUINT4PSSv,
  },
  uk: {
    QUINT4_PS: i18nQUINT4PSUk,
  },
};

export default bundledResources;
