/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  i18nWebServiceActionTypes,
  I18N__WEBSERVICE__INITIALIZE,
  i18nWebServiceInitPayload,
  I18N__WEBSERVICE__REQUEST,
  i18nWebServiceRequestPayload,
} from './types';

export const i18nWebServiceInit = (payload: i18nWebServiceInitPayload)
  : i18nWebServiceActionTypes => ({
  type: I18N__WEBSERVICE__INITIALIZE,
  payload,
});

export const i18nWebServiceTranslationRequest = (payload: i18nWebServiceRequestPayload)
  : i18nWebServiceActionTypes => ({
  type: I18N__WEBSERVICE__REQUEST,
  payload,
});
