/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { ListGroup } from 'react-bootstrap';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { ExecutionState, useTypedSelector } from '../../store';
import { LoadingControl } from '../../components/LoadingControl/LoadingControl';
import PreviewValueGroupControl from '../../components/PreviewComponent/PreviewValueGroupControl/PreviewValueGroupControl';
import './PreviewValueDatasetReduxControl.css';
import { DatasetContext } from '../../views/DatasetContext';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { previewDatasetStateSelector } from '../../store/deviceInstances/store/previewDataset/selector';

export interface PreviewValueDatasetReduxProps {
  caption: string;
  identRef: IdentRef;
  deviceInstanceId: string;
}

const PreviewValueDatasetReduxControl: React.FC<PreviewValueDatasetReduxProps> = (props: PreviewValueDatasetReduxProps)
: React.ReactElement => {
  const { identRef, caption, deviceInstanceId } = props;
  const { t } = useTranslation();

  const { targetDataset } = useContext(DatasetContext);
  const previewDatasetValues = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)?.values);
  const previewDatasetDescriptors = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset)?.descriptors);
  const previewDatasetState = useTypedSelector((state) => previewDatasetStateSelector(state.deviceInstances, deviceInstanceId)?.state ?? ExecutionState.init);

  if (previewDatasetValues === undefined) {
    return previewDatasetState === ExecutionState.pending
      ? <LoadingControl title={t<string>('LOADING_PREVIEW_DATA')} />
      : <div />;
  }

  const structureDescriptor = previewDatasetDescriptors?.[identRef];
  if (structureDescriptor === undefined) {
    return <UnsupportedControl text={`PreviewValueDatasetReduxControl: Undefined ident ${identRef}`} />;
  }

  if (structureDescriptor.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return <UnsupportedControl text={`PreviewValueDatasetReduxControl: Unsupported type ${structureDescriptor.type}`} />;
  }

  const componentGroups = structureDescriptor.elements.map((element) => (
    <ListGroup.Item key={`listitem-${identRef}-${element.identRef}`}>
      <PreviewValueGroupControl deviceInstanceId={deviceInstanceId} identRef={element.identRef} />
    </ListGroup.Item>
  ));

  return (
    <div className="preview-dataset-redux-control h-100">
      <div className="preview-dataset-redux-control__caption">
        <p>{caption}</p>
      </div>
      <div className="preview-dataset-redux-control__list h-100">
        <ListGroup className="h-100" variant="flush">
          {componentGroups}
        </ListGroup>
      </div>
    </div>
  );
};

export default PreviewValueDatasetReduxControl;
