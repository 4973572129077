/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { DeviceInstancesState } from '../../types';
import { IoLinkSystemCommandState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const ioLinkSystemCommandSelector = (state: DeviceInstancesState, deviceInstanceId: string | undefined)
: IoLinkSystemCommandState | undefined => {
  if (deviceInstanceId === undefined) {
    return undefined;
  }
  return state.instances[deviceInstanceId]?.ioLinkAction;
};
