/* eslint-disable max-len */
import React from 'react';
import { gaugeCenterX, gaugeCenterY, gaugeWidth } from '../common';
import SVGGaugePointer from './SVGGaugePointer/SVGGaugePointer';
import DisplayFormat from '../../../../../helpers/displayFormat';

export interface SVGGaugeControlProps {
  currentValue: number,
  currentUnit: string;
  displayFormat: string,
  gaugeMinValue: number,
  gaugeMaxValue: number
}

const SVGGaugeValue: React.FC<SVGGaugeControlProps> = (props: SVGGaugeControlProps)
: React.ReactElement => {
  const {
    currentValue, currentUnit, gaugeMaxValue, gaugeMinValue, displayFormat,
  } = props;

  const value = DisplayFormat(displayFormat, currentValue);
  return (
    <g>
      <SVGGaugePointer gaugeMaxValue={gaugeMaxValue} gaugeMinValue={gaugeMinValue} currentValue={currentValue} />
      <text x={gaugeCenterX} y={gaugeCenterY - 50} clipPath="url(#clip-unit)" fontSize={36} fontWeight="bold" textAnchor="middle" fill="black">{currentUnit}</text>
      <text x={gaugeCenterX} y={gaugeCenterY} fontSize={36} fontWeight="bold" textAnchor="middle" fill="black">{value}</text>
      <clipPath id="clip-unit">
        <rect x={160} y={gaugeCenterY - 50 - 30} width={gaugeWidth - 320} height={36} fill="green" fillOpacity={0.2} strokeWidth={2} stroke="red" />
      </clipPath>
    </g>
  );
};

export default SVGGaugeValue;
