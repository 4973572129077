/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { useEffect, useState } from 'react';
import AppSystemService from '../services/AppService/AppSystemService';

const defaultVersion = 'unknown';

/** The version will be shown as 1.24 insteand of 1.2.4  */
const simplifyVersion = (version: string): string => {
  const vers = version.split('.');
  if (vers.length === 3) {
    const lasts = vers[2].split('-');
    return vers[2].indexOf('-') === -1
      ? `${vers[0]}.${vers[1]}`
      : `${vers[0]}.${vers[1]}-${lasts[lasts.length - 1]}`;
  }
  return version;
};

export const formatAppVersion = (version: string): string => {
  const regVersion = version.match(/^(.+?)\+.*$/);
  return simplifyVersion(regVersion?.[1] ?? version);
};

const useAppVersion = (): string => {
  const [appVersion, setAppVersion] = useState('');

  useEffect(() => {
    const getAppVersion = async (): Promise<void> => {
      const appVer = await AppSystemService.GetAppVersion();
      const version = formatAppVersion(appVer);
      setAppVersion(version);
    };
    getAppVersion().catch(() => {
      setAppVersion(defaultVersion);
    });
  }, []);

  return appVersion;
};

export default useAppVersion;
