/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { ReduxControlProps } from '../ReduxControlProps';
import { UnsupportedControl } from '../../components/UnsupportedControl';
import DeviceStatusListControl from '../../components/DeviceStatusListControl/DeviceStatusListControl';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import withControlVisibility from '../hoc/withControlVisibility';

const DeviceStatusListReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement => {
  const { identRef, deviceInstanceId } = props;
  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);

  if (descriptor === undefined) {
    return <UnsupportedControl text={`DeviceStatusListReduxControl: Undefined object ${identRef}`} />;
  }

  if (descriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="DeviceStatusListReduxControl: Supported type is ControlDescriptor only" />;
  }

  if (descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLDEVICESTATUSLIST) {
    return <UnsupportedControl text="DeviceStatusListReduxControl: Supported ControlType is CTLDEVICESTATUSLIST only" />;
  }

  return (
    <DeviceStatusListControl
      deviceInstanceId={deviceInstanceId}
      statusList={descriptor.controlType.DEVICESTATUSES.map((item) => item.control)}
    />
  );
};

export default withControlVisibility(DeviceStatusListReduxControl);
