/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../store';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { PreviewTable } from '../../components/PreviewTable/PreviewTable';
import { DatasetContext } from '../../views/DatasetContext';
import { Record, RecordValueType } from '../../components/PreviewTable/PreviewTableHeader/PreviewTableHeader';
import { deviceDescriptorSelector, deviceStatusValueSelector } from '../../store/deviceInstances/store/deviceDataset/selector';

export const AreRecordsEqual = (oldRecords: Record[], newRecords: Record[]): boolean => {
  if (oldRecords.length !== newRecords.length) {
    return false;
  }

  const foundRecord = oldRecords.find((oldRecord, recordIndex) => {
    const newRecord = newRecords[recordIndex];

    if (oldRecord.values.length !== newRecord.values.length) {
      return true;
    }

    const foundObj = oldRecord.values.find((oldObj, objIndex) => {
      const newObj = newRecord.values[objIndex];
      return oldObj.value !== newObj.value || oldObj.unit !== newObj.unit;
    });

    return foundObj !== undefined;
  });

  return foundRecord === undefined;
};

export interface PreviewTableControlProps {
  identRef: string;
  numberOfPoints?: number;
  deviceInstanceId: string;
}

export const PreviewTableControl: React.FC<PreviewTableControlProps> = (props: PreviewTableControlProps)
: React.ReactElement => {
  const { identRef, numberOfPoints, deviceInstanceId } = props;
  const { targetDataset } = useContext(DatasetContext);

  const tableDescription = useTypedSelector((state) => {
    const tableDesc = deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef);
    if (tableDesc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return undefined;
    }
    return tableDesc.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.TABLE ? undefined : tableDesc.valueType;
  });

  const getMemberValue = (member: string): RecordValueType | undefined => useTypedSelector((state) => {
    const memDescriptor = deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, member);
    if (memDescriptor === undefined || memDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return undefined;
    }

    if (memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT
      && memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.INTEGER
      && memDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER) {
      return undefined;
    }

    const memValue = deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, targetDataset, member);
    if (memValue === undefined) {
      return undefined;
    }

    return {
      unit: memDescriptor.unit ?? '',
      value: memValue.value as number,
    };
  }, (oldValue, newValue) => oldValue?.unit === newValue?.unit && oldValue?.value === newValue?.value);

  const recordsData = useTypedSelector((state) => {
    const records: Record[] = [];

    (tableDescription as DeviceModelStatus.StatusDescriptorValueTypeTable)?.records
      .filter((item) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, item)?.type === DeviceModelStatus.StatusType.StatusDescriptor)
      .forEach((rec) => {
        const recDef = deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, rec) as DeviceModelStatus.StatusDescriptor;
        const record = recDef.valueType as DeviceModelStatus.StatusDescriptorValueTypeTableRecord;

        const recordValues: RecordValueType[] = [];

        record.members.forEach((mem) => {
          const recordValue = getMemberValue(mem);

          if (recordValue !== undefined) {
            recordValues.push(recordValue);
          }
        });

        if (recordValues.length > 0) {
          records.push({ values: recordValues });
        }
      });
    return records;
  }, (oldValue, newValue) => AreRecordsEqual(oldValue, newValue));

  if (tableDescription === undefined) {
    return <UnsupportedControl text={`PreviewTableControl [${identRef}]: undefined table`} />;
  }

  if (recordsData.length === 0) {
    return <UnsupportedControl text={`PreviewTableControl [${identRef}]: no records data`} />;
  }

  const filteredRecords = numberOfPoints === undefined ? recordsData : recordsData.filter((value, index) => index <= numberOfPoints - 1);

  return (
    <PreviewTable records={filteredRecords} showNumbering />
  );
};

export default PreviewTableControl;
