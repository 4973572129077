/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { SystemDataCurrentChart } from './CurrentChart/CurrentChart';
import { SystemDataPowerChart } from './PowerChart/PowerChart';
import './SystemDataMenu.scss';

export interface SystemDataMenuControlProps {
  deviceInstanceId: string;
  dataView?: DeviceModelStatus.UI.Contactron.MotorStarterDataView;
}

const SystemDataMenuControl: React.FC<SystemDataMenuControlProps> = (props: SystemDataMenuControlProps):
  React.ReactElement => {
  const {
    deviceInstanceId,
    dataView,
  } = props;

  if (dataView === undefined) {
    return <></>;
  }

  return (
    <div className="contactron-system-data-menu">
      <div className="contactron-system-data-menu__current">
        <SystemDataCurrentChart
          deviceInstanceId={deviceInstanceId}
          current={dataView.current}
        />
      </div>
      <div className="contactron-system-data-menu__power">
        <SystemDataPowerChart
          deviceInstanceId={deviceInstanceId}
          power={dataView.power}
        />
      </div>
    </div>
  );
};

export default SystemDataMenuControl;
