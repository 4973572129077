/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import useSPortWriteParameter from '../../../../SPort/hooks/useSPortWriteParameter';
import { MethodStageExecutionStatus } from '../../../../../store/deviceInstances/store/deviceMethod/types';
import H3ActivateButton from './H3ActivateButton/H3ActivateButton';
import './H3ManualButtonGroup.scss';

export const H3MANUALCONTROL_DEACTIVATE = 0x40;
export const H3MANUALCONTROL_ACTIVATE = 0x20;

export interface H3ManualButtonGroupProps {
  // eslint-disable-next-line react/no-unused-prop-types
  deviceInstanceId: string;
  controlView: DeviceModelStatus.UI.Contactron.MotorStarterH3ManualControlView;
  manualActive?: boolean;
}

const H3ManualButtonGroup: React.FC<H3ManualButtonGroupProps> = (props: H3ManualButtonGroupProps):React.ReactElement => {
  const { t } = useTranslation();
  const { controlView, deviceInstanceId, manualActive } = props;

  const [manualControlActive, setManualControlActive] = useState(manualActive ?? false);
  // const activateButtonTitle = manualControlActive
  //   ? 'CONTACTRON__ONLINE__MANUAL_CONTROL_BUTTON__DEACTIVATE_TITLE'
  //   : 'CONTACTRON__ONLINE__MANUAL_CONTROL_BUTTON__ACTIVATE_TITLE';

  const [activateState, writeActivateParameter] = useSPortWriteParameter(deviceInstanceId, `s-port-activate__${controlView.activate.address}__action`);
  const [onState, writeOnParameter] = useSPortWriteParameter(deviceInstanceId, `s-port-on__${controlView.on.address}__action`);
  const [stopState, writeStopParameter] = useSPortWriteParameter(deviceInstanceId, `s-port-stop__${controlView.stop.address}__action`);

  // const ledStatusValue = useTypedSelector((state) => {
  //   const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.device);
  //   return dataset?.values.IFS_ADDR_LED_R.value ?? 0;
  // });

  const actionRunning = (activateState === MethodStageExecutionStatus.InProgress
    || onState === MethodStageExecutionStatus.InProgress
    || stopState === MethodStageExecutionStatus.InProgress);

  const buttonDisabled = actionRunning || !manualControlActive;

  useEffect(() => () => {
    if (manualControlActive) {
      // eslint-disable-next-line no-bitwise
      writeActivateParameter(controlView.stop.address, new Uint8Array([0x00, controlView.stop.value]));
      writeActivateParameter(controlView.activate.address, new Uint8Array([0x00, H3MANUALCONTROL_DEACTIVATE]));
    }
  }, [manualControlActive, controlView]);

  return (
    <div className="h3motorstarter-online-manual-buttons">
      <div className="h3motorstarter-online-manual--activate">
        <H3ActivateButton
          deviceInstanceId={deviceInstanceId}
          controlView={controlView}
          manualModeActive={manualControlActive}
          disabled={actionRunning}
          onClick={(writeValue) => {
            setManualControlActive(!manualControlActive);
            writeActivateParameter(controlView.activate.address, new Uint8Array([0x00, writeValue]));
          }}
        />
      </div>
      {/* <Button
          variant="secondary-small"
          block
          disabled={actionRunning}
          onClick={() => {
            let writeValue = H3MANUALCONTROL_DEACTIVATE;
            if (!manualControlActive) {
              writeValue = ledStatusValue !== 0
                ? controlView.on.value
                : H3MANUALCONTROL_ACTIVATE;
            }
            setManualControlActive(!manualControlActive);
            writeActivateParameter(controlView.activate.address, new Uint8Array([0x00, writeValue]));
          }}
        >
          {t(activateButtonTitle)}
        </Button> */
      }
      <div className="h3motorstarter-online-manual--on">
        <Button
          variant="secondary-small"
          block
          disabled={buttonDisabled}
          onClick={() => {
            if (manualControlActive) {
              writeOnParameter(controlView.on.address, new Uint8Array([0x00, controlView.on.value]));
            }
          }}
        >
          {t(controlView.on.label)}
        </Button>
      </div>
      <div className="h3motorstarter-online-manual--stop">
        <Button
          variant="secondary-small"
          block
          disabled={buttonDisabled}
          onClick={() => {
            if (manualControlActive) {
              writeStopParameter(controlView.stop.address, new Uint8Array([0x00, controlView.stop.value]));
            }
          }}
        >
          {t(controlView.stop.label)}
        </Button>
      </div>
    </div>
  );
};

export default H3ManualButtonGroup;
