/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

export const MIME_TYPE__CXEDP = 'application/json+cxedp';

export const FILE_EXTENTION__CXEDP = '.cxedp';
