/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { DatasetState, DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { useTypedSelector } from '../../../../../store';
import { deviceTargetDatasetSelector } from '../../../../../store/deviceInstances/store/dataStorage/selectors';

export const getSpanControlType = (identRef: IdentRef, dataset?: DatasetState)
: DeviceModelStatus.UI.RangeWithSpanControlType | undefined => {
  const spanDescriptor = dataset?.descriptors[identRef];
  if (spanDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (spanDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN) {
    return undefined;
  }
  return spanDescriptor.controlType;
};

const useRangeMemberDescriptors = (deviceInstanceId: string, identRef: IdentRef, target: DatasetType)
: DeviceModelStatus.StatusDescriptor[] => {
  const dataset = useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target));
  const controlType = getSpanControlType(identRef, dataset);
  if (controlType === undefined) {
    return [];
  }

  const descriptors = ['rangeEnd', 'rangeStart', 'spanVar'].reduce((acc, member) => {
    const desc = dataset?.descriptors[controlType[member]];
    if (desc === undefined) {
      return acc;
    }
    if (desc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return acc;
    }
    return [
      ...acc,
      desc,
    ];
  }, [] as DeviceModelStatus.StatusDescriptor[]);
  return descriptors;
};

export default useRangeMemberDescriptors;
