/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './VerticalAxis.css';

export type LabelAlignment = 'left' | 'right';

export interface VerticalAxisProps {
  labels: number[];
  alignment: LabelAlignment;
}

const RoundValue = (value: number, base: number): string => (Math.round(value * base) / base).toString();

export const VerticalAxis: React.FC<VerticalAxisProps> = (props: VerticalAxisProps) : React.ReactElement => {
  const { labels, alignment } = props;
  const numberOfGaps = labels.length - 1;
  const labelGap = 100 / numberOfGaps;
  const filteredLabels: number[] = [];
  for (let i = labels.length - 1; i > 0; i -= 1) {
    filteredLabels.push(labels[i]);
  }

  const alignmentClass = alignment === 'left' ? 'flotLeft' : 'floatRight';
  const labelClasses = `VerticalAxis-control-label VerticalAxis-control-labelText ${alignmentClass}`;

  const ticks = filteredLabels.map((label, index) => {
    const key = `ver-label-${index}`;
    const displayLabel = RoundValue(label, 100);
    return (
      <div key={key} style={{ height: `${labelGap}%` }}>
        <div className={labelClasses}>
          {displayLabel}
        </div>
      </div>
    );
  });

  ticks.push((
    <div key={`ver-label-${filteredLabels.length}`} style={{ height: '0px' }}>
      <div className={labelClasses}>
        {RoundValue(labels[0], 100)}
      </div>
    </div>
  ));

  return (
    <div className="VerticalAxis-control" data-testid="vertical-axis">
      {ticks}
    </div>
  );
};
