/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { IdentRef } from '@gpt/commons';
import {
  DataTableRow, DataTableRowItem,
} from '../../../../components/DataTableControl';
import { useContextDatasetStatusValue } from '../../../../hooks/useContextDataset';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import {
  HDC_ERROR_MEMORY__HDR_CURRENT__WIDTH,
  HDC_ERROR_MEMORY__HDR_ERROR_ENTRY__WIDTH,
  HDC_ERROR_MEMORY__HDR_TEMPERATURE__WIDTH,
  HDC_ERROR_MEMORY__HDR_VOLTAGE__WIDTH,
  HDC_ERROR_MEMORY__HDR_WORKING_HOURS__WIDTH,
  HDC_ERROR_MEMORY__VAR__CURRENT,
  HDC_ERROR_MEMORY__VAR__ENTRY,
  HDC_ERROR_MEMORY__VAR__TEMPERATURE,
  HDC_ERROR_MEMORY__VAR__VOLTAGE,
  HDC_ERROR_MEMORY__VAR__WORK_HOURS,
} from '../../HDCConstants';
import { useHDCEntryEnumLabelValue } from './hooks/useHDCEntryEnumLabelValue';
import useHDCIsEntryEmpty from './hooks/useHDCIsEntryEmpty';

export interface HDCErrorMemoryTableRowProps {
  variableIndex: number;
  deviceInstanceId: string;
  targetDataset?: DatasetType;
}

const useHDCEntryValue = (deviceInstanceId: string | undefined | undefined, identRef: IdentRef, target?: DatasetType): number => {
  const statusValue = useContextDatasetStatusValue(deviceInstanceId, identRef, target);
  return statusValue?.value ?? 0;
};

const HDCErrorMemoryTableRow
: React.FC<HDCErrorMemoryTableRowProps> = (props: HDCErrorMemoryTableRowProps):
  React.ReactElement | null => {
  const {
    variableIndex, deviceInstanceId, targetDataset,
  } = props;

  const isRowEmpty = useHDCIsEntryEmpty(deviceInstanceId, HDC_ERROR_MEMORY__VAR__ENTRY(variableIndex), targetDataset);
  const errorEntryEnumLabel = useHDCEntryEnumLabelValue(deviceInstanceId, HDC_ERROR_MEMORY__VAR__ENTRY(variableIndex), targetDataset);
  const workingHoursStatusValue = useHDCEntryValue(deviceInstanceId, HDC_ERROR_MEMORY__VAR__WORK_HOURS(variableIndex), targetDataset);
  const currentStatusValue = useHDCEntryValue(deviceInstanceId, HDC_ERROR_MEMORY__VAR__CURRENT(variableIndex), targetDataset);
  const voltageStatusValue = useHDCEntryValue(deviceInstanceId, HDC_ERROR_MEMORY__VAR__VOLTAGE(variableIndex), targetDataset);
  const temperatureStatusValue = useHDCEntryValue(deviceInstanceId, HDC_ERROR_MEMORY__VAR__TEMPERATURE(variableIndex), targetDataset);

  return (
    <DataTableRow>
      <DataTableRowItem width={HDC_ERROR_MEMORY__HDR_ERROR_ENTRY__WIDTH}>{isRowEmpty ? '' : errorEntryEnumLabel}</DataTableRowItem>
      <DataTableRowItem width={HDC_ERROR_MEMORY__HDR_WORKING_HOURS__WIDTH}>{isRowEmpty ? '' : workingHoursStatusValue}</DataTableRowItem>
      <DataTableRowItem width={HDC_ERROR_MEMORY__HDR_CURRENT__WIDTH}>{isRowEmpty ? '' : currentStatusValue}</DataTableRowItem>
      <DataTableRowItem width={HDC_ERROR_MEMORY__HDR_VOLTAGE__WIDTH}>{isRowEmpty ? '' : voltageStatusValue}</DataTableRowItem>
      <DataTableRowItem width={HDC_ERROR_MEMORY__HDR_TEMPERATURE__WIDTH}>{isRowEmpty ? '' : temperatureStatusValue}</DataTableRowItem>
    </DataTableRow>
  );
};

export default HDCErrorMemoryTableRow;
