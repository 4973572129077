/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { ReduxControlProps } from '../../ReduxControlProps';
import PSRManualControl from './PSRManualControl';

const steps = [
  'PSR_UNI_L__WHEEL_MANUEL_STEP1',
  'PSR_UNI_L__WHEEL_MANUEL_STEP2',
  'PSR_UNI_L__WHEEL_MANUEL_STEP3',
  'PSR_UNI_L__WHEEL_MANUEL_STEP4',
  'PSR_UNI_L__WHEEL_MANUEL_STEP5',
  'PSR_UNI_L__WHEEL_MANUEL_STEP6',
  'PSR_UNI_L__WHEEL_MANUEL_STEP7',
  'PSR_UNI_L__WHEEL_MANUEL_STEP8',
];

const PSRManualControlCH2: React.FC<ReduxControlProps> = ():React.ReactElement => (
  <PSRManualControl steps={steps} />
);

export default PSRManualControlCH2;
