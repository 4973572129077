/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';

export const I18N__WEBSERVICE__INITIALIZE = 'I18N__WEBSERVICE__INITIALIZE';
export const I18N__WEBSERVICE__REQUEST = 'I18N__WEBSERVICE__REQUEST';

export interface i18nWebServiceInitPayload {
  url: string;
}

export interface i18nWebServiceInitAction extends Action<typeof I18N__WEBSERVICE__INITIALIZE> {
  payload: i18nWebServiceInitPayload;
}

export interface i18nWebServiceRequestPayload {
  language: string;
  namespace: string;
}

export interface i18nWebServiceRequestAction extends Action<typeof I18N__WEBSERVICE__REQUEST> {
  payload: i18nWebServiceRequestPayload;
}

export type i18nWebServiceActionTypes = i18nWebServiceInitAction | i18nWebServiceRequestAction;
