/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { SpecialComponents } from 'react-markdown/lib/ast-to-react';
import { NormalComponents } from 'react-markdown/lib/complex-types';
import useLicenseInfo from '../hooks/useLicenseInfo';
import PackageLegalInformation from './PackageLegal/PackageLegalInformation';

const BackstagePageLegalInformationV2: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const [expandedLicense, setExpandedLicense] = useState<number>(-1);
  const licenseInfoJson = useLicenseInfo();

  const markdownComponents: Partial<NormalComponents & SpecialComponents> = {
    h1: 'h1',
    p: ({ ...props }) => <p {...props} />,
    a: ({ ...props }) => <a {...props} target="_blank" rel="noreferrer" />,
  };

  const licenseInfo = licenseInfoJson.map((entry, idx) => (
    <PackageLegalInformation
      key={`pkg-${entry.package.name}--${entry.package.version ?? 'unknown'}`}
      pkg={entry}
      expandedLicense={expandedLicense === idx}
      setExpandedLicense={() => setExpandedLicense(expandedLicense === idx ? -1 : idx)}
    />
  ));

  return (
    <div className="full">
      <h1>
        {`${t<string>('BACKSTAGE_PAGE_LEGAL_INFORMATION__TITLE')}`}
      </h1>
      <ReactMarkdown components={markdownComponents}>
        {t<string>('BACKSTAGE_PAGE_LEGAL_INFORMATION__TEXT')}
      </ReactMarkdown>
      <div>
        {licenseInfo}
      </div>
    </div>
  );
};

export default BackstagePageLegalInformationV2;
