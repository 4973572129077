/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */
import { IdentRef } from '@gpt/commons';
import { useCallback, useEffect, useState } from 'react';
import { useTypedSelector } from '../../../../store';
import { deviceStatusValueSelector } from '../../../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';

const useDragIndicatorStatusValue = (
  deviceInstanceId: string,
  identRef: IdentRef,
  datasetType?: DatasetType,
) => {
  const [minValue, setMinValue] = useState<undefined | number>(undefined);
  const [maxValue, setMaxValue] = useState<undefined | number>(undefined);

  const value = useTypedSelector((state) => {
    const statusValue = deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, datasetType ?? DatasetType.device, identRef);
    return statusValue?.value ?? 0;
  });

  useEffect(() => {
    if (maxValue === undefined) {
      setMaxValue(value);
    } else if (value > maxValue) {
      setMaxValue(value);
    }
  }, [value, maxValue]);

  useEffect(() => {
    if (minValue === undefined) {
      setMinValue(value);
    } else if (value < minValue) {
      setMinValue(value);
    }
  }, [value, minValue]);

  const resetDragValues = useCallback((currentValue) => {
    setMinValue(currentValue);
    setMaxValue(currentValue);
  }, []);

  return [value, minValue ?? value, maxValue ?? value, resetDragValues] as const;
};

export default useDragIndicatorStatusValue;
