/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { DeviceInstanceActionPayload } from '../../../common';

export const IOLINK__ACTION__EXECUTE_SYSTEM_COMMAND = 'IOLINK__ACTION__EXECUTE_SYSTEM_COMMAND';

/* ************************************************************************************ */
/* ACTION: IoLinkExecuteSysCommandAction                                               */
/* ************************************************************************************ */
export interface IoLinkExecuteSysCommandPayload extends DeviceInstanceActionPayload {
  command: number;
}

export interface IoLinkExecuteSysCommandAction extends Action<typeof IOLINK__ACTION__EXECUTE_SYSTEM_COMMAND> {
  payload: IoLinkExecuteSysCommandPayload;
}

export type typeIoLinkSystemCommandMiddlewareActions = IoLinkExecuteSysCommandAction;
