/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { LoadingControl } from '../../../components/LoadingControl/LoadingControl';
import UnsupportedControl from '../../../components/UnsupportedControl/UnsupportedControl';
import { DatasetContext } from '../../DatasetContext';
import { useTypedSelector } from '../../../store';
import { appStructureSelector } from '../../../store/selectors';
import './CompareViewControl.scss';
import CompareGroupStatusValues from '../../../controls/Compare/CompareGroupStatusValues/CompareGroupStatusValues';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { deviceTargetDatasetSelector } from '../../../store/deviceInstances/store/dataStorage/selectors';

const CompareViewControl: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);

  const activeDeviceInstance = useTypedSelector((state) => state.deviceInstances.activeDeviceInstance);
  const rootMenu = useTypedSelector((state) => {
    const deviceInstance = state.deviceInstances.activeDeviceInstance;
    return appStructureSelector('APP_COMPAREDEVICE', deviceTargetDatasetSelector(state.deviceInstances, deviceInstance, targetDataset));
  });
  const compareMode = useTypedSelector((state) => state.compareView.compareMethod);

  if (rootMenu === undefined || activeDeviceInstance === undefined) {
    return <LoadingControl title={t<string>('SIDEBAR__COMPARE_PARAMETERS_TITLE')} />;
  }

  if (rootMenu.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return <UnsupportedControl text="CompareViewControl: Only structure is supported" />;
  }

  return (
    <div className="compare-param-control">
      {rootMenu.elements.map((element) => (
        <CompareGroupStatusValues
          key={`cmp-view-group--${rootMenu.identRef}--${element.identRef}`}
          deviceInstanceId={activeDeviceInstance}
          compareMode={compareMode}
          compareMenuIdent={element.identRef}
          leftDatasetType={DatasetType.user}
          rightDatasetType={DatasetType.device}
        />
      ))}
    </div>
  );
};

export default CompareViewControl;
