/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import './DeviceStatusVariableValueControl.scss';

export interface DeviceStatusVariableValueProps {
  label: string;
  value: string;
  unit?: string;
}

export const DeviceStatusVariableValueControl: React.FC<DeviceStatusVariableValueProps> = (props: DeviceStatusVariableValueProps): React.ReactElement => {
  const {
    label, value, unit,
  } = props;

  return (
    <div className="device-status-variable-value__container">
      <div className="device-status-variable-value__container-space" />
      <div className="device-status-variable-value__container-label">
        {`${label}:`}
        <span> </span>
      </div>
      <div className="device-status-variable-value__container-value">
        {value}
      </div>
      {unit && (
      <div className="device-status-variable-value__container-unit">
        {unit}
      </div>
      )}

    </div>
  );
};
