/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import PageSelectActiveDevice from '../../PageCollection/PageSelectActiveDevice/PageSelectActiveDevice';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import { deviceWizardInitButtons, deviceWizardSetButtons, deviceWizardUpdateButtons } from '../../../store/deviceWizard';
import { WIZARD_BUTTON_LABEL__NEXT, WIZARD_BUTTON_LABEL__TRANSFER } from '../../helper';
import WizardView from '../../WizardView/WizardView';
import { wizardPreviewMenuSelector, wizardReportMenuSelector } from '../../../store/selectors/rootStructureSelector';
import TPFilePageCreateBackupCxe from './TPFilePageCreateBackupCxe/TPFilePageCreateBackupCxe';
import TPFilePageTransferDatasetCxe from './TPFilePageTransferDataset/TPFilePageTransferDatasetCxe';
import { closeCxeDeviceParameterizationApp } from '../../../services/clipxEngineerIntegrationService';
import { LoadingControl } from '../../../components/LoadingControl';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { disposeDevice, useTypedSelector } from '../../../store';
import { executeDeviceDownloadMethod } from '../../../store/deviceInstances/middleware/deviceMethodExecution/actions';
import { WizardProps } from '../../types';

const TransferDeviceParameterWizardCxe:React.FC<WizardProps> = (props: WizardProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deviceInstanceId } = props;

  const orderCode = useTypedSelector((state) => state.cxeTransferWizard.orderCode);
  const transferValues = useTypedSelector((state) => state.cxeTransferWizard.transferValues);
  const reportMenu = useTypedSelector((state) => wizardReportMenuSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, 'WIZARD_TRANSFERPARAMETERFILE'));
  const previewMenu = useTypedSelector((state) => wizardPreviewMenuSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, 'WIZARD_TRANSFERPARAMETERFILE'));

  useEffect(() => {
    dispatch(deviceWizardInitButtons({
      abort: ButtonState.enabled,
      back: ButtonState.disabled,
      next: ButtonState.disabled,
      complete: ButtonState.hidden,
      pcta: ButtonRole.next,
    }));
  }, []);

  if (orderCode === undefined || transferValues === undefined) {
    return <LoadingControl title={t<string>('LOADING_DATA')} />;
  }

  return (
    <WizardView
      title={t<string>('WIZARD_TRANSFER_DEVICE_PARAMETER_CXE__TITLE')}
      onWizardExit={async (mode: ButtonRole) => {
        if (mode === ButtonRole.abort) {
          closeCxeDeviceParameterizationApp({
            result: 'cancelled',
          });
        } else {
          closeCxeDeviceParameterizationApp({
            result: 'success',
          });
        }
        dispatch(disposeDevice(deviceInstanceId));
      }}
    >
      <PageSelectActiveDevice
        title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_SELECT_DEVICE__TITLE')}
        deviceInstanceId={deviceInstanceId}
        filter={(device) => (`${orderCode}` === `${device.catalog.productOrderNumber}`)}
        supportedWizard={DeviceModelStatus.Wizards.WizardType.TRANSFERPARAMETERFILE}
        onChangeToNextPage={() => {
          dispatch(deviceWizardSetButtons({
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.disabled },
            next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__TRANSFER },
            complete: { state: ButtonState.hidden },
            pcta: ButtonRole.next,
          }));
        }}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.enabled,
            back: ButtonState.disabled,
            next: ButtonState.enabled,
            complete: ButtonState.hidden,
            pcta: ButtonRole.next,
          }));
        }}
      />
      <TPFilePageCreateBackupCxe
        title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_CREATE_BACKUP__TITLE')}
        deviceInstanceId={deviceInstanceId}
        previewMenu={previewMenu}
        onChangeToNextPage={() => {
          dispatch(executeDeviceDownloadMethod({
            targetInstance: deviceInstanceId,
            methodIdent: DeviceModelStatus.Methods.METHOD_DOWNLOAD__IDENTREF,
            values: transferValues,
          }));
          dispatch(deviceWizardUpdateButtons({
            abort: ButtonState.disabled,
            back: ButtonState.disabled,
            next: ButtonState.hidden,
            complete: ButtonState.disabled,
            pcta: ButtonRole.complete,
          }));
        }}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardSetButtons({
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.enabled },
            next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__NEXT },
            complete: { state: ButtonState.hidden },
            pcta: ButtonRole.next,
          }));
        }}
      />
      <TPFilePageTransferDatasetCxe
        title={t<string>('WIZARD_TRANSFER_PARAMETER_FILE__PAGE_TRANSFER_DATASET__TITLE')}
        reportMenu={reportMenu}
        deviceInstanceId={deviceInstanceId}
        onChangeToPreviousPage={() => {
          dispatch(deviceWizardSetButtons({
            abort: { state: ButtonState.enabled },
            back: { state: ButtonState.enabled },
            next: { state: ButtonState.enabled, label: WIZARD_BUTTON_LABEL__TRANSFER },
            complete: { state: ButtonState.hidden },
            pcta: ButtonRole.next,
          }));
        }}
      />
    </WizardView>
  );
};

export default TransferDeviceParameterWizardCxe;
