/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import Button from 'react-bootstrap/Button';
import ArrowCircle from '../../../../assets/icons/button-arrow-circle.svg';
import ArrowLeft from '../../../../assets/icons/button-arrow-left.svg';
import CompareParameterViewLayout from '../../../../views/CompareView/CompareParameterViewLayout';
import { ButtonFunction } from '../../CompareTypes';
import EqualSign from './assets/equal-sign.svg';
import UnequalSign from './assets/unequal-sign.svg';
import './CompareContainer.scss';

export interface CompareContainerProps extends React.PropsWithChildren<any> {
    label?: string;
    onButtonClick: () => void,
    buttonFunction: ButtonFunction,
    equal: boolean,
    showImageAndSign?: boolean;
    hideLeftControl?: boolean;
    hideRightControl?: boolean;
}

const CompareContainer
  : React.FC<CompareContainerProps> = (props: CompareContainerProps)
: React.ReactElement => {
    const {
      label,
      onButtonClick, children,
      equal, buttonFunction, showImageAndSign,
      hideLeftControl, hideRightControl,
    } = props;

    let showSignControl = true;

    // If showLeftControl and showRightControl is false, Hide button
    const btnFunction = hideLeftControl === true || hideRightControl === true
      ? ButtonFunction.Hidden
      : buttonFunction;

    let classHiddenName = '';
    let buttonIcon = '';
    switch (btnFunction) {
      case ButtonFunction.WriteValue:
        buttonIcon = ArrowLeft;
        break;
      case ButtonFunction.RedoValue:
        buttonIcon = ArrowCircle;
        break;
      case ButtonFunction.Hidden:
        buttonIcon = ArrowCircle;
        classHiddenName = 'compare-container__button--hidden';
        break;
      default:
    }

    if (showImageAndSign === false) {
      showSignControl = false;
      classHiddenName = 'compare-container__button--hidden';
    }

    if (hideLeftControl === true || hideRightControl === true) {
      showSignControl = false;
      classHiddenName = 'compare-container__button--hidden';
    }

    const signControl = showSignControl
      ? <img className="compare-container__center__sign" src={equal ? EqualSign : UnequalSign} alt="button icon" />
      : undefined;

    return (
      <CompareParameterViewLayout>
        <div className="compare-container__left d-flex flex-row align-items-right">
          <div className="compare-container__left__label d-flex flex-row align-items-right m-0 p-0 px-1">
            <p>{hideLeftControl && hideRightControl ? (null) : label}</p>
          </div>
          <div className="compare-container__left__control compare-container__control--x-padding d-flex flex-row align-items-center">
            {hideLeftControl ? (null) : (children[0] ?? <div>Not available</div>)}
          </div>
        </div>
        <div className="compare-container__center d-flex justify-content-center align-items-center">
          {signControl}
        </div>
        <div className="compare-container__right__control compare-container__control--x-padding d-flex flex-row align-items-center w-100">
          <Button variant="tertiary" className={`compare-container__button rounded-0 border-0 text-format ${classHiddenName}`} onClick={() => onButtonClick()}>
            <img className="compare-container__button__arrow p-0 m-0" src={buttonIcon} alt="button icon" />
          </Button>
          <div className="px-1 w-100">
            {hideRightControl ? (null) : (children[1] ?? <div>Not available</div>)}
          </div>
        </div>
      </CompareParameterViewLayout>
    );
  };

export default CompareContainer;
