/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, WebDevice } from '@gpt/commons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingControl } from '../../../components/LoadingControl';
import SelectableAccordion, { SelectableAccordionItem } from '../../../components/SelectableAccordion/SelectableAccordion';
import PreviewDeviceParametersControl from '../../../controls/PreviewDeviceParametersControl/PreviewDeviceParametersControl';
import PageSelectFileComponent from '../../../PageComponents/PageSelectFileComponent/PageSelectFileComponent';
import SelectDeviceDialog from '../../../PageComponents/SelectDeviceDialog/SelectDeviceDialog';
import { ButtonRole, ButtonState } from '../../../store/deviceWizard/types';
import { useTypedSelector } from '../../../store/reduxStore';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';
import './CPFilePageSelectCatalogDevice.css';
import { deviceMethodExecutionSelector } from '../../../store/deviceInstances/store/deviceMethod/selectors';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';
import {
  deviceWizardUpdateButtons, instantiateDeviceOfflineExternalFile, ExecutionState, instantiateDevice,
} from '../../../store';
import { deviceInstanceActiveDeviceState } from '../../../store/deviceInstances/store/activeDevice/selectors';

export const TRANSFER_PARAM_WIZARD__METHOD = 'TRANSFER-PARAM-WIZARD--INSTANTIATE-DEVICE-EXTFILE';

// eslint-disable-next-line no-unused-vars
enum TemplateType {
  // eslint-disable-next-line no-unused-vars
  NewFile,
  // eslint-disable-next-line no-unused-vars
  ExistingFile,
}

interface ExternalFileInfo {
  filename: string;
  content: string;
}

interface CPFilePageSelectCatalogDeviceProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const CPFilePageSelectCatalogDevice: React.FC<CPFilePageSelectCatalogDeviceProps> = (props: CPFilePageSelectCatalogDeviceProps)
  : React.ReactElement => {
  const { t } = useTranslation();
  const { active, previewMenu, deviceInstanceId } = props;

  const [deviceCatalogIdent, setDeviceCatalogIdent] = useState<string | undefined>(undefined);
  const catalogDeviceList = useTypedSelector((state) => {
    const deviceList = Object.keys(state.discoveryService.catalog.deviceList).map((key) => state.discoveryService.catalog.deviceList[key]);
    return deviceList.filter(
      (device) => device.properties.communication === WebDevice.CommunicationType.enabled
      && device.properties.supportedWizard.includes(DeviceModelStatus.Wizards.WizardType.CREATEPARAMETERFILE) === true,
    );
  });
  const activeDeviceState = useTypedSelector((state) => deviceInstanceActiveDeviceState(state.deviceInstances, deviceInstanceId));
  const methodWarning = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, TRANSFER_PARAM_WIZARD__METHOD)?.message);
  const methodStage = useTypedSelector((state) => deviceMethodExecutionSelector(state.deviceInstances, deviceInstanceId, TRANSFER_PARAM_WIZARD__METHOD)?.stage ?? MethodStageExecutionStatus.Initialize);
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState<ExternalFileInfo | undefined>(undefined);
  const [selectedMethod, setSelectedMethod] = useState<TemplateType>(TemplateType.NewFile);

  const selectableItems: SelectableAccordionItem[] = [
    {
      id: 'TEMPLATE_NEW_FILE',
      title: t<string>('WIZARD_CREATE_PARAMETER_FILE__PAGE_SELECT_DEVICE__NEW_PARAMETER_FILE'),
      createControl: (): React.ReactElement | undefined => undefined,
    },
    {
      id: 'TEMPLATE_EXISTING_FILE',
      title: t<string>('WIZARD_CREATE_PARAMETER_FILE__PAGE_SELECT_DEVICE__EXISTING_PARAMETER_FILE'),
      createControl: (): React.ReactElement => (
        <PageSelectFileComponent
          title=""
          buttonCaption={t<string>('STANDARD_BUTTON__SELECT_FILE')}
          buttonVariant="primary"
          selectedFilename={selectedFile?.filename}
          warning={methodWarning}
          onFileSelected={async (result) => {
            setSelectedFile({ filename: result.fileName, content: result.contents as string });
            dispatch(deviceWizardUpdateButtons({
              source: 'CPFilePageSelectCatalogDevice',
              abort: ButtonState.disabled,
              back: ButtonState.disabled,
              next: ButtonState.disabled,
              complete: ButtonState.hidden,
              pcta: ButtonRole.next,
            }));
            dispatch(instantiateDeviceOfflineExternalFile({
              methodId: TRANSFER_PARAM_WIZARD__METHOD,
              filename: result.fileName,
              content: result.contents as string,
            }, deviceInstanceId));
          }}
        />
      ),
    },
  ];

  // load preview control,
  // when user go back to the page and file is already select (device is undefined)
  // we need to load preview once again
  useEffect(() => {
    if (active !== true) {
      return;
    }

    if (selectedMethod === TemplateType.NewFile) {
      return;
    }

    if (selectedFile !== undefined && previewMenu === undefined) {
      dispatch(instantiateDeviceOfflineExternalFile({
        methodId: TRANSFER_PARAM_WIZARD__METHOD,
        filename: selectedFile.filename,
        content: selectedFile.content as string,
      }, deviceInstanceId));
    }
  }, [selectedFile, previewMenu, selectedMethod, active, deviceInstanceId]);

  useEffect(() => {
    if (active !== true) {
      return;
    }

    if (activeDeviceState !== ExecutionState.success) {
      dispatch(deviceWizardUpdateButtons({
        source: 'CPFilePageSelectCatalogDevice(2)',
        abort: ButtonState.enabled,
        back: ButtonState.disabled,
        next: ButtonState.disabled,
        complete: ButtonState.hidden,
        pcta: ButtonRole.next,
      }));
      return;
    }

    if (deviceCatalogIdent !== undefined) {
      dispatch(deviceWizardUpdateButtons({
        source: 'CPFilePageSelectCatalogDevice(3)',
        abort: ButtonState.enabled,
        back: ButtonState.disabled,
        next: ButtonState.enabled,
        complete: ButtonState.hidden,
        pcta: ButtonRole.next,
        focusPcta: ButtonRole.next,
      }));
    }
  }, [activeDeviceState, deviceCatalogIdent, active]);

  useEffect(() => {
    if (!active) {
      return;
    }
    if (selectedFile !== undefined) {
      dispatch(deviceWizardUpdateButtons({
        source: 'CPFilePageSelectCatalogDevice(4)',
        abort: ButtonState.enabled,
        back: ButtonState.disabled,
        next: methodStage === MethodStageExecutionStatus.DoneFailed || methodStage === MethodStageExecutionStatus.DoneSuccess ? ButtonState.enabled : ButtonState.disabled,
        complete: ButtonState.hidden,
        pcta: ButtonRole.next,
        focusPcta: methodStage === MethodStageExecutionStatus.DoneSuccess ? ButtonRole.next : undefined,
      }));
    }
  }, [selectedFile, methodStage, active]);

  let previewDatasetControl;
  if (selectedFile === undefined) {
    previewDatasetControl = undefined;
  } else {
    switch (methodStage) {
      case MethodStageExecutionStatus.Initialize:
        previewDatasetControl = undefined;
        break;
      case MethodStageExecutionStatus.InProgress:
        previewDatasetControl = <LoadingControl title={t<string>('LOADING_DATA')} />;
        break;
      case MethodStageExecutionStatus.DoneFailed:
        previewDatasetControl = undefined;
        break;
      case MethodStageExecutionStatus.DoneSuccess: {
        previewDatasetControl = (previewMenu === undefined) ? undefined : (
          <PreviewDeviceParametersControl
            parameterFile={selectedFile.filename.replace(/^.*(\\|\/|:)/, '')}
            identRef={previewMenu}
            deviceInstanceId={deviceInstanceId}
          />
        );
        break;
      }
      default:
        previewDatasetControl = undefined;
    }
  }

  return (
    <Container fluid className="w-100 h-100">
      <Row className="h-100">
        <Col xs={6} className="h-100">
          <div className="wiz-reset-container__title pb-3">
            {t<string>('WIZARD_CREATE_PARAMETER_FILE__PAGE_SELECT_DEVICE__SELECT_METHOD')}
          </div>
          <SelectableAccordion
            group="method-group"
            items={selectableItems}
            line={false}
            onClick={(id) => {
              if (id === 'TEMPLATE_NEW_FILE') {
                setSelectedMethod(TemplateType.NewFile);
              } else if (id === 'TEMPLATE_EXISTING_FILE') {
                setSelectedMethod(TemplateType.ExistingFile);
              }
            }}
          />
        </Col>
        <Col xs={6} className="h-100">
          <div className="cpfile-page-select-preview-container p-2">
            {
              selectedMethod === TemplateType.NewFile
              && (
                <SelectDeviceDialog
                  catalogDeviceList={catalogDeviceList}
                  onSelectDevice={async (device) => {
                    dispatch(instantiateDevice({
                      deviceInfo: {
                        catalog: {
                          ...device,
                        },
                      },
                      targetInstance: deviceInstanceId,
                      wizardMode: true,
                    }));
                    setDeviceCatalogIdent(device.deviceCatalogIdent);
                  }}
                  selectedDeviceCatalogIdent={deviceCatalogIdent}
                />
              )
            }
            {
              selectedMethod === TemplateType.ExistingFile
              && (previewDatasetControl)
            }
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CPFilePageSelectCatalogDevice;
