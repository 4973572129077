/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useRef, useState } from 'react';
import { LinearizationRecord, Point } from '../../../Interfaces/LinearizationInterface';
import { Tooltip, TooltipProps } from '../../Tooltip/Tooltip';
import './LinearizationPoint.css';

export interface LinearizationPointProps {
  drawingPoint: Point,
  linearizationValue: LinearizationRecord,
  isValid: boolean;
  onClick: () => void;
  // eslint-disable-next-line no-unused-vars
  onMouseEnter: (point: Point) => void;
  // eslint-disable-next-line no-unused-vars
  onMouseLeave: (point: Point) => void;
}

export const LinearizationPoint: React.FC<LinearizationPointProps> = (props: LinearizationPointProps): React.ReactElement => {
  const {
    drawingPoint, linearizationValue, isValid, onClick, onMouseEnter, onMouseLeave,
  } = props;
  const [isSelected, setIsSelected] = useState(false);
  const pointRef = useRef<HTMLDivElement>(null);

  const onMouseLeaveHandler = () => {
    setIsSelected(false);
    onMouseLeave(drawingPoint);
  };

  const onMouseEnterHandler = () => {
    setIsSelected(true);
    onMouseEnter(drawingPoint);
  };

  const RoundValue = (value: number) => (Math.round(value * 1000) / 1000).toString();

  const tooltipItems: TooltipProps = {
    items: [
      {
        label: linearizationValue.xAxisRecord.measurement,
        value: RoundValue(linearizationValue.xAxisRecord.value),
        unit: linearizationValue.xAxisRecord.unit,
      },
      {
        label: linearizationValue.yAxisRecord.measurement,
        value: RoundValue(linearizationValue.yAxisRecord.value),
        unit: linearizationValue.yAxisRecord.unit,
      },
      {
        label: linearizationValue.deviationRecord.measurement,
        value: RoundValue(linearizationValue.deviationRecord.value),
        unit: linearizationValue.deviationRecord.unit,
      },
    ],
  };

  const linearizationPointClass = isValid ? 'LinearizationPoint' : 'LinearizationPoint-invalid';

  return (
    <div style={{ position: 'absolute', left: `${drawingPoint.xValue - 8}px`, bottom: `${drawingPoint.yValue - 8}px` }}>
      {isSelected && (
        <div className="LinearizationPoint-tooltop-container">
          <Tooltip items={tooltipItems.items} />
        </div>
      )}
      <div
        role="presentation"
        ref={pointRef}
        className={linearizationPointClass}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        onClick={() => onClick()}
        onKeyUp={() => {}}
      />
    </div>
  );
};
