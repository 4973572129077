/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Resource } from 'i18next';

import i18nDictEn from './PSR_UNI_en.json';
import i18nDictDe from './PSR_UNI_de.json';
import i18nDictEs from './PSR_UNI_es.json';
import i18nDictFr from './PSR_UNI_fr.json';
import i18nDictIt from './PSR_UNI_it.json';
import i18nDictJa from './PSR_UNI_ja.json';
import i18nDictPl from './PSR_UNI_pl.json';
import i18nDictPt from './PSR_UNI_pt.json';
import i18nDictRu from './PSR_UNI_ru.json';
import i18nDictTr from './PSR_UNI_tr.json';
import i18nDictZh from './PSR_UNI_zh.json';
import i18nDictCs from './PSR_UNI_cs.json';
import i18nDictDa from './PSR_UNI_da.json';
import i18nDictFi from './PSR_UNI_fi.json';
import i18nDictHu from './PSR_UNI_hu.json';
import i18nDictKo from './PSR_UNI_ko.json';
import i18nDictNb from './PSR_UNI_nb.json';
import i18nDictNl from './PSR_UNI_nl.json';
import i18nDictRo from './PSR_UNI_ro.json';
import i18nDictSv from './PSR_UNI_sv.json';
import i18nDictUk from './PSR_UNI_uk.json';

/**
 * Default translation for application
 */
const PSR_UNI: Resource = {
  en: {
    PSR_UNI: i18nDictEn,
  },
  de: {
    PSR_UNI: i18nDictDe,
  },
  es: {
    PSR_UNI: i18nDictEs,
  },
  fr: {
    PSR_UNI: i18nDictFr,
  },
  it: {
    PSR_UNI: i18nDictIt,
  },
  ja: {
    PSR_UNI: i18nDictJa,
  },
  pl: {
    PSR_UNI: i18nDictPl,
  },
  pt: {
    PSR_UNI: i18nDictPt,
  },
  ru: {
    PSR_UNI: i18nDictRu,
  },
  tr: {
    PSR_UNI: i18nDictTr,
  },
  zh: {
    PSR_UNI: i18nDictZh,
  },
  cs: {
    PSR_UNI: i18nDictCs,
  },
  da: {
    PSR_UNI: i18nDictDa,
  },
  fi: {
    PSR_UNI: i18nDictFi,
  },
  hu: {
    PSR_UNI: i18nDictHu,
  },
  ko: {
    PSR_UNI: i18nDictKo,
  },
  nb: {
    PSR_UNI: i18nDictNb,
  },
  nl: {
    PSR_UNI: i18nDictNl,
  },
  ro: {
    PSR_UNI: i18nDictRo,
  },
  sv: {
    PSR_UNI: i18nDictSv,
  },
  uk: {
    PSR_UNI: i18nDictUk,
  },
};

export default PSR_UNI;
