/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { writeActiveDeviceVariableValues } from '../../../store';

const useDispatchStringValue = (deviceInstance: string | undefined) => {
  const dispatch = useDispatch();

  const dispatchValue = useCallback((
    newValue: string,
    statusValue?: DeviceModelStatus.StatusValue,
  ): void => {
    if (statusValue === undefined || deviceInstance === undefined) {
      return;
    }
    dispatch(writeActiveDeviceVariableValues(deviceInstance, [{
      identRef: statusValue.identRef,
      value: newValue,
      backupValue: statusValue.backupValue ?? statusValue.value,
    }]));
  }, []);

  return dispatchValue;
};

export default useDispatchStringValue;
