/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { DeviceModelStatus, Services } from '@gpt/commons';
import {
  Middleware, MiddlewareAPI, Dispatch,
} from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { createFakeExternalDataset } from '../../../../__fake__/createFakeExternalDataset';
import { ExecutionState } from '../../../common';
import {
  PREVIEW_DATASET__REQUEST_DEVICE_DATASET,
  PREVIEW_DATASET__REQUEST_EXTERNAL_DATASET,
  PREVIEW_DATASET__REQUEST_INITIAL_DATASET,
  typePreviewDatasetActionTypes,
} from './types';
import { deviceInstancesStoreSelector } from '../../../reduxStoreSelector';
import { DatasetState } from '../../store/deviceDataset/types';
import { setPreviewDeviceRequestStatus, setPreviewDataset } from '../../store/previewDataset';
import { deviceInstanceActiveDeviceInstance } from '../../store/activeDevice/selectors';

const datasets: Services.DeviceModelServer.DatasetProviderService.ExternalDataset[] = [
  createFakeExternalDataset('dataset__1', 'Prifile#1', 'Dataset #101, 2020-11-23 14:00', '2020-11-23 14:00'),
  createFakeExternalDataset('dataset__2', 'Prifile#1', 'Dataset #244, 2020-12-23 18:00', '2020-12-23 18:00'),
  createFakeExternalDataset('dataset__3', 'Prifile#1', 'Dataset #311, 2020-12-31 23:59', '2020-12-31 23:59'),
];

// export const callDatasetRequest = async (broker: ServiceBroker,
//   ident: Services.DeviceModelServer.DatasetProviderService.DatasetIdent): Promise<Services.DeviceModelServer.DatasetProviderService.GetDatasetResponse> => {
//   const request: Services.DeviceModelServer.DatasetProviderService.GetDatasetRequest = {
//     type: Services.DeviceModelServer.DatasetProviderService.DPS_GET_DATASET_REQUEST,
//     ident,
//     requestId: uuidv4(),
//   };
//   return broker.call<Services.DeviceModelServer.DatasetProviderService.GetDatasetResponse,
//     Services.DeviceModelServer.DatasetProviderService.GetDatasetRequest>(`${Services.DeviceModelServer.DatasetProviderService.ServiceFullname}.datasetRequest`, request);
// };

const callDatasetRequest = async (ident: Services.DeviceModelServer.DatasetProviderService.DatasetIdent)
: Promise<Services.DeviceModelServer.DatasetProviderService.GetDatasetResponse> => new Promise<Services.DeviceModelServer.DatasetProviderService.GetDatasetResponse>((resolve) => {
  setTimeout(() => {
    resolve({
      type: Services.DeviceModelServer.DatasetProviderService.DPS_GET_DATASET_RESPONSE,
      requestId: uuidv4(),
      dataset: datasets.find((data) => data.header.datasetId === ident),
      deviceInstanceId: 'deviceInstanceId',
    });
  }, 200);
});

// const callInitialDatasetRequest = async (broker: ServiceBroker)
// : Promise<Services.DeviceModelServer.DatasetProviderService.GetDatasetResponse> => new Promise<Services.DeviceModelServer.DatasetProviderService.GetDatasetResponse>((resolve) => {
//   setTimeout(() => {
//     resolve({
//       type: Services.DeviceModelServer.DatasetProviderService.DPS_GET_DATASET_RESPONSE,
//       requestId: uuidv4(),
//       dataset: createFakeExternalDataset('initial', 'Prifile#1', 'Initial', new Date().toISOString()),
//     });
//   }, 200);
// });

const previewDatasetMiddleware = (): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typePreviewDatasetActionTypes>(action: A): Promise<A> => {
  switch (action.type) {
    case PREVIEW_DATASET__REQUEST_EXTERNAL_DATASET: {
      const { targetInstance, data } = action.payload;
      const { datasetIdent } = data;
      api.dispatch(setPreviewDeviceRequestStatus(targetInstance, ExecutionState.pending));
      const response = await callDatasetRequest(datasetIdent);
      if (response.error === undefined) {
        api.dispatch(setPreviewDataset(targetInstance, response.dataset));
        api.dispatch(setPreviewDeviceRequestStatus(targetInstance, ExecutionState.success));
      } else {
        api.dispatch(setPreviewDataset(targetInstance));
        api.dispatch(setPreviewDeviceRequestStatus(targetInstance, ExecutionState.failed));
      }
    }
      break;
    case PREVIEW_DATASET__REQUEST_INITIAL_DATASET: {
      const { targetInstance, data } = action.payload;
      const { previewMenuId } = data;
      api.dispatch(setPreviewDeviceRequestStatus(targetInstance, ExecutionState.pending));
      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const deviceDataset = deviceInstances.instances[targetInstance].deviceDataset.user as DatasetState;
      if (deviceDataset !== undefined) {
        const datasetValues: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetValues = {};
        Object.keys(deviceDataset.descriptors).forEach((key) => {
          if (deviceDataset.descriptors[key] !== undefined && deviceDataset.values[key] !== undefined) {
            let initialValue;
            const descriptor = deviceDataset.descriptors[key];

            if (descriptor.type === DeviceModelStatus.StatusType.StatusDescriptor) {
              if (descriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.TABLE
                && descriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.TABLERECORD
                && descriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.RECORD) {
                initialValue = descriptor.valueType.initialValue;
              } else {
                initialValue = '';
              }
            }
            datasetValues[key] = JSON.parse(JSON.stringify(deviceDataset.values[key]));
            datasetValues[key].value = initialValue;
          }
        });

        const activeDeviceState = deviceInstanceActiveDeviceInstance(deviceInstances, targetInstance);
        const externalDataset: Services.DeviceModelServer.DatasetProviderService.ExternalDataset = {
          version: Services.DeviceModelServer.DatasetProviderService.EXTERNAL_DATASET__VERSION,
          header: {
            caption: 'DEVICE_DATASET',
            datasetId: 'device-dataset',
            saveDateTime: new Date(),
            userComment: '',
            device: JSON.parse(JSON.stringify(activeDeviceState?.device ?? {})),
            // previewMenu: (deviceDataset.descriptors.APP_REVIEWDEVICE as DeviceModelStatus.ApplicationDescriptor).appType.menu,
            previewMenu: previewMenuId,
          },
          values: datasetValues,
          descriptors: JSON.parse(JSON.stringify(deviceDataset.descriptors)),
        };
        api.dispatch(setPreviewDataset(targetInstance, externalDataset));
        api.dispatch(setPreviewDeviceRequestStatus(targetInstance, ExecutionState.success));
      } else {
        api.dispatch(setPreviewDeviceRequestStatus(targetInstance, ExecutionState.failed));
      }

      /*
      const response = await callInitialDatasetRequest(serviceBroker);
      if (response.error === undefined) {
        api.dispatch(setPreviewDataset(response.dataset));
        api.dispatch(setPreviewDeviceRequestStatus(ExecutionState.success));
      } else {
        api.dispatch(setPreviewDataset());
        api.dispatch(setPreviewDeviceRequestStatus(ExecutionState.failed));
      }
      */
    }
      break;
    case PREVIEW_DATASET__REQUEST_DEVICE_DATASET: {
      const { targetInstance, data } = action.payload;
      const { previewMenuId } = data;
      api.dispatch(setPreviewDeviceRequestStatus(targetInstance, ExecutionState.pending));
      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const deviceDataset = deviceInstances.instances[targetInstance].deviceDataset.user as DatasetState;

      if (deviceDataset !== undefined) {
        const datasetValues: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetValues = {};
        Object.keys(deviceDataset.values).forEach((key) => {
          if (deviceDataset.values[key] !== undefined) {
            datasetValues[key] = JSON.parse(JSON.stringify(deviceDataset.values[key]));
          }
        });

        const activeDeviceState = deviceInstanceActiveDeviceInstance(deviceInstances, targetInstance);
        const externalDataset: Services.DeviceModelServer.DatasetProviderService.ExternalDataset = {
          version: Services.DeviceModelServer.DatasetProviderService.EXTERNAL_DATASET__VERSION,
          header: {
            caption: 'DEVICE_DATASET',
            datasetId: 'device-dataset',
            saveDateTime: new Date(),
            userComment: '',
            device: JSON.parse(JSON.stringify(activeDeviceState?.device)),
            // previewMenu: (deviceDataset.descriptors.APP_REVIEWDEVICE as DeviceModelStatus.ApplicationDescriptor).appType.menu,
            previewMenu: previewMenuId,
          },
          values: datasetValues,
          descriptors: JSON.parse(JSON.stringify(deviceDataset.descriptors)),
        };
        api.dispatch(setPreviewDataset(targetInstance, externalDataset));
        api.dispatch(setPreviewDeviceRequestStatus(targetInstance, ExecutionState.success));
      } else {
        api.dispatch(setPreviewDeviceRequestStatus(targetInstance, ExecutionState.failed));
      }
    }
      break;
    default:
  }
  const result = next(action);
  return result;
};

export default previewDatasetMiddleware;
