/* eslint-disable max-len */
import React from 'react';
import {
  mapValueToAngle, gaugeMinAngle,
  gaugeMaxAngle, polarToCartesian, gaugeCenterX, gaugeCenterY, gaugeStrokeWidth, gaugeRadius,
} from '../common';

const gaugeTextRadius = gaugeRadius + gaugeStrokeWidth / 2 + 8;

export interface SVGGaugeTextPoint {
  value: number;
  text: string;
}

export interface SVGGaugeTextPointsProps {
  gaugePoints: SVGGaugeTextPoint[];
  gaugeMinValue: number,
  gaugeMaxValue: number
}

const SVGGaugeTextPoints: React.FC<SVGGaugeTextPointsProps> = (props: SVGGaugeTextPointsProps)
: React.ReactElement => {
  const { gaugePoints, gaugeMaxValue, gaugeMinValue } = props;

  const gaugeTextPoints = gaugePoints.map((point) => {
    const angle = mapValueToAngle(gaugeMinValue, gaugeMaxValue, gaugeMinAngle, gaugeMaxAngle, point.value);
    const arcPos = polarToCartesian(gaugeCenterX, gaugeCenterY, gaugeTextRadius, angle);
    let textAnchor = 'middle';
    if (angle === 0) {
      textAnchor = 'middle';
    } else {
      textAnchor = angle < 0 ? 'end' : 'start';
    }
    const key = `gauge-point-${angle}-${point.value}-${point.text}`;
    return <text key={key} x={arcPos.x} y={arcPos.y} fontSize={14} fontWeight="bold" textAnchor={textAnchor} fill="black">{point.text}</text>;
  });

  return (
    <g>
      {gaugeTextPoints}
      {/* <circle cx={gaugeCenterX} cy={gaugeCenterY} r={gaugeTextRadius} fillOpacity={0.1} /> */}
    </g>
  );
};

export default SVGGaugeTextPoints;
