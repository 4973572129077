/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import {
  DeviceInformation, WebDevice,
} from '@gpt/commons';
import EventEmitter from 'events';
import { infrastructureService } from '../InfrastructureService/InfrastructureService';

// eslint-disable-next-line no-unused-vars
type PromiseResolveFnType = <T>(value: T | PromiseLike<T>) => void;

// eslint-disable-next-line no-unused-vars
type PromiseRejectFnType = (error) => void;

interface WebWorkerPendingCall {
    resolve: PromiseResolveFnType;
    reject: PromiseRejectFnType;
}

interface WebWorkerPendingList {
    [requestId: string]: WebWorkerPendingCall;
}

const createDeviceWebWorkerBlob = async (deviceCatalogFilename: string): Promise<string | undefined> => {
  const workerScript = await infrastructureService.downloadDeviceModel(deviceCatalogFilename);
  if (workerScript === undefined) {
    return undefined;
  }

  const workerBlob = new Blob(
    [workerScript],
    { type: 'text/javascript' },
  );
  return URL.createObjectURL(workerBlob);
};

const createDeviceWebWorker = async (deviceCatalogIdent: string, deviceCatalogFilename: string): Promise<Worker | undefined> => {
  let workerBlobUrl: string | undefined;
  try {
    workerBlobUrl = await createDeviceWebWorkerBlob(deviceCatalogFilename);
  } catch (err) {
    // eslint-disable-next-line no-console
    workerBlobUrl = undefined;
  }

  if (workerBlobUrl === undefined) {
    return undefined;
  }

  let worker: Worker | undefined;
  try {
    worker = new Worker(workerBlobUrl, { name: `device-${deviceCatalogIdent}`, type: 'classic' });
  } catch (err) {
    // Error is not handled here
  }
  return worker;
};

export interface IWebWorkerLoader {
  dispose: () => Promise<void>;

  // eslint-disable-next-line no-unused-vars
  create: (device: DeviceInformation) => Promise<boolean>;

  /**
   *
   * @param request
   * @returns
   */
  postMessage: <TReq extends WebDevice.WebDeviceRequestTypes, TRes extends WebDevice.WebDeviceResponseTypes>(request: TReq) => Promise<TRes>;
}

class WebWorkerLoader implements IWebWorkerLoader {
  private worker: Worker | undefined;

  private pending: WebWorkerPendingList = {};

  private eventEmitter: EventEmitter;

  constructor(eventEmitter: EventEmitter) {
    this.eventEmitter = eventEmitter;
  }

  public dispose = async (): Promise<void> => {
    this.debug('webworker: dispose');
    if (this.worker === undefined) {
      return;
    }
    this.worker.terminate();
    this.worker = undefined;
    Object.keys(this.pending)
      .forEach((key) => {
        this.debug(' pending: ', key);
        this.pending[key].reject(new Error('WebWorker terminated'));
      });
    this.pending = {};
  };

  public create = async (device: DeviceInformation): Promise<boolean> => {
    if (this.worker !== undefined) {
      return true;
    }

    this.worker = await createDeviceWebWorker(device.catalog.deviceCatalogIdent, device.catalog.file.name);
    if (this.worker === undefined) {
      return false;
    }
    this.worker.addEventListener('message', this.onMessageListener);
    return true;
  };

  private onMessageListener = (ev: MessageEvent<WebDevice.WebDeviceResponseTypes | WebDevice.WebDeviceEventsTypes>) => {
    const { requestId, kind } = ev.data;
    if (kind === 'WEBDEVICE__NOTIFICATION_EVENT' || kind === 'WEBDEVICE__UPDATE_DATA_EVENT') {
      this.eventEmitter.emit(kind, ev.data);
    } else if (this.pending[requestId] !== undefined) {
      this.debug('    -- message (pending)', requestId);
      const { [requestId]: callback, ...rest } = this.pending;
      this.pending = rest;
      callback.resolve(ev.data);
    } else {
      this.debug('    -- message (unknown)', requestId);
      this.eventEmitter.emit(kind, ev.data);
    }
  };

  /**
     *
     * @param target Targer service + action: v1.ServiceName.FunctionName or ServiceName.FunctionName
     * @param request
     * @returns
     */
  public postMessage = async <TReq extends WebDevice.WebDeviceRequestTypes, TRes extends WebDevice.WebDeviceResponseTypes>(request: TReq): Promise<TRes> => {
    const { requestId } = request;
    return new Promise<TRes>((resolve, reject) => {
      this.debug(' >> -- postMessage', requestId);
      if (this.worker === undefined) {
        reject(new Error('WebWorker is not initialized'));
        return;
      }
      // Register request in pending queue
      if (this.pending[requestId] !== undefined) {
        reject(new Error(`Request with id ${requestId} already registered`));
        return;
      }
      this.pending[requestId] = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resolve: resolve as any,
        reject,
      };
      this.debug('    -- postMessage', requestId, request);
      this.worker.postMessage(request);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function, class-methods-use-this, @typescript-eslint/no-explicit-any
  private debug = (...args: any[]) => { };
}

export default WebWorkerLoader;
