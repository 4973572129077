/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { writeActiveDeviceVariableValues } from '../../../store';
import CheckboxEditorControl from '../../../components/Basic/CheckboxEditorControl/CheckboxEditorControl';
import { ParameterControl } from '../../../components/ParameterEditor';

export interface CheckboxEnumeratorControlProps {
  identRef: IdentRef;
  deviceInstanceId: string;
  enumeratorDescriptor: DeviceModelStatus.StatusDescriptorValueTypeEnumerator;
  statusValue?: number;
  backupValue?: number;
  label: string;
  help: string;
  readonly?: boolean;
  modified: boolean;
  handling: DeviceModelStatus.StatusDescriptorHandling,
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
  // eslint-disable-next-line no-unused-vars
  onCustomValueChange?: (value: boolean) => void;
}

const CheckboxEnumeratorControl
:React.FC<CheckboxEnumeratorControlProps> = (props: CheckboxEnumeratorControlProps):
  React.ReactElement => {
  const {
    deviceInstanceId,
    identRef, enumeratorDescriptor, statusValue, backupValue,
    label, help, modified, readonly, handling,
    onShowContentHelp, onHideContentHelp, onCustomValueChange,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <ParameterControl
      label={t<string>(label)}
    >
      <CheckboxEditorControl
        id={identRef}
        value={Boolean(statusValue)}
        label={t<string>(label)}
        help={t<string>(help)}
        edited={modified}
        onShowContentHelp={onShowContentHelp}
        onHideContentHelp={onHideContentHelp}
        onChange={(originalValue: boolean) => {
          if (onCustomValueChange !== undefined) {
            onCustomValueChange(originalValue);
            return;
          }

          if (statusValue === undefined) {
            return;
          }

          let newValue = 0;
          if (originalValue) {
            const enumItem = enumeratorDescriptor.enumerator.find((entry) => entry.value !== 0);
            newValue = enumItem ? enumItem.value : 0;
          }

          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            value: newValue,
            backupValue,
            identRef,
          }]));
        }}
        readonly={readonly ?? (statusValue === undefined || handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY)}
      />
      <div className="menu-control--bottom-padding" />
    </ParameterControl>
  );
};

export default CheckboxEnumeratorControl;
