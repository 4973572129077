/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { CompareStatusValueProps } from '../../CompareTypes';
import { UnsupportedControl } from '../../../../components/UnsupportedControl';
import { useTypedSelector } from '../../../../store';
import { deviceTargetDatasetSelector } from '../../../../store/deviceInstances/store/dataStorage/selectors';
import compareDeviceSpecificControls from '../compareDeviceSpecificControls';

/**
 * Compare two values from Dataset Value : value vs externalValue
 * backupValue used as storage for value before modification
 *
 * @param props Control Properties
 * @returns
 */
const CompareDeviceSpecific
: React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
  : React.ReactElement => {
  const {
    parentId,
    identRef,
    leftDataset,
    rightDataset,
    compareMode,
    onShowContentHelp, onHideContentHelp,
    deviceInstanceId,
  } = props;

  const ctrlType = useTypedSelector((state) => {
    // eslint-disable-next-line max-len
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, rightDataset);
    if (dataset === undefined) {
      return undefined;
    }

    const desc = dataset.descriptors[identRef];
    if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
      return undefined;
    }
    const { controlType } = desc;
    return controlType?.type === DeviceModelStatus.UI.ControlType.CTLDEVICESPECIFIC
      ? controlType.controlType
      : undefined;
  });

  if (ctrlType === undefined || compareDeviceSpecificControls[ctrlType] === undefined) {
    return <UnsupportedControl text={`CompareDeviceSpecific: unsupported control type [${ctrlType ?? 'undefined'}] / [${identRef}]`} />;
  }

  const C = compareDeviceSpecificControls[ctrlType];
  return (
    <C
      parentId={parentId}
      identRef={identRef}
      deviceInstanceId={deviceInstanceId}
      leftDataset={leftDataset}
      rightDataset={rightDataset}
      onHideContentHelp={onHideContentHelp}
      onShowContentHelp={onShowContentHelp}
      compareMode={compareMode}
    />
  );
};

export default CompareDeviceSpecific;
