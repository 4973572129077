/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  SystemInformationActionTypes,
  INFORMATION__SET_HOST_NAME,
  INFORMATION__SET_USER_NAME,
  INFORMATION__APP_INITIALIZED,
} from './types';

const setSystemHostName = (payload: string): SystemInformationActionTypes => ({
  type: INFORMATION__SET_HOST_NAME,
  payload,
});

const setSystemUserName = (payload: string): SystemInformationActionTypes => ({
  type: INFORMATION__SET_USER_NAME,
  payload,
});

const setSystemAppInitialized = (): SystemInformationActionTypes => ({
  type: INFORMATION__APP_INITIALIZED,
});

export {
  setSystemHostName,
  setSystemUserName,
  setSystemAppInitialized,
};
