/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { writeActiveDeviceVariableValues } from '../../../../store';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import UnsupportedControl from '../../../UnsupportedControl/UnsupportedControl';
import NumberEditorControl from '../../NumberEditorControl/NumberEditorControl';
import './VariableFloatEditorControl.css';

import { updateDeviceDatasetValues } from '../../../../store/deviceInstances/store/deviceDataset';
import { VariableEditorControlsProps } from '../types';
import useFloatMessageContextHelp from '../hooks/useFloatMessageContextHelp';

export interface VariableFloatEditorControlProps extends VariableEditorControlsProps {
  warning?: string;
  showLabel?: boolean;
  altLabel?: string;
  // eslint-disable-next-line no-unused-vars
  onCustomValueChange?: (value: string) => void;
}

const VariableFloatEditorControl
:React.FC<VariableFloatEditorControlProps> = (props: VariableFloatEditorControlProps):
  React.ReactElement => {
  const {
    statusDescriptor, statusValue, onShowContentHelp, onHideContentHelp,
    showContextMessageDef, readonly, warning, showLabel, deviceInstanceId,
    onCustomValueChange, altLabel,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warningMessage, showContentHelp, hideContentHelp] = useFloatMessageContextHelp(statusDescriptor, statusValue, showContextMessageDef, onShowContentHelp, onHideContentHelp);

  if (statusDescriptor === undefined) {
    return <div />;
  }
  if (statusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="VariableUIntegerEditorControl: Unsupported input object type" />;
  }
  if (statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
    return <UnsupportedControl text="VariableDateEditorControl: only FLOAT type is supported" />;
  }

  const { label, unit } = statusDescriptor;
  const { displayFormat, stepSize, precision } = statusDescriptor.valueType;

  return (
    <NumberEditorControl
      identRef={statusDescriptor?.identRef}
      label={showLabel === false ? undefined : t<string>(altLabel ?? label)}
      value={statusValue === undefined ? '' : statusValue.value}
      readonly={readonly ?? (statusValue === undefined || statusDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY)}
      modified={statusValue?.modified}
      unit={unit === undefined ? undefined : t<string>(unit)}
      stepSize={stepSize}
      precision={precision}
      displayFormat={displayFormat ?? '%.2f'}
      onValueChange={(originalValue) => {
        if (onCustomValueChange) {
          onCustomValueChange(originalValue);
          return;
        }

        if (statusValue === undefined) {
          return;
        }

        const parsedValue = parseFloat(originalValue);
        if (Number.isNaN(parsedValue)) {
          dispatch(updateDeviceDatasetValues(deviceInstanceId, {
            targetDataset: DatasetType.user,
            values: [{
              value: originalValue,
              backupValue: statusValue.backupValue,
              identRef: statusValue.identRef,
              valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
              valueValidityDescription: `${t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT')}`,
            }],
          }));
        } else {
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
            value: parsedValue,
            backupValue: statusValue.backupValue,
            identRef: statusDescriptor.identRef,
          }]));
        }
      }}
      onFocus={() => showContentHelp()}
      onBlur={() => hideContentHelp()}
      warning={warningMessage ?? warning}
    />
  );
};

export default VariableFloatEditorControl;
