/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateEditorControl } from '../../../../../../components/ParameterEditor';
import TextEditorControl from '../../../../../../components/ParameterEditor/TextEditorControl/TextEditorControl';
import { setContextParameterHelp } from '../../../../../../store/contexthelp/actions';
import { CompareValueProps } from '../../../../CompareTypes';
import CompareContainer from '../../../CompareContainer/CompareContainer';
import './CompareDateValue.css';

export interface CompareDateValueProps extends CompareValueProps {
  valueLeft: string;
  valueRight: string;
  // eslint-disable-next-line no-unused-vars
  onLeftValueChange?: (value: string) => void;
}

const CompareDateValue: React.FC<CompareDateValueProps> = (props: CompareDateValueProps)
: React.ReactElement => {
  const {
    onButtonClick, label, help, valueLeft, valueRight, readonly, modified, onLeftValueChange,
    onHideContentHelp, onShowContentHelp, buttonFunction, warning,
    hideLeftControl, hideRightControl,
    equal,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hideContentHelp = useCallback((): void => {
    dispatch(setContextParameterHelp({
      title: '',
      text: '',
    }));
    onHideContentHelp?.();
  }, [onHideContentHelp]);

  const showContentHelp = useCallback((): void => {
    dispatch(setContextParameterHelp({
      title: label ? t(label) : '',
      text: help ? t(help) : '',
    }));
    onShowContentHelp?.();
  }, [onShowContentHelp, label, help]);

  const rightChild = (readonly)
    ? (
      <TextEditorControl
        value={valueLeft ?? ''}
        displayFormat=""
        onValueChange={() => 0}
        modified={modified}
        onFocus={showContentHelp}
        onBlur={hideContentHelp}
        warning={warning}
      />
    )
    : (
      <DateEditorControl
        value={valueLeft ?? ''}
        onChange={(value) => onLeftValueChange?.(value)}
        edited={modified}
        onShowContentHelp={showContentHelp}
        onHideContentHelp={hideContentHelp}
        warning={warning}
      />
    );

  return (
    <CompareContainer
      label={label}
      buttonFunction={buttonFunction}
      equal={equal}
      onButtonClick={onButtonClick}
      hideLeftControl={hideLeftControl}
      hideRightControl={hideRightControl}
    >
      {rightChild}
      <TextEditorControl
        displayFormat=""
        value={valueRight ?? ''}
        onValueChange={() => 0}
        readonly
      />
    </CompareContainer>
  );
};

export default CompareDateValue;
