/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import CreateBackupComponent from '../../../components/CreateBackupComponent/CreateBackupComponent';
import PreviewDeviceDatasetReduxControl from '../../../controls/PreviewDeviceDatasetReduxControl/PreviewDeviceDatasetReduxControl';
import './RestoreDeviceBackupPage.scss';
import { WizardViewPageProps } from '../../WizardView/WizardViewPage';

export interface RestoreDeviceBackupPageProps extends WizardViewPageProps {
  deviceInstanceId: string;
}

const RestoreDeviceBackupPage: React.FC<RestoreDeviceBackupPageProps> = (props: RestoreDeviceBackupPageProps): React.ReactElement => {
  const { t } = useTranslation();
  const { previewMenu, deviceInstanceId } = props;

  const previewDataset = (previewMenu === undefined)
    ? <div />
    : (
      <PreviewDeviceDatasetReduxControl
        deviceInstanceId={deviceInstanceId}
        caption={t<string>('WIZARD_RESTORE_DEVICE__PAGE_BACKUP__DATASET_PREVIEW')}
        identRef={previewMenu}
      />
    );

  return (
    <Container fluid className="reset-wizard-page2-container h-100 w-100">
      <Row className="h-100">
        <Col xs={6}>
          <CreateBackupComponent
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onBackupCreated={() => {}}
            deviceInstanceId={deviceInstanceId}
          />
        </Col>
        <Col xs={6} className="preview-control--frame-padding h-100">
          {previewDataset}
        </Col>
      </Row>
    </Container>
  );
};

export default RestoreDeviceBackupPage;
