/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */

import { IdentRef } from '@gpt/commons';
import React, { PropsWithChildren } from 'react';
import { StructureItemState } from '../../controls/ReduxControlProps';
import './WizardView.scss';

export interface WizardViewPageProps {
  title: string;
  identRef?: IdentRef;
  reportMenu?: IdentRef;
  previewMenu?: IdentRef;
  active?: boolean;
  hideStepper?: boolean;
  state?: StructureItemState;
  onChangeToNextPage?: () => void,
  onChangeToPreviousPage?: () => void,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface WizardViewPageProp extends WizardViewPageProps, PropsWithChildren<any> {
}

const WizardViewPage: React.FC<PropsWithChildren<any>> = (
  props: WizardViewPageProp,
): React.ReactElement => {
  const {
    children,
  } = props;

  return (
    <>{children}</>
  );
};

export default WizardViewPage;
