/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { CurrentStatusListControl } from '../../components/CurrentStatusListControl/CurrentStatusListControl';
import { useContextDatasetDescriptors, useContextDatasetValues } from '../../hooks/useContextDataset';
import { CurrentStatusControlType } from '../../components/CurrentStatusControl/CurrentStatusControl';

export interface CurrentStatusListDescriptorProps {
    statusList: DeviceModelStatus.UI.CurrentStatusList[];
    deviceInstanceId: string;
}

export const CurrentStatusListDescriptorControl: React.FC<CurrentStatusListDescriptorProps> = (
  props: CurrentStatusListDescriptorProps,
) => {
  const { statusList, deviceInstanceId } = props;
  const { t } = useTranslation();

  const activeDatasetValues = useContextDatasetValues(deviceInstanceId);
  const activeDatasetDescriptors = useContextDatasetDescriptors(deviceInstanceId);

  const statusListDeviceControl = statusList.reduce((acc, item) => {
    const ctrlDescriptor = activeDatasetDescriptors[item.control];
    if (ctrlDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
      return acc;
    }
    if (ctrlDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLCURRENTSTATUS) {
      return acc;
    }
    if (ctrlDescriptor.visibility !== undefined && !ctrlDescriptor.visibility) {
      return acc;
    }

    const variableDesc = activeDatasetDescriptors[ctrlDescriptor.controlType.variable];
    if (variableDesc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return acc;
    }
    if (!variableDesc.visibility) {
      return acc;
    }
    return [
      ...acc,
      {
        title: ctrlDescriptor.controlType.title,
        variableTitle: t(variableDesc.label),
        unit: variableDesc.unit,
        currentValue: activeDatasetValues[ctrlDescriptor.controlType.variable].value.toString(),
        color: ctrlDescriptor.controlType.color,
      },
    ];
  }, [] as CurrentStatusControlType[]);
  return <CurrentStatusListControl statusList={statusListDeviceControl} />;
};
