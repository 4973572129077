/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { ReduxControlProps } from '../ReduxControlProps';
import { useTypedSelector } from '../../store';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { CurrentStatusControl } from '../../components/CurrentStatusControl/CurrentStatusControl';
import { DatasetContext } from '../../views/DatasetContext';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import withControlVisibility from '../hoc/withControlVisibility';
import displayStatusValueSelector from '../selectors/displayStatusValueSelector';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetState } from '../../store/deviceInstances/store/deviceDataset/types';

const controlCurrentStatus = (dataset: DatasetState, identRef: IdentRef): DeviceModelStatus.UI.CurrentStatusControlType | undefined => {
  const desc = dataset.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (desc.controlType?.type === DeviceModelStatus.UI.ControlType.CTLCURRENTSTATUS) {
    return desc.controlType;
  }
  return undefined;
};

const currentStatusVariable = (dataset: DatasetState, identRef: IdentRef): IdentRef | undefined => {
  const desc = dataset.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (desc.controlType?.type !== DeviceModelStatus.UI.ControlType.CTLCURRENTSTATUS) {
    return undefined;
  }
  return desc.controlType.variable;
};

const CurrentStatusReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
: React.ReactElement | null => {
  const { identRef, deviceInstanceId } = props;
  const { t } = useTranslation();
  const { targetDataset } = useContext(DatasetContext);
  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);

  const deviceValue = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (dataset === undefined) {
      return '';
    }

    const varIdent = currentStatusVariable(dataset, identRef);
    if (varIdent === undefined) {
      return '';
    }
    return displayStatusValueSelector(dataset, varIdent);
  });

  const varDescriptorLabel = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (dataset === undefined) {
      return '';
    }

    const controlType = controlCurrentStatus(dataset, identRef);
    if (controlType === undefined) {
      return '';
    }
    const descrip = dataset.descriptors[controlType.variable];
    return descrip.type === DeviceModelStatus.StatusType.StatusDescriptor ? descrip.label : '';
  });

  const varDescriptorUnit = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (dataset === undefined) {
      return '';
    }

    const controlType = controlCurrentStatus(dataset, identRef);
    if (controlType === undefined) {
      return '';
    }
    const descrip = dataset.descriptors[controlType.variable];
    return descrip.type === DeviceModelStatus.StatusType.StatusDescriptor ? descrip.unit : undefined;
  });

  const varDescriptorVisible = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    if (dataset === undefined) {
      return '';
    }
    const controlType = controlCurrentStatus(dataset, identRef);
    if (controlType === undefined) {
      return false;
    }
    const descrip = dataset.descriptors[controlType.variable];
    return descrip.type === DeviceModelStatus.StatusType.StatusDescriptor ? descrip.visibility : true;
  });

  if (descriptor === undefined) {
    return <UnsupportedControl text={`CurrentStatusReduxControl [${identRef}]: undefined object`} />;
  }
  if (descriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text={`CurrentStatusReduxControl [${identRef}]: supported object type is ControlDescriptor only`} />;
  }
  if (descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLCURRENTSTATUS) {
    return <UnsupportedControl text={`CurrentStatusReduxControl [${identRef}]: supported controlType is CTLCURRENTSTATUS only`} />;
  }
  if (!varDescriptorVisible) {
    return (null);
  }

  return (
    <CurrentStatusControl
      title={descriptor.controlType.title === undefined ? undefined : t<string>(descriptor.controlType.title)}
      variableTitle={t<string>(varDescriptorLabel)}
      unit={varDescriptorUnit === undefined ? undefined : t<string>(varDescriptorUnit)}
      currentValue={deviceValue}
      color={descriptor.controlType.color}
    />
  );
};

export default withControlVisibility(CurrentStatusReduxControl);
