/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import React, { useEffect } from 'react';
import withControlVisibility from '../../../controls/hoc/withControlVisibility';
import { ReduxControlProps } from '../../../controls/ReduxControlProps';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../../hooks/useContextDataset';
import UnsupportedControl from '../../UnsupportedControl/UnsupportedControl';
import VariableEditorControl from './VariableEditorControl';

export interface VariableReduxEditorProps extends ReduxControlProps {
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
}

const VariableReduxEditorControl:
React.FC<VariableReduxEditorProps> = (props: VariableReduxEditorProps):
React.ReactElement => {
  const {
    identRef, onShowContentHelp, onHideContentHelp, readonly, onStateChanged,
    deviceInstanceId,
  } = props;

  const statusDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  const datasetValue = useContextDatasetStatusValue(deviceInstanceId, identRef);

  const datasetValueModified = datasetValue?.modified ?? false;
  const datasetValueValidity = datasetValue?.valueValidity ?? DeviceModelStatus.StatusValueValidity.valid;
  const datasetDescValidity = statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor
    ? statusDescriptor.validity : false;

  // When control removed, the modified and error state is clean
  useEffect(() => () => {
    onStateChanged(identRef, {
      modified: false,
      error: false,
    });
  }, []);

  useEffect(() => {
    const error = datasetDescValidity ? datasetValueValidity !== DeviceModelStatus.StatusValueValidity.valid : false;
    const modified = datasetDescValidity ? datasetValueModified : false;
    onStateChanged(identRef, {
      error,
      modified,
    });
  }, [identRef, datasetValueModified, datasetValueValidity, datasetDescValidity]);

  if (statusDescriptor === undefined) {
    return <UnsupportedControl text={`VariableReduxEditorControl: Undefined object ${identRef}`} />;
  }

  if (statusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text={`VariableReduxEditorControl: Unsupported object type ${statusDescriptor.type}`} />;
  }

  return (
    <VariableEditorControl
      statusValue={datasetValue}
      statusDescriptor={statusDescriptor}
      deviceInstanceId={deviceInstanceId}
      readonly={readonly}
      onShowContentHelp={onShowContentHelp}
      onHideContentHelp={onHideContentHelp}
    />
  );
};

export default withControlVisibility(VariableReduxEditorControl);
