/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback, useEffect, useState } from 'react';
import { IdentRef } from '@gpt/commons';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import {
  DROPDOWN__ITEMID0__NOT_SELECTED, DROPDOWN__ITEMID1__POWER_OUT, DROPDOWN__ITEMID2__VOLTAGE_OUT,
  DROPDOWN__ITEMID3__CURRENT_OUT, DROPDOWN__ITEMID4__TEMPERATURE, DROPDOWN__ITEMID5__VIN_OK,
  DROPDOWN__ITEMID6__RUNTIME, DROPDOWN__ITEMID7__OVPMAIN,
} from '../DropDown/DropDownHelpers';
import { Quint4DigitalSignalBlock } from '../types';
import Quint4OutputBlockVCP from './OutputBlockVCP';
import Quint4OutputBlockRuntime from './OutputBlockRuntime';
import Quint4OutputBlockOVP from './OutputBlockOVP';
import { StructureItemState, StructureItemStateChangeCallback, StructureItems } from '../../../ReduxControlProps';
import { StateUpdateCallback, transformMenuItemState } from '../../../hooks/useMenuItemStateUpdate';
import { useContextDatasetNumberProperty } from '../../../../hooks/useContextDataset';
import Quint4OutputBlockThreshold from './OutputBlockThreshold';

export interface Quint4OutputBlockSwitchProps {
  blockIdent: string;
  deviceInstanceId: string;
  switchValue: number;
  block: Quint4DigitalSignalBlock;
  target?: DatasetType;
  showContextMessageDef?: boolean;
  onStateChanged: StructureItemStateChangeCallback;
}

// eslint-disable-next-line max-len
export const useOutputBlockSwitchStateUpdate = (switchValue: number, block: Quint4DigitalSignalBlock) => {
  const [childrenState, setChildrenState] = useState<StructureItems>({});

  // eslint-disable-next-line max-len
  const updateMenuItemState: StateUpdateCallback = useCallback((ident: IdentRef, state: StructureItemState) => {
    setChildrenState((prev) => transformMenuItemState(prev, ident, state));
  }, []);

  let mod = false;
  let iserror = false;
  switch (switchValue) {
    case DROPDOWN__ITEMID0__NOT_SELECTED:
      mod = false;
      iserror = false;
      break;
    case DROPDOWN__ITEMID1__POWER_OUT:
      mod = (childrenState[block.outLimitPout]?.modified ?? false) || (childrenState[block.outCorrespondToP]?.modified ?? false);
      iserror = (childrenState[block.outLimitPout]?.error ?? false) || (childrenState[block.outCorrespondToP]?.error ?? false);
      break;
    case DROPDOWN__ITEMID2__VOLTAGE_OUT:
      mod = (childrenState[block.outLimitVout]?.modified ?? false) || (childrenState[block.outCorrespondToV]?.modified ?? false);
      iserror = (childrenState[block.outLimitVout]?.error ?? false) || (childrenState[block.outCorrespondToV]?.error ?? false);
      break;
    case DROPDOWN__ITEMID3__CURRENT_OUT:
      mod = (childrenState[block.outLimitPout]?.modified ?? false) || (childrenState[block.outCorrespondToI]?.modified ?? false);
      iserror = (childrenState[block.outLimitPout]?.error ?? false) || (childrenState[block.outCorrespondToI]?.error ?? false);
      break;
    case DROPDOWN__ITEMID4__TEMPERATURE:
      mod = false;
      iserror = false;
      break;
    case DROPDOWN__ITEMID5__VIN_OK:
      mod = false;
      iserror = false;
      break;
    case DROPDOWN__ITEMID6__RUNTIME:
      mod = childrenState[block.outLimitTotRuntime]?.modified ?? false;
      iserror = childrenState[block.outLimitTotRuntime]?.error ?? false;
      break;
    case DROPDOWN__ITEMID7__OVPMAIN:
      mod = childrenState[block.outOVPVariable]?.modified ?? false;
      iserror = childrenState[block.outOVPVariable]?.error ?? false;
      break;
    default:
      mod = false;
      iserror = false;
      break;
  }
  return [mod, iserror, updateMenuItemState] as const;
};

const Quint4OutputBlockSwitch
:React.FC<Quint4OutputBlockSwitchProps> = (props: Quint4OutputBlockSwitchProps):
  React.ReactElement => {
  const {
    target,
    blockIdent,
    deviceInstanceId,
    switchValue,
    block,
    showContextMessageDef,
    onStateChanged,
  } = props;

  let outputBlock;
  const {
    outLimitVout,
    outLimitPout,
    outCorrespondToP,
    outCorrespondToV,
    outCorrespondToI,
    outLimitTotRuntime,
    outOVPVariable,
    outConglomerationThreshold,
  } = block;

  const [modified, error, onChildStateChange] = useOutputBlockSwitchStateUpdate(switchValue, block);
  const isDCDCDevice = useContextDatasetNumberProperty(deviceInstanceId, 'isDeviceDCDC', target) ?? 0;

  useEffect(() => {
    onStateChanged(blockIdent, {
      error,
      modified,
    });
  }, [blockIdent, modified, error]);

  switch (switchValue) {
    case DROPDOWN__ITEMID0__NOT_SELECTED:
      outputBlock = <></>;
      break;
    case DROPDOWN__ITEMID1__POWER_OUT:
      outputBlock = (
        <Quint4OutputBlockVCP
          deviceInstanceId={deviceInstanceId}
          target={target}
          correspontVariableIdentRef={outCorrespondToP}
          procentVariableIdentRef={outLimitPout}
          showContextMessageDef={showContextMessageDef}
          onStateChanged={onChildStateChange}
        />
      );
      break;
    case DROPDOWN__ITEMID2__VOLTAGE_OUT:
      outputBlock = (
        <Quint4OutputBlockVCP
          deviceInstanceId={deviceInstanceId}
          target={target}
          correspontVariableIdentRef={outCorrespondToV}
          procentVariableIdentRef={outLimitVout}
          showContextMessageDef={showContextMessageDef}
          onStateChanged={onChildStateChange}
        />
      );
      break;
    case DROPDOWN__ITEMID3__CURRENT_OUT:
      outputBlock = (
        <Quint4OutputBlockVCP
          deviceInstanceId={deviceInstanceId}
          target={target}
          correspontVariableIdentRef={outCorrespondToI}
          procentVariableIdentRef={outLimitPout}
          showContextMessageDef={showContextMessageDef}
          onStateChanged={onChildStateChange}
        />
      );
      break;
    case DROPDOWN__ITEMID4__TEMPERATURE:
      outputBlock = <></>;
      break;
    case DROPDOWN__ITEMID5__VIN_OK:
      outputBlock = <></>;
      if (isDCDCDevice === 1) {
        outputBlock = (
          <Quint4OutputBlockThreshold
            target={target}
            deviceInstanceId={deviceInstanceId}
            outVinOkThresholdIdent={outConglomerationThreshold}
            showContextMessageDef={showContextMessageDef}
            onStateChanged={onChildStateChange}
          />
        );
      }
      break;
    case DROPDOWN__ITEMID6__RUNTIME:
      outputBlock = (
        <Quint4OutputBlockRuntime
          deviceInstanceId={deviceInstanceId}
          target={target}
          runtimeVariableIdentRef={outLimitTotRuntime}
          showContextMessageDef={showContextMessageDef}
          onStateChanged={onChildStateChange}
        />
      );
      break;
    case DROPDOWN__ITEMID7__OVPMAIN:
      outputBlock = (
        <Quint4OutputBlockOVP
          deviceInstanceId={deviceInstanceId}
          target={target}
          ovpVariableIdentRef={outOVPVariable}
          showContextMessageDef={showContextMessageDef}
          onStateChanged={onChildStateChange}
        />
      );
      break;
    default:
      outputBlock = <></>;
      break;
  }

  return (
    <>
      {outputBlock}
    </>
  );
};

export default Quint4OutputBlockSwitch;
