/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';
import deviceModelApplicationDescriptor from './deviceModelApplicationDescriptor';
import deviceModelControlDescriptor from './deviceModelControlDescriptor';
import deviceModelStatusDescriptorCollection from './deviceModelStatusDescriptor';
import fdcml10DeviceModelStatusDescriptorCollection from './deviceModelStatusDescriptor.fdcml10';
import deviceModelStructureDescriptorCockpit from './deviceModelStructureDescriptor.cockpit';
import deviceModelStructureDescriptorCompare from './deviceModelStructureDescriptor.compare';
import deviceModelStructureDescriptorParameters from './deviceModelStructureDescriptor.parameters';
import deviceModelStructureDescriptorPreview from './deviceModelStructureDescriptor.preview';
import deviceModelWizardDescriptor from './deviceModelWizardDescriptor';

// eslint-disable-next-line max-len
const deviceModelDescriptorCollection: DeviceModelStatus.DeviceModelDescriptorCollection = {
  ...fdcml10DeviceModelStatusDescriptorCollection,
  ...deviceModelStatusDescriptorCollection,
  ...deviceModelStructureDescriptorCockpit,
  ...deviceModelStructureDescriptorParameters,
  ...deviceModelStructureDescriptorCompare,
  ...deviceModelStructureDescriptorPreview,
  ...deviceModelControlDescriptor,
  ...deviceModelApplicationDescriptor,
  ...deviceModelWizardDescriptor,
};

export const deviceModelDescriptorPreviewCollection: DeviceModelStatus.DeviceModelDescriptorCollection = {
  ...fdcml10DeviceModelStatusDescriptorCollection,
  ...deviceModelStatusDescriptorCollection,
  ...deviceModelStructureDescriptorPreview,
  ...deviceModelApplicationDescriptor,
};

export default deviceModelDescriptorCollection;
