/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import DisplayFormat from '../../helpers/displayFormat';
import { useContextDatasetStatusDescriptor, useContextDatasetStatusValue } from '../../hooks/useContextDataset';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';

const useDisplayStatusValue = (
  deviceInstanceId: string | undefined,
  identRef: IdentRef,
  target?: DatasetType,
): string => {
  const statusDescriptor = useContextDatasetStatusDescriptor(
    deviceInstanceId,
    identRef,
    target,
  );
  const statusValue = useContextDatasetStatusValue(
    deviceInstanceId,
    identRef,
    target,
  );
  const { t } = useTranslation();

  const defaultValue = 'N/A';
  if (statusValue === undefined) {
    return defaultValue;
  }

  if (statusDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return defaultValue;
  }

  let variableValue = '';
  const { valueType } = statusDescriptor;
  switch (valueType.type) {
    case DeviceModelStatus.StatusDescriptorValueType.FLOAT: {
      const parsedValue = Number.parseFloat(`${statusValue.value}`);
      const stringValue = Number.isNaN(parsedValue) ? '' : DisplayFormat(valueType.displayFormat ?? '%.2f', statusValue.value);
      variableValue = statusDescriptor?.unit === undefined ? stringValue : `${stringValue} ${t(statusDescriptor.unit)}`;
    }
      break;
    case DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR: {
      const value = valueType.enumerator.find((entry) => entry.value === statusValue.value);
      variableValue = value !== undefined ? t(value.label) : '';
    }
      break;
    case DeviceModelStatus.StatusDescriptorValueType.INTEGER:
    case DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER: {
      if (statusValue.value === undefined) {
        variableValue = '';
        break;
      }
      const stringValue = valueType.displayFormat === undefined
        ? (statusValue.value ?? '')
        : DisplayFormat(valueType.displayFormat, statusValue.value);
      variableValue = statusDescriptor?.unit === undefined ? stringValue : `${stringValue} ${t(statusDescriptor.unit)}`;
    }
      break;
    case DeviceModelStatus.StatusDescriptorValueType.STRING:
    case DeviceModelStatus.StatusDescriptorValueType.DATETIME:
    case DeviceModelStatus.StatusDescriptorValueType.DATE:
    case DeviceModelStatus.StatusDescriptorValueType.IPV4_ADDRESS:
    case DeviceModelStatus.StatusDescriptorValueType.MAC_ADDRESS:
      variableValue = statusValue.value ?? '';
      break;
    case DeviceModelStatus.StatusDescriptorValueType.TABLE:
      variableValue = 'table';
      break;
    default:
      variableValue = '';
  }

  return variableValue;
};

export default useDisplayStatusValue;
