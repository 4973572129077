/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { LinearizationTableItem } from '../../../../store/linearizationDataset/types';
import { TableColumnHeader } from '../TableColumnHeader/TableColumnHeader';
import { TableElement } from '../TableElement/TableElement';
import './SmallTable.css';

export interface SmallTableProps {
    items: LinearizationTableItem[];
    header1: string;
    header2: string;
    // eslint-disable-next-line no-unused-vars
    onXValueChange: (pointIndex: number, pointValue: string) => void;
    // eslint-disable-next-line no-unused-vars
    onYValueChange: (pointIndex: number, pointValue: string) => void;
}

export const SmallTable:React.FC<SmallTableProps> = (props: SmallTableProps): React.ReactElement => {
  const {
    items, header1, header2, onXValueChange, onYValueChange,
  } = props;

  return (
    <>
      <div className="smallTable_separator-container">
        <div className="smallTable_separator-container-separator" />
      </div>
      <div className="smallTable-container">
        <div className="smallTable-container-header">
          <TableColumnHeader header1={header1} header2={header2} />
        </div>
        <div className="smallTable-container-data">
          {items.map((item, index) => (
            <div
              className="smallTable-container-data-element"
              key={`tableElement-${index + 1}`}
            >
              <TableElement
                index={index}
                item={item}
                onXValueChange={(pointIndex: number, pointValue: string) => onXValueChange(index, pointValue)}
                onYValueChange={(pointIndex: number, pointValue: string) => onYValueChange(index, pointValue)}
                id={`input__x__${index}`}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
