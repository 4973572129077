/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { CSSProperties, PropsWithChildren } from 'react';

export interface DataTableRowHeaderItemProps extends PropsWithChildren<any> {
  customClassname?: string;
  width?: string;
}

export const DataTableRowHeaderItem: React.FC<DataTableRowHeaderItemProps> = (props: DataTableRowHeaderItemProps):
  React.ReactElement => {
  const {
    children, customClassname, width,
  } = props;

  const style: CSSProperties = {
    width: width ?? '250px',
  };

  return (
    <div style={style} className={`data-table-control-cell data-table-control-cell--header ${customClassname ?? ''}`}>{children}</div>
  );
};
