/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { DeviceInstanceActionPayload } from '../../common';

export const IOLINK__SYSTEM_COMMAND__SET_STATE = 'IOLINK__SYSTEM_COMMAND__SET_STATE';

export const IOLINK__SYSTEM_COMMAND__ACTIONS = 'IOLINK__SYSTEM_COMMAND__ACTIONS';

export enum IoLinkSystemCommandStage {
  // eslint-disable-next-line no-unused-vars
  stageInitial = 'initial',
  // eslint-disable-next-line no-unused-vars
  stageCommandExecuting = 'executing',
  // eslint-disable-next-line no-unused-vars
  stageUploading = 'uploading',
  // eslint-disable-next-line no-unused-vars
  stageDoneSuccess = 'success',
  // eslint-disable-next-line no-unused-vars
  stageDoneFailed = 'failed',
}

export interface IoLinkSystemCommandState {
  state: IoLinkSystemCommandStage;
}

/* ************************************************************************************ */
/* ACTION: IoLinkSetCommandStateAction                                               */
/* ************************************************************************************ */
export interface IoLinkSetCommandStatePayload extends DeviceInstanceActionPayload {
  stage: IoLinkSystemCommandStage;
}

export interface IoLinkSetCommandStateAction extends Action<typeof IOLINK__SYSTEM_COMMAND__SET_STATE> {
  payload: IoLinkSetCommandStatePayload;
}

export type typeIoLinkSystemCommandActions = IoLinkSetCommandStateAction;

/* ************************************************************************************ */
/* TYPE: typeDeviceInstanceIoLinkSystemCommandAction                                                   */
/* ************************************************************************************ */
export interface typeDeviceInstanceIoLinkSystemCommandPayload extends DeviceInstanceActionPayload {
  action: typeIoLinkSystemCommandActions;
}

export interface typeDeviceInstanceIoLinkSystemCommandAction extends Action<typeof IOLINK__SYSTEM_COMMAND__ACTIONS> {
  payload: typeDeviceInstanceIoLinkSystemCommandPayload;
}
