/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { DeviceModelStatus } from '@gpt/commons';
import {
  SetDeviceMethodExecutionStatePayload,
  typeDeviceInstanceDeviceMethodExecutionAction,
} from './types';

export const setDeviceMethodExecutionState = (targetInstance: string, payload: SetDeviceMethodExecutionStatePayload)
  : typeDeviceInstanceDeviceMethodExecutionAction => ({
  type: 'DEVICE_INSTANCE__METHOD_EXECUTION__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'METHOD_EXECUTION__SET_EXECUTION_STATE',
      payload: payload,
    },
  },
});

export const cleanDeviceMethodExecutionState = (targetInstance: string, payload: DeviceModelStatus.MethodIdent)
  : typeDeviceInstanceDeviceMethodExecutionAction => ({
  type: 'DEVICE_INSTANCE__METHOD_EXECUTION__ACTIONS',
  payload: {
    targetInstance,
    action: {
      type: 'METHOD_EXECUTION__CLEANUP',
      payload,
    },
  },
});
