/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus } from '@gpt/commons';
import { DatasetState, DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';
import { SVGGaugeTextPoint } from '../../components/GaugeControl/SVGGaugeControl/SVGGauge/SVGGaugeTextPoints/SVGGaugeTextPoints';
import DisplayFormat from '../../helpers/displayFormat';
import { SVGGaugeSegment } from '../../components/GaugeControl/SVGGaugeControl/SVGGauge/SVGGaugeSegments/SVGGaugeSegments';
import { GetDisplayFormat } from '../../helpers/functions';

export interface AnalogGaugeData {
  title?: string;
  subtitle?: string;
  minValue: number;
  maxValue: number;
  loloValue?: number;
  loValue?: number;
  hiValue?: number;
  hihiValue?: number;
  displayFormat?: string;
}

const internalControlTypeSelector = (identRef: string, state?: DatasetState)
: DeviceModelStatus.UI.AnalogGaugeControlType | undefined => {
  const desc = state?.descriptors[identRef];
  if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  return desc.controlType.type === DeviceModelStatus.UI.ControlType.CTLANALOGGAUGE ? desc.controlType : undefined;
};

const stringParamSelector = (stringParam?: DeviceModelStatus.UI.typeGaugeStringParam, state?: DatasetState)
: string => {
  if (stringParam === undefined) {
    return '';
  }
  if (stringParam.type === DeviceModelStatus.UI.ControlValueKind.constantString) {
    return stringParam.value;
  }
  return state?.values[stringParam.variable]?.value ?? '';
};

const numberParamSelector = (numberParam?: DeviceModelStatus.UI.typeGaugeNumberParam, state?: DatasetState)
: number | undefined => {
  if (numberParam === undefined) {
    return undefined;
  }
  if (numberParam.type === DeviceModelStatus.UI.ControlValueKind.constantInteger) {
    return numberParam.value;
  }
  return state?.values[numberParam.variable]?.value;
};

const valueDescriptorSelector = (identRef: string, state?: DatasetState)
: DeviceModelStatus.StatusDescriptor | undefined => {
  const descriptor = state?.descriptors[identRef];
  return descriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? descriptor : undefined;
};

export const analogGaugeControlTypeSelector = (deviceInstances: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string)
: DeviceModelStatus.UI.AnalogGaugeControlType | undefined => {
  const state = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  return internalControlTypeSelector(identRef, state);
};

export const analogGaugeValueDescriptorSelector = (deviceInstances: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string)
: DeviceModelStatus.StatusDescriptor | undefined => {
  const state = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  const controlType = internalControlTypeSelector(identRef, state);
  if (controlType === undefined || state === undefined) {
    return undefined;
  }
  return valueDescriptorSelector(controlType.variable, state);
};

export const analogGaugeValueSelector = (deviceInstances: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string)
: DeviceModelStatus.StatusValue | undefined => {
  const state = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  const controlType = internalControlTypeSelector(identRef, state);
  if (controlType === undefined || state === undefined) {
    return undefined;
  }
  return state.values[controlType.variable];
};

const minValueSelector = (state: DatasetState | undefined, minValue: DeviceModelStatus.UI.typeGaugeNumberParam | undefined, range: DeviceModelStatus.StatusDescriptorRange | undefined)
: number | undefined => (minValue === undefined ? (range?.regions[0].minValue ?? 0) : numberParamSelector(minValue, state));

const maxValueSelector = (state: DatasetState | undefined, maxValue: DeviceModelStatus.UI.typeGaugeNumberParam | undefined, range: DeviceModelStatus.StatusDescriptorRange | undefined)
: number | undefined => {
  if (range === undefined) {
    return numberParamSelector(maxValue, state);
  }

  return maxValue === undefined
    ? range.regions[range.regions.length - 1].maxValue ?? 0
    : numberParamSelector(maxValue, state);
};

export const analogGaugeDataSelector = (deviceInstances: DeviceInstancesState, deviceInstanceId: string, targetDataset: DatasetType, identRef: string)
:AnalogGaugeData | undefined => {
  const state = deviceTargetDatasetSelector(deviceInstances, deviceInstanceId, targetDataset);
  const controlType = internalControlTypeSelector(identRef, state);
  if (controlType === undefined || state === undefined) {
    return undefined;
  }
  const descriptor = valueDescriptorSelector(controlType.variable, state);
  const range = descriptor ? DeviceModelStatus.Functions.GetDescriptorRange(descriptor) : undefined;

  const displayFormat = controlType?.displayFormat ?? (descriptor === undefined ? '%.2f' : GetDisplayFormat(descriptor));

  return {
    title: controlType.title !== undefined
      ? stringParamSelector(controlType.title, state)
      : (descriptor?.label ?? ''),
    subtitle: stringParamSelector(controlType.subTitle, state),
    minValue: minValueSelector(state, controlType.minValue, range) ?? 0,
    maxValue: maxValueSelector(state, controlType.minValue, range) ?? 100,
    loloValue: numberParamSelector(controlType?.loloValue, state),
    loValue: numberParamSelector(controlType?.loValue, state),
    hiValue: numberParamSelector(controlType?.hiValue, state),
    hihiValue: numberParamSelector(controlType?.hihiValue, state),
    displayFormat,
  };
};

export const calculateAnalogGaugePoints = (segments: SVGGaugeSegment[], displayFormat: string): SVGGaugeTextPoint[] => {
  const minValue = segments.length > 0 ? segments[0].minValue : 0;

  const minPoint: SVGGaugeTextPoint = {
    value: minValue,
    text: DisplayFormat(displayFormat, minValue),
  };

  const points = segments.reduce((acc, seg) => {
    if (acc[acc.length - 1].value === seg.maxValue) {
      return acc;
    }
    return [
      ...acc,
      {
        value: seg.maxValue,
        text: DisplayFormat(displayFormat, seg.maxValue),
      },
    ];
  }, [minPoint] as SVGGaugeTextPoint[]);
  return points;
};

export const calculateAnalogGaugeSegment = (analogData: AnalogGaugeData | undefined)
: SVGGaugeSegment[] => {
  const minValue = analogData?.minValue ?? 0;
  const mxValue = analogData?.maxValue ?? 100;

  const maxValue = [minValue, analogData?.loloValue, analogData?.loValue, analogData?.hiValue, analogData?.hihiValue, mxValue]
    .reduce((acc, vx) => (vx === undefined ? acc : Math.max(acc ?? 0, vx)), mxValue) ?? 100;

  const gaugeSegments: SVGGaugeSegment[] = [
    {
      minValue,
      maxValue: analogData?.loloValue ?? minValue,
      color: 'red',
    },
    {
      minValue: analogData?.loloValue ?? minValue,
      maxValue: analogData?.loValue ?? (analogData?.loloValue ?? minValue),
      color: 'yellow',
    },
    {
      minValue: analogData?.loValue ?? (analogData?.loloValue ?? minValue),
      maxValue: analogData?.hiValue ?? (analogData?.hihiValue ?? maxValue),
      color: 'green',
    },
    {
      minValue: analogData?.hiValue ?? (analogData?.hihiValue ?? maxValue),
      maxValue: analogData?.hihiValue ?? maxValue,
      color: 'yellow',
    },
    {
      minValue: analogData?.hihiValue ?? maxValue,
      maxValue,
      color: 'red',
    },
  ];
  const segments = gaugeSegments
    .filter((seg) => seg.minValue !== seg.maxValue);
  return segments.length > 0 ? segments : [{
    minValue,
    maxValue,
    color: 'green',
  }];
};
