/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { useEffect, useState } from 'react';
import AppSystemService from '../services/AppService/AppSystemService';

const useIsAppPortable = (): boolean => {
  const [isAppPortable, setIsAppPortable] = useState(false);

  useEffect(() => {
    const getIsAppPortable = async (): Promise<void> => {
      const isPortable = await AppSystemService.IsAppPortable();
      setIsAppPortable(isPortable);
    };
    getIsAppPortable().catch(() => {
      setIsAppPortable(false);
    });
  }, []);

  return isAppPortable;
};

export default useIsAppPortable;
