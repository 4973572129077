/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../store/reduxStore';
import { ReduxControlProps } from '../ReduxControlProps';
import UnsupportedControl from '../../components/UnsupportedControl/UnsupportedControl';
import { PaperControl } from '../../components/PaperControl/PaperControl';
import ReduxControl from '../ReduxControl/ReduxControl';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import { DatasetContext } from '../../views/DatasetContext';
import withControlVisibility from '../hoc/withControlVisibility';
import { deviceDescriptorSelector } from '../../store/deviceInstances/store/deviceDataset/selector';

const PaperReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    onStateChanged,
    deviceInstanceId,
    readonly,
  } = props;

  const { targetDataset } = useContext(DatasetContext);
  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);
  const { t } = useTranslation();

  const menuLabel = useTypedSelector((state) => {
    const desc = deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, identRef);
    if (desc?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
      return '';
    }
    if (desc.controlType.type === DeviceModelStatus.UI.ControlType.CTLPAPER) {
      const menuDesc = deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, targetDataset, desc.controlType.menu);

      if (menuDesc?.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
        return '';
      }

      return t(menuDesc.label);
    }
    return '';
  });

  if (descriptor === undefined) {
    return <UnsupportedControl text={`PaperReduxControl: undefined object ${identRef}`} />;
  }

  if (descriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="PaperReduxControl: Supported object type is ControlDescriptor only" />;
  }

  if (descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLPAPER) {
    return <UnsupportedControl text="PaperReduxControl: Supported ControlType is CTLPAPER only" />;
  }

  return (
    <PaperControl title={menuLabel} testid="bla">
      <ReduxControl
        identRef={descriptor.controlType.menu}
        deviceInstanceId={deviceInstanceId}
        key={`paper--${identRef}--${descriptor.controlType.menu}`}
        onStateChanged={onStateChanged}
        readonly={readonly}
      />
    </PaperControl>
  );
};

export default withControlVisibility(PaperReduxControl);
