/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import { Row } from 'react-bootstrap';
import './ParameterControl.css';
import {
  ParameterLabelColumns, ParameterUnitColumns, ParameterValueColumns,
} from './ParameterControlColumnCount';

export type ParameterControlProps = PropsWithChildren<{
  label?: string;
  unit?: string;
}>;

const ParameterControl:
React.FC<ParameterControlProps> = (props: ParameterControlProps):
React.ReactElement => {
  const {
    children, label, unit,
  } = props;

  if (label === undefined) {
    return (
      <Row className="flex-nowrap align-items-center py-1 m-0 p-0 w-100">
        <div className={`col-${ParameterValueColumns + ParameterLabelColumns} h-100 parameter-control-value m-0 p-0 px-1`}>
          {children}
        </div>
        <div className={`col-${ParameterUnitColumns} h-100 parameter-control-unit m-0 p-0 px-1`}>
          <p>
            {unit}
          </p>
        </div>
      </Row>
    );
  }

  return (
    <Row className="flex-nowrap align-items-center pt-1 m-0 px-0 w-100">
      <div className={`col-${ParameterLabelColumns} parameter-control-label m-0 p-0 px-1`}>
        <p>
          {label}
        </p>
      </div>
      <div className={`col-${ParameterValueColumns} h-100 parameter-control-value m-0 p-0 px-1`}>
        {children}
      </div>
      <div className={`col-${ParameterUnitColumns} h-100 parameter-control-unit m-0 p-0 px-1`}>
        <p>
          {unit}
        </p>
      </div>
    </Row>
  );
};

const ParameterTupleControl:
React.FC<ParameterControlProps> = (
  props: ParameterControlProps,
): React.ReactElement => {
  const {
    children, label, unit,
  } = props;

  if (label === undefined) {
    return (
      <Row className="flex-nowrap pt-1 align-items-center">
        <div className={`col-${ParameterValueColumns + ParameterLabelColumns} parameter-control-value`}>
          {children}
        </div>
        <div className={`col-${ParameterUnitColumns} parameter-control-unit`}>
          <p>
            {unit}
          </p>
        </div>
      </Row>
    );
  }

  return (
    <Row className="flex-nowrap py-1 align-items-center">
      <div className={`col-${ParameterLabelColumns} parameter-control-label`}>
        <p>
          {label}
        </p>
      </div>
      <div className={`col-${ParameterValueColumns} parameter-control-value`}>
        {children}
      </div>
      <div className={`col-${ParameterUnitColumns} parameter-control-unit`}>
        <p>
          {unit}
        </p>
      </div>
    </Row>
  );
};

export default ParameterControl;
export { ParameterTupleControl };
