/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { ControlStateCollection } from '../types';
import {
  SetGaugeIndicatorAction,
} from './types';

const gaugeReducer = (
  state: ControlStateCollection,
  action: SetGaugeIndicatorAction,
): ControlStateCollection => {
  if (state[action.payload.ident] === undefined) {
    return ({
      ...state,
      [action.payload.ident]: {
        showIndicator: action.payload.indicator,
      },
    });
  }

  return ({
    ...state,
    [action.payload.ident]: {
      ...state[action.payload.ident],
      showIndicator: action.payload.indicator,
    },
  });
};

export default gaugeReducer;
