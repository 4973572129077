/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { ReduxControlProps } from '../ReduxControlProps';
import { ImageComponent } from '../../components/ImageComponent';
import { UnsupportedControl } from '../../components/UnsupportedControl';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import withControlVisibility from '../hoc/withControlVisibility';

const ImageControl:
React.FC<ReduxControlProps> = (props: ReduxControlProps):
React.ReactElement => {
  const { identRef, deviceInstanceId } = props;
  const imageDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);

  if (imageDescriptor === undefined) {
    return <UnsupportedControl text={`ImageControl: identRef '${identRef}' is not defined`} />;
  }

  if (imageDescriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="ImageControl: supported descriptor type is 'ControlDescriptor'" />;
  }

  const { controlType } = imageDescriptor;
  if (controlType.type !== DeviceModelStatus.UI.ControlType.CTLPICTURE) {
    return <UnsupportedControl text="ImageControl: supported control type is 'CTLPICTURE'" />;
  }

  return (
    <ImageComponent
      imageResourceId={controlType.picture}
      deviceInstanceId={deviceInstanceId}
      width={controlType.width}
      height={controlType.height}
      top={controlType.top}
    />
  );
};

export default withControlVisibility(ImageControl);
