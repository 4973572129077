/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/prop-types */

import React, { PropsWithChildren } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './DropDownTrend.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DropDownTrendToggleProps extends PropsWithChildren<any> {
  // eslint-disable-next-line no-unused-vars
  onClick: (e) => void;
}

// eslint-disable-next-line max-len
const DropDownTrendToggle = React.forwardRef<HTMLAnchorElement, DropDownTrendToggleProps>((props, ref) => {
  const {
    children,
    onClick,
  } = props;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-icon-link"
    >
      <div className="flex-container-dropdown">
        {children}
        <div className="dropdown-icon-container">
          <div className="dropdown-icon" />
        </div>
      </div>
    </a>
  );
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DropDownTrendMenuProps extends PropsWithChildren<any> {
  style: React.CSSProperties;
  className: string;
}

const DropDownTrendMenu = React.forwardRef<HTMLDivElement, DropDownTrendMenuProps>((props, ref) => {
  const {
    style,
    className,
    children,
  } = props;
  const { t } = useTranslation();

  return (
    <div
      ref={ref}
      style={style}
      className={className}
    >
      <div className="d-flex flex-column p-2 dropdown-menu-trend">
        <span className="notice">{t('TREND_DROPDOWN_SUBTITLE')}</span>
        <div className="divide-line" />
        {children}
      </div>
    </div>
  );
});

interface DropDownTrendItemProps {
  checked: boolean;
  label: string;
  onChange: () => void;
}

// eslint-disable-next-line max-len, no-unused-vars
const DropDownTrendItem = React.forwardRef<HTMLInputElement, DropDownTrendItemProps>((props, ref) => {
  const {
    checked,
    onChange,
    label,
  } = props;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      <input
        type="checkbox"
        className="checkbox-trend__input"
        onChange={onChange}
      />
      <svg
        className="checkbox-trend"
        viewBox="0 0 15 11"
        fill="none"
      >
        <path
          d="M1 4.5L5 9L14 1"
          strokeWidth="2"
          stroke={checked ? '#0098a1' : 'none'}
        />
      </svg>
      {label}
    </label>
  );
});

export interface DropDownTrendProps {
  itemStates: {id: string, label: string, checked: boolean}[];
  // eslint-disable-next-line no-unused-vars
  onChange: (id: string) => void;
}

export const DropDownTrend: React.FC<DropDownTrendProps> = (props: DropDownTrendProps):
React.ReactElement => {
  const {
    itemStates,
    onChange,
  } = props;
  const { t } = useTranslation();

  return (
    <Dropdown>
      <Dropdown.Toggle as={DropDownTrendToggle}>
        {t('TREND_DROPDOWN_TITLE')}
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={DropDownTrendMenu}
      >
        {itemStates.map((i) => (
          <Dropdown.Item
            key={i.id}
            as={DropDownTrendItem}
            label={i.label}
            checked={i.checked}
            onChange={() => { onChange(i.id); }}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
