/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import {
  DeviceInformation, DeviceModelStatus, IdentRef, Services,
} from '@gpt/commons';
import { DeviceDatasetValueCollection } from '../store/deviceInstances/store/deviceDataset';

export interface DeviceDescription {
    info: DeviceInformation,
    previewMenu: IdentRef,
    values: DeviceDatasetValueCollection;
    descriptors: DeviceModelStatus.DeviceModelDescriptorCollection;
}

export interface CreateExternalDatasetContentProps {
    caption: string,
    datasetId: Services.DeviceModelServer.DatasetProviderService.DatasetIdent,
    device: DeviceDescription,
    datasetType: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType;
    userComment?: string,
  }

const createExternalDatasetContent = async (props: CreateExternalDatasetContentProps)
: Promise<Services.DeviceModelServer.DatasetProviderService.ExternalDataset> => {
  const {
    caption, datasetId, device, userComment, datasetType,
  } = props;
  const datasetValues: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetValues = {};
  Object.keys(device.values)
    .forEach((key) => {
      datasetValues[key] = device.values[key] !== undefined
        ? JSON.parse(JSON.stringify(device.values[key]))
        : undefined;
    });

  const externalDataset: Services.DeviceModelServer.DatasetProviderService.ExternalDataset = {
    version: Services.DeviceModelServer.DatasetProviderService.EXTERNAL_DATASET__VERSION,
    header: {
      caption,
      datasetId,
      device: device.info,
      previewMenu: device.previewMenu,
      saveDateTime: new Date(),
      userComment: userComment ?? '',
      datasetType,
    },
    values: datasetValues,
    descriptors: device.descriptors,
  };
  return externalDataset;
};

export default createExternalDatasetContent;
