/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { WizardViewPageProps } from '../../../WizardView/WizardViewPage';

const Page1SelectDevice
: React.FC<WizardViewPageProps> = (props: WizardViewPageProps) : React.ReactElement => {
  const {
    title,
  } = props;

  return (
    <div>{title}</div>
  );
};

export default Page1SelectDevice;
