/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import './DeviceEnumeratorEditor.scss';
import BasicSelectEditor from '../../../../../../components/ParameterEditor/Basic/BasicSelectEditor/BasicSelectEditor';
import BasicTextEditor from '../../../../../../components/ParameterEditor/Basic/BasicTextEditor/BasicTextEditor';

const itemOptions = (
  min: number,
  max: number,
): { [val: number]: string } => {
  if (min === max) {
    return {
      [min]: `${min}`,
    };
  }

  const results = [...Array(max - min + 1).keys()].reduce((acc, idx) => ({
    ...acc,
    [idx + min]: `${idx + min}`,
  }), {});
  return results;
};

export interface OnlineEnumeratorEditorProps {
  label: string;
  unit: string;
  readonly?: boolean;
  value: number;
  min: number;
  max: number;
  // eslint-disable-next-line no-unused-vars
  onValueChange: (value: number) => void;
}

const OnlineEnumeratorEditor
:React.FC<OnlineEnumeratorEditorProps> = (props: OnlineEnumeratorEditorProps):
  React.ReactElement => {
  const {
    min,
    max,
    value,
    label,
    unit,
    readonly,
    onValueChange,
  } = props;
  const { t } = useTranslation();
  // const [warning, showContextHelp, hideContentHelp] = useIntegerMessageContextHelp(statusDescriptor, statusValue, showContextMessageDef, onShowContentHelp, onHideContentHelp);

  const isReadonly = (min === max) || readonly;
  const control = isReadonly ? (
    <BasicTextEditor
      label={t(label) ?? ''}
      displayFormat="%s"
      value={`${value}`}
      readonly
      onValueChange={() => 0}
      modified={false}
    />
  ) : (
    <BasicSelectEditor
      value={value}
      options={itemOptions(min, max)}
      onChange={(event) => onValueChange(parseInt(event.target.value, 10))}
    />
  );

  return (
    <div className="caparoc-channel-current-enumerator">
      <div className="caparoc-channel-current-enumerator__label">
        {t(label ?? '')}
      </div>
      <div className="caparoc-channel-current-enumerator__control">
        {control}
      </div>
      <div className="caparoc-channel-current-enumerator__unit">
        {unit}
      </div>
    </div>
  );
};

export default OnlineEnumeratorEditor;
