/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import { Services } from '@gpt/commons';

const validateExternalDatasetDescriptors = async (header: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetDescriptors): Promise<void> => new Promise<void>((resolve, reject) => {
  if (header === undefined) {
    reject(new Error('Header not defined'));
    return;
  }
  resolve();
});

const validateExternalDatasetValues = async (values: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetValues): Promise<void> => new Promise<void>((resolve, reject) => {
  if (values === undefined) {
    reject(new Error('Values not defined'));
    return;
  }
  resolve();
});

const validateExternalDatasetHeader = async (header: Services.DeviceModelServer.DatasetProviderService.ExternalDatasetHeader): Promise<void> => new Promise<void>((resolve, reject) => {
  if (header === undefined) {
    reject(new Error('Header not defined'));
    return;
  }
  if (header.device === undefined) {
    reject(new Error('Header: device not defined'));
    return;
  }
  if (header.device === undefined) {
    reject(new Error('Header: Device not defined'));
    return;
  }
  if (header.device.catalog === undefined) {
    reject(new Error('Header: Catalog not defined'));
    return;
  }
  resolve();
});

const validateExternalDataset = async (externalDataset: Services.DeviceModelServer.DatasetProviderService.ExternalDataset): Promise<void> => {
  await validateExternalDatasetHeader(externalDataset.header);
  await validateExternalDatasetValues(externalDataset.values);
  await validateExternalDatasetDescriptors(externalDataset.descriptors);
};

export default validateExternalDataset;
