/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import { Services } from '@gpt/commons';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import loggerFactory from '../../../../services/UILogger';
import { cleanContextParameter } from '../../../contexthelp/actions';
import { deviceInstancesStoreSelector } from '../../../reduxStoreSelector';
import { DatasetType } from '../../store/deviceDataset/types';
import { MethodStageExecutionStatus } from '../../store/deviceMethod/types';
import { executeMethodStageInit } from '../deviceMethod/actions';
import {
  typeDeviceMethodMiddlewareActions, EXECUTE_DEVICE_UPLOAD_METHOD,
  EXECUTE_DEVICE_DOWNLOAD_METHOD, EXECUTE_DEVICE_DOWNLOAD_DATASET_METHOD,
} from './types';
import { setDeviceMethodExecutionState } from '../../store/deviceMethod/actions';

const logger = loggerFactory.create('method-exec');

export const deviceMethodExecutionMiddleware = (): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeDeviceMethodMiddlewareActions>(action: A): Promise<A> => {
  const executeDeviceUpload = async (targetInstance: string, methodIdent: string) => {
    logger.debug('Upload data from device', targetInstance, methodIdent);
    // regard disappearing parameters → hide its help & warning message
    api.dispatch(cleanContextParameter());
    api.dispatch(setDeviceMethodExecutionState(targetInstance, {
      methodIdent,
      stage: MethodStageExecutionStatus.Initialize,
      steps: [],
    }));
    api.dispatch(executeMethodStageInit(targetInstance, {
      methodIdent,
      values: [],
    }));
  };

  const executeDeviceDownload = async (targetInstance: string, methodIdent: string, values: Services.DeviceModel.StatusValueRef[]) => {
    // regard disappearing parameters → hide its help & warning message
    api.dispatch(cleanContextParameter());

    logger.debug('Download data to device', methodIdent);

    api.dispatch(setDeviceMethodExecutionState(targetInstance, {
      methodIdent,
      stage: MethodStageExecutionStatus.Initialize,
      steps: [],
    }));

    api.dispatch(executeMethodStageInit(targetInstance, {
      methodIdent,
      values,
    }));
  };

  const executeDeviceDownloadDataset = async (targetInstance: string, methodIdent: string, sourceDataset: DatasetType) => {
    // create dataset
    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const sourceDatasetState = deviceInstances.instances[targetInstance]?.deviceDataset[sourceDataset];
    const values: Services.DeviceModel.StatusValueRef[] = (sourceDataset === undefined)
      ? []
      : Object.keys(sourceDatasetState?.values ?? {}).map((identRef) => ({
        identRef,
        value: sourceDatasetState.values[identRef].value,
        backupValue: undefined,
      }));
    await executeDeviceDownload(targetInstance, methodIdent, values);
  };

  switch (action.type) {
    case EXECUTE_DEVICE_UPLOAD_METHOD: {
      const { methodIdent, targetInstance } = action.payload;
      await executeDeviceUpload(targetInstance, methodIdent);
      break;
    }

    case EXECUTE_DEVICE_DOWNLOAD_METHOD: {
      const { values, methodIdent, targetInstance } = action.payload;
      logger.debug('Download data to device', methodIdent);
      await executeDeviceDownload(targetInstance, methodIdent, values);
      break;
    }

    case EXECUTE_DEVICE_DOWNLOAD_DATASET_METHOD: {
      const { targetInstance, sourceDataset, methodIdent } = action.payload;
      logger.debug('Download dataset to device', targetInstance, methodIdent, sourceDataset);
      await executeDeviceDownloadDataset(targetInstance, methodIdent, sourceDataset);
      break;
    }
    default:
  }
  const result = next(action);
  return result;
};
