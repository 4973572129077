/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Quint4DropDownBaseControl from '../../../Quint4DropDown/Quint4DropDownControl';
import { StructureItemStateChangeCallback } from '../../../../ReduxControlProps';
import { DatasetContext } from '../../../../../views/DatasetContext';
import useStatusValueStateUpdate from '../../../hooks/useStatusValueStateUpdate';
import useComboResultValue from '../../hooks/useComboResultValue';

export interface Quint4DigitalResultProps {
  identRef: string;
  deviceInstanceId: string;
  onValueChange: (value: number) => void;
  onShowContentHelp?: () => void;
  onHideContentHelp?: () => void;
  showContextMessageDef?: boolean;
  warning?: string;
  readonly?: boolean;
  onStateChanged: StructureItemStateChangeCallback,
}

const Quint4DigitalResultDropDown
  :React.FC<Quint4DigitalResultProps> = (props: Quint4DigitalResultProps):
    React.ReactElement => {
    const {
      onShowContentHelp,
      onHideContentHelp,
      onValueChange,
      readonly,
      showContextMessageDef,
      warning,
      deviceInstanceId,
      identRef,
      onStateChanged,
    } = props;
    const { t } = useTranslation();

    const { targetDataset } = useContext(DatasetContext);
    const visibility = useStatusValueStateUpdate(identRef, deviceInstanceId, targetDataset, onStateChanged);

    const [dropDownValue, label, help, hiItemValue, hiItemLabel, loItemValue, loItemLabel] = useComboResultValue(identRef, deviceInstanceId, targetDataset);

    if (visibility === false) {
      return <></>;
    }

    return (
      <Quint4DropDownBaseControl
        label={label}
        help={help}
        value={dropDownValue?.value}
        options={{
          [loItemValue ?? 0]: t(loItemLabel),
          [hiItemValue ?? 1]: t(hiItemLabel),
        }}
        valueModified={dropDownValue?.modified ?? false}
        warning={warning}
        onValueChange={onValueChange}
        onShowContentHelp={onShowContentHelp}
        onHideContentHelp={onHideContentHelp}
        readonly={readonly}
        showContextMessageDef={showContextMessageDef}
      />
    );
  };

export default Quint4DigitalResultDropDown;
