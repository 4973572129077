/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */

import {
  DocumentType,
  Report,
  DocumentLanguage,
  IdentRef,
} from '@gpt/commons';
import { Action } from 'redux';
import { DatasetType } from '../deviceInstances/store/deviceDataset/types';
import { DeviceInstanceActionPayload } from '../deviceInstances/common';

export const REPORT_SERVICE__CREATE_REPORT = 'REPORT_SERVICE__CREATE_REPORT';
export const REPORT_SERVICE__SET_REPORT_STATUS = 'REPORT_SERVICE__SET_REPORT_STATUS';

export enum ReportStatus {
  INIT = 0,
  SUCCESS = 1,
  FAILED = 2,
  INPROGRESS = 3,
}

export interface CreateReportState {
  status: ReportStatus;
  documentDataUrl?: string;
}

export interface CreateReportActionPayload {
  documentType: DocumentType;
  reportData: Report;
  language: DocumentLanguage;
  reportMenu?: IdentRef,
  reportFilename: string;
  activeDataset: DatasetType;
  compareDataset: DatasetType;
  dictFamily: string;
}

export interface CreateReportPayload extends DeviceInstanceActionPayload {
  report: CreateReportActionPayload;
}

export interface SetReportStatusAction
  extends Action<typeof REPORT_SERVICE__SET_REPORT_STATUS> {
    payload: ReportStatus;
}

export interface CreateReportAction
  extends Action<typeof REPORT_SERVICE__CREATE_REPORT> {
    payload: CreateReportPayload;
}

export type ReportServiceActionTypes =
  | SetReportStatusAction
  | CreateReportAction
