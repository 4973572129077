/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../store/reduxStore';
import { ReduxControlProps } from '../ReduxControlProps';
import { UnsupportedControl } from '../../components/UnsupportedControl';
import InputSliderDescriptorControl from './InputSliderDescriptorControl';
import { variablesErrorStatusSelector, variablesModifiedStatusSelector, variablesVisibilitySelector } from '../selectors/variablesStatusSelector';
import { useContextDatasetDescriptor } from '../../hooks/useContextDataset';
import { DatasetContext } from '../../views/DatasetContext';
import controlTypeSelector from '../selectors/controlTypeSelector';
import withControlVisibility from '../hoc/withControlVisibility';
import { deviceTargetDatasetSelector } from '../../store/deviceInstances/store/dataStorage/selectors';

const InputSliderReduxControl: React.FC<ReduxControlProps> = (props: ReduxControlProps)
  : React.ReactElement | null => {
  const { identRef, onStateChanged, deviceInstanceId } = props;
  const { targetDataset } = useContext(DatasetContext);
  const descriptor = useContextDatasetDescriptor(deviceInstanceId, identRef);

  const modified = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const control = controlTypeSelector<DeviceModelStatus.UI.SliderControlType>(DeviceModelStatus.UI.ControlType.CTLSLIDER, identRef, deviceDataset);
    if (control === undefined || deviceDataset === undefined) {
      return false;
    }

    if (control === undefined) {
      return false;
    }
    return variablesModifiedStatusSelector(deviceDataset, [
      control.variable,
    ]);
  });

  const error = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const control = controlTypeSelector<DeviceModelStatus.UI.SliderControlType>(DeviceModelStatus.UI.ControlType.CTLSLIDER, identRef, deviceDataset);
    if (control === undefined || deviceDataset === undefined) {
      return false;
    }
    return variablesErrorStatusSelector(deviceDataset, [
      control.variable,
    ]);
  });

  const visibility = useTypedSelector((state) => {
    const deviceDataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, targetDataset);
    const control = controlTypeSelector<DeviceModelStatus.UI.SliderControlType>(DeviceModelStatus.UI.ControlType.CTLSLIDER, identRef, deviceDataset);
    if (control === undefined || deviceDataset === undefined) {
      return false;
    }
    return variablesVisibilitySelector(deviceDataset, [
      control.variable,
    ]);
  });

  useEffect(() => () => {
    onStateChanged(identRef, { modified: false, error: false });
  }, []);

  useEffect(() => {
    onStateChanged(identRef, {
      error,
      modified,
    });
  }, [error, modified, identRef]);

  if (descriptor === undefined) {
    return <UnsupportedControl text={`InputSliderRangeReduxControl: Undefined object ${identRef}`} />;
  }

  if (descriptor.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return <UnsupportedControl text="InputSliderRangeReduxControl: Supported type is ControlDescriptor only" />;
  }

  if (descriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLSLIDER) {
    return <UnsupportedControl text="InputSliderRangeReduxControl: Supported ControlType is CTLSLIDER only" />;
  }

  if (visibility === false) {
    return (null);
  }

  return (
    <InputSliderDescriptorControl
      deviceInstanceId={deviceInstanceId}
      variable={descriptor.controlType.variable}
      minRangeValue={descriptor.controlType.rangeMin}
      maxRangeValue={descriptor.controlType.rangeMax}
    />
  );
};

export default withControlVisibility(InputSliderReduxControl);
