/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStationTopology } from '../../../../components/StationNavigation/hooks/useStationTopology';
import ContactronCockpitModuleLayout8 from './Layout8/CockpitModuleLayout8';
import ContactronCockpitModuleLayout16 from './Layout16/CockpitModuleLayout16';
import ContactronCockpitModuleLayout32 from './Layout32/CockpitModuleLayout32';
import { sPortWriteParameter } from '../../../../store/deviceInstances/middleware/deviceMethod';
import useModuleInstanceSelector from './hooks/useModuleInstanceSelector';
import { LoadingControl } from '../../../../components/LoadingControl';

export interface ContactronCockpitModuleLayoutProps {
  stationInstanceId: string;
}

const ContactronCockpitModuleLayout: React.FC<ContactronCockpitModuleLayoutProps> = (
  props: ContactronCockpitModuleLayoutProps,
):React.ReactElement => {
  const { stationInstanceId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const topology = useStationTopology(stationInstanceId) ?? [];
  const moduleInstanceSelector = useModuleInstanceSelector(stationInstanceId);

  const moduleActionFlash = useCallback((instanceId: string, moduleAddress: string) => {
    dispatch(sPortWriteParameter({
      actionId: 'contactron-layout8-card--device-ident',
      memoryAddress: 0x7800,
      data: new Uint8Array([0x00, 0x01]),
      targetInstance: instanceId,
      slaveAddress: moduleAddress,
    }));
  }, []);

  if (topology.length === 0) {
    return <LoadingControl title={t('CONTACTRON_STATION__SEARCHING')} />;
  }

  let layout = <></>;
  if (topology.length < 9) {
    layout = (
      <ContactronCockpitModuleLayout8
        topology={topology}
        stationInstanceId={stationInstanceId}
        onModuleIdent={(moduleAddress) => moduleActionFlash(stationInstanceId, moduleAddress)}
        onModuleOpen={(moduleInstanceId) => moduleInstanceSelector(moduleInstanceId)}
      />
    );
  } else if (topology.length < 17) {
    layout = (
      <ContactronCockpitModuleLayout16
        topology={topology}
        stationInstanceId={stationInstanceId}
        onModuleIdent={(moduleAddress) => moduleActionFlash(stationInstanceId, moduleAddress)}
        onModuleOpen={(moduleInstanceId) => moduleInstanceSelector(moduleInstanceId)}
      />
    );
  } else if (topology.length < 33) {
    layout = (
      <ContactronCockpitModuleLayout32
        topology={topology}
        stationInstanceId={stationInstanceId}
        onModuleIdent={(moduleAddress) => moduleActionFlash(stationInstanceId, moduleAddress)}
        onModuleOpen={(moduleInstanceId) => moduleInstanceSelector(moduleInstanceId)}
      />
    );
  }

  return (
    <>{layout}</>
  );
};

export default ContactronCockpitModuleLayout;
