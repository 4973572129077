/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { IdentRef, Services } from '@gpt/commons';
import { Action } from 'redux';
import { CompareModeType } from '../../views/CompareView/common';
import { DatasetType } from '../deviceInstances/store/deviceDataset/types';
import { DeviceInstanceAction } from '../deviceInstances/common';

export const COMPARE_VIEW__SET_COMPARE_METHOD = 'COMPARE_VIEW__SET_COMPARE_METHOD';
export const COMPARE_VIEW__SET_COMPARE_DATASET_IDENT = 'COMPARE_VIEW__SET_COMPARE_DATASET_IDENT';
export const COMPARE_VIEW__SET_DEVICE_AS_EXTERNAL = 'COMPARE_VIEW__SET_DEVICE_AS_EXTERNAL';

export const COMPARE_VIEW__WRITE_VALUES = 'COMPARE_VIEW__WRITE_VALUES';
export const COMPARE_VIEW__REDO_VALUES = 'COMPARE_VIEW__REDO_VALUES';

export interface CompareViewState {
  compareMethod: CompareModeType;
  compareDatasetIdent?: Services.DeviceModelServer.DatasetProviderService.DatasetIdent;
}

export interface CompareViewSetCompareMethodAction extends Action<typeof COMPARE_VIEW__SET_COMPARE_METHOD> {
  payload: CompareModeType;
}

export interface CompareViewSetCompareDatasetIdentAction extends Action<typeof COMPARE_VIEW__SET_COMPARE_DATASET_IDENT> {
  payload: Services.DeviceModelServer.DatasetProviderService.DatasetIdent;
}

export interface CompareViewSetDeviceDatasetAsExternalAction extends Action<typeof COMPARE_VIEW__SET_DEVICE_AS_EXTERNAL> {
  payload: Services.DeviceModelServer.DatasetProviderService.DatasetIdent;
}

export interface CompareValuesPayload {
  sourceDataset: DatasetType;
  targetDataset: DatasetType;
  items: IdentRef[];
}

export type CompareWriteValuesAction = DeviceInstanceAction<typeof COMPARE_VIEW__WRITE_VALUES, CompareValuesPayload>

export type CompareRedoValuesAction = DeviceInstanceAction<typeof COMPARE_VIEW__REDO_VALUES, CompareValuesPayload>

export type typeCompareViewActionTypes = CompareViewSetCompareMethodAction
  | CompareViewSetCompareDatasetIdentAction
  | CompareWriteValuesAction
  | CompareRedoValuesAction;
