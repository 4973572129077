/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { DeviceStatusControl } from '../../../../components/DeviceStatusControl/DeviceStatusControl';
import { ReduxControlProps } from '../../../ReduxControlProps';
import { useTypedSelector } from '../../../../store';
import { deviceTargetDatasetSelector } from '../../../../store/deviceInstances/store/dataStorage/selectors';
import { DatasetState, DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import './SQDeviceStatusLed.scss';

const controlTypeSelector = (dataset: DatasetState | undefined, identRef: IdentRef) => {
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  return controlType.type !== DeviceModelStatus.UI.ControlType.CTLDEVICESTATUS
    ? undefined
    : controlType;
};

const SQDeviceStatusLed: React.FC<ReduxControlProps> = (
  props: ReduxControlProps,
): React.ReactElement | null => {
  const { deviceInstanceId, identRef } = props;

  const label = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.device);
    const controlType = controlTypeSelector(dataset, identRef);
    return controlType?.type !== DeviceModelStatus.UI.ControlType.CTLDEVICESTATUS
      ? ''
      : controlType.label;
  });

  const behaviour = useTypedSelector((state) => {
    const dataset = deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, DatasetType.device);
    const controlType = controlTypeSelector(dataset, identRef);
    if (controlType === undefined) {
      return undefined;
    }
    const value = dataset?.values[controlType.variable]?.value;
    const item = controlType.BEHAVIOUR.find((bh) => bh.flag === value);
    return item === undefined
      ? controlType.DEFAULT
      : item;
  });

  if (behaviour === undefined) {
    return (null);
  }

  return (
    <div className="contactron-sq-device-status">
      <DeviceStatusControl
        label={label}
        led={behaviour.led}
        text={behaviour.text}
        message={behaviour.message}
      />
    </div>
  );
};

export default SQDeviceStatusLed;
