/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

export const MAX_SELECTED_MODULE_INPUTS_OUTPUTS = 16;
export const MAX_SELECTED_STATION_INPUTS = 32;
export const MAX_SELECTED_STATION_OUTPUTS = 15;

export interface i18nMessage {
  message: string;
  arg: string;
}

export const isStationMaxReached = (selectedInputs: number, selectedOutputs: number, moduleCount: number): i18nMessage | undefined => {
  const totalInputs = selectedInputs * moduleCount;
  const totalOutputs = selectedOutputs * moduleCount;

  let message: i18nMessage | undefined;
  if (totalInputs > MAX_SELECTED_STATION_INPUTS) {
    message = {
      message: 'WIZARD_CONTACTRON__MESSAGE__STATION__MAX_SELECTED_INPUT_PROCESS_DATA',
      arg: `${MAX_SELECTED_STATION_INPUTS}`,
    };
  } else if (totalOutputs > MAX_SELECTED_STATION_OUTPUTS) {
    message = {
      message: 'WIZARD_CONTACTRON__MESSAGE__STATION__MAX_SELECTED_OUTPUT_PROCESS_DATA',
      arg: `${MAX_SELECTED_STATION_OUTPUTS}`,
    };
  }
  return message;
};

export const isMaxSelectionReached = (selectedModuleInputs: number, selectedModuleOutputs: number, applyAll: boolean, modulesCount: number): i18nMessage | undefined => {
  let message: i18nMessage | undefined;
  if ((selectedModuleInputs + selectedModuleOutputs) >= MAX_SELECTED_MODULE_INPUTS_OUTPUTS) {
    message = {
      message: 'WIZARD_CONTACTRON__MESSAGE__MODULE__MAX_SELECTED_PROCESS_DATA',
      arg: `${MAX_SELECTED_MODULE_INPUTS_OUTPUTS}`,
    };
  } else if (applyAll) {
    message = isStationMaxReached(selectedModuleInputs, selectedModuleOutputs, modulesCount);
  }
  return message;
};
