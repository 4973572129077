/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './CurrentStatusControl.css';
import { trendColorsMap } from '../ApexTrendControl/types';

export interface CurrentStatusControlType {
  title?: string,
  variableTitle: string,
  currentValue: string;
  unit?: string;
  color: string;
}

export interface CurrentStatusControlProps {
    title?: string,
    variableTitle: string,
    currentValue: string;
    unit?: string;
    color: string;
}

export const CurrentStatusControl: React.FC<CurrentStatusControlProps> = (
  props: CurrentStatusControlProps,
): React.ReactElement => {
  const {
    title, variableTitle, unit, currentValue, color,
  } = props;

  return (
    <div style={{ borderLeftColor: trendColorsMap[color], borderLeftWidth: '3px', borderLeftStyle: 'solid' }} className="CurrentStatusControl__Container">
      <div className="CurrentStatusControl__TitleContainer">
        {
            title && (
            <div className="CurrentStatusControl__TitleContainer-channel">
              {title}
            </div>
            )
        }
        <div className="CurrentStatusControl__TitleContainer-label">{variableTitle}</div>

        <div className="CurrentStatusControl__TitleContainer-valueUnit">
          <div className="CurrentStatusControl__TitleContainer-value">{currentValue}</div>
          <div className="CurrentStatusControl__TitleContainer-unit">{unit}</div>
        </div>
      </div>
    </div>
  );
};
