/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import './ProcessDataInputOutput.scss';
import { ProcessDataIOElement } from './ProcessDataIOElement/ProcessDataIOElement';

export interface ProcessDataInputOutputProps {
  moduleDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceType;
}

export const ProcessDataInputOutput: React.FC<ProcessDataInputOutputProps> = (
  props: ProcessDataInputOutputProps,
): React.ReactElement => {
  const { moduleDescription } = props;
  const { t } = useTranslation();

  const inputData = (moduleDescription?.inputDataList ?? []).map((itemName) => {
    const mdesc = moduleDescription.inputDataDescription[itemName];

    return (
      <ProcessDataIOElement
        key={`device-process-data-input-checkbox-${mdesc.address}-${mdesc.name}`}
        kind="input"
        label={mdesc.label}
        name={mdesc.name}
        help={mdesc.help}
        defaultValue={mdesc.checked}
        readonly={mdesc.readonly}
      />
    );
  });

  const outputData = (moduleDescription?.outputDataList ?? []).map((itemName) => {
    const mdesc = moduleDescription.outputDataDescription[itemName];
    return (
      <ProcessDataIOElement
        key={`device-process-data-output-checkbox-${mdesc.address}-${mdesc.name}`}
        kind="output"
        label={mdesc.label}
        name={mdesc.name}
        help={mdesc.help}
        defaultValue={mdesc.checked}
        readonly={mdesc.readonly}
      />
    );
  });

  return (
    <div className="device-process-data-input-output">
      <div className="device-process-data-input-output__input">
        <div className="device-process-data-input-output__input-header">
          <div className="device-process-data-input-output-header">
            {t('WIZARD_CONTACTRON__PAGE5_INPUT')}
          </div>
        </div>
        <div className="device-process-data-input-output__input-data">
          {inputData}
        </div>
      </div>
      <div className="device-process-data-input-output__output">
        <div className="device-process-data-input-output__input-header">
          <div className="device-process-data-input-output-header">
            {t('WIZARD_CONTACTRON__PAGE5_OUTPUT')}
          </div>
        </div>
        <div className="device-process-data-input-output__output-data">
          {outputData}
        </div>
      </div>
    </div>
  );
};
