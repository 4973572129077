/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { writeActiveDeviceVariableValues } from '../../../store';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { updateDeviceDatasetValues } from '../../../store/deviceInstances/store/deviceDataset';

const useDispatchDateValue = (deviceInstanceId: string | undefined, targetDataset: DatasetType) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const dispatchValue = useCallback((
    newValue: string,
    statusValue?: DeviceModelStatus.StatusValue,
  ): void => {
    if (statusValue === undefined || deviceInstanceId === undefined) {
      return;
    }

    const validateRange = (value: string): boolean => {
      const isValidDate = new Date(value);
      return !Number.isNaN(isValidDate.getDate());
    };

    if (validateRange(newValue)) {
      dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
        value: newValue,
        backupValue: statusValue.backupValue,
        identRef: statusValue.identRef,
      }]));
    } else {
      dispatch(updateDeviceDatasetValues(deviceInstanceId, {
        targetDataset: targetDataset ?? DatasetType.user,
        values: [{
          value: newValue,
          backupValue: statusValue.backupValue,
          identRef: statusValue.identRef,
          valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
          valueValidityDescription: t<string>('PARAMETER_VALIDATION_ERROR__INVALID_DATE_FORMAT'),
        }],
      }));
    }
  }, []);

  return dispatchValue;
};

export default useDispatchDateValue;
