/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { PropsWithChildren } from 'react';
import CompareParameterViewLayout from '../../../../views/CompareView/CompareParameterViewLayout';
import './CompareGroupValues.css';

export interface CompareGroupValuesProps extends PropsWithChildren<any> {
    title: string;
}

const CompareGroupValues
  : React.FC<CompareGroupValuesProps> = (props: CompareGroupValuesProps)
: React.ReactElement => {
    const {
      title, children,
    } = props;

    return (
      <>
        <CompareParameterViewLayout>
          <div className="compare-group-title"><p>{title}</p></div>
          <div />
          <div />
        </CompareParameterViewLayout>
        <div className="pt-1">
          {children}
        </div>
        <div className="border-bottom m-0 p-0 mt-2 mb-2" />
      </>
    );
  };

export default CompareGroupValues;
