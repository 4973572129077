/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageBoxContainer } from '../../components/MessageBoxContainer/MessageBoxContainer';
import { useTypedSelector } from '../../store';

const ApplicationMessageRedux: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const appMessages = useTypedSelector((state) => state.applicationMessage.messageList);

  if (appMessages === undefined || appMessages.length === 0) {
    return (<></>);
  }

  const messages = appMessages.map((item) => ({
    type: item.type ?? 'error',
    id: item.id,
    message: item.messageId ? t(item.messageId) : '',
    showCloseButton: item.show,
  }));

  return (
    <MessageBoxContainer
      messages={messages}
    />
  );
};

export default ApplicationMessageRedux;
