/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setContextParameterHelp } from '../../../../store/contexthelp';
import { CompareModeType } from '../../../../views/CompareView/common';
import { DatasetState, DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import { UnsupportedControl } from '../../../../components/UnsupportedControl';
import { useTypedSelector } from '../../../../store';
import { CompareStatusValueProps, ButtonFunction } from '../../CompareTypes';
import useRangeMidButton from './hooks/useRangeMidButton';
import useCompareRangeMembers from './hooks/useCompareRangeMembers';
import CompareRangeStatusValue from './CompareRangeStatusValue/CompareRangeStatusValue';
import { DeviceInstancesState } from '../../../../store/deviceInstances/types';
import { deviceTargetDatasetSelector } from '../../../../store/deviceInstances/store/dataStorage/selectors';

const getSpanControlType = (identRef: IdentRef, dataset?: DatasetState)
: DeviceModelStatus.UI.RangeWithSpanControlType | undefined => {
  const spanDescriptor = dataset?.descriptors[identRef];
  if (spanDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  if (spanDescriptor.controlType.type !== DeviceModelStatus.UI.ControlType.CTLRANGEWITHSPAN) {
    return undefined;
  }
  return spanDescriptor.controlType;
};

const getVariableDescriptor = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: IdentRef,
  member: 'rangeEnd' | 'rangeStart' | 'spanVar',
) => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const controlType = getSpanControlType(identRef, dataset);
  if (controlType === undefined) {
    return undefined;
  }
  return dataset?.descriptors[controlType[member]];
};

const getControlVisibility = (
  state: DeviceInstancesState,
  deviceInstanceId: string,
  targetDataset: DatasetType,
  identRef: IdentRef,
): boolean => {
  const dataset = deviceTargetDatasetSelector(state, deviceInstanceId, targetDataset);
  const spanDescriptor = dataset?.descriptors[identRef];
  if (spanDescriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return false;
  }
  return spanDescriptor.visibility ?? true;
};

const createCompareVariableControl = (
  identRef: IdentRef,
  deviceInstanceId: string,
  parentId: string,
  compareMode: CompareModeType,
  leftDataset: DatasetType,
  rightDataset: DatasetType,
  // eslint-disable-next-line no-unused-vars
  onMidButtonClick: (buttonFunction: ButtonFunction) => void,
  onShowContentHelp: () => void,
  onHideContentHelp: () => void,
  hideLeftControl: boolean,
  hideRightControl: boolean,
) : JSX.Element => (
  <CompareRangeStatusValue
    parentId={parentId}
    deviceInstanceId={deviceInstanceId}
    key={`cmp-range-${parentId}--${identRef}`}
    compareMode={compareMode}
    identRef={identRef}
    leftDataset={leftDataset}
    rightDataset={rightDataset}
    onShowContentHelp={onShowContentHelp}
    onHideContentHelp={onHideContentHelp}
    onMidButtonClick={onMidButtonClick}
    hideLeftControl={hideLeftControl}
    hideRightControl={hideRightControl}
  />
);

const CompareRangeWithSpanControl
  : React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
: React.ReactElement | null => {
    const {
      identRef, leftDataset, rightDataset, compareMode, parentId,
      deviceInstanceId, onShowContentHelp, onHideContentHelp,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [butFunction, action] = useRangeMidButton(deviceInstanceId, identRef, leftDataset, rightDataset);
    const equal = useCompareRangeMembers(deviceInstanceId, identRef, leftDataset, rightDataset);

    const leftRangeStartDesc = useTypedSelector((state) => getVariableDescriptor(state.deviceInstances, deviceInstanceId, leftDataset, identRef, 'rangeStart'));
    const leftRangeEndDesc = useTypedSelector((state) => getVariableDescriptor(state.deviceInstances, deviceInstanceId, leftDataset, identRef, 'rangeEnd'));

    const ltControlVisibility = useTypedSelector(
      (state) => getControlVisibility(state.deviceInstances, deviceInstanceId, leftDataset, identRef),
    );
    const rtControlVisibility = useTypedSelector(
      (state) => getControlVisibility(state.deviceInstances, deviceInstanceId, rightDataset, identRef),
    );

    if (leftRangeStartDesc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return <UnsupportedControl text="CompareRangeWithSpanControl: rangeStartDesc Unsupported type" />;
    }

    if (leftRangeEndDesc?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return <UnsupportedControl text="CompareRangeWithSpanControl: rangeEndDesc Unsupported type" />;
    }

    if (compareMode === CompareModeType.ShowDifference && equal) {
      return (null);
    }

    const groupElements: JSX.Element[] = [leftRangeStartDesc, leftRangeEndDesc]
      .map((item) => createCompareVariableControl(
        item.identRef,
        deviceInstanceId,
        parentId,
        compareMode,
        leftDataset,
        rightDataset,
        () => action(butFunction),
        () => {
          dispatch(setContextParameterHelp({
            title: item.label === undefined ? '' : t<string>(item.label),
            text: item.help === undefined ? '' : t<string>(item.help),
          }));
          onShowContentHelp?.();
        },
        () => {
          dispatch(setContextParameterHelp({
            title: '',
            text: '',
          }));
          onHideContentHelp?.();
        },
        ltControlVisibility === false,
        rtControlVisibility === false,
      ));
    return (
      <>
        {groupElements}
      </>
    );
  };

export default CompareRangeWithSpanControl;
