/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import React from 'react';
import './FwdRefInputBoxControl.scss';

export interface FwdRefInputBoxProps {
    type: string;
    value: string | number;
    id?: string;
    minValue?: number;
    maxValue?: number;
    step?: number;
    warning?: string;
    readonly?: boolean;
    disabled?: boolean;
    edited?: boolean;
    maxLength?: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

const FwdRefInputBoxControl = React.forwardRef<HTMLInputElement, FwdRefInputBoxProps>((props, ref) => {
  const {
    type, value,
    minValue, maxValue, step,
    warning, id,
    readonly, disabled,
    edited, maxLength,
    onChange, onBlur, onFocus,
  } = props;
  const classNameEditedText = (edited !== undefined && edited === true) ? 'textfield__input--edited' : '';

  return (
    <div className="fwd-inputbox-control">
      <div className="input-field">
        <input
          id={id}
          ref={ref}
          type={type}
          value={Number.isNaN(value) ? 'NaN' : value}
          min={minValue}
          max={maxValue}
          step={step}
          className={`textfield__input ${classNameEditedText} ${warning !== undefined ? 'invalid' : ''}`}
          readOnly={readonly}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
          onFocus={() => onFocus?.()}
          onBlur={() => onBlur?.()}
          maxLength={maxLength}
          data-testid="fwd-ref-input-box-control-input"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="textfield__label">{}</label>
        {/* <span className="helper-text" data-error={warning} /> */}
        <div className="input-field-control__warning d-flex flex-row justify-content-start align-items-start">
          <div className="input-field-control__warning-text">{warning}</div>
        </div>
      </div>
    </div>
  );
});

export default FwdRefInputBoxControl;
