/* eslint-disable function-paren-newline */
import { IdentRef, DeviceModelStatus } from '@gpt/commons';
import { DatasetState } from '../../store/deviceInstances/store/deviceDataset/types';

const descriptorValidity = (deviceDataset: DatasetState, ident: IdentRef): boolean => {
  const descriptor = deviceDataset.descriptors[ident];
  if (descriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return false;
  }
  return descriptor.validity;
};

export const variablesModifiedStatusSelector = (
  deviceDataset: DatasetState, identRefs: IdentRef[],
): boolean => {
  if (identRefs.length === 0) {
    return false;
  }
  const modified = identRefs
    .find((ident) => {
      const descValidity = descriptorValidity(deviceDataset, ident);
      return descValidity === false
        ? false
        : (deviceDataset.values[ident]?.modified ?? false);
    });
  return modified !== undefined;
};

export const variablesErrorStatusSelector = (
  deviceDataset: DatasetState, identRefs: IdentRef[],
): boolean => {
  if (identRefs.length === 0) {
    return false;
  }
  const validity = identRefs
    .find((ident) => {
      const descValidity = descriptorValidity(deviceDataset, ident);
      return descValidity === false
        ? false
        // eslint-disable-next-line max-len
        : (deviceDataset.values[ident]?.valueValidity !== DeviceModelStatus.StatusValueValidity.valid);
    });
  return validity !== undefined;
};

export const variablesVisibilitySelector = (
  deviceDataset: DatasetState, identRefs: IdentRef[],
): boolean => {
  if (identRefs.length === 0) {
    return true;
  }
  // Find 1st element with Visibility === False
  const visibilityFalse = identRefs.find((ident) => {
    const descriptor = deviceDataset.descriptors[ident];
    if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
      return false;
    }
    return descriptor.visibility === false;
  });
  return visibilityFalse === undefined;
};
