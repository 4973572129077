/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */

import isElectron from 'is-electron';
import { Store } from 'redux';
import { Services, WebDevice } from '@gpt/commons';
import {
  HostConnector,
  SubApplication,
  AssetInformation,
  ApplicationStartOptions,
  ApplicationCloseStatus,
  ImportLocalFileOptions,
  ImportLocalFileResult,
  ExportLocalFileOptions,
  ExportLocalFileResult,
} from '@gpt/cxe-dp-integration';
import { Logger } from '../UILogger';
import { ExecutionState } from '../../store/common';
import { requestDeviceCatalogDeviceList } from '../../store/deviceCatalog/actions';
import { prepareTransferValues } from '../../store/wizards/cxeTransferWizard/actions';
import { PackageVersion } from '../../PackageVersion';
import { RoutePaths } from '../../RoutePaths';
import { deviceDiscoveryServiceSelector, deviceInstancesStoreSelector } from '../../store/reduxStoreSelector';
import { MethodStageExecutionStatus } from '../../store/deviceInstances/store/deviceMethod/types';
import { instantiateDevice, instantiateDeviceOfflineExternalFile } from '../../store/deviceInstances/middleware/webDeviceInstantiation/actions';
import { WIZARD__CXE_EDIT_DEVICE_PARAM__ID, WIZARD__CXE_TRANSFER_DEVICE_PARAM__ID } from './clipxEngineerConsts';
import { setApplicationLanguage } from '../../store/middleware/appLanguage/actions';

class ClipxEngineerIntegrationService {
  public static instance: ClipxEngineerIntegrationService;

  public hostConnector: HostConnector | undefined;

  private logger?: Logger;

  private appStartAsset: AssetInformation | undefined;

  private readonly LOAD_DATASET_BY_CXE = 'LOAD-DATASET-BY-CLIPX-ENGINEER';

  public constructor(reduxStore: Store) {
    if (isElectron()) {
      // in Electron based desktop application,
      // clipx ENGINEER integration isn't necessary
      return;
    }

    // set static instance singleton (for closeCxeDeviceParameterizationApp)
    ClipxEngineerIntegrationService.instance = this;

    // create "HostConnector" instance (of "cxe-dp-integration" library)
    this.hostConnector = new HostConnector({
      isAvailable: async (timeoutSecs?: number): Promise<boolean> => {
        // check availability of deviceCatalog in Redux store
        const discoveryService = deviceDiscoveryServiceSelector(reduxStore.getState());
        if (discoveryService.catalog.executionState === ExecutionState.success) {
          return true;
        }
        // request deviceCatalog.deviceList from Redux store
        if (discoveryService.catalog.executionState !== ExecutionState.pending) {
          reduxStore.dispatch(requestDeviceCatalogDeviceList());
        }
        // to reduce request flood, add short delay before sending response
        await new Promise((resolve) => { setTimeout(() => resolve, 50); });
        return false;
      },

      getVersion: () => Promise.resolve(PackageVersion),

      getSupportedProductIds: async (): Promise<string[]> => {
        const deviceCatalog = deviceDiscoveryServiceSelector(reduxStore.getState());
        const { deviceList } = deviceCatalog.catalog;
        const orderCodes = Object.keys(deviceList).map((dt) => deviceList[dt].productOrderNumber);
        const uniqueOrderCodes = [...new Set(orderCodes)];
        return uniqueOrderCodes;
      },

      start: async (options: ApplicationStartOptions): Promise<void> => {
        // log start() options, but for parameterDataSet only its length
        const parameterDataSetLength = options.parameterDataSet
          ? `[length: ${options.parameterDataSet?.length}]` : undefined;
        this.logger?.info(
          'start cxE device parameterization',
          { ...options, parameterDataSet: parameterDataSetLength },
        );

        if (options.languageCode) {
          this.logger?.debug('setting UI language code', options.languageCode);
          // setAppUiLanguageCode(this.socket, options.languageCode);
          reduxStore.dispatch(setApplicationLanguage(options.languageCode));
        }

        if (options.subApplication === SubApplication.WizardEditParameterSet) {
          await this.startDeviceParameterization(WIZARD__CXE_EDIT_DEVICE_PARAM__ID, options, reduxStore);
        } else if (options.subApplication === SubApplication.WizardTransferParameterSet) {
          await this.startTransferDeviceParameterization(options, reduxStore);
        }
        this.startSubApplication(options);
      },
    });

    // don't show StartupView but LoadingSpinner until .start()
    // has been called and (if requested) device model is loaded…
    window.location.hash = `#${RoutePaths.LoadingView}`;
  }

  private subAppRoutePaths = new Map<SubApplication, RoutePaths>([
    [SubApplication.StartupView, RoutePaths.StartupView],
    [SubApplication.DeviceCockpit, RoutePaths.DeviceView],
    [SubApplication.DeviceParameterization, RoutePaths.DeviceParameterView],
    [SubApplication.DeviceCompareParameters, RoutePaths.DeviceCompareParameterView],
    [SubApplication.WizardDeviceStartup, RoutePaths.WizardCommissioning],
    [SubApplication.WizardReplaceDevice, RoutePaths.WizardChangeDevice],
    [SubApplication.WizardRestoreDevice, RoutePaths.WizardRestoreDevice],
    [SubApplication.WizardEditParameterSet, RoutePaths.WizardEditParameterSetCxe],
    [SubApplication.WizardCreateParameterSet, RoutePaths.WizardCreateParameterSet],
    [SubApplication.WizardTransferParameterSet, RoutePaths.WizardTransferDeviceParameterCxe],
  ]);

  private async startDeviceParameterization(deviceInstanceId: string, options: ApplicationStartOptions, reduxStore: Store) {
    this.appStartAsset = options.asset;

    this.logger?.debug('startDeviceParameterization', deviceInstanceId);
    const hasDevice = this.startInstantiateDevice(deviceInstanceId, options, reduxStore);

    if (hasDevice) {
      await this.waitInstantiateDevice(deviceInstanceId, reduxStore);
    }
  }

  // eslint-disable-next-line max-len
  private async startTransferDeviceParameterization(options: ApplicationStartOptions, reduxStore: Store) {
    const log = this.logger;
    const { dispatch } = reduxStore;
    const { parameterDataSet, deviceProductId } = options;
    const startMsg = 'Starting cxE device transfer parameterization based on';
    this.logger?.debug('startTransferDeviceParameterization', deviceProductId);

    const startAppFailed = () => {
      const errorMsg = 'Failed to start cxE device parameterization (parameterDataSet or deviceProductId) not defined';
      log?.warn(errorMsg);
      this.closeCxeDpApp({ result: 'cancelled' });
      throw new Error(errorMsg);
    };

    if (parameterDataSet && deviceProductId) {
      log?.info(
        `${startMsg} ParameterDataSet:`,
        `(size: ${parameterDataSet.length})`,
        this.getCxedpJsonHeaderDevice(parameterDataSet),
        `DeviceProductId/ProductOrderNumber: ${deviceProductId}`,
      );

      const parseDataset = JSON.parse(parameterDataSet) as Services.DeviceModelServer.DatasetProviderService.ExternalDataset;

      if (parseDataset === null) {
        startAppFailed();
        return false;
      }

      dispatch(prepareTransferValues({
        orderCode: deviceProductId,
        dataset: parameterDataSet,
      }));

      if (parseDataset.header.datasetType === Services.DeviceModelServer.DatasetProviderService.ExternalDatasetType.HARDWARE_SWITCH) {
        await this.startDeviceParameterization(WIZARD__CXE_TRANSFER_DEVICE_PARAM__ID, options, reduxStore);
      }
      return true;
    }

    startAppFailed();
    return false;
  }

  private startInstantiateDevice(deviceInstanceId: string, options: ApplicationStartOptions, reduxStore: Store): boolean {
    let result = true;
    const log = this.logger;
    const { dispatch } = reduxStore;

    log?.debug(`startInstantiateDevice ${deviceInstanceId}`);
    const deviceCatalog = deviceDiscoveryServiceSelector(reduxStore.getState());
    const { catalog } = deviceCatalog;
    const deviceList = Object.keys(catalog.deviceList).map((key) => catalog.deviceList[key]);
    const { parameterDataSet, deviceProductId, deviceTypeIdent } = options;
    const startMsg = 'Starting cxE device parameterization based on';

    if (parameterDataSet) {
      // start device type and load device model dataset of passed cxedt JSON contents
      log?.info(
        `${startMsg} ParameterDataSet:`,
        `(size: ${parameterDataSet.length})`,
        this.getCxedpJsonHeaderDevice(parameterDataSet),
      );

      this.logger?.debug(` --> instantiateDeviceOfflineExternalFile ${deviceInstanceId}`);
      dispatch(instantiateDeviceOfflineExternalFile({
        methodId: this.LOAD_DATASET_BY_CXE,
        filename: 'clipxENGINEER.dataset.cxedt',
        content: parameterDataSet,
      }, deviceInstanceId));
    } else if (deviceProductId || deviceTypeIdent) {
      // start device type according to passed OrderCode or DeviceTypeIdent string
      let device: undefined | WebDevice.DeviceCatalogInformation;

      if (deviceTypeIdent) {
        log?.info(`${startMsg} DeviceTypeIdent:`, deviceTypeIdent);
        device = deviceList.find((dt) => `${dt.deviceDriverId}` === `${deviceTypeIdent}`);
        if (!device) {
          // no matching device driver found, display available deviceDriverIds
          log?.debug('available DeviceDriverIds:', deviceList.map((d) => d.deviceDriverId));
        }
      } else {
        log?.info(`${startMsg} DeviceProductId/ProductOrderNumber:`, deviceProductId);
        const devices = deviceList.filter((dt) => `${dt.productOrderNumber}` === `${deviceProductId}`);
        if (devices.length === 0) {
          // no matching device driver found, display available productOrderNumbers
          log?.debug('available ProductOrderNumbers:', deviceList.map((d) => d.productOrderNumber));
        } else {
          log?.debug('matching DeviceDriverIds:', devices.map((dt) => dt.deviceDriverId));
          device = this.selectBestDeviceDriver(devices);
        }
      }

      log?.debug(` --> instantiateDevice ${device?.deviceCatalogIdent}`);
      if (device) {
        dispatch(instantiateDevice({
          deviceInfo: { catalog: device },
          targetInstance: deviceInstanceId,
          wizardMode: false,
        }));
      } else {
        let errorMsg = 'Failed to start cxE device parameterization';
        const deviceType = deviceProductId
          ? `DeviceProductId ${deviceProductId}`
          : `DeviceTypeIdent ${deviceTypeIdent}`;
        errorMsg += `; no device driver found for ${deviceType}`;
        log?.warn(` --> FAILED: instantiateDevice ${errorMsg}`);
        this.closeCxeDpApp({ result: 'cancelled' });
        throw new Error(errorMsg);
      }
    } else {
      result = false;
    }

    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  private getCxedpJsonHeaderDevice = (json: string) => {
    let result;
    try {
      result = JSON.parse(json)?.header?.device;
    } catch (error) {
      result = `Parsing cxedp JSON failed: ${error}`;
    }
    return result;
  };

  private deviceDriverRegex = /^([\w-]*)-?([0-9a-f]{2})-([0-9a-f]{2})-([0-9a-f]{2})-([0-9a-f]{2})-([0-9a-f]{4})-([0-9a-f]+)_?([\w-]*)$/i;

  private selectBestDeviceDriver = (devices: WebDevice.DeviceCatalogInformation[]):
  WebDevice.DeviceCatalogInformation => {
    let relevantDevices = devices;
    // priorize S-Port over NFC
    const devicesNFC = devices.filter((dt) => dt.protocol === 'NFC-IFS');
    const devicesSPort = devices.filter((dt) => dt.protocol === 'S-PORT');
    if (devicesNFC.length > 0) { relevantDevices = devicesNFC; }
    if (devicesSPort.length > 0) { relevantDevices = devicesSPort; }
    // sort device drivers by its ConfigVersion
    const collator = new Intl.Collator();
    relevantDevices = relevantDevices.map((dt) => {
      const matches = this.deviceDriverRegex.exec(dt.deviceDriverId);
      return { ...dt, configVersion: matches?.[6] ?? '' };
    }).sort((a, b) => collator.compare(a.configVersion, b.configVersion));

    // select last device driver → with highest ConfigVersion
    const result = relevantDevices.slice(-1)[0];
    this.logger?.info(
      'Using DeviceDriverId:',
      result.deviceDriverId,

      `(${result.protocol} / ${result.deviceTypeName})`,
    );
    return result;
  };

  private async waitInstantiateDevice(deviceInstanceId: string, reduxStore: Store): Promise<void> {
    // actively wait (asynchronously poll) while instantiation of device model is pending
    let deviceExecutionState = ExecutionState.pending;
    this.logger?.debug(`waitInstantiateDevice ${deviceInstanceId}`);
    while (deviceExecutionState === ExecutionState.pending) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => { setTimeout(resolve, 100); });

      const deviceInstances = deviceInstancesStoreSelector(reduxStore.getState());
      deviceExecutionState = deviceInstances.instances[deviceInstanceId]?.activeDevice.executionState ?? ExecutionState.pending;
      this.logger?.debug(` --> waitInstantiateDevice ${deviceInstanceId} -> ${deviceExecutionState}`);
    }

    // check if "activeDevice.executionState" indicates failed device model instantiation
    if (deviceExecutionState === ExecutionState.failed) {
      let errorMsg = 'failed to instantiate device model';
      const deviceInstances = deviceInstancesStoreSelector(reduxStore.getState());
      // try to get more detailed information about failed deviceMethodExecution
      const deviceMethodExecution = deviceInstances.instances[deviceInstanceId]?.deviceMethodExecution ?? {};
      const methodWarning = deviceMethodExecution[this.LOAD_DATASET_BY_CXE]?.message;
      const methodStage = deviceMethodExecution[this.LOAD_DATASET_BY_CXE]?.stage;
      if (methodStage === MethodStageExecutionStatus.DoneFailed && methodWarning) {
        errorMsg = methodWarning;
      }
      this.logger?.warn(` --> FAILED: waitInstantiateDevice ${deviceInstanceId} -> ${errorMsg}`);
      throw new Error(errorMsg);
    }
  }

  private startSubApplication(options: ApplicationStartOptions) {
    const { subApplication } = options;
    const routePath = this.subAppRoutePaths.get(subApplication);
    if (routePath) {
      // navigate to routed view of according SubApplication
      this.logger?.debug(`started SubApplication "${subApplication}" → navigation to "#${routePath}"`);
      // const url = new URL(`#${routePath}`, window.location.href);
      window.location.hash = `#${routePath}`;
    } else {
      this.logger?.warn('there\'s no RoutePath for SubApplication', subApplication);
    }
  }

  public closeCxeDpApp(status: ApplicationCloseStatus): void {
    this.logger?.info(
      'close DeviceParameterization in cxE',
      { ...status, parameterDataSet: `[${status.parameterDataSet?.length}]` },
    );

    if (this.hostConnector) {
      const defaultStatus = { result: 'success', asset: this.appStartAsset };
      this.hostConnector.closeApplication({ ...defaultStatus, ...status });
      this.hostConnector = undefined;

      // display empty UI instead of StartupView (after closing wizard)
      window.location.hash = `#${RoutePaths.EmptyView}`;
    } else {
      this.logger?.warn('failed to close DeviceParameterization in cxE');
    }
  }
}

// eslint-disable-next-line max-len
export const initializeClipxEngineerIntegrationService = (reduxStore: Store): void => {
  const cxeis = new ClipxEngineerIntegrationService(reduxStore);
};

export const closeCxeDeviceParameterizationApp = (
  status: ApplicationCloseStatus,
): void => {
  ClipxEngineerIntegrationService.instance?.closeCxeDpApp(status);
};

export const importLocalFile = (options: ImportLocalFileOptions)
: Promise<ImportLocalFileResult> => {
  const hostConnector = ClipxEngineerIntegrationService?.instance.hostConnector;
  if (!hostConnector) return Promise.reject(new Error('cxe-dp-integration not available'));
  return hostConnector.importLocalFile(options);
};

export const exportLocalFile = (options: ExportLocalFileOptions)
: Promise<ExportLocalFileResult> => {
  const hostConnector = ClipxEngineerIntegrationService?.instance.hostConnector;
  if (!hostConnector) return Promise.reject(new Error('cxe-dp-integration not available'));
  return hostConnector.exportLocalFile(options);
};

export default ClipxEngineerIntegrationService;
