/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { IdentRef } from '@gpt/commons';
import { Action } from 'redux';

export const SET_GAUGE_INDICATOR = 'SET_GAUGE_INDICATOR';

export interface GaugeControlState {
    showIndicator: boolean;
}

export interface SetGaugeIndicatorPayload {
     indicator: boolean;
     ident: IdentRef;
}

export interface SetGaugeIndicatorAction extends Action<typeof SET_GAUGE_INDICATOR> {
    payload: SetGaugeIndicatorPayload;
}
