/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { LinearizationComponent } from '../../../components/LinearizationComponent/LinearizationComponent';
import { LoadingControl } from '../../../components/LoadingControl';
import { ExecutionState, useTypedSelector } from '../../../store';
import { SplineCalculationStatus } from '../../../store/linearizationDataset/types';
import LinearizationMessage from '../../../components/LinearizationMessage/LinearizationMessage';
import './LinearizationDialog.css';

export interface LinearizationDialogProps {
  label: string;
  onCancel: () => Promise<void>;
  onAccept: () => Promise<void>;
}

export const LinearizationDialog:React.FC<LinearizationDialogProps> = (props: LinearizationDialogProps): React.ReactElement => {
  const { onAccept, onCancel, label } = props;
  const executionState = useTypedSelector((state) => state.linearizationDataset.executionState);
  const isValidTable = useTypedSelector((state) => state.linearizationDataset.table.isValid);

  const splineCalcStatus = useTypedSelector((state) => state.linearizationDataset.splineCalculationState);
  const splineCalcMessage = useTypedSelector((state) => state.linearizationDataset.splineMessage);

  const linPositionRef = useRef<HTMLDivElement>(null);
  const messageBoxRef = useRef<HTMLDivElement>(null);

  const topPosition = linPositionRef?.current?.getClientRects()[0].top;
  const leftPosition = linPositionRef?.current?.getClientRects()[0].left;

  const { t } = useTranslation();

  let control: React.ReactElement;
  let cancelButtonDisabled = true;
  let acceptButtonDisabled = true;
  switch (executionState) {
    case ExecutionState.failed:
      control = <LoadingControl title={t<string>('FAILED')} />;
      cancelButtonDisabled = false;
      break;
    case ExecutionState.success:
      control = <LinearizationComponent />;
      cancelButtonDisabled = false;
      acceptButtonDisabled = !isValidTable || (splineCalcMessage?.type ?? 'success') === 'error';
      break;
    default:
      control = <LoadingControl title={t<string>('LOADING_DATA')} />;
      cancelButtonDisabled = false;
      break;
  }

  switch (splineCalcStatus) {
    case SplineCalculationStatus.done:
      // Do nothing
      break;
    case SplineCalculationStatus.inprogress:
      acceptButtonDisabled = true;
      break;
    case SplineCalculationStatus.error:
      acceptButtonDisabled = true;
      break;
    default:
      acceptButtonDisabled = true;
      break;
  }

  return (
    <div className="linearization-modal-dialog">
      <div className="linearization-modal-dialog__container">
        <div className="linearization-modal-dialog__container__header">
          <div className="linearization-modal-dialog__container__header__text">
            {label}
          </div>
          <div
            className="linearization-modal-dialog__container__header__messagebox"
            ref={messageBoxRef}
            style={{
              top: topPosition === undefined
                ? undefined
                : topPosition + 20,
              left: leftPosition === undefined
                ? undefined
                : leftPosition + 210,
            }}
          >
            <LinearizationMessage />
          </div>
        </div>
        <div className="linearization-modal-dialog__container__body">
          {control}
        </div>
        <div className="linearization-modal-dialog__container__buttons">
          <div className="linearization-modal-dialog__container__buttons__f">
            <Button className="button-text" variant="secondary" onClick={() => onCancel()} disabled={cancelButtonDisabled}>
              {t<string>('STANDARD_BUTTON__CANCEL')}
            </Button>
            <Button className="button-text" variant="primary" onClick={() => onAccept()} disabled={acceptButtonDisabled}>
              {t<string>('STANDARD_BUTTON__ACCEPT')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModalLinearizationDialog: React.FC<LinearizationDialogProps> = (props:LinearizationDialogProps) => {
  const { onAccept, onCancel, label } = props;
  const modalRoot = document.getElementById('modal-root');
  if (modalRoot === null) {
    return <div />;
  }
  return (
    <>
      {ReactDOM.createPortal(
        <LinearizationDialog label={label} onAccept={onAccept} onCancel={onCancel} />,
        modalRoot,
      )}
    </>
  );
};
