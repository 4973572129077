/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store';
import { ModalPasswordDialog } from '../../../modal/ModalPasswordDialog/ModalPasswordDialog';
import { ModalAcceptDataDialog } from '../../../modal/ModalAcceptDataDialog/ModalAcceptDataDialog';
import { AcceptDataListItem } from '../../../modal/ModalAcceptDataDialog/AcceptDataList/AcceptDataList';
import { executeMethodStagePassword, abortMethodExecution, executeMethodStageAcceptData } from '../../../store/deviceInstances/middleware/deviceMethod/actions';
import { MethodStageExecutionStatus } from '../../../store/deviceInstances/store/deviceMethod/types';

export interface DownloadMethodProps {
  methodIdent: string;
  deviceInstanceId: string;
}

const DownloadMethodControl
:React.FC<DownloadMethodProps> = (props: DownloadMethodProps): React.ReactElement => {
  const { methodIdent, deviceInstanceId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const stageStatus = useTypedSelector(
    (state) => {
      const deviceInstance = state.deviceInstances.instances[deviceInstanceId];
      return deviceInstance.deviceMethodExecution[methodIdent]?.stage
        ?? MethodStageExecutionStatus.Initialize;
    },
  );
  // eslint-disable-next-line max-len
  const acceptData = useTypedSelector(
    (state) => {
      const deviceInstance = state.deviceInstances.instances[deviceInstanceId];
      return deviceInstance.deviceMethodExecution[methodIdent]?.acceptData;
    },
  );

  let control = <div />;
  if (stageStatus === MethodStageExecutionStatus.RequestPassword) {
    control = (
      <ModalPasswordDialog
        onAccept={async (password: string) => {
          dispatch(executeMethodStagePassword(deviceInstanceId, {
            methodIdent,
            password,
          }));
        }}
        onCancel={async () => {
          dispatch(abortMethodExecution(deviceInstanceId, {
            methodIdent,
          }));
        }}
      />
    );
  } else if (stageStatus === MethodStageExecutionStatus.RequestAcceptData) {
    const data: AcceptDataListItem[] = (acceptData ?? [])
      .map((item) => {
        const itemValue = item.value ? t(`${item.value}`) : '';
        const itemUnit = item.unit ? t(`${item.unit}`) : '';
        return {
          acceptDataId: item.identRef,
          acceptDataLabel: t(item.label),
          acceptDataValueUnit: `${itemValue} ${itemUnit}`,
        };
      });

    control = (
      <ModalAcceptDataDialog
        acceptDataList={data}
        onAccept={async () => {
          dispatch(executeMethodStageAcceptData(deviceInstanceId, {
            methodIdent,
            accept: true,
          }));
        }}
        onCancel={async () => {
          dispatch(abortMethodExecution(deviceInstanceId, {
            methodIdent,
          }));
        }}
      />
    );
  }
  return <>{control}</>;
};

export default DownloadMethodControl;
