/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useCallback } from 'react';
import { Services } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { ReduxControlProps } from '../../ReduxControlProps';
import Quint4CharacteristicSelector from './CharacteristicSelector/Quint4CharacteristicSelector';
import {
  varFuseTriggerFactor, varFuseTriggerFactorCorrespond,
  varIoutCharacteristicsDynamicBoost, varIoutCharacteristicsPowerMode,
  varIoutCharacteristicsSFB, varIoutCharacteristicsStaticBoost, varTfuseMax,
} from './types';
import VariableReduxEditorControl from '../../../components/ParameterEditor/VariableEditorControl/VariableEditorReduxControl';
import { writeActiveDeviceVariableValues } from '../../../store';
import { useContextDatasetStatusValue } from '../../../hooks/useContextDataset';
import Quint4CheckboxControl from '../Quint4CheckboxControl/Quint4CheckboxControl';

const POWER_MODE__UI_ADVACED_MODE = 1;
const POWER_MODE__FUSE_MODE = 2;
const POWER_MODE__SMART_HICCUP = 4;

const Quint4Characteristics: React.FC<ReduxControlProps> = (props: ReduxControlProps)
  : React.ReactElement => {
  const { deviceInstanceId, onStateChanged } = props;
  const dispatch = useDispatch();

  const updateBlockItemState = onStateChanged;

  const ioutPowerModeValue = useContextDatasetStatusValue(deviceInstanceId, varIoutCharacteristicsPowerMode);
  const ioutStaticBoostValue = useContextDatasetStatusValue(deviceInstanceId, varIoutCharacteristicsStaticBoost);
  const ioutDynamicBoostValue = useContextDatasetStatusValue(deviceInstanceId, varIoutCharacteristicsDynamicBoost);
  const ioutSFBValue = useContextDatasetStatusValue(deviceInstanceId, varIoutCharacteristicsSFB);

  const ioutDynamicBoostReadonly = ioutPowerModeValue?.value === POWER_MODE__FUSE_MODE;
  const ioutSFBReadonly = ioutPowerModeValue?.value === POWER_MODE__FUSE_MODE || ioutPowerModeValue?.value === POWER_MODE__SMART_HICCUP;
  const fuseModeTrippingReadonly = ioutPowerModeValue?.value !== POWER_MODE__FUSE_MODE;

  const createIoutCharacteristics = useCallback((staticboost: boolean, dynamicBoost: boolean, sfb: boolean): Services.DeviceModel.StatusValueRef[] => {
    let result: Services.DeviceModel.StatusValueRef[] = [];
    result = ioutStaticBoostValue === undefined ? result : [
      ...result,
      {
        identRef: ioutStaticBoostValue.identRef,
        value: staticboost ? 1 : 0,
        backupValue: ioutStaticBoostValue?.backupValue,
      },
    ];
    result = ioutDynamicBoostValue === undefined ? result : [
      ...result,
      {
        identRef: ioutDynamicBoostValue.identRef,
        value: dynamicBoost ? 1 : 0,
        backupValue: ioutDynamicBoostValue?.backupValue,
      },
    ];
    result = ioutSFBValue === undefined ? result : [
      ...result,
      {
        identRef: ioutSFBValue.identRef,
        value: sfb ? 1 : 0,
        backupValue: ioutSFBValue?.backupValue,
      },
    ];
    return result;
  }, [ioutStaticBoostValue, ioutDynamicBoostValue, ioutSFBValue]);

  return (
    <div className="quint4-digital-signal">
      <Quint4CharacteristicSelector
        deviceInstanceId={deviceInstanceId}
        statusValue={ioutPowerModeValue}
        showContextMessageDef
        onStateChanged={updateBlockItemState}
        onValueChange={(value) => {
          let variableList: Services.DeviceModel.StatusValueRef[] = [];
          switch (value) {
            case POWER_MODE__UI_ADVACED_MODE:
              variableList = createIoutCharacteristics(true, true, true);
              break;
            case POWER_MODE__FUSE_MODE:
              variableList = createIoutCharacteristics(true, false, false);
              break;
            case POWER_MODE__SMART_HICCUP:
              variableList = createIoutCharacteristics(true, true, false);
              break;
            default:
          }
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [
            ...variableList,
            {
              identRef: varIoutCharacteristicsPowerMode,
              value,
              backupValue: ioutPowerModeValue?.backupValue,
            },
          ]));
        }}
      />
      <Quint4CheckboxControl
        deviceInstanceId={deviceInstanceId}
        identRef={varIoutCharacteristicsStaticBoost}
        onStateChanged={updateBlockItemState}
        onCustomValueChange={(value) => {
          const variableList = value
            ? [{
              identRef: varIoutCharacteristicsStaticBoost,
              value: 1,
              backupValue: ioutStaticBoostValue?.backupValue,
            }]
            : createIoutCharacteristics(false, false, false);
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, variableList));
        }}
      />
      <Quint4CheckboxControl
        deviceInstanceId={deviceInstanceId}
        identRef={varIoutCharacteristicsDynamicBoost}
        onStateChanged={updateBlockItemState}
        readonly={ioutDynamicBoostReadonly}
        onCustomValueChange={(value) => {
          const variableList = value
            ? [{
              identRef: varIoutCharacteristicsStaticBoost,
              value: 1,
              backupValue: ioutStaticBoostValue?.backupValue,
            },
            {
              identRef: varIoutCharacteristicsDynamicBoost,
              value: 1,
              backupValue: ioutStaticBoostValue?.backupValue,
            }]
            : [
              {
                identRef: varIoutCharacteristicsDynamicBoost,
                value: 0,
                backupValue: ioutStaticBoostValue?.backupValue,
              },
              {
                identRef: varIoutCharacteristicsSFB,
                value: 0,
                backupValue: ioutSFBValue?.backupValue,
              },
            ];
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, variableList));
        }}
      />
      <Quint4CheckboxControl
        deviceInstanceId={deviceInstanceId}
        identRef={varIoutCharacteristicsSFB}
        onStateChanged={updateBlockItemState}
        readonly={ioutSFBReadonly}
        onCustomValueChange={(value) => {
          const variableList = [{
            identRef: varIoutCharacteristicsSFB,
            value: value ? 1 : 0,
            backupValue: ioutSFBValue?.backupValue,
          }];
          dispatch(writeActiveDeviceVariableValues(deviceInstanceId, variableList));
        }}
      />
      <VariableReduxEditorControl
        deviceInstanceId={deviceInstanceId}
        identRef={varTfuseMax}
        onStateChanged={updateBlockItemState}
        readonly={fuseModeTrippingReadonly}
      />
      <VariableReduxEditorControl
        deviceInstanceId={deviceInstanceId}
        identRef={varFuseTriggerFactor}
        onStateChanged={updateBlockItemState}
        readonly={fuseModeTrippingReadonly}
      />
      <VariableReduxEditorControl
        deviceInstanceId={deviceInstanceId}
        identRef={varFuseTriggerFactorCorrespond}
        onStateChanged={updateBlockItemState}
        readonly
      />
    </div>
  );
};

export default Quint4Characteristics;
