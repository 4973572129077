/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

export const WIZARD__CXE_EDIT_DEVICE_PARAM__ID = 'WIZARD__CXE_EDIT_DEVICE_PARAM__ID';
export const WIZARD__CXE_TRANSFER_DEVICE_PARAM__ID = 'WIZARD__CXE_TRANSFER_DEVICE_PARAM__ID';
