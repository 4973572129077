import { calcDeviationFunctions, fncCalcPointsDeviation, SplineFn } from './deviation';
import { LinearizationTable, LinearizationTableItem } from '../types';

/* eslint-disable max-len */
const isLinXYPointValid = (
  value: number,
  prevValue?: number,
  nextValue?: number,
) : boolean => {
  if (prevValue === undefined || nextValue === undefined) {
    return true;
  }

  // min and max on x or y axes
  const min = prevValue < nextValue ? prevValue : nextValue;
  const max = prevValue > nextValue ? prevValue : nextValue;

  if (value > min && value < max) {
    return true;
  }

  return false;
};

const isLinTableDatasetValid = (linData: LinearizationTableItem[], pointsCounter: number): boolean => {
  const invalidItem = linData
    .filter((item, index) => index < pointsCounter - 1)
    .find((item) => !(item.isXValid && item.isYValid));
  return invalidItem === undefined;
};

export const updateLinearizationTableValidity = (table: LinearizationTable, pointsCounter: number): LinearizationTable => {
  const lastIndex = pointsCounter > table.linearizationData.length ? table.linearizationData.length - 1 : pointsCounter - 1;

  const linearizationData = table.linearizationData.map((item, index) => {
    const prevPoint = index > 0 ? table.linearizationData[index - 1] : undefined;
    const nextPoint = index < lastIndex ? table.linearizationData[index + 1] : undefined;
    return {
      ...item,
      isXValid: isLinXYPointValid(item.xValue, prevPoint?.xValue, nextPoint?.xValue),
      isYValid: isLinXYPointValid(item.yValue, prevPoint?.yValue, nextPoint?.yValue),
    };
  });
  const isValid = isLinTableDatasetValid(linearizationData, pointsCounter);
  return {
    ...table,
    linearizationData,
    isValid,
  };
};

export const recalcDeviationFunctions = (tableLinData: LinearizationTableItem[], pointsCounter: number, maxRegError: number): SplineFn[] => {
  const pts = tableLinData.filter((data, index) => index < pointsCounter);
  return calcDeviationFunctions(maxRegError, pts.map((p) => p.yValue), pts.map((p) => p.xValue), pointsCounter);
};

export const calculatePointDeviation = (tableLinData: LinearizationTableItem[], splineFn: SplineFn[], pointsCounter: number): number[] => {
  const pts = tableLinData.filter((data, index) => index < pointsCounter);
  const devPoints = fncCalcPointsDeviation(splineFn, pts);
  return tableLinData.map((item, idx) => (idx < devPoints.length ? devPoints[idx][1] : 0));
};
