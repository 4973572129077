/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { UnsupportedControl } from '../../../components/UnsupportedControl';
import { useTypedSelector } from '../../../store';
import { CompareStatusValueProps } from '../CompareTypes';
import { deviceDescriptorSelector } from '../../../store/deviceInstances/store/deviceDataset/selector';
import compareControlMap from './compareControls';

/**
 * Compare two values from Dataset Value : value vs externalValue
 * backupValue used as storage for value before modification
 *
 * @param props Control Properties
 * @returns
 */
const CompareControl
: React.FC<CompareStatusValueProps> = (props: CompareStatusValueProps)
  : React.ReactElement => {
  const {
    parentId,
    identRef,
    leftDataset,
    rightDataset,
    compareMode,
    onShowContentHelp, onHideContentHelp,
    deviceInstanceId,
  } = props;

  const valueType = useTypedSelector(
    (state) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, leftDataset, identRef),
  );

  let control;
  let visibility = true;
  if (valueType?.type === DeviceModelStatus.StatusType.StatusDescriptor) {
    control = 'StatusValue';
    visibility = valueType.visibility;
  } else if (valueType?.type === DeviceModelStatus.StatusType.ControlDescriptor) {
    control = valueType.controlType.type;
  }

  if (!visibility) {
    return <div />;
  }

  if (control === undefined || compareControlMap[control] === undefined) {
    return <UnsupportedControl text={`CompareStatusValue: unsupported control type [${control ?? 'undefined'}] / [${identRef}]`} />;
  }

  const C = compareControlMap[control];
  return (
    <C
      parentId={parentId}
      identRef={identRef}
      deviceInstanceId={deviceInstanceId}
      leftDataset={leftDataset}
      rightDataset={rightDataset}
      onHideContentHelp={onHideContentHelp}
      onShowContentHelp={onShowContentHelp}
      compareMode={compareMode}
    />
  );
};

export default CompareControl;
