/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from '../../../../../../components/ProgressBar/ProgressBar';
import SelectProcessCheckbox from '../SelectProcessCheckbox/SelectProcessCheckbox';
import { useTypedSelector } from '../../../../../../store';
import './DeviceProcessData.scss';
import { ProcessDataInputOutput } from '../ProcessDataInputOutput/ProcessDataInputOutput';
import { contactronWizardSetAllData } from '../../../../../../store/wizards/contactronStation';
import { showApplicationMessage } from '../../../../../../store/applicationMessage/actions';
import { isStationMaxReached, MAX_SELECTED_MODULE_INPUTS_OUTPUTS } from '../Helpers/processDataHelpers';

const convertToPercentage = (selectedCount: number): number => (selectedCount / MAX_SELECTED_MODULE_INPUTS_OUTPUTS) * 100;

export interface DeviceProcessDataProps {
  moduleDesc: DeviceModelStatus.Wizards.ContactronStation.StationDeviceType;
}

export const DeviceProcessData: React.FC<DeviceProcessDataProps> = (props: DeviceProcessDataProps):
React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { moduleDesc } = props;
  const applyAllData = useTypedSelector((state) => state.contactronInitWizard.applyAllData);
  const moduleInputSelected = useTypedSelector((state) => {
    const mod = state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule];
    return mod.input.totalSelected;
  });
  const moduleOutputSelected = useTypedSelector((state) => {
    const mod = state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule];
    return mod.output.totalSelected;
  });
  const percent = convertToPercentage(moduleInputSelected + moduleOutputSelected);

  const moduleCount = useTypedSelector((state) => Object.keys(state.contactronInitWizard.modules).length - 1);
  const address = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].instance.address);

  return (
    <div className="device-process-data-container">
      <div className="device-process-data-container__header">
        <div>
          <strong>{t('WIZARD_CONTACTRON__PAGE5_SELECT_DEVICE_PROCESS_DATA')}</strong>
          {' '}
          {`(max. ${MAX_SELECTED_MODULE_INPUTS_OUTPUTS})`}
        </div>
      </div>
      <div className="device-process-data-container__device_progress">
        {t('WIZARD_CONTACTRON__PAGE5_PROCESS_DATA_PER_DEVICE')}
        {' '}
        {moduleInputSelected + moduleOutputSelected}
        {' '}
        {`/ ${MAX_SELECTED_MODULE_INPUTS_OUTPUTS}`}
        <div className="device-process-data-container__device_progress-bar">
          <ProgressBar percent={percent} />
        </div>
      </div>
      <div className="device-process-data-container__select">
        <ProcessDataInputOutput moduleDescription={moduleDesc} />
      </div>
      <div className="device-process-data-container__apply-for-all-devices">
        <SelectProcessCheckbox
          checked={applyAllData}
          label={t('WIZARD_CONTACTRON__PAGE5_APPLY_PROCESS_DATA_FOR_ALL')}
          onChange={(value) => {
            let setAllData = true;
            if (value === true) {
              const msg = isStationMaxReached(moduleInputSelected, moduleOutputSelected, moduleCount);
              if (msg !== undefined) {
                const text = t(msg.message, { MAX_NUMBER: msg.arg });
                dispatch(showApplicationMessage('warning', text));
                setAllData = false;
              }
            }
            if (setAllData) {
              dispatch(contactronWizardSetAllData({ address, value }));
            }
          }}
        />
      </div>
    </div>
  );
};
