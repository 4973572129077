/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import ColdJunctionLineCompensationBlock from './ColdJunctionLineCompensationBlock/ColdJunctionLineCompensationBlock';
import ColdJunctionLayout from './ColdJunctionLayout/ColdJunctionLayout';
import './ColdJunctionLineCompensation.scss';

export interface ColdJunctionLineCompensationProps {
  deviceInstanceId: string;
  title: string
  block: DeviceModelStatus.UI.SPort.ColdJunctionLineCompensationBlock[];
}

const ColdJunctionLineCompensation: React.FC<ColdJunctionLineCompensationProps> = (props: ColdJunctionLineCompensationProps)
: React.ReactElement | null => {
  const { deviceInstanceId, title: label, block } = props;
  const { t } = useTranslation();

  const blockItems = block.map((bl) => (
    <ColdJunctionLineCompensationBlock
      deviceInstanceId={deviceInstanceId}
      block={bl}
      key={`cold-junction-line-cmp--${deviceInstanceId}-${bl.variable}`}
    />
  ));

  return (
    <div className="cold-junction-line-cmp">
      <div className="cold-junction-line-cmp-text">{t(label)}</div>
      <ColdJunctionLayout>
        {blockItems}
      </ColdJunctionLayout>
    </div>
  );
};

export default ColdJunctionLineCompensation;
