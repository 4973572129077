/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { EventMonitorTemplate } from '../EventMonitorTemplate/EventMonitorTemplate';
import IconError from '../../../assets/icons/icon-error.svg';
import IconWarning from '../../../assets/icons/icon-warning.svg';
import IconMessage from '../../../assets/icons/icon-message.svg';
import IconAppear from '../../../assets/icons/icon-arrow-down-circle-red.svg';
import IconDisappear from '../../../assets/icons/icon-arrow-down-circle-green.svg';
import IconSingleshot from '../../../assets/icons/icon-arrow-down-circle-blue.svg';
import './EventMonitorList.css';

export interface EventMonitorListItem {
  eventId: string,
  eventMessage: string,
  eventMode: string,
  eventType: string,
  eventTimestamp: string,
}

export interface EventMonitorListProps {
  eventList: EventMonitorListItem[];
}

const pad2 = (num: number): string => `0${num}`.slice(-2);
// format timestamp from milliseconds into "yyyy-mm-dd / hh:mm:ss"
export const formatTimestamp = (ts) => {
  const d = new Date(ts);
  // return (d.toISOString().split('.')[0]).split('T');
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();

  return [`${year}-${pad2(month)}-${pad2(day)}`, `${pad2(hours)}:${pad2(minutes)}:${pad2(seconds)}`];
};

// eslint-disable-next-line max-len
export const EventMonitorList: React.FC<EventMonitorListProps> = (props: EventMonitorListProps): React.ReactElement => {
  const { eventList } = props;

  const eventMonitorListItems = eventList.map((item) => {
    const {
      eventId, eventMessage, eventMode, eventType, eventTimestamp,
    } = item;

    let messageIcon = IconError;
    switch (eventType) {
      case 'error':
        messageIcon = IconError;
        break;
      case 'warning':
        messageIcon = IconWarning;
        break;
      case 'message':
        messageIcon = IconMessage;
        break;
      default:
        messageIcon = IconError;
    }

    let modeIcon = IconAppear;
    switch (eventMode) {
      case 'appears':
        modeIcon = IconAppear;
        break;
      case 'disappears':
        modeIcon = IconDisappear;
        break;
      case 'singleshot':
        modeIcon = IconSingleshot;
        break;
      default:
        modeIcon = IconAppear;
    }

    const milliseconds = Date.parse(eventTimestamp);
    const timestamp = new Date(milliseconds);

    const eventFormattedTimestamp = formatTimestamp(timestamp);

    return (
      <EventMonitorTemplate key={eventId}>
        <div className="event-monitor-list__message">
          <div className="event-monitor-list__message-icon">
            <img src={messageIcon} alt={eventType} />
          </div>
          {eventMessage}
        </div>
        <div className="event-monitor-list__mode">
          <div className="event-monitor-list__mode-icon">
            <img src={modeIcon} alt={eventMode} />
          </div>
          {eventMode}
        </div>
        <div>
          {eventFormattedTimestamp[0]}
          &nbsp;
          /
          &nbsp;
          {eventFormattedTimestamp[1]}
        </div>
      </EventMonitorTemplate>
    );
  });

  return (
    <div className="event-monitor-list">
      {eventMonitorListItems}
    </div>
  );
};
