/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */

import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DatasetType } from '../../../store/deviceInstances/store/deviceDataset/types';
import { useContextDatasetDescriptor } from '../../../hooks/useContextDataset';
import { UnsupportedControl } from '../../../components/UnsupportedControl';
import CompareGroupValues from './CompareGroupValues/CompareGroupValues';
import CompareControl from '../Controls/CompareControl';
import { CompareModeType } from '../../../views/CompareView/common';

export interface CompareGroupStatusValuesProps {
  deviceInstanceId: string;
  compareMode: CompareModeType;
  compareMenuIdent: IdentRef;
  leftDatasetType: DatasetType;
  rightDatasetType: DatasetType;
}

const CompareGroupStatusValues
: React.FC<CompareGroupStatusValuesProps> = (props: CompareGroupStatusValuesProps)
: React.ReactElement => {
  const {
    compareMode,
    compareMenuIdent,
    leftDatasetType,
    rightDatasetType,
    deviceInstanceId,
  } = props;
  const { t } = useTranslation();
  const menuDescriptor = useContextDatasetDescriptor(deviceInstanceId, compareMenuIdent, leftDatasetType);

  if (menuDescriptor?.type !== DeviceModelStatus.StatusType.StructureDescriptor) {
    return <UnsupportedControl text="CompareGroupStatusValues: Only structure is supported" />;
  }

  const groupElements = menuDescriptor.elements.map((element) => (
    <CompareControl
      key={`cmp-group--${compareMenuIdent}--${element.identRef}`}
      deviceInstanceId={deviceInstanceId}
      parentId={compareMenuIdent}
      identRef={element.identRef}
      leftDataset={leftDatasetType}
      rightDataset={rightDatasetType}
      compareMode={compareMode}
    />
  ));

  return (
    <CompareGroupValues title={t<string>(menuDescriptor.label)}>
      {groupElements}
    </CompareGroupValues>
  );
};

export default CompareGroupStatusValues;
