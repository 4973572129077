/* eslint-disable @typescript-eslint/no-empty-function */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import './EventMonitorTrioList.css';
import { Trio3ListItem } from './Trio3ListItem/Trio3ListItem';
import { Trio3EventList, Trio3EventState } from '../../../store/deviceInstances/store/ioLinkTrio3EventMonitor/types';

export interface EventMonitorTrioListProps {
  eventList?: Trio3EventList;
  targetInstance: string;
}

// eslint-disable-next-line max-len
export const EventMonitorTrioList: React.FC<EventMonitorTrioListProps> = (props: EventMonitorTrioListProps): React.ReactElement => {
  const { eventList, targetInstance } = props;

  const evList = eventList ?? {};
  const eventMonitorTrioListItems = Object
    .keys(evList)
    .reduce((acc, key) => {
      const {
        id, event, state, timestamp,
      } = evList[key];

      if (state === Trio3EventState.inactive) {
        return acc;
      }

      return [
        ...acc,
        <Trio3ListItem
          key={id}
          id={id}
          state={state}
          timestamp={timestamp}
          event={event}
          targetInstance={targetInstance}
        />,
      ];
    }, [] as React.ReactElement[]);

  return (
    <div className="trio3-event-monitor-list">
      {eventMonitorTrioListItems}
    </div>
  );
};
