/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { UnsupportedControl } from '../../../../../components/UnsupportedControl';
import DisplayFormat from '../../../../../helpers/displayFormat';
import { useContextDatasetDescriptor, useContextDatasetStatusValue } from '../../../../../hooks/useContextDataset';
import { CompareModeType } from '../../../../../views/CompareView/common';
import { CompareStatusValueProps } from '../../../CompareTypes';
import useCompareMiddleButton from '../../../hooks/useCompareMiddleButton';
import useDispatchFloatValue from '../../../hooks/useDispatchFloatValue';
import useVariableValidationMessage from '../../../hooks/useVariableValidationMessage';
import CompareFloatValue from '../../StatusValues/CompareFloatStatusValue/CompareFloatValue/CompareFloatValue';
import CompareStringValue from '../../StatusValues/CompareStringStatusValue/CompareStringValue/CompareStringValue';

export interface CompareRangeStatusValueProps extends CompareStatusValueProps {
  hideLeftControl: boolean;
  hideRightControl: boolean;
}

/**
 * Compare two values from Dataset Value : value vs externalValue
 * backupValue used as storage for value before modification
 *
 * @param props Control Properties
 * @returns
 */
const CompareRangeStatusValue
: React.FC<CompareRangeStatusValueProps> = (props: CompareRangeStatusValueProps)
  : React.ReactElement | null => {
  const {
    parentId,
    compareMode,
    deviceInstanceId,
    identRef, leftDataset, rightDataset,
    onShowContentHelp, onHideContentHelp,
    onMidButtonClick,
    showImageAndSign,
    hideLeftControl,
    hideRightControl,
  } = props;
  const { t } = useTranslation();

  const leftDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef, leftDataset);
  const leftValue = useContextDatasetStatusValue(deviceInstanceId, identRef, leftDataset);

  const rightDescriptor = useContextDatasetDescriptor(deviceInstanceId, identRef, rightDataset);
  const rightValue = useContextDatasetStatusValue(deviceInstanceId, identRef, rightDataset);

  const dispatchFloatValue = useDispatchFloatValue(deviceInstanceId, leftDataset);
  const message = useVariableValidationMessage(leftDescriptor, leftValue);
  const [buttonFunction, onHookMiddleButtonClick] = useCompareMiddleButton(deviceInstanceId, leftValue, rightValue);

  if (leftDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareFloatStatusValue: Unsupported type" />;
  }

  if (rightDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return <UnsupportedControl text="CompareFloatStatusValue: Unsupported type" />;
  }

  if (leftDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
    return <UnsupportedControl text={`CompareFloatStatusValue: only FLOAT is supported !${leftDescriptor.valueType.type}`} />;
  }

  if (rightDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
    return <UnsupportedControl text={`CompareFloatStatusValue: only FLOAT is supported !${rightDescriptor.valueType.type}`} />;
  }

  const leftUnit = leftDescriptor.unit === undefined ? undefined : t<string>(leftDescriptor.unit);
  const rightUnit = rightDescriptor.unit === undefined ? undefined : t<string>(rightDescriptor.unit);
  const equal = leftValue?.value === rightValue?.value && rightUnit === leftUnit;

  if (compareMode === CompareModeType.ShowDifference && equal) {
    return (null);
  }

  const hideLfControl = (leftDescriptor.visibility === false || hideLeftControl);
  const hideRtControl = (rightDescriptor.visibility === false || hideRightControl);

  if (compareMode === CompareModeType.ShowAll) {
    const leftStringValue = DisplayFormat(leftDescriptor.valueType.displayFormat ?? '', leftValue?.value);
    const rightStringValue = DisplayFormat(rightDescriptor.valueType.displayFormat ?? '', rightValue?.value);
    return (
      <CompareStringValue
        key={`cmp-float-${parentId}-${leftDescriptor.identRef}-${rightDescriptor.identRef}`}
        label={t(leftDescriptor.label)}
        help={t<string>(leftDescriptor.help)}
        equal={equal}
        valueLeft={leftStringValue}
        valueLeftUnit={leftUnit}
        hideLeftControl={hideLfControl}
        valueRight={rightStringValue}
        valueRightUnit={rightUnit}
        hideRightControl={hideRtControl}
        onButtonClick={() => {
          if (onMidButtonClick === undefined) {
            onHookMiddleButtonClick(buttonFunction);
          } else {
            onMidButtonClick(buttonFunction);
          }
        }}
        modified={leftValue?.modified ?? false}
        readonly
        warning={message}
        onHideContentHelp={onHideContentHelp}
        onShowContentHelp={onShowContentHelp}
        buttonFunction={buttonFunction}
        showImageAndSign={showImageAndSign}
      />
    );
  }

  return (
    <CompareFloatValue
      key={`cmp-float-${parentId}-${leftDescriptor.identRef}-${rightDescriptor.identRef}`}
      label={t(leftDescriptor.label)}
      help={t<string>(leftDescriptor.help)}
      equal={equal}
      valueLeft={leftValue?.value}
      valueLeftDisplayFormat={leftDescriptor.valueType.displayFormat}
      valueLeftUnit={leftUnit}
      hideLeftControl={hideLfControl}
      valueRight={rightValue?.value}
      valueRightUnit={rightUnit}
      valueRightDisplayFormat={rightDescriptor.valueType.displayFormat}
      hideRightControl={hideRtControl}
      onButtonClick={() => {
        if (onMidButtonClick === undefined) {
          onHookMiddleButtonClick(buttonFunction);
        } else {
          onMidButtonClick(buttonFunction);
        }
      }}
      modified={leftValue?.modified ?? false}
      readonly={leftDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY}
      onLeftValueChange={(ev) => {
        if (compareMode === CompareModeType.AllParamsEditable) {
          dispatchFloatValue(ev, leftValue);
        }
      }}
      warning={message}
      stepSize={leftDescriptor.valueType.stepSize}
      onHideContentHelp={onHideContentHelp}
      onShowContentHelp={onShowContentHelp}
      buttonFunction={buttonFunction}
      showImageAndSign={showImageAndSign}
    />
  );
};

export default CompareRangeStatusValue;
