/* eslint-disable no-bitwise */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useCallback } from 'react';
import './OnlineCaparocBlockCheckbox.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import OnlineCaparocCheckbox from './OnlineCaparocCheckbox/OnlineCaparocCheckbox';
import useIoLinkWriteParameter from '../../../../IoLink/hooks/useIoLinkWriteParameter';
import { MethodStageExecutionStatus } from '../../../../../store/deviceInstances/store/deviceMethod/types';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { useContextDatasetStatusDescriptor, useContextDatasetStatusValue } from '../../../../../hooks/useContextDataset';
import { UnsupportedControl } from '../../../../../components/UnsupportedControl';
import { updateDeviceDatasetValues } from '../../../../../store/deviceInstances/store/deviceDataset';

const varDataStorage = 'var_V_DeviceAccessLocks__DataStorage';
const varLocalParameterization = 'var_V_DeviceAccessLocks__LocalParameterization';
const varLocalUserInterface = 'var_V_DeviceAccessLocks__LocalUserInterface';

export interface OnlineCaparocBlockCheckboxProps {
  deviceInstanceId: string;
}

const OnlineCaparocBlockCheckbox: React.FC<OnlineCaparocBlockCheckboxProps> = (props: OnlineCaparocBlockCheckboxProps):
React.ReactElement => {
  const {
    deviceInstanceId,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [executionStage, writeIoLinkParameter] = useIoLinkWriteParameter(deviceInstanceId, 'ONLINE_CAPAAROC_BLOCK_CHECKBOX');

  const dataStorageStatusDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, varDataStorage, DatasetType.device);
  const dataStorageStatusValue = useContextDatasetStatusValue(deviceInstanceId, varDataStorage, DatasetType.device);

  const localParameterizationStatusDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, varLocalParameterization, DatasetType.device);
  const localParameterizationStatusValue = useContextDatasetStatusValue(deviceInstanceId, varLocalParameterization, DatasetType.device);

  const localUserInterfaceStatusDescriptor = useContextDatasetStatusDescriptor(deviceInstanceId, varLocalUserInterface, DatasetType.device);
  const localUserInterfaceStatusValue = useContextDatasetStatusValue(deviceInstanceId, varLocalUserInterface, DatasetType.device);

  const checkboxesDisabled = executionStage === MethodStageExecutionStatus.InProgress;

  const onChange = useCallback((val1: number, val2: number, val3: number): void => {
    let result = 0;

    if (val1 === 1) {
      result |= 1 << 1;
    }

    if (val2 === 1) {
      result |= 1 << 2;
    }

    if (val3 === 1) {
      result |= 1 << 3;
    }

    writeIoLinkParameter(12, new Uint8Array([0x00, result]));
  }, [writeIoLinkParameter]);

  if (dataStorageStatusDescriptor === undefined || dataStorageStatusValue === undefined
      || localParameterizationStatusDescriptor === undefined || localParameterizationStatusValue === undefined
      || localUserInterfaceStatusDescriptor === undefined || localUserInterfaceStatusValue === undefined) {
    return <UnsupportedControl text="OnlineCaparocBlockCheckbox: Undefined variable or value" />;
  }

  return (
    <div className="online-caparoc-block-checkbox">
      <OnlineCaparocCheckbox
        identRef={varDataStorage}
        value={dataStorageStatusValue.value}
        label={t(dataStorageStatusDescriptor.label)}
        help={t(dataStorageStatusDescriptor.help)}
        disable={checkboxesDisabled}
        onCheckboxChange={(value) => {
          // send new value to dataset, while waiting for new data
          dispatch(updateDeviceDatasetValues(deviceInstanceId, {
            targetDataset: DatasetType.device,
            values: [{
              value,
              identRef: varDataStorage,
              backupValue: undefined,
            }],
          }));
          onChange(value, localParameterizationStatusValue.value, localUserInterfaceStatusValue.value);
        }}
      />
      <OnlineCaparocCheckbox
        identRef={varLocalParameterization}
        value={localParameterizationStatusValue.value}
        label={t(localParameterizationStatusDescriptor.label)}
        help={t(localParameterizationStatusDescriptor.help)}
        disable={checkboxesDisabled}
        onCheckboxChange={(value) => {
          // send new value to dataset, while waiting for new data
          dispatch(updateDeviceDatasetValues(deviceInstanceId, {
            targetDataset: DatasetType.device,
            values: [{
              value,
              identRef: varLocalParameterization,
              backupValue: undefined,
            }],
          }));
          onChange(dataStorageStatusValue.value, value, localUserInterfaceStatusValue.value);
        }}
      />
      <OnlineCaparocCheckbox
        identRef={varLocalUserInterface}
        value={localUserInterfaceStatusValue.value}
        label={t(localUserInterfaceStatusDescriptor.label)}
        help={t(localUserInterfaceStatusDescriptor.help)}
        disable={checkboxesDisabled}
        onCheckboxChange={(value) => {
          // send new value to dataset, while waiting for new data
          dispatch(updateDeviceDatasetValues(deviceInstanceId, {
            targetDataset: DatasetType.device,
            values: [{
              value,
              identRef: varLocalUserInterface,
              backupValue: undefined,
            }],
          }));
          onChange(dataStorageStatusValue.value, localParameterizationStatusValue.value, value);
        }}
      />
    </div>
  );
};

export default OnlineCaparocBlockCheckbox;
