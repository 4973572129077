/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { DatasetState } from '../../store/deviceInstances/store/deviceDataset/types';
import DisplayFormat from '../../helpers/displayFormat';
import { roundValue } from '../../helpers/precisionValue';

const displayStatusValueSelector = (deviceDataset: DatasetState, identRef: IdentRef) => {
  const statusDescriptor = deviceDataset.descriptors[identRef];
  const statusValue = deviceDataset.values[identRef];
  const { t } = useTranslation();

  const defaultValue = 'N/A';
  if (statusDescriptor === undefined || statusValue === undefined) {
    return defaultValue;
  }

  if (statusDescriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return defaultValue;
  }

  let variableValue = '';
  const { valueType } = statusDescriptor;
  switch (valueType.type) {
    case DeviceModelStatus.StatusDescriptorValueType.FLOAT: {
      const parsedValue = Number.parseFloat(`${statusValue.value}`);
      if (Number.isNaN(parsedValue)) {
        variableValue = '';
      } else if (valueType.precision) {
        variableValue = `${roundValue(parsedValue, valueType.precision, 0)}`;
      } else {
        variableValue = DisplayFormat(valueType.displayFormat ?? '%.2f', statusValue.value);
      }
    }
      break;
    case DeviceModelStatus.StatusDescriptorValueType.ENUMERATOR: {
      const enumValue = valueType as DeviceModelStatus.StatusDescriptorValueTypeEnumerator;
      const value = enumValue.enumerator.find((entry) => entry.value === statusValue.value);
      variableValue = value !== undefined ? t(value.label) : '';
    }
      break;
    case DeviceModelStatus.StatusDescriptorValueType.INTEGER:
    case DeviceModelStatus.StatusDescriptorValueType.UNSIGNED_INTEGER:
      if (statusValue.value === undefined) {
        variableValue = '';
        break;
      }
      variableValue = valueType.displayFormat === undefined
        ? (statusValue.value ?? '')
        : DisplayFormat(valueType.displayFormat, statusValue.value);
      break;
    case DeviceModelStatus.StatusDescriptorValueType.STRING:
    case DeviceModelStatus.StatusDescriptorValueType.DATETIME:
    case DeviceModelStatus.StatusDescriptorValueType.DATE:
    case DeviceModelStatus.StatusDescriptorValueType.IPV4_ADDRESS:
    case DeviceModelStatus.StatusDescriptorValueType.MAC_ADDRESS:
      variableValue = statusValue.value ?? '';
      break;
    case DeviceModelStatus.StatusDescriptorValueType.TABLE:
      variableValue = 'table';
      break;
    default:
      variableValue = '';
  }

  return variableValue;
};

export default displayStatusValueSelector;
