/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import React from 'react';
import { gaugeWidth } from '../common';

export interface SVGGaugeTitleProps {
  title: string,
  subtitle: string,
}

const SVGGaugeTitle: React.FC<SVGGaugeTitleProps> = (props: SVGGaugeTitleProps)
: React.ReactElement => {
  const { title, subtitle } = props;

  const sanTitle = title.slice(0, 18);
  const sanSubtitle = subtitle.slice(0, 33);

  return (
    <g>
      <text x={gaugeWidth / 2} y={30} clipPath="url(#clip-title)" fontSize={36} fontWeight="bold" textAnchor="middle" fill="black">{sanTitle}</text>
      <text x={gaugeWidth / 2} y={62} fontSize={24} fontWeight="normal" textAnchor="middle" fill="black">{sanSubtitle}</text>
      <clipPath id="clip-title">
        <rect x={60} y={2} width={gaugeWidth - 130} height={36} strokeWidth={1} fill="red" fillOpacity={0.2} />
      </clipPath>
    </g>
  );
};

export default SVGGaugeTitle;
