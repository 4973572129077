/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import ButtonWithDescriptionControl from './ButtonWithDescriptionControl/ButtonWithDescriptionControl';
import './CommandBlock.scss';

export interface CommandBlockProps {
    block: DeviceModelStatus.UI.SPort.CommandBlockControlType;
    deviceInstanceId: string;
}

const CommandBlock: React.FC<CommandBlockProps> = (props: CommandBlockProps):
  React.ReactElement => {
  const {
    block,
    deviceInstanceId,
  } = props;

  const { restartDevice, acknowledgeRelayOutput } = block;

  const restartDev = restartDevice ? (
    <ButtonWithDescriptionControl
      title={restartDevice.label}
      text={restartDevice.subLabel}
      buttonLabel={restartDevice.button.label}
      deviceInstanceId={deviceInstanceId}
      address={restartDevice.button.address}
      resetCode={restartDevice.button.resetCode}
      dialogTitle={restartDevice.button.title}
      dialogMessage={restartDevice.button.message}
      resetDevice
      actionId="s-port--mini-mcr--restart-device"
    />
  ) : <></>;

  const ackRelayOutputItems = (acknowledgeRelayOutput ?? []).map((ack) => (
    <div className="ack-relay-output" key={`ack-relay-output--${deviceInstanceId}-${ack.button.label}-${ack.button.address}`}>
      <ButtonWithDescriptionControl
        title={ack.label}
        text={ack.subLabel}
        buttonLabel={ack.label}
        deviceInstanceId={deviceInstanceId}
        address={ack.button.address}
        resetCode={ack.button.resetCode}
        dialogTitle={ack.button.title}
        dialogMessage={ack.button.message}
        actionId="s-port--mini-mcr--acknowledge-relay"
      />
    </div>
  ));

  return (
    <div className="command-block">
      <div className="command-block-restart">
        {restartDev}
      </div>
      <div>
        {ackRelayOutputItems}
      </div>
    </div>
  );
};

export default CommandBlock;
