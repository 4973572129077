/* eslint-disable jsx-a11y/label-has-associated-control */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NumberEditorControl from '../../../../../../components/ParameterEditor/NumberEditorControl/NumberEditorControl';
import { CompareValueProps } from '../../../../CompareTypes';
import CompareContainer from '../../../CompareContainer/CompareContainer';
import { setContextParameterHelp } from '../../../../../../store/contexthelp';

export interface CompareFloatValueProps extends CompareValueProps {
  label: string;
  valueLeft: number;
  valueLeftDisplayFormat?: string;
  valueLeftUnit?: string;
  valueRight: number;
  valueRightDisplayFormat?: string;
  valueRightUnit?: string;
  stepSize?: number;
  precision?: number;
  // eslint-disable-next-line no-unused-vars
  onLeftValueChange?: (value: string) => void;
}

const CompareFloatValue
  : React.FC<CompareFloatValueProps> = (props: CompareFloatValueProps)
: React.ReactElement => {
    const {
      onButtonClick, label, help,
      valueLeft, valueLeftDisplayFormat, valueLeftUnit,
      valueRight, valueRightDisplayFormat, valueRightUnit,
      readonly, modified, onLeftValueChange, warning, stepSize,
      onShowContentHelp, onHideContentHelp, buttonFunction, showImageAndSign,
      hideLeftControl, hideRightControl,
      precision,
      equal,
    } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
      <CompareContainer
        label={label}
        buttonFunction={buttonFunction}
        equal={equal}
        onButtonClick={onButtonClick}
        showImageAndSign={showImageAndSign}
        hideLeftControl={hideLeftControl}
        hideRightControl={hideRightControl}
      >
        <NumberEditorControl
          value={valueLeft}
          displayFormat={valueLeftDisplayFormat ?? '%.2f'}
          onValueChange={(value) => onLeftValueChange?.(value)}
          unit={valueLeftUnit}
          readonly={readonly}
          modified={modified}
          warning={warning}
          stepSize={stepSize}
          precision={precision}
          onFocus={() => {
            dispatch(setContextParameterHelp({
              title: t(label),
              text: help ? t(help) : '',
            }));
            onShowContentHelp?.();
          }}
          onBlur={() => {
            dispatch(setContextParameterHelp({
              title: '',
              text: '',
            }));
            onHideContentHelp?.();
          }}
        />
        <NumberEditorControl
          value={valueRight}
          precision={precision}
          displayFormat={valueRightDisplayFormat ?? '%.2f'}
          onValueChange={() => 0}
          unit={valueRightUnit}
          readonly
        />
      </CompareContainer>
    );
  };

export default CompareFloatValue;
