/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import './LayoutCardActionSubmenu.scss';
import openModuleIcon from '../../../../../../assets/icons/icon-open-module.svg';
import highlightModuleIcon from '../../../../../../assets/icons/icon-highlight-module.svg';

export type CardActionSubmenuTypes = 'open' | 'flash';

export interface CardActionSubmenuProps {
    // eslint-disable-next-line no-unused-vars
    onAction: (action: CardActionSubmenuTypes) => void;
  }

export const LayoutCardActionSubmenu:React.FC<CardActionSubmenuProps> = (props: CardActionSubmenuProps): React.ReactElement => {
  const { t } = useTranslation();
  const { onAction } = props;

  return (
    <div>
      <div
        className="layout-card-action-submenu__element"
        onClick={() => onAction('open')}
        role="button"
        onKeyUp={() => {}}
        tabIndex={0}
      >
        <div className="layout-card-action-submenu__element-button">
          <Image src={openModuleIcon} className="layout-card-action-submenu__element-icon" />
        </div>
        <div className="layout-card-action-submenu__element-text">
          {t<string>('CONTACTRON_STATION_CARD_SURFACE_OPEN')}
        </div>
      </div>
      <div
        className="layout-card-action-submenu__element"
        onClick={() => onAction('flash')}
        role="button"
        onKeyUp={() => {}}
        tabIndex={0}
      >
        <div className="layout-card-action-submenu__element-button">
          <Image src={highlightModuleIcon} className="layout-card-action-submenu__element-icon" />
        </div>
        <div className="layout-card-action-submenu__element-text">{t<string>('CONTACTRON_STATION_CARD_SURFACE_FLASH')}</div>
      </div>
    </div>
  );
};
