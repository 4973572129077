/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useEffect, useState, useCallback } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setContextParameterHelp, setContextParameterWarning } from '../../../../store/contexthelp';

const GetStringMaxLength = (descriptor: DeviceModelStatus.DeviceModelDescriptor): number => {
  if (descriptor === undefined) {
    return 0;
  }
  if (descriptor.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return 0;
  }
  if (descriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.STRING) {
    return 0;
  }
  return descriptor.valueType.length;
};

const useStringMessageContextHelp = (
  statusDescriptor: DeviceModelStatus.StatusDescriptor,
  statusValue?: DeviceModelStatus.StatusValue,
  showContextMessageDef?: boolean,
  onShowContentHelp?: () => void,
  onHideContentHelp?: () => void,
): readonly [string | undefined, () => void, () => void] => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [warning, setWarning] = useState<string | undefined>(undefined);
  const [showContextMessage, setShowContextMessage] = useState<boolean>(showContextMessageDef ?? false);

  const statusValueLabel = t(statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? statusDescriptor.label : '');
  const statusValueHelp = t(statusDescriptor?.type === DeviceModelStatus.StatusType.StatusDescriptor ? statusDescriptor.help : '');

  const hideContentHelp = useCallback((): void => {
    setShowContextMessage(false);
    dispatch(setContextParameterWarning(undefined));
    onHideContentHelp?.();
  }, [onHideContentHelp]);

  const showContentHelp = useCallback((): void => {
    setShowContextMessage(true);
    if (statusDescriptor !== undefined) {
      const maxLength = GetStringMaxLength(statusDescriptor);
      const message = t<string>('PARAMETER__VALID_STRING_LENGTH__HELP', {
        MAXLENGTH: `${maxLength}`,
      });
      dispatch(setContextParameterHelp({
        title: statusValueLabel,
        text: statusValueHelp,
        message,
      }));
    }
    onShowContentHelp?.();
  }, [statusDescriptor, statusValueHelp, statusValueLabel, onShowContentHelp]);

  const { value, valueValidityDescription, valueValidity } = statusValue ?? {
    value: undefined,
    valueValidityDescription: undefined,
    valueValidity: DeviceModelStatus.StatusValueValidity.valid,
  };

  useEffect(() => {
    if (value === undefined) {
      return;
    }

    const updateMessage = (message?: string) => {
      if (showContextMessage) {
        dispatch(setContextParameterWarning(message));
      }
      setWarning(message);
    };

    if (valueValidity === DeviceModelStatus.StatusValueValidity.valid) {
      updateMessage(undefined);
    } else {
      updateMessage(t<string>('PARAMETER_VALIDATION_ERROR__OUT_OF_RANGE__MAXLENGTH', {
        MAXLENGTH: valueValidityDescription,
      }));
    }
  }, [value, showContextMessage, valueValidityDescription, valueValidity]);

  return [warning, showContentHelp, hideContentHelp] as const;
};

export default useStringMessageContextHelp;
