/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import { Store } from 'redux';
import { deviceInstancesStoreSelector, systemInformationStoreSelector } from '../../store/reduxStoreSelector';
import { deviceInstanceActiveDeviceInstance } from '../../store/deviceInstances/store/activeDevice/selectors';

const interpolation: { [key: string]: string | (() => string) } = {};

interpolation.DATE_TIME_NOW = () => new Date().toLocaleString(); // i.e. "23.5.2021, 14:02:27"
interpolation.DATE_NOW = new Date().toLocaleDateString(); // i.e. "23.5.2021"
interpolation.TIME_NOW = new Date().toLocaleTimeString(); // i.e. "14:02:27"

/**
 * Initializes handlers for special i18next interpolation values
 * which can be used in translations, i.e. "{{USER_NAME}}" or "{{DEVICE_NAME}}"
 */
export const initInterpolationValues = (store: Store): void => {
  interpolation.USER_NAME = () => {
    // eslint-disable-next-line prefer-destructuring
    const systemInfo = systemInformationStoreSelector(store.getState());
    return systemInfo.userName;
  };
  interpolation.SYSTEM_NAME = () => {
    // eslint-disable-next-line prefer-destructuring
    const systemInfo = systemInformationStoreSelector(store.getState());
    return systemInfo.hostName;
  };
  // eslint-disable-next-line arrow-body-style
  interpolation.DEVICE_NAME = () => {
    const deviceInstances = deviceInstancesStoreSelector(store.getState());
    // eslint-disable-next-line max-len
    const activeDevice = deviceInstanceActiveDeviceInstance(deviceInstances, deviceInstances.activeDeviceInstance);
    return activeDevice?.device?.catalog.deviceTypeName ?? '';
  };
};

/**
 * i18next interpolation fallback to substitute predefined keys.
 * https://www.i18next.com/translation-function/interpolation
 */
export const getInterpolationValue = (key: string): string => {
  let result: string;
  const value = interpolation[key];

  if (typeof value === 'string') {
    result = value;
  } else if (typeof value === 'function') {
    result = value();
  } else {
    result = key;
  }
  return result;
};
