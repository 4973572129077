/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import i18next from 'i18next';
import React, {
  useContext,
  useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getAppUiLanguageCode, setAppUiLanguageCode } from '../../../../i18n-config';

const languageNames = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  pt: 'Português',
  tr: 'Türkçe',
  ru: 'Русский',
  zh: '汉语 (simplified Chinese)',
  pl: 'Polski',
  ja: '日本語 (Japanese)',
  cs: 'Čeština',
  da: 'Dansk',
  fi: 'Suomi',
  hu: 'Magyar',
  ko: '한국어 (Korean)',
  nb: 'Norsk',
  nl: 'Nederlands',
  ro: 'Română',
  sv: 'Svenska',
  uk: 'Yкраїнська',
};

const BackstageLanguageSettings: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const [languageCodes, setLanguageCodes] = useState<string[]>([]);
  const [language, setLanguage] = useState<string>('en');

  useEffect(() => {
    let supportedLngs: string[] = [];
    if (typeof i18next.options.supportedLngs !== 'object') {
      supportedLngs = ['en'];
    } else {
      supportedLngs = i18next.options.supportedLngs
        .filter((lng) => (lng !== 'dev' && lng !== 'cimode'))
        .map((lng) => lng);
    }

    const currentLangCode = getAppUiLanguageCode();
    setLanguageCodes(supportedLngs);
    setLanguage(currentLangCode);
  }, []);

  useEffect(() => {
    setAppUiLanguageCode(language);
  }, [language]);

  return (
    <>
      {`${t<string>('BACKSTAGE_PAGE_SETTINGS__LANGUAGE')}`}
      <br />
      <select
        value={language}
        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
          setLanguage(ev.target.value);
        }}
      >
        {
          languageCodes.map((langCode) => (
            <option value={langCode} key={`lang-code-${langCode}`}>
              {languageNames[langCode] ?? langCode}
            </option>
          ))
        }
      </select>
    </>
  );
};

export default BackstageLanguageSettings;
