/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
// eslint-disable-next-line import/no-cycle
import './ChannelStatusControl.css';

export interface ChannelStatusControlProps {
    title: string,
    nomVariableTitle: string,
    variableLabel: string,
    currentValue: string;
    unit?: string;
    color: string;
}

export const ChannelStatusControl: React.FC<ChannelStatusControlProps> = (
  props: ChannelStatusControlProps,
): React.ReactElement => {
  const {
    title, nomVariableTitle, variableLabel, unit, currentValue, color,
  } = props;

  const classes = `ChannelStatusControl__Container-Rectangle ChannelStatusControl_Led-${color}`;
  const label = `${variableLabel}: `;

  return (
    <div className="ChannelStatusControl__Container">
      <div className={classes} />
      <div className="ChannelStatusControl__TitleContainer">
        {
            title && (
            <div className="ChannelStatusControl__TitleContainer-channel">
              {title}
            </div>
            )
        }
        <div className="ChannelStatusControl__TitleContainer-current">
          <div className="ChannelStatusControl__TitleContainer-label">{nomVariableTitle}</div>
          <div className="ChannelStatusControl__TitleContainer-valueUnit">
            <div className="ChannelStatusControl__TitleContainer-valueUnit-label">{label}</div>
            <div className="ChannelStatusControl__TitleContainer-valueUnit-value">{currentValue}</div>
            <div className="ChannelStatusControl__TitleContainer-valueUnit-unit">{unit}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
