/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../store';
import { SplineCalculationStatus } from '../../../../store/linearizationDataset/types';
import {
  Axis, LinearizationRecord, Point, Serie,
} from '../../Interfaces/LinearizationInterface';
import { DrawableLabel, HorizontalAxis } from '../Axis/Horizontal/HorizontalAxis';
import { VerticalAxis } from '../Axis/Vertical/VerticalAxis';
import { AxisAlignedBoundingBox } from '../AxisAlignedBoundingBox';
import { DeviationPoint } from '../Point/DeviationPoint/DeviationPoint';
import { LinearizationPoint } from '../Point/LinearizationPoint/LinearizationPoint';
import { TicksCalculator } from './TicksCalculator';
import './Chart.css';

export type chartType = 'small' | 'large';

export interface LabelspaceInfo {
  dimension: number;
  labelSpace: number;
}

export interface PlotArea {
  width: number;
  height: number;
}

export interface ChartProps {
  // eslint-disable-next-line no-unused-vars
  onClick: (pointIndex: number) => void;
  type: chartType;
}

const Round2Decimals = (value: number): number => (Math.round(value * 100) / 100);

const GetPointsRectangle = (points: Point[]):AxisAlignedBoundingBox => {
  let minX = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;
  let minY = Number.POSITIVE_INFINITY;
  let maxY = Number.NEGATIVE_INFINITY;

  for (let i = 0; i < points.length; i += 1) {
    const currentPoint = points[i];

    if (currentPoint.xValue < minX) {
      minX = currentPoint.xValue;
    }

    if (currentPoint.xValue > maxX) {
      maxX = currentPoint.xValue;
    }

    if (currentPoint.yValue < minY) {
      minY = currentPoint.yValue;
    }

    if (currentPoint.yValue > maxY) {
      maxY = currentPoint.yValue;
    }
  }

  return new AxisAlignedBoundingBox(minX, minY, maxX, maxY);
};

// eslint-disable-next-line no-unused-vars
const GetLinearizationBoundingBox = (serie: Serie, xAxis: Axis): AxisAlignedBoundingBox => {
  const pointsContainer = GetPointsRectangle(serie.data);
  const yLabelSpace = serie.yAxis.labelSpace;
  const { minX, maxX } = pointsContainer;
  const minY = Math.floor(pointsContainer.minY / yLabelSpace) * yLabelSpace;
  const maxY = Math.ceil(pointsContainer.maxY / yLabelSpace) * yLabelSpace;

  // MaxY needs to be adjusted in order to have odd number of vertical labels
  const correctedMaxY = ((maxY - minY) / yLabelSpace) % 2 === 0 ? maxY : maxY + yLabelSpace;

  return new AxisAlignedBoundingBox(minX, minY, maxX, correctedMaxY);
};

const FitToDimension = (
  value: number,
  minValue: number,
  maxValue: number,
  screenDimension: number,
): number => ((value - minValue) / (maxValue - minValue)) * screenDimension;

const ScalePointToPlotArea = (point: Point, boundingBox: AxisAlignedBoundingBox, plotArea: PlotArea): Point => {
  const scaledX = FitToDimension(point.xValue, boundingBox.minX, boundingBox.maxX, plotArea.width);
  const scaledY = FitToDimension(point.yValue, boundingBox.minY, boundingBox.maxY, plotArea.height);

  return {
    xValue: scaledX,
    yValue: scaledY,
    isXValid: point.isXValid,
    isYValid: point.isYValid,
  };
};

const CreateLine = (serie: Serie, boundingBox: AxisAlignedBoundingBox, plotArea: PlotArea): JSX.Element[] => {
  const lineSegments: JSX.Element[] = [];
  const points = serie.data;

  for (let i = 0; i < points.length - 1; i += 1) {
    const startPoint = ScalePointToPlotArea(points[i], boundingBox, plotArea);
    const endPoint = ScalePointToPlotArea(points[i + 1], boundingBox, plotArea);
    const lineKey = `line${i}-startX${startPoint.xValue}-startY${startPoint.yValue}-endX${endPoint.xValue}-endY${endPoint.yValue}`;
    lineSegments.push((
      <line key={lineKey} x1={startPoint.xValue} y1={startPoint.yValue} x2={endPoint.xValue} y2={endPoint.yValue} className="Chart-control-line" style={{ stroke: `${serie.color}` }} />
    ));
  }

  return lineSegments;
};

const CreateLeadingLines = (scaledPoint: Point): JSX.Element[] => {
  const leadingLines: JSX.Element[] = [];

  leadingLines.push((
    <line key={`hor-leadline-startX0-startY${scaledPoint.yValue}-endX${scaledPoint.xValue}-endY${scaledPoint.yValue}`} x1={0} y1={scaledPoint.yValue} x2={scaledPoint.xValue} y2={scaledPoint.yValue} className="Chart-control-leadingLine" />
  ));
  leadingLines.push((
    <line key={`ver-leadline-startX${scaledPoint.xValue}-startY0-endX${scaledPoint.xValue}-endY${scaledPoint.yValue}`} x1={scaledPoint.xValue} y1={0} x2={scaledPoint.xValue} y2={scaledPoint.yValue} className="Chart-control-leadingLine" />
  ));

  return leadingLines;
};

const CreateGrid = (boundingBox: AxisAlignedBoundingBox, horizontalLabels: DrawableLabel[], verticalLabels: number[], plotArea: PlotArea): JSX.Element[] => {
  const boxHeight = boundingBox.Height();
  const boxWidth = boundingBox.Width();

  const gridLines: JSX.Element[] = [];
  for (let i = 1; i <= horizontalLabels.length - 1; i += 1) {
    const labelXPosition = horizontalLabels[i].label;
    const scaledX = ((labelXPosition - boundingBox.minX) / boxWidth) * plotArea.width;
    gridLines.push((
      <line key={`ver-gridline${i}-x${scaledX}-y0`} x1={scaledX} y1={0} x2={scaledX} y2={plotArea.height} className="Chart-control-gridline" />
    ));
  }

  for (let i = 1; i <= verticalLabels.length - 1; i += 1) {
    const labelYPosition = verticalLabels[i];
    const scaledY = ((labelYPosition - boundingBox.minY) / boxHeight) * plotArea.height;
    gridLines.push((
      <line key={`hor-gridline${i}-x0-y${scaledY}`} x1={0} y1={scaledY} x2={plotArea.width} y2={scaledY} className="Chart-control-gridline" />
    ));
  }

  return gridLines;
};

const CreateHorizontalAxisLabels = (boundingBox: AxisAlignedBoundingBox, space: number): DrawableLabel[] => {
  const labels: DrawableLabel[] = [];

  labels.push({ label: boundingBox.minX, isVisible: false }); // First label
  const secondLabelValue = Math.ceil(boundingBox.minX / space) * space === boundingBox.minX
    ? (Math.ceil(boundingBox.minX / space) * space) + space : Math.ceil(boundingBox.minX / space) * space;
  const roundedSecondLabelValue = Round2Decimals(secondLabelValue);
  labels.push({ label: roundedSecondLabelValue, isVisible: true });

  let lastLabelValue = secondLabelValue;
  while (lastLabelValue < boundingBox.maxX) {
    lastLabelValue += space;
    const roundedLabelValue = Round2Decimals(lastLabelValue);
    if (roundedLabelValue < Round2Decimals(boundingBox.maxX)) {
      labels.push({ label: roundedLabelValue, isVisible: true });
    }
  }

  labels.push({ label: boundingBox.maxX, isVisible: false }); // Last label

  return labels;
};

const CreateVerticalAxisLabels = (boundingBox: AxisAlignedBoundingBox, space: number): number[] => {
  const labelsCount = (boundingBox.Height()) / space;
  const labels: number[] = [];

  for (let i = 0; i <= labelsCount; i += 1) {
    const labelValue = boundingBox.minY + i * space;
    labels.push(labelValue);
  }

  return labels;
};

const GetDeviationBoundingBox = (baseBoundingBox: AxisAlignedBoundingBox, serie: Serie, numberOfLabels): AxisAlignedBoundingBox => {
  const floor = Math.floor(numberOfLabels / 2);
  const { minX, maxX } = baseBoundingBox;
  const minY = 0 - serie.yAxis.labelSpace * floor;
  const maxY = (numberOfLabels - 1) * serie.yAxis.labelSpace + minY;

  return new AxisAlignedBoundingBox(minX, minY, maxX, maxY);
};

const CreateLinearizationSerie = (points: Point[], pointsBox: AxisAlignedBoundingBox, unit: string, name: string): Serie => {
  const color = '#1D778A';
  const ticksCalculator = new TicksCalculator(pointsBox.minY, pointsBox.maxY, 8);
  const formatedUnit = unit ?? '';

  const yAxis: Axis = {
    labelSpace: ticksCalculator.getTickSpacing(),
    unit: formatedUnit,
    name,
  };

  return { data: points, color, yAxis };
};

const CreateDeviationSerie = (points: Point[], pointsBox: AxisAlignedBoundingBox, labelsCount: number, name: string, unit: string): Serie => {
  const floor = Math.floor(labelsCount / 2);
  const ticksCalculator = new TicksCalculator(pointsBox.minY, pointsBox.maxY, 8);
  let calculatedLabelSpace = ticksCalculator.getTickSpacing();
  calculatedLabelSpace = calculatedLabelSpace < 0.2 ? 0.2 : calculatedLabelSpace;

  if (pointsBox.IsValid()) {
    while (Math.abs(pointsBox.minY) > floor * calculatedLabelSpace || Math.abs(pointsBox.maxY) > floor * calculatedLabelSpace) {
      calculatedLabelSpace += calculatedLabelSpace;
    }
  }

  const formatedUnit = unit ?? '';
  const color = '#E9C982';
  const yAxis: Axis = {
    labelSpace: calculatedLabelSpace,
    unit: formatedUnit,
    name,
  };

  return { data: points, color, yAxis };
};

const CreateXAxis = (pointsBox: AxisAlignedBoundingBox, unit: string, name: string): Axis => {
  const ticksCalculator = new TicksCalculator(pointsBox.minX, pointsBox.maxX, 10);
  const formatedUnit = unit ?? '';

  return {
    labelSpace: ticksCalculator.getTickSpacing(),
    name,
    unit: formatedUnit,
  };
};

export const Chart: React.FC<ChartProps> = (props: ChartProps): React.ReactElement => {
  const { type, onClick } = props;
  const { t } = useTranslation();
  const linearizationTable = useTypedSelector((state) => state.linearizationDataset.table);
  const pointsCounter = useTypedSelector((state) => state.linearizationDataset.pointsCounter);
  const chartRef = useRef<HTMLDivElement>(null);
  const [hoveredPoint, setHoveredPoint] = useState<Point>({
    xValue: 0, yValue: 0, isXValid: true, isYValid: true,
  });
  const xAxisUnit = useTypedSelector((state) => state.linearizationDataset.table.header.header1);
  const yAxisUnit = useTypedSelector((state) => state.linearizationDataset.table.header.header2);

  const [chartDimensions, setChartDimension] = useState({
    width: 0,
    height: 0,
  });
  const splineCalcStatus = useTypedSelector((state) => state.linearizationDataset.splineCalculationState);

  const linearizationDataPoints: Point[] = linearizationTable.linearizationData.filter((value, index) => index <= pointsCounter.value - 1)
    .map((item) => ({
      xValue: item.xValue, yValue: item.yValue, isXValid: item.isXValid, isYValid: item.isYValid,
    }));
  const linearizationPointsBox = GetPointsRectangle(linearizationDataPoints);
  const xAxis = CreateXAxis(linearizationPointsBox, t(xAxisUnit), t<string>('LINTABLE__CHART_VIEW_TEMPERATURE'));
  const linearizationSerie = CreateLinearizationSerie(linearizationDataPoints, linearizationPointsBox, t(yAxisUnit), t<string>('LINTABLE__CHART_VIEW_VOLTAGE'));
  const linearizationBoundingBox = GetLinearizationBoundingBox(linearizationSerie, xAxis);
  const verticalLabelsCount = linearizationPointsBox.IsValid() ? (linearizationBoundingBox.Height() / linearizationSerie.yAxis.labelSpace) + 1 : 0;

  const deviationDataPoints: Point[] = linearizationTable.linearizationData.filter((value, index) => index <= pointsCounter.value - 1)
    .map((item) => ({
      xValue: item.xValue, yValue: item.deviationValue, isXValid: item.isXValid, isYValid: item.isYValid,
    }));
  const deviationPointsBox = GetPointsRectangle(deviationDataPoints);
  const deviationSerie = CreateDeviationSerie(deviationDataPoints, deviationPointsBox, verticalLabelsCount, t<string>('LINTABLE__CHART_VIEW_LINEARIZATION_ERROR'), t(xAxisUnit));
  const deviationBoundingBox = GetDeviationBoundingBox(linearizationBoundingBox, deviationSerie, verticalLabelsCount);

  const linearizationLine = CreateLine(linearizationSerie, linearizationBoundingBox, chartDimensions);
  const deviationLine = CreateLine(deviationSerie, deviationBoundingBox, chartDimensions);

  const onPointEnterHandler = (point: Point) => {
    setHoveredPoint(point);
  };

  const onPointLeaveHandler = (point: Point) => {
    setHoveredPoint({
      xValue: 0,
      yValue: 0,
      isXValid: true,
      isYValid: true,
    });
  };

  const linearizationPoints = linearizationSerie.data.map((point, index) => {
    const linearizationValue: LinearizationRecord = {
      xAxisRecord: {
        measurement: xAxis.name,
        value: point.xValue,
        unit: xAxis.unit,
      },
      yAxisRecord: {
        measurement: linearizationSerie.yAxis.name,
        value: point.yValue,
        unit: linearizationSerie.yAxis.unit,
      },
      deviationRecord: {
        measurement: deviationSerie.yAxis.name,
        value: deviationSerie.data[index].yValue,
        unit: deviationSerie.yAxis.unit,
      },
    };
    const scaledPoint = ScalePointToPlotArea(point, linearizationBoundingBox, chartDimensions);
    const key = `linearizationPoint-${index}`;
    return (
      <LinearizationPoint
        key={key}
        onClick={() => onClick(index)}
        drawingPoint={scaledPoint}
        linearizationValue={linearizationValue}
        isValid={point.isXValid && point.isYValid}
        onMouseEnter={onPointEnterHandler}
        onMouseLeave={onPointLeaveHandler}
      />
    );
  });
  const deviationPoints = deviationSerie.data.map((point, index) => {
    const scaledPoint = ScalePointToPlotArea(point, deviationBoundingBox, chartDimensions);
    const key = `deviationPoint-${index}`;
    return (
      <DeviationPoint key={key} x={scaledPoint.xValue} y={scaledPoint.yValue} color={deviationSerie.color} />
    );
  });

  const leadingLines = CreateLeadingLines(hoveredPoint);
  const horizontalAxisLabels = CreateHorizontalAxisLabels(linearizationBoundingBox, xAxis.labelSpace);
  const linearizationVerticalAxisLabels = CreateVerticalAxisLabels(linearizationBoundingBox, linearizationSerie.yAxis.labelSpace);
  const deviationVerticalAxisLabels = CreateVerticalAxisLabels(deviationBoundingBox, deviationSerie.yAxis.labelSpace);
  const gridLines = CreateGrid(linearizationBoundingBox, horizontalAxisLabels, linearizationVerticalAxisLabels, chartDimensions);

  useEffect(() => {
    const ploatAreaElement = chartRef.current;
    const observer = new ResizeObserver(() => {
      if (ploatAreaElement) {
        const chartWidth = ploatAreaElement.clientWidth;
        const chartHeight = ploatAreaElement.clientHeight;

        if (chartWidth !== chartDimensions.width || chartHeight !== chartDimensions.height) {
          setChartDimension({ height: chartHeight, width: chartWidth });
        }
      }
    });

    if (ploatAreaElement) {
      observer.observe(ploatAreaElement);
    }

    return () => {
      if (ploatAreaElement) {
        observer.unobserve(ploatAreaElement);
      }
    };
  }, [chartRef]);

  const linearizationAxis = type === 'large' ? (
    <div style={{ height: `${chartDimensions.height}px`, marginRight: '12px' }} className="Chart-control-graphics-verticalBar-left">
      <VerticalAxis labels={linearizationVerticalAxisLabels} alignment="right" />
    </div>
  ) : (
    <div style={{ height: `${chartDimensions.height}px` }} className="Chart-control-graphics-verticalBar-left Chart-control-verticalAxis-minimalistic">
      <div
        style={{
          transformOrigin: 'center bottom', transform: 'rotate(-90deg)', whiteSpace: 'nowrap', color: `${linearizationSerie.color}`,
        }}
        className="Chart-control-legend-item"
      >
        {`${linearizationSerie.yAxis.unit}`}
      </div>
    </div>
  );

  const deviationAxis = type === 'large' ? (
    <div style={{ height: `${chartDimensions.height}px`, marginLeft: '8px' }} className="Chart-control-graphics-verticalBar-right">
      <VerticalAxis labels={deviationVerticalAxisLabels} alignment="left" />
    </div>
  ) : (
    <div style={{ height: `${chartDimensions.height}px` }} className="Chart-control-graphics-verticalBar-right Chart-control-verticalAxis-minimalistic">
      <div
        style={{
          transformOrigin: 'center bottom', transform: 'rotate(90deg)', whiteSpace: 'nowrap', color: `${deviationSerie.color}`,
        }}
        className="Chart-control-legend-item"
      >
        {`${deviationSerie.yAxis.unit}`}
      </div>
    </div>
  );

  const horizontalAxisName = `${t<string>(xAxis.unit)}`;
  const horizontalAxis = type === 'large' ? (
    <div className="Chart-control-graphics-horizontalBar">
      <HorizontalAxis labels={horizontalAxisLabels} name={horizontalAxisName} boundingBox={linearizationBoundingBox} />
    </div>
  ) : (
    <div className="Chart-control-horizontalAxis-minimalistic">
      {horizontalAxisName}
    </div>
  );

  const chartRatio = type === 'large' ? { width: 80, height: 80 } : { width: 80, height: 40 };
  const graphicsStyle = {
    width: `${chartRatio.width}%`,
    height: `${chartRatio.height}%`,
  };

  // Currently className="Chart-control" from css file is not working and this is fixed with inline style

  return (
    <div
      data-testid="chart"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      {type === 'large' ? (
        <div className="Chart-control-legend">
          <div style={{ color: `${linearizationSerie.color}` }} className="Chart-control-legend-item">
            {`${linearizationSerie.yAxis.unit}`}
          </div>
          <div style={{ color: `${deviationSerie.color}` }} className="Chart-control-legend-item">
            {`${deviationSerie.yAxis.unit}`}
          </div>
        </div>
      ) : undefined}
      <div style={graphicsStyle} className="Chart-control-graphics">
        <div ref={chartRef} className="Chart-control-plotArea">
          <svg className="Chart-control-svg">
            {gridLines}
            {leadingLines}
            {linearizationLine}
            {splineCalcStatus === SplineCalculationStatus.done ? (
              <>
                {deviationLine}
              </>
            ) : undefined }
          </svg>
          {type === 'large' ? linearizationPoints : undefined}
          {(splineCalcStatus === SplineCalculationStatus.done && type === 'large' ? (
            <>{deviationPoints}</>
          ) : undefined)}
        </div>
        {linearizationAxis}
        {deviationAxis}
        {horizontalAxis}
      </div>
    </div>
  );
};
