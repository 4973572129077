/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';
import fdcml10DeviceModelStatusValueCollection from './deviceModelStatusVector.fdcml10';
import deviceModelParametersStatusVector from './deviceModelStatusVector.parameters';

const deviceModelStatusVector: DeviceModelStatus.StatusValueCollection = {
  ...fdcml10DeviceModelStatusValueCollection,
  ...deviceModelParametersStatusVector,
};

export default deviceModelStatusVector;
