/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../store';
import { distinct } from '../../BackstagePageServices';
import caparocModuleList from './CaparocModuleList.json';
import mac1050222DeviceList from './MACX_1050222_DeviceList.json';
import mac1050233DeviceList from './MACX_1050233_DeviceList.json';
import mac2865654DeviceList from './MACX_2865654_DeviceList.json';
import mac2865751DeviceList from './MACX_2865751_DeviceList.json';
import hiddenDeviceList from './HiddenDeviceList.json';

interface SupportedDevice {
  deviceCatalogIdent: string;
  deviceTypeName: string;
  productOrderNumber: string;
  productURL: string;
  deviceVendor: string;
  deviceFamily: string;
  protocol: string;
}

const isCaparocStation = (dt: SupportedDevice): boolean => `${dt.productOrderNumber}` === '1115670';

const addCaparocModuleList = (supportedDeviceList: SupportedDevice[])
: SupportedDevice[] => supportedDeviceList.reduce((acc, dt) => {
  if (!isCaparocStation(dt)) {
    return [
      ...acc,
      dt,
    ];
  }
  const supportedModuleList: SupportedDevice[] = caparocModuleList.map((mod) => ({
    deviceCatalogIdent: `caparoc-module-${mod.deviceTypeName.toLocaleLowerCase()}`,
    deviceFamily: 'CAPAROC IOL',
    deviceTypeName: mod.deviceTypeName,
    deviceVendor: 'Phoenix Contact',
    productOrderNumber: mod.productOrderNumber,
    productURL: mod.productURL,
    protocol: 'io-link',
  }));
  return [
    ...acc,
    dt,
    ...supportedModuleList,
  ];
}, [] as SupportedDevice[]);

const macxDeviceMap = {
  o1050222: mac1050222DeviceList,
  o1050233: mac1050233DeviceList,
  o2865654: mac2865654DeviceList,
  o2865751: mac2865751DeviceList,
};

const addMACXDeviceList = (supportedDeviceList: SupportedDevice[])
: SupportedDevice[] => supportedDeviceList.reduce((acc, dt) => {
  const key = `o${dt.productOrderNumber}`;
  if (macxDeviceMap[key] === undefined) {
    return [
      ...acc,
      dt,
    ];
  }
  const supportedModuleList: SupportedDevice[] = macxDeviceMap[key]
    .map((mod) => ({
      deviceCatalogIdent: `macx-device-${mod.deviceTypeName.toLocaleLowerCase()}`,
      deviceFamily: 'MACX MCR',
      deviceTypeName: mod.deviceTypeName,
      deviceVendor: 'Phoenix Contact',
      productOrderNumber: mod.productOrderNumber,
      productURL: mod.productURL,
      protocol: 's-port',
    }));
  return [
    ...acc,
    dt,
    ...supportedModuleList,
  ];
}, [] as SupportedDevice[]);

const BackstageSupportedDeviceList: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  const deviceCatalog = useTypedSelector((state) => state.discoveryService.catalog.deviceList);
  const supportedDeviceList = Object.keys(deviceCatalog).reduce((acc, key) => {
    const device = deviceCatalog[key];
    if (hiddenDeviceList.find((item) => `${item.productOrderNumber}` === `${device.productOrderNumber}`) !== undefined) {
      return acc;
    }
    return [
      ...acc,
      {
        deviceTypeName: device.deviceTypeName,
        productOrderNumber: device.productOrderNumber,
        productURL: device.productURL,
        deviceCatalogIdent: device.deviceCatalogIdent,
        deviceFamily: device.deviceFamily,
        deviceVendor: device.deviceVendor,
        protocol: device.protocol,
      }];
  }, [] as SupportedDevice[]);

  const collator = new Intl.Collator();
  let sortedDeviceList = distinct(supportedDeviceList, (dt) => dt.deviceTypeName)
    .sort((a, b) => collator.compare(a?.deviceTypeName, b?.deviceTypeName));
  // replace single protocol by list of protocls supported by device variants
  sortedDeviceList = sortedDeviceList.map((dt) => {
    const variants = Object.keys(deviceCatalog)
      .filter((key) => deviceCatalog[key].deviceTypeName === dt.deviceTypeName);
    let protocols = variants.map((dc) => deviceCatalog[dc].protocol);
    protocols = protocols.filter((pr, idx, arr) => arr.indexOf(pr) === idx);
    const protocol = protocols.join(', ');
    return { ...dt, protocol };
  });

  // Add CAPAROC Modules after Station
  sortedDeviceList = addCaparocModuleList(sortedDeviceList);
  sortedDeviceList = addMACXDeviceList(sortedDeviceList);

  const columnCount = 4; // split device list into multiple columns
  const columnLength = Math.ceil(sortedDeviceList.length / columnCount);
  const deviceListColumn = [...Array(columnCount).keys()].map((iCol) => (
    sortedDeviceList.slice(iCol * columnLength, (iCol + 1) * columnLength)));

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {deviceListColumn.map((deviceList, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`deviceList-${index}`} style={{ margin: '1em' }}>
          {deviceList.map((item) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`deviceList-${index}-${item.deviceCatalogIdent}`}
              title={
                `${item.deviceVendor}\n`
                + `${item.deviceTypeName}\n`
                + `${t<string>('BACKSTAGE_PAGE_APP_INFO__DEVICE_TYPE_FAMILY')}: ${item.deviceFamily}\n`
                + `${t<string>('BACKSTAGE_PAGE_APP_INFO__DEVICE_TYPE_PROTOCOL')}: ${item.protocol}\n`
              }
            >
              {item.deviceTypeName}
              {' ('}
              <a
                title={item.productURL}
                href={item.productURL}
                target="_blank"
                rel="noreferrer"
              >
                {item.productOrderNumber}
              </a>
              {') '}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default BackstageSupportedDeviceList;
