/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { WizardViewPageProps } from '../../../WizardView/WizardViewPage';
import './Page5SelectProcessData.scss';
import { SelectProcessDataParent } from './SelectProcessData/SelectProcessDataParent';

export interface Page5SelectProcessDataProps extends WizardViewPageProps {
  // eslint-disable-next-line react/no-unused-prop-types
  stationInstanceId: string;
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType;
}

const Page5SelectProcessData
: React.FC<Page5SelectProcessDataProps> = (props: Page5SelectProcessDataProps) : React.ReactElement => {
  const { stationDeviceDescription } = props;

  return (
    <div className="contactron-commissioning-wizard">
      <div className="page-station-select-process-data">
        <SelectProcessDataParent stationDeviceDescription={stationDeviceDescription} />
      </div>
    </div>
  );
};

export default Page5SelectProcessData;
